import { IPaginationRequest, IPaginationResponse, WahioFile } from ".";
import { AccountUser } from "./accountUser";
import { Contact } from "./contact";
import { IDiscountOrderItem, IOrderBase, OrderBaseItem, ITaxOrderItem, OrderItemStatus, OrderStatus } from "./orderBase";
import { IOrderRefund, OrderPayment, PurchasePayment } from "./orderPayment";
import { IOrganization } from "./organizationTypes";
import { IProduct, IProductVariant } from "./product";
import { IWarehouse } from "./warehouse";
import { IWarehouseLocation } from "./warehouseLocation";

export const UPDATE_CURRENT_PURCHASE = "UPDATE_CURRENT_PURCHASE";
export const ADD_PURCHASE = "ADD_PURCHASE";
export const UPDATE_PURCHASE = "UPDATE_PURCHASE";
export const REQUEST_PURCHASES = "REQUEST_PURCHASES";
export const RECEIVE_PURCHASES = "RECEIVE_PURCHASES";
export const INVALID_REQUEST_PURCHASES = "INVALID_REQUEST_PURCHASES";

export interface ICurrentPurchaseState {
    currentPurchase: IPurchase;
}

export interface IPurchaseState {
    pagination: IPaginationResponse<IPurchase>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface IPurchaseItem extends OrderBaseItem {
    purchaseId?: string;
    quantityReceived: number;
    quantityReceivedTemp: number;
    location?: IWarehouseLocation;
    warehouse?: IWarehouse;
    newSerials?: string[];
    quantityReturned: number;
    hasReturns: boolean;
    lastReturnDate?: Date;
    additionalInfoLoaded?: boolean; //ONLY UI
    isSelected?: boolean; //ONLY UI
}

export class PurchaseItem implements IPurchaseItem {
    constructor(product: IProduct, quantity: number, warehouse?: IWarehouse) {
        this.product = product;
        this.productId = product.id;
        this.unitPrice = product.basePrice;
        this.totalUnitPrice = product.basePrice;
        this.quantity = quantity;

        if (warehouse && warehouse.id) {
            this.warehouseId = warehouse.id;
            this.warehouse = warehouse;
        }
    }
    id?: string;
    productId: string;
    product: IProduct;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    purchaseId?: string;
    quantity: number = 1;
    quantityReceived: number = 0;
    quantityReceivedTemp: number = 0;
    unitPrice: number;
    totalUnitPrice: number;
    stockTemp?: number = 0;
    comment?: string;
    position: number = 0;
    status: OrderItemStatus = "empty";
    locationCode?: string;
    location?: IWarehouseLocation;
    discounts: IDiscountOrderItem[] = [];
    taxes: ITaxOrderItem[] = [];
    manualPercentageDiscount: number = 0;
    manualPercentageDiscountValue: number = 0;
    productVariantId?: string | undefined;
    productVariant?: IProductVariant | undefined;
    basePrice: number = 0;
    totalTax: number = 0;
    totalTaxPercentage: number = 0;
    availableStock: number = 0;
    quantityReturned: number = 0;
    hasReturns: boolean = false;
    additionalInfoLoaded?: boolean = false; //ONLY UI
    isSelected?: boolean = false; //ONLY UI

    basePricePreTaxDiscount: number = 0;
    subTotal: number = 0;
    total: number = 0;
}

export interface ICurrentSaleState {
    currentPurchase: IPurchase;
}

export interface IStatusHistory {
    id: string;
    purchaseId: string;
    status: OrderStatus;
    dateCreated: Date;
}
export interface IItemHistory {
    id: string;
    purchaseId: string;
    productId: string;
    product?: IProduct;
    quantity: number;
    groupId: string;
    dateCreated: Date;
}

export interface IPurchaseDelete {
    id: string;
    accountUserId: string;
    accountUser?: AccountUser;
    cashRegisterId: string;
    orderId: string;
    dateDeleted: Date;
    comment: string;
    status: string;
}
export interface IPurchase extends IOrderBase {
    totalQuantity: number;
    auxid?: string;
    totalQuantityReceived: number;
    totalQuantityReturned: number;
    totalDelivery: number;
    supplierId: string;
    supplier?: Contact;
    warehouseId: string;
    warehouse?: IWarehouse;
    statusHistory: IStatusHistory[];
    items: IPurchaseItem[];
    payments: PurchasePayment[];
    refunds: IOrderRefund[];
    itemsHistory: IItemHistory[];
    orderDelete?: IPurchaseDelete;
    accountUserEntitiesLoaded?: boolean;
    supplierInvoiceNumber: string;
    hasReturns?: boolean;
    dateCreatedCustom?: Date;
    comments?: IPurchaseComment[];
    commentsCount: number;
    totalAmountReturns: number;
}
export class Purchase implements IPurchase {
    constructor(accountId: string, accountUserId: string, organizationId: string, warehouse?: IWarehouse) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
        this.organizationId = organizationId;

        if (warehouse) {
            this.warehouseId = warehouse.id ? warehouse.id : "";
            this.warehouse = warehouse;
        }
    }

    id?: string;
    accountId: string;
    dateCreated?: Date;
    number: number = 0;
    accountUserId: string;
    totalAmount: number = 0;
    subTotal: number = 0;
    totalPaid: number = 0;
    totalPayment: number = 0;
    totalRefund: number = 0;
    totalQuantity: number = 0;
    totalQuantityReceived: number = 0;
    totalQuantityReturned: number = 0;
    organizationId: string;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    supplierId: string = "";
    supplier?: Contact;
    costCenterId: string = "";
    comment: string = "";
    totalDelivery: number = 0;
    totalTaxItems: number = 0;
    dateUpdated?: Date | undefined;
    totalAmountItems: number = 0;
    totalAmountReturns: number = 0;
    totalItemsDiscount: number = 0;
    totalDiscount: number = 0;
    totalTip: number = 0;
    percentageDiscount: number = 0;
    percentageTip: number = 0;
    moneyReceived: number = 0;
    moneyReturned: number = 0;
    hasReturns: boolean = false;
    commentsCount: number = 0;
    comments: IPurchaseComment[] = [];

    status: OrderStatus = "paid";
    statusHistory: IStatusHistory[] = [];
    items: IPurchaseItem[] = [];
    payments: OrderPayment[] = [];
    refunds: IOrderRefund[] = [];
    itemsHistory: IItemHistory[] = [];
    paymentTermsDays: number = 0;

    orderDelete?: IPurchaseDelete;
    accountUserEntitiesLoaded?: boolean;
    supplierInvoiceNumber: string = "";

    organization?: IOrganization | undefined;
    accountUser?: AccountUser | undefined;
    totalManualPercentageDiscountItems: number = 0;
    lastAccountUserId: string = "";

    itemsCount: number = 0;
    itemsQuantityCount: number = 0;

    taxIncluded: boolean = true;

    includesTaxWithholding: boolean = false;
    taxWithholdingName: string = "";
    taxWithholdingPercentage: number = 0;
    taxWithholdingValue: number = 0;

    preTaxDiscount: boolean = true;
    files: WahioFile[] = [];
}

export interface IPurchaseComment {
    id?: string;
    orderId: string;
    accountUserId: string;
    description: string;
    dateCreated?: Date;
    dateUpdate?: Date;
    orderNumber?: number; //SEARCH ONLY
    supplierName?: string; //SEARCH ONLY
}

export interface IPurchaseSearchModel extends IPaginationRequest {
    accountId: string;
    accountUserId?: string;
    organizationId?: string;
    warehouseId?: string;
    supplierId?: string;
    status?: string;
    searchValue?: string;
    dateRangeActive?: boolean;
    dateStart?: Date | string;
    dateEnd?: Date | string;
}
export interface IUpdateCurrentPurchase {
    type: typeof UPDATE_CURRENT_PURCHASE;
    value: IPurchase;
}

export interface RequestPurchases {
    type: typeof REQUEST_PURCHASES;
    value: IPurchaseSearchModel;
}
export interface ReceivePurchases {
    type: typeof RECEIVE_PURCHASES;
    value: IPaginationResponse<IPurchase>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_PURCHASES;
    value: any;
}
export interface UpdatePurchase {
    type: typeof UPDATE_PURCHASE;
    value: IPurchase;
}

export interface AddPurchase {
    type: typeof ADD_PURCHASE;
    value: IPurchase;
}

export type PurchaseTypes = RequestPurchases | ReceivePurchases | InvalidRequest | UpdatePurchase | AddPurchase | IUpdateCurrentPurchase;
