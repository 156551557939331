import React, { useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { wahioFetch } from "../../../api";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { IWarehouseProductLocation, IWarehouseProductLocationAdjustmentInput } from "../../../api/models/warehouseLocation";
import { PrimaryButton, DefaultButton } from "../../_controls";
import LoadingDualRing from "../../LoadingDualRing";
import Modal, { IModalShow } from "../../modals/Modal";
import TextBoxNumeral from "../../TextBoxNumeral";
import { NewProductLocationContainer } from "./styled";
import WarehouseProductLocationCard from "./WarehouseProductLocationCard";
import { FormGroup, FormFooter } from "../../_controls/forms/styled";
import ConfirmationModal from "../../modals/ConfirmationModal";

interface IProps extends IModalShow {
    productLocation: IWarehouseProductLocation;
    onFinish: () => void;
}

export default function WarehouseLocationMovementModal(props: IProps) {
    const alert = useAlert();
    const [state, setState] = useState<IWarehouseProductLocationAdjustmentInput>({
        quantity: 0,
        locationCode: props.productLocation.warehouseLocation.code,
        productId: props.productLocation.warehouseProduct.productId,
        warehouseId: props.productLocation.warehouseLocation.warehouseId,
        productVariantId: props.productLocation.warehouseProduct.productVariantId,
    });
    const [isFetching, setIsFetching] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);

    const onHandleChange = () => {
        setIsFetching(true);
        wahioFetch.post(
            productEndpoint.post.warehouseProductLocationAdjustment,
            state,
            (success) => {
                alert.success("El ajuste fue creado con éxito.");
                setIsFetching(false);
                props.onFinish();
                props.setShow(true);
            },
            (error) => {
                if (error.data && error.data.message) {
                    alert.error(error.data.message);
                } else {
                    alert.error("Hubo un error al intentar crear el ajuste.");
                }
                setIsFetching(false);
            }
        );
    };

    const confirmChanges = () => {
        if (state.quantity <= 0) {
            setShowModalConfirm(true);
        } else {
            onHandleChange();
        }
    };

    return (
        <>
            {showModalConfirm && (
                <ConfirmationModal
                    show={showModalConfirm}
                    onCancel={() => setShowModalConfirm(false)}
                    onOkay={onHandleChange}
                    title="¿Estas seguro?"
                    description={`Crear ajuste en ${state.quantity} unidades`}
                    setShow={setShowModalConfirm}
                />
            )}
            <Modal id="moda-customer-modal" scrollInside={false} title={"Ajuste de stock"} ignoreOutsideClick showHeader={true} {...props}>
                <NewProductLocationContainer>
                    <WarehouseProductLocationCard hideDropdown={true} item={props.productLocation} setSelectedProductLocation={() => {}} />
                    <div>
                        <FormGroup>
                            <label htmlFor="stock">Nuevo Stock</label>
                            <TextBoxNumeral
                                format="number"
                                onNumberChange={(value) => setState({ ...state, quantity: value })}
                                value={state.quantity}
                                autoFocus
                            />
                        </FormGroup>
                    </div>

                    <FormFooter>
                        {isFetching ? (
                            <LoadingDualRing />
                        ) : (
                            <>
                                <DefaultButton onClick={() => props.setShow(false)} className="mr-1" rounded>
                                    Cancelar
                                </DefaultButton>
                                <PrimaryButton onClick={confirmChanges} rounded>
                                    Guardar Cambios
                                </PrimaryButton>
                            </>
                        )}
                    </FormFooter>
                </NewProductLocationContainer>
            </Modal>
        </>
    );
}
