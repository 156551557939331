import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import ReactToPrint from "react-to-print";
import { getServiceMessageError } from "../../../api";
import { OrderInvoiceNumerationImpl } from "../../../api/models/orderInvoiceNumeration";
import { IOrganizationTemplate } from "../../../api/models/organizationTypes";
import { getOrder } from "../../../api/orders/orderApi";
import { useOrderInvoiceNumerationContext } from "../../../store/contexts/OrderInvoiceNumerationContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { useAlert } from "../../Alerts/Alert";
import InvoiceFormat, { OrderInvoiceParams } from "../../InvoiceFormat";
import InvoicePrintPreview from "../../InvoiceFormat/InvoicePrintPreview";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, SquareButton } from "../../_controls";
interface IInvoicePrintProps extends OrderInvoiceParams {
    small?: boolean;
    autoOpen?: boolean;
    btnTitle?: string;
    btnIcon?: string;
    showDropdownClass?: boolean;
    completeOrder?: boolean;
    btnClass?: string;
}

export default function InvoicePrint(props: IInvoicePrintProps) {
    const { organizationState } = useContext(OrganizationContext);
    const [order, setOrder] = useState(props.order);
    const alert = useAlert();

    const { orderInvoiceNumerationState, orderInvoiceNumerationActions } = useOrderInvoiceNumerationContext();
    const componentRef = useRef<any>(<div />);
    const buttonTriggerRef = useRef<any>();
    const openRef = useRef(false);
    const [selectedNumeration, setSelectedNumeration] = useState<OrderInvoiceNumerationImpl>();
    const [showPreview, setShowPreview] = useState(false);

    const [itemsLoaded, setItemsLoaded] = useState(false);

    const orderMutation = useMutation((orderId: string) => getOrder(orderId), {
        onSuccess: (order) => {
            order.isDelivery = !!order.deliveryOrder;
            setOrder(order);
            setItemsLoaded(true);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        setOrder(props.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    useEffect(() => {
        if (selectedNumeration && props.autoOpen && !openRef.current) {
            openRef.current = true;
            buttonTriggerRef.current?.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNumeration]);

    useEffect(() => {
        if (orderInvoiceNumerationState.items.length === 0) orderInvoiceNumerationActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (itemsLoaded) {
            buttonTriggerRef.current?.click();
        }
    }, [itemsLoaded]);

    useEffect(() => {
        if (order.orderInvoiceNumerationId) {
            setSelectedNumeration(orderInvoiceNumerationState.items.find((x) => x.id === order.orderInvoiceNumerationId));
        }
    }, [order, orderInvoiceNumerationState.items]);

    let currentOrganization = organizationState.currentOrganization;

    if (!currentOrganization) return null;

    const getTemplate = () => {
        let template: IOrganizationTemplate = {
            invoiceTemplateTitle1: currentOrganization?.invoiceTemplateTitle1 ?? "",
            invoiceTemplateTitle2: currentOrganization?.invoiceTemplateTitle2 ?? "",
            invoiceTemplateTitle3: currentOrganization?.invoiceTemplateTitle3 ?? "",
            invoiceTemplateTitle4: currentOrganization?.invoiceTemplateTitle4 ?? "",
        };
        return template;
    };

    const Button = props.btnTitle ? DefaultButton : SquareButton;

    const dropDownClass = props.showDropdownClass ? "dropdown-button" : "";

    return (
        <>
            <ReactToPrint
                onBeforePrint={() => setShowPreview(true)}
                onAfterPrint={() => setShowPreview(false)}
                pageStyle={`@page {
                    size: auto;
                    margin: 5mm;
                    background: white;
                }
                @media print {
                    html, body {
                        background: white;
                        height: initial !important;
                        overflow: initial !important;
                        -webkit-print-color-adjust: exact;                     
                    }
                    
                }
                h4.class-name
                    @page{
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    }
      `}
                trigger={() => {
                    if (!order.items || order.items.length === 0) return <span style={{ display: "none" }}></span>;
                    return (
                        <Button rounded ref={buttonTriggerRef} small={props.small} className={`${dropDownClass} ${props.btnClass ?? ""}`}>
                            <span className={props.btnIcon ? props.btnIcon : "fa-regular fa-print"}></span>
                            {props.btnTitle ?? ""}
                        </Button>
                    );
                }}
                content={() => componentRef.current}
            />
            {(!order.items || order.items.length === 0) && (
                <Button
                    onClick={() => orderMutation.mutate(order.id ?? "INVALID")}
                    small={props.small}
                    className={props.showDropdownClass ? "dropdown-button" : ""}
                >
                    {orderMutation.isLoading ? (
                        <LoadingDualRing small />
                    ) : (
                        <>
                            <span className={props.btnIcon ? props.btnIcon : "fa-regular fa-print"}></span>
                            {props.btnTitle ?? ""}
                        </>
                    )}
                </Button>
            )}
            {showPreview && <InvoicePrintPreview onClick={() => setShowPreview(false)} />}
            <div style={{ display: "none", background: "white" }}>
                <InvoiceFormat
                    image={currentOrganization?.image}
                    ref={componentRef}
                    order={order ?? undefined}
                    orderTable={props.orderTable}
                    template={getTemplate()}
                    numeration={selectedNumeration}
                    completeOrder={props.completeOrder}
                />
            </div>
        </>
    );
}
