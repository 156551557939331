import React, { useContext, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { ContactLabelImpl, ContactLabel } from "../../../api/models/contact";
import { createUpdateContactLabel } from "../../../api/orders/contactApi";
import { getAuthBody } from "../../../auth";
import { ContactContext } from "../../../store/contexts/ContactContext";
import ColorListOption from "../../Colors/ColorListOption";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, DefaultInput, Flex, PrimaryButton } from "../../_controls";
import { FormGroup } from "../../_controls/forms/styled";
import messages from "./messages";

export interface ContactLabelFormProps {
    onCreate: (value: ContactLabel) => void;
    onCancel: () => void;
    contactLabel?: ContactLabel;
}

export default function ContactLabelForm(props: ContactLabelFormProps) {
    const intl = useIntl();
    const alert = useAlert();
    const { contactActions } = useContext(ContactContext);
    const [state, setState] = useState<ContactLabel>(props.contactLabel ? props.contactLabel : new ContactLabelImpl(getAuthBody().accountId));

    const labelMutation = useMutation((data: ContactLabel) => createUpdateContactLabel(data), {
        onSuccess: (data) => {
            props.onCreate(data);
            alert.success(state.id ? "Etiqueta Actualizada" : "Etiqueta Creada");
            if (state.id) {
                contactActions.updateLabel(data);
            } else {
                contactActions.addLabel(data);
            }
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const onChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const onHandleSave = (e: any) => {
        e.preventDefault();
        labelMutation.mutate(state);
    };

    return (
        <form onSubmit={onHandleSave}>
            <Flex column gap={20} padding={25}>
                <h4 className="text-bold m-0">
                    {state.id ? intl.formatMessage(messages.updateLabel) : intl.formatMessage(messages.createLabel)}
                </h4>

                <FormGroup>
                    <label htmlFor="name">{intl.formatMessage(messages.name)}</label>
                    <DefaultInput
                        required
                        rounded
                        minLength={3}
                        onChange={onChange}
                        name="name"
                        value={state.name}
                        placeholder={intl.formatMessage(messages.labelName)}
                    />
                </FormGroup>

                <ColorListOption color={state.color} setColor={(color) => setState({ ...state, color })} />

                <Flex justifyEnd gap10 className="mt-1">
                    <DefaultButton disabled={labelMutation.isLoading} rounded onClick={props.onCancel} type="button">
                        {intl.formatMessage(messages.cancel)}
                    </DefaultButton>
                    <PrimaryButton disabled={labelMutation.isLoading} rounded type="submit">
                        {state.id ? intl.formatMessage(messages.updateLabel) : intl.formatMessage(messages.createLabel)}
                        {labelMutation.isLoading && <LoadingDualRing small />}
                    </PrimaryButton>
                </Flex>
            </Flex>
        </form>
    );
}
