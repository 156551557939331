import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
} from "date-fns";

import customMoment from "../../utils/momentFormat/dateFormat";

export const dateDefaultOptions = {
    startOfWeek: startOfWeek(customMoment().toDate(), { weekStartsOn: 1 }),
    endOfWeek: endOfWeek(customMoment().toDate(), { weekStartsOn: 1 }),
    startOfLastWeek: startOfWeek(addDays(customMoment().toDate(), -7), { weekStartsOn: 1 }),
    endOfLastWeek: endOfWeek(addDays(customMoment().toDate(), -7), { weekStartsOn: 1 }),
    startOfToday: startOfDay(customMoment().toDate()),
    endOfToday: endOfDay(customMoment().toDate()),
    startOfYesterday: startOfDay(addDays(customMoment().toDate(), -1)),
    endOfYesterday: endOfDay(addDays(customMoment().toDate(), -1)),
    startOfMonth: startOfMonth(customMoment().toDate()),
    endOfMonth: endOfMonth(customMoment().toDate()),
    startOfLastMonth: startOfMonth(addMonths(customMoment().toDate(), -1)),
    endOfLastMonth: endOfMonth(addMonths(customMoment().toDate(), -1)),
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
    },
};

export function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const dateDefaultOptionPairs = {
    today: {
        startDate: dateDefaultOptions.startOfToday,
        endDate: dateDefaultOptions.endOfToday,
    },
    yesterday: {
        startDate: dateDefaultOptions.startOfYesterday,
        endDate: dateDefaultOptions.endOfYesterday,
    },
    last7Days: {
        startDate: customMoment().add(-7, "days").toDate(),
        endDate: customMoment().toDate(),
    },
    thisWeek: {
        startDate: dateDefaultOptions.startOfWeek,
        endDate: dateDefaultOptions.endOfWeek,
    },
    lastWeek: {
        startDate: dateDefaultOptions.startOfLastWeek,
        endDate: dateDefaultOptions.endOfLastWeek,
    },

    last30Days: {
        startDate: customMoment().add(-30, "days").toDate(),
        endDate: customMoment().toDate(),
    },
    thisMonth: {
        startDate: dateDefaultOptions.startOfMonth,
        endDate: dateDefaultOptions.endOfMonth,
    },
    lastMonth: {
        startDate: dateDefaultOptions.startOfLastMonth,
        endDate: dateDefaultOptions.endOfLastMonth,
    },
};

export const dateDefaultOptionPairsTitle = (range) => {
    var key = Object.keys(dateDefaultOptionPairs).find(
        (key) =>
            customMoment(dateDefaultOptionPairs[key].startDate).format("DD/MM/YYYY") ===
                customMoment(range.startDate).format("DD/MM/YYYY") &&
            customMoment(dateDefaultOptionPairs[key].endDate).format("DD/MM/YYYY") === customMoment(range.endDate).format("DD/MM/YYYY")
    );
    return key ? key : "custom";
};

export const defaultStaticRanges = createStaticRanges([
    {
        label: "Hoy",
        range: () => dateDefaultOptionPairs.today,
    },
    {
        label: "Ayer",
        range: () => dateDefaultOptionPairs.yesterday,
    },

    {
        label: "Esta semana",
        range: () => dateDefaultOptionPairs.thisWeek,
    },
    {
        label: "La semana pasada",
        range: () => dateDefaultOptionPairs.lastWeek,
    },
    {
        label: "Este mes",
        range: () => dateDefaultOptionPairs.thisMonth,
    },
    {
        label: "El mes pasado",
        range: () => dateDefaultOptionPairs.lastMonth,
    },
]);

export const defaultInputRanges = [
    {
        label: "Días hasta hoy",
        range(value) {
            return {
                startDate: addDays(dateDefaultOptions.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                endDate: dateDefaultOptions.endOfToday,
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.endDate, dateDefaultOptions.endOfToday)) return "-";
            if (!range.startDate) return "∞";
            return differenceInCalendarDays(dateDefaultOptions.endOfToday, range.startDate) + 1;
        },
    },
    {
        label: "Días a partir de hoy",
        range(value) {
            const today = customMoment().toDate();
            return {
                startDate: today,
                endDate: addDays(today, Math.max(Number(value), 1) - 1),
            };
        },
        getCurrentValue(range) {
            if (!isSameDay(range.startDate, dateDefaultOptions.startOfToday)) return "-";
            if (!range.endDate) return "∞";
            return differenceInCalendarDays(range.endDate, dateDefaultOptions.startOfToday) + 1;
        },
    },
];
