import styled from "styled-components";
import { device } from "../../theme/themeProvider";

export const MainPanelContainerStyle = styled.div`
    display: flex;
    height: calc(100vh - ${(props: { topPosition: number }) => props.topPosition - 16}px);
    position: relative;
`;

export const PanelCardViewEmpty = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-icon {
        font-size: 2rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    .empty-title {
        margin: 0px;
        margin-top: 20px;
    }
    .margin-0 {
        margin: 0px;
    }
`;

export const PanelCardGridItemsView = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    grid-auto-rows: min-content;
    width: 100%;
    min-width: auto;
    padding: 15px;
    box-sizing: border-box;
    overflow: auto;
    border-right: 0px solid ${(props) => props.theme.palette.backgroundDark};
    z-index: 0;
    padding-top: 5px;

    .panel-sticky {
        position: sticky;
        top: -5px;
        z-index: 2;
        background: ${(props) => props.theme.palette.background};
        padding-bottom: 5px;
    }

    @media ${device.lg2} {
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        width: 400px;
        min-width: 400px;

        &.width-auto {
            width: 100%;
            min-width: auto;
            border: 0px;
        }
    }
`;

export const PanelCardColumnItemsView = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    border-right: 0px solid ${(props) => props.theme.palette.backgroundDark};
    z-index: 0;

    @media ${device.lg2} {
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        width: 400px;
        min-width: 400px;

        &.width-auto {
            width: 100%;
            min-width: auto;
            border: 0px;
        }
    }
`;

export const HeaderBackStyle = styled.div`
    margin-right: 10px;
    cursor: pointer;
    border-radius: 30px;
    align-items: center;
    display: flex;
    padding: 2px 7px;
    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const BodyContainerHeaderStyle = styled.div`
    display: flex;
    align-items: center;
`;

export const BodyContainerStyle = styled.div`
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 920px;
    margin: auto;

    &.large {
        max-width: 1200px;
    }
`;

interface PanelPreviewContainerStyleProps {
    forceFullScreen?: boolean;
}

export const PanelPreviewContainerStyle = styled.div`
    width: 100%;
    overflow: auto;
    background: ${(props) => props.theme.palette.background};
    position: fixed;
    top: 55px;
    bottom: 0;
    z-index: 3;
    display: none;
    padding-top: 15px;

    .summary-group {
        padding: 10px 20px;
    }

    &.panel-active {
        display: block;
    }

    @media ${device.md} {
        z-index: 1;
        padding-top: 0px;
        top: 0px;
        position: absolute;
    }

    @media ${device.lg2} {
        z-index: 1;
        padding-top: 0px;
        top: 0px;
        position: relative;
        display: block;
    }

    @media (max-width: 1500px) {
        ${(props: PanelPreviewContainerStyleProps) =>
            props.forceFullScreen &&
            `
         z-index: 1;      
         padding-top: 0px;
         top: 0px;
         position: absolute;
    `}
    }

    @media ${device.xl} {
        .body-container {
            width: 90%;
        }
    }
`;
