import React from "react";
import AppHorizontalMenu from "../../../pages/AppContainer/AppHorizontalMenu";
import { Flex } from "../../_controls";
import AccountBillingPlanForm from "./AccountBillingPlanForm";

interface AccountBillingConfigProps {
    isNewContract?: boolean;
}

export default function AccountBillingConfig(props: AccountBillingConfigProps) {
    const title = props.isNewContract ? "Tu ultima factura ha vencido" : "Tu prueba gratuita a finalizado";

    return (
        <>
            <AppHorizontalMenu hideActions />
            <Flex padding={20} paddingTop={70} column>
                <Flex column alignCenter gap5 className="mt-2">
                    <p className="m-0 text-light">{title}</p>
                    <h1 className="m-0 text-bold">Elige un plan para continuar</h1>
                </Flex>
                <AccountBillingPlanForm />
            </Flex>
        </>
    );
}
