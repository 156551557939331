import React from "react";
import { useIntl } from "react-intl";
import { ProductCreationProps } from ".";
import { ProductType } from "../../../api/models/product";
import { DefaultButton, Flex, TextField } from "../../_controls";
import PopoverRadix from "../../_controls/PopoverRadix";
import GroupButtonRow from "../../_controls/buttons/GroupButtonRow";
import { ProductDetailCheck } from "./DetailsForm";
import { getProductTypeTitle } from "./helpers";
import messages from "./messages";

export default function ProductTypeSelect(props: ProductCreationProps) {
    const { product, onChange } = props;
    const intl = useIntl();

    return (
        <Flex column gap5>
            <TextField small light>
                Tipo de producto
            </TextField>
            <PopoverRadix
                align="start"
                trigger={
                    <DefaultButton justifyStart bgLight borderRadius={10}>
                        <span className={product.type === "service" ? "fa-regular fa-sparkles" : "fa-regular fa-box"}></span>
                        {getProductTypeTitle(product)}
                        <span className="fa-regular fa-chevron-down" style={{ marginLeft: "auto" }} />
                    </DefaultButton>
                }
            >
                <Flex column gap10>
                    <TextField small light>
                        Tipo de producto
                    </TextField>
                    <GroupButtonRow
                        activeOption={product.type ?? "product"}
                        onClickOption={(option) => onChange({ ...product, type: option.value as ProductType })}
                        options={[
                            { title: "Producto", value: "product", icon: "fa-regular fa-box" },
                            { title: "Servicio", value: "service", icon: "fa-regular fa-sparkles" },
                        ]}
                    />

                    <Flex gap15 flexWrap marginTop={10}>
                        <ProductDetailCheck
                            checked={product.isParent}
                            disabled={product.includesSerials || product.includesVariants}
                            onClick={() =>
                                onChange({
                                    ...product,
                                    isParent: !product.isParent,
                                    controlInventory: false,
                                    includesVariants: false,
                                    includesSerials: false,
                                })
                            }
                            title={intl.formatMessage(messages.compound)}
                        />
                        <ProductDetailCheck
                            checked={product.includesOptionals}
                            disabled={product.includesSerials || product.includesVariants}
                            onClick={() =>
                                onChange({
                                    ...product,
                                    includesOptionals: !product.includesOptionals,
                                    includesVariants: false,
                                    includesSerials: false,
                                })
                            }
                            title={intl.formatMessage(messages.optionals)}
                        />
                        <ProductDetailCheck
                            checked={product.includesVariants}
                            disabled={product.includesSerials || product.isParent || product.includesOptionals}
                            onClick={() =>
                                onChange({
                                    ...product,
                                    includesVariants: !product.includesVariants,
                                    includesSerials: false,
                                    isParent: false,
                                })
                            }
                            title={intl.formatMessage(messages.variants)}
                        />
                        <ProductDetailCheck
                            disabled={product.includesVariants || product.isParent || product.includesOptionals}
                            checked={product.includesSerials}
                            onClick={() =>
                                onChange({
                                    ...product,
                                    controlInventory: true,
                                    includesSerials: !product.includesSerials,
                                    includesVariants: false,
                                    isParent: false,
                                })
                            }
                            title={intl.formatMessage(messages.serials)}
                        />
                    </Flex>
                </Flex>
            </PopoverRadix>
        </Flex>
    );
}
