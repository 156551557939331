import { CloudImages } from "../../assets"
import { AUDIO_EXTENSIONS, COMPRESSION_EXTENSIONS, PRESENTATION_EXTENSIONS, VIDEO_EXTENSIONS } from "./helper";

export const getCloudItemExtensionImage = (extension: string) => {
    if (["pdf"].includes(extension)) return CloudImages.pdf;
    else if (["ai"].includes(extension)) return CloudImages.imageIllustrator;
    else if (["xd"].includes(extension)) return CloudImages.imageXd;
    else if (["psd"].includes(extension)) return CloudImages.imagePsd;
    else if (["svg", "eps"].includes(extension)) return CloudImages.imageSvg;
    else if (["txt", "ttf"].includes(extension)) return CloudImages.imageText;
    else if (["doc", "docx"].includes(extension)) return CloudImages.imageWord;
    else if (COMPRESSION_EXTENSIONS.includes(extension)) return CloudImages.imageZip;
    else if (VIDEO_EXTENSIONS.includes(extension)) return CloudImages.imageVideo;
    else if (AUDIO_EXTENSIONS.includes(extension)) return CloudImages.imageMp3;
    else if (PRESENTATION_EXTENSIONS.includes(extension)) return CloudImages.imagePowerPoint;
    else if (["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xlr", "csv"].includes(extension)) return CloudImages.excel;

    return CloudImages.imageFile;
};
