import React, { useContext, useEffect, useRef, useState } from "react";
import { ListProductProps } from ".";
import { useEventListenerWindow } from "../..";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import ProductCard, { ProductCardFake, ProductRowFake } from "../ProductCard";
import ProductRow from "../ProductRow";
import { ProductRowContainer, ProductSquareContainer } from "./styled";

export const getArrayFakeCards = (card: boolean) => {
    return Array.from({ length: 40 }, (_, i) => (card ? <ProductCardFake key={i} /> : <ProductRowFake key={i} />));
};

export const ProductList = (props: ListProductProps) => {
    const { appSettingsState } = useContext(AppSettingsContext);

    if (props.showFakeItems) {
        if (appSettingsState.productListShape === "square") {
            return <ProductSquareContainer id={PRODUCT_CARDS_CONTAINER_ID}>{getArrayFakeCards(true)}</ProductSquareContainer>;
        } else {
            return <ProductRowContainer id={PRODUCT_CARDS_CONTAINER_ID}>{getArrayFakeCards(false)}</ProductRowContainer>;
        }
    }
    return appSettingsState.productListShape === "square" ? <ProductCardList {...props} /> : <ProductRowList {...props} />;
};

export const PRODUCT_CARDS_CONTAINER_ID = "PRODUCT_CARDS_CONTAINER_ID";

export function ProductCardList(props: ListProductProps) {
    const [panelSize, setPanelSize] = useState("");
    const panelRef = useRef<any>();
    const timerRef = useRef<any>();

    useEffect(() => {
        handleCalculateMobile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isExpandible]);

    const handleCalculateMobile = () => {
        if (panelRef.current) {
            let rect = panelRef.current.getBoundingClientRect();
            if (rect.width > 600) {
                setPanelSize("large");
            } else if (rect.width > 500) {
                setPanelSize("medium");
            } else {
                setPanelSize("small");
            }
        }
    };

    const handleUpdateMobile = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => handleCalculateMobile(), 100);
    };

    useEventListenerWindow("resize", () => {
        handleUpdateMobile();
    });

    let numberIndex = -1;
    if (props.searchType === "product") {
        return (
            <ProductSquareContainer ref={panelRef} id={PRODUCT_CARDS_CONTAINER_ID}>
                {props.productPagination &&
                    props.productPagination.items.map((item) => {
                        numberIndex += 1;
                        let activeObj = props.activeProducts ? props.activeProducts[item.id] : undefined;
                        let activeQuantity = activeObj?.quantity ?? 0;
                        const disabled = props.disabledActiveProducts && activeQuantity > 0 ? true : false;
                        return (
                            <ProductCard
                                activeUnits={activeQuantity}
                                disabled={disabled}
                                {...props}
                                product={item}
                                key={item.id}
                                index={numberIndex}
                            />
                        );
                    })}
            </ProductSquareContainer>
        );
    }

    return (
        <ProductSquareContainer ref={panelRef} className={panelSize} id={PRODUCT_CARDS_CONTAINER_ID}>
            {props.warehouseProductPagination &&
                props.warehouseProductPagination.items.map((item) => {
                    let stock = item.stock - item.reserved;
                    if (!props.warehouse?.allowNegativeStock) stock = stock < 0 ? 0 : stock;
                    numberIndex += 1;

                    let activeObj = props.activeProducts ? props.activeProducts[item.productId] : undefined;

                    return (
                        <ProductCard
                            activeUnits={activeObj?.quantity}
                            {...props}
                            product={item.product}
                            warehouseProduct={item}
                            stock={stock}
                            index={numberIndex}
                            key={item.id}
                        />
                    );
                })}
        </ProductSquareContainer>
    );
}

export function ProductRowList(props: ListProductProps) {
    let numberIndex = -1;
    if (props.searchType === "product") {
        return (
            <ProductRowContainer id={PRODUCT_CARDS_CONTAINER_ID}>
                {props.productPagination &&
                    props.productPagination.items.map((item) => {
                        numberIndex++;

                        let activeObj = props.activeProducts ? props.activeProducts[item.id] : undefined;
                        return <ProductRow activeUnits={activeObj?.quantity} {...props} product={item} key={item.id} index={numberIndex} />;
                    })}
            </ProductRowContainer>
        );
    }

    return (
        <ProductRowContainer id={PRODUCT_CARDS_CONTAINER_ID}>
            {props.warehouseProductPagination &&
                props.warehouseProductPagination.items.map((item) => {
                    let stock = item.stock - item.reserved;
                    if (!props.warehouse?.allowNegativeStock) {
                        stock = stock < 0 ? 0 : stock;
                    }
                    numberIndex++;

                    let activeObj = props.activeProducts ? props.activeProducts[item.productId] : undefined;

                    return (
                        <ProductRow
                            activeUnits={activeObj?.quantity}
                            {...props}
                            product={item.product}
                            warehouseProduct={item}
                            stock={stock}
                            key={item.id}
                            index={numberIndex}
                        />
                    );
                })}
        </ProductRowContainer>
    );
}
