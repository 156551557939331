import React from "react";
import { WahioCloudItem } from "../../api/models/wahioCloud";
import Modal, { IModalShow } from "../modals/Modal";
import WahioCloudItemList, { WahioCloudItemListProps } from "./WahioCloudItemList";

interface WahioCloudItemsModalProps extends IModalShow, WahioCloudItemListProps {}

export default function WahioCloudItemsModal(props: WahioCloudItemsModalProps) {
    const { defaultType } = props;

    const onClickItems = (items: WahioCloudItem[]) => {
        if (props.onClickItems) {
            props.setShow(false);
            props.onClickItems(items);
        }
    };
    return (
        <Modal
            sizeType="md3"
            scrollInside={true}
            useButtonClose
            ignoreOutsideClick
            useMobileView
            styleBody={{ overflow: "hidden" }}
            {...props}
        >
            <WahioCloudItemList
                {...props}
                defaultType={defaultType}
                usingModal={true}
                onClickItems={onClickItems}
                onCloseModal={() => props.setShow(false)}
                allowSelection={true}
            />
        </Modal>
    );
}
