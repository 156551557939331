import styled from "styled-components";
import { device } from "../../../theme/themeProvider";
import { Flex } from "../../_controls";

export const OrderProductCardView = styled.div`
    display: flex;
    border-radius: 15px;
    padding: 5px;
    border: 1px solid transparent;
    position: relative;
    gap: 10px;
    align-items: flex-start;

    &.hover {
        cursor: pointer;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    ${(props: { addBackgroundHidde?: boolean }) =>
        props.addBackgroundHidde
            ? `
    opacity: 0.4;
    &.hover {
        cursor: pointer;
        &:hover {
            background: transparent;
        }
    }
    &:after { 
        content: " ";
        text-align: center;
        padding-top: 10px;
        font-size: .8rem;
        z-index: 6;
        height: 100%;
        width: 100%;
        cursor: default;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    `
            : ""}

    .flex-quantity {
        display: flex;
        input {
            border-radius: 40px;
            text-align: center;
            padding: 0px;
        }
    }
    .margin-left {
        margin-left: auto;
    }

    &.added {
        background: ${(props) => props.theme.palette.primary}20;
        border: 1px solid ${(props) => props.theme.palette.primary}30;
        &:hover {
            background: ${(props) => props.theme.palette.primary}30;
        }
    }

    .quantity-view {
        font-weight: 500;
    }
`;

export const GridTabLine = styled(Flex)`
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    padding: 0px 15px;
    height: 38px;
    font-weight: 500;
    align-items: center;
`;
export const OrderReturnSearchOrderStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    .input-group {
        margin-top: 10px;
        display: flex;
        input {
            width: 100%;
        }
    }
`;

export const OrderReturnFormStyle = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OrderReturnFormContainer = styled.div`
    padding: 20px;
`;

export const SummaryCard = styled.div`
    display: flex;
    padding: 0px 20px;
    .summary-title {
        display: block;
        color: ${(props) => props.theme.palette.textLight};
    }
    .card {
        width: 100%;
    }
    .secondary-card {
        margin-top: 15px;
    }

    flex-direction: column;

    @media ${device.md} {
        flex-direction: row;
        .secondary-card {
            margin-top: 0px;
        }
    }
    .icon {
        color: blue;
        background: black;
        font-size: 10px;
        width: 10px;
        height: 10px;
        padding: 2px;
        border-radius: 10px;
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};

        &.danger {
            background: ${(props) => props.theme.palette.dangerColor}20;
            color: ${(props) => props.theme.palette.dangerColor};
        }
    }

    tr td:first-child {
        padding-right: 15px;
    }
`;

export const OrderReturnProfileStyle = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NextActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    /* background: aqua; */
    padding: 13px;

    button {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const OrderReturnProductStyle = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    border-radius: 15px;

    .flex-fixed-height {
        max-height: 325px;
        overflow: auto;
    }

    .btn-active {
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    .label {
        font-weight: 500;
        font-size: 17px;
    }
    .labelpair {
        margin-right: 15px;
        margin-bottom: 10px;
    }
    &.padding-large {
        padding: 30px;
    }
    &.padding-b-0 {
        padding-bottom: 0px;
    }

    .return-items-container {
        .sale-item-card:last-child {
            border-bottom: 0px solid ${(props) => props.theme.palette.backgroundDark};
        }
    }
    &.border-right {
        border-right: 2px solid ${(props) => props.theme.palette.backgroundDark};
    }

    .flex-wrap {
        display: flex;
    }
    .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .input {
        margin-bottom: 15px;
    }
`;

export const OrderReturnDetailsStyle = styled.div`
    display: flex;
    flex-direction: column;
`;
