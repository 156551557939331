import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

import { Order } from "../../../api/models/order";
import { SquareButton } from "../../_controls";

import OrderLabel from "./OrderLabel";

interface OrderLabelPrintProps {
    order: Order;
    square?: boolean;
    w100?: boolean;
}

export default function OrderLabelPrint(props: OrderLabelPrintProps) {
    const componentRef = useRef<any>(<div />);

    return (
        <>
            <ReactToPrint
                pageStyle={`@page {
                    size: auto;
                    margin: 5mm;
                    background: white;
                }
                @media print {
                    html, body {
                        background: white;
                        height: initial !important;
                        overflow: initial !important;
                        -webkit-print-color-adjust: exact;

                        section {
                            break-inside: auto;
                          }
                    }
                    
                }
                h4.class-name
                    @page{
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    }
      `}
                trigger={() => (
                    <SquareButton w100={props.w100} title="Imprimir Etiqueta del pedido" bgLight borderRadius={10}>
                        <span className="fa-regular fa-print"></span>
                    </SquareButton>
                )}
                content={() => componentRef.current}
            />
            <div style={{ display: "none", background: "white" }}>
                <OrderLabel ref={componentRef} order={props.order} />
            </div>
        </>
    );
}
