import styled from "styled-components";

export const FlexView = styled.div`
    display: flex;
    .checkbox {
        margin-top: 10px;
        margin-right: 20px;
    }
`;

interface IInputGroup {
    bottomSpace?: boolean;
}

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: ${(props: IInputGroup) => (props.bottomSpace ? "10px" : "0")};
    &.bottom-space {
        margin: 10px;
    }

    input {
        width: 100%;
    }
    label {
        margin-bottom: 5px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const ElementSpace = styled.div`
    margin-bottom: 10px;
`;

export const FieldsFlexContainer = styled.div`
    display: flex;
    .btnactive {
        color: ${(props) => props.theme.palette.primary};
    }
`;
