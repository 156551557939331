import React, { useContext } from "react";
import { getUserPermissions } from "../../../api/models/accountUser";
import { Order } from "../../../api/models/order";
import { UserContext } from "../../../store/contexts/UserContext";
import { formatMoney, getDateFormat, getDateFromNow } from "../../../utils";
import PermissionActionDenied from "../../PermissionDenied/PermissionActionDenied";
import { Flex, PrimaryButton, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import { ConfirmQueuedContainer } from "./styled";

interface IConfirmQueuedOrderProps {
    order: Order;
    onConfirm: () => void;
    rejectButton: React.ReactNode;
}

export default function ConfirmQueuedOrder(props: IConfirmQueuedOrderProps) {
    const { order } = props;

    const { userState } = useContext(UserContext);

    const deliveryOrder = order.deliveryOrder;

    if (!getUserPermissions(userState.user).order?.canConfirm) {
        return <PermissionActionDenied message="No tienes permiso para confirmar o rechazar pedidos." />;
    }

    return (
        <ConfirmQueuedContainer>
            <div className="panel-body">
                <h3 className="m-0">¿Quieres confirmar este pedido?</h3>
                {deliveryOrder && (
                    <Table className="table-details" tdPadding={7} tdPaddingRight={15}>
                        <tbody>
                            <tr>
                                <td className="min-width">Contacto</td>
                                <td>{deliveryOrder.contactName}</td>
                            </tr>
                            <tr>
                                <td>Dirección</td>
                                <td>{deliveryOrder.address}</td>
                            </tr>
                            <tr>
                                <td>Ciudad</td>
                                <td>
                                    {deliveryOrder.city} - {deliveryOrder.state}
                                </td>
                            </tr>
                            <tr>
                                <td>Pago</td>
                                <td>
                                    {deliveryOrder.cashOnDeliveryOrder ? (
                                        <Flex alignCenter gap5>
                                            <i className="fa-regular fa-truck delivery" />
                                            <TextField>Contra entrega</TextField>
                                        </Flex>
                                    ) : (
                                        <TextField>{order.totalPaid > 0 ? formatMoney(order.totalPaid) : "Pendiente"}</TextField>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Fecha</td>
                                <td>
                                    <Flex column>
                                        <TextField>{getDateFormat(order.dateCreated, "DD MMM YYYY hh:mm a")}</TextField>
                                        <TextField small light>
                                            {getDateFromNow(order.dateCreated)}
                                        </TextField>
                                    </Flex>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )}

                <Flex alignCenter gap10>
                    {props.rejectButton}
                    <PrimaryButton rounded onClick={props.onConfirm}>
                        Confirmar
                    </PrimaryButton>
                </Flex>
            </div>
        </ConfirmQueuedContainer>
    );
}
