import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    button {
        justify-content: flex-start;
        border: 0px;
    }
`;
