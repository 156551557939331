import React from "react";
import { useIntl } from "react-intl";
import { ITaxOrderItemBase } from "../../../../api/models/orderBase";
import { ITax } from "../../../../api/models/tax";
import { formatMoney } from "../../../../utils";
import Dropdown from "../../../Dropdown";
import { Flex, TextField } from "../../../_controls";
import { DefaultButton, PrimaryButton } from '../../../_controls/buttons/index';
import messages from "./messages";

export interface TaxOrderItemProps {
    orderTaxes: ITaxOrderItemBase[];
    basePrice: number;
    taxesAvailable: ITax[];
    taxAmount: number;
    totalPrice: number;
    onClickTax: (tax: ITax) => void;
    onClickRemoveTax: (tax: ITaxOrderItemBase) => void;
    setShow: (show: boolean) => void;
}

export default function TaxOrderItem(props: TaxOrderItemProps) {
    const { taxesAvailable, orderTaxes, basePrice, onClickRemoveTax, onClickTax, taxAmount, totalPrice } = props;
    const intl = useIntl();

    const getTaxesList = () => {
        const taxesAvailableList = taxesAvailable.filter((tax) => tax.id !== undefined && !orderTaxes.some((orderTax) => orderTax.taxId === tax.id));
        return taxesAvailableList;
    }

    return (
        <Flex column padding={20} gap15>
            <Flex spaceBetween>
                <Flex column border borderRadius={8} padding={10}>
                    <TextField small light>{intl.formatMessage(messages.basePrice)}</TextField>
                    <TextField bold>{formatMoney(basePrice)}</TextField>
                </Flex>
                <Flex column border borderRadius={8} padding={10}>
                    <TextField small light>{intl.formatMessage(messages.taxTotal)}</TextField>
                    <TextField bold>{formatMoney(taxAmount)}</TextField>
                </Flex>
                <Flex column border borderRadius={8} padding={10}>
                    <TextField small light>{intl.formatMessage(messages.totalPrice)}</TextField>
                    <TextField bold>{formatMoney(totalPrice)}</TextField>
                </Flex>
            </Flex>
            <Flex>
                <Dropdown title="Seleccionar impuestos">
                    <Flex column padding={10}>
                        {getTaxesList().map((item, index) => (
                            <DefaultButton className="dropdown-button" onClick={() => onClickTax(item)} key={index}>
                                <span>{item.name}</span>
                            </DefaultButton>
                        ))}
                    </Flex>
                </Dropdown>
            </Flex>
            <Flex column gap15 padding={10}>
                {orderTaxes.length > 0 ?
                    <>
                        <TextField bold>{intl.formatMessage(messages.selectedTaxes)}</TextField>
                        {orderTaxes &&
                            orderTaxes.map((tax, index) => (
                                <Flex alignCenter border borderRadius={100} spaceBetween key={index} padding={10}>
                                    <TextField className="ml-1">{tax.taxName}</TextField>
                                    <TextField onClick={() => onClickRemoveTax(tax)} className="wahioicon-times cursor-pointer" />
                                </Flex>
                            ))
                        }
                    </>
                    : <TextField light small>{intl.formatMessage(messages.noTaxes)}</TextField>
                }
            </Flex>
            <Flex alignEnd gap5 justifyEnd>
                <PrimaryButton borderRadius={10} onClick={() => props.setShow(false)}>{intl.formatMessage(messages.save)}</PrimaryButton>
            </Flex>
        </Flex>
    );
}
