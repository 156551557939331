import styled from "styled-components";

export const IconCardStyle = styled.div`
    display: flex;
    align-items: center;

    &.margin {
        margin-top: 3px;
        margin-bottom: 7px;
    }
    color: ${(props) => props.theme.palette.text};
    .card-title {
        &.bold {
            font-weight: 600;
        }
    }
    .icon {
        width: 30px;
        min-width: 30px;
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};

        ${(props: { iconColor?: string }) =>
            props.iconColor
                ? `
        background:  ${props.iconColor}30;
        color: ${props.iconColor};
        `
                : ""};

        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 26px;
        margin-right: 10px;
        font-size: 15px;

        &.small {
            width: 25px;
            min-width: 25px;
            height: 25px;
            font-size: 13px;
        }
    }
    &.iconlight {
        color: ${(props) => props.theme.palette.textLight};
        .icon {
            background: ${(props) => props.theme.palette.backgroundDark};
            color: ${(props) => props.theme.palette.textLight};
        }
        opacity: 0.4;
    }
    .card-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .sub-title {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 13px;
        }
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }

    &.disabled {
        opacity: 0.4;
        .icon {
            background: ${(props) => props.theme.palette.backgroundDark};
            color: ${(props) => props.theme.palette.textLight};
        }
        .card-content {
            color: ${(props) => props.theme.palette.textLight};
            .sub-title {
                color: ${(props) => props.theme.palette.textLight};
            }
        }
    }
`;
