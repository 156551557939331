import { useMutation } from "react-query";
import { updateAccountUser } from "../../api/account/accountUser";
import { AccountUser, AccountUserQuickStart } from "../../api/models/accountUser";
import { useUserContext } from "../../store/contexts/UserContext";
import customMoment from "../../utils/momentFormat/dateFormat";
import { QuickStartOptionName } from "./data";

export const useQuickStartUpdate = () => {
    const { userState, setUserState } = useUserContext();
    const updateAccountUserMutate = useMutation((user: AccountUser) => updateAccountUser(user));

    const saveChanges = (value: AccountUserQuickStart) => {
        if (userState.user) {
            const newUser = { ...userState.user, quickStart: { ...value, updatedAt: customMoment().format() } };
            setUserState({ ...userState, user: newUser });
            updateAccountUserMutate.mutate(newUser);
        }
    };

    const updateOption = (optionName: QuickStartOptionName, isComplete: boolean) => {
        var quickStart = userState.user?.quickStart;
        if (userState.user && quickStart && quickStart.options) {
            quickStart.options = quickStart.options.map((item) => {
                if (item.name === optionName) {
                    item.isComplete = isComplete;
                }
                return item;
            });
            saveChanges(quickStart);
        }
    };

    const setHidePermanently = (value: boolean) => {
        if (userState.user && userState.user.quickStart) {
            saveChanges({ ...userState.user.quickStart, hidePermanently: value, show: !value });
        }
    };

    return { saveChanges, setHidePermanently, updateOption };
};
