import {
    ProductCostMultipleSearchInput,
    ProductCostResponse,
    ProductPartialUpdateBulkItem,
    ProductPartialUpdateInput,
} from "../../../api/models/product";
import { getAuthBody } from "../../../auth";
import customMoment from "../../../utils/momentFormat/dateFormat";

export const getProductMultipleSearchInput = (productIds: string[], productVariantIds: string[]) => {
    let costMultipleInput: ProductCostMultipleSearchInput = {
        productIds,
        productVariantIds,
        accountId: getAuthBody().accountId,
        dateStart: customMoment().add(-8, "months").format(),
        dateEnd: customMoment().add(1, "day").format(),
        limit: 0,
        minutesOffset: new Date().getTimezoneOffset(),
    };
    return costMultipleInput;
};

export const getProductUpdateBulkItem = (costSummary: ProductCostResponse) => {
    let body: ProductPartialUpdateInput = {
        costUpdatedAt: customMoment().format(),
    };
    if (!costSummary.preventUpdate) {
        body.costWeightedAverage = costSummary.costWeightedAverage;
        body.costBaseWeightedAverage = costSummary.costBaseWeightedAverage;
        body.costAverage = costSummary.costAverage;
        body.costBaseAverage = costSummary.costBaseAverage;
        body.costLastPurchase = costSummary.costLastPurchase;
        body.costBaseLastPurchase = costSummary.costBaseLastPurchase;
    }

    let updateValue: ProductPartialUpdateBulkItem = {
        productId: costSummary.productId,
        productVariantId: costSummary.productVariantId,
        body: body,
    };
    return updateValue;
};
