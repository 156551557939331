import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ProductCreationProps } from ".";
import { IProductChild } from "../../../api/models/product";
import { useAlert } from "../../Alerts/Alert";
import { QuantityContainer, QuantityContainerBody } from "../../Orders/OrderStyles/RowStyles";
import TextBoxNumberTimer from "../../TextBoxNumeral/TextBoxNumeralTimer";
import { DefaultButton, Flex, FlexImageStyle, SquareButton } from "../../_controls";
import { ProductAddOption } from "../ProductCard";
import { ProductActiveInput, ProductActiveObject } from "../ProductSearchList";
import ProductSearchListModal from "../ProductSearchList/ProductSearchListModal";
import { getProductSkuAndCode, getProductThumbnailUrl } from "../helper";
import messages from "./messages";

interface ProductComponentRowProps {
    productChild: IProductChild;
    onChange: (value: IProductChild) => void;
    onRemove: (value: IProductChild) => void;
    bigView?: boolean;
    smallView?: boolean;
}

const ProductComponentRow = (props: ProductComponentRowProps) => {
    const inputRef = React.useRef<any>();
    const { productChild } = props;
    const { child, productVariant } = productChild;

    const onNumberChange = (value: number) => {
        props.onChange({ ...productChild, quantity: value });
    };
    const onChangeQuantity = (value: number) => {
        let newQuantity = productChild.quantity + value;
        if (newQuantity < 0) {
            return;
        }
        props.onChange({ ...productChild, quantity: newQuantity });
    };
    return (
        <Flex alignCenter justifyCenter spaceBetween border padding={10} borderRadius={10}>
            <Flex w100 alignCenter justifyCenter gap15>
                <FlexImageStyle width={80} borderRadius={5}>
                    <img src={getProductThumbnailUrl(child, productVariant)} alt={productChild.child?.name} />
                </FlexImageStyle>
                <Flex column w100 gap5>
                    <span>{productChild.child?.name}</span>
                    <span className="light">{getProductSkuAndCode(child, productVariant)}</span>
                </Flex>
            </Flex>

            <Flex gap15 alignCenter justifyCenter>
                <QuantityContainerBody>
                    <QuantityContainer>
                        <SquareButton className="btn-quantity" onClick={() => onChangeQuantity(-1)}>
                            <i className="fa-regular fa-minus" />
                        </SquareButton>
                        <TextBoxNumberTimer
                            id="textOrderQuantity"
                            ref={inputRef}
                            milliseconds={500}
                            className="textbox-quantity textbox-order-item"
                            format="number"
                            type="text"
                            value={productChild.quantity}
                            onNumberChange={onNumberChange}
                            maxLength={9}
                        />
                        <SquareButton className="btn-quantity" onClick={() => onChangeQuantity(1)}>
                            <i className="fa-regular fa-plus" />
                        </SquareButton>
                    </QuantityContainer>
                </QuantityContainerBody>
                <SquareButton onClick={() => props.onRemove(productChild)}>
                    <span className="wahioicon-trash"></span>
                </SquareButton>
            </Flex>
        </Flex>
    );
};

export default function ComponentsForm(props: ProductCreationProps) {
    const intl = useIntl();
    const alert = useAlert();
    const [showProductList, setShowProductList] = useState(false);

    const [productActiveObject, setProductActiveObject] = useState<ProductActiveObject>({});

    useEffect(() => {
        loadProductActiveObject();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product.childs]);

    const loadProductActiveObject = () => {
        let object: ProductActiveObject = {};
        props.product.childs?.forEach((item) => {
            let useProduct: ProductActiveInput = {
                productId: item.childId,
                quantity: item.quantity,
            };
            let existing = object[item.childId];
            if (existing) {
                useProduct.quantity += existing.quantity;
            }
            object[item.childId] = useProduct;
        });
        setProductActiveObject(object);
    };

    const onClickProduct = (value: ProductAddOption) => {
        let childs = props.product.childs ?? [];

        let exists = childs.find(
            (x) => x.childId === value.product.id && (!value.productVariant || value.productVariant.id === x.productVariantId)
        );
        if (exists) {
            alert.info(intl.formatMessage(messages.theProductIsAlreadyAdded));
            return;
        }

        let child: IProductChild = {
            childId: value.product.id,
            productVariant: value.productVariant,
            productVariantId: value.productVariant?.id,
            child: value.product,
            quantity: 1,
        };

        childs = [...childs, child];

        props.onChange({ ...props.product, childs });
    };
    const onChildChange = (child: IProductChild) => {
        let childs = props.product.childs ?? [];
        let childsCopy: IProductChild[] = [];
        childs.forEach((element) => {
            if (element.childId === child.childId && (!element.productVariantId || element.productVariantId === child.productVariantId)) {
                childsCopy.push(child);
            } else {
                childsCopy.push(element);
            }
        });

        props.onChange({ ...props.product, childs: childsCopy });
    };
    const onRemoveChild = (child: IProductChild) => {
        let childs = props.product.childs?.filter((x) => {
            if (x.productVariant) {
                return x.productVariantId !== child.productVariantId;
            }
            return x.childId !== child.childId;
        });

        props.onChange({ ...props.product, childs });
    };

    return (
        <Flex column>
            {showProductList && (
                <ProductSearchListModal
                    activeProducts={productActiveObject}
                    preventCloseOnClick
                    onClickProduct={onClickProduct}
                    show={showProductList}
                    setShow={setShowProductList}
                />
            )}
            <DefaultButton rounded onClick={() => setShowProductList(true)}>
                <span className="wahioicon-plus"></span>
                {intl.formatMessage(messages.addProducts)}
            </DefaultButton>

            <Flex column marginTop={10} gap15>
                {props.product?.childs?.map((item, index) => (
                    <ProductComponentRow onRemove={onRemoveChild} key={index} onChange={onChildChange} productChild={item} />
                ))}
            </Flex>
        </Flex>
    );
}
