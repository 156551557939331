import React, { useState } from "react";
import { IAddImageBody } from "../../api/models/productImage";
import { DefaultButton, DefaultInput, PrimaryButton } from "../_controls";
import Modal from "../modals/Modal";
import { VideoContainer } from "./styled";
import { getVideoUrl } from "./utils";

export interface IClickSave {
    handleImageBody: (body: IAddImageBody) => void;
}

export default function AddVideoLinkModal(props: IClickSave) {
    const [showModal, setShowModal] = useState(false);
    const [currentLink, setcurrentLink] = useState("");

    const handleChanges = () => {
        props.handleImageBody({ url: currentLink, type: "videoLink" });
        setcurrentLink("");
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <Modal
                    id="modalvidelink"
                    scrollInside={false}
                    overflowAuto={false}
                    title={"Agregar Link de Video"}
                    showHeader={true}
                    show={showModal}
                    setShow={setShowModal}
                >
                    <VideoContainer key={currentLink}>
                        <DefaultInput
                            className="input-link"
                            onChange={(e) => setcurrentLink(e.target.value)}
                            value={currentLink}
                            placeholder="Link del video"
                        />

                        {currentLink && (
                            <>
                                <div className="iframe-container">
                                    <iframe
                                        title="VideLink"
                                        width="560"
                                        height="315"
                                        src={getVideoUrl(currentLink)}
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <div className="btn-actions">
                                    <PrimaryButton onClick={() => handleChanges()}>Agregar Enlace</PrimaryButton>
                                </div>
                            </>
                        )}
                    </VideoContainer>
                </Modal>
            )}

            <DefaultButton small w100 borderRadius={10} onClick={() => setShowModal(true)}>
                <span className="fa-regular fa-video"></span> Video
            </DefaultButton>
        </>
    );
}
