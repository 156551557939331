import { ITransactionAccountsImportItem, IImportRequestModel, ImportResponse } from "../../components/ImportExport/ExcelImport/helper";
import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { TransactionAccount, TransactionAccountConcept, TransactionAccountDelete, TransactionAccountPayment, TransactionAccountSearchModel } from "../models/transactionAccount";
import { accountEndpoint } from "../restApiEndpoint";

export const getTransactionAccount = (id: string): Promise<TransactionAccount> => {
    return wahioFetchApi.get(accountEndpoint.get.transactionAccount(id)).then((res: any) => res.data);
};

export const getTransactionAccountByRef = (id: string): Promise<TransactionAccount> => {
    return wahioFetchApi.get(accountEndpoint.get.transactionAccountByRef(id)).then((res: any) => res.data);
};
export const transactionAccountQuerySearch = (data: TransactionAccountSearchModel): Promise<IPaginationResponse<TransactionAccount>> => {
    return wahioFetchApi.post(accountEndpoint.post.transactionAccountQuerySearch, data).then((res: any) => res.data);
};

export const createUpdateTransactionAccount = (data: TransactionAccount): Promise<TransactionAccount> => {
    return wahioFetchApi.post(accountEndpoint.post.transactionAccount, data).then((res: any) => res.data);
};

export const transactionAccountCreatePayment = (data: TransactionAccountPayment): Promise<TransactionAccount> => {
    return wahioFetchApi.post(accountEndpoint.post.transactionAccountCreatePayment, data).then((res: any) => res.data);
};

export const createUpdateTransactionAccountConcept = (data: TransactionAccountConcept): Promise<TransactionAccountConcept> => {
    return wahioFetchApi.post(accountEndpoint.post.transactionAccountConcept, data).then((res: any) => res.data);
};
export const getTransactionAccountConcepts = (data: string): Promise<TransactionAccountConcept[]> => {
    return wahioFetchApi.get(accountEndpoint.get.transactionAccountConceptsByAccount(data)).then((res: any) => res.data);
};
export const importTransactionAccount = (data: IImportRequestModel<ITransactionAccountsImportItem>): Promise<ImportResponse<TransactionAccount>> => {
    return wahioFetchApi.post(accountEndpoint.post.importTransactionsAccounts, data).then((res: any) => res.data);
};
export const deleteTransactionAccount = (data: TransactionAccountDelete): Promise<TransactionAccount> => {
    return wahioFetchApi.post(accountEndpoint.post.transactionAccountDelete, data).then((res: any) => res.data);
}