import { getServiceMessageError, wahioFetch } from "..";
import { IAppSettingsState } from "../../appSettings/types";
import { getAuthBody } from "../../auth";
import { getOrderCopyOmit } from "../../components/Orders/orderUtils";
import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { GroupItem } from "../models/_statistics";
import { Account } from "../models/accountUser";
import {
    IOrderDeleteCreate,
    IOrderInvoiceCreateModel,
    Order,
    OrderCommentUnreadInput,
    OrderItemLog,
    OrderItemLogSearch,
    OrderNotificationResponse,
    OrderSearchDateRange,
    OrderSearchGroupInput,
    OrderSearchInput,
    OrderSearchNotificationCount,
    OrderSearchStatusCount,
} from "../models/order";
import { OrderStatus } from "../models/orderBase";
import { OrderInvoiceNumeration } from "../models/orderInvoiceNumeration";
import { IOrderPayment } from "../models/orderPayment";
import { OrderSerialSearchResponse, ProductSerialSearchInput } from "../models/product";
import { IWarehouse } from "../models/warehouse";
import { localOrderEndpoint } from "../restApiEndpoint";

export interface OrderStatusUpdateInput {
    status: OrderStatus;
    organizationId: string;
    orderId: string;
    accountUserId?: string;
}

export interface handleDownloadProductsOptions {
    warehouse: IWarehouse;
    categoryId?: string;
    searchValue?: string;
    status?: string;
    appSettingsState?: IAppSettingsState;
    onFinish: () => void;
    onFailed: (error: any) => void;
}

interface handleDeclineOrderProps {
    comment?: string;
    cashRegisterId: string;
    orderId: string;
    organizationId: string;
    onSuccess: () => void;
    onError: (error: any) => void;
}

interface CreateOrderInputProps {
    order: Order;
    warehouse: IWarehouse;
    account: Account;
    orderInvoiceNumerationId?: string;
    appSettingsState: IAppSettingsState;
    onSuccess: (order: Order) => void;
    onFailed: (message: string) => void;
}

export const handleDeclineOrder = (props: handleDeclineOrderProps) => {
    const { comment, cashRegisterId, orderId, organizationId } = props;

    let body: IOrderDeleteCreate = {
        accountUserId: getAuthBody().accountUserId,
        cashRegisterId: cashRegisterId,
        orderId: orderId,
        method: "cash",
        status: "declined",
        organizationId: organizationId,
        comment,
    };

    wahioFetch.post(
        localOrderEndpoint.post.deleteOrder,
        body,
        (sucesss) => {
            props.onSuccess();
        },
        (error) => {
            props.onError(error);
        }
    );
};

export const handleCreateOrder = (orderInput: CreateOrderInputProps) => {
    const { order, warehouse, appSettingsState, onSuccess, onFailed } = orderInput;

    order.lastAccountUserId = getAuthBody().accountUserId;
    order.allowLogisticsService = warehouse.allowLogisticsService;
    order.deliveryOrder = order.isDelivery ? order.deliveryOrder : undefined;
    order.status = "partial";
    order.source = order.source ? order.source : "manually";

    if (!order.id) {
        order.includeInvoice = true;
        if (!orderInput.orderInvoiceNumerationId) return onFailed("Se requiere una numeración");
        order.orderInvoiceNumerationId = orderInput?.orderInvoiceNumerationId;
    }

    order.totalTaxItems = order.totalTaxItems ? order.totalTaxItems : 0;

    if (!order.id) {
        order.dateCreatedCustom = appSettingsState.orderForm?.allowCustomDate ? order.dateCreatedCustom : undefined;
    }

    wahioFetch.post(
        localOrderEndpoint.post.orderUpdateCreateAll,
        { ...order },
        (success) => {
            onSuccess(success.data);
        },
        (error) => {
            onFailed(getServiceMessageError(error));
        }
    );
};

export const putOrderStatus = (searchInput: OrderStatusUpdateInput): Promise<Order> => {
    return wahioFetchApi.put(localOrderEndpoint.put.orderStatus, searchInput).then((res: any) => res.data);
};

export const getOrders = (searchInput: OrderSearchInput): Promise<IPaginationResponse<Order>> => {
    return wahioFetchApi.post(localOrderEndpoint.post.getOrderQuerySearch, searchInput).then((res: any) => res.data);
};

export const getOrderItemLogs = (searchInput: OrderItemLogSearch): Promise<IPaginationResponse<OrderItemLog>> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderItemLogsSearch, searchInput).then((res: any) => res.data);
};

export const getOrderCountByStatus = (searchInput: OrderSearchStatusCount): Promise<{ count: number }> => {
    return wahioFetchApi.post(localOrderEndpoint.post.getOrderCountByStatus, searchInput).then((res: any) => res.data);
};

export const getOrderCommentsUnread = (data: OrderCommentUnreadInput): Promise<{ count: number }> => {
    return wahioFetchApi.get(localOrderEndpoint.get.getOrderCommentsUnread(data.organizationId, data.userId)).then((res: any) => res.data);
};

export const checkOrderPaymentAndUpdate = (searchInput: string): Promise<Order> => {
    return wahioFetchApi.get(localOrderEndpoint.get.checkOrderPaymentAndUpdate, searchInput).then((res: any) => res.data);
};

export const getOrder = (orderId: string): Promise<Order> => {
    return wahioFetchApi.get(localOrderEndpoint.get.orderById(orderId)).then((res: any) => res.data);
};

export const getOrderByNumber = (number: string): Promise<Order> => {
    return wahioFetchApi.get(localOrderEndpoint.get.orderByNumber(number, getAuthBody().accountId)).then((res: any) => res.data);
};

export const getOrdersGroup = (searchInput: OrderSearchGroupInput): Promise<GroupItem[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.getOrderGroupQuerySearch, searchInput).then((res: any) => res.data);
};

export const createOrderInvoice = (data: IOrderInvoiceCreateModel): Promise<Order> => {
    return wahioFetchApi.post(localOrderEndpoint.post.createOrderInvoice, data).then((res: any) => res.data);
};

export const createUpdateOrder = (data: Order): Promise<Order> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderUpdateCreateAll, getOrderCopyOmit(data)).then((res: any) => res.data);
};

export const orderCreatePayment = (data: IOrderPayment): Promise<Order> => {
    return wahioFetchApi.post(localOrderEndpoint.post.singlePayment, data).then((res: any) => res.data);
};

export const createMultipleInvoices = (data: IOrderInvoiceCreateModel[]): Promise<Order[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.createMultipleInvoices, data).then((res: any) => res.data);
};

export const orderInvoiceNumerationByAccount = (accountId: string): Promise<OrderInvoiceNumeration[]> => {
    return wahioFetchApi.get(localOrderEndpoint.get.invoiceNumerationByAccount(accountId)).then((res: any) => res.data);
};

export const getOrdersBySerialCode = (data: ProductSerialSearchInput): Promise<OrderSerialSearchResponse[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderSearchBySerial, data).then((res: any) => res.data);
};

export const getOrderCountByDateRange = (data: OrderSearchDateRange): Promise<{ count: number }> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderCountByDateRange, data).then((res: any) => res.data);
};

export const getOrderNotificationCount = (data: OrderSearchNotificationCount): Promise<OrderNotificationResponse> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderNotificationCount, data).then((res: any) => res.data);
};

export const deleteRejectOrder = (data: IOrderDeleteCreate): Promise<Order> => {
    return wahioFetchApi.post(localOrderEndpoint.post.deleteOrder, data).then((res: any) => res.data);
};
