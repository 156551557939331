import { defineMessages } from "react-intl";

const base = "components.orderReturns";

export default defineMessages({
    orderReturns: {
        id: `${base}.orderReturns`,
        defaultMessage: "Devoluciones",
    },
    createOrderReturns: {
        id: `${base}.createOrderReturns`,
        defaultMessage: "Crear devolución",
    },
    status: {
        id: `${base}.status`,
        defaultMessage: "Estado",
    },
    order: {
        id: `${base}.order`,
        defaultMessage: "Orden",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    orderReturn: {
        id: `${base}.orderReturn`,
        defaultMessage: "Devolución",
    },
    changes: {
        id: `${base}.changes`,
        defaultMessage: "Cambios",
    },
    returns: {
        id: `${base}.returns`,
        defaultMessage: "Devoluciones",
    },
    returnsAndExchanges:{
        id: `${base}.returnsAndExchanges`,
        defaultMessage: "Devoluciones y cambios",
    },
    youMustIncludeAtLeastOneItemToCreateTheReturn:{
        id: `${base}.youMustIncludeAtLeastOneItemToCreateTheReturn`,
        defaultMessage: "Debes incluir al menos un artículo para crear la devolución",
    },
    searchOrder:{
        id: `${base}.searchOrder`,
        defaultMessage: "Buscar orden",
    },
    searchProducts:{
        id: `${base}.searchProducts`,
        defaultMessage: "Buscar productos",
    },
    payments:{
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },
    searchByOrderOrInvoice:{
        id: `${base}.searchByOrderOrInvoice`,
        defaultMessage: "Buscar por orden o factura",
    },
    theLocationCodeIsMandatory:{
        id: `${base}.theLocationCodeIsMandatory`,
        defaultMessage: "El código de la ubicación es obligatorio",
    },
    theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterInOrderToAddPayments:{
        id: `${base}.theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterInOrderToAddPayments`,
        defaultMessage: "La Tienda actual debe tener una caja asignada, o debes activar una caja para poder agregar pagos",
    },
    returnCreatedSuccessfully:{
        id: `${base}.returnCreatedSuccessfully`,
        defaultMessage: "Devolución creada con éxito",
    },
    thisOrderAlreadyContainsReturns:{
        id: `${base}.thisOrderAlreadyContainsReturns`,
        defaultMessage: "Esta order ya contiene devoluciones",
    },
    beCareful:{
        id: `${base}.beCareful`,
        defaultMessage: "Ten cuidado",
    },
    added:{
        id: `${base}.added`,
        defaultMessage: "Agregado",
    },
    search:{
        id: `${base}.search`,
        defaultMessage: "Buscar",
    },
    theOrderCouldNotBeFound:{
        id: `${base}.theOrderCouldNotBeFound`,
        defaultMessage: "No se encontró la orden",
    },
    noAdditionalUnitsAvailable:{
        id: `${base}.noAdditionalUnitsAvailable`,
        defaultMessage: "No hay mas unidades disponibles",
    },
    orderTotal:{
        id: `${base}.orderTotal`,
        defaultMessage: "Total orden",
    },
    totalPaid:{
        id: `${base}.totalPaid`,
        defaultMessage: "Total pagado",
    },
    totalReturns:{
        id: `${base}.totalReturns`,
        defaultMessage: "Total devolución",
    },
    totalExchanges:{
        id: `${base}.totalExchanges`,
        defaultMessage: "Total cambios",
    },
    selectTheProductsYouWantToReturn:{
        id: `${base}.selectTheProductsYouWantToReturn`,
        defaultMessage: "Selecciona los productos que quieres devolver",
    },
    returnAndExchanges:{
        id: `${base}.returnAndExchanges`,
        defaultMessage: "Devoluciones y cambios",
    },
    changesCannotBeAddedToOrders:{
        id: `${base}.changesCannotBeAddedToOrders`,
        defaultMessage: "No se pueden añadir cambios a los pedidos.",
    },
    clickOn:{
        id: `${base}.clickOn`,
        defaultMessage: "Haz clic en",
    },
    toAddAnExchange:{
        id: `${base}.toAddAnExchange`,
        defaultMessage: "Para agregar un cambio",
    },
    toAddChangesToAnOrderYouMustCreateNewOrderOrdersCanOnlyHaveReturns:{
        id: `${base}.toAddChangesToAnOrderYouMustCreateNewOrderOrdersCanOnlyHaveReturns`,
        defaultMessage: "Para agregar cambios a un pedido debe crear un nuevo pedido, los pedidos solo pueden tener devoluciones",
    },
});
