import React from "react";
import { ContactLabel } from "../../../api/models/contact";
import Modal, { IModalShow } from "../../modals/Modal";
import ContactLabelForm, { ContactLabelFormProps } from "./ContactLabelForm";
import messages from "./messages";
import { useIntl } from "react-intl";

interface IProps extends ContactLabelFormProps, IModalShow {}

export default function ContactLabelFormModal(props: IProps) {
    const intl = useIntl();
    const onCreate = (value: ContactLabel) => {
        props.onCreate(value);
        props.setShow(false);
    };

    return (
        <Modal
            scrollInside={false}
            overflowAuto={false}
            title={props.contactLabel ? intl.formatMessage(messages.labelList) : intl.formatMessage(messages.createLabel)}
            useButtonClose
            ignoreOutsideClick
            {...props}
        >
            <ContactLabelForm {...props} onCreate={onCreate} onCancel={() => props.setShow(false)} />
        </Modal>
    );
}
