export const ADD_ORDER_CHANNEL = "ADD_ORDER_CHANNEL";
export const DELETE_ORDER_CHANNEL = "DELETE_ORDER_CHANNEL";
export const UPDATE_ORDER_CHANNEL = "UPDATE_ORDER_CHANNEL";
export const REQUEST_ORDER_CHANNELS = "REQUEST_ORDER_CHANNELS";
export const RECEIVE_ORDER_CHANNELS = "RECEIVE_ORDER_CHANNELS";
export const INVALID_REQUEST_ORDER_CHANNELS = "INVALID_REQUEST_ORDER_CHANNELS";

export interface IOrderChannel {
    id?: string;
    accountId: string;
    userOwnerId: string;
    name: string;
    dateCreated?: Date;
}

export class OrderChannel implements IOrderChannel {
    id?: string;
    accountId: string;
    userOwnerId: string = "";
    name: string = "";
    dateCreated?: Date;

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export interface IOrderChannelState {
    items: IOrderChannel[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestOrderChannels {
    type: typeof REQUEST_ORDER_CHANNELS;
}
export interface ReceiveOrderChannels {
    type: typeof RECEIVE_ORDER_CHANNELS;
    items: Array<IOrderChannel>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_ORDER_CHANNELS;
    value: any;
}
export interface UpdateOrderChannel {
    type: typeof UPDATE_ORDER_CHANNEL;
    value: IOrderChannel;
}

export interface AddOrderChannel {
    type: typeof ADD_ORDER_CHANNEL;
    value: IOrderChannel;
}

interface DeleteOrderChannel {
    type: typeof DELETE_ORDER_CHANNEL;
    id: string;
}

export type OrderChannelTypes =
    | RequestOrderChannels
    | ReceiveOrderChannels
    | InvalidRequest
    | UpdateOrderChannel
    | AddOrderChannel
    | DeleteOrderChannel;
