import styled from "styled-components";
import { device } from "../../../theme/themeProvider";
import { DefaultButton } from "../../_controls";

export const MainContainer = styled.div`
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const PRODUCT_SQUARE_GRID_SIZE = 110;
export const PRODUCT_SQUARE_GRID_GAP = 10;
export const PRODUCT_SQUARE_GRID_PADDING_SIDE = 15;

export const ProductSquareContainer = styled.div`
    display: grid;
    grid-gap: ${PRODUCT_SQUARE_GRID_GAP}px;
    grid-template-columns: repeat(auto-fill, minmax(${PRODUCT_SQUARE_GRID_SIZE}px, 1fr));
    padding: 7px ${PRODUCT_SQUARE_GRID_PADDING_SIDE}px;

    &.large {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
`;
export const LoadMoreDetailsContainer = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProductSettingsContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

export const ProductRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 5px;
`;

export const NotMoreItems = styled.div`
    color: ${(props) => props.theme.palette.textLight};
    padding: 10px;
    text-align: center;
`;

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: sticky;
    top: 0px;
    background: ${(props) => props.theme.palette.background};
    z-index: 2;

    .search-input {
        width: 100%;
        input {
            background: transparent;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        }
    }

    button {
        background: transparent;
        color: ${(props) => props.theme.palette.text};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const WarehouseSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 1px;
    gap: 15px;
    width: auto;
    margin-bottom: 20px;
    button {
        white-space: nowrap;
    }

    .select__control {
        max-width: 160px;
        min-width: 160px;
        .select__single-value {
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const AddProductFloatCard = styled(DefaultButton)`
    padding: 12px 15px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: 2;
    bottom: 20px;
    border-radius: 40px;
    background: #00bfa5;
    color: white;
    border: 0;
    box-shadow: 1px 1px 14px #009688ab;
    &:hover {
        background: #008976;
    }
`;

export const ExpandableContainer = styled.div`
    &.left-panel {
        width: 100%;
        max-width: 500px;
        border-top-left-radius: 10px;
    }
    &.expandible {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 11;
        .product-search-layout {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            background: ${(props) => props.theme.palette.backgroundModal};
        }

        .product-search-body {
            position: absolute;
            width: 100%;
            max-width: 500px;
            z-index: 11;
            background: ${(props) => props.theme.palette.background};
            height: 100%;
            max-height: 596px;
            box-shadow: 1px 1px 14px ${(props) => props.theme.palette.shadowColor};
            bottom: 0px;
            border-radius: 20px 20px 0 0;
            overflow: hidden;
            padding-bottom: 17px;
            box-sizing: border-box;
            transition: transform 0.3s ease-in-out;

            .search-header-top {
                padding: 15px;
                padding-bottom: 3px;
            }
        }
        @media ${device.md} {
            .product-search-body {
                bottom: 35px;
                border-radius: 20px;
            }
        }
    }

    &.disabled {
        opacity: 0.8;
        position: relative;
        :after {
            content: " ";
            position: absolute;
            background: ${(props) => props.theme.palette.backgroundLight}40;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: unset;
        }
    }
`;

export const SearchContainerView = styled.div`
    display: flex;
    width: 100%;
    background: pink;
    background: ${(props) => props.theme.palette.backgroundLight};
    border-radius: 30px;
    max-width: 300px;
    .search-input {
        margin: 0px;
    }

    input {
        border: 0px;
        background: transparent;
    }
    .dropdownvalue {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .dropbtn {
        border: 0px;
        background: transparent;
        font-size: 13px;
        padding-right: 7px;
        height: 100%;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 0;
        .icon {
            margin-left: 6px;
            margin-right: 0px;
        }
    }
`;

export const LoadingContainer = styled.div`
    text-align: center;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    justify-content: center;
    .body {
        background: ${(props) => props.theme.palette.background};
        width: 60px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        box-shadow: ${(props) => props.theme.palette.shadow};
    }
`;

export const MoreItemsContainer = styled.div`
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 14px;
    display: flex;
    justify-content: center;
    button {
        border-radius: 50px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const CounterFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 8px;
    background: ${(props) => props.theme.palette.primary};
    color: white;
    font-size: 10px;
    border-radius: 30px;
    padding: 4px 5px;
    position: absolute;
    font-weight: 500;
    top: -6px;
    cursor: default;
    left: 23px;
`;

export const ProductItemsContainer = styled.div`
    overflow: auto;
`;

export const ProductQuantityPanelStyle = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .product-quantity-added {
        background: ${(props) => props.theme.palette.backgroundLight};
        padding: 7px 10px;
        border-radius: 15px;
    }

    .quantity-card {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 5px;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        border-radius: 40px;

        .input-quantity {
            width: 100%;
            border-radius: 50px;
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            border: 0;
            padding: 3px;
        }
    }
`;

export const CategoriesPanelStyle = styled.div`
    display: grid;
    align-items: center;
    position: relative;

    .arrow-icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
        position: absolute;
        z-index: 1;
        background: ${(props) => props.theme.palette.background};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        &.left {
            left: -10px;
        }
        &.right {
            right: -10px;
        }
    }
    .category-list {
        overflow: auto;
        ::-webkit-scrollbar {
            height: 0px;
        }
        scroll-behavior: smooth;

        button {
            border: 0;
            padding: 2px 10px;
            border-radius: 8px;

            &.active {
                background: ${(props) => props.theme.palette.primary};
                color: white;
            }
        }
    }
`;

export const CategoryGridCard = styled.div`
    border-radius: 15px;
    cursor: pointer;
    &.active {
        background: #304ffe33;
        border-color: #304ffe;
    }
    .category-name {
        padding: 5px 8px;
        font-weight: 500;
        text-align: center;
    }
    .category-image {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 15px;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &:hover {
        background: ${(props) => props.theme.palette.background};
        box-shadow: 1px 1px 10px ${(props) => props.theme.palette.shadowColor};
        .category-image {
            transition: border-radius 0.2 ease-in-out;
            border-radius: 15px 15px 0 0;
            border-color: transparent;
        }
    }
`;
