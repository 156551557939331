import styled from "styled-components";

export const OrderTabsGridHeader = styled.div`
    display: grid;
    overflow: auto;
`;
export const OrderTabsHeader = styled.div`
    display: flex;
    padding: 10px;
    padding-bottom: 0;
    padding-top: 5px;
    padding-left: 7px;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .order-tab-separator {
        width: 0px;
        height: 15px;
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .order-tab-button {
        border: 0;
        border-radius: 10px 10px 0 0;
        padding: 4px 10px;
        color: ${(props) => props.theme.palette.textLight};
        justify-content: space-between;
        font-weight: 400;

        .remove-tab {
            border-radius: 20px;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};

            &:hover {
                background: ${(props) => props.theme.palette.primary}40;
                color: ${(props) => props.theme.palette.text};
            }
        }

        &:hover {
            background: ${(props) => props.theme.palette.primary}20;
        }

        &.selected {
            font-weight: 500;
            color: ${(props) => props.theme.palette.text};
            background: ${(props) => props.theme.palette.backgroundLight};
            box-sizing: border-box;
        }
    }
`;

export const ScreenPreviewContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    background: ${(props) => props.theme.palette.backgroundLight};

    .preview-ads {
        width: 100%;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        .ads-image-container {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .preview-summary {
        padding: 20px;
        width: 550px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .order-items {
            gap: 5px;
            height: 100%;
            overflow: auto;
        }

        .order-item-row {
            padding-bottom: 5px;
            border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
            &:last-child {
                border: 0;
            }
        }

        .summary-total {
            margin-top: auto;
            border-top: 1px solid ${(props) => props.theme.palette.backgroundDark};

            table {
                width: 100%;
                td {
                    padding: 5px;
                }
                .total-row {
                    font-size: 20px;
                    font-weight: 500;
                    td {
                        border-top: 1px solid ${(props) => props.theme.palette.backgroundDark};
                    }
                }
            }
        }
    }
`;
