import React, { useState } from "react";
import { useIntl } from "react-intl";
import { OrderDetailsPanelProps } from ".";
import { useAppSettingsContext } from "../../../../appSettings/AppSettingsContext";
import { OrderFormSettings } from "../../../../appSettings/types";
import { Flex, GridTemplate, SquareButton, TextField } from "../../../_controls";
import CheckBox from "../../../_controls/inputs/CheckBox";
import Modal from "../../../modals/Modal";
import messages from "../messages";
import OrderTypeSelector from "./OrderTypeSelector";
import TooltipRadix from "../../../_controls/WahioTooltip/TooltipRadix";

const DropdownOrderOptions = (props: OrderDetailsPanelProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal && (
                <Modal show={showModal} setShow={setShowModal} title="Configuración de ordenes" showHeader>
                    <OrderSettingsPanel />
                </Modal>
            )}

            <TooltipRadix
                title={"Abrir configuración"}
                trigger={
                    <SquareButton onClick={() => setShowModal(true)}>
                        <i className="fa-regular fa-cog"></i>
                    </SquareButton>
                }
            />
        </>
    );
};

const OrderSettingsPanel = () => {
    const { appSettingsState, appSettingsActions } = useAppSettingsContext();

    const orderFormSettings = appSettingsState.orderForm ?? {};

    const onChangeOrderForm = (key: keyof OrderFormSettings, value: boolean) => {
        appSettingsActions.setAllSettings({
            ...appSettingsState,
            orderForm: {
                ...orderFormSettings,
                [key]: value,
            },
        });
    };

    return (
        <>
            <Flex column gap={30} padding={20} minWidth={280}>
                <Flex column gap10>
                    <TextField small bold>
                        Vistas
                    </TextField>

                    <GridTemplate gap={20} size={200}>
                        <CheckBox
                            name="showPriceSelector"
                            title={`Mostrar Precios`}
                            checked={orderFormSettings.showPriceSelector}
                            onChange={(e) => onChangeOrderForm("showPriceSelector", e.target.checked)}
                        />
                        <CheckBox
                            name="showWarehouseSelector"
                            title={`Mostrar Bodega`}
                            checked={orderFormSettings.showWarehouseSelector}
                            onChange={(e) => onChangeOrderForm("showWarehouseSelector", e.target.checked)}
                        />
                        <CheckBox
                            name="showChannelSelector"
                            title={`Canal de Venta`}
                            checked={orderFormSettings.showChannelSelector}
                            onChange={(e) => onChangeOrderForm("showChannelSelector", e.target.checked)}
                        />
                        <CheckBox
                            name="showSellerSelector"
                            title={`Vendedor`}
                            checked={orderFormSettings.showSellerSelector}
                            onChange={(e) => onChangeOrderForm("showSellerSelector", e.target.checked)}
                        />
                        <CheckBox
                            name="showNumerationSelector"
                            title={`Numeración`}
                            checked={orderFormSettings.showNumerationSelector}
                            onChange={(e) => onChangeOrderForm("showNumerationSelector", e.target.checked)}
                        />

                        <CheckBox
                            name="allowCustomDate"
                            title={`Fecha Personalizada`}
                            checked={orderFormSettings.allowCustomDate}
                            onChange={(e) => onChangeOrderForm("allowCustomDate", e.target.checked)}
                        />
                    </GridTemplate>
                </Flex>

                <Flex column gap10>
                    <TextField small light>
                        Propina
                    </TextField>

                    <CheckBox
                        name="showTotalTip"
                        title={`Mostrar Propina`}
                        description="La propina se mostrará en el resumen de la orden"
                        checked={orderFormSettings.showTotalTip}
                        onChange={(e) => onChangeOrderForm("showTotalTip", e.target.checked)}
                    />

                    <CheckBox
                        name="tipBeforeTax"
                        title={`Propina antes de impuestos`}
                        description="Si está activado, la propina se calculará antes de los impuestos"
                        checked={orderFormSettings.tipBeforeTax}
                        onChange={(e) => onChangeOrderForm("tipBeforeTax", e.target.checked)}
                    />
                </Flex>

                <Flex column gap10>
                    <TextField small light>
                        Descuento general
                    </TextField>

                    <CheckBox
                        name="showTotalDiscount"
                        title={`Mostrar Descuento`}
                        description="El descuento se mostrará en el resumen de la orden"
                        checked={orderFormSettings.showTotalDiscount}
                        onChange={(e) => onChangeOrderForm("showTotalDiscount", e.target.checked)}
                    />

                    <CheckBox
                        name="discountBeforeTax"
                        title={`Descuento antes de impuestos`}
                        description="Si está activado, el descuento se calculará antes de los impuestos"
                        checked={orderFormSettings.discountBeforeTax}
                        onChange={(e) => onChangeOrderForm("discountBeforeTax", e.target.checked)}
                    />
                </Flex>

                <TextField small bold>
                    Configuración
                </TextField>

                <CheckBox
                    name="autoPrintOrder"
                    title={`Auto Impresión`}
                    description="Si está activado, la orden se imprimirá automáticamente al finalizar la orden"
                    checked={appSettingsState.autoPrintOrder}
                    onChange={(e) =>
                        appSettingsActions.setAllSettings({
                            ...appSettingsState,
                            autoPrintOrder: e.target.checked,
                        })
                    }
                />
            </Flex>
        </>
    );
};

const OrderPanelHeaderTop = (props: OrderDetailsPanelProps) => {
    const intl = useIntl();

    let showDraft = false;
    if (props.origin !== "restaurant") {
        if (props.order.status === "draft" || !props.order.id) {
            showDraft = true;
        }
    }

    return (
        <Flex spaceBetween gap10 alignCenter>
            <OrderTypeSelector {...props} />
            <Flex gap10 alignCenter>
                {props.origin !== "restaurant" && !props.order.id && (
                    <TooltipRadix
                        title={intl.formatMessage(messages.clearOrderForm)}
                        trigger={
                            <SquareButton rounded removeBackground colorText onClick={() => props.setConfirmClearOrder(true)}>
                                <i className="fa-sharp fa-regular fa-broom"></i>
                            </SquareButton>
                        }
                    />
                )}

                {showDraft && (
                    <TooltipRadix
                        title={intl.formatMessage(messages.saveAsDraft)}
                        trigger={
                            <SquareButton
                                colorText
                                removeBackground
                                rounded
                                disabled={props.order.itemsQuantityCount === 0}
                                onClick={() => props.setOpenDraftModal(true)}
                            >
                                <i className="fa-regular fa-floppy-disk"></i>
                            </SquareButton>
                        }
                    />
                )}
                <DropdownOrderOptions {...props} />
            </Flex>
        </Flex>
    );
};

export default OrderPanelHeaderTop;
