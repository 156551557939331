import { defineMessages } from "react-intl";

const base = "components.quickStart";

export default defineMessages({
    createProduct: {
        id: `${base}.createProduct`,
        defaultMessage: "Crear un Producto",
    },
    createContact: {
        id: `${base}.createContact`,
        defaultMessage: "Crear un Contacto",
    },
    createSale: {
        id: `${base}.createSale`,
        defaultMessage: "Crear una Venta POS",
    },
    createOrder: {
        id: `${base}.createOrder`,
        defaultMessage: "Crear un Pedido",
    },
    createPurchase: {
        id: `${base}.createPurchase`,
        defaultMessage: "Crear una Compra",
    },
});
