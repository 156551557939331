import styled from "styled-components";
import { Flex } from "../../_controls";

export const PermissionRowStyle = styled(Flex)`
    .permission-title {
        white-space: nowrap;
    }
`;

export const PermissionKeysPreview = styled(Flex)`
    font-size: 13px;
    flex-wrap: nowrap;
    gap: 5px;
    overflow: hidden;
    .key-item {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 10px;
        padding: 1px 4px;
        border-radius: 18px;
    }
`;
