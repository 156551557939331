import React, { createContext, useReducer, useEffect } from "react";
import { saveData, LocalStoreKeys } from "..";
import { IInventoryAdjustmentActions, useActions } from "../actions/inventoryAdjustment";
import { applyMiddleware } from "../middlewares/inventoryAdjustment";
import { inventoryAdjustmentReducer, initState } from "../reducers/inventoryAdjustment";
import { IInventoryAdjustmentState } from "../../api/models/inventoryAdjustment";

interface IContextProps {
    inventoryAdjustmentState: IInventoryAdjustmentState;
    inventoryAdjustmentActions: IInventoryAdjustmentActions;
}

export const InventoryAdjustmentContext = createContext({} as IContextProps);

const InventoryAdjustmentContextProvider = (props: any) => {
    const [inventoryAdjustmentState, dispatch] = useReducer(inventoryAdjustmentReducer, initState);

    useEffect(() => {
        saveData(LocalStoreKeys.inventoryAdjustment, inventoryAdjustmentState);
    }, [inventoryAdjustmentState]);

    const inventoryAdjustmentActions = useActions(inventoryAdjustmentState, applyMiddleware(dispatch));

    return <InventoryAdjustmentContext.Provider value={{ inventoryAdjustmentState, inventoryAdjustmentActions }}>{props.children}</InventoryAdjustmentContext.Provider>;
};

export default InventoryAdjustmentContextProvider;
