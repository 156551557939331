import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import {
    IProduct,
    IProductVariant,
    ProductCostResponse,
    ProductCostSearchInput,
    ProductPartialUpdateData,
    ProductPartialUpdateInput,
} from "../../../api/models/product";
import { productPartialUpdate } from "../../../api/products/productsApi";
import { getProductCostByOnPurchases } from "../../../api/purchases/purchaseApi";
import { getAuthBody } from "../../../auth";
import { checkAndGetMessage } from "../../../i18n/helper";
import { formatMoney, getDateFromNow } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import TextBoxNumeral from "../../TextBoxNumeral";
import { COST_BASE_LIST, COST_LIST, ProductCostLink } from "../../Warehouses/WarehouseProfile/WarehouseCostView";
import warehouseProfileMessages from "../../Warehouses/WarehouseProfile/messages";
import WarehouseSelect from "../../Warehouses/WarehouseSelect";
import { DefaultButton, Flex, GridTemplate, PrimaryButton, TextField } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationCosts(props: ProductNavigationProfileProps) {
    const intl = useIntl();
    return (
        <NavigationCard {...props} title={intl.formatMessage(messages.costs)}>
            <ProductCostSummaryPanel {...props} stock={1} />
        </NavigationCard>
    );
}

interface ProductCostSummaryProps extends ProductNavigationProfileProps {
    stock: number;
}

export const ProductCostSummaryPanel = (props: ProductCostSummaryProps) => {
    const { product } = props;
    const intl = useIntl();
    const alert = useAlert();

    const [showBasePrice, setShowBasePrice] = useState(false);

    const [showEditCost, setShowEditCost] = useState(false);

    var productBase = props.productVariant ? props.productVariant : props.product;

    const getProductCostMutation = useMutation((data: ProductCostSearchInput) => getProductCostByOnPurchases(data), {
        onSuccess: (data) => {
            handleProcessCostResult(data);
        },
    });

    const saveProductCostMutation = useMutation((body: ProductPartialUpdateData) => productPartialUpdate(body), {
        onSuccess: (data) => {},
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleGetCosts = () => {
        let data: ProductCostSearchInput = {
            productId: product.id,
            productVariantId: props.productVariant?.id,
            accountId: getAuthBody().accountId,
            dateStart: customMoment().add(-8, "months").format(),
            dateEnd: customMoment().add(1, "day").format(),
            limit: 0,
            minutesOffset: new Date().getTimezoneOffset(),
        };
        getProductCostMutation.mutate(data);
    };

    const handleProcessCostResult = (data: ProductCostResponse) => {
        if (data.preventUpdate) {
            alert.info(
                "No se puede actualizar los costos calculados porque no hay compras registradas en el periodo de tiempo seleccionado"
            );
            return;
        }

        handleSaveCalculateCosts(data);
    };

    const saveLocalProductCost = (data: ProductCostResponse) => {
        if (props.productVariant && data.productVariantId) {
            props.productVariant.costAverage = data.costAverage;
            props.productVariant.costBaseAverage = data.costBaseAverage;
            props.productVariant.costWeightedAverage = data.costWeightedAverage;
            props.productVariant.costBaseWeightedAverage = data.costBaseWeightedAverage;
            props.productVariant.costLastPurchase = data.costLastPurchase;
            props.productVariant.costBaseLastPurchase = data.costBaseLastPurchase;
        }
        props.onUpdateProduct({ ...product, ...data, costUpdatedAt: customMoment().utc().format() });
    };

    const handleSaveCalculateCosts = (costSummary: ProductCostResponse) => {
        alert.success("Costos actualizados correctamente");
        saveLocalProductCost(costSummary);

        let data: ProductPartialUpdateInput = {
            costWeightedAverage: costSummary.costWeightedAverage,
            costBaseWeightedAverage: costSummary.costBaseWeightedAverage,
            costAverage: costSummary.costAverage,
            costBaseAverage: costSummary.costBaseAverage,
            costLastPurchase: costSummary.costLastPurchase,
            costBaseLastPurchase: costSummary.costBaseLastPurchase,
            costUpdatedAt: customMoment().format(),
            basePrice: costSummary.basePrice,
        };
        handleSaveCosts(data, product, props.productVariant);
    };

    const handleSaveCosts = (data: ProductPartialUpdateInput, product: IProduct, productVariant?: IProductVariant) => {
        saveProductCostMutation.mutate({
            data,
            id: productVariant?.id ?? product.id,
            isVariant: !!productVariant,
        });
    };

    if (!productBase) return null;
    const COST_LIST_DEFAULT = showBasePrice ? COST_BASE_LIST : COST_LIST;

    const isUpdatingCost = getProductCostMutation.isLoading || saveProductCostMutation.isLoading;

    const stockValue = props.warehouseProduct?.stock ?? 0;

    const defaultWarehouseId = props.defaultWarehouseId ?? props.selectedWarehouse?.id;

    return (
        <Flex column gap={20}>
            <WarehouseSelect defaultWarehouseId={defaultWarehouseId} onWarehouseChange={(value) => props.setSelectedWarehouse(value)} />
            {showEditCost && (
                <Modal show={showEditCost} setShow={setShowEditCost} useButtonClose ignoreOutsideClick>
                    <EditCostPanel
                        {...props}
                        onSaveCustomCost={(value) => {
                            setShowEditCost(false);
                            handleSaveCalculateCosts(value);
                        }}
                        onCancel={() => setShowEditCost(false)}
                    />
                </Modal>
            )}
            {props.productVariant && <TextField bold>Costos {props.productVariant?.valuesLine}</TextField>}
            <Flex gap10 alignCenter>
                <DefaultButton borderRadius={10} bgLight disabled={isUpdatingCost} onClick={() => setShowEditCost(true)}>
                    <i className="fa-regular fa-pen-to-square"></i> Editar
                </DefaultButton>
                <DefaultButton borderRadius={10} bgLight disabled={isUpdatingCost} onClick={() => handleGetCosts()}>
                    {isUpdatingCost ? <LoadingDualRing small /> : <i className="fa-regular fa-arrows-rotate"></i>} Re-calcular
                </DefaultButton>
            </Flex>

            <TextField small light>
                {intl.formatMessage(messages.updated)} {getDateFromNow(productBase.costUpdatedAt)}
            </TextField>

            <Table tdPaddingRight={20} tdPadding={7} className="fist-column-light" cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th>Costo</th>
                        <th>Valor Unidad</th>
                        <th>Costo Inventario ({stockValue} Stock)</th>
                    </tr>
                </thead>
                <tbody>
                    {COST_LIST_DEFAULT.map((field, index) => {
                        const value: number = (productBase as any)[field];
                        return (
                            <tr key={index}>
                                <td className="min-width">{checkAndGetMessage(intl, warehouseProfileMessages, field)}</td>
                                <td>{formatMoney(Math.round(value))}</td>
                                <td>{formatMoney(Math.round(value * stockValue))}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <CheckBox checked={showBasePrice} title="Mostrar costo sin impuesto" onChange={(e) => setShowBasePrice(e.target.checked)} />

            <ProductCostLink />
        </Flex>
    );
};

interface EditCostPanelProps {
    product: IProduct;
    productVariant?: IProductVariant;
    onSaveCustomCost: (costBody: ProductCostResponse) => void;
    onCancel: () => void;
}

const EditCostPanel = (props: EditCostPanelProps) => {
    const [costBody, setCostBody] = useState<ProductCostResponse>(() => {
        const productBase = props.productVariant ? props.productVariant : props.product;
        return {
            basePrice: productBase.basePrice,
            costWeightedAverage: productBase.costWeightedAverage,
            costBaseWeightedAverage: productBase.costBaseWeightedAverage,
            costAverage: productBase.costAverage,
            costBaseAverage: productBase.costBaseAverage,
            costLastPurchase: productBase.costLastPurchase,
            costBaseLastPurchase: productBase.costBaseLastPurchase,
            costUpdatedAt: customMoment().format(),
            productId: props.product.id,
            productVariantId: props.productVariant?.id,
        };
    });

    return (
        <Flex padding={20} column gap={20}>
            <Flex column gap5>
                <h3 className="m-0">Editar Costos</h3>
                <TextField small light>
                    Ten en cuanta que si actualizas el costo manual y después haces compras, el costo manual será reemplazado por el calculo
                    de costos de las compras
                </TextField>
            </Flex>
            <GridTemplate gap={15}>
                <Flex column gap10>
                    <Flex column>
                        <TextField bold>Costos con impuestos</TextField>
                    </Flex>
                    {COST_LIST.map((field, index) => {
                        return <CostInputEdit key={index} field={field} costBody={costBody} setCostBody={setCostBody} />;
                    })}
                </Flex>
                <Flex column gap10>
                    <Flex column>
                        <TextField bold>Costos sin impuestos</TextField>
                    </Flex>
                    {COST_BASE_LIST.map((field, index) => {
                        return <CostInputEdit key={index} field={field} costBody={costBody} setCostBody={setCostBody} />;
                    })}
                </Flex>
            </GridTemplate>

            <Flex gap15 justifyEnd marginTop={20}>
                <DefaultButton bgLight borderRadius={10} w100 onClick={() => props.onCancel()}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton
                    w100
                    borderRadius={10}
                    onClick={() => {
                        props.onSaveCustomCost(costBody);
                    }}
                >
                    Guardar
                </PrimaryButton>
            </Flex>
        </Flex>
    );
};

interface CostInputEditProps {
    field: string;
    costBody: ProductCostResponse;
    setCostBody: (costBody: ProductCostResponse) => void;
}
const CostInputEdit = (props: CostInputEditProps) => {
    const intl = useIntl();
    const { field, costBody, setCostBody } = props;

    return (
        <Flex column gap5>
            <TextField light small>
                {checkAndGetMessage(intl, warehouseProfileMessages, field)}
            </TextField>

            <TextBoxNumeral
                w100
                rounded
                format={"money"}
                value={(costBody as any)[field]}
                onNumberChange={(value) => {
                    setCostBody({ ...costBody, [field]: value });
                }}
            />
        </Flex>
    );
};
