import { getUniqueId } from "../../utils";
import { IOrganization } from "./organizationTypes";
import { WahioCloudItem, WahioCloudItemType } from "./wahioCloud";

export type PaymentMethod =
    | "invalid"
    | "cash"
    | "transfer"
    | "creditCard"
    | "debitCard"
    | "giftCard"
    | "deposit"
    | "online"
    | "card"
    | "orderReturn";

export const METHOD_ICONS = {
    cash: "fa-regular fa-money-bill",
    card: "fa-regular fa-credit-card",
    creditCard: "fa-regular fa-credit-card",
    debitCard: "fa-regular fa-credit-card",
    transfer: "fa-regular fa-arrow-right-from-line",
    deposit: "fa-sharp fa-regular fa-envelope-open-dollar",
    online: "fa-regular fa-globe",
};

export type WahioFileAccessTypeList = "public" | "private";

export const PAYMENT_METHOD_LIST: PaymentMethod[] = [
    "cash",
    "transfer",
    "card",
    "creditCard",
    "debitCard",
    "giftCard",
    "deposit",
    "online",
];

export const paymentMethodCashList: PaymentMethod[] = ["cash"];
export const paymentMethodBankList: PaymentMethod[] = ["card", "transfer", "deposit", "online"];

export interface IUniqueAttribute {
    id: string;
    value: string;
}

export interface WahioFile {
    id: string; //GENERATE IN THE UI
    url: string;
    thumbnailUrl?: string;
    type?: WahioCloudItemType;
    dateCreated?: Date;
    index: number;
    name: string;
    imageAnalysisAsString?: string;
    fileTagsAsString?: string;
    fileTags?: string[];
    accessType?: WahioFileAccessTypeList;
}

export class WahioFileFromCloud implements WahioFile {
    id: string;
    url: string;
    type?: WahioCloudItemType;
    dateCreated?: Date | undefined;
    index: number;
    name: string = "";
    fileTagsAsString?: string;
    fileTags?: string[];
    imageAnalysisAsString?: string;
    thumbnailUrl?: string;

    constructor(value: WahioCloudItem) {
        this.id = getUniqueId();
        this.url = value.url;
        this.name = value.name;
        this.index = 0;
        this.fileTags = value.fileTags;
        this.fileTagsAsString = value.fileTagsAsString;
        this.thumbnailUrl = value.thumbnailUrl;
        if (value.imageAnalysis) {
            this.imageAnalysisAsString = JSON.stringify(value.imageAnalysis);
        }
        this.type = value.type;
    }
}

export class WahioFileImpl implements WahioFile {
    id: string;
    url: string;
    thumbnailUrl?: string;
    type?: WahioCloudItemType;
    dateCreated?: Date | undefined;
    index: number;
    name: string = "";
    fileTagsAsString?: string;
    imageAnalysisAsString?: string;
    fileTags?: string[];
    accessType?: WahioFileAccessTypeList;

    constructor(url: string, type?: WahioCloudItemType, thumbnailUrl?: string) {
        this.id = getUniqueId();
        this.url = url;
        this.type = type;
        this.index = 0;
        this.accessType = "private";
        this.thumbnailUrl = thumbnailUrl;
    }
}

export interface SelectBaseOption {
    id: string;
    label: string;
    value: string;
}

export interface SelectOptionObject<T> {
    label: string;
    value: string | number;
    object: T;
}

export interface SelectObject<T> {
    object: T;
    label: string;
    value: string | number;
}

export interface SelectBaseOptionColor extends SelectBaseOption {
    color: string;
    colorName?: string;
}

export interface IPaginationRequest {
    currentPage: number;
    pageSize: number;
}

export interface PaginationRequestDateFilter extends DateFilter {
    currentPage: number;
    pageSize: number;
}

export interface IPageRequestAccount extends IPaginationRequest {
    accountId: string;
    valueId: string;
}
export class PageRequestAccount implements IPageRequestAccount {
    accountId: string;
    valueId: string;
    currentPage: number = 1;
    pageSize: number = 20;

    constructor(accountId?: string, valueId?: string, currentPage?: number, pageSize?: number) {
        this.accountId = accountId ? accountId : "";
        this.valueId = valueId ? valueId : "";
        this.currentPage = currentPage ? currentPage : 1;
        this.pageSize = pageSize ? pageSize : 20;
    }
}
export interface IPaginationList extends IPaginationRequest {
    totalPages: number;
    totalItems: number;
}
export class PaginationRequest implements IPaginationRequest {
    currentPage: number = 1;
    pageSize: number = 20;
}

export interface IPaginationResponse<T> extends IPaginationList {
    items: T[];
}

export class PaginationResponse<T> implements IPaginationResponse<T> {
    items: T[] = [];
    currentPage: number = 1;
    pageSize: number = 20;
    totalPages: number = 0;
    totalItems: number = 0;
    constructor(pagSize?: number) {
        if (pagSize) {
            this.pageSize = pagSize;
        }
    }
}

export class UniqueAttribute implements IUniqueAttribute {
    id: string;
    value: string;
    constructor(id: string, value: string) {
        this.id = id;
        this.value = value;
    }
}
export interface IImageSaveResult {
    status: number;
    success: Boolean;
    url: string;
    name?: string;
}
export interface IBusinessException {
    status: number;
    code: string;
    message: string;
    type: string;
}

export interface IGenericState<T> {
    items: T[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export class BusinessException implements IBusinessException {
    code: string = "";
    message: string = "";
    type: string = "";
    status: number;

    constructor(code: string = "", message: string = "", type: string = "", status?: number) {
        this.code = code;
        this.message = message;
        this.type = type;
        this.status = status ? status : 0;
    }
}
export const createBusinessException = (errorObject: any) => {
    const data = errorObject.data ? errorObject.data : {};
    return new BusinessException(data.code, data.message, data.type, errorObject.statusCode);
};
export const ERROR_UPLOAD_IMAGE = "ERROR_UPLOAD_IMAGE";

export interface DateBaseFilter {
    accountId: string;
    organizationId?: string;
    organization?: IOrganization;
    minutesOffset: number;
}

export interface DateFilter extends DateBaseFilter {
    dateStart: Date | string;
    dateEnd: Date | string;
    limit: number;
}
export interface DateFilterYear extends DateBaseFilter {
    year: number;
}
