import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import CreatableSelect from "react-select/creatable";
import { getCitiesByCountryAndState } from "../../api/geoLocation/geoLocationApi";
import { SelectOptionObject } from "../../api/models";
import { City } from "../../api/models/geoLocation";
import { SelectBody } from "../Select/styled";

interface CitySelectorProps {
    defaultValue?: string;
    countryCode?: string;
    stateCode?: string;
    onChangeState: (value?: City) => void;
    onCreateValue: (value: string) => void;
    hideTitle?: boolean;
    small?: boolean;
    title?: string;
    disabled?: boolean;
    w100?: boolean;
}

export function CitySelector(props: CitySelectorProps) {
    const [queryKey, setQueryKey] = useState(() => {
        return ["query_cities", props.stateCode];
    });

    const allowSearchRef = useRef(false);
    const queryClient = useQueryClient();

    const getCities = () => {
        return getCitiesByCountryAndState(props.countryCode ?? "CO", props.stateCode ?? "CO");
    };

    const cityQuery = useQuery(queryKey, () => getCities(), {
        refetchOnWindowFocus: false,
        enabled: allowSearchRef.current && props.stateCode !== undefined,
    });

    useEffect(() => {
        if (!props.stateCode) {
            cityQuery.data = [];
            return;
        }
        var _queryKey = ["query_cities", props.stateCode];
        setQueryKey(_queryKey);
        allowSearchRef.current = false;
        cityQuery.data = queryClient.getQueryData<City[]>(_queryKey);
        if (!cityQuery.data || cityQuery.data.length === 0) {
            allowSearchRef.current = true;
            cityQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stateCode]);

    const getOption = (item: City) => {
        let option: SelectOptionObject<City> = {
            label: `${item.name}`,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    const onCreateOption = (optionName: string) => {
        props.onCreateValue(optionName);
    };

    const getOptionFromValue = (value: string) => {
        let state: City = {
            id: 0,
            name: value,
            latitude: "",
            longitude: "",
        };
        let option: SelectOptionObject<City> = {
            label: value,
            value: value,
            object: state,
        };
        return option;
    };

    return (
        <SelectBody className={props.w100 ? "w100" : ""}>
            {!props.hideTitle && <span className="label">{props.title ?? "Seleccionar ciudad"}</span>}
            <CreatableSelect
                placeholder={"Seleccionar"}
                className={`${props.defaultValue ? "item-active" : ""}`}
                classNamePrefix="select"
                key={props.defaultValue}
                defaultValue={props.defaultValue ? getOptionFromValue(props.defaultValue) : undefined}
                isDisabled={props.disabled}
                onChange={(value) => props.onChangeState(value?.object)}
                onCreateOption={onCreateOption}
                isLoading={cityQuery.isFetching}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="supplier"
                options={cityQuery.data?.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
