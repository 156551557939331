import {
    ECOMMERCE_ICONS_FLAT,
    ECOMMERCE_ICONS_LINEAR_COLOR,
    ECOMMERCE_ICONS_TRITONE,
    ECOMMERCE_ICONS_V2_FLAT,
    ECOMMERCE_ICONS_V2_LINEAR_COLOR,
} from "./icons";

export type EcommerceIconType = "flat" | "tritone" | "linear" | "flatV2" | "linearV2";

export const ecommerceIcons = {
    flat: ECOMMERCE_ICONS_FLAT,
    tritone: ECOMMERCE_ICONS_TRITONE,
    linear: ECOMMERCE_ICONS_LINEAR_COLOR,
    flatV2: ECOMMERCE_ICONS_V2_FLAT,
    linearV2: ECOMMERCE_ICONS_V2_LINEAR_COLOR,
};
