import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { getServiceMessageError } from "../../../api";
import { createCashRegisterLog, getCashRegisterLogActive } from "../../../api/account/cashRegisterApi";
import { ICashRegister } from "../../../api/models/cashRegister";
import { CashRegisterLogCreateInput } from "../../../api/models/cashRegisterLog";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { formatMoney, getDateFromNow, getModelFullName } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import CardIcon from "../../_Cards/CardIcon";
import { Flex, PrimaryButton, TextField } from "../../_controls";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { CashRegisterManualEntryButton } from "../CashRegisterEntries/CashRegisterEntryForm";
import { CashRegisterMovementButton } from "../CashRegisterMovement";
import CashRegisterOrganizationSelect from "../CashRegisterSelect/CashRegisterOrganizationSelect";
import messages from "./messages";
import { CreateRegisterLogView } from "./styled";
import GroupButtonRow from "../../_controls/buttons/GroupButtonRow";

interface CreateRegisterLogState {
    openConfirmationModal: boolean;
}

export interface CreateRegisterLogProps {
    hideHomeLink?: boolean;
    blockScreen?: () => void;
}

export default function CreateRegisterLog(props: CreateRegisterLogProps) {
    const intl = useIntl();
    const alert = useAlert();

    const [onlyCashMethod, setOnlyCashMethod] = useState(true);

    const { cashRegisterState, cashRegisterActions } = useContext(CashRegisterContext);
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const [state, setState] = useState<CreateRegisterLogState>({
        openConfirmationModal: false,
    });

    const [selectedCashRegister, setSelectedCashRegister] = useState<ICashRegister>();
    const logActiveMutation = useMutation((cashRegisterId: string) => getCashRegisterLogActive(cashRegisterId));
    const createLogMutation = useMutation((data: CashRegisterLogCreateInput) => createCashRegisterLog(data), {
        onSuccess: (data) => {
            alert.success(intl.formatMessage(messages.successfulCashOpening));
            cashRegisterActions.set({ ...cashRegisterState, logActive: data });
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        if (selectedCashRegister) {
            const cashRegister = cashRegisterState.orgItems.find((x) => x.id === selectedCashRegister?.id);
            if (cashRegister) setCurrentCashRegister(cashRegister);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.orgItems]);

    const setCurrentCashRegister = (cashRegister: ICashRegister) => {
        setSelectedCashRegister(cashRegister);
        if (cashRegister.id) logActiveMutation.mutate(cashRegister.id);
        cashRegisterActions.setCurrentCashRegister(cashRegister);
    };

    const handleSetActiveLog = () => {
        if (selectedCashRegister && logActiveMutation.data) {
            cashRegisterActions.set({ ...cashRegisterState, currentCashRegister: selectedCashRegister, logActive: logActiveMutation.data });
            appSettingsActions.setAllSettings({ ...appSettingsState, activeLogCashRegisterId: selectedCashRegister.id ?? "" });
        }
    };

    const handleCreateLog = () => {
        let body: CashRegisterLogCreateInput = {
            cashRegisterId: selectedCashRegister?.id ?? "",
            accountUserId: getAuthBody().accountUserId,
        };
        setState({ ...state, openConfirmationModal: false });
        createLogMutation.mutate(body);
        appSettingsActions.setAllSettings({ ...appSettingsState, activeLogCashRegisterId: selectedCashRegister?.id ?? "" });
    };

    const startCashRegister = () => {
        if (!selectedCashRegister) {
            alert.error("Es necesario seleccionar una caja");
            return;
        }
        setState({ ...state, openConfirmationModal: true });
    };

    return (
        <CreateRegisterLogView>
            {state.openConfirmationModal && (
                <ConfirmationModal
                    onCancel={() => setState({ ...state, openConfirmationModal: false })}
                    onOkay={() => handleCreateLog()}
                    title={intl.formatMessage(messages.confirmCashOpening)}
                    description={intl.formatMessage(messages.cashEnabledAmount)}
                    show={state.openConfirmationModal}
                    setShow={() => setState({ ...state, openConfirmationModal: false })}
                />
            )}

            <h3 className="header-title">Iniciar Caja</h3>

            <GroupButtonRow
                activeOption={onlyCashMethod}
                onClickOption={(option) => setOnlyCashMethod(option.value)}
                options={[
                    { title: "Solo Cajas Efectivo", value: true },
                    { title: "Todas", value: false },
                ]}
            />

            <CashRegisterOrganizationSelect
                title={"Seleccionar Caja"}
                typeFilter={onlyCashMethod ? "cash" : undefined}
                defaultCashRegisterId={selectedCashRegister?.id}
                onCashRegisterChange={(value) => {
                    setCurrentCashRegister(value);
                    if (props.blockScreen) props.blockScreen();
                }}
            />
            {logActiveMutation.isLoading && <LoadingDualRing center />}
            {!logActiveMutation.isLoading && selectedCashRegister && logActiveMutation.data && (
                <div className="cash-register-card">
                    <Flex gap10 alignCenter>
                        <i className="fa-regular fa-lock"></i>
                        <Flex column>
                            <span>{selectedCashRegister.name}</span>
                            <TextField small light>
                                Registro de Caja #{logActiveMutation.data.number}
                            </TextField>
                        </Flex>
                    </Flex>
                    <Flex gap={20}>
                        <CardIcon title={getDateFromNow(logActiveMutation.data.dateStart)} smallTitle="Activado" className="mr-1" />
                        <CardIcon title={getModelFullName(logActiveMutation.data.creatorUser)} smallTitle="Por" />
                    </Flex>
                    <PrimaryButton rounded onClick={() => handleSetActiveLog()}>
                        <span className="wahioicon-play-circle"></span> Continuar
                    </PrimaryButton>
                </div>
            )}
            {!logActiveMutation.isLoading && selectedCashRegister && !logActiveMutation.data && (
                <div className="cash-register-card">
                    <span className="header-title">{selectedCashRegister.name}</span>
                    <CardIcon title={formatMoney(selectedCashRegister.amount)} smallTitle="Saldo Inicial" />
                    <TextField small light>
                        Para cambiar el saldo inicial debes hacer un traslado
                    </TextField>
                    <div className="flex-center">
                        <div className="mr-1">
                            <CashRegisterMovementButton
                                defaultOrigin={selectedCashRegister}
                                onCreate={(value) => cashRegisterActions.refetch()}
                                onCancel={() => {}}
                            />
                        </div>
                        <CashRegisterManualEntryButton
                            defaultCashRegister={selectedCashRegister}
                            onCreate={(value) => cashRegisterActions.refetch()}
                            onCancel={() => {}}
                        />
                    </div>
                </div>
            )}

            {createLogMutation.isLoading && <LoadingDualRing center />}
            <Flex spaceBetween gap10 alignCenter>
                {!props.hideHomeLink && (
                    <NavLink to="/" className="flex-center">
                        <span className="wahioicon-arrow-left mr-1"></span> Home
                    </NavLink>
                )}
                {!logActiveMutation.data && selectedCashRegister && (
                    <PrimaryButton rounded onClick={() => startCashRegister()}>
                        <i className="fa-regular fa-play"></i> {intl.formatMessage(messages.starCash)}
                    </PrimaryButton>
                )}
            </Flex>
        </CreateRegisterLogView>
    );
}
