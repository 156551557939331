import { Dispatch } from "react";

import { PurchaseTypes } from "../../api/models/purchase";

export const applyMiddleware = (dispatch: Dispatch<PurchaseTypes>) => (action: PurchaseTypes) => {
    switch (action.type) {
        default:
            dispatch(action);
    }
};
