import { IWarehouseMovementEntrySearchModel } from "../../components/Warehouses/WarehouseMovementList";
import wahioFetchApi from "../fetchApi";
import { PaginationResponse } from "../models";
import { IWarehouse, WarehouseCostSummary } from "../models/warehouse";
import { IWarehouseMovement } from "../models/warehouseMovement";
import { accountEndpoint, productEndpoint } from "../restApiEndpoint";

export const getWarehouse = (warehouseId: string): Promise<IWarehouse> => {
    return wahioFetchApi.get(productEndpoint.get.warehouseId(warehouseId)).then((res: any) => res.data);
};

export const getWarehousesByOrganization = (organizationId: string): Promise<IWarehouse[]> => {
    return wahioFetchApi.get(accountEndpoint.get.warehouseByOrganization(organizationId)).then((res: any) => res.data);
};

export const getWarehousesByAccount = (accountId: string): Promise<IWarehouse[]> => {
    return wahioFetchApi.get(productEndpoint.get.warehousesByAccount(accountId)).then((res: any) => res.data);
};

export const getWareHouseMovements = (body: IWarehouseMovementEntrySearchModel): Promise<PaginationResponse<IWarehouseMovement>> => {
    return wahioFetchApi.post(productEndpoint.post.warehouseMovementQuerysearch, body).then((res: any) => res.data);
};

export const getWarehouseCostSummary = (id: string): Promise<WarehouseCostSummary> => {
    return wahioFetchApi.get(productEndpoint.get.warehouseCostSummary(id)).then((res: any) => res.data);
};

export const getWarehouseMovement = (id: string): Promise<IWarehouseMovement> => {
    return wahioFetchApi.get(productEndpoint.get.warehouseMovement(id)).then((res: any) => res.data);
};

export const updateWarehouseMovementDetails = (data: IWarehouseMovement): Promise<IWarehouseMovement> => {
    return wahioFetchApi.put(productEndpoint.put.warehouseMovementDetails, data).then((res: any) => res.data?.value);
};
