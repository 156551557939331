import React, { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useEventListenerWindow } from "../..";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { FlexItemsView } from "../../Orders/CreateOrderForm/styled";
import { CardsContainer, TableCustomOrder } from "../../Orders/OrderStyles/styled";
import { Flex, SquareButton, TextField } from "../../_controls";
import { TableCustomTr } from "../../_controls/tables/styled";
import { PurchaseItemActionsProps, PurchaseItemFormRow } from "../PurchaseItemRow";
import { ApplyToAllProductsPurchase } from "./ApplyAllProductsPurchase";
import PurchaseCreatePreview from "./PurchaseCreatePreview";
import messages from "./messages";

export interface OrderSummaryProps extends PurchaseItemActionsProps {
    items: IPurchaseItem[];
    forceMobileView?: boolean;
    isMobile: boolean;
    onUpdatePurchase: (value: IPurchase) => void;
    onUpdateItems: (value: IPurchaseItem[]) => void;
    purchaseCreateResult?: IPurchase;
}

interface OrderSummaryState {
    position: {
        top: number;
        width: number;
    };
    lastQuantity: number;
}

const PANEL_SUMMARY_ID = "panel-summary-purchase";

export default function OrderSummary(props: OrderSummaryProps) {
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const panelRef = useRef<any>();
    const timerRef = useRef<any>();
    const intl = useIntl();

    const [state, setState] = useState<OrderSummaryState>({
        position: {
            top: 1,
            width: window.innerWidth,
        },
        lastQuantity: 0,
    });

    const [mobileState, setMobileState] = useState({
        panelIsMobile: false,
        panelIsSmallMobile: false,
    });

    useEffect(() => {
        if (panelRef.current) {
            handleCalculateMobile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCalculateMobile = () => {
        if (panelRef.current) {
            let rect = panelRef.current.getBoundingClientRect();
            let newMobile = rect.width < 600;
            let smallMobile = rect.width < 425;
            setMobileState({ ...mobileState, panelIsMobile: newMobile, panelIsSmallMobile: smallMobile });
        }
    };

    const handleUpdateMobile = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => handleCalculateMobile(), 100);
    };

    useEventListenerWindow("resize", () => {
        handleUpdateMobile();
    });

    const onChangeItemsView = () => {
        appSettingsActions.setAllSettings({
            ...appSettingsState,
            orderSummaryDesktopSimpleView: !appSettingsState.orderSummaryDesktopSimpleView,
        });
    };

    useEffect(() => {
        if (props.items.length > state.lastQuantity) {
            let element = document.getElementById(PANEL_SUMMARY_ID);
            if (element) {
                element.scrollTop += 1500;
            }
            setState({ ...state, lastQuantity: props.items.length });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items]);

    let requireMobile = mobileState.panelIsMobile;
    if (appSettingsState.orderSummaryDesktopSimpleView || props.isMobile) {
        requireMobile = true;
    }

    return (
        <CardsContainer className="summary-container" ref={panelRef}>
            {props.purchaseCreateResult && <PurchaseCreatePreview purchaseResult={props.purchaseCreateResult} />}
            {!props.isMobile && (
                <Flex paddingLeft={20} paddingRight={20} paddingTop={15} alignCenter gap15 spaceBetween>
                    <TextField bold fontSize={17}>
                        Detalles
                    </TextField>
                    <Flex gap10 alignCenter>
                        {props.items.length > 1 && (
                            <ApplyToAllProductsPurchase currentPurchase={props.purchase} onUpdateAllItem={props.onUpdateItems} />
                        )}
                        {!mobileState.panelIsMobile && props.items.length > 0 && (
                            <FlexItemsView>
                                <SquareButton
                                    colorText
                                    onClick={onChangeItemsView}
                                    className={!appSettingsState.orderSummaryDesktopSimpleView ? "active" : ""}
                                >
                                    <span className="fa-regular fa-list"></span>
                                </SquareButton>
                                <SquareButton
                                    colorText
                                    onClick={onChangeItemsView}
                                    className={appSettingsState.orderSummaryDesktopSimpleView ? "active" : ""}
                                >
                                    <span className="fa-regular fa-grid-2"></span>
                                </SquareButton>
                            </FlexItemsView>
                        )}
                    </Flex>
                </Flex>
            )}
            {props.items.length <= 0 && (
                <Flex alignCenter justifyCenter w100 marginTop={10}>
                    <TextField light>{intl.formatMessage(messages.noProductSelected)}</TextField>
                </Flex>
            )}
            {requireMobile && (
                <Flex paddingTop={10} column>
                    {props.items.map((item, index) => {
                        return (
                            <PurchaseItemFormRow
                                {...props}
                                isMobile={true}
                                purchaseItem={item}
                                key={index}
                                smallMobile={mobileState.panelIsSmallMobile}
                            />
                        );
                    })}
                </Flex>
            )}
            {!requireMobile && props.items.length > 0 && (
                <TableCustomOrder cellSpacing="0" cellPadding="0">
                    <thead>
                        <TableCustomTr>
                            <th></th>
                            <th></th>
                            <th>Nombre</th>
                            <th>Cant</th>
                            <th>Precio</th>
                            <th>Total</th>
                        </TableCustomTr>
                    </thead>
                    <tbody>
                        {props.items.map((item, index) => (
                            <PurchaseItemFormRow {...props} purchaseItem={item} key={index} smallMobile={mobileState.panelIsSmallMobile} />
                        ))}
                    </tbody>
                </TableCustomOrder>
            )}
        </CardsContainer>
    );
}
