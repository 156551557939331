import { defineMessages } from "react-intl";

const base = "components.warehouseProfile";

export default defineMessages({
    basePrice: {
        id: `${base}.basePrice`,
        defaultMessage: "Costo Base",
    },
    costAverage: {
        id: `${base}.costAverage`,
        defaultMessage: "Promedio",
    },
    costWeightedAverage: {
        id: `${base}.costWeightedAverage`,
        defaultMessage: "Ponderado",
    },
    costLastPurchase: {
        id: `${base}.costLastPurchase`,
        defaultMessage: "Ultimo",
    },
    costBaseAverage: {
        id: `${base}.costBaseAverage`,
        defaultMessage: "Promedio",
    },
    costBaseWeightedAverage: {
        id: `${base}.costBaseWeightedAverage`,
        defaultMessage: "Ponderado",
    },
    costBaseLastPurchase: {
        id: `${base}.costBaseLastPurchase`,
        defaultMessage: "Ultimo",
    },
});
