import styled from "styled-components";
import { DefaultInput } from "../_controls";

export const Container = styled.div`
    position: relative;
`;
export const ToolTip = styled.div`
    position: absolute;
    text-align: center;
    left: 0;
    color: var(--text-primary-2);
    font-size: 14px;
    font-weight: 500;
    top: -64%;
    background: var(--bg-primary);
    border-radius: 40px;
    padding: 2px 9px;
    box-shadow: 0px 2px 10px var(--color-primary-shadow);
`;

interface InputCustomProps {
    rounded?: boolean;
    w100?: boolean;
    bgLight?: boolean;
}

export const InputCustom = styled(DefaultInput)`
    color: ${(props) => props.theme.palette.text};
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    outline: none;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    width: 80px;
    border-radius: 4px;
    box-sizing: border-box;

    ${(props: InputCustomProps) => (props.rounded ? "border-radius: 50px;" : "")}
    ${(props: InputCustomProps) => (props.w100 ? "width: 100%;" : "")}
    ${(props: InputCustomProps) => (props.bgLight ? "" : "background: transparent;")}

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    &:focus {
        border-color: ${(props) => props.theme.palette.backgroundDark};
    }
`;
