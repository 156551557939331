import styled from "styled-components";

export const FinalizeContainer = styled.div`
    padding: 30px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .light {
        color: ${(props) => props.theme.palette.textLight};
    }

    .small {
        font-size: 13px;
    }

    .min-width {
        width: 1%;
        padding-right: 7px;
    }

    .text-warning {
        color: #ff9800;
        display: flex;
        align-items: center;
        margin: 11px 0px;
        font-weight: 400;
        &.danger {
            color: ${(props) => props.theme.palette.dangerColor};
        }
    }

    .btn-add-payment {
        border-radius: 50px;
    }
    .btn-extra {
        border-radius: 50px;
        padding: 0.3rem 13px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .subtotal {
        font-size: 13px;
        text-align: right;
        width: 100%;
        display: block;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const TotalAmountCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;

    color: ${(props) => props.theme.palette.text};
    .title-amount {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const NotificationCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: baseline;
    transition: padding 0.3s ease-in-out;

    .btn-notification {
        background: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    &.notification-active {
        .btn-notification {
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const NotificationBody = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .margin-top {
        margin-top: 10px;
    }
    .notification-icon {
        color: ${(props) => props.theme.palette.primary};
    }
    .notification-item {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
        position: relative;

        .disabled-panel {
            position: absolute;
            height: 100%;

            z-index: 1;
            width: 100%;
            display: flex;
            align-items: center;
            .text {
                color: ${(props) => props.theme.palette.textLight};
                font-size: 13px;
            }
        }

        .notification-input {
            background: ${(props) => props.theme.palette.backgroundDark};
            height: 31px;
            width: 100%;
            border-radius: 50px;
            background: transparent;
            border: 0px solid ${(props) => props.theme.palette.backgroundDark};
            font-weight: 500;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.palette.backgroundLight};
            }
        }
        .icon {
            width: 25px;
            height: 25px;
            min-width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            z-index: 2;

            &.active {
                background: ${(props) => props.theme.palette.primary}20;
                color: ${(props) => props.theme.palette.primary};
            }
        }
    }

    .select__control {
        border: 0px solid ${(props) => props.theme.palette.backgroundDark};
        min-width: auto;
        min-height: 20px;
        height: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .select__single-value {
            width: fit-content;
            padding-right: 2px;
        }

        .select__value-container {
            margin-bottom: 2px;
        }
    }
    .phone-group {
        display: flex;
        align-items: center;
        border: 0px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 50px;
        width: 100%;
        height: 31px;

        .dropdown-btn {
            border-radius: 0px;
            background: transparent;
            border: 0;
            border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        }

        .notification-input {
            border-radius: 0px;
            border: 0px;
            width: 100%;
            height: 100%;
            padding-left: 4px;
        }
    }
`;

export const PaymentTermView = styled.div`
    display: flex;
    flex-direction: column;
    .payment-title {
        font-size: 13px;
        margin-bottom: 3px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .date {
        display: flex;
        flex-direction: column;
        .light {
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    .input-group {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 30px;
    }

    [class^="wahioicon-"] {
        color: ${(props) => props.theme.palette.dangerColor};
        position: absolute;
        left: 7px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 50px;
        margin-right: 10px;
        justify-content: center;
    }
    input {
        padding: 10px;
        padding-left: 45px;
        width: 135px;
        margin-right: 15px;
    }
`;

export const AddChangeView = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    padding-bottom: 20px;
    gap: 10px;
    flex-wrap: wrap;

    .change-card {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        border-radius: 50px;
        border: 0px solid ${(props) => props.theme.palette.backgroundDark};
        color: #009688;
        font-size: 14px;
    }

    .change-value {
        font-weight: 500;
    }
    .negative {
        color: #f44336;
    }
    input {
        border-radius: 50px;
        padding-left: 15px;
        font-weight: 500;
    }
    .suggestion {
        padding: 3px 8px;
        border-radius: 50px;
        font-size: 13px;
        font-weight: 400;
        background: transparent;
        color: ${(props) => props.theme.palette.textLight};
        border: transparent;
    }

    .suggestionbody {
        border-radius: 23px;
        padding: 5px 15px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const ActionsButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;

    button {
        width: 100%;
    }
    .cancel {
        background: ${(props) => props.theme.palette.backgroundLight};
        border-color: transparent;
        color: ${(props) => props.theme.palette.textLight};
    }
    .btn-primary {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        border-color: transparent;
    }
    .btn-warning {
        background: #ff9800;
        color: white;
        border-color: transparent;
    }
    .btn-danger {
        background: #f44336;
        color: white;
        border-color: transparent;
    }
`;

export const PaymentMethodsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

    button {
        padding: 8px;
        width: 100%;
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.text};
        justify-content: flex-start;
        padding-left: 16px;
        border: 0;
        font-weight: 400;
    }
`;

export const ExtraPaymentRow = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    padding-right: 10px;

    input {
        width: 100%;
        border: 0px;
        background: transparent;
        border-radius: 0px;
        padding: 7px;
        font-weight: 500;
        text-align: right;
    }
    .row-title {
        width: 250px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const SuccessContainer = styled.div`
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        font-size: 3rem;
    }
    p,
    h2 {
        margin: 0;
    }

    h2 {
        margin-top: 2rem;
    }
    p {
        margin-bottom: 14px;
    }
`;

export const ContainerProductsOutOfStock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 31px 25px;

    .header {
        .title {
            margin: 0;
            margin-top: 5px;
            margin-bottom: 4px;
            font-weight: 500;
        }
        p {
            margin: 0px;
        }
    }

    .image {
        width: 50px;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 10px;
        img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
        }
    }

    .notAvailable {
        color: ${(props) => props.theme.palette.dangerColor};
    }
`;
