import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { putCashRegisterEntry } from "../../../api/account/cashRegisterApi";
import { METHOD_ICONS, PaymentMethod, WahioFile } from "../../../api/models";
import { ICashRegisterEntry } from "../../../api/models/cashRegister";
import { getOrder } from "../../../api/orders/orderApi";
import { getPurchase } from "../../../api/purchases/purchaseApi";
import { checkAndGetMessage } from "../../../i18n/helper";
import globalMessages from "../../../messages";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { useCashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { capitalize, formatMoney, getDateFormat, getModelFullName } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import OrderProfileModal from "../../Orders/OrderProfile/OrderProfileModal";
import PurchaseProfileModal from "../../Purchases/PurchaseProfile/PurchaseProfileModal";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import TooltipRadix from "../../_controls/WahioTooltip/TooltipRadix";
import { TableCustom, TableCustomTr } from "../../_controls/tables/styled";
import Modal, { IModalShow } from "../../modals/Modal";
import { CashRegisterEntryBase, EntryIconCard } from "./CashRegisterEntryCard";
import { CashRegisterManualEntryModal } from "./CashRegisterEntryForm";
import { NotificationPaymentReceiptButtons } from "./PaymentReceipt/NotificationPaymentReceipt";
import { PaymentReceiptPrint } from "./PaymentReceipt/PaymentReceiptPrint";
import { CashEntryContent, EntryMethod } from "./styled";

interface CashEntryCardModalContentProps extends CashRegisterEntryBase {
    onEntryChange: (entry: ICashRegisterEntry) => void;
    restoreEntry: () => void;
    entryType: string;
}

interface CashRegisterEntryProfileModalProps extends IModalShow, CashEntryCardModalContentProps {}

export const CashRegisterEntryProfileModal = (props: CashRegisterEntryProfileModalProps) => {
    const { show, setShow, entry, onEntryChange, entryType } = props;
    return (
        <>
            {show && (
                <Modal show={show} setShow={setShow} useButtonClose>
                    <CashRegisterEntryProfile
                        entry={entry}
                        icon={props.icon}
                        restoreEntry={() => onEntryChange(props.entry)}
                        onEntryChange={onEntryChange}
                        entryType={entryType}
                    />
                </Modal>
            )}
        </>
    );
};

export default function CashRegisterEntryProfile(props: CashEntryCardModalContentProps) {
    const { entryType } = props;

    const [entry, setEntry] = useState(props.entry);

    useEffect(() => {
        setEntry(props.entry);
    }, [props.entry]);

    const { organizationState } = useContext(OrganizationContext);
    const { getUserById } = useAccountUserContext();
    const [showChanges, setShowChanges] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const alert = useAlert();

    const { getCashRegister } = useCashRegisterContext();

    const updateMutation = useMutation((data: ICashRegisterEntry) => putCashRegisterEntry(data), {
        onSuccess: (data) => {
            alert.success("Registro Actualizado con éxito");
            props.onEntryChange({ ...entry, dateCreated: data.dateCreated });
            setShowChanges(false);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const updateFiles = (files: WahioFile[]) => {
        setEntry({ ...entry, files });
        setShowChanges(true);
    };

    const handleUpdate = () => {
        const newEntry = { ...entry };
        if (newEntry.customDateCreated) {
            newEntry.customDateCreated = customMoment(entry.customDateCreated).utc().format();
        }
        updateMutation.mutate(newEntry);
    };

    let entryAmount = entry.amount;
    if (!entry.isIncome) {
        entryAmount *= -1;
    }

    const allowEdit = !entry.referenceId;

    return (
        <Flex column gap={20} padding={25}>
            {showEditModal && (
                <CashRegisterManualEntryModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    cashRegisterEntry={entry}
                    onCreate={(value) => setEntry(value)}
                    onCancel={() => setShowEditModal(false)}
                />
            )}
            <Flex column>
                <TextField bold>Pago #{entry.number}</TextField>
                <TextField small light>
                    {getDateFormat(entry.dateCreated, "DD MMM YYYY hh:mm:a")}
                </TextField>
            </Flex>
            <Flex alignCenter spaceBetween>
                <Flex alignCenter gap15>
                    <EntryIconCard {...props} />
                    <Flex column>
                        <TextField fontSize={25} bold>
                            {formatMoney(entryAmount)}
                        </TextField>
                    </Flex>
                </Flex>
                <Flex gap10 alignCenter>
                    <NotificationPaymentReceiptButtons entry={entry} currentOrganization={organizationState.currentOrganization!} />
                    {allowEdit && (
                        <TooltipRadix
                            title="Editar"
                            delay={0}
                            trigger={
                                <SquareButton onClick={() => setShowEditModal(true)} rounded bgLight>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </SquareButton>
                            }
                        />
                    )}
                    <PaymentReceiptPrint entry={entry} />
                </Flex>
            </Flex>

            <CashEntryContent>
                <TableCustom className="width-auto" cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <TableCustomTr className="small hide-border">
                            <td className="text-light">Método</td>
                            <td>
                                <EntryMethodCard method={entry.method} />
                            </td>
                        </TableCustomTr>
                        {entry.contact && (
                            <TableCustomTr className="small hide-border">
                                <td className="text-light">Contacto</td>
                                <td>{getModelFullName(entry.contact)}</td>
                            </TableCustomTr>
                        )}
                        <TableCustomTr className="small hide-border">
                            <td className="text-light">Caja</td>
                            <td>{entry.cashRegisterName ?? getCashRegister(entry.cashRegisterId)?.name}</td>
                        </TableCustomTr>
                        <TableCustomTr className="small hide-border">
                            <td className="text-light">Fecha</td>
                            <td>{getDateFormat(entry.dateCreated, "DD MMM YYYY hh:mm:A")}</td>
                        </TableCustomTr>

                        <TableCustomTr className="small hide-border">
                            <td className="text-light">Categoría</td>
                            <td>{entryType}</td>
                        </TableCustomTr>
                        <TableCustomTr className="small hide-border">
                            <td className="text-light">Responsable</td>
                            <td>{getModelFullName(getUserById(entry.accountUserId))}</td>
                        </TableCustomTr>
                        {entry.description && (
                            <TableCustomTr className="small hide-border">
                                <td className="text-light">Descripción</td>
                                <td>{entry.description}</td>
                            </TableCustomTr>
                        )}
                        {entry.referenceId && (
                            <TableCustomTr className="small hide-border">
                                <td className="text-light">Referencia</td>
                                <td>
                                    <ReferenceButton entry={entry} />
                                </td>
                            </TableCustomTr>
                        )}

                        {entry.items && entry.items.length > 0 && (
                            <>
                                <TableCustomTr className="small">
                                    <td className="text-light"></td>
                                    <td></td>
                                </TableCustomTr>
                                <TableCustomTr className="small hide-border">
                                    <td className="text-light"></td>
                                    <td></td>
                                </TableCustomTr>
                            </>
                        )}

                        {entry.items?.map((item, index) => {
                            return (
                                <TableCustomTr key={index} className="small hide-border">
                                    <td className="text-light">{capitalize(item.type)}</td>
                                    <td>{formatMoney(item.amount)}</td>
                                </TableCustomTr>
                            );
                        })}
                    </tbody>
                </TableCustom>

                <Flex spaceBetween alignCenter marginTop={15}>
                    <span></span>
                    {showChanges && (
                        <Flex gap10 alignCenter>
                            <DefaultButton
                                onClick={() => {
                                    setShowEditModal(false);
                                    props.restoreEntry();
                                }}
                                disabled={updateMutation.isLoading}
                                borderRadius={10}
                                bgLight
                            >
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton disabled={updateMutation.isLoading} onClick={handleUpdate} borderRadius={10}>
                                Guardar {updateMutation.isLoading && <LoadingDualRing small />}
                            </PrimaryButton>
                        </Flex>
                    )}
                </Flex>
            </CashEntryContent>
            <Flex gap10 flexWrap>
                <FilesViewer
                    smallCard
                    showAttachButton
                    cardMaxHeight={110}
                    cardMaxWidth={110}
                    onUpdateFiles={updateFiles}
                    files={entry.files ?? []}
                />
            </Flex>
        </Flex>
    );
}

export const EntryMethodCard = ({ method }: { method: PaymentMethod }) => {
    const intl = useIntl();
    const icon = (METHOD_ICONS as any)[method];

    return (
        <EntryMethod className={method}>
            {icon && <span className={`${icon} method-icon`}></span>}
            <span>{checkAndGetMessage(intl, globalMessages, method)}</span>
        </EntryMethod>
    );
};

interface ReferenceRowProps {
    entry: ICashRegisterEntry;
}

const ReferenceButton = (props: ReferenceRowProps) => {
    const { entry } = props;
    const [loaded, setLoaded] = useState(false);
    const orderMutation = useMutation((id: string) => getOrder(id));
    const purchaseMutation = useMutation((id: string) => getPurchase(id));

    const [showDocument, setShowDocument] = useState(false);

    const handleFetchDocumentReference = () => {
        if (loaded) return;

        setLoaded(true);
        if (entry.referenceId) {
            if (entry.type === "sale" || entry.type === "order") {
                orderMutation.mutate(entry.referenceId);
            }
            if (entry.type === "purchase") {
                purchaseMutation.mutate(entry.referenceId);
            }
        }
    };

    const onClickShowDocument = () => {
        handleFetchDocumentReference();
        setShowDocument(true);
    };

    if (!entry.referenceId) {
        return null;
    }

    const isLoading = purchaseMutation.isLoading || orderMutation.isLoading;

    if (isLoading) return <LoadingDualRing small />;

    if (entry.type === "sale") {
        return (
            <>
                {showDocument && orderMutation.data && (
                    <OrderProfileModal show={showDocument} setShow={setShowDocument} order={orderMutation.data} />
                )}
                <DefaultButton primaryOutline hideBorder small disabled={isLoading} onClick={() => onClickShowDocument()} borderRadius={10}>
                    Orden #{entry.referenceNumber} {isLoading && <LoadingDualRing small />}
                </DefaultButton>
            </>
        );
    }

    if (entry.type === "purchase") {
        return (
            <>
                {showDocument && purchaseMutation.data && (
                    <PurchaseProfileModal show={showDocument} setShow={setShowDocument} purchase={purchaseMutation.data} />
                )}
                <DefaultButton primaryOutline small disabled={isLoading} onClick={() => onClickShowDocument()} borderRadius={10}>
                    Compra #{entry.referenceNumber} {isLoading && <LoadingDualRing small />}
                </DefaultButton>
            </>
        );
    }

    return null;
};
