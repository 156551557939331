import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { EventCalendar, EventSearchInput, EventSearchGroup } from "../models/eventCalendar";
import { marketingEndpoint } from "../restApiEndpoint";

export const createEventCalendar = (event: EventCalendar): Promise<EventCalendar> => {
    return wahioFetchApi.post(marketingEndpoint.post.eventCreate, event).then((res: any) => res.data);
};

export const eventSearchCalendar = (event: EventSearchInput): Promise<IPaginationResponse<EventCalendar>> => {
    return wahioFetchApi.post(marketingEndpoint.post.eventSearch, event).then((res: any) => res.data);
};

export const eventGroupSearchCalendar = (event: EventSearchInput): Promise<EventSearchGroup[]> => {
    return wahioFetchApi.post(marketingEndpoint.post.eventGroupByDate, event).then((res: any) => res.data);
};
