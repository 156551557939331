import styled from "styled-components";
import { TableCustomTr } from "../../_controls/tables/styled";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowImageContainer = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;

    margin-right: 0.8rem;
    border-radius: 4px;
    overflow: hidden;
    margin: 6px;

    &.big {
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
        margin-right: 15px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const TopViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 14px;

    .header-title {
        margin: 0px;
        margin-bottom: 8px;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    background: ${(props) => props.theme.palette.background};
`;

export const FlexCheckboxImage = styled.div`
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
`;
export const ContactLabelItem = styled.span`
    font-size: 13px;
    padding: 2px 5px;
    border-radius: 10px;
    color: ${(props) => props.theme.palette.textLight};
    background: ${(props) => props.theme.palette.backgroundLight};
`;

export const SellerCardContainers = styled.div`
    display: grid;
    width: 100%;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    .body {
        overflow: auto;
    }
`;

export const SellerCardContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 15px;    
    border-radius: 15px;
    width: auto;
    max-width: 180px;
    padding-right: 30px;
    background: ${(props) => props.theme.palette.backgroundLight};

    .card-body {
        overflow: hidden;
    }

    [class^="card-number"] {        
        min-width: 25px;
        height: 25px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 50%;
        background: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .card-number-1 {
        background: #F57F17;
        color: white;
    }
`;

export const CustomerRowTr = styled(TableCustomTr)`
    th,
    td {
        padding: 10px 4px;
    }

    .row-checkbox-container {
        display: none;
        &.row-selected {
            display: flex;
        }
    }
    .show-hover {
        visibility: hidden;
    }

    .row-image-container {
        display: flex;
    }

    &:hover {
        th,
        td {
            background: ${(props) => props.theme.palette.backgroundLight};
            cursor: pointer;
        }

        .row-image-container {
            display: none;
        }
        .row-checkbox-container {
            display: flex;
        }
        .show-hover {
            visibility: visible;
        }
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    .light a {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }

    .col {
        display: flex;
        flex-direction: column;
    }
`;
