import React, { useEffect } from "react";
import { OrderChangeProps } from ".";
import { useAppSettingsContext } from "../../../../appSettings/AppSettingsContext";
import { formatMoney } from "../../../../utils";
import Dropdown from "../../../Dropdown";
import TextBoxNumeral from "../../../TextBoxNumeral";
import { DefaultButton, Flex, TextField } from "../../../_controls";
import { InputTipContainer, TotalTableStyle } from "../styled";

interface PercentageListProps {
    value: number;
    onChangeValue: (value: number) => void;
    title: string;
}

const TIP_LIST = [0, 5, 10, 20, 30, 50];

export const PercentageList = (props: PercentageListProps) => {
    return (
        <Dropdown small icon="fa-regular fa-angle-down" contentHorizontal="left">
            <Flex column gap5 padding={20}>
                <TextField small light marginBottom={5}>
                    {props.title}
                </TextField>
                {TIP_LIST.map((item, index) => {
                    const active = item === props.value ? "active-line" : "";
                    return (
                        <DefaultButton key={index} className={`dropdown-button ${active}`} onClick={() => props.onChangeValue(item)}>
                            %{item}
                        </DefaultButton>
                    );
                })}
            </Flex>
        </Dropdown>
    );
};

const DiscountAndTipsRows = (props: OrderChangeProps) => {
    const { appSettingsState } = useAppSettingsContext();
    const orderSettings = appSettingsState.orderForm ?? {};
    const { order } = props;

    useEffect(() => {
        if (orderSettings.tipBeforeTax !== order.tipBeforeTax) {
            onExtraValueChange("tip", 0, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSettings.tipBeforeTax]);

    useEffect(() => {
        if (orderSettings.discountBeforeTax !== order.discountBeforeTax) {
            onExtraValueChange("discount", 0, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSettings.discountBeforeTax]);

    const onExtraValueChange = (type: "discount" | "tip", value: number, isPercentage?: boolean) => {
        let calculateValue = value;
        let calculatePer = value;

        let baseAmount = order.totalAmountItems;
        if ((type === "tip" && orderSettings.tipBeforeTax) || (type === "discount" && orderSettings.discountBeforeTax)) {
            baseAmount = order.totalAmountItems - order.totalTaxItems;
        }

        if (order.totalAmountItems === 0) return;
        if (isPercentage) {
            calculateValue = baseAmount * (value / 100);
        } else {
            calculatePer = (100 * value) / baseAmount;
        }

        if (type === "discount") {
            if (calculatePer > 100) {
                calculatePer = 100;
                calculateValue = baseAmount;
            }
            props.onOrderChange({ ...order, totalDiscount: calculateValue, percentageDiscount: calculatePer });
        } else {
            props.onOrderChange({ ...order, totalTip: calculateValue, percentageTip: calculatePer });
            if (value === 0) {
                window.localStorage.removeItem("LOCAL_TIP");
            } else {
                window.localStorage.setItem("LOCAL_TIP", value.toString());
            }
        }
    };

    return (
        <>
            {appSettingsState.orderForm?.showTotalDiscount && (
                <tr className="padding-5">
                    <td>Descuento</td>
                    <td>
                        <InputTipContainer className={order.totalDiscount > 0 ? "active" : ""}>
                            <TextBoxNumeral
                                value={order.totalDiscount}
                                format="money"
                                onNumberChange={(value) => onExtraValueChange("discount", value)}
                            />
                            <TextBoxNumeral
                                value={order.percentageDiscount}
                                format="percentage"
                                className="percentage"
                                onNumberChange={(value) => onExtraValueChange("discount", value, true)}
                            />
                            <PercentageList
                                title="Descuento"
                                value={order.percentageDiscount}
                                onChangeValue={(item) => onExtraValueChange("discount", item, true)}
                            />
                        </InputTipContainer>
                    </td>
                </tr>
            )}

            {appSettingsState.orderForm?.showTotalTip && (
                <tr className="padding-5">
                    <td>Propina</td>
                    <td>
                        <InputTipContainer className={order.totalTip > 0 ? "active" : ""}>
                            <TextBoxNumeral
                                value={order.totalTip}
                                format="money"
                                onNumberChange={(value) => onExtraValueChange("tip", value)}
                            />
                            <TextBoxNumeral
                                value={order.percentageTip}
                                format="percentage"
                                className="percentage"
                                onNumberChange={(value) => onExtraValueChange("tip", value, true)}
                            />
                            <PercentageList
                                title="Propina"
                                value={order.percentageTip}
                                onChangeValue={(item) => onExtraValueChange("tip", item, true)}
                            />
                        </InputTipContainer>
                    </td>
                </tr>
            )}
        </>
    );
};

const OrderSummaryTotals = (props: OrderChangeProps) => {
    const { order } = props;
    return (
        <TotalTableStyle tdPaddingRight={20} cellPadding={0} cellSpacing={0}>
            <tbody>
                <tr>
                    <td>Sub total</td>
                    <td>{formatMoney(order.subTotal)}</td>
                </tr>
                {order.totalManualPercentageDiscountItems > 0 && (
                    <tr>
                        <td>Detalles Desc</td>
                        <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                    </tr>
                )}
                <DiscountAndTipsRows {...props} />
                {order.totalDelivery > 0 && (
                    <tr>
                        <td>Envió</td>
                        <td>{formatMoney(order.totalDelivery)}</td>
                    </tr>
                )}
                {order.totalTaxItems > 0 && (
                    <tr>
                        <td>Impuestos</td>
                        <td>{formatMoney(order.totalTaxItems)}</td>
                    </tr>
                )}
                <tr>
                    <td>
                        <TextField bold fontSize={17}>
                            Total
                        </TextField>
                    </td>
                    <td>
                        <TextField bold fontSize={17}>
                            {formatMoney(props.order.totalAmount)}
                        </TextField>
                    </td>
                </tr>
            </tbody>
        </TotalTableStyle>
    );
};

export default OrderSummaryTotals;
