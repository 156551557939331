import React from "react";
import { useUserContext } from "../../../store/contexts/UserContext";
import { getDateFormat } from "../../../utils";
import Modal, { IModalShow } from "../../modals/Modal";
import { Flex } from "../../_controls";
import AccountBillingPlanForm, { AccountBillingPlanFormProps } from "./AccountBillingPlanForm";

interface AccountBillingConfigModalProps extends IModalShow, AccountBillingPlanFormProps {}

export default function AccountBillingConfigModal(props: AccountBillingConfigModalProps) {
    const { userState } = useUserContext();
    return (
        <Modal {...props} sizeType="md2" useMobileView useButtonClose>
            <Flex padding={20} column>
                <Flex column alignCenter gap5 className="mt-2">
                    <p className="m-0 text-light">Comienza a usar Wahio sin restricciones</p>
                    <h1 className="m-0 text-bold">Elige el plan que mejor se adapte a tu negocio</h1>
                    {userState.user?.account?.billing && userState.user?.account?.plan === "free" && (
                        <span>Tu prueba gratuita vence: {getDateFormat(userState.user.account.trialExpirationDate, 'DD MMM YYYY')}</span>
                    )}
                </Flex>
                <AccountBillingPlanForm {...props} />
            </Flex>
        </Modal>
    );
}
