import * as Popover from "@radix-ui/react-popover";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useIsMobileListener } from "../..";
import { getServiceMessageError } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { DeliveryStatus, IDeliveryOrder, IShippingLabel } from "../../../api/models/deliveryOrder";
import { Order } from "../../../api/models/order";
import { updateDeliveryOrder } from "../../../api/orders/deliveryOrder";
import { UpdateLabelInput, updateDeliveryOrderLabel } from "../../../api/orders/shipmentApi";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { ShipmentSettingsPanelButton } from "../../Account/ClientSettings/ShipmentSettingsPanel";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { ShippingLabelFormModal } from "../../Shipments/ShippingLabelForm";
import { getShippingLabelOrganization } from "../../Shipments/helper";
import { DefaultInput, Flex, PrimaryButton, TextField } from "../../_controls";
import { DefaultButton, SquareButton } from "../../_controls/buttons/index";
import ContainerCollapse from "../../_controls/containers/ContainerCollapse";
import { HoverCardContentStyle } from "../../_controls/PopoverRadix/hoverCardStyle";
import ConfirmationModal from "../../modals/ConfirmationModal";
import ShipmentProgress from "../Shipments/ShipmentProgress";
import { ShipmentStatusVertical } from "../Shipments/ShipmentProgressVertical";
import { useShipmentStatusList } from "../Shipments/hook";
import shipmentMessages from "../Shipments/messages";
import OrderLabelPrint from "./OrderLabelPrint";

interface ShipmentViewProps {
    order: Order;
    deliveryOrder: IDeliveryOrder;
    shipmentStatusFetching: boolean;
    handleUpdateShipmentStatus: (id: string, destinationStatus: DeliveryStatus, sourceStatus: DeliveryStatus) => void;
    onUpdateOrder?: (deliveryOrder: IDeliveryOrder) => void;
}

interface ShippingState {
    total: number;
    current: number;
    hasSerials: boolean;
    serialCompleted: boolean;
    requiredSerialValues: boolean;
    allowSerials: boolean;
    shippingLink: string;
    shippingNumber: string;
}

export default function ShipmentView(props: ShipmentViewProps) {
    const { deliveryOrder } = props;
    const intl = useIntl();
    const [showModalEditLabel, setShowModalEditLabel] = useState(false);
    const alert = useAlert();
    const [openHoverConfirm, setOpenHoverConfirm] = React.useState(false);
    const [openHoverConfig, setOpenHoverConfig] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const { organizationState } = useContext(OrganizationContext);
    const [moveNextStatus, setMoveNextStatus] = useState<DeliveryStatus>();

    const { userState } = useContext(UserContext);
    const allowLogisticService = !!props.order.allowLogisticsService;

    const { shipmentStatusState } = useClientSettings();
    const { getNextShipmentStatus, getStatusTitle } = useShipmentStatusList();

    const nextDeliveryStatus = React.useMemo(() => {
        return getNextShipmentStatus(deliveryOrder.status, allowLogisticService);
    }, [getNextShipmentStatus, deliveryOrder.status, allowLogisticService]);

    const isMobile = useIsMobileListener({ size: 750 });

    const [shippingState, setShippingState] = useState<ShippingState>({
        total: 0,
        current: 0,
        hasSerials: false,
        serialCompleted: false,
        requiredSerialValues: false,
        allowSerials: false,
        shippingLink: deliveryOrder.shippingTrackingLink ?? "",
        shippingNumber: deliveryOrder.shippingTrackingNumber ?? "",
    });

    useEffect(() => {
        const items = props.order.items?.filter((x) => x.product?.includesSerials);
        const totalSerials = items?.reduce((x, y) => x + y.quantity, 0);
        const fillSerials = items?.reduce((x, y) => x + (y.serials ?? []).filter((x) => !!x).length, 0);
        setShippingState({
            ...shippingState,
            total: totalSerials,
            current: fillSerials,
            hasSerials: totalSerials > 0,
            serialCompleted: totalSerials === fillSerials,
            allowSerials: !props.order.allowLogisticsService,
            shippingLink: props.order?.deliveryOrder?.shippingTrackingLink ?? "",
            shippingNumber: props.order?.deliveryOrder?.shippingTrackingNumber ?? "",
            requiredSerialValues:
                !props.order.allowLogisticsService &&
                deliveryOrder.status === "processing" &&
                totalSerials > 0 &&
                !(totalSerials === fillSerials),
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    const permission = getUserPermissions(userState.user).order;

    const onShippingLabelChange = (label: IShippingLabel) => {
        labelMutation.mutate({ shippingLabel: label, deliveryOrderId: deliveryOrder.id ?? "INVALID" });
    };

    const labelMutation = useMutation((input: UpdateLabelInput) => updateDeliveryOrderLabel(input), {
        onSuccess: (data) => {
            if (props.onUpdateOrder) {
                props.onUpdateOrder({ ...deliveryOrder, shippingLabel: data.shippingLabel });
            }
            alert.success("La etiqueta ha sido actualizada con éxito");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const NextStatus = () => {
        if (deliveryOrder.status === "delivered") return null;

        if (allowLogisticService) {
            var allowNextStatus =
                props.order.orderPicking?.status === "completed" ||
                (deliveryOrder.status !== "processing" && deliveryOrder.status !== "picking");
            if (!allowNextStatus) {
                return null;
            }
        }

        return (
            <PrimaryButton disabled={!permission?.update || props.shipmentStatusFetching} rounded={true}>
                <span className="fa-regular fa-arrow-right"></span> {getStatusTitle(nextDeliveryStatus)}
                {props.shipmentStatusFetching && <LoadingDualRing small />}
            </PrimaryButton>
        );
    };

    const blockStatusMove = shippingState.allowSerials && shippingState.requiredSerialValues;

    return (
        <ContainerCollapse>
            {showModalEditLabel && (
                <ShippingLabelFormModal
                    show={showModalEditLabel}
                    setShow={setShowModalEditLabel}
                    organizationReadOnly
                    order={props.order}
                    onCancel={() => setShowModalEditLabel(false)}
                    shippingLabel={getShippingLabelOrganization(deliveryOrder.shippingLabel, organizationState.currentOrganization)}
                    onUpdate={onShippingLabelChange}
                />
            )}

            {moveNextStatus && (
                <ConfirmationModal
                    show={!!moveNextStatus}
                    setShow={() => {
                        setMoveNextStatus(undefined);
                    }}
                    title={`Mover pedido a ${intl.formatMessage(shipmentMessages[moveNextStatus])}`}
                    description={
                        'El estado de la orden pasara de "' +
                        intl.formatMessage(shipmentMessages[deliveryOrder.status]) +
                        '" a "' +
                        intl.formatMessage(shipmentMessages[moveNextStatus]) +
                        '"'
                    }
                    onOkay={() => {
                        props.handleUpdateShipmentStatus(deliveryOrder.id ?? "", moveNextStatus, deliveryOrder.status);
                        setMoveNextStatus(undefined);
                    }}
                />
            )}
            <Flex alignCenter gap10 spaceBetween flexWrap>
                <Flex gap10 alignCenter>
                    <ShipmentSettingsPanelButton />
                    <TextField bold>Estado</TextField>
                </Flex>
                <Flex gap10 flexWrap alignCenter spaceBetween>
                    <Flex gap10 alignCenter>
                        {labelMutation.isLoading && <LoadingDualRing small />}
                        <OrderLabelPrint order={props.order} w100 />
                        <SquareButton
                            colorText
                            title="Editar Etiqueta del pedido"
                            w100
                            borderRadius={10}
                            bgLight
                            onClick={() => setShowModalEditLabel(true)}
                        >
                            <span className="fa-regular fa-pen-to-square"></span>
                        </SquareButton>

                        <Popover.Root
                            open={openHoverConfig}
                            onOpenChange={(value) => {
                                if (!value) setOpenHoverConfig(value);
                            }}
                        >
                            <Popover.Trigger asChild>
                                <SquareButton id="ignore" className="btn-card-popover" onClick={() => setOpenHoverConfig(true)}>
                                    <i className="fa-regular fa-barcode-read"></i>
                                </SquareButton>
                            </Popover.Trigger>

                            <Popover.Portal>
                                <HoverCardContentStyle>
                                    <Popover.Content
                                        side="left"
                                        align="start"
                                        className="HoverCardContent"
                                        style={{ width: "320px", padding: "20px" }}
                                        sideOffset={5}
                                    >
                                        <CardPopContent
                                            order={props.order}
                                            deliveryOrder={deliveryOrder}
                                            shippingState={shippingState}
                                            setShippingState={setShippingState}
                                            showModalEditLabel={showModalEditLabel}
                                            setShowModalEditLabel={(value) => {
                                                setShowModalEditLabel(value);
                                                setOpenHoverConfig(false);
                                            }}
                                        />
                                        <Popover.Arrow className="HoverCardArrow" />
                                    </Popover.Content>
                                </HoverCardContentStyle>
                            </Popover.Portal>
                        </Popover.Root>
                    </Flex>

                    {(!shippingState.allowSerials || !shippingState.requiredSerialValues) && (
                        <Popover.Root
                            open={openHoverConfirm}
                            onOpenChange={(value) => {
                                if (!value) setOpenHoverConfirm(value);
                            }}
                        >
                            <Popover.Trigger asChild>
                                <Flex onClick={() => setOpenHoverConfirm(true)}>
                                    <NextStatus />
                                </Flex>
                            </Popover.Trigger>

                            <Popover.Portal>
                                <HoverCardContentStyle>
                                    <Popover.Content
                                        side="bottom"
                                        align="end"
                                        className="HoverCardContent"
                                        style={{ width: "290px", padding: "20px" }}
                                        sideOffset={5}
                                    >
                                        <Flex column gap10>
                                            <Flex column gap5>
                                                <TextField bold>¿Estas seguro?</TextField>
                                                <TextField light small>
                                                    Confirmar movimiento
                                                </TextField>
                                            </Flex>

                                            <Flex gap10>
                                                <DefaultButton onClick={() => setOpenHoverConfirm(false)} borderRadius={10} w100 bgLight>
                                                    Cancelar
                                                </DefaultButton>
                                                <PrimaryButton
                                                    onClick={() => {
                                                        setOpenHoverConfirm(false);
                                                        props.handleUpdateShipmentStatus(
                                                            deliveryOrder.id ?? "",
                                                            nextDeliveryStatus,
                                                            deliveryOrder.status
                                                        );
                                                    }}
                                                    borderRadius={10}
                                                    w100
                                                >
                                                    Confirmar
                                                </PrimaryButton>
                                            </Flex>
                                        </Flex>
                                        <Popover.Arrow className="HoverCardArrow" />
                                    </Popover.Content>
                                </HoverCardContentStyle>
                            </Popover.Portal>
                        </Popover.Root>
                    )}
                </Flex>
            </Flex>

            {openChangeStatusModal && (
                <ConfirmationModal
                    onCancel={() => setOpenChangeStatusModal(false)}
                    onOkay={() => {
                        setOpenChangeStatusModal(false);
                        props.handleUpdateShipmentStatus(deliveryOrder.id ?? "", nextDeliveryStatus, deliveryOrder.status);
                    }}
                    title="¿Estás seguro de que quieres mover el envió al siguiente estado? "
                    description={`El estado de la orden pasara de ${intl.formatMessage(
                        shipmentMessages[deliveryOrder.status]
                    )} a ${intl.formatMessage(
                        shipmentMessages[nextDeliveryStatus]
                    )}, es posible que no puedas revertir el estado del envío.`}
                    show={openChangeStatusModal}
                    setShow={setOpenChangeStatusModal}
                />
            )}

            {!isMobile ? (
                <ShipmentProgress
                    order={props.order}
                    deliveryOrder={deliveryOrder}
                    onMoveStatus={(status) => (blockStatusMove ? undefined : setMoveNextStatus(status))}
                />
            ) : (
                <ShipmentStatusVertical
                    onMoveStatus={(status) => (blockStatusMove ? undefined : setMoveNextStatus(status))}
                    deliveryOrder={deliveryOrder}
                    allowLogisticsService={!!props.order.allowLogisticsService}
                />
            )}

            {!shipmentStatusState.statusLogisticActive[deliveryOrder.status] && (
                <TextField small light>
                    El estado actual del pedido <strong>'{intl.formatMessage(shipmentMessages[deliveryOrder.status])}'</strong> no es
                    visible
                </TextField>
            )}

            {blockStatusMove && (
                <TextField small light>
                    Debes completar los seriales para mover al siguiente estado
                </TextField>
            )}
        </ContainerCollapse>
    );
}

interface CardPopContentProps {
    order: Order;
    deliveryOrder: IDeliveryOrder;
    onUpdateOrder?: (deliveryOrder: IDeliveryOrder) => void;
    shippingState: ShippingState;
    setShippingState: (value: ShippingState) => void;
    showModalEditLabel: boolean;
    setShowModalEditLabel: (value: boolean) => void;
}

const CardPopContent = (props: CardPopContentProps) => {
    const { deliveryOrder, shippingState, setShippingState } = props;
    const alert = useAlert();

    const deliveryOrderMutation = useMutation((data: IDeliveryOrder) => updateDeliveryOrder(data), {
        onSuccess: (data) => {
            if (props.onUpdateOrder) {
                props.onUpdateOrder({ ...deliveryOrder, ...data });
            }
            alert.success("Orden actualizada con éxito");
        },
        onError: (er) => {
            alert.error(getServiceMessageError(er));
        },
    });

    const changeShippingValues = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setShippingState({
            ...shippingState,
            [e.target.name]: e.target.value,
        });
    };

    const handleSaveShippingValues = () => {
        const deliveryOrderCopy = {
            ...deliveryOrder,
            shippingTrackingLink: shippingState.shippingLink,
            shippingTrackingNumber: shippingState.shippingNumber,
        };

        deliveryOrderMutation.mutate(deliveryOrderCopy);
    };

    return (
        <Flex column gap={25}>
            <Flex column gap15>
                <TextField bold>Rastreo de envío</TextField>
                <Flex gap15 column>
                    <Flex column w100 gap5>
                        <TextField light small>
                            Tracking link
                        </TextField>
                        <DefaultInput
                            border
                            rounded
                            name="shippingLink"
                            value={shippingState.shippingLink}
                            background
                            placeholder="https://..."
                            onChange={(e) => changeShippingValues(e)}
                        />
                    </Flex>
                    <Flex column w100 gap5>
                        <TextField light small>
                            Número de Rastreo
                        </TextField>
                        <DefaultInput
                            border
                            rounded
                            name="shippingNumber"
                            value={shippingState.shippingNumber}
                            background
                            placeholder="12D32..."
                            onChange={changeShippingValues}
                        />
                    </Flex>
                </Flex>

                {(deliveryOrder.shippingTrackingLink !== shippingState.shippingLink ||
                    deliveryOrder.shippingTrackingNumber !== shippingState.shippingNumber) && (
                    <Flex gap5 justifyEnd alignCenter>
                        <PrimaryButton
                            disabled={deliveryOrderMutation.isLoading}
                            onClick={() => handleSaveShippingValues()}
                            borderRadius={10}
                        >
                            Guardar Link {deliveryOrderMutation.isLoading && <LoadingDualRing small />}
                        </PrimaryButton>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
