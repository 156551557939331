import React from "react";
import { ProgressBarContainer } from "./styled";
import { Flex, TextField } from "../containers";

interface ProgressBarValueProps {
    currentValue: number;
    limitValue: number;
    title: string;
    maxWidth?: boolean;
    hideTitle?: boolean;
    negativeValue?: boolean;
    titleNegativeValue?: string;
    showHorizontal?: boolean;
    w100?: boolean;
}

export const ProgressBarValue = (props: ProgressBarValueProps) => {
    const percentage = (props.currentValue / props.limitValue) * 100;

    const horizontalClass = props.showHorizontal ? "horizontal" : "";

    return (
        <ProgressBarContainer maxWidth={props.maxWidth} className={`${props.w100 ? "w100" : ""} ${horizontalClass}`}>
            {props.negativeValue ? (
                <Flex>
                    <TextField light small>
                        {props.titleNegativeValue ?? ""}
                    </TextField>
                </Flex>
            ) : (
                <span className="progress-title">{!props.hideTitle && `${props.title} ${props.currentValue} de ${props.limitValue}`}</span>
            )}
            <div className="bg-container">
                <div
                    className={props.negativeValue ? "bg-danger" : "bg-green"}
                    style={{
                        height: "8px",
                        transition: "all .8s",
                        width: `${percentage}%`,
                    }}
                />
            </div>
        </ProgressBarContainer>
    );
};
