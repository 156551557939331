import React, { useEffect, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { removeFirstCapitalize } from "../../../utils";
import { DefaultButton, DefaultInput, Flex, PrimaryButton } from "../../_controls";
import { TableCustom } from "../../_controls/tables/styled";
import LoadingDualRing from "../../LoadingDualRing";
import { ImportTemplateType } from "../utils";
import { IImportModel, make_cols, SheetJSFT, FailureMatch } from "./helper";
import { ExcelTableContainer, CustomTr, DataInputForm, DragDropFileContainer, ErrorView, FooterContainer } from "./styled";
import { ImportFielMapping } from "./models";

interface ExcelReaderState {
    data: any[];
    cols: any[];
    importModelList: any[];
    invalidColumns?: string[];
    fileHeaders: string[];
}

export interface ExcelReaderProps {
    importModel: IImportModel;
    template: ImportTemplateType;
    linkTitle: string;
    onHandleImport: (data: any[]) => void;
    importLoading: boolean;
    failures?: FailureMatch[];
    onCancel: () => void;
    children?: React.ReactNode;
    mapping: ImportFielMapping;
}

const removeRegex = /\([^()]*\)/;
const camelRegex = "(?<!(^|[A-Z]))(?=[A-Z])|(?<!^)(?=[A-Z][a-z])";

const ExcelReader = (props: ExcelReaderProps) => {
    const alert = useAlert();
    const intl = useIntl();
    const [state, setState] = useState<ExcelReaderState>({
        data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
        cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
        importModelList: [],
        invalidColumns: undefined,
        fileHeaders: [],
    });

    useEffect(() => {
        if (props.failures && props.failures.length > 0) {
            setState({ ...state, data: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.failures]);

    const handleFile = (file: File) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e: any) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            convertObject(data, make_cols(ws["!ref"]));
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    const convertObject = (data: any[], cols: any[]) => {
        if (data.length <= 2) {
            alert.error(intl.formatMessage(messages.theFileIsNotCorrect));
            return;
        }

        let fileHeaders: string[] = data[0].map((x: string) => (x ? `${x}`.replace(removeRegex, "").trim() : ""));
        fileHeaders = fileHeaders.filter((x) => !!x);

        if (props.importModel.headers && props.importModel.headers.length > 0) {
            let missingHeaders: string[] = [];

            let lowerCaseHeader = fileHeaders.map((x) => x.toLowerCase());

            props.importModel.headers.forEach((element) => {
                if (!lowerCaseHeader.includes(element.toLowerCase())) {
                    missingHeaders.push(element);
                }
            });

            if (missingHeaders.length > 0) {
                setState({
                    ...state,
                    invalidColumns: missingHeaders,
                });
                return;
            }
        }

        let objectList: any[] = [];

        for (let i = 1; i < data.length; i++) {
            const element = data[i];
            let model: any = {};
            if (element[0] === undefined && element[1] === undefined) {
                continue;
            }
            for (let col = 0; col < fileHeaders.length; col++) {
                let header = removeFirstCapitalize(fileHeaders[col]);
                if (props.importModel.translation) {
                    header = props.importModel.translation[fileHeaders[col]] ?? header;
                }
                let value = element[col];
                var mapping = props.mapping[value];
                if (mapping) {
                    value = mapping.process(value);
                }
                model[header] = value;
            }
            objectList.push(model);
        }
        setState({ ...state, data, cols, invalidColumns: undefined, importModelList: objectList, fileHeaders });
    };

    return (
        <DragDropFile handleFile={handleFile}>
            <Flex column gap={20}>
                <DataInput {...props} handleFile={handleFile} />
                {state.invalidColumns && (
                    <ErrorView>
                        <span className="error-title">{intl.formatMessage(messages.errorProcessingFile)}</span> <br />
                        <ul>
                            {state.invalidColumns.map((col, index) => (
                                <li key={index}>{col}</li>
                            ))}
                        </ul>
                    </ErrorView>
                )}
                {props.failures && props.failures.length > 0 && (
                    <ErrorView>
                        <span className="error-title">{intl.formatMessage(messages.someDataWasNotLoadedCorrectly)}</span> <br />
                        <table>
                            {props.failures.map((col, index) => (
                                <tr key={index}>
                                    <td>{col.name}:</td>
                                    <td>{col.message}</td>
                                </tr>
                            ))}
                        </table>
                    </ErrorView>
                )}
                {props.importLoading && <LoadingDualRing center />}
                {!props.importLoading && state.data.length > 0 && (
                    <FooterContainer>
                        <DefaultButton onClick={props.onCancel} className="mr-1">
                            {intl.formatMessage(messages.cancel)}
                        </DefaultButton>
                        <PrimaryButton onClick={() => props.onHandleImport(state.importModelList)}>
                            {intl.formatMessage(messages.import)}
                        </PrimaryButton>
                    </FooterContainer>
                )}
                <ExcelTableContainer>
                    <OutTable {...state} columnLenght={props.importModel.headers?.length ?? -1} />
                </ExcelTableContainer>
            </Flex>
        </DragDropFile>
    );
};

/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/

interface DragDropFileProps {
    handleFile: (file: File) => void;
    children: React.ReactNode;
}

const DragDropFile = (props: DragDropFileProps) => {
    const [state, setState] = useState({
        dragOver: false,
    });

    const suppress = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        setState({ ...state, dragOver: true });
    };

    const onDrop = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        const files = evt.dataTransfer.files;
        if (files && files[0]) props.handleFile(files[0]);
        setState({ ...state, dragOver: false });
    };

    return (
        <DragDropFileContainer
            className={state.dragOver ? "dragover" : ""}
            onDrop={onDrop}
            onDragEnter={suppress}
            onDragOver={suppress}
            onDragLeave={() => setState({ ...state, dragOver: false })}
        >
            {props.children}
        </DragDropFileContainer>
    );
};

interface DataInputProps extends ExcelReaderProps {
    handleFile: (file: File) => void;
}

const DataInput = (props: DataInputProps) => {
    const intl = useIntl();
    const handleChange = (e: any) => {
        const files = e.target.files;
        if (files && files[0]) props.handleFile(files[0]);
    };

    const getTemplate = () => {
        if (props.template === "productOrder") {
            return require("./template_purchase.xlsx");
        }
        if (props.template === "contact") {
            return require("./template_contacts.xlsx");
        }
        if (props.template === "product") {
            return require("./template_products.xlsx");
        }
        if (props.template === "transactionAccount") {
            return require("./template_import_accounts.xlsx");
        }
        return "";
    };

    return (
        <DataInputForm>
            <div>{props.children}</div>
            <div className="label-body">
                <span className="mb-1">
                    {intl.formatMessage(messages.downloadFileFrom)}{" "}
                    <Link to={getTemplate()} target="_blank" download>
                        {props.linkTitle}
                    </Link>{" "}
                    {intl.formatMessage(messages.toContinueTheImport)}
                </span>
                <div className="label-row">
                    <span className="label-title mr-1">{intl.formatMessage(messages.dragAFileOrClickTheButtonToImport)}</span>
                    <label className="input-label cursor-pointer" htmlFor="file">
                        {intl.formatMessage(messages.import)}
                    </label>
                </div>
            </div>
            <DefaultInput className="input-file" type="file" id="file" accept={SheetJSFT} onChange={handleChange} />
        </DataInputForm>
    );
};

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/

interface OutTableProps extends ExcelReaderState {
    columnLenght: number;
}

const OutTable = (props: OutTableProps) => {
    return (
        <TableCustom cellPadding={0} cellSpacing={0} className="table table-striped">
            <tbody>
                {props.data.map((row, index) => {
                    if (index === 0) {
                        return (
                            <CustomTr key={index}>
                                {props.cols.map((col, colIndex) => {
                                    if (colIndex >= props.columnLenght) return null;
                                    return (
                                        <th key={col.key}>
                                            {`${row[col.key]}`.replace(removeRegex, "").trim().split(camelRegex).join(" ")}
                                        </th>
                                    );
                                })}
                            </CustomTr>
                        );
                    }
                    if (row[0] === undefined && row[1] === undefined) return null;
                    return (
                        <CustomTr key={index}>
                            {props.cols.map((col, colIndex) => {
                                if (colIndex >= props.columnLenght) return null;
                                return <td key={col.key}>{row[col.key]}</td>;
                            })}
                        </CustomTr>
                    );
                })}
            </tbody>
        </TableCustom>
    );
};

export default ExcelReader;
