import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { PaymentMethod, WahioFile } from "../../api/models";
import { CashRegisterType, ICashRegister } from "../../api/models/cashRegister";
import { IOrderPaymentBase } from "../../api/models/orderPayment";
import paymentMessages from "../../messages";
import { CashRegisterContext } from "../../store/contexts/CashRegisterContext";
import { OrganizationContext } from "../../store/contexts/OrganizationContext";
import { formatMoney, getDateFormat } from "../../utils";
import CashRegisterOrganizationSelect from "../CashRegisters/CashRegisterSelect/CashRegisterOrganizationSelect";
import TextBoxNumeral from "../TextBoxNumeral";
import CustomFilesModalModal from "../WahioCloud/CustomFilesModal";
import { Flex, SquareButton, TextField } from "../_controls";
import PaymentMethodSelect from "./PaymentMethodSelect";
import { BtnFiles, PaymentRowBody, PaymentRowContainer, ReadonlyPaymentCard } from "./styled";
import WahioTooltip from "../_controls/WahioTooltip";

export type PaymentChangeType = "method" | "value";

export interface PaymentRowProps {
    totalPayment: number;
    totalAmount: number;
    counter: number;
    payment: IOrderPaymentBase;
    onChangePayment: (payment: IOrderPaymentBase, type: PaymentChangeType) => void;
    onRemovePayment: (payment: IOrderPaymentBase) => void;
    hidePaymentInput?: boolean;
    hideFiles?: boolean;
    removePadding?: boolean;
    readonly?: boolean;
    title?: string;
    disabledInput?: boolean;
    activeCashRegister?: ICashRegister;
    columnRows?: boolean;
    hideActions?: boolean;
    hideRemoveButton?: boolean;
}

const PaymentRow = (props: PaymentRowProps) => {
    const { payment, hideActions } = props;
    const [showFilesModal, setShowFilesModal] = useState(false);
    const { organizationState } = useContext(OrganizationContext);
    const { cashRegisterState } = useContext(CashRegisterContext);

    const isReadonly = props.readonly && !!payment.id;
    const intl = useIntl();

    const onChangeValue = (method: PaymentMethod, newAmount: number, type: PaymentChangeType) => {
        let cashRegister = cashRegisterState.orgItems.find((x) => x.id === payment.cashRegisterId);

        if (type === "method") {
            var methodType: CashRegisterType = method === "cash" ? "cash" : "bank";
            if (methodType === props.activeCashRegister?.type) {
                cashRegister = props.activeCashRegister;
            } else {
                let existingWithMethod = cashRegisterState.orgItems.find((x) => x.defaultPaymentMethod === method);
                if (existingWithMethod) {
                    cashRegister = existingWithMethod;
                } else {
                    if (methodType !== cashRegister?.type) {
                        cashRegister = cashRegisterState.orgItems.find((x) => x.type === methodType);
                    }
                }
            }
        }
        props.onChangePayment({ ...payment, method: method, amount: newAmount, cashRegisterId: cashRegister?.id ?? "" }, type);
    };

    const onCompletePayment = () => {
        let newAmount = props.totalAmount - props.totalPayment + payment.amount;
        onChangeValue(payment.method, newAmount, "value");
    };

    const onSelectedFiles = (files: WahioFile[] = []) => {
        props.onChangePayment({ ...payment, files }, "value");
    };

    const missingValue = props.totalAmount - props.totalPayment;
    const blockPayments = organizationState.currentOrganization?.requiredCashRegister && !cashRegisterState.logActive;
    if (blockPayments) {
        return (
            <Flex column>
                <span className="text-small">Pago bloqueado</span>
                <span className="text-light text-small">Se requiere activar la caja para crear pagos</span>
            </Flex>
        );
    }

    const hideRemoveBtn = props.hideActions || props.hideRemoveButton;

    if (isReadonly) {
        return (
            <ReadonlyPaymentCard>
                <Flex column>
                    <TextField bold>{formatMoney(payment.amount)}</TextField>
                    <Flex gap10 alignCenter>
                        <TextField>{intl.formatMessage(paymentMessages[payment.method])}</TextField>
                        <TextField small light>
                            {getDateFormat(payment.createdAt ?? payment.dateCreated, "DD MMM YY, hh:mm:a")}
                        </TextField>
                    </Flex>
                </Flex>
                <Flex gap10>
                    {!props.hideFiles && (
                        <BtnFiles type="button" rounded onClick={() => setShowFilesModal(true)}>
                            <i className="fa-regular fa-paperclip"></i>
                            {payment.files && payment.files.length > 0 && <span className="files-count">{payment.files.length}</span>}
                        </BtnFiles>
                    )}
                    {!hideRemoveBtn && (
                        <SquareButton type="button" onClick={() => props.onRemovePayment(payment)}>
                            <i className="fa-regular fa-trash"></i>
                        </SquareButton>
                    )}
                </Flex>
            </ReadonlyPaymentCard>
        );
    }

    const typeFilter = payment.method === "cash" ? "cash" : "bank";

    const useActiveCash = typeFilter === props.activeCashRegister?.type;

    return (
        <PaymentRowContainer removePadding={props.removePadding}>
            {showFilesModal && (
                <CustomFilesModalModal
                    modalTitle="Archivos de Pago"
                    files={payment.files ?? []}
                    onFilesChanges={onSelectedFiles}
                    show={showFilesModal}
                    setShow={setShowFilesModal}
                />
            )}
            {!props.hidePaymentInput && (
                <Flex alignCenter gap={10}>
                    <TextBoxNumeral
                        className="payment-input"
                        onNumberChange={(value) => onChangeValue(payment.method, value, "value")}
                        format="money"
                        value={payment.amount ?? 0}
                        autoFocus={true}
                        maxLength={18}
                        disabled={props.disabledInput}
                    />
                    {!props.hideFiles && (
                        <BtnFiles removeBackground type="button" rounded onClick={() => setShowFilesModal(true)}>
                            <i className="fa-regular fa-paperclip"></i>
                            {payment.files && payment.files.length > 0 && <span className="files-count">{payment.files.length}</span>}
                        </BtnFiles>
                    )}
                    {!hideActions && missingValue > 0 && !isReadonly && payment.amount === 0 && (
                        <SquareButton removeBackground type="button" onClick={onCompletePayment}>
                            <i className="fa-regular fa-fill"></i>
                        </SquareButton>
                    )}
                    {!hideRemoveBtn && (
                        <SquareButton removeBackground type="button" onClick={() => props.onRemovePayment(payment)}>
                            <i className="fa-regular fa-trash"></i>
                        </SquareButton>
                    )}
                </Flex>
            )}

            {!isReadonly && (
                <PaymentRowBody className={(props.hidePaymentInput && props.columnRows) ? "column" : ""}>
                    <Flex gap10 className={(props.hidePaymentInput && props.columnRows) ? "hideInput" : ""}>
                        <PaymentMethodSelect
                            readonly={isReadonly}
                            method={payment.method}
                            onChange={(value) => onChangeValue(value, payment.amount, "method")}
                        />
                        <Flex gap10 alignCenter>
                            <CashRegisterOrganizationSelect
                                defaultCashRegisterId={useActiveCash ? props.activeCashRegister?.id : payment.cashRegisterId}
                                hideTitle
                                readonly={isReadonly}
                                typeFilter={typeFilter}
                                disabled={useActiveCash}
                                onCashRegisterChange={(value) => {
                                    if (value.id) {
                                        props.onChangePayment({ ...payment, cashRegisterId: value.id }, "value");
                                    }
                                }}
                            />
                            {useActiveCash && props.activeCashRegister && (
                                <WahioTooltip
                                    small
                                    text={`La caja tipo ${intl.formatMessage(
                                        paymentMessages[props.activeCashRegister.type]
                                    )} solo puede usar la caja activa`}
                                    timeout={0}
                                />
                            )}
                            {props.hidePaymentInput && (
                                <Flex alignCenter gap10 marginLeftAuto>
                                    {!hideActions && (
                                        <SquareButton type="button" onClick={() => props.onRemovePayment(payment)}>
                                            <span className="wahioicon-trash"></span>
                                        </SquareButton>
                                    )}
                                    {!props.hideFiles && (
                                        <BtnFiles type="button" rounded onClick={() => setShowFilesModal(true)}>
                                            <i className="fa-regular fa-paperclip"></i>
                                            {payment.files && payment.files.length > 0 && (
                                                <span className="files-count">{payment.files.length}</span>
                                            )}
                                        </BtnFiles>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </PaymentRowBody>
            )}
        </PaymentRowContainer>
    );
};
export default PaymentRow;
