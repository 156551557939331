import React, { useState } from "react";
import { useIntl } from "react-intl";
import { WarehouseProductException } from "../../../api/models/warehouseProduct";
import { DefaultButton, Flex, FlexImageStyle, TextField } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";
import NavigationStock from "../ProductProfileNavigation/NavigationStock";
import { getProductThumbnailUrl } from "../helper";
import { ExceptionCard } from "./styled";

interface ProductExceptionFormProps {
    items: WarehouseProductException[];
}

export default function ProductExceptionForm(props: ProductExceptionFormProps) {
    return (
        <Flex column gap15 padding={20}>
            <TextField light small>
                No fue posible actualizar el stock de algunos productos, revisa el resumen de los productos
            </TextField>
            {props.items.map((item, index) => {
                return <ExceptionCardLine key={index} item={item} />;
            })}
        </Flex>
    );
}

interface ExceptionCardLineProps {
    item: WarehouseProductException;
}
const ExceptionCardLine = (props: ExceptionCardLineProps) => {
    const { item } = props;
    const intl = useIntl();
    const [stockAvailable, setStockAvailable] = useState(item.stockAvailable);
    const [showInventory, setShowInventory] = useState(false);
    return (
        <>
            <ExceptionCard>
                <Flex alignCenter gap={20} alignStart>
                    {item.product && (
                        <FlexImageStyle borderRadius={15}>
                            <img src={getProductThumbnailUrl(item.product)} alt="" />
                        </FlexImageStyle>
                    )}
                    <Flex column>
                        {item.product && (
                            <>
                                <TextField>{item.product.name}</TextField>
                            </>
                        )}
                        <TextField light small>
                            {item.message}
                        </TextField>
                        <Flex gap10 marginTop={15} alignCenter>
                            <Flex alignCenter gap5>
                                <TextField small light>
                                    Requerido:
                                </TextField>
                                <TextField>{item.stockRequested}</TextField>
                            </Flex>
                            <Flex alignCenter gap5>
                                <TextField small light>
                                    Disponible:
                                </TextField>
                                <TextField>{stockAvailable}</TextField>
                            </Flex>
                            {item.product && (
                                <DefaultButton bgLight onClick={() => setShowInventory(!showInventory)} small rounded>
                                    <span className="fa-regular fa-boxes-stacked"></span> Inventario
                                </DefaultButton>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                {item.product && showInventory && (
                    <Flex column>
                        <NavigationStock
                            defaultWarehouseId={item.warehouseId}
                            product={item.product}
                            onUpdateProduct={() => {}}
                            setWarehouseProduct={(data) => {
                                if (data.warehouseId === item.warehouseId) {
                                    let stock = data.stock - data.reserved;
                                    if (stock < 0) stock = 0;
                                    setStockAvailable(stock);
                                }
                            }}
                            setProductVariant={() => {}}
                            setSelectedGroup={() => {}}
                            setSelectedWarehouse={() => {}}
                            fetchWarehouseProduct={() => {}}
                            intl={intl}
                        />
                    </Flex>
                )}
            </ExceptionCard>
        </>
    );
};

interface ProductExceptionFormModalProps extends IModalShow, ProductExceptionFormProps {}

export const ProductExceptionFormModal = (props: ProductExceptionFormModalProps) => {
    return (
        <Modal
            show={props.show}
            colorStyle="danger"
            sizeType="md"
            setShow={props.setShow}
            ignoreOutsideClick
            title="Excepciones"
            useMobileView
            showHeader
        >
            <ProductExceptionForm {...props} />
        </Modal>
    );
};
