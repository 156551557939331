import React from "react";
import AppSettingsProvider from "../appSettings/AppSettingsContext";
import AlertContextProvider from "../components/Alerts/AlertContext";
import ProductSearchContextProvider from "../components/Products/ProductSearchList/ProductSearchContext";
import { I18nProvider } from "../i18n";
import StyledThemeProvider from "../theme/themeProvider";
import AccountUserContextProvider from "./contexts/AccountUserContext";
import CashRegisterContextProvider from "./contexts/CashRegisterContext";
import ChatContextProvider from "./contexts/ChatContext";
import ClientSettingsContextProvider from "./contexts/ClientSettingsContext";
import CloudContextProvider from "./contexts/CloudContext";
import ContactContextProvider from "./contexts/ContactContext";
import CountryCodesContextProvider from "./contexts/CountryCodesContext";
import CurrentOrderContextProvider from "./contexts/CurrentOrderContext";
import CurrentPurchaseContextProvider from "./contexts/CurrentPurchaseContext";
import CustomCategoryContextProvider from "./contexts/CustomCategoryContext";
import DiscountContextProvider from "./contexts/DiscountContext";
import EBillingContextProvider from "./contexts/EBillingContext";
import InventoryAdjustmentContextProvider from "./contexts/InventoryAdjustmentContext";
import InventoryLocationContextProvider from "./contexts/InventoryLocationContext";
import LoungeContextProvider from "./contexts/LoungeContext";
import MenuContextProvider from "./contexts/MenuContext";
import OrderChannelContextProvider from "./contexts/OrderChannelContext";
import OrderLocalStorageContextProvider from "./contexts/OrderDataSummaryContext";
import OrderInvoiceNumerationContext from "./contexts/OrderInvoiceNumerationContext";
import OrderLocalContextProvider from "./contexts/OrderLocalContext";
import OrderScreenPreviewContextProvider from "./contexts/OrderScreenPreviewContext";
import OrganizationContextProvider from "./contexts/OrganizationContext";
import PurchaseContextProvider from "./contexts/PurchaseContext";
import TaxContextProvider from "./contexts/TaxContext";
import TransactionAccountContextProvider from "./contexts/TransactionAccountContext";
import UserContextProvider from "./contexts/UserContext";
import WarehouseContextProvider from "./contexts/WarehouseContext";
import WarehouseProductContextProvider from "./contexts/WarehouseProductContext";

interface IContextProviders {
    children: React.ReactNode;
}

const ContextProviders = ({ children }: IContextProviders) => {
    return (
        <AppSettingsProvider>
            <I18nProvider>
                <StyledThemeProvider>
                    <AlertContextProvider>
                        <AccountUserContextProvider>
                            <OrganizationContextProvider>
                                <UserContextProvider>
                                    <WarehouseProductContextProvider>
                                        <TransactionAccountContextProvider>
                                            <CurrentPurchaseContextProvider>
                                                <CurrentOrderContextProvider>
                                                    <ContactContextProvider>
                                                        <OrderLocalStorageContextProvider>
                                                            <PurchaseContextProvider>
                                                                <CustomCategoryContextProvider>
                                                                    <LoungeContextProvider>
                                                                        <InventoryAdjustmentContextProvider>
                                                                            <TaxContextProvider>
                                                                                <OrderChannelContextProvider>
                                                                                    <CashRegisterContextProvider>
                                                                                        <DiscountContextProvider>
                                                                                            <OrderInvoiceNumerationContext>
                                                                                                <ChatContextProvider>
                                                                                                    <CountryCodesContextProvider>
                                                                                                        <WarehouseContextProvider>
                                                                                                            <MenuContextProvider>
                                                                                                                <InventoryLocationContextProvider>
                                                                                                                    <EBillingContextProvider>
                                                                                                                        <CloudContextProvider>
                                                                                                                            <ClientSettingsContextProvider>
                                                                                                                                <ProductSearchContextProvider>
                                                                                                                                    <OrderLocalContextProvider>
                                                                                                                                        <OrderScreenPreviewContextProvider>
                                                                                                                                            {
                                                                                                                                                children
                                                                                                                                            }
                                                                                                                                        </OrderScreenPreviewContextProvider>
                                                                                                                                    </OrderLocalContextProvider>
                                                                                                                                </ProductSearchContextProvider>
                                                                                                                            </ClientSettingsContextProvider>
                                                                                                                        </CloudContextProvider>
                                                                                                                    </EBillingContextProvider>
                                                                                                                </InventoryLocationContextProvider>
                                                                                                            </MenuContextProvider>
                                                                                                        </WarehouseContextProvider>
                                                                                                    </CountryCodesContextProvider>
                                                                                                </ChatContextProvider>
                                                                                            </OrderInvoiceNumerationContext>
                                                                                        </DiscountContextProvider>
                                                                                    </CashRegisterContextProvider>
                                                                                </OrderChannelContextProvider>
                                                                            </TaxContextProvider>
                                                                        </InventoryAdjustmentContextProvider>
                                                                    </LoungeContextProvider>
                                                                </CustomCategoryContextProvider>
                                                            </PurchaseContextProvider>
                                                        </OrderLocalStorageContextProvider>
                                                    </ContactContextProvider>
                                                </CurrentOrderContextProvider>
                                            </CurrentPurchaseContextProvider>
                                        </TransactionAccountContextProvider>
                                    </WarehouseProductContextProvider>
                                </UserContextProvider>
                            </OrganizationContextProvider>
                        </AccountUserContextProvider>
                    </AlertContextProvider>
                </StyledThemeProvider>
            </I18nProvider>
        </AppSettingsProvider>
    );
};

export default ContextProviders;
