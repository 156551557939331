import { ContactImpl, CustomerAddress, Contact } from "../../../api/models/contact";
import { getAuthBody } from "../../../auth";

export const getInitialContact = (fullNameOrDocument?: string) => {
    let customer:Contact = new ContactImpl(getAuthBody().accountId);

    if (fullNameOrDocument) {
        let parseValue = parseFloat(fullNameOrDocument);
        if (parseValue) {
            customer.identificationNumber = `${parseValue}`;
        } else {
            let splitName = fullNameOrDocument.split(" ");
            if (splitName.length > 1) {
                var firstName = splitName.slice(0, -1).join(" ");
                var lastName = splitName.slice(-1).join(" ");
                customer.firstName = firstName;
                customer.lastName = lastName;
            } else {
                customer.firstName = fullNameOrDocument;
            }
        }
    }

    customer.emails.push({ email: "" });
    customer.addresses.push(new CustomerAddress("customeraddress"));
    customer.phones.push({
        phoneNumber: "",
        phonePrefix: "57",
    });
    return customer;
};

export const getExistingContact = (existingContact: Contact) => {
    let contact = { ...existingContact };
    if (!contact.emails || contact.emails.length === 0) {
        contact.emails = [{ email: "" }];
    }
    if (!contact.addresses || contact.addresses.length === 0) {
        contact.addresses = [new CustomerAddress("customeraddress")];
    }
    if (!contact.phones || contact.phones.length === 0) {
        contact.phones = [
            {
                phoneNumber: "",
                phonePrefix: "57",
            },
        ];
    }

    return contact;
};
