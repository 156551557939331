import styled from "styled-components";

export const SelectorContainer = styled.div`
    .remove-value {
        background: ${(props) => props.theme.palette.backgroundDark};
        border-radius: 7px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon-left {
        margin-left: auto;
    }
    .btn-category {
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.textLight};
        font-weight: 400;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        &.active-item {
            background: ${(props) => props.theme.palette.backgroundLight};
            color: ${(props) => props.theme.palette.text};
            font-weight: 500;
        }
    }
`;
export const CashRegisterEntryCategoryGrid = styled.div`
    display: grid;
    grid-gap: 20px 5px;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-auto-rows: min-content;
    box-sizing: border-box;
    max-height: 540px;
    overflow: auto;

    &.remove-max-height {
        max-height: none;
    }
    &.small {
        grid-gap: 5px 5px;
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    }
`;

export const NewCategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input-filter {
        background: ${(props) => props.theme.palette.background};
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const CategoryCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    .icon {
        width: 50px;
        min-width: 50px;
        font-size: 20px;
        height: 50px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    &.small {
        .icon {
            width: 35px;
            min-width: 35px;
            height: 35px;
        }
    }
    &.active {
        .icon {
            background: ${(props) => props.theme.palette.primary}20;
            color: ${(props) => props.theme.palette.primary};
        }
        .category-name {
            color: ${(props) => props.theme.palette.primary};
        }
    }

    .category-name {
        font-size: 12px;
        color: ${(props) => props.theme.palette.text};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    :hover {
        .icon {
            background: ${(props) => props.theme.palette.primary}20;
            color: ${(props) => props.theme.palette.primary};
        }
        .category-name {
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;
