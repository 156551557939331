export interface VariantOptionExample {
    name: string;
    options?: string[];
}

export const variantOptionsExamples: VariantOptionExample[] = [
    {
        name: "color",
        options: [],
    },
    {
        name: "size",
        options: [""],
    },
    {
        name: "flavor",
        options: [],
    },
    {
        name: "weight",
        options: [],
    },
];
