import React, { useContext, useEffect } from "react";
import { IPurchase, IPurchaseDelete } from "../../../api/models/purchase";
import { CustomTableTrFromOrder, TableCustom, OrderRejectedCard } from "../../Orders/OrderProfile/styled";
import messages from "./messages";
import { useIntl } from "react-intl";
import { getAccountUserFullName } from "../../../api/models/accountUser";
import { getDateFormatCalendar } from "../../../utils";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { ORDER_STATUS_STYLE } from "../../Orders/OrderStatusFilter/helpers";
import CardIcon from "../../_Cards/CardIcon";

interface OrderRejectedProps {
    order: IPurchase;
    orderDelete?: IPurchaseDelete;
}

export default function OrderRejected(props: OrderRejectedProps) {
    const { order, orderDelete } = props;
    const { cashRegisterState } = useContext(CashRegisterContext);

    useEffect(() => {
        
    }, []);

    const orderStatus = ORDER_STATUS_STYLE[order.status];

    const intl = useIntl();
    return (
        <OrderRejectedCard>
            <CardIcon
                iconColor="#f44336"
                icon={orderStatus.icon}
                title={`#${order.number}`}
                smallTitle={intl.formatMessage(messages[order.status])}
            />

            <TableCustom>
                <tbody>
                    <CustomTableTrFromOrder>
                        <td className="min-width">
                            <span className="light">{intl.formatMessage(messages[order.status])} por: </span>
                        </td>
                        <td>
                            <span>
                                {getAccountUserFullName(orderDelete?.accountUser)}, {getDateFormatCalendar(orderDelete?.dateDeleted)}
                            </span>
                        </td>
                    </CustomTableTrFromOrder>

                    <CustomTableTrFromOrder>
                        <td className="min-width">
                            <span className="light">Comentario:</span>
                        </td>
                        <td>
                            <span>{orderDelete?.comment}</span>
                        </td>
                    </CustomTableTrFromOrder>
                    <CustomTableTrFromOrder>
                        <td className="min-width">
                            <span className="light">Caja: </span>
                        </td>
                        <td>
                            <span>{cashRegisterState.orgItems.find((x) => x.id === orderDelete?.cashRegisterId)?.name}</span>
                        </td>
                    </CustomTableTrFromOrder>
                </tbody>
            </TableCustom>
        </OrderRejectedCard>
    );
}
