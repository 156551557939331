import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { getOrderTableByOrder } from "../../../api/orders/restaurantApi";
import { getDateFormat, getTimeInterval } from "../../../utils";
import LoadingDualRing from "../../LoadingDualRing";
import { Flex, GridTemplate, TextField } from "../../_controls";
import { FlexImageIcon } from "../../_controls/containers/FlexImageLetter";
import { OrderNavigationProps } from ".";

export default function OrderTableView(props: OrderNavigationProps) {
    const { order } = props;

    const orderTableMutation = useMutation((orderId: string) => getOrderTableByOrder(orderId));
    const getOrderTableById = () => {
        if (order.id && order?.isRestaurant) {
            orderTableMutation.mutate(order.id);
        }
    };

    useEffect(() => {
        getOrderTableById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const orderTable = orderTableMutation.data;

    if (orderTableMutation.isLoading) return <LoadingDualRing center />;

    if (!orderTable) return null;

    return (
        <Flex column gap10>
            <Flex gap10 alignCenter>
                <FlexImageIcon icon="fa-regular fa-utensils" />
                <Flex column>
                    <TextField>Mesa {orderTable.tableNumber}</TextField>
                    <TextField small light>
                        {orderTable.lounge?.name}
                    </TextField>
                </Flex>
            </Flex>

            {orderTableMutation.isLoading && <LoadingDualRing small={true} />}
            {orderTable && (
                <GridTemplate gap={20} size={160}>
                    {orderTable.dateStart && (
                        <>
                            <Flex column gap5>
                                <TextField small light>
                                    Fecha
                                </TextField>
                                <TextField>{getDateFormat(orderTable.dateStart, "DD MMM YY")}</TextField>
                            </Flex>
                            <Flex column gap5>
                                <TextField small light>
                                    Hora
                                </TextField>
                                <TextField>
                                    {getDateFormat(orderTable.dateStart, "hh:mm:a")}
                                    {orderTable.dateEnd && order.isRestaurantClose && ` - ${getDateFormat(orderTable.dateEnd, "hh:mm:a")}`}
                                </TextField>
                            </Flex>
                        </>
                    )}

                    {order.isRestaurantClose && (
                        <Flex column gap5>
                            <TextField small light>
                                Duración
                            </TextField>
                            <TextField>{getTimeInterval(orderTable.dateEnd, orderTable.dateStart)}</TextField>
                        </Flex>
                    )}

                    {orderTableMutation.isLoading && <LoadingDualRing small />}
                </GridTemplate>
            )}
        </Flex>
    );
}
