import { defineMessages } from "react-intl";

const base = "components.productCreationForm";

export default defineMessages({
    createAnother: {
        id: `${base}.createAnother`,
        defaultMessage: "Crear otro producto",
    },
    okay: {
        id: `${base}.okay`,
        defaultMessage: "Aceptar",
    },
    createProduct: {
        id: `${base}.createProduct`,
        defaultMessage: "Crear Producto",
    },
    editProduct: {
        id: `${base}.editProduct`,
        defaultMessage: "Editar Producto",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    createdSuccessfully: {
        id: `${base}.createdSuccessfully`,
        defaultMessage: "Producto creado con éxito",
    },
    detail: {
        id: `${base}.detail`,
        defaultMessage: "Detalles",
    },
    prices: {
        id: `${base}.prices`,
        defaultMessage: "Precios",
    },
    price: {
        id: `${base}.price`,
        defaultMessage: "Precio",
    },
    basePrice: {
        id: `${base}.basePrice`,
        defaultMessage: "Costo del producto",
    },
    salePrices: {
        id: `${base}.salePrices`,
        defaultMessage: "Precios de venta",
    },
    images: {
        id: `${base}.images`,
        defaultMessage: "imágenes",
    },
    components: {
        id: `${base}.components`,
        defaultMessage: "Componentes",
    },
    variants: {
        id: `${base}.variants`,
        defaultMessage: "Variantes",
    },
    isVariantProduct: {
        id: `${base}.isVariantProduct`,
        defaultMessage: "Es Producto Variable",
    },
    includesSerials: {
        id: `${base}.includesSerials`,
        defaultMessage: "Incluye Seriales",
    },
    more: {
        id: `${base}.more`,
        defaultMessage: "Detalles",
    },
    inputName: {
        id: `${base}.inputName`,
        defaultMessage: "Nombre del producto",
    },
    inputCategory: {
        id: `${base}.inputCategory`,
        defaultMessage: "Categoría",
    },
    inputSalePrice: {
        id: `${base}.inputSalePrice`,
        defaultMessage: "Precio de venta",
    },

    priceHelperLine0: {
        id: `${base}.priceHelperLine0`,
        defaultMessage: "El 'Costo del producto' es el precio de compra del producto",
    },
    createAnotherProduct: {
        id: `${base}.createAnotherProduct`,
        defaultMessage: "Crear otro producto",
    },
    priceHelperLine1: {
        id: `${base}.priceHelperLine1`,
        defaultMessage: "El 'Precio 1' será usado en el E-commerce y restaurante.",
    },
    priceHelperLine2: {
        id: `${base}.priceHelperLine2`,
        defaultMessage: "Asegúrate de agregar los precios de forma descendente.",
    },
    priceHelperLine3: {
        id: `${base}.priceHelperLine3`,
        defaultMessage: "Puedes agregar hasta {0} precios de venta por producto.",
    },
    imageHelperLine1: {
        id: `${base}.imageHelperLine1`,
        defaultMessage: "Puedes subir hasta {0} imágenes po r producto",
    },
    imageHelperLine2: {
        id: `${base}.imageHelperLine2`,
        defaultMessage: "Asegúrate que las imágenes tengan un fondo blanco",
    },
    imageHelperLine3: {
        id: `${base}.imageHelperLine3`,
        defaultMessage: "Asegúrate que el formato sea jpg, png, gif",
    },
    mainImages: {
        id: `${base}.mainImages`,
        defaultMessage: "Imagen principal",
    },
    secondaryImages: {
        id: `${base}.secondaryImages`,
        defaultMessage: "Imágenes secundarias",
    },
    productSku: {
        id: `${base}.productSku`,
        defaultMessage: "Sku",
    },
    productBarcode: {
        id: `${base}.productBarcode`,
        defaultMessage: "Código de barras",
    },
    productReference: {
        id: `${base}.productReference`,
        defaultMessage: "Referencia",
    },
    productDescription: {
        id: `${base}.productDescription`,
        defaultMessage: "Descripción",
    },
    productCharacteristics: {
        id: `${base}.productCharacteristics`,
        defaultMessage: "Características",
    },
    productBrand: {
        id: `${base}.productBrand`,
        defaultMessage: "Marca",
    },
    productManufacturer: {
        id: `${base}.productManufacturer`,
        defaultMessage: "Manufactura",
    },
    categorySelected: {
        id: `${base}.categorySelected`,
        defaultMessage: "Categoría seleccionada",
    },
    companySelected: {
        id: `${base}.companySelected`,
        defaultMessage: "Empresa",
    },
    searchCategory: {
        id: `${base}.searchCategory`,
        defaultMessage: "Buscar categoría",
    },
    searchCompany: {
        id: `${base}.searchCompany`,
        defaultMessage: "Buscar empresa",
    },
    newCategory: {
        id: `${base}.newCategory`,
        defaultMessage: "Crear Categoría",
    },
    createCompany: {
        id: `${base}.createCompany`,
        defaultMessage: "Crear Compañia",
    },
    missingData: {
        id: `${base}.missingData`,
        defaultMessage: "El nombre, el precio y la categoría son obligatorios",
    },
    addDescription: {
        id: `${base}.addDescription`,
        defaultMessage: "Agregar descripción",
    },
    saveChanges: {
        id: `${base}.saveChanges`,
        defaultMessage: "Guardar cambios",
    },
    youDoNotHavePermissionsToEditProducts: {
        id: `${base}.youDoNotHavePermissionsToEditProducts`,
        defaultMessage: "No tienes permisos para editar productos",
    },
    youDoNotHavePermissionsToCreateProducts: {
        id: `${base}.youDoNotHavePermissionsToCreateProducts`,
        defaultMessage: "No tienes permisos para crear productos",
    },
    theProductWasSuccessfullyUpdated: {
        id: `${base}.theProductWasSuccessfullyUpdated`,
        defaultMessage: "El producto fue actualizado con éxito",
    },
    imageFormatNotAllowed: {
        id: `${base}.imageFormatNotAllowed`,
        defaultMessage: "Formato de imagen no permitido:",
    },
    willBeDeleted: {
        id: `${base}.willBeDeleted`,
        defaultMessage: "Se eliminarán",
    },
    youAlreadyAddedThisCategory: {
        id: `${base}.youAlreadyAddedThisCategory`,
        defaultMessage: "Ya agregaste esta categoría",
    },
    mainCategory: {
        id: `${base}.mainCategory`,
        defaultMessage: "Categoría Principal",
    },
    secondaryCategories: {
        id: `${base}.secondaryCategories`,
        defaultMessage: "Categorías secundarias",
    },
    controlStock: {
        id: `${base}.controlStock`,
        defaultMessage: "Controlar Stock",
    },
    compositeProduct: {
        id: `${base}.compositeProduct`,
        defaultMessage: "Producto Compuesto",
    },
    theProductIsAlreadyAdded: {
        id: `${base}.theProductIsAlreadyAdded`,
        defaultMessage: "El producto ya está agregado",
    },
    addProducts: {
        id: `${base}.addProducts`,
        defaultMessage: "Agregar productos",
    },
    sizesAndWeights: {
        id: `${base}.sizesAndWeights`,
        defaultMessage: "Tamaños y pesos",
    },
    width: {
        id: `${base}.width`,
        defaultMessage: "Ancho",
    },
    heigh: {
        id: `${base}.heigh`,
        defaultMessage: "Alto",
    },
    weight: {
        id: `${base}.weight`,
        defaultMessage: "Peso",
    },
    length: {
        id: `${base}.length`,
        defaultMessage: "Largo",
    },
    selectedTaxes: {
        id: `${base}.selectedTaxes`,
        defaultMessage: "Impuestos seleccionados",
    },
    create: {
        id: `${base}.create`,
        defaultMessage: "Crear",
    },
    selectTaxes: {
        id: `${base}.selectTaxes`,
        defaultMessage: "Seleccionar impuestos",
    },
    nameOfVariantAlreadyExists: {
        id: `${base}.nameOfVariantAlreadyExists`,
        defaultMessage: "El nombre de la variante ya existe",
    },
    unitMeasureOptions: {
        id: `${base}.unitMeasureOptions`,
        defaultMessage: "Opciones de medidas",
    },
    volume: {
        id: `${base}.volume`,
        defaultMessage: "Volumen",
    },
    temperature: {
        id: `${base}.temperature`,
        defaultMessage: "Temperatura",
    },
    area: {
        id: `${base}.area`,
        defaultMessage: "Área",
    },
    speed: {
        id: `${base}.speed`,
        defaultMessage: "Velocidad",
    },
    mass: {
        id: `${base}.mass`,
        defaultMessage: "Masa",
    },
    unitLength: {
        id: `${base}.unitLength`,
        defaultMessage: "Longitud",
    },
    productMeasurements: {
        id: `${base}.productMeasurements`,
        defaultMessage: "Medidas del producto",
    },
    measures: {
        id: `${base}.measures`,
        defaultMessage: "Medidas",
    },
    createTax: {
        id: `${base}.createTax`,
        defaultMessage: "Crear impuesto",
    },
    newTax: {
        id: `${base}.newTax`,
        defaultMessage: "Nuevo impuesto",
    },
    thisOptionAllowsYouCreateAnotherProductWhenYouFinishCreatingTheCurrentProduct: {
        id: `${base}.thisOptionAllowsYouCreateAnotherProductWhenYouFinishCreatingTheCurrentProduct`,
        defaultMessage: "Esta opción permite crear otro producto cuando termines de crear el producto actual",
    },
    keepInMind: {
        id: `${base}.keepInMind`,
        defaultMessage: "Tenga en cuenta",
    },
    compound: {
        id: `${base}.compound`,
        defaultMessage: "Compuesto",
    },
    optionals: {
        id: `${base}.optionals`,
        defaultMessage: "Opcionales",
    },
    variant: {
        id: `${base}.variant`,
        defaultMessage: "Variante",
    },

    ProductVariantSearch: {
        id: `${base}.variant`,
        defaultMessage: "Variante",
    },
    serials: {
        id: `${base}.serials`,
        defaultMessage: "Seriales",
    },
    profile: {
        id: `${base}.profile`,
        defaultMessage: "Perfil",
    },
    nameOfVariantCannotBeEmpty: {
        id: `${base}.nameOfVariantCannotBeEmpty`,
        defaultMessage: "El nombre de la variante no puede estar vacío",
    },
    color: {
        id: `${base}.color`,
        defaultMessage: "Color",
    },
    size: {
        id: `${base}.size`,
        defaultMessage: "Talla",
    },
    flavor: {
        id: `${base}.flavor`,
        defaultMessage: "Sabor",
    },
    addValuesAndPressEnter: {
        id: `${base}.addValuesAndPressEnter`,
        defaultMessage: "Agregue valores y presione enter",
    },
    exColor: {
        id: `${base}.exColor`,
        defaultMessage: "Ej: Color",
    },
    thereCannotBeTwoVariantsWithTheSameName: {
        id: `${base}.thereCannotBeTwoVariantsWithTheSameName`,
        defaultMessage: "No puede haber dos variantes con el mismo nombre",
    },
    chooseHowYouWantTheVariantsToLookLikeYouCanClickTheImageIconToChangeTheTypeOfView: {
        id: `${base}.chooseHowYouWantTheVariantsToLookLikeYouCanClickTheImageIconToChangeTheTypeOfView`,
        defaultMessage: "Elija cómo desea que se vean las variantes. Puede hacer clic en el icono de imagen para cambiar el tipo de vista",
    },
    previewIn: {
        id: `${base}.previewIn`,
        defaultMessage: "Vista previa en: ",
    },
    variantOptions: {
        id: `${base}.variantOptions`,
        defaultMessage: "Opciones de variante",
    },
    selectVariantOptionsAndValues: {
        id: `${base}.selectVariantOptionsAndValues`,
        defaultMessage: "Seleccione opciones y valores de variante",
    },
    adOption: {
        id: `${base}.adOption`,
        defaultMessage: "Agregar opción",
    },
    details: {
        id: `${base}.details`,
        defaultMessage: "Detalles",
    },
    useSameImageIn: {
        id: `${base}.useSameImageIn`,
        defaultMessage: "Usar la misma imagen en",
    },
    useSamePriceIn: {
        id: `${base}.useSamePriceIn`,
        defaultMessage: "Usar el mismo precio en",
    },
    none: {
        id: `${base}.none`,
        defaultMessage: "Ninguna",
    },
    ifYouApplyThisOptionTheImageYouSelectInAnyVariantWillBeAppliedToTheOthers: {
        id: `${base}.ifYouApplyThisOptionTheImageYouSelectInAnyVariantWillBeAppliedToTheOthers`,
        defaultMessage: "Si aplica esta opción, la imagen que seleccione en cualquier variante se aplicará a las otras",
    },
    ifYouApplyThisOptionThePriceYouSelectInAnyVariantWillBeAppliedToTheOthers: {
        id: `${base}.ifYouApplyThisOptionThePriceYouSelectInAnyVariantWillBeAppliedToTheOthers`,
        defaultMessage: "Si aplica esta opción, el precio que seleccione en cualquier variante se aplicará a las otras",
    },
    inAllVariants: {
        id: `${base}.inAllVariants`,
        defaultMessage: "En todas las variantes",
    },
    allVariants: {
        id: `${base}.allVariants`,
        defaultMessage: "Todas las variantes",
    },
    preview: {
        id: `${base}.preview`,
        defaultMessage: "Vista previa",
    },
    ungrouped: {
        id: `${base}.ungrouped`,
        defaultMessage: "Sin agrupar",
    },
    grouped: {
        id: `${base}.groupedBy`,
        defaultMessage: "Agrupado",
    },
    referenceProduct: {
        id: `${base}.referenceProduct`,
        defaultMessage: "Referencia del producto",
    },
    theSameImageWillBeAppliedForAllVariantsThatMatchTheSelectedOne: {
        id: `${base}.theSameImageWillBeAppliedForAllVariantsThatMatchTheSelectedOne`,
        defaultMessage: "Se aplicará la misma imagen para todas las variantes que coincidan con la seleccionada",
    },
    theSamePriceWillBeAppliedForAllVariantsThatMatchTheSelectedOne: {
        id: `${base}.theSamePriceWillBeAppliedForAllVariantsThatMatchTheSelectedOne`,
        defaultMessage: "Se aplicará el mismo precio para todas las variantes que coincidan con la seleccionada",
    },
    example: {
        id: `${base}.example`,
        defaultMessage: "Ejemplo",
    },
    sameImage: {
        id: `${base}.sameImage`,
        defaultMessage: "misma imagen",
    },
    samePrice: {
        id: `${base}.samePrice`,
        defaultMessage: "mismo precio",
    },
    blue: {
        id: `${base}.blue`,
        defaultMessage: "Azul",
    },
    green: {
        id: `${base}.green`,
        defaultMessage: "Verde",
    },
    red: {
        id: `${base}.red`,
        defaultMessage: "Rojo",
    },
    productPreview: {
        id: `${base}.productPreview`,
        defaultMessage: "Vista previa del producto",
    },
    useSameImage: {
        id: `${base}.useSameImage`,
        defaultMessage: "Usar la misma imagen",
    },
    useSamePrice: {
        id: `${base}.useSamePrice`,
        defaultMessage: "Usar el mismo precio",
    },

    showTaxSelector: {
        id: `${base}.showTaxSelector`,
        defaultMessage: "Mostrar selector de impuestos",
    },

    showOptionReference: {
        id: `${base}.showOptionReference`,
        defaultMessage: "Mostrar opción de referencia",
    },
});
