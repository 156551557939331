import React from "react";
import { useIntl } from "react-intl";
import { IProduct } from "../../../api/models/product";
import NavigationTabs, { INavigationTabOption, ISelectedTag } from "../../NavigationTabs";
import messages from "./messages";

export enum ProductCreationStage {
    Detail,
    Prices,
    Variants,
    Components,
    More,
    Description,
    Optionals,
}

interface CreationTabsProps extends ISelectedTag<number> {
    product: IProduct;
}

const CreationTabs = (props: CreationTabsProps) => {
    const intl = useIntl();
    const { product } = props;

    const defaultOption: INavigationTabOption<number> = {
        key: ProductCreationStage.Detail,
        title: intl.formatMessage(messages.profile),
    };

    const options = React.useMemo(() => {
        let options: INavigationTabOption<number>[] = [defaultOption];
        if (props.product.isParent) {
            options.push({
                key: ProductCreationStage.Components,
                title: intl.formatMessage(messages.components),
            });
        }
        if (product.includesOptionals) {
            options.push({
                key: ProductCreationStage.Optionals,
                title: intl.formatMessage(messages.optionals),
            });
        }
        if (props.product.includesVariants) {
            options.push({
                key: ProductCreationStage.Variants,
                title: intl.formatMessage(messages.variants),
            });
        }
        if (!props.product.includesVariants) {
            options.push({
                key: ProductCreationStage.Prices,
                title: intl.formatMessage(messages.prices),
            });
            options.push({
                key: ProductCreationStage.More,
                title: intl.formatMessage(messages.more),
            });
        }

        options.push({
            key: ProductCreationStage.Description,
            title: intl.formatMessage(messages.productDescription),
        });
        return options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.includesSerials, product.isParent, product.includesVariants, product.includesOptionals]);

    return <NavigationTabs hidePadding hideBorder options={options} onChange={props.onChange} />;
};
export default CreationTabs;
