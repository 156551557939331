import moment from 'moment';
import React, { useContext } from 'react';
import { OrderInvoiceFormatProps } from '.';
import { getAccountUserFullName } from '../../api/models/accountUser';
import { OrderItem } from '../../api/models/order';
import translate from '../../i18n/translate';
import { AccountUserContext } from '../../store/contexts/AccountUserContext';
import { OrganizationContext } from '../../store/contexts/OrganizationContext';
import { formatMoney, getDateFormatll } from '../../utils';
import { getItemTaxPercentage } from '../../utils/items';
import OrderProfileMessages from "../Orders/OrderProfile/messages";
import { Flex, FlexImageStyle, TextField } from '../_controls';
import { Table } from '../_controls/tables/styled';
import { CardBodyHalfPage, FlexColumn, FormatHalfPage, FormatHalfPageContent, TableInvoiceFormatHalfPage } from './styled';

export const InvoiceFormatHalfPage = (props: OrderInvoiceFormatProps) => {

    const { order, testMode, template } = props;
    const { accountUserState } = useContext(AccountUserContext);
    const { organizationState } = useContext(OrganizationContext);
    const organization = organizationState.currentOrganization;

    if (!order) return null;

    const hasInvoice = !!order.orderInvoice;
    const customer = order.customer;
    const delivery = order.deliveryOrder;
    const invoice = order.orderInvoice;

    const getCustomerFullName = () => {
        if (customer) {
            return customer.firstName + " " + customer.lastName;
        }
        return "Cliente General";
    };

    const getCustomerIdentification = () => {
        let type = customer?.identificationType;
        if (type && type.length > 3) {
            type = type.substring(0, 3);
        }
        return type + ": " + customer?.identificationNumber;
    };
    const getProductSku = (item: OrderItem) => {
        if (item.productVariant) {
            return item.productVariant.sku;
        } else {
            return item.product?.sku;
        }
    };

    const isPartial = order.status === "partial";
    const needPayment = order.totalAmount - order.totalPaid > 0;

    const firstAddress = customer?.addresses && customer.addresses.length > 0 ? customer.addresses[0] : undefined;

    let items = order.items ?? [];

    return (
        <FormatHalfPage>
            <FormatHalfPageContent>
                <CardBodyHalfPage>
                    {!testMode && (
                        <>
                            <TableInvoiceFormatHalfPage cellSpacing="0" cellPadding="0">
                                <thead>
                                    <tr>
                                        <td className="not-padding">
                                            <div className="header-space"> </div>
                                        </td>
                                    </tr>
                                </thead>
                                <thead className="table-header">
                                    <tr>
                                        <th>#</th>
                                        <th>Sku</th>
                                        <th>Descripción</th>
                                        <th>Precio</th>
                                        <th>Cantidad</th>
                                        <th>Desc</th>
                                        <th>Imp</th>
                                        <th className="right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => {
                                        if (item.product || item.productVariant) {
                                            let total = item.unitPrice * (item.quantity - item.quantityReturned);
                                            return (
                                                <tr key={index}>
                                                    <td className="td-center">{index + 1}</td>
                                                    <td>{getProductSku(item) ?? "---"}</td>
                                                    <td>
                                                        {item.product?.name.replace("[Eliminado]" && "[Serial]", "")}
                                                        {item.serials
                                                            ? item.serials.map((serial) => {
                                                                return (
                                                                    <Flex column>
                                                                        <span className="text-smallSerials">{serial}</span>
                                                                    </Flex>
                                                                );
                                                            })
                                                            : ""}
                                                    </td>
                                                    <td>{formatMoney(item.unitPrice)}</td>
                                                    <td>
                                                        {item.quantity - item.quantityReturned}{" "}
                                                        {item.quantityReturned > 0 && `(${-item.quantityReturned})`}
                                                    </td>
                                                    <td>{item.manualPercentageDiscount}%</td>
                                                    <td>{getItemTaxPercentage(item)}%</td>
                                                    <td className="right">
                                                        {formatMoney(total - (total * item.manualPercentageDiscount) / 100)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="not-padding">
                                            <div className="footer-space"> </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </TableInvoiceFormatHalfPage>
                        </>
                    )}
                    <div className="header">
                        <Flex alignStart spaceBetween>
                            {props.image && (
                                <FlexImageStyle className="ml-1" width={40} borderRadius={8}>
                                    <img src={props.image} alt="Org" />
                                </FlexImageStyle>
                            )}
                            <Flex gap={20}>
                                {!testMode && <TextField paddingTop={2} smaller light>Software By Wahio, suite.wahio.com</TextField>}
                                <Flex alignStart border spaceBetween borderRadius={8} padding={10} minWidth={280}>
                                    <Flex column gap5>
                                        {template.invoiceTemplateTitle1 &&
                                            <TextField bold>{template?.invoiceTemplateTitle1}</TextField>
                                        }
                                        <TextField bold>
                                            {hasInvoice ? "" : "Orden"} Nro {hasInvoice ? order.orderInvoice?.number : order.number}
                                        </TextField>
                                    </Flex>
                                    <Flex column gap5>
                                        <TextField>Estado</TextField>
                                        <TextField className={needPayment ? "status-card" : ""}>
                                            {translate(OrderProfileMessages[order.status]).props.defaultMessage}
                                        </TextField>
                                    </Flex>
                                    {isPartial && (
                                        <Flex column gap5>
                                            <TextField>Pagos: {formatMoney(order.totalPaid)}</TextField>
                                            <TextField>Saldo: {formatMoney(order.totalAmount - order.totalPaid)}</TextField>
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                        <div className="grid3 mt-1">
                            <Flex border borderRadius={8} column padding={5} gap5 minHeight={50}>
                                <TextField bold >Cliente: {getCustomerFullName()}</TextField>
                                <Flex gap10>
                                    {customer?.identificationNumber && <TextField >{getCustomerIdentification()}</TextField>}
                                    <TextField>Tel: {customer?.phoneNumber ? customer.phoneNumber : " - - -"}</TextField>
                                </Flex>
                                {!delivery && firstAddress && (
                                    <Flex column gap5>
                                        <TextField>{firstAddress.state}, {firstAddress.city}</TextField>
                                        <TextField>{firstAddress.description} {firstAddress.additionalInformation}</TextField>
                                    </Flex>
                                )}
                                {order.contactSellerId &&
                                    <TextField>Vendedor: {getAccountUserFullName(order.contactSeller)}</TextField>
                                }
                            </Flex>
                            <Flex border borderRadius={8} column padding={5} gap5 minHeight={50}>
                                <TextField bold>Lugar y Fecha de expedición</TextField>
                                <TextField>{organization?.address}</TextField>
                                <TextField>Creado: {getDateFormatll(order.dateCreatedCustom ?? order.dateCreated)} </TextField>
                                {invoice &&
                                    <TextField>Facturado: {getDateFormatll(order.dateCreatedCustom ?? invoice.dateCreated)}</TextField>
                                }
                                <TextField>Creado por: {getAccountUserFullName(accountUserState.items.find((x) => x.id === order.accountUserId))}</TextField>

                            </Flex>
                            <Flex border borderRadius={8} column padding={5} spaceBetween minHeight={50}>
                                <Flex>
                                    {order.paymentTermsDays !== 0 && order.status !== "paid" && (
                                        <Flex column gap5>
                                            <TextField bold>Vencimiento</TextField>
                                            <TextField>Término: {order.paymentTermsDays} Días</TextField>
                                            <Flex gap={3}>
                                                <TextField>Vence:</TextField>
                                                <TextField>
                                                    {moment(order.dateCreatedCustom ?? order.dateCreated)
                                                        .add(order.paymentTermsDays, "days")
                                                        .format("LL")}
                                                </TextField>
                                            </Flex>
                                        </Flex>
                                    )}
                                </Flex>
                                <Flex gap5 alignEnd justifyEnd>
                                    <TextField style={{ width: "50px" }}>Firma</TextField>
                                    <Flex style={{ width: "100%", height: "1px", background: "grey" }}></Flex>
                                </Flex>
                            </Flex>
                        </div>
                    </div>
                    <Flex column marginTop={10} className="footer">
                        <Flex className="grid3">
                            <Flex column minHeight={60} border borderRadius={8} padding={10} gap5>
                                {delivery && (
                                    <>
                                        <TextField className="bold">Enviar a: {delivery.contactName}</TextField>
                                        <TextField className="m-0">Teléfono: {delivery.phoneNumber}</TextField>
                                        <TextField className="m-0"> Ciudad: {delivery.city}/{delivery.state}</TextField>
                                        <TextField className="m-0">Dirección: {delivery.address}</TextField>
                                        <TextField className="m-0">{delivery.additionalInformation}</TextField>
                                    </>
                                )}
                                {order.comment && !delivery && (
                                    <TextField>Observaciones:</TextField>
                                )}
                            </Flex>
                            <Flex column className="text-template" minHeight={60} border borderRadius={8} padding={10} gap={2}>
                                {props.numeration && props.numeration.description &&
                                    <Flex column>
                                        <TextField smaller>{props.numeration.description}</TextField>
                                    </Flex>
                                }
                                {(template.invoiceTemplateTitle2 || template.invoiceTemplateTitle3) && (
                                    <>
                                        {template.invoiceTemplateTitle2 && (
                                            <TextField smaller>{template.invoiceTemplateTitle2}</TextField>
                                        )}
                                        <TextField smaller>{template.invoiceTemplateTitle3}</TextField>
                                    </>
                                )}
                            </Flex>
                            <Flex column minHeight={60} border borderRadius={8} padding={10} gap5>
                                {order.moneyReceived > 0 && (
                                    <FlexColumn className="invoice-details">
                                        <span>Cantidad recibida: {formatMoney(order.moneyReceived)}</span>
                                        <span className="bold">Cambio: {formatMoney(order.moneyReturned)}</span>
                                    </FlexColumn>
                                )}
                                <Table cellSpacing={0} cellPadding={0} widthAuto className='total-table' hideBorde>
                                    <tbody>
                                        <tr>
                                            <td>Subtotal</td>
                                            <td>{formatMoney(order.subTotal)}</td>
                                        </tr>
                                        <tr>
                                            <td>Impuesto</td>
                                            <td>{formatMoney(order.totalTaxItems)}</td>
                                        </tr>
                                        {order.totalManualPercentageDiscountItems > 0 && (
                                            <tr>
                                                <td>Descuento</td>
                                                <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                                            </tr>
                                        )}
                                        {order.totalDiscount > 0 && (
                                            <tr>
                                                <td>Descuento General</td>
                                                <td>{formatMoney(order.totalDiscount)}</td>
                                            </tr>
                                        )}
                                        {order.totalAmountReturns > 0 && (
                                            <tr>
                                                <td>Devolución</td>
                                                <td>{formatMoney(order.totalAmountReturns)}</td>
                                            </tr>
                                        )}
                                        {order.totalTip > 0 && (
                                            <tr>
                                                <td>Propina</td>
                                                <td>{formatMoney(order.totalTip)}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td className="bold">TOTAL</td>
                                            <td className="bold">{formatMoney(order.totalAmount)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Flex>
                        </Flex>
                    </Flex>
                </CardBodyHalfPage>
            </FormatHalfPageContent>
        </FormatHalfPage>
    )
}
