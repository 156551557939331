export const REQUEST_PRODUCT_CATEGORIES = "REQUEST_PRODUCT_CATEGORIES";
export const RECEIVE_PRODUCT_CATEGORIES = "RECEIVE_PRODUCT_CATEGORIES";
export const INVALID_REQUEST_PRODUCT_CATEGORIES = "INVALID_REQUEST_PRODUCT_CATEGORIES";

export interface IProductCategory {
    id?: string;
    name: string;
    dateCreated: Date;
    globalCategoryId: string;
    categoryParentId: string;
    image?: string;
    accountId: string;
    verified: boolean;
    status: boolean;
    firstProductName?: string;
    productCount: number;
    accumulatedSales: number;
    sortIndex: number;
    childs: IProductCategory[];
}
export class ProductCategory implements IProductCategory {
    id?: string;
    name: string = "";
    dateCreated: Date = new Date();
    globalCategoryId: string = "";
    categoryParentId: string = "";
    image: string = "";
    accountId: string = "";
    verified: boolean = false;
    status: boolean = false;
    childs: IProductCategory[] = [];
    firstProductName?: string;
    productCount: number = 0;
    accumulatedSales: number = 0;
    sortIndex: number = 0;

    constructor(accountId: string, name?: string, image?: string) {
        this.name = name ? name : "";
        this.accountId = accountId;
        this.image = image ? image : "";
    }
}

export interface CustomCategorySortedFilter {
    accountId: string;
    customCategoryIds?: string[];
    customCategoryExcludedIds?: string[];
}

export interface CustomCategoryBulkIndex {
    accountId: string;
    categoryListSorted: string[];
}

export interface IProductCategoryState {
    items: IProductCategory[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestProductCategories {
    type: typeof REQUEST_PRODUCT_CATEGORIES;
    custom?: boolean;
}
export interface ReceiveProductCategories {
    type: typeof RECEIVE_PRODUCT_CATEGORIES;
    items: Array<IProductCategory>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_PRODUCT_CATEGORIES;
    value: any;
}

export type ProductCategoryTypes = RequestProductCategories | ReceiveProductCategories | InvalidRequest;
