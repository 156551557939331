import * as HoverCard from "@radix-ui/react-popover";
import React, { useEffect, useState } from "react";
import { TRACK_ENDPOINT } from "../../../api/appConfig";
import { ShipmentSettings } from "../../../api/models/accountUser";
import { DeliveryStatus } from "../../../api/models/deliveryOrder";
import { IOrderPicking, Order } from "../../../api/models/order";
import { OrderType } from "../../../api/models/orderBase";
import { COLOR_LIST } from "../../../constants/colors";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { formatMoney, getDateFromNow, getModelFullName, ignoreElementClick, joinUrl } from "../../../utils";
import { ButtonCreateOrderOrder } from "../../AccountingPayments/AccountingPanel/AccountingButtonPayment";
import DateTimeLabel from "../../DatePicker/DateTimeLabel";
import LoadingDualRing from "../../LoadingDualRing";
import CardIcon from "../../_Cards/CardIcon";
import { Flex, SquareButton, TextField } from "../../_controls";
import { HoverCardContentStyle } from "../../_controls/PopoverRadix/hoverCardStyle";
import { ProgressBarValue } from "../../_controls/progressBars/ProgressBar";
import Modal from "../../modals/Modal";
import OrderProfile from "../OrderProfile";
import ButtonEditOrder from "../OrderProfile/ButtonEditOrder";
import { MoreDetailsTableView } from "../OrderProfile/NavigationDetails";
import InvoicePrint from "../OrderProfile/OrderInvoicePrint";
import OrderStatusLine from "../OrderStatusLine";
import { OrderCardBody, OrderCardContainer, ShipmentStatusLine } from "./styled";

export type OrderCardViewType = "shipmentStep" | "address";

interface OrderCardViewProps {
    order: Order;
    onSelected?: (value: Order) => void;
    isSelected?: boolean;
    hideShipmentSteps?: boolean;
    hideShipmentAddress?: boolean;
    showOrderOnClick?: boolean;
    showPicking?: boolean;
    orderType?: OrderType;
    shipmentSettings?: ShipmentSettings;
    hideHoverContent?: boolean;
}

interface PickingViewProps {
    orderPicking?: IOrderPicking;
    status?: DeliveryStatus;
}

const PickingView = (props: PickingViewProps) => {
    const { orderPicking, status } = props;

    if (status === "processing") {
        return <CardIcon icon="wahioicon-clock" smallIcon iconColor={COLOR_LIST.blue} title="Listo para iniciar" />;
    }

    if (orderPicking) {
        if (orderPicking.totalQuantity === orderPicking.totalQuantityReceived) {
            return <CardIcon icon="wahioicon-check" smallIcon iconColor={COLOR_LIST.green} title="Completado" />;
        }
        return (
            <ProgressBarValue title="Picking" limitValue={orderPicking.totalQuantity} currentValue={orderPicking.totalQuantityReceived} />
        );
    }
    return null;
};

export default function OrderCardView(props: OrderCardViewProps) {
    const [order, setOrder] = useState(props.order);
    const [showInfo, setShowInfo] = useState(false);

    const { getStatusItem } = useClientSettings();

    useEffect(() => {
        setOrder(props.order);
    }, [props.order]);

    const [orderState, setOrderState] = useState({
        isFetching: false,
        isLoaded: false,
        showOrderModal: false,
    });

    const statusItem = React.useMemo(() => {
        if (!order.deliveryOrder) return null;
        return getStatusItem(order.deliveryOrder.status);
    }, [getStatusItem, order.deliveryOrder]);

    const customer = order.customer;

    const getCustomerName = () => {
        if (customer) {
            return getModelFullName(customer);
        } else {
            return "Cliente General";
        }
    };

    const onClickCard = (e: any) => {
        const id = e.target.id;
        var ignore = ignoreElementClick(e.target, "ignore-click");
        if (id === "btn-show-order" || ignore) return;

        if (props.onSelected) {
            props.onSelected(order);
        } else if (props.showOrderOnClick) {
            setOrderState({ ...orderState, showOrderModal: true });
        }
    };

    const orderPicking = order.orderPicking;

    return (
        <>
            {orderState.showOrderModal && (
                <Modal
                    sizeType="md"
                    title={`Orden: #${order.number}`}
                    showHeader={true}
                    setShow={(value) => setOrderState({ ...orderState, showOrderModal: value })}
                    show={orderState.showOrderModal}
                    id={`order-${order.id}`}
                >
                    <OrderProfile onUpdateOrder={setOrder} showSimpleView={true} order={order} />
                </Modal>
            )}
            <OrderCardContainer>
                {!props.hideHoverContent && (
                    <Flex alignCenter gap10 className="hover-comp" id="ignore-click">
                        <HoverCard.Root onOpenChange={(open) => setShowInfo(open)}>
                            <HoverCard.Trigger asChild>
                                <SquareButton title="Ver información">
                                    <i className="fa-regular fa-info"></i>
                                </SquareButton>
                            </HoverCard.Trigger>

                            <HoverCard.Portal>
                                <HoverCardContentStyle>
                                    <HoverCard.Content
                                        side="right"
                                        align="start"
                                        className="HoverCardContent"
                                        style={{ width: "360px", maxWidth: "360px", padding: "20px" }}
                                        sideOffset={5}
                                    >
                                        {showInfo && (
                                            <MoreDetailsTableView
                                                showDeliveryInfo
                                                order={order}
                                                onOrderChange={() => {}}
                                                fetchOrder={() => {}}
                                            />
                                        )}

                                        <HoverCard.Arrow className="HoverCardArrow" />
                                    </HoverCard.Content>
                                </HoverCardContentStyle>
                            </HoverCard.Portal>
                        </HoverCard.Root>

                        <a
                            href={joinUrl(TRACK_ENDPOINT, order.trackingNumber ?? "NA")}
                            rel="noopener noreferrer"
                            title="Compartir orden"
                            target="_blank"
                        >
                            <SquareButton>
                                <i className="fa-regular fa-share"></i>
                            </SquareButton>
                        </a>

                        <ButtonEditOrder order={order} onChange={(order) => setOrder(order)} />

                        {(order.status === "credit" || order.status === "partial") && order.invoiceAvailable && (
                            <ButtonCreateOrderOrder order={order} onChange={(order) => setOrder(order)} />
                        )}

                        <InvoicePrint order={order} />
                    </Flex>
                )}
                <OrderCardBody className={`order-body ${props.isSelected ? "selected" : ""}`} onClick={(e) => onClickCard(e)}>
                    <Flex spaceBetween alignCenter className="card-header">
                        <Flex gap5 alignCenter>
                            <span className={`card-header-title ${!customer ? "general" : ""}`}>{getCustomerName()}</span>
                        </Flex>
                        <Flex alignCenter gap10>
                            {order.allowLogisticsService && (
                                <span title="Bodega con logística" className="wahioicon-boxes logistics-icon"></span>
                            )}
                            <DateTimeLabel
                                small
                                bold
                                date={order.invoiceCreatedAt ?? order.dateCreated}
                                className={!props.hideHoverContent ? "hide-on-hover" : ""}
                            />
                        </Flex>
                    </Flex>

                    {props.showPicking && <PickingView orderPicking={orderPicking} status={order.deliveryOrder?.status} />}
                    {orderState.isFetching && <LoadingDualRing center small />}

                    {order && order.orderTable && (
                        <TextField small light>
                            Mesa {order.orderTable.tableNumber}
                        </TextField>
                    )}
                    {order.eBillingNumber && (
                        <TextField small light title="Factura electronica">
                            {order.eBillingNumber}
                        </TextField>
                    )}
                    {!props.showPicking && (
                        <Flex alignCenter spaceBetween marginTop={5}>
                            <Flex alignCenter gap10 flexWrap>
                                <OrderStatusLine useIcon size="sm" status={order.status} />
                                <TextField>#{order.number}</TextField>

                                {statusItem && !props.showPicking && !props.hideShipmentSteps && (
                                    <ShipmentStatusLine className={`status-${order.deliveryOrder?.status ?? ""}`}>
                                        <span className={`status-icon ${statusItem.icon}`} />
                                        {statusItem.title}
                                    </ShipmentStatusLine>
                                )}

                                {order.deliveryOrder?.cashOnDeliveryOrder && (
                                    <TextField title="Pago contra entrega" small className="delivery fa-regular fa-truck" />
                                )}
                                {order.commentsCount > 0 && (
                                    <Flex gap10 alignCenter>
                                        <TextField className="fa-regular fa-message-lines"></TextField> {order.commentsCount}
                                    </Flex>
                                )}
                            </Flex>
                            <TextField bold>{formatMoney(order.totalAmount)}</TextField>
                        </Flex>
                    )}
                </OrderCardBody>
            </OrderCardContainer>
        </>
    );
}

export const OrderDateView = ({ order, orderType }: { order: Order; orderType?: OrderType }) => {
    if (orderType === "invoice" && order.orderInvoice) {
        return (
            <TextField small light>
                {getDateFromNow(order.orderInvoice.dateCreated)}
            </TextField>
        );
    } else {
        return (
            <TextField small light>
                {getDateFromNow(order.dateCreated)}
            </TextField>
        );
    }
};
