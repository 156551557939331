import React from "react";
import { IPurchaseItem } from "../../../api/models/purchase";
import { IWarehouseLocation } from "../../../api/models/warehouseLocation";
import { COLOR_LIST, getColorValue } from "../../../constants/colors";
import { getProductThumbnailUrl, getProductSkuAndCode } from "../../Products/helper";
import ProductSerials from "../../Products/ProductSerials";
import TextBoxNumeral from "../../TextBoxNumeral";
import WarehouseLocationSelect from "../../Warehouses/WarehouseLocations/WarehouseLocationSelect";
import { SquareButton } from "../../_controls";
import { LocationContainer, PurchaseReceiveItemViewCard } from "./styled";

interface IPurchaseItemReceive {
    purchaseItem: IPurchaseItem;
    onChange: (item: IPurchaseItem) => void;
    isSelected: boolean;
    allowLogisticsService: boolean;
    locations: IWarehouseLocation[];
    listOfRepeatedSerials?: string[];
}

export default function PurchaseItemReceive(props: IPurchaseItemReceive) {
    const { purchaseItem } = props;
    const { product, productVariant } = purchaseItem;
    const image = getProductThumbnailUrl(product, productVariant);

    const updateQuantity = (value: number) => {
        const difference = purchaseItem.quantity - purchaseItem.quantityReturned - (purchaseItem.quantityReceived + value);
        let copyValue = value;
        if (difference < 0) {
            copyValue = purchaseItem.quantity - purchaseItem.quantityReturned - purchaseItem.quantityReceived;
        }

        if (copyValue < 0) {
            copyValue = 0;
        }
        let copyItem = { ...purchaseItem, quantityReceivedTemp: copyValue };
        props.onChange(copyItem);
    };

    const increaseQuantity = (value: number) => {
        var tempValue = purchaseItem.quantityReceivedTemp ?? 0;
        updateQuantity(tempValue + value);
    };

    const onLocationChange = (location?: IWarehouseLocation) => {
        props.onChange({ ...props.purchaseItem, locationCode: location?.code, location });
    };

    const isComplete = purchaseItem.quantity - purchaseItem.quantityReturned === purchaseItem.quantityReceived;

    const invalidLocation = purchaseItem.locationCode && !purchaseItem.location;

    const onChangeSerials = (serials: string[]) => {
        props.onChange({ ...purchaseItem, newSerials: serials, quantityReceivedTemp: serials.length });
    };

    return (
        <PurchaseReceiveItemViewCard
            id={product?.id}
            locationColor={(COLOR_LIST as any)[props.purchaseItem.location?.color ?? "notColor"]}
            className={`purchase-item-receive ${props.isSelected ? "selected" : ""} ${invalidLocation ? "invalid-location" : ""}`}
        >
            <div className="image">
                <img src={image} alt={product?.name} />
            </div>
            <div className="content">
                <div className="flex-space">
                    <div className="FlexCol">
                        <span className="title">
                            {purchaseItem.product?.name} {purchaseItem.locationCode}
                        </span>
                        {productVariant && <span className="subtitle">Variante: {productVariant.valuesLine}</span>}
                        <span className="subtitle">{getProductSkuAndCode(product, productVariant)}</span>
                    </div>

                    <div className="flex">
                        {props.allowLogisticsService && (
                            <LocationContainer className="mr-1" color={getColorValue(props.purchaseItem.location?.color)}>
                                <div className="location-circle"></div>
                                <WarehouseLocationSelect
                                    selectedLocation={props.purchaseItem.location}
                                    onLocationChange={onLocationChange}
                                    locations={props.locations}
                                    showNormalSize
                                />
                            </LocationContainer>
                        )}

                        <div className="quantity-controls mr-1">
                            {!purchaseItem.product?.includesSerials && (
                                <>
                                    <SquareButton onClick={() => increaseQuantity(-1)}>
                                        <span className="wahioicon-minus"></span>
                                    </SquareButton>
                                    {!isComplete && (
                                        <TextBoxNumeral
                                            format="number"
                                            onNumberChange={updateQuantity}
                                            value={purchaseItem.quantityReceivedTemp}
                                        />
                                    )}
                                    <SquareButton className="mr-1" onClick={() => increaseQuantity(1)}>
                                        <span className="wahioicon-plus"></span>
                                    </SquareButton>
                                    <SquareButton onClick={() => updateQuantity(purchaseItem.quantity - purchaseItem.quantityReturned - purchaseItem.quantityReceived)}>
                                        <span className="wahioicon-fill"></span>
                                    </SquareButton>
                                </>
                            )}
                        </div>

                        <span className="light ml-1">Restante: {purchaseItem.quantity - (purchaseItem.quantityReceived + (purchaseItem.quantityReceivedTemp ?? 0))}</span>
                    </div>
                </div>

                {purchaseItem.product?.includesSerials && (
                    <ProductSerials
                        serials={purchaseItem.newSerials ?? []}
                        onChange={onChangeSerials}
                        serialLimit={purchaseItem.quantity - (purchaseItem.quantityReceived)}
                        useLimit
                    />
                )} 
            </div>
        </PurchaseReceiveItemViewCard>
    );
}
