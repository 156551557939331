import React, { createContext, useContext, useEffect, useReducer } from "react";
import { saveData, LocalStoreKeys } from "../store/index";
import { ISettingsActions, useActions } from "./actions";
import { applyMiddleware } from "./middleware";
import { appSettingsReducer, initState } from "./reducer";
import { IAppSettingsState } from "./types";

interface IContextProps {
    appSettingsState: IAppSettingsState;
    appSettingsActions: ISettingsActions;
}

export const AppSettingsContext = createContext({} as IContextProps);

const AppSettingsContextProvider = (props: any) => {
    const [appSettingsState, dispatch] = useReducer(appSettingsReducer, initState);

    useEffect(() => {
        saveData(LocalStoreKeys.appSettings, appSettingsState);
    }, [appSettingsState]);

    const appSettingsActions = useActions(appSettingsState, applyMiddleware(dispatch));
    return <AppSettingsContext.Provider value={{ appSettingsState, appSettingsActions }}>{props.children}</AppSettingsContext.Provider>;
};

export const useAppSettingsContext = () => {
    return useContext(AppSettingsContext);
};

export default AppSettingsContextProvider;
