import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useIsMobileListener } from "../..";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { Contact, ContactType } from "../../../api/models/contact";
import { QUERY_CONTACT_LIST_SELECT } from "../../../api/queryKeys";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import PATHS from "../../../constants/paths";
import { UserContext } from "../../../store/contexts/UserContext";
import { stringIsNullOrEmpty } from "../../../utils";
import ColombiaStatesSelector from "../../Addresses/States/ColombiaStatesSelector";
import { useAlert } from "../../Alerts/Alert";
import CustomDateInput from "../../DatePicker/CustomDateInput";
import DefaultDataSelector from "../../ElectronicBillings/EBillingData/EBillingDataSelector";
import LoadingDualRing from "../../LoadingDualRing";
import PermissionDenied from "../../PermissionDenied";
import WahioCloudImageSelector from "../../WahioCloud/WahioCloudImageSelector";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, TextField } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";
import { IModalShow } from "../../modals/Modal";
import CustomerAddressesView from "./ContactAddressesView";
import ContactEmailsView from "./ContactEmailsView";
import CustomerPhonesView from "./ContactPhonesView";
import { getExistingContact, getInitialContact } from "./contactHelper";
import messages from "./messages";
import { ContactMainContainer, LabelOptionContact } from "./styled";

export interface CreateContactFormProps extends IModalShow {
    onCreate: (value: Contact) => void;
    contact?: Contact;
    customerNameOrDocument?: string;
    redirectToProfile?: boolean;
    defaultContactType?: ContactType;
}

export interface IContactProps {
    id: string;
    currentCustomer: Contact;
    setCurrentCustomer: (value: Contact) => void;
}

interface ICustomerResponse {
    isFetching: boolean;
    isError: boolean;
    error?: any;
    value?: Contact;
}

const getEBillingViewStatus = (contact?: Contact) => {
    if (!contact) return false;
    return contact.typeLiabilityId || contact.typePersonId || contact.typeRegimeId || contact.cityId;
};

export default function CreateContactForm(props: CreateContactFormProps) {
    const intl = useIntl();
    const alert = useAlert();
    const { userState } = useContext(UserContext);

    const [showEBillingFields, setShowEBillingFields] = useState(getEBillingViewStatus(props.contact));
    const [customerResult, setCustomerResult] = useState<ICustomerResponse>({
        isFetching: false,
        isError: false,
    });

    const isMobile = useIsMobileListener({ size: 800 });
    const queryClient = useQueryClient();

    const getContactLoaded = () => {
        if (props.contact) {
            return getExistingContact({ ...props.contact });
        }
        let initialContact = getInitialContact(props.customerNameOrDocument);

        if (props.defaultContactType) {
            initialContact[props.defaultContactType] = true;
        }

        return initialContact;
    };

    const [contact, setContact] = useState(() => getContactLoaded());
    const navigate = useNavigate();

    const onChange = (e: any) => {
        let contactCopy = { ...contact, [e.target.name]: e.target.value };
        let addresses = contact.addresses;

        if (e.target.name === "firstName" || e.target.name === "lastName") {
            if (addresses && addresses.length > 0) {
                addresses[0].contactName = contactCopy.firstName + " " + contactCopy.lastName;
            }
        }

        setContact({ ...contactCopy, addresses });
    };

    const finalizeDisabled = stringIsNullOrEmpty(contact.firstName);

    const onSummit = () => {
        setCustomerResult({ ...customerResult, isFetching: true });
        const optionFetch = contact.id ? wahioFetch.put : wahioFetch.post;
        const url = contact.id ? localOrderEndpoint.put.customerAll : localOrderEndpoint.post.customer;
        if (contact.emails[0].email === "") {
            contact.email = "";
        }

        return optionFetch(
            url,
            contact,
            (res) => {
                queryClient.fetchQuery(QUERY_CONTACT_LIST_SELECT);
                setCustomerResult({
                    ...customerResult,
                    isFetching: false,
                    value: res.data,
                    isError: false,
                });

                alert.success(intl.formatMessage(messages.clientUpdated));
                props.onCreate(res.data);
                if (props.redirectToProfile) {
                    navigate(PATHS.contactProfile(res.data.id));
                }
            },
            (err) => {
                setCustomerResult({
                    ...customerResult,
                    isFetching: false,
                    value: undefined,
                    isError: true,
                    error: err,
                });
                alert.error(getServiceMessageError(err));
            }
        );
    };

    const clearCustomerState = () => {
        props.setShow(false);
    };

    if (!contact.id && !getUserPermissions(userState.user).contact?.create) {
        return <PermissionDenied message={intl.formatMessage(messages.notPermission)} />;
    }
    if (contact.id && !getUserPermissions(userState.user).contact?.update) {
        return <PermissionDenied message={intl.formatMessage(messages.notUpdate)} />;
    }

    const setBirthdayDate = (date: Date) => {
        if (!date) return;
        date.setHours(0, 0, 0, 0);
        setContact({ ...contact, birthday: date });
    };

    return (
        <ContactMainContainer>
            <Flex column gap15 marginBottom={15}>
                <TextField bold>Tipo de contacto</TextField>
                <Flex gap15 flexWrap>
                    <CheckBox
                        checked={contact.isCompany}
                        onChange={() => setContact({ ...contact, isCompany: !contact.isCompany })}
                        title="Empresa"
                    />
                    <CheckBox
                        checked={contact.isCourier}
                        onChange={() => setContact({ ...contact, isCourier: !contact.isCourier })}
                        title="Mensajero"
                    />
                    <CheckBox
                        checked={contact.isSeller}
                        onChange={() => setContact({ ...contact, isSeller: !contact.isSeller })}
                        title="Vendedor"
                    />
                    <CheckBox
                        checked={contact.isSupplier}
                        onChange={() => setContact({ ...contact, isSupplier: !contact.isSupplier })}
                        title="Proveedor"
                    />
                    <CheckBox
                        checked={contact.isCustomer}
                        onChange={() => setContact({ ...contact, isCustomer: !contact.isCustomer })}
                        title="Cliente"
                    />
                </Flex>
            </Flex>

            <Flex column gap15>
                <h4 className="subtitle">{intl.formatMessage(messages.personalInformation)}</h4>
                {isMobile ? (
                    <>
                        <Flex column gap15>
                            <Flex alignCenter gap15 w100>
                                <WahioCloudImageSelector
                                    image={contact.image}
                                    onSelectImage={(image) => setContact({ ...contact, image })}
                                />
                                <ContactIdentificationRow contact={contact} setContact={setContact} onChange={onChange} />
                            </Flex>
                            <ContactNamesRow setContact={setContact} contact={contact} onChange={onChange} />
                        </Flex>
                    </>
                ) : (
                    <>
                        <Flex gap={30} alignCenter>
                            <WahioCloudImageSelector image={contact.image} onSelectImage={(image) => setContact({ ...contact, image })} />
                            <Flex column gap15 w100>
                                <ContactIdentificationRow setContact={setContact} contact={contact} onChange={onChange} />
                                <ContactNamesRow setContact={setContact} contact={contact} onChange={onChange} />
                            </Flex>
                        </Flex>
                    </>
                )}
            </Flex>

            <Flex column gap15>
                <h4 className="subtitle">{intl.formatMessage(messages.optionalFields)}</h4>
                <ContactLabelsOptions
                    id="create-customer"
                    key="create-customer"
                    currentCustomer={contact}
                    setCurrentCustomer={setContact}
                />
                <Flex column gap5 alignStart>
                    <Flex column gap5>
                        <TextField light small>
                            {intl.formatMessage(messages.birthday)}
                        </TextField>
                        <CustomDateInput
                            hideTimeSelector
                            date={new Date(contact.birthday ?? "")}
                            onDateChange={(value) => setBirthdayDate(value)}
                        />
                    </Flex>
                </Flex>

                <Flex column className="mt-1" gap15>
                    <Flex alignCenter gap15>
                        <h4 className="subtitle ">{intl.formatMessage(messages.invoiceElectronic)}</h4>
                        <DefaultButton small rounded onClick={() => setShowEBillingFields(!showEBillingFields)}>
                            {showEBillingFields ? (
                                <>
                                    <span className="wahioicon-angle-up"></span>
                                    Ocultar
                                </>
                            ) : (
                                <>
                                    <span className="wahioicon-angle-down"></span> Mostrar
                                </>
                            )}
                        </DefaultButton>
                    </Flex>
                    {showEBillingFields && <EBillingFields contact={contact} setContact={setContact} />}
                </Flex>
            </Flex>

            <Flex justifyEnd gap10 className="mt-1">
                <DefaultButton rounded disabled={customerResult.isFetching} type="button" onClick={clearCustomerState}>
                    <FormattedMessage {...messages.cancel} />
                </DefaultButton>
                <PrimaryButton rounded disabled={customerResult.isFetching || finalizeDisabled} onClick={onSummit}>
                    {contact.id ? "Actualizar Contacto" : "Crear Contacto"}
                    {customerResult.isFetching && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </ContactMainContainer>
    );
}

interface ContactFieldRowProps {
    contact: Contact;
    setContact: (contact: Contact) => void;
    onChange: (e: any) => void;
}

const ContactLabelsOptions = (props: IContactProps) => {
    return (
        <Flex column gap={20}>
            <Flex column>
                <LabelOptionContact htmlFor="title">
                    <FormattedMessage {...messages.email} />
                </LabelOptionContact>
                <ContactEmailsView key={props.id} {...props} />
            </Flex>

            <Flex column>
                <LabelOptionContact className="font-md text-secondary" htmlFor="title">
                    <FormattedMessage {...messages.phone} />
                </LabelOptionContact>
                <CustomerPhonesView key={props.id} {...props} />
            </Flex>
            <Flex column>
                <LabelOptionContact className="font-md text-secondary" htmlFor="title">
                    <FormattedMessage {...messages.address} />
                </LabelOptionContact>
                <CustomerAddressesView key={props.id} {...props} />
            </Flex>
        </Flex>
    );
};

interface EBillingFieldsProps {
    contact: Contact;
    setContact: (value: Contact) => void;
}

const EBillingFields = (props: EBillingFieldsProps) => {
    const { contact, setContact } = props;
    const intl = useIntl();
    return (
        <Flex flexWrap gap15>
            <DefaultDataSelector
                type={"typeOrganizations"}
                onSelectedChange={(value) => {
                    setContact({ ...contact, typePersonId: value.id.toString() });
                }}
                selectedId={contact.typePersonId ? parseInt(contact.typePersonId) : undefined}
                title={intl.formatMessage(messages.personType)}
            />
            <DefaultDataSelector
                type={"typeRegimes"}
                onSelectedChange={(value) => {
                    setContact({ ...contact, typeRegimeId: value.id.toString() });
                }}
                selectedId={contact.typeRegimeId ? parseInt(contact.typeRegimeId) : undefined}
                title={intl.formatMessage(messages.typeRegime)}
            />
            <DefaultDataSelector
                type={"typeLiabilities"}
                onSelectedChange={(value) => {
                    setContact({ ...contact, typeLiabilityId: value.id.toString() });
                }}
                selectedId={contact.typeLiabilityId ? parseInt(contact.typeLiabilityId) : undefined}
                title={intl.formatMessage(messages.typeLiability)}
            />
            <ColombiaStatesSelector
                initialCityId={contact.cityId ? parseInt(contact.cityId) : undefined}
                onCityChange={(value) => setContact({ ...contact, cityId: value.id.toString() })}
            />
        </Flex>
    );
};

export const ContactNamesRow = (props: ContactFieldRowProps) => {
    const { contact, onChange } = props;
    const intl = useIntl();

    return (
        <Flex gap15>
            <Flex column gap5 w100>
                <span className="text-light text-small">{contact.isCompany ? "Nombre de la empresa" : "Nombre"}</span>
                <DefaultInput
                    rounded
                    placeholder={contact.isCompany ? "Nombre de la empresa" : "Nombre"}
                    value={contact.firstName}
                    name="firstName"
                    onChange={onChange}
                    w100
                />
            </Flex>

            {!contact.isCompany && (
                <Flex column gap5 w100>
                    <span className="text-light text-small">{intl.formatMessage(messages.lastName)}</span>
                    <DefaultInput
                        rounded
                        placeholder={intl.formatMessage(messages.lastName)}
                        value={contact.lastName}
                        name="lastName"
                        onChange={onChange}
                        w100
                    />
                </Flex>
            )}
        </Flex>
    );
};

export const ContactIdentificationRow = (props: ContactFieldRowProps) => {
    const { contact, onChange, setContact } = props;
    const intl = useIntl();

    return (
        <Flex gap15 columnMobile w100>
            <Flex w100>
                <DefaultDataSelector
                    type={"typeDocumentIdentifications"}
                    selectedId={contact.typeDocumentIdentificationId ? parseInt(contact.typeDocumentIdentificationId) : undefined}
                    onSelectedChange={(data) =>
                        setContact({
                            ...contact,
                            identificationType: data.name,
                            typeDocumentIdentificationId: data.id.toString(),
                        })
                    }
                    title={"Tipo de Identificación"}
                    w100
                />
            </Flex>
            <Flex column gap5 w100>
                <span className="text-light text-small">{intl.formatMessage(messages.identificationNumber)}</span>
                <DefaultInput
                    rounded
                    placeholder={intl.formatMessage(messages.identificationNumber)}
                    value={contact.identificationNumber}
                    name="identificationNumber"
                    onChange={onChange}
                />
            </Flex>
        </Flex>
    );
};
