import React, { Ref, useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import ReactToPrint from "react-to-print";
import { AccountUser } from "../../../../api/models/accountUser";
import { ICashRegisterEntry } from "../../../../api/models/cashRegister";
import { IOrganization } from "../../../../api/models/organizationTypes";
import messages from "../../../../messages";
import { useAccountUserContext } from "../../../../store/contexts/AccountUserContext";
import { OrganizationContext } from "../../../../store/contexts/OrganizationContext";
import { formatMoney, getDateFormat, getModelFullName } from "../../../../utils";
import { DefaultButton, Flex, SquareButton, TextField } from "../../../_controls";
import { Table } from "../../../_controls/tables/styled";
import { PaymentFormatPrintContentSmall } from "../styled";

interface PaymentReceiptPrintProps {
    small?: boolean;
    entry: ICashRegisterEntry;
    btnTitle?: string;
}

export const PaymentReceiptPrint = (props: PaymentReceiptPrintProps) => {
    const { organizationState } = useContext(OrganizationContext);
    const { getUserById, accountUserState } = useAccountUserContext();
    const componentRef = useRef<any>(<div />);
    const buttonTriggerRef = useRef<any>();
    const [entry, setEntry] = useState(props.entry);
    let currentOrganization = organizationState.currentOrganization;

    const entryUser = React.useMemo(() => {
        return getUserById(entry.accountUserId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry.accountUserId, accountUserState.items]);

    useEffect(() => {
        setEntry(props.entry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entry]);

    if (!currentOrganization) return null;

    const Button = props.btnTitle ? DefaultButton : SquareButton;

    return (
        <>
            <ReactToPrint
                pageStyle={`@page {
                    size: auto;
                    margin: 5mm;
                    background: white;
                }
                @media print {
                    html, body {
                        background: white;
                        height: initial !important;
                        overflow: initial !important;
                        -webkit-print-color-adjust: exact;                     
                    }
                    
                }
                h4.class-name
                    @page{
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    }
      `}
                trigger={() => {
                    return (
                        <Button title="Imprimir" bgLight ref={buttonTriggerRef} small={props.small} borderRadius={10}>
                            <span className="fa-regular fa-print" /> {props.btnTitle}
                        </Button>
                    );
                }}
                content={() => componentRef.current}
            />

            <div style={{ display: "none", background: "white", width: "100%" }}>
                <PrintFormat user={entryUser} organization={currentOrganization} ref={componentRef} entry={entry} />
            </div>
        </>
    );
};

interface PaymentFormatProps {
    entry: ICashRegisterEntry;
    organization: IOrganization;
    user?: AccountUser;
}

export const PrintFormat = React.forwardRef((props: PaymentFormatProps, ref: Ref<HTMLDivElement>) => {
    const { organization, entry } = props;
    const { contact } = entry;
    const intl = useIntl();

    const entryItems = entry.items ?? [];

    return (
        <PaymentFormatPrintContentSmall ref={ref}>
            <Flex alignStart spaceBetween gap={20} flexWrap>
                <Flex gap10 alignCenter className="company-card">
                    {organization.image && (
                        <div className="image-container">
                            <img src={organization.image} alt="organización" />
                        </div>
                    )}
                    <Flex column justifyCenter>
                        <TextField bold>{organization.name}</TextField>
                        <TextField>{organization.address}</TextField>
                        <TextField>{organization.city}</TextField>
                    </Flex>
                </Flex>
                <Flex column fitContent>
                    <TextField bold fontSize={14}>
                        Comprobante de pago
                    </TextField>
                    <Table cellPadding={0} cellSpacing={0} tdPadding={4} tdPaddingRight={15}>
                        <tbody>
                            <tr>
                                <td>Fecha</td>
                                <td>
                                    <TextField small>{getDateFormat(entry.dateCreated, "DD/MM/YYYY hh:mm:a")}</TextField>
                                </td>
                            </tr>
                            <tr>
                                <td>Pago</td>
                                <td>#{entry.number}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Flex>
            </Flex>

            <Flex column fitContent>
                <Table cellPadding={0} cellSpacing={0} tdPadding={4} tdPaddingRight={15}>
                    <tbody>
                        {entry.referenceNumber && (
                            <tr>
                                <td>Referencia</td>
                                <td>#{entry.referenceNumber}</td>
                            </tr>
                        )}
                        {contact && (
                            <>
                                <tr>
                                    <td>Contacto</td>
                                    <td>{getModelFullName(contact)}</td>
                                </tr>
                                {contact.identificationNumber && (
                                    <tr>
                                        <td>Documento</td>
                                        <td>{contact.identificationNumber}</td>
                                    </tr>
                                )}
                            </>
                        )}
                        {entry.category && (
                            <tr>
                                <td>Categoría</td>
                                <td>{entry.category.name}</td>
                            </tr>
                        )}
                        <tr>
                            <td>Método</td>
                            <td>{intl.formatMessage((messages as any)[entry.method])}</td>
                        </tr>

                        {props.user && (
                            <tr>
                                <td>Recibido por</td>
                                <td>
                                    <TextField>{getModelFullName(props.user)}</TextField>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Flex>
            <Table className="table-items" cellPadding={0} cellSpacing={0} tdPadding={4} tdPaddingRight={15}>
                <tbody>
                    <tr className="table-header">
                        <td>Cantidad</td>
                        <td>Descripción</td>
                        <td>Valor</td>
                    </tr>
                    {entryItems.length === 0 && (
                        <tr>
                            <td>1</td>
                            <td>{entry.description}</td>
                            <td>{formatMoney(entry.amount)}</td>
                        </tr>
                    )}

                    {entryItems.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>1</td>
                                <td>{item.name}</td>
                                <td>{formatMoney(item.amount)}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <TextField style={{ marginRight: 10 }}>Total</TextField>
                            <TextField bold fontSize={15}>
                                {formatMoney(entry.amount)}
                            </TextField>
                        </td>
                    </tr>
                </tbody>
            </Table>

            <Flex marginTop={10} column border padding={10} borderRadius={10} gap10>
                <TextField>Firma:</TextField>
                <TextField>Observaciones:</TextField>
            </Flex>
            <Flex marginTop={10} alignCenter justifyCenter>
                <TextField small light>
                    Software Wahio, suite.wahio.com
                </TextField>
            </Flex>
        </PaymentFormatPrintContentSmall>
    );
});
