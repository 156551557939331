import React from "react";
import Dropdown, { DropdownStyleOption } from "./";

interface ListItem<T> {
    id: string;
    icon?: string;
    image?: any;
    title: string;
    object?: T;
}

export const getListItem = <T,>(id: string, title: string, icon?: string, image?: any, object?: T) => {
    let item: ListItem<T> = {
        id,
        title,
        icon,
        image,
        object,
    };
    return item;
};

interface DropdownListProps<T> extends DropdownStyleOption {
    items?: ListItem<T>[];
    onClickItem: (item: ListItem<T>) => void;
    onRemoveItem: () => void;
    title: string;
    prefix?: string;
    showRemove?: boolean;
    hideIcon?: boolean;
    hideBorder?: boolean;
    w100?: boolean;
    small?: boolean;
    bgLight?: boolean;
    textLight?: boolean;
}

export default function DropdownList<T>(props: DropdownListProps<T>) {
    const { items = [] } = props;
    return (
        <Dropdown w100={props.w100} {...props} hideIcon={props.hideIcon} title={props.title} prefix={props.prefix}>
            <ul className="dropdown-ul">
                {props.showRemove && (
                    <>
                        <li onClick={() => props.onRemoveItem()}>
                            <span className="wahioicon-trash icon"></span>
                            <span>Remover</span>
                        </li>
                        <hr />
                    </>
                )}
                {items.map((item, index) => (
                    <li
                        key={index}
                        onClick={() => {
                            props.onClickItem(item);
                        }}
                    >
                        {item.icon && <span className={`${item.icon} icon`}></span>}
                        {item.image && (
                            <div className="item-image">
                                <img src={item.image} alt={item.title} />
                            </div>
                        )}
                        <span>{item.title}</span>
                    </li>
                ))}
            </ul>
        </Dropdown>
    );
}
