import React from "react";
import { COLOR_LIST, getColorValue } from "../../constants/colors";
import { SquareButton } from "../_controls";
import { ColorListContainer } from "./styled";

interface ColorDropdownProps {
    color: string;
    setColor: (value: string) => void;
    className?: string;
}

export default function ColorListOption(props: ColorDropdownProps) {
    return (
        <ColorListContainer className={props.className ?? ""}>
            {Object.keys(COLOR_LIST).map((item, index) => {
                let active = props.color === item;
                let colorValue = getColorValue(item);
                return (
                    <SquareButton
                        type="button"
                        onClick={() => props.setColor(item)}
                        style={{ background: active ? colorValue : colorValue + "30", borderColor: colorValue, color: "white" }}
                        className="btn-color"
                        key={index}
                    >
                        {active ? <span className="wahioicon-check"></span> : null}
                    </SquareButton>
                );
            })}
        </ColorListContainer>
    );
}
