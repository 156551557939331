import React, { useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useMutation } from "react-query";
import styled from "styled-components";
import { getServiceMessageError } from "../../../api";
import { ContactLabel } from "../../../api/models/contact";
import { deleteContactLabel } from "../../../api/orders/contactApi";
import { getColorValue } from "../../../constants/colors";
import { useContactContext } from "../../../store/contexts/ContactContext";
import { ignoreElementClick } from "../../../utils";
import LoadingDualRing from "../../LoadingDualRing";
import { useConfirmationModal } from "../../modals/ConfirmationModal";
import { DefaultButton, Flex, SquareButton } from "../../_controls";
import ContactLabelFormModal from "../ContactLabels/ContactLabelFormModal";

interface ContactLabelPanelProps {
    onClickLabel: (label: ContactLabel) => void;
    selectedLabel?: ContactLabel;
}

export default function ContactLabelPanel(props: ContactLabelPanelProps) {
    const { contactState } = useContactContext();
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    return (
        <Flex column gap5>
            {showUpdateModal && (
                <ContactLabelFormModal
                    onCreate={() => {}}
                    onCancel={() => setShowUpdateModal(false)}
                    show={showUpdateModal}
                    setShow={setShowUpdateModal}
                />
            )}
            <DefaultButton removeBackground onClick={() => setShowUpdateModal(true)} small w100 justifyStart rounded className="mb-1" hideBorder>
                <i className="fa-light fa-plus"></i> Etiqueta
            </DefaultButton>
            {contactState.labels.map((item, index) => (
                <ContactLabelRow {...props} label={item} selected={props.selectedLabel?.id === item.id} key={index} />
            ))}
        </Flex>
    );
}

interface ContactLabelRowProps extends ContactLabelPanelProps {
    label: ContactLabel;
    selected?: boolean;
}
const ContactLabelRow = (props: ContactLabelRowProps) => {
    const { label } = props;
    const alert = useAlert();
    const { contactState, contactActions } = useContactContext();
    const DeleteConfirmation = useConfirmationModal();
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const onClickLabel = (e: any) => {
        if (!ignoreElementClick(e.target, "ignore")) {
            props.onClickLabel(label);
        }
    };

    const removeMutation = useMutation((labelId: string) => deleteContactLabel(labelId), {
        onSuccess: (data) => {
            alert.success("Etiqueta eliminada con éxito");
            let items = contactState.labels.filter((item) => item.id !== data.id);
            contactActions.receiveLabels(items);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    return (
        <>
            {showUpdateModal && (
                <ContactLabelFormModal
                    contactLabel={label}
                    onCreate={() => {}}
                    onCancel={() => setShowUpdateModal(false)}
                    show={showUpdateModal}
                    setShow={setShowUpdateModal}
                />
            )}
            <DeleteConfirmation.Modal
                title="¿Estas seguro?"
                description="Esta acción no se puede revertir"
                onCancel={() => DeleteConfirmation.show(false)}
                onOkay={() => {
                    DeleteConfirmation.show(false);
                    removeMutation.mutate(label.id ?? "NA");
                }}
            />
            <ContactLabelRowStyle onClick={onClickLabel} className={props.selected ? "selected" : ""}>
                {removeMutation.isLoading ? (
                    <LoadingDualRing small />
                ) : (
                    <i className="fa-light fa-tag" style={{ color: getColorValue(label.color) }}></i>
                )}
                <Flex column gap5>
                    <span>{label.name}</span>
                </Flex>
                <Flex className="actions" gap5 alignCenter id="ignore">
                    <SquareButton small onClick={() => DeleteConfirmation.show()}>
                        <i className="fa-regular fa-trash"></i>
                    </SquareButton>
                    <SquareButton small onClick={() => setShowUpdateModal(true)}>
                        <i className="fa-regular fa-pen"></i>
                    </SquareButton>
                </Flex>
            </ContactLabelRowStyle>
        </>
    );
};

const ContactLabelRowStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 8px 17px;
    border-radius: 40px;
    cursor: pointer;

    &.selected {
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .actions {
        display: none;
        position: absolute;
        right: 10px;
        background: ${(props) => props.theme.palette.backgroundLight};
        padding-left: 5px;
    }
    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
        .actions {
            display: flex;
        }
    }
`;
