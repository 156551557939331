import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";
import CreateOrderModal from "..";
import { getServiceMessageError } from "../../../api";
import { TRACK_ENDPOINT } from "../../../api/appConfig";
import { IPaginationResponse } from "../../../api/models";
import { getUserPermissions } from "../../../api/models/accountUser";
import { allowOrderReturnStatusList } from "../../../api/models/deliveryOrder";
import { Order } from "../../../api/models/order";
import { getOrder } from "../../../api/orders/orderApi";
import { QUERY_ORDER_LIST_KEY } from "../../../api/queryKeys";
import PATHS from "../../../constants/paths";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import { getModelFullName, joinUrl } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import Dropdown from "../../Dropdown";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationProfile, { NavigationProfileHeader, NavigationProfileHeaderProps, useSelectedGroupTab } from "../../NavigationPanel";
import { NavigationCardGroup } from "../../NavigationPanel/NavigationCard";
import PermissionDenied from "../../PermissionDenied";
import { ProductExceptionFormModal } from "../../Products/ProductExceptionForm";
import { DefaultButton, Flex, SquareButton, TextField } from "../../_controls";
import PopoverRadix from "../../_controls/PopoverRadix";
import { NavigationOrderComments } from "../OrderComments/OrderCommentsSummary";
import { OrderReturnFormModal } from "../OrderReturns/OrderReturnForm";
import OrderStatusLine from "../OrderStatusLine";
import { NavigationDeliveryInfo, NavigationPicking, NavigationShipmentView } from "./NavigationDeliveryOrder";
import NotificationTabView from "./NavigationDetails";
import NavigationEBilling from "./NavigationEBilling";
import NavigationItems from "./NavigationItems";
import NavigationOrderFiles from "./NavigationOrderFiles";
import NavigationOrderProfile, { RejectOrder } from "./NavigationOrderProfile";
import NavigationPayments from "./NavigationPayments";
import NavigationRefunds from "./NavigationRefunds";
import OrderDuplication from "./OrderDuplication";
import { OrderInvoiceGiftPrint } from "./OrderInvoiceGiftPrint";
import InvoicePrint from "./OrderInvoicePrint";
import OrderVoidPanel from "./OrderVoidPanel";
import { useDeliveryOrderStatus } from "./hooks";
import { DELIVERY_INFO_MENU, DELIVERY_PICKING_MENU, MENU_OPTIONS, NavigationGroupName } from "./menuHelper";
import messages from "./messages";
import { ActiveRestaurantContainer, NormalContainer, OrderButtonsFlex } from "./styled";

interface OrderProfileProps {
    order: Order;
    preventReload?: boolean;
    showSimpleView?: boolean;
    onUpdateOrder?: (value: Order) => void;
    onUpdateDeliveryStatus?: (value: Order) => void;
    showBackButton?: boolean;
    onClickBack?: () => void;
}

export interface OrderNavigationProps {
    order: Order;
    onOrderChange: (order: Order) => void;
    showSimpleView?: boolean;
    fetchOrder: () => void;
}

function OrderProfileComp(props: OrderProfileProps) {
    const orderId = props.order.id ?? "INVALID_ID";
    const intl = useIntl();
    const alert = useAlert();
    const queryClient = useQueryClient();
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);
    const { userState } = useUserContext();

    const { selectedGroup, setSelectedGroup } = useSelectedGroupTab<NavigationGroupName>(NavigationGroupName.Profile);

    const [order, setOrder] = useState<Order | undefined>(() => {
        if (props.order) return props.order;
        return ordersInCache?.items.find((x) => x.id === orderId);
    });

    const customOptions = useMemo(() => {
        let options = [...MENU_OPTIONS];
        if (order?.deliveryOrder) {
            options.splice(1, 0, DELIVERY_INFO_MENU);
            if (order.orderPicking) {
                options.splice(2, 0, DELIVERY_PICKING_MENU);
            }
        }
        return options;
    }, [order]);

    const deliveryStatusHook = useDeliveryOrderStatus({
        onOrderChange: (data) => {
            setOrder(data);
            if (props.onUpdateDeliveryStatus) {
                props.onUpdateDeliveryStatus(data);
            }
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const orderMutation = useMutation((orderId: string) => getOrder(orderId), {
        onSuccess: (order) => {
            order.isDelivery = !!order.deliveryOrder;
            setOrder(order);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const isLoading = orderMutation.isLoading;

    const ordersInCache = queryClient.getQueryData<IPaginationResponse<Order>>(QUERY_ORDER_LIST_KEY);

    useEffect(() => {
        !props.preventReload && fetchOrderById();

        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOrderById = () => {
        orderMutation.mutate(orderId);
    };

    if (!order && isLoading) {
        return <LoadingDualRing center={true} />;
    }

    if (!order) {
        return null;
    }

    const onOrderChange = (order: Order) => {
        if (props.onUpdateOrder) props.onUpdateOrder(order);
        setOrder(order);
    };

    const permission = getUserPermissions(userState.user).order;

    if (!permission?.viewOrdersFromOtherUsers && userState.user?.id !== order.accountUserId) {
        return <PermissionDenied message={intl.formatMessage(messages.youDoNotHavePermissionsToViewThisOrder)} />;
    }

    const restaurantActive = order.isRestaurant && !order.isRestaurantClose;

    const orderProps = {
        order: order,
        onOrderChange: onOrderChange,
        showSimpleView: props.showSimpleView,
        fetchOrder: fetchOrderById,
    };

    let subTitle = "Cliente General";
    if (order.customer) {
        subTitle = `${getModelFullName(order.customer)}`;
        if (order.customer.identificationNumber) {
            subTitle += ` - ${order.customer.identificationNumber}`;
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${intl.formatMessage(messages.order)} ${order.number}`} - Wahio</title>
            </Helmet>

            {deliveryStatusHook.showModalErrors && deliveryStatusHook.errorList.length > 0 && (
                <ProductExceptionFormModal
                    show={deliveryStatusHook.showModalErrors}
                    setShow={deliveryStatusHook.setShowModalErrors}
                    items={deliveryStatusHook.errorList}
                />
            )}

            <NavigationProfile
                headerComp={
                    <OrderHeader
                        order={order}
                        title={`Orden: #${order.number}`}
                        titleLink={PATHS.orderPosProfile(order.id)}
                        subTitle={subTitle}
                        showBackButton={props.showBackButton}
                        onOrderChange={setOrder}
                        fetchOrder={fetchOrderById}
                        onBackAction={props.onClickBack}
                        customActions={<OrderActionsPanel {...orderProps} />}
                    />
                }
                menuOptions={customOptions}
                selectedTab={selectedGroup}
                setSelectedTab={setSelectedGroup}
            >
                <>
                    <NavigationCardGroup groupName={NavigationGroupName.Profile} selectedGroup={selectedGroup}>
                        {!order.orderDelete && restaurantActive && (
                            <ActiveRestaurantContainer>
                                <span className="wahioicon-utensils icon"></span>{" "}
                                <span> La orden se encuentra activa actualmente en el Restaurante</span>
                            </ActiveRestaurantContainer>
                        )}
                        {order.orderDelete && <OrderVoidPanel order={order} orderDelete={order.orderDelete} />}
                        <NavigationOrderProfile {...orderProps} />
                        <NavigationShipmentView {...orderProps} />
                        <NavigationItems {...orderProps} />
                        <NavigationPayments {...orderProps} />
                        <NavigationRefunds {...orderProps} />
                        <NavigationOrderComments {...orderProps} />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.DeliveryInfo} selectedGroup={selectedGroup}>
                        <NavigationDeliveryInfo {...orderProps} />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.Picking} selectedGroup={selectedGroup}>
                        <NavigationPicking {...orderProps} />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.EBilling} selectedGroup={selectedGroup}>
                        <NavigationEBilling order={order} />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.Files} selectedGroup={selectedGroup}>
                        <NavigationOrderFiles {...orderProps} />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.Details} selectedGroup={selectedGroup}>
                        <NotificationTabView {...orderProps} />
                    </NavigationCardGroup>
                </>
            </NavigationProfile>
        </>
    );
}

export default function OrderProfile(props: OrderProfileProps) {
    if (props.showSimpleView) {
        return (
            <NormalContainer>
                <OrderProfileComp {...props} />
            </NormalContainer>
        );
    }

    return <OrderProfileComp {...props} />;
}

const OrderActionsPanel = (props: OrderNavigationProps) => {
    const { order: currentOrder, onOrderChange: setCurrentOrder, fetchOrder } = props;
    const { deliveryOrder } = currentOrder;
    const intl = useIntl();
    const alert = useAlert();

    const [showModalCreateOrderReturn, setShowModalCreateOrderReturn] = useState(false);
    const [showEditOrder, setShowEditOrder] = useState(false);

    const restaurantActive = currentOrder.isRestaurant && !currentOrder.isRestaurantClose;

    return (
        <>
            {showModalCreateOrderReturn && (
                <OrderReturnFormModal
                    onCreate={() => {
                        fetchOrder();
                    }}
                    orderId={currentOrder.id}
                    show={showModalCreateOrderReturn}
                    setShow={setShowModalCreateOrderReturn}
                />
            )}
            {showEditOrder && (
                <CreateOrderModal
                    order={currentOrder}
                    onCompleted={(order) => {
                        setCurrentOrder(order);
                        setShowEditOrder(false);
                        alert.success(intl.formatMessage(messages.TheOrderHasBeenUpdatedSuccessfully));
                    }}
                    show={showEditOrder}
                    setShow={setShowEditOrder}
                />
            )}
            {!currentOrder.orderDelete && (
                <>
                    <OrderButtonsFlex alignCenter gap10 flexWrap>
                        <OrderShareButton order={currentOrder} />
                        {!restaurantActive &&
                            currentOrder.status !== "void" &&
                            currentOrder.status !== "queued" &&
                            currentOrder.status !== "declined" && (
                                <SquareButton title={intl.formatMessage(messages.editOrder)} onClick={() => setShowEditOrder(true)}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </SquareButton>
                            )}
                        <InvoicePrint order={currentOrder} />
                        <Dropdown textLight icon="fa-regular fa-ellipsis-vertical" contentHorizontal="left">
                            <Flex column padding={10} gap5>
                                <OrderDuplication order={currentOrder} />

                                {!restaurantActive && currentOrder.status !== "queued" && currentOrder.status !== "void" && (
                                    <RejectOrder {...props} status="void" title="Anular orden" />
                                )}
                                <OrderInvoiceGiftPrint currentOrder={currentOrder} />
                                {!props.showSimpleView &&
                                    currentOrder.orderInvoice &&
                                    (!deliveryOrder || allowOrderReturnStatusList.includes(deliveryOrder?.status)) && (
                                        <DefaultButton
                                            className="dropdown-button"
                                            rounded
                                            onClick={() => setShowModalCreateOrderReturn(true)}
                                        >
                                            <span className="wahioicon-arrows-alt-h"></span>
                                            Devoluciones/Cambios
                                        </DefaultButton>
                                    )}
                            </Flex>
                        </Dropdown>
                    </OrderButtonsFlex>
                </>
            )}
        </>
    );
};

export const OrderShareButton = ({ order }: { order: Order }) => {
    const alert = useAlert();
    return (
        <PopoverRadix
            widthAuto
            trigger={
                <SquareButton>
                    <i className="fa-sharp fa-regular fa-share"></i>
                </SquareButton>
            }
        >
            <Flex column gap20>
                <Flex column>
                    <TextField small light>
                        Tracking Number
                    </TextField>
                    <TextField bold>{order.trackingNumber}</TextField>
                </Flex>

                {order.trackingNumber && (
                    <Flex gap10 alignCenter>
                        <DefaultButton
                            bgLight
                            borderRadius={10}
                            onClick={() => {
                                navigator.clipboard
                                    .writeText(joinUrl(TRACK_ENDPOINT, order.trackingNumber ?? "-"))
                                    .then(() => alert.success("El link ha sido copiado con éxito"));
                            }}
                        >
                            <i className="fa-sharp fa-regular fa-link-simple"></i>
                            Copiar
                        </DefaultButton>
                        <a href={joinUrl(TRACK_ENDPOINT, order.trackingNumber ?? "-")} target="_blank" rel="noreferrer">
                            <DefaultButton bgLight borderRadius={10}>
                                <i className="fa-regular fa-arrow-up-right-from-square"></i>
                                Abrir
                            </DefaultButton>
                        </a>
                    </Flex>
                )}
            </Flex>
        </PopoverRadix>
    );
};

const OrderHeader = (props: NavigationProfileHeaderProps & OrderNavigationProps) => {
    return (
        <>
            <NavigationProfileHeader
                {...props}
                titleComponent={
                    <OrderStatusLine
                        customStyle={{ width: "40px", height: "40px", borderRadius: "10px", fontSize: "16px" }}
                        size="lg"
                        useIcon
                        status={props.order.status}
                    />
                }
            />
        </>
    );
};
