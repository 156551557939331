import React, { useEffect } from "react";
import Select from "react-select";
import { SelectOptionObject } from "../../../api/models";
import { OrderInvoiceNumeration } from "../../../api/models/orderInvoiceNumeration";
import { useOrderInvoiceNumerationContext } from "../../../store/contexts/OrderInvoiceNumerationContext";
import { SelectBody } from "../../Select/styled";

interface OrderInvoiceNumerationProps {
    orderInvoiceNumerationId?: string;
    onNumerationChange: (value: OrderInvoiceNumeration | undefined) => void;
    hideTitle?: boolean;
    alternateSelector?: boolean;
}

export default function OrderInvoiceNumerationSelect(props: OrderInvoiceNumerationProps) {
    const { orderInvoiceNumerationState, orderInvoiceNumerationActions, selectedNumeration, setSelectedNumeration } =
        useOrderInvoiceNumerationContext();

    const items = orderInvoiceNumerationState.items;

    useEffect(() => {
        if (orderInvoiceNumerationState.items.length === 0) orderInvoiceNumerationActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var selected = items.find((x) => x.id === props.orderInvoiceNumerationId);
        setSelectedNumeration(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.orderInvoiceNumerationId]);

    const getOption = (item: OrderInvoiceNumeration) => {
        let option: SelectOptionObject<OrderInvoiceNumeration> = {
            value: item.id ?? "NA",
            label: item.name,
            object: item,
        };
        return option;
    };

    const onNumerationChange = (value?: OrderInvoiceNumeration) => {
        setSelectedNumeration(value);
        props.onNumerationChange(value);
    };

    return (
        <SelectBody className={props.alternateSelector ? "alternate-selector" : ""}>
            {!props.hideTitle && <span className="label">Numeración</span>}
            <Select
                placeholder="Numeración"
                className={`react-select-basic ${selectedNumeration ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedNumeration?.name}
                defaultValue={selectedNumeration ? getOption(selectedNumeration) : undefined}
                isDisabled={false}
                isClearable
                onChange={(value) => {
                    if (value?.object) onNumerationChange(value?.object);
                    else {
                        props.onNumerationChange(undefined);
                    }
                }}
                isLoading={orderInvoiceNumerationState.isFetching}
                isRtl={false}
                isSearchable={true}
                name="warehouse"
                options={items.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
