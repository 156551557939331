import { useEffect, useRef, useState } from "react";

export function useBarcodeListener(onBarcodeChange: (barcode: string) => void, stop?: boolean) {
    let barcodeValue = "";
    let barcodeInterval: any = undefined;
    const handler = (e: any) => {
        if (stop) {
            return;
        }

        if (barcodeInterval) {
            clearInterval(barcodeInterval);
        }
        if (e.code === "Enter") {
            if (barcodeValue) {
                barcodeValue = barcodeValue.replaceAll("Shift", "");

                onBarcodeChange(barcodeValue);

                barcodeValue = "";
                return;
            }
            return;
        }
        if (e.code !== "Shift") {
            barcodeValue += e.key;
        }
        barcodeInterval = setInterval(() => (barcodeValue = ""), 20);
    };
    // Add event listener using our hook
    useEventListenerDocument("keydown", handler);
}

interface MobileListenerProps {
    milliseconds?: number;
    size?: number;
}

export const useIsMobileListener = (options?: MobileListenerProps) => {
    const { milliseconds = 100, size = 700 } = options ?? {};
    const timerRef = useRef<any>();

    const [isMobile, setIsMobile] = useState(window.innerWidth < size);

    const handleUpdateMobile = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setIsMobile(window.innerWidth < size);
        }, milliseconds);
    };
    useEventListenerWindow("resize", () => {
        handleUpdateMobile();
    });

    return isMobile;
};

export const useIsMobileListenerRef = (ref: any, options?: MobileListenerProps) => {
    const { milliseconds = 100, size = 700 } = options ?? {};
    const timerRef = useRef<any>();

    const [isMobile, setIsMobile] = useState(ref?.innerWidth ?? 0 < size);

    useEffect(() => {
        if (ref) {
            ref.addEventListener("resize", () => {
                handleUpdateMobile();
            });
        }
        return () => {
            if (ref) {
                ref.removeEventListener("resize");
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const handleUpdateMobile = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setIsMobile(ref.innerWidth < size);
        }, milliseconds);
    };

    return isMobile;
};

export function useEventListenerDocument(eventName: string, handler: (value: any) => void) {
    // Create a ref that stores handler
    const savedHandler = useRef<any>();
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = document.addEventListener;
            if (!isSupported) return;
            // Create event listener that calls handler function stored in ref
            const eventListener = (event: any) => savedHandler.current(event);
            // Add event listener
            document.addEventListener(eventName, eventListener);
            // Remove event listener on cleanup
            return () => {
                document.removeEventListener(eventName, eventListener);
            };
        },
        [eventName] // Re-run if eventName or element changes
    );
}

export function useEventListenerWindow(eventName: string, handler: (value: any) => void) {
    // Create a ref that stores handler
    const savedHandler = useRef<any>();
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = window.addEventListener;
            if (!isSupported) return;
            // Create event listener that calls handler function stored in ref
            const eventListener = (event: any) => savedHandler.current(event);
            // Add event listener
            window.addEventListener(eventName, eventListener);
            // Remove event listener on cleanup
            return () => {
                window.removeEventListener(eventName, eventListener);
            };
        },
        [eventName] // Re-run if eventName or element changes
    );
}
