import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { ThemeContext, useTheme } from "styled-components";
import { APP_MODE, APP_VERSION, GOOGLE_ANALYTICS_ID } from "../../api/appConfig";
import { ACCOUNT_ACTIVE_STATUS, AccountUser } from "../../api/models/accountUser";
import { useWahioSocket, useWahioSocketRooms } from "../../api/socket";
import { EventKey, sendSocketEvent, useSocketDeviceOnEvent } from "../../api/socket/sockedManager";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { CloudImages } from "../../assets";
import { IAuthBody, clearAuthSession } from "../../auth";
import { useEventListenerWindow } from "../../components";
import AccountBillingConfig from "../../components/Account/Billing/AccountBillingConfig";
import ChatContainer from "../../components/Chats/ChatContainer";
import { useChatListener } from "../../components/Chats/ChatUserList";
import LoadingDualRing from "../../components/LoadingDualRing";
import { SCREEN_PREVIEW_PARAM } from "../../components/Orders/OrderMultiPanel";
import OrderScreenPreview from "../../components/Orders/OrderMultiPanel/OrderScreenPreview";
import QuickStartVertical from "../../components/QuickStart/QuickStartVertical";
import StoreSelectMain from "../../components/Stores/StoreSelectMain";
import UserProfile from "../../components/Users/UserProfile";
import { AlertEmailConfirmationLine } from "../../components/Users/UserProfile/ConfirmEmail";
import { DefaultButton, Flex, TextField } from "../../components/_controls";
import { IUserState, UserContext, useUserContext } from "../../store/contexts/UserContext";
import { getUtcDate } from "../../utils";
import customMoment from "../../utils/momentFormat/dateFormat";
import AppHorizontalMenu from "./AppHorizontalMenu";
import AppVerticalMenu from "./AppVerticalMenu/AppVerticalMenu";
import DisabledAccountView from "./DisabledAccountView";
import DueInvoiceValidator from "./DueInvoiceValidator";
import { InvalidSession } from "./InvalidSession";
import { useUserDevice } from "./UserDevice";
import { useFaviconDetector } from "./hooks";
import { AlertLineContainer, AppContentContainer, AppGlobalContainer, AppSubContainer, ChooseStoreContainer } from "./styled";

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

export const messageAudio = new Audio(CloudImages.audioMessage);
export const notificationAudio = new Audio(CloudImages.audioNotification);

interface AppProps {
    authBody?: IAuthBody;
    authParam: string | null;
    children: React.ReactNode;
}

interface ValidateAccount {
    billingRequired: boolean;
    newContractRequired: boolean;
    emailConfirmationRequired: boolean;
}

export const APP_CONTENT_ID = "APP_CONTENT_BASE";

export const appIsModePos = (user?: AccountUser) => {
    if (!user) return false;
    return APP_MODE === "POS" || user.type === "seller";
};

function AppContainer(props: AppProps) {
    const { userState, userActions, setUserState } = useUserContext();
    const userStateRef = React.useRef<IUserState | undefined>(undefined);
    const wahioSocket = useWahioSocket();
    const urlParams = new URLSearchParams(window.location.search);
    const isScreenPreview = urlParams.get(SCREEN_PREVIEW_PARAM) === "true";

    useWahioSocketRooms();

    const [validateAccount, setValidateAccount] = useState<ValidateAccount>({
        billingRequired: false,
        newContractRequired: false,
        emailConfirmationRequired: false,
    });

    useEffect(() => {
        const { authBody } = props;
        if (!userState.user && authBody) userActions.requestAccountUser(authBody.accountUserId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userState.user && !userStateRef.current) {
            userStateRef.current = userState;
            wahioSocket.sendUserConnected(userState.user);
            if (userState.user.id) {
                wahioSocket.roomSuscribe(userState.user.id);
            }
        }
        if (userState.user) {
            userStateRef.current = userState;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.user]);

    useEffect(() => {
        if (userState.user) {
            const account = userState.user.account;

            if (!account) return;

            const isFree = account.plan === "free";
            const expired = customMoment(getUtcDate(userState.user.account?.trialExpirationDate)).startOf("day");
            const actualDay = customMoment().startOf("day");

            const daysDiff = expired.diff(actualDay, "day");
            const newContractRequired = userState.user.account?.status === "newContractRequired";

            let emailConfirmationRequired = false;
            const accountCreatedAt = customMoment(account.dateCreated);
            const daysSinceAccountCreated = actualDay.diff(accountCreatedAt, "days");

            if (accountCreatedAt.year() >= 2023 && daysSinceAccountCreated > 2 && !userState.user.emailConfirmed) {
                emailConfirmationRequired = true;
            }

            var billingRequired = isFree && daysDiff <= 0;

            if (!userState.user.account?.billing) {
                billingRequired = true;
            }

            if (isFree && userState.freeDays === undefined) {
                setUserState({ ...userState, freeDays: daysDiff });
            }

            setValidateAccount({ ...validateAccount, billingRequired, newContractRequired, emailConfirmationRequired });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.user]);

    if (isScreenPreview) {
        return <OrderScreenPreview />;
    }

    if (userState.isUserFetching) {
        return <LoadingDualRing center={true} />;
    }

    if (validateAccount.billingRequired || validateAccount.newContractRequired) {
        return <AccountBillingConfig isNewContract={validateAccount.newContractRequired} />;
    }

    return <AppContainerBase {...props} validateAccount={validateAccount} />;
}

interface AppContainerBaseProps extends AppProps {
    validateAccount?: ValidateAccount;
}

function AppContainerBase(props: AppContainerBaseProps) {
    const location = useLocation();
    const [sessionIsValid, setSessionIsValid] = useState(true);
    const { userState } = useContext(UserContext);
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const [accountUserId, setAccountUserId] = useState("");
    const [appOffline, setAppOffline] = useState(false);

    const themeContext = useContext(ThemeContext);

    const userDeviceId = useUserDevice();

    const panelAlertRef = React.useRef<any>();
    const lastPathnameRef = React.useRef<string>("");

    const themeStyled = useTheme();

    useFaviconDetector();
    useChatListener();

    useSocketDeviceOnEvent(EventKey.deviceActive, (deviceId) => {
        setSessionIsValid(deviceId === userDeviceId);
    });

    useEffect(() => {
        analyzeAppVersion();
        const { authBody } = props;
        setAccountUserId(authBody?.accountUserId!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const analyzeAppVersion = () => {
        if (!appSettingsState.appVersion) {
            appSettingsActions.setAllSettings({ ...appSettingsState, appVersion: APP_VERSION });
        } else if (appSettingsState.appVersion !== APP_VERSION) {
            appSettingsActions.setAllSettings({ ...appSettingsState, appVersion: APP_VERSION });
        }
    };

    useEventListenerWindow("offline", () => {
        setAppOffline(true);
    });

    useEventListenerWindow("online", () => {
        setAppOffline(false);
    });

    useEffect(() => {
        const theme = themeStyled.palette.themeName ?? "light";

        let android = document.querySelector('meta[name="theme-color"]');
        let ios = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
        if (android && ios) {
            android.setAttribute("content", themeContext.palette.background);
            if (theme === "dark") {
                ios.setAttribute("content", "black-translucent");
            } else if (theme === "light") {
                ios.setAttribute("content", "default");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeStyled.palette.themeName]);

    React.useEffect(() => {
        if (lastPathnameRef.current !== location.pathname) {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname });
        }
        lastPathnameRef.current = location.pathname;
    }, [location]);

    const handlerUseHereClick = () => {
        setSessionIsValid(true);
        sendSocketEvent(EventKey.useHere, { userId: accountUserId, deviceId: userDeviceId });
    };

    if (userState.isUserFetching) {
        return <LoadingDualRing center={true} />;
    }
    if (!props.authBody) {
        throw new Error("Auth body is required");
    }

    if (!sessionIsValid) {
        return <InvalidSession handlerUseHere={handlerUseHereClick} />;
    }

    if (userState.unauthorized) {
        return (
            <div>
                <AppHorizontalMenu hideActions />
                <Flex alignCenter column w100 padding={30} gap={15} marginTop={60} boxSizingBorderBox>
                    <Flex column alignCenter>
                        <h3 className="m-0">La sesión ha expirado</h3>
                        <TextField fontSize={15}>
                            No pudimos validar sus credenciales de inicio de sesión, por favor, vuelva a iniciar sesión.
                        </TextField>
                    </Flex>

                    <DefaultButton bgLight borderRadius={10} onClick={() => clearAuthSession()}>
                        <span>Iniciar Session</span>
                    </DefaultButton>
                </Flex>
            </div>
        );
    }

    const account = userState.user?.account;
    let currentStatus = account?.status;
    if (account && currentStatus && !ACCOUNT_ACTIVE_STATUS.includes(currentStatus)) {
        return <DisabledAccountView account={account} />;
    }

    if (!userState.user && userState.isFetchDone) {
        return (
            <div>
                <AppHorizontalMenu hideActions />
                <Flex alignCenter column w100 padding={30} marginTop={60} boxSizingBorderBox>
                    <TextField fontSize={18} bold color="#E53935">
                        No se pudo recuperar la información del usuarios actual
                    </TextField>
                    <TextField fontSize={18} light>
                        Es posible que se haya perdido la conexión a internet{" "}
                    </TextField>
                    <TextField fontSize={18} light>
                        Debes recargar la pagina
                    </TextField>
                </Flex>
            </div>
        );
    }

    if (!userState.user) return null;

    if (props.validateAccount?.emailConfirmationRequired) {
        return (
            <div>
                <AppHorizontalMenu />
                <Flex marginTop={55} column alignCenter>
                    <Flex column maxWidth={600} w100 paddingTop={20}>
                        <UserProfile hideBackLink accountUser={userState.user} onUpdateUser={() => {}} onDeleted={() => {}} />
                    </Flex>
                </Flex>
            </div>
        );
    }

    let appContentClass = appSettingsState.menuSmall ? "small-menu" : "";

    const modePos = appIsModePos(userState.user);

    if (modePos) {
        appContentClass += " no-vertical-menu";
    }

    return (
        <>
            {userState.user && <ChatContainer />}
            <AppGlobalContainer id="PANEL-GLOBAL">
                <AppHorizontalMenu />
                <AppSubContainer id="PANEL-SUB-CONTAINER">
                    {!appSettingsState.defaultOrganizationId ? (
                        <ChooseStoreContainer>
                            <StoreSelectMain />
                        </ChooseStoreContainer>
                    ) : (
                        <>
                            {!modePos && <AppVerticalMenu />}
                            <AppContentContainer id={APP_CONTENT_ID} className={appContentClass}>
                                {appOffline && (
                                    <AlertLineContainer>
                                        <span className="wahioicon-exclamation-triangle"></span>
                                        <span>Se perdió la conexión a internet</span>
                                    </AlertLineContainer>
                                )}
                                {!userState.user.emailConfirmed && <AlertEmailConfirmationLine />}
                                <DueInvoiceValidator refValue={panelAlertRef} onLoad={() => {}} />
                                {props.children}
                            </AppContentContainer>
                            <QuickStartVertical />
                        </>
                    )}
                </AppSubContainer>
            </AppGlobalContainer>
        </>
    );
}

export const useAppContentEventListener = (eventName: string, onChange: (e: any) => void) => {
    useEffect(() => {
        let element = document.getElementById(APP_CONTENT_ID);
        var event: any = undefined;
        if (element) {
            event = element.addEventListener(eventName, (e: any) => {
                onChange(e);
            });
        }
        return () => {
            if (event) window.removeEventListener(eventName, event);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default AppContainer;
