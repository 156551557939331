import styled from "styled-components";
import { IThemePalette } from "../../theme/themePalette";
import { device } from "../../theme/themeProvider";

interface ContainerProps {
    withBorder?: boolean;
    menuSmall?: boolean;
    padding?: number;
    theme: {
        palette: IThemePalette;
    };
}

export const Container = styled.div`
    padding: ${(props) => props.padding + "px"};
    margin: auto;
    position: relative;

    left: 0;

    ${(props: ContainerProps) => (props.withBorder ? `order: 1px solid ${props.theme.palette.backgroundDark};` : "")}

    &.size-sm {
        max-width: 600px;
    }
    &.size-sm2 {
        max-width: 700px;
    }
    &.size-md {
        max-width: 900px;
    }
    &.size-lg {
        max-width: 1280px;
    }
`;

export const BackgroundHide = styled.div`
    position: relative;
    ${(props: { addBackgroundHide?: boolean }) =>
        props.addBackgroundHide
            ? `
        opacity: 0.4;
        &:after { 
            content: "No tienes permisos para actualizar";
            text-align: center;
            padding-top: 10px;
            font-size: .8rem;
            z-index: 6;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
        }
        `
            : ""}
`;

export const SubContainerStyleView = styled(BackgroundHide)`
    border: 0px;
    border-radius: 15px;
    padding: 15px 20px;
    position: relative;
    .container-title {
        margin-top: 5px;
        font-weight: 500;
    }
    @media ${device.sm} {
        padding: 15px 25px;
        border: ${(props: { hideBorder?: boolean }) => (props.hideBorder ? "0px" : "1px")} solid
            ${(props) => props.theme.palette.backgroundDark};
    }
`;
