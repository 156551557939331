import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { WahioFile, WahioFileFromCloud, WahioFileImpl } from "../../../api/models";
import { IAddImageBody } from "../../../api/models/productImage";
import { WahioCloudItem } from "../../../api/models/wahioCloud";
import { CloudItemCreateFilesModal } from "../../WahioCloud/CreateCloudItems/CloudItemCreateFiles";
import WahioCloudItemsModal from "../../WahioCloud/WahioCloudItemsModal";
import { DefaultButton, Flex, SquareButton, TextField } from "../../_controls";
import AddVideoLinkModal from "../../images/AddVideoLinkModal";
import { getVideoUrl } from "../../images/utils";
import { AllImageContainer, DragImageCardContainer, ImageViewCard, ImagesSampleView } from "./styled";

const reorder = (list: WahioFile[] = [], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "transparent" : "transparent",
});

interface ImagesFormProps {
    images: WahioFile[];
    onChangeImages: (images: WahioFile[]) => void;
    hideHeader?: boolean;
}

export default function ImagesForm(props: ImagesFormProps) {
    const [showWahioCloudModal, setShowWahioCloudModal] = useState(false);
    const [dragEnterActive, setDragEnterActive] = useState(false);
    const [filesToCreated, setFilesToCreated] = useState<File[]>([]);

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const orderedItems = reorder(props.images, result.source.index, result.destination.index);

        props.onChangeImages(
            orderedItems.map((item, index) => {
                item.index = index;
                return item;
            })
        );
    };

    const onRemoveImage = (item: WahioFile) => {
        let newImages = props.images?.filter((x) => x.id !== item.id);
        props.onChangeImages(newImages);
    };

    const onClickWahioCloudItems = (items: WahioCloudItem[]) => {
        let newImages = items.map((item) => new WahioFileFromCloud(item));
        let allImages = [...props.images];
        allImages = [...allImages, ...newImages];
        allImages = allImages.map((img, index) => {
            img.index = index;
            return img;
        });
        props.onChangeImages(allImages);
    };

    const onAddVideo = (body: IAddImageBody) => {
        if (body.type === "videoLink" && body.url) {
            let image = new WahioFileImpl(body.url, "videoLink");
            let allImages = props.images ?? [];

            image.index = allImages.length;
            allImages = [...allImages, image];
            props.onChangeImages(allImages);
        }
    };

    const dragOver = (e: any) => {
        setDragEnterActive(true);
        e.preventDefault();
    };

    const dragEnter = (e: any) => {
        setDragEnterActive(true);
        e.preventDefault();
    };

    const dragLeave = (e: any) => {
        setDragEnterActive(false);
        e.preventDefault();
    };

    const fileDrop = (e: any) => {
        setDragEnterActive(false);
        e.preventDefault();
        const files = e.dataTransfer.files;
        setFilesToCreated(files);
    };

    return (
        <>
            {showWahioCloudModal && (
                <WahioCloudItemsModal
                    show={showWahioCloudModal}
                    setShow={setShowWahioCloudModal}
                    defaultType="image"
                    onClickItems={onClickWahioCloudItems}
                    allowSelectionMultiple
                />
            )}
            <div className="image-div" style={{ display: "grid" }}>
                {filesToCreated.length > 0 && (
                    <CloudItemCreateFilesModal
                        onItemCreated={() => {}}
                        files={filesToCreated}
                        autoClose
                        onClose={(items) => {
                            if (items) onClickWahioCloudItems(items);
                            setFilesToCreated([]);
                        }}
                    />
                )}

                <AllImageContainer
                    className={`${dragEnterActive ? "drag-active" : ""} ${props.images.length === 0 ? "show-border" : ""}`}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    {!props.hideHeader && (
                        <Flex alignStart spaceBetween>
                            <Flex>
                                <TextField bold>Imágenes</TextField>
                            </Flex>
                            <Flex gap10>
                                <DefaultButton small borderRadius={10} onClick={() => setShowWahioCloudModal(true)}>
                                    <i className="fa-regular fa-camera"></i> Imagen
                                </DefaultButton>
                                <AddVideoLinkModal handleImageBody={onAddVideo} />
                            </Flex>
                        </Flex>
                    )}

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (
                                <ImagesSampleView
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {props.images.map((item, index: number) => (
                                        <Draggable key={item.id} draggableId={item.url} index={index}>
                                            {(provided, snapshot) => (
                                                <DragImageCardContainer
                                                    style={provided.draggableProps.style}
                                                    isDragging={snapshot.isDragging}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <ImageCard item={item} onRemoveImage={onRemoveImage} />
                                                </DragImageCardContainer>
                                            )}
                                        </Draggable>
                                    ))}

                                    {!snapshot.isDraggingOver && (
                                        <ImageViewCard className="add-hover" onClick={() => setShowWahioCloudModal(true)}>
                                            <i className="fa-regular fa-plus"></i>
                                        </ImageViewCard>
                                    )}

                                    {props.images.length === 0 && (
                                        <TextField small light>
                                            Arrastra y suelta las imagenes aqui
                                        </TextField>
                                    )}

                                    {provided.placeholder}
                                </ImagesSampleView>
                            )}
                        </Droppable>
                    </DragDropContext>
                </AllImageContainer>
            </div>
        </>
    );
}

interface ImageCardProps {
    item: WahioFile;
    onRemoveImage: (item: WahioFile) => void;
}

const ImageCard = (props: ImageCardProps) => {
    const { item, onRemoveImage } = props;
    return (
        <ImageViewCard>
            {item.type === "videoLink" ? (
                <iframe title="VideLink" width="560" height="315" src={getVideoUrl(item.url)} frameBorder="0" allowFullScreen></iframe>
            ) : (
                <img src={item.url} alt="" />
            )}

            <SquareButton onClick={() => onRemoveImage(item)} className="remove">
                <span className="wahioicon-trash"></span>
            </SquareButton>
        </ImageViewCard>
    );
};
