import { IntlShape } from "react-intl";

export const checkIntlMessage = (messages: any, key?: string) => {
    if (key && messages[key]) return true;
    return false;
};

export const checkAndGetMessage = (intl: IntlShape, messages: any, key?: string) => {
    if (key && checkIntlMessage(messages, key)) {
        return intl.formatMessage(messages[key]);
    }
    return key ?? "";
};