export interface IThemePalette {
    themeName: "light" | "dark";
    shadowColor: string;
    primary: string;
    primaryLight: string;
    primary2: string;
    primaryDark: string;
    primaryDark2: string;
    dangerColor: string;
    dangerColorDark: string;
    warningColor: string;
    background: string;
    backgroundDark: string;
    backgroundDarker: string;
    backgroundLight: string;
    backgroundModal: string;
    background98: string;
    text: string;
    textDark: string;
    textLight: string;
    textLighter: string;
    shadow: string;
    boxShadowHsl: string;
    green: string;
    greenLight: string;
    menuHoverColor: string;
    menuIconColor: string;
    backgroundGradientBody: string;
    borderAppTheme: string;
}

export const lightTheme: IThemePalette = {
    themeName: "light",
    shadowColor: "rgb(175 175 175 / 38%)",
    primary: "#304FFE",
    primaryLight: "#dce5ff",
    primary2: "#009688",
    primaryDark: "#1735db",
    primaryDark2: "#007167",
    dangerColor: "#D32F2F",
    dangerColorDark: "#a51616",
    warningColor: "#ff9800",
    background: "#ffffff",
    backgroundDark: "#e6e6e6",
    backgroundDarker: "#c1c1c1",
    backgroundLight: "#f2f2f2",
    backgroundModal: "rgba(0, 0, 0, 0.521)",
    background98: "rgb(255 255 255 / 85%)",
    text: "#000000",
    textDark: "#000000",
    textLight: "#808080",
    textLighter: "#80808094",
    shadow: "1px 1px 7px #8c8c8c4a",
    green: "#00bfa5",
    greenLight: "#d4ece9",
    boxShadowHsl: "hsl(206 22% 7% / 35%) 0px 3px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
    menuHoverColor: "rgb(103 58 183 / 16%)",
    menuIconColor: "#304FFE",
    backgroundGradientBody: "linear-gradient(90deg, rgb(48 79 254 / 13%) 0%, rgb(252 70 208 / 14%) 100%)",
    borderAppTheme: "border: 1px solid #f2f2f2;",
};

export const darkTheme: IThemePalette = {
    themeName: "dark",
    shadowColor: "rgb(0 0 0 / 52%)",
    primary: "#0091ff",
    primaryLight: "#173f5f",
    primary2: "#009688",
    primaryDark: "#0076d1",
    primaryDark2: "#007167",
    dangerColor: "#D32F2F",
    dangerColorDark: "#a51616",
    warningColor: "#ff9800",
    background: "#1d1d1d",
    backgroundDark: "#313131",
    backgroundDarker: "#5f5f5f",
    backgroundLight: "#2a2a2a",
    backgroundModal: "rgba(68, 68, 68, 0.65)",
    background98: "rgb(29 29 29 / 94%)",
    text: "#ffffff",
    textDark: "#808080",
    textLight: "#808080",
    textLighter: "#80808080",
    shadow: "1px 1px 7px #000000a8",
    green: "#00bfa5",
    greenLight: "#2a3d3a",
    boxShadowHsl: "rgb(0 0 0) 0px 3px 42px -10px, rgb(0 0 0 / 90%) 0px 10px 20px -15px",
    menuHoverColor: "rgb(33 150 243 / 7%)",
    menuIconColor: "#0091ff",
    backgroundGradientBody: "#1d1d1d",
    borderAppTheme: "border: 1px solid #2a2a2a;",
};
