import React, { useEffect, useState } from "react";
import { Order, OrderItem } from "../../../api/models/order";
import { getProductThumbnailUrl, getProductSku } from "../../Products/helper";
import { Flex, TextField, FlexImageStyle, GridTemplate, PrimaryButton } from "../../_controls";
import { OrderSerialsConfigView, SerialLine } from "./styled";
import CreateTable from "react-select/creatable";
import { getSelectOption } from "../../../utils/select";
import { ProgressBarValue } from "../../_controls/progressBars/ProgressBar";
import { useMutation } from "react-query";
import { createUpdateOrder } from "../../../api/orders/orderApi";
import { useAlert } from "../../Alerts/Alert";
import { getServiceMessageError } from "../../../api";
import LoadingDualRing from "../../LoadingDualRing";
import { SelectBody } from "../../Select/styled";

interface OrderSerialsConfigProps {
    order: Order;
    onChange: (value: Order) => void;
}

export default function OrderSerialsConfig(props: OrderSerialsConfigProps) {
    const [serialItems, setSerialItems] = useState<OrderItem[]>([]);
    const alert = useAlert();
    const [serialValues, setSerialValues] = useState({
        total: 0,
        current: 0,
    });

    const orderMutation = useMutation((order: Order) => createUpdateOrder(order), {
        onSuccess: (data) => {
            alert.success("Seriales guardados con éxito");
            props.onChange(data);
        },
        onError: (er) => {
            alert.error(getServiceMessageError(er));
        },
    });

    useEffect(() => {
        const totalSerials = serialItems.reduce((x, y) => x + y.quantity, 0);
        const fillSerials = serialItems.reduce((x, y) => x + (y.serials ?? []).filter((x) => !!x).length, 0);

        setSerialValues({
            total: totalSerials,
            current: fillSerials,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serialItems]);

    useEffect(() => {
        setInitialSerials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.items]);

    const onSaveOrderChanges = () => {
        let items: OrderItem[] = [];

        props.order.items.forEach((item) => {
            let serialItem = serialItems.find((x) => x.id === item.id);
            if (serialItem) {
                items.push(serialItem);
            } else {
                items.push(item);
            }
        });

        orderMutation.mutate({ ...props.order, items });
    };

    const setInitialSerials = () => {
        let items: OrderItem[] = [];
        props.order.items
            ?.filter((x) => x.product?.includesSerials)
            .forEach((item) => {
                let serials = new Array(item.quantity).fill("");
                item.serials?.forEach((serial, index) => {
                    serials[index] = serial;
                });
                items.push({ ...item, serials });
            });
        setSerialItems(items);
    };

    const onItemChange = (item: OrderItem, index: number) => {
        let items = [...serialItems];
        items[index] = item;
        setSerialItems(items);
    };

    return (
        <OrderSerialsConfigView>
            <TextField light small>
                Para mover la orden al siguiente estado debes ingresar todos los seriales
            </TextField>
            <div className="items">
                {serialItems.map((item, index) => (
                    <OrderItemSerial item={item} index={index} onItemChange={onItemChange} />
                ))}
            </div>

            <Flex alignCenter justifyEnd className="mt-2" gap15>
                <div className="serial-summary">
                    <ProgressBarValue title="Seriales" limitValue={serialValues.total} currentValue={serialValues.current} />
                </div>
                {orderMutation.isLoading && <LoadingDualRing small />}
                <PrimaryButton disabled={orderMutation.isLoading} onClick={onSaveOrderChanges}>
                    Guardar Seriales
                </PrimaryButton>
            </Flex>
        </OrderSerialsConfigView>
    );
}

interface OrderItemSerialProps {
    item: OrderItem;
    index: number;
    onItemChange: (item: OrderItem, index: number) => void;
}

const OrderItemSerial = (props: OrderItemSerialProps) => {
    const { item } = props;

    const onSerialChange = (serial: string, index: number) => {
        let serials = [...(item.serials ?? [])];
        serials[index] = serial;

        props.onItemChange({ ...item, serials }, props.index);
    };

    const serials = item.serials && item.serials.length > 0 ? item.serials : [""];
    const serialCount = serials.filter((x) => !!x).length;

    return (
        <Flex className="order-item" alignCenter gap10>
            <FlexImageStyle fitCover width={40} borderRadius={10}>
                <img src={getProductThumbnailUrl(item.product, item.productVariant)} alt="" />
            </FlexImageStyle>
            <Flex column w100>
                <Flex spaceBetween w100>
                    <Flex column>
                        <span>{item.product?.name}</span>
                        <span className="text-light text-small">{getProductSku(item.product, item.productVariant)}</span>
                    </Flex>
                    <Flex gap10 alignCenter>
                        <span className={`text-light text-small ${serialCount === item.quantity ? "text-primary" : ""}`}>
                            Seriales: {serials.filter((x) => !!x).length} de {item.quantity}
                        </span>
                    </Flex>
                </Flex>

                <GridTemplate className="mt-1" gap={5} size={160}>
                    {serials.map((serial, index) => {
                        return (
                            <SerialLine alignCenter className={`serial-line ${serial.length > 0 ? "active" : ""}`}>
                                <SelectBody className="select-body">
                                    <CreateTable
                                        placeholder="Seriales"
                                        className="create-table"
                                        classNamePrefix="select"
                                        key={serial}
                                        defaultValue={serial ? getSelectOption({ id: serial, value: serial }) : undefined}
                                        isDisabled={false}
                                        onCreateOption={(value) => onSerialChange(value, index)}
                                        onChange={(e: any) => {
                                            onSerialChange(e?.value ?? "", index);
                                        }}
                                        isRtl={false}
                                        isSearchable={true}
                                        isClearable
                                        name="seller"
                                        options={item.availableSerials
                                            ?.filter((x) => !serials.includes(x))
                                            .map((item) => getSelectOption({ id: item, value: item }))}
                                    />
                                </SelectBody>
                            </SerialLine>
                        );
                    })}
                </GridTemplate>
            </Flex>
        </Flex>
    );
};
