import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { device } from "../../../theme/themeProvider";

interface GroupButtonContainerProps {
    backgroundMain?: boolean;
}

export const GroupButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 5px;
    border-radius: 15px;
    background: ${(props) => props.theme.palette.backgroundLight};
    ${(props: GroupButtonContainerProps) => (props.backgroundMain ? `background: ${(p: any) => p.theme.palette.background}` : "")}
    width: fit-content;
    flex-wrap: wrap;
    gap: 5px;
    box-sizing: border-box;

    &.w100 {
        width: 100%;
        flex-wrap: nowrap;
    }
    &.hide-padding {
        padding: 0;
    }

    button {
        border: 0px;
        background: ${(props) => props.theme.palette.backgroundLight};
        border-radius: 12px;
        color: ${(props) => props.theme.palette.textLight};

        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            color: ${(props) => props.theme.palette.text};
        }
    }

    .active {
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.primary};
        box-shadow: 1px 1px 4px ${(props) => props.theme.palette.shadowColor};
        &:hover {
            background: ${(props) => props.theme.palette.background};
            color: ${(props) => props.theme.palette.primary};
        }
    }

    &.small-view {
        padding: 0px;
        button {
            padding: 5px 10px;
            font-size: 13px;
        }
    }
`;

interface DefaultButtonProps {
    theme: DefaultTheme;
    small?: boolean;
    bgLight?: boolean;
    colorLight?: boolean;
    colorPrimary?: boolean;
    primaryOutline?: boolean;
    rounded?: boolean;
    borderRadius?: number;
    primaryStyle?: boolean;
    hideBorder?: boolean;
    w100?: boolean;
    justifyStart?: boolean;
    removeBackground?: boolean;
    withOutBackground?: boolean;
    colorText?: boolean;
    border?: boolean;
}

export const DefaultButton = styled.button`
    background: ${(props) => props.theme.palette.background};
    outline: transparent;
    padding: 5px 14px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    color: ${(props) => props.theme.palette.text};
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    white-space: nowrap;
    gap: 10px;
    position: relative;
    width: ${(props: DefaultButtonProps) => (props.w100 ? "100%" : "fit-content")};

    &.small {
        font-size: 13px;
        padding: 5px 11px;
    }

    &.bg-light {
        border-color: transparent;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    &.light-color {
        color: ${(props) => props.theme.palette.textLight};
    }

    .left-icon {
        margin-left: auto;
    }

    ${(props: DefaultButtonProps) => (props.small ? `font-size: 13px; padding: 3px 11px;` : "")}
    ${(props: DefaultButtonProps) =>
        props.bgLight
            ? `border-color: transparent;background: ${props.theme.palette.backgroundLight}; color: ${props.theme.palette.text};`
            : ""}
    ${(props: DefaultButtonProps) =>
        props.withOutBackground
            ? `border-color: transparent; background: ${props.theme.palette.background}; color: ${props.theme.palette.text};`
            : ""}

    ${(props: DefaultButtonProps) => (props.colorLight ? `color: ${props.theme.palette.textLight}; ` : "")}
    ${(props: DefaultButtonProps) => (props.colorPrimary ? `color: ${props.theme.palette.primary}; ` : "")}
    ${(props: DefaultButtonProps) => (props.colorText ? `color: ${props.theme.palette.text}; ` : "")}
    ${(props: DefaultButtonProps) => (props.removeBackground ? `background: transparent;` : "")}

    justify-content: ${(props: DefaultButtonProps) => (props.justifyStart ? "flex-start" : "center")};
    border-radius: ${(props: DefaultButtonProps) => (props.rounded ? "50px" : "3px")};
    ${(props: DefaultButtonProps) => props.borderRadius && `border-radius: ${props.borderRadius}px;`}

    justify-content: ${(props: DefaultButtonProps) => (props.justifyStart ? "flex-start" : "center")};
    align-items: center;

    ${(props: DefaultButtonProps) =>
        props.primaryOutline
            ? `border: 1px solid ${props.theme.palette.primary}; background: ${props.theme.palette.primary}20; color: ${props.theme.palette.primary};`
            : ""}
    ${(props: DefaultButtonProps) => (props.hideBorder ? `border: 0;` : "")}

    &:disabled {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight}70;
        border-color: transparent;
        opacity: 0.6;
        cursor: default;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            color: ${(props) => props.theme.palette.textLight}70;
        }
    }

    &:hover {
        transition: background 0.3s;
        ${(props: DefaultButtonProps) =>
            props.withOutBackground
                ? `background: ${props.theme.palette.backgroundLight};`
                : `background: ${props.theme.palette.backgroundDark};`}
    }

    &.filled {
        background: ${(props) => props.theme.palette.backgroundDark};

        &:hover {
            transition: background 0.3s;
            color: ${(props) => props.theme.palette.text};
        }
    }
    .counter {
        position: absolute;
        top: -5px;
        right: -5px;
        background: ${(props) => props.theme.palette.primary};
        color: white;
        font-weight: 500;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
    }

    &.dropdown-button {
        border: 0px;
        width: 100%;
        justify-content: flex-start;
        border-radius: 15px;
    }

    &.isSelected {
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.primary};

        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

interface ButtonFilterProps {
    counter: number;
    title?: string;
    onClick: () => void;
    small?: boolean;
}

export const ButtonFilter = (props: ButtonFilterProps) => {
    const Button = props.title ? DefaultButton : SquareButton;
    return (
        <ButtonFilterContainer className={props.counter > 0 ? "active" : ""}>
            <Button small={props.small} rounded onClick={props.onClick}>
                {props.title && <span className="filter-title">{props.title} </span>}
                <i className="fa-regular fa-filter"></i>
                {props.counter > 0 && <span className="btn-count">{props.counter}</span>}
            </Button>
        </ButtonFilterContainer>
    );
};

const ButtonFilterContainer = styled.div`
    button {
        color: ${(props) => props.theme.palette.textLight};
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        min-height: 33px;
        gap: 0;
        transition: gap 0.2s ease-in-out;
    }
    &.active {
        button {
            color: ${(props) => props.theme.palette.primary};
            background: ${(props) => props.theme.palette.primary}20;
            border-color: transparent;
            &:hover {
                background: ${(props) => props.theme.palette.primary}40;
            }
        }
    }
    .btn-count {
        position: absolute;
        background: ${(props) => props.theme.palette.primary};
        color: white;
        top: -8px;
        right: 0;
        font-size: 12px;
        padding: 0px 6px;
        border-radius: 10px;
    }

    .filter-title {
        visibility: hidden;
        font-size: 0;
        transition: font-size 0.2s ease-in-out;
    }
    :hover {
        button {
            gap: 10px;
        }
        .filter-title {
            visibility: visible;
            font-size: 13px;
        }
    }
`;

export const DefaultButtonFill = styled(DefaultButton)`
    background: ${(props) => props.theme.palette.backgroundDark};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDarker};
    }
`;

export const PrimaryButton = styled(DefaultButton)`
    color: white;
    background: ${(props) => props.theme.palette.primary};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.primaryDark};
    }
`;
export const PrimaryGradientButton = styled(DefaultButton)`
    background-image: linear-gradient(45deg, rgba(63, 94, 251, 1), #e62fb8);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    &.square {
        width: 33px;
        height: 33px;
        min-width: 33px;

        &.small {
            width: 25px;
            height: 25px;
            min-width: 25px;
        }
    }

    &:hover {
        box-shadow: 1px 1px 5px #ffc0cb63;

        background-image: linear-gradient(45deg, rgba(63, 94, 251, 1), #e62fb8);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    &:disabled {
        background-image: linear-gradient(45deg, rgba(63, 94, 251, 1), #e62fb8);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        border-color: transparent;
        opacity: 0.6;
        cursor: default;
        &:hover {
            background: transparent;
            box-shadow: none;
            background-image: linear-gradient(45deg, rgba(63, 94, 251, 1), #e62fb8);
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
        }
    }
`;

export const GreenButton = styled(DefaultButton)`
    color: ${(props) => props.theme.palette.green};
    background: ${(props) => props.theme.palette.greenLight};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.green};
        color: white;
    }
`;

export const PrimaryButtonDark = styled(DefaultButton)`
    background: ${(props) => props.theme.palette.text};
    color: ${(props) => props.theme.palette.background};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.textDark};
    }
`;

export const PrimaryButtonOutline = styled(DefaultButton)`
    color: ${(props) => props.theme.palette.primary};
    background: ${(props) => props.theme.palette.background};
    border-color: ${(props) => props.theme.palette.backgroundDark};
    &:hover {
        background: ${(props) => props.theme.palette.primaryDark};
        color: white;
        border-color: transparent;
    }
`;

export const PrimaryButtonMobile = styled(PrimaryButton)`
    .title {
        display: flex;
    }
    .line-icon {
        display: flex;
    }
    .btn-mobile-icon {
        display: none;
    }
    position: initial;
    z-index: 3;
    bottom: 23px;
    right: 13px;
    padding: 5px 14px;
    box-shadow: none;

    background: transparent;
    color: ${(props) => props.theme.palette.primary};
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    transition: 0.3 background ease-in-out;

    :hover {
        background: ${(props) => props.theme.palette.primary};
        border: 1px solid transparent;
        color: white;
    }

    @media (max-width: 767px) {
        .btn-mobile-icon {
            display: flex;
            margin: 0;
            font-size: 1.3rem;
        }
        .title {
            display: none;
        }
        .line-icon {
            display: none;
        }

        background: ${(props) => props.theme.palette.primary};
        color: white;
        border: 1px solid transparent;

        width: 45px;
        height: 45px;
        position: fixed;
        z-index: 3;
        bottom: 23px;
        right: 13px;
        padding: 15px;
        box-shadow: -3px -3px 11px ${(props) => props.theme.palette.shadowColor};
    }
`;

export const ButtonMobileRounded = styled(PrimaryButton)`
    border-radius: 50%;
    padding: 10px;
`;

export const TrashButtonMobile = styled(DefaultButton)`
    position: fixed;
    z-index: 3;
    bottom: 90px;
    right: 18px;
    font-size: 17px;
    padding: 12px;
    background: #c1c1c1;
    border-color: transparent;
    color: white;
    border-radius: 50px;
    &:hover {
        transition: background 0.2s;
        transition: color 0.2s;
        background: #ff001ade;
        color: white;
        .counter {
            color: transparent;
            background: transparent;
        }
    }
    .counter {
        position: absolute;
        top: -5px;
        right: -5px;
        background: #ff001ade;
        color: white;
        font-weight: 500;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        transition: all 0.2s ease-in-out;
    }
    @media ${device.md} {
        .title {
            display: flex;
        }
        .line-icon {
            display: flex;
        }
        .btn-mobile-icon {
            display: none;
        }
        position: initial;
        z-index: 3;
        bottom: 23px;
        right: 13px;
        padding: 7px 14px;
        box-shadow: none;
    }
`;

export const PrimaryButton2 = styled(DefaultButton)`
    color: white;
    background: ${(props) => props.theme.palette.primary2};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.primaryDark2};
    }
`;

export const PrimaryButton3 = styled(DefaultButton)`
    color: white;
    background: #7c4dff;
    border-color: transparent;
    &:hover {
        background: #3c206f;
    }
`;

export const DangerButton = styled(DefaultButton)`
    color: white;
    background: #f44336;
    border-color: transparent;
    &:hover {
        background: #b7362d;
    }
`;

export const DangerButtonOutline = styled(DefaultButton)`
    color: ${(props) => props.theme.palette.dangerColor};
    background: ${(props) => props.theme.palette.dangerColor}20;
    border-color: ${(props) => props.theme.palette.dangerColor};
    &:hover {
        background: ${(props) => props.theme.palette.dangerColor};
        color: white;
    }
`;

export const SquareButton = styled(DefaultButton)`
    width: 33px;
    height: 33px;
    min-width: 33px;

    [class^="wahioicon"] {
        margin-right: 0px;
    }

    &.small {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }

    ${(props: DefaultButtonProps) => (props.small ? ` width: 25px;height: 25px;min-width: 25px;` : "")}

    padding: 0px;
    border: 1px solid transparent;
    color: ${(props) => props.theme.palette.text};
    background: ${(props) => props.theme.palette.backgroundLight};

    ${(props: DefaultButtonProps) => props.border && `border: 1px solid ${props.theme.palette.backgroundDark};`}
    ${(props: DefaultButtonProps) => (props.removeBackground ? `background: transparent;` : "")}
    ${(props: DefaultButtonProps) => (props.colorText ? `color: ${props.theme.palette.text};` : "")}

    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark}40;
    }

    &.primary {
        color: ${(props) => props.theme.palette.primary};
    }

    &.bg-light {
        color: ${(props) => props.theme.palette.textLight};
        background: ${(props) => props.theme.palette.background};

        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    &.danger {
        background: #f443363d;
        color: #f44336;
        &:hover {
            background: #f4433652;
        }
    }

    border-radius: 50px;
    outline-style: none;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const SquareButtonOutline = styled(SquareButton)`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    background: ${(props) => props.theme.palette.background};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const ButtonColumn = styled(DefaultButton)`
    border-radius: 30px;
    border: 0;
    font-size: 13px;
    color: ${(props) => props.theme.palette.textLight};
    [class^="wahioicon"] {
        margin-right: 0px;
    }
    &.active {
        color: ${(props) => props.theme.palette.primary};
    }
`;
