import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { SelectBaseOption } from "../../../api/models";
import { getUserPermissions } from "../../../api/models/accountUser";
import { OrderItem } from "../../../api/models/order";
import { ITaxOrderItem, convertTaxOrderItem } from "../../../api/models/orderBase";
import { IProduct, IProductVariant } from "../../../api/models/product";
import { ITax } from "../../../api/models/tax";
import { IWarehouseLocation, WarehouseLocationProductSearch } from "../../../api/models/warehouseLocation";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { CloudImages } from "../../../assets";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { formatMoney, getModelFullName, ignoreElementClick } from "../../../utils";
import { getSelectOption } from "../../../utils/select";
import { applyTaxToValue, calculateItemBasePrice, extractTaxInformation, getItemTotalTax } from "../../../utils/taxes";
import { useAlert } from "../../Alerts/Alert";
import DateTimeLabel from "../../DatePicker/DateTimeLabel";
import { ProductProfileModal } from "../../Products/ProductProfileNavigation";
import TaxOrderItemModal from "../../Products/Taxes/TaxOrderItem/TaxOrderItemModal";
import { getProductSku, getProductSkuAndCode, getProductThumbnailUrl } from "../../Products/helper";
import PreparationStatusIcon from "../../Restaurants/PreparationStatusIcon";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import TextBoxNumberTimer from "../../TextBoxNumeral/TextBoxNumeralTimer";
import { Flex, FlexImageStyle, SquareButton, TextField } from "../../_controls";
import LabelPair from "../../_controls/LabelPair";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import { useConfirmationModal } from "../../modals/ConfirmationModal";
import { OrderFormOrigin } from "../CreateOrderForm";
import { getOrderItemTotals } from "../CreateOrderForm/helpers";
import {
    CardBody,
    CardContainer,
    EditSpan,
    FlexSpaceBetween,
    FlexWrapContainer,
    ImageCardContainer,
    ImageContainer,
    PriceContainer,
    QuantityContainer,
    QuantityContainerBody,
    RowContainer,
} from "../OrderStyles/RowStyles";
import { getAvailableStock, stockIsValid } from "../orderUtils";
import OrderItemModal from "./OrderItemModal";
import {
    ButtonPercentage,
    DateTitleLine,
    FlexCol,
    LabelPairCard,
    NegativeQuantityContainer,
    PercentageCard,
    ProductSerialsContainer,
} from "./styled";

export interface OrderItemActionsProps {
    hideLocationCode: boolean;
    onUpdateItem: (item: OrderItem) => void;
    onRemoveItem: (item: OrderItem) => void;
    availableTaxes: ITax[];
}

interface OrderItemProps extends OrderItemActionsProps {
    orderItem: OrderItem;
    hideSerials?: boolean;
    isMobile: boolean;
    isSmallMobile?: boolean;
    origin?: OrderFormOrigin;
    dateTitle?: string;
}

export interface OrderItemCardProps extends OrderItemProps {
    image: any;
    setShowTaxesModal: (show: boolean) => void;
    showTaxesModal: boolean;
    onPriceChange: (value: number) => void;
    onClickTax?: (tax: ITax) => void;
    onClickRemoveTax?: (value: ITaxOrderItem) => void;
    onChangeWarehouseProductVariant: (value?: IWarehouseProduct) => void;
    warehouseLocations: IWarehouseLocation[];
    onClickProductPreview: () => void;
}

interface QuantityCardProps {
    orderItem: OrderItem;
    onUpdateItem: (value: OrderItem) => void;
    smallView?: boolean;
    bigView?: boolean;
    autoWidth?: boolean;
    origin?: OrderFormOrigin;
}

export const QuantityCard = (props: QuantityCardProps) => {
    const alert = useAlert();

    const inputRef = React.useRef<any>();

    useEffect(() => {
        if (props.origin !== "quotation" && !stockIsValid(props.orderItem)) {
            alert.info(`Stock insuficiente para ${props.orderItem.product?.name}, disponible: ${props.orderItem.stockTemp}`);
            props.onUpdateItem({ ...props.orderItem, quantity: props.orderItem.stockTemp ?? 0 });
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orderItem.quantity, props.orderItem.stockTemp]);

    const increaseQuantity = (value: number) => {
        const newQuantity = props.orderItem.quantity + value;
        if (newQuantity < 1) return;

        if (props.origin !== "quotation" && !stockIsValid(props.orderItem)) {
            alert.info(`Stock insuficiente para ${props.orderItem.product?.name}, disponible: ${props.orderItem.stockTemp}`);
            if (props.orderItem.quantity > (props.orderItem.stockTemp ?? 0)) {
                props.onUpdateItem({ ...props.orderItem, quantity: props.orderItem.stockTemp ?? 0 });
            }
            return;
        }

        props.onUpdateItem({ ...props.orderItem, quantity: newQuantity });
    };

    const updateQuantity = (value: number) => {
        if (value <= 0) return;
        props.onUpdateItem({ ...props.orderItem, quantity: value });
    };

    return (
        <QuantityContainerBody className={`${props.bigView ? "big-view" : ""}`}>
            {props.origin !== "quotation" &&
                props.orderItem.stockTemp !== undefined &&
                props.orderItem.stockTemp - props.orderItem.quantity < 0 &&
                props.orderItem.product?.controlInventory && (
                    <NegativeQuantityContainer data-tip="Nuevo stock de producto en negativo ">
                        {props.orderItem.stockTemp - props.orderItem.quantity}
                    </NegativeQuantityContainer>
                )}
            <QuantityContainer className={`${props.smallView ? "small" : ""} ${props.bigView ? "big-view" : ""}`}>
                <SquareButton className="btn-quantity" onClick={() => increaseQuantity(-1)}>
                    <i className="fa-regular fa-minus"></i>
                </SquareButton>
                <TextBoxNumberTimer
                    id="textOrderQuantity"
                    ref={inputRef}
                    milliseconds={500}
                    className="textbox-quantity textbox-order-item"
                    format="number"
                    type="text"
                    value={props.orderItem.quantity}
                    onNumberChange={updateQuantity}
                    maxLength={9}
                />
                <SquareButton className="btn-quantity" onClick={() => increaseQuantity(1)}>
                    <i className="fa-regular fa-plus"></i>
                </SquareButton>
            </QuantityContainer>
        </QuantityContainerBody>
    );
};

const getSelectOptionSerial = (item?: string) => {
    let option: SelectBaseOption = {
        id: item ?? "",
        value: item ?? "",
        label: item ?? "",
    };
    return option;
};

interface OrderItemsRowContainerProps {
    items: OrderItem[];
    onChange: (items: OrderItem[]) => void;
}
export const OrderItemsRowContainer = (props: OrderItemsRowContainerProps) => {
    const onUpdateItem = (item: OrderItem, index: number) => {
        let items = [...props.items];
        items[index] = getOrderItemTotals(item);
        props.onChange(items);
    };

    const onRemoveItem = (index: number) => {
        props.onChange(props.items.filter((x, i) => i !== index));
    };

    return (
        <Flex column gap5 minHeight={300}>
            {props.items.map((item, index) => {
                return (
                    <OrderItemRow
                        isMobile={true}
                        isSmallMobile={true}
                        hideLocationCode={false}
                        orderItem={item}
                        key={index}
                        availableTaxes={[]}
                        onUpdateItem={(item) => onUpdateItem(item, index)}
                        onRemoveItem={() => onRemoveItem(index)}
                    />
                );
            })}
        </Flex>
    );
};

export default function OrderItemRow(props: OrderItemProps) {
    const { orderItem, availableTaxes } = props;
    const { product, productVariant } = orderItem;
    const [showTaxesModal, setShowTaxesModal] = useState(false);
    const [warehouseLocations, setWarehouseLocations] = useState<IWarehouseLocation[]>([]);
    const [showProductPreview, setShowProductPreview] = useState(false);
    const alert = useAlert();
    const loadedRef = useRef(false);

    useEffect(() => {
        if (props.origin !== "restaurant" && !orderItem.additionalInfoLoaded && !loadedRef.current) {
            if (orderItem.warehouse?.allowLogisticsService && !props.hideLocationCode) {
                fetchWarehouseLocationsByProduct();
            }
            loadedRef.current = true;
            props.onUpdateItem({ ...orderItem, additionalInfoLoaded: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchWarehouseProduct = () => {
        if (props.orderItem.warehouseProduct) {
            wahioFetch.get(
                productEndpoint.get.warehouseProductById(props.orderItem.warehouseProduct?.id ?? ""),
                (success) => {
                    const warehouseProduct: IWarehouseProduct = success.data;
                    props.onUpdateItem({
                        ...orderItem,
                        warehouseProduct,
                        product: warehouseProduct.product,
                        stockTemp: getAvailableStock(warehouseProduct),
                    });
                },
                (error) => {}
            );
        }
    };

    // const handleSearchReservedCount = () => {
    //     const warehouseProduct = orderItem.warehouseProduct;
    //     if (orderItem.id || !orderItem.product?.controlInventory || orderItem.product.isParent) return;
    //     if (!warehouseProduct) return;

    //     const data: ProductReservedSearchInput = {
    //         productId: warehouseProduct.productId,
    //         warehouseId: warehouseProduct.warehouseId,
    //         productVariantId: orderItem.productVariantId,
    //     };

    //     wahioFetch.post(
    //         localOrderEndpoint.post.getProductReservedCount,
    //         data,
    //         (success) => {
    //             const data: ProductCountResponse = success.data;
    //             if (data.count !== warehouseProduct.reserved) {
    //                 autoUpdateReservedStock(data.count);
    //             }
    //         },
    //         (error) => {
    //             alert.error(getServiceMessageError(error));
    //         }
    //     );
    // };

    // const autoUpdateReservedStock = (reservedStock: number) => {
    //     const warehouseProduct = orderItem.warehouseProduct;
    //     if (!warehouseProduct) return;

    //     const body: IInventoryProductAdjustmentModel = {
    //         productId: warehouseProduct.productId,
    //         warehouseId: warehouseProduct.warehouseId,
    //         productVariantId: orderItem.productVariantId,
    //         accountUserId: getAuthBody().accountUserId,
    //         quantity: reservedStock,
    //         serials: [],
    //     };

    //     wahioFetch.put(
    //         productEndpoint.put.updateReservedStock,
    //         body,
    //         () => {
    //             alert.info(
    //                 `El stock reservado de '${product?.name}' ha sido ajustado automáticamente de (${warehouseProduct.reserved}) a (${reservedStock})`
    //             );
    //             warehouseProduct.reserved = reservedStock;
    //             let stockTemp = warehouseProduct.stock - warehouseProduct.reserved;
    //             stockTemp = stockTemp < 0 ? 0 : stockTemp;
    //             const quantity = orderItem.quantity > stockTemp ? stockTemp : orderItem.quantity;
    //             props.onUpdateItem({ ...orderItem, warehouseProduct, stockTemp, quantity });
    //         },
    //         (error) => {
    //             alert.error(getServiceMessageError(error));
    //         }
    //     );
    // };

    const fetchWarehouseLocationsByProduct = () => {
        let input: WarehouseLocationProductSearch = {
            productId: orderItem.productId,
            warehouseId: orderItem.warehouseId,
            productVariantId: orderItem.productVariantId,
        };
        wahioFetch.post(
            productEndpoint.post.warehouseLocationByProduct,
            input,
            (success) => {
                setWarehouseLocations(success.data);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const onPriceChange = (newValue: number) => {
        orderItem.unitPrice = newValue;
        let basePriceResult = calculateItemBasePrice(orderItem, orderItem.taxes, true, false);
        orderItem.basePrice = basePriceResult.basePrice;
        orderItem.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;
        let taxAmount = getItemTotalTax(orderItem.taxes, orderItem, true, false);
        props.onUpdateItem({ ...orderItem, totalTax: taxAmount, basePrice: basePriceResult.basePrice, unitPrice: newValue });
    };

    const onChangeWarehouseProductVariant = (warehouseProduct?: IWarehouseProduct) => {
        onPriceChange(warehouseProduct?.productVariant?.price ?? orderItem.basePrice);
        props.onUpdateItem({
            ...props.orderItem,
            productVariant: warehouseProduct?.productVariant,
            productVariantId: warehouseProduct?.productVariant?.id,
            stockTemp: warehouseProduct?.stock,
        });
    };

    const image = getProductThumbnailUrl(product, productVariant);

    const currentProps: OrderItemCardProps = {
        ...props,
        orderItem,
        onPriceChange,
        image,
        setShowTaxesModal,
        showTaxesModal,
        availableTaxes,
        onChangeWarehouseProductVariant,
        warehouseLocations,
        onClickProductPreview: () => setShowProductPreview(true),
    };

    if (props.origin === "restaurant") {
        return <OrderItemRestaurant {...currentProps} />;
    }

    return (
        <>
            {showProductPreview && (
                <ProductProfileModal
                    show={showProductPreview}
                    setShow={() => setShowProductPreview(false)}
                    product={product}
                    onUpdateProduct={() => handleSearchWarehouseProduct()}
                />
            )}
            {props.isMobile ? <OrderCard {...currentProps} /> : <OrderRow {...currentProps} />}
        </>
    );
}

function OrderRow(props: OrderItemCardProps) {
    const { orderItem, image } = props;
    const { product } = orderItem;
    const { onChangeWarehouseProductVariant } = props;
    const [showOrderItemModal, setShowOrderItemModal] = useState(false);
    const { appSettingsState } = useAppSettingsContext();
    const { userState } = useContext(UserContext);

    const getSelectOptionVariant = (item?: IProductVariant) => {
        let option: SelectBaseOption = {
            id: item?.id ?? "",
            value: item?.id ?? "",
            label: item?.valuesLine ?? "",
        };
        return option;
    };

    const onAddSerial = (serial?: string) => {
        if (!serial) return;
        let serials = orderItem.serials ?? [];
        serials = [...serials, serial];
        props.onUpdateItem({ ...orderItem, serials, quantity: serials.length });
    };

    const onRemoveSerial = (serial: string) => {
        let serials = orderItem.serials?.filter((x) => x !== serial) ?? [];
        props.onUpdateItem({ ...orderItem, serials, quantity: serials.length });
    };

    const optionalsConfig = orderItem.optionalsConfig;
    const permission = getUserPermissions(userState.user).order;

    return (
        <RowContainer>
            <td className="min-width">
                <Flex className="icon-card">
                    <TextField className="position-index" small bold>
                        {orderItem.position}
                    </TextField>
                    <SquareButton removeBackground className="btn-delete" onClick={() => props.onRemoveItem(orderItem)}>
                        <i className="fa-regular fa-trash"></i>
                    </SquareButton>
                </Flex>
            </td>
            <td className="min-width">
                <ImageContainer className="image-container" onClick={() => setShowOrderItemModal(true)}>
                    <span className="wahioicon-pen edit-icon"></span>
                    <img src={image} alt={product?.name} />
                </ImageContainer>
                {showOrderItemModal && <OrderItemModal {...props} show={showOrderItemModal} setShow={setShowOrderItemModal} />}
            </td>
            <td>
                <FlexCol>
                    <span className="product-title">{product?.name}</span>
                    {optionalsConfig && (
                        <>
                            {optionalsConfig.totalExtraPrice > 0 && (
                                <TextField small light>
                                    Extra: + {formatMoney(optionalsConfig.totalExtraPrice)}
                                </TextField>
                            )}
                            {optionalsConfig.description && (
                                <TextField small light>
                                    {optionalsConfig.description}
                                </TextField>
                            )}
                        </>
                    )}

                    <TextField small>{getProductSkuAndCode(product, props.orderItem.productVariant)}</TextField>

                    {(orderItem.totalTaxPercentage > 0 || orderItem.manualPercentageDiscount > 0) && (
                        <Flex gap5>
                            {orderItem.totalTaxPercentage > 0 && (
                                <ButtonPercentage title="Impuesto" className="tax">
                                    <span className="tax-percentage">{orderItem.totalTaxPercentage}%</span>
                                </ButtonPercentage>
                            )}
                            {orderItem.manualPercentageDiscount > 0 && (
                                <ButtonPercentage title="Descuento" className="discount" onClick={() => setShowOrderItemModal(true)}>
                                    <span className="tax-percentage">{orderItem.manualPercentageDiscount}%</span>
                                </ButtonPercentage>
                            )}
                        </Flex>
                    )}

                    {!props.hideSerials && product?.includesSerials && (
                        <div>
                            <ProductSerialsContainer>
                                {props.orderItem.serials?.map((serial, index) => (
                                    <div className="serial-line">
                                        <span className="index">{index + 1}</span>
                                        <span className="serial mr-1">{serial}</span>
                                        <SquareButton className="small" onClick={() => onRemoveSerial(serial)}>
                                            <span className="wahioicon-trash"></span>
                                        </SquareButton>
                                    </div>
                                ))}
                            </ProductSerialsContainer>
                            <SelectBody className="border-none">
                                <Select
                                    placeholder="Seriales"
                                    className="select-min-len"
                                    classNamePrefix="select"
                                    key={props.orderItem.serials?.length}
                                    defaultValue={undefined}
                                    isDisabled={false}
                                    onChange={(e) => onAddSerial(e?.value)}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="seller"
                                    options={props.orderItem.warehouseProduct?.serials
                                        ?.filter((x) => !orderItem.serials?.includes(x))
                                        .map((item) => getSelectOptionSerial(item))}
                                />
                            </SelectBody>
                        </div>
                    )}

                    {props.orderItem.productVariant && (
                        <SelectBody className="border-none">
                            <Select
                                placeholder="Seleccionar"
                                className="select-min-len"
                                classNamePrefix="select"
                                key={props.orderItem.productVariantId}
                                defaultValue={
                                    props.orderItem.productVariant ? getSelectOptionVariant(props.orderItem.productVariant) : undefined
                                }
                                isDisabled={false}
                                onChange={(value) =>
                                    onChangeWarehouseProductVariant(
                                        props.orderItem.warehouseProduct?.warehouseProductVariants?.find(
                                            (x) => x.productVariantId === value?.id
                                        )
                                    )
                                }
                                isRtl={false}
                                isSearchable={true}
                                name="seller"
                                options={
                                    appSettingsState.productSearchOnlyStockProducts
                                        ? props.orderItem.warehouseProduct?.warehouseProductVariants
                                              ?.filter((x) => x.stock !== 0)
                                              .map((item) => getSelectOptionVariant(item.productVariant))
                                        : props.orderItem?.warehouseProduct?.warehouseProductVariants?.map((item) =>
                                              getSelectOptionVariant(item.productVariant)
                                          )
                                }
                            />
                        </SelectBody>
                    )}
                    {!props.hideLocationCode &&
                        props.orderItem.product?.controlInventory &&
                        props.orderItem.warehouse?.allowLogisticsService && (
                            <SelectBody className="">
                                <Select
                                    placeholder="Seleccionar"
                                    className="select-min-len"
                                    classNamePrefix="select"
                                    key={props.orderItem.locationCode}
                                    defaultValue={
                                        orderItem.locationCode
                                            ? getSelectOption({ id: orderItem.locationCode, value: orderItem.locationCode })
                                            : undefined
                                    }
                                    isDisabled={false}
                                    onChange={(value) => props.onUpdateItem({ ...orderItem, locationCode: value?.id })}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="location"
                                    options={props.warehouseLocations.map((item) =>
                                        getSelectOption({ id: item.code, value: `${item.code} (${item.globalStock})` })
                                    )}
                                />
                            </SelectBody>
                        )}
                </FlexCol>
            </td>
            <td>
                {!props.hideSerials && props.orderItem.product?.includesSerials ? (
                    <span>{props.orderItem.serials?.length}</span>
                ) : (
                    <QuantityCard {...props} />
                )}
            </td>
            <td>
                <Flex gap5 alignCenter>
                    <PriceContainer>
                        <TextBoxNumeral
                            className="input-price"
                            format="money"
                            value={orderItem.unitPrice}
                            onNumberChange={(value) => props.onPriceChange(value)}
                            maxLength={18}
                            disabled={!permission?.orderFormAllowEditPrice}
                        />
                        {product && orderItem.unitPrice !== product[orderItem.defaultPrice] && (
                            <EditSpan className="wahioicon-pencil-alt" />
                        )}
                    </PriceContainer>
                </Flex>
            </td>

            <td>
                <TextField bold600>{formatMoney(orderItem.total)}</TextField>
            </td>
        </RowContainer>
    );
}

function OrderCard(props: OrderItemCardProps) {
    const { orderItem, image } = props;
    const { product } = orderItem;
    const { appSettingsState } = useAppSettingsContext();
    const { onChangeWarehouseProductVariant } = props;
    const [showOrderItemModal, setShowOrderItemModal] = useState(false);

    const getSelectOptionVariant = (item?: IProductVariant) => {
        let option: SelectBaseOption = {
            id: item?.id ?? "",
            value: item?.id ?? "",
            label: item?.valuesLine ?? "",
        };
        return option;
    };

    const onAddSerial = (serial?: string) => {
        if (!serial) return;
        let serials = orderItem.serials ?? [];
        serials = [...serials, serial];
        props.onUpdateItem({ ...orderItem, serials, quantity: serials.length });
    };

    const onRemoveSerial = (serial: string) => {
        let serials = orderItem.serials?.filter((x) => x !== serial) ?? [];
        props.onUpdateItem({ ...orderItem, serials, quantity: serials.length });
    };

    const showSerialsQuantity = !props.hideSerials && props.orderItem.product?.includesSerials;

    const onClickCard = (e: any) => {
        if (ignoreElementClick(e.target, "ignore")) return;
        setShowOrderItemModal(true);
    };

    const optionalsConfig = orderItem.optionalsConfig;
    return (
        <>
            {showOrderItemModal && <OrderItemModal {...props} show={showOrderItemModal} setShow={setShowOrderItemModal} />}
            <CardContainer onClick={onClickCard}>
                <Flex alignCenter w100 gap10>
                    <Flex alignCenter gap5>
                        {!props.isSmallMobile && (
                            <SquareButton id="ignore" className="btn-position" onClick={() => props.onRemoveItem(orderItem)}>
                                <span className="btn-text">{orderItem.position}</span>
                                <i className="fa-regular fa-trash btn-icon"></i>
                            </SquareButton>
                        )}
                        <FlexImageStyle width={45} fitCover borderRadius={15}>
                            <img src={image} alt={product?.name} />
                        </FlexImageStyle>
                    </Flex>
                    <Flex column>
                        <Flex alignCenter spaceBetween>
                            <TextField>{product?.name}</TextField>
                            {showSerialsQuantity && <TextField>{props.orderItem.serials?.length}</TextField>}
                        </Flex>

                        {optionalsConfig && (
                            <>
                                {optionalsConfig.description && (
                                    <TextField small light>
                                        {optionalsConfig.description}
                                    </TextField>
                                )}
                            </>
                        )}

                        <TextField small light>
                            {getProductSku(product, props.orderItem.productVariant)}
                        </TextField>
                        <Flex alignCenter gap10 flexWrap>
                            <span>{formatMoney(orderItem.total)}</span>
                            {orderItem.totalTaxPercentage > 0 && <PercentageCard>{orderItem.totalTaxPercentage}%</PercentageCard>}

                            {orderItem.manualPercentageDiscount > 0 && (
                                <PercentageCard className="discount">
                                    {orderItem.details?.discountName} {orderItem.manualPercentageDiscount}%
                                </PercentageCard>
                            )}
                        </Flex>

                        {!props.hideSerials && product?.includesSerials && (
                            <div id="ignore">
                                <ProductSerialsContainer>
                                    {props.orderItem.serials?.map((serial, index) => (
                                        <div className="serial-line" key={index}>
                                            <span className="index">{index + 1}</span>
                                            <TextField className="serial mr-1">{serial}</TextField>
                                            <SquareButton className="small" onClick={() => onRemoveSerial(serial)}>
                                                <span className="fa-regular fa-trash"></span>
                                            </SquareButton>
                                        </div>
                                    ))}
                                </ProductSerialsContainer>
                                <SelectBody id="ignore" className="border-none">
                                    <Select
                                        placeholder="Seriales"
                                        className="select-min-len"
                                        classNamePrefix="select"
                                        key={props.orderItem.serials?.length}
                                        defaultValue={undefined}
                                        isDisabled={false}
                                        onChange={(e) => onAddSerial(e?.value)}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="seller"
                                        options={props.orderItem.warehouseProduct?.serials
                                            ?.filter((x) => !orderItem.serials?.includes(x))
                                            .map((item) => getSelectOptionSerial(item))}
                                    />
                                </SelectBody>
                            </div>
                        )}

                        {props.orderItem.productVariant && (
                            <SelectBody id="ignore" className="">
                                <Select
                                    placeholder="Seleccionar"
                                    className="select-min-len select-small"
                                    classNamePrefix="select"
                                    key={props.orderItem.productVariantId}
                                    defaultValue={
                                        props.orderItem.productVariant
                                            ? getSelectOption({
                                                  id: orderItem.productVariant?.id ?? "",
                                                  value: orderItem.productVariant?.valuesLine ?? "",
                                              })
                                            : undefined
                                    }
                                    isDisabled={false}
                                    onChange={(value) =>
                                        onChangeWarehouseProductVariant(
                                            props.orderItem.warehouseProduct?.warehouseProductVariants?.find(
                                                (x) => x.productVariantId === value?.id
                                            )
                                        )
                                    }
                                    isRtl={false}
                                    isSearchable={true}
                                    name="seller"
                                    options={
                                        appSettingsState.productSearchOnlyStockProducts
                                            ? props.orderItem.warehouseProduct?.warehouseProductVariants
                                                  ?.filter((x) => x.stock !== 0)
                                                  .map((item) => getSelectOptionVariant(item.productVariant))
                                            : props.orderItem?.warehouseProduct?.warehouseProductVariants?.map((item) =>
                                                  getSelectOptionVariant(item.productVariant)
                                              )
                                    }
                                />
                            </SelectBody>
                        )}
                        {!props.hideLocationCode && props.orderItem.warehouse?.allowLogisticsService && (
                            <SelectBody id="ignore" className="">
                                <Select
                                    placeholder="Seleccionar"
                                    className="select-min-len"
                                    classNamePrefix="select"
                                    key={props.orderItem.locationCode}
                                    defaultValue={
                                        orderItem.locationCode
                                            ? getSelectOption({ id: orderItem.locationCode, value: orderItem.locationCode })
                                            : undefined
                                    }
                                    isDisabled={false}
                                    onChange={(value) => props.onUpdateItem({ ...orderItem, locationCode: value?.id })}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="location"
                                    options={props.warehouseLocations.map((item) =>
                                        getSelectOption({ id: item.code, value: `${item.code} (${item.globalStock})` })
                                    )}
                                />
                            </SelectBody>
                        )}
                    </Flex>
                </Flex>
                {!showSerialsQuantity && !props.isSmallMobile && (
                    <div id="ignore">
                        <QuantityCard smallView {...props} />
                    </div>
                )}
                {props.isSmallMobile && (
                    <SquareButton
                        style={{ marginRight: "10px" }}
                        id="ignore"
                        className="btn-position"
                        onClick={() => props.onRemoveItem(orderItem)}
                    >
                        <span className="btn-text">{props.orderItem.quantity}</span>
                        <i className="fa-regular fa-trash btn-icon"></i>
                    </SquareButton>
                )}
            </CardContainer>
        </>
    );
}
export function OrderReturnItemCard(props: OrderItemProps) {
    const { orderItem, availableTaxes } = props;
    const { product } = orderItem;
    const [backgroundEffect, setBackgroundEffect] = useState(false);
    const [productLoaded, setProductLoaded] = useState(false);
    const timeValue = useRef(setTimeout(() => {}, 10));
    const [showTaxesModal, setShowTaxesModal] = useState(false);

    const [warehouseProductVariant, onChangeWarehouseProductVariant] = useState<IWarehouseProduct | undefined>();

    useEffect(() => {
        if (productLoaded) {
            loadInitialProductWithTaxes();
        } else {
            fetchAllProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLoaded]);

    useEffect(() => {
        setBackgroundEffect(true);
        clearTimeout(timeValue.current);
        timeValue.current = setTimeout(() => {
            setBackgroundEffect(false);
        }, 1000);
    }, [orderItem.quantity]);

    const fetchAllProduct = () => {
        setProductLoaded(false);

        if (product?.hasTaxes) {
            if (product?.taxes && product.taxes.length > 0) {
                setProductLoaded(true);
            } else {
                wahioFetch.get(
                    productEndpoint.get.product(product.id),
                    (success) => {
                        const product: IProduct = success.data;
                        props.onUpdateItem({ ...props.orderItem, product });
                        setProductLoaded(true);
                    },
                    (error) => {
                        console.error("Error al consultar el producto", error);
                    }
                );
            }
        } else {
            setProductLoaded(true);
        }
    };

    const calculateBasePrice = (price: number = orderItem.unitPrice, taxes: ITaxOrderItem[]): number => {
        let [taxPercentage, taxAmount] = extractTaxInformation(taxes);
        price -= taxAmount;
        price = price / (taxPercentage / 100 + 1);
        price = price < 0 ? 0 : price;
        return price;
    };

    const loadInitialProductWithTaxes = () => {
        let taxes = orderItem.taxes != null && orderItem.taxes.length > 0 ? orderItem.taxes : getTaxesFromProduct(0);
        calculateChangesFromTaxes(taxes);
    };

    const calculateChangesFromTaxes = (taxes: ITaxOrderItem[]) => {
        let basePrice = calculateBasePrice(orderItem.unitPrice, taxes);
        let taxAmount = getTotalTax(taxes, basePrice);
        props.onUpdateItem({ ...props.orderItem, basePrice: basePrice, taxes: taxes, totalTax: taxAmount });
    };

    const getTaxesFromProduct = (base: number): ITaxOrderItem[] => {
        if (!!product?.taxes) {
            let productTaxes: ITaxOrderItem[] = [];
            product.taxes.forEach((productTax) => {
                productTaxes.push(convertTaxOrderItem(productTax.tax, base, true));
            });
            return productTaxes;
        }
        return [];
    };

    const changePrice = (newValue: number) => {
        let basePrice = calculateBasePrice(newValue, orderItem.taxes);
        let taxAmount = getTotalTax(orderItem.taxes, orderItem.basePrice);
        props.onUpdateItem({ ...props.orderItem, totalTax: taxAmount, basePrice: basePrice, unitPrice: newValue });
    };

    const changeManualDiscount = (value: number) => {
        if (value > 100) {
            value = 100;
        }
        if (value < 0) {
            value = 0;
        }
        props.onUpdateItem({ ...props.orderItem, manualPercentageDiscount: value });
    };

    const getTotalTax = (taxes: ITaxOrderItem[], basePrice: number) => {
        let [taxPercentaje, taxAmmount] = extractTaxInformation(taxes);
        let newValue = applyTaxToValue(basePrice, taxPercentaje, taxAmmount);
        let taxAmount = newValue - basePrice;
        props.onUpdateItem({ ...props.orderItem, totalTax: taxAmount });
        return taxAmount;
    };

    const onClickTax = (tax: ITax) => {
        let existingTax = orderItem.taxes.filter((x) => x.taxId === tax.id);

        if (existingTax.length === 0) {
            let taxes = [...props.orderItem.taxes, convertTaxOrderItem(tax, orderItem.basePrice, false)];
            calculateChangesFromTaxes(taxes);
        }
    };

    const onClickRemoveTax = (tax: ITaxOrderItem) => {
        let taxes = props.orderItem.taxes.filter((x) => x.taxId !== tax.taxId);
        calculateChangesFromTaxes(taxes);
    };

    const image = product && product.profilePicture ? product.profilePicture : CloudImages.defaultImage;

    const currentProps = {
        ...props,
        saleItem: orderItem,
        backgroundEffect,
        image,
        setShowTaxesModal,
        showTaxesModal,
        changePrice,
        onClickTax,
        taxesAvaliables: availableTaxes,
        onClickRemoveTax,
        productLoaded,
        changeManualDiscount,
        warehouseProductVariant,
        onChangeWarehouseProductVariant,
        onPriceChange: (value: number) => {},
        onClickProductPreview: () => {},
    };

    return <OrderReturnCard {...currentProps} warehouseLocations={[]} />;
}

function OrderReturnCard(props: OrderItemCardProps) {
    const { orderItem, image, availableTaxes, setShowTaxesModal, showTaxesModal, onClickTax, onClickRemoveTax } = props;
    const { product } = orderItem;

    const total = orderItem.unitPrice * orderItem.quantity;

    return (
        <CardContainer>
            {showTaxesModal && (
                <TaxOrderItemModal
                    show={showTaxesModal}
                    setShow={setShowTaxesModal}
                    basePrice={orderItem.basePrice}
                    orderTaxes={orderItem.taxes}
                    taxesAvailable={availableTaxes}
                    taxAmount={orderItem.totalTax}
                    totalPrice={orderItem.unitPrice}
                    onClickTax={onClickTax ? onClickTax : () => {}}
                    onClickRemoveTax={onClickRemoveTax ? onClickRemoveTax : () => {}}
                />
            )}
            <ImageCardContainer className="mr-1">
                <img src={image} alt={product?.name} />
            </ImageCardContainer>
            <CardBody>
                <div className="title-header">
                    <div className="title-card">
                        <span>
                            {orderItem.position} - {product?.name}
                        </span>
                        <span className="light">
                            {product?.sku} {product?.code}
                        </span>
                        {orderItem.warehouse && <span className="light">{orderItem.warehouse.name}</span>}
                    </div>
                    <SquareButton className="action delete" onClick={() => props.onRemoveItem(orderItem)}>
                        <span className="wahioicon-trash-alt"></span>
                    </SquareButton>
                </div>
                <FlexWrapContainer className="mt-1">
                    <div>
                        <LabelPairCard className="with-hover" onClick={() => setShowTaxesModal(true)}>
                            <LabelPair title="Impuesto" value={formatMoney(orderItem.totalTax)} />
                        </LabelPairCard>
                    </div>
                    <LabelPairCard>
                        <PriceContainer>
                            <TextBoxNumeral
                                className="min"
                                format="number"
                                value={orderItem.manualPercentageDiscount}
                                onNumberChange={(value) => props.onUpdateItem({ ...orderItem, manualPercentageDiscount: value })}
                            />
                            <EditSpan className="wahioicon-percentage"></EditSpan>
                        </PriceContainer>
                    </LabelPairCard>
                    <LabelPairCard>
                        <LabelPair title="Total" value={formatMoney(total - total * (orderItem.manualPercentageDiscount / 100))} />
                    </LabelPairCard>
                </FlexWrapContainer>
                <FlexSpaceBetween className="mt-1">
                    <PriceContainer>
                        <TextBoxNumeral
                            className="input-price"
                            format="money"
                            value={orderItem.unitPrice}
                            onNumberChange={(value) => props.onUpdateItem({ ...orderItem, unitPrice: value })}
                        />
                        {product && orderItem.unitPrice !== product[orderItem.defaultPrice] && (
                            <EditSpan className="wahioicon-pencil-alt"></EditSpan>
                        )}
                    </PriceContainer>
                    <QuantityCard {...props} />
                </FlexSpaceBetween>
            </CardBody>
        </CardContainer>
    );
}

export const OrderItemRestaurant = (props: OrderItemCardProps) => {
    const { orderItem, image } = props;
    const { product } = orderItem;

    const [showOrderItemModal, setShowOrderItemModal] = useState(false);
    const { getUserById } = useAccountUserContext();
    const user = getUserById(props.orderItem.accountUserId);

    const ConfirmDelete = useConfirmationModal();

    const onClickCard = (e: any) => {
        if (ignoreElementClick(e.target, "ignore")) return;
        setShowOrderItemModal(true);
    };

    return (
        <>
            <ConfirmDelete.Modal
                onOkay={() => props.onRemoveItem(orderItem)}
                onCancel={() => ConfirmDelete.show(false)}
                title="Confirmar Eliminación de producto"
                description="Esta acción no se puede revertir, el producto sera eliminado"
            />
            {props.dateTitle && (
                <DateTitleLine>
                    <DateTimeLabel includeDayAndTime date={props.dateTitle} />
                </DateTitleLine>
            )}
            {showOrderItemModal && <OrderItemModal {...props} show={showOrderItemModal} setShow={setShowOrderItemModal} />}
            <CardContainer onClick={onClickCard}>
                <Flex alignCenter w100 gap10>
                    <FlexImageStyle width={40} borderRadius={15}>
                        <img src={image} alt={product?.name} />
                    </FlexImageStyle>
                    <Flex column w100>
                        <TextField>{product?.name}</TextField>

                        {props.orderItem.optionalsConfig?.description && (
                            <TextField small light>
                                {props.orderItem.optionalsConfig?.description}
                            </TextField>
                        )}

                        {props.orderItem.comment && (
                            <TextField small light>
                                {props.orderItem.comment}
                            </TextField>
                        )}

                        <Flex alignCenter gap10 flexWrap>
                            <TextField bold>{formatMoney(orderItem.total)}</TextField>
                            {orderItem.totalTaxPercentage > 0 && <PercentageCard>{orderItem.totalTaxPercentage}%</PercentageCard>}

                            {orderItem.manualPercentageDiscount > 0 && (
                                <PercentageCard className="discount">{orderItem.manualPercentageDiscount}%</PercentageCard>
                            )}

                            {orderItem.createdAt && <DateTimeLabel date={orderItem.createdAt} small light includeDayAndTime />}
                        </Flex>

                        {props.orderItem.productVariant && <ProductVariantCardSelection {...props} />}
                    </Flex>
                    <Flex alignCenter gap10>
                        <SquareButton id="ignore" small className="btn-position" onClick={() => ConfirmDelete.show()}>
                            <span className="btn-text">{props.orderItem.quantity}</span>
                            <i className="fa-regular fa-trash btn-icon"></i>
                        </SquareButton>
                        <PreparationStatusIcon status={orderItem.preparationStatus ?? "waiting"} />
                        {user && (
                            <FlexImageLetter
                                width={25}
                                image={user.image}
                                tooltipTitle={getModelFullName(user)}
                                text={user.firstName ?? "NA"}
                            />
                        )}
                    </Flex>
                </Flex>
            </CardContainer>
        </>
    );
};

const ProductVariantCardSelection = (props: OrderItemCardProps) => {
    const { orderItem, onChangeWarehouseProductVariant } = props;

    const { appSettingsState } = useAppSettingsContext();

    const getSelectOptionVariant = (item?: IProductVariant) => {
        let option: SelectBaseOption = {
            id: item?.id ?? "",
            value: item?.id ?? "",
            label: item?.valuesLine ?? "",
        };
        return option;
    };

    return (
        <SelectBody id="ignore" className="">
            <Select
                placeholder="Seleccionar"
                className="select-min-len select-small"
                classNamePrefix="select"
                key={props.orderItem.productVariantId}
                defaultValue={
                    props.orderItem.productVariant
                        ? getSelectOption({
                              id: orderItem.productVariant?.id ?? "",
                              value: orderItem.productVariant?.valuesLine ?? "",
                          })
                        : undefined
                }
                isDisabled={false}
                onChange={(value) =>
                    onChangeWarehouseProductVariant(
                        props.orderItem.warehouseProduct?.warehouseProductVariants?.find((x) => x.productVariantId === value?.id)
                    )
                }
                isRtl={false}
                isSearchable={true}
                name="seller"
                options={
                    appSettingsState.productSearchOnlyStockProducts
                        ? props.orderItem.warehouseProduct?.warehouseProductVariants
                              ?.filter((x) => x.stock !== 0)
                              .map((item) => getSelectOptionVariant(item.productVariant))
                        : props.orderItem?.warehouseProduct?.warehouseProductVariants?.map((item) =>
                              getSelectOptionVariant(item.productVariant)
                          )
                }
            />
        </SelectBody>
    );
};
