import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { updateClientSettingsPartial } from "../../../api/account/clientSettingsApi";
import { ClientSettingsMutationInput, ShipmentSettings, ShipmentStatusSetting } from "../../../api/models/accountUser";
import { DeliveryStatus, SIMPLE_SHIPMENT_STATUS_LIST } from "../../../api/models/deliveryOrder";
import { getAuthBody } from "../../../auth";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { useAlert } from "../../Alerts/Alert";
import Dropdown from "../../Dropdown";
import LoadingDualRing from "../../LoadingDualRing";
import { SHIPMENT_ICON_GROUP } from "../../Orders/Shipments/helpers";
import shipmentMessages from "../../Orders/Shipments/messages";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import { IconsPanelGrid, ShipmentSettingsPanelView } from "./styled";

const BLOCKED_STATUS: DeliveryStatus[] = ["pending", "delivered"];

const statusIcons = {
    picking: [],
    packing: [],
    pending: [
        "fa-regular fa-clock",
        "fa-regular fa-hourglass-start",

        // Relacionado a "recibido"
        "fa-regular fa-inbox",
        "fa-regular fa-handshake",
        "fa-regular fa-clipboard-check",

        // Relacionado a "pendiente"
        "fa-regular fa-exclamation-circle",
        "fa-regular fa-circle",
        "fa-regular fa-calendar-check",
    ],
    processing: [
        "fa-regular fa-pallet-box",
        "fa-regular fa-clipboard-list", // En preparación
        "fa-regular fa-utensils", // Se está cocinando
        "fa-regular fa-wrench", // Se está armando
        "fa-regular fa-wand-magic-sparkles",
        "fa-regular fa-box-open-full",
    ],
    readyToShip: [
        "fa-regular fa-route",
        "fa-regular fa-truck-loading",
        "fa-regular fa-dolly",
        "fa-regular fa-boxes",
        "fa-regular fa-box-check",
        "fa-regular fa-tasks",
        "fa-regular fa-conveyor-belt",
        "fa-regular fa-thumbs-up",
        "fa-regular fa-check-circle",
    ],
    shipped: [
        "fa-regular fa-person-biking-mountain",
        "fa-regular fa-motorcycle",
        "fa-regular fa-truck",
        "fa-regular fa-shipping-fast",
        "fa-regular fa-plane-departure",
        "fa-regular fa-ship",
    ],
    delivered: [
        "fa-regular fa-check",
        "fa-regular fa-box-check",
        "fa-regular fa-check-circle",
        "fa-regular fa-check-double",
        "fa-regular fa-check-square",
    ],
};

interface ShipmentSettingsPanelProps {
    onCompleted: () => void;
}

export default function ShipmentSettingsPanel(props: ShipmentSettingsPanelProps) {
    const intl = useIntl();
    const alert = useAlert();

    const clientSettingsHook = useClientSettings();
    const [settings, setSettings] = useState<ShipmentSettings>({ ...clientSettingsHook.shipmentSettings });

    const hasChanges = React.useMemo(() => {
        let json1 = JSON.stringify(settings);
        let json2 = JSON.stringify(clientSettingsHook.shipmentSettings);
        return json1 !== json2;
    }, [settings, clientSettingsHook.shipmentSettings]);

    const saveSettingsMutation = useMutation((data: ClientSettingsMutationInput) => updateClientSettingsPartial(data), {
        onSuccess: (data) => {
            clientSettingsHook.saveSettingsField(settings, "shipmentSettings");
            props.onCompleted();
            alert.success("Los cambios se guardaron correctamente");
        },
    });

    const handleSaveChanges = () => {
        let data: ClientSettingsMutationInput = {
            accountId: getAuthBody().accountId,
            body: {
                shipmentSettings: settings,
            },
        };

        saveSettingsMutation.mutate(data);
    };

    const onStatusChange = (status: string, name: keyof ShipmentStatusSetting, value: any) => {
        let statusList = { ...settings.statusList } ?? {};
        let statusValue: ShipmentStatusSetting = { ...statusList[status] } ?? {};
        statusValue[name] = value;
        statusList[status] = statusValue;
        setSettings({ ...settings, statusList });
    };

    const statusList = settings.statusList ?? {};

    const isLoading = saveSettingsMutation.isLoading;

    return (
        <ShipmentSettingsPanelView>
            <Table tdPaddingRight={15} cellPadding={0} cellSpacing={0} hideBorde>
                <tbody>
                    {SIMPLE_SHIPMENT_STATUS_LIST.map((item) => {
                        const statusSettings = statusList[item];
                        const disabled = statusSettings?.disabled;

                        const icon = statusSettings?.icon ?? SHIPMENT_ICON_GROUP[item];

                        return (
                            <tr key={item}>
                                <td>
                                    <Flex column gap5>
                                        <TextField small light>
                                            {checkAndGetMessage(intl, shipmentMessages, item)}
                                        </TextField>
                                        <Flex alignCenter gap15>
                                            <Dropdown icon={icon} btnClassName="dropdown-status-icon">
                                                <IconsPanelGrid>
                                                    {statusIcons[item].map((icon) => (
                                                        <div
                                                            className="grid-icon"
                                                            key={icon}
                                                            onClick={() => {
                                                                onStatusChange(item, "icon", icon);
                                                            }}
                                                        >
                                                            <TextField className={icon} />
                                                        </div>
                                                    ))}
                                                </IconsPanelGrid>
                                            </Dropdown>

                                            <DefaultInput
                                                w100
                                                value={statusList[item]?.title ?? ""}
                                                onChange={(e) => onStatusChange(item, "title", e.target.value)}
                                                borderRadius={10}
                                                className="status-input"
                                                type="text"
                                            />
                                            {BLOCKED_STATUS.includes(item as DeliveryStatus) ? (
                                                <Flex gap10>
                                                    <TextField light className="fa-regular fa-lock" />
                                                    <TextField light small>
                                                        Bloqueado
                                                    </TextField>
                                                </Flex>
                                            ) : (
                                                <CheckBox
                                                    title={"Habilitar"}
                                                    checked={!disabled}
                                                    onChange={(e) => onStatusChange(item, "disabled", !e.target.checked)}
                                                />
                                            )}
                                        </Flex>
                                    </Flex>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Flex gap10 alignCenter>
                <DefaultButton disabled={isLoading} onClick={props.onCompleted} borderRadius={10} bgLight w100>
                    Cancelar
                </DefaultButton>
                <PrimaryButton disabled={isLoading || !hasChanges} w100 borderRadius={10} onClick={handleSaveChanges}>
                    Guardar Cambios {isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </ShipmentSettingsPanelView>
    );
}

export const ShipmentSettingsPanelButton = () => {
    const [showModal, setShowModal] = React.useState(false);

    return (
        <div>
            <SquareButton title="Configurar Estados" small bgLight borderRadius={10} onClick={() => setShowModal(true)}>
                <i className="fa-regular fa-gear"></i>
            </SquareButton>
            {showModal && (
                <Modal ignoreOutsideClick show={showModal} setShow={setShowModal} showHeader title="Información de envío">
                    <ShipmentSettingsPanel onCompleted={() => setShowModal(false)} />
                </Modal>
            )}
        </div>
    );
};
