import { useAlert } from "../Alerts/Alert";
import { PaymentMethod } from "../../api/models";
import { IOrderPaymentBase } from "../../api/models/orderPayment";
import { getAuthBody } from "../../auth";
import { useCashRegisterContext } from "../../store/contexts/CashRegisterContext";
import { useOrganizationContext } from "../../store/contexts/OrganizationContext";

interface NewPaymentOptions {
    amount?: number;
    gatewayId?: string;
    ignoreCashRegisterEntry?: boolean;
}
export default function usePaymentHook() {
    const { organizationState } = useOrganizationContext();
    const { cashRegisterState } = useCashRegisterContext();
    const alert = useAlert();

    const getNewPayment = (options?: NewPaymentOptions) => {
        let cashRegisterId = organizationState.currentOrganization?.defaultCashRegisterId;

        if (cashRegisterState.logActive) {
            cashRegisterId = cashRegisterState.logActive?.cashRegisterId;
        }

        if (!cashRegisterId) {
            alert.error("La Tienda actual debe tener una caja asignada, o debes activar una caja para poder agregar pagos");
            return undefined;
        }

        let cashRegister = cashRegisterState.orgItems.find((x) => x.id === cashRegisterId);

        if (!cashRegister) {
            if (cashRegisterState.orgItems.length > 0) {
                cashRegister = cashRegisterState.orgItems[0];
            } else {
                alert.error("No se encontró ninguna caja en el sistema para realizar el pago");
                return undefined;
            }
        }

        let nextMethod: PaymentMethod = cashRegister.defaultPaymentMethod;
        if (!nextMethod) {
            nextMethod = cashRegister.type === "cash" ? "cash" : "card";
        }

        let payment: IOrderPaymentBase = {
            amount: options?.amount ?? 0,
            cashRegisterId: cashRegister?.id ?? "NA",
            cashRegisterOriginId: cashRegisterId,
            accountUserId: getAuthBody().accountUserId,
            method: nextMethod,
            status: "active",
            paymentGatewayId: options?.gatewayId ?? undefined,
            files: [],
            ignoreCashRegisterEntry: options?.ignoreCashRegisterEntry,
        };
        return payment;
    };

    return { getNewPayment };
}
