import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IContactProps } from ".";
import { CustomerAddress, IContactAddress } from "../../../api/models/contact";
import { getUniqueId, stringIsNullOrEmpty } from "../../../utils";
import { ILabelAction } from "../../InputTwoColsActions";
import { DefaultButton, Flex, PrimaryButton, SquareButton } from "../../_controls";
import Modal from "../../modals/Modal";
import ContactAddressForm from "./ContactAddressForm";
import messages from "./messages";
import { AddressLineContainer, InputDirections } from "./styled";

interface IEmailLabel extends ILabelAction, IContactProps {
    item: IContactAddress;
}
const ContactAddressLabels = (props: IEmailLabel) => {
    const intl = useIntl();
    const [modalOpen, setModalOpen] = useState(false);
    const { item } = props;

    const onChange = (value: IContactAddress) => {
        let addresses = [...props.currentCustomer.addresses];
        addresses[props.index] = value;
        props.setCurrentCustomer({ ...props.currentCustomer, addresses: addresses });
    };

    const getAddressLine = () => {
        let list: string[] = [];
        if (item.description) {
            list.push(item.description);
        }
        if (item.state) {
            list.push(item.state);
        }
        if (item.city) {
            list.push(item.city);
        }
        if (item.neighborhood) {
            list.push(item.neighborhood);
        }

        return list.join(", ");
    };

    return (
        <AddressLineContainer>
            {modalOpen && (
                <Modal
                    sizeType="sm"
                    ignoreOutsideClick
                    scrollInside={false}
                    title={intl.formatMessage(messages.addressTwo)}
                    removeVerticalAlign={false}
                    showHeader={true}
                    show={modalOpen}
                    setShow={setModalOpen}
                >
                    <Flex column gap={20} padding={25}>
                        <ContactAddressForm onChange={onChange} item={item} />
                        <Flex justifyEnd gap10>
                            <DefaultButton rounded onClick={() => setModalOpen(false)}>
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton rounded onClick={() => setModalOpen(false)} type="button">
                                Guardar Cambios
                            </PrimaryButton>
                        </Flex>
                    </Flex>
                </Modal>
            )}
            <Flex w100 row alignCenter>
                <InputDirections onClick={() => setModalOpen(true)}>
                    <span className="wahioicon-map-marker-alt icon"></span>
                    {item.description || item.state || item.city ? (
                        <span className="ml-1 text-bold ">{getAddressLine()}</span>
                    ) : (
                        <span className="text-light  ml-1">{intl.formatMessage(messages.clickAddAddress)}</span>
                    )}
                </InputDirections>
                <Flex alignCenter gap10>
                    <SquareButton
                        small
                        disabled={props.totalItems === 1}
                        type="button"
                        onClick={() => {
                            props.onRemove(props.index);
                        }}
                    >
                        <span className="wahioicon-trash"></span>
                    </SquareButton>
                    <SquareButton
                        small
                        disabled={!(props.itemNumber === props.totalItems && !stringIsNullOrEmpty(item.description))}
                        type="button"
                        onClick={() => {
                            props.onAdd();
                        }}
                    >
                        <span className="wahioicon-plus"></span>
                    </SquareButton>
                </Flex>
            </Flex>
        </AddressLineContainer>
    );
};

const CustomerAddressesView = (props: IContactProps) => {
    const intl = useIntl();
    const { currentCustomer, setCurrentCustomer } = props;

    const onRemoveAddress = (index: number) => {
        if (currentCustomer.addresses.length === 1) return;
        let newList = currentCustomer.addresses.filter((x, i) => i !== index);
        setCurrentCustomer({ ...currentCustomer, addresses: newList });
    };
    const onAddAddress = () => {
        let listCopy = currentCustomer.addresses;
        let customerAddress = new CustomerAddress(getUniqueId());
        customerAddress.contactName = props.currentCustomer.firstName + " " + props.currentCustomer.lastName;
        customerAddress.phoneNumber = props.currentCustomer.phones.length > 0 ? props.currentCustomer.phones[0].phoneNumber : "";
        listCopy = [...listCopy, customerAddress];
        setCurrentCustomer({ ...currentCustomer, addresses: listCopy });
    };

    return (
        <Flex column border borderRadius={10}>
            {currentCustomer.addresses.map((item, index) => (
                <ContactAddressLabels
                    {...props}
                    index={index}
                    key={index}
                    item={item}
                    onAdd={onAddAddress}
                    totalItems={currentCustomer.addresses.length}
                    itemNumber={index + 1}
                    onRemove={onRemoveAddress}
                    placeholder={intl.formatMessage(messages.address)}
                    labelPlaceholder={intl.formatMessage(messages.label)}
                />
            ))}
        </Flex>
    );
};

export default CustomerAddressesView;
