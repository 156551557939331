import React, { createContext, useEffect, useContext, useState } from "react";
import { getStoredData, saveData, LocalStoreKeys } from "..";
import { AccountUser } from "../../api/models/accountUser";
import { IOrganization } from "../../api/models/organizationTypes";
import { UserSessionResponseItem } from "../../api/socket/models";
import { MessageStatusItem } from "../../components/Chats/models";
import { getModelFullName } from "../../utils";

export interface UserChat {
    userId: string;
    userImage?: string;
    userName: string;
}

export interface GroupChat {
    group: string;
    image?: string;
    title: string;
}

interface ChatState {
    activeChats?: UserChat[];
    activeUsers?: UserSessionResponseItem[];
    activeGroupChats?: GroupChat[];
    messageStatusItems?: MessageStatusItem[];
    messagesCounter: number;
    messagesGroupCounter: number;
}

interface IContextProps {
    chatState: ChatState;
    setChatState: (value: ChatState) => void;
    addUserToChat: (user: AccountUser) => void;
    addOrganizationToChat: (org: IOrganization) => void;
    removeUserToChat: (userId: string) => void;
    removeGroupToChat: (group: string) => void;
}

export const ChatContext = createContext({} as IContextProps);

const initialState: ChatState = {
    activeChats: [],
    activeGroupChats: [],
    messageStatusItems: [],
    messagesCounter: 0,
    messagesGroupCounter: 0,
};

const ChatContextProvider = (props: any) => {
    const [chatState, setChatState] = useState<ChatState>(getStoredData<ChatState>(initialState, LocalStoreKeys.chat));

    useEffect(() => {
        saveData(LocalStoreKeys.chat, chatState);
    }, [chatState]);

    useEffect(() => {
        let counter = chatState.messageStatusItems?.reduce((sum, item) => sum + item.count, 0) ?? 0;
        setChatState({ ...chatState, messagesCounter: counter });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatState.messageStatusItems]);

    const addUserToChat = (user: AccountUser) => {
        let chatUser: UserChat = {
            userId: user.id,
            userImage: user.image,
            userName: getModelFullName(user),
        };
        let activeChats = chatState.activeChats ?? [];
        setChatState({ ...chatState, activeChats: [...activeChats, chatUser] });
    };

    const addOrganizationToChat = (org: IOrganization) => {
        let chatUser: GroupChat = {
            group: org.id ?? "invalid",
            title: org.name,
            image: org.image,
        };
        let activeChats = chatState.activeGroupChats ?? [];
        setChatState({ ...chatState, activeGroupChats: [...activeChats, chatUser] });
    };

    const removeGroupToChat = (group: string) => {
        let activeChats = chatState.activeGroupChats?.filter((x) => x.group !== group) ?? [];
        setChatState({ ...chatState, activeGroupChats: activeChats });
    };

    const removeUserToChat = (userId: string) => {
        let activeChats = chatState.activeChats?.filter((x) => x.userId !== userId) ?? [];
        setChatState({ ...chatState, activeChats: activeChats });
    };

    return (
        <ChatContext.Provider
            value={{ chatState, setChatState, addUserToChat, removeUserToChat, removeGroupToChat, addOrganizationToChat }}
        >
            {props.children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => {
    return useContext(ChatContext);
};

export default ChatContextProvider;
