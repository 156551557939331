import React from "react";
import { useIntl } from "react-intl";
import { AccountPlanBase } from "../../../../api/models/accountUser";
import { Flex } from "../../../_controls";
import { BillingConfigData } from "../billingConfigDataModel";
import messages from "../messages";
import { AccountPlanTable } from "../styled";

interface PlanComparisonProps {
    billingConfig: BillingConfigData;
}

export default function PlanComparison(props: PlanComparisonProps) {
    const { billingConfig } = props;
    const intl = useIntl();

    return (
        <Flex w100 boxSizingBorderBox overflowAuto>
            <AccountPlanTable cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th></th>
                        {Object.keys(billingConfig.prices.monthly).map((key, index) => {
                            let plan = key as AccountPlanBase;

                            return (
                                <th key={index}>
                                    <span>{intl.formatMessage(messages[plan])}</span>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {billingConfig.items.map((item, index1) => {
                        return (
                            <tr key={index1}>
                                <td>{item.value}</td>
                                {Object.keys(item).map((key, index) => {
                                    if (key === "value") return null;
                                    let plan = key as AccountPlanBase;
                                    return (
                                        <td key={index}>
                                            {item[plan].value ? (
                                                item[plan].value
                                            ) : item[plan].status ? (
                                                <span className="wahioicon-check"></span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </AccountPlanTable>
        </Flex>
    );
}
