import React, { useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { WahioFile } from "../../../api/models";
import { IProduct } from "../../../api/models/product";
import { createUpdateProduct } from "../../../api/products/productsApi";
import { useAlert } from "../../Alerts/Alert";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../../_controls";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export const NavigationFiles = (props: ProductNavigationProfileProps) => {
    const { product } = props;
    const [files, setFiles] = useState(product.files);
    const alert = useAlert();

    const [showSaveChanges, setShowSaveChanges] = useState(false);

    const productMutation = useMutation((product: IProduct) => createUpdateProduct(product), {
        onSuccess: (product) => {
            setShowSaveChanges(false);
            alert.success("Los archivos fueron guardados con éxito");
            props.onUpdateProduct(product);
        },
        onError: (error) => {
            setShowSaveChanges(false);
            alert.error(getServiceMessageError(error));
        },
    });

    const onFilesChange = (files: WahioFile[]) => {
        setFiles(files);
        setShowSaveChanges(true);
    };

    const handleCancelChanges = () => {
        setFiles(product.files);
        setShowSaveChanges(false);
    };

    const onSaveChanges = () => {
        let product = { ...props.product, files };
        productMutation.mutate(product);
    };

    return (
        <NavigationCard {...props} title={props.intl.formatMessage(messages.files)}>
            <Flex column gap={20}>
                {files && files.length === 0 && (
                    <Flex gap10 alignCenter>
                        <span className="fa-regular fa-folder-empty"></span>
                        <TextField small light>
                            No hay archivos adjuntos
                        </TextField>
                    </Flex>
                )}
                <FilesViewer showAttachButton files={files ?? []} cardMaxWidth={140} onUpdateFiles={onFilesChange} showAccessType />
                <Flex gap10 alignCenter>
                    {showSaveChanges && (
                        <>
                            <DefaultButton onClick={handleCancelChanges} className="mr-1" rounded>
                                Cancelar
                            </DefaultButton>

                            {productMutation.isLoading ? (
                                <LoadingDualRing className="ml-1" small />
                            ) : (
                                <PrimaryButton rounded onClick={() => onSaveChanges()}>
                                    Guardar Cambios
                                </PrimaryButton>
                            )}
                        </>
                    )}
                </Flex>
            </Flex>
        </NavigationCard>
    );
};

export default NavigationFiles;
