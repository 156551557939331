import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Order, OrderItem } from "../../../api/models/order";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import PATHS from "../../../constants/paths";
import { useOrderLocalContext } from "../../../store/contexts/OrderLocalContext";
import { DefaultButton } from "../../_controls";

interface OrderDuplicationProps {
    order: Order;
}

export default function OrderDuplication(props: OrderDuplicationProps) {
    const { appSettingsState } = useAppSettingsContext();
    const { onAddOrder } = useOrderLocalContext();
    const { order } = props;

    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Navigate to={PATHS.orderPosNew("last")} />;
    }

    const handleDuplicateOrder = () => {
        let orderCopy = { ...order };
        orderCopy.id = undefined;
        orderCopy.orderPicking = undefined;
        orderCopy.pickingAvailable = false;
        orderCopy.includeInvoice = false;
        orderCopy.invoiceAvailable = false;
        orderCopy.orderInvoice = undefined;
        orderCopy.notifications = undefined;
        if (orderCopy.deliveryOrder) {
            orderCopy.deliveryOrder.id = undefined;
            orderCopy.deliveryOrder.history = undefined;
            orderCopy.deliveryOrder.orderId = undefined;
            orderCopy.deliveryOrder.status = "pending";
        }

        orderCopy.hasReturns = false;
        orderCopy.orderTypeTemp = "sale";
        let items: OrderItem[] = [];
        orderCopy.items.forEach((element) => {
            element.id = undefined;
            element.orderId = undefined;
            element.taxes = [];
            element.serials = [];
            element.hasReturns = false;
            element.quantityReturned = 0;
            element.locationCode = undefined;
            items.push(element);
        });
        orderCopy.items = items;
        orderCopy.payments = [];
        orderCopy.refunds = [];
        orderCopy.statusHistory = [];
        orderCopy.orderDelete = undefined;
        orderCopy.isRestaurant = false;
        orderCopy.trackingNumber = undefined;
        orderCopy.orderInvoiceNumerationId = undefined;
        orderCopy.defaultPrice = appSettingsState.defaultPrice;

        onAddOrder(orderCopy);
        setRedirect(true);
    };

    return (
        <>
            <DefaultButton onClick={() => handleDuplicateOrder()} rounded className="dropdown-button">
                <span className="wahioicon-clone"></span>
                Duplicar orden
            </DefaultButton>
        </>
    );
}
