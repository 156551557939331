import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { SquareButton } from "../buttons";
import { TooltipRadixContainer } from "./styled";

interface TooltipRadixProps {
    icon?: string;
    title?: string;
    delay?: number;
    trigger?: React.ReactNode;
    content?: React.ReactNode;
    side?: "top" | "bottom" | "left" | "right";
    align?: "start" | "center" | "end";
    small?: boolean;
}

const TooltipRadix = (props: TooltipRadixProps) => {
    const { side = "top", align = "center" } = props;

    const withContent = props.content || props.title;

    return (
        <Tooltip.Provider delayDuration={props.delay ?? 0}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    {props.trigger ? (
                        props.trigger
                    ) : (
                        <SquareButton small={props.small}>
                            <span className={props.icon ? props.icon : "fa-regular fa-exclamation"}></span>
                        </SquareButton>
                    )}
                </Tooltip.Trigger>
                {withContent && (
                    <Tooltip.Portal>
                        <TooltipRadixContainer>
                            <Tooltip.Content align={align} side={side} className="TooltipContent" sideOffset={5}>
                                {props.title}
                                {props.content}
                                <Tooltip.Arrow className="TooltipArrow" />
                            </Tooltip.Content>
                        </TooltipRadixContainer>
                    </Tooltip.Portal>
                )}
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};

export default TooltipRadix;
