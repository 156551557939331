import styled from "styled-components";

export const ShipmentSettingsPanelView = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .status-input {
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        font-weight: 500;
    }

    .dropdown-btn {
        width: 40px;
        height: 40px;
    }
`;

export const PriceSettingsPanelView = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const IconsPanelGrid = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    .grid-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        font-size: 17px;
        border-radius: 10px;
        background: ${(props) => props.theme.palette.backgroundLight};

        :hover {
            cursor: pointer;
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;
