import _ from "lodash";
import wahioFetchApi from "../fetchApi";
import {
    IOrganization,
    IOrganizationWarehouse,
    OrganizationCashRegister,
    OrganizationCashRegisterCreate,
    OrganizationSettingsPartialInput,
    OrganizationUpdateItemPartial,
    getOrganizationOmit,
} from "../models/organizationTypes";
import { IWarehouse, Warehouse } from "../models/warehouse";
import { accountEndpoint, productEndpoint } from "../restApiEndpoint";

export const getOrganizationById = (id: string): Promise<IOrganization> => {
    return wahioFetchApi.get(accountEndpoint.get.organizationsById(id)).then((res: any) => res.data);
};

export const updateOrganization = (organization: IOrganization): Promise<IOrganization> => {
    return wahioFetchApi.put(accountEndpoint.put.organization, getOrganizationOmit(organization)).then((res: any) => res.data);
};
export const createOrganization = (organization: IOrganization): Promise<IOrganization> => {
    return wahioFetchApi.post(accountEndpoint.post.organization, organization).then((res: any) => res.data);
};

export const setWarehouseToOrganization = (body: IOrganizationWarehouse): Promise<IOrganizationWarehouse> => {
    return wahioFetchApi.post(accountEndpoint.post.organizationWarehouse, body).then((res: any) => res.data);
};

export const createOrUpdateWarehouse = (warehouse: IWarehouse): Promise<Warehouse> => {
    if (!_.isEmpty(warehouse.id)) {
        return wahioFetchApi.put(productEndpoint.post.warehouse, warehouse).then((res: any) => res.data);
    } else {
        return wahioFetchApi.post(productEndpoint.post.warehouse, warehouse).then((res: any) => res.data);
    }
};

export const setCashRegisterToOrganization = (body: OrganizationCashRegisterCreate): Promise<OrganizationCashRegister> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterToOrganization, body).then((res: any) => res.data);
};

export const deleteCashRegisterOrganization = (id: string): Promise<OrganizationCashRegister> => {
    return wahioFetchApi.delete(accountEndpoint.delete.cashRegisterOrganization(id)).then((res: any) => res.data);
};

export const deleteOrganization = (id: string): Promise<IOrganization> => {
    return wahioFetchApi.delete(accountEndpoint.delete.organization(id)).then((res: any) => res.data);
};

export const getCashRegistersByOrganization = (organizationId: string): Promise<OrganizationCashRegister[]> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegistersByOrganization(organizationId)).then((res: any) => res.data);
};

export const updateOrganizationPartial = (data: OrganizationSettingsPartialInput): Promise<OrganizationUpdateItemPartial> => {
    return wahioFetchApi
        .patch(accountEndpoint.patch.updateOrganizationPartial(data.organizationId), data.body)
        .then((res: any) => res.data);
};
