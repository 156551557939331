import styled from "styled-components";

export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;

    .label {
        margin-left: 15px;
        cursor: pointer;
    }
`;

export const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    min-width: 50px;
    height: 25px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
        outline: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => props.theme.palette.backgroundDark};
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input:checked + .slider {
        background-color: ${(props) => props.theme.palette.primary};
    }

    input:checked + .slider:before {
        transform: translateX(22px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;
