import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../components/_controls";

export const MainContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.palette.backgroundLight};
`;

export const BoxContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 25px 30px;
    margin-bottom: 40px;
    margin: 29px;
    background: ${(props) => props.theme.palette.background};

    border-radius: 8px;
    box-shadow: 1px 1px 12px ${(props) => props.theme.palette.shadowColor};
    .content {
        margin: 0px;
        margin-bottom: 20px;
    }
    .btns {
        display: flex;
        justify-content: flex-end;
    }
`;

interface InvalidSessionProps {
    handlerUseHere: any;
}

export function InvalidSession(props: InvalidSessionProps) {
    return (
        <MainContainer>
            <BoxContainer>
                <h2>Hemos detectado una sesión en otro dispositivo.</h2>
                <p className="content">
                    Haga clic en <strong>"Usar aquí"</strong> para continuar en este dispositivo.
                </p>
                <div className="btns">
                    <PrimaryButton onClick={props.handlerUseHere}>USAR AQUÍ</PrimaryButton>
                </div>
            </BoxContainer>
        </MainContainer>
    );
}
