import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectOptionObject } from "../../../api/models";
import { CashRegisterType, ICashRegister } from "../../../api/models/cashRegister";
import { useCashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { SelectBody } from "../../Select/styled";

interface CashRegisterSelectProps {
    defaultCashRegisterId?: string;
    onCashRegisterChange: (value?: ICashRegister) => void;
    onCashRegisterLoad?: (value?: ICashRegister) => void;
    ignoreList?: ICashRegister[];
    hideTitle?: boolean;
    small?: boolean;
    title?: string;
    typeFilter?: CashRegisterType;
    allowRemove?: boolean;
    alternateSelector?: boolean;
}

export default function CashRegisterSelect(props: CashRegisterSelectProps) {
    const { cashRegisterState, cashRegisterActions } = useCashRegisterContext();

    const [selectedCashRegister, setSelectedCashRegister] = useState<ICashRegister>();
    const [items, setItems] = useState<ICashRegister[]>([]);

    useEffect(() => {
        if (cashRegisterState.allItems.length > 0) return;
        cashRegisterActions.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let cashRegisterItems = cashRegisterState.allItems;
        if (props.ignoreList && props.ignoreList.length > 0) {
            cashRegisterItems = cashRegisterItems.filter((x) => !props.ignoreList?.map((x) => x.id ?? "NA").includes(x.id ?? "NA"));
        }

        if (props.typeFilter) {
            setItems(cashRegisterItems.filter((x) => x.type === props.typeFilter));
        } else {
            setItems(cashRegisterItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.allItems, props.typeFilter]);

    useEffect(() => {
        if (props.defaultCashRegisterId) {
            var cashRegister = items.find((x) => x.id === props.defaultCashRegisterId);
            setSelectedCashRegister(cashRegister);
            if (props.onCashRegisterLoad) props.onCashRegisterLoad(cashRegister);
        } else {
            setSelectedCashRegister(undefined);
            if (props.onCashRegisterLoad) props.onCashRegisterLoad(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.defaultCashRegisterId]);

    const onCashRegisterChange = (cashRegister?: ICashRegister) => {
        setSelectedCashRegister(cashRegister);
        if (props.onCashRegisterLoad) props.onCashRegisterLoad(cashRegister);
        props.onCashRegisterChange(cashRegister);
    };

    const getOption = (item: ICashRegister) => {
        let option: SelectOptionObject<ICashRegister> = {
            label: item.name,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    return (
        <SelectBody menuMinWidth={200} selectMinWidth={140} className={props.alternateSelector ? "alternate-selector" : ""}>
            {!props.hideTitle && <span className="label">{props.title ?? "Cajas"}</span>}
            <Select
                placeholder="Caja"
                className={`${props.small ? "select-small" : ""} ${selectedCashRegister ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedCashRegister?.name}
                defaultValue={selectedCashRegister ? getOption(selectedCashRegister) : undefined}
                isDisabled={false}
                onChange={(value) => onCashRegisterChange(value?.object)}
                isRtl={false}
                isSearchable={true}
                isClearable={!!props.allowRemove}
                isLoading={cashRegisterState.isFetching}
                name="cashRegister"
                options={items.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
