import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { createCashRegisterEntryCategory } from "../../../api/account/cashRegisterApi";
import { CashRegisterEntryCategory, EntryCategoryBase } from "../../../api/models/cashRegister";
import { getAuthBody } from "../../../auth";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useCashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, TextField } from "../../_controls";
import InputTimer from "../../_controls/inputs/InputTimer";
import Modal from "../../modals/Modal";
import messages from "../messages";
import { ALL_ICONS, DEFAULT_CATEGORIES, INTERNAL_CATEGORIES } from "./categoryIcons";
import { CashRegisterEntryCategoryGrid, CategoryCard, NewCategoryContainer, SelectorContainer } from "./styled";

interface CashRegisterEntryCategorySelectorProps extends EntryCategoryIconsProps {
    onSelected: (value?: EntryCategoryBase) => void;
    w100?: boolean;
    allowRemove?: boolean;
    showInternalCategories?: boolean;
}

interface EntryCategoryIconsProps {
    selected?: EntryCategoryBase;
    selectedName?: string;
}

export const useEntryCategoryIcons = (props: EntryCategoryIconsProps) => {
    const { entryCategoryState } = useCashRegisterContext();
    const [selectedValue, setSelectedValue] = useState<EntryCategoryBase>();

    useEffect(() => {
        if (props.selectedName) {
            let value = [...INTERNAL_CATEGORIES, ...entryCategoryState.items, ...DEFAULT_CATEGORIES].find(
                (x) => x.name === props.selectedName
            );

            setSelectedValue(value ?? { name: props.selectedName, icon: "fa-regular fa-pen" });
        } else {
            setSelectedValue(props.selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedName, props.selected, entryCategoryState.items]);

    return selectedValue;
};

export default function CashRegisterEntryCategorySelector(props: CashRegisterEntryCategorySelectorProps) {
    const [showModal, setShowModal] = useState(false);
    const { cashRegisterActions, entryCategoryState } = useCashRegisterContext();
    const intl = useIntl();

    const selectedValue = useEntryCategoryIcons(props);

    useEffect(() => {
        cashRegisterActions.loadCategoriesIfNotExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showNewCategory, setShowNewCategory] = useState(false);

    const onSelected = (value?: EntryCategoryBase) => {
        props.onSelected(value);
        setShowModal(false);
        setShowNewCategory(false);
    };

    const onClickButton = (e: any) => {
        if (e.target.id === "btn-remove") {
            onSelected(undefined);
        } else {
            setShowModal(true);
        }
    };

    return (
        <SelectorContainer>
            <DefaultButton
                justifyStart
                w100={props.w100}
                className={`btn-category ${!!selectedValue ? "active-item" : ""}`}
                rounded
                onClick={onClickButton}
            >
                {selectedValue ? (
                    <>
                        <span className={selectedValue.icon}></span>
                        {checkAndGetMessage(intl, messages, selectedValue.name)}

                        {props.allowRemove && <span id="btn-remove" className="remove-value wahioicon-times"></span>}
                    </>
                ) : (
                    <>
                        Categoría
                        <span className="wahioicon-angle-down icon-left"></span>
                    </>
                )}
            </DefaultButton>
            {showModal && (
                <Modal show={showModal} setShow={setShowModal} removeVerticalAlign ignoreOutsideClick showHeader title="Categorías">
                    <Flex column padding={20} gap={20} paddingBottom={35}>
                        {showNewCategory && <CreateNewCategory onSelected={onSelected} onBack={() => setShowNewCategory(false)} />}
                        {!showNewCategory && (
                            <>
                                {props.showInternalCategories && (
                                    <SelectedCategories onSelected={onSelected} selected={props.selected} items={INTERNAL_CATEGORIES} />
                                )}
                                {entryCategoryState.items.length > 0 && (
                                    <>
                                        <SelectedCategories
                                            onSelected={onSelected}
                                            selected={props.selected}
                                            items={entryCategoryState.items}
                                        />
                                        <hr />
                                    </>
                                )}
                                <SelectedCategories onSelected={onSelected} selected={props.selected} items={DEFAULT_CATEGORIES} />
                            </>
                        )}
                        {!showNewCategory && (
                            <PrimaryButton onClick={() => setShowNewCategory(!showNewCategory)} borderRadius={10} bgLight>
                                <i className="fa-regular fa-plus"></i> Nueva Categoría
                            </PrimaryButton>
                        )}
                    </Flex>
                </Modal>
            )}
        </SelectorContainer>
    );
}

export const EntryCategoryListAll = (props: CashRegisterEntryCategorySelectorProps) => {
    const { cashRegisterActions, entryCategoryState } = useCashRegisterContext();

    useEffect(() => {
        cashRegisterActions.loadCategoriesIfNotExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelected = (value?: EntryCategoryBase) => {
        props.onSelected(value);
    };

    let extraCategories: EntryCategoryBase[] = DEFAULT_CATEGORIES;
    if (props.showInternalCategories) {
        extraCategories = [...extraCategories, ...INTERNAL_CATEGORIES];
    }
    if (entryCategoryState.items.length > 0) {
        extraCategories = [...extraCategories, ...entryCategoryState.items];
    }

    return (
        <>
            <SelectedCategories removeMaxHeight onSelected={onSelected} selected={props.selected} items={extraCategories} />
        </>
    );
};

const ITEMS_PER_PAGE = 56;

interface CreateNewCategoryProps extends CashRegisterEntryCategorySelectorProps {
    onBack: () => void;
}

const CreateNewCategory = (props: CreateNewCategoryProps) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [filterValue, setFilterValue] = useState("");
    const { entryCategoryState, setEntryCategoryState } = useCashRegisterContext();
    const [filteredItems, setFilteredItems] = useState<string[]>(ALL_ICONS);
    const alert = useAlert();
    const [category, setCategory] = useState<CashRegisterEntryCategory>({
        id: "",
        name: "",
        icon: "fa-regular fa-pen",
        createdAt: new Date(),
        accountId: getAuthBody().accountId,
    });

    const totalPages = useMemo(() => {
        return Math.ceil(filteredItems.length / ITEMS_PER_PAGE);
    }, [filteredItems]);

    const createCategoryMutation = useMutation((body: CashRegisterEntryCategory) => createCashRegisterEntryCategory(body), {
        onSuccess: (data) => {
            setEntryCategoryState({ ...entryCategoryState, items: [data, ...entryCategoryState.items] });
            props.onSelected(data);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        setCurrentPage(0);
        if (filterValue) {
            setFilteredItems(ALL_ICONS.filter((x) => x.includes(filterValue.toLowerCase())));
        } else {
            setFilteredItems(ALL_ICONS);
        }
    }, [filterValue]);

    const handleCreate = () => {
        createCategoryMutation.mutate(category);
    };

    const handleNext = () => {
        setCurrentPage((old) => Math.min(old + 1, totalPages - 1));
    };

    const handlePrevious = () => {
        setCurrentPage((old) => Math.max(old - 1, 0));
    };

    const currentItems = filteredItems.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

    return (
        <NewCategoryContainer>
            <Flex column gap5>
                <TextField bold>Nombre de la Categoría</TextField>
                <Flex gap5 alignCenter>
                    {category.icon && (
                        <CategoryCard className="active small">
                            <span className={`icon ${category.icon}`}></span>
                        </CategoryCard>
                    )}
                    <DefaultInput
                        w100
                        rounded
                        value={category.name}
                        onChange={(e) => setCategory({ ...category, name: e.target.value })}
                        placeholder="Nombre de la categoría"
                    />
                </Flex>
            </Flex>

            <Flex column gap5>
                <TextField small light>
                    Seleccionar Icono
                </TextField>
                <InputTimer
                    className="input-filter"
                    placeholder={"Filtrar iconos (Ingles), arrow, box, home..."}
                    milliseconds={400}
                    onChange={(e) => setFilterValue(e.target.value)}
                />
            </Flex>

            <Flex column gap10>
                <CashRegisterEntryCategoryGrid className="small">
                    {currentItems.map((item, index) => {
                        return (
                            <CategoryCard
                                title={item.replace("fa-regular fa-", "")}
                                className={item === category.icon ? "active" : ""}
                                key={index}
                                onClick={() => setCategory({ ...category, icon: item })}
                            >
                                <span className={`icon ${item}`}></span>
                            </CategoryCard>
                        );
                    })}
                </CashRegisterEntryCategoryGrid>
                {totalPages > currentPage && (
                    <Flex gap10 alignCenter marginTop={10}>
                        Pagina: {currentPage + 1} de {totalPages}
                        <DefaultButton rounded disabled={currentPage === 0} onClick={handlePrevious}>
                            <i className="fa-regular fa-arrow-left"></i>
                        </DefaultButton>
                        <DefaultButton rounded onClick={handleNext}>
                            <i className="fa-regular fa-arrow-right"></i>
                        </DefaultButton>
                    </Flex>
                )}
            </Flex>

            <Flex justifyEnd gap10>
                <DefaultButton onClick={() => props.onBack()} borderRadius={10} bgLight>
                    Volver
                </DefaultButton>
                <PrimaryButton
                    onClick={() => handleCreate()}
                    disabled={category.name.length < 3 || createCategoryMutation.isLoading}
                    borderRadius={10}
                >
                    Crear Categoría {createCategoryMutation.isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </NewCategoryContainer>
    );
};

interface SelectedCategoriesProps extends CashRegisterEntryCategorySelectorProps {
    items: EntryCategoryBase[];
    removeMaxHeight?: boolean;
}

const SelectedCategories = (props: SelectedCategoriesProps) => {
    return (
        <CashRegisterEntryCategoryGrid className={props.removeMaxHeight ? "remove-max-height" : ""}>
            {props.items.map((item, index) => {
                return (
                    <CategoryCard
                        key={index}
                        className={item.name === props.selected?.name ? "active" : ""}
                        onClick={() => props.onSelected(item)}
                    >
                        <span className={`icon ${item.icon}`}></span>
                        <span className="category-name">{item.label ?? item.name}</span>
                    </CategoryCard>
                );
            })}
        </CashRegisterEntryCategoryGrid>
    );
};
