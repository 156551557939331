import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { IInventoryProductAdjustmentModel } from "../../../api/models/inventoryAdjustment";
import { stockProductAdjustment } from "../../../api/products/productsApi";
import { getAuthBody } from "../../../auth";
import { useAlert } from "../../Alerts/Alert";
import ProductSerials from "../ProductSerials";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

const NavigationSerials = (props: ProductNavigationProfileProps) => {
    const { warehouseProduct } = props;
    const timeCheckSerialRef = React.useRef<any>();
    const alert = useAlert();

    const [updateModel, setUpdateModel] = useState({
        serials: warehouseProduct?.serials ?? [],
        hasChanges: false,
    });

    const stockMutation = useMutation((data: IInventoryProductAdjustmentModel) => stockProductAdjustment(data), {
        onSuccess: (data) => {
            props.setWarehouseProduct(data);
            alert.success("Ajuste realizado con éxito");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        handleCheckSerials(updateModel.serials);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateModel]);

    function haveDifferences(arr1: string[], arr2: string[]): boolean {
        return !_.isEmpty(_.difference(arr1, arr2)) || !_.isEmpty(_.difference(arr2, arr1));
    }

    const handleCheckSerials = (serials: string[]) => {
        if (timeCheckSerialRef.current) {
            clearTimeout(timeCheckSerialRef.current);
        }
        timeCheckSerialRef.current = setTimeout(() => {
            let withoutEmpty = serials.filter((x) => x.trim() !== "");
            let hasChanges = haveDifferences(withoutEmpty, warehouseProduct?.serials ?? []);
            if (hasChanges !== updateModel.hasChanges) {
                setUpdateModel({
                    serials: serials,
                    hasChanges: hasChanges,
                });
            }
        }, 500);
    };

    const handleSaveSerials = () => {
        if (!updateModel.hasChanges || !warehouseProduct) return;

        let newSerials = updateModel.serials.filter((x) => x.trim() !== "");
        let quantity = newSerials.length;

        const data: IInventoryProductAdjustmentModel = {
            productId: warehouseProduct.productId,
            warehouseId: warehouseProduct.warehouseId,
            quantity: quantity,
            accountUserId: getAuthBody().accountUserId,
            productVariantId: props.productVariant?.id ?? "",
            serials: updateModel.serials,
        };
        stockMutation.mutate(data);
    };

    useEffect(() => {
        setUpdateModel({
            serials: warehouseProduct?.serials ?? [],
            hasChanges: false,
        });
    }, [warehouseProduct?.serials]);

    return (
        <NavigationCard
            {...props}
            title={props.intl.formatMessage(messages.stockSerials)}
            actionsComponent={
                <NavigationActions isLoading={stockMutation.isLoading} disabled={!updateModel.hasChanges} onSave={handleSaveSerials} />
            }
        >
            <ProductSerials
                serials={updateModel.serials}
                onChange={(value) => {
                    setUpdateModel({
                        ...updateModel,
                        serials: value,
                    });
                }}
            />
        </NavigationCard>
    );
};

export default NavigationSerials;
