import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { useIsMobileListener } from "../../../components";
import CashRegisterLogActiveButton from "../../../components/CashRegisters/CashRegisterLog/CashRegisterLogActiveButton";
import ChatUserListDropdown from "../../../components/Chats/ChatUserList";
import QuickActionsMenu from "../../../components/QuickActionsMenu";
import VerticalMenuItem, { VerticalMenuStateComp } from "../../../components/VerticalMenuItem";
import { Flex } from "../../../components/_controls";
import PATHS from "../../../constants/paths";
import { ignoreElementClickByIds } from "../../../utils";
import OrganizationTab from "../OrganizationTab";
import { VerticalMenuContainer } from "../styled";
import { useMenuOptions } from "./MenuOptionsHook";

export interface IPathGroup {
    pathname: string;
    activeMenu: string;
    showAllOptions?: boolean;
}

const MENU_FLEX_ID = "MENU_FLEX_1O82983492384-02934";

const SINGLE_VIEW = [
    PATHS.products,
    PATHS.orders,
    PATHS.shipments,
    PATHS.payments,
    PATHS.cashRegisters,
    PATHS.account,
    PATHS.contacts,
    PATHS.config,
];

export default function AppVerticalMenu() {
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const location = useLocation();
    const [pathGroup, setPathGroup] = useState<IPathGroup>({ pathname: "", activeMenu: "", showAllOptions: false });

    const isMobile = useIsMobileListener({ size: 768 });
    const { options } = useMenuOptions();

    useEffect(() => {
        var arrayPath = location.pathname.split("/").filter((x) => x);
        if (arrayPath.length > 0) {
            const activeMenu = "/" + arrayPath[0];
            setPathGroup({
                pathname: location.pathname,
                activeMenu: activeMenu,
                showAllOptions: !SINGLE_VIEW.includes(activeMenu),
            });
        }
    }, [location.pathname]);

    const menuOpen = appSettingsState.menuOpen;
    const menuSmall = !isMobile && appSettingsState.menuSmall;

    return (
        <>
            <VerticalMenuContainer
                onClick={(e) => {
                    const ignore = ignoreElementClickByIds(e.target, [MENU_FLEX_ID]);
                    if (!ignore) {
                        appSettingsActions.setAllSettings({ ...appSettingsState, menuOpen: false });
                    }
                }}
                className={`${menuOpen ? "menu-open" : ""} ${menuSmall ? "small-menu" : ""}`}
                menuOpen={!!menuOpen}
            >
                <div id={MENU_FLEX_ID} className="menu-flex">
                    <OrganizationTab />

                    <Flex
                        gap15
                        column
                        style={{
                            width: appSettingsState.menuSmall ? "auto" : "100%",

                            boxSizing: "border-box",
                            paddingLeft: appSettingsState.menuSmall ? "0" : "15px",
                        }}
                    >
                        {isMobile && (
                            <>
                                <ChatUserListDropdown />
                                <QuickActionsMenu />
                                <CashRegisterLogActiveButton />
                            </>
                        )}
                    </Flex>

                    <Flex column style={{ height: "100%" }} w100>
                        {options.map((option, index) => {
                            if (option.hidden) return null;
                            const isActiveWithChild = SINGLE_VIEW.includes(pathGroup.activeMenu);
                            const isActive = pathGroup.activeMenu === option.link;

                            if (!pathGroup.showAllOptions && isActiveWithChild && !isActive) return null;
                            const showBackBottom = !pathGroup.showAllOptions && isActiveWithChild && isActive;

                            return (
                                <VerticalMenuItem
                                    key={index}
                                    tourName={option.tourName}
                                    hideTitle={menuSmall}
                                    showBackOption={showBackBottom}
                                    onBackClick={() => setPathGroup({ ...pathGroup, showAllOptions: true })}
                                    icon={option.icon}
                                    title={option.message}
                                    childList={option.childs}
                                    link={option.link}
                                    pathGroup={pathGroup}
                                    startLink={option.startLink}
                                    notifications={option.notifications}
                                />
                            );
                        })}
                        {!pathGroup.showAllOptions && <VerticalMenuStateComp />}
                    </Flex>
                </div>
            </VerticalMenuContainer>
        </>
    );
}
