import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { IPurchase, IPurchaseItem } from '../../../api/models/purchase'
import { formatMoney, getDateFormatLLLL, getDateFormatll } from '../../../utils'
import { ORDER_STATUS_STYLE } from '../../Orders/OrderStatusFilter/helpers'
import { DefaultButton, Flex, TextField } from '../../_controls'
import ContainerCollapse from '../../_controls/containers/ContainerCollapse'
import { TableCustom, TableCustomTr } from '../../_controls/tables/styled'
import messages from './messages'

interface PurchaseDetailsProps {
    currentOrder: IPurchase;
}

export const PurchaseDetailsTabView = (props: PurchaseDetailsProps) => {
    const [productsWithReturns, setProductsWithReturns] = useState<IPurchaseItem[]>()

    useEffect(() => {
        let itemsWithReturns = props.currentOrder.items.filter(x => x.hasReturns)
        setProductsWithReturns(itemsWithReturns)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex gap={20} column>
            {props.currentOrder.refunds && props.currentOrder.refunds.length > 0 && (
                <ContainerCollapse initialStateOpen title="Reembolso">
                    <TableCustom cellSpacing="0" cellPadding="0">
                        <tbody>
                            {props.currentOrder.refunds.map((item, index) => (
                                <TableCustomTr key={index}>
                                    <td>
                                        <span>
                                            <strong>{formatMoney(item.amount)}</strong>
                                        </span>{" "}
                                        <span className="light"> {getDateFormatLLLL(item.dateCreated)}</span>
                                    </td>
                                </TableCustomTr>
                            ))}
                        </tbody>
                    </TableCustom>
                </ContainerCollapse>
            )}
            {props.currentOrder.statusHistory && props.currentOrder.statusHistory.length > 0 && (
                <ContainerCollapse initialStateOpen title="Historial de estados">
                    <TableCustom cellSpacing="0" cellPadding="0">
                        <tbody>
                            {props.currentOrder.statusHistory.map((item, index) => (
                                <TableCustomTr key={index}>
                                    <td className="min-width">
                                        <DefaultButton className={`${ORDER_STATUS_STYLE[item.status].class} btn-status`}>
                                            <span className={ORDER_STATUS_STYLE[item.status].icon}></span>{" "}
                                            <span>
                                                <FormattedMessage {...messages[item.status]} />
                                            </span>
                                        </DefaultButton>
                                    </td>
                                    <td>
                                        <span className="light"> {getDateFormatLLLL(item.dateCreated)}</span>
                                    </td>
                                </TableCustomTr>)
                            )}
                        </tbody>
                    </TableCustom>
                </ContainerCollapse>
            )}
            {productsWithReturns && productsWithReturns.length > 0 && (
                <ContainerCollapse title="Devoluciones">
                    <Flex column gap15>
                        {productsWithReturns.map((item, index) =>
                            <Flex alignCenter gap15 w100>
                                <Flex column>
                                    <TextField>{`${item.product?.name} ${item.productVariant ? `/ ${item.productVariant?.valuesLine}` : ""}`}</TextField>
                                    <TextField light small>Cantidad: {item.quantityReturned}</TextField>
                                    {item.lastReturnDate && <TextField light small>Fecha: {getDateFormatll(item?.lastReturnDate)}</TextField>}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </ContainerCollapse>
            )}
        </Flex>
    )
}
