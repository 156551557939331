import React, { useState } from "react";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { IInventoryProductAdjustmentModel } from "../../../api/models/inventoryAdjustment";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import { useUserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { DeliveryOrderStatusButton } from "../../Orders/DeliveryOrdersByStatusModal";
import ShipmentOrderCount from "../../Orders/Shipments/ShipmentOrderCount";
import { ProductLocationsTable } from "../../Warehouses/WarehouseLocations/LocationProductCardView";
import { NewProductLocationModal } from "../../Warehouses/WarehouseLocations/NewProductLocationForm";
import { DefaultButton, Flex } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import ConfirmationModal from "../../modals/ConfirmationModal";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { StockRow } from "./NavigationStock";
import messages from "./messages";
import { ProductStockInvalidContainer } from "./styled";

export default function NavigationStockLocation(props: ProductNavigationProfileProps) {
    const { warehouseProduct, selectedWarehouse, fetchWarehouseProduct } = props;

    const [showNewProductLocationModal, setShowNewProductLocationModal] = useState(false);
    const { userPermission } = useUserContext();
    const allowEdit = userPermission.inventoryAdjustment?.create && userPermission.inventoryAdjustment?.update;

    if (!warehouseProduct) return null;

    return (
        <NavigationCard {...props} title={props.intl.formatMessage(messages.stockLocation)}>
            <Flex column gap20>
                {showNewProductLocationModal && warehouseProduct.warehouse && (
                    <NewProductLocationModal
                        warehouse={warehouseProduct.warehouse}
                        warehouseProduct={{ ...warehouseProduct, product: props.product, productVariant: props.productVariant }}
                        show={showNewProductLocationModal}
                        setShow={setShowNewProductLocationModal}
                        onProductAdded={() => {
                            fetchWarehouseProduct();
                        }}
                    />
                )}
                <Table cellPadding={0} cellSpacing={0} rowHeight={45}>
                    <tbody>
                        <StockRow
                            icon="fa-sharp fa-regular fa-inboxes"
                            title="En ubicaciones"
                            value={warehouseProduct.warehouseProductLocationsTotalStock}
                            buttonComp={
                                <DefaultButton
                                    disabled={!allowEdit}
                                    bgLight
                                    borderRadius={10}
                                    onClick={() => setShowNewProductLocationModal(true)}
                                >
                                    <span className="fa-regular fa-pen-to-square"></span> Ubicar
                                </DefaultButton>
                            }
                        />
                        <StockRow
                            icon="fa-regular fa-box"
                            title="En Packing"
                            value={<ShipmentOrderCount warehouseProduct={warehouseProduct} status={"packing"} />}
                            buttonComp={
                                <DeliveryOrderStatusButton
                                    warehouseProduct={warehouseProduct}
                                    deliveryStatusList={["packing"]}
                                    onOrderUpdate={() => {}}
                                />
                            }
                        />
                    </tbody>
                </Table>

                <div className="stock-details">
                    {warehouseProduct &&
                        warehouseProduct.warehouseProductLocations &&
                        warehouseProduct.warehouseProductLocations.length > 0 && (
                            <ProductLocationsTable
                                warehouseProduct={warehouseProduct}
                                product={props.product}
                                productLocations={warehouseProduct.warehouseProductLocations}
                                onUpdateLocation={() => fetchWarehouseProduct()}
                            />
                        )}
                </div>

                {selectedWarehouse?.allowLogisticsService &&
                    warehouseProduct &&
                    warehouseProduct.stock !== warehouseProduct.warehouseProductLocationsTotalStock && (
                        <ProductStockInvalidView onFixedStock={fetchWarehouseProduct} item={warehouseProduct} />
                    )}
            </Flex>
        </NavigationCard>
    );
}

interface ProductStockInvalidProps {
    item: IWarehouseProduct;
    onFixedStock: () => void;
}
export const ProductStockInvalidView = (props: ProductStockInvalidProps) => {
    const { item } = props;
    const alert = useAlert();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const handleFixedStock = () => {
        setIsFetching(true);
        setShowConfirmationModal(false);
        let body: IInventoryProductAdjustmentModel = {
            productId: item.productId,
            warehouseId: item.warehouseId,
            quantity: item.warehouseProductLocationsTotalStock,
            accountUserId: getAuthBody().accountUserId,
            productVariantId: item.productVariantId,
            serials: [],
        };
        wahioFetch.post(
            productEndpoint.post.inventoryProductAdjustment,
            body,
            () => {
                setIsFetching(false);
                props.onFixedStock();
            },
            (error) => {
                setIsFetching(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    return (
        <ProductStockInvalidContainer className="mb-1">
            {showConfirmationModal && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmationModal(false)}
                    onOkay={() => handleFixedStock()}
                    title="¿Estas seguro?"
                    description="El stock del producto se ajustara a la cantidad de productos ubicados."
                    show={showConfirmationModal}
                    setShow={setShowConfirmationModal}
                />
            )}
            <div className="icon">
                <span className="wahioicon-exclamation-triangle"></span>
            </div>
            <div className="stock-message">
                <span className="bold">{item.stock - item.warehouseProductLocationsTotalStock} productos sin ubicar</span>
            </div>
            {isFetching ? (
                <LoadingDualRing className="ml-1" center small />
            ) : (
                <DefaultButton onClick={() => setShowConfirmationModal(true)} rounded className="ml-1 small filled">
                    Corregir
                </DefaultButton>
            )}
        </ProductStockInvalidContainer>
    );
};
