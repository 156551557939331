import { defineMessages } from "react-intl";

const base = "app.globalMessages";

export default defineMessages({
    read: {
        id: `${base}.read`,
        defaultMessage: "Ver",
    },
    create: {
        id: `${base}.Crear`,
        defaultMessage: "Crear",
    },
    update: {
        id: `${base}.update`,
        defaultMessage: "Actualizar",
    },
    delete: {
        id: `${base}.delete`,
        defaultMessage: "Eliminar",
    },
    canConfirm: {
        id: `${base}.canConfirm`,
        defaultMessage: "Confirmar/Rechazar",
    },
    createWithoutConfirmation: {
        id: `${base}.createWithoutConfirmation`,
        defaultMessage: "Crear sin confirmar",
    },
    completeAllPicking: {
        id: `${base}.completeAllPicking`,
        defaultMessage: "Autocompletar Picking",
    },
    completePickingItem: {
        id: `${base}.completePickingItem`,
        defaultMessage: "Autocompletar Picking por item",
    },
    viewOrdersFromOtherUsers: {
        id: `${base}.viewOrdersFromOtherUsers`,
        defaultMessage: "Ver ordenes de otros usuarios",
    },
    allowUseAllWarehouses: {
        id: `${base}.allowUseAllWarehouses`,
        defaultMessage: "Permitir cambiar la bodega",
    },
    seeAccountsPayable: {
        id: `${base}.seeAccountsPayable`,
        defaultMessage: "Cartera: Cuentas por pagar",
    },
    seeAccountsReceivable: {
        id: `${base}.seeAccountsReceivable`,
        defaultMessage: "Cartera: Cuentas por cobrar",
    },
    activateCashRegister: {
        id: `${base}.activateCashRegister`,
        defaultMessage: "Activar control caja",
    },
    createMovement: {
        id: `${base}.createMovement`,
        defaultMessage: "Crear Traslado",
    },
    updateMovement: {
        id: `${base}.updateMovement`,
        defaultMessage: "Actualizar Traslado",
    },
    allowSeeAllWarehouses: {
        id: `${base}.allowSeeAllWarehouses`,
        defaultMessage: "Ver todas las bodegas",
    },
    onlyUseDefaultWarehouseForStore: {
        id: `${base}.onlyUseDefaultWarehouseForStore`,
        defaultMessage: "Solo usar bodega predeterminada",
    },
    allowCreatePayments: {
        id: `${base}.allowCreatePayments`,
        defaultMessage: "Permitir crear pagos",
    },
    allowCreateComments: {
        id: `${base}.allowCreateComments`,
        defaultMessage: "Permitir crear comentarios",
    },
    inventoryAdjustment: {
        id: `${base}.inventoryAdjustment`,
        defaultMessage: "Ajuste de inventario",
    },
    invoiceNumeration: {
        id: `${base}.invoiceNumeration`,
        defaultMessage: "Numeración de factura",
    },
    account: {
        id: `${base}.account`,
        defaultMessage: "Cuenta",
    },
    statistics: {
        id: `${base}.statistics`,
        defaultMessage: "Estadisticas",
    },
    organization: {
        id: `${base}.organization`,
        defaultMessage: "Tiendas",
    },
    user: {
        id: `${base}.user`,
        defaultMessage: "Usuarios",
    },
    product: {
        id: `${base}.product`,
        defaultMessage: "Productos",
    },
    warehouse: {
        id: `${base}.warehouse`,
        defaultMessage: "Bodegas",
    },
    category: {
        id: `${base}.category`,
        defaultMessage: "Categoria",
    },
    sale: {
        id: `${base}.sale`,
        defaultMessage: "Ventas",
    },
    order: {
        id: `${base}.order`,
        defaultMessage: "Ordenes",
    },
    purchase: {
        id: `${base}.purchase`,
        defaultMessage: "Compras",
    },
    tax: {
        id: `${base}.tax`,
        defaultMessage: "Impuestos",
    },
    discount: {
        id: `${base}.discount`,
        defaultMessage: "Descuentos",
    },
    catalog: {
        id: `${base}.catalog`,
        defaultMessage: "Catalogo",
    },
    restaurant: {
        id: `${base}.restaurant`,
        defaultMessage: "Restaurante",
    },
    contact: {
        id: `${base}.contact`,
        defaultMessage: "Contacto",
    },
    channel: {
        id: `${base}.channel`,
        defaultMessage: "Canales",
    },
    expense: {
        id: `${base}.expense`,
        defaultMessage: "Gastos",
    },
    cashRegister: {
        id: `${base}.cashRegister`,
        defaultMessage: "Cajas y Bancos",
    },
    allowSeeAllCashRegisters: {
        id: `${base}.allowSeeAllCashRegisters`,
        defaultMessage: "Ver todas las cajas",
    },
    permission: {
        id: `${base}.permission`,
        defaultMessage: "Permisos y Roles",
    },
    cloud: {
        id: `${base}.cloud`,
        defaultMessage: "Wahio - Cloud",
    },
    billing: {
        id: `${base}.billing`,
        defaultMessage: "Wahio - Facturación",
    },
    allowCreatePaymentMethods: {
        id: `${base}.allowCreatePaymentMethods`,
        defaultMessage: "Crear métodos de pago",
    },
    allowDeletePaymentMethods: {
        id: `${base}.allowDeletePaymentMethods`,
        defaultMessage: "Eliminar métodos de pago",
    },
    allowBuyLicenses: {
        id: `${base}.allowBuyLicenses`,
        defaultMessage: "Comprar licencias",
    },
    bank: {
        id: `${base}.bank`,
        defaultMessage: "Banco",
    },
    cash: {
        id: `${base}.cash`,
        defaultMessage: "Efectivo",
    },
    card: {
        id: `${base}.card`,
        defaultMessage: "Tarjeta",
    },
    creditCard: {
        id: `${base}.creditCard`,
        defaultMessage: "Tarjeta de crédito",
    },
    debitCard: {
        id: `${base}.debitCard`,
        defaultMessage: "Tarjeta de débito",
    },
    transfer: {
        id: `${base}.transfer`,
        defaultMessage: "Transferencia",
    },
    deposit: {
        id: `${base}.deposit`,
        defaultMessage: "Depósito",
    },
    giftCard: {
        id: `${base}.giftCard`,
        defaultMessage: "Tarjeta de regalo",
    },
    online: {
        id: `${base}.online`,
        defaultMessage: "En línea",
    },
    orderReturn: {
        id: `${base}.orderReturn`,
        defaultMessage: "Devolución",
    },
    invalid: {
        id: `${base}.invalid`,
        defaultMessage: "Invalido",
    },
    manually: {
        id: `${base}.manually`,
        defaultMessage: "Creado Manualmente",
    },
    ecommerce: {
        id: `${base}.ecommerce`,
        defaultMessage: "Tienda Virtual",
    },
    null: {
        id: `${base}.null`,
        defaultMessage: "Nulo",
    },
    addCashRegisters: {
        id: `${base}.addCashRegisters`,
        defaultMessage: "Agregar cajas",
    },
    addWarehouses: {
        id: `${base}.addWarehouses`,
        defaultMessage: "Agregar bodegas",
    },
    addUsers: {
        id: `${base}.addUsers`,
        defaultMessage: "Agregar usuarios",
    },
    partial: {
        id: `${base}.partial`,
        defaultMessage: "Parcial",
    },
    paid: {
        id: `${base}.paid`,
        defaultMessage: "Pagado",
    },
    partialPaymentOrder: {
        id: `${base}.partialPaymentOrder`,
        defaultMessage: "Pago parcial a orden",
    },
    orderPayment: {
        id: `${base}.orderPayment`,
        defaultMessage: "Pago de orden",
    },
    payment: {
        id: `${base}.payment`,
        defaultMessage: "Pagos",
    },
    accounting: {
        id: `${base}.accounting`,
        defaultMessage: "Cartera",
    },
    orderFormAllowEditPrice: {
        id: `${base}.orderFormAllowEditPrice`,
        defaultMessage: "Editar precios",
    },
    orderFormAllowEditDiscount: {
        id: `${base}.orderFormAllowEditDiscount`,
        defaultMessage: "Editar descuentos",
    },
    orderFormAllowEditTax: {
        id: `${base}.orderFormAllowEditTax`,
        defaultMessage: "Editar impuestos",
    },
});
