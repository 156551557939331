import React, { createContext, useEffect, useContext, useState } from "react";
import { getStoredData, saveData, LocalStoreKeys } from "..";
import { useMutation } from "react-query";
import { getAccountUsersByAccount } from "../../api/account/accountUser";
import { AccountUser } from "../../api/models/accountUser";
import { getAuthBody } from "../../auth";
import { colorListChat } from "../../constants/colors";
import _ from "lodash";

export interface IAccountUserState {
    isFetching: boolean;
    items: AccountUser[];
    itemsObj?: { [key: string]: AccountUser };
}

export interface IAccountUserActions {
    requestAccountUsers: () => void;
    receiveAccountUsers: (items: AccountUser[]) => void;
}

const initStateBase: IAccountUserState = {
    isFetching: false,
    items: [],
    itemsObj: {},
};

interface IContextProps {
    accountUserState: IAccountUserState;
    accountUserActions: IAccountUserActions;
    getUserById: (id?: string) => AccountUser | undefined;
}

export const AccountUserContext = createContext({} as IContextProps);

const AccountUserContextProvider = (props: any) => {
    const [accountUserState, setAccountUserState] = useState(getStoredData<IAccountUserState>(initStateBase, LocalStoreKeys.accountUser));

    const usersMutation = useMutation((accountId: string) => getAccountUsersByAccount(accountId), {
        onSuccess: (data) => {
            setAccountUserState({ ...accountUserState, isFetching: false, items: getItemsWithColor(data), itemsObj: getItemsObj(data) });
        },
        onError: (error) => {
            setAccountUserState({ ...accountUserState, isFetching: false });
        },
    });

    useEffect(() => {
        saveData(LocalStoreKeys.accountUser, accountUserState);
    }, [accountUserState]);

    const getItemsObj = (items: AccountUser[]) => {
        let obj = _.sortBy(items, "dateCreated").reduce((obj: { [key: string]: AccountUser }, item) => {
            obj[item.id] = item;
            return obj;
        }, {});
        return obj;
    };

    const getUserById = (id?: string) => {
        if (!id) return undefined;
        return accountUserState.itemsObj ? accountUserState.itemsObj[id] : undefined;
    };

    const getItemsWithColor = (items: AccountUser[]) => {
        let result = _.sortBy(items, "firstName").map((item, index) => {
            let itemCopy = { ...item };
            let colorIndex = index % colorListChat.length;
            itemCopy.color = colorListChat[colorIndex];
            return itemCopy;
        });
        return result;
    };

    const requestAccountUsers = () => {
        if (accountUserState.items.length > 0 || usersMutation.isLoading) return;
        setAccountUserState({ ...accountUserState, isFetching: true });
        usersMutation.mutate(getAuthBody().accountId);
    };
    const receiveAccountUsers = (items: AccountUser[]) => {
        setAccountUserState({ ...accountUserState, items: getItemsWithColor(items) });
    };

    return (
        <AccountUserContext.Provider
            value={{ accountUserState, accountUserActions: { requestAccountUsers, receiveAccountUsers }, getUserById }}
        >
            {props.children}
        </AccountUserContext.Provider>
    );
};

export const useAccountUserContext = () => {
    return useContext(AccountUserContext);
};

export default AccountUserContextProvider;
