import React, { useEffect, useState } from "react";
import { useAlert } from "../Alerts/Alert";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";
import { getServiceMessageError } from "../../api";
import { postCreateUpdateWahioFolder, updateWahioCloudFile } from "../../api/account/wahioCloudApi";
import { WahioCloudFolder, WahioCloudItem } from "../../api/models/wahioCloud";
import { QUERY_WAHIOCLOUD_FOLDERS } from "../../api/queryKeys";
import { CloudImages } from "../../assets";
import LoadingDualRing from "../LoadingDualRing";
import Modal, { IModalShow } from "../modals/Modal";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, TextField } from "../_controls";
import { getCloudItemExtensionImage } from "./extensionImage";
import messages from "./messages";
import { FolderCardTree, FolderTreeContent, MoveModalContent } from "./styled";

export interface FolderMoveModalProps extends IModalShow {
    folders: WahioCloudFolder[];
    itemFolder?: WahioCloudFolder;
    itemFile?: WahioCloudItem;
    onSelected?: () => void;

}

interface StateProps {
    folders: WahioCloudFolder[];
    folderOpen: WahioCloudFolder | null;
    folderParentId: string;
    mainFolder: boolean;
}

export const MoveFoldersModal = (props: FolderMoveModalProps) => {
    const queryClient = useQueryClient();
    const useUrl = props.itemFile?.type === "image" && props.itemFile.fileExtension !== "svg";
    const image = useUrl ? props.itemFile?.url : getCloudItemExtensionImage(props.itemFile?.fileExtension ?? "");
    const intl = useIntl();
    const alert = useAlert();

    const [state, setState] = useState<StateProps>({
        folderParentId: "",
        folders: [],
        folderOpen: null,
        mainFolder: false
    })

    useEffect(() => {
        let items = props.folders.filter(x => x.folderParentId === undefined);
        setState({ ...state, folders: items })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.folders])

    const updateFolderMutation = useMutation((folder: WahioCloudFolder) => postCreateUpdateWahioFolder(folder), {
        onSuccess: (data) => {
            alert.success("Archivo actualizado con éxito")
            queryClient.refetchQueries(QUERY_WAHIOCLOUD_FOLDERS)
            props.onSelected && props.onSelected();
            props.setShow(false);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error))
        },
    });

    const updateCloudFileMutation = useMutation((newFile: WahioCloudItem) => updateWahioCloudFile(newFile), {
        onSuccess: (data) => {
            props.setShow(false);
            alert.success("Archivo actualizado con éxito");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        }
    })

    const onMoveItem = () => {
        if (props.itemFolder) {
            const updateFolder = { ...props.itemFolder };
            updateFolder.folderParentId = state.mainFolder ? "" : state.folderOpen?.id ?? "";
            updateFolderMutation.mutate(updateFolder);
        } else if (props.itemFile) {
            const updateFile = { ...props.itemFile };
            updateFile.folderId = state.mainFolder ? "" : state.folderOpen?.id;
            updateCloudFileMutation.mutate(updateFile);
        }
    }

    return (
        <Modal show={props.show} setShow={props.setShow} useMobileView showHeader title="Mover archivos" sizeType='md'>
            <Flex gap10>
                <FolderTreeContent>
                    <FolderCardTree
                        className={`${state?.folderOpen?.id === "active" ? "active" : ""} main-folder`}
                        onClick={() => setState({ ...state, folderOpen: null, mainFolder: true })}
                    >
                        <FlexImageStyle width={15}>
                            <img src={CloudImages.openFolder} alt={"folder-item"} />
                        </FlexImageStyle>
                        <TextField small light>{intl.formatMessage(messages.mainFolder)}</TextField>
                    </FolderCardTree>
                    <Flex w100 marginBottom={5} >
                        <FolderTree folders={state.folders} state={state} setState={setState} />
                    </Flex>
                </FolderTreeContent>
                <MoveModalContent >
                    {(state.folderOpen || state.mainFolder) ? (
                        <Flex column gap10>
                            <TextField small>{`Mover a ${state?.folderOpen?.name ?? "Carpeta Principal"}`}</TextField>
                            <Flex className="image">
                                <Flex alignCenter justifyCenter>
                                    <FlexImageStyle width={70} borderRadius={10} >
                                        <img src={props.itemFile ? image : CloudImages.openFolder} alt={props.itemFolder && props.itemFolder.name} />
                                    </FlexImageStyle>
                                </Flex>
                                <TextField className="name">{props.itemFile ? props.itemFile.name : props.itemFolder && props.itemFolder.name}</TextField>
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex>
                            <TextField light small>Selecciona una carpeta</TextField>
                        </Flex>
                    )}
                    <Flex alignEnd justifyEnd gap10 padding={10}>
                        <DefaultButton borderRadius={10} small onClick={() => props.setShow(false)}>Cancelar</DefaultButton>
                        {(state.folderOpen || state.mainFolder) &&
                            <PrimaryButton borderRadius={10} small onClick={() => onMoveItem()}>{
                                (updateFolderMutation.isLoading || updateCloudFileMutation.isLoading) ? <LoadingDualRing small /> : "Mover aquí"}
                            </PrimaryButton>
                        }
                    </Flex>
                </MoveModalContent>
            </Flex>

        </Modal>
    )
}

export interface FolderContentProps {
    folders: WahioCloudFolder[];
    state: StateProps;
    setState: (state: StateProps) => void;
}

export const FolderTree = (props: FolderContentProps) => {
    const [showChilds, setShowChilds] = useState<string[]>([])

    const addOrRemoveFolderShowChilds = (folderId: string) => {
        if (showChilds.includes(folderId)) {
            setShowChilds(showChilds.filter(x => x !== folderId))
        } else {
            setShowChilds([...showChilds, folderId])
        }
    }

    const renderFolders = (props: FolderContentProps) => {
        return (
            <Flex column gap10 marginLeft={20} w100>
                {props.folders.map((folder) => (
                    <>
                        <FolderCardTree
                            key={folder.id}
                            className={`${props.state?.folderOpen?.id === folder.id ? "active" : ""}`}
                            onDoubleClick={() => addOrRemoveFolderShowChilds(folder?.id ?? "")}
                            onClick={() => props.setState({ ...props.state, folderOpen: folder })}
                        >
                            {folder?.childs?.length > 0 &&
                                <TextField
                                    small
                                    light
                                    className={`${folder.id && showChilds.includes(folder?.id) ? "fa-regular fa-caret-down" : "fa-regular fa-caret-right"} caret`}
                                    onClick={() => addOrRemoveFolderShowChilds(folder?.id ?? "")}
                                />
                            }
                            <FlexImageStyle width={15}>
                                <img src={CloudImages.openFolder} alt={"folder-item"} />
                            </FlexImageStyle>
                            <TextField small light>{folder.name}</TextField>
                        </FolderCardTree>
                        {folder.id && showChilds.includes(folder?.id) && folder?.childs?.length > 0 &&
                            <Flex w100>
                                {renderFolders({ ...props, folders: folder.childs })}
                            </Flex>
                        }
                    </>
                ))}
            </Flex>
        )
    }
    return <>{renderFolders(props)}</>
}
