import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AccountUserQuickStart } from "../../api/models/accountUser";
import { checkAndGetMessage } from "../../i18n/helper";
import { useUserContext } from "../../store/contexts/UserContext";
import customMoment from "../../utils/momentFormat/dateFormat";
import { Flex, SquareButton, TextField } from "../_controls";
import HelpCenterSearch from "./HelpCenterSearch";
import QuickStartShowHelpCenterRecovery from "./QuickStartShowHelpCenterRecovery";
import { QuickStartOptionContent, quickStartOptions } from "./data";
import messages from "./messages";
import { useQuickStartUpdate } from "./quickStartHook";
import { PanelIconContainer, QuickStartHelpContainer, QuickStartOptionCardStyle, QuickStartVerticalContainer } from "./styled";

const createDefaultQuickStart = () => {
    var data: AccountUserQuickStart = {
        show: true,
        hidePermanently: false,
        updatedAt: customMoment().format(),
        options: quickStartOptions.map((option) => {
            return { name: option.name, isComplete: false };
        }),
    };
    return data;
};

export default function QuickStartVertical() {
    const [optionOpen, setOptionOpen] = useState(-1);
    const { userState, setUserState } = useUserContext();

    const [quickStart, setQuickStart] = useState<AccountUserQuickStart>();
    const [options, setOptions] = useState<QuickStartOptionContent[]>(quickStartOptions);
    const [showReopenHelpCenterTour, setShowReopenHelpCenterTour] = useState(false);
    const isMounted = React.useRef(false);
    const quickStartUpdate = useQuickStartUpdate();

    const [showIntroduction, setShowIntroduction] = useState(false);

    useEffect(() => {
        isMounted.current = true;
        if (userState.user) {
            if (userState.user.quickStart) {
                setQuickStart(userState.user?.quickStart ?? createDefaultQuickStart());
                const newOptions = quickStartOptions.map((item) => {
                    return {
                        ...item,
                        isComplete: userState.user?.quickStart?.options?.find((option) => option.name === item.name)?.isComplete ?? false,
                    };
                });
                setOptions(newOptions);
            } else {
                setUserState({
                    ...userState,
                    user: { ...userState.user, quickStart: createDefaultQuickStart() },
                });
            }
        } else {
            setQuickStart(undefined);
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.user]);

    if (!quickStart) return null;

    const handleShow = (show: boolean) => {
        quickStartUpdate.saveChanges({ ...quickStart, show });
    };

    const handleHide = () => {
        quickStartUpdate.saveChanges({ ...quickStart, hidePermanently: true });
        setShowReopenHelpCenterTour(true);
    };

    if (quickStart.hidePermanently && showReopenHelpCenterTour) {
        return <QuickStartShowHelpCenterRecovery />;
    }
    if (quickStart.hidePermanently) return null;

    if (!quickStart.show) {
        return (
            <QuickStartHelpContainer>
                <Flex className="content-area" gap10 alignCenter onClick={() => handleShow(true)}>
                    <i className="fa-regular fa-lightbulb"></i> <TextField bold>Ayuda</TextField>
                </Flex>
                <SquareButton small className="btn-close" title="Cerrar" onClick={() => handleHide()}>
                    <span className="fa-regular fa-xmark"></span>
                </SquareButton>
            </QuickStartHelpContainer>
        );
    }

    return (
        <QuickStartVerticalContainer>
            <Flex column gap5 className="quick-actions-header">
                <h3 className="text-bold m-0">Centro de ayuda</h3>
                <SquareButton rounded className="quick-start-close" onClick={() => handleShow(false)}>
                    <span className="fa-regular fa-xmark"></span>
                </SquareButton>
            </Flex>

            <Flex className="content-body">
                <HelpCenterSearch />
                <CardIconPanel
                    icon="fa-regular fa-rocket-launch"
                    onClick={() => setShowIntroduction(!showIntroduction)}
                    title="Introducción"
                    description="Aprende lo básico de Wahio"
                />

                {showIntroduction && (
                    <Flex column className="quick-actions-options">
                        {options.map((option, index) => (
                            <QuickStartOptionCard
                                key={index}
                                index={index}
                                optionOpen={optionOpen}
                                setOptionOpen={setOptionOpen}
                                option={option}
                            />
                        ))}
                    </Flex>
                )}
                <Flex column gap10>
                    <TextField bold>Otras formas de obtener ayuda</TextField>
                    <CardIconPanelLink
                        link="https://support.wahio.com/docs/"
                        icon="fa-regular fa-book-open"
                        title="Abrir centro de ayuda"
                        description="Artículos de ayuda y tutoriales"
                    />
                    <CardIconPanelLink
                        link="https://api.whatsapp.com/send?phone=573114114261&text=Hola,%20tengo%20una%20consulta%20sobre%20Wahio."
                        icon="wahioicon-whatsapp"
                        title="Chatea con nosotros"
                        description="Escríbenos por WhatsApp"
                    />
                </Flex>
            </Flex>
        </QuickStartVerticalContainer>
    );
}

interface CardIconPanelProps {
    onClick?: () => void;
    title: string;
    description: string;
    icon: string;
    extraIcon?: React.ReactNode;
}

interface CardIconPanelLinkProps extends CardIconPanelProps {
    link: string;
}

const CardIconPanelLink = (props: CardIconPanelLinkProps) => {
    return (
        <a href={props.link} target="_blank" rel="noreferrer">
            <CardIconPanel {...props} extraIcon={<TextField light className="ml-auto fa-regular fa-up-right-from-square"></TextField>} />
        </a>
    );
};

const CardIconPanel = (props: CardIconPanelProps) => {
    return (
        <PanelIconContainer onClick={props.onClick}>
            <TextField className={`icon ${props.icon}`}></TextField>
            <Flex column>
                <TextField className="card-title">{props.title}</TextField>
                <TextField small light>
                    {props.description}
                </TextField>
            </Flex>
            {props.extraIcon}
        </PanelIconContainer>
    );
};

interface QuickStartOptionCardProps {
    option: QuickStartOptionContent;
    index: number;
    optionOpen: number;
    setOptionOpen: (optionOpen: number) => void;
}

const QuickStartOptionCard = (props: QuickStartOptionCardProps) => {
    const { option, index } = props;
    const isOpen = props.optionOpen === index;
    const intl = useIntl();

    const handleShowOptionContent = () => {
        props.setOptionOpen(isOpen ? -1 : index);
    };

    return (
        <QuickStartOptionCardStyle className="option-card">
            <Flex gap15 alignCenter onClick={handleShowOptionContent} pointer>
                <div className={`option-check ${option.isComplete ? "active" : ""}`}>
                    {option.isComplete ? <span className={"fa-regular fa-check"}></span> : <span>{index + 1}</span>}
                </div>
                <Flex>
                    <TextField bold>{checkAndGetMessage(intl, messages, option.name)}</TextField>
                </Flex>
                <SquareButton className="btn-angle" removeBackground>
                    <span
                        className="wahioicon-angle-down angle-icon"
                        style={{ transition: ".3s all", transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
                    ></span>
                </SquareButton>
            </Flex>
            {isOpen && option.content}
        </QuickStartOptionCardStyle>
    );
};
