import React from "react";
import { Contact } from "../../../../../api/models/contact";
import { getAuthBody } from "../../../../../auth";
import { useOrganizationContext } from "../../../../../store/contexts/OrganizationContext";
import ContactSelect from "../../../../Contacts/ContactSelect";
import { OrderChangeProps } from "..";

interface ContactDetailsProps extends OrderChangeProps {
    preventRemoveCustomer?: boolean;
    w100?: boolean;
}

const ContactDetails = (props: ContactDetailsProps) => {
    const { organizationState } = useOrganizationContext();
    const { order } = props;
    const { customer } = order;

    const updateCustomer = (contact?: Contact) => {
        let deliveryOrder = order.deliveryOrder;
        if (deliveryOrder) {
            if (contact && contact.addresses && contact.addresses.length > 0) {
                let address = contact.addresses[0];
                deliveryOrder = {
                    ...deliveryOrder,
                    accountId: getAuthBody().accountId,
                    address: address.description,
                    country: address.country,
                    city: address.city,
                    state: address.state,
                    contactName: contact.firstName + " " + contact.lastName,
                    phoneNumber: contact.phoneNumber,
                    originAddress: organizationState.currentOrganization?.address,
                    originState: organizationState.currentOrganization?.state,
                    originCity: organizationState.currentOrganization?.city,
                    additionalInformation: address.additionalInformation
                };
            } else if (contact) {
                deliveryOrder = { ...deliveryOrder };
                deliveryOrder.accountId = getAuthBody().accountId;
                deliveryOrder.contactName = contact.firstName + " " + contact.lastName;
                deliveryOrder.phoneNumber = contact.phoneNumber;
                deliveryOrder.address = contact.address;
                deliveryOrder.originAddress = organizationState.currentOrganization?.address;
                deliveryOrder.originState = organizationState.currentOrganization?.state;
                deliveryOrder.originCity = organizationState.currentOrganization?.city;
            }
        }
        props.onOrderChange({ ...order, customer: contact, customerId: contact?.id, deliveryOrder });
    };

    return (
        <ContactSelect
            w100={props.w100}
            title="Cliente"
            hidePadding
            placeholder="Seleccionar Cliente"
            contact={customer}
            onUpdateContact={updateCustomer}
            preventRemoveCustomer={props.preventRemoveCustomer}
            allowCreate
            loadCompleteContact
            checkDebts="debts"
        />
    );
};
export default ContactDetails;
