import { createGlobalStyle } from "styled-components";
import { device } from "./theme/themeProvider";

const GlobalStyle = createGlobalStyle` 
   body, button, input , textarea {
    font-family: 'Poppins', sans-serif, 'Roboto', 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    '-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'sans-serif';
    font-size: 14px;
  }

  @media ${device.md} {     
      body {
        font-size: 14px;
      }
  }

  html, body {   
    line-height: 1.3;
  }

  html, body { 
    background: ${(props) => props.theme.palette.background};
    color: ${(props) => props.theme.palette.text};
    margin: 0;
    padding: 0; 
    background: ${(props) => props.theme.palette.backgroundGradientBody};
  }
  #app {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
  }
 
  a {
    color:  ${(props) => props.theme.palette.primary};
    text-decoration: none;
    &:visited {
      color: ${(props) => props.theme.palette.primary};
    }
  }


hr {
  height: 1px;
  background: ${(props) => props.theme.palette.backgroundDark};
  width: 100%;
  border: 0px;
}

.large-font-2 { 
  font-size: 2rem;
}
.large-font-3 { 
  font-size: 3rem;
}
.w-100 {
  width: 100%;
}
.m-0 {
  margin: 0px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-auto { 
  margin-left: auto;
}
.ml-2 {
  margin-left: 20px;
}
.ml-1_5 {
  margin-left: 15px;
}
.mb-1 { 
  margin-bottom: 10px;
}
.mb-2 { 
  margin-bottom: 20px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mt-1 { 
  margin-top: 10px;
}
.mt-2 { 
  margin-top: 20px;
}

.m-tb {
  margin-bottom: 3px;
  margin-top: 3px;
}
.pt-1 {
  padding-top: 10px;
}
.pb-0 
 { 
   padding-bottom: 0px;
 }

&::-webkit-scrollbar-track {
    background: transparent;
}

&::-webkit-scrollbar {
   width: 14px;
   background: transparent;
}

&::-webkit-scrollbar-thumb {
  background:  ${(props) => props.theme.palette.backgroundLight};
  border: 3px solid transparent;
  border-radius: 50px;
  background-clip: padding-box;  

  &:hover { 
    background:  ${(props) => props.theme.palette.backgroundDark};
    border: 3px solid transparent;
    border-radius: 50px;
    background-clip: padding-box; 
  }
}
.border-dashed {
  border-bottom: 1px dashed ${(props) => props.theme.palette.backgroundDark};
}
.display-flex { 
  display: flex;
}
.flex-center { 
  display: flex;
  align-items: center;
}
.flex-col { 
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.space-between { 
  justify-content: space-between;
}
.bg-background { 
  background: ${(props) => props.theme.palette.background};
}
.bg-light {
  background: ${(props) => props.theme.palette.backgroundLight};
}
.text-light { 
  color: ${(props) => props.theme.palette.textLight};
}
.text-center{ 
  text-align: center;
}
.text-primary { 
  color: ${(props) => props.theme.palette.primary};
}
.text-alter-primary {
  color: #2962ff87;
}
.text-small {
  font-size: 13px;
}
.text-small-light {
  font-size: 13px;
  color: ${(props) => props.theme.palette.textLight};
}
.text-center {
  text-align: center;
}
.text-bold { 
  font-weight: 500;
}
.text-danger {
  color: ${(props) => props.theme.palette.dangerColor};
}
.text-primary {
  color: ${(props) => props.theme.palette.primary};
}
.red {
  color: red;
}
.flex-end {
  justify-content: flex-end;
}
.row-reverse {
  flex-direction: row-reverse;
} 
.gap-1 {
  gap:10px;
}
.word-spacing {
  gap:2px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.wrap{
  flex-wrap: wrap;
}
.text-success {
  color: green;
}
.font14 {
  font-size: 14px;
}
.font-title {
  font-size: 17px;
  font-weight: 500;
}
.no-wrap {
  white-space: nowrap;
}
`;

export default GlobalStyle;
