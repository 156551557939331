import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { CurrentRegisterLogModal } from ".";
import { getCashRegisterLogActive } from "../../../api/account/cashRegisterApi";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import LoadingDualRing from "../../LoadingDualRing";
import { LogActiveButton } from "./styled";

export const useCashRegisterLogAuto = () => {
    const isMounted = React.useRef(true);
    const { appSettingsState } = useContext(AppSettingsContext);
    const { cashRegisterState, cashRegisterActions } = useContext(CashRegisterContext);

    const currentCashRegister = cashRegisterState.currentCashRegister;

    const activeLogMutation = useMutation((cashRegisterId: string) => getCashRegisterLogActive(cashRegisterId));

    useEffect(() => {
        isMounted.current = true;
        if (currentCashRegister && currentCashRegister.id === appSettingsState.activeLogCashRegisterId) return;
        if (cashRegisterState.orgItems.length === 0) return;

        if (appSettingsState.activeLogCashRegisterId) {
            let cashRegister = cashRegisterState.orgItems.find((x) => x.id === appSettingsState.activeLogCashRegisterId);
            if (cashRegister && !activeLogMutation.isLoading) {
                activeLogMutation.mutateAsync(appSettingsState.activeLogCashRegisterId).then((data) => {
                    cashRegisterActions.set({ ...cashRegisterState, currentCashRegister: cashRegister, logActive: data });
                });
            }
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettingsState.activeLogCashRegisterId, cashRegisterState.orgItems]);

    return { isLoading: activeLogMutation.isLoading };
};

export default function CashRegisterLogActiveButton() {
    const { appSettingsState } = useContext(AppSettingsContext);
    const { cashRegisterState } = useContext(CashRegisterContext);
    const { organizationState } = useContext(OrganizationContext);
    const [showCashRegisterLog, setShowCashRegisterLog] = useState(false);

    const autoLog = useCashRegisterLogAuto();

    if (autoLog.isLoading) {
        return <LoadingDualRing small />;
    }

    if (cashRegisterState.logActive || organizationState.currentOrganization?.requiredCashRegister) {
        let register = cashRegisterState.logActive;
        let btnTitle = register ? `Caja` : "Activar Caja";
        const activeClass = cashRegisterState.logActive ? "active" : "";
        const isSquare = appSettingsState.menuSmall ? "square" : "";

        return (
            <>
                {showCashRegisterLog && (
                    <CurrentRegisterLogModal useButtonClose={true} show={showCashRegisterLog} setShow={setShowCashRegisterLog} />
                )}
                <LogActiveButton
                    borderRadius={10}
                    className={`${activeClass} ${isSquare}`}
                    title={cashRegisterState.logActive ? "Cerrar caja" : "Iniciar caja"}
                    onClick={() => setShowCashRegisterLog(true)}
                >
                    <span className="cash-icon">◉</span>
                    {appSettingsState.menuSmall ? "" : btnTitle}
                </LogActiveButton>
            </>
        );
    }
    return null;
}
