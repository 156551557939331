import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

export default function LoadingProgressBar() {
    const themeContext = useContext(ThemeContext);
    const [progressCounter, setProgressCounter] = useState(0);
    useEffect(() => {
        if (progressCounter >= 99.9) return;
        const time = progressCounter / 2;
        let newValue = progressCounter + 0.1;
        if (progressCounter >= 80) newValue = progressCounter + 0.05;
        const interval = setInterval(() => setProgressCounter(newValue), time);
        return () => clearInterval(interval);
    });
    return (
        <div
            id="PROGRESS-BAR"
            style={{
                width: "100%",
                height: "4px",
                position: "absolute",
                zIndex: 1222,
                top: 0,
                left: 0,
                background: themeContext.palette.background,
            }}
        >
            <div
                style={{
                    width: progressCounter.toString() + "%",
                    background: "linear-gradient(90deg, rgb(48 79 254) 0%, rgb(252 70 208) 100%)",
                    height: "100%",
                }}
            ></div>
        </div>
    );
}
