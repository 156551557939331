import wahioFetchApi from "../fetchApi";
import { IEcommerce } from "../models/productCatalog";
import { productEndpoint } from "../restApiEndpoint";

export const getEcommerceList = (accountId: string): Promise<IEcommerce[]> => {
    return wahioFetchApi.get(productEndpoint.get.ecommerceByAccount(accountId)).then((res: any) => res.data);
};

export const getEcommerce = (ecommerceId: string): Promise<IEcommerce> => {
    return wahioFetchApi.get(productEndpoint.get.ecommerceById(ecommerceId)).then((res: any) => res.data);
};

export const updateCreateEcommerce = (ecommerce: IEcommerce): Promise<IEcommerce> => {
    return wahioFetchApi.post(productEndpoint.post.ecommerce, ecommerce).then((res: any) => res.data);
};
export const deleteEcommerce = (id: string): Promise<IEcommerce> => {
    return wahioFetchApi.delete(productEndpoint.delete.ecommerceId(id)).then((res: any) => res.data);
};

export const getCheckEcommerceName = (value: IEcommerce): Promise<{ status: boolean }> => {
    return wahioFetchApi.get(productEndpoint.get.validateExistCatalogName(value.name, value.id)).then((res: any) => res.data);
};
