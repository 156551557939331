import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { createOrUpdateWarehouse, setWarehouseToOrganization } from "../../../api/account/organizationApi";
import { getServiceMessageError } from "../../../api/index";
import { getUserPermissions } from "../../../api/models/accountUser";
import { IOrganizationWarehouse } from "../../../api/models/organizationTypes";
import { IWarehouse, Warehouse } from "../../../api/models/warehouse";
import { getAuthBody } from "../../../auth";
import { CustomCategoryContext } from "../../../store/contexts/CustomCategoryContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import PermissionDenied from "../../PermissionDenied";
import { DefaultButton, Flex, PrimaryButton } from "../../_controls";
import { InputTextFieldSimple } from "../../_controls/InputTextField";
import CheckBox from "../../_controls/inputs/CheckBox";
import messages from "./messages";

export interface WarehouseFormProps {
    onCreate: (warehouse: IWarehouse) => void;
    onCancel: () => void;
    warehouseUpdate?: IWarehouse;
}

const getWarehouseLoaded = () => {
    const auth = getAuthBody();
    return new Warehouse(auth.accountId, auth.accountUserId, "");
};

export default function WarehouseForm(props: WarehouseFormProps) {
    const intl = useIntl();
    const alert = useAlert();

    const { userState } = useContext(UserContext);
    const { customCategoryActions } = useContext(CustomCategoryContext);
    const { warehouseActions } = useWarehouseContext();
    const [currentWarehouse, setCurrentWarehouse] = useState(props.warehouseUpdate ? props.warehouseUpdate : getWarehouseLoaded());
    const [relateWarehouseWithStore, setRelateWarehouseWithStore] = useState(true);
    const { organizationState } = useContext(OrganizationContext);

    useEffect(() => {
        customCategoryActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const relatedWarehouseMutation = useMutation((body: IOrganizationWarehouse) => setWarehouseToOrganization(body), {
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const createOrUpdateWarehouseMutation = useMutation((warehouse: IWarehouse) => createOrUpdateWarehouse(warehouse), {
        onSuccess: async (data) => {
            if (props.warehouseUpdate) {
                alert.success(intl.formatMessage(messages.warehouseUpdatedSuccessfully));
            } else {
                alert.success(intl.formatMessage(messages.warehouseCreatedSuccessfully));
            }
            if (relateWarehouseWithStore) {
                await relatedWarehouseMutation.mutateAsync({
                    warehouseId: data.id as string,
                    organizationId: organizationState?.currentOrganization?.id,
                });
            }
            props.onCreate(data);
            warehouseActions.reload();
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const onCreateWarehouse = () => {
        if (currentWarehouse.name.length === 0 || currentWarehouse.address?.length === 0 || currentWarehouse.address === undefined) {
            alert.error("Faltan campos requeridos");
            return;
        }
        createOrUpdateWarehouseMutation.mutate(currentWarehouse);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentWarehouse({ ...currentWarehouse, [e.target.name]: e.target.value });
    };

    if (currentWarehouse.id && !getUserPermissions(userState.user).warehouse?.update) {
        return <PermissionDenied message="No tienes permisos para editar bodegas" />;
    }
    if (!currentWarehouse.id && !getUserPermissions(userState.user).warehouse?.create) {
        return <PermissionDenied message="No tienes permisos para crear bodegas" />;
    }

    return (
        <Flex column gap10 paddingTop={15} padding={20}>
            <InputTextFieldSimple
                name="name"
                value={currentWarehouse.name}
                onChange={handleChange}
                type="text"
                placeholder={intl.formatMessage(messages.name)}
                autocomplete="off"
            />
            <InputTextFieldSimple
                name="address"
                value={currentWarehouse.address}
                onChange={handleChange}
                type="text"
                placeholder={intl.formatMessage(messages.address)}
                autocomplete="off"
            />
            {!props.warehouseUpdate && (
                <CheckBox
                    className="mt-2"
                    name="isWarehouseSelected"
                    title={`Activar bodega para tienda ${organizationState?.currentOrganization?.name}`}
                    checked={relateWarehouseWithStore}
                    onChange={(e) => setRelateWarehouseWithStore(e.target.checked)}
                />
            )}

            {createOrUpdateWarehouseMutation.isLoading ? (
                <LoadingDualRing center />
            ) : (
                <Flex justifyEnd gap10 paddingTop={20} paddingBottom={20}>
                    <DefaultButton onClick={() => props.onCancel()}>Cancelar</DefaultButton>
                    <PrimaryButton onClick={() => onCreateWarehouse()}>Guardar</PrimaryButton>
                </Flex>
            )}
        </Flex>
    );
}
