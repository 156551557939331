import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { MessageAsReadData, chatCreateMessage, markMessagesAsRead, messagesQuerySearch } from "../../../api/chats/chatApi";
import { useWahioSocket } from "../../../api/socket";
import { useSocketOnRoomChange } from "../../../api/socket/sockedManager";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { messageAudio } from "../../../pages/AppContainer";
import { UserChat, useChatContext } from "../../../store/contexts/ChatContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import { Message, MessageSearchInput } from "../models";
import ChatConversationBase, { ChatContent, ChatContentPosition } from "./ChatConversationBase";

interface ConversationCardProps {
    item: UserChat;
}

export default function ChatMessageCard(props: ConversationCardProps) {
    const { removeUserToChat, chatState, setChatState } = useChatContext();
    const { userState } = useUserContext();
    const { appSettingsState } = useContext(AppSettingsContext);
    const wahioSocket = useWahioSocket();
    const alert = useAlert();
    const userGroupRef = useRef<string>("");
    const [userGroup, setUserGroup] = useState("");

    const [chatItems, setChatItems] = useState<Message[]>([]);
    const chatItemsRef = useRef<Message[]>([]);

    const [messageQuerySearch] = useState<MessageSearchInput>({
        userId1: userState.user?.id ?? "INVALID",
        userId2: props.item.userId,
        pageSize: 50,
        currentPage: 1,
    });

    const handleSearch = () => {
        return messagesQuerySearch(messageQuerySearch);
    };

    const messageQuery = useQuery(`QUERY_KEY-${props.item.userId}`, handleSearch, {
        refetchOnWindowFocus: false,
        enabled: !!userGroup,
        onSuccess: (data) => {
            setChatItems(data.items);
        },
    });

    const createMessageMutation = useMutation((data: Message) => chatCreateMessage(data), {
        onSuccess: (data) => {},
        onError: (data) => {
            alert.error(`No se puedo enviar el mensaje...`);
        },
    });
    const markMessageReadMutation = useMutation((data: MessageAsReadData) => markMessagesAsRead(data), {
        onSuccess: (data) => {
            let messagesCounter = chatState.messageStatusItems?.filter((x) => x.fromUserId !== props.item.userId);
            setChatState({
                ...chatState,
                messageStatusItems: messagesCounter,
            });
        },
        onError: (data) => {
            alert.error(`No se puedo enviar el mensaje...`);
        },
    });

    useEffect(() => {
        if (userGroup && userState.user) {
            markMessageReadMutation.mutate({ userGroup, toUserId: userState.user.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGroup]);

    useEffect(() => {
        chatItemsRef.current = chatItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatItems]);

    useSocketOnRoomChange((data) => {
        if (data && data.userGroup === userGroupRef.current) {
            setChatItems([data, ...chatItemsRef.current]);
            if (!appSettingsState.turnOfNotifications) messageAudio.play();
        }
    });

    useEffect(() => {
        //wahioSocket.roomSuscribe(wahioSocket.rooms.chatGroup(""));
        if (userState.user) {
            let userIdsArr = [props.item.userId, userState.user?.id ?? "INVALID_USER"];
            const userGroupResult = _.sortBy(userIdsArr).join("-");
            userGroupRef.current = userGroupResult;
            setUserGroup(userGroupResult);
            wahioSocket.roomSuscribe(wahioSocket.rooms.chatGroup(userGroupResult));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSendChatMessage = (text: string) => {
        if (userGroup && userState.user) {
            let message: Message = {
                id: "",
                userGroup: userGroup,
                description: text,
                fromUserId: userState.user.id,
                toUserId: props.item.userId,
                createdAt: customMoment().toISOString(),
                accountId: getAuthBody().accountId,
            };
            createMessageMutation.mutate(message);
            wahioSocket.sendChatMessage(message);
            setChatItems([message, ...chatItems]);
        }
    };

    return (
        <ChatConversationBase
            headerImage={props.item.userImage}
            headerTitle={props.item.userName}
            onRemoveChat={() => removeUserToChat(props.item.userId)}
            onClickSendMessage={onSendChatMessage}
            isLoading={messageQuery.isLoading}
        >
            {chatItems.map((item, index) => {
                let position: ChatContentPosition = item.fromUserId === userState.user?.id ? "right" : "left";
                let spaceTop = index > 0 && chatItems[index - 1].toUserId !== item.toUserId ? true : false;

                let hasMoreItems = chatItems.length > index + 2;

                return (
                    <ChatContent
                        key={index}
                        spaceTop={spaceTop}
                        position={position}
                        description={item.description}
                        createdAt={item.createdAt}
                        hasMoreItems={hasMoreItems}
                        nextCreatedAt={hasMoreItems ? chatItems[index + 1].createdAt : undefined}
                    />
                );
            })}
        </ChatConversationBase>
    );
}
