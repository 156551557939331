import { useMemo } from "react";
import { useIntl } from "react-intl";
import { RESTAURANT_ICONS } from "../../../components/Settings/AccountSettings";
import settingsMessages from "../../../components/Settings/messages";
import { PATHS } from "../../../constants/paths";
import { checkIntlMessage } from "../../../i18n/helper";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { useOrderDataSummaryContext } from "../../../store/contexts/OrderDataSummaryContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import {
    IMenuOption,
    getConfigChilds,
    getContactChilds,
    getPaymentChilds,
    getProductChilds,
    getSaleOrderChilds,
    getShipmentChilds,
} from "../menuConfig";
import messages from "../messages";

export interface MenuNotification {
    count: number;
    path: string;
}
export interface VerticalMenuOption {
    tourName?: string;
    icon?: string;
    childs?: IMenuOption[];
    link: string;
    startLink?: string;
    message: string;
    notifications?: MenuNotification[];
    hidden?: boolean;
}

export const TOUR_NAME_MENU_PRODUCT = "TOUR_NAME_MENU_PRODUCT";
export const TOUR_NAME_MENU_CONTACT = "TOUR_NAME_MENU_CONTACT";
export const TOUR_NAME_MENU_SALE = "TOUR_NAME_MENU_SALE";
export const TOUR_NAME_MENU_SHIPMENT = "TOUR_NAME_MENU_SHIPMENT";

export const useMenuOptions = () => {
    const { userPermission } = useUserContext();
    const { clientSettings } = useClientSettings();
    const orderLocalStorageContext = useOrderDataSummaryContext();

    const intl = useIntl();

    const options = useMemo(() => {
        const optionsList: VerticalMenuOption[] = [
            {
                icon: "fa-regular fa-house",
                message: intl.formatMessage(messages.itemHome),
                link: PATHS.home,
                startLink: undefined,
            },
            {
                tourName: TOUR_NAME_MENU_PRODUCT,
                icon: "fa-regular fa-boxes-stacked",
                message: intl.formatMessage(messages.products),
                link: PATHS.products,
                startLink: PATHS.productList,
                childs: getProductChilds(userPermission),
            },
            {
                tourName: TOUR_NAME_MENU_SALE,
                icon: "fa-regular fa-cart-shopping",
                message: intl.formatMessage(messages.saleOrders),
                link: PATHS.orders,
                startLink: PATHS.orderSalesList,
                childs: getSaleOrderChilds(userPermission, clientSettings),
                notifications: [
                    {
                        count: orderLocalStorageContext.orderLocalStorageState.unreadComments ?? 0,
                        path: PATHS.orderCommentList,
                    },
                ],
            },
            {
                tourName: TOUR_NAME_MENU_SHIPMENT,
                icon: "fa-regular fa-cart-shopping-fast",
                message: intl.formatMessage(messages.deliveryOrders),
                link: PATHS.shipments,
                startLink: PATHS.shipmentsList,
                childs: getShipmentChilds(userPermission),
                notifications: [
                    {
                        count: orderLocalStorageContext.orderLocalStorageState.queueCount ?? 0,
                        path: PATHS.orderShipmentsQueue,
                    },
                    {
                        count: orderLocalStorageContext.orderLocalStorageState.orderTempCount ?? 0,
                        path: PATHS.shipmentsListWhatsapp,
                    },
                ],
            },
            {
                tourName: TOUR_NAME_MENU_CONTACT,
                icon: "fa-regular fa-users",
                message: intl.formatMessage(messages.contacts),
                childs: getContactChilds(userPermission),
                link: PATHS.contacts,
                startLink: PATHS.contactList,
            },
            {
                icon: RESTAURANT_ICONS[clientSettings.commerceType ?? "restaurant"],
                message: checkIntlMessage(settingsMessages, clientSettings?.commerceType)
                    ? intl.formatMessage(settingsMessages[clientSettings?.commerceType ?? "restaurant"])
                    : intl.formatMessage(messages.itemRestaurant),
                link: PATHS.restaurant,
                hidden: !clientSettings?.enabledRestaurant,
            },
            {
                icon: "fa-regular fa-dollar-sign",
                message: intl.formatMessage(messages.payments),
                link: PATHS.payments,
                startLink: PATHS.paymentsAll,
                childs: getPaymentChilds(userPermission),
            },
            {
                icon: "fa-regular fa-gear",
                message: intl.formatMessage(messages.itemConfig),
                link: PATHS.config,
                startLink: PATHS.settingsAccountProfile,
                childs: getConfigChilds(userPermission),
            },
        ];
        return optionsList;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userPermission,
        clientSettings,
        orderLocalStorageContext.orderLocalStorageState.unreadComments,
        orderLocalStorageContext.orderLocalStorageState.queueCount,
        orderLocalStorageContext.orderLocalStorageState.orderTempCount,
    ]);

    return { options };
};
