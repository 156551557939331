import React, { createContext, useReducer, useEffect } from "react";
import { saveData, LocalStoreKeys } from "..";
import { ILoungeActions, useActions } from "../actions/lounge";
import { applyMiddleware } from "../middlewares/lounge";
import { loungeReducer, initState } from "../reducers/lounge";
import { ILoungeState } from "../../api/models/lounge";

interface IContextProps {
    loungeState: ILoungeState;
    loungeActions: ILoungeActions;
}

export const LoungeContext = createContext({} as IContextProps);

const LoungeContextProvider = (props: any) => {
    const [loungeState, dispatch] = useReducer(loungeReducer, initState);

    useEffect(() => {
        saveData(LocalStoreKeys.lounge, loungeState);
    }, [loungeState]);

    const loungeActions = useActions(loungeState, applyMiddleware(dispatch));

    return <LoungeContext.Provider value={{ loungeState, loungeActions }}>{props.children}</LoungeContext.Provider>;
};

export default LoungeContextProvider;
