import { Dispatch } from "react";
import { wahioFetch } from "../../api";
import { purchaseEndpoint } from "../../api/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/purchase";
import { PurchaseTypes, REQUEST_PURCHASES } from "../../api/models/purchase";

export const applyMiddleware = (dispatch: Dispatch<PurchaseTypes>) => (action: PurchaseTypes) => {
    switch (action.type) {
        case REQUEST_PURCHASES:
            dispatch(actionRequest(action.value));
            return wahioFetch.post(
                purchaseEndpoint.post.purchaseSearchQuery,
                action.value,
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
