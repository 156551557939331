import { Dispatch } from "react";
import { getAuthBody } from "../../auth";
import { wahioFetch } from "../../api";
import { localOrderEndpoint } from "../../api/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/orderChannel";
import { OrderChannelTypes, REQUEST_ORDER_CHANNELS } from "../../api/models/orderChannel";

export const applyMiddleware = (dispatch: Dispatch<OrderChannelTypes>) => (action: OrderChannelTypes) => {
    switch (action.type) {
        case REQUEST_ORDER_CHANNELS:
            dispatch(actionRequest());
            const authBody = getAuthBody();
            return wahioFetch.get(
                localOrderEndpoint.get.channelsByAccount(authBody.accountId),
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
