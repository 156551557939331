import styled from "styled-components";
import { TableCustom, TableCustomTr } from "../_controls/tables/styled";

export const InvoiceDesignCard = styled.div`
    padding: 0px;
    display: flex;
    position: relative;
    flex-direction: column;
    background: white;
    width: 100%;
    color: black;
    padding-right: 2px;
    min-height: 100%;
`;

export const InvoicePrintPreviewContainer = styled.div`
    [class*="wahioicon"] {
        color: ${(props) => props.theme.palette.primary};
        font-size: 30px;
        background: ${(props) => props.theme.palette.backgroundLight};
        padding: 14px;
        border-radius: 56px;
        animation: change-background 1.2s linear infinite;
    }

    @keyframes change-background {
        0% {
            background: ${(props) => props.theme.palette.background};
        }
        50% {
            background: ${(props) => props.theme.palette.primary}20;
        }
        100% {
            background: ${(props) => props.theme.palette.background};
        }
    }

    .body {
        background: ${(props) => props.theme.palette.background};
        padding: 20px;
        display: flex;
        margin: auto;
        max-width: 500px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        border-radius: 16px;
        gap: 20px;
        box-shadow: 1px 1px 20px ${(props) => props.theme.palette.shadowColor};
    }
`;

export const InvoiceDesignCardSmall = styled.div`
    padding: 0px;
    margin: 0px;
    display: flex;
    position: relative;
    flex-direction: column;
    background: white;
    width: 100%;
    color: black;
    min-height: 100%;
`;

export const headerHeight = 200;

export const CardBodyNormal = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 12px;
    box-sizing: border-box;
    padding-bottom: 30px;
    background-color: white;
    color: black;
    line-height: 1.2;

    .total-table {
        background-color: white;
        color: black;
        tr {
            td {
                padding: 0px;
            }
        }
    }
    .header {
        position: fixed;
        width: 100%;
        top: 0px;
    }
    .footer {
        position: fixed;
        background: white;
        bottom: 0px;
        width: 100%;
    }

    .footer,
    .footer-space {
        height: 220px;
    }

    .header,
    .header-space {
        height: ${headerHeight}px;
    }

    &.test-mode {
        gap: 20px;
        .footer,
        .footer-space {
            height: auto;
            position: initial;
        }

        .header,
        .header-space {
            height: auto;
            position: initial;
        }
    }

    .footer-subtitle {
        text-align: center;
        font-size: 12px;
        color: grey;
        padding-top: 15px;
        padding-bottom: 30px;
    }

    hr {
        height: 1px;
        background: black;
        width: 100%;
        border: 0px;
    }

    .grid3 {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 1fr;
        break-inside: avoid;
    }

    .sign-footer {
        display: flex;
        justify-content: space-between;

        .sign-space {
            min-width: 250px;
        }
        .qr-title {
            font-size: 12px;
            color: grey;
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }

    .grid-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .bold {
        font-weight: 500;
    }
    .min-width {
        min-width: 200px;
    }

    .border {
        border: 1px solid grey;
        border-radius: 10px;
        padding: 10px;
        color: black;
        background: white;
        overflow: hidden;
        &.observation {
            height: 30px;
            margin-top: 8px;
        }

        &.card,
        &.cardFooter {
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
            }
            .all-text {
                white-space: normal;
            }
        }
        &.cardFooter {
            height: 100px;
        }
        &.card {
            height: 100px;
        }
    }
    .card-plane-text {
        height: 40px;
        overflow: hidden;
    }
    .status-card {
        background: black;
        color: white;
        border-radius: 50px;
        padding: 2px 5px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 10px;
        width: fit-content;
    }

    .flexrow {
        display: flex;
        align-items: center;
    }

    .ml-2 {
        margin-left: 15px;
    }

    .FlexCol {
        display: flex;
        flex-direction: column;
    }
    .space-between {
        justify-content: space-between;
    }
    .flexcenter {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .center {
        text-align: center;
    }
    .img-container {
        width: auto;
        height: 50px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .mt-1 {
        margin-top: 10px;
    }
    .title {
        font-size: 15px;
        font-weight: 500;
        margin-top: 15px;
    }
    .bold {
        font-weight: 500;
    }
    .invoice-details {
        margin-top: 20px;
    }
`;

export const CardBodySmall = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    font-size: 12px;
    box-sizing: border-box;
    padding-bottom: 30px;
    background: white;
    color: black;
    line-height: 1.2;

    .footer-subtitle {
        text-align: center;
        font-size: 12px;
        color: black;
        padding-top: 15px;
        padding-bottom: 30px;
    }
    hr {
        height: 1px;
        background: black;
        width: 100%;
        border: 0px;
    }

    .status-card {
        background: black;
        color: white;
        border-radius: 50px;
        padding: 6px 10px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
    }

    table {
        font-size: 12px;
        background: white;
        color: black;
    }

    .bold {
        font-weight: 500;
    }

    .center {
        text-align: center;
    }

    .image-container {
        display: flex;
        margin-bottom: 15px;

        .image {
            height: 100px;
            border-radius: 15px;
            overflow: hidden;
            margin: auto;

            img {
                height: 100%;
            }
        }
    }

    .border {
        display: flex;
        flex-direction: column;
    }
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InvoiceTableNormal = styled.table`
    border-collapse: separate;
    border-radius: 10px;
    font-size: 12px;
    color: black;
    background: white;
    color: black;
    line-height: 1.4;
    page-break-inside: auto;
    page-break-after: auto;
    page-break-before: auto;

    .td-center {
        text-align: center;
    }
    .text-smallSerials {
        font-size: 10px;
    }
    th {
        padding: 8px;
    }
    td {
        padding: 8px;
        padding-top: 4px;
        padding-bottom: 4px;

        &.not-padding {
            padding: 0px;
        }
    }
    .table-header {
        display: table-header-group;
        th {
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
        }
        th:first-child {
            border-left: 1px solid grey;
            border-top-left-radius: 10px;
        }
        th:last-child {
            border-right: 1px solid grey;
            border-top-right-radius: 10px;
        }
    }

    tbody {
        border: solid black 1px;

        td,
        th {
            border-left: solid grey 1px;
            border-top: solid grey 1px;

            text-align: left;

            &.right {
                text-align: right;
            }
        }

        td {
            border: 0px;
            border-left: 1px solid grey;
        }
        th {
            border-top: none;
            font-weight: 500;
            border-bottom: 1px solid grey;
        }

        td:last-child {
            border-right: 1px solid grey;
        }

        tr:last-child {
            td {
                border-bottom: 1px solid grey;
            }
            td:first-child {
                border-bottom: 1px solid grey;
                border-bottom-left-radius: 10px;
            }
            td:last-child {
                border-bottom-right-radius: 10px;
            }
        }
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
`;

export const InvoiceTableSmall = styled.table`
    font-size: 12px;
    position: relative;
    color: black;
    .col {
        display: flex;
        flex-direction: column;
    }
    .td-center {
        text-align: center;
    }
    th {
        padding: 0px;
        text-align: left;
    }
    .right {
        text-align: right;
    }
    td {
        padding: 0px;
        padding-top: 4px;
        padding-bottom: 4px;

        &.not-padding {
            padding: 0px;
        }
    }
`;

export const TotalTable = styled(TableCustom)`
    margin-top: 20px;
`;

export const CustomTr = styled(TableCustomTr)`
    th,
    td {
        border: 0px;
        padding: 0px;
        padding-left: 4px;
        text-align: center;
        color: black;
        font-size: 12px;
    }
    .row-title {
        font-weight: 500;
        padding-top: 6px;
    }

    .right {
        text-align: right;
        padding-right: 0;
    }
    .left {
        padding-left: 0;
        text-align: left;
    }
`;

export const WahioMark = styled.div`
    display: flex;
    margin-top: 18px;
    align-items: center;
    img {
        width: 25px;
        height: 25px;
        margin-right: 15px;
    }
`;

export const StatusContainer = styled.div`
    background: #e6e6e6;
    padding: 6px 15px;
    margin-top: 14px;
    border-radius: 19px;
    text-align: center;
    width: fit-content;
`;
interface IFakeViewProps {
    large?: boolean;
}
export const FakeView = styled.div`
    display: flex;
    flex-direction: column;

    span {
        width: ${(props: IFakeViewProps) => (props.large ? "100%" : "50%")};
        height: 10px;
        background: #e6e6e6;
        margin-bottom: 4px;
        border-radius: 23px;
    }
`;

export const FormatTrakingNumberInvoiceSmall = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    .qr-view {
        padding: 6px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        display: flex;
    }
`;

export const InvoiceBillingContent = styled.div`
    padding: 0px;
    background: white;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    table {
        margin-top: 50px;
        background: white;
        color: black;
        line-height: 1.4;
        border-bottom: 1px dashed ${(props) => props.theme.palette.textLight};
    }
`;

export const InvoiceBillingBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px;
    color: black;

    .image {
        width: 150px;
        height: 90px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .customer-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 15px;
    }

    .invoice {
        font-size: 22px;
        font-weight: 500;
    }

    .font-small {
        font-size: 10px;
    }
    .description {
        width: 40%;
    }
    .details {
        width: 60%;
        justify-content: space-around;
    }
    .description-table {
        th {
            text-align: center;
            font-weight: 500;
            font-size: 13px;
        }
        td {
            text-align: center;
            padding: 5px 0px;
        }
        th:first-child {
            text-align: left;
        }
        td:first-child {
            text-align: left;
        }
        th:last-child {
            text-align: right;
        }
        td:last-child {
            text-align: right;
        }
    }
    .border-dashed {
        border-bottom: 1px dashed ${(props) => props.theme.palette.textLight};
    }
    .total {
        font-size: 18px;
        font-weight: 500;
    }
`;

export const FormatHalfPage = styled.div`
    background-color: white;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const FormatHalfPageContent = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    background: white;
    border-radius: 10px;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
`;

export const CardBodyHalfPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 11px;
    box-sizing: border-box;
    background-color: white;
    color: black;
    line-height: 0.5;

    .total-table {
        background-color: white;
        color: black;
        tr {
            td {
                padding: 0px;
                font-size: 11px;
            }
        }
    }
    .header {
        position: fixed;
        width: 100%;
        top: 0px;
    }
    .footer {
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .footer,
    .footer-space {
        height: 90px;
    }

    .header,
    .header-space {
        height: 120px;
    }

    .footer-subtitle {
        text-align: center;
        font-size: 10px;
        color: grey;
        padding-top: 15px;
        padding-bottom: 30px;
    }

    hr {
        height: 1px;
        background: black;
        width: 100%;
        border: 0px;
    }

    .grid3 {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 1fr;
        break-inside: avoid;
    }

    .sign-footer {
        display: flex;
        justify-content: space-between;

        .sign-space {
            min-width: 180px;
        }
        .qr-title {
            font-size: 12px;
            color: grey;
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }

    .grid-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .text-template {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        line-height: 0.8;
    }

    .card-plane-text {
        height: 40px;
        overflow: hidden;
    }
    .status-card {
        background: black;
        color: white;
        border-radius: 50px;
        padding: 3px 10px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.8rem;
    }

    .flexrow {
        display: flex;
        align-items: center;
    }

    .flexcenter {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .img-container {
        width: auto;
        height: 50px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .title {
        font-size: 15px;
        font-weight: 500;
        margin-top: 15px;
    }
    .invoice-details {
        margin-top: 20px;
    }
`;

export const TableInvoiceFormatHalfPage = styled.table`
    border-collapse: separate;
    border-radius: 10px;
    font-size: 12px;
    color: black;
    background: white;
    color: black;
    line-height: 0.5;
    page-break-inside: auto;
    page-break-after: auto;
    page-break-before: auto;

    .td-center {
        text-align: center;
    }
    .text-smallSerials {
        font-size: 10px;
    }
    th {
        padding: 8px;
    }
    td {
        padding: 8px;
        padding-top: 4px;
        padding-bottom: 4px;

        &.not-padding {
            padding: 0px;
        }
    }
    .table-header {
        display: table-header-group;
        th {
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
        }
        th:first-child {
            border-left: 1px solid grey;
            border-top-left-radius: 10px;
        }
        th:last-child {
            border-right: 1px solid grey;
            border-top-right-radius: 10px;
        }
    }

    tbody {
        border: solid black 1px;
        font-size: 11px;

        td,
        th {
            border-left: solid grey 1px;
            border-top: solid grey 1px;

            text-align: left;

            &.right {
                text-align: right;
            }
        }

        td {
            border: 0px;
            border-left: 1px solid grey;
        }
        th {
            border-top: none;
            font-weight: 500;
            border-bottom: 1px solid grey;
        }

        td:last-child {
            border-right: 1px solid grey;
        }

        tr:last-child {
            td {
                border-bottom: 1px solid grey;
            }
            td:first-child {
                border-bottom: 1px solid grey;
                border-bottom-left-radius: 10px;
            }
            td:last-child {
                border-bottom-right-radius: 10px;
            }
        }
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
`;
