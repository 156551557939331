import { defineMessages } from "react-intl";

const base = "components.excelImport";

export default defineMessages({
    downloadFileFrom: {
        id: `${base}.downloadFileFrom`,
        defaultMessage: "Descargar archivo de",
    },
    toContinueTheImport: {
        id: `${base}.toContinueTheImport`,
        defaultMessage: "para continuar con la importación",
    },
    dragAFileOrClickTheButtonToImport: {
        id: `${base}.dragAFileOrClickTheButtonToImport`,
        defaultMessage: "Arrastra un archivo o haz clic en el botón para importar",
    },
    importFile: {
        id: `${base}.importFile`,
        defaultMessage: "Importar Archivo",
    },
    import: {
        id: `${base}.import`,
        defaultMessage: "Importar",
    },
    someDataWasNotLoadedCorrectly: {
        id: `${base}.someDataWasNotLoadedCorrectly`,
        defaultMessage: "Algunos datos no se cargaron correctamente, este es el resumen:",
    },
    errorProcessingFile: {
        id: `${base}.errorProcessingFile`,
        defaultMessage: "Error al procesar el archivo, faltan columnas:",
    },
    theFileIsNotCorrect: {
        id: `${base}.theFileIsNotCorrect`,
        defaultMessage: "El archivo no es correcto",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    
});