import React, { createContext } from "react";
import { Order } from "../../api/models/order";
import { useLocalStorage } from "../../utils/hooks";
import { getEmptyOrder } from "../reducers/currentOrder";

interface OrderLocalContextProps {
    orders: Order[];
    onUpdateOrder: (index: number, order: Order) => void;
    onDeleteOrder: (index: number) => void;
    onAddOrder: (order: Order) => void;
    onUpdateAllOrders: (orders: Order[]) => void;
}

const OrderLocalContext = createContext({} as OrderLocalContextProps);

const getDefaultData = (): Order[] => {
    return [getEmptyOrder()];
};

const OrderLocalContextProvider = (props: any) => {
    const [localOrders, setLocalOrders] = useLocalStorage<Order[]>("order_list_local_app", getDefaultData());

    const onUpdateOrder = (index: number, order: Order) => {
        let orders = localOrders;
        orders[index] = order;
        setLocalOrders([...orders]);
    };

    const onDeleteOrder = (index: number) => {
        let orders = localOrders;
        orders.splice(index, 1);
        setLocalOrders([...orders]);
    };

    const onAddOrder = (order: Order) => {
        let orders = localOrders;
        orders.push(order);
        setLocalOrders([...orders]);
    };

    const onUpdateAllOrders = (orders: Order[]) => {
        setLocalOrders([...orders]);
    };

    return (
        <OrderLocalContext.Provider
            value={{
                orders: localOrders,
                onUpdateOrder,
                onDeleteOrder,
                onAddOrder,
                onUpdateAllOrders,
            }}
        >
            {props.children}
        </OrderLocalContext.Provider>
    );
};

export const useOrderLocalContext = () => {
    return React.useContext(OrderLocalContext);
};

export default OrderLocalContextProvider;
