import React, { useEffect, useState } from "react";
import TextBoxNumeral from "../TextBoxNumeral";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, TextField } from "../_controls";
import Modal from "./Modal";
import { SingleInputContainer } from "./styled";

interface IPropsInput {
    show: boolean;
    setShow: (value: boolean) => void;
    placeholder: string;
    value: string;
    title: string;
    onOkay: (value: string) => void;
    autofocus?: boolean;
    selectAllInputValue?: boolean;
    format?: "number" | "money";
}

function InputModal(props: IPropsInput) {
    const { show, setShow, value, placeholder, title, onOkay } = props;
    const [selectState, setSelectState] = useState(false);

    useEffect(() => {
        setSelectState(true);
    }, []);

    const [inputValue, setInputValue] = useState(value);
    useEffect(() => {
        setInputValue(value);
    }, [setShow, value]);
    const onChange = (e: any) => {
        setInputValue(e.target.value);
    };
    const handleOkay = (e: any) => {
        e.preventDefault();
        if (value !== inputValue) {
            onOkay(inputValue);
        }
        setShow(false);
    };
    const selectAllInputContent = (e: any) => {
        if (props.selectAllInputValue && selectState) e.target.select();
        setSelectState(false);
    };

    return (
        <>
            <Modal title={title} showHeader={true} setShow={setShow} show={show}>
                <SingleInputContainer>
                    <DefaultInput
                        onSelect={selectAllInputContent}
                        autoFocus={props.autofocus}
                        name="value"
                        value={inputValue}
                        type="text"
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                    <div className="button-option">
                        <PrimaryButton onClick={handleOkay} type="submit">
                            Aceptar
                        </PrimaryButton>
                    </div>
                </SingleInputContainer>
            </Modal>
        </>
    );
}

interface InputNumberModalProps {
    show: boolean;
    setShow: (value: boolean) => void;
    placeholder: string;
    value: number;
    title: string;
    onOkay: (value: number) => void;
    format?: "number" | "money";
}

export function InputNumberModal(props: InputNumberModalProps) {
    const { show, setShow, value, placeholder, title, onOkay } = props;
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [setShow, value]);
    const onChange = (e: any) => {
        setInputValue(e.target.value);
    };
    const handleOkay = (e: any) => {
        e.preventDefault();
        if (value !== inputValue) {
            onOkay(inputValue);
        }
        setShow(false);
    };

    return (
        <>
            <Modal setShow={setShow} show={show} useButtonClose ignoreOutsideClick>
                <Flex column gap={20} padding={20}>
                    <TextField bold fontSize={16}>
                        {title}
                    </TextField>
                    <TextBoxNumeral
                        w100
                        autoFocus
                        rounded
                        name="value"
                        value={inputValue}
                        type="text"
                        onChange={onChange}
                        placeholder={placeholder}
                        onNumberChange={setInputValue}
                        format={props.format ?? "number"}
                        style={{
                            fontSize: 20,
                            textAlign: "right",
                        }}
                    />
                    <Flex justifyEnd gap10>
                        <DefaultButton bgLight borderRadius={10} onClick={() => props.setShow(false)} type="button">
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton borderRadius={10} onClick={handleOkay} type="submit">
                            Confirmar Valor
                        </PrimaryButton>
                    </Flex>
                </Flex>
            </Modal>
        </>
    );
}

export default InputModal;
