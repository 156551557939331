import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { LocalStoreKeys, getStoredData, saveData } from "..";
import { getServiceMessageError } from "../../api";
import { getAccountUserAndOrganizations } from "../../api/account/accountUser";
import { stripeGetPaymentMethodList } from "../../api/account/stripeApi";
import { AccountPaymentMethod, AccountUser, getUserPermissions } from "../../api/models/accountUser";
import { IBasePermission } from "../../api/models/accountUserPermissions";
import { IEcommerce } from "../../api/models/productCatalog";
import { useAppSettingsContext } from "../../appSettings/AppSettingsContext";
import { loadDefaultNumeralSymbol } from "../../utils/numberFormat/moneyFormat";
import { useOrganizationContext } from "./OrganizationContext";
import { getEcommerceList } from "../../api/products/ecommerceApi";
import { getAuthBody } from "../../auth";

export interface IUserState {
    isUserFetching: boolean;
    isFetchDone?: boolean;
    user?: AccountUser;
    unauthorized: boolean;
    freeDays?: number;
}

const initStateBase: IUserState = {
    isUserFetching: false,
    user: undefined,
    unauthorized: false,
};

export const initState = getStoredData<IUserState>(initStateBase, LocalStoreKeys.accountUser);

interface IContextProps {
    userState: IUserState;
    userPermission: IBasePermission;
    setUserState: (value: IUserState) => void;
    userActions: IUserActions;
    methodList: AccountPaymentMethod[];
    refetchMethods: () => void;
    methodIsFetching: boolean;
    ecommerceList: IEcommerce[];
    setEcommerceList: (value: IEcommerce[]) => void;
}

export interface IUserActions {
    requestAccountUser: (accountUserId: string) => void;
    setUser: (value: AccountUser) => void;
}

export const UserContext = createContext({} as IContextProps);

const UserContextProvider = (props: any) => {
    const [accountId, setAccountId] = useState<string>("");

    const [userState, setUserState] = useState<IUserState>(initState);
    const { appSettingsState, appSettingsActions } = useAppSettingsContext();
    const organizationContext = useOrganizationContext();
    const appSettings = useAppSettingsContext();

    const [ecommerceList, setEcommerceList] = useState<IEcommerce[]>([]);

    const [methodList, setMethodList] = useState<AccountPaymentMethod[]>([]);
    const dateLastFetchRef = React.useRef<number>(-1);

    const userPermission = React.useMemo(() => {
        return getUserPermissions(userState.user);
    }, [userState.user]);

    useEffect(() => {
        if (userState.user?.accountId) {
            setAccountId(userState.user?.accountId);
        }
    }, [userState.user?.accountId]);

    const accountUserMutation = useMutation((id: string) => getAccountUserAndOrganizations(id), {
        onSuccess: (data) => {
            let user = data.accountUser;
            let currentOrganization = data.organizations.find((x) => x.id === appSettings.appSettingsState.defaultOrganizationId);
            if (currentOrganization) {
                appSettingsActions.setAllSettings({
                    ...appSettingsState,
                    defaultWarehouseId: currentOrganization?.ecommerceDefaultWarehouseId,
                });
            }
            organizationContext.setOrganizationState({
                ...organizationContext.organizationState,
                items: data.organizations,
                currentOrganization,
            });
            const currencySymbol = user.account?.clientSettings?.currencySymbol;
            if (currencySymbol) {
                loadDefaultNumeralSymbol(currencySymbol);
            }
            setUserState({ ...userState, user: user, isUserFetching: false, isFetchDone: true });
        },
        onError: (error: any) => {
            let unauthorized = false;
            if (error && error.response && error.response.status === 401) {
                unauthorized = true;
            } else {
                console.log(getServiceMessageError(error));
            }
            setUserState({ ...userState, user: undefined, isUserFetching: false, isFetchDone: true, unauthorized });
        },
    });

    const requestAccountUser = (accountUserId: string) => {
        setUserState({ ...userState, isUserFetching: true });
        accountUserMutation.mutate(accountUserId);
    };

    const methodListMutation = useMutation((accountId: string) => stripeGetPaymentMethodList(accountId), {
        onSuccess: (data) => {
            setMethodList(data);
        },
    });

    useEffect(() => {
        refetchMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);

    const refetchMethods = () => {
        if (accountId) {
            let diff = Date.now() - dateLastFetchRef.current;
            if (dateLastFetchRef.current !== -1 || diff < 10000) {
                return;
            }

            methodListMutation.reset();
            methodListMutation.mutate(accountId);
            dateLastFetchRef.current = Date.now();
        }
    };

    useEffect(() => {
        saveData(LocalStoreKeys.accountUser, userState);
    }, [userState]);

    return (
        <UserContext.Provider
            value={{
                userState,
                setUserState,
                userPermission: userPermission,
                userActions: {
                    requestAccountUser,
                    setUser: (value) => setUserState({ ...userState, user: value }),
                },
                methodList,
                refetchMethods,
                methodIsFetching: methodListMutation.isLoading,
                ecommerceList,
                setEcommerceList,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export const useEcommerceList = () => {
    const { ecommerceList, setEcommerceList } = useUserContext();

    const getEcommerceMutation = useMutation((accountId: string) => getEcommerceList(accountId), {
        onSuccess: (data) => {
            setEcommerceList(data);
        },
    });

    useEffect(() => {
        if (ecommerceList.length === 0) {
            getEcommerceMutation.mutate(getAuthBody().accountId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { ecommerceList, setEcommerceList, isLoading: getEcommerceMutation.isLoading };
};

export const useUserContext = () => {
    return useContext(UserContext);
};

export default UserContextProvider;
