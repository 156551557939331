import { defineMessages } from "react-intl";

const base = "components.settings.themeSettings";

export default defineMessages({
    light: {
        id: `${base}.light`,
        defaultMessage: "Claro",
    },
    dark: {
        id: `${base}.dark`,
        defaultMessage: "Oscuro",
    },
    theme: {
        id: `${base}.theme`,
        defaultMessage: "Tema",
    },
    device: {
        id: `${base}.device`,
        defaultMessage: "Dispositivo",
    },
});
