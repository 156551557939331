import React, { useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import EmptyResultViews from ".";
import { CloudImages } from "../../assets";

import PATHS from "../../constants/paths";
import WarehouseFormModal from "../Warehouses/WarehouseForm/WarehouseFormModal";
import { Flex } from "../_controls";
import { PrimaryButton } from "../_controls/buttons/index";
import messages from "./messages";

interface EmptyResultViewsProps {
    organizationId?: string;
    origin?: string;
}

export default function EmptyWarehouseView(props: EmptyResultViewsProps) {
    const intl = useIntl();
    const [openCreateWarehouse, setOpenCreateWarehouse] = useState(false);

    return (
        <>
            {openCreateWarehouse && (
                <WarehouseFormModal
                    onCancel={() => {}}
                    onCreate={(result) => {}}
                    show={openCreateWarehouse}
                    setShow={setOpenCreateWarehouse}
                />
            )}
            <EmptyResultViews
                image={CloudImages.warehouseView}
                title={"La tienda no contiene ninguna bodega"}
                description={"Debes seleccionar una bodega para continuar"}
                notPadding
            />
            <Flex alignCenter justifyCenter gap15 paddingTop={10}>
                <NavLink to={PATHS.storeProfileWarehouse(props.organizationId)}>
                    <PrimaryButton rounded className="text-bold">
                        {intl.formatMessage(messages.assignWarehouse)}
                    </PrimaryButton>
                </NavLink>
            </Flex>
        </>
    );
}
