import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { createWahioCloudFile } from "../../../api/account/wahioCloudApi";
import { WahioCloudItem } from "../../../api/models/wahioCloud";
import { getAuthBody } from "../../../auth";
import { useCloudContext } from "../../../store/contexts/CloudContext";
import { useOrganizationContext } from "../../../store/contexts/OrganizationContext";
import { bytesToSize } from "../../../utils";
import { compressImageAsync } from "../../../utils/images";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import Modal from "../../modals/Modal";
import { checkIfExtensionIsValid, getFileExtension, getWahioCloudItemType } from "../helper";
import { CloudItemCreateFilesContainer, FileCardStyle } from "./styled";

interface CloudItemCreateFilesProps {
    folderId?: string;
    files: File[];
    onClose: (results?: WahioCloudItem[]) => void;
    onItemCreated: (value: WahioCloudItem) => void;
    autoClose?: boolean;
}

export interface ItemResult {
    file: File;
    cloudItem?: WahioCloudItem;
    index: number;
    status: "success" | "error";
    errorMessage?: string;
}

export function CloudItemCreateFiles(props: CloudItemCreateFilesProps) {
    const { files } = props;
    const [fileRefs] = useState<any>(Array.from(files).map(() => React.createRef()));
    const { organizationState } = useOrganizationContext();
    const { addNewWahioCloudItem } = useCloudContext();
    const createMutation = useMutation((data: FormData) => createWahioCloudFile(data));
    const resultsRef = React.useRef<ItemResult[]>([]);
    const [results, setResults] = useState<ItemResult[]>([]);
    const [loadingIndex, setLoadingIndex] = useState(-1);

    const stopCreation = React.useRef(false);

    useEffect(() => {
        if (files && files.length > 0) {
            stopCreation.current = false;
            runCreateFiles(0);
        }
        return () => {
            stopCreation.current = true;
            createMutation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const runCreateFiles = async (index: number) => {
        const nextIndex = index + 1;
        const file = files[index];
        setLoadingIndex(index);
        createFile(file, index, (result) => {
            resultsRef.current = [...resultsRef.current, result];
            setResults(resultsRef.current);

            if (nextIndex <= files.length - 1) {
                runCreateFiles(nextIndex);
            } else {
                setLoadingIndex(-1);
                if (files.length === 0) {
                    props.onClose();
                }
                if (props.autoClose) {
                    const allItems = resultsRef.current.map((x) => x.cloudItem).filter((x): x is WahioCloudItem => !!x);
                    props.onClose(allItems);
                }
            }
        });
    };

    const getFormData = (file: File) => {
        const formData = new FormData();
        formData.append("files", file);
        formData.append("accountId", getAuthBody().accountId);
        formData.append("accountUserId", getAuthBody().accountUserId);
        formData.append("organizationId", organizationState.currentOrganization?.id ?? "");
        if (props.folderId) {
            formData.append("folderId", props.folderId);
        }
        return formData;
    };

    const createFile = async (file: File, index: number, onResult: (value: ItemResult) => void) => {
        if (stopCreation.current) return onResult({ status: "error", index, errorMessage: `Proceso Cancelado`, file: file });
        let extension = getFileExtension(file);
        let type = getWahioCloudItemType(extension);
        let isValidExtension = checkIfExtensionIsValid(extension);

        fileRefs[index]?.current?.scrollIntoView({
            behavior: "smooth", // para un desplazamiento suave
            block: "end",
        });

        if (!isValidExtension) {
            onResult({ status: "error", index, errorMessage: `Extension invalida: ${extension}`, file: file });
            return;
        }

        if (type === "image") {
            try {
                let fileResult = await compressImageAsync(file);
                if (fileResult) {
                    file = fileResult;
                }
            } catch (error) {
                onResult({ status: "error", index, errorMessage: `Error al comprimir la imagen`, file: file });
                return;
            }
        }

        const formData = getFormData(file);

        createMutation
            .mutateAsync(formData)
            .then((items) => {
                const cloudItem = items.length > 0 ? items[0] : undefined;
                if (cloudItem) {
                    props.onItemCreated(cloudItem);
                }
                onResult({
                    status: "success",
                    index,
                    file,
                    cloudItem: cloudItem,
                });
                if (cloudItem) {
                    addNewWahioCloudItem(cloudItem)
                }
            })
            .catch((err) => {
                onResult({
                    status: "error",
                    index,
                    file,
                    cloudItem: undefined,
                });
            });
    };

    return (
        <CloudItemCreateFilesContainer>
            <Flex column gap15>
                <Flex spaceBetween justifyEnd alignEnd w100>
                    <TextField bold>Subiendo Archivos: {results.length} de {files.length}</TextField>
                    {files.length > results.length ? (
                        <DefaultButton rounded onClick={() => props.onClose()}>
                            Cancelar
                        </DefaultButton>
                    ) : !props.autoClose ? (
                        <PrimaryButton rounded disabled={files.length > results.length} onClick={() => props.onClose()}>
                            Confirmar
                        </PrimaryButton>
                    ) : null}
                </Flex>
            </Flex>

            <Flex column gap10 className="container-items">
                {files &&
                    Array.from(files).map((item, index) => {
                        const isLoading = loadingIndex === index;
                        const itemResult = results.find((x) => x.index === index);
                        return (
                            <div ref={fileRefs[index]} key={index}>
                                <FileCard isLoading={isLoading} itemResult={itemResult} file={item} />
                            </div>
                        );
                    })}
            </Flex>
            {/* {loadingIndex >= 0 && */}
                <Flex className="panel-info">
                    <TextField bold small>No cierres el modal hasta que se terminen de subir los archivos.</TextField>
                </Flex>
            {/* } */}
        </CloudItemCreateFilesContainer>
    );
}
interface FileCardProps {
    file: File;
    itemResult?: ItemResult;
    isLoading: boolean;
}
const FileCard = (props: FileCardProps) => {
    const { file, itemResult } = props;

    let icon = "fa-regular fa-clock";
    if (itemResult?.status === "success") {
        icon = "fa-regular fa-check";
    } else if (itemResult?.status === "error") {
        icon = "fa-regular fa-triangle-exclamation";
    }

    return (
        <FileCardStyle className={props.itemResult?.status ?? ""}>
            <i className="fa-regular fa-file-image icon-type"></i>
            <Flex column>
                <TextField>{file.name}</TextField>
                <TextField small light>
                    {bytesToSize(file.size)}
                </TextField>
                {itemResult?.errorMessage && <TextField small>{itemResult.errorMessage}</TextField>}
            </Flex>
            <SquareButton className="btn-status">{props.isLoading ? <LoadingDualRing small /> : <i className={icon}></i>}</SquareButton>
        </FileCardStyle>
    );
};

export const CloudItemCreateFilesModal = (props: CloudItemCreateFilesProps) => {
    return (
        <Modal show={props.files.length > 0} setShow={() => {}}>
            <CloudItemCreateFiles {...props} />
        </Modal>
    );
};
