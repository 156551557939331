import React, { useState } from "react";
import { useIntl } from "react-intl";
import ProductCreateForm, { ProductCreateFormProps } from ".";
import { IProduct } from "../../../api/models/product";
import Modal, { IModalShow, ModalSize } from "../../modals/Modal";
import messages from "./messages";

interface ModalProductFormProps extends IModalShow, ProductCreateFormProps {
    onCreate: (product: IProduct) => void;
}

export default function ProductFormModal(props: ModalProductFormProps) {
    const [modalSize, setModalSize] = useState<ModalSize>("md");

    const onCreate = (product: IProduct) => {
        props.onCreate(product);
        props.setShow(false);
    };
    const intl = useIntl();

    return (
        <Modal
            sizeType={modalSize}
            overflowAuto={false}
            removeVerticalAlign={true}
            ignoreOutsideClick
            title={
                intl.formatMessage(props.updateProduct ? messages.editProduct : messages.createProduct) +
                `${props.updateProduct ? ": " + props.updateProduct.name : ""}`
            }
            useButtonClose
            useMobileView
            {...props}
        >
            <ProductCreateForm
                {...props}
                onCancel={() => props.setShow(false)}
                onCreate={onCreate}
                onVariantStage={(isActive) => setModalSize(isActive ? "lg" : "md")}
            />
        </Modal>
    );
}
