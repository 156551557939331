import { defineMessages } from "react-intl";

const base = "components.purchaseProfile";

export default defineMessages({
    order: {
        id: `${base}.order`,
        defaultMessage: "Orden",
    },
    void: {
        id: `${base}.void`,
        defaultMessage: "Anulado",
    },
    paid: {
        id: `${base}.paid`,
        defaultMessage: "Pagado",
    },
    credit: {
        id: `${base}.credit`,
        defaultMessage: "Crédito",
    },
    quotation: {
        id: `${base}.quotation`,
        defaultMessage: "Cotización",
    },
    open: {
        id: `${base}.open`,
        defaultMessage: "Order Abierta",
    },
    partial: {
        id: `${base}.partial`,
        defaultMessage: "Pago parcial",
    },
    queued: {
        id: `${base}.queued`,
        defaultMessage: "En cola",
    },
    draft: {
        id: `${base}.draft`,
        defaultMessage: "Borrador",
    },
    authorized: {
        id: `${base}.authorized`,
        defaultMessage: "Authorizado",
    },
    declined: {
        id: `${base}.declined`,
        defaultMessage: "Rechazada",
    },
    invalid: {
        id: `${base}.invalid`,
        defaultMessage: "Estado invalido",
    },
    status: {
        id: `${base}.status`,
        defaultMessage: "Estado",
    },
    in: {
        id: `${base}.in`,
        defaultMessage: "en",
    },
    generalCustomer: {
        id: `${base}.generalCustomer`,
        defaultMessage: "Cliente general",
    },
    supplier: {
        id: `${base}.supplier`,
        defaultMessage: "Proveedor",
    },
    warehouseDestiny: {
        id: `${base}.warehouseDestiny`,
        defaultMessage: "Bodega",
    },

    continueEdit: {
        id: `${base}.continueEdit`,
        defaultMessage: "Continuar editando",
    },
    createdAt: {
        id: `${base}.createdAt`,
        defaultMessage: "Creado",
    },
    customDate: {
        id: `${base}.customDate`,
        defaultMessage: "Fecha personalizada",
    },
    seller: {
        id: `${base}.seller`,
        defaultMessage: "Vendedor",
    },
    delivery: {
        id: `${base}.delivery`,
        defaultMessage: "Envío",
    },
    active: {
        id: `${base}.active`,
        defaultMessage: "Activo",
    },
    restaurant: {
        id: `${base}.restaurant`,
        defaultMessage: "Restaurante",
    },
    newPayment: {
        id: `${base}.newPayment`,
        defaultMessage: "Nuevo pago",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    accumulatedPayment: {
        id: `${base}.accumulatedPayment`,
        defaultMessage: "Pago acumulado",
    },
    remainingPayment: {
        id: `${base}.remainingPayment`,
        defaultMessage: "Pago restante",
    },
    payments: {
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },
    refunds: {
        id: `${base}.refunds`,
        defaultMessage: "Reembolsos",
    },
    statusHistory: {
        id: `${base}.statusHistory`,
        defaultMessage: "Historial estados",
    },
    editOrder: {
        id: `${base}.editOrder`,
        defaultMessage: "Editar pedido",
    },
    deleteOrder: {
        id: `${base}.deleteOrder`,
        defaultMessage: "Eliminar pedido",
    },
    printOrder: {
        id: `${base}.printOrder`,
        defaultMessage: "Imprimir pedido",
    },
    serialNumberAlreadyExists: {
        id: `${base}.serialNumberAlreadyExists`,
        defaultMessage: "El número de serial ya existe",
    },
    addPayment: {
        id: `${base}.addPayment`,
        defaultMessage: "Agregar pago",
    },
});
