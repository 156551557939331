import { defineMessages } from "react-intl";

const base = "api.wompi";

export default defineMessages({
    APPROVED: {
        id: `${base}.APPROVED`,
        defaultMessage: "Aprobado",
    },
    DECLINED: {
        id: `${base}.DECLINED`,
        defaultMessage: "Declinado",
    },
    PENDING: {
        id: `${base}.PENDING`,
        defaultMessage: "Pendiente",
    },
    BANCOLOMBIA_TRANSFER: {
        id: `${base}.BANCOLOMBIA_TRANSFER`,
        defaultMessage: "Transferencia Bancolombia",
    },
    CARD: {
        id: `${base}.CARD`,
        defaultMessage: "Tarjeta Deb/Cred",
    },
    BANCOLOMBIA_COLLECT: {
        id: `${base}.BANCOLOMBIA_COLLECT`,
        defaultMessage: "Efectivo/Corresponsal",
    },
    showDetails: {
        id: `${base}.showDetails`,
        defaultMessage: "Ver detalles",
    },
    hideDetails: {
        id: `${base}.hideDetails`,
        defaultMessage: "Ocultar detalles",
    },
});
