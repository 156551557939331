import React from "react";
import { QuickStartOption } from "../../api/models/accountUser";
import { TOUR_NAME_QUICK_ACTIONS } from "../QuickActionsMenu";
import { TOUR_NAME_MENU_PRODUCT } from "../../pages/AppContainer/AppVerticalMenu/MenuOptionsHook";
import { TOUR_NAME_CREATE_PRODUCT } from "../SearchTabTitle/SearchProductTabTitle";
import QuickStartContact from "./QuickStartContact";
import QuickStartCreateSale from "./QuickStartCreateSale";
import QuickStartCreateShipment from "./QuickStartCreateShipment";
import QuickStartProduct from "./QuickStartProduct";
import QuickStartPurchase from "./QuickStartPurchase";

export const PRODUCT_TARGET = "." + TOUR_NAME_MENU_PRODUCT;
export const CREATE_PRODUCT_TARGET = "." + TOUR_NAME_CREATE_PRODUCT;
export const QUICK_ACTIONS_TARGET = "." + TOUR_NAME_QUICK_ACTIONS;

export const defaultStyleOptions = {
    arrowColor: "#fff",
    backgroundColor: "#fff",
    beaconSize: 36,
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: "#1c33df",
    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
    textColor: "#333",
    width: undefined,
    zIndex: 100,
    borderRadius: 20,
};

export interface QuickStartOptionContent extends QuickStartOption {
    name: QuickStartOptionName;
    isComplete: boolean;
    content: React.ReactNode;
}

export type QuickStartOptionName = "createProduct" | "createContact" | "createSale" | "createOrder" | "createPurchase";

export const quickStartOptions: QuickStartOptionContent[] = [
    {
        name: "createProduct",
        isComplete: false,
        content: <QuickStartProduct />,
    },
    {
        name: "createContact",
        isComplete: false,
        content: <QuickStartContact />,
    },
    {
        name: "createSale",
        isComplete: false,
        content: <QuickStartCreateSale />,
    },
    {
        name: "createOrder",
        isComplete: false,
        content: <QuickStartCreateShipment />,
    },
    {
        name: "createPurchase",
        isComplete: false,
        content: <QuickStartPurchase />,
    },
];

interface CourseOption {
    category: string;
    items: CourseOptionItem[];
}

interface CourseOptionItem {
    title: string;
    link: string;
}

export const supportCourseOptions: CourseOption[] = [
    {
        category: "Ventas y pedidos",
        items: [
            {
                title: "Crear una Venta",
                link: "https://support.wahio.com/docs/como-crear-una-orden/",
            },
            {
                title: "Crear un Pedido",
                link: "https://support.wahio.com/docs/como-crear-un-pedido/",
            },
            {
                title: "Crear una Cotizaciones",
                link: "https://support.wahio.com/docs/como-crear-una-cotizacion/",
            },
            {
                title: "Hacer seguimiento a un pedido",
                link: "https://support.wahio.com/docs/como-hacer-seguimiento-a-un-pedido/",
            },
        ],
    },
    {
        category: "Productos",
        items: [
            {
                title: "Crear un producto",
                link: "https://support.wahio.com/docs/como-crear-un-producto/",
            },
            {
                title: "Crear un impuesto",
                link: "https://support.wahio.com/docs/como-crear-un-producto/",
            },
            {
                title: "Crear una bodega",
                link: "https://support.wahio.com/docs/como-crear-una-bodega/",
            },
        ],
    },
    {
        category: "Contactos",
        items: [
            {
                title: "Crear un contacto",
                link: "https://support.wahio.com/docs/como-crear-un-contacto/",
            },
            {
                title: "Crear un cliente",
                link: "https://support.wahio.com/docs/como-crear-un-cliente/",
            },
            {
                title: "Crear un proveedor",
                link: "https://support.wahio.com/docs/como-crear-un-proveedor/",
            },
        ],
    },
];
