import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { AccountUser, accountUserIsAdmin, getAccountUserFullName, getUserPermissions } from "../../../api/models/accountUser";
import { IOrderComment, Order } from "../../../api/models/order";
import { createOrderComment, deleteOrderComment } from "../../../api/orders/orderCommentApi";
import { useWahioSocket } from "../../../api/socket";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import TextareaChat from "../../Chats/ChatContainer/TextareaChat";
import DateTimeLabel from "../../DatePicker/DateTimeLabel";
import Dropdown from "../../Dropdown";
import { DefaultButton, Flex, TextField } from "../../_controls";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { OrderCommentInputBody, OrderCommentRowContainer } from "./styled";

var parse = require("html-react-parser");

interface OrderCommentRowProps {
    order: Order;
    comment: IOrderComment;
    onUpdate: (comment: IOrderComment) => void;
    onRemove: (comment: IOrderComment) => void;
    smallView?: boolean;
}

export default function OrderCommentFormRow(props: OrderCommentRowProps) {
    const { getUserById } = useContext(AccountUserContext);

    const user = getUserById(props.comment.accountUserId);
    return (
        <OrderCommentRowContainer className={`${props.smallView ? "hide-controls" : ""}`}>
            <div className="body">
                {!props.smallView && <FlexImageLetter width={35} image={user?.image} text={user?.firstName ?? "A"} />}
                <MessageView {...props} user={user} />
            </div>
        </OrderCommentRowContainer>
    );
}

interface MessageViewProps extends OrderCommentRowProps {
    user?: AccountUser;
}

const MessageView = (props: MessageViewProps) => {
    const { comment } = props;
    const [allowEdit, setAllowEdit] = useState(false);

    const { userState } = useContext(UserContext);
    const [showDeleteComment, setShowDeleteComment] = useState(false);
    const alert = useAlert();

    const wahioSocket = useWahioSocket();

    const permission = getUserPermissions(userState.user);

    const mutationComment = useMutation((data: IOrderComment) => createOrderComment(data), {
        onSuccess: (data) => {
            props.onUpdate(data);
            wahioSocket.sendOrderMessageEvent(props.order, data.description);
            setAllowEdit(false);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });
    const mutationDelComment = useMutation((id: string) => deleteOrderComment(id), {
        onSuccess: (data) => {
            props.onRemove(data);
            alert.info("Comentario eliminado");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const handleDeleteComment = () => {
        mutationDelComment.mutate(props.comment.id ?? "");
    };

    const handleSaveComment = (description: string) => {
        mutationComment.mutate({ ...comment, description });
    };

    if (!comment.description || allowEdit) {
        return (
            <OrderCommentInputBody>
                <TextareaChat
                    placeholder="Ingrese un comentario"
                    isLoading={mutationComment.isLoading}
                    onSendMessage={handleSaveComment}
                    initialValue={comment.description}
                    removePadding
                />
            </OrderCommentInputBody>
        );
    }

    const allowEditComments =
        (comment.accountUserId === userState.user?.id || accountUserIsAdmin(userState.user)) &&
        permission.order &&
        permission.order.allowCreateComments;

    return (
        <Flex alignCenter gap10 spaceBetween className="message-card">
            {showDeleteComment && (
                <ConfirmationModal
                    show={showDeleteComment}
                    setShow={setShowDeleteComment}
                    title="¿Estas seguro?"
                    description="Esta acción no se puede revertir"
                    onCancel={() => setShowDeleteComment(false)}
                    onOkay={() => handleDeleteComment()}
                />
            )}
            <Flex column>
                <Flex alignCenter flexWrap>
                    <TextField small light bold className="mr-1">
                        {getAccountUserFullName(props.user)}
                    </TextField>
                    <DateTimeLabel light small date={props.comment.dateCreated} className="hide-on-hover" />
                </Flex>
                <div className="content">{parse(comment.description)}</div>
            </Flex>

            {allowEditComments && (
                <Dropdown
                    withOutBackgroundButton
                    isLoading={mutationDelComment.isLoading || mutationComment.isLoading}
                    small
                    icon="fa-regular fa-ellipsis-vertical"
                    contentHorizontal="left"
                >
                    <Flex column padding={15} gap10>
                        <DefaultButton onClick={() => setAllowEdit(true)} className="dropdown-button">
                            <span className="fa-regular fa-pen-to-square"></span> Editar
                        </DefaultButton>
                        <DefaultButton onClick={() => setShowDeleteComment(true)} className="dropdown-button">
                            <span className="fa-regular fa-trash"></span> Eliminar
                        </DefaultButton>
                    </Flex>
                </Dropdown>
            )}
        </Flex>
    );
};
