import wahioFetchApi from "../fetchApi";
import { IProductSearchModel } from "../models/product";
import { CustomCategoryBulkIndex, CustomCategorySortedFilter, IProductCategory } from "../models/productCategory";
import { productEndpoint } from "../restApiEndpoint";

export const getCustomCategories = (product: IProductSearchModel): Promise<IProductCategory[]> => {
    return wahioFetchApi.post(productEndpoint.post.customCategorySearch, product).then((res: any) => res.data);
};

export const getCustomCategoriesSorted = (data: CustomCategorySortedFilter): Promise<IProductCategory[]> => {
    return wahioFetchApi.post(productEndpoint.post.customCategorySorted, data).then((res: any) => res.data);
};

export const putCustomCategoryBulkIndex = (data: CustomCategoryBulkIndex): Promise<{ count: number; message: string }> => {
    return wahioFetchApi.put(productEndpoint.put.customCategoryBulkIndex, data).then((res: any) => res.data);
};

export const patchCustomCategory = (body: { id: string; data: any }): Promise<IProductCategory> => {
    return wahioFetchApi.patch(productEndpoint.patch.customCategory(body.id), body.data).then((res: any) => res.data);
};

export const createUpdateCustomCategory = (category: IProductCategory): Promise<IProductCategory> => {
    return wahioFetchApi.post(productEndpoint.post.customCategory, category).then((res: any) => res.data);
};

export const deleteCustomCategory = (categoryId: string): Promise<IProductCategory> => {
    return wahioFetchApi.delete(productEndpoint.delete.customCategory(categoryId)).then((res: any) => res.data);
};