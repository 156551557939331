import styled from "styled-components";
import { device } from "../../../theme/themeProvider";

export const ShipmentProgressCard = styled.div`
    display: flex;
    width: 110%;
    left: -5%;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
    margin-top: 10px;
    min-width: 100%;
    overflow: auto;
`;

export const ShipmentStepContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    min-width: 110px;

    padding-top: 5px;
    .step-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    .step-date {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
        text-align: center;
    }
    .step-date-fixed {
        height: 17px;
        max-height: 17px;
        max-width: 99px;
    }
    .hidecolor {
        color: ${(props) => props.theme.palette.background};
    }
    .step-circle {
        width: 45px;
        height: 45px;
        border-radius: 100px;
        font-size: 20px;
        background: ${(props) => props.theme.palette.backgroundLight};
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.palette.textLight};
    }

    .time {
        background: ${(props) => props.theme.palette.primaryLight};
        position: absolute;
        left: 73%;

        @media ${device.md} {
            left: 72%;
        }

        top: 19px;
        color: ${(props) => props.theme.palette.primaryDark};
        z-index: 1;
        padding: 2px 6px;
        font-size: 11px;
        border-radius: 10px;
        font-weight: 500;
        white-space: nowrap;
    }

    :hover {
        .step-circle {
            background: ${(props) => props.theme.palette.background};
            color: ${(props) => props.theme.palette.text};
            box-shadow: 1px 2px 10px ${(props) => props.theme.palette.shadowColor};
            cursor: pointer;
        }
    }

    &.active {
        .step-circle {
            background: ${(props) => props.theme.palette.primaryLight};
            color: ${(props) => props.theme.palette.primaryDark};
        }
        .step-title {
            margin-top: 8px;
            font-size: 13px;
            color: ${(props) => props.theme.palette.primaryDark};
            font-weight: 500;
        }

        :hover {
            .step-circle {
                background: ${(props) => props.theme.palette.primaryLight};
                color: ${(props) => props.theme.palette.primaryDark};
                box-shadow: 0px 0px 0px ${(props) => props.theme.palette.shadowColor};
                cursor: default;
            }
        }
    }

    .step-title {
        margin-top: 8px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    .step-line {
        height: 2px;
        width: 76%;
        background: ${(props) => props.theme.palette.backgroundDark};
        position: absolute;
        left: 62%;
        top: 26px;

        width: 84%;
        left: 58%;

        &.line-active {
            background: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const ShipmentStatusVerticalStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
export const StatusVerticalCard = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    color: ${(props) => props.theme.palette.textLight};

    .btn-move {
        display: none;
    }
    .left-content {
        margin-left: auto;
    }

    &.inactive {
        &:hover {
            .status-icon {
                background: ${(props) => props.theme.palette.background};
                color: ${(props) => props.theme.palette.text};
                cursor: pointer;
                box-shadow: 1px 1px 10px ${(props) => props.theme.palette.shadowColor};
            }
            .btn-move {
                display: flex;
            }
        }
    }

    .status-icon {
        width: 35px;
        height: 35px;
        border-radius: 100px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => props.theme.palette.backgroundLight};
        position: relative;

        &::after {
            content: " ";
            position: absolute;
            width: 2px;
            height: 20px;
            background: ${(props) => props.theme.palette.backgroundLight};
            bottom: -20px;
        }
    }

    &:last-child {
        .status-icon {
            &::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 0px;
            }
        }
    }

    &.active {
        opacity: 1;

        .status-title {
            color: ${(props) => props.theme.palette.primary};
            font-weight: 500;
        }
        .status-icon {
            background: ${(props) => props.theme.palette.primary}20;
            color: ${(props) => props.theme.palette.primary};

            &.show-line {
                &::after {
                    background: ${(props) => props.theme.palette.primary};
                }
            }
        }
    }
`;
