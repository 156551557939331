import React from "react";
import styled from "styled-components";
import { getColorValue } from "../../../constants/colors";
import { IAccountPermission } from "../../../api/models/accountUserPermissions";

interface UserTypeLabelProps {
    accountPermission?: IAccountPermission;
    className?: string;
    isAdmin: boolean;
}

const CustomLabel = styled.div`
    border-radius: 20px;
    display: block;
    font-weight: 500;
    width: fit-content;
    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.textLight};
    padding: 1px 7px;
    font-size: 13px;
`;

export default function UserTypeLabel(props: UserTypeLabelProps) {
    const { accountPermission, className } = props;
    if (props.isAdmin) {
        return (
            <CustomLabel className={`${className}`} style={{ background: "#ff980040", color: "#ff9800" }}>
                Admin Principal
            </CustomLabel>
        );
    }
    if (accountPermission) {
        return (
            <CustomLabel
                className={`${className}`}
                style={{ background: getColorValue(accountPermission.color) + "30", color: getColorValue(accountPermission.color) }}
            >
                {accountPermission?.name ?? "Sin Rol"}
            </CustomLabel>
        );
    }
    return null;
}
