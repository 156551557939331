import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { WahioCloudFolder, WahioCloudItem, WahioCloudSearchFolderModel, WahioCloudSearchModel, WahioCloudSummaryResponse } from "../models/wahioCloud";
import { accountEndpoint } from "../restApiEndpoint";

export const getWahioCloudSummary = (accountId: string): Promise<WahioCloudSummaryResponse> => {
    return wahioFetchApi.get(accountEndpoint.get.wahioCloudSummary(accountId)).then((res: any) => res.data);
};

export const getWahioCloudItemsQuery = (data: WahioCloudSearchModel): Promise<IPaginationResponse<WahioCloudItem>> => {
    return wahioFetchApi.post(accountEndpoint.post.wahioCloudQuerySearch, data).then((res: any) => res.data);
};

export const deleteWahioCloudFiles = (data: WahioCloudItem[]): Promise<WahioCloudItem[]> => {
    return wahioFetchApi.post(accountEndpoint.post.deleteWahioCloudFiles, data).then((res: any) => res.data);
};

export const updateWahioCloudFile = (data: WahioCloudItem): Promise<WahioCloudItem> => {
    return wahioFetchApi.put(accountEndpoint.put.updateWahioCloudFile, data).then((res: any) => res.data);
}
export const createWahioCloudFile = (data: FormData): Promise<WahioCloudItem[]> => {
    return wahioFetchApi.post(accountEndpoint.post.uploadWahioCloudFiles, data).then((res: any) => res.data);
}

export const postCreateUpdateWahioFolder = (data: WahioCloudFolder): Promise<WahioCloudFolder> => {
    return wahioFetchApi.post(accountEndpoint.post.createWahioFolder, data).then((res: any) => res.data)};

export const deleteWahioCloudFolders = (data: WahioCloudFolder[]): Promise<WahioCloudFolder[]> => {
    return wahioFetchApi.post(accountEndpoint.post.deleteWahioCloudFolders, data).then((res: any) => res.data);};

export const getWahioCloudFoldersQuery = (data: WahioCloudSearchFolderModel): Promise<IPaginationResponse<WahioCloudFolder>> => {
    return wahioFetchApi.post(accountEndpoint.post.wahioCloudFolderQuerySearch, data).then((res: any) => res.data);};

