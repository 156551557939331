import styled from "styled-components";

export const CalendarGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
`;

export const MonthContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 900px;
    overflow: auto;
    padding-left: 10px;
    box-sizing: border-box;

    .month-week {
        display: flex;
        width: 100%;
        height: 100%;

        &.week-header {
            height: 40px;
        }
    }

    @media (max-width: 768px) {
        padding-left: 0;
    }
`;

export const MonthDayView = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    &.day-header {
        border: 0;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-top: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }

    :first-child {
        border-left: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const WeekContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

export const WeekDayView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    &.small-column {
        max-width: 55px;
    }

    .week-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${(props) => props.theme.palette.background};
        position: sticky;
        top: 0px;
        min-height: 45px;
        justify-content: center;
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }

    .day-column {
        display: flex;
        flex-direction: column;
    }
`;

export const HourBlockContainer = styled.div`
    display: flex;
    gap: 15px;
    min-height: 50px;
    height: ${(props: { autoHeight?: boolean }) => (props.autoHeight ? "auto" : "50px")};
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .time-line {
        padding-left: 10px;
        padding-top: 15px;
        white-space: nowrap;
    }
`;

export const EventBlockContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 5px;

    .event-line {
        display: grid;
        width: 100%;
    }
    .event-line-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        width: 100%;
    }
`;

export const EventLine = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 13px;
    overflow: hidden;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    background: ${(props) => props.theme.palette.backgroundLight};
    border-radius: 8px;
    padding: 2px 5px;
    padding-left: 10px;
    background: ${(props) => props.color}20;
    font-weight: 500;

    .line-time {
        font-size: 12px;
    }
    justify-content: center;
    position: relative;

    .event-title {
        color: ${(props) => props.color};
    }

    &::after {
        content: " ";
        position: absolute;
        background: ${(props) => props.color};
        left: 0;
        width: 4px;
        height: 65%;
        border-radius: 0 8px 8px 0;
    }
`;

export const CreateEventContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;

    .input-title {
        font-weight: 600;
        font-size: 16px;
        border: 0;
        background: transparent;
        border-bottom: 2px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 0px;
    }
`;

export const CalendarHeader = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
`;
