import React from "react";
import { TransactionAccountStateProps } from ".";
import { formatMoney } from "../../../../utils";
import PaymentsForm from "../../../Payments/PaymentsForm";
import { Flex } from "../../../_controls";
import { Table } from "../../../_controls/tables/styled";
import { TransactionAccountTabBody } from "../styled";

export default function Payments(props: TransactionAccountStateProps) {
    const { body, setBody } = props;

    return (
        <TransactionAccountTabBody>
            <Flex gap={15} column>
                <Flex column>
                    <Table tdPadding={5} cellPadding={0} cellSpacing={0} tdPaddingRight={15} className="fist-column-light ">
                        <tbody>
                            <tr>
                                <td className="min-width">Pagado</td>
                                <td>{formatMoney(body.totalPaid)}</td>
                            </tr>
                            <tr>
                                <td>Restante</td>
                                <td>{formatMoney(body.totalAmount - body.totalPaid)}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{formatMoney(body.totalAmount)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Flex>

                <PaymentsForm
                    payments={body.payments ?? []}
                    onChange={(value) => {
                        setBody({ ...body, payments: value });
                    }}
                    uniqueMethod={false}
                    totalAmount={body.totalAmount}
                    blockExistingPayments
                    showAddPaymentButton
                />
            </Flex>
        </TransactionAccountTabBody>
    );
}
