import React from "react";
import Barcode from "react-barcode";

interface BarcodeViewProps {
    value: string;
    height?: number;
    hiddeValue?: boolean;
}

export default function BarcodeView(props: BarcodeViewProps) {
    return <Barcode value={props.value} height={props.height} />;
}
