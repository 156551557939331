import React from "react";
import { OrderItemPreparationStatus } from "../../api/models/order";
import { PreparationStatusLine } from "./styled";
import messages from "./messages";
import { useIntl } from "react-intl";

const icons = {
    waiting: "fa-regular fa-clock",
    inPreparation: "fa-regular fa-utensils",
    ready: "wahioicon-check-circle",
};

export default function PreparationStatusIcon({ status }: { status: OrderItemPreparationStatus }) {
    const intl = useIntl();
    return (
        <PreparationStatusLine className={`preparation-status ${status}`} title={intl.formatMessage(messages[status])}>
            <span className={icons[status]}></span>
        </PreparationStatusLine>
    );
}
