import React, { useState } from "react";
import { useIntl } from "react-intl";
import { wahioFetch } from "../../../api";
import { IBasePermission } from "../../../api/models/accountUserPermissions";
import { IPurchasePayment } from "../../../api/models/orderPayment";
import { IPurchase } from "../../../api/models/purchase";
import { purchaseEndpoint } from "../../../api/restApiEndpoint";
import { formatMoney } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex, TextField } from "../../_controls";
import ContainerCollapse from "../../_controls/containers/ContainerCollapse";
import { ProgressBarValue } from "../../_controls/progressBars/ProgressBar";
import { Table } from "../../_controls/tables/styled";
import PurchaseCommentsSummary from "../PurchaseComments/PurchaseCommentsSummary";
import PurchasePayments from "./PurchasePayments";
import SinglePaymentModal from "./SinglePaymentModal";
import { NavigationGroupName } from "./menuHelper";
import messages from "./messages";
import { ContainerBody, ContainerButtonReceive } from "./styled";

interface SummaryTabsProps {
    currentOrder: IPurchase;
    showSimpleView?: boolean;
    onUpdateOrder: (value: IPurchase) => void;
    permission: IBasePermission;
    setChangeTabKey: (value: NavigationGroupName) => void;
}

export const SummaryTabView = (props: SummaryTabsProps) => {
    const { currentOrder, permission } = props;
    const [openSinglePayment, setOpenSinglePayment] = useState(false);
    const [singlePayResult, setSinglePayResult] = useState({
        isFetching: false,
        isError: false,
    });
    const alert = useAlert();
    const intl = useIntl();

    const getOrderReceivedStatus = (purchase: IPurchase) => {
        const { totalQuantity, totalQuantityReceived } = purchase;
        if (totalQuantity === totalQuantityReceived) return "Completado";
        else if (totalQuantityReceived > 0 && totalQuantityReceived < totalQuantity) return "Entrega parcial";
        else return "Sin entregar";
    };

    const onAddPayment = (value: IPurchasePayment[]) => {
        setSinglePayResult({ isFetching: true, isError: false });
        let payments: IPurchasePayment[] = currentOrder.payments ?? [];
        let newPayments = value;

        newPayments.forEach((payment) => {
            payment.purchaseId = currentOrder.id;
            payments.push(payment);
        });

        let purchaseToUpdate = { ...currentOrder, payments };
        wahioFetch.post(
            purchaseEndpoint.post.purchaseAll,
            { ...purchaseToUpdate },
            (success) => {
                let purchaseResult: IPurchase = { ...success.data, items: currentOrder.items };
                props.onUpdateOrder(purchaseResult);
                setSinglePayResult({ isFetching: false, isError: false });
                alert.success("Pago realizado con éxito");
            },
            (error) => {
                setSinglePayResult({ isFetching: false, isError: true });
                alert.error("Error al intentar crear el pago");
            }
        );
    };

    return (
        <>
            {currentOrder.status !== "void" && currentOrder.status !== "draft" && (
                <>
                    <ContainerBody className="container-body">
                        <Flex column gap={20}>
                            <TextField bold>Resumen de productos</TextField>
                            <Table
                                cellPadding={0}
                                cellSpacing={0}
                                tdPadding={2}
                                tdPaddingRight={15}
                                hideBorde
                                className="fist-column-light"
                            >
                                <tbody>
                                    <tr>
                                        <td className="min-width">Estado</td>
                                        <td>{getOrderReceivedStatus(currentOrder)}</td>
                                    </tr>
                                    <tr>
                                        <td>Items</td>
                                        <td>{currentOrder.itemsCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="min-width">Cantidad</td>
                                        <td>{currentOrder.itemsQuantityCount}</td>
                                    </tr>
                                    {currentOrder.hasReturns && (
                                        <>
                                            <tr>
                                                <td className="min-width">Devoluciones</td>
                                                <td>{currentOrder.totalQuantityReturned}</td>
                                            </tr>
                                            <tr>
                                                <td className="min-width">Nueva Cantidad</td>
                                                <td>{currentOrder.itemsQuantityCount - currentOrder.totalQuantityReturned}</td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>

                            <Flex gap={15} flexWrap spaceBetween>
                                <ProgressBarValue
                                    maxWidth
                                    title="Recibido"
                                    limitValue={currentOrder.totalQuantity - currentOrder.totalQuantityReturned}
                                    currentValue={currentOrder.totalQuantityReceived - currentOrder.totalQuantityReturned}
                                    w100
                                />
                                {currentOrder.totalQuantityReceived !== currentOrder.totalQuantity && (
                                    <ContainerButtonReceive>
                                        <DefaultButton
                                            className="btn-receive"
                                            borderRadius={10}
                                            bgLight
                                            onClick={() => props.setChangeTabKey(NavigationGroupName.Receive)}
                                        >
                                            <i className="fa-regular fa-arrow-right" />
                                            <TextField>Recibir</TextField>
                                        </DefaultButton>
                                    </ContainerButtonReceive>
                                )}
                            </Flex>
                        </Flex>
                    </ContainerBody>

                    <ContainerCollapse title="Pagos" initialStateOpen>
                        <Flex column gap={20}>
                            {(currentOrder.status === "partial" || currentOrder.status === "credit") && (
                                <Flex gap15 flexWrap spaceBetween>
                                    {openSinglePayment && (
                                        <SinglePaymentModal
                                            onAddPayment={onAddPayment}
                                            purchase={currentOrder}
                                            show={openSinglePayment}
                                            setShow={setOpenSinglePayment}
                                        />
                                    )}

                                    <Flex gap15>
                                        <Flex column>
                                            <TextField light>{intl.formatMessage(messages.accumulatedPayment)}</TextField>
                                            <TextField>{formatMoney(currentOrder.totalPayment)}</TextField>
                                        </Flex>
                                        <Flex column>
                                            <TextField light>{intl.formatMessage(messages.remainingPayment)}</TextField>
                                            <TextField>{formatMoney(currentOrder.totalAmount - currentOrder.totalPayment)}</TextField>
                                        </Flex>
                                    </Flex>

                                    <Flex>
                                        <DefaultButton
                                            borderRadius={10}
                                            disabled={!permission.purchase?.allowCreatePayments || singlePayResult.isFetching}
                                            onClick={() => setOpenSinglePayment(true)}
                                            bgLight
                                        >
                                            <span className="wahioicon-plus"></span> {intl.formatMessage(messages.newPayment)}
                                            {singlePayResult.isFetching && <LoadingDualRing small={true} />}
                                        </DefaultButton>
                                        {!permission.purchase?.allowCreatePayments && (
                                            <div className="ml-1">
                                                <span className="wahioicon-lock mr-1"></span>No tienes permisos para agregar pagos
                                            </div>
                                        )}
                                    </Flex>
                                </Flex>
                            )}
                            {currentOrder.payments && currentOrder.payments.length > 0 && (
                                <PurchasePayments purchase={currentOrder} onUpdateOrder={props.onUpdateOrder} />
                            )}
                        </Flex>
                    </ContainerCollapse>
                </>
            )}
            <ContainerCollapse>
                <PurchaseCommentsSummary purchase={currentOrder} onUpdatePurchase={props.onUpdateOrder} />
            </ContainerCollapse>
        </>
    );
};
