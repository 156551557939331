import React from "react";
import { OrderChangeProps } from ".";
import { OrderFormOrigin } from "..";
import { DeliveryOrder } from "../../../../api/models/deliveryOrder";
import { OrderStatus } from "../../../../api/models/orderBase";
import { getAuthBody } from "../../../../auth";
import Dropdown from "../../../Dropdown";
import { DefaultButton, Flex, TextField } from "../../../_controls";
import { DropdownOrderTypeStyle } from "../styled";

interface OrderTypeDropdownProps extends OrderChangeProps {
    origin?: OrderFormOrigin;
}

interface OrderObjectTypeItem {
    title: string;
    icon: string;
}

interface ObjectOrderType {
    sale: OrderObjectTypeItem;
    shipment: OrderObjectTypeItem;
    quotation: OrderObjectTypeItem;
}

const orderTypeObj: ObjectOrderType = {
    sale: { title: "Venta", icon: "fa-regular fa-bag-shopping" },
    shipment: { title: "Pedido", icon: "fa-regular fa-truck" },
    quotation: { title: "Cotización", icon: "fa-regular fa-file-lines" },
};

const OrderTypeSelector = (props: OrderTypeDropdownProps) => {
    const convertOrder = (status?: OrderStatus) => {
        let newOrder = { ...props.order };
        newOrder.orderTypeTemp = "sale";
        if (status === "quotation") {
            newOrder.orderTypeTemp = "quotation";
        }
        newOrder.deliveryOrder = undefined;
        newOrder.isDelivery = undefined;
        newOrder.status = status ?? "partial";

        props.onOrderChange(newOrder);
    };

    const newOrderShipment = () => {
        let newOrder = { ...props.order };
        newOrder.isDelivery = true;
        newOrder.orderTypeTemp = "shipment";
        if (!newOrder.deliveryOrder) {
            newOrder.deliveryOrder = new DeliveryOrder(getAuthBody().accountId, "");
        }
        newOrder.status = "queued";
        props.onOrderChange(newOrder);
    };

    const onClick = (key: string) => {
        if (key === "shipment") {
            newOrderShipment();
        } else if (key === "quotation") {
            convertOrder("quotation");
        } else {
            convertOrder();
        }
    };

    const obj = orderTypeObj[props.order.orderTypeTemp ?? "sale"];

    if (props.order.id) {
        return <TextField bold600>Editar: #{props.order.number}</TextField>;
    }

    if (props.origin === "restaurant") {
        return <TextField bold600>Nueva Mesa</TextField>;
    }

    return (
        <DropdownOrderTypeStyle className={props.order.orderTypeTemp ?? "sale"}>
            <Dropdown
                tourName="ORDER_TYPE"
                disabled={!!props.order.id}
                title={obj.title ?? "Order"}
                icon={"fa-regular fa-angle-down"}
                iconPosition="left"
            >
                <Flex column gap10 padding={10}>
                    {Object.keys(orderTypeObj).map((key, index) => {
                        return (
                            <DefaultButton key={index} className="dropdown-button" onClick={() => onClick(key)}>
                                <span className={(orderTypeObj as any)[key].icon}></span> {(orderTypeObj as any)[key].title}
                            </DefaultButton>
                        );
                    })}
                </Flex>
            </Dropdown>
        </DropdownOrderTypeStyle>
    );
};

export default OrderTypeSelector;
