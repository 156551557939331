import React from "react";
import Modal, { IModalShow } from "./Modal";

interface PDFViewModalProps extends IModalShow {
    url?: string;
    base64?: string;
}

export default function PDFViewModal(props: PDFViewModalProps) {
    return (
        <Modal {...props} id="input-modal-pdf" sizeType="lg" title={"Preview"} showHeader={true}>
            {props.base64 && <embed style={{ minHeight: "calc(100vh - 113px)", width: "100%" }} src={`data:application/pdf;base64,${props.base64}`} />}
            {props.url && (
                <object style={{ minHeight: "calc(100vh - 113px)", width: "100%" }} data={props.url} type="application/pdf">
                    <p>
                        <a href={props.url}>PDF</a>
                    </p>
                </object>
            )}
        </Modal>
    );
}
