import { defineMessages } from "react-intl";

const base = "components.account.billing";

export default defineMessages({
    generationDate: {
        id: `${base}.generationDate`,
        defaultMessage: "Fecha de generación",
    },
    number: {
        id: `${base}.number`,
        defaultMessage: "Número",
    },
    description: {
        id: `${base}.description`,
        defaultMessage: "Descripción",
    },
    billingPeriod: {
        id: `${base}.billingPeriod`,
        defaultMessage: "Periodo de facturación",
    },
    dueDate: {
        id: `${base}.dueDate`,
        defaultMessage: "Fecha de vencimiento",
    },
});
