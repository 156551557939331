import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
`;

export const ContactTypeLabel = styled.div`
    padding: 2px 10px;
    background: ${(props) => props.theme.palette.backgroundLight};
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
`;

export const TextLinkStyle = styled.span`
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const DetailRow = styled.div`
    display: flex;

    p {
        margin: 0px;
    }
    span {
        color: ${(props) => props.theme.palette.textLight};
    }

    .item {
        margin-left: 25px;
    }

    table {
        margin-left: 23px;
        td {
            color: ${(props) => props.theme.palette.text};
        }
        .label {
            width: 1%;
            white-space: nowrap;
            padding-right: 7px;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const OrderLinkCardStyle = styled.div`
    padding: 10px 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 500;
    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.text};
    cursor: pointer;

    &:hover {
        background: ${(props) => props.color}35;
    }
    .icon {
        background: ${(props) => props.color};
        color: white;
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
