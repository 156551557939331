import styled from "styled-components";

interface TableProps {
    widthAuto?: boolean;
    tdPadding?: number;
    hideBorde?: boolean;
    tdPaddingRight?: number;
    fistColumnLight?: boolean;
    alignRight?: boolean;
    alignCenter?: boolean;
    rowHeight?: number;
}

export const Table = styled.table`
    width: 100%;
    ${(props: TableProps) => (props.widthAuto ? `width: auto;` : "")}
    .min-width {
        width: 1%;
        white-space: nowrap;
    }

    .header-light {
        th {
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    tr {
        th {
            text-align: left;
            font-weight: 500;
        }
        &.disabled {
            td {
                opacity: 0.3;
            }
        }
    }

    ${(props: TableProps) => (props.rowHeight ? `tr { height: ${props.rowHeight}px;}` : "")}

    &.fist-column-light {
        tr {
            td {
                :first-child {
                    color: ${(props) => props.theme.palette.textLight};
                }
            }
        }
    }

    tr {
        td {
            padding: 10px 10px 10px 0px;
            border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};

            ${(props: TableProps) => (props.hideBorde ? `border-bottom: 0;` : "")}
            ${(props: TableProps) => (props.tdPadding || props.tdPadding === 0 ? `padding: ${props.tdPadding}px; padding-left: 0px;` : "")}
            ${(props: TableProps) => (props.tdPaddingRight ? `padding-right: ${props.tdPaddingRight}px;` : "")}
            ${(props: TableProps) => (props.alignRight ? `text-align: right;` : "")}
            ${(props: TableProps) => (props.alignCenter ? `text-align: center;` : "")}
            &.not-pr {
                padding-right: 0px;
            }
        }
        :last-child {
            td {
                border-bottom: 0;
            }
        }
        &.border-dashed {
            td {
                border-bottom: 1px dashed ${(props) => props.theme.palette.backgroundLight};
            }
        }
        &.border-top-dashed {
            td {
                border-top: 1px dashed ${(props) => props.theme.palette.backgroundLight};
            }
        }
    }
`;

export const TableCustom = styled.table`
    width: 100%;
    &.width-auto {
        width: auto;
    }
    thead {
        th,
        td {
            padding: 5px 5px;
        }
    }
    tr:last-child {
        th,
        td,
        tr {
            border: 0px;
        }
    }
`;

export const TableCustomTr = styled.tr`
    padding: 10px 17px;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    table {
        border-collapse: collapse;
    }
    &.small {
        padding: 5px;
        td {
            padding: 4px 0px;
            padding-right: 15px;
        }
    }
    &.hide-border {
        td {
            border: 0px;
        }
    }
    .min-width {
        width: 1%;
        white-space: nowrap;
    }
    th {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }

    .padding-left {
        padding-left: 10px;
    }
    .FlexCol {
        display: flex;
        flex-direction: column;
    }
    .col {
        display: flex;
        flex-direction: column;
    }
    &.hover {
        &:hover {
            th,
            td {
                background: ${(props) => props.theme.palette.backgroundLight};
                cursor: pointer;
            }
        }
    }

    th,
    td {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
        text-align: left;
        line-height: 1.2;
        padding: 15px 4px;
        font-weight: 400;
        &.text-right {
            text-align: right;
        }
        a {
            display: block;
            color: ${(props) => props.theme.palette.text};
        }
    }
`;
