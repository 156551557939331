import React, { useContext, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import PATHS from "../../constants/paths";
import { TOUR_NAME_QUICK_ACTIONS } from "../QuickActionsMenu";
import { TOUR_NAME_MENU_SHIPMENT } from "../../pages/AppContainer/AppVerticalMenu/MenuOptionsHook";
import { TOUR_MENU_SHIPMENT_BOARD, TOUR_MENU_SHIPMENT_PICKING } from "../../pages/AppContainer/menuConfig";
import { TOUR_NAME_CREATE_PRODUCT } from "../SearchTabTitle/SearchProductTabTitle";
import { Flex, PrimaryButton, TextField } from "../_controls";
import { useQuickStartUpdate } from "./quickStartHook";
import { CustomVideo } from "./styled";

export const MENU_TARGET = "." + TOUR_NAME_MENU_SHIPMENT;
export const QUICK_ACTIONS_TARGET = "." + TOUR_NAME_QUICK_ACTIONS;
export const CREATE_PRODUCT_TARGET = "." + TOUR_NAME_CREATE_PRODUCT;

export default function QuickStartCreateShipment() {
    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const updateQuickStart = useQuickStartUpdate();

    const [steps] = useState<Step[]>(() => {
        const stepList: Step[] = [
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>Aprende a crear un pedido</TextField>
                        <TextField>Gestionas tus pedidos de principio a fin, desde la creación hasta la entrega.</TextField>
                        <ul>
                            <li>Gestiona pedidos de tu E-commerce</li>
                            <li>Crea pedidos de forma manual</li>
                            <li>Controla el estado de los pedidos en el tablero de Ordenes</li>
                            <li>Acepta o rechaza las ordenes del e-commerce</li>
                        </ul>
                    </Flex>
                ),
                placement: "center",
                target: "body",
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-grid-2"></span> Acciones rápidas {">"} Crear Pedido
                        </TextField>
                        <TextField>Aquí puedes acceder a diferentes acciones rápidas como crear productos, ventas, contactos...</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                target: QUICK_ACTIONS_TARGET,
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-cart-shopping-fast"></span> Pedidos {">"} Crear Pedido
                        </TextField>

                        <TextField>Aquí podrás gestionar todas tus pedidos</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                target: MENU_TARGET,
            },

            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-basket-shopping-simple"></span> Panel de Pedidos
                        </TextField>
                        <TextField>Aquí se mostraran todos tus pedidos y un resumen estadístico</TextField>
                    </Flex>
                ),

                placement: "center",
                target: "body",
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-shelves"></span> Picking
                        </TextField>
                        <TextField>
                            Si tu bodega tiene la logística activada, podrás ver los pedidos que se encuentran en estado de picking y podrás
                            prepararlos
                        </TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                target: `.${TOUR_MENU_SHIPMENT_PICKING}`,
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-table-layout"></span> Tablero de ordenes
                        </TextField>
                        <TextField>Cambia el estado de tus pedidos fácilmente, moviendo cada orden a su respectivo estado.</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                target: `.${TOUR_MENU_SHIPMENT_BOARD}`,
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>Crea tu primer pedido</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                locale: { next: "Crear Pedido", last: "Crear Pedido" },
                target: ".CREATE_ORDER",
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>Panel de Creación de Pedidos</TextField>
                        <TextField>Para iniciar creando tu venta, solo debes hacer clic en algún producto</TextField>
                    </Flex>
                ),
                placement: "center",
                target: "body",
            },
        ];
        return stepList;
    });

    const [tourState, setTourState] = useState({
        run: false,
        stepIndex: 0,
    });

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, action, index } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setTourState({ ...tourState, run: false });
        }

        if (index === 3) {
            navigate(PATHS.shipmentsList);
        }
        if (steps.length - 1 === index) {
            navigate(PATHS.orderShipmentNew("shipment"));
        }
        if (status === STATUS.FINISHED) {
            handleCompleteTour();
        }

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
            setTourState({ ...tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        }
    };

    const handleCompleteTour = () => {
        updateQuickStart.updateOption("createOrder", true);
    };

    const handleInitTour = () => {
        navigate("/tour");
        setTourState({ ...tourState, run: true, stepIndex: 0 });
    };

    return (
        <div>
            {steps.length > 0 && (
                <Joyride
                    callback={handleJoyrideCallback}
                    continuous={true}
                    run={tourState.run}
                    stepIndex={tourState.stepIndex}
                    scrollToFirstStep={true}
                    locale={{ next: "Siguiente", back: "Atrás" }}
                    showProgress={true}
                    showSkipButton={true}
                    disableOverlayClose
                    steps={steps}
                    styles={{
                        options: {
                            arrowColor: themeContext.palette.background,
                            backgroundColor: themeContext.palette.background,
                            beaconSize: 36,
                            overlayColor: "rgba(0, 0, 0, 0.5)",
                            primaryColor: "#304FFE",
                            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                            textColor: themeContext.palette.text,
                            width: undefined,
                            zIndex: 100,
                        },
                        buttonNext: {
                            fontWeight: 500,
                            borderRadius: "10px",
                            padding: "10px 15px",
                            background: "#304FFE",
                        },
                        tooltip: {
                            borderRadius: "20px",
                            background: themeContext.palette.background,
                            color: themeContext.palette.text,
                        },
                    }}
                />
            )}

            <Flex column gap10 padding={10}>
                <TextField>En Wahio existen dos formas de crear Pedidos</TextField>
                <Flex gap10 alignCenter>
                    1. En el menu lateral: <span className="fa-regular fa-cart-shopping-fast"></span>
                    <TextField bold>Pedidos</TextField>
                </Flex>
                <TextField>
                    2. En el menu de acciones rápidas: <span className="fa-regular fa-grid-2"></span>
                </TextField>
                <PrimaryButton rounded onClick={handleInitTour}>
                    Muéstrame como
                </PrimaryButton>

                <Flex className="mt-2" alignCenter gap10>
                    <span className="fa-regular fa-video"></span>
                    <TextField bold>Ver Tutorial</TextField>
                </Flex>

                <CustomVideo controls controlsList="nodownload" className="mt-1">
                    <source src={"https://wahiomedia.blob.core.windows.net/wahiotutorials/crear_pedido.mp4"} type="video/mp4" />
                    Tu navegador no soporta la etiqueta de video.
                </CustomVideo>
            </Flex>
        </div>
    );
}
