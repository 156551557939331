import React, { ButtonHTMLAttributes } from "react";
import { PrimaryButtonMobile } from ".";

interface MobileButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: string;
    title: string;
    rounded?: boolean;
    tourName?: string;
}

export default function MobileButton(props: MobileButtonProps) {
    const { title } = props;
    const baseIcon = props.icon ?? "wahioicon-plus";
    return (
        <PrimaryButtonMobile {...props} className={`${props.tourName ?? ""} ${props.className ?? ""}`}>
            <span className={`${baseIcon} btn-mobile-icon`}></span>
            {props.icon && <span className={`${props.icon} line-icon`}></span>}
            <span className="title">{title}</span>
        </PrimaryButtonMobile>
    );
}
