import { defineMessages } from "react-intl";

const base = "components.cashRegister";

export default defineMessages({
    loginCreatedSuccessfully: {
        id: `${base}.loginCreatedSuccessfully`,
        defaultMessage: "El ingreso fue creado con éxito",
    },
    description: {
        id: `${base}.description`,
        defaultMessage: "Descripción",
    },
    descriptionTwo: {
        id: `${base}.descriptionTwo`,
        defaultMessage: "Descripción",
    },
    outputCreatedSuccessfully: {
        id: `${base}.outputCreatedSuccessfully`,
        defaultMessage: "La salida fue creada con éxito",
    },
    originBox: {
        id: `${base}.originBox`,
        defaultMessage: "Caja Origen",
    },
    originBoxTwo: {
        id: `${base}.originBox`,
        defaultMessage: "Caja Origen",
    },
    toSelect: {
        id: `${base}.toSelect`,
        defaultMessage: "Seleccionar",
    },
    toSelectTwo: {
        id: `${base}.toSelectTwo`,
        defaultMessage: "Seleccionar",
    },
    paymentMethod: {
        id: `${base}.paymentMethod`,
        defaultMessage: "Método de pago",
    },
    category: {
        id: `${base}.category`,
        defaultMessage: "Categoría",
    },
    cashRegister: {
        id: `${base}.cashRegister`,
        defaultMessage: "Caja",
    },
    contact: {
        id: `${base}.contact`,
        defaultMessage: "Contacto",
    },
    startDate: {
        id: `${base}.startDate`,
        defaultMessage: "Desde",
    },
    endDate: {
        id: `${base}.endDate`,
        defaultMessage: "Hasta",
    },
    value: {
        id: `${base}.value`,
        defaultMessage: "Valor",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    confirm: {
        id: `${base}.confirm`,
        defaultMessage: "Confirmar",
    },
    createManualEntry: {
        id: `${base}.createManualEntry`,
        defaultMessage: "Crear entrada manual",
    },
    createManualOutput: {
        id: `${base}.createManualOutput`,
        defaultMessage: "Crear Salida manual",
    },
    movementCreated: {
        id: `${base}.movementCreated`,
        defaultMessage: "Movimiento creado con éxito",
    },
    selectOriginDestination: {
        id: `${base}.movementCreated`,
        defaultMessage: "Movimiento creado con éxito",
    },
    originDestination: {
        id: `${base}.originDestination`,
        defaultMessage: "Selecciona la Caja de Origen y Destino",
    },
    destinationCash: {
        id: `${base}.destinationCash`,
        defaultMessage: "Caja Destino",
    },
    valueTransfer: {
        id: `${base}.valueTransfer`,
        defaultMessage: "Valor a Transferir",
    },
    createCashTransfer: {
        id: `${base}.createCashTransfer`,
        defaultMessage: "Crear Traslado de Caja",
    },
    cashRegisterLog: {
        id: `${base}.cashRegisterLog`,
        defaultMessage: "Registro de Caja",
    },
    sale: {
        id: `${base}.sale`,
        defaultMessage: "Venta",
    },
    expense: {
        id: `${base}.expense`,
        defaultMessage: "Gasto",
    },
    purchase: {
        id: `${base}.purchase`,
        defaultMessage: "Compra",
    },
    transfer: {
        id: `${base}.transfer`,
        defaultMessage: "Traslado",
    },
    manualAdjustment: {
        id: `${base}.manualAdjustment`,
        defaultMessage: "Ajuste Manual",
    },
    doNotPermissions: {
        id: `${base}.doNotPermissions`,
        defaultMessage: "No tienes permisos para activar o desactivar cajas",
    },

    balance: {
        id: `${base}.balance`,
        defaultMessage: "Saldo",
    },
    defaultMethod: {
        id: `${base}.defaultMethod`,
        defaultMessage: "Método Predeterminado",
    },
    notAssigned: {
        id: `${base}.notAssigned`,
        defaultMessage: "No Asignado",
    },
    cashMovements: {
        id: `${base}.cashMovements`,
        defaultMessage: "Movimientos de Caja",
    },
    date: {
        id: `${base}.date`,
        defaultMessage: "Fecha",
    },
    third: {
        id: `${base}.third`,
        defaultMessage: "Tercero",
    },
    type: {
        id: `${base}.type`,
        defaultMessage: "Tipo",
    },
    transactionAccount: {
        id: `${base}.transactionAccount`,
        defaultMessage: "Cuenta Externa",
    },
    multiplePayment: {
        id: `${base}.multiplePayment`,
        defaultMessage: "Pagos Multiples",
    },
});
