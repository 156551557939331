/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { getServiceMessageError, wahioFetch } from "../../api";
import { updateWahioCloudFile } from "../../api/account/wahioCloudApi";
import { getUserPermissions } from "../../api/models/accountUser";
import { WahioCloudFolder, WahioCloudItem } from "../../api/models/wahioCloud";
import { accountEndpoint } from "../../api/restApiEndpoint";
import { UserContext } from "../../store/contexts/UserContext";
import { useAlert } from "../Alerts/Alert";
import Dropdown from "../Dropdown";
import LoadingDualRing from "../LoadingDualRing";
import { DefaultButton, Flex, SquareButton } from "../_controls";
import ConfirmationModal from "../modals/ConfirmationModal";
import InputModal from "../modals/InputModal";
import PDFViewModal from "../modals/PDFViewModal";
import { MoveFoldersModal } from "./MoveFoldersModal";
import { WahioCloudInfoModal } from "./WahioCloudInfoModal";
import { getCloudItemExtensionImage } from "./extensionImage";
import { CloudItemCardContainer } from "./styled";

interface WahioCloudItemCardProps {
    item: WahioCloudItem;
    isMock?: boolean;
    onRemove: (item: WahioCloudItem) => void;
    onSelected: (item: WahioCloudItem) => void;
    isSelected: boolean;
    folders: WahioCloudFolder[];
}

interface WahioCloudItemCardMockProps {
    name: string;
}

export function WahioCloudItemCardMock(props: WahioCloudItemCardMockProps) {
    const { name } = props;

    return (
        <CloudItemCardContainer>
            <div className="image-container">
                <LoadingDualRing center />
            </div>
            <div className="body-cloud">
                <span className="name" title={name}>
                    {name}
                </span>
                <SquareButton className="btn-action">
                    <span className="wahioicon-ellipsis-h"></span>
                </SquareButton>
            </div>
        </CloudItemCardContainer>
    );
}

export default function WahioCloudItemCard(props: WahioCloudItemCardProps) {
    const [item, setItem] = useState(props.item);
    const alert = useAlert();
    const { userState } = useContext(UserContext);
    const [showUpdateName, setShowUpdateName] = useState(false);
    const [showMoveFile, setShowMoveFile] = useState(false);
    const [showInfoItem, setShowInfoItem] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const useUrl = item.type === "image" && item.fileExtension !== "svg";
    const image = useUrl ? item.thumbnailUrl ?? item.url : getCloudItemExtensionImage(item.fileExtension);
    const [showPreview, setShowPreview] = useState(false);
    const { isSelected } = props;

    const updateCloudFileMutation = useMutation((newFile: WahioCloudItem) => updateWahioCloudFile(newFile), {
        onSuccess: (data) => {
            alert.success("Archivo actualizado con éxito");
            setItem(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const handleUpdateCloudFile = (name: string) => {
        updateCloudFileMutation.mutate({ ...item, name });
    };

    const handleDeleteCloudFile = () => {
        setIsFetching(true);
        setShowConfirmDelete(false);
        wahioFetch.post(
            accountEndpoint.post.deleteWahioCloudFiles,
            [item],
            () => {
                setIsFetching(false);
                alert.success("Archivo eliminado con éxito");
                props.onRemove(item);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setIsFetching(false);
            }
        );
    };

    const permissionCloud = getUserPermissions(userState.user).cloud;

    return (
        <CloudItemCardContainer className={isSelected ? "selected" : ""}>
            {showPreview && <PDFViewModal show={showPreview} setShow={setShowPreview} url={item.url} />}
            {showConfirmDelete && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmDelete(false)}
                    onOkay={() => handleDeleteCloudFile()}
                    title="¿Estas seguro?"
                    description="Esta acción no se puede revertir"
                    show={showConfirmDelete}
                    setShow={setShowConfirmDelete}
                />
            )}
            {showUpdateName && (
                <InputModal
                    show={showUpdateName}
                    setShow={setShowUpdateName}
                    placeholder="Cambiar nombre"
                    value={item.name}
                    title={"Cambiar Nombre del archivo"}
                    onOkay={(value) => handleUpdateCloudFile(value)}
                    autofocus
                    selectAllInputValue
                />
            )}
            {showInfoItem && (
                <WahioCloudInfoModal
                    title="Propiedades del archivo"
                    description="Características del archivo"
                    show={showInfoItem}
                    setShow={setShowInfoItem}
                    item={item}
                />
            )}
            {showMoveFile && <MoveFoldersModal show={showMoveFile} setShow={setShowMoveFile} itemFile={item} folders={props.folders} />}
            <div onDoubleClick={() => setShowInfoItem(true)} className="image-container" onClick={() => props.onSelected(item)}>
                <div className={`image-cloud ${!useUrl ? "file-extention" : ""}`}>
                    <img src={image} alt={item.name} />
                </div>
            </div>
            <div className="body-cloud">
                <span className="name" title={item.name} onClick={() => props.onSelected(item)}>
                    {item.name}.{item.fileExtension}
                </span>
                {isFetching ? (
                    <LoadingDualRing small />
                ) : (
                    <Dropdown small icon="fa-regular fa-ellipsis-vertical" contentHorizontal="left" contentOutside contentVertical="inline">
                        <Flex column gap5 padding={10}>
                            <DefaultButton small className="dropdown-button" onClick={() => setShowInfoItem(true)}>
                                <i className="fa-regular fa-circle-info"></i> Información
                            </DefaultButton>
                            {permissionCloud?.update && (
                                <>
                                    <DefaultButton small className="dropdown-button" onClick={() => setShowUpdateName(true)}>
                                        <i className="fa-regular fa-pen-to-square"></i> Renombrar
                                    </DefaultButton>
                                    <DefaultButton small className="dropdown-button" onClick={() => setShowMoveFile(true)}>
                                        <span className="fa-regular fa-arrow-right-to-line"></span> Mover
                                    </DefaultButton>
                                </>
                            )}
                            {permissionCloud?.delete && (
                                <DefaultButton small className="dropdown-button" onClick={() => setShowConfirmDelete(true)}>
                                    <span className="fa-regular fa-trash"></span> Eliminar
                                </DefaultButton>
                            )}
                            <a href={item.url} target="_blank">
                                <DefaultButton small className="dropdown-button">
                                    <span className="fa-regular fa-download"></span> Descargar
                                </DefaultButton>
                            </a>
                            {item.fileExtension === "pdf" && (
                                <DefaultButton small className="dropdown-button" onClick={() => setShowPreview(true)}>
                                    <span className="fa-regular fa-eye"></span> Ver
                                </DefaultButton>
                            )}
                        </Flex>
                    </Dropdown>
                )}
            </div>
        </CloudItemCardContainer>
    );
}
