import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { IShippingLabelOrganization } from "../../../api/models/deliveryOrder";
import { Order } from "../../../api/models/order";
import { IOrganization } from "../../../api/models/organizationTypes";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { getExampleOrder } from "../../InvoiceFormat";
import { OrderLabelContent } from "../../Orders/OrderProfile/OrderLabel";
import { default as messages } from "../../Orders/OrderProfile/messages";
import { DefaultButton, DefaultTextarea, Flex, PrimaryButton, TextField } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";
import Modal, { IModalShow } from "../../modals/Modal";
import { SelectedLabelOptions, ShippingLabelContainer } from "./styled";

interface ShippingLabelFormModalProps extends ShippingLabelFormProps, IModalShow {}

export interface ShippingLabelFormProps {
    onUpdate: (value: IShippingLabelOrganization) => void;
    organization?: IOrganization;
    organizationReadOnly?: boolean;
    onCancel: () => void;
    shippingLabel: IShippingLabelOrganization;
    order?: Order;
}

export const ShippingLabelForm = (props: ShippingLabelFormProps) => {
    const { order } = props;
    const deliveryOrder = order?.deliveryOrder;
    const intl = useIntl();
    const [shippingLabel, setShippingLabel] = useState<IShippingLabelOrganization>(props.shippingLabel);

    const { organizationState } = useContext(OrganizationContext);

    const organization = props.organization ?? organizationState.currentOrganization;

    const handleChangeCheckbox = (e: any) => {
        setShippingLabel({ ...shippingLabel, [e.target.name]: e.target.checked });
    };

    return (
        <ShippingLabelContainer>
            <div className="left-panel">
                {props.order && deliveryOrder && (
                    <OrderLabelContent
                        organization={organization}
                        order={{ ...order, deliveryOrder: { ...deliveryOrder, shippingLabel } }}
                    />
                )}
            </div>
            <Flex column gap={20} className="right-panel">
                <Flex column gap10>
                    <Flex>
                        <CheckBox
                            checked={shippingLabel.observation}
                            title={intl.formatMessage(messages.observations)}
                            name="observation"
                            onChange={handleChangeCheckbox}
                        />
                    </Flex>
                    <DefaultTextarea
                        placeholder="Detalles de Envío"
                        value={shippingLabel.comment ?? ""}
                        onChange={(e) => setShippingLabel({ ...shippingLabel, comment: e.target.value })}
                        borderRadius={15}
                    />
                </Flex>

                <Flex column gap10>
                    <TextField bold light>
                        Mi empresa
                    </TextField>

                    <SelectedLabelOptions>
                        <CheckBox
                            title={intl.formatMessage(messages.organizationAddress)}
                            name="organizationAddress"
                            onChange={(e) => handleChangeCheckbox(e)}
                            checked={shippingLabel.organizationAddress}
                        />

                        <CheckBox
                            checked={shippingLabel.organizationPhone}
                            title={intl.formatMessage(messages.organizationPhone)}
                            name="organizationPhone"
                            onChange={handleChangeCheckbox}
                        />
                    </SelectedLabelOptions>
                </Flex>

                <Flex column gap10>
                    <TextField bold light>
                        Seleccione detalles de la orden
                    </TextField>
                    <SelectedLabelOptions>
                        <CheckBox
                            checked={shippingLabel.customerDocumentNumber}
                            title={"Documento del cliente"}
                            name="customerDocumentNumber"
                            onChange={handleChangeCheckbox}
                        />
                        <CheckBox
                            checked={shippingLabel.orderNumber}
                            title={intl.formatMessage(messages.orderNumber)}
                            name="orderNumber"
                            onChange={handleChangeCheckbox}
                        />
                        <CheckBox
                            checked={shippingLabel.invoiceNumber}
                            title={intl.formatMessage(messages.invoiceNumber)}
                            name="invoiceNumber"
                            onChange={handleChangeCheckbox}
                        />
                        <CheckBox
                            checked={shippingLabel.shippingValue}
                            title={intl.formatMessage(messages.shippingValue)}
                            name="shippingValue"
                            onChange={handleChangeCheckbox}
                        />

                        <CheckBox
                            checked={shippingLabel.paymentStatus}
                            title={intl.formatMessage(messages.paymentStatus)}
                            name="paymentStatus"
                            onChange={handleChangeCheckbox}
                        />

                        <CheckBox
                            checked={shippingLabel.paymentMethod}
                            title={intl.formatMessage(messages.paymentMethod)}
                            name="paymentMethod"
                            onChange={handleChangeCheckbox}
                        />

                        <CheckBox
                            checked={shippingLabel.orderValue}
                            title={intl.formatMessage(messages.orderValue)}
                            name="orderValue"
                            onChange={handleChangeCheckbox}
                        />
                        <CheckBox
                            checked={shippingLabel.trackingNumber}
                            title={intl.formatMessage(messages.trackingNumber)}
                            name="trackingNumber"
                            onChange={handleChangeCheckbox}
                        />
                        <CheckBox
                            checked={shippingLabel.trackingQr}
                            title={intl.formatMessage(messages.trackingQr)}
                            name="trackingQr"
                            onChange={handleChangeCheckbox}
                        />
                    </SelectedLabelOptions>
                </Flex>
                <Flex gap10 alignCenter justifyEnd style={{ marginTop: "auto" }}>
                    <DefaultButton borderRadius={10} bgLight onClick={props.onCancel}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton borderRadius={10} onClick={() => props.onUpdate(shippingLabel)}>
                        Guardar Cambios
                    </PrimaryButton>
                </Flex>
            </Flex>
        </ShippingLabelContainer>
    );
};

export const ShippingLabelFormModal = (props: ShippingLabelFormModalProps) => {
    const onCreate = (value: any) => {
        props.onUpdate(value);
        props.setShow(false);
    };

    const order = React.useMemo(() => {
        if (!props.order) {
            return getExampleOrder();
        }
        return props.order
    }, [props.order]);

    return (
        <Modal
            sizeType="md2"
            scrollInside={false}
            overflowAuto={false}
            removeVerticalAlign
            title="Etiqueta de envío"
            showHeader={true}
            {...props}
        >
            <ShippingLabelForm {...props} order={order} onUpdate={onCreate} onCancel={() => props.setShow(false)} />
        </Modal>
    );
};
