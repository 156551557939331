import { WahioFile } from ".";
import { AccountUser } from "./accountUser";
import { IOrganization } from "./organizationTypes";
import { IProduct, IProductVariant, ProductOptionalsConfig } from "./product";
import { ITax } from "./tax";
import { IWarehouseProduct } from "./warehouseProduct";

export type OrderStatus =
    | "void"
    | "paid"
    | "credit"
    | "open"
    | "quotation"
    | "partial"
    | "queued"
    | "draft"
    | "authorized"
    | "declined"
    | "invalid";

export const ALL_ORDER_STATUS_LIST: OrderStatus[] = ["paid", "credit", "partial", "open", "quotation", "queued", "draft", "void"];

export const orderStatusListWithoutInvoice: OrderStatus[] = ["queued", "draft", "declined", "void", "quotation"];

export const orderStatusOpenList: OrderStatus[] = ["paid", "partial", "credit"];

export const orderStatusExpenseList: OrderStatus[] = ["paid", "partial", "credit", "void"];

export type OrderType = "shipment" | "invoice" | "restaurant";

export type OrderSourceType = "manually" | "restaurant" | "catalog" | "ecommerce";

export const OrderSourceList: OrderSourceType[] = ["manually", "restaurant", "catalog", "ecommerce"];

export interface IOrderStatusOption {
    status: OrderStatus;
    name: any;
}
export class OrderStatusOption implements IOrderStatusOption {
    status: OrderStatus;
    name: any;
    constructor(status: OrderStatus, name: any) {
        this.status = status;
        this.name = name;
    }
}

export enum OrderStatusType {
    neutral,
    defined,
}

export type OrderItemStatus = "empty" | "inprogress" | "completed";

export interface OrderBaseItem {
    id?: string;
    productId: string;
    product?: IProduct;
    warehouseId: string;
    quantity: number;
    stockTemp?: number; // ONLY UI
    unitPrice: number;
    totalUnitPrice: number;
    comment?: string;
    position: number;
    status: OrderItemStatus;
    totalTax: number;
    totalTaxPercentage: number;
    basePrice: number;
    basePricePreTaxDiscount: number;
    taxes: ITaxOrderItem[];
    discounts: IDiscountOrderItem[];
    manualPercentageDiscount: number;
    manualPercentageDiscountValue: number;
    productVariantId?: string;
    productVariant?: IProductVariant;
    warehouseProduct?: IWarehouseProduct;
    optionalsConfig?: ProductOptionalsConfig;
    serials?: string[];
    availableSerials?: string[];
    availableStock: number;
    subTotal: number;
    total: number;
    locationCode?: string;
}

export interface IDiscountOrderItem {
    id?: string;
    valueBase: number;
    discountId?: string;
    discountType: string;
    discountCode: string;
    discountName: string;
    discountDescription: string;
    discountPercentage: number;
    value: number;
}

export interface ITaxOrderItemBase {
    id?: string;
    taxId?: string;
    taxTributeType: string;
    taxName: string;
    taxDescription: string;
    taxValue: number;
    taxPercentage: number;
    taxIsInclude: boolean;
    taxIsPercentage: boolean;
    valueBase: number;
}
export interface ITaxOrderItem extends ITaxOrderItemBase {
    orderItemId?: string;
}

export interface ITaxPurchaseItem extends ITaxOrderItemBase {
    purchaseItemId?: string;
}

export interface ITaxOrderReturnItem extends ITaxOrderItemBase {
    orderReturnItemId?: string;
}

export const convertTaxOrderItem = (tax: ITax, valueBase: number, taxIsInclude: boolean): ITaxOrderItemBase => {
    let isPercentage = tax.type === "percentage";
    return {
        taxId: tax.id,
        taxDescription: tax.description,
        taxName: tax.name,
        taxTributeType: tax.tributeType,
        taxIsPercentage: isPercentage,
        taxPercentage: isPercentage ? tax.value : 0,
        taxValue: isPercentage ? 0 : tax.value,
        taxIsInclude: taxIsInclude,
        valueBase: valueBase,
    };
};

export interface IOrderBase {
    id?: string;
    accountId: string;
    organization?: IOrganization;
    dateCreated?: Date;
    dateUpdated?: Date;
    number: number;
    accountUserId: string;
    accountUser?: AccountUser;
    totalAmount: number;
    subTotal: number;
    totalAmountItems: number;
    totalManualPercentageDiscountItems: number;
    totalItemsDiscount: number;
    totalPaid: number;
    totalPayment: number;
    totalDiscount: number;
    totalTaxItems: number;
    totalTip: number;
    percentageDiscount: number;
    percentageTip: number;
    totalRefund: number;
    organizationId: string;
    costCenterId: string;
    moneyReceived: number;
    moneyReturned: number;
    comment: string;
    status: OrderStatus;
    paymentTermsDays: number;
    paymentTermsDaysDefaultLoaded?: boolean; //UI ONLY
    lastAccountUserId: string;
    itemsCount: number;
    itemsQuantityCount: number;
    lastCashRegisterId?: string;
    taxIncluded: boolean;
    preTaxDiscount: boolean;
    includesTaxWithholding: boolean;
    taxWithholdingName: string;
    taxWithholdingPercentage: number;
    taxWithholdingValue: number;
    files: WahioFile[];
}
