import React from "react";
import { useIntl } from "react-intl";
import { IContactProps } from ".";
import { IContactEmail } from "../../../api/models/contact";
import { stringIsNullOrEmpty } from "../../../utils";
import InputTwoColsActions, { ILabelAction } from "../../InputTwoColsActions";
import { Flex } from "../../_controls";
import messages from "./messages";

interface IEmailLabel extends ILabelAction, IContactProps {
    item: IContactEmail;
}
const ContactEmailLabels = (props: IEmailLabel) => {
    const { item } = props;
    const onChange = (value: string, label: string) => {
        var emails = [...props.currentCustomer.emails];
        emails[props.index] = { ...item, email: value, label };
        props.setCurrentCustomer({ ...props.currentCustomer, emails });
    };
    return (
        <InputTwoColsActions
            {...props}
            value={item.email}
            label={item.label}
            showAdd={props.itemNumber === props.totalItems && !stringIsNullOrEmpty(item.email)}
            className="mb-1"
            showRemove={props.totalItems > 1}
            onAdd={props.onAdd}
            onRemove={props.onRemove}
            onChange={onChange}
        />
    );
};

const ContactEmailsView = (props: IContactProps) => {
    const intl = useIntl();
    const { currentCustomer, setCurrentCustomer } = props;

    const onRemoveEmail = (index: number) => {
        if (currentCustomer.emails.length === 1) return;
        let emailsCopy = currentCustomer.emails.filter((x, i) => i !== index);
        setCurrentCustomer({ ...currentCustomer, emails: emailsCopy });
    };
    const onAddEmail = () => {
        let emailsCopy = currentCustomer.emails;
        emailsCopy = [...emailsCopy, { email: "" }];
        setCurrentCustomer({ ...currentCustomer, emails: emailsCopy });
    };

    let elements = currentCustomer.emails.map((item, index) => (
        <ContactEmailLabels
            {...props}
            key={index}
            index={index}
            totalItems={currentCustomer.emails.length}
            item={item}
            onAdd={onAddEmail}
            itemNumber={index + 1}
            onRemove={onRemoveEmail}
            placeholder={intl.formatMessage(messages.email)}
            labelPlaceholder={intl.formatMessage(messages.label)}
        />
    ));
    return (
        <Flex column border borderRadius={10}>
            {elements}
        </Flex>
    );
};

export default ContactEmailsView;
