import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { WahioFile } from "../../../api/models";
import { IProduct, ProductPartialUpdateData, ProductPartialUpdateInput } from "../../../api/models/product";
import { IProductCategory } from "../../../api/models/productCategory";
import { createUpdateProduct, productPartialUpdate } from "../../../api/products/productsApi";
import { getDateFormat } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import { DefaultButton, DefaultInput, Flex, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import ProductCategorySelector from "../ProductCategories/ProductCategorySelector";
import ImagesForm from "../ProductCreationForm/ImagesForm";
import { getProductCode, getProductImages, getProductSku } from "../helper";
import { NavigationGroupName } from "./menuHelper";
import { ProfileTable } from "./styled";

export default function NavigationProductProfile(props: ProductNavigationProfileProps) {
    return (
        <>
            <NavigationGeneralInformation {...props} />
            <NavigationImages {...props} />
            <NavigationStock {...props} />
        </>
    );
}

const NavigationGeneralInformation = (props: ProductNavigationProfileProps) => {
    const [product, setProduct] = useState(props.product);
    const [productVariant, setProductVariant] = useState(props.productVariant);

    const [hasChanges, setHasChanges] = useState(false);

    const updateMutation = useMutation((data: IProduct) => createUpdateProduct(data), {
        onSuccess: (data) => {
            setHasChanges(false);
            props.onUpdateProduct(data);
            var variant = data.variants?.find((v) => v.id === productVariant?.id);
            if (variant) props.setProductVariant(variant);
        },
    });

    useEffect(() => {
        setProduct(props.product);
        setProductVariant(props.productVariant);
    }, [props.product, props.productVariant]);

    useEffect(() => {
        let categoryChange = product.customCategoryId !== props.product.customCategoryId;
        let nameChange = product.name !== props.product.name;
        let skuChange = getProductSku(product, productVariant) !== getProductSku(props.product, props.productVariant);
        let codeChange = getProductCode(product, productVariant) !== getProductCode(props.product, props.productVariant);

        setHasChanges(categoryChange || nameChange || skuChange || codeChange);
    }, [product, productVariant, props.product, props.productVariant]);

    const onChangeProVar = (e: any) => {
        if (productVariant) {
            setProductVariant({ ...productVariant, [e.target.name]: e.target.value });
        } else {
            setProduct({ ...product, [e.target.name]: e.target.value });
        }
    };

    const onCategoryChange = (value?: IProductCategory) => {
        if (value) {
            setProduct({ ...product, customCategory: value, customCategoryId: value.id });
        }
    };

    const onSaveChanges = () => {
        let productUpdate = { ...product };
        if (productVariant && productUpdate.variants) {
            productUpdate.variants = productUpdate.variants.map((variant) => {
                if (variant.id === productVariant.id) {
                    return productVariant;
                }
                return variant;
            });
        }
        updateMutation.mutate(productUpdate);
    };

    return (
        <NavigationCard
            {...props}
            title={"Información general"}
            actionsComponent={<NavigationActions disabled={!hasChanges} isLoading={updateMutation.isLoading} onSave={onSaveChanges} />}
        >
            <Flex column gap={20}>
                <ProfileTable tdPaddingRight={20} tdPadding={5} className="fist-column-light" cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td className="min-width">Nombre</td>
                            <td>
                                <DefaultInput
                                    w100
                                    placeholder="name"
                                    name="Ingresa el nombre del producto"
                                    value={product.name}
                                    onChange={(e) => {
                                        setProduct({ ...product, name: e.target.value });
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Categoría</td>
                            <td>
                                <ProductCategorySelector
                                    defaultCategoryId={props.product.customCategoryId}
                                    onChangeCategory={onCategoryChange}
                                    hideTitle
                                    selectBodyClass="w100"
                                    title={"Categoría"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Código</td>
                            <td>
                                <DefaultInput
                                    w100
                                    name="code"
                                    placeholder="Código"
                                    value={getProductCode(product, productVariant)}
                                    onChange={onChangeProVar}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Sku</td>
                            <td>
                                <DefaultInput
                                    w100
                                    name="sku"
                                    placeholder="Ingresa el Sku"
                                    value={getProductSku(product, productVariant)}
                                    onChange={onChangeProVar}
                                />
                            </td>
                        </tr>
                    </tbody>
                </ProfileTable>

                <TextField small light>
                    Creado {getDateFormat(product.dateCreated, "DD MMM YYYY")}
                </TextField>
            </Flex>
        </NavigationCard>
    );
};

const NavigationStock = (props: ProductNavigationProfileProps) => {
    if (!(props.warehouseProduct && props.product.controlInventory)) return null;

    return (
        <NavigationCard
            title={`Stock en ${props.selectedWarehouse?.name}`}
            actionsComponent={
                <DefaultButton hideBorder small colorLight borderRadius={10} onClick={() => props.setSelectedGroup(NavigationGroupName.Stock)}>
                    <span className="fa-regular fa-arrow-right"></span> Ver Stock
                </DefaultButton>
            }
        >
            <Table tdPaddingRight={20} tdPadding={7} className="fist-column-light" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td className="min-width">Stock</td>
                        <td>{props.warehouseProduct.stock}</td>
                    </tr>
                    <tr>
                        <td>Reservado</td>
                        <td>{props.warehouseProduct.reserved}</td>
                    </tr>
                    <tr>
                        <td>Disponible</td>
                        <td>{props.warehouseProduct.stock - props.warehouseProduct.reserved}</td>
                    </tr>
                </tbody>
            </Table>
        </NavigationCard>
    );
};

const NavigationImages = (props: ProductNavigationProfileProps) => {
    const { product, productVariant } = props;
    const alert = useAlert();

    const [hasChanges, setHasChanges] = useState(false);

    const [productUpdateBody, setProductUpdateBody] = useState<ProductPartialUpdateInput>({
        images: getProductImages(product, productVariant),
    });

    useEffect(() => {
        setProductUpdateBody({ images: getProductImages(product, productVariant) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, productVariant]);

    const saveProductMutation = useMutation((body: ProductPartialUpdateData) => productPartialUpdate(body), {
        onSuccess: (data) => {
            props.onUpdateProduct({
                ...props.product,
                images: data.images,
            });
            alert.success("El producto ha sido actualizado con éxito");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const onSaveChanges = () => {
        const id = productVariant?.id ?? props.product.id;
        saveProductMutation.mutate({ id, isVariant: !!productVariant, data: productUpdateBody });
    };

    const onImagesChanges = (images: WahioFile[]) => {
        setProductUpdateBody({ ...productUpdateBody, images });
        setHasChanges(true);
    };

    return (
        <NavigationCard
            {...props}
            title={"Imágenes"}
            actionsComponent={<NavigationActions disabled={!hasChanges} isLoading={saveProductMutation.isLoading} onSave={onSaveChanges} />}
        >
            <ImagesForm hideHeader images={productUpdateBody.images ?? []} onChangeImages={onImagesChanges} />
        </NavigationCard>
    );
};
