import React from "react";
import { CSSProperties } from "styled-components";
import { DefaultButton, GroupButtonContainer } from ".";
import LoadingDualRing from "../../LoadingDualRing";
import TooltipRadix from "../WahioTooltip/TooltipRadix";

export interface ButtonOption<T> {
    icon?: string;
    title: string;
    title2?: string;
    tooltip?: string;
    value: T;
    disabled?: boolean;
    color?: string;
    isLoading?: boolean;
}

interface GroupButtonRowProps<T> {
    activeOption?: T;
    onClickOption: (value: ButtonOption<T>) => void;
    options: ButtonOption<T>[];
    onDoubleClick?: (e: any) => void;
    smallView?: boolean;
    disabled?: boolean;
    w100?: boolean;
    hidePadding?: boolean;
}

export default function GroupButtonRow<T>(props: GroupButtonRowProps<T>) {
    const smallViewClass = props.smallView ? "small-view" : "";
    const widthClass = props.w100 ? "w100" : "";
    const hidePaddingClass = props.hidePadding ? "hide-padding" : "";

    return (
        <GroupButtonContainer className={`group-button-container ${smallViewClass} ${widthClass} ${hidePaddingClass}`}>
            {props.options.map((option, index) => {
                let style: CSSProperties = {};
                const isActive = props.activeOption === option.value;
                if (option.color && isActive) {
                    style = { color: option.color };
                }
                return (
                    <TooltipRadix
                        key={index}
                        delay={0}
                        title={option.tooltip}
                        trigger={
                            <DefaultButton
                                key={index}
                                type="button"
                                w100={props.w100}
                                disabled={props.disabled || option.disabled}
                                onDoubleClick={props.onDoubleClick}
                                onClick={() => props.onClickOption(option)}
                                className={`${isActive ? "active" : ""}`}
                                style={style}
                            >
                                {!option.disabled && option.icon && <span className={option.icon}></span>}
                                {option.disabled && <span className="wahioicon-lock" />}
                                {option.title}
                                {!option.isLoading && option.title2 && ` ${option.title2}`}
                                {option.isLoading && <LoadingDualRing small />}
                            </DefaultButton>
                        }
                    />
                );
            })}
        </GroupButtonContainer>
    );
}
