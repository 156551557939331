import { Dispatch } from "react";
import { ICurrentPurchaseState, IPurchase, IUpdateCurrentPurchase, PurchaseTypes, UPDATE_CURRENT_PURCHASE } from "../../api/models/purchase";

export interface ICurrentPurchaseActions {
    update: (purchase: IPurchase) => void;
}

export const actionUpdate = (purchase: IPurchase): IUpdateCurrentPurchase => {
    return {
        type: UPDATE_CURRENT_PURCHASE,
        value: purchase,
    };
};

export const useActions = (state: ICurrentPurchaseState, dispatch: Dispatch<PurchaseTypes>): ICurrentPurchaseActions => ({
    update: (purchase: IPurchase) => {
        dispatch(actionUpdate(purchase));
    },
});
