import React, { createContext, useState } from "react";

export interface MenuState {
    menuElement?: React.ReactNode;
    link?: string;
}

interface MenuContextProps {
    menuState: MenuState;
    setMenuState: (value: MenuState) => void;
}

export const MenuContext = createContext({} as MenuContextProps);

const MenuContextProvider = (props: any) => {
    const [menuState, setMenuState] = useState<MenuState>({});

    return <MenuContext.Provider value={{ menuState, setMenuState }}>{props.children}</MenuContext.Provider>;
};

export const useMenuContext = () => {
    return React.useContext(MenuContext);
};

export default MenuContextProvider;
