import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { MessageGroupStatusInput, getMessagesGroupStatusByUser, getMessagesStatusByUser } from "../../../api/chats/chatApi";
import { AccountUser } from "../../../api/models/accountUser";
import { IOrganization } from "../../../api/models/organizationTypes";
import { useWahioSocket } from "../../../api/socket";
import { UserSessionChangeData } from "../../../api/socket/models";
import { AppSettingsContext, useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { CloudImages } from "../../../assets";
import PATHS from "../../../constants/paths";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { GroupChat, UserChat, useChatContext } from "../../../store/contexts/ChatContext";
import { useOrganizationContext } from "../../../store/contexts/OrganizationContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import { MOBILE_SIZE } from "../../../theme/themeProvider";
import { getDateFromNow, getModelFullName } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import { DefaultButton, Flex, FlexImageStyle, SquareButton } from "../../_controls";
import { Message, MessageGroup } from "../models";
import { ChatUserListContainer, ChatUserRow } from "./styled";

import * as Popover from "@radix-ui/react-popover";
import { EventKey, useSocketAccountOnEvent, useSocketOnRoomChange } from "../../../api/socket/sockedManager";
import { HoverCardContentStyle } from "../../_controls/PopoverRadix/hoverCardStyle";

type RefetchMessagesType = "store" | "user";

export default function ChatUserListDropdown() {
    const { chatState } = useChatContext();
    const counter = chatState.messagesCounter + chatState.messagesGroupCounter;
    const { appSettingsState } = useAppSettingsContext();

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                {appSettingsState.menuSmall ? (
                    <SquareButton>
                        {counter > 0 && <span className="counter">{counter}</span>}
                        <span className="fa-regular fa-message"></span>
                    </SquareButton>
                ) : (
                    <DefaultButton bgLight rounded justifyStart>
                        {counter > 0 && <span className="counter">{counter}</span>}
                        <span className="fa-regular fa-message"></span> Chat
                    </DefaultButton>
                )}
            </Popover.Trigger>

            <Popover.Portal>
                <HoverCardContentStyle>
                    <Popover.Content className="HoverCardContent" side="left" align="start" style={{ padding: 10 }} sideOffset={0}>
                        <ChatUserPanel />
                        <Popover.Arrow className="HoverCardArrow" />
                    </Popover.Content>
                </HoverCardContentStyle>
            </Popover.Portal>
        </Popover.Root>
    );
}

export const useChatListener = () => {
    const { accountUserState, accountUserActions } = useAccountUserContext();

    const orgContext = useOrganizationContext();
    const { userState } = useUserContext();
    const { chatState, setChatState } = useChatContext();
    const [users, setUsers] = useState<AccountUser[]>([]);
    const subscriptionCompletedRef = useRef(false);
    const wahioSocket = useWahioSocket();
    const alert = useAlert();
    //this is for the sockets
    const messageStatusRef = useRef(false);
    const chatStateRef = useRef(chatState);
    const accountUserStateRef = useRef(accountUserState);
    const chatSortTimeRef = useRef<any>();
    const usersRef = useRef<AccountUser[]>([]);
    const [refetchMessagesCounter, setRefetchMessagesCounter] = useState<RefetchMessagesType>();
    const organization = orgContext.organizationState.currentOrganization;

    const messageStatusMutation = useMutation((id: string) => getMessagesStatusByUser(id), {
        onSuccess: (data) => {
            setChatState({ ...chatState, messageStatusItems: data });
        },
    });
    const messageGroupStatusMutation = useMutation((data: MessageGroupStatusInput) => getMessagesGroupStatusByUser(data), {
        onSuccess: (data) => {
            setChatState({ ...chatState, messagesGroupCounter: data.count });
        },
    });

    useEffect(() => {
        chatStateRef.current = chatState;
        usersRef.current = users;
        accountUserStateRef.current = accountUserState;
    }, [chatState, users, accountUserState]);

    useEffect(() => {
        if (users.length > 0 && userState.user && !messageStatusRef.current) {
            messageStatusRef.current = true;
            messageStatusMutation.mutate(userState.user.id);
            if (organization) messageGroupStatusMutation.mutate({ group: organization.id ?? "-", userId: userState.user.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    useEffect(() => {
        if (refetchMessagesCounter) {
            if (refetchMessagesCounter === "store" && organization && userState.user) {
                messageGroupStatusMutation.mutate({ group: organization.id ?? "-", userId: userState.user.id });
            } else if (refetchMessagesCounter === "user" && userState.user) {
                messageStatusMutation.mutate(userState.user.id);
            }
            setRefetchMessagesCounter(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchMessagesCounter]);

    useSocketAccountOnEvent(EventKey.userSession, (data: UserSessionChangeData) => {
        if (chatStateRef.current) {
            let newState = chatStateRef.current;
            if (data.status === "disconnected" && data.user) {
                let users = newState.activeUsers?.filter((x) => x.userId !== data.user?.userId);
                newState = { ...newState, activeUsers: users };
                setChatState(newState);
            } else {
                newState = { ...newState, activeUsers: data.users };
                setChatState(newState);
            }
            chatStateRef.current = newState;
        }
    });

    useSocketOnRoomChange((body) => {
        if (body && body.toUserId && !chatStateRef.current.activeChats?.find((x) => x.userId === body.fromUserId)) {
            const data = body as Message;
            if (window.innerWidth <= MOBILE_SIZE) {
                setRefetchMessagesCounter("user");
                let obj = accountUserStateRef.current.itemsObj;
                let user = obj ? obj[data.fromUserId] : undefined;
                if (user) {
                    alert.notification({
                        title: user.firstName,
                        description: data.description,
                        image: user.image,
                    });
                }
                return;
            }

            let user = usersRef.current.find((x) => x.id === data.fromUserId);
            if (user) {
                let chats = chatStateRef.current.activeChats ?? [];
                let newChat: UserChat = {
                    userId: user.id,
                    userName: getModelFullName(user),
                    userImage: user.image,
                };
                setChatState({ ...chatStateRef.current, activeChats: [...chats, newChat] });
            }
        } else if (body && body.group && organization && !chatStateRef.current.activeGroupChats?.find((x) => x.group === organization.id)) {
            const data = body as MessageGroup;
            if (window.innerWidth <= MOBILE_SIZE) {
                setRefetchMessagesCounter("store");
                let obj = accountUserStateRef.current.itemsObj;
                let user = obj ? obj[data.fromUserId] : undefined;
                if (user) {
                    alert.notification({
                        title: `${user.firstName} en ${organization.name}`,
                        description: data.description,
                        image: user.image,
                    });
                }

                return;
            }

            let chats = chatStateRef.current.activeGroupChats ?? [];
            let newChat: GroupChat = {
                group: organization.id ?? "INVALID",
                title: organization.name,
                image: organization.image,
            };
            setChatState({ ...chatStateRef.current, activeGroupChats: [...chats, newChat] });
        }
    });

    useEffect(() => {
        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!subscriptionCompletedRef.current && userState.user && accountUserState.items.length > 0) {
            let usersIds = _.uniq(accountUserState.items.map((x) => x.id));
            usersIds.forEach((id) => {
                let userIdsArr = [id, userState.user?.id ?? "INVALID_USER"];
                const userGroupResult = _.sortBy(userIdsArr).join("-");
                wahioSocket.roomSuscribe(wahioSocket.rooms.chatGroup(userGroupResult));
            });
            if (organization && organization.id) wahioSocket.roomSuscribe(wahioSocket.rooms.chatGroup(organization.id));
            subscriptionCompletedRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items]);

    useEffect(() => {
        clearTimeout(chatSortTimeRef.current);
        chatSortTimeRef.current = setTimeout(() => {
            let newUsers = accountUserState.items.map((itemBase) => {
                let item = { ...itemBase };
                let active = chatState.activeUsers?.find((x) => x.userId === item.id);
                if (active) item.isOnline = true;
                item.messageStateItem = chatState.messageStatusItems?.find((x) => x.fromUserId === item.id);
                return item;
            });
            newUsers = newUsers.filter((x) => x.id !== userState.user?.id && x.status === "active");
            setUsers(_.sortBy(newUsers, "isOnline", "messageStateItem.count"));
        }, 100);

        return () => {
            clearTimeout(chatSortTimeRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items, chatState.activeUsers, chatState.messageStatusItems]);

    return null;
};

export const ChatUserPanel = () => {
    const { accountUserState } = useAccountUserContext();
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const orgContext = useOrganizationContext();
    const { userState } = useUserContext();
    const { chatState, addOrganizationToChat, addUserToChat, removeUserToChat, removeGroupToChat } = useChatContext();
    const [users, setUsers] = useState<AccountUser[]>([]);
    const chatSortTimeRef = useRef<any>();

    const organization = orgContext.organizationState.currentOrganization;

    useEffect(() => {
        clearTimeout(chatSortTimeRef.current);
        chatSortTimeRef.current = setTimeout(() => {
            let newUsers = accountUserState.items.map((itemBase) => {
                let item = { ...itemBase };
                let active = chatState.activeUsers?.find((x) => x.userId === item.id);
                if (active) item.isOnline = true;
                item.messageStateItem = chatState.messageStatusItems?.find((x) => x.fromUserId === item.id);
                return item;
            });
            newUsers = newUsers.filter((x) => x.id !== userState.user?.id && x.status === "active");
            setUsers(_.sortBy(newUsers, "isOnline", "messageStateItem.count"));
        }, 100);

        return () => {
            clearTimeout(chatSortTimeRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items, chatState.activeUsers, chatState.messageStatusItems]);

    const handleUserChat = (user: AccountUser) => {
        let chats = chatState.activeChats ?? [];
        if (!chats.find((x) => x.userId === user.id)) {
            addUserToChat(user);
        } else {
            removeUserToChat(user.id);
        }
    };

    const handleOrganizationChat = (organization: IOrganization) => {
        let chats = chatState.activeGroupChats ?? [];
        if (!chats.find((x) => x.group === organization.id)) {
            addOrganizationToChat(organization);
        } else {
            removeGroupToChat(organization.id ?? "");
        }
    };

    return (
        <ChatUserListContainer column padding={25} gap10>
            {organization && (
                <>
                    <span className="m-0 ml-1">Chat Grupal</span>
                    <ChatUserRow
                        gap10
                        alignCenter
                        onClick={() => {
                            handleOrganizationChat(organization);
                        }}
                    >
                        <div className="image-container">
                            <FlexImageStyle width={35} borderRadius={30} bgLight>
                                {organization.image ? <img src={organization.image} alt="" /> : <span className="wahioicon-user"></span>}
                            </FlexImageStyle>
                        </div>
                        <Flex column>
                            <span className="m-0 text-bold">{organization.name}</span>
                            {chatState.messagesGroupCounter > 0 && (
                                <span className="pending-messages">{chatState.messagesGroupCounter} Mensajes</span>
                            )}
                        </Flex>
                    </ChatUserRow>
                </>
            )}
            <span className="m-0 ml-1">Chats</span>
            {users.length > 0 ? (
                <Flex column gap={2}>
                    {users.map((item) => (
                        <ChatUserRow
                            key={item.id}
                            gap10
                            alignCenter
                            onClick={() => handleUserChat(item)}
                            className={`${item.isOnline ? "online" : ""}`}
                        >
                            <div className="image-container">
                                <FlexImageStyle width={35} borderRadius={30} bgLight>
                                    {item.image ? <img src={item.image} alt="" /> : <span className="fa-regular fa-user"></span>}
                                </FlexImageStyle>
                            </div>
                            <Flex column className="user-card">
                                <span className="m-0 text-bold">{getModelFullName(item)}</span>

                                {item.messageStateItem && item.messageStateItem.count > 0 && (
                                    <Flex alignCenter spaceBetween>
                                        <span className="pending-messages">{item.messageStateItem.lastMessage} </span>
                                        <span className="pending-message-bubble">{item.messageStateItem.count}</span>
                                    </Flex>
                                )}
                                {item.messageStateItem && item.messageStateItem.lastMessageDate && (
                                    <span className="pending-message-date">{getDateFromNow(item.messageStateItem.lastMessageDate)}</span>
                                )}
                            </Flex>
                        </ChatUserRow>
                    ))}
                </Flex>
            ) : (
                <Flex column w100 alignCenter justifyCenter>
                    <FlexImageStyle width={200} mt={-40} mb={-30}>
                        <img src={CloudImages.networks} alt="" />
                    </FlexImageStyle>
                    <Flex alignCenter>
                        <span className="text-center">
                            No es posible usar el chat sin usuarios Si deseas agregar mas usuarios a tu organización, es necesario adquirir
                            nuevas licencias y puede hacerlo{" "}
                            <NavLink
                                to={PATHS.accountBilling}
                                onClick={() => appSettingsActions.setAllSettings({ ...appSettingsState, menuOpen: false })}
                            >
                                aquí
                            </NavLink>
                        </span>
                    </Flex>
                </Flex>
            )}
        </ChatUserListContainer>
    );
};
