import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { appIsModePos } from ".";
import { BillingCycle } from "../../api/models/accountBilling";
import { AccountPlan } from "../../api/models/accountUser";
import { OrderCommentUnreadInput, OrderSearchNotificationCount } from "../../api/models/order";
import { getOrderCommentsUnread, getOrderNotificationCount } from "../../api/orders/orderApi";
import { getOrderMetadata } from "../../api/orders/orderMetadata";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { CloudImages } from "../../assets";
import { getAuthBody } from "../../auth";
import { useIsMobileListener } from "../../components";
import AccountBillingConfigModal from "../../components/Account/Billing/AccountBillingConfigModal";
import ProfileSettingsMenu from "../../components/ProfileSettingsMenu";
import { useQuickStartUpdate } from "../../components/QuickStart/quickStartHook";
import { Flex, SquareButton, TextField } from "../../components/_controls";
import { useOrderDataSummaryContext } from "../../store/contexts/OrderDataSummaryContext";
import { useOrganizationContext } from "../../store/contexts/OrganizationContext";
import { useUserContext } from "../../store/contexts/UserContext";
import AppsDropdownGrid from "./AppsGrid";
import FreeLabelView from "./FreeLabelView";
import OrganizationTab from "./OrganizationTab";
import { HorizontalMenu, MenuButtonDesk, RightPanel } from "./styled";

interface AppHorizontalMenuProps {
    hideActions?: boolean;
    refValue?: any;
}

export const useAppMenuTitle = (title?: string) => {
    const isMobile = useIsMobileListener({ size: 700 });

    useEffect(() => {
        let appTitle = "Wahio App";
        if (isMobile && title) {
            appTitle = title;
        }
        SetAppMenuTitle(appTitle);

        return () => {
            SetAppMenuTitle("Wahio App");
        };
    }, [title, isMobile]);
};

export const SetAppMenuTitle = (title: string) => {
    let element = document.getElementById(MENU_TITLE_ID);
    if (element) {
        element.textContent = title;
    }
};

const MENU_TITLE_ID = "app_menu_title";

interface ShowBilling {
    show: boolean;
    plan?: AccountPlan;
    cycle?: BillingCycle;
}

export default function AppHorizontalMenu(props: AppHorizontalMenuProps) {
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);

    const { userState, userPermission } = useUserContext();
    const { organizationState } = useOrganizationContext();
    const user = userState.user;
    const account = user?.account;

    const quickStartUpdate = useQuickStartUpdate();

    const { orderLocalStorageState, setOrderLocalStorageState } = useOrderDataSummaryContext();
    const orderLocalStorageRef = React.useRef(orderLocalStorageState);
    const [showModalBillingConfig, setShowModalBillingConfig] = useState<ShowBilling>(() => {
        //check if the params launchCreatePlan exists in the url to show the modal, and then remove it from the url
        const urlParams = new URLSearchParams(window.location.search);
        const launchCreatePlan = urlParams.get("launchCreatePlan");
        const planCycle = urlParams.get("cycle");
        if (launchCreatePlan) {
            window.history.replaceState({}, "", window.location.pathname);
            return {
                show: true,
                plan: launchCreatePlan as AccountPlan,
                cycle: planCycle as BillingCycle,
            };
        }
        return {
            show: false,
            plan: undefined,
        };
    });

    const orderNotificationCountMutation = useMutation((data: OrderSearchNotificationCount) => getOrderNotificationCount(data), {
        onSuccess: (data) => {
            let newState = { ...orderLocalStorageRef.current, queueCount: data.queueCount, orderTempCount: data.orderTempCount };
            orderLocalStorageRef.current = newState;
            setOrderLocalStorageState(newState);
        },
    });

    const orderMetadataMutation = useMutation((accountId: string) => getOrderMetadata(accountId), {
        onSuccess: (data) => {
            let newState = { ...orderLocalStorageRef.current, orderMetadata: data };
            orderLocalStorageRef.current = newState;
            setOrderLocalStorageState(newState);
        },
    });

    const messageUnreadMutation = useMutation((data: OrderCommentUnreadInput) => getOrderCommentsUnread(data), {
        onSuccess: (data) => {
            let newState = { ...orderLocalStorageRef.current, unreadComments: data.count };
            orderLocalStorageRef.current = newState;
            setOrderLocalStorageState(newState);
        },
    });

    useEffect(() => {
        let org = organizationState.currentOrganization;
        let user = userState.user;
        if (org && user) {
            messageUnreadMutation.mutate({ organizationId: org.id ?? "INVALID", userId: user.id });
        }
        orderMetadataMutation.mutate(getAuthBody().accountId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleFetchOrderCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderLocalStorageState.lastOrderRequestId]);

    const handleFetchOrderCount = () => {
        if (userPermission.order?.read) {
            const data: OrderSearchNotificationCount = {
                accountId: getAuthBody().accountId,
                organizationId: "",
            };
            orderNotificationCountMutation.mutate(data);
        }
    };

    const onClickOpenMenu = () => {
        appSettingsActions.setAllSettings({ ...appSettingsState, menuOpen: !appSettingsState.menuOpen });
    };

    const onClickMenuSmall = () => {
        appSettingsActions.setAllSettings({ ...appSettingsState, menuSmall: !appSettingsState.menuSmall });
    };

    const modePos = appIsModePos(userState.user);

    return (
        <>
            {showModalBillingConfig.show && (
                <AccountBillingConfigModal
                    show={showModalBillingConfig.show}
                    setShow={(value) => setShowModalBillingConfig({ ...showModalBillingConfig, show: false })}
                    paramsOptions={{
                        plan: showModalBillingConfig.plan,
                        cycle: showModalBillingConfig.cycle,
                    }}
                />
            )}

            <HorizontalMenu ref={props.refValue}>
                <Flex alignCenter gap10>
                    {!modePos && (
                        <>
                            <MenuButtonDesk className="desk" onClick={() => onClickMenuSmall()}>
                                <i className="fa-sharp fa-regular fa-bars"></i>
                            </MenuButtonDesk>
                            <MenuButtonDesk className="mobile" onClick={() => onClickOpenMenu()}>
                                <i className="fa-sharp fa-regular fa-bars"></i>
                            </MenuButtonDesk>
                        </>
                    )}

                    <Flex alignCenter gap={7}>
                        <NavLink to="/">
                            <img src={CloudImages.wahioLogo100} width="25" alt="WahioLogo" />
                        </NavLink>

                        <Flex column>
                            {!modePos && account?.plan !== "free" && (
                                <Flex column>
                                    <TextField id={MENU_TITLE_ID + "FA"}>Version 30.7.0</TextField>
                                    <TextField small light>
                                        Service worker auto load
                                    </TextField>
                                </Flex>
                            )}
                            {account?.plan === "free" && <FreeLabelView onClick={() => setShowModalBillingConfig({ show: true })} />}
                        </Flex>
                    </Flex>
                </Flex>

                <RightPanel>
                    <AppsDropdownGrid />
                    <SquareButton
                        title="Ayuda"
                        colorText
                        className="MENU_BTN_HELP"
                        onClick={() => {
                            quickStartUpdate.setHidePermanently(false);
                        }}
                    >
                        <i className="fa-regular fa-question"></i>
                    </SquareButton>
                    {modePos && <OrganizationTab smallView />}
                    {userState.user && <ProfileSettingsMenu />}
                </RightPanel>
            </HorizontalMenu>
        </>
    );
}
