import CryptoJS from "crypto-js";

export const encrypted = content => {
    var words = CryptoJS.enc.Utf8.parse(content);
    return CryptoJS.enc.Base64.stringify(words);
};
export const decrypted = encrypted => {
    var words = CryptoJS.enc.Base64.parse(encrypted);
    return CryptoJS.enc.Utf8.stringify(words);
};
