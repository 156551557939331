import { IShippingLabel, IShippingLabelOrganization, ShippingLabelOrganization } from "../../api/models/deliveryOrder";
import { IOrganization } from "../../api/models/organizationTypes";

export const getShippingLabelOrganization = (shippingLabel?: IShippingLabel, organization?: IOrganization) => {
    let label: IShippingLabelOrganization = {
        ...(organization?.shippingLabel ?? new ShippingLabelOrganization()),
        ...shippingLabel,
    };
    return label;
};
