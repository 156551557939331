import styled from "styled-components";

export const TransactionAccountFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    padding-top: 5px;
`;
export const TransactionAccountTabBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .transaction-amount {
        border-radius: 25px;
        width: 100%;
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const TransactionAccountListHeader = styled.div`
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const TransactionAccountCardViewStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 13px 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    border-radius: 15px;
    transition: box-shadow 0.2s;

    &:hover {
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowColor};
    }

    &.selected {
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowColor};
    }

    .flex {
        display: flex;
        align-items: baseline;
    }
    .space-between {
        justify-content: space-between;
    }
`;

export const TransactionAccountProfileStyle = styled.div`
    padding: 20px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.hide-border {
        border: 0;
    }
`;

export const TransactionPaymentCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px;
    &:hover {
        border-radius: 15px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    .method-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.cash {
            color: #00c853;
        }
        &.transfer {
            color: #ff5722;
        }
        &.card,
        &.creditCard,
        &.debitCard {
            color: #5e35b1;
        }
    }
`;
