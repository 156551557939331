import { CashRegisterLog } from "../../api/models/cashRegisterLog";
import { IOrganization } from "../../api/models/organizationTypes";
import { IAppSettingsState } from "../../appSettings/types";

export function getOrgCashRegisterOrDefault(appSettings: IAppSettingsState, currentOrganization?: IOrganization) {
    return appSettings.activeLogCashRegisterId ? appSettings.activeLogCashRegisterId : currentOrganization?.defaultCashRegisterId;
}

export const getCashRegisterOriginId = (currentOrganization?: IOrganization, cashRegisterActive?: CashRegisterLog) => {
    if (cashRegisterActive?.cashRegisterId) return cashRegisterActive.cashRegisterId;
    return currentOrganization?.defaultCashRegisterId ?? "";
};
