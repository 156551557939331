import moment from "moment";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { OrderInvoiceFormatProps } from ".";
import { TRACK_ENDPOINT } from "../../api/appConfig";
import { getAccountUserFullName } from "../../api/models/accountUser";
import { IDENTIFICATION_TYPES } from "../../constants";
import translate from "../../i18n/translate";
import globalMessages from "../../messages";
import { AccountUserContext } from "../../store/contexts/AccountUserContext";
import { OrganizationContext } from "../../store/contexts/OrganizationContext";
import { formatMoney, getDateFormat, getDateFormatll, joinUrl } from "../../utils";
import { getItemTaxPercentage } from "../../utils/items";
import OrderProfileMessages from "../Orders/OrderProfile/messages";
import InvoiceFormatRestaurantActive from "./InvoiceFormatRestaurantActive";
import { CardBodySmall, InvoiceDesignCardSmall, InvoiceTableSmall } from "./styled";

import QRCode from "qrcode.react";
import { TitleTemplate } from "../../api/models/organizationTypes";
import { getCodeAndReference, getProductSku } from "../Products/helper";
import { Flex, TextField } from "../_controls";

export default function InvoiceFormatSmall(props: OrderInvoiceFormatProps) {
    const intl = useIntl();
    const { order, testMode, template, printSettings } = props;
    const { accountUserState } = useContext(AccountUserContext);
    const { organizationState } = useContext(OrganizationContext);

    const customer = order.customer;
    const delivery = order.deliveryOrder;
    const organization = organizationState.currentOrganization;
    const disabledFields = printSettings?.disabledFields ?? organization?.settings?.orderPrint?.disabledFields ?? {};

    const titleTemplate = React.useMemo(() =>
        printSettings?.titleTemplate ??
        organization?.settings?.orderPrint?.titleTemplate ?? {}
        , [organization?.settings?.orderPrint?.titleTemplate, printSettings?.titleTemplate]);

    const customTitles = React.useMemo(() => {
        const value: TitleTemplate = {
            title1: titleTemplate.title1 ?? template.invoiceTemplateTitle1,
            title2: titleTemplate.title2 ?? template.invoiceTemplateTitle2,
            title3: titleTemplate.title3 ?? template.invoiceTemplateTitle3,
        };
        return value;
    }, [titleTemplate, template]);

    if (!order) return null;

    const getCustomer = () => {
        if (customer) {
            return customer.firstName + " " + customer.lastName;
        }
        return "Cliente General";
    };

    const getCustomerIdentification = () => {
        let type = customer?.identificationType;
        if (customer?.identificationType) {
            type = IDENTIFICATION_TYPES.find((x) => x.id === customer.identificationType)?.value ?? type;
        }
        return type + ": " + customer?.identificationNumber;
    };

    const getCustomerCity = () => {
        if (customer) {
            const city = customer.addresses.find((x) => x.description === customer.address)?.city;
            return city;
        }
        return "";
    };

    const needPayments = order.totalAmount - order.totalPaid > 0;
    let orderDate = order.dateCreatedCustom ?? order.dateCreated;
    const restaurantActive = order.isRestaurant && !order.isRestaurantClose && !props.completeOrder;

    if (restaurantActive) return <InvoiceFormatRestaurantActive {...props} />;

    return (
        <InvoiceDesignCardSmall>
            <CardBodySmall>
                {props.image && (
                    <div className="image-container">
                        <div className="image">
                            <img src={props.image} alt="Org" />
                        </div>
                    </div>
                )}
                <div className="border">
                    {customTitles.title1 && <span className="bold center mb-1">{customTitles.title1}</span>}
                    {customTitles.title2 && <span className="center mb-1">{customTitles.title2}</span>}

                    {organization?.storePhone && <span>{`Tel: ${organization.storePhone}`}</span>}
                    {organization?.address && <span>Dirección: {organization.address}</span>}
                    {!disabledFields.user && <span>Elaborada por: {getAccountUserFullName(accountUserState.items.find((x) => x.id === order.accountUserId))}</span>}
                    {!disabledFields.orderDate && <TextField>{`Fecha: ${getDateFormat(orderDate, "DD MMM YYYY  hh:mm:a")}`}</TextField>}
                    {!disabledFields.seller && order.contactSellerId && <span>Vendedor: {getAccountUserFullName(order.contactSeller)}</span>}

                    {(!disabledFields.orderNumber || !disabledFields.invoiceNumber) &&
                        <>
                            {!disabledFields.orderNumber && <TextField>Orden # {order.number} </TextField>}
                            {!disabledFields.invoiceNumber && <TextField>Factura de venta # {order.orderInvoice?.number}</TextField>}
                        </>
                    }
                    {!disabledFields.eBillingNumber && order.eBillingNumber && <span>Fact Electronica # {order.eBillingNumber}</span>}
                    {!disabledFields.orderStatus &&
                        <>
                            {!needPayments ? (
                                <span className="">Estado: {translate(OrderProfileMessages[order.status]).props.defaultMessage}</span>
                            ) : (
                                <span className="status-card mt-1">
                                    ••• {translate(OrderProfileMessages[order.status]).props.defaultMessage} •••
                                </span>
                            )}
                        </>
                    }
                </div>
                <div className="border mt-1">
                    <span className="bold">Cliente: {getCustomer()}</span>
                    {customer?.phoneNumber && <span>Teléfono {customer?.phoneNumber ? customer.phoneNumber : " - - -"}</span>}
                    {customer?.identificationNumber && <span>{getCustomerIdentification()}</span>}
                    {!delivery && customer?.address && <span>Dirección: {customer.address}</span>}
                    {!delivery && customer?.addresses && customer.addresses.length > 0 && <span>Ciudad: {getCustomerCity()}</span>}
                </div>

                <div className="border mt-1">
                    {order.payments && order.payments.length > 0 && (
                        <>
                            <span className="bold">Total pagado: {formatMoney(order.totalPaid)}</span>
                            {order.payments.map((item, index) => (
                                <Flex key={index} marginTop={2}>
                                    <TextField>{`${intl.formatMessage(globalMessages[item.method])}: ${formatMoney(item.amount)} 
                                        ${!disabledFields.paymentsDate ? `- ${getDateFormatll(item.dateCreated)}` : ""}`}
                                    </TextField>
                                </Flex>
                            ))}
                        </>
                    )}

                    {order.status === "partial" && needPayments && (
                        <span className="status-card mt-1">Saldo Pendiente: {formatMoney(order.totalAmount - order.totalPaid)}</span>
                    )}
                    {!disabledFields.dueDate && order.totalAmount > order.totalPaid && (
                        <>
                            <span className="bold mt-1">Vencimiento: Término {order.paymentTermsDays} Días</span>
                            <span>
                                Vence:{" "}
                                {moment(order.dateCreatedCustom ?? order.dateCreated)
                                    .add(order.paymentTermsDays, "days")
                                    .format("LL")}
                            </span>
                        </>
                    )}
                </div>
                {!testMode && (
                    <>
                        <InvoiceTableSmall className="mt-2" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th>Descripción</th>
                                    <th className="right">Total</th>
                                </tr>
                                {order.items &&
                                    order.items.map((item, index) => {
                                        if (item.product) {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col">
                                                            <span>{item.product.name}</span>
                                                            {!disabledFields.itemSku && <span>{`Sku: ${getProductSku(item.product, item.productVariant) ?? "---"}`}</span>}
                                                            {!disabledFields.itemCode && <span>{`Código: ${getCodeAndReference(item) ?? "---"}`}</span>}
                                                            {!disabledFields.itemReference && <span>{`Referencia: ${getCodeAndReference(item, true) ?? "---"}`}</span>}
                                                            <span>
                                                                {formatMoney(item.unitPrice)} x {item.quantity - item.quantityReturned}
                                                                {item.quantityReturned > 0 && ` (${-item.quantityReturned})`}
                                                                {!disabledFields.itemDiscount && item.manualPercentageDiscount ? " -" + item.manualPercentageDiscount + "%" : ""}
                                                                {!disabledFields.itemTax && item.totalTax > 0 && `  imp: ${getItemTaxPercentage(item)}%`}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="right">
                                                        {formatMoney(item.unitPrice * (item.quantity - item.quantityReturned))}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                            </tbody>
                        </InvoiceTableSmall>
                        {!disabledFields.itemsCount &&
                            <TextField bold className="mt-1">{`Cantidad total de artículos: ${order.itemsCount}`}</TextField>
                        }
                    </>
                )}
                {delivery && (
                    <div className={"border mt-1"}>
                        <>
                            <span className="bold">Enviar a:</span>
                            <span>Contacto: {delivery.contactName}</span>
                            <span>Teléfono: {delivery.phoneNumber}</span>
                            <span>
                                Ciudad: {delivery.city} / {delivery.state}
                            </span>
                            <span>Dirección: {delivery.address}</span>
                            <span>Barrio: {delivery.neighborhood}</span>
                            <span>Datos adicionales: {delivery.additionalInformation}</span>
                        </>
                    </div>
                )}
                {customTitles.title3 && <span className="mt-1">{customTitles.title3}</span>}
                {order.comment && (
                    <div className="border mt-2">
                        <span>Observaciones: {order.comment}</span>
                    </div>
                )}

                <div className="border mt-1">
                    {order.customerPayment && (
                        <Flex marginTop={10} marginBottom={20}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Dinero recibido</td>
                                        <td>{formatMoney(order.customerPayment.amountGiven)}</td>
                                    </tr>
                                    <tr>
                                        <td>Cambio</td>
                                        <td>{formatMoney(order.customerPayment.changeReturned)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Flex>
                    )}
                    <table>
                        <tbody>
                            <tr>
                                <td>Subtotal</td>
                                <td>{formatMoney(order.subTotal)}</td>
                            </tr>
                            {!disabledFields.itemTax &&
                                <tr>
                                    <td>Impuesto</td>
                                    <td>{formatMoney(order.totalTaxItems)}</td>
                                </tr>
                            }
                            {!disabledFields.itemDiscount && order.totalManualPercentageDiscountItems > 0 && (
                                <tr>
                                    <td>Descuento</td>
                                    <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                                </tr>
                            )}
                            {!disabledFields.itemDiscount && order.totalDiscount > 0 && (
                                <tr>
                                    <td>Descuento General</td>
                                    <td>{formatMoney(order.totalDiscount)}</td>
                                </tr>
                            )}
                            {order.totalAmountReturns > 0 && (
                                <tr>
                                    <td>Devolución</td>
                                    <td>{formatMoney(order.totalAmountReturns)}</td>
                                </tr>
                            )}
                            {order.totalTip > 0 && (
                                <tr>
                                    <td>Propina</td>
                                    <td>{formatMoney(order.totalTip)}</td>
                                </tr>
                            )}
                            {order.totalDelivery > 0 && (
                                <tr>
                                    <td>Envío</td>
                                    <td>{formatMoney(order.totalDelivery)}</td>
                                </tr>
                            )}
                            <tr>
                                <td className="bold">TOTAL</td>
                                <td className="bold">{formatMoney(order.totalAmount)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {props.numeration && props.numeration.description && <div className="mt-2">{props.numeration.description}</div>}
                {!disabledFields.sign &&
                    <Flex column gap={20} marginTop={20}>
                        <div className="sign-space">
                            <br />
                            <br />
                            <hr />
                            <span>{organization?.name}, Firma y Sello</span>
                        </div>
                        <div className="sign-space">
                            <br />
                            <br />
                            <hr />
                            <span>Cliente, Firma y Sello</span>
                        </div>
                    </Flex>
                } 
                {!disabledFields.qrTracking &&
                    <Flex justifyCenter alignCenter marginTop={20} column gap15>
                        <span>Escaneame</span>
                        <Flex border borderRadius={10} padding={10}>
                            <QRCode size={80} value={joinUrl(TRACK_ENDPOINT, order.trackingNumber ?? "")} />
                        </Flex>
                    </Flex>
                }

                {!testMode && <div className="footer-subtitle mt-1">Software By Wahio, suite.wahio.com</div>}

                {/* {testMode && (
                    <>
                        <FakeView className="invoice-details">
                            <span></span>
                            <span></span>
                        </FakeView>
                        <FakeView large={true} className="invoice-details">
                            <span></span>
                        </FakeView>
                        <FakeView>
                            <span></span>
                        </FakeView>
                    </>
                )} */}
            </CardBodySmall>
        </InvoiceDesignCardSmall>
    );
}
