import { defineMessages } from "react-intl";

const base = "pages.createPurchase";

export default defineMessages({
    createOrder: {
        id: `${base}.createOrder`,
        defaultMessage: "Crear Compra",
    },
    editOrden: {
        id: `${base}.editOrden`,
        defaultMessage: "Editar orden",
    },
    finish: {
        id: `${base}.finish`,
        defaultMessage: "Finalizar compra",
    },
    price: {
        id: `${base}.price`,
        defaultMessage: "Precio",
    },
    warehouseDestiny: {
        id: `${base}.warehouseDestiny`,
        defaultMessage: "Bodega Destino",
    },
    supplier: {
        id: `${base}.supplier`,
        defaultMessage: "Proveedor",
    },
    delivery: {
        id: `${base}.delivery`,
        defaultMessage: "Envío",
    },
    deliveryCost: {
        id: `${base}.deliveryCost`,
        defaultMessage: "Costo de envío",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    subTotal: {
        id: `${base}.subTotal`,
        defaultMessage: "SubTotal",
    },
    addCustomerToContinue: {
        id: `${base}.addCustomerToContinue`,
        defaultMessage: "Agrega un cliente para continuar",
    },
    noProductSelected: {
        id: `${base}.noProductSelected`,
        defaultMessage: "Seleccione un producto para crear una nueva orden",
    },
    finishPaid: {
        id: `${base}.finishPaid`,
        defaultMessage: "Finalizar Pagado",
    },
    finishPartial: {
        id: `${base}.finishPartial`,
        defaultMessage: "Finalizar con pago parcial",
    },
    finishCredit: {
        id: `${base}.finishCredit`,
        defaultMessage: "Finalizar como crédito",
    },
    payments: {
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },
    addPayment: {
        id: `${base}.addPayment`,
        defaultMessage: "Agregar pago",
    },
    addOtherPayment: {
        id: `${base}.addOtherPayment`,
        defaultMessage: "Agregar otro pago",
    },
    paymentExceedsOrderValue: {
        id: `${base}.paymentExceedsOrderValue`,
        defaultMessage: "El pago supera el valor de la orden",
    },
    cancelModal: {
        id: `${base}.cancelModal`,
        defaultMessage: "Cancelar",
    },
    amount: {
        id: `${base}.amount`,
        defaultMessage: "Valor",
    },
    discount: {
        id: `${base}.discount`,
        defaultMessage: "Descuento",
    },
    tip: {
        id: `${base}.tip`,
        defaultMessage: "Propina",
    },
    supplierWarehouseRequired: {
        id: `${base}.supplierWarehouseRequired`,
        defaultMessage: "El proveedor y la bodega son requeridos.",
    },
    thisOptionAllowsRestartTheOrder: {
        id: `${base}.thisOptionAllowsRestartTheOrder`,
        defaultMessage: "Esta opción permite reiniciar la orden",
    },
    saveYourOrderAndContinueLater: {
        id: `${base}.saveYourOrderAndContinueLater`,
        defaultMessage: "Guardar orden y continuar mas tarde",
    },
    importPurchase: {
        id: `${base}.importPurchase`,
        defaultMessage: "Importar compra",
    },
});
