import React, { useState, useRef } from "react";
import TextBoxNumeral, { ITextBoxNumeralProps } from ".";

export interface ITextBoxNumberTimerProps extends ITextBoxNumeralProps {
    milliseconds: number;
    numberLimit?: number;
}

export const TextBoxNumberTimer = React.forwardRef<HTMLInputElement, ITextBoxNumberTimerProps>((props, ref) => {
    const [searchState, setSearchState] = useState({
        value: props.value ? props.value : 0,
        keyValue: props.value,
        waiting: false,
    });

    const timeValue = useRef(setTimeout(() => {}, 10));

    function timeWaiting(value: number) {
        clearTimeout(timeValue.current);
        timeValue.current = setTimeout(() => {
            if (props.numberLimit && value > props.numberLimit) {
                value = props.numberLimit;
            }
            setSearchState({ ...searchState, value, waiting: false });
            props.onNumberChange(value);
        }, props.milliseconds);
    }

    const onChangeHandle = (value: number) => {
        timeWaiting(value);
        if (!searchState.waiting) {
            setSearchState({ ...searchState, waiting: true });
        }
    };

    const onBlur = (e: any) => {
        if (props.onBlur) {
            props.onBlur(e.target.value);
        }
    };

    return <TextBoxNumeral ref={ref} autoComplete="off" id={props.id} onBlur={onBlur} {...props} onNumberChange={onChangeHandle} />;
});

export default TextBoxNumberTimer;
