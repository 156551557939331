import { ProductOptional } from "../../../api/models/product";

export function getOptionalSelectionText(product: ProductOptional): string {
    let message = "";

    if (product.minSelection && product.maxSelection) {
        message =
            product.minSelection === product.maxSelection
                ? `Selecciona ${product.minSelection}`
                : `Selecciona mínimo ${product.minSelection} y máximo ${product.maxSelection}`;
    } else if (product.minSelection) {
        message = `Selecciona mínimo ${product.minSelection}`;
    } else if (product.maxSelection) {
        message = `Selecciona máximo ${product.maxSelection}`;
    }

    return message;
}

export const getOptionalsSelectedMessages = (optionals: ProductOptional[]) => {
    let messagesList: string[] = [];
    optionals.forEach((optional) => {
        if (optional.items) {
            optional.items.forEach((item) => {
                if (item.selected) {
                    let value = item.name;
                    if (item.selectedQuantity && item.selectedQuantity > 1) {
                        value = `${item.name} x ${item.selectedQuantity}`;
                    }
                    messagesList.push(value);
                }
            });
        }
    });

    return messagesList.join(", ");
};
