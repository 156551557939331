import { defineMessages } from "react-intl";

const base = "components.contactLabels";

export default defineMessages({
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre",
    },
    contactSuccessfully: {
        id: `${base}.contactSuccessfully`,
        defaultMessage: "Etiqueta de contacto actualizada con éxito",
    },
    labelName: {
        id: `${base}.labelName`,
        defaultMessage: "Nombre de la etiqueta",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    save: {
        id: `${base}.save`,
        defaultMessage: "Guardar",
    },
    newLabel: {
        id: `${base}.newLabel`,
        defaultMessage: "Nueva Etiqueta",
    },
    searchLabels: {
        id: `${base}.searchLabels`,
        defaultMessage: "Buscar Etiquetas",
    },
    nameTwo: {
        id: `${base}.nameTwo`,
        defaultMessage: "Nombre",
    },
    color: {
        id: `${base}.color`,
        defaultMessage: "color",
    },
    created: {
        id: `${base}.created`,
        defaultMessage: "Creado",
    },
    labelList: {
        id: `${base}.labelList`,
        defaultMessage: "Lista de Etiquetas",
    },
    editLabel: {
        id: `${base}.editLabel`,
        defaultMessage: "Editar Etiqueta",
    },
    createLabel: {
        id: `${base}.createLabel`,
        defaultMessage: "Crear Etiqueta",
    },
    updateLabel: {
        id: `${base}.updateLabel`,
        defaultMessage: "Actualizar Etiqueta",
    },
    contactUpdated: {
        id: `${base}.contactUpdated`,
        defaultMessage: "Etiqueta de contacto actualizada con éxito",
    },
    contactRemoved: {
        id: `${base}.contactRemoved`,
        defaultMessage: "Etiqueta de contacto eliminada",
    },
    youAreSure: {
        id: `${base}.youAreSure`,
        defaultMessage: "¿Estas seguro?",
    },
    actionCannotReversed: {
        id: `${base}.actionCannotReversed`,
        defaultMessage: "Esta acción no se puede revertir",
    },
    nameThree: {
        id: `${base}.nameThree`,
        defaultMessage: "Nombre",
    },
    
});
