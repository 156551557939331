export interface KeyParamMapping<T, P> {
    key: keyof T;
    param: P;
    decode?: (value: string) => any;
}

export const getParamBackLink = (name: string, path: string) => {
    return `name:${name}&path:${path}`;
};

export const getParamBackLinkObj = (backLink: string) => {
    const regex = /name:(.*?)&path:(.*)/;
    const match = backLink.match(regex);
    if (match) {
        const name = match[1];
        const path = match[2];

        return { name, path };
    }

    return { name: "", path: "" };
};
