import React, { useEffect, useRef, useState } from "react";
import { useEventListenerDocument } from "../..";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { IProduct } from "../../../api/models/product";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { IWarehouseLocation } from "../../../api/models/warehouseLocation";
import { productEndpoint, purchaseEndpoint } from "../../../api/restApiEndpoint";
import { COLOR_LIST } from "../../../constants/colors";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex, PrimaryButton } from "../../_controls";

import PurchaseItemReceive from "./PurchaseItemReceive";
import { NavigationGroupName } from "./menuHelper";
import { BarcodeContainer, ReceivedTitleContainer, TableReceivedCardContainer } from "./styled";

interface TableReceiveItemsProps {
    purchase: IPurchase;
    onChange: (value: IPurchase) => void;
    setChangeTabKey: (value: NavigationGroupName) => void;
}

interface IProductBarcodeResult {
    lastProduct?: IProduct;
    lastLocation?: IWarehouseLocation;
    product?: IProduct;
    location?: IWarehouseLocation;
}

const getInitialProductResult = () => {
    var result: IProductBarcodeResult = {
        product: undefined,
        location: undefined,
    };

    return result;
};

export default function TableReceiveItems(props: TableReceiveItemsProps) {
    const isMounted = React.useRef(true);
    const { purchase } = props;
    const [locationList, setLocationList] = useState<IWarehouseLocation[]>([]);
    const allowLogisticsServices = !!purchase.warehouse?.allowLogisticsService;

    const matchCodeLecture = useRef<IProductBarcodeResult>({
        product: undefined,
        location: undefined,
    });

    const [productBarcodeResult, setProductBarcodeResult] = useState<IProductBarcodeResult>(getInitialProductResult());

    const alert = useAlert();

    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        handleGetWarehouseLocations();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGetWarehouseLocations = () => {
        if (!isMounted.current) return;
        wahioFetch.get(
            productEndpoint.get.warehouseLocationsByWarehouse(purchase.warehouseId),
            (success) => {
                setLocationList(success.data);
            },
            (error) => {
                alert.error("Error al leer las ubicaciones");
            }
        );
    };
    const updatePurchaseItemQuantity = (codeLecture: IProductBarcodeResult) => {
        const purchaseItem = purchase.items.find((x) => x.productId === codeLecture.product?.id);
        if (!purchaseItem) return;

        let value = purchaseItem.quantityReceivedTemp ?? 0;
        value = value + 1;

        const diference = purchaseItem.quantity - purchaseItem.quantityReturned - (purchaseItem.quantityReceived + value);
        let copyValue = value;
        if (diference < 0) {
            alert.info("No puedes agregar más unidades a " + purchaseItem.product?.name);
            copyValue = purchaseItem.quantity - purchaseItem.quantityReceived;
            return;
        }

        if (copyValue < 0) {
            copyValue = 0;
        }
        let copyItem = {
            ...purchaseItem,
            quantityReceivedTemp: copyValue,
            locationCode: codeLecture.location?.code,
            location: codeLecture.location,
        };

        alert.success(purchaseItem.product?.name + " agregado en " + codeLecture.location?.code);
        onChangeItem(copyItem);
    };

    let barcodeValue = "";
    let barcodeInterval: any = undefined;
    const handler = (e: any) => {
        if (barcodeInterval) {
            clearInterval(barcodeInterval);
        }
        if (e.code === "Enter") {
            if (barcodeValue) {
                barcodeValue = barcodeValue.replaceAll("Shift", "");
                if (!purchase.items) {
                    barcodeValue = "";
                    return;
                }

                var item = purchase.items.find((x) => x.product?.code === barcodeValue);

                if (item) {
                    let newProduct = matchCodeLecture.current.lastProduct && item.productId !== matchCodeLecture.current.lastProduct?.id;

                    matchCodeLecture.current = {
                        ...matchCodeLecture.current,
                        location: newProduct ? undefined : matchCodeLecture.current.location,
                        product: item.product,
                        lastProduct: item.product,
                    };
                } else {
                    var locationResult = locationList.find((x) => x.code.toUpperCase() === barcodeValue.toUpperCase());

                    if (locationResult) {
                        var removeLocation =
                            matchCodeLecture.current.lastLocation && locationResult.code !== matchCodeLecture.current.lastLocation.code;

                        if (matchCodeLecture.current.lastProduct) {
                            if (matchCodeLecture.current.product?.id === matchCodeLecture.current.lastProduct?.id) {
                                removeLocation = false;
                            }
                        }

                        if (locationResult.code === matchCodeLecture.current.location?.code) {
                            alert.info("Ya extá activa la ubicacion: " + locationResult.code);
                        }

                        matchCodeLecture.current = {
                            ...matchCodeLecture.current,
                            location: locationResult,
                            lastLocation: locationResult,
                            product: removeLocation ? undefined : matchCodeLecture.current.product,
                            lastProduct: removeLocation ? undefined : matchCodeLecture.current.lastProduct,
                        };
                    } else {
                        alert.info("No se encontro ningún resultado");
                    }
                }
                barcodeValue = "";
                if (matchCodeLecture.current.product && matchCodeLecture.current.location) {
                    if (matchCodeLecture.current.product.includesSerials) {
                        alert.info(`Debes agregar los seriales de ${matchCodeLecture.current.product.name}`);
                    } else {
                        updatePurchaseItemQuantity(matchCodeLecture.current);
                    }
                    matchCodeLecture.current = {
                        ...matchCodeLecture.current,
                        location: matchCodeLecture.current.location,
                        product: undefined,
                    };
                }
                setProductBarcodeResult(matchCodeLecture.current);
                return;
            }
            return;
        }
        if (e.code !== "Shift") {
            barcodeValue += e.key;
        }
        barcodeInterval = setInterval(() => (barcodeValue = ""), 20);
    };
    // Add event listener using our hook
    useEventListenerDocument("keydown", handler);

    const resetProductAndLocationLecture = () => {
        matchCodeLecture.current = {
            lastLocation: undefined,
            lastProduct: undefined,
            location: undefined,
            product: undefined,
        };
        setProductBarcodeResult({
            product: undefined,
            lastProduct: undefined,
        });
    };

    const onSaveReceive = () => {
        let copyItems: IPurchaseItem[] = [];
        let productsWithoutLocations: string[] = [];
        purchase.items.forEach((element) => {
            if (props.purchase.warehouse?.allowLogisticsService && element.quantityReceivedTemp > 0 && !element.locationCode) {
                productsWithoutLocations.push(element.product?.name ?? element.productId);
            }
        });

        if (productsWithoutLocations.length > 0) {
            alert.info(`Debes seleccionar las ubicaciones de los siguientes productos: ${productsWithoutLocations.join(", ")}`);
            return;
        }

        props.purchase.items.forEach((item) => {
            let element = { ...item };
            let quantityNew = element.quantityReceivedTemp ? element.quantityReceivedTemp : 0;
            let newTotalQuantity = element.quantityReceived + quantityNew;
            if (newTotalQuantity > element.quantity) {
                newTotalQuantity = element.quantity;
            }
            let serials = element.serials ?? [];
            serials = [...serials, ...(element.newSerials ?? [])];
            element.serials = serials;
            element.quantityReceived = newTotalQuantity;

            copyItems.push(element);
        });

        let purchaseToUpdate = { ...props.purchase, items: copyItems };

        setIsFetching(true);
        wahioFetch.post(
            purchaseEndpoint.post.purchaseAll,
            { ...purchaseToUpdate },
            (success) => {
                let newPurchase: IPurchase = success.data
                props.onChange(newPurchase);
                if (newPurchase.items.filter(x => x.quantity === x.quantityReceived).length === purchase.items.length) {
                    props.setChangeTabKey(NavigationGroupName.Profile)
                }
                setIsFetching(false);
                resetProductAndLocationLecture();
                alert.success("La compra fue actualizada con éxito");
            },
            (error) => {
                setIsFetching(false);
                let errorResult = getServiceMessageError(error);

                if (errorResult) {
                    if (Array.isArray(errorResult)) {
                        errorResult.forEach((element: any) => {
                            alert.error(element.message);
                        });
                    } else {
                        alert.error(errorResult);
                    }
                } else {
                    alert.error("Hubo un error al intentar actualizar la compra");
                }
            }
        );
    };
    const onChangeItem = (item: IPurchaseItem) => {
        let newItems: IPurchaseItem[] = [];

        if (item.locationCode) {
            item.location = locationList.find((x) => x.code.toUpperCase() === item.locationCode?.toUpperCase());
            if (item.location) {
                item.locationCode = item.locationCode.toUpperCase();
            }
        }

        purchase.items.forEach((value) => {
            if (value.id === item.id) {
                newItems.push(item);
            } else {
                newItems.push(value);
            }
        });

        props.onChange({ ...purchase, items: newItems });
    };

    const sumeNewEntries = () => {
        let sum = 0;
        purchase.items.forEach((item) => {
            sum += item.quantityReceivedTemp ? item.quantityReceivedTemp : 0;
        });
        return sum;
    };
    const onConfirmAll = () => {
        let productsWithSerials = purchase.items.filter(x => x.product?.includesSerials)
        if (productsWithSerials && productsWithSerials.filter(x => x.newSerials?.length !== x.quantity).length > 0) {
            alert.error("No se pueden agregar cantidades a productos sin seriales")
            return
        }
        let copyItems: IPurchaseItem[] = [];
        props.purchase.items.forEach((element) => {
            element.quantityReceivedTemp = element.quantity - element.quantityReceived;
            copyItems.push(element);
        });
        let purchaseToUpdate = { ...props.purchase, items: copyItems };
        props.onChange(purchaseToUpdate);
    };
    if (!purchase.items) return null;

    const newEntriesCount = sumeNewEntries();

    const selectedProductItem = productBarcodeResult.lastProduct && purchase.items.find((x) => x.product?.id === productBarcodeResult.lastProduct?.id);

    return (
        <TableReceivedCardContainer className={selectedProductItem ? "item-selected" : ""}>
            <ReceivedTitleContainer>
                <h3 className="mr-1">Recibir</h3>
                {productBarcodeResult.location && (
                    <BarcodeContainer color={(COLOR_LIST as any)[productBarcodeResult.location.color]}>
                        {productBarcodeResult.location.code}
                    </BarcodeContainer>
                )}
                {productBarcodeResult.lastProduct && !productBarcodeResult.location && (
                    <BarcodeContainer>
                        <span className="wahioicon-clock mr-1"></span>
                        <span>Esperando ubicación</span>
                    </BarcodeContainer>
                )}
                {productBarcodeResult.lastLocation && !productBarcodeResult.lastProduct && (
                    <BarcodeContainer>
                        <span className="wahioicon-clock mr-1"></span>
                        <span>Esperando Producto</span>
                    </BarcodeContainer>
                )}
                {productBarcodeResult.lastProduct && productBarcodeResult.location && (
                    <DefaultButton className="ml-1" rounded onClick={() => resetProductAndLocationLecture()}>
                        <span className="wahioicon-times"></span> Quitar Foco
                    </DefaultButton>
                )}
            </ReceivedTitleContainer>
            <div className="mt-2">
                <div className="items-container">
                    <div className="fake-container">
                        {selectedProductItem && (
                            <PurchaseItemReceive
                                allowLogisticsService={allowLogisticsServices}
                                isSelected={true}
                                purchaseItem={selectedProductItem}
                                onChange={onChangeItem}
                                locations={locationList}
                            />
                        )}
                    </div>
                    {purchase.items
                        .filter((x) => x.quantityReceived < x.quantity - x.quantityReturned)
                        .map((item, index) => {
                            if (productBarcodeResult.lastProduct?.id === item.productId) return null;
                            return (
                                <PurchaseItemReceive
                                    allowLogisticsService={allowLogisticsServices}
                                    isSelected={productBarcodeResult.lastProduct?.id === item.productId}
                                    purchaseItem={item}
                                    onChange={onChangeItem}
                                    key={item.id ?? index}
                                    locations={locationList}
                                />
                            );
                        })}
                </div>
                {isFetching ? (
                    <LoadingDualRing center={true} />
                ) : (
                    <div className="mt-2">
                        <Flex gap10 justifyEnd>
                            <DefaultButton borderRadius={10} bgLight onClick={onConfirmAll}>
                                Agregar restante
                            </DefaultButton>
                            {newEntriesCount > 0 && (
                                <PrimaryButton borderRadius={10} bgLight onClick={onSaveReceive}>
                                    Recibir: {newEntriesCount}
                                </PrimaryButton>
                            )}
                        </Flex>
                    </div>
                )}
            </div>
        </TableReceivedCardContainer>
    );
}
