import { getStoredData, LocalStoreKeys } from "..";
import {
    ADD_ORDER_CHANNEL,
    OrderChannelTypes,
    IOrderChannel,
    IOrderChannelState,
    INVALID_REQUEST_ORDER_CHANNELS,
    RECEIVE_ORDER_CHANNELS,
    REQUEST_ORDER_CHANNELS,
    UPDATE_ORDER_CHANNEL,
} from "../../api/models/orderChannel";

export class InitStateBase implements IOrderChannelState {
    items: IOrderChannel[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IOrderChannelState>(new InitStateBase(), LocalStoreKeys.orderChannel);

export const orderChannelReducer = (state: IOrderChannelState, action: OrderChannelTypes): IOrderChannelState => {
    switch (action.type) {
        case ADD_ORDER_CHANNEL:
            return {
                ...state,
                items: [...state.items, action.value],
            };
        case UPDATE_ORDER_CHANNEL:
            let newlist: IOrderChannel[] = [];

            state.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                items: newlist,
            };
        case RECEIVE_ORDER_CHANNELS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_ORDER_CHANNELS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_ORDER_CHANNELS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
