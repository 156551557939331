import React from "react";
import Modal, { IModalShow } from "../../../modals/Modal";
import { useIntl } from "react-intl";
import messages from "./messages";
import TaxOrderItem, { TaxOrderItemProps } from ".";

interface IProps extends TaxOrderItemProps, IModalShow {}

export default function TaxOrderItemModal(props: IProps) {
    const intl = useIntl();
    
    return (
        <Modal
            id="moda-order-item-tax"                  
            scrollInside={false}
            overflowAuto={false}
            title={intl.formatMessage(messages.taxes)}
            showHeader={true}
            {...props}>
            <TaxOrderItem {...props}  />
        </Modal>
    );
}
