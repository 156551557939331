import { Order, OrderItem } from "./order";
import { ITaxOrderReturnItem } from "./orderBase";
import { IOrderReturnPayment } from "./orderPayment";
import { IProduct, IProductVariant } from "./product";
import { IPurchaseItem } from "./purchase";
import { IWarehouse } from "./warehouse";

export interface IOrderReturn {
    id?: string;
    orderId: string;
    newOrderId?: string;
    order?: Order;
    number: number;
    cashRegisterId: string;
    accountId: string;
    accountUserId: string;
    items: IOrderReturnItem[];
    newItems: OrderItem[];
    dateCreated?: Date;
    totalAmount: number;
    totalAmountItems: number;
    orderInvoiceNumerationId: string;
    totalManualPercentageDiscountItems: number;
    totalTaxItems: number;
    totalItems: number;
    payments: IOrderReturnPayment[];
    returnPayments: IOrderReturnPayment[];
    organizationId: string;
    documentType: string;
}

export class OrderReturn implements IOrderReturn {
    orderId: string = "";
    order?: Order | undefined;
    number: number = 0;
    cashRegisterId: string = "";
    accountId: string;
    accountUserId: string;
    items: IOrderReturnItem[] = [];
    newItems: OrderItem[] = [];
    dateCreated?: Date | undefined;
    totalAmount: number = 0;
    totalAmountItems: number = 0;
    totalManualPercentageDiscountItems: number = 0;
    totalTaxItems: number = 0;
    totalItems: number = 0;
    payments: IOrderReturnPayment[] = [];
    returnPayments: IOrderReturnPayment[] = [];
    organizationId: string = "";
    orderInvoiceNumerationId: string = "";

    constructor(accountId: string, accountUserId: string) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
    }
    id?: string | undefined;
    newOrderId?: string | undefined;
    documentType: string = "NOTA_CREDITO";
}

interface OrderReturnItemBase{
    id?: string;
    stockLimit: number; // only UI
    productId: string;
    product?: IProduct;
    productVariantId?: string;
    productVariant?: IProductVariant;
    quantity: number;
    unitPrice: number;
    totalUnitPrice: number;
    position: number;
    warehouseId: string;
    warehouse?: IWarehouse;
    manualPercentageDiscount: number;
    totalTax: number;
    basePrice: number;
    taxes: ITaxOrderReturnItem[];
    serials?: string[];
    originalItem?: OrderItem | IPurchaseItem; //UI ONLY
}

export interface IOrderReturnItem extends OrderReturnItemBase {
    orderReturnId: string;
    orderItemId: string;
}

export interface IPurchaseReturnItem extends OrderReturnItemBase {
    purchaseReturnId: string;
    purchaseItemId: string;
}

export const loadOrderReturnItem = (orderItem: OrderItem) => {
    let item: IOrderReturnItem = {
        orderItemId: orderItem.id ?? "",
        product: orderItem.product,
        productId: orderItem.productId,
        stockLimit: orderItem.quantity,
        quantity: 1,
        unitPrice: orderItem.unitPrice,
        totalUnitPrice: orderItem.unitPrice - (orderItem.manualPercentageDiscount * 100) / orderItem.unitPrice,
        productVariant: orderItem.productVariant,
        productVariantId: orderItem.productVariantId,
        orderReturnId: "",
        position: 0,
        warehouseId: orderItem.warehouseId,
        manualPercentageDiscount: orderItem.manualPercentageDiscount,
        totalTax: 0,
        basePrice: 0,
        originalItem: orderItem,
        taxes: [],
    };
    if (orderItem.serials && orderItem.serials.length === 1) {
        item.serials = orderItem.serials;
    }
    return item;
};
