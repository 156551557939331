import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { transactionAccountCreatePayment } from "../../../api/account/transactionAccount";
import { AccountingOrigin, OrderAccountingResultItem } from "../../../api/models/accounting";
import { Order } from "../../../api/models/order";
import { IOrderPayment, IOrderPaymentBase, IPurchasePayment } from "../../../api/models/orderPayment";
import { TransactionAccountPayment } from "../../../api/models/transactionAccount";
import { orderCreatePayment } from "../../../api/orders/orderApi";
import { purchaseCreatePayment } from "../../../api/purchases/purchaseApi";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useCashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { formatMoney } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import PaymentRow from "../../Payments/PaymentRow";
import usePaymentHook from "../../Payments/paymentHooks";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import messages from "../messages";

interface ButtonPaymentDocumentProps {
    item: OrderAccountingResultItem;
    onChange: () => void;
}

export const ButtonPaymentDocument = (props: ButtonPaymentDocumentProps) => {
    const { item } = props;

    return (
        <>
            {item.originName === "order" && <ButtonOrder  {...props} />}
            {item.originName === "purchase" && <ButtonPurchase {...props} />}
            {item.originName === "transactionAccount" && <ButtonTransactionAccount {...props} />}
        </>
    );
};

interface ButtonPaymentProps {
    onChange: (value: IOrderPaymentBase) => void;
    onCancel: () => void;
    originName?: AccountingOrigin;
    orderNumber: number | string;
    isLoading: boolean;
    multipleItems?: boolean;
    totalAmount: number;
    existingAmount: number;
}

const PaymentForm = (props: ButtonPaymentProps) => {
    const { originName = "none", orderNumber, totalAmount, existingAmount } = props;
    const intl = useIntl();

    const { cashRegisterState } = useCashRegisterContext();

    const paymentHook = usePaymentHook();

    const activeCashRegister = React.useMemo(() => {
        if (cashRegisterState.logActive?.cashRegisterId) {
            let value = cashRegisterState.orgItems.find((x) => x.id === cashRegisterState.logActive?.cashRegisterId);
            return value;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.logActive]);

    const [payment, setPayment] = useState<IOrderPaymentBase | undefined>(() => {
        let value = paymentHook.getNewPayment();
        return value;
    });

    const balance = totalAmount - existingAmount;

    return (
        <Flex column padding={30} gap={20}>
            <TextField bold fontSize={17}>
                Pagar {props.multipleItems ? "Multiples Items" : checkAndGetMessage(intl, messages, originName) + " #" + orderNumber}
            </TextField>
            <Flex>
                <Table tdPadding={5} tdPaddingRight={15} cellPadding={0} cellSpacing={0} hideBorde>
                    <tbody>
                        <tr>
                            <td>
                                <TextField>{intl.formatMessage(messages.newPayment)}</TextField>
                            </td>
                            <td>{formatMoney(payment?.amount ?? 0)}</td>
                        </tr>
                        {!props.multipleItems && (
                            <tr>
                                <td><TextField>{intl.formatMessage(messages.remaining)}</TextField></td>
                                <td>{formatMoney(balance - (payment?.amount ?? 0))}</td>
                            </tr>
                        )}
                        {existingAmount > 0 && (
                            <tr>
                                <td>
                                    <TextField bold fontSize={15}>{intl.formatMessage(messages.accumulated)}</TextField>
                                </td>
                                <td>
                                    <TextField bold fontSize={15}>{formatMoney(existingAmount)}</TextField>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <TextField bold fontSize={15}>{intl.formatMessage(messages.total)}</TextField>
                            </td>
                            <td>
                                <TextField bold fontSize={15}>{formatMoney(totalAmount)}</TextField>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Flex>
            {payment && (
                <PaymentRow
                    totalPayment={0}
                    totalAmount={balance}
                    onRemovePayment={() => {}}
                    counter={1}
                    onChangePayment={setPayment}
                    payment={payment}
                    activeCashRegister={activeCashRegister}
                    hideRemoveButton
                />
            )}
            <Flex gap10 justifyEnd marginTop={15}>
                <DefaultButton disabled={props.isLoading} borderRadius={10} w100 onClick={() => props.onCancel()}>
                    {intl.formatMessage(messages.cancel)}
                </DefaultButton>
                <PrimaryButton
                    borderRadius={10}
                    w100
                    disabled={!payment || payment.amount === 0 || props.isLoading}
                    onClick={() => {
                        if (payment) props.onChange(payment);
                    }}
                >
                    {intl.formatMessage(messages.createPayment)} {props.isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </Flex>
    );
};

interface ButtonCreateOrderOrderProps {
    order: Order;
    onChange: (order: Order) => void;
}

export const ButtonCreateOrderOrder = (props: ButtonCreateOrderOrderProps) => {
    const { order } = props;
    const [show, setShow] = useState(false);
    const alert = useAlert();
    const intl = useIntl();

    const mutation = useMutation((data: IOrderPayment) => orderCreatePayment(data), {
        onSuccess: (data) => {
            props.onChange(data);
            setShow(false);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleCreate = (value: IOrderPaymentBase) => {
        let payment = { ...value, orderId: props.order.id ?? "na" };
        mutation.mutate(payment);
    };

    return (
        <>
            {show && (
                <Modal show={show} setShow={setShow} useButtonClose ignoreOutsideClick>
                    <PaymentForm
                        originName={"order"}
                        orderNumber={order.number}
                        totalAmount={order.totalAmount}
                        existingAmount={order.totalPayment}
                        isLoading={mutation.isLoading}
                        onChange={handleCreate}
                        onCancel={() => setShow(false)}
                    />
                </Modal>
            )}

            <SquareButton
                title={intl.formatMessage(messages.createPayment)}
                className="btn-payment"
                removeBackground
                onClick={() => {
                    setShow(true);
                }}
            >
                {mutation.isLoading ? <LoadingDualRing small /> : <span className="fa-regular fa-credit-card"></span>}
            </SquareButton>
        </>
    );
};

const ButtonOrder = (props: ButtonPaymentDocumentProps) => {
    const [show, setShow] = useState(false);
    const alert = useAlert();

    const mutation = useMutation((data: IOrderPayment) => orderCreatePayment(data), {
        onSuccess: () => {
            props.onChange();
            setShow(false);
            alert.success("Pago Creado con éxito");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleCreate = (value: IOrderPaymentBase) => {
        let payment = { ...value, orderId: props.item.orderId ?? "na" };
        mutation.mutate(payment);
    };

    return (
        <>
            {show && (
                <Modal show={show} setShow={setShow} useButtonClose ignoreOutsideClick>
                    <PaymentForm
                        isLoading={mutation.isLoading}
                        onChange={handleCreate}
                        onCancel={() => setShow(false)}
                        originName={props.item.originName}
                        orderNumber={props.item.orderNumber}
                        totalAmount={props.item.balance}
                        existingAmount={0}
                    />
                </Modal>
            )}

            <SquareButton
                className="btn-payment"
                removeBackground
                onClick={() => {
                    setShow(true);
                }}
            >
                {mutation.isLoading ? <LoadingDualRing small /> : <span className="fa-regular fa-credit-card"></span>}
            </SquareButton>
        </>
    );
};

const ButtonPurchase = (props: ButtonPaymentDocumentProps) => {
    const [show, setShow] = useState(false);
    const alert = useAlert();

    const mutation = useMutation((data: IPurchasePayment) => purchaseCreatePayment(data), {
        onSuccess: (data) => {
            props.onChange();
            setShow(false);
            alert.success("Pago Creado con éxito");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleCreate = (value: IOrderPaymentBase) => {
        let payment = { ...value, purchaseId: props.item.orderId ?? "na" };
        mutation.mutate(payment);
    };

    return (
        <>
            {show && (
                <Modal show={show} setShow={setShow} useButtonClose ignoreOutsideClick>
                    <PaymentForm
                        originName={props.item.originName}
                        orderNumber={props.item.orderNumber}
                        totalAmount={props.item.balance}
                        existingAmount={0}
                        isLoading={mutation.isLoading}
                        onChange={handleCreate}
                        onCancel={() => setShow(false)}
                    />
                </Modal>
            )}

            <SquareButton
                className="btn-payment"
                removeBackground
                onClick={() => {
                    setShow(true);
                }}
            >
                {mutation.isLoading ? <LoadingDualRing small /> : <span className="fa-regular fa-credit-card"></span>}
            </SquareButton>
        </>
    );
};

const ButtonTransactionAccount = (props: ButtonPaymentDocumentProps) => {
    const [show, setShow] = useState(false);
    const alert = useAlert();

    const mutation = useMutation((data: TransactionAccountPayment) => transactionAccountCreatePayment(data), {
        onSuccess: () => {
            props.onChange();
            setShow(false);
            alert.success("Pago Creado con éxito");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleCreate = (value: IOrderPaymentBase) => {
        let payment = { ...value, transactionAccountId: props.item.orderId ?? "na" };
        mutation.mutate(payment);
    };

    return (
        <>
            {show && (
                <Modal show={show} setShow={setShow} useButtonClose ignoreOutsideClick>
                    <PaymentForm
                        originName={props.item.originName}
                        orderNumber={props.item.orderNumber}
                        totalAmount={props.item.balance}
                        existingAmount={0}
                        isLoading={mutation.isLoading}
                        onChange={handleCreate}
                        onCancel={() => setShow(false)}
                    />
                </Modal>
            )}

            <SquareButton
                className="btn-payment"
                removeBackground
                onClick={() => {
                    setShow(true);
                }}
            >
                {mutation.isLoading ? <LoadingDualRing small /> : <span className="fa-regular fa-credit-card"></span>}
            </SquareButton>
        </>
    );
};
