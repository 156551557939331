import * as Popover from "@radix-ui/react-popover";
import React, { useEffect, useRef, useState } from "react";
import { HoverCardContentStyle } from "./hoverCardStyle";

interface PopoverContentProps {
    children: React.ReactNode;
    trigger: React.ReactNode;
    side?: "top" | "right" | "bottom" | "left";
    align?: "start" | "center" | "end";
    widthAuto?: boolean;
    width?: number;
    autoClose?: boolean;
}
export const PopoverRadix = (props: PopoverContentProps) => {
    const [open, setOpen] = useState(false);
    const popoverRef = useRef<any>(null);

    let style = {
        width: props.widthAuto ? `auto` : "290px",
        padding: "20px",
        maxWidth: "100%",
    };
    if (props.width) {
        style.width = `${props.width}px`;
    }

    const handleClickOutside = (event: any) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        // Agregar el listener cuando el componente se monta
        document.addEventListener("mousedown", handleClickOutside);

        // Limpiar el listener cuando el componente se desmonta
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Popover.Root
            open={open}
            onOpenChange={(open) => {
                setOpen(open);
            }}
        >
            <Popover.Trigger asChild>{props.trigger}</Popover.Trigger>

            <Popover.Portal>
                <HoverCardContentStyle ref={popoverRef}>
                    <Popover.Content
                        onClick={() => {
                            if (props.autoClose) {
                                setOpen(false);
                            }
                        }}
                        side={props.side}
                        align={props.align}
                        className="HoverCardContent"
                        style={style}
                        sideOffset={5}
                    >
                        {props.children}
                        <Popover.Arrow className="HoverCardArrow" />
                    </Popover.Content>
                </HoverCardContentStyle>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default PopoverRadix;
