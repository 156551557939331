import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import Select from "react-select";
import { getServiceMessageError } from "../../../api";
import { postCashRegisterEntry } from "../../../api/account/cashRegisterApi";
import { PaymentMethod, SelectBaseOption, paymentMethodBankList, paymentMethodCashList } from "../../../api/models";
import { CashRegisterEntryItem, ICashRegister, ICashRegisterEntry } from "../../../api/models/cashRegister";
import { getAuthBody } from "../../../auth";
import globalMessages from "../../../messages";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import { formatMoney, getUtcDate } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import ContactSelect from "../../Contacts/ContactSelect";
import CustomDateInput from "../../DatePicker/CustomDateInput";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import PermissionDenied from "../../PermissionDenied";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, DefaultInput, Flex, GridTemplate, PrimaryButton, SquareButton, TextField } from "../../_controls";
import GroupButtonRow from "../../_controls/buttons/GroupButtonRow";
import Modal, { IModalShow } from "../../modals/Modal";
import CashRegisterEntryCategorySelector from "../CashRegisterEntryCategoryForm/CashRegisterEntryCategorySelector";
import CashRegisterSelect from "../CashRegisterSelect/CashRegisterSelect";
import messages from "../messages";
import { CashRegisterEntryItemCard, CashRegisterMovementContainer, EntryInputContainer } from "../styled";

export interface CashRegisterManualEntryProps {
    defaultCashRegister?: ICashRegister;
    cashRegisterEntry?: ICashRegisterEntry;
    onCreate: (value: ICashRegisterEntry) => void;
    onCancel: () => void;
    square?: boolean;
}

export const CashRegisterManualEntryButton = (props: CashRegisterManualEntryProps) => {
    const [showModal, setShowModal] = useState(false);
    const Button = props.square ? SquareButton : DefaultButton;

    return (
        <>
            <Button onClick={() => setShowModal(true)} rounded={true}>
                <span className="fa-regular fa-plus"></span> {!props.square ? "Pago" : ""}
            </Button>
            {showModal && <CashRegisterManualEntryModal show={showModal} setShow={setShowModal} {...props} />}
        </>
    );
};

const getEmptyEntry = (cashRegister?: ICashRegister) => {
    let entry: ICashRegisterEntry = {
        cashRegisterId: cashRegister?.id ?? "",
        cashRegister: cashRegister,
        number: 0,
        amount: 0,
        type: "",
        referenceNumber: "",
        referenceId: "",
        method: "cash",
        description: "",
        contactId: "",
        comment: "",
        isIncome: false,
        accountUserId: getAuthBody().accountUserId,
        customDateCreated: new Date(),
        files: [],
    };
    return entry;
};

export default function CashRegisterEntryForm(props: CashRegisterManualEntryProps) {
    const intl = useIntl();
    const alert = useAlert();
    const { cashRegisterState } = useContext(CashRegisterContext);
    const [showMoreOption, setShowMoreOption] = useState(false);
    const [showDate, setShowDate] = useState(false);

    const { userPermission } = useUserContext();

    const entryMutation = useMutation((entry: ICashRegisterEntry) => postCashRegisterEntry(entry), {
        onSuccess: (data) => {
            alert.success(
                data.isIncome
                    ? intl.formatMessage(messages.loginCreatedSuccessfully)
                    : intl.formatMessage(messages.outputCreatedSuccessfully)
            );
            props.onCreate(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const [entryBody, setEntryBody] = useState<ICashRegisterEntry>(() => {
        if (props.cashRegisterEntry) {
            let entry = { ...props.cashRegisterEntry };
            entry.customDateCreated = customMoment(getUtcDate(entry.dateCreated?.toString())).toDate();
            return entry;
        }
        return getEmptyEntry(props.defaultCashRegister);
    });

    useEffect(() => {
        let currentCashRegister = entryBody.cashRegister;
        if (currentCashRegister) {
            if (currentCashRegister.type === "cash" && entryBody.method !== "cash") {
                setEntryBody({ ...entryBody, method: "cash" });
            } else if (currentCashRegister.type !== "cash" && entryBody.method === "cash") {
                setEntryBody({ ...entryBody, method: paymentMethodBankList[0] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entryBody.cashRegisterId]);

    useEffect(() => {
        if (!entryBody.cashRegisterId && !props.defaultCashRegister && cashRegisterState.orgItems.length > 0) {
            let result = cashRegisterState.orgItems[0];
            setEntryBody({ ...entryBody, cashRegisterId: result.id ?? "", cashRegister: result });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.orgItems]);

    const handleCreateEntry = () => {
        if (!entryBody.type) {
            alert.info("La categoría es requerido");
            return;
        }
        if (entryBody.amount <= 0) {
            alert.info("El monto debe ser mayor a 0");
            return;
        }
        let newEntry = { ...entryBody };
        newEntry.customDateCreated = customMoment(entryBody.customDateCreated).utc().format();
        entryMutation.mutate(entryBody);
    };

    const getMethodOption = (item: PaymentMethod) => {
        let option: SelectBaseOption = {
            id: item,
            value: item,
            label: intl.formatMessage(globalMessages[item]),
        };
        return option;
    };

    const originChange = (cashRegisterId?: string) => {
        let cashRegister: ICashRegister | undefined = undefined;
        if (cashRegisterId) {
            cashRegister = cashRegisterState.orgItems.find((x) => x.id === cashRegisterId);
        }
        setEntryBody({ ...entryBody, cashRegister: cashRegister, cashRegisterId: cashRegister?.id ?? "" });
    };

    const getPaymentMethodOptions = () => {
        if (entryBody.cashRegister?.type === "cash") {
            return paymentMethodCashList.map((item) => getMethodOption(item));
        } else {
            return paymentMethodBankList.map((item) => getMethodOption(item));
        }
    };

    const addItem = () => {
        var items = entryBody.items ?? [];
        const item: CashRegisterEntryItem = {
            id: "",
            cashRegisterEntryId: "",
            amount: 0,
            name: "",
            type: `Item ${items.length + 1}`,
        };
        items.push(item);
        setEntryBody({ ...entryBody, items: items });
    };

    const onRemoveItem = (index: number) => {
        var items = entryBody.items ?? [];
        items.splice(index, 1);
        setEntryBody({ ...entryBody, items: items });
    };

    const onChangeItem = (item: CashRegisterEntryItem, index: number) => {
        var items = entryBody.items ?? [];
        items[index] = item;
        setEntryBody({ ...entryBody, items: items });
    };

    const isLoading = entryMutation.isLoading;

    if (entryBody.id && !userPermission.payment?.update) {
        return <PermissionDenied />;
    }

    if (!entryBody.id && !userPermission.payment?.create) {
        return <PermissionDenied />;
    }

    return (
        <CashRegisterMovementContainer>
            <EntryInputContainer>
                <Flex column gap5>
                    <Flex alignCenter spaceBetween>
                        <TextField small light>
                            {intl.formatMessage(messages.value)}
                        </TextField>
                        {entryBody.cashRegister && (
                            <TextField small light>
                                Saldo: {formatMoney(entryBody.cashRegister.amount + entryBody.amount * (!entryBody.isIncome ? -1 : 1))}
                            </TextField>
                        )}
                    </Flex>
                    <TextBoxNumeral
                        className="input-number"
                        rounded
                        w100
                        format="money"
                        value={entryBody.amount}
                        onNumberChange={(value) => setEntryBody({ ...entryBody, amount: value })}
                    />
                </Flex>

                {entryBody.items &&
                    entryBody.items.map((item, index) => {
                        return (
                            <EntryItemCard
                                key={index}
                                onRemove={() => onRemoveItem(index)}
                                item={item}
                                onChange={(item) => onChangeItem(item, index)}
                            />
                        );
                    })}

                <Flex gap={25} alignCenter spaceBetween flexWrap>
                    <GroupButtonRow
                        hidePadding
                        activeOption={entryBody.isIncome}
                        onClickOption={(option) => setEntryBody({ ...entryBody, isIncome: option.value })}
                        options={[
                            {
                                title: "Salida",
                                value: false,
                                color: "#D32F2F",
                                icon: "wahioicon-minus-circle",
                            },
                            {
                                title: "Entrada",
                                value: true,
                                icon: "wahioicon-plus-circle",
                            },
                        ]}
                    />
                    <DefaultButton bgLight borderRadius={10} onClick={addItem}>
                        <span className="fa-regular fa-plus"></span> Item
                    </DefaultButton>
                </Flex>
            </EntryInputContainer>

            <Flex column gap5>
                <TextField light small>
                    {intl.formatMessage(messages.description)} (Opcional)
                </TextField>
                <DefaultInput
                    rounded
                    value={entryBody.description ?? ""}
                    placeholder={intl.formatMessage(messages.descriptionTwo)}
                    onChange={(e) => setEntryBody({ ...entryBody, description: e.target.value })}
                />
            </Flex>

            <GridTemplate>
                <Flex column gap5>
                    <TextField small light>
                        Seleccionar Categoría
                    </TextField>
                    <CashRegisterEntryCategorySelector
                        selectedName={entryBody.type}
                        w100
                        onSelected={(value) => {
                            if (value) {
                                setEntryBody({ ...entryBody, type: value.name, category: value });
                            }
                        }}
                    />
                </Flex>

                {!props.defaultCashRegister && (
                    <CashRegisterSelect
                        defaultCashRegisterId={entryBody.cashRegisterId}
                        onCashRegisterChange={(value) => originChange(value?.id)}
                    />
                )}
                {entryBody.cashRegister?.type === "bank" && (
                    <SelectBody className="w100">
                        <span className="label">{intl.formatMessage(messages.paymentMethod)}</span>
                        <Select
                            placeholder={intl.formatMessage(messages.toSelectTwo)}
                            className={entryBody.method ? "item-active" : ""}
                            classNamePrefix="select"
                            key={entryBody.method}
                            defaultValue={entryBody.method ? getMethodOption(entryBody.method) : undefined}
                            isDisabled={false}
                            onChange={(value) => {
                                if (value && (value.id as PaymentMethod)) setEntryBody({ ...entryBody, method: value.id as PaymentMethod });
                            }}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={getPaymentMethodOptions()}
                        />
                    </SelectBody>
                )}
            </GridTemplate>

            <FilesViewer
                smallCard
                cardMaxHeight={80}
                cardMaxWidth={80}
                showAttachButton
                customComponent={
                    <>
                        <DefaultButton bgLight borderRadius={10} onClick={() => setShowMoreOption(!showMoreOption)}>
                            <i className="fa-regular fa-user-plus"></i> Contacto
                        </DefaultButton>
                        <DefaultButton
                            bgLight
                            borderRadius={10}
                            onClick={() => {
                                setShowDate(!showDate);
                            }}
                        >
                            <i className="fa-regular fa-calendar-pen"></i> Fecha
                        </DefaultButton>
                    </>
                }
                onUpdateFiles={(files) => setEntryBody({ ...entryBody, files })}
                files={entryBody.files ?? []}
            />

            {showDate && (
                <Flex column gap5>
                    <TextField small light>
                        Fecha del movimiento
                    </TextField>
                    <CustomDateInput
                        date={customMoment(entryBody.customDateCreated).toDate()}
                        onDateChange={(value) => setEntryBody({ ...entryBody, customDateCreated: value })}
                    />
                </Flex>
            )}

            {showMoreOption && (
                <ContactSelect
                    w100
                    contact={entryBody.contact}
                    onUpdateContact={(contact) => setEntryBody({ ...entryBody, contact: contact, contactId: contact?.id ?? "" })}
                    title={"Seleccionar Contacto"}
                    placeholder={"Buscar contactos"}
                />
            )}

            <Flex gap10 alignCenter justifyEnd marginTop={15}>
                <DefaultButton w100 bgLight disabled={isLoading} onClick={() => props.onCancel()} borderRadius={10}>
                    {intl.formatMessage(messages.cancel)}
                </DefaultButton>
                <PrimaryButton w100 disabled={isLoading} onClick={() => handleCreateEntry()} borderRadius={10}>
                    Crear Transacción {isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </CashRegisterMovementContainer>
    );
}

interface EntryItemCardProps {
    item: CashRegisterEntryItem;
    onChange: (item: CashRegisterEntryItem) => void;
    onRemove: () => void;
}
const EntryItemCard = (props: EntryItemCardProps) => {
    const { item, onChange } = props;
    return (
        <CashRegisterEntryItemCard>
            <DefaultInput
                className="description"
                placeholder="Descripción"
                value={item.type}
                onChange={(e) => onChange({ ...item, type: e.target.value })}
            />
            <TextBoxNumeral
                className="amount"
                value={item.amount}
                onNumberChange={(value) => onChange({ ...item, amount: value })}
                format={"money"}
            />
            <SquareButton className="ml-1 mr-1" small onClick={props.onRemove}>
                <span className="wahioicon-trash" />
            </SquareButton>
        </CashRegisterEntryItemCard>
    );
};

interface CashRegisterManualEntryModalProps extends CashRegisterManualEntryProps, IModalShow {}

export function CashRegisterManualEntryModal(props: CashRegisterManualEntryModalProps) {
    const onCreate = (value: ICashRegisterEntry) => {
        props.onCreate(value);
        props.setShow(false);
    };
    return (
        <Modal scrollInside={false} overflowAuto={false} showHeader={true} title="Nueva Transacción" ignoreOutsideClick {...props}>
            <CashRegisterEntryForm {...props} onCreate={onCreate} onCancel={() => props.setShow(false)} />
        </Modal>
    );
}
