import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { METHOD_ICONS } from "../../../api/models";
import { ICashRegisterEntry } from "../../../api/models/cashRegister";
import { getOrder } from "../../../api/orders/orderApi";
import { checkAndGetMessage } from "../../../i18n/helper";
import { formatMoney, getDateFormat, getModelFullName } from "../../../utils";
import OrderProfileModal from "../../Orders/OrderProfile/OrderProfileModal";
import { Flex, TextField } from "../../_controls";
import messages from "../messages";
import { CashRegisterEntryProfileModal } from "./CashRegisterEntryProfile";
import { CashEntryCard, CashEntryCardContainer, DateTitleLine, EntryIcon } from "./styled";

export interface CashRegisterEntryBase {
    entry: ICashRegisterEntry;
    onEntryChange: (entry: ICashRegisterEntry) => void;
    icon?: string;
}

interface CashEntryOptions {
    showCashRegisterName?: boolean;
    hideType?: boolean;
}

interface CashRegisterEntryCardProps extends CashRegisterEntryBase, CashEntryOptions {
    dateTitle?: string;
}

const ENTRY_COLORS = { income: "#6f83ff", expense: "#F44336" };

export default function CashRegisterEntryCard(props: CashRegisterEntryCardProps) {
    const [showContent, setShowContent] = useState(false);
    const intl = useIntl();
    const [entry, setEntry] = useState(props.entry);
    const entryType = checkAndGetMessage(intl, messages, entry.type);
    const [loaded, setLoaded] = useState(false);
    const orderMutation = useMutation((id: string) => getOrder(id));
    const [showDocument, setShowDocument] = useState(false);

    useEffect(() => {
        setEntry(props.entry);
    }, [props.entry]);

    const handleFetchDocumentReference = () => {
        if (loaded) return;

        setLoaded(true);
        if (entry.type === "sale" && entry.referenceId) {
            orderMutation.mutate(entry.referenceId);
        }
    };

    const handleClickCard = () => {
        setShowContent(!showContent);
        handleFetchDocumentReference();
    };

    return (
        <>
            {showContent && (
                <CashRegisterEntryProfileModal
                    show={showContent}
                    setShow={setShowContent}
                    entry={entry}
                    icon={props.icon}
                    restoreEntry={() => setEntry(props.entry)}
                    onEntryChange={(value) => {
                        setEntry(entry);
                        props.onEntryChange(value);
                    }}
                    entryType={entryType}
                />
            )}
            {props.dateTitle && (
                <DateTitleLine>
                    <span>{props.dateTitle}</span>
                </DateTitleLine>
            )}
            <CashEntryCardContainer className={showContent ? "active" : ""}>
                {showDocument && orderMutation.data && entry.type === "sale" && (
                    <OrderProfileModal show={showDocument} setShow={setShowDocument} order={orderMutation.data} />
                )}
                <CashEntryCardLine {...props} handleClickCard={handleClickCard} entryType={entryType} />
            </CashEntryCardContainer>
        </>
    );
}

interface CashEntryCardLineProps extends CashRegisterEntryBase, CashEntryOptions {
    handleClickCard?: () => void;
    entryType: string;
}

export const EntryIconCard = (props: CashRegisterEntryBase) => {
    const { entry } = props;

    let cardIcon = props.icon ? props.icon : entry.isIncome ? "fa-regular fa-arrow-up-to-line" : "fa-regular fa-arrow-down-to-line";

    if (!props.icon) {
        const icon = (METHOD_ICONS as any)[props.entry.method];
        if (icon) {
            cardIcon = icon;
        }
    }
    const color = entry.isIncome ? ENTRY_COLORS.income : ENTRY_COLORS.expense;

    return (
        <EntryIcon
            className={`${cardIcon} entry-icon`}
            style={{
                background: color + "20",
                color: color,
            }}
        />
    );
};

const CashEntryCardLine = (props: CashEntryCardLineProps) => {
    const { entry, handleClickCard, entryType } = props;
    const { contact } = entry;

    return (
        <CashEntryCard
            className={"entry-card"}
            style={{ cursor: handleClickCard ? "pointer" : "default" }}
            onClick={() => {
                if (handleClickCard) handleClickCard();
            }}
        >
            <EntryIconCard {...props} />
            <Flex column className="entry-content">
                <TextField ellipsis>
                    #{entry.number}. {props.hideType ? "" : entryType}
                    {contact ? `. ${getModelFullName(contact)}` : ""}
                </TextField>
                {props.showCashRegisterName && (
                    <TextField light small ellipsis>
                        {entry.cashRegisterName}
                    </TextField>
                )}
                <TextField light small ellipsis>
                    {getDateFormat(entry.dateCreated, "DD MMM hh:mm a")}
                </TextField>
            </Flex>
            <Flex alignCenter gap15 className="ml-auto">
                <TextField nowrap color={entry.isIncome ? "#304FFE" : ENTRY_COLORS.expense} bold>
                    {entry.isIncome ? "" : "-"}
                    {formatMoney(entry.amount)}
                </TextField>
            </Flex>
        </CashEntryCard>
    );
};
