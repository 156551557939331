import wahioFetchApi from "../fetchApi";
import { AccountClientSettings, ClientSettingsMutationInput, ClientSettingsPartialInput } from "../models/accountUser";
import { accountEndpoint } from "../restApiEndpoint";

export const updateClientSettings = (data: AccountClientSettings): Promise<AccountClientSettings> => {
    return wahioFetchApi.post(accountEndpoint.post.clientSettings, data).then((res: any) => res.data);
};

export const updateClientSettingsPartial = (data: ClientSettingsMutationInput): Promise<ClientSettingsPartialInput> => {
    return wahioFetchApi.patch(accountEndpoint.patch.clientSettingsPartial(data.accountId), data.body).then((res: any) => res.data);
};
