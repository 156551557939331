import { IPaginationRequest } from ".";
import { IProductSearchModel, IProductVariant, ProductImpl } from "./product";
import { IWarehouse } from "./warehouse";

export const ADD_INVENTORY_ADJUSTMENT = "ADD_INVENTORY_ADJUSTMENT";
export const DELETE_INVENTORY_ADJUSTMENT = "DELETE_INVENTORY_ADJUSTMENT";
export const UPDATE_INVENTORY_ADJUSTMENT = "UPDATE_INVENTORY_ADJUSTMENT";
export const REQUEST_INVENTORY_ADJUSTMENTS = "REQUEST_INVENTORY_ADJUSTMENTS";
export const RECEIVE_INVENTORY_ADJUSTMENTS = "RECEIVE_INVENTORY_ADJUSTMENTS";
export const INVALID_REQUEST_INVENTORY_ADJUSTMENTS = "INVALID_REQUEST_INVENTORY_ADJUSTMENTS";

export type AdjustmentStatus = "pending" | "progress" | "completed" | "deleted";

export const AdjustmentStatusList: AdjustmentStatus[] = ["pending", "completed", "deleted"];

export interface IInventoryAdjustment {
    id?: string;
    name: string;
    accountId: string;
    accountUserId: string;
    dateStart?: Date;
    dateEnd?: Date;
    adjustmentNumber: number;
    warehouseId: string;
    warehouse?: IWarehouse;
    updatedProducts: number;
    status: AdjustmentStatus;
    items: IInventoryAdjustmentDetail[];
}
export interface IInventoryAdjustmentDetail {
    id?: string;
    inventoryAdjustmentId: string;
    accountUserId: string;
    productVariantId?: string;
    productVariant?: IProductVariant;
    productId: string;
    product: ProductImpl;
    dateUpdated: Date;
    warehouseId: string;
    warehouse?: IWarehouse;
    oldStock: number;
    newStock: number;
    serials?: string[];
    inventoryLocationId?: string;
    inventoryLocation?: InventoryLocation;
}
export class InventoryAdjustment implements IInventoryAdjustment {
    id?: string;
    name: string = "";
    accountId: string;
    accountUserId: string;
    dateStart?: Date;
    dateEnd?: Date;
    adjustmentNumber: number = 0;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    updatedProducts: number = 1;
    status: AdjustmentStatus = "pending";
    items: IInventoryAdjustmentDetail[] = [];

    constructor(accountId: string, accountUserId: string) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
    }
}

export interface InventoryLocation {
    id: string;
    name: string;
    createdAt: string | Date;
    accountId: string;
}

export interface InventoryDetailsProductRequest {
    inventoryAdjustmentId: string;
    productId: string;
    productVariantId?: string;
}

export interface InventoryDetailProductCount {
    productId: string;
    totalStock: number;
    count: number;
}
export interface InventoryDetailsRemoveRequest {
    inventoryAdjustmentId: string;
    detailIds: string[];
}

export interface IInventoryAdjustmentSearchModel extends IProductSearchModel {
    include: boolean;
    inventoryAdjustmentId: string;
    accountUserId?: string;
    inventoryLocationId?: string;
}

export type InventorySortOptions = "default" | "price";
export interface ISearchInventorySort {
    sortDesc: boolean;
    sort: InventorySortOptions;
}

export interface SearchInventoryAdjusment extends IPaginationRequest, ISearchInventorySort {
    accountId: string;
    accountUserId?: string;
    warehouseId?: string;
    searchValue?: string;
    productId?: string;
    status?: AdjustmentStatus;
}
export interface IInventoryProductAdjustmentModel {
    productId: string;
    warehouseId: string;
    quantity: number;
    accountUserId: string;
    productVariantId?: string;
    serials: string[];
}

export interface IInventoryAdjustmentState {
    items: IInventoryAdjustment[];
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface RequestInventoryAdjustments {
    type: typeof REQUEST_INVENTORY_ADJUSTMENTS;
}
export interface ReceiveInventoryAdjustments {
    type: typeof RECEIVE_INVENTORY_ADJUSTMENTS;
    items: Array<IInventoryAdjustment>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_INVENTORY_ADJUSTMENTS;
    value: any;
}
export interface UpdateInventoryAdjustment {
    type: typeof UPDATE_INVENTORY_ADJUSTMENT;
    value: IInventoryAdjustment;
}

export interface AddInventoryAdjustment {
    type: typeof ADD_INVENTORY_ADJUSTMENT;
    value: IInventoryAdjustment;
}

interface DeleteInventoryAdjustment {
    type: typeof DELETE_INVENTORY_ADJUSTMENT;
    id: string;
}

export type InventoryAdjustmentTypes =
    | RequestInventoryAdjustments
    | ReceiveInventoryAdjustments
    | InvalidRequest
    | UpdateInventoryAdjustment
    | AddInventoryAdjustment
    | DeleteInventoryAdjustment;
