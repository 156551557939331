import styled from "styled-components";
import { Flex } from "../../_controls";

export const EBillingContainerForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const CertificateCard = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    background: ${(props) => props.theme.palette.backgroundLight};
    padding: 2px 10px;
    padding-right: 2px;
    border-radius: 20px;
    height: 33px;
    box-sizing: border-box;

    .ml-auto {
        margin-left: auto;
    }
`;

export const ResolutionCardStyled = styled(Flex)`
    .textbox-numeral {
        background: ${(props) => props.theme.palette.backgroundDark};
        font-weight: 600;
        &.active { 
            background: ${(props) => props.theme.palette.background};
        }
    }
`;

export const TestSetStatusLabel = styled.div`
    padding: 2px 10px;
    background: red;
    border-radius: 15px;
    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.textLight};
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;

    &.pending {
        background: #f57f1720;
        color: #f57f17;
    }
    &.error {
        background: #d32f2f20;
        color: #d32f2f;
    }
    &.done {
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};
    }
`;
