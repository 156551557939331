import wahioFetchApi from "../fetchApi";
import { IPurchasePayment } from "../models/orderPayment";
import {
    OrderSerialSearchResponse,
    ProductCostSearchInput,
    ProductCostResponse,
    ProductSerialSearchInput,
    ProductCostMultipleSearchInput,
} from "../models/product";
import { IPurchase, IPurchaseComment } from "../models/purchase";
import { IPurchaseReturn, ResponseResultPurchaseReturn } from "../models/purchaseReturns";
import { purchaseEndpoint } from "../restApiEndpoint";

export const getPurchase = (purchaseId: string): Promise<IPurchase> => {
    return wahioFetchApi.get(purchaseEndpoint.get.purchaseById(purchaseId)).then((res: any) => res.data);
};

export const getPurchasesBySerialCode = (data: ProductSerialSearchInput): Promise<OrderSerialSearchResponse[]> => {
    return wahioFetchApi.post(purchaseEndpoint.post.purchaseSearchBySerial, data).then((res: any) => res.data);
};

export const purchaseCreatePayment = (data: IPurchasePayment): Promise<IPurchase> => {
    return wahioFetchApi.post(purchaseEndpoint.post.singlePayment, data).then((res: any) => res.data);
};

export const purchaseCreateReturn = (data: IPurchaseReturn): Promise<ResponseResultPurchaseReturn> => {
    return wahioFetchApi.post(purchaseEndpoint.post.purchaseReturn, data).then((res: any) => res.data);
};

export const createPurchaseComment = (data: IPurchaseComment): Promise<IPurchaseComment> => {
    return wahioFetchApi.post(purchaseEndpoint.post.purchaseComment, data).then((res: any) => res.data);
};

export const deletePurchaseComment = (id: string): Promise<IPurchaseComment> => {
    return wahioFetchApi.delete(purchaseEndpoint.delete.purchaseComment(id)).then((res: any) => res.data);
};

export const getProductCostByOnPurchases = (data: ProductCostSearchInput): Promise<ProductCostResponse> => {
    return wahioFetchApi.post(purchaseEndpoint.post.productCostByPurchases, data).then((res: any) => res.data);
};

export const getProductCostByOnPurchasesMultiple = (data: ProductCostMultipleSearchInput): Promise<ProductCostResponse[]> => {
    return wahioFetchApi.post(purchaseEndpoint.post.productCostByPurchasesMultiple, data).then((res: any) => res.data);
};
