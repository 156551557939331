import React from "react";
import { NavLink } from "react-router-dom";
import { IPurchase } from "../../../api/models/purchase";
import PATHS from "../../../constants/paths";
import { OrderCreatedPreviewStyle } from "../../Orders/OrderStyles/styled";
import { Flex, SquareButton, TextField } from "../../_controls";

interface PurchaseCreatedPreviewProps {
    purchaseResult: IPurchase;
}

export default function PurchaseCreatePreview(props: PurchaseCreatedPreviewProps) {
    return (
        <OrderCreatedPreviewStyle>
            <Flex column>
                <TextField>Compra creada</TextField>
                <TextField bold>#{props.purchaseResult.number}</TextField>
            </Flex>
            <PurchaseCreatedActions {...props} />
        </OrderCreatedPreviewStyle>
    );
}

export const PurchaseCreatedActions = (props: PurchaseCreatedPreviewProps) => {
    return (
        <Flex gap10 alignCenter>
            <NavLink to={PATHS.productPurchasesListProfileId(props.purchaseResult.id)}>
                <SquareButton>
                    <i className="fa-regular fa-eye"></i>
                </SquareButton>
            </NavLink>
        </Flex>
    );
};