import React from "react";
import { CreatePropsFormProps } from "..";
import { orderStatusOpenList } from "../../../../api/models/orderBase";
import { formatMoney } from "../../../../utils";
import { PrimaryButton, PrimaryButton2, PrimaryButton3 } from "../../../_controls";

interface ActionButtonProps extends CreatePropsFormProps {
    totalItemsQuantity: number;
    onClickFinalize: () => void;
    onClickSaveStatus: () => void;
}

export default function ActionButton(props: ActionButtonProps) {
    const { order, totalItemsQuantity } = props;

    if (props.origin !== "restaurant" && props.status && orderStatusOpenList.includes(props.status)) {
        return (
            <PrimaryButton rounded disabled={totalItemsQuantity === 0} onClick={props.onClickFinalize}>
                {order.id ? "Guardar Cambios" : "Finalizar"} ({order.items.length})
            </PrimaryButton>
        );
    }

    if (props.status === "queued") {
        return (
            <PrimaryButton2 rounded disabled={totalItemsQuantity === 0 || !order.customer} onClick={props.onClickSaveStatus}>
                Enviar Orden {formatMoney(order.totalAmount)}
            </PrimaryButton2>
        );
    }

    if (props.status === "quotation") {
        return (
            <PrimaryButton3 rounded disabled={totalItemsQuantity === 0 || !order.customer} onClick={props.onClickSaveStatus}>
                {"Guardar Cotización"} {formatMoney(order.totalAmount)}
            </PrimaryButton3>
        );
    }

    if (props.origin === "restaurant") {
        return (
            <PrimaryButton3 rounded disabled={totalItemsQuantity === 0} onClick={props.onClickFinalize}>
                Finalizar Mesa
            </PrimaryButton3>
        );
    }

    return (
        <PrimaryButton rounded disabled={totalItemsQuantity === 0} onClick={props.onClickFinalize}>
            {order.id ? "Guardar Cambios" : "Finalizar"} {formatMoney(order.totalAmount)}
        </PrimaryButton>
    );
}
