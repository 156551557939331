import styled from "styled-components";

export const SelectBodyBase = styled.div`
    &.margin {
        justify-content: center;

        padding: 15px 15px;
        background: ${(props) => props.theme.palette.backgroundLight};
        border-radius: 10px;
        margin: 6px 10px;

        .btn-address {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }
        .select__control {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;

            &.select__control--menu-is-open {
                border-color: ${(props) => props.theme.palette.backgroundDark};
                border-color: ${(props) => props.theme.palette.primary};
                background-color: ${(props) => props.theme.palette.background};
            }
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }

        .textbox-numeral {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;
            &:focus {
                background-color: ${(props) => props.theme.palette.background};
                border-color: ${(props) => props.theme.palette.primary};
            }
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }
    }
    .label {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 5px;
    }
`;

export const SelectImageContainer = styled.div`
    display: flex;
    align-items: center;

    .image-container {
        height: 30px;
        min-width: 30px;
        max-width: 100px;
        display: flex;
        justify-content: center;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`;

interface SelectBodyProps {
    menuMinWidth?: number;
    selectMinWidth?: number;
    gap?: number;
    hideIndicator?: boolean;
}

export const SelectBody = styled(SelectBodyBase)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: ${(props: SelectBodyProps) => (props.gap ? `${props.gap}px` : "0px")};

    &.w100 {
        width: 100%;
        .select-style {
            width: 100%;
        }
    }

    &.icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .icon {
            position: absolute;
            left: 12px;
            z-index: 1;
            font-size: 13px;
        }

        .select__value-container {
            padding-left: 28px;
        }
    }

    .light {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }

    &.fit-content {
        width: fit-content;
        max-width: 500px;
    }

    .select__multi-value {
        border-radius: 30px;
    }

    .select__multi-value__label {
        font-weight: 500;
        font-size: 14px;
    }

    &.multi-value-theme-color {
        .select__multi-value {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
        .select__multi-value__label {
            color: ${(props) => props.theme.palette.text};
            font-size: 14px;
        }
    }
    .select__menu {
        top: 100%;
        z-index: 200;
        border-radius: 8px;
        box-shadow: 1px 2px 9px ${(props) => props.theme.palette.shadowColor};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        overflow: hidden;
        ${(props: SelectBodyProps) => (props.menuMinWidth ? `min-width: ${props.menuMinWidth}px` : "min-width: 120px;")};
    }

    ${(props: SelectBodyProps) => (props.hideIndicator ? ".select__indicator, .select__indicators { display: none; }" : "")}

    .select__indicator {
        padding: 4px 8px;
    }
    .select__control {
        border-radius: 16px;
        background-color: ${(props) => props.theme.palette.background};
        border-color: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.text};
        min-height: 33px;
        ${(props: SelectBodyProps) => props.selectMinWidth && `min-width: ${props.selectMinWidth}px`};

        &.select__control--menu-is-open {
            border-color: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    &.hover {
        &:hover {
            .select__control {
                border-color: ${(props) => props.theme.palette.backgroundDark};
                background: ${(props) => props.theme.palette.backgroundLight};
            }
        }
    }

    .select__control--is-disabled {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
        .select__placeholder {
            color: ${(props) => props.theme.palette.textLight}40;
        }
    }

    &.border-none {
        .select__control {
            border-radius: 5px;
            border-color: transparent;
            background-color: transparent;
        }
    }

    .item-active {
        .select__control {
            background-color: ${(props) => props.theme.palette.backgroundLight};
            border-color: transparent;
            font-weight: 500;
            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark}60;
                border-color: ${(props) => props.theme.palette.backgroundDark};
            }
        }
    }

    .select__control--is-disabled {
        opacity: 0.5;
    }
    &.danger {
        .select__control {
            background-color: ${(props) => props.theme.palette.backgroundLight};
            border-color: #f44336;

            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark}60;
            }
        }
    }
    .select__single-value {
        color: ${(props) => props.theme.palette.text};
    }
    .select__input-container {
        color: ${(props) => props.theme.palette.textLight};
    }
    .select__option--is-focused {
        background-color: ${(props) => props.theme.palette.primary}40;
        color: ${(props) => props.theme.palette.text};
        &.select__option--is-selected {
            background-color: ${(props) => props.theme.palette.primaryDark};
        }
    }
    .select__option--is-selected {
        background-color: ${(props) => props.theme.palette.primary};
        color: white;
    }
    .select__menu-list {
        background: ${(props) => props.theme.palette.background};
        padding: 0px;
    }
    .select__indicator-separator {
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .react-select-basic {
        min-width: 180px;
        width: 100%;
    }

    .select-max-len {
        max-width: 300px;
    }

    .select-min-len {
        min-width: 100px;
    }
    .select-flex {
        .select__control {
            display: flex;
        }
    }
    &.bg-light {
        .select__control {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .select-small {
        .select__control {
            min-height: 20px;
        }

        .select__single-value {
            font-weight: 500;
            font-size: 13px;
        }

        .select__value-container {
            padding: 0px;
            padding-left: 4px;
            margin: 0px;
        }
        .select__input-container {
            padding: 0px;
            margin: 0px;
        }
        .select__indicators {
            height: 20px;
        }
    }
`;
