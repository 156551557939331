import React, { useEffect, useState } from "react";
import { useEventListenerWindow } from "..";
import { ProductTitleLink } from "../Products/ProductProfileNavigation/styled";
import { Flex, FlexImageStyle, SquareButton, TextField } from "../_controls";
import NavigationMenuPanel, { NavigationMenuPanelProps } from "./NavigationMenuPanel";
import { NavigationContainer, NavigationContentWrapped, ProductProfileNavigationWrapped } from "./styled";

interface NavigationProfileProps<T extends string> extends NavigationMenuPanelProps<T> {
    headerComp: React.ReactNode;
    children: React.ReactNode;
}

export default function NavigationProfile<T extends string>(props: NavigationProfileProps<T>) {
    const panelRef = React.useRef<HTMLDivElement>(null);
    const [isMobileView, setIsMobileView] = useState(false);

    useEventListenerWindow("resize", () => handleCheckView());
    useEffect(() => handleCheckView(), []);

    const handleCheckView = () => {
        if (panelRef.current) {
            const width = panelRef.current.offsetWidth;
            setIsMobileView(width < 800);
        }
    };

    return (
        <NavigationContainer className={`${isMobileView ? "navigation-mobile" : ""}`} ref={panelRef}>
            {props.headerComp}
            <ProductProfileNavigationWrapped>
                <NavigationMenuPanel {...props} />
                <NavigationContentWrapped className="navigation-content-wrapped">{props.children}</NavigationContentWrapped>
            </ProductProfileNavigationWrapped>
        </NavigationContainer>
    );
}

export const useSelectedGroupTab = <T extends string>(defaultTab: T) => {
    const [selectedGroup, setSelectedGroup] = useState<T>(() => {
        const url = new URL(window.location.href);
        const tab = url.searchParams.get("groupTab");
        if (tab) return tab as T;
        return defaultTab;
    });

    //update url params when change tab
    useEffect(() => {
        const url = new URL(window.location.href);
        url.searchParams.set("groupTab", selectedGroup);
        window.history.pushState({}, "", url.toString());
    }, [selectedGroup]);

    return { selectedGroup, setSelectedGroup };
};

export interface NavigationProfileHeaderProps {
    showBackButton?: boolean;
    onBackAction?: () => void;
    image?: string;
    titleComponent?: React.ReactNode;
    title: string;
    titleLink: string;
    subTitle?: string;
    customComponent?: React.ReactNode;
    customActions?: React.ReactNode;
}
export const NavigationProfileHeader = (props: NavigationProfileHeaderProps) => {
    const { image, title, titleLink, subTitle } = props;

    return (
        <Flex column gap10>
            <Flex gap10 alignCenter spaceBetween>
                <Flex gap15 alignCenter overflowAuto>
                    {props.showBackButton && (
                        <SquareButton
                            onClick={() => {
                                if (props.onBackAction) props.onBackAction();
                                else window.history.back();
                            }}
                        >
                            <i className="fa-regular fa-arrow-left"></i>
                        </SquareButton>
                    )}
                    {image && (
                        <FlexImageStyle width={40} borderRadius={10} fitCover>
                            <img src={image} alt="" />
                        </FlexImageStyle>
                    )}
                    {props.titleComponent}
                    <Flex column overflowAuto>
                        <ProductTitleLink to={titleLink}>{title}</ProductTitleLink>
                        <TextField light ellipsis>{subTitle}</TextField>
                    </Flex>
                </Flex>
                {props.customActions}
            </Flex>
            {props.customComponent}
        </Flex>
    );
};
