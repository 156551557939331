import { getStoredData, LocalStoreKeys } from "..";
import {
    ADD_INVENTORY_ADJUSTMENT,
    InventoryAdjustmentTypes,
    IInventoryAdjustment,
    IInventoryAdjustmentState,
    INVALID_REQUEST_INVENTORY_ADJUSTMENTS,
    RECEIVE_INVENTORY_ADJUSTMENTS,
    REQUEST_INVENTORY_ADJUSTMENTS,
    UPDATE_INVENTORY_ADJUSTMENT,
} from "../../api/models/inventoryAdjustment";

export class InitStateBase implements IInventoryAdjustmentState {
    items: IInventoryAdjustment[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IInventoryAdjustmentState>(new InitStateBase(), LocalStoreKeys.inventoryAdjustment);

export const inventoryAdjustmentReducer = (state: IInventoryAdjustmentState, action: InventoryAdjustmentTypes): IInventoryAdjustmentState => {
    switch (action.type) {
        case ADD_INVENTORY_ADJUSTMENT:
            return {
                ...state,
                items: [...state.items, action.value],
            };
        case UPDATE_INVENTORY_ADJUSTMENT:
            let newlist: IInventoryAdjustment[] = [];

            state.items.forEach((item) => {
                if (item.id === action.value.id) {
                    newlist.push(action.value);
                } else {
                    newlist.push(item);
                }
            });

            return {
                ...state,
                items: newlist,
            };
        case RECEIVE_INVENTORY_ADJUSTMENTS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.items,
            };
        case REQUEST_INVENTORY_ADJUSTMENTS:
            return {
                ...state,
                isFetching: true,
                isError: false,
            };
        case INVALID_REQUEST_INVENTORY_ADJUSTMENTS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                error: action.value,
            };
        default:
            return state;
    }
};
