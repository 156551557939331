import React, { Ref, forwardRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSProperties } from "styled-components";
import { v4 as uuid } from "uuid";
import { useEventListenerDocument, useEventListenerWindow } from "..";
import { SquareButton } from "../_controls";
import { ButtonClose, CloseContainer, ModalHeader, ModalTitle, WahioModal, WahioModalBody, WahioModalBodyParent } from "./styled";

export interface IModalShow {
    show: boolean;
    setShow: (value: boolean) => void;
}

export type ModalSize = "sm" | "sm2" | "md" | "md2" | "md3" | "lg" | "lg2" | "auto";

export type ColorStyleType = "danger" | "success";

interface ModalBaseDetails {
    children?: React.ReactNode;
    showHeader?: boolean;
    title?: string;
    classNameTitle?: string;
    removeVerticalAlign?: boolean;
    removeTopPadding?: boolean;
    sizeType?: ModalSize;
    scrollInside?: boolean;
    overflowAuto?: boolean;
    borderRadius?: number;
    ignoreOutsideClick?: boolean;
    useButtonClose?: boolean;
    styleBody?: CSSProperties;
    functionClose?: () => void;
    useMobileView?: boolean;
    zIndex?: number;
    hideScrollBar?: boolean;
    colorStyle?: ColorStyleType;
    overflowBodyNormal?: boolean;
}
interface ModalBaseProps extends ModalBaseDetails {
    id?: string;
    show: boolean;
    setShow: (value: boolean) => void;
}

const MODA_CLASS_NAME = "wahio-modal-2273482734829349";

const WahioModalHeader = (props: ModalBaseProps) => {
    return (
        <ModalHeader className={`modal-header`}>
            <ModalTitle className={props.classNameTitle ?? ""}>{props.title}</ModalTitle>
            <CloseContainer>
                <ButtonClose
                    type="button"
                    className={`btn-close`}
                    onClick={() => {
                        props.setShow(false);
                        if (props.functionClose) {
                            props.functionClose();
                        }
                    }}
                >
                    <span className={`fa-regular fa-xmark`}></span>
                </ButtonClose>
            </CloseContainer>
        </ModalHeader>
    );
};

const ModalBase = forwardRef((props: ModalBaseProps, ref: Ref<HTMLDivElement>) => {
    let { children, show, setShow, showHeader: header, sizeType } = props;
    sizeType = sizeType ?? "sm";

    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const [modalId] = useState(props.id ?? uuid());

    const onClickParent = (e: any) => {
        if (props.ignoreOutsideClick) return;
        if (e.target.id === modalId) {
            setShow(false);
        }
    };

    useEventListenerWindow("resize", () => {
        setContainerWidth(window.innerWidth);
    });

    useEventListenerDocument("keydown", (e) => {
        if (e.key === "Escape") {
            var elements = document.getElementsByClassName(MODA_CLASS_NAME);
            var elementsId = Array.prototype.filter.call(elements, (element) => element);
            var ids = elementsId.map((element) => element.id);
            if (ids.length > 0 && ids[ids.length - 1] === modalId) {
                props.setShow(false);
            }
        }
    });

    const verticalAlign = props.removeVerticalAlign ? "" : "m-auto";
    const removeTop = props.removeTopPadding ? "remove-top" : "";
    const classInsideScroll = props.scrollInside ? "wahio-modal-content-auto-scroll" : "";
    const overflowAutoClass = props.overflowAuto ? "overflow-auto" : "";

    const useMobileView = props.useMobileView && containerWidth < 900 ? "mobile-view" : "";

    let bodyStyle = props.styleBody ?? {};
    let contentChildren: CSSProperties = {};
    if (props.colorStyle) {
        bodyStyle.overflow = "hidden";
    }
    if (props.overflowBodyNormal) {
        bodyStyle.overflow = "visible";
    }

    const showHeader = !props.useButtonClose && header;

    if (showHeader && props.overflowAuto) {
        bodyStyle.display = "grid";
        bodyStyle.gridTemplateRows = "auto 1fr";
        bodyStyle.maxHeight = "calc(100vh - 40px)";

        contentChildren.overflow = "auto";
        contentChildren.height = "100%";
    }

    return (
        <WahioModal
            zIndex={props.zIndex}
            className={`${MODA_CLASS_NAME} ${useMobileView} ${props.colorStyle ?? ""} ${props.hideScrollBar ? "hideScrollBar" : ""}`}
            onClick={onClickParent}
            id={modalId}
            show={show}
            ref={ref}
        >
            <WahioModalBodyParent
                className={`modal-body-parent wahio-modal-${sizeType} ${overflowAutoClass} ${verticalAlign} ${removeTop}`}
            >
                <WahioModalBody style={bodyStyle} borderRadius={props.borderRadius} className={`modal-body`}>
                    {showHeader && <WahioModalHeader {...props} />}
                    <div className={`${classInsideScroll} content-children`} style={contentChildren}>
                        {props.useButtonClose && (
                            <SquareButton
                                removeBackground
                                onClick={() => {
                                    props.setShow(false);
                                }}
                                className="btn-close-rounded"
                            >
                                <i className="fa-regular fa-xmark"></i>
                            </SquareButton>
                        )}
                        {children}
                    </div>
                </WahioModalBody>
            </WahioModalBodyParent>
        </WahioModal>
    );
});

const Modal = (props: ModalBaseProps) => {
    const refElement = React.createRef<any>();

    useEffect(() => {
        // const divModal = document.getElementById("modal");
        // if (divModal && refElement.current) {
        //     const indice = Array.prototype.indexOf.call(divModal.children, refElement.current);
        //     setElementIndex(indice);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ReactDOM.createPortal(<ModalBase {...props} ref={refElement} />, (document as any).querySelector("#modal"));
};

export const useModal = () => {
    const [showModal, setShowModal] = useState(false);

    const show = (value?: boolean) => {
        setShowModal(value !== undefined ? value : true);
    };
    const ModalResult = (props: ModalBaseDetails) => (showModal ? <Modal {...props} show={showModal} setShow={setShowModal} /> : <></>);

    return { Modal: ModalResult, show };
};

export default Modal;
