import React from "react";
import styled from "styled-components";
import { Flex, TextField } from "../containers";

interface CheckBoxProps {
    id?: string;
    name?: string;
    title?: string;
    description?: string;
    onChange: (value: any) => void;
    checked?: boolean;
    className?: string;
    toolTip?: string;
    classNameTitle?: string;
    small?: boolean;
}

const CheckboxContainer = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 24px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkbox-title {
        padding-left: 15px;
        display: flex;
    }

    .checkmark {
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 4px;
        border: 2px solid ${(props) => props.theme.palette.textLight}80;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover input ~ .checkmark {
        box-shadow: 0px 0px 0px 6px ${(props) => props.theme.palette.backgroundDark};
    }

    input:checked ~ .checkmark {
        background-color: ${(props) => props.theme.palette.primary};
        border-color: ${(props) => props.theme.palette.primary};
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }
    .checkmark:after {
        top: 5%;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &.small{
        .checkmark{
            height: 15px;
            width: 15px;
            border: 1px solid ${(props) => props.theme.palette.textLight}80;
        }

    }
`;

export default function CheckBox(props: CheckBoxProps) {
    const { id, name, title } = props;

    return (
        <CheckboxContainer className={`checkbox-container checkbox ${props.className ?? ""}`}>
            {title && (
                <Flex column className="checkbox-title">
                    <span className={` ${props.classNameTitle ?? ""}`}>{title}</span>
                    {props.description && (
                        <TextField light small>
                            {props.description}
                        </TextField>
                    )}
                </Flex>
            )}
            <input checked={props.checked} type="checkbox" id={id} name={name} onChange={props.onChange} />
            <span className="checkmark"></span>
        </CheckboxContainer>
    );
}
