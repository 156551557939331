import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { getSelectOption } from "../../utils/select";
import { SelectBody } from "../Select/styled";
import { Flex, SquareButton } from "../_controls";
import { FlexMainContainer } from "./styled";

export interface ILabelAction {
    index: number;
    onRemove: (index: number) => void;
    onAdd: () => void;
    totalItems: number;
    itemNumber: number;
    placeholder: string;
    labelPlaceholder: string;
}

interface ILabelItem extends ILabelAction {
    id: string;
    onChange: (value: string, label: string) => void;
    value: string;
    label?: string;
    className?: string;
    showRemove: boolean;
    showAdd: boolean;
    initialComponent?: any;
    minWidth?: boolean;
}

export default function InputTwoColsActions(props: ILabelItem) {
    const [selectValue, setSelectValue] = useState("");

    const onChange = (e: any) => {
        props.onChange(e.target.value, props.label ?? "");
    };
    const initialComp = props.initialComponent ? props.initialComponent : null;
    return (
        <FlexMainContainer alignCenter>
            {initialComp}
            <input
                className={`value-label-input ${props.minWidth ? "min-Width" : ""}`}
                onChange={onChange}
                name="value"
                value={props.value}
                type="text"
                placeholder={props.placeholder}
            />

            <SelectBody className="select-body">
                <CreatableSelect
                    placeholder={"Etiqueta"}
                    key={props.label}
                    classNamePrefix="select"
                    value={props.label ? getSelectOption({ id: props.label, value: props.label }) : undefined}
                    onInputChange={(value) => setSelectValue(value)}
                    isDisabled={false}
                    onChange={(value) => props.onChange(props.value, value?.value ?? "")}
                    onCreateOption={() => props.onChange(props.value, selectValue)}
                    isRtl={false}
                    isSearchable={true}
                    options={["Oficina", "Casa", "Empresa", "Local"].map((item) => getSelectOption({ id: item, value: item }))}
                />
            </SelectBody>
            <Flex gap10>
                <SquareButton disabled={!props.showAdd} small onClick={props.onAdd}>
                    <span className="wahioicon-plus"></span>
                </SquareButton>
                <SquareButton disabled={!props.showRemove} small onClick={() => props.onRemove(props.index)}>
                    <span className="wahioicon-trash"></span>
                </SquareButton>
            </Flex>
        </FlexMainContainer>
    );
}
