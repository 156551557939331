import { ProductActiveInput } from ".";
import { PRODUCT_SQUARE_LIST_CARD } from "../ProductCard";

export const getActiveProductQuantity = (activeProducts: ProductActiveInput[], productId: string, warehouseId?: string) => {
    if (activeProducts && activeProducts.length > 0) {
        const items = activeProducts.filter((x) => x.productId === productId && (!warehouseId || x.warehouseId === warehouseId));
        return items.reduce((sum, item) => (sum += item.quantity), 0);
    }
    return 0;
};

export const calculateProductGridColumns = (result: (result: number) => void) => {
    let elements = document.getElementsByClassName(PRODUCT_SQUARE_LIST_CARD);
    var rowLength = Array.from(elements).reduce(
        (prev: any, next: any) => {
            if (!prev[2]) {
                var ret = next.getBoundingClientRect().left;
                // if increasing, increment counter
                if (!(prev[0] > -1 && ret < prev[1])) {
                    prev[0]++;
                } else {
                    prev[2] = 1;
                } // else stop counting
            }
            return [prev[0], ret, prev[2]]; // [counter, elem, stop-counting]
        },
        [0, null, 0]
    )[0];
    
    result(rowLength);
};

export const ID_IGNORE_CLICK = "ID_IGNORE_CLICK";
