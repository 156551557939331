import { defineMessages } from "react-intl";

const base = "components.deviceSettings";

export default defineMessages({
    changesSavedSuccessfully: {
        id: `${base}.changesSavedSuccessfully`,
        defaultMessage: "Los cambios se guardaron correctamente",
    },
    stock: {
        id: `${base}.stock`,
        defaultMessage: "Stock",
    },
    onlyWithStock: {
        id: `${base}.onlyWithStock`,
        defaultMessage: "Sólo con Stock",
    },
    onlyNegativeStock: {
        id: `${base}.onlyWithoutStock`,
        defaultMessage: "Sólo stock negativo",
    },
    images: {
        id: `${base}.images`,
        defaultMessage: "Imágenes",
    },
    onlyWithImage: {
        id: `${base}.onlyWithImage`,
        defaultMessage: "Sólo con Imagen",
    },
    onlyWithoutImage: {
        id: `${base}.onlyWithoutImage`,
        defaultMessage: "Sólo sin Imagen",
    },
    minimalPrice: {
        id: `${base}.minimalPrice`,
        defaultMessage: "Precio Mínimo",
    },
    maximumPrice: {
        id: `${base}.maximumPrice`,
        defaultMessage: "Precio Máximo",
    },
    minimumStock: {
        id: `${base}.minimumStock`,
        defaultMessage: "Stock Mínimo",
    },
    maximumStock: {
        id: `${base}.maximumStock`,
        defaultMessage: "Stock Máximo",
    },
    apply: {
        id: `${base}.apply`,
        defaultMessage: "Aplicar",
    },
    prices: {
        id: `${base}.prices`,
        defaultMessage: "Precios",
    },
});
