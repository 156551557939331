import React from "react";
import { SelectBody } from "../Select/styled";
import Select from "react-select";
import { PAYMENT_METHOD_LIST, PaymentMethod } from "../../api/models";
import { getSelectOption } from "../../utils/select";
import { useIntl } from "react-intl";
import paymentMessages from "../../messages";
import { TextField } from "../_controls";

interface PaymentMethodSelectProps {
    method?: PaymentMethod;
    onChange: (value: PaymentMethod) => void;
    readonly?: boolean;
}

export default function PaymentMethodSelect(props: PaymentMethodSelectProps) {
    const { method } = props;
    const intl = useIntl();

    if (props.readonly) {
        return <TextField>{method ? intl.formatMessage(paymentMessages[method]) : "Sin Método"}</TextField>;
    }

    return (
        <SelectBody className="select-body" menuMinWidth={150}>
            <Select
                placeholder={"Método"}
                className={`${method ? "item-active" : ""}`}
                key={method}
                classNamePrefix="select"
                defaultValue={
                    method
                        ? getSelectOption({
                              id: method,
                              value: intl.formatMessage(paymentMessages[method] ?? paymentMessages.invalid),
                          })
                        : undefined
                }
                onChange={(value) => props.onChange(value?.id as PaymentMethod)}
                isRtl={false}
                isSearchable={true}
                name="seller"
                options={PAYMENT_METHOD_LIST.map((item) => getSelectOption({ id: item, value: intl.formatMessage(paymentMessages[item]) }))}
            />
        </SelectBody>
    );
}
