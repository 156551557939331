import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { CreatePropsFormProps } from "..";
import { createEventCalendar } from "../../../../api/marketing/marketingApi";
import { getUserPermissions } from "../../../../api/models/accountUser";
import { IDeliveryOrder } from "../../../../api/models/deliveryOrder";
import { EventCalendar } from "../../../../api/models/eventCalendar";
import { Order } from "../../../../api/models/order";
import { OrderStatus } from "../../../../api/models/orderBase";
import { TransactionAccount, TransactionAccountImp } from "../../../../api/models/transactionAccount";
import { createUpdateOrder } from "../../../../api/orders/orderApi";
import { useWahioSocket } from "../../../../api/socket";
import { AppSettingsContext } from "../../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../../auth";
import PATHS from "../../../../constants/paths";
import { useOrderDataSummaryContext } from "../../../../store/contexts/OrderDataSummaryContext";
import { UserContext } from "../../../../store/contexts/UserContext";
import { useWarehouseContext } from "../../../../store/contexts/WarehouseContext";
import { formatMoney } from "../../../../utils";
import customMoment from "../../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import { ProductExceptionFormModal } from "../../../Products/ProductExceptionForm";
import { DefaultButton, PrimaryButton, TextField } from "../../../_controls";
import Modal, { IModalShow } from "../../../modals/Modal";
import { useWarehouseProductException } from "../../OrderProfile/hooks";
import { checkDeliveryOrder } from "../helpers";
import { DeliveryOrderLineStyle, OrderShipmentConfirmationStyle } from "../styled";
import { getDefaultNotification } from "./NotificationOrderCard";
import NotificationMessageCard from "./NotificationMessageCard";
import { TotalAmountCard } from "./styled";

interface OrderShipmentConfirmationModalProps extends IModalShow, CreatePropsFormProps {
    onCreateTransactionAccount: (data: TransactionAccount) => void;
}

export default function OrderShipmentConfirmationModal(props: OrderShipmentConfirmationModalProps) {
    const { order } = props;
    const alert = useAlert();
    const { userState } = useContext(UserContext);
    const { getWarehouseById } = useWarehouseContext();
    const { appSettingsState } = useContext(AppSettingsContext);
    const wahioSocked = useWahioSocket();
    const warehouseException = useWarehouseProductException();

    const { orderLocalStorageState } = useOrderDataSummaryContext();

    useEffect(() => {
        const { sendNotification, showNotificationCard } = getDefaultNotification(order);
        props.onOrderChange({ ...order, showNotificationCard, sendNotification });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createEventMutation = useMutation((data: EventCalendar) => createEventCalendar(data));

    const orderMutation = useMutation((order: Order) => createUpdateOrder(order), {
        onSuccess: (data) => {
            if (!order.id) wahioSocked.sendOrderEvent(order);

            if (order.deliveryOrder) handleCreateShipmentEvent(order.deliveryOrder, data);

            props.onCompleted(data);
            if (order.deliveryOrder?.includesTransactionAccount) {
                props.onCreateTransactionAccount(getTransactionAccount(data, order.deliveryOrder));
            }
            alert.notification({
                title: "Orden Creada con éxito",
                description: `Orden #${data.number}`,
                icon: "fa-regular fa-bag-shopping",
                openLink: `${window.location.origin}${PATHS.orderShipmentProfile(data.id)}`,
            });
            props.setShow(false);
        },
        onError: (error) => {
            warehouseException.checkError(error);
            warehouseException.setShowModalErrors(true);
        },
    });

    const getTransactionAccount = (order: Order, deliveryOrder: IDeliveryOrder) => {
        let amountAccount: number = 0;
        let costsItemsString: string[] = [];
        if (deliveryOrder.costItems) {
            deliveryOrder.costItems.forEach((item) => {
                amountAccount += item.value;
                costsItemsString.push(`${item.name}: ${formatMoney(item.value)}`);
            });
        }
        let transactionAccount = new TransactionAccountImp(
            order.organizationId,
            deliveryOrder.courierId ?? "",
            deliveryOrder?.accountingConcept?.id ?? "",
            "payable"
        );

        transactionAccount.referenceId = order.id;
        transactionAccount.referenceType = "order";

        transactionAccount.dueDate = customMoment().add(30, "days").toDate();
        transactionAccount.paymentTermsDays = 30;

        transactionAccount.notes = `Cuenta por pagar orden #${order.number}, ${costsItemsString.join(", ")}`;
        transactionAccount.totalAmount = amountAccount;
        return transactionAccount;
    };

    const getTitle = () => {
        let status = order.status ?? props.status;
        if (status === "draft") return order.id ? "Actualizar Borrador" : "Crear Borrador";
        if (status === "queued") return order.id ? "Actualizar Orden" : "Crear Orden";
        if (status === "quotation") return order.id ? "Actualizar Cotización" : "Crear Cotización";

        return order.id ? "Guardar Cambios" : "Crear Orden";
    };

    const onSaveStatusOrder = (status: OrderStatus) => {
        if (getAuthBody().accountId !== userState.user?.accountId) {
            alert.error(
                "No se puede crear la orden porque se ha combinado la información de dos cuentas, por favor cierra sesión y vuelve a intentar"
            );
            return;
        }

        let orderCopy = { ...order };
        orderCopy.status = status;
        orderCopy.account = userState.user?.account;
        orderCopy.orderMetadata = orderLocalStorageState.orderMetadata;

        let warehouse = getWarehouseById(appSettingsState.defaultWarehouseId);
        orderCopy.allowLogisticsService = !!warehouse?.allowLogisticsService;

        if (status === "queued") {
            orderCopy.isDelivery = true;
            if (!orderCopy.deliveryOrder) {
                alert.error(`El envío es requerido`);
                return;
            }
        }

        if (!orderCopy.id) {
            orderCopy.dateCreatedCustom = appSettingsState.orderForm?.allowCustomDate ? orderCopy.dateCreatedCustom : undefined;
        }

        if (!orderCopy.accountUserId) {
            orderCopy.accountUserId = getAuthBody().accountUserId;
        }

        orderCopy.accountId = getAuthBody().accountId;
        orderCopy.organizationId = appSettingsState.defaultOrganizationId;

        order.tipBeforeTax = appSettingsState.orderForm?.tipBeforeTax ?? false;
        order.discountBeforeTax = appSettingsState.orderForm?.discountBeforeTax ?? false;

        orderCopy.includeInvoice = false;
        orderCopy.lastAccountUserId = getAuthBody().accountUserId;
        orderCopy.payments = [];
        orderCopy.deliveryOrder = orderCopy.isDelivery ? orderCopy.deliveryOrder : undefined;
        orderCopy.status = status;
        orderCopy.source = orderCopy.source ? orderCopy.source : "manually";
        orderCopy.totalTaxItems = orderCopy.totalTaxItems ? orderCopy.totalTaxItems : 0;

        if (orderCopy.isDelivery && !orderCopy.deliveryOrder) {
            alert.error("El envío es requerido");
        }

        if (orderCopy.isDelivery && orderCopy.deliveryOrder) {
            if (!checkDeliveryOrder(orderCopy.deliveryOrder)) {
                alert.error("Falta información en los detalles de envío.");
                return;
            }
        }

        if (props.status === "quotation") {
            orderCopy.isDelivery = false;
            orderCopy.deliveryOrder = undefined;
        }

        if (!orderCopy.isDelivery) {
            orderCopy.deliveryOrder = undefined;
        }

        if (status === "queued" && getUserPermissions(userState.user).order?.createWithoutConfirmation) {
            orderCopy.status = "credit";
        }

        orderMutation.mutate(orderCopy);
    };

    const handleCreateShipmentEvent = (deliveryOrder: IDeliveryOrder, createOrder: Order) => {
        if (deliveryOrder.event) {
            let event: EventCalendar = { ...deliveryOrder.event };
            event.title = `Orden #${createOrder.number}. ${deliveryOrder.contactName}`;
            event.relatedEntityId = createOrder.id;
            event.relatedEntityType = "order";
            createEventMutation.mutate(event);
        }
    };

    return (
        <Modal sizeType="sm" ignoreOutsideClick {...props} title={getTitle()} useButtonClose>
            {warehouseException.showModalErrors && warehouseException.errorList.length > 0 && (
                <ProductExceptionFormModal
                    show={warehouseException.showModalErrors}
                    setShow={warehouseException.setShowModalErrors}
                    items={warehouseException.errorList}
                />
            )}
            <OrderShipmentConfirmationStyle>
                <TotalAmountCard>
                    <TextField small bold>
                        Total
                    </TextField>
                    <TextField bold600 fontSize={25} className="title-amount">
                        {formatMoney(order.totalAmount)}
                    </TextField>
                </TotalAmountCard>
                {order.deliveryOrder && <DeliveryOrderLine deliveryOrder={order.deliveryOrder} />}

                <NotificationMessageCard order={order} onOrderChange={props.onOrderChange} />

                {orderMutation.isLoading && <LoadingDualRing center />}
                {!orderMutation.isLoading && (
                    <div className="btn-actions">
                        <DefaultButton borderRadius={10} onClick={() => props.setShow(false)} className="mr-1 bg-light">
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton borderRadius={10} onClick={() => onSaveStatusOrder(props.status ?? order.status)}>
                            {getTitle()}
                        </PrimaryButton>
                    </div>
                )}
            </OrderShipmentConfirmationStyle>
        </Modal>
    );
}

interface DeliveryOrderLineProps {
    deliveryOrder: IDeliveryOrder;
}

const DeliveryOrderLine = (props: DeliveryOrderLineProps) => {
    const { deliveryOrder } = props;
    return (
        <DeliveryOrderLineStyle>
            <div className="col pr-1">
                <span>{deliveryOrder.originCity}</span>
                <span>{deliveryOrder.originState}</span>
            </div>
            <div className="icon">
                {deliveryOrder.courier && deliveryOrder.courier.image ? (
                    <img src={deliveryOrder.courier.image} alt="Courier" />
                ) : (
                    <i className="fa-regular fa-truck"></i>
                )}
            </div>
            <div className="col pl-1">
                <span>{deliveryOrder.originCity}</span>
                <span>{deliveryOrder.originState}</span>
            </div>
            <div className="line"></div>
        </DeliveryOrderLineStyle>
    );
};
