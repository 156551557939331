import styled from "styled-components";
import { device} from "../../theme/themeProvider";
import { Flex } from "../_controls";

export const FlexMainContainer = styled(Flex)`
    display: flex;
    width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;

    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    :last-child {
        border-bottom: 0px;
    }
    .dropdown-btn {
        border: 0;
        gap: 0;
    }
    .dropdown-right-icon {
        padding-left: 5px;
    }
    .value-label-input {
        background: transparent;
        width: 100%;
        font-weight: 400;
        outline: none;
        padding: 0.8rem;
        padding-left: 7px;
        border-radius: 0px;
        border: 0;
        color: ${(props) => props.theme.palette.text};
        width: 100%;
        font-size: 14px;
        &::placeholder {
            font-weight: 400;
            font-size: 13px;
        }
    }
    .select-body {
        width: 100%;
        display: none;
    }

    @media ${device.sm} {
        .select-body {
            display: block;
        }
    }
    .select__control {
        border: 0;
    }
    .separator {
        width: 1px;
        background: ${(props) => props.theme.palette.backgroundDark};
    }
    .min-Width {
        width: 75%;
    }
`;
