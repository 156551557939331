import React, { useState } from "react";
import { useMutation } from "react-query";
import { ECOMMERCE_ENDPOINT, KDS_ENDPOINT, TRACK_ENDPOINT, WAHIO_POS_ENDPOINT } from "../../../api/appConfig";
import { IEcommerce } from "../../../api/models/productCatalog";
import { getEcommerceList } from "../../../api/products/ecommerceApi";
import { getAuthBody } from "../../../auth";
import { useAlert } from "../../../components/Alerts/Alert";
import Dropdown from "../../../components/Dropdown";
import { getEcommerceName } from "../../../components/Ecommerce/helper";
import LoadingDualRing from "../../../components/LoadingDualRing";
import { DefaultButton, Flex, FlexImageStyle, GridTemplate, TextField } from "../../../components/_controls";
import Modal from "../../../components/modals/Modal";
import { capitalize, joinUrl } from "../../../utils";
import { GridCard } from "./styled";

export default function AppsDropdownGrid() {
    return (
        <>
            <Dropdown icon="fa-regular fa-grid-2" contentHorizontal="left" ignoreInternalClicks>
                <AppGrips />
            </Dropdown>
        </>
    );
}

export const AppGrips = () => {
    const [showEcommerce, setShowEcommerce] = useState(false);
    const alert = useAlert();

    const [ecommerceList, setEcommerceList] = useState<IEcommerce[]>([]);

    const ecommerceMutation = useMutation((id: string) => getEcommerceList(id), {
        onSuccess: (data) => {
            if (data.length === 1) {
                window.open(joinUrl(ECOMMERCE_ENDPOINT, getEcommerceName(data[0])), "_blank");
            } else if (data.length > 1) {
                setEcommerceList(data);
                setShowEcommerce(true);
            } else {
                alert.info("No tienes ningún ecommerce creado");
            }
        },
    });

    const [wahioPosUrl] = useState(() => {
        let url = WAHIO_POS_ENDPOINT;
        return url;
    });

    const handleLoadEcommerce = () => {
        if (ecommerceMutation.data && ecommerceMutation.data.length > 0) {
            if (ecommerceMutation.data.length === 1) {
                window.open(joinUrl(ECOMMERCE_ENDPOINT, getEcommerceName(ecommerceMutation.data[0])), "_blank");
            } else {
                setShowEcommerce(true);
            }
        } else {
            ecommerceMutation.mutate(getAuthBody().accountId);
        }
    };

    return (
        <Flex column w100>
            {showEcommerce && ecommerceList.length > 1 && (
                <Modal show={showEcommerce} setShow={setShowEcommerce}>
                    <Flex column padding={20} gap={20}>
                        <TextField>Elige la tienda que quieres abrir</TextField>
                        <Flex column gap10>
                            {ecommerceList.slice(0, 10).map((item, index) => (
                                <a
                                    key={index}
                                    target="_blank"
                                    style={{ width: "100%" }}
                                    rel="noopener noreferrer"
                                    href={joinUrl(ECOMMERCE_ENDPOINT, getEcommerceName(item))}
                                >
                                    <DefaultButton onClick={() => setShowEcommerce(false)} w100 rounded justifyStart>
                                        {capitalize(item.name)}
                                    </DefaultButton>
                                </a>
                            ))}
                        </Flex>
                    </Flex>
                </Modal>
            )}
            <Flex column padding={20} style={{ minWidth: "270px" }}>
                <GridTemplate size={100} gap={5}>
                    <a href={wahioPosUrl} target="_blank" rel="noopener noreferrer">
                        <GridCard>
                            <FlexImageStyle width={65}>
                                <img src="https://stowahioimagelibrary.blob.core.windows.net/common/order-3d.png" alt="" />
                            </FlexImageStyle>
                            <TextField>Wahio Pos</TextField>
                        </GridCard>
                    </a>
                    <a href={KDS_ENDPOINT} target="_blank" rel="noopener noreferrer">
                        <GridCard>
                            <FlexImageStyle width={65}>
                                <img src="https://stowahioimagelibrary.blob.core.windows.net/common/wahio-kds-logo.png" alt="" />
                            </FlexImageStyle>
                            <TextField>Wahio KDS</TextField>
                        </GridCard>
                    </a>
                    <GridCard onClick={() => handleLoadEcommerce()}>
                        <FlexImageStyle width={65}>
                            {ecommerceMutation.isLoading ? (
                                <LoadingDualRing />
                            ) : (
                                <img src="https://stowahioimagelibrary.blob.core.windows.net/common/ecommerce-3d.png" alt="" />
                            )}
                        </FlexImageStyle>
                        <TextField>Ecommerce</TextField>
                    </GridCard>
                    <a href={new URL(TRACK_ENDPOINT).origin} target="_blank" rel="noopener noreferrer">
                        <GridCard>
                            <FlexImageStyle width={65}>
                                <img src="https://stowahioimagelibrary.blob.core.windows.net/common/tracking-3d.png" alt="" />
                            </FlexImageStyle>
                            <TextField>Tracking</TextField>
                        </GridCard>
                    </a>
                    <GridCard>
                        <span className="label-soon">Pronto</span>
                        <FlexImageStyle width={65}>
                            <img src="https://stowahioimagelibrary.blob.core.windows.net/common/marketing-3d.png" alt="" />
                        </FlexImageStyle>
                        <TextField>Marketing</TextField>
                    </GridCard>
                </GridTemplate>
            </Flex>
        </Flex>
    );
};
