import wahioFetchApi from "../fetchApi";
import { DateFilter } from "../models";
import { NotificationSummaryRequest } from "../models/notification";
import {
    GlobalSummaryResponse,
    ProductAccumulatedSales,
    SaleItemProductResult,
    OrdersMetricsOverview,
    StatisticsCategoryGroupResult,
} from "../models/statisticsType";
import {
    CustomCategorySearchFilter,
    CustomCategorySearchGroupModel,
    GlobalSummaryFilter,
    GroupItem,
    OrderAndPurchaseGroupItem,
    OrderSearchGroupModel,
    ProductSearchGroupModel,
} from "../models/_statistics";
import { statisticsEndpoint } from "../restApiEndpoint";
export interface OrdersByBullingRangeResponse {
    orderCount: number;
}
export interface AccountReportResponse {
    orderCount: number;
    productCount: number;
    contactCount: number;
    ebillingCount: number;
}

export const ordersPurchasesInGroup = (data: OrderSearchGroupModel): Promise<OrderAndPurchaseGroupItem[]> => {
    return wahioFetchApi.post(statisticsEndpoint.post.orderPurchaseInGroup, data).then((res: any) => res.data);
};

export const getStatisticsBestProducts = (data: DateFilter): Promise<ProductAccumulatedSales[]> => {
    return wahioFetchApi.post(statisticsEndpoint.post.productSales, data).then((res: any) => res.data);
};
export const getStatisticsLastProductSales = (data: DateFilter): Promise<SaleItemProductResult[]> => {
    return wahioFetchApi.post(statisticsEndpoint.post.lastProductSales, data).then((res: any) => res.data);
};
export const getStatisticsCustomCategoryGroup = (data: DateFilter): Promise<StatisticsCategoryGroupResult[]> => {
    return wahioFetchApi.post(statisticsEndpoint.post.customCategoryGroup, data).then((res: any) => res.data);
};

export const statisticGlobalSummary = (data: GlobalSummaryFilter): Promise<GlobalSummaryResponse> => {
    return wahioFetchApi.post(statisticsEndpoint.post.globalSummary, data).then((res: any) => res.data);
};

export const getOrdersMetricsOverview = (data: GlobalSummaryFilter): Promise<OrdersMetricsOverview> => {
    return wahioFetchApi.post(statisticsEndpoint.post.ordersMetricsOverview, data).then((res: any) => res.data);
};

export const productSalesInGroup = (data: ProductSearchGroupModel): Promise<GroupItem[]> => {
    return wahioFetchApi.post(statisticsEndpoint.post.productSalesInGroup, data).then((res: any) => res.data);
};
export const productSalesByRange = (data: ProductSearchGroupModel): Promise<GroupItem> => {
    return wahioFetchApi.post(statisticsEndpoint.post.productSalesByRange, data).then((res: any) => res.data);
};

export const customCategorySalesInGroup = (data: CustomCategorySearchFilter): Promise<GroupItem[]> => {
    return wahioFetchApi.post(statisticsEndpoint.post.customCategoryalesInGroup, data).then((res: any) => res.data);
};
export const customCategorySalesByRange = (data: CustomCategorySearchGroupModel): Promise<GroupItem> => {
    return wahioFetchApi.post(statisticsEndpoint.post.customCategorySalesByRange, data).then((res: any) => res.data);
};
export const getAccountConsumptionReport = (searchInput: NotificationSummaryRequest): Promise<AccountReportResponse> => {
    return wahioFetchApi.post(statisticsEndpoint.post.accountConsumption, searchInput).then((res: any) => res.data);
};
export const getOrdersCountByBillingRange = (data: NotificationSummaryRequest): Promise<OrdersByBullingRangeResponse> => {
    return wahioFetchApi.post(statisticsEndpoint.post.orderCountByRange, data).then((res: any) => res.data);
};
