import React from "react";
import { useUserContext } from "../../store/contexts/UserContext";
import { AccountPlanLabel } from "./styled";

interface FreeLabelViewProps {
    onClick: () => void;
}

export default function FreeLabelView(props: FreeLabelViewProps) {
    const { userState } = useUserContext();
    const freeDays = userState.freeDays;
    if (freeDays === undefined) return null;

    let message = freeDays > 1 ? `${userState.freeDays} días` : `1 dia`;

    if (freeDays <= 0) {
        message = "";
    }

    return (
        <AccountPlanLabel onClick={props.onClick}>
            <span className="hide-mobile">Wahio App</span>
            <span className="assent-message">
                <span className="hide-mobile">Prueba Gratuita</span>
                <span className="show-mobile">Free</span>
                <span>{message}</span>
            </span>
        </AccountPlanLabel>
    );
}
