import React, { createContext } from "react";
import { useIntl } from "react-intl";
import { AccountClientSettings, AccountClientSettingsImpl, PriceSettings, ShipmentSettings } from "../../api/models/accountUser";
import { ALL_SHIPMENT_STATUS_LIST, DeliveryStatus, SIMPLE_SHIPMENT_STATUS_LIST, ShipmentStatusItem } from "../../api/models/deliveryOrder";
import { getAuthBody } from "../../auth";
import { SHIPMENT_ICON_GROUP, SHIPMENT_STATUS_COLOR } from "../../components/Orders/Shipments/helpers";
import shipmentMessages from "../../components/Orders/Shipments/messages";
import { CLIENT_PRODUCT_PRICES } from "../../components/Products/PriceList/helper";
import priceMessages from "../../components/Products/PriceList/messages";
import { checkAndGetMessage } from "../../i18n/helper";
import { useUserContext } from "./UserContext";

interface ShipmentStatusObjectState {
    status: Record<DeliveryStatus, ShipmentStatusItem>;
    statusActive: Record<DeliveryStatus, ShipmentStatusItem>;
    statusLogistic: Record<DeliveryStatus, ShipmentStatusItem>;
    statusLogisticActive: Record<DeliveryStatus, ShipmentStatusItem>;
}

const defaultShipmentStatusItem = (): ShipmentStatusItem => ({
    title: "",
    icon: "",
    disabled: true,
    color: "#f57f17",
});

const defaultShipmentStatusObjectState = (): ShipmentStatusObjectState => {
    const state = {} as Record<DeliveryStatus, ShipmentStatusItem>;
    const defaultItem = defaultShipmentStatusItem();
    state.pending = defaultItem;

    return {
        status: { ...state },
        statusActive: { ...state },
        statusLogistic: { ...state },
        statusLogisticActive: { ...state },
    };
};

const EXCLUDED_STATUS = ["picking", "packing"];

interface ClientSettingsContextProps {
    clientSettings: AccountClientSettings;
    shipmentSettings: ShipmentSettings;
    priceSettings: PriceSettings;
    shipmentStatusState: ShipmentStatusObjectState;
    saveSettingsField: (value: any, name: keyof AccountClientSettings) => void;
    getStatusItem: (status: DeliveryStatus) => ShipmentStatusItem;
    getPriceTitle: (priceKey: string) => string;
}

export const ClientSettingsContext = createContext({} as ClientSettingsContextProps);

const ClientSettingsContextProvider = (props: any) => {
    const intl = useIntl();
    const { userState, setUserState } = useUserContext();
    const account = userState.user?.account;

    const clientSettings = React.useMemo(() => {
        return account?.clientSettings ?? new AccountClientSettingsImpl(getAuthBody().accountId);
    }, [account?.clientSettings]);

    const shipmentSettings = React.useMemo(() => {
        let _shipmentSettings: ShipmentSettings = clientSettings?.shipmentSettings ?? {};
        let statusList = _shipmentSettings.statusList ?? {};

        SIMPLE_SHIPMENT_STATUS_LIST.forEach((item) => {
            let statusItem = statusList[item];
            statusList[item] = {
                icon: statusItem?.icon ? statusItem?.icon : SHIPMENT_ICON_GROUP[item],
                disabled: statusItem?.disabled,
                title: statusItem?.title ? statusItem?.title : checkAndGetMessage(intl, shipmentMessages, item),
            };
        });
        _shipmentSettings.statusList = statusList;
        return _shipmentSettings;
    }, [clientSettings?.shipmentSettings, intl]);

    const priceSettings = React.useMemo(() => {
        let _priceSettings = clientSettings?.priceSettings ?? {};
        let priceList = _priceSettings.priceList ?? {};

        CLIENT_PRODUCT_PRICES.forEach((item) => {
            let priceItem = priceList[item];
            priceList[item] = {
                name: priceItem?.name ? priceItem?.name : checkAndGetMessage(intl, priceMessages, item),
            };
        });
        _priceSettings.priceList = priceList;
        return _priceSettings;
    }, [clientSettings.priceSettings, intl]);

    const shipmentStatusState = React.useMemo(() => {
        const createStatusItem = (item: DeliveryStatus): ShipmentStatusItem => {
            const statusSettings = shipmentSettings.statusList?.[item];
            return {
                title: statusSettings?.title ? statusSettings?.title : intl.formatMessage(shipmentMessages[item]),
                icon: statusSettings?.icon ? statusSettings?.icon : SHIPMENT_ICON_GROUP[item],
                disabled: !!statusSettings?.disabled,
                color: SHIPMENT_STATUS_COLOR[item],
            };
        };

        const reducer = (acc: ShipmentStatusObjectState, item: DeliveryStatus) => {
            const statusItem = createStatusItem(item);
            acc.statusLogistic[item] = statusItem;
            if (!statusItem.disabled) {
                acc.statusLogisticActive[item] = statusItem;
            }

            if (!EXCLUDED_STATUS.includes(item)) {
                acc.status[item] = statusItem;
                if (!statusItem.disabled) {
                    acc.statusActive[item] = statusItem;
                }
            }
            return acc;
        };
        return ALL_SHIPMENT_STATUS_LIST.reduce(reducer, defaultShipmentStatusObjectState());
    }, [intl, shipmentSettings.statusList]);

    const getStatusItem = (status: DeliveryStatus) => {
        return shipmentStatusState.statusLogistic[status];
    };

    const getPriceTitle = (priceKey: string) => {
        return priceSettings.priceList?.[priceKey]?.name ?? priceKey;
    };

    const saveSettingsField = (value: any, name: keyof AccountClientSettings) => {
        if (userState.user && account) {
            account.clientSettings = {
                ...clientSettings,
                [name]: value,
            };
            setUserState({ ...userState, user: { ...userState.user, account } });
        }
    };

    return (
        <ClientSettingsContext.Provider
            value={{
                clientSettings,
                shipmentSettings,
                priceSettings,
                saveSettingsField,
                shipmentStatusState,
                getStatusItem,
                getPriceTitle,
            }}
        >
            {props.children}
        </ClientSettingsContext.Provider>
    );
};

export const useClientSettings = () => {
    return React.useContext(ClientSettingsContext);
};

export default ClientSettingsContextProvider;
