import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getServiceMessageError } from "../../../api";
import {
    getCashRegister,
    postCashRegisterEntry,
    putCashRegisterLogFinish,
    putCashRegisterLogPreview,
    putCashRegisterMovement,
} from "../../../api/account/cashRegisterApi";
import { CashRegisterMovementInput, ICashRegister, ICashRegisterEntry } from "../../../api/models/cashRegister";
import { CashRegisterLogFinishInput } from "../../../api/models/cashRegisterLog";
import { getAuthBody } from "../../../auth";
import PATHS from "../../../constants/paths";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { formatMoney, getDateFormat, getDateFromNow, getModelFullName } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, DefaultTextarea, Flex, PrimaryButton, TextField } from "../../_controls";
import GroupButtonRow from "../../_controls/buttons/GroupButtonRow";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import { Table } from "../../_controls/tables/styled";
import ConfirmationModal from "../../modals/ConfirmationModal";
import CashRegisterSelect from "../CashRegisterSelect/CashRegisterSelect";
import { CashRegisterLogPreview } from "./CashRegisterLogPreview";
import messages from "./messages";
import { CashRegisterFinalizeContainer, CashRegisterLogView } from "./styled";

interface RegisterLogViewProps {
    setShowModal: (showModal: boolean) => void;
    hideCloseBtn?: boolean;
    blockScreen?: () => void;
}

type LogFinalAction = "moveMoney" | "clearCashRegister" | "nothing";

type FlowStatus = "review" | "finalization";

interface LogFinalizationState {
    action: LogFinalAction;
    cashRegisterDestiny?: ICashRegister;
}

export default function RegisterLogView(props: RegisterLogViewProps) {
    const intl = useIntl();
    const { cashRegisterState, cashRegisterActions } = useContext(CashRegisterContext);
    const navigate = useNavigate();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const { getUserById } = useAccountUserContext();

    const [state, setState] = useState({
        showFinalize: false,
        endAmount: 0,
        endBankAmount: 0,
        description: "",
    });

    const [addDescription, setAddDescription] = useState(false);
    const alert = useAlert();
    const [cashRegister, setCashRegister] = useState<ICashRegister>();
    const registerLog = cashRegisterState.logActive;
    const [flowStatus, setFlowStatus] = useState<FlowStatus>("review");
    const [logFinalization, setLogFinalization] = useState<LogFinalizationState>({
        action: "moveMoney",
    });

    const userOwner = React.useMemo(() => {
        if (registerLog) {
            return getUserById(registerLog.creatorUserId);
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.logActive]);

    useEffect(() => {
        if (cashRegisterState.currentCashRegister?.id) cashRegisterMutation.mutate(cashRegisterState.currentCashRegister.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cashRegisterMutation = useMutation((id: string) => getCashRegister(id), {
        onSuccess: (data) => {
            setCashRegister(data);
        },
        onError: (err) => {
            alert.error("No fue posible cargar la caja actual");
        },
    });

    const logPreviewMutation = useMutation((data: CashRegisterLogFinishInput) => putCashRegisterLogPreview(data), {
        onSuccess: () => {
            setFlowStatus("finalization");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const logFinalizationMutation = useMutation((data: CashRegisterLogFinishInput) => putCashRegisterLogFinish(data), {
        onSuccess: async () => {
            alert.success(intl.formatMessage(messages.cashClosingCompleted));

            if (logFinalization.action === "moveMoney" && logFinalization.cashRegisterDestiny) {
                await handleCreateMovement();
            } else if (logFinalization.action === "clearCashRegister") {
                await handleCreateAdjustment();
            }
            cashRegisterActions.refetch();
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const movementMutation = useMutation((data: CashRegisterMovementInput) => putCashRegisterMovement(data));
    const adjustmentMutation = useMutation((data: ICashRegisterEntry) => postCashRegisterEntry(data));

    const handleCreateAdjustment = () => {
        if (!cashRegister || cashRegister.amount === 0) return;

        let data: ICashRegisterEntry = {
            cashRegisterId: cashRegister.id ?? "NA",
            number: 0,
            amount: cashRegister.amount,
            type: "manualAdjustment",
            referenceNumber: "",
            referenceId: "",
            method: "cash",
            description: "Ajuste de cierre de caja",
            contactId: "",
            comment: "Ajuste de cierre de caja",
            isIncome: false,
            accountUserId: getAuthBody().accountUserId,
        };
        adjustmentMutation.mutate(data);
    };

    const handleCreateMovement = async () => {
        if (!cashRegister || !logFinalization.cashRegisterDestiny) return;
        let data: CashRegisterMovementInput = {
            accountUserId: getAuthBody().accountUserId,
            cashRegisterOriginId: cashRegister.id ?? "NA",
            cashRegisterDestinyId: logFinalization.cashRegisterDestiny.id ?? "NA",
            comment: "Movimiento de cierre de cada",
            amount: cashRegister.amount,
            method: "cash",
        };
        await movementMutation.mutateAsync(data);
    };

    const handleLoadLogPreview = async () => {
        let body: CashRegisterLogFinishInput = {
            cashRegisterId: cashRegister?.id ?? "NA",
            accountUserId: getAuthBody().accountUserId,
            endAmount: state.endAmount,
            endBankAmount: state.endBankAmount,
        };
        await logPreviewMutation.mutateAsync(body);
    };

    const handleFinalizeLog = () => {
        if (logFinalization.action === "moveMoney" && !logFinalization.cashRegisterDestiny) {
            alert.info("Debes seleccionar la caja de destino para poder finalizar");
            return;
        }

        let body: CashRegisterLogFinishInput = {
            cashRegisterId: cashRegister?.id ?? "NA",
            accountUserId: getAuthBody().accountUserId,
            endAmount: state.endAmount,
            endBankAmount: state.endBankAmount,
            description: addDescription ? state.description : "",
        };
        setState({ ...state, showFinalize: false });
        logFinalizationMutation.mutate(body);
    };

    const isLoading = logFinalizationMutation.isLoading;
    const movementIsLoading = movementMutation.isLoading;
    const adjustmentIsLoading = adjustmentMutation.isLoading;

    const onRedirectToLogs = () => {
        if (logFinalizationMutation.data) {
            cashRegisterActions.set({ ...cashRegisterState, logActive: undefined });
            navigate(PATHS.cashRegisterLogProfile(logFinalizationMutation.data.id));
            props.setShowModal(false);
        }
    };

    const onClearLogAndClose = () => {
        if (logFinalizationMutation.data) {
            cashRegisterActions.set({ ...cashRegisterState, logActive: undefined });
            props.setShowModal(false);
        }
    };

    if (logFinalizationMutation.data) {
        return (
            <Flex column padding={25} gap={20}>
                <h3 className="m-0">Registro de caja creado con éxito</h3>
                <Flex>
                    <Table cellPadding={0} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td className="text-light">Registro</td>
                                <td>#{logFinalizationMutation.data.number}</td>
                            </tr>
                            <tr>
                                <td className="text-light">Fecha</td>
                                <td>{getDateFormat(logFinalizationMutation.data.dateEnd, "DD MMM hh:mm:a")}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Flex>
                {movementIsLoading && (
                    <Flex alignCenter gap10>
                        <LoadingDualRing small /> <span>Creando Movimiento de caja</span>
                    </Flex>
                )}
                {adjustmentIsLoading && (
                    <Flex alignCenter gap10>
                        <LoadingDualRing small /> <span>Creando ajuste de caja</span>
                    </Flex>
                )}
                {!movementIsLoading && !adjustmentIsLoading && (
                    <Flex gap10>
                        {!props.hideCloseBtn && (
                            <DefaultButton onClick={onClearLogAndClose} rounded>
                                Cerrar
                            </DefaultButton>
                        )}
                        <PrimaryButton onClick={onRedirectToLogs} rounded>
                            <span className="wahioicon-arrow-right"></span>
                            Ver Registro de caja
                        </PrimaryButton>
                    </Flex>
                )}
            </Flex>
        );
    }

    if (!registerLog) return null;

    return (
        <>
            <CashRegisterLogView>
                {showConfirmationModal && (
                    <ConfirmationModal
                        onCancel={() => setShowConfirmationModal(false)}
                        onOkay={() => {
                            handleFinalizeLog();
                        }}
                        title={intl.formatMessage(messages.confirmCashClosing)}
                        description={""}
                        show={showConfirmationModal}
                        setShow={setShowConfirmationModal}
                    />
                )}

                <Flex gap10 alignCenter>
                    <TextField fontSize={16} color="#4caf50" className="fa-regular fa-circle-dot"></TextField>

                    <TextField bold fontSize={18}>
                        {cashRegister?.name}
                    </TextField>
                </Flex>

                {!state.showFinalize && (
                    <Table cellPadding={0} cellSpacing={0} tdPadding={8} tdPaddingRight={20} hideBorde>
                        <tbody>
                            <tr>
                                <td className="min-width">
                                    <TextField small light>
                                        {intl.formatMessage(messages.date)}
                                    </TextField>
                                </td>
                                <td>
                                    <TextField>{getDateFormat(registerLog.dateStart, "DD MMM hh:mm:a")}</TextField>
                                </td>
                            </tr>
                            <tr>
                                <td className="min-width">
                                    <TextField small light>
                                        {intl.formatMessage(messages.activeTime)}
                                    </TextField>
                                </td>
                                <td>
                                    <TextField>{getDateFromNow(registerLog.dateStart)}</TextField>
                                </td>
                            </tr>
                            <tr>
                                <td className="min-width">
                                    <TextField small light>
                                        {intl.formatMessage(messages.responsable)}
                                    </TextField>
                                </td>
                                <td>
                                    <Flex alignCenter gap10>
                                        {userOwner && <FlexImageLetter width={25} image={userOwner.image} text={userOwner.firstName} />}
                                        <TextField>{getModelFullName(userOwner)}</TextField>
                                    </Flex>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )}

                {state.showFinalize && !isLoading && (
                    <CashRegisterFinalizeContainer>
                        <Flex column gap={20}>
                            <Flex gap15>
                                <Flex w100 gap5 bgLight column padding={10} borderRadius={15}>
                                    <span className="text-small text-light">Conteo de Efectivo</span>
                                    <TextBoxNumeral
                                        format="money"
                                        maxLength={18}
                                        autoFocus
                                        onNumberChange={(value) => {
                                            setFlowStatus("review");
                                            setState({ ...state, endAmount: value });
                                        }}
                                    />
                                </Flex>
                            </Flex>
                            {logPreviewMutation.data && (
                                <Flex column gap15>
                                    <CashRegisterLogPreview log={logPreviewMutation.data} />
                                    <DefaultButton
                                        className={addDescription ? "active" : ""}
                                        borderRadius={10}
                                        onClick={() => setAddDescription(!addDescription)}
                                    >
                                        <TextField small className={addDescription ? "text-primary" : "text-light"}>
                                            Agregar descripción
                                        </TextField>
                                    </DefaultButton>
                                    {addDescription && (
                                        <DefaultTextarea
                                            placeholder="Opcional......"
                                            rows={1}
                                            onChange={(e) => setState({ ...state, description: e.target.value })}
                                            value={state.description}
                                        />
                                    )}
                                    <Flex className="mt-1" column>
                                        <TextField>Después de finalizar</TextField>
                                        <TextField small light>
                                            Elige que quieres hacer con el dinero de {cashRegister?.name} cuando finalices
                                        </TextField>
                                    </Flex>
                                    <GroupButtonRow
                                        activeOption={logFinalization.action}
                                        onClickOption={(option) =>
                                            setLogFinalization({ ...logFinalization, action: option.value as LogFinalAction })
                                        }
                                        options={[
                                            { title: "Trasladar dinero", value: "moveMoney" },
                                            {
                                                title: "Dejar caja en $0",
                                                value: "clearCashRegister",
                                            },
                                            {
                                                title: "No hacer nada",
                                                value: "nothing",
                                            },
                                        ]}
                                    />

                                    {logFinalization.action === "clearCashRegister" && (
                                        <TextField small light>
                                            Se realizara un ajuste de caja, se establecerá el saldo en $0. No se perderá ningún movimiento
                                            de {cashRegister?.name}{" "}
                                        </TextField>
                                    )}
                                    {cashRegister && logFinalization.action === "moveMoney" && (
                                        <CashRegisterSelect
                                            title="Selecciona la caja destino"
                                            ignoreList={[cashRegister]}
                                            defaultCashRegisterId={logFinalization.cashRegisterDestiny?.id}
                                            onCashRegisterChange={(value) =>
                                                setLogFinalization({ ...logFinalization, cashRegisterDestiny: value })
                                            }
                                        />
                                    )}
                                    {logFinalization.action === "moveMoney" && logFinalization.cashRegisterDestiny && (
                                        <TextField small light>
                                            Se moverán {formatMoney(cashRegister?.amount)} a {logFinalization.cashRegisterDestiny.name}
                                        </TextField>
                                    )}
                                </Flex>
                            )}
                            {logPreviewMutation.isLoading && <LoadingDualRing center />}
                            <Flex gap10 flexWrap justifyEnd>
                                <DefaultButton bgLight borderRadius={10} onClick={() => props.setShowModal(false)}>
                                    {intl.formatMessage(messages.cancel)}
                                </DefaultButton>
                                <PrimaryButton borderRadius={10} onClick={() => handleLoadLogPreview()}>
                                    <i className="fa-regular fa-calculator"></i>
                                    {logPreviewMutation.data ? "Volver a Revisar" : "Revisar"}
                                </PrimaryButton>
                                {flowStatus === "finalization" && (
                                    <PrimaryButton borderRadius={10} onClick={() => setShowConfirmationModal(true)}>
                                        <span className="wahioicon-arrow-right"></span> Finalizar
                                    </PrimaryButton>
                                )}
                            </Flex>
                        </Flex>
                    </CashRegisterFinalizeContainer>
                )}
                {!state.showFinalize && !isLoading && (
                    <Flex gap10>
                        {!props.hideCloseBtn && (
                            <DefaultButton bgLight borderRadius={10} onClick={() => props.setShowModal(false)}>
                                Cerrar
                            </DefaultButton>
                        )}
                        <PrimaryButton
                            borderRadius={10}
                            onClick={() => {
                                if (props.blockScreen) props.blockScreen();
                                setState({ ...state, showFinalize: true });
                            }}
                        >
                            {intl.formatMessage(messages.finishCashRegister)}
                        </PrimaryButton>
                    </Flex>
                )}
                {isLoading && <LoadingDualRing center={true} />}
            </CashRegisterLogView>
        </>
    );
}
