import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { TransactionAccountConcept } from "../../../../api/models/transactionAccount";
import { useTransactionAccountContext } from "../../../../store/contexts/TransactionAccountContext";
import { SelectOptionObject } from "../../../../api/models";
import { getAuthBody } from "../../../../auth";
import { SelectBody } from "../../../Select/styled";

interface ResolutionSelectorProps {
    defaultValue?: TransactionAccountConcept;
    onChange: (value?: TransactionAccountConcept) => void;
    title: string;
    disabled?: boolean;
    w100?: boolean;
    hideTitle?: boolean;
    allowRemove?: boolean;
}

export default function TransactionAccountConceptSelector(props: ResolutionSelectorProps) {
    const [selectedValue, setSelectedValue] = useState<TransactionAccountConcept | undefined>(props.defaultValue);

    const { concepts, fetchItemsIfNotExits, createUpdateConcept, isFetching } = useTransactionAccountContext();

    useEffect(() => {
        fetchItemsIfNotExits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedValue(props.defaultValue);
    }, [props.defaultValue]);

    const getOption = (item: TransactionAccountConcept) => {
        let option: SelectOptionObject<TransactionAccountConcept> = {
            value: item.id,
            label: item.name,
            object: item,
        };
        return option;
    };

    const onValueChange = (value?: TransactionAccountConcept) => {
        setSelectedValue(value);
        props.onChange(value);
    };

    const handleCreateNew = (name: string) => {
        let data: TransactionAccountConcept = {
            id: "",
            name: name,
            createdAt: new Date(),
            accountId: getAuthBody().accountId,
        };

        createUpdateConcept(data, (result) => {
            onValueChange(result);
        });
    };

    return (
        <SelectBody className={props.w100 ? "w100" : ""}>
            {!props.hideTitle && props.title && <span className="label">{props.title}</span>}
            <CreatableSelect
                placeholder="Concepto"
                className={`react-select-basic ${selectedValue ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedValue?.id}
                value={selectedValue ? getOption(selectedValue) : undefined}
                isDisabled={props.disabled}
                onChange={(value) => {
                    onValueChange(value?.object);
                }}
                isRtl={false}
                isLoading={isFetching}
                onCreateOption={handleCreateNew}
                isSearchable={true}
                isClearable={props.allowRemove}
                name="concepts"
                options={concepts.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
