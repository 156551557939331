import styled from "styled-components";

export const ImageContainer = styled.div`
    position: relative;
    border: 2px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    input {
        display: none;
    }
    .image-label {
        object-fit: cover;
        img {
            height: 100%;
            width: 100%;
            cursor: pointer;
            object-fit: cover;
        }
    }
    .image-options {
        display: flex;
        position: absolute;
        bottom: -25px;
        width: 100%;
        opacity: 0;
        transition: all 0.3s;
        .option {
            width: 100%;
            text-align: center;
            padding: 5px;
        }
    }
    &:hover .image-options {
        bottom: 0px;
        opacity: 1;
        transition: all 0.3s;
    }
    .image-options .option button {
        padding: 0.3rem 7px !important;
        border-radius: 20px;
        box-shadow: 1px 1px 11px ${(props) => props.theme.palette.shadow};
        background: ${(props) => props.theme.palette.background};
        border: 0;
        color: ${(props) => props.theme.palette.text};
    }
`;
