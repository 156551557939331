import { ICashRegisterEntry } from "../../../api/models/cashRegister";
import { Order } from "../../../api/models/order";
import { IOrderPayment } from "../../../api/models/orderPayment";
import { getAuthBody } from "../../../auth";

export const getEntryFromOrderPayment = (order: Order, payment: IOrderPayment, isIncome: boolean) => {
    let auth = getAuthBody();

    let body: ICashRegisterEntry = {
        cashRegisterId: payment.cashRegisterId,
        cashRegisterOriginId: payment.cashRegisterId,
        amount: payment.amount,
        type: "sale",
        method: payment.method,
        description: `Pago orden #${order.number}`,
        contactId: order.customerId ?? "",
        isIncome,
        accountUserId: auth.accountUserId,
        files: payment.files,
        number: 0,
        referenceNumber: order.number.toString(),
        referenceId: order.id ?? "",
        referencePaymentId: payment.id ?? "",
        comment: "",
    };
    return body;
};
