import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { getServiceMessageError, wahioFetch } from "../../../../../api";
import { SelectBaseOption } from "../../../../../api/models";
import { Contact, IContactAddress } from "../../../../../api/models/contact";
import { DeliveryOrder, IDeliveryOrder } from "../../../../../api/models/deliveryOrder";
import { localOrderEndpoint } from "../../../../../api/restApiEndpoint";
import { getAuthBody } from "../../../../../auth";
import { ILocationStateValue, colombiaStatesAndCities } from "../../../../../constants/statesWithCities";
import { OrganizationContext } from "../../../../../store/contexts/OrganizationContext";
import { getModelFullName } from "../../../../../utils/index";
import { getContactAddressText } from "../../../../../utils/orders";
import { useAlert } from "../../../../Alerts/Alert";
import ContactAddressForm from "../../../../Contacts/ContactForm/ContactAddressForm";
import DropdownList from "../../../../Dropdown/DropdownList";
import LoadingDualRing from "../../../../LoadingDualRing";
import { SelectBody } from "../../../../Select/styled";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, SquareButton } from "../../../../_controls";
import Modal, { IModalShow } from "../../../../modals/Modal";
import { OrderDetailsProps } from ".";

interface OriginAddressModalProps extends OrderDetailsProps, IModalShow {}

export default function OrderDeliveryModal(props: OriginAddressModalProps) {
    const { organizationState } = useContext(OrganizationContext);
    const { order } = props;
    const alert = useAlert();
    const [deliveryOrder, setDeliveryOrder] = useState(
        order.deliveryOrder ? order.deliveryOrder : new DeliveryOrder(getAuthBody().accountId, "", organizationState.currentOrganization)
    );
    const [editOrigin, setEditOrigin] = useState(false);

    const [createNewContactAddress, setCreateNewContactAddress] = useState(false);
    const [newAddressLoading, setNewAddressLoading] = useState(false);

    const [initialContactAddress, setInitialContactAddress] = useState({
        ...(order.deliveryOrder as any),
        description: order.deliveryOrder?.address,
    });
    const [contactAddress, setContactAddress] = useState<IContactAddress>(initialContactAddress);

    const onContactAddressChange = (address: IContactAddress) => {
        const isEqual = JSON.stringify(initialContactAddress) === JSON.stringify(address);
        setCreateNewContactAddress(!isEqual);
        setContactAddress(address);
    };

    useEffect(() => {
        if (contactAddress && contactAddress.contactName !== props.order.deliveryOrder?.contactName) {
            setContactAddress({ ...contactAddress, contactName: getModelFullName(order.customer) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateNewContactAddress = () => {
        if (order.customer) {
            let customer: Contact = { ...order.customer };
            let newAddress = { ...contactAddress, id: undefined };
            customer.addresses = [...(customer.addresses ?? []), newAddress];
            setNewAddressLoading(true);
            wahioFetch.put(
                localOrderEndpoint.put.customerAll,
                customer,
                (success) => {
                    props.onOrderChange({ ...props.order, customer: success.data });
                    setInitialContactAddress(contactAddress);
                    setCreateNewContactAddress(false);
                    alert.success(`La dirección fue creada correctamente para ${customer.firstName} ${customer.lastName}`);
                    setNewAddressLoading(false);
                },
                (error) => {
                    alert.error(getServiceMessageError(error));
                    setNewAddressLoading(false);
                }
            );
        }
    };

    const onSaveChanges = () => {
        const contactAddressCopy = { ...contactAddress };
        const deliveryOrderResult: IDeliveryOrder = {
            ...deliveryOrder,
            phoneNumber: contactAddressCopy.phoneNumber,
            contactName: contactAddressCopy.contactName,
            courier: deliveryOrder.courier,
            originState: deliveryOrder.originState,
            originCity: deliveryOrder.originCity,
            id: deliveryOrder.id,
            state: contactAddressCopy.state,
            city: contactAddressCopy.city,
            stateCode: contactAddressCopy.stateCode,
            countryCode: contactAddressCopy.countryCode,
            country: contactAddressCopy.country,
            additionalInformation: contactAddressCopy.additionalInformation,
            address: contactAddressCopy.description,
            description: contactAddressCopy.description,
            neighborhood: contactAddressCopy.neighborhood,
        };
        props.onOrderChange({ ...props.order, deliveryOrder: deliveryOrderResult });
        props.setShow(false);
    };

    return (
        <Modal
            id="modal-address-form"
            sizeType="md"
            title={"Origen y Destino"}
            useButtonClose
            ignoreOutsideClick
            showHeader={false}
            {...props}
        >
            <Flex column gap={20} padding={30}>
                <Flex gap10 column>
                    <Flex gap10 className="mb-1" alignCenter flexWrap>
                        <h4 className="m-0 text-bold">Dirección de Cliente</h4>
                        {order.customer?.addresses && order.customer?.addresses.length > 0 && (
                            <div>
                                <DropdownList
                                    small
                                    title="Elegir Dirección"
                                    onClickItem={(item) => {
                                        if (item.object) {
                                            setInitialContactAddress(item.object);
                                            setContactAddress(item.object);
                                        }
                                    }}
                                    onRemoveItem={() => {}}
                                    items={order.customer.addresses.map((address) => {
                                        return { id: address.id ?? "", title: getContactAddressText(address), object: address };
                                    })}
                                />
                            </div>
                        )}
                        {createNewContactAddress && !newAddressLoading && (
                            <PrimaryButton onClick={handleCreateNewContactAddress} rounded small>
                                Guardar nueva dirección
                            </PrimaryButton>
                        )}
                        {newAddressLoading && <LoadingDualRing small />}
                    </Flex>
                    <ContactAddressForm item={contactAddress} onChange={onContactAddressChange} />
                </Flex>

                <Flex column gap10 flexWrap className="mt-1">
                    <Flex gap10 alignCenter>
                        <h4 className="m-0 text-bold">Origen</h4>
                        <span className="text-light">
                            {deliveryOrder.originState}, {deliveryOrder.originCity}, {deliveryOrder.originAddress}
                        </span>
                        <SquareButton small onClick={() => setEditOrigin(!editOrigin)}>
                            <span className="wahioicon-pen"></span>
                        </SquareButton>
                    </Flex>
                    <Flex style={{ display: editOrigin ? "flex" : "none" }} column border borderRadius={15} padding={20} maxWidth={400}>
                        <OriginAddressForm deliveryOrder={deliveryOrder} onDeliveryOrderChange={setDeliveryOrder} />
                    </Flex>
                </Flex>
                <Flex gap10 justifyEnd>
                    <DefaultButton borderRadius={10} className="mr-1" onClick={() => props.setShow(false)}>
                        Cancelar
                    </DefaultButton>

                    <PrimaryButton borderRadius={10} onClick={onSaveChanges}>
                        Guardar Cambios
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
}

interface OriginAddressProps {
    deliveryOrder: IDeliveryOrder;
    onDeliveryOrderChange: (value: IDeliveryOrder) => void;
}

const OriginAddressForm = (props: OriginAddressProps) => {
    const { deliveryOrder, onDeliveryOrderChange } = props;

    const onAddressChange = (e: any) => {
        onDeliveryOrderChange({ ...deliveryOrder, [e.target.name]: e.target.value });
    };

    const stateChange = (locationValue?: ILocationStateValue) => {
        let originState = locationValue ? locationValue.state : "";
        let originCity = locationValue && locationValue.cities && locationValue.cities.length > 0 ? locationValue.cities[0] : "";

        onDeliveryOrderChange({ ...deliveryOrder, originState, originCity });
    };

    const getSelectOption = (item: string) => {
        let option: SelectBaseOption = {
            id: item,
            value: item,
            label: item,
        };
        return option;
    };

    return (
        <Flex column gap15>
            <Flex gap15>
                <SelectBody className="w100">
                    <span className="label">Departamento</span>
                    <Select
                        placeholder="Departamento"
                        key={deliveryOrder.originState}
                        classNamePrefix="select"
                        defaultValue={deliveryOrder.originState ? getSelectOption(deliveryOrder.originState) : undefined}
                        isDisabled={false}
                        onChange={(value) => stateChange(colombiaStatesAndCities.find((x) => x.state === value?.id))}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="seller"
                        options={colombiaStatesAndCities.map((item) => getSelectOption(item.state))}
                    />
                </SelectBody>

                <SelectBody className="w100">
                    <span className="label">Ciudad</span>
                    <Select
                        placeholder="Ciudad"
                        key={deliveryOrder.originCity}
                        classNamePrefix="select"
                        defaultValue={deliveryOrder.originCity ? getSelectOption(deliveryOrder.originCity) : undefined}
                        isDisabled={false}
                        onChange={(value) => onAddressChange({ target: { name: "originCity", value: value?.id } })}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="seller"
                        options={
                            !deliveryOrder.originState
                                ? []
                                : colombiaStatesAndCities
                                      .find((x) => x.state.toUpperCase() === deliveryOrder.originState?.toUpperCase())
                                      ?.cities.map((item) => getSelectOption(item))
                        }
                    />
                </SelectBody>
            </Flex>
            <Flex column gap5>
                <label htmlFor="originAddress" className="text-light text-small">
                    Dirección
                </label>
                <DefaultInput
                    rounded
                    name="originAddress"
                    value={deliveryOrder.originAddress}
                    onChange={onAddressChange}
                    placeholder="Dirección"
                />
            </Flex>
        </Flex>
    );
};
