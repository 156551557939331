import React from "react";
import { DefaultButton } from "../_controls";
import { BackActionCustom, BackActionLineStyle } from "./styled";
import { getParamBackLinkObj } from "../FilterParams/helper";
import { NavLink } from "react-router-dom";

export interface BackActionProps {
    onClick?: () => void;
    title?: string;
    icon?: string;
    className?: string;
}

export const BackAction = (props: BackActionProps) => {
    const { onClick, title, icon } = props;
    return (
        <BackActionCustom className={props.className ?? ""} onClick={onClick ? onClick : () => window.history.back()}>
            <span className={`${icon ? icon : "fa-regular fa-arrow-left"} text-small`}></span>
            <span>{title ? title : "Volver"}</span>
        </BackActionCustom>
    );
};

interface BackActionLineProps {
    title?: string;
    backLinkParam?: string;
}

export const BackActionLine = (props: BackActionLineProps) => {
    let title = props.title;

    const backLink = React.useMemo(() => {
        if (props.backLinkParam) {
            return getParamBackLinkObj(props.backLinkParam);
        }
        return undefined;
    }, [props.backLinkParam]);

    if (backLink) {
        title = backLink.name;
    }

    if (backLink && backLink.path) {
        return (
            <BackActionLineStyle>
                <NavLink to={backLink.path}>
                    <DefaultButton>
                        <span className={"icon fa-regular fa-arrow-left"}></span>
                        <span>{title ? title : "Volver"}</span>
                    </DefaultButton>
                </NavLink>
            </BackActionLineStyle>
        );
    }

    return (
        <BackActionLineStyle>
            <DefaultButton onClick={() => window.history.back()}>
                <span className={"icon fa-regular fa-arrow-left"}></span>
                <span>{title ? title : "Volver"}</span>
            </DefaultButton>
        </BackActionLineStyle>
    );
};
