import React from "react";
import { ButtonColumn } from ".";
//import { ProductSortOption } from "../../../store/types/product";

export interface ISortOptions {
    sort: string;
    sortAsc: boolean;
}

interface ButtonSortColumnProps {
    title?: string;
    sort: string;
    selectedSortOption: ISortOptions;
    onClick: () => void;
}

export default function ButtonSortColumn(props: ButtonSortColumnProps) {
    const isActive = props.selectedSortOption.sort === props.sort;

    return (
        <ButtonColumn onClick={props.onClick} className={isActive ? "active" : ""}>
            {props.title && <span className="mr-1">{props.title}</span>}
            {!isActive ? (
                <span className="wahioicon-sort"></span>
            ) : (
                <span className={props.selectedSortOption.sortAsc ? "wahioicon-long-arrow-alt-up" : "wahioicon-long-arrow-alt-down"}></span>
            )}
        </ButtonColumn>
    );
}
