import React, { useEffect, useState } from "react";
import { WahioFile, WahioFileImpl } from "../../../api/models";
import { WahioCloudItem } from "../../../api/models/wahioCloud";
import { getDateFromNow } from "../../../utils";
import Dropdown from "../../Dropdown";
import WahioCloudItemsModal from "../../WahioCloud/WahioCloudItemsModal";
import WahioFileCard from "../../WahioCloud/WahioFileCard";
import { DefaultButton, Flex, SquareButton, TextField } from "../../_controls";
import Modal from "../../modals/Modal";
import { FilesViewContentContainer } from "./styled";

interface FilesViewerProps extends FilesViewContentProps {
    onUpdateFiles: (files: WahioFile[]) => void;
    cardMaxHeight?: number;
    cardMaxWidth?: number;
    smallCard?: boolean;
    showAttachButton?: boolean;
    customComponent?: React.ReactNode;
    smallButton?: boolean;
    showAccessType?: boolean;
    containerClassName?: string;
}

export default function FilesViewer(props: FilesViewerProps) {
    const { files } = props;
    const [imageFiles, setImageFiles] = useState(() => props.files.filter((x) => x.type === "image"));

    const [showWahioCloud, setShowWahioCloud] = useState(false);

    useEffect(() => {
        setImageFiles(props.files.filter((x) => x.type === "image"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const onItemSelected = (items: WahioCloudItem[]) => {
        let files: WahioFile[] = [];
        items.forEach((item) => {
            let file = new WahioFileImpl(item.url, item.type, item.thumbnailUrl);
            file.name = item.name;
            files.push(file);
        });
        let existing = props.files ?? [];
        props.onUpdateFiles([...existing, ...files]);
    };

    const onChangeIndex = (item: WahioFile) => {
        const index = imageFiles.findIndex((x) => x.id === item.id);
        setSelectedIndex(index);
    };

    const onRemove = (index: number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        props.onUpdateFiles(newFiles);
    };

    const onUpdate = (file: WahioFile, index: number) => {
        const newFiles = [...files];
        newFiles[index] = file;
        props.onUpdateFiles(newFiles);
    };

    const onChangeAccessTypeAllFiles = (value: boolean) => {
        let newFiles = [...files];
        newFiles.forEach((x) => (x.accessType = value === true ? "public" : "private"));
        props.onUpdateFiles(newFiles);
    };

    return (
        <>
            {selectedIndex >= 0 && (
                <Modal sizeType="md" show={selectedIndex >= 0} useButtonClose setShow={() => setSelectedIndex(-1)}>
                    <FilesViewContent files={imageFiles} initialIndex={selectedIndex} />
                </Modal>
            )}
            {showWahioCloud && (
                <WahioCloudItemsModal
                    show={showWahioCloud}
                    setShow={setShowWahioCloud}
                    onClickItems={onItemSelected}
                    allowSelectionMultiple
                    allowSelection
                />
            )}

            <Flex column gap={10} className={props.containerClassName ?? ""}>
                {(props.showAttachButton || props.customComponent || props.showAccessType) && (
                    <Flex gap10 alignCenter flexWrap>
                        {props.showAttachButton && (
                            <DefaultButton small={props.smallButton} onClick={() => setShowWahioCloud(true)} borderRadius={10} bgLight>
                                <span className="fa-sharp fa-regular fa-paperclip" />
                                Adjuntar
                            </DefaultButton>
                        )}
                        {props.showAccessType && files.length > 1 && (
                            <Dropdown icon="fa-regular fa-ellipsis-vertical">
                                <Flex gap={10} column padding={20}>
                                    <DefaultButton className="dropdown-button" onClick={() => onChangeAccessTypeAllFiles(true)}>
                                        <i className="fa-regular fa-lock-open"></i> Marcar todo como publico
                                    </DefaultButton>
                                    <DefaultButton className="dropdown-button" onClick={() => onChangeAccessTypeAllFiles(false)}>
                                        <i className="fa-regular fa-lock"></i> Marcar todo como privado
                                    </DefaultButton>
                                </Flex>
                            </Dropdown>
                        )}
                        {props.customComponent}
                    </Flex>
                )}
                {files && files.length > 0 && (
                    <Flex flexWrap gap10>
                        {files.map((item, index) => (
                            <Flex key={index} maxHeight={props.cardMaxHeight} maxWidth={props.cardMaxWidth ?? 100}>
                                <WahioFileCard
                                    onClick={() => onChangeIndex(item)}
                                    smallItem={props.smallCard}
                                    onRemove={() => onRemove(index)}
                                    onUpdate={(item) => onUpdate(item, index)}
                                    item={item}
                                    key={index}
                                    showAccessType={props.showAccessType}
                                />
                            </Flex>
                        ))}
                    </Flex>
                )}
            </Flex>
        </>
    );
}

interface FilesViewContentProps {
    files: WahioFile[];
}

interface FilesViewContentBaseProps extends FilesViewContentProps {
    initialIndex: number;
}

export const FilesViewContent = (props: FilesViewContentBaseProps) => {
    const { files } = props;
    const [activeIndex, setActiveIndex] = useState(props.initialIndex);
    useEffect(() => {}, [props.files]);

    if (files.length === 0) {
        return <div>No hay archivos</div>;
    }

    const onChangeIndex = (back?: boolean) => {
        if (back) {
            if (activeIndex > 0) {
                setActiveIndex(activeIndex - 1);
            } else {
                setActiveIndex(files.length - 1);
            }
        } else {
            if (activeIndex < files.length - 1) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(0);
            }
        }
    };

    const file = files[activeIndex];

    return (
        <FilesViewContentContainer>
            <Flex alignCenter justifyCenter spaceBetween gap10 w100>
                <SquareButton disabled={files.length <= 1} onClick={() => onChangeIndex(true)}>
                    <span className="wahioicon-arrow-left"></span>
                </SquareButton>

                <div className="file-container">
                    {file.type === "image" && (
                        <div className="file-image">
                            <img src={file.url} alt={file.name} />
                        </div>
                    )}
                </div>

                <SquareButton disabled={files.length <= 1} onClick={() => onChangeIndex()}>
                    <span className="wahioicon-arrow-right"></span>
                </SquareButton>
            </Flex>
            <TextField>{file.name}</TextField>
            {file.dateCreated && (
                <TextField small light>
                    {getDateFromNow(file.dateCreated)}
                </TextField>
            )}
        </FilesViewContentContainer>
    );
};
