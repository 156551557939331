import wahioFetchApi from "../fetchApi";
import { ITax, ProductByTax, ProductTaxBulkInput, UpdateProductTaxInput } from "../models/tax";
import { productEndpoint } from "../restApiEndpoint";

export const deleteTax = (taxId: string): Promise<ITax> => {
    return wahioFetchApi.delete(productEndpoint.delete.taxById(taxId)).then((res: any) => res.data);
};

export const getTaxesByAccount = (accountId: string): Promise<ITax[]> => {
    return wahioFetchApi.get(productEndpoint.get.taxesByAccount(accountId)).then((res: any) => res.data);
};

export const getProductsByTax = (taxId: string): Promise<ProductByTax[]> => {
    return wahioFetchApi.get(productEndpoint.get.productsByTax(taxId)).then((res: any) => res.data)
};

export const productTaxBulk = (data: ProductTaxBulkInput): Promise<ProductByTax[]> => {
    return wahioFetchApi.post(productEndpoint.post.taxProductBulk, data).then((res: any) => res.data)
};

export const productTaxDelete = (accountId: string, productTaxId: string): Promise<ProductByTax> => {
    return wahioFetchApi.delete(productEndpoint.delete.productTax(accountId, productTaxId)).then((res: any) => res.data)
}

export const updateProductTax = (data:UpdateProductTaxInput): Promise<ProductByTax> => {
    return wahioFetchApi.put(productEndpoint.put.updateProductTax, data).then((res: any) => res.data)
}
