import customMoment from "../../utils/momentFormat/dateFormat";

export const getNextHalfHour = () => {
    const now = customMoment();
    const minutes = now.minutes();
    let nextHalfHour;

    if (minutes < 30) {
        nextHalfHour = now.minutes(30).seconds(0);
    } else {
        nextHalfHour = now.add(1, "hours").minutes(0).seconds(0);
    }

    return nextHalfHour;
};
