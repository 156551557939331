import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderNavigationProps } from ".";
import globalMessages from "../../../messages";
import { formatMoney, getDateFormatLLLL } from "../../../utils";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { TableCustomTr } from "../../_controls/tables/styled";
import messages from "./messages";
import { TableCustom } from "./styled";

export default function NavigationRefunds(props: OrderNavigationProps) {
    const { order } = props;
    const intl = useIntl();

    if (!order.refunds || order.refunds.length === 0) return null;

    return (
        <NavigationCard title="Reembolso">
            <TableCustom cellSpacing="0" cellPadding="0">
                <tbody>
                    {order.refunds &&
                        order.refunds.map((item, index) => (
                            <TableCustomTr key={index}>
                                <td>
                                    <span>
                                        <strong>{formatMoney(item.amount)}</strong>
                                    </span>{" "}
                                    {item.method && (
                                        <span className="light-color">
                                            <FormattedMessage {...messages.in} />{" "}
                                            {(globalMessages as any)[item.method]
                                                ? intl.formatMessage((globalMessages as any)[item.method])
                                                : item.method}
                                        </span>
                                    )}
                                    <br />
                                    <span className="light"> {getDateFormatLLLL(item.dateCreated)}</span>
                                </td>
                            </TableCustomTr>
                        ))}
                </tbody>
            </TableCustom>
        </NavigationCard>
    );
}
