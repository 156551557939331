import styled from "styled-components";
import { DefaultButton, Flex } from "../../_controls";
import { TableCustomTr } from "../../_controls/tables/styled";

export const CardContainer = styled.div`
    width: 100%;
`;

export const CashRegisterLogContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CashRegisterLogHistoryContainer = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: 1fr;
`;

export const SelectCashRegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;

    .header-title {
        margin: 0px;
    }
`;

export const CashRegisterLogCardView = styled(Flex)`
    cursor: pointer;
    :hover {
        border: 1px solid transparent;
        box-shadow: 1px 1px 11px ${(props) => props.theme.palette.shadowColor};
    }
`;

export const CashRegisterLogHistoryCard = styled.div`
    padding: 30px 25px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .dates {
        display: flex;
        color: ${(props) => props.theme.palette.textLight};

        font-size: 0.8rem;
        font-weight: 600;
        align-items: center;
        margin-top: 7px;
        padding: 6px 15px;
        background: ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
    }

    .table-title {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .title {
        font-size: 1.3rem;
        font-weight: 500;
    }
    .table-container {
        display: flex;
        margin-bottom: 15px;
    }

    .results {
        display: flex;
        margin-top: 15px;
        .value {
            padding: 12px 15px;
            background: ${(props) => props.theme.palette.backgroundDark};
            margin-right: 10px;
            border-radius: 8px;
        }
    }
`;

export const CashRegisterLogView = styled.div`
    margin-top: 0px;
    padding: 30px 25px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const MoneyLabelColor = styled.div`
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    &.positive {
        color: #304ffe;
    }
    &.negative {
        color: ${(props) => props.theme.palette.dangerColor};
    }
`;

export const CashRegisterFinalizeContainer = styled.div`
    max-height: calc(100vh - 190px);
    overflow: auto;
    .textbox-numeral {
        font-size: 18px;
        font-weight: 500;
        border-radius: 50px;
        padding: 6px 5px;
        width: 100%;

        border-radius: 50px;
        border: 0px;
        border: 1px solid transparent;
        background: ${(props) => props.theme.palette.backgroundLight};

        &:focus {
            border: 1px solid ${(props) => props.theme.palette.primary};
        }
    }

    .label-center {
        display: block;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 20px;
    }
`;

export const LogNavigationTitle = styled.h2`
    margin: 0px;
    font-weight: 500;
`;

export const CashRegisterLogProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
        margin: 0px;
    }
    .money-card {
        display: flex;
        flex-direction: column;
        padding: 12px 18px;
        background: ${(props) => props.theme.palette.backgroundLight};
        border-radius: 10px;
        height: 70px;
        justify-content: space-between;
        box-sizing: border-box;

        &.positive {
            border-left: 0px solid ${(props) => props.theme.palette.primary};
            color: ${(props) => props.theme.palette.primary};
        }
        &.negative {
            border-left: 0px solid #f44336;
            color: #f44336;
        }

        .title {
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            .icon {
                margin-right: 5px;
            }
        }
        .sub-title {
            font-size: 12px;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const TableRowLog = styled(TableCustomTr)`
    cursor: pointer;
`;

export const CreateRegisterLogView = styled.div`
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header-title {
        margin: 0px;
    }

    .cash-register-card {
        background: ${(props) => props.theme.palette.backgroundLight};
        padding: 15px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
`;

export const TextNumeralContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label {
        margin-bottom: 4px;
    }

    .textbox-numeral {
        text-align: right;
        border-radius: 50px;
    }
`;

export const CustomTableTr = styled(TableCustomTr)`
    padding: 10px 17px;
    border-bottom: 0px;

    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }

    .btn-light {
        padding: 3px 12px;
        border-radius: 40px;
        border: 0px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    th,
    td {
        border-bottom: 0px solid blue;
        text-align: left;
        line-height: 1.2;
        padding: 0px 4px;
        padding-left: 0px;
        padding-right: 10px;
        font-weight: 400;
        a {
            display: block;
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const CloseCashRegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    h2 {
        margin: 0px;
        margin-bottom: 15px;
    }
    span {
        font-size: 15px;
        font-weight: 500;
    }
    .btn {
        max-width: 200px;
    }
`;

export const LogActiveIcon = styled.div`
    color: red;
    &.active {
        color: #4caf50;
    }
`;

export const LogActiveButton = styled(DefaultButton)`
    background: transparent;
    justify-content: flex-start;
    border-color: transparent;
    color: ${(props) => props.theme.palette.text};

    :hover {
        background: ${(props) => props.theme.palette.menuHoverColor};
    }

    &.square {
    }

    .cash-icon {
        color: red;
    }

    &.active {
        color: ${(props) => props.theme.palette.text};
        .cash-icon {
            color: #4caf50;
        }
    }
`;
