import React from "react";
import { DeliveryStatus, IDeliveryOrder } from "../../../api/models/deliveryOrder";
import { Order } from "../../../api/models/order";
import { useShipmentStatusList } from "./hook";
import { ShipmentProgressCard, ShipmentStepContainer } from "./styled";

interface ShipmentProgressProps {
    deliveryOrder: IDeliveryOrder;
    order: Order;
    smallView?: boolean;
    onMoveStatus: (status: DeliveryStatus) => void;
}

export default function ShipmentProgress(props: ShipmentProgressProps) {
    const { deliveryOrder } = props;

    const { getClosestVisibleStatus, getHistoryStatusList } = useShipmentStatusList();

    const currentStatusIndex = React.useMemo(() => {
        return getClosestVisibleStatus(deliveryOrder.status, props.order.allowLogisticsService);
    }, [deliveryOrder.status, getClosestVisibleStatus, props.order.allowLogisticsService]);

    const shipmentItems = React.useMemo(() => {
        return getHistoryStatusList(props.order.allowLogisticsService, deliveryOrder.history);
    }, [deliveryOrder.history, getHistoryStatusList, props.order.allowLogisticsService]);

    return <ShipmentStepIcon subTitleFixed items={shipmentItems} indexActive={currentStatusIndex} onMoveStatus={props.onMoveStatus} />;
}

export interface ShipmentStepItem {
    icon: string;
    title: string;
    subTitle?: string;
    smallTextLine?: string;
    textHelper?: string;
    status: DeliveryStatus;
    disabled?: boolean;
}

interface ShipmentStepIconProps {
    items: ShipmentStepItem[];
    indexActive: number;
    subTitleFixed: boolean;
    onMoveStatus: (status: DeliveryStatus) => void;
}

function ShipmentStepIcon(props: ShipmentStepIconProps) {
    return (
        <ShipmentProgressCard>
            {props.items.map((item, index) => {
                const statusActive = index <= props.indexActive;
                if (item.disabled) return null;
                return (
                    <ShipmentStepContainer key={index} className={` ${statusActive ? "active" : ""}`}>
                        <div className={`step-body`}>
                            <div
                                title={item.textHelper}
                                className={`status-${item.status} step-circle`}
                                onClick={() => {
                                    if (!statusActive) props.onMoveStatus(item.status);
                                }}
                            >
                                {<span className={item.icon}></span>}
                            </div>

                            <span className="step-title">{item.title}</span>
                            <span className={`step-date ${props.subTitleFixed ? "step-date-fixed" : ""}`}>{item.subTitle}</span>
                        </div>
                        {item.smallTextLine && <div className="time">{item.smallTextLine}</div>}
                        {index < props.items.length - 1 && (
                            <div className={`step-line ${index < props.indexActive ? "line-active" : ""}`}></div>
                        )}
                    </ShipmentStepContainer>
                );
            })}
        </ShipmentProgressCard>
    );
}
