import _, { capitalize } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { useTheme } from "styled-components";
import SwiperCore, { A11y, Autoplay, Controller, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuid } from "uuid";
import { useEventListenerWindow } from "../..";
import { GroupItem, GroupItemType } from "../../../api/models/_statistics";
import { DeliveryStatus } from "../../../api/models/deliveryOrder";
import { OrderSearchInput } from "../../../api/models/order";
import { OrderStatus, orderStatusOpenList } from "../../../api/models/orderBase";
import { getOrdersGroup } from "../../../api/orders/orderApi";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { DateFormatCalendar, formatMoney, getComparisonPercentage, getDateFormat } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import customNumeral from "../../../utils/numberFormat/moneyFormat";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex, GroupButtonContainer, TextField } from "../../_controls";
import { useModal } from "../../modals/Modal";
import { ChartContainerGroup, ChartContainerItem, OrderSummaryItemsContainer } from "../styled";
import FilterForm from "./FilterForm";

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y, Controller]);

export interface GenericStatisticsSummaryProps {
    statisticsItems: GroupItem[];
    child?: React.ReactNode;
    hideChartPadding?: boolean;
    isLoading?: boolean;
    defaultGroup?: {
        groupType: GroupItemType;
        onChange: (groupType: GroupItemType) => void;
    };
}

const CONTAINER_ID = "summary_container_id";

export interface OrderGroupSummaryState {
    statusList: OrderStatus[];
    shipmentList: DeliveryStatus[];
}

interface OrderGroupSummaryProps {
    accountIds?: string[];
}

export const OrderGroupSummary = (props: OrderGroupSummaryProps) => {
    const { appSettingsState } = useContext(AppSettingsContext);
    const groupType = appSettingsState.orderListStatisticsGroupType ?? "day";
    const filterModal = useModal();

    const [state, setState] = useState<OrderGroupSummaryState>({
        statusList: orderStatusOpenList,
        shipmentList: [],
    });

    const handleGetOrders = () => {
        let input: OrderSearchInput = {
            accountId: getAuthBody().accountId,
            accountIds: props.accountIds,
            minutesOffset: new Date().getTimezoneOffset(),
            currentPage: 1,
            statusList: state.statusList,
            shipmentStatusList: state.shipmentList,
            pageSize: 10,
        };
        return getOrdersGroup({ ...input, groupType, limit: 7 });
    };

    const groupQuery = useQuery("STATISTICS_ORDER_GROUP", handleGetOrders, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        groupQuery.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupType, state, props.accountIds]);

    if (!groupQuery.data) return null;

    return (
        <>
            <filterModal.Modal useButtonClose>
                <FilterForm
                    state={state}
                    setState={(state) => {
                        setState(state);
                        filterModal.show(false);
                    }}
                />
            </filterModal.Modal>
            <OrderGroupSummaryView
                child={
                    <DefaultButton onClick={() => filterModal.show()}>
                        <span className="wahioicon-filter"></span> Filtros: {state.statusList.length + state.shipmentList.length}
                    </DefaultButton>
                }
                statisticsItems={groupQuery.data}
            />
        </>
    );
};

export function OrderGroupSummaryView(props: GenericStatisticsSummaryProps) {
    const { statisticsItems } = props;
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const groupType = props.defaultGroup?.groupType ?? appSettingsState.orderListStatisticsGroupType ?? "day";

    const [slidesPerView, setSlidesPerView] = useState(4);
    const [gridColumns, setGridColumns] = useState(200);

    const [groupItems, setGroupItems] = useState(statisticsItems ?? []);
    //const [chartItems, setChartItems] = useState([...(statisticsItems ?? [])].reverse());

    const [chartFormatters, setChartFormatters] = useState(() => {
        return {
            tick: groupType === "day" ? "MMM D" : "MMM",
            label: groupType === "day" ? "DD MMM YYYY" : "MMMM",
        };
    });

    const timeOutRef = React.useRef<any>();

    useEffect(() => {
        if (statisticsItems) loadGroupItems(statisticsItems);
        updateSlidesPerView();
        clearTimeout(timeOutRef.current);
        timeOutRef.current = setTimeout(() => {
            updateSlidesPerView();
        }, 400);
    }, [statisticsItems]);

    useEffect(() => {
        clearTimeout(timeOutRef.current);
        timeOutRef.current = setTimeout(() => {
            updateSlidesPerView();
        }, 400);
    }, [appSettingsState.menuSmall]);

    const loadGroupItems = (groupItems: GroupItem[]) => {
        let items: GroupItem[] = [];
        var newGroupItems = [...groupItems].reverse().reduce((items, item) => {
            if (items.length > 0) {
                const preItem = items[items.length - 1];
                item.comparisonValue = preItem.value;
                item.comparisonCount = preItem.count;
            }
            items.push(item);
            return items;
        }, items);
        setGroupItems([...newGroupItems].reverse());
    };

    useEventListenerWindow("resize", () => {
        updateSlidesPerView();
    });

    const updateSlidesPerView = () => {
        const container = document.getElementById(CONTAINER_ID);
        if (container) {
            let width = container.clientWidth;
            if (width > 1000) {
                width = 1000;
            }

            setSlidesPerView(Math.trunc(width / 170));
            let columnWidth = Math.trunc(width / 3);
            const min = 245;
            const max = 450;
            if (columnWidth < min) {
                columnWidth = min;
            } else if (columnWidth > max) {
                columnWidth = max;
            }
            setGridColumns(columnWidth);
        }
    };
    const onChangeGroupType = (type: GroupItemType) => {
        setChartFormatters({
            tick: type === "day" ? "MMM D" : "MMM",
            label: type === "day" ? "DD MMM YYYY" : "MMMM",
        });
        if (props.defaultGroup) {
            props.defaultGroup.onChange(type);
        } else {
            appSettingsActions.setAllSettings({ ...appSettingsState, orderListStatisticsGroupType: type });
        }
    };

    if (!groupItems || groupItems.length === 0) return null;

    return (
        <OrderSummaryItemsContainer id={CONTAINER_ID}>
            <Flex alignCenter gap15 className="mb-1" flexWrap>
                <TextField bold>Resumen Ventas</TextField>
                <GroupButtonContainer className="small-view">
                    {props.child}
                    <DefaultButton onClick={() => onChangeGroupType("day")} className={`${groupType === "day" ? "active" : ""}`}>
                        Agrupar por Dia
                    </DefaultButton>
                    <DefaultButton onClick={() => onChangeGroupType("month")} className={`${groupType === "month" ? "active" : ""}`}>
                        Agrupar por Mes
                    </DefaultButton>
                </GroupButtonContainer>
                {props.isLoading && <LoadingDualRing small />}
            </Flex>
            <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={10}
                slidesPerGroup={1}
                loop={false}
                navigation={true}
                className="mySwiper mt-1"
            >
                <div className="summary-items">
                    {groupItems &&
                        groupItems.map((item, index) => {
                            const comparisonResult = getComparisonPercentage(item.value, item.comparisonValue);
                            const isPositive = comparisonResult && comparisonResult >= 0;
                            return (
                                <SwiperSlide key={index}>
                                    <div className="item-card" key={index}>
                                        <TextField>
                                            {groupType === "month"
                                                ? capitalize(getDateFormat(item.date, "MMMM"))
                                                : DateFormatCalendar({ value: item.date, hideTime: true })}
                                        </TextField>

                                        <div className="item-body">
                                            <span className="item-value">{formatMoney(item.value)}</span>
                                            {comparisonResult && (
                                                <div className={`comparison ${isPositive ? "positive" : "negative"}`}>
                                                    <span
                                                        className={
                                                            isPositive
                                                                ? "fa-regular fa-arrow-up-long icon"
                                                                : "fa-regular fa-arrow-down-long icon"
                                                        }
                                                    ></span>
                                                    <span>{Math.abs(Math.trunc(comparisonResult))}%</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                </div>
            </Swiper>
            <ChartContainerGroup
                style={{
                    gridTemplateColumns: `repeat(auto-fill, minmax(${gridColumns}px, 1fr))`,
                }}
                className="mt-2"
            >
                <ChartLineItem
                    hideContainer={props.hideChartPadding}
                    items={groupItems}
                    linearGradientColor="#007aff"
                    areaName="Valor Ventas/Dia"
                    tooltipName="Ventas"
                    areaDataKey="value"
                    toolTipFormatterValue={(value) => formatMoney(customNumeral(value))}
                    labelFormatter={(value) => getDateFormat(customMoment(value).toDate(), chartFormatters.label)}
                    tickFormatter={(value) => `${getDateFormat(value, chartFormatters.tick)}`}
                    xAxisDataKey="date"
                />
                <ChartLineItem
                    hideContainer={props.hideChartPadding}
                    items={groupItems}
                    linearGradientColor="#7C4DFF"
                    areaName="Cantidad Ventas/Dia"
                    tooltipName="Ventas"
                    toolTipFormatterValue={(value) => customNumeral(value).format("0,0")}
                    labelFormatter={(value) => getDateFormat(customMoment(value).toDate(), chartFormatters.label)}
                    tickFormatter={(value) => `${getDateFormat(value, chartFormatters.tick)}`}
                    areaDataKey="count"
                    xAxisDataKey="date"
                />
            </ChartContainerGroup>
        </OrderSummaryItemsContainer>
    );
}

interface ChartLineItemProps {
    items: GroupItem[];
    linearGradientColor: string;
    areaName: string;
    tooltipName: string;
    toolTipFormatterValue?: (value: string | number | (string | number)[]) => string;
    labelFormatter?: (value: string | number) => string;
    tickFormatter?: (value: any) => string;
    areaDataKey: string;
    xAxisDataKey: string;
    hideContainer?: boolean;
}

export const ChartLineItem = (props: ChartLineItemProps) => {
    const { linearGradientColor } = props;
    const [chartId] = useState(uuid());
    const { palette } = useTheme();

    const items = _.reverse([...props.items]);
    return (
        <ChartContainerItem className={props.hideContainer ? "" : "container"}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={730} height={250} data={items}>
                    <defs>
                        <linearGradient id={chartId} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={linearGradientColor} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={linearGradientColor} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey={props.xAxisDataKey}
                        stroke="#93939326"
                        tick={{ fill: "#838383a6" }}
                        tickFormatter={(value) => (props.tickFormatter ? props.tickFormatter(value) : value)}
                    />

                    <Tooltip
                        contentStyle={{
                            background: palette.background,
                            color: palette.text,
                            borderRadius: 10,
                            borderColor: palette.backgroundDark,
                        }}
                        labelFormatter={(t) => (props.labelFormatter ? props.labelFormatter(t) : t)}
                        formatter={(value, name) => {
                            value = props.toolTipFormatterValue ? props.toolTipFormatterValue(value) : value;
                            name = props.tooltipName;

                            return [value, name];
                        }}
                    />
                    <Legend />
                    <Area
                        type="monotone"
                        dataKey={props.areaDataKey}
                        name={props.areaName}
                        stroke={linearGradientColor}
                        fillOpacity={1}
                        fill={`url(#${chartId})`}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </ChartContainerItem>
    );
};
