import { OrderTypeTemp } from "../api/models/order";
import { WarehouseNavigationTab } from "../components/Warehouses/WarehouseContainerView";

export const TAB_PATH_CASH_REGISTER = "cashregister";
export const TAB_PATH_CASH_REGISTER_LOG = "cashregisterlog";

export const TAB_PATH_STORE_ECOMMERCE = "ecommerce";
export const TAB_PATH_STORE_PROFILE = "profile";
export const TAB_PATH_STORE_USERS = "users";
export const TAB_PATH_STORE_WAREHOUSES = "warehouses";
export const TAB_PATH_STORE_CASHREGISTERS = "cashregister";

export const TAB_PATH_DELIVERY_BOARD = "deliveryboard";
export const TAB_PATH_DELIVERY_LIST = "deliverylist";

export const PATHS = {
    home: "/home",
    products: "/products",
    productList: "/products/list",
    productWarehouses: "/products/warehouses",
    productWarehousesTab: (tab: WarehouseNavigationTab) => `/products/warehouses?tab=${tab}`,
    productRecycleBin: "/products/recyclebin",

    payments: "/payments",
    paymentsAccountingPanel: "/payments/accounting/contacts",
    paymentsAccountingContactProfile: (id?: string) => `/payments/accounting/contacts/profile/${id ? id : ":contactId"}`,
    paymentsTransactionAccount: "/payments/transactionAccount",
    paymentsTransactionAccountProfile: (id?: string) => `/payments/transactionAccount/profile/${id ? id : ":transactionAccountId"}`,
    paymentsAll: "/payments/all",

    cashRegisters: "/payments/cashregisters",
    cashRegistersList: "/payments/cashregisters/list",
    cashRegistersDashboard: "/payments/dashboard",
    cashRegistersProfile: (id?: string) => `/payments/cashregisters/list/profile/${id ? id : ":cashRegisterId"}`,
    cashRegisterLogList: "/payments/cashregisters/logs",
    cashRegisterLogProfile: (logId?: string) => `/payments/cashregisters/logs/profile/${logId ? logId : ":cashRegisterLogId"}`,
    cashRegisterLog: "/payments/cashregisters/cashregisterlog",

    productPurchasesList: "/products/purchases/list",
    productPurchasesNew: "/products/purchases/list/newpurchase",
    productPurchasesSummary: "/products/purchases/summary",
    productPurchasesEditId: (id?: string) => `/products/purchases/edit/${id ? id : ":orderId"}`,
    productPurchasesListProfileId: (id?: string) => `/products/purchases/list/${id ? id : ":orderId"}`,

    productWarehousesProfile: (id?: string) => `/products/warehouses/profile/${id ? id : ":warehouseId"}`,

    productWarehousesMovementProfile: (id?: string) => `/products/warehouses/movement/${id ? id : ":movementId"}`,

    productWarehousesMovementNew: `/products/warehouses/movement/create`,
    productWarehousesMovementUpdate: (id?: string) => `/products/warehouses/movement/update/${id ? id : ":movementId"}`,
    productInventoryAdjustmentEditId: (id?: string) => `/products/adjustments/edit/${id ? id : ":inventoryAdjustmentId"}`,
    productInventoryAdjustmentProfileId: (id?: string) => `/products/adjustments/profile/${id ? id : ":inventoryAdjustmentId"}`,
    productCategories: "/products/categories",
    productLogs: "/products/logs",
    productCategoryProfile: (categoryId?: string) => `/products/categories/profile/${categoryId ? categoryId : ":categoryId"}`,
    productCatalogList: "/products/productcatalog",
    productTax: "/products/taxes",
    productTaxProfile: (id?: string) => `/products/taxes/${id ? id : ":id"}`,
    productDiscount: "/products/discounts",
    productDiscountProfile: (id?: string) => `/products/discounts/profile/${id ? id : ":id"}`,
    productProfileId: (id?: string) => `/products/list/${id ? id : ":productId"}`,

    orders: "/orders",

    orderPos: "/orders/pos",
    orderChannels: "/orders/channels",

    orderPosProfile: (id?: string) => `/orders/sales/${id ? id : ":orderId"}`,
    orderPosProfileEditId: (orderId?: string) => `/orders/pos/list/edit/${orderId ? orderId : ":orderId"}`,
    orderSaleNewQuotation: (type?: OrderTypeTemp) => `/orders/sales/newquote${type ? `?type=${type}` : ""}`,
    orderPosNew: (type?: string) => `/orders/sales/createnew${type ? `?type=${type}` : ""}`,
    orderSalesList: "/orders/sales",
    orderReturns: "/orders/returns",
    orderVoidList: "/orders/void",
    orderQuoteList: "/orders/quotations",
    orderDraft: "/orders/draft",
    orderRestaurant: "/orders/restaurant",
    orderCommentList: "/orders/comments",

    shipments: "/shipments",
    shipmentsList: "/shipments/list",
    shipmentsListWhatsapp: "/shipments/whatsapp",
    orderShipmentsQueue: "/shipments/queue",
    orderShipmentNew: (type?: OrderTypeTemp) => `/shipments/list/new${type ? `?type=${type}` : ""}`,
    orderShipmentReadyToShip: "/shipments/readyToShip",
    orderShipmentsBoard: "/shipments/shipmentsboard",
    orderShipmentProfile: (id?: string) => `/shipments/list/${id ? id : ":orderId"}`,

    shipmentsListPicking: "/shipments/picking",
    shipmentsListPickingPreview: (orderId: string) => `/shipments/picking?${"order"}=${orderId}`,
    restaurant: "/restaurant",

    contacts: "/contacts",
    contactList: "/contacts/all",
    contactSellers: "/contacts/sellers",
    contactCompany: "/contacts/companies",
    contactCourier: "/contacts/couriers",
    contactSuppliers: "/contacts/suppliers",
    contactProfile: (id?: string) => `/contacts/all/profile/${id ? id : ":contactId"}`,

    config: "/settings",
    configGeneral: "/settings/localSettings",
    configInvoiceNumeration: "/settings/invoicenumeration",
    configPrinters: "/settings/printers",
    configEBilling: "/settings/ebilling",

    account: "/settings",
    settingsAccountProfile: "/settings/profile",
    settingsAccountUserProfile: "/settings/userprofile",
    settingsAccountHelpCenter: "/settings/helpcenter",

    settingsAccountProfileEditUserId: (id?: string) => `/settings/profile/users/${id ? id : ":accountUserId"}`,
    accountReports: "/settings/reports",
    accountStores: "/settings/stores",
    accountInvoices: "/settings/invoices",
    accountBilling: "/settings/billing",
    accountStatistics: "/settings/statistics",
    accountCloud: "/settings/cloud",
    accountUsers: "/settings/users",
    accountCalendar: "/settings/calendar",

    ecommerce: "/settings/ecommerce",
    ecommerceProfile: (id?: string) => `/settings/ecommerce/profile/${id ? id : ":productCatalogId"}`,

    accountOrganizationsProfile: (id?: string) => `/settings/stores/profile/${id ? id : ":organizationId"}`,

    storeProfile: (id?: string) => `/storeprofile/${id ? id : ":organizationId"}`,
    storeProfileWarehouse: (id?: string) => `/storeprofile/${id ? id : ":organizationId"}?tab=warehouses`,
    storeProfileEcommerce: (id?: string) => `/storeprofile/${id ? id : ":organizationId"}?tab=ecommerce`,
    deliveries: "/deliveries",
};

export default PATHS;
