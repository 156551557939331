import { IPaginationRequest } from ".";
import { AccountUser } from "./accountUser";

export type ProductLogOrigin = "unknown" | "order" | "purchase" | "movement" | "adjustment" | "warehouseLocation";
export const PRODUCT_LOG_ORIGIN_LIST: ProductLogOrigin[] = ["unknown", "order", "purchase", "movement", "adjustment", "warehouseLocation"];

export interface ProductLog {
    id: string;
    accountId: string;
    accountUserId: string;
    accountUser?: AccountUser;
    warehouseId: string;
    warehouseName?: string;
    productId: string;
    productName?: string;
    productSku?: string;
    productImage?: string;

  
    warehouseLocationId?: string;
    locationCode?: string;
    locationColor?: string;
  
    productVariantId?: string;
    productVariantImage?: string;
    productVariantSku?: string;
    productVariantValuesLine?: string;
  
    documentId: string;
    documentItemId?: string;
    documentNumber?: string;
    quantity: number;
    createdAt: Date;
    origin: ProductLogOrigin;
}

export interface SearchModelProductLog extends IPaginationRequest {
    accountId: string;
    customCategoryId?: string;
    warehouseId?: string;
    accountUserId?: string;
    searchValue?: string;
    productId?: string;
    documentId?: string;
    onlyProductWithImage: boolean;
    onlyProductWithoutImage: boolean;
    origin?: ProductLogOrigin;
    status: string;
    dateRangeActive?: boolean;
    dateStart?: Date;
    dateEnd?: Date;
    minutesOffset: number;
}
