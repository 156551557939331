import * as Popover from "@radix-ui/react-popover";
import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";
import { COLOR_LIST, getColorValue, LIGHT_COLORS, WahioColorOption } from "../../constants/colors";
import colorMessages from "../../constants/messages";
import { checkAndGetMessage } from "../../i18n/helper";
import { DefaultButton, Flex, GridTemplate } from "../_controls";
import { HoverCardContentStyle } from "../_controls/PopoverRadix/hoverCardStyle";
import { DropdownColorOption } from "./styled";

interface ColorDropdownProps {
    color?: string;
    onRemove?: () => void;
    onSelected: (color: string) => void;
    hideRemove?: boolean;
    ignoreColors?: WahioColorOption[];
    small?: boolean;
    hideTitle?: boolean;
    title?: string;
    icon?: string;
}

export default function ColorDropdown(props: ColorDropdownProps) {
    const intl = useIntl();

    let buttonStyle: CSSProperties = {
        minHeight: "36px",
    };

    if (props.color) {
        buttonStyle = {
            ...buttonStyle,
            color: LIGHT_COLORS.includes(props.color as any) ? "black" : "white",
            background: getColorValue(props.color),
            borderColor: "transparent",
        };
    }

    let title: string | undefined = undefined;
    if (!props.hideTitle) {
        title = props.title ? props.title : checkAndGetMessage(intl, colorMessages, props.color);
    }

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <DefaultButton borderRadius={10} style={buttonStyle}>
                    <span className={props.icon ? props.icon : "fa-regular fa-palette"}></span> {title}
                </DefaultButton>
            </Popover.Trigger>

            <Popover.Portal>
                <HoverCardContentStyle>
                    <Popover.Content
                        side="bottom"
                        align="start"
                        className="HoverCardContent"
                        style={{ width: "290px", padding: "20px" }}
                        sideOffset={5}
                    >
                        <Flex gap={15} column minWidth={150}>
                            {!props.hideRemove && props.color && props.onRemove && (
                                <>
                                    <DefaultButton
                                        borderRadius={10}
                                        bgLight
                                        onClick={() => {
                                            if (props.onRemove) props.onRemove();
                                        }}
                                        rounded={true}
                                    >
                                        <i className="fa-regular fa-trash"></i> Remover Color
                                    </DefaultButton>
                                </>
                            )}

                            <GridTemplate size={25}>
                                {Object.keys(COLOR_LIST).map((item, index) => {
                                    if (props.ignoreColors?.includes(item as any)) return null;
                                    return (
                                        <DropdownColorOption
                                            style={{ background: getColorValue(item) }}
                                            title={checkAndGetMessage(intl, colorMessages, item)}
                                            key={index}
                                            onClick={() => props.onSelected(item)}
                                        >
                                            {item === props.color && <i className="fa-regular fa-check"></i>}
                                        </DropdownColorOption>
                                    );
                                })}
                            </GridTemplate>
                        </Flex>
                        <Popover.Arrow className="HoverCardArrow" />
                    </Popover.Content>
                </HoverCardContentStyle>
            </Popover.Portal>
        </Popover.Root>
    );
}
