import React, { useState } from "react";
import { IWarehouseProductLocation } from "../../../api/models/warehouseLocation";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { COLOR_LIST } from "../../../constants/colors";
import { Flex, SquareButton, TextField } from "../../_controls";
import WarehouseLocationAdjustmentModal from "./WarehouseLocationAdjustmentModal";
import WarehouseLocationMovementModal from "./WarehouseLocationMovementModal";
import { LocationsTable, WarehouseLocationCard, WarehouseLocationCardSmall } from "./styled";
import { IProduct } from "../../../api/models/product";

interface LocationCardViewProps {
    productLocation: IWarehouseProductLocation;
    onClickCard?: (value: IWarehouseProductLocation) => void;
}

interface LocationCardViewSmallProps extends LocationCardViewProps {
    onUpdateLocation: () => void;
}

export default function LocationProductCardView(props: LocationCardViewProps) {
    const { productLocation } = props;
    const location = productLocation.warehouseLocation;

    return (
        <WarehouseLocationCard className="active" color={(COLOR_LIST as any)[location.color]}>
            <div className="body" onClick={() => props.onClickCard && props.onClickCard(productLocation)}>
                <span className={`stock`}>{productLocation.stock}</span>
                <span className="label mt-1">{location.code}</span>
            </div>
        </WarehouseLocationCard>
    );
}

export function LocationProductCardViewSmall(props: LocationCardViewSmallProps) {
    const { productLocation } = props;
    const [showEditLocationOption, setShowEditLocationOption] = useState<"MOVEMENT" | "ADJUSTMENT" | undefined>(undefined);

    const location = productLocation.warehouseLocation;

    return (
        <WarehouseLocationCardSmall className="location-card" color={(COLOR_LIST as any)[location.color]}>
            {showEditLocationOption === "MOVEMENT" && (
                <WarehouseLocationMovementModal
                    productLocation={productLocation}
                    show={showEditLocationOption === "MOVEMENT"}
                    setShow={() => setShowEditLocationOption(undefined)}
                    onFinish={() => {
                        props.onUpdateLocation();
                    }}
                />
            )}
            {showEditLocationOption === "ADJUSTMENT" && (
                <WarehouseLocationAdjustmentModal
                    productLocation={productLocation}
                    show={showEditLocationOption === "ADJUSTMENT"}
                    setShow={() => setShowEditLocationOption(undefined)}
                    onFinish={() => {
                        props.onUpdateLocation();
                    }}
                />
            )}
            <div className="card-item location-card">
                <span className={`stock`}>{productLocation.stock}</span>
                <span className="label">{location.code}</span>
            </div>
            <div className="card-item">
                <SquareButton className="btn-action small mr-small" onClick={() => setShowEditLocationOption("ADJUSTMENT")}>
                    <span className="wahioicon-pen"></span>
                </SquareButton>
                <SquareButton className="btn-action small" onClick={() => setShowEditLocationOption("MOVEMENT")}>
                    <span className="wahioicon-exchange-alt"></span>
                </SquareButton>
            </div>
        </WarehouseLocationCardSmall>
    );
}

interface LocationProductTableProps {
    product: IProduct;
    warehouseProduct: IWarehouseProduct;
    productLocations: IWarehouseProductLocation[];
    onUpdateLocation: () => void;
}

interface ShowEditLocation {
    productLocation?: IWarehouseProductLocation;
    type?: "MOVEMENT" | "ADJUSTMENT";
}

export function ProductLocationsTable(props: LocationProductTableProps) {
    const [editOption, setEditOption] = useState<ShowEditLocation>({});

    return (
        <>
            {editOption.productLocation && editOption.type === "MOVEMENT" && (
                <WarehouseLocationMovementModal
                    productLocation={{
                        ...editOption.productLocation,
                        warehouseProduct: { ...props.warehouseProduct, product: props.product },
                    }}
                    show={editOption.type === "MOVEMENT"}
                    setShow={() => setEditOption({})}
                    onFinish={() => {
                        props.onUpdateLocation();
                    }}
                />
            )}
            {editOption.productLocation && editOption.type === "ADJUSTMENT" && (
                <WarehouseLocationAdjustmentModal
                    productLocation={{
                        ...editOption.productLocation,
                        warehouseProduct: { ...props.warehouseProduct, product: props.product },
                    }}
                    show={editOption.type === "ADJUSTMENT"}
                    setShow={() => setEditOption({})}
                    onFinish={() => {
                        props.onUpdateLocation();
                    }}
                />
            )}
            <LocationsTable cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <th>Ubicación</th>
                        <th>Stock</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.productLocations.map((productLocation) => (
                        <LocationProductTableRow
                            key={productLocation.warehouseLocationId}
                            productLocation={productLocation}
                            onUpdateLocation={props.onUpdateLocation}
                            onClickOption={(value) => setEditOption(value)}
                        />
                    ))}
                </tbody>
            </LocationsTable>
        </>
    );
}

interface LocationProductTableRowProps {
    productLocation: IWarehouseProductLocation;
    onUpdateLocation: () => void;
    onClickOption: (value: ShowEditLocation) => void;
}

export function LocationProductTableRow(props: LocationProductTableRowProps) {
    const { productLocation, onClickOption } = props;
    const location = productLocation.warehouseLocation;

    const locationColor = (COLOR_LIST as any)[location.color];

    return (
        <tr color={locationColor}>
            <td>
                <Flex gap10 alignCenter>
                    <div className="row-color" style={{ backgroundColor: locationColor }}>
                        <i className="fa-regular fa-inbox"></i>
                    </div>
                    <TextField>{location.code}</TextField>
                </Flex>
            </td>
            <td>{productLocation.stock}</td>
            <td>
                <Flex gap10 alignCenter justifyEnd className="btn-actions">
                    <SquareButton
                        onClick={() =>
                            onClickOption({
                                type: "ADJUSTMENT",
                                productLocation,
                            })
                        }
                    >
                        <span className="fa-regular fa-pen-to-square"></span>
                    </SquareButton>
                    <SquareButton
                        onClick={() =>
                            onClickOption({
                                type: "MOVEMENT",
                                productLocation,
                            })
                        }
                    >
                        <i className="fa-regular fa-right-left"></i>
                    </SquareButton>
                </Flex>
            </td>
        </tr>
    );
}
