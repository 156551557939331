import React, { useContext } from "react";
import { getAccountUserFullName } from "../../api/models/accountUser";
import { IOrganization } from "../../api/models/organizationTypes";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { CloudImages } from "../../assets";
import { useOrganizationContext } from "../../store/contexts/OrganizationContext";
import { StoreSelectMainView } from "./styled";
interface IOrganizationCardProps {
    organization: IOrganization;
}

export default function StoreSelectMainCard(props: IOrganizationCardProps) {
    const { organization } = props;
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const orgContext = useOrganizationContext();
    const users = organization.users;

    const onClickStore = () => {
        orgContext.setCurrentOrganizationState(props.organization);
        appSettingsActions.setAllSettings({ ...appSettingsState, defaultOrganizationId: organization.id ?? "" });
    };

    return (
        <StoreSelectMainView onClick={onClickStore}>
            <div className="header">
                <div className="image">
                    <img src={organization.image ? organization.image : CloudImages.defaultImage} alt={organization.name} />
                </div>
                <div className="content">
                    <span className="title">{organization.name}</span>
                    <span className="light">{organization.address}</span>
                </div>
            </div>
            <div className="user-container">
                {users &&
                    users.map((item, index) => {
                        return (
                            <div className="user-card-container">
                                <div key={index} title={getAccountUserFullName(item.accountUser)} className="user-card">
                                    {item.accountUser?.image ? (
                                        <img src={item.accountUser?.image} alt={item.accountUser?.firstName} />
                                    ) : (
                                        <span>{item.accountUser?.firstName.substring(0, 1)}</span>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </StoreSelectMainView>
    );
}
