import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { OrderItemLog, OrderItemLogSearch } from "../../../api/models/order";
import { getOrderItemLogs } from "../../../api/orders/orderApi";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { getDateFormat, getDateFormatCalendar } from "../../../utils";
import LoadingDualRing from "../../LoadingDualRing";
import Modal, { IModalShow } from "../../modals/Modal";
import { Flex } from "../../_controls";
import { OrderItemLogCard, OrderItemLogsContainer } from "./styled";

interface OrderItemLogsProps {
    orderId: string;
    content?: React.ReactNode;
}

export function OrderItemLogs(props: OrderItemLogsProps) {
    const [orderItemLogGroup, setOrderItemLogGroup] = useState<{ [key: string]: OrderItemLog[] }>({});

    const logsMutation = useMutation((data: OrderItemLogSearch) => getOrderItemLogs(data), {
        onSuccess: (data) => {
            var groupData = data.items.reduce((prev: { [key: string]: OrderItemLog[] }, item) => {
                let list = prev[getDateFormat(item.createdAt, "DDMMYYYYhhmm")] ?? [];
                prev[getDateFormat(item.createdAt, "DDMMYYYYhhmm")] = [...list, item];
                return prev;
            }, {});
            setOrderItemLogGroup(groupData);
        },
    });

    useEffect(() => {
        handleSearchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orderId]);

    const handleSearchLogs = () => {
        let data: OrderItemLogSearch = {
            orderId: props.orderId,
            currentPage: 1,
            pageSize: 20,
        };
        if (props.orderId) {
            logsMutation.mutate(data);
        }
    };

    if (logsMutation.isLoading) return <LoadingDualRing center />;

    return (
        <Flex column gap15 w100>
            {props.content}
            <OrderItemLogsContainer>
                {Object.keys(orderItemLogGroup).map((key) => {
                    let list = orderItemLogGroup[key];
                    let first = list[0];
                    return (
                        <Flex column gap5 key={key}>
                            <Flex gap10 alignCenter>
                                <span className="date-point"></span>
                                <span className="date-text text-small">{getDateFormatCalendar(first.createdAt)}</span>
                            </Flex>
                            <Flex className="card-group" column>
                                {list.map((item) => (
                                    <OrderItemCardView item={item} key={item.id} />
                                ))}
                            </Flex>
                        </Flex>
                    );
                })}
            </OrderItemLogsContainer>
        </Flex>
    );
}

interface OrderItemCardViewProps {
    item: OrderItemLog;
}
const OrderItemCardView = ({ item }: OrderItemCardViewProps) => {
    var { accountUserState } = useAccountUserContext();
    return (
        <OrderItemLogCard>
            <Flex column className="card-body">
                <Flex spaceBetween w100 alignCenter>
                    <Flex column>
                        <h3 className="m-0 text-bold card-title">{item.product?.name}</h3>
                        <span className="text-light text-small">
                            {accountUserState.items.find((x) => x.id === item.accountUserId)?.firstName}
                        </span>
                    </Flex>
                    <span className={`quantity ${item.action}`}>
                        {item.action === "remove" ? "-" : "+"} {item.quantity}
                    </span>
                </Flex>
            </Flex>
        </OrderItemLogCard>
    );
};

interface OrderItemLogsModalProps extends IModalShow, OrderItemLogsProps {}

export const OrderItemLogsModal = (props: OrderItemLogsModalProps) => {
    return (
        <Modal {...props} showHeader title="Historial Items">
            <Flex padding={20}>
                <OrderItemLogs {...props} />
            </Flex>
        </Modal>
    );
};
