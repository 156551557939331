import React from "react";
import { FormattedMessage } from "react-intl";

interface IMessageBody {
    id: string;
    defaultMessage: string;
}

const translate = (message: IMessageBody) => {
    return <FormattedMessage {...message} />;
};

export default translate;
