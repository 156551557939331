import XLSX from "xlsx";
import { AccountUser } from "../../../api/models/accountUser";
import { AccountingType, OrderAccountingResultItem } from "../../../api/models/accounting";
import { Contact } from "../../../api/models/contact";
import { IProductCategory } from "../../../api/models/productCategory";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";

/* list of supported file types */
export const SheetJSFT = [
    "xlsx",
    "xlsb",
    "xlsm",
    "xls",
    "xml",
    "csv",
    "txt",
    "ods",
    "fods",
    "uos",
    "sylk",
    "dif",
    "dbf",
    "prn",
    "qpw",
    "123",
    "wb*",
    "wq*",
    "html",
    "htm",
]
    .map(function (x) {
        return "." + x;
    })
    .join(",");

/* generate an array of column objects */
export const make_cols = (refstr: any) => {
    let o: any[] = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

export interface IImportModel {
    headers: string[];
    translation: { [key: string]: string };
}

export interface ImportState<T> {
    importLoading: boolean;
    importResponse?: ImportResponse<T>;
    showImport: boolean;
}

export const ContactImportModel: IImportModel = {
    headers: ["Nombres", "Apellidos", "Telefono", "Correo", "Direccion", "Departamento", "Ciudad", "NumeroDocumento", "PersonaJuridica"],
    translation: {
        Nombres: "firstName",
        Apellidos: "lastName",
        Telefono: "phoneNumber",
        Correo: "email",
        Direccion: "address",
        Departamento: "state",
        Ciudad: "city",
        NumeroDocumento: "documentNumber",
        PersonaJurídica: "type",
    },
};

export const TransactionAccountsImportModel: IImportModel = {
    headers: ["Nombres", "Apellidos", "NumeroDocumento", "NumeroTelefono", "Email", "Tipo", "Total", "TotalPagado", "Concepto", "Notas"],
    translation: {
        Nombres: "firstName",
        Apellidos: "lastName",
        NumeroDocumento: "identificationNumber",
        NumeroTelefono: "phoneNumber",
        Email: "email",
        Tipo: "type",
        Total: "totalAmount",
        TotalPagado: "totalPaid",
        Concepto: "concept",
        Notas: "notes",
    },
};

export const CustomCategoryImportModel: IImportModel = {
    headers: ["Nombre", "Productos"],
    translation: {
        Nombre: "name",
        Productos: "productCount",
    },
};

export const AccountUserImportModel: IImportModel = {
    headers: ["Nombres", "Apellidos", "Correo", "Telefono"],
    translation: {
        Nombre: "firstName",
        Apellidos: "lastName",
        Correo: "email",
        Telefono: "phoneNumber",
    },
};

export const ProductOrderImportModel: IImportModel = {
    headers: ["CodigoSku", "Cantidad", "Costo"],
    translation: {
        CodigoSku: "codeOrSku",
        Cantidad: "quantity",
        Costo: "price",
    },
};

export const ProductLogImportModel: IImportModel = {
    headers: ["ProductID", "Producto", "Codigo", "Sku", "Bodega", "Origen", "Cantidad", "Fecha"],
    translation: {
        CodigoSku: "codeOrSku",
        Cantidad: "quantity",
        Costo: "price",
    },
};

export const ProductImportModel: IImportModel = {
    headers: [
        "Id",
        "Nombre",
        "Categoria",
        "Descripcion",
        "ControlStock",
        "CostoProducto",
        "PrecioVenta1",
        "PrecioVenta2",
        "PrecioVenta3",
        "PrecioVenta4",
        "PrecioVenta5",
        "Stock",
        "CodigoBarras",
        "Sku",
        "Caracteristicas",
        "Ancho",
        "Largo",
        "Alto",
        "Peso",
        "Referencia",
    ],

    translation: {
        Id: "productId",
        Nombre: "name",
        Categoria: "categoryName",
        Descripcion: "description",
        ControlStock: "controlStock",
        CostoProducto: "basePrice",
        PrecioVenta1: "price1",
        PrecioVenta2: "price2",
        PrecioVenta3: "price3",
        PrecioVenta4: "price4",
        PrecioVenta5: "price5",
        Stock: "stock",
        CodigoBarras: "barcode",
        Sku: "sku",
        Caracteristicas: "features",
        Ancho: "width",
        Largo: "length",
        Alto: "height",
        Peso: "weight",
        Referencia: "reference",
    },
};
export const AccountingReceivedImportModel: IImportModel = {
    headers: [
        "IDCliente",
        "Cliente",
        "Email",
        "Telefono",
        "NroIdentificacion",
        "TotalDocumentos",
        "Saldo",
        "SaldoVencido",
        "FechaUltimoPago",
    ],

    translation: {
        IDCliente: "customerId",
        Cliente: "contactName",
        Email: "contactEmail",
        Telefono: "contactPhone",
        NroIdentificacion: "contactDocument",
        TotalDocumentos: "orderCount",
        Saldo: "balance",
        SaldoVencido: "balanceDue",
        FechaUltimoPago: "lastPaymentDate",
    },
};

export const convertProductListToExport = (warehouseProduct: IWarehouseProduct[]) => {
    let exportRows: any[] = [];
    exportRows.push(ProductImportModel.headers);
    warehouseProduct.forEach((cus) => {
        exportRows.push([
            cus.productId,
            cus.product.name,
            cus.product.customCategory?.name,
            cus.product.description,
            cus.product.controlInventory ? "SI" : "NO",
            cus.product.basePrice,
            cus.product.price,
            cus.product.price2,
            cus.product.price3,
            cus.product.price4,
            cus.product.price5,
            cus.stock,
            cus.product.code,
            cus.product.sku,
            cus.product.characteristics,
            cus.product.itemWidth,
            cus.product.itemLength,
            cus.product.itemHeight,
            cus.product.itemWeight,
            cus.product.reference,
        ]);
    });
    return exportRows;
};

export const convertAccountingReceivedListToExport = (items: OrderAccountingResultItem[]) => {
    let exportRows: any[] = [];
    exportRows.push(AccountingReceivedImportModel.headers);
    items.forEach((cus) => {
        exportRows.push([
            cus.contactId,
            cus.contactName,
            cus.contactEmail,
            cus.contactPhone,
            cus.contactDocument,
            cus.orderCount,
            cus.balance,
            cus.balanceDue,
            cus.lastPaymentDate,
        ]);
    });
    return exportRows;
};

export const convertContactListToExport = (customers: Contact[]) => {
    let exportRows: any[] = [];
    exportRows.push(ContactImportModel.headers);
    customers.forEach((cus) => {
        let state = cus.addresses && cus.addresses.length > 0 ? cus.addresses[0].state : "";
        let city = cus.addresses && cus.addresses.length > 0 ? cus.addresses[0].city : "";

        exportRows.push([
            cus.firstName,
            cus.lastName,
            cus.phoneNumber,
            cus.email,
            cus.address,
            state,
            city,
            cus.identificationNumber,
            cus.type,
        ]);
    });
    return exportRows;
};

export const convertCustomCategoryListToExport = (items: IProductCategory[]) => {
    let exportRows: any[] = [];
    exportRows.push(CustomCategoryImportModel.headers);
    items.forEach((cus) => {
        exportRows.push([cus.name, cus.productCount]);
    });
    return exportRows;
};

export const convertAccountUsersListToExport = (items: AccountUser[]) => {
    let exportRows: any[] = [];
    exportRows.push(AccountUserImportModel.headers);
    items.forEach((cus) => {
        exportRows.push([cus.firstName, cus.lastName, cus.email, cus.phoneNumber]);
    });
    return exportRows;
};

export interface IImportRequestModel<T> {
    accountId: string;
    accountUserId?: string;
    cashRegisterId?: string;
    items: T[];
}

export interface IProductImportRequestModel extends IImportRequestModel<IProductImportItem> {
    warehouseId: string;
    createNotFound: boolean;
    updateStock: boolean;
}
export interface IContactImportItem {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    documentNumber: string;
    type?: string;
    address: string;
    state: string;
    city: string;
}

export interface ITransactionAccountsImportItem {
    firstName: string;
    lastName: string;
    documentNumber: string;
    phoneNumber: string;
    email: string;
    transactionAccountType: AccountingType;
    totalAmount: number;
    totalPaid: number;
    concept: string;
    notes: string;
}

export interface TransactionAccountImportRequest {
    accountId: string;
    accountUserId: string;
    cashRegisterId?: string;
    items: ITransactionAccountsImportItem[];
}

export interface IProductImportItem {
    accountId: string;
    warehouseId: string;
    updateStock: boolean;
    productId: string;
    name: string;
    categoryName: string;
    description: string;
    controlStock: string;
    controlInventory: boolean;
    basePrice: number;
    price1: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    stock: number;
    barcode: string;
    sku: string;
    width: number;
    length: number;
    height: number;
    weight: number;
    reference: string;
}

export interface IProductPurchaseImportItem {
    codeOrSku: string;
    quantity: number;
}

export interface FailureMatch {
    name: string;
    message: string;
}
export interface ImportResponse<T> {
    failures: FailureMatch[];
    items: T[];
}

export const exportExcelFile = (data: any[], fileName: string) => {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};
