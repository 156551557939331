import React from "react";
import { UlContainer } from "./styled";

export const DropdownItem = () => {
    return <li></li>;
};

interface DropdownOptionsProps {
    children: any;
}

export default function DropdownClassicOptions(props: DropdownOptionsProps) {
    return <UlContainer>{props.children}</UlContainer>;
}
