import React from 'react';
import { IBasePermission } from '../../../api/models/accountUserPermissions';
import { IPurchase } from '../../../api/models/purchase';
import TableReceiveItems from './TableReceiveItems';
import { ContainerBody } from './styled';
import { NavigationGroupName } from './menuHelper';

interface PurchaseReceiveProductsProps {
    currentOrder: IPurchase;
    permission: IBasePermission;
    onUpdateOrder: (value: IPurchase) => void;
    setChangeTabKey: (value: NavigationGroupName) => void;
}

export const PurchaseReceiveProducts = (props: PurchaseReceiveProductsProps) => {
    const { currentOrder } = props;

    return (
        <ContainerBody className="container-body">
            <TableReceiveItems setChangeTabKey={props.setChangeTabKey} key={currentOrder.id} purchase={currentOrder} onChange={props.onUpdateOrder} />
        </ContainerBody>
    )
}
