import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { DeliveryStatus } from "../../../api/models/deliveryOrder";
import { OrderStatus } from "../../../api/models/orderBase";
import { KeyParamMapping } from "../../FilterParams/helper";

export type OrderParamType =
    | "search"
    | "status"
    | "order"
    | "customer"
    | "paymentMethod"
    | "channel"
    | "user"
    | "seller"
    | "source"
    | "invoiceNumeration"
    | "courier"
    | "withReturn"
    | "withoutReturn"
    | "withEBilling"
    | "withoutEBilling"
    | "page"
    | "withInvoice"
    | "withoutInvoice"
    | "shippingStatus"
    | "startDate"
    | "endDate"
    | "backLink";

export const IgnoreActiveFilters: (keyof OrderListParams)[] = ["order", "page", "backLink"];
export const IgnoreFilterValues: (keyof OrderListParams)[] = ["backLink"];

export interface OrderListParams {
    search?: string;
    status?: OrderStatus;
    shippingStatus?: DeliveryStatus;
    order?: string;

    customer?: string;
    paymentMethod?: string;
    channel?: string;
    user?: string;
    seller?: string;
    source?: string;
    invoiceNumeration?: string;
    courier?: string;

    withInvoice?: string;
    withoutInvoice?: string;
    withReturn?: string;
    withoutReturn?: string;
    withEBilling?: string;
    withoutEBilling?: string;

    startDate?: string;
    endDate?: string;

    page?: number;

    backLink?: string;
}

export const EntryParamsKeyNames: KeyParamMapping<OrderListParams, OrderParamType>[] = [
    { key: "search", param: "search" },
    { key: "status", param: "status" },
    { key: "shippingStatus", param: "shippingStatus" },
    { key: "startDate", param: "startDate" },
    { key: "endDate", param: "endDate" },
    { key: "order", param: "order" },
    { key: "customer", param: "customer" },
    { key: "paymentMethod", param: "paymentMethod" },
    { key: "channel", param: "channel" },
    { key: "user", param: "user" },
    { key: "seller", param: "seller" },
    { key: "source", param: "source" },
    { key: "invoiceNumeration", param: "invoiceNumeration" },
    { key: "courier", param: "courier" },
    { key: "withInvoice", param: "withInvoice" },
    { key: "withoutInvoice", param: "withoutInvoice" },
    { key: "withReturn", param: "withReturn" },
    { key: "withoutReturn", param: "withoutReturn" },
    { key: "withEBilling", param: "withEBilling" },
    { key: "withoutEBilling", param: "withoutEBilling" },
    { key: "page", param: "page" },
    { key: "backLink", param: "backLink" },
];

export const getOrderParams = (params: OrderListParams) => {
    let search = "?";

    EntryParamsKeyNames.forEach((item) => {
        const value = params[item.key];
        if (value) {
            const processed = value.toString();
            search += getSearchValue(item.param, processed);
        }
    });

    if (search[search.length - 1] === "&") {
        search = search.substring(0, search.length - 1);
    }
    return search;
};

export const calculateActiveFilters = (productParams: OrderListParams) => {
    let counter = EntryParamsKeyNames.filter((x) => !IgnoreActiveFilters.includes(x.key) && !!productParams[x.key]).length;
    return counter;
};

const getSearchValue = (key: keyof OrderListParams, value: string) => {
    return `${key}=${encodeURIComponent(value)}&`;
};

export const useOrderSearchParams = () => {
    const [urlParams, setParams] = useSearchParams();

    const setSearchParams = (state: OrderListParams) => {
        const search = getOrderParams(state);
        setParams(search);
    };

    const getSearchParamsText = (state: OrderListParams) => {
        const search = getOrderParams(state);
        return search;
    };

    const getSearchParams = () => {
        let searchParams: OrderListParams = {};
        EntryParamsKeyNames.forEach((item) => {
            const value = urlParams.get(item.param);
            if (value) {
                var processed = item.decode ? item.decode(value) : value;
                searchParams[item.key] = processed;
            }
        });
        searchParams.page = parseInt(urlParams.get("page") ?? "1") ?? 1;
        if (searchParams.page === 1) {
            searchParams.page = undefined;
        }

        return searchParams;
    };

    const [searchParams, activeFilterCount, paramsWithoutOrder] = useMemo(() => {
        const newParams = getSearchParams();
        const filterCount = calculateActiveFilters(newParams);
        const paramsWithoutOrder = getSearchParamsText({ ...newParams, order: undefined });
        return [newParams, filterCount, paramsWithoutOrder];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    return { orderParams: searchParams, setOrderParams: setSearchParams, getSearchParamsText, activeFilterCount, paramsWithoutOrder };
};
