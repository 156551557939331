import React from "react";
import { formatMoney } from "../../../utils";
import { Order } from "../../../api/models/order";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Table } from "../../_controls/tables/styled";
import { TextField } from "../../_controls";

interface TotalDetailsProps {
    order: Order;
}

export const TotalDetails = (props: TotalDetailsProps) => {
    const { order } = props;
    const intl = useIntl();

    return (
        <Table cellSpacing={0} cellPadding={0} tdPadding={5} tdPaddingRight={15} hideBorde>
            <tbody>
                <tr>
                    <td>Subtotal</td>
                    <td>{formatMoney(order.subTotal)}</td>
                </tr>
                {order.totalManualPercentageDiscountItems > 0 && (
                    <tr>
                        <td>Items Desc</td>
                        <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                    </tr>
                )}
                {order.totalDiscount > 0 && (
                    <tr>
                        <td>G.Descuento</td>
                        <td>{formatMoney(order.totalDiscount)}</td>
                    </tr>
                )}
                {order.totalTaxItems > 0 && (
                    <tr>
                        <td>Impuesto</td>
                        <td>{formatMoney(order.totalTaxItems)}</td>
                    </tr>
                )}
                {order.totalTip > 0 && (
                    <tr>
                        <td>Propina</td>
                        <td>{formatMoney(order.totalTip)}</td>
                    </tr>
                )}
                {order.isDelivery && order.totalDelivery > 0 && (
                    <tr>
                        <td>Envío</td>
                        <td>{formatMoney(order.deliveryOrder?.amount)}</td>
                    </tr>
                )}
                {order.totalAmountReturns > 0 && (
                    <tr>
                        <td>Devolución</td>
                        <td>{formatMoney(order.totalAmountReturns)}</td>
                    </tr>
                )}
                <tr className="border-top-dashed">
                    <td>
                        <TextField bold>{intl.formatMessage(messages.total)}</TextField>
                    </td>
                    <td>
                        <TextField bold>{formatMoney(order.totalAmount)}</TextField>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};
