import { TransactionAccount } from "../../../../api/models/transactionAccount";
import { getAuthBody } from "../../../../auth";
import customMoment from "../../../../utils/momentFormat/dateFormat";

export const getTransactionBody = (value?: TransactionAccount) => {
    if (value) {
        return value;
    }

    let body: TransactionAccount = {
        id: "",
        accountId: getAuthBody().accountId,
        organizationId: "",
        contactId: "",
        number: 0,
        createdAt: customMoment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        updatedAt: new Date(),
        dueDate: customMoment().add(30, "day").startOf("day").format(),
        paymentTermsDays: 30,
        deleteDate: new Date(),
        totalAmount: 0,
        totalPaid: 0,
        conceptId: "",
        type: "receivable",
        status: "credit",
        notes: "",
    };

    return body;
};
