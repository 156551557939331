import React, { useEffect, useState } from "react";
import OrderLocalContextProvider from "../../../store/contexts/OrderLocalContext";
import { Order } from "../../../api/models/order";
import { ScreenPreviewContainer } from "./styled";
import { Flex } from "../../_controls";
import { formatMoney } from "../../../utils";

export default function OrderScreenPreview() {
    return (
        <OrderLocalContextProvider>
            <PreviewSummary />
        </OrderLocalContextProvider>
    );
}

const PreviewSummary = () => {
    const [order, setOrder] = useState<Order>();

    const items = React.useMemo(() => {
        //items in reverse order
        return order?.items.slice().reverse() ?? [];
    }, [order?.items]);

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.origin !== window.location.origin) {
                return;
            }

            if (event.data.type === "order") {
                setOrder(event.data.order);
            }
        });
        return () => {
            window.removeEventListener("message", () => {});
        };
    }, []);

    return (
        <ScreenPreviewContainer>
            <div className="preview-ads">
                <div className="ads-image-container">
                    <img
                        src="https://storagemultimediadev.blob.core.windows.net/dd4707a0-89a8-4e28-b765-302bb28ba257/7061506-368a1c83-abca-4314-b09f-73c849f453dd.jpg"
                        alt="ads"
                    />
                </div>
            </div>
            <div className="preview-summary">
                <h2>Resumen</h2>
                <Flex column className="order-items">
                    {items.map((item, index) => {
                        return (
                            <Flex column key={index} className="order-item-row">
                                <span>
                                    {item.quantity}x {item.product?.name}
                                </span>
                                <span>{formatMoney(item.total)}</span>
                            </Flex>
                        );
                    })}
                </Flex>

                {order && (
                    <div className="summary-total">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Subtotal</td>
                                    <td>{formatMoney(order.subTotal)}</td>
                                </tr>
                                {order.totalDiscount > 0 && (
                                    <tr>
                                        <td>Descuento</td>
                                        <td>{formatMoney(order.totalDiscount)}</td>
                                    </tr>
                                )}
                                {order.totalItemsDiscount > 0 && (
                                    <tr>
                                        <td>Descuento Items</td>
                                        <td>{formatMoney(order.totalItemsDiscount)}</td>
                                    </tr>
                                )}
                                {order.totalTaxItems > 0 && (
                                    <tr>
                                        <td>Impuestos</td>
                                        <td>{formatMoney(order.totalTaxItems)}</td>
                                    </tr>
                                )}
                                <tr className="total-row">
                                    <td>Total</td>
                                    <td>{formatMoney(order.totalAmount)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </ScreenPreviewContainer>
    );
};
