import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { DefaultInputDate, DefaultInputTime, Flex, TextField } from "../_controls";

export interface EventDateInterval {
    startDate: Moment;
    endDate: Moment;
}

interface EventIntervalProps {
    initialStartDate: Moment;
    initialEndDate: Moment;
    onDateChange: (dates: EventDateInterval) => void;
}

const EventInterval: React.FC<EventIntervalProps> = ({ initialStartDate, initialEndDate, onDateChange }) => {
    const [startDate, setStartDate] = useState<Moment>(initialStartDate);
    const [startTime, setStartTime] = useState<string>(initialStartDate.format("HH:mm"));
    const [endTime, setEndTime] = useState<string>(initialEndDate.format("HH:mm"));

    const prevDatesRef = React.useRef<{ startDate: Moment; endDate: Moment }>({ startDate: initialStartDate, endDate: initialEndDate });

    useEffect(() => {
        let startDateTime = startDate
            .clone()
            .hour(Number(startTime.split(":")[0]))
            .minute(Number(startTime.split(":")[1]));

        let endDateTime = startDate
            .clone()
            .hour(Number(endTime.split(":")[0]))
            .minute(Number(endTime.split(":")[1]));

        // Verifica si la hora final es menor que la hora inicial y ajusta la fecha final
        if (endDateTime.isBefore(startDateTime)) {
            endDateTime = startDateTime.clone().add(30, "minutes");
            setEndTime(endDateTime.format("HH:mm"));
        }

        const newDates = { startDate: startDateTime, endDate: endDateTime };

        // Verifica si las fechas realmente han cambiado
        if (!startDateTime.isSame(prevDatesRef.current.startDate) || !endDateTime.isSame(prevDatesRef.current.endDate)) {
            onDateChange(newDates);
            prevDatesRef.current = newDates;
        }
    }, [startDate, startTime, endTime, onDateChange]);

    return (
        <Flex gap10 column w100>
            <Flex column gap5>
                <TextField small light>
                    Fecha: {initialStartDate.format("ddd DD MMM")}
                </TextField>
                <DefaultInputDate
                    className="hide-icon"
                    type="date"
                    w100
                    value={startDate.format("YYYY-MM-DD")}
                    onChange={(e) => setStartDate(moment(e.target.value))}
                />
            </Flex>
            <Flex gap10>
                <Flex column gap5>
                    <TextField small light>
                        Desde
                    </TextField>
                    <DefaultInputTime
                        w100
                        className="hide-icon"
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </Flex>
                <Flex column gap5>
                    <TextField small light>
                        Hasta
                    </TextField>
                    <DefaultInputTime w100 className="hide-icon" type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EventInterval;
