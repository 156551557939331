import { defineMessages } from "react-intl";

const base = "pages.createSale";

export default defineMessages({
    createOrder: {
        id: `${base}.createOrder`,
        defaultMessage: "Crear venta",
    },
    editOrder: {
        id: `${base}.editOrder`,
        defaultMessage: "Editar orden",
    },
    finish: {
        id: `${base}.finish`,
        defaultMessage: "Finalizar",
    },
    sendOrder: {
        id: `${base}.sendOrder`,
        defaultMessage: "Enviar Pedido",
    },
    sendQuotation: {
        id: `${base}.sendQuotation`,
        defaultMessage: "Crear Cotización",
    },
    price: {
        id: `${base}.price`,
        defaultMessage: "Precio",
    },
    seller: {
        id: `${base}.seller`,
        defaultMessage: "Vendedor",
    },
    customer: {
        id: `${base}.customer`,
        defaultMessage: "Cliente",
    },
    delivery: {
        id: `${base}.delivery`,
        defaultMessage: "Envío",
    },
    tax: {
        id: `${base}.tax`,
        defaultMessage: "Impuesto",
    },
    isDigitalInvoice: {
        id: `${base}.isDigitalInvoice`,
        defaultMessage: "Generar fáctura electronica",
    },
    deliveryCost: {
        id: `${base}.deliveryCost`,
        defaultMessage: "Costo de envío",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    subTotal: {
        id: `${base}.subTotal`,
        defaultMessage: "SubTotal",
    },
    addCustomerToContinue: {
        id: `${base}.addCustomerToContinue`,
        defaultMessage: "Cliente es obligatorio",
    },
    noProductSelected: {
        id: `${base}.noProductSelected`,
        defaultMessage: "Seleccione un producto para crear una nueva orden",
    },
    finishPaid: {
        id: `${base}.finishPaid`,
        defaultMessage: "Finalizar Pagado",
    },
    finishPartial: {
        id: `${base}.finishPartial`,
        defaultMessage: "Finalizar con pago parcial",
    },
    finishCredit: {
        id: `${base}.finishCredit`,
        defaultMessage: "Finalizar como crédito",
    },
    payments: {
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },
    addPayment: {
        id: `${base}.addPayment`,
        defaultMessage: "Agregar pago",
    },
    addOtherPayment: {
        id: `${base}.addOtherPayment`,
        defaultMessage: "Agregar otro pago",
    },
    paymentExceedsOrderValue: {
        id: `${base}.paymentExceedsOrderValue`,
        defaultMessage: "El pago supera el valor de la orden",
    },
    cancelModal: {
        id: `${base}.cancelModal`,
        defaultMessage: "Cancelar",
    },
    amount: {
        id: `${base}.amount`,
        defaultMessage: "Valor",
    },
    discount: {
        id: `${base}.discount`,
        defaultMessage: "Descuento",
    },
    tip: {
        id: `${base}.tip`,
        defaultMessage: "Propina",
    },
    saveAsDraft: {
        id: `${base}.saveAsDraft`,
        defaultMessage: "Guardar como borrador",
    },
    clearOrderForm: {
        id: `${base}.clearOrderForm`,
        defaultMessage: "Limpiar orden",
    },
    thisOrderMayTakeLongerThanUsualToCompleteDueToTheNumberOfItems: {
        id: `${base}.thisOrderMayTakeLongerThanUsualToCompleteDueToTheNumberOfItems`,
        defaultMessage: "Esta orden puede tardar más de lo habitual en completarse debido a la cantidad de artículos",
    },
    exceededTheAmountOfProductsAllowedPerOrder: {
        id: `${base}.exceededTheAmountOfProductsAllowedPerOrder`,
        defaultMessage: "Se ha excedido la cantidad de productos permitidos por orden",
    },
    quotation: {
        id: `${base}.quotation`,
        defaultMessage: "Cotización",
    },
    shipment: {
        id: `${base}.shipment`,
        defaultMessage: "Pedido",
    },
    sale: {
        id: `${base}.sale`,
        defaultMessage: "Venta",
    },
});
