import styled from "styled-components";

export const ExceptionCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    border-radius: 15px;
    background: ${(props) => props.theme.palette.backgroundLight};
    &:last-child {
        border: 0;
    }
`;
