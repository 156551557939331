import { City, State, Country } from "./../models/geoLocation";
import { GEO_LOCATION_ENDPOINT } from "./../appConfig";
import { joinUrl } from "../../utils";

export const getCountries = (): Promise<Country[]> => {
    const url = joinUrl(GEO_LOCATION_ENDPOINT, "v1/geolocation/countries");
    return fetch(url).then((res: any) => res.json());
};

export const getCountryStates = (countryCode: string): Promise<State[]> => {
    const url = joinUrl(GEO_LOCATION_ENDPOINT, `v1/geolocation/country/${countryCode}/states`);
    return fetch(url).then((res: any) => res.json());
};

export const getCitiesByCountryAndState = (countryCode: string, stateCode: string): Promise<City[]> => {
    const url = joinUrl(GEO_LOCATION_ENDPOINT, `v1/geolocation/country/${countryCode}/states/${stateCode}/cities`);
    return fetch(url).then((res: any) => res.json());
};
