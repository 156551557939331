import { useIntl } from "react-intl";
import { DeliveryStatus, IDeliveryHistory } from "../../../api/models/deliveryOrder";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { getTimeInterval } from "../../../utils";
import { getDateTimeLabel } from "../../DatePicker/DateTimeLabel";
import { ShipmentStepItem } from "./ShipmentProgress";
import { SHIPMENT_STATUS_TEXT_HELPER } from "./helpers";
import shipmentMessages from "./messages";

export const useShipmentStatusList = () => {
    const { shipmentSettings, shipmentStatusState } = useClientSettings();
    const intl = useIntl();

    function getClosestVisibleStatus(currentStatus: DeliveryStatus, allowLogisticsService: boolean) {
        let allStatus = allowLogisticsService ? shipmentStatusState.statusLogistic : shipmentStatusState.status;
        let activeStatus = allowLogisticsService ? shipmentStatusState.statusLogisticActive : shipmentStatusState.statusActive;

        const ALL_STATUS_LIST = Object.keys(allStatus).map((x) => x as DeliveryStatus);
        const ACTIVE_STATUS_LIST = Object.keys(activeStatus).map((x) => x as DeliveryStatus);

        let currentIndex = ALL_STATUS_LIST.indexOf(currentStatus);
        while (currentIndex >= 0) {
            let matchIndex = ACTIVE_STATUS_LIST.indexOf(ALL_STATUS_LIST[currentIndex]);
            if (matchIndex >= 0) {
                return matchIndex;
            }
            currentIndex--;
        }

        return 0;
    }

    const getStatusTitle = (status: DeliveryStatus) => {
        var statusSettings = shipmentSettings.statusList?.[status];
        return statusSettings?.title ?? intl.formatMessage(shipmentMessages[status]);
    };

    const getHistoryStatusList = (allowLogistics: boolean, history?: IDeliveryHistory[]) => {
        let activeStatus = allowLogistics ? shipmentStatusState.statusLogisticActive : shipmentStatusState.statusActive;

        let items = Object.keys(activeStatus).map((statusKey, index) => {
            let status = statusKey as DeliveryStatus;
            const timesInterval = getStatusIntervalTimes(history);
            let interval = timesInterval[status];
            var statusSettings = activeStatus[status];

            let item: ShipmentStepItem = {
                icon: statusSettings?.icon ?? "",
                title: statusSettings?.title ?? "---",
                subTitle: interval ? getDateTimeLabel(interval.date) : "",
                smallTextLine: interval ? interval.timeInterval : "",
                textHelper: SHIPMENT_STATUS_TEXT_HELPER[status],
                status: status,
                disabled: statusSettings?.disabled,
            };
            return item;
        });
        return items;
    };

    const getNextShipmentStatus = (currentStatus: DeliveryStatus, allowLogisticService: boolean) => {
        let activeStatusObj = allowLogisticService ? shipmentStatusState.statusLogisticActive : shipmentStatusState.statusActive;
        let activeStatusList = Object.keys(activeStatusObj).map((x) => x as DeliveryStatus);

        if (!activeStatusObj[currentStatus]) {
            var validIndex = getClosestVisibleStatus(currentStatus, allowLogisticService);
            currentStatus = activeStatusList[validIndex];
        }
        if (currentStatus === "delivered") return "delivered";

        return activeStatusList[activeStatusList.indexOf(currentStatus) + 1];
    };

    return { getNextShipmentStatus, getClosestVisibleStatus, getHistoryStatusList, getStatusTitle };
};

interface ShipmentInterval {
    date: Date;
    timeInterval: string;
}

const getStatusIntervalTimes = (history?: IDeliveryHistory[]) => {
    let intervalList: { [key: string]: ShipmentInterval } = {};

    if (!history || history.length === 0) {
        return intervalList;
    }

    let beforeDate: Date | undefined;
    let historyReverse = [...history].reverse();

    historyReverse.forEach((element) => {
        intervalList[element.status] = {
            date: element.dateCreated,
            timeInterval: beforeDate ? getTimeInterval(beforeDate, element.dateCreated) : "",
        };
        beforeDate = element.dateCreated;
    });

    return intervalList;
};
