import React from "react";
import Select from "react-select";
import { SelectBaseOption } from "../../../api/models";
import { OrderItem } from "../../../api/models/order";
import { IOrderReturnItem } from "../../../api/models/orderReturn";
import { formatMoney } from "../../../utils";
import { getProductSkuAndCode, getProductThumbnailUrl } from "../../Products/helper";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { Flex, FlexImageStyle, SquareButton } from "../../_controls";
import { ProductSerialsContainer } from "../OrderItemRow/styled";
import { ImageContainer } from "../OrderStyles/RowStyles";
import { OrderProductCardView } from "./styled";

interface OrderProductCardProps {
    item: OrderItem;
    onClick: (value: any) => void;
    added: boolean;
}
interface OrderReturnItemCardProps {
    item: IOrderReturnItem;
    onChange: (value: IOrderReturnItem) => void;
    onRemove: (value: IOrderReturnItem) => void;
}

interface OrderReturnItemViewProps {
    item: IOrderReturnItem | OrderItem;
}

export default function OrderProductCard(props: OrderProductCardProps) {
    const { item } = props;
    const { product, productVariant } = item;

    const realQuantity = item.quantity - item.quantityReturned;

    return (
        <OrderProductCardView
            addBackgroundHidde={realQuantity === 0}
            className={`hover ${props.added ? "added" : ""}`}
            // onClick={() => realQuantity > 0 && props.onClick(item)}
            onClick={() => props.onClick(item)}
        >
            <FlexImageStyle fitCover width={50} borderRadius={10}>
                <img src={getProductThumbnailUrl(product, productVariant)} alt={product?.name} />
            </FlexImageStyle>
            <Flex column alignStart w100>
                <Flex spaceBetween gap10 w100 alignStart>
                    <span className="title">{product?.name}</span>
                    <span className="quantity-view">{item.quantity}</span>
                </Flex>
                <span className="text-small text-light">{getProductSkuAndCode(product, productVariant)}</span>
                <Flex gap10 flexWrap className="text-small">
                    {item.quantityReturned > 0 && (
                        <>
                            <span className="quantity-view">Ya se devolvió: {item.quantityReturned}</span>
                            <span className="quantity-view">Disponible: {realQuantity}</span>
                        </>
                    )}

                    <span className="">{formatMoney(item.unitPrice)}</span>
                    {item.manualPercentageDiscount > 0 && (
                        <>
                            <span className="">- {item.manualPercentageDiscount}% =</span>
                            <span className="">{formatMoney(item.unitPrice - (item.unitPrice * item.manualPercentageDiscount) / 100)}</span>
                        </>
                    )}
                </Flex>
                {item.serials && item.serials.length > 0 && (
                    <Flex column className="mt-1" gap5>
                        {item.serials.map((serial) => (
                            <Flex key={serial} className="text-light text-small text-bold">
                                {serial}
                            </Flex>
                        ))}
                    </Flex>
                )}
            </Flex>
        </OrderProductCardView>
    );
}

export function OrderReturnProductView(props: OrderReturnItemViewProps) {
    const { item } = props;
    const { product, productVariant } = item;
    return (
        <OrderProductCardView>
            <ImageContainer>
                <img src={getProductThumbnailUrl(product, productVariant)} alt={product?.name} />
            </ImageContainer>
            <div className="content">
                <span className="title">{product?.name}</span>
                <span>{getProductSkuAndCode(product, productVariant)}</span>
                <div className="flex">
                    <span className="quantity-view mr-1">{item.quantity}</span>
                    <span className="mr-1">{formatMoney(item.unitPrice)}</span>
                    {item.manualPercentageDiscount > 0 && (
                        <>
                            <span className="mr-1">- {item.manualPercentageDiscount}% =</span>
                            <span className="mr-1">
                                {formatMoney(item.unitPrice - (item.unitPrice * item.manualPercentageDiscount) / 100)}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </OrderProductCardView>
    );
}

export function OrderReturnItemCard(props: OrderReturnItemCardProps) {
    const { item } = props;
    const { product, productVariant } = item;

    const onIncreaseQuantity = (value: number) => {
        let newQuantity = item.quantity + value;

        if (newQuantity < 0) {
            return;
        }
        if (newQuantity > item.stockLimit) {
            return;
        }
        props.onChange({ ...item, quantity: newQuantity });
    };

    const onRemoveSerial = (serial: string) => {
        let serials = item.serials?.filter((x) => x !== serial) ?? [];
        props.onChange({ ...item, serials });
    };

    const onAddSerial = (serial?: string) => {
        if (!serial) return;
        let serials = item.serials ?? [];
        serials = [...serials, serial];
        props.onChange({ ...item, serials, quantity: serials.length });
    };

    const onUpdateNumber = (newQuantity: number) => {
        if (newQuantity < 0) {
            return;
        } else if (newQuantity > item.stockLimit) {
            return;
        }
        props.onChange({ ...item, quantity: newQuantity });
    };

    const getSelectOptionSerial = (item?: string) => {
        let option: SelectBaseOption = {
            id: item ?? "",
            value: item ?? "",
            label: item ?? "",
        };
        return option;
    };

    return (
        <OrderProductCardView className="return-item">
            <Flex column gap5 w100>
                <Flex gap10 alignStart>
                    <FlexImageStyle fitCover width={50} borderRadius={10}>
                        <img src={getProductThumbnailUrl(product, productVariant)} alt={product?.name} />
                    </FlexImageStyle>
                    <Flex column alignStart w100>
                        <Flex spaceBetween alignCenter gap5 w100>
                            <span className="title">{product?.name}</span>
                            {item.product?.includesSerials && <span>{item.serials?.length}</span>}
                        </Flex>
                        <span className="text-small text-light">{getProductSkuAndCode(product, productVariant)}</span>
                        <Flex gap5 alignCenter flexWrap className="text-small text-light">
                            {item.manualPercentageDiscount > 0 && (
                                <>
                                    <span>-{item.manualPercentageDiscount}% =</span>
                                    <span>{formatMoney(item.unitPrice - (item.unitPrice * item.manualPercentageDiscount) / 100)}</span>
                                </>
                            )}

                            <span>
                                {formatMoney(item.quantity * (item.unitPrice - (item.unitPrice * item.manualPercentageDiscount) / 100))}
                            </span>
                        </Flex>

                        {!item.product?.includesSerials && (
                            <Flex alignCenter gap10 spaceBetween w100 marginTop={5}>
                                <div className="flex-quantity">
                                    <SquareButton
                                        disabled={item.quantity === 1}
                                        onClick={() => onIncreaseQuantity(-1)}
                                        className="mr-1 small"
                                    >
                                        <span className="wahioicon-minus"></span>
                                    </SquareButton>

                                    <TextBoxNumeral value={item.quantity} onNumberChange={onUpdateNumber} format="number" />

                                    <SquareButton
                                        disabled={item.quantity === item.stockLimit}
                                        onClick={() => onIncreaseQuantity(1)}
                                        className="ml-1 small"
                                    >
                                        <span className="wahioicon-plus"></span>
                                    </SquareButton>
                                </div>
                                <SquareButton onClick={() => props.onRemove(item)} className="small">
                                    <span className="wahioicon-trash"></span>
                                </SquareButton>
                            </Flex>
                        )}

                        {item.product?.includesSerials && item.originalItem?.serials && (
                            <Flex alignCenter w100 spaceBetween>
                                <Flex column>
                                    <ProductSerialsContainer>
                                        {item.serials?.map((serial, index) => (
                                            <div className="serial-line" key={index}>
                                                <span className="index">{index + 1}</span>
                                                <span className="serial mr-1">{serial}</span>
                                                <SquareButton className="small" onClick={() => onRemoveSerial(serial)}>
                                                    <span className="wahioicon-trash"></span>
                                                </SquareButton>
                                            </div>
                                        ))}
                                    </ProductSerialsContainer>
                                    <SelectBody className="border-none">
                                        <Select
                                            placeholder="Seriales"
                                            className="select-min-len"
                                            classNamePrefix="select"
                                            key={item.serials?.length}
                                            defaultValue={undefined}
                                            isDisabled={false}
                                            onChange={(e) => onAddSerial(e?.value)}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="seller"
                                            options={item.originalItem?.serials
                                                ?.filter((x) => !item.serials?.includes(x))
                                                .map((item) => getSelectOptionSerial(item))}
                                        />
                                    </SelectBody>
                                </Flex>
                                <Flex>
                                    <SquareButton onClick={() => props.onRemove(item)} className="small">
                                        <span className="wahioicon-trash"></span>
                                    </SquareButton>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </OrderProductCardView>
    );
}
