import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import CreateOrderModal from "..";
import { getServiceMessageError } from "../../../api";
import { Contact } from "../../../api/models/contact";
import { DeliveryOrder } from "../../../api/models/deliveryOrder";
import { IOrderInvoiceCreateModel, Order } from "../../../api/models/order";
import { IWarehouse } from "../../../api/models/warehouse";
import { getContact } from "../../../api/orders/contactApi";
import { createOrderInvoice } from "../../../api/orders/orderApi";
import { getWarehouse } from "../../../api/products/warehouseApi";
import { getAuthBody } from "../../../auth";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex } from "../../_controls";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { QuoteActionContainer } from "./styled";

interface OrderQuoteViewProps {
    order: Order;
    onOrderUpdate: (order: Order) => void;
}

export default function OrderQuoteView(props: OrderQuoteViewProps) {
    const [showConfirmCreateSale, setShowConfirmCreateSale] = useState(false);
    const alert = useAlert();
    const { organizationState } = useContext(OrganizationContext);
    const [orderForEdit, setOrderForEdit] = useState<Order | undefined>(undefined);
    const { getWarehouseById } = useWarehouseContext();

    const [orderWarehouse, setOrderWarehouse] = useState<IWarehouse>();

    const contactMutation = useMutation((id: string) => getContact(id), {
        onSuccess: (contact) => handleCreateDeliveryOrder(contact),
    });

    useEffect(() => {
        handleSearchDefaultWarehouse(props.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    const warehouseMutation = useMutation((id: string) => getWarehouse(id), {
        onSuccess: (data) => {
            setOrderWarehouse(data);
        },
    });

    const invoiceMutation = useMutation((data: IOrderInvoiceCreateModel) => createOrderInvoice(data), {
        onSuccess: (orderResult) => {
            alert.success("La order fue actualizada correctamente");
            props.onOrderUpdate(orderResult);
        },
        onError: (data) => {
            alert.error(getServiceMessageError(data));
        },
    });

    const handleSearchDefaultWarehouse = (order?: Order) => {
        if (!order?.defaultWarehouse && order?.items && order.items.length > 0) {
            let warehouseIds = _.uniq(order.items.map((x) => x.warehouseId));

            if (warehouseIds.length === 1) {
                let id = warehouseIds[0];
                let warehouse = getWarehouseById(id);
                if (warehouse) {
                    setOrderWarehouse(warehouse);
                } else {
                    warehouseMutation.mutate(id);
                }
            }
        }
    };

    const handleCreateSale = () => {
        let model: IOrderInvoiceCreateModel = {
            orderId: props.order.id ?? "",
            accountUserId: getAuthBody().accountUserId,
            orderInvoiceNumerationId: organizationState.currentOrganization?.orderInvoiceNumerationId ?? "",
        };

        invoiceMutation.mutate(model);
    };

    const handleCreateDeliveryOrder = (contact: Contact) => {
        let deliveryOrder = new DeliveryOrder(getAuthBody().accountId, "");
        deliveryOrder.originAddress = organizationState.currentOrganization?.address;
        deliveryOrder.originState = organizationState.currentOrganization?.state;
        deliveryOrder.originCity = organizationState.currentOrganization?.city;
        if (contact.addresses && contact.addresses.length > 0) {
            let address = contact.addresses[0];
            deliveryOrder = { ...deliveryOrder, ...(address as any), id: undefined, address: address.description };
        }
        setOrderForEdit({ ...props.order, deliveryOrder, status: "credit", isDelivery: true, customer: contact });
    };

    return (
        <>
            {orderForEdit && (
                <CreateOrderModal
                    order={orderForEdit}
                    onCompleted={(order) => {
                        props.onOrderUpdate(order);
                        setOrderForEdit(undefined);
                        alert.success("La orden ha sido actualizada con éxito");
                    }}
                    show={!!orderForEdit}
                    setShow={() => setOrderForEdit(undefined)}
                />
            )}
            {showConfirmCreateSale && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmCreateSale(false)}
                    onOkay={handleCreateSale}
                    title="Convertir cotización en Venta"
                    description="¿Está seguro de que desea convertir la cotización en venta? la orden se facturará automáticamente"
                    show={showConfirmCreateSale}
                    setShow={setShowConfirmCreateSale}
                />
            )}
            <QuoteActionContainer>
                <h3 className="action-title">¿Que deseas hacer con la cotización?</h3>

                <Flex flexWrap gap10 alignCenter>
                    <DefaultButton rounded onClick={() => setOrderForEdit(props.order)}>
                        <i className="fa-regular fa-pen"></i>
                        Editar
                    </DefaultButton>
                    {warehouseMutation.isLoading && <LoadingDualRing className="mr-1" small />}
                    {orderWarehouse && (
                        <DefaultButton
                            disabled={warehouseMutation.data?.allowLogisticsService}
                            rounded
                            onClick={() => setShowConfirmCreateSale(true)}
                        >
                            <i className="fa-regular fa-bag-shopping"></i>
                            Convertir en Venta {invoiceMutation.isLoading && <LoadingDualRing className="ml-1" small />}
                        </DefaultButton>
                    )}
                    <DefaultButton rounded onClick={() => contactMutation.mutate(props.order.customerId ?? "invalid")}>
                        <i className="fa-regular fa-truck"></i>
                        Convertir en Pedido {contactMutation.isLoading && <LoadingDualRing className="ml-1" small />}
                    </DefaultButton>
                </Flex>
                {orderWarehouse?.allowLogisticsService && (
                    <p className="small-description">No se puede facturar una orden creada en una bodega con logística</p>
                )}
            </QuoteActionContainer>
        </>
    );
}
