import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IBasePermission } from "../../../api/models/accountUserPermissions";
import appMessages from "../../../messages";
import ToggleSwitch from "../../ToggleSwitch";
import { Flex, SquareButton } from "../../_controls";
import { FormGroup } from "../../_controls/forms/styled";
import CheckBox from "../../_controls/inputs/CheckBox";
import { PermissionCheckboxContainer } from "../styled";
import { PermissionRowStyle, PermissionKeysPreview } from "./styled";

interface PermissionCardProps {
    permission: IBasePermission;
    keyPermission: string;
    onActionChange: (e: any) => void;
    setAll: (key: string, value: boolean) => void;
}

export default function PermissionCard(props: PermissionCardProps) {
    const { permission, keyPermission, onActionChange } = props;
    const intl = useIntl();

    const [showActions, setShowActions] = useState(false);
    let action = (permission as any)[keyPermission];

    useEffect(() => {
        if (!action) {
            props.setAll(keyPermission, false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    if (!action) return null;

    const getMessage = (key: string) => {
        return (appMessages as any)[key] ? intl.formatMessage((appMessages as any)[key]) : key;
    };

    const allIsChecked = Object.values(action).every(Boolean);

    return (
        <PermissionRowStyle w100 column className="permission-row">
            <Flex w100 spaceBetween alignCenter gap10>
                <Flex alignCenter gap10 w100 overflowHidden>
                    {!showActions && (
                        <ToggleSwitch
                            name={`all`}
                            checked={allIsChecked}
                            onChecked={(value) => {
                                props.setAll(keyPermission, value);
                            }}
                        />
                    )}
                    <span className="permission-title">{getMessage(keyPermission)}</span>
                    {Object.keys(action).length === 0 && <span className="wahioicon-lock label"></span>}
                    <PermissionKeysPreview>
                        {Object.keys(action).map((key, index) => {
                            if (!action[key]) return null;
                            return (
                                <span className="key-item" key={index}>
                                    {getMessage(key)}
                                </span>
                            );
                        })}
                    </PermissionKeysPreview>
                </Flex>
                <SquareButton type="button" onClick={() => setShowActions(!showActions)} className="small">
                    <span className={!showActions ? "wahioicon-chevron-left" : "wahioicon-chevron-down"}></span>
                </SquareButton>
            </Flex>
            {showActions && (
                <PermissionCheckboxContainer className="mt-1">
                    <FormGroup bottomSpaceSmall>
                        <CheckBox
                            id={keyPermission}
                            name={`all`}
                            title={"Todo"}
                            className="checkbox-item"
                            checked={allIsChecked}
                            onChange={() => {
                                setShowActions(true);
                                props.setAll(keyPermission, !allIsChecked);
                            }}
                        />
                    </FormGroup>
                    {Object.keys(action).map((keyAction, index) => {
                        return (
                            <FormGroup bottomSpaceSmall key={index}>
                                <CheckBox
                                    id={"permission-checkbox-option-" + index}
                                    name={`${keyPermission}.${keyAction}`}
                                    title={getMessage(keyAction)}
                                    className="checkbox-item"
                                    checked={(action as any)[keyAction]}
                                    onChange={onActionChange}
                                />
                            </FormGroup>
                        );
                    })}
                </PermissionCheckboxContainer>
            )}
        </PermissionRowStyle>
    );
}
