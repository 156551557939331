import React, { useState, useRef, InputHTMLAttributes, useEffect } from "react";
import { DefaultInput } from ".";
import { getUniqueId } from "../../../utils";

interface IBoxProps extends InputHTMLAttributes<HTMLInputElement> {
    placeholder: string;
    milliseconds: number;
    initialValue?: string;
    rounded?: boolean;
    icon?: string;
    onChange: (e: any) => void;
}

export default function InputTimer(props: IBoxProps) {
    const [searchState, setSearchState] = useState({
        value: props.initialValue ? props.initialValue : "",
        waiting: false,
    });

    const [inputId] = useState(getUniqueId());
    const timeValue = useRef(setTimeout(() => {}, 10));

    useEffect(() => {
        let element: any = document.getElementById(inputId);
        if (element && props.initialValue) {
            element.value = props.initialValue;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function timeWaiting(e: any) {
        var target = { ...e.target, name: props.name };
        var value = target.value;

        clearTimeout(timeValue.current);
        timeValue.current = setTimeout(() => {
            setSearchState({ value, waiting: false });
            props.onChange({ target });
        }, props.milliseconds);
    }

    const onChangeHandle = (e: any) => {
        timeWaiting(e);
        if (!searchState.waiting) {
            setSearchState({ ...searchState, waiting: true });
        }
    };

    const onBlur = (e: any) => {
        if (props.onBlur) {
            props.onBlur(e.target.value);
        }
    };

    return <DefaultInput rounded={props.rounded} id={inputId} onBlur={onBlur} {...props} onChange={onChangeHandle} />;
}
