import React from "react";
import { ProductNavigationProfileProps } from ".";
import ProductLogList from "../ProductLogs/ProductLogList";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationLogs(props: ProductNavigationProfileProps) {
    return (
        <NavigationCard {...props} title={props.intl.formatMessage(messages.logs)} hideHeader>
            <ProductLogList pageSize={15} productId={props.product.id} />
        </NavigationCard>
    );
}
