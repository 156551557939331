import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { DeliveryStatus } from "../../../api/models/deliveryOrder";
import { Order } from "../../../api/models/order";
import { WarehouseProductException } from "../../../api/models/warehouseProduct";
import { DeliveryOrderStatusData, updateDeliveryOrderStatus } from "../../../api/orders/deliveryOrder";
import { useWahioSocket } from "../../../api/socket";
import { getAuthBody } from "../../../auth";
import { UserContext } from "../../../store/contexts/UserContext";

interface useDeliveryOrderStatusProps {
    onOrderChange: (order: Order) => void;
    onError: (error: any) => void;
}

export const useWarehouseProductException = () => {
    const [errorList, setErrorList] = useState<WarehouseProductException[]>([]);
    const [showModalErrors, setShowModalErrors] = useState(false);

    const checkError = (error: any) => {
        var response = error?.response;
        if (response && response.data && response.data.code === "WAREHOUSE_PRODUCT_EXCEPTION_LIST") {
            setErrorList(response.data.object);
            setShowModalErrors(true);
        }
    };

    return { errorList, checkError, showModalErrors, setShowModalErrors };
};

export const useDeliveryOrderStatus = (props: useDeliveryOrderStatusProps) => {
    const wahioSocket = useWahioSocket();
    const { userState } = useContext(UserContext);

    const [errorList, setErrorList] = useState<WarehouseProductException[]>([]);
    const [showModalErrors, setShowModalErrors] = useState(false);

    const deliveryStatusMutation = useMutation((data: DeliveryOrderStatusData) => updateDeliveryOrderStatus(data), {
        onError: (error: any) => {
            var response = error?.response;
            if (response && response.data && response.data.code === "WAREHOUSE_PRODUCT_EXCEPTION_LIST") {
                setErrorList(response.data.object);
                setShowModalErrors(true);
            }
            props.onError(error);
        },
    });

    const handleUpdateShipmentStatus = (deliveryOrderId: string, destinationStatus: DeliveryStatus, sourceStatus: DeliveryStatus) => {
        deliveryStatusMutation.mutateAsync({ deliveryOrderId, newStatus: destinationStatus }).then((data) => {
            wahioSocket.sendOrderBoardEvent({
                source: sourceStatus,
                destination: destinationStatus,
                viewId: "ShipmentView",
                orderId: data.id ?? "NA",
                userName: userState.user?.firstName ?? "",
                userImage: userState.user?.image ?? "",
                accountId: getAuthBody().accountId,
            });
            props.onOrderChange(data);
        });
    };

    return {
        deliveryStatusMutation,
        isLoading: deliveryStatusMutation.isLoading,
        handleUpdateShipmentStatus,
        errorList,
        showModalErrors,
        setShowModalErrors,
    };
};
