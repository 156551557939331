import styled from "styled-components";

export const OrderItemLogsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    ::before {
        content: " ";
        border-left: 1px solid ${(props) => props.theme.palette.backgroundDark};
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 15px;
        top: 0;
    }

    .date-point {
        background: ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        width: 11px;
        height: 11px;
        margin-left: 10px;
    }

    .card-group {
        background: ${(props) => props.theme.palette.backgroundLight};
        z-index: 1;
        border-radius: 15px;
    }
`;
export const OrderItemLogCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .card-title {
        font-size: 14px;
    }
    .date-text {
        color: ${(props) => props.theme.palette.textLight};
    }
    .quantity {
        font-weight: 500;
        padding: 5px;
        border-radius: 8px;

        &.add {
            color: ${(props) => props.theme.palette.primary};
            background: ${(props) => props.theme.palette.primary}20;
        }
        &.remove {
            color: ${(props) => props.theme.palette.dangerColor};
            background: ${(props) => props.theme.palette.dangerColor}20;
        }
    }

    .card-body {
        padding: 10px;       
    }
`;
