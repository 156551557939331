import styled from "styled-components";

export const MainContainer = styled.div`
    padding: 30px;
    gap: 10px;

    .confirmation-title { 
        font-size: 18px;
        margin: 0;
    }
    .input-comment {
        width: 100%;
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        background: transparent;
    }
`;
