import { pick } from "lodash";
import { USER_SELLER_PERMISSION_LIST } from "../../components/Account/Permissions/PermissionForm";
import { MessageStatusItem } from "../../components/Chats/models";
import { AccountBilling, AccountBillingBase, BillingCycle, BillingInvoice } from "./accountBilling";
import { BasePermissionLoaded, IAccountPermission, IBasePermission } from "./accountUserPermissions";
import { Contact } from "./contact";
import { IOrganization } from "./organizationTypes";
import { WompiPublicData } from "./wompi";

export type CompanyImageTypes = "cover" | "profile";
export type AccountUserType = "user" | "seller";
export type AccountStatus =
    | "disabled"
    | "active"
    | "suspended"
    | "deleted"
    | "annulled"
    | "paymentRequired"
    | "trial"
    | "cancelled"
    | "newContractRequired";

export type CommerceType = "restaurant" | "bar" | "kiosk" | "cart";
export const COMMERCE_TYPE_LIST: CommerceType[] = ["restaurant", "bar", "kiosk", "cart"];

export const ACCOUNT_ACTIVE_STATUS: AccountStatus[] = ["active", "trial", "newContractRequired"];

export const getAccountUserFullName = (user?: AccountUser | Contact) => {
    if (user) {
        return `${user.firstName} ${user.lastName}`;
    }
    return "";
};

export interface Account {
    id: string;
    dateCreated: Date | string;
    balanceUpdatedAt: string;
    balance: number;
    userLicenses: number;
    sellerLicenses: number;
    activeUsers: number;
    includesTrial: boolean;
    trialExpirationDate: string;
    allowEcommerceService: boolean;
    allowDigitalInvoiceService: boolean;
    ecommerceOrganizationId: string;
    companyNit?: string;
    companyName: string;
    companyType: string;
    companyRangeEmployees: string;
    companyState: string;
    companyCity: string;
    companyAddress: string;
    companyNumberPhone: string;
    companyEmail: string;
    companyProfileImage: string;
    companyCoverImage: string;
    accountOwnerId: string;
    clientSettings: AccountClientSettings;
    billing?: AccountBilling;
    status: AccountStatus;
    lastStatusDescription: string;
    plan: AccountPlan;
    organizations: IOrganization[];
    users: AccountUser[];
    defaultPaymentMethodId?: string;
    associatedAccounts?: AssociatedAccount[];
    stripeCustomerId?: string;
    stripeSetupIntentId?: string;
}

export type AccountPlanBase = "basic" | "standard" | "premium";
export type AccountPlan = "free" | AccountPlanBase;

export interface AssociatedAccount {
    id: string;
    parentAccountId: string;
    childAccountId: string;
    childAccount?: Account;
    createdAt?: string;
}

export interface AccountUpdateInput {
    accountId: string;
    costPerUser: number;
    currency: string;
    allowLogisticsService: boolean;
    allowEcommerceService: boolean;
    allowDigitalInvoiceService: boolean;
    allowRestaurantService: boolean;
    companyName: string;
    companyProfileImage: string;
    companyCoverImage: string;
    ecommerceOrganizationId: string;
    status: string;
    lastStatusDescription: string;
}

export interface AccountUser {
    id: string;
    accountId: string;
    account?: Account;
    image?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    phoneConfirmed: boolean;
    phonePrefix: string;
    permission: IBasePermission;
    accountPermissionId?: string;
    accountPermission?: IAccountPermission;
    password?: string;
    isOnline?: boolean; //UI ONLY
    messageStateItem?: MessageStatusItem; // UI ONLY
    color?: string; // UI ONLY
    dateCreated?: Date;
    status: AccountUserStatus;
    quickStart?: AccountUserQuickStart;
    type?: AccountUserType;
}

export interface AccountCancelled {
    id: string;
    accountId: string;
    accountUserId: string;
    cancelationReason: string;
    cancelationReasonIndex: number; //JUST FOR UI
    comment: string;
    createdAt: string;
}

export interface AccountUserQuickStart {
    updatedAt: Date | string;
    show: boolean;
    hidePermanently: boolean;
    options?: QuickStartOption[];
}

export interface QuickStartOption {
    name: string;
    isComplete: boolean;
}

export interface AccountRecoverRequestInput {
    accountId: string;
    accountUserId: string;
}

export type AccountUserStatus = "pending" | "active" | "suspended" | "disabled" | "invalid";

export const accountUserIsAdmin = (user?: AccountUser) => {
    if (user && user.account) {
        return user.id === user.account.accountOwnerId;
    } else {
        return false;
    }
};

export interface AccountUserAndOrganizations {
    accountUser: AccountUser;
    organizations: IOrganization[];
}

export interface ClientSettingsMutationInput {
    accountId: string;
    body: ClientSettingsPartialInput;
}
export interface ClientSettingsPartialInput {
    shipmentSettings?: ShipmentSettings;
    priceSettings?: PriceSettings;
}

export interface AccountClientSettings {
    id?: string;
    accountId: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    price?: string;
    price2?: string;
    price3?: string;
    price4?: string;
    price5?: string;
    price6?: string;
    price7?: string;
    price8?: string;
    price9?: string;
    price10?: string;
    defaultPaymentTermsDays: number;
    enabledRestaurant: boolean;
    commerceType?: CommerceType;
    defaultColorsAsString?: string;
    defaultColors?: ClientSettingColor[];
    countryCode?: string;
    cityName?: string;
    stateName?: string;
    timezone?: string;
    currency?: string;
    currencySymbol?: string;
    voluntaryTip: boolean;
    voluntaryTipPercentage?: number;
    shipmentSettings?: ShipmentSettings;
    priceSettings?: PriceSettings;
}

export interface PriceSettings {
    priceList?: { [key: string]: PriceSettingValue };
}

export interface PriceSettingValue {
    name: string;
}

export interface ShipmentSettings {
    statusList?: { [key: string]: ShipmentStatusSetting };
}

export interface ShipmentStatusSetting {
    disabled?: boolean;
    title?: string;
    icon?: string;
}

export interface ClientSettingColor {
    name: string;
    value: string;
}

export class AccountClientSettingsImpl implements AccountClientSettings {
    id?: string | undefined;
    accountId: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    price?: string;
    price2?: string | undefined;
    price3?: string | undefined;
    price4?: string | undefined;
    price5?: string | undefined;
    price6?: string | undefined;
    price7?: string | undefined;
    price8?: string | undefined;
    price9?: string | undefined;
    price10?: string | undefined;
    defaultPaymentTermsDays: number = 0;
    voluntaryTip: boolean = false;
    voluntaryTipPercentage?: number = 0;

    defaultColorsAsString?: string;
    defaultColors?: ClientSettingColor[];

    enabledRestaurant: boolean = false;
    commerceType?: CommerceType = "restaurant";

    countryCode?: string;
    cityName?: string;
    stateName?: string;
    timezone?: string;
    currency?: string;
    currencySymbol?: string;
    shipmentSettings?: ShipmentSettings;
    priceSettings?: PriceSettings;

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export const checkUserIsOwner = (id: string, user?: AccountUser) => {
    return user?.account?.accountOwnerId === id;
};

export const getUserPermissions = (user?: AccountUser): IBasePermission => {
    if (user?.account?.accountOwnerId === user?.id) {
        return new BasePermissionLoaded(true);
    }

    let basePermission: IBasePermission;
    if (user && user.accountPermission?.permission) {
        basePermission = user.accountPermission.permission;
    } else {
        basePermission = user?.permission ?? new BasePermissionLoaded(false);
    }

    if (user?.type === "seller") {
        basePermission = pick(basePermission, USER_SELLER_PERMISSION_LIST);
    }

    return basePermission;
};

export class AccountUserImpl implements AccountUser {
    id: string = "";
    accountId: string = "";
    account?: Account;
    image?: string;
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    emailConfirmed: boolean = false;
    phoneConfirmed: boolean = false;
    phoneNumber: string = "";
    phonePrefix: string = "";
    password?: string;
    permission: IBasePermission = new BasePermissionLoaded(false);
    accountPermission?: IAccountPermission;
    accountPermissionId?: string;
    status: AccountUserStatus = "active";

    constructor(accountId?: string) {
        this.accountId = accountId ?? "";
    }
}

export type PaymentMethodSource = "wompi" | "stripe";

export interface AccountPaymentMethod {
    id: string;
    accountId: string;
    sourceId: string;
    source: PaymentMethodSource;
    type: string;
    token: string;
    status: string;
    createdAt: string | Date;
    publicData?: WompiPublicData;
    brand: string;
    expMonth: string;
    expYear: string;
    last4: string;
}

export interface AccountCreateContractInput extends AccountBillingBase {
    plan: AccountPlan;
    accountPaymentMethod: AccountPaymentMethod;
    paymentAmount: number;
    paymentMethod: string;
    paymentSource: string;
    paymentSourceTransactionId: string;
    userLicenses: number;
    usersIdsDisabled: string[];
}

export interface AccountAddNewLicensesInput {
    accountId: string;
    newLicenses: number;
    paymentAmount: number;
    paymentMethod: string;
    paymentSource: string;
    paymentSourceTransactionId: string;
    userType: AccountUserType;
}

export interface AccountUpdatePlanInput {
    licenses: number;
    costPerUser: number;
    costPerBilledDays: number;
    accountId: string;
    billedDays: number;
    paymentAmount: number;
    newPlan: AccountPlan;
    cycle: BillingCycle;
    paymentMethod: string;
    paymentSource: string;
    paymentSourceTransactionId: string;
}

export interface AccountBillingNextDateInput {
    accountId: string;
    newCostPerUser: number;
}

export interface AccountBillingNextDateResponse {
    nextDate: string;
    currentDate: string;
    initialDate: string;
    billedDays: number;
    totalDays: number;
    costPerBilledDays: number;
    costPerBilledDaysSeller: number;
}

export interface BillingDueInvoices {
    minDueDate: string;
    daysLeft: number;
    dueInvoices: BillingInvoice[];
}

export interface AccountInvoiceResponse {
    account: Account;
    invoice: BillingInvoice;
}

export interface AccountCreateContractResult {
    account: Account;
    invoice: BillingInvoice;
    accountPaymentMethod: AccountPaymentMethod;
}

export interface ISocialPayload {
    providerName: string;
    tokenId: string;
    email: string;
}

export interface ISignData {
    value: string;
    password: string;
}

export interface IAuthObjectBody {
    accountUser: AccountUser;
}

export interface AccountApi {
    id: string;
    accountId: string;
    apiKey: string;
    createdAt: string;
    rotatedAd: string | null;
}
