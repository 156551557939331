import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { IProduct, ProductImpl } from "../../../api/models/product";
import { createUpdateProduct } from "../../../api/products/productsApi";
import { getAuthBody } from "../../../auth";
import { UserContext } from "../../../store/contexts/UserContext";
import { stringIsNullOrEmpty } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import PermissionDenied from "../../PermissionDenied";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../../_controls";
import { ImageFileDefault } from "../../_controls/InputSelectableImage";
import WahioTooltip from "../../_controls/WahioTooltip";
import CheckBox from "../../_controls/inputs/CheckBox";
import ComponentsForm from "./ComponentsForm";
import CreationTabs, { ProductCreationStage } from "./CreationTabs";
import DetailsForm from "./DetailsForm";
import MoreDetailsForm from "./MoreDetailsForm";
import PricesForm from "./PricesForm";
import ProductDescription from "./ProductDescriptionForm";
import ProductOptionalsForm from "./ProductOptionanls/ProductOptionalsForm";
import ProductVariantsForm from "./ProductVariantsForm";
import { loadExistingProduct } from "./helpers";
import messages from "./messages";
import { MainContainer } from "./styled";

export interface ProductCreateFormProps {
    onCreate: (value: IProduct) => void;
    onCancel: () => void;
    updateProduct?: IProduct;
    onVariantStage?: (isActive: boolean) => void;
    onFinalizeFunction?: (value: IProduct) => void;
}

export interface ProductCreationProps {
    product: IProduct;
    onChange: (value: IProduct) => void;
}
interface ICurrentStage extends ProductCreationProps {
    stage: ProductCreationStage;
}
const CurrentStateView = (props: ICurrentStage) => {
    const getShow = (stage: ProductCreationStage) => {
        return stage === props.stage ? "show" : "hidde";
    };
    return (
        <>
            <Flex w100 className={getShow(ProductCreationStage.Detail)}>
                <DetailsForm {...props} />
            </Flex>
            {props.product.includesOptionals && (
                <Flex w100 className={getShow(ProductCreationStage.Optionals)}>
                    <ProductOptionalsForm {...props} />
                </Flex>
            )}
            {props.product.includesVariants && (
                <Flex w100 className={getShow(ProductCreationStage.Variants)}>
                    <ProductVariantsForm {...props} />
                </Flex>
            )}
            {props.product.isParent && (
                <Flex w100 className={getShow(ProductCreationStage.Components)}>
                    <ComponentsForm {...props} />
                </Flex>
            )}
            {!props.product.includesVariants && (
                <Flex w100 className={getShow(ProductCreationStage.Prices)}>
                    <PricesForm {...props} />
                </Flex>
            )}
            <Flex w100 className={getShow(ProductCreationStage.More)}>
                <MoreDetailsForm {...props} />
            </Flex>
            <Flex w100 className={getShow(ProductCreationStage.Description)}>
                <ProductDescription {...props} />
            </Flex>
        </>
    );
};
//const initListImages: IImageFile[] = [];
const initMainImage = new ImageFileDefault("image-input-0");

const initialOptions = () => {
    return {
        mainImage: initMainImage,
        currentStage: ProductCreationStage.Detail,
        createProduct: new ProductImpl(""),
        missingDataActive: false,
    };
};

export default function ProductCreateForm(props: ProductCreateFormProps) {
    const intl = useIntl();
    const alert = useAlert();
    const { userState } = useContext(UserContext);
    const [currentProduct, setCurrentProduct] = useState(
        props.updateProduct ? loadExistingProduct(props.updateProduct) : new ProductImpl(getAuthBody().accountId)
    );
    const [selectNewProduct, setSelectNewProduct] = useState(false);
    const [options, setOptions] = useState(initialOptions());

    const updateProductMutation = useMutation((product: IProduct) => createUpdateProduct(product), {
        onSuccess: (product) => {
            alert.success(intl.formatMessage(messages.theProductWasSuccessfullyUpdated));

            if (selectNewProduct) {
                setCurrentProduct(new ProductImpl(getAuthBody().accountId));
                setOptions(initialOptions());
            } else props.onCreate(product);
            if (props.onFinalizeFunction) {
                props.onFinalizeFunction(product);
            }
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        if (props.onVariantStage) {
            props.onVariantStage(options.currentStage === ProductCreationStage.Variants);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.currentStage]);

    const setCurrentStage = (stage: ProductCreationStage) => {
        setOptions({ ...options, currentStage: stage });
    };

    const onCreateValue = async () => {
        if (isMissingData()) {
            setOptions({ ...options, missingDataActive: true });
            return;
        }

        let copyCurrentProduct = { ...currentProduct };
        if (copyCurrentProduct.isParent === false) {
            copyCurrentProduct.childs = [];
        }

        updateProductMutation.mutate(copyCurrentProduct);
    };

    const isMissingData = () => {
        return (
            stringIsNullOrEmpty(currentProduct.name) ||
            !currentProduct.customCategory ||
            stringIsNullOrEmpty(currentProduct.customCategory.id)
        );
    };

    if (currentProduct.id && !getUserPermissions(userState.user).product?.update) {
        return <PermissionDenied message={intl.formatMessage(messages.youDoNotHavePermissionsToEditProducts)} />;
    }

    if (!currentProduct.id && !getUserPermissions(userState.user).product?.create) {
        return <PermissionDenied message={intl.formatMessage(messages.youDoNotHavePermissionsToCreateProducts)} />;
    }

    return (
        <MainContainer>
            <TextField bold fontSize={17}>
                {currentProduct.id ? "Editar Producto" : "Crear Producto"}
            </TextField>
            <CreationTabs product={currentProduct} onChange={(value) => setCurrentStage(value.key)} />
            <Flex>
                <CurrentStateView product={currentProduct} stage={options.currentStage} onChange={(value) => setCurrentProduct(value)} />
            </Flex>

            {options.missingDataActive && (
                <div>
                    <span className="required-data">{intl.formatMessage(messages.missingData)}</span>
                </div>
            )}
            <Flex justifyEnd gap15 className="mt-2 mb-1" paddingTop={10} w100>
                <Flex spaceBetween alignCenter w100={!props.updateProduct} flexWrap gap15>
                    {!props.updateProduct && (
                        <Flex alignCenter gap10>
                            <WahioTooltip
                                timeout={1000}
                                width={300}
                                trigger={
                                    <CheckBox
                                        title={intl.formatMessage(messages.createAnotherProduct)}
                                        id="create-new-product"
                                        name="nuevo producto"
                                        checked={selectNewProduct}
                                        onChange={() => setSelectNewProduct(!selectNewProduct)}
                                    />
                                }
                                small
                                text={intl.formatMessage(
                                    messages.thisOptionAllowsYouCreateAnotherProductWhenYouFinishCreatingTheCurrentProduct
                                )}
                            />
                        </Flex>
                    )}
                    <Flex alignCenter gap15>
                        <DefaultButton
                            borderRadius={10}
                            bgLight
                            disabled={updateProductMutation.isLoading}
                            className="text-light"
                            onClick={() => props.onCancel()}
                        >
                            <FormattedMessage {...messages.cancel} />
                        </DefaultButton>
                        <PrimaryButton borderRadius={10} disabled={updateProductMutation.isLoading} onClick={onCreateValue}>
                            {props.updateProduct ? (
                                <FormattedMessage {...messages.editProduct} />
                            ) : (
                                <FormattedMessage {...messages.createProduct} />
                            )}{" "}
                            {updateProductMutation.isLoading && <LoadingDualRing small />}
                        </PrimaryButton>
                    </Flex>
                </Flex>
            </Flex>
        </MainContainer>
    );
}
