import React, { useContext } from "react";
import { InvoiceDesignCard, CardBodyNormal, FlexColumn, InvoiceTableNormal } from "../styled";
import { formatMoney, getDateFormatll } from "../../../utils";
import OrderProfileMessages from "../../Orders/OrderProfile/messages";
import translate from "../../../i18n/translate";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { getAccountUserFullName } from "../../../api/models/accountUser";
import moment from "moment";
import { IInvoiceFormatPurchaseProps } from ".";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";

export default function InvoiceFormatNormal(props: IInvoiceFormatPurchaseProps) {
    const { order, template } = props;
    const { accountUserState } = useContext(AccountUserContext);
    const { organizationState } = useContext(OrganizationContext);
    const organization = organizationState.currentOrganization;

    const customer = order.supplier;

    const getCustomer = () => {
        if (customer) {
            return customer.firstName + " " + customer.lastName;
        }
        return "General";
    };

    const isPartial = order.status === "partial";
    const needPayment = order.totalAmount - order.totalPaid > 0;

    return (
        <InvoiceDesignCard>
            <CardBodyNormal>
                <InvoiceTableNormal className="header-space" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <td className="not-padding">
                                <div className="header-space"> </div>
                            </td>
                        </tr>
                    </thead>
                    <thead className="table-header">
                        <tr>
                            <th>#</th>
                            <th>Sku</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Recibido</th>
                            <th>Precio</th>
                            <th>Desc</th>
                            <th className="right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items &&
                            order.items.map((item, index) => {
                                if (item.product) {
                                    return (
                                        <tr key={index}>
                                            <td className="td-center">{index + 1}</td>
                                            <td>{item.product.sku ? item.product.sku : "---"}</td>
                                            <td>{item.product.name}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.quantityReceived}</td>
                                            <td>{formatMoney(item.unitPrice)}</td>
                                            <td>{item.manualPercentageDiscount}</td>
                                            <td className="right">{formatMoney(item.unitPrice * item.quantity)}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                    </tbody>
                    <tfoot className="footer-space">
                        <tr>
                            <td className="not-padding">

                            </td>
                        </tr>
                    </tfoot>
                </InvoiceTableNormal>

                <div className="header">
                    <div className="grid-header">
                        {props.image && (
                            <div className="img-container">
                                <img src={props.image} alt="Org" />
                            </div>
                        )}
                        <div className="border flexrow min-width">
                            <div className="FlexCol">
                                {template.invoiceTemplateTitle1 && <span className="bold">{template?.invoiceTemplateTitle1}</span>}
                                <span className="bold">Compra #{order.number}</span>
                            </div>
                            <div className="FlexCol ml-2">
                                <span>Estado</span>
                                <span className={needPayment ? "status-card" : ""}>
                                    {translate(OrderProfileMessages[order.status]).props.defaultMessage}
                                </span>
                            </div>
                            {isPartial && (
                                <div className="FlexCol ml-2">
                                    <span>Pagos: {formatMoney(order.totalPaid)}</span>
                                    <span>Saldo: {formatMoney(order.totalAmount - order.totalPaid)}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="grid3 mt-1 ">
                        <div className="border card FlexCol">
                            <span className="bold">Proveedor</span>
                            <span>{getCustomer()}</span>
                            <span>Télefono {customer?.phoneNumber ? customer.phoneNumber : " - - -"}</span>
                            {customer?.address && <span>Dirección: {customer.address}</span>}
                        </div>
                        <div className="border card FlexCol  space-between">
                            <div className="FlexCol">
                                <span className="bold">Lugar y Fecha de expedición</span>
                                {order.dateCreatedCustom
                                    ? <span>Fecha: {getDateFormatll(order.dateCreatedCustom)}</span>
                                    : <span>Creado: {getDateFormatll(order.dateCreated)} </span>
                                }
                                <span>
                                    Creado por: {getAccountUserFullName(accountUserState.items.find((x) => x.id === order.accountUserId))}
                                </span>
                            </div>
                        </div>
                        <div className="border card FlexCol  space-between">
                            {order.status !== "paid" &&
                                <>
                                    <div className="FlexCol">
                                        <span className="bold">Vencimiento</span>
                                        <span>Término: {order.paymentTermsDays} Días</span>
                                        <span>Vence: {moment(order.dateCreated).add(order.paymentTermsDays, "days").format("LL")}</span>
                                    </div>
                                    <div className="FlexCol">
                                        <hr />
                                        <span>Condición:</span>
                                    </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <section className="grid3 mt-1">
                        <div className="border card FlexCol ">
                            <span className="bold">Almacen:</span>
                            <span>{order.warehouse?.name}</span>
                            <span className="bold">Cantidad:</span>
                            <span>Total Cantidad: {order.totalQuantity}</span>
                            <span>Total Recibido: {order.totalQuantityReceived}</span>
                        </div>
                        <div className="border card FlexCol ">
                            {(template.invoiceTemplateTitle2 || template.invoiceTemplateTitle3) && (
                                <>
                                    {template.invoiceTemplateTitle2 && (
                                        <span className="mb-1 all-text">{template.invoiceTemplateTitle2}</span>
                                    )}
                                    <span className="all-text">{template.invoiceTemplateTitle3}</span>
                                </>
                            )}
                        </div>
                        <div className="border  card FlexCol ">
                            {order.moneyReceived > 0 && (
                                <FlexColumn className="invoice-details">
                                    <span>Cantidad recibida: {formatMoney(order.moneyReceived)}</span>
                                    <span className="bold">Cambio: {formatMoney(order.moneyReturned)}</span>
                                </FlexColumn>
                            )}
                            <table className="total-table">
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>{formatMoney(order.subTotal)}</td>
                                    </tr>
                                    <tr>
                                        <td>Impuestos</td>
                                        <td>{formatMoney(order.totalTaxItems)}</td>
                                    </tr>
                                    <tr>
                                        <td>Retenciones</td>
                                        <td>{formatMoney(order.taxWithholdingValue)}</td>
                                    </tr>
                                    <tr>
                                        <td>Descuento</td>
                                        <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                                    </tr>
                                    <tr>
                                        <td className="bold">TOTAL</td>
                                        <td className="bold">{formatMoney(order.totalAmount)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <div className="border observation  FlexCol">
                        <span>Observaciones:</span>
                    </div>
                    <section className="sign-footer">
                        <div className="sign-space">
                            <br />
                            <br />
                            <hr />
                            <span>{organization?.name}, Firma y Sello</span>
                        </div>
                        <div className="sign-space">
                            <br />
                            <br />
                            <hr />
                            <span>Cliente, Firma y Sello</span>
                        </div>
                    </section>
                    <div className="footer-subtitle">Software By Wahio, suite.wahio.com</div>
                </div>
            </CardBodyNormal>
        </InvoiceDesignCard>
    );
}
