import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { getServiceMessageError } from '../../api';
import { postCreateUpdateWahioFolder } from '../../api/account/wahioCloudApi';
import { WahioCloudFolder, WahioCloudFolderImpl } from '../../api/models/wahioCloud';
import { CloudImages } from '../../assets';
import { getAuthBody } from '../../auth';
import { useAlert } from '../Alerts/Alert';
import LoadingDualRing from '../LoadingDualRing';
import { DefaultButton, DefaultInput, PrimaryButton } from '../_controls';
import { Flex, FlexImageStyle } from '../_controls/containers/index';
import Modal from '../modals/Modal';
import messages from './messages';

interface WahioCloudFolderContentProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    folderUpdate?: WahioCloudFolder;
    folderParent?: WahioCloudFolder;
    onCreate: () => void;
}

export const WahioCloudCreateFolderModal = (props: WahioCloudFolderContentProps) => {
    const intl = useIntl();
    const alert = useAlert();

    const [wahioFolderState, setWahioFolderState] = useState(
        props.folderUpdate ? props.folderUpdate : new WahioCloudFolderImpl(getAuthBody().accountId)
    );

    const createFolderMutation = useMutation((folder: WahioCloudFolder) => postCreateUpdateWahioFolder(folder), {
        onSuccess: (data) => {
            alert.success("Carpeta creada con éxito")
            props.onCreate()
            props.setShowModal(false);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error))
        },
    });

    const onSubmit = () => {
        if (wahioFolderState.name.length === 0) return;
        let newFolder = { ...wahioFolderState };
        if (props.folderParent) {
            newFolder.folderParentId = props.folderParent.id;
        }
        createFolderMutation.mutate(newFolder);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWahioFolderState({ ...wahioFolderState, [e.target.name]: e.target.value });
    };

    return (
        <Modal
            useButtonClose
            show={props.showModal}
            setShow={props.setShowModal}
        >
            <Flex column gap15 padding={20}>
                <FlexImageStyle width={60}>
                    <img src={CloudImages.openFolder} alt="Imagen de la carpeta" />
                </FlexImageStyle>

                <DefaultInput
                    name="name"
                    value={wahioFolderState.name}
                    onChange={handleChange}
                    type="text"
                    placeholder={intl.formatMessage(messages.name)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    autoFocus
                />

                <Flex gap10 justifyEnd marginTop={10}>
                    <DefaultButton borderRadius={10} onClick={() => props.setShowModal(false)}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton borderRadius={10} disabled={!wahioFolderState.name} onClick={() => onSubmit()}>
                        {props.folderUpdate ? "Editar" : "Crear"}
                        {createFolderMutation.isLoading && <LoadingDualRing small />}
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
}
