import { defineMessages } from "react-intl";

const base = "components.taxForm";

export default defineMessages({
    required: {
        id: `${base}.required`,
        defaultMessage: "Campo requerido",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre del impuesto",
    },
    description: {
        id: `${base}.description`,
        defaultMessage: "Descripción",
    },
    value: {
        id: `${base}.value`,
        defaultMessage: "del impuesto",
    },
    tributeType: {
        id: `${base}.tributeType`,
        defaultMessage: "Tipo de Impuesto",
    },
    createTax: {
        id: `${base}.createTax`,
        defaultMessage: "Crear Impuesto",
    },
    editTax: {
        id: `${base}.editTax`,
        defaultMessage: "Editar Impuesto",
    },
    percentajeType: {
        id: `${base}.percentajeType`,
        defaultMessage: "Porcentaje",
    },
    valueType: {
        id: `${base}.valueType`,
        defaultMessage: "Valor",
    },
    errorWhenTryingCreateTax:{
        id: `${base}.errorWhenTryingCreateTax`,
        defaultMessage: "Error al intentar crear el impuesto",
    },

});
