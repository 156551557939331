import styled from "styled-components";
import { Flex } from "../_controls";

export const CoreSettingsContainer = styled.div`
    .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .icon {
            margin-right: 15px;
            width: 30px;
            height: 30px;
            background: ${(props) => props.theme.palette.backgroundDark};
            text-align: center;
            border-radius: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .active {
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }
    }
`;

export const FlexInputAddContainer = styled(Flex)`
    width: 130px;
    align-items: center;
    .content-move {
        width: 0px;
        min-width: 0px;
        font-size: 0;
        padding: 0;
        border: 0;
        transition: all 0.2s;
    }
    &:hover {
        .content-move {
            width: 25px;
            min-width: 25px;
            font-size: 13px;
            padding: 0;
            border: 0;
            margin-left: 5px;
        }
    }
`;

export const FlexAddItem = styled.div`
    display: flex;
    background: ${(props) => props.theme.palette.background};
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    .item-name {
        width: 100%;
        max-width: 100px;
        border-radius: 0;
        background: transparent;
        padding: 6px 10px;
    }
    .item-container {
        border-radius: 0px;
        overflow: hidden;
        display: flex;
        width: 35px;
        height: 100%;
    }
    .color-value {
        -webkit-appearance: none;
        border: none;
        width: 100%;
        height: 31px;
        padding: 0;

        ::-webkit-color-swatch-wrapper {
            padding: 0;
        }
        ::-webkit-color-swatch {
            border: none;
        }
    }
`;

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    .active {
        color: ${(props) => props.theme.palette.primary};
        border-color: ${(props) => props.theme.palette.primary};
    }
    .title {
        font-size: 17.5px;
        font-weight: 500;
    }
`;
