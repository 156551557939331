import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CreateOrderModal from "..";
import { getServiceMessageError } from "../../../api";
import { Order } from "../../../api/models/order";
import { getOrder } from "../../../api/orders/orderApi";
import { useAlert } from "../../Alerts/Alert";
import { SquareButton } from "../../_controls";
import LoadingDualRing from "../../LoadingDualRing";

interface ButtonEditOrderProps {
    order: Order;
    onChange: (order: Order) => void;
}

export default function ButtonEditOrder(props: ButtonEditOrderProps) {
    const [showEditOrder, setShowEditOrder] = useState(false);
    const [order, setOrder] = useState(props.order);

    const alert = useAlert();

    useEffect(() => {
        setOrder(props.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    const orderMutation = useMutation((orderId: string) => getOrder(orderId), {
        onSuccess: (order) => {
            order.isDelivery = !!order.deliveryOrder;
            setOrder(order);
            setShowEditOrder(true);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    return (
        <>
            {showEditOrder && order.items && order.items.length > 0 && (
                <CreateOrderModal
                    order={order}
                    onCompleted={(order) => {
                        props.onChange(order);
                        setShowEditOrder(false);
                        alert.success("Orden Actualizada con éxito");
                    }}
                    show={showEditOrder}
                    setShow={setShowEditOrder}
                />
            )}
            <SquareButton
                title={"Editar Orden"}
                onClick={() => {
                    if (!order.items || order.items.length === 0) {
                        orderMutation.mutate(order.id ?? "inv");
                    } else {
                        setShowEditOrder(true);
                    }
                }}
            >
                {orderMutation.isLoading ? <LoadingDualRing small /> : <i className="fa-regular fa-pen-to-square"></i>}
            </SquareButton>
        </>
    );
}
