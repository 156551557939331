import styled from "styled-components";

export * from "./buttons";
export * from "./containers";
export * from "./inputs";
export * from "./TextFields";

interface LabelGroupOptions {
    lSpace?: boolean;
    rSpace?: boolean;
    tSpace?: boolean;
    bSpace?: boolean;
}

export const LabelGroup = styled.div`
    display: flex;
    flex-direction: column;

    ${(props: LabelGroupOptions) => (props.rSpace ? "margin-right: 15px;" : "")}
    ${(props: LabelGroupOptions) => (props.lSpace ? "margin-left: 15px;" : "")}
    ${(props: LabelGroupOptions) => (props.tSpace ? "margin-top: 10px;" : "")}
    ${(props: LabelGroupOptions) => (props.bSpace ? "margin-bottom: 10px;" : "")}

    .label,
    label {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
