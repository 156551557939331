import { Order } from "./../models/order";
import { DeliveryOrderUpdateModel, DeliveryStatus } from "./../models/deliveryOrder";
import wahioFetchApi from "../fetchApi";
import { IDeliveryOrder } from "../models/deliveryOrder";
import { localOrderEndpoint } from "../restApiEndpoint";

export const updateDeliveryOrder = (data: IDeliveryOrder): Promise<IDeliveryOrder> => {
    return wahioFetchApi.put(localOrderEndpoint.put.deliveryOrder, data).then((res: any) => res.data);
};

export interface DeliveryOrderStatusData {
    deliveryOrderId: string;
    newStatus: DeliveryStatus;
}

export const updateDeliveryOrderStatus = (data: DeliveryOrderStatusData): Promise<Order> => {
    return wahioFetchApi.put(localOrderEndpoint.put.updateDeliveryStatus(data.deliveryOrderId, data.newStatus), {}).then((res: any) => res.data);
};

export interface PatchDeliveryOrderBody {
    id: string;
    data: DeliveryOrderUpdateModel;
}

export const patchDeliveryOrder = (data: PatchDeliveryOrderBody): Promise<DeliveryOrderUpdateModel> => {
    return wahioFetchApi.patch(localOrderEndpoint.patch.deliveryOrder(data.id), data.data).then((res: any) => res.data);
};
