import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { getAccountBillingDueInvoices } from "../../api/account/accountApi";
import { BillingDueInvoices } from "../../api/models/accountUser";
import { getAuthBody } from "../../auth";
import { Flex } from "../../components/_controls";
import PATHS from "../../constants/paths";
import { getDateFormat } from "../../utils";
import { AlertLineContainer } from "./styled";

interface DueInvoiceValidatorProps {
    refValue: any;
    onLoad: () => void;
}

export default function DueInvoiceValidator(props: DueInvoiceValidatorProps) {
    const [dueInfo, setDueInfo] = useState<BillingDueInvoices>();
    const invoiceMutation = useMutation((accountId: string) => getAccountBillingDueInvoices(accountId), {
        onSuccess: (data) => {
            setDueInfo(data);
        },
    });

    useEffect(() => {
        props.onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dueInfo]);

    useEffect(() => {
        setTimeout(() => {
            invoiceMutation.mutate(getAuthBody().accountId);
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!dueInfo) return null;
    if (dueInfo.dueInvoices.length === 0) return null;

    return (
        <AlertLineContainer className="danger" ref={props.refValue}>
            <NavLink to={PATHS.accountBilling}>
                <Flex alignCenter gap10>
                    <span className="wahioicon-credit-card"></span>
                    <span>
                        {dueInfo.dueInvoices.length === 1 ? `Tienes una factura` : `Tienes ${dueInfo.dueInvoices.length} Facturas`}{" "}
                        pendiente: Fecha limite de pago {getDateFormat(dueInfo.minDueDate, "DD MMM")}
                    </span>
                </Flex>
            </NavLink>
        </AlertLineContainer>
    );
}
