import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IProductCategory } from "../../../api/models/productCategory";
import { CloudImages } from "../../../assets";
import { CustomCategoryContext } from "../../../store/contexts/CustomCategoryContext";
import { sortByName } from "../../../utils/sorts";
import DropdownList, { getListItem } from "../../Dropdown/DropdownList";
import SearchInputTimer from "../../_controls/inputs/SearchInputTimer";
import messages from "./messages";
import { SearchContainerView } from "./styled";

interface SearchWithCategoriesProps {
    selectedCategoryId?: string;
    onChangeCategory: (value?: IProductCategory) => void;
    setSearchValue: (value: string) => void;
    searchValue?: string;
    containerClass?: string;
}

export const INPUT_SEARCH_ID = "INPUT_SEARCH_PRODUCT";

export default function SearchWithCategories(props: SearchWithCategoriesProps) {
    const { customCategoryState, customCategoryActions } = useContext(CustomCategoryContext);
    const { selectedCategoryId, onChangeCategory, setSearchValue } = props;
    const [selectedCategory, setSelectedCategory] = useState<IProductCategory>();
    const [currentValue, setCurrentValue] = useState(props.searchValue ? props.searchValue : "");
    const intl = useIntl();

    useEffect(() => {
        if (props.searchValue !== currentValue) {
            setCurrentValue(props.searchValue ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchValue]);

    useEffect(() => {
        if (customCategoryState.items.length === 0) customCategoryActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedCategory(customCategoryState.items.find((x) => x.id === selectedCategoryId));
    }, [customCategoryState.items, selectedCategoryId]);

    const onChange = (e: any) => {
        if (e.target.value.trim() === props.searchValue?.trim()) return;
        let value = e.target.value.trim();
        setCurrentValue(value);
        setSearchValue(value);
    };

    return (
        <SearchContainerView className={props.containerClass ?? ""}>
            <DropdownList
                hideIcon
                styleButton={{ border: 0, background: "transparent", maxWidth: "125px", paddingRight: 0 }}
                title={selectedCategory ? selectedCategory.name : intl.formatMessage(messages.all)}
                items={customCategoryState.items
                    .sort((a, b) => sortByName(a, b))
                    .map((item) => getListItem(item.id ?? "", item.name, "", item.image ? item.image : CloudImages.defaultImage, item))}
                showRemove={!!selectedCategory}
                onRemoveItem={() => onChangeCategory(undefined)}
                onClickItem={(item) => onChangeCategory(item.object)}
            />

            <SearchInputTimer
                inputId={INPUT_SEARCH_ID}
                milliseconds={500}
                onChange={onChange}
                initialValue={currentValue}
                placeholder={intl.formatMessage(messages.searchProduct)}
            />
        </SearchContainerView>
    );
}
