import { IPasswordUpdateInput } from "../../components/Users/UserProfile/UpdatePassword";
import wahioFetchApi from "../fetchApi";
import { AccountUserImpl, AccountUserAndOrganizations, AccountUser } from "../models/accountUser";
import { accountEndpoint } from "../restApiEndpoint";

export const sendConfirmationEmail = (userId: string): Promise<AccountUser> => {
    return wahioFetchApi.get(accountEndpoint.get.sendConfirmationEmail(userId)).then((res: any) => res.data);
};

export const updateAccountUser = (user: AccountUser): Promise<AccountUser> => {
    return wahioFetchApi.put(accountEndpoint.put.accountUser, user).then((res: any) => res.data);
};

export const updateAccountUserPassword = (user: IPasswordUpdateInput): Promise<IPasswordUpdateInput> => {
    return wahioFetchApi.put(accountEndpoint.put.updateAccountUserPassword, user).then((res: any) => res.data);
};

export const getAccountUserAndOrganizations = (userId: string): Promise<AccountUserAndOrganizations> => {
    return wahioFetchApi.get(accountEndpoint.get.accountUserOrganizations(userId)).then((res: any) => res.data);
};

export const getAccountUsersByAccount = (accountId: string): Promise<AccountUserImpl[]> => {
    return wahioFetchApi.get(accountEndpoint.get.accountUsersByAccount(accountId)).then((res: any) => res.data);
};

export const accountUserSetActiveStatus = (userId: string): Promise<AccountUser> => {
    return wahioFetchApi.put(accountEndpoint.put.accountUserActiveStatus(userId), {}).then((res: any) => res.data);
};
export const accountUserDelete = (userId: string): Promise<AccountUser> => {
    return wahioFetchApi.delete(accountEndpoint.delete.accountUser(userId)).then((res: any) => res.data);
};
