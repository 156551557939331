import React, { useEffect, useState } from "react";
import { Contact } from "../../../../../api/models/contact";
import { CostItem, IDeliveryOrder } from "../../../../../api/models/deliveryOrder";
import { Order } from "../../../../../api/models/order";
import { TransactionAccountConcept } from "../../../../../api/models/transactionAccount";
import { getAuthBody } from "../../../../../auth";
import { useTransactionAccountContext } from "../../../../../store/contexts/TransactionAccountContext";
import { formatMoney } from "../../../../../utils";
import { useAlert } from "../../../../Alerts/Alert";
import ContactSelect from "../../../../Contacts/ContactSelect";
import TextBoxNumeral from "../../../../TextBoxNumeral";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, SquareButton, TextField } from "../../../../_controls";
import GroupButtonRow from "../../../../_controls/buttons/GroupButtonRow";
import CheckBox from "../../../../_controls/inputs/CheckBox";
import Modal, { IModalShow } from "../../../../modals/Modal";
import { DeliveryCostLine } from "../../styled";

export interface IShippingCostModal extends EditShippingCostModalProps, IModalShow {}

export interface ShippingCostState {
    excludesShippingCost: boolean;
    courier?: Contact;
    courierId?: string;
    currentAmount: number;
    createTransactionAccount: boolean;
    accountingConcept?: TransactionAccountConcept;
}

const ShippingCostModal = (props: IShippingCostModal) => {
    const { deliveryOrder, order, onUpdateOrder } = props;
    const [costItems, setCostItems] = useState<CostItem[]>(deliveryOrder.costItems ?? []);

    const {
        fetchConceptComplete,
        concepts,
        createUpdateConcept,
        fetchItemsIfNotExits,
        isFetching: conceptsFetching,
    } = useTransactionAccountContext();

    const [state, setState] = useState<ShippingCostState>({
        courier: deliveryOrder.courier,
        courierId: deliveryOrder.courierId,
        excludesShippingCost: deliveryOrder.excludesCostItemsInOrder ?? false,
        createTransactionAccount: deliveryOrder?.includesTransactionAccount ?? false,
        accountingConcept: deliveryOrder?.accountingConcept,
        currentAmount: deliveryOrder.amount ?? 0,
    });
    const alert = useAlert();

    useEffect(() => {
        fetchItemsIfNotExits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var totalValue = costItems.reduce((total, item) => total + item.value, 0);
        setState({ ...state, currentAmount: totalValue });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [costItems]);

    useEffect(() => {
        if (fetchConceptComplete && state.createTransactionAccount && !state.accountingConcept) {
            var existingConcept = concepts.find((x) => x.name === "Mensajeria");
            if (existingConcept) {
                setState({ ...state, accountingConcept: existingConcept });
            } else {
                createUpdateConcept({ name: "Mensajeria", accountId: getAuthBody().accountId, id: "", createdAt: new Date() }, (result) => {
                    setState({ ...state, accountingConcept: result });
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.createTransactionAccount, fetchConceptComplete]);

    const onSaveChanges = () => {
        if (state.excludesShippingCost && state.createTransactionAccount && !state.courierId) {
            alert.error("Mensajero requerido para crear cuenta por cobrar");
            return;
        }

        const deliveryOrderResult: IDeliveryOrder = {
            ...deliveryOrder,
            courier: state.courier,
            courierId: state.courier?.id,
            amount: state.excludesShippingCost ? 0 : state.currentAmount,
            costItemsTotal: state.currentAmount,
            costItems: costItems,
            excludesCostItemsInOrder: state.excludesShippingCost,
            includesTransactionAccount: state.createTransactionAccount,
            accountingConcept: state.accountingConcept,
            accountingAmount: state.currentAmount,
        };
        onUpdateOrder({
            ...order,
            deliveryOrder: deliveryOrderResult,
            totalDelivery: state.excludesShippingCost ? 0 : deliveryOrderResult.amount,
        });
        props.setShow(false);
    };

    return (
        <Modal {...props} showHeader title="Editar Costos de envío" ignoreOutsideClick>
            <Flex padding={20} column gap={25}>
                <ContactSelect
                    title="Mensajero"
                    placeholder="Mensajero"
                    contact={state.courier}
                    onUpdateContact={(value) => setState({ ...state, courier: value, courierId: value?.id })}
                    isCourier
                    w100
                />

                <Flex gap5 column>
                    <TextField light small>
                        Incluir Costos de Envío en el Total de la Orden
                    </TextField>
                    <GroupButtonRow
                        hidePadding
                        activeOption={state.excludesShippingCost}
                        onClickOption={(option) =>
                            setState({ ...state, excludesShippingCost: option.value, createTransactionAccount: false })
                        }
                        options={[
                            {
                                title: "Incluido",
                                value: false,
                                tooltip: "Los costos se incluirán en el total de la orden",
                            },
                            {
                                title: "Excluido",
                                value: true,
                                tooltip: "No se sumaran al total de la orden",
                            },
                        ]}
                    />
                </Flex>

                <DeliveryCostList items={costItems} setItems={setCostItems} />

                {state.excludesShippingCost && (
                    <CheckBox
                        name={""}
                        title="Crear cuenta por pagar"
                        checked={state.createTransactionAccount}
                        onChange={(e) => setState({ ...state, createTransactionAccount: e.target.checked })}
                    />
                )}

                <Flex alignEnd justifyEnd gap10>
                    <DefaultButton bgLight borderRadius={10} onClick={() => props.setShow(false)}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton disabled={conceptsFetching} borderRadius={10} onClick={() => onSaveChanges()}>
                        Guardar Cambios
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
};

interface EditShippingCostModalProps {
    deliveryOrder: IDeliveryOrder;
    onUpdateOrder: (value: Order) => void;
    order: Order;
}

//create a function to convert a number in format 0.000,00

export const EditShippingCostModal = (props: EditShippingCostModalProps) => {
    const [show, setShow] = useState(false);

    const cost = props.deliveryOrder.costItemsTotal ?? 0;

    return (
        <>
            {show && <ShippingCostModal {...props} show={show} setShow={setShow} />}
            <Flex gap10 alignCenter>
                <DefaultButton title='Editar Costo de Envío' className="btn-cost" hideBorder rounded onClick={() => setShow(!show)}>
                    <TextField bold>{formatMoney(cost)}</TextField>
                </DefaultButton>
                <TextField small light>
                    {props.deliveryOrder.excludesCostItemsInOrder ? "Excluido" : "Incluido"}
                </TextField>
            </Flex>
        </>
    );
};

interface DeliveryCostListProps {
    items: CostItem[];
    setItems: (items: CostItem[]) => void;
}

const DeliveryCostList = (props: DeliveryCostListProps) => {
    const { items, setItems } = props;

    useEffect(() => {
        if (items.length === 0) {
            const newItem: CostItem = {
                blockName: true,
                name: "Envío",
                value: 0,
            };
            setItems([newItem]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const onItemChange = (item: CostItem, index: number) => {
        let newItems = items.map((x, i) => (i === index ? item : x));
        setItems(newItems);
    };

    const onRemove = (index: number) => {
        let newItems = items.filter((x, i) => i !== index);
        setItems(newItems);
    };

    const addItem = () => {
        const newItem: CostItem = {
            name: "",
            value: 0,
        };
        setItems([...items, newItem]);
    };

    return (
        <>
            <Flex column gap10>
                <Flex spaceBetween alignCenter>
                    <TextField bold>Costos de Envío</TextField>
                    <DefaultButton small bgLight borderRadius={10} onClick={() => addItem()}>
                        <span className="fa-regular fa-plus" /> Item
                    </DefaultButton>
                </Flex>

                {items.map((item, index) => (
                    <DeliveryCostRow
                        key={index}
                        index={index}
                        nameDisabled={item.blockName}
                        item={item}
                        setItem={onItemChange}
                        onRemove={() => onRemove(index)}
                    />
                ))}
            </Flex>
        </>
    );
};

interface DeliveryCostRowProps {
    item: CostItem;
    setItem: (item: CostItem, index: number) => void;
    onRemove: () => void;
    nameDisabled?: boolean;
    index: number;
}

const DeliveryCostRow = (props: DeliveryCostRowProps) => {
    const { item, setItem } = props;

    return (
        <DeliveryCostLine>
            <DefaultInput
                className="description"
                placeholder="Descripción"
                disabled={props.nameDisabled}
                value={item.name}
                onChange={(e) => setItem({ ...item, name: e.target.value }, props.index)}
            />
            <TextBoxNumeral
                className="amount"
                value={item.value}
                onNumberChange={(value) => setItem({ ...item, value: value }, props.index)}
                format={"money"}
            />
            {props.index !== 0 && (
                <SquareButton className="ml-1 mr-1" removeBackground small onClick={() => props.onRemove()}>
                    <i className="fa-regular fa-trash"></i>
                </SquareButton>
            )}
        </DeliveryCostLine>
    );
};
