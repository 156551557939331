import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import ReactToPrint from "react-to-print";
import { getServiceMessageError } from "../../../api";
import { IOrganizationTemplate } from "../../../api/models/organizationTypes";
import { IPurchase } from "../../../api/models/purchase";
import { getPurchase } from "../../../api/purchases/purchaseApi";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { useAlert } from "../../Alerts/Alert";
import InvoiceFormat from "../../InvoiceFormat/Purchase";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, SquareButton } from "../../_controls";

interface IInvoicePrintProps {
    order: IPurchase;
    btnTitle?: string;
    showDropdownClass?: boolean;
    small?: boolean;
    btnIcon?: string;
}

export default function InvoicePrint(props: IInvoicePrintProps) {
    const { organizationState } = useContext(OrganizationContext);
    const componentRef = useRef<any>(<div />);
    const buttonTriggerRef = useRef<any>();
    const [order, setOrder] = useState(props.order)
    const [itemsLoaded, setItemsLoaded] = useState(false);
    let currentOrganization = organizationState.currentOrganization;
    const alert = useAlert();

    const orderMutation = useMutation((orderId: string) => getPurchase(orderId), {
        onSuccess: (order) => {
            setOrder(order);
            setItemsLoaded(true);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        setOrder(props.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    useEffect(() => {
        if (itemsLoaded) {
            buttonTriggerRef.current?.click();
        }
    }, [itemsLoaded]);

    if (!currentOrganization) return null;

    const getTemplate = () => {
        let template: IOrganizationTemplate = {
            invoiceTemplateTitle1: currentOrganization?.invoiceTemplateTitle1 ?? "",
            invoiceTemplateTitle2: currentOrganization?.invoiceTemplateTitle2 ?? "",
            invoiceTemplateTitle3: currentOrganization?.invoiceTemplateTitle3 ?? "",
            invoiceTemplateTitle4: currentOrganization?.invoiceTemplateTitle4 ?? "",
        };
        return template;
    };

    const Button = props.btnTitle ? DefaultButton : SquareButton;

    return (
        <>
            <ReactToPrint
                pageStyle={`@page {
                    size: auto;
                    margin: 5mm;
                    background: white;
                }
                @media print {
                    html, body {
                        background: white;
                        height: initial !important;
                        overflow: initial !important;
                        -webkit-print-color-adjust: exact;                     
                    }
                    
                }
                h4.class-name
                    @page{
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    }
      `}
                trigger={() => {
                    if (!order.items || order.items.length === 0) return <span style={{ display: "none" }}></span>;
                    return (
                        <SquareButton ref={buttonTriggerRef} small={props.small} title="Imprimir"> <span className="fa-regular fa-print" /> </SquareButton>
                    )
                }}
                content={() => componentRef.current}
            />
            {(!order.items || order.items.length === 0) && (
                <Button
                    onClick={() => orderMutation.mutate(order.id ?? "INVALID")}
                    small={props.small}
                    className={props.showDropdownClass ? "dropdown-button" : ""}
                >
                    {orderMutation.isLoading ? (
                        <LoadingDualRing small />
                    ) : (
                        <>
                            <span className={props.btnIcon ? props.btnIcon : "fa-regular fa-print"}></span>
                            {props.btnTitle ?? ""}
                        </>
                    )}
                </Button>
            )}
            <div style={{ display: "none", background: "white" }}>
                <InvoiceFormat image={currentOrganization?.image} ref={componentRef} order={order} template={getTemplate()} />
            </div>
        </>
    );
}
