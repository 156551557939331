import React, { useState } from "react";
import { IPurchaseItem } from "../../../api/models/purchase";
import { formatMoney } from "../../../utils";
import { ProductProfileModal } from "../../Products/ProductProfileNavigation";
import { getProductSku, getProductThumbnailUrl } from "../../Products/helper";
import { Flex, FlexImageStyle, TextField } from "../../_controls";
import { PurchaseItemTableRow } from "./styled";

export default function PurchaseItemView(props: { item: IPurchaseItem }) {
    const { item } = props;
    const { product, productVariant } = item;
    const [showProductModal, setShowProductModal] = useState(false);

    const image = getProductThumbnailUrl(product, productVariant);
    const realQuantity = item.quantity - item.quantityReturned;
    const isTotalReturned = realQuantity <= 0;

    return (
        <>
            {showProductModal && <ProductProfileModal product={item.product} show={showProductModal} setShow={setShowProductModal} />}
            <PurchaseItemTableRow className={isTotalReturned ? "returned" : ""}>
                <FlexImageStyle borderRadius={10} width={40} onClick={() => setShowProductModal(true)}>
                    <img src={image} alt="" />
                </FlexImageStyle>
                <Flex column alignStart marginLeft={10}>
                    <TextField className="product-name" onClick={() => setShowProductModal(true)}>
                        {item.product?.name}
                    </TextField>
                    <Flex gap5>
                        <TextField small light>
                            {getProductSku(item.product, productVariant)}
                        </TextField>
                        {item.manualPercentageDiscount > 0 && (
                            <TextField className="item-discount" title="Descuento">
                                {item.manualPercentageDiscount}%
                            </TextField>
                        )}
                        {item.totalTax > 0 && (
                            <TextField className="item-tax" title="Impuesto">
                                {item.totalTaxPercentage}%
                            </TextField>
                        )}
                    </Flex>
                </Flex>

                <Flex marginLeftAuto alignCenter gap10>
                    {isTotalReturned ? (
                        <Flex column alignEnd>
                            <TextField small>Producto</TextField>
                            <TextField small>Devuelto</TextField>
                        </Flex>
                    ) : (
                        <Flex column alignEnd>
                            <TextField small nowrap>
                                {formatMoney(item.unitPrice)} x {realQuantity}
                            </TextField>
                            <TextField bold>{formatMoney(item.total)}</TextField>
                        </Flex>
                    )}
                </Flex>
            </PurchaseItemTableRow>
        </>
    );
}
