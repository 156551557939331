import React from "react";
import { TRACK_ENDPOINT } from "../../../api/appConfig";
import { joinUrl } from "../../../utils";
import { Flex } from "../../_controls";
import { TrackingNumberContainer } from "./styled";

interface OrderTrackingNumberProps {
    trackingNumber: string;
}

export default function OrderTrackingNumber(props: OrderTrackingNumberProps) {
    return (
        <TrackingNumberContainer>
            <a href={joinUrl(TRACK_ENDPOINT, props.trackingNumber)} rel="noopener noreferrer" target="_blank">
                <Flex gap5 alignCenter>
                    <span className="linktext">{props.trackingNumber}</span>
                </Flex>
            </a>
        </TrackingNumberContainer>
    );
}
