import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { updateClientSettingsPartial } from "../../../api/account/clientSettingsApi";
import { ClientSettingsMutationInput, PriceSettingValue, PriceSettings } from "../../../api/models/accountUser";
import { getAuthBody } from "../../../auth";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { CLIENT_PRODUCT_PRICES } from "../../Products/PriceList/helper";
import priceMessages from "../../Products/PriceList/messages";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import { PriceSettingsPanelView } from "./styled";

interface PriceSettingsPanelProps {
    onCompleted: () => void;
}

export default function PriceSettingsPanel(props: PriceSettingsPanelProps) {
    const intl = useIntl();
    const alert = useAlert();

    const clientSettingsHook = useClientSettings();
    const [settings, setSettings] = useState<PriceSettings>({ ...clientSettingsHook.priceSettings });

    const hasChanges = React.useMemo(() => {
        let json1 = JSON.stringify(settings);
        let json2 = JSON.stringify(clientSettingsHook.shipmentSettings);
        return json1 !== json2;
    }, [settings, clientSettingsHook.shipmentSettings]);

    const saveSettingsMutation = useMutation((data: ClientSettingsMutationInput) => updateClientSettingsPartial(data), {
        onSuccess: (data) => {
            clientSettingsHook.saveSettingsField(settings, "priceSettings");
            props.onCompleted();
            alert.success("Los cambios se guardaron correctamente");
        },
    });

    const handleSaveChanges = () => {
        let data: ClientSettingsMutationInput = {
            accountId: getAuthBody().accountId,
            body: {
                priceSettings: settings,
            },
        };

        saveSettingsMutation.mutate(data);
    };

    const onItemChange = (status: string, name: keyof PriceSettingValue, value: any) => {
        let priceList = { ...settings.priceList } ?? {};
        let priceValue: PriceSettingValue = { ...priceList[status] } ?? {};
        priceValue[name] = value;
        priceList[status] = priceValue;
        setSettings({ ...settings, priceList });
    };

    const priceList = settings.priceList ?? {};

    const isLoading = saveSettingsMutation.isLoading;

    return (
        <PriceSettingsPanelView>
            <Flex column gap5>
                <h3 className="m-0">Renombrar lista de precios</h3>
                <TextField small light>
                    Puedes renombrar los nombres de los precios de tus productos. Por ejemplo, "Precio 1",
                    puedes cambiarlo a "Precio por mayor" o "Precio minorista".
                </TextField>
            </Flex>
            <Table tdPaddingRight={15} cellPadding={0} cellSpacing={0} hideBorde>
                <tbody>
                    {CLIENT_PRODUCT_PRICES.map((item) => {
                        return (
                            <tr key={item}>
                                <td>
                                    <TextField small light>
                                        {checkAndGetMessage(intl, priceMessages, item)}
                                    </TextField>
                                </td>
                                <td>
                                    <Flex column gap5>
                                        <Flex alignCenter gap15>
                                            <DefaultInput
                                                w100
                                                value={priceList[item]?.name ?? ""}
                                                onChange={(e) => onItemChange(item, "name", e.target.value)}
                                                borderRadius={10}
                                                className="status-input"
                                                type="text"
                                            />
                                        </Flex>
                                    </Flex>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Flex gap10 alignCenter>
                <DefaultButton disabled={isLoading} onClick={props.onCompleted} borderRadius={10} bgLight w100>
                    Cancelar
                </DefaultButton>
                <PrimaryButton disabled={isLoading || !hasChanges} w100 borderRadius={10} onClick={handleSaveChanges}>
                    Guardar Cambios {isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </PriceSettingsPanelView>
    );
}

interface PriceSettingsPanelButtonProps {
    small?: boolean;
    title?: string;
}
export const PriceSettingsPanelButton = (props: PriceSettingsPanelButtonProps) => {
    const [showModal, setShowModal] = React.useState(false);

    return (
        <div>
            <DefaultButton small={props.small} title="Configurar Estados" bgLight borderRadius={10} onClick={() => setShowModal(true)}>
                <i className="fa-regular fa-gear"></i> {props.title ?? "Configurar precios"}
            </DefaultButton>
            {showModal && (
                <Modal ignoreOutsideClick show={showModal} setShow={setShowModal} useButtonClose>
                    <PriceSettingsPanel onCompleted={() => setShowModal(false)} />
                </Modal>
            )}
        </div>
    );
};
