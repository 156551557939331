import { defineMessages } from "react-intl";

const base = "components.settings";

export default defineMessages({
    restaurant: {
        id: `${base}.restaurant`,
        defaultMessage: "Restaurante",
    },
    bar: {
        id: `${base}.bar`,
        defaultMessage: "Bar",
    },
    kiosk: {
        id: `${base}.kiosk`,
        defaultMessage: "Kiosco",
    },
    cart: {
        id: `${base}.cart`,
        defaultMessage: "Carrito",
    },
    setting: {
        id: `${base}.Setting`,
        defaultMessage: "Configuración",
    },
});
