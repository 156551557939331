import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { getServiceMessageError } from "../../../api";
import { IProduct, IProductVariant, WarehouseProductSummaryInput } from "../../../api/models/product";
import { IWarehouse } from "../../../api/models/warehouse";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { getProductById } from "../../../api/products";
import { getProductSummary } from "../../../api/products/productsApi";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import PATHS from "../../../constants/paths";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationProfile, { NavigationProfileHeader, NavigationProfileHeaderProps, useSelectedGroupTab } from "../../NavigationPanel";
import { NavigationCardGroup } from "../../NavigationPanel/NavigationCard";
import { Flex, SquareButton } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";
import ProductFormModal from "../ProductCreationForm/ProductFormModal";
import { getProductThumbnailUrl } from "../helper";
import NavigationBarcodeFile from "./NavigationBarcodeFile";
import NavigationCosts from "./NavigationCosts";
import NavigationDescription from "./NavigationDescription";
import NavigationEcommerceConfig, { ButtonCopyProductLink } from "./NavigationEcommerceConfig";
import NavigationExtraDetails from "./NavigationExtraDetails";
import NavigationFeatures from "./NavigationFeatures";
import NavigationFiles from "./NavigationFiles";
import NavigationLogs from "./NavigationLogs";
import NavigationOptionals from "./NavigationOptionals";
import NavigationPrices from "./NavigationPrices";
import NavigationAi from "./NavigationProductAi";
import NavigationProductProfile from "./NavigationProductProfile";
import NavigationSerials from "./NavigationSerials";
import NavigationStatistics from "./NavigationStatistics";
import NavigationStock from "./NavigationStock";
import NavigationStockLocation from "./NavigationStockLocation";
import NavigationVariants from "./NavigationVariants";
import ProductVariantsPreview from "./ProductVariantSelector";
import { MENU_OPTIONS, MENU_OPTION_OPTIONALS, MENU_OPTION_VARIANTS, NavigationGroupName } from "./menuHelper";
import messages from "./messages";

export interface ProductNavigationProfileProps {
    product: IProduct;
    productVariant?: IProductVariant;
    setProductVariant: (value: IProductVariant) => void;
    onUpdateProduct: (product: IProduct) => void;
    warehouseProduct?: IWarehouseProduct;
    setWarehouseProduct: (value: IWarehouseProduct) => void;
    defaultWarehouseId?: string;
    selectedGroup?: NavigationGroupName;
    setSelectedGroup: (value: NavigationGroupName) => void;
    selectedWarehouse?: IWarehouse;
    setSelectedWarehouse: (value?: IWarehouse) => void;
    fetchWarehouseProduct: () => void;
    intl: IntlShape;
}

interface ProductProfileNavigationProps {
    product?: IProduct;
    defaultWarehouseId?: string;
    onUpdateProduct?: (product: IProduct) => void;
    onWarehouseProductChange?: (value: IWarehouseProduct) => void;
    showBackButton?: boolean;
    onBackAction?: () => void;
}

export default function ProductProfileNavigation(props: ProductProfileNavigationProps) {
    const params = useParams<{ productId: string }>();
    const [product, setProduct] = useState<IProduct | undefined>(props.product);
    const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouse>();
    const [warehouseProduct, setWarehouseProduct] = useState<IWarehouseProduct>();
    const [productVariant, setProductVariant] = useState<IProductVariant>();
    const { selectedGroup, setSelectedGroup } = useSelectedGroupTab<NavigationGroupName>(NavigationGroupName.Profile);
    const alert = useAlert();
    const { warehouseState } = useWarehouseContext();
    const { appSettingsState } = useAppSettingsContext();
    const intl = useIntl();

    const customMenuOptions = React.useMemo(() => {
        let menuOptions = [...MENU_OPTIONS];
        if (product?.includesOptionals) {
            menuOptions.splice(1, 0, MENU_OPTION_OPTIONALS);
        }
        if (product?.includesVariants) {
            menuOptions.splice(1, 0, MENU_OPTION_VARIANTS);
        }
        menuOptions = menuOptions.map((option) => {
            option.title = intl.formatMessage(messages[option.tabName]);
            return option;
        });
        return menuOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    const productMutation = useMutation((id: string) => getProductById(id), {
        onSuccess: (data) => {
            setProduct(data);
            if (data.includesVariants && data.variants && data.variants.length > 0) {
                setProductVariant(data.variants[0]);
            }
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const warehouseProductMutation = useMutation((input: WarehouseProductSummaryInput) => getProductSummary(input), {
        onSuccess: (data) => {
            onWarehouseProductChange(data);
        },
    });

    useEffect(() => {
        const warehouses = warehouseState.allWarehouses;
        let warehouseId = props.defaultWarehouseId ?? appSettingsState.defaultWarehouseId;

        if (!warehouseId && warehouses.length > 0) {
            warehouseId = warehouses[0].id ?? "";
        }

        if (warehouseId) {
            const matchResult = warehouses.find((warehouse) => warehouse.id === warehouseId);
            if (matchResult) {
                setSelectedWarehouse(matchResult);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseState.allWarehouses, props.defaultWarehouseId]);

    useEffect(() => {
        fetchWarehouseProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWarehouse, product?.id, productVariant]);

    const fetchWarehouseProduct = () => {
        if (!selectedWarehouse || !product) return;
        const data: WarehouseProductSummaryInput = {
            productId: product?.id ?? "",
            productVariantId: productVariant?.id,
            warehouseId: selectedWarehouse?.id ?? "",
        };
        warehouseProductMutation.mutate(data);
    };

    useEffect(() => {
        const productId = props.product?.id ?? params.productId;
        if (productId) {
            productMutation.mutate(productId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.productId]);

    const onUpdateProduct = (product: IProduct) => {
        if (props.onUpdateProduct) {
            props.onUpdateProduct(product);
        }
        setProduct(product);
    };

    const onWarehouseProductChange = (value: IWarehouseProduct) => {
        setWarehouseProduct(value);
        if (props.onWarehouseProductChange) {
            props.onWarehouseProductChange(value);
        }
    };

    if (!product && productMutation.isLoading) {
        return <LoadingDualRing center />;
    }

    if (!product) return null;

    const profileProps: ProductNavigationProfileProps = {
        product,
        onUpdateProduct,
        selectedGroup,
        setSelectedGroup,
        productVariant,
        setProductVariant,
        warehouseProduct,
        setWarehouseProduct: onWarehouseProductChange,
        selectedWarehouse,
        setSelectedWarehouse,
        fetchWarehouseProduct,
        intl,
    };

    return (
        <>
            <NavigationProfile
                headerComp={
                    <ProductHeader
                        {...profileProps}
                        image={getProductThumbnailUrl(product, productVariant)}
                        title={`${product.name} ${productVariant?.valuesLine ?? ""}`}
                        titleLink={PATHS.productProfileId(product.id)}
                        subTitle={product.customCategory?.name}
                        showBackButton={props.showBackButton}
                        onBackAction={props.onBackAction}
                    />
                }
                menuOptions={customMenuOptions}
                selectedTab={selectedGroup}
                setSelectedTab={setSelectedGroup}
            >
                <NavigationContent {...profileProps} />
            </NavigationProfile>
        </>
    );
}

function NavigationContent(props: ProductNavigationProfileProps) {
    return (
        <>
            <ProfileGroup {...props} />
            <StockGroup {...props} />
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Variants}>
                <NavigationVariants {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Optionals}>
                <NavigationOptionals {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.ProductAiAnalysis}>
                <NavigationAi {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Costs}>
                <NavigationCosts {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Files}>
                <NavigationFiles {...props} />
                <NavigationBarcodeFile {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Statistics}>
                <NavigationStatistics {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Logs}>
                <NavigationLogs {...props} />
            </NavigationCardGroup>
            <NavigationCardGroup {...props} groupName={NavigationGroupName.Ecommerce}>
                <NavigationEcommerceConfig {...props} />
            </NavigationCardGroup>
        </>
    );
}

export const StockGroup = (props: ProductNavigationProfileProps) => {
    return (
        <NavigationCardGroup {...props} groupName={NavigationGroupName.Stock}>
            <NavigationStock {...props} />

            {props.product.includesSerials && <NavigationSerials {...props} />}
            {props.selectedWarehouse?.allowLogisticsService && <NavigationStockLocation {...props} />}
        </NavigationCardGroup>
    );
};

export const ProfileGroup = (props: ProductNavigationProfileProps) => {
    return (
        <NavigationCardGroup {...props} groupName={NavigationGroupName.Profile}>
            <NavigationProductProfile {...props} />
            <NavigationPrices {...props} />
            <NavigationExtraDetails {...props} />
            <NavigationFeatures {...props} />
            <NavigationDescription {...props} />
        </NavigationCardGroup>
    );
};

const ProductHeader = (props: NavigationProfileHeaderProps & ProductNavigationProfileProps) => {
    const { product } = props;
    const [openEditProduct, setOpenEditProduct] = useState(false);
    return (
        <>
            {openEditProduct && (
                <ProductFormModal
                    updateProduct={product}
                    onCancel={() => {}}
                    onCreate={(value) => props.onUpdateProduct(value)}
                    show={openEditProduct}
                    setShow={setOpenEditProduct}
                />
            )}
            <NavigationProfileHeader
                {...props}
                customComponent={<ProductVariantsPreview {...props} />}
                customActions={
                    <Flex gap10 alignCenter>
                        <ButtonCopyProductLink product={product} />
                        <SquareButton borderRadius={10} bgLight onClick={() => setOpenEditProduct(true)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                        </SquareButton>
                    </Flex>
                }
            />
        </>
    );
};

interface ProductProfileModalProps extends IModalShow, ProductProfileNavigationProps {}

export function ProductProfileModal(props: ProductProfileModalProps) {
    return (
        <Modal
            borderRadius={30}
            sizeType="md"
            id="modal-product-profile"
            removeVerticalAlign
            showHeader
            title={"Perfil del producto"}
            useMobileView
            {...props}
        >
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <ProductProfileNavigation {...props} />
            </div>
        </Modal>
    );
}
