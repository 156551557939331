import { AccountClientSettings } from "../../api/models/accountUser";
import { IBasePermission } from "../../api/models/accountUserPermissions";
import PATHS from "../../constants/paths";

export const TOUR_MENU_SHIPMENT_PICKING = "TOUR_MENU_SHIPMENT_PICKING";
export const TOUR_MENU_SHIPMENT_BOARD = "TOUR_MENU_SHIPMENT_BOARD";
export const TOUR_MENU_SHIPMENT_QUEUE = "TOUR_MENU_SHIPMENT_QUEUE";
export const TOUR_MENU_PRODUCT_PURCHASE = "TOUR_MENU_PRODUCT_PURCHASE";

export interface IMenuOption {
    icon?: string;
    title: string;
    link: string;
    noExact?: boolean;
    tourName?: string;
    childs?: IMenuOption[];
}

export class MenuOption implements IMenuOption {
    icon?: string;
    title: string;
    link: string;
    noExact?: boolean;
    tourName?: string;
    childs?: IMenuOption[];
    constructor(icon: string, title: string, link: string, tourName?: string) {
        this.icon = icon;
        this.title = title;
        this.link = link;
        this.tourName = tourName;
    }
}

export const getProductChilds = (p: IBasePermission) => {
    let childList: IMenuOption[] = [];

    let itemProduct = new MenuOption("fa-regular fa-box-open-full", "products", PATHS.productList);
    if (p.product?.read) {
        childList.push(itemProduct);
    }
    if (p.warehouse?.read) {
        let item = new MenuOption("fa-regular fa-warehouse-full", "warehouses", PATHS.productWarehouses);
        childList.push(item);
    }

    if (p.purchase?.read) {
        childList.push(
            new MenuOption("fa-regular fa-basket-shopping", "purchases", PATHS.productPurchasesList, TOUR_MENU_PRODUCT_PURCHASE)
        );
    }
    if (p.tax?.read) {
        childList.push(new MenuOption("fa-regular fa-filter-circle-dollar", "taxes", PATHS.productTax));
    }
    if (p.discount?.read) {
        childList.push(new MenuOption("fa-regular fa-badge-percent", "discounts", PATHS.productDiscount));
    }
    if (p.category?.read) {
        childList.push(new MenuOption("fa-regular fa-tags", "categories", PATHS.productCategories));
    }
    if (p.product?.read) {
        childList.push(new MenuOption("fa-regular fa-pallet-boxes", "movements", PATHS.productLogs));
    }
    if (p.product?.read) {
        childList.push(new MenuOption("fa-regular fa-trash-can-list", "bin", PATHS.productRecycleBin));
    }
    return childList;
};

export const getPaymentChilds = (p: IBasePermission) => {
    let childList: IMenuOption[] = [];

    if (p.cashRegister?.read) {
        childList.push(new MenuOption("fa-regular fa-circle-dollar-to-slot", "payments", PATHS.paymentsAll));
        childList.push(new MenuOption("fa-regular fa-landmark", "cashRegisterAndBank", PATHS.cashRegistersList));
        childList.push(new MenuOption("fa-regular fa-chart-pie", "dashboard", PATHS.cashRegistersDashboard));

        childList.push(new MenuOption("fa-regular fa-wallet", "accountingPanel", PATHS.paymentsAccountingPanel));
        childList.push(new MenuOption("fa-regular fa-file-invoice", "transactionAccounts", PATHS.paymentsTransactionAccount));
    }

    if (p.cashRegister?.read) {
        childList.push(new MenuOption("fa-regular fa-list-tree", "cashRecords", PATHS.cashRegisterLogList));
    }

    return childList;
};

export const getShipmentChilds = (p: IBasePermission) => {
    let childList: IMenuOption[] = [];
    childList.push(new MenuOption("fa-regular fa-basket-shopping-simple", "orders", PATHS.shipmentsList));
    childList.push(new MenuOption("wahioicon-whatsapp", "whatsApp", PATHS.shipmentsListWhatsapp));
    childList.push(new MenuOption("fa-regular fa-shelves", "picking", PATHS.shipmentsListPicking, TOUR_MENU_SHIPMENT_PICKING));

    childList.push(new MenuOption("fa-regular fa-route", "orderReadyToShip", PATHS.orderShipmentReadyToShip));

    childList.push(new MenuOption("fa-regular fa-table-layout", "ordersBoard", PATHS.orderShipmentsBoard, TOUR_MENU_SHIPMENT_BOARD));
    childList.push(new MenuOption("fa-regular fa-stopwatch", "queueOrders", PATHS.orderShipmentsQueue, TOUR_MENU_SHIPMENT_QUEUE));
    return childList;
};

export const getSaleOrderChilds = (p: IBasePermission, clientSettings?: AccountClientSettings) => {
    let childList: IMenuOption[] = [];

    if (p.order?.read) {
        childList.push(new MenuOption("fa-regular fa-bag-shopping", "sales", PATHS.orderSalesList));
        childList.push(new MenuOption("fa-regular fa-file-lines", "quotes", PATHS.orderQuoteList));
        childList.push(new MenuOption("fa-regular fa-box-archive", "null", PATHS.orderVoidList));
        childList.push(new MenuOption("fa-regular fa-file", "draft", PATHS.orderDraft));
        childList.push(new MenuOption("fa-regular fa-comments", "comments", PATHS.orderCommentList));
        if (clientSettings?.enabledRestaurant)
            childList.push(new MenuOption("fa-regular fa-fork-knife", "barRestaurant", PATHS.orderRestaurant));

        childList.push(new MenuOption("fa-regular fa-file-exclamation", "returns", PATHS.orderReturns));
    }

    if (p.channel?.read) childList.push(new MenuOption("fa-regular fa-bullhorn", "channels", PATHS.orderChannels));

    return childList;
};

export const getContactChilds = (p: IBasePermission) => {
    let childList: IMenuOption[] = [];
    childList.push(new MenuOption("fa-regular fa-user", "contacts", PATHS.contactList));
    childList.push(new MenuOption("fa-regular fa-user-tag", "sellers", PATHS.contactSellers));
    childList.push(new MenuOption("fa-regular fa-person-biking-mountain", "couriers", PATHS.contactCourier));
    childList.push(new MenuOption("fa-regular fa-building", "companies", PATHS.contactCompany));
    childList.push(new MenuOption("fa-regular fa-person-dolly", "suppliers", PATHS.contactSuppliers));

    return childList;
};

export const getCashRegisterChildsChilds = (p: IBasePermission) => {
    let childList: IMenuOption[] = [];

    if (p.cashRegister?.read) {
        childList.push(new MenuOption("fa-regular fa-landmark", "cashRegister", PATHS.cashRegistersList));
        childList.push(new MenuOption("fa-regular fa-list-tree", "cashRecords", PATHS.cashRegisterLogList));
    }

    return childList;
};

export const getConfigChilds = (p: IBasePermission) => {
    let childList: IMenuOption[] = [];

    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-circle-user", "myAccount", PATHS.settingsAccountProfile));
    }
    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-users", "users", PATHS.accountUsers));
    }
    if (p.cloud?.read) {
        childList.push(new MenuOption("fa-regular fa-cloud", "itemCloud", PATHS.accountCloud));
    }

    childList.push(new MenuOption("fa-regular fa-bags-shopping", "ecommerce", PATHS.ecommerce));
    childList.push(new MenuOption("fa-regular fa-calendar", "calendar", PATHS.accountCalendar));

    if (p.statistics?.read) {
        childList.push(new MenuOption("fa-regular fa-chart-pie", "statistics", PATHS.accountStatistics));
    }

    if (p.organization?.read) {
        childList.push(new MenuOption("fa-regular fa-buildings", "stores", PATHS.accountStores));
    }

    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-file-chart-column", "reports", PATHS.accountReports));
    }

    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-list-ol", "numberings", PATHS.configInvoiceNumeration));
    }

    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-file-contract", "electronicBilling", PATHS.configEBilling));
    }
    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-folder-gear", "localSettings", PATHS.configGeneral));
    }

    if (p.account?.read) {
        childList.push(new MenuOption("fa-regular fa-shield-check", "mySubscription", PATHS.accountBilling));
    }

    return childList;
};
