import React, { useEffect, useState } from "react";
import { useUserContext } from "../../store/contexts/UserContext";
import AccountBillingConfigModal from "../Account/Billing/AccountBillingConfigModal";
import { UpdatePlanButton } from "../Account/Billing/styled";
import LoadingDualRing from "../LoadingDualRing";
import { DefaultInput, Flex, PrimaryGradientButton, SquareButton, TextField } from "../_controls";
import WahioTooltip from "../_controls/WahioTooltip";

const parse = require("html-react-parser");

const API_URL = "https://api.openai.com/v1/chat/completions";
const API_KEY = "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";

interface ChatResponsePlaygroundProps {
    prompt: string;
    btnTitle: string;
    onResponse: (response: string) => void;
    onCompleted?: () => void;
    disabled?: boolean;
    showInput?: boolean;
    placeholder?: string;
    showResponse?: boolean;
    autoLoad?: boolean;
    square?: boolean;
}

export const ChatResponsePlayground = (props: ChatResponsePlaygroundProps) => {
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [localPrompt, setLocalPrompt] = useState("");
    const [localResponse, setLocalResponse] = useState("");

    const [showUpdatePlan, setShowUpdatePlan] = useState(false);

    const { userState } = useUserContext();
    const account = userState.user?.account;
    const authorizedToAnalyzeSalesIa = account?.plan === "premium" || account?.plan === "free";
    const controllerRef = React.useRef<AbortController | null>(null);

    const setResult = (result: string) => {
        setLocalResponse(result);
        props.onResponse(result);
    };

    useEffect(() => {
        if (props.autoLoad && props.prompt) {
            generate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generate = async () => {
        const prompt = `${props.prompt} ${localPrompt}`;

        if (!prompt) {
            return;
        }

        setIsGenerating(true);
        setResult("Generando...");

        controllerRef.current = new AbortController();
        const signal = controllerRef.current.signal;

        try {
            const response = await fetch(API_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${API_KEY}`,
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [{ role: "user", content: prompt }],
                    max_tokens: 500,
                    stream: true,
                }),
                signal,
            });

            const reader = response.body!.getReader();
            const decoder = new TextDecoder("utf-8");
            let resultText = "";

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const chunk = decoder.decode(value);
                const lines = chunk.split("\n");

                const parsedLines = lines
                    .map((line) => line.replace(/^data: /, "").trim())
                    .filter((line) => line !== "" && line !== "[DONE]")
                    .map((line) => JSON.parse(line));

                for (const parsedLine of parsedLines) {
                    const { choices } = parsedLine;
                    const { delta } = choices[0];
                    const { content } = delta;

                    if (content) {
                        resultText += content.replace(/\n/g, "<br>");
                    }
                }

                setResult(resultText);
            }
        } catch (error) {
            if (signal.aborted) {
                setResult("Request aborted.");
            } else {
                console.error("Error:", error);
                setResult("Error occurred while generating.");
            }
        } finally {
            setIsGenerating(false);
            if (props.onCompleted) props.onCompleted();
            controllerRef.current = null;
        }
    };

    const stop = () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
            controllerRef.current = null;
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            generate();
        }
    };

    useEffect(() => {
        return () => {
            // Abort any ongoing fetch operation when the component unmounts
            if (controllerRef.current) {
                controllerRef.current.abort();
            }
        };
    }, []);

    return (
        <Flex column gap10>
            {showUpdatePlan && (
                <AccountBillingConfigModal
                    existingPlanOptions={{ plan: account?.plan, cycle: account?.billing?.cycle }}
                    show={showUpdatePlan}
                    setShow={setShowUpdatePlan}
                />
            )}
            <Flex
                gap10
                alignCenter
                style={{
                    display: props.autoLoad ? "none" : "flex",
                }}
            >
                {props.showInput && (
                    <DefaultInput
                        w100
                        onKeyDown={handleKeyPress}
                        rounded
                        placeholder={props.placeholder ?? "Escribe algo al chatbot"}
                        value={localPrompt}
                        onChange={(e) => setLocalPrompt(e.target.value)}
                    />
                )}
                {props.square ? (
                    <PrimaryGradientButton
                        rounded
                        onClick={generate}
                        className="square"
                        disabled={props.disabled || !authorizedToAnalyzeSalesIa}
                    >
                        {isGenerating ? <LoadingDualRing small /> : <i className="fa-regular fa-wand-magic-sparkles"></i>}
                    </PrimaryGradientButton>
                ) : (
                    <PrimaryGradientButton rounded onClick={generate} disabled={props.disabled || !authorizedToAnalyzeSalesIa}>
                        <i className="fa-regular fa-wand-magic-sparkles" /> {props.btnTitle}
                        {isGenerating && <LoadingDualRing small />}
                    </PrimaryGradientButton>
                )}

                {isGenerating && (
                    <SquareButton id="stopBtn" disabled={!isGenerating} onClick={stop}>
                        <i className="fa-regular fa-stop"></i>
                    </SquareButton>
                )}

                {!authorizedToAnalyzeSalesIa && (
                    <WahioTooltip
                        timeout={200}
                        children={
                            <Flex gap10 column>
                                <TextField>AI Disponible en plan Premium</TextField>
                                <UpdatePlanButton className="small" onClick={() => setShowUpdatePlan(true)}>
                                    <span className="title">Actualizar plan</span>
                                </UpdatePlanButton>
                            </Flex>
                        }
                    />
                )}
            </Flex>

            {props.showResponse && localResponse && (
                <Flex>
                    <TextField>{parse(localResponse)}</TextField>
                </Flex>
            )}

            {props.autoLoad && isGenerating && (
                <SquareButton id="stopBtn" disabled={!isGenerating} onClick={stop}>
                    <i className="fa-regular fa-stop"></i>
                </SquareButton>
            )}
        </Flex>
    );
};
