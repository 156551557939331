import { wahioFetch } from "..";
import { getAuthBody } from "../../auth";
import { convertProductListToExport, exportExcelFile } from "../../components/ImportExport/ExcelImport/helper";
import { ProductListParams } from "../../components/Products/ProductList/productParamHelper";
import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { IProduct } from "../models/product";
import { IWarehouse } from "../models/warehouse";
import { IWarehouseProduct, IWarehouseProductSearchModel } from "../models/warehouseProduct";
import { productEndpoint } from "../restApiEndpoint";

export interface handleDownloadProductsOptions {
    warehouse: IWarehouse;
    categoryId?: string;
    searchValue?: string;
    status?: string;
    params?: ProductListParams;
    onFinish: () => void;
    onFailed: (error: any) => void;
}

export const handleDownloadProducts = (option: handleDownloadProductsOptions, page: number = 1, items: IWarehouseProduct[] = []) => {
    const { params, warehouse, categoryId, searchValue, status } = option;

    const body: IWarehouseProductSearchModel = {
        accountId: getAuthBody().accountId,
        currentPage: page,
        pageSize: 100,
        searchValue,
        customCategoryId: categoryId,
        warehouseId: warehouse?.id,
        sort: "name",
        sortDesc: false,
        status,
        inStockOnly: !!params?.onlyWithStock,
        onlyNegativeStock: !!params?.negativeStock,
        minPrice: params?.minPrice,
        maxPrice: params?.maxPrice,
        minStock: params?.minStock,
        maxStock: params?.maxStock,
        onlyProductWithImage: !!params?.onlyWithImage,
        onlyProductWithoutImage: !!params?.onlyWithoutImage,
    };

    wahioFetch.post(
        productEndpoint.post.warehouseProductSearchQuery,
        body,
        (success) => {
            let res: IPaginationResponse<IWarehouseProduct> = success.data;
            items = [...items, ...res.items];
            console.log("PAGE: ", page, " OF ", res.totalPages);
            if (res.totalPages > page) {
                handleDownloadProducts(option, page + 1, items);
            } else {
                let rows = convertProductListToExport(items);
                exportExcelFile(rows, `productos_${warehouse?.name.toLocaleLowerCase().replaceAll(" ", "_")}`);
                option.onFinish();
            }
        },
        (error) => {
            option.onFailed(error);
        }
    );
};

export const getWarehouseProducts = (searchInput: IWarehouseProductSearchModel): Promise<IPaginationResponse<IWarehouseProduct>> => {
    return wahioFetchApi.post(productEndpoint.post.warehouseProductSearchQuery, searchInput).then((res: any) => res.data);
};

export const getProducts = (searchInput: IWarehouseProductSearchModel): Promise<IPaginationResponse<IProduct>> => {
    return wahioFetchApi.post(productEndpoint.post.productSearchQuery, searchInput).then((res: any) => res.data);
};

export const getProductById = (id: string): Promise<IProduct> => {
    return wahioFetchApi.get(productEndpoint.get.product(id)).then((res: any) => res.data);
};
