import { getModelFullName } from "../../utils";
import {
    IWompiCheckTransactionResponse,
    WompiCreditCardInput,
    WompiPaymentSourceInput,
    WompiSignatureIntegrityInputBase,
    WompiTransactionWithPaymentSource,
} from "../models/wompi";

import { useMutation } from "react-query";
import { CreditCardState } from "../../components/Account/Billing/BillingPaymentMethod/BillingPaymentMethodCard";
import { CreatePaymentSourceResult, getSignatureInput, getTransactionSourceInput } from "../../components/Account/Billing/helpers";
import { useAlert } from "../../components/Alerts/Alert";
import { useUserContext } from "../../store/contexts/UserContext";
import { waitFetch } from "../fetchApi";
import {
    createWompiCreditCard,
    createWompiPaymentSource,
    createWompiPaymentSourceTransaction,
    getWompiIntegritySignature,
    getWompiToken,
    getWompiTransaction,
} from "./wompiApi";
import { getServiceMessageError } from "..";

export const useWompiPayments = () => {
    const { userState } = useUserContext();
    const alert = useAlert();
    const getTokenMutation = useMutation(() => getWompiToken());

    const wompiIntegrityMutation = useMutation((data: WompiSignatureIntegrityInputBase) => getWompiIntegritySignature(data));
    const wompiCreditCardMutation = useMutation((data: WompiCreditCardInput) => createWompiCreditCard(data));
    const wompiCreatePaymentSource = useMutation((data: WompiPaymentSourceInput) => createWompiPaymentSource(data));
    const createTransactionMutation = useMutation((data: WompiTransactionWithPaymentSource) => createWompiPaymentSourceTransaction(data));
    const getTransactionMutation = useMutation((id: string) => getWompiTransaction(id));

    const createPaymentSource = (
        creditCardState: CreditCardState,
        onSuccess: (value: CreatePaymentSourceResult) => void,
        onError: (err: any) => void
    ) => {
        let userName = getModelFullName(userState.user);
        getTokenMutation
            .mutateAsync()
            .then((wompiToken) => {
                const data: WompiCreditCardInput = {
                    number: creditCardState.number.valueReal,
                    exp_month: creditCardState.expirationDate.month.padStart(2, "0"),
                    exp_year: creditCardState.expirationDate.year,
                    cvc: creditCardState.cvc.value,
                    card_holder: userName + " Wahio",
                };
                wompiCreditCardMutation
                    .mutateAsync(data)
                    .then((cardResult) => {
                        const paymentSourceInput: WompiPaymentSourceInput = {
                            type: "CARD",
                            token: cardResult.data.id,
                            customer_email: userState.user?.email ?? "INVALID",
                            acceptance_token: wompiToken.data.presigned_acceptance.acceptance_token,
                        };
                        wompiCreatePaymentSource
                            .mutateAsync(paymentSourceInput)
                            .then((paymentSourceResult) => {
                                return onSuccess({
                                    creditCard: cardResult,
                                    wompiToken: wompiToken,
                                    paymentSource: paymentSourceResult,
                                });
                            })
                            .catch((err) => onError(err));
                    })
                    .catch((err) => onError(err));
            })
            .catch((err) => onError(err));
    };

    const getTransactionWithRetry = async (transactionId: string, tries: number): Promise<IWompiCheckTransactionResponse | undefined> => {
        const onError = (err: any): any => {
            tries -= 1;
            if (tries <= 0) {
                alert.error("No fue posible obtener el estado de la transacción");
                throw err;
            }

            return waitFetch(3000).then(() => getTransactionWithRetry(transactionId, tries));
        };

        try {
            const transaction = await getTransactionMutation.mutateAsync(transactionId);
            if (transaction.data.status === "DECLINED") {
                alert.error("Pago rechazado");
                return undefined;
            } else if (transaction.data.status !== "APPROVED") {
                alert.error("No fue posible obtener un estado aprobado del pago");
                return undefined;
            }
            return transaction;
        } catch (err) {
            onError(err);
        }
        return undefined;
    };

    const createWompiTransaction = async (
        referenceId: string,
        amountInCents: number,
        paymentMethodToken: string
    ): Promise<IWompiCheckTransactionResponse | undefined> => {
        try {
            const inputIntegrity = getSignatureInput(referenceId, amountInCents.toString());
            const signatureRes = await wompiIntegrityMutation.mutateAsync(inputIntegrity);
            const transactionPaymentSource = getTransactionSourceInput(
                amountInCents,
                userState.user?.email ?? "NA",
                signatureRes.signature,
                referenceId,
                paymentMethodToken
            );

            const creationResult = await createTransactionMutation.mutateAsync(transactionPaymentSource);
            return await getTransactionWithRetry(creationResult.data.id, 6);
        } catch (err) {
            alert.error(getServiceMessageError(err));
        }
        return undefined;
    };

    return {
        createPaymentSource,
        getTransactionMutation,
        createTransactionMutation,
        getTokenMutation,
        wompiIntegrityMutation,
        createWompiTransaction,
    };
};
