import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { ECOMMERCE_ENDPOINT } from "../../../api/appConfig";
import {
    EcommerceLabelPositions,
    IProduct,
    ProductEcommerceLabel,
    ProductEcommerceStatusType,
    ProductPartialUpdateData,
    ProductPartialUpdateInput,
    ProductSearchEngineInput,
} from "../../../api/models/product";
import { createUpdateProduct, productPartialUpdate, putProductSearchEngine } from "../../../api/products/productsApi";
import { CloudImages } from "../../../assets";
import { EcommerceIconType, ecommerceIcons } from "../../../assets/ecommerceIcons";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useEcommerceList } from "../../../store/contexts/UserContext";
import { formatMoney, getRoundNumber, joinUrl } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import Dropdown from "../../Dropdown";
import { getEcommerceName } from "../../Ecommerce/helper";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import TextBoxNumeral from "../../TextBoxNumeral";
import WahioCloudItemsModal from "../../WahioCloud/WahioCloudItemsModal";
import { DefaultButton, DefaultInput, DefaultTextarea, Flex, FlexImageStyle, GridTemplate, SquareButton, TextField } from "../../_controls";
import GroupButtonRow from "../../_controls/buttons/GroupButtonRow";
import NavigationEcommerceStatistics from "./NavigationEcommerceStatistics";
import messages from "./messages";
import { ImageTabViewContainer, ProductCardPreviewContainer, ProductEcommerceLine } from "./styled";
import PopoverRadix from "../../_controls/PopoverRadix";

export default function NavigationEcommerceConfig(props: ProductNavigationProfileProps) {
    return (
        <>
            <ProductEcommerceVisibility {...props} />
            <EcommerceShareProductLink {...props} />
            <EcommerceSearchEngine {...props} />
            <ProductEcommercePreview {...props} />
            <NavigationEcommerceStatistics {...props} />
        </>
    );
}

const EcommerceShareProductLink = (props: ProductNavigationProfileProps) => {
    return (
        <NavigationCard {...props} title="Compartir producto">
            <ProductEcommerceLinks product={props.product} />
        </NavigationCard>
    );
};

export const ButtonCopyProductLink = ({ product }: { product: IProduct }) => {
    return (
        <PopoverRadix
            trigger={
                <SquareButton>
                    <i className="fa-regular fa-share-nodes"></i>
                </SquareButton>
            }
        >
            <ProductEcommerceLinks product={product} />
        </PopoverRadix>
    );
};

export const ProductEcommerceLinks = ({ product }: { product: IProduct }) => {
    const { ecommerceList, isLoading } = useEcommerceList();

    const alert = useAlert();

    const onClickCopyLink = (link: string) => {
        navigator.clipboard.writeText(link).then(() => alert.success("El link ha sido copiado con éxito"));
    };

    return (
        <Flex column>
            {isLoading && <LoadingDualRing center />}
            {ecommerceList.length === 0 && (
                <TextField small light>
                    No hay ecommerce configurados
                </TextField>
            )}
            <Flex column gap10>
                {ecommerceList.map((item, index) => {
                    const link = joinUrl(ECOMMERCE_ENDPOINT, `${getEcommerceName(item)}/p/${product.slug}`);
                    return (
                        <ProductEcommerceLine key={index}>
                            <Flex className="text-link" column overflowAuto onClick={() => onClickCopyLink(link)}>
                                <TextField>{item.name}</TextField>
                                <TextField ellipsis small light>
                                    {joinUrl(ECOMMERCE_ENDPOINT, `${getEcommerceName(item)}/p/${product.slug}`)}
                                </TextField>
                            </Flex>
                            <Flex gap10 alignCenter>
                                <a href={link} target="_blank" rel="noreferrer">
                                    <SquareButton removeBackground>
                                        <span className="fa-regular fa-external-link"></span>
                                    </SquareButton>
                                </a>
                            </Flex>
                        </ProductEcommerceLine>
                    );
                })}
            </Flex>
        </Flex>
    );
};

const getDefaultSearchEngine = (product: IProduct) => {
    return {
        productId: product.id,
        slug: product.slug,
        title: product.searchEngineTitle ?? "",
        description: product.searchEngineDescription ?? "",
    };
};

const EcommerceSearchEngine = (props: ProductNavigationProfileProps) => {
    const [searchEngine, setSearchEngine] = useState<ProductSearchEngineInput>(getDefaultSearchEngine(props.product));
    const alert = useAlert();

    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setSearchEngine(getDefaultSearchEngine(props.product));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product]);

    const putMutation = useMutation((data: ProductSearchEngineInput) => putProductSearchEngine(data), {
        onSuccess: (data) => {
            props.onUpdateProduct({
                ...props.product,
                searchEngineTitle: data.title,
                searchEngineDescription: data.description,
                slug: data.slug,
            });
            alert.success("El producto ha sido actualizado con éxito");
        },
    });

    const handleSaveChanges = () => {
        putMutation.mutate(searchEngine);
    };

    const isValidSlug = (input: string) => {
        const slugPattern = /^[a-zA-Z0-9-_]+$/;
        return slugPattern.test(input);
    };

    const onChange = (e: any) => {
        let value: string = e.target.value;

        if (e.target.name === "slug") {
            value = value.replaceAll(" ", "-");
            if (isValidSlug(value) || value === "") {
                setSearchEngine({ ...searchEngine, [e.target.name]: value });
                setHasChanges(true);
            }
        } else {
            setSearchEngine({ ...searchEngine, [e.target.name]: value });
            setHasChanges(true);
        }
    };

    return (
        <NavigationCard
            {...props}
            title="Motor de búsqueda"
            actionsComponent={<NavigationActions isLoading={putMutation.isLoading} disabled={!hasChanges} onSave={handleSaveChanges} />}
        >
            <Flex column gap20>
                <Flex column gap5>
                    <TextField small light>
                        Slug
                    </TextField>
                    <DefaultInput name="slug" value={searchEngine.slug} onChange={onChange} placeholder="Slug del producto" />
                </Flex>
                <Flex column gap5>
                    <TextField small light>
                        Título Motor de búsqueda
                    </TextField>
                    <DefaultInput name="title" value={searchEngine.title} onChange={onChange} placeholder="Titulo" />
                </Flex>
                <Flex column gap5>
                    <TextField small light>
                        Descripción Motor de búsqueda
                    </TextField>
                    <DefaultTextarea
                        rows={3}
                        name="description"
                        value={searchEngine.description}
                        onChange={onChange}
                        placeholder="Descripción"
                    />
                </Flex>
            </Flex>
        </NavigationCard>
    );
};

function ProductEcommerceVisibility(props: ProductNavigationProfileProps) {
    const intl = useIntl();
    const { product } = props;
    const timerChangesRef = React.useRef<NodeJS.Timeout>();
    const [hasChanges, setHasChanges] = useState(false);

    const [productUpdateBody, setProductUpdateBody] = useState<ProductPartialUpdateInput>({
        ecommerceStatus: props.product.ecommerceStatus,
        hideChildsInEcommerce: props.product.hideChildsInEcommerce,
    });

    const alert = useAlert();

    const saveProductMutation = useMutation((body: ProductPartialUpdateData) => productPartialUpdate(body), {
        onSuccess: (data) => {
            props.onUpdateProduct({
                ...props.product,
                ecommerceStatus: data.ecommerceStatus ?? "visible",
                hideChildsInEcommerce: !!data.hideChildsInEcommerce,
            });
            alert.success("El producto ha sido actualizado con éxito");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        setProductUpdateBody({
            ecommerceStatus: props.product.ecommerceStatus,
            hideChildsInEcommerce: props.product.hideChildsInEcommerce,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product]);

    useEffect(() => {
        if (timerChangesRef.current) {
            clearTimeout(timerChangesRef.current);
        }
        timerChangesRef.current = setTimeout(() => {
            setHasChanges(checkChanges());
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productUpdateBody]);

    const checkChanges = () => {
        const productChanges = productUpdateBody.ecommerceStatus !== props.product.ecommerceStatus;
        const childsChanges = productUpdateBody.hideChildsInEcommerce !== props.product.hideChildsInEcommerce;
        return productChanges || childsChanges;
    };

    const handleSaveChanges = () => {
        saveProductMutation.mutate({ id: props.product.id, isVariant: false, data: productUpdateBody });
    };

    const onChangeEcommerceStatus = (status: ProductEcommerceStatusType) => {
        setProductUpdateBody({ ...productUpdateBody, ecommerceStatus: status });
    };

    return (
        <NavigationCard
            {...props}
            title={intl.formatMessage(messages.ecommerceVisibility)}
            actionsComponent={
                <NavigationActions disabled={!hasChanges} onSave={handleSaveChanges} isLoading={saveProductMutation.isLoading} />
            }
        >
            <Flex column gap={20}>
                <Flex gap15 alignStart>
                    <Flex column gap5>
                        <Flex column gap10>
                            <GroupButtonRow
                                activeOption={productUpdateBody.ecommerceStatus}
                                onClickOption={(option) => onChangeEcommerceStatus(option.value as ProductEcommerceStatusType)}
                                options={[
                                    { title: "Visible", value: "visible", icon: "wahioicon-eye" },
                                    { title: "Oculto", value: "hidden", icon: "wahioicon-eye-slash" },
                                    { title: "Agotado", value: "outOfStock", icon: "wahioicon-store-slash" },
                                ]}
                            />
                            {product.childs.length > 0 && product.ecommerceStatus !== "hidden" && (
                                <Flex column gap5 marginTop={10}>
                                    <TextField small light>
                                        Componentes
                                    </TextField>
                                    <GroupButtonRow
                                        smallView
                                        onClickOption={(option) => {
                                            setProductUpdateBody({ ...productUpdateBody, hideChildsInEcommerce: option.value });
                                        }}
                                        activeOption={product.hideChildsInEcommerce}
                                        options={[
                                            { title: "Ocultar", value: true, icon: "wahioicon-times" },
                                            { title: "Mostrar", value: false, icon: "wahioicon-check" },
                                        ]}
                                    />
                                </Flex>
                            )}
                        </Flex>
                        {product.ecommerceStatus === "hidden" && (
                            <span className="text-light text-small mt-1">El producto no se mostrara en el ecommerce</span>
                        )}
                        {product.ecommerceStatus === "outOfStock" && (
                            <span className="text-light text-small mt-1">El producto se mostrara como agotado en el ecommerce</span>
                        )}
                        {product.ecommerceStatus === "visible" && (
                            <>
                                {!product.hideChildsInEcommerce ? (
                                    <span className="text-light text-small mt-1">
                                        El producto y sus componentes se mostraran en el ecommerce
                                    </span>
                                ) : (
                                    <span className="text-light text-small mt-1">El producto se mostrara en el ecommerce</span>
                                )}
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </NavigationCard>
    );
}

function ProductEcommercePreview(props: ProductNavigationProfileProps) {
    const intl = useIntl();
    const [product, setProduct] = useState(props.product);
    const [ecommerceLabel, setEcommerceLabel] = useState<ProductEcommerceLabel>();
    const timerChangesRef = React.useRef<NodeJS.Timeout>();
    const [hasChanges, setHasChanges] = useState(false);
    const [initialDate] = useState(customMoment().format("YYYY-MM-DD"));
    const alert = useAlert();

    const updateMutation = useMutation((product: IProduct) => createUpdateProduct(product), {
        onSuccess: (data) => {
            setProduct(data);
            setEcommerceLabel(getDefaultEcommerceLabel(data));
            props.onUpdateProduct(data);
            alert.success("El producto ha sido actualizado con éxito");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        setProduct(props.product);
        setEcommerceLabel(getDefaultEcommerceLabel(props.product));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product]);

    useEffect(() => {
        if (timerChangesRef.current) {
            clearTimeout(timerChangesRef.current);
        }
        timerChangesRef.current = setTimeout(() => {
            setHasChanges(checkChanges());
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, ecommerceLabel]);

    const checkChanges = () => {
        var originalLabel = JSON.stringify(getDefaultEcommerceLabel(product));
        var newLabel = JSON.stringify(ecommerceLabel);

        const labelChanges = originalLabel !== newLabel;
        const productChanges = product.ecommerceStatus !== props.product.ecommerceStatus;
        const childsChanges = product.hideChildsInEcommerce !== props.product.hideChildsInEcommerce;
        return labelChanges || productChanges || childsChanges;
    };

    const handleSaveChanges = () => {
        let productCopy = { ...product };
        if (ecommerceLabel) {
            productCopy.ecommerceLabels = [ecommerceLabel];
            productCopy.ecommerceLabelsAsString = JSON.stringify([ecommerceLabel]);
            productCopy.ecommerceStatus = product.ecommerceStatus;
        }

        updateMutation.mutate(productCopy);
    };

    const getDefaultEcommerceLabel = (product: IProduct) => {
        if (product.ecommerceLabels && product.ecommerceLabels.length > 0) {
            return product.ecommerceLabels[0];
        } else {
            const label: ProductEcommerceLabel = {
                imageUrl: undefined,
                title: "",
                position: "topRight",
                discountValue: 0,
                startDate: initialDate,
                endDate: initialDate,
            };
            return label;
        }
    };

    const getProductImage = () => {
        if (product.images && product.images.length > 0) {
            return product.images[0].url;
        }
        return CloudImages.defaultImage;
    };

    const getBasePrice = () => {
        if (ecommerceLabel && ecommerceLabel.discountValue) {
            var discount = (product.price * 100) / (100 - ecommerceLabel.discountValue);
            return getRoundNumber(discount);
        }
        return 0;
    };

    const discountValue = ecommerceLabel?.discountValue ?? 0;

    return (
        <NavigationCard
            {...props}
            title={intl.formatMessage(messages.ecommerceProductPreview)}
            actionsComponent={<NavigationActions disabled={!hasChanges} onSave={handleSaveChanges} isLoading={updateMutation.isLoading} />}
        >
            <Flex column gap={20}>
                <Flex column gap10>
                    {ecommerceLabel && (
                        <Flex gap10 flexWrap>
                            <EcommerceLabelPanel
                                product={product}
                                ecommerceLabel={ecommerceLabel}
                                setEcommerceLabel={(value) => setEcommerceLabel(value)}
                            />

                            <Flex column gap5 padding={15} border borderRadius={15}>
                                <span>Descuento %</span>
                                <Flex gap5 alignCenter>
                                    <span className="wahioicon-percent"></span>
                                    <TextBoxNumeral
                                        rounded
                                        onNumberChange={(value) => setEcommerceLabel({ ...ecommerceLabel, discountValue: value })}
                                        value={ecommerceLabel.discountValue}
                                        format={"number"}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
                <ProductCardPreviewContainer className={product.ecommerceStatus}>
                    <div className="image">
                        <img src={getProductImage()} alt="" />
                        {product.ecommerceStatus !== "outOfStock" && ecommerceLabel?.imageUrl && (
                            <div className={`image-label ${ecommerceLabel.position}`}>
                                <img src={ecommerceLabel.imageUrl} alt="" />
                            </div>
                        )}
                        {product.ecommerceStatus === "outOfStock" && (
                            <div className="out-of-stock">
                                <span>Agotado</span>
                            </div>
                        )}
                    </div>
                    <Flex column className="title-content">
                        <span className="product-title">{product.name}</span>
                        <Flex column>
                            {discountValue > 0 && <span className="discount-line">{formatMoney(getBasePrice())}</span>}
                            <Flex gap10>
                                <span className="product-price">{formatMoney(product.price)}</span>
                                {discountValue > 0 && <span className="product-discount">{discountValue}%</span>}
                            </Flex>
                        </Flex>
                    </Flex>
                </ProductCardPreviewContainer>
            </Flex>
        </NavigationCard>
    );
}

interface EcommerceLabelPanelProps {
    product: IProduct;
    ecommerceLabel: ProductEcommerceLabel;
    setEcommerceLabel: (ecommerceLabel: ProductEcommerceLabel) => void;
}

const EcommerceLabelPanel = (props: EcommerceLabelPanelProps) => {
    const { ecommerceLabel, setEcommerceLabel } = props;
    const [showWahioCloud, setShowWahioCloud] = useState(false);
    const [ecommerceIconsType, setEcommerceIconsType] = useState<EcommerceIconType>("flat");
    const intl = useIntl();

    return (
        <Flex gap10 padding={15} border borderRadius={15} column>
            <span className="text-light text-small">Agregar etiqueta</span>
            {props.product.ecommerceStatus === "outOfStock" && (
                <Flex gap10 alignCenter>
                    <span className="wahioicon-exclamation-triangle"></span>
                    <span className="text-light text-small ">El producto debe estar visible para agregar una etiqueta</span>
                </Flex>
            )}
            <Flex gap10 flexWrap>
                {showWahioCloud && (
                    <WahioCloudItemsModal
                        show={showWahioCloud}
                        setShow={setShowWahioCloud}
                        onClickItems={(items) => props.setEcommerceLabel({ ...ecommerceLabel, imageUrl: items[0].url })}
                        allowSelection
                        defaultType="image"
                    />
                )}
                <Dropdown icon="wahioicon-images" title="Galería" ignoreInternalClicks>
                    <Flex column gap15 padding={20}>
                        <ImageTabViewContainer>
                            {Object.keys(ecommerceIcons).map((key, index) => (
                                <div
                                    className={`tab ${key === ecommerceIconsType ? "active" : ""}`}
                                    onClick={() => setEcommerceIconsType(key as EcommerceIconType)}
                                    key={index}
                                >
                                    Pack {index + 1}
                                </div>
                            ))}
                        </ImageTabViewContainer>

                        <GridTemplate w100 size={50}>
                            {ecommerceIcons[ecommerceIconsType].map((item, index) => {
                                return (
                                    <FlexImageStyle
                                        onClick={() => setEcommerceLabel({ ...ecommerceLabel, imageUrl: item.url })}
                                        padding={5}
                                        pointer
                                        shadowHover
                                        w100
                                        key={index}
                                    >
                                        <img loading="lazy" src={item.url} alt="" />
                                    </FlexImageStyle>
                                );
                            })}
                        </GridTemplate>
                    </Flex>
                </Dropdown>
                <DefaultButton rounded type="button" onClick={() => setShowWahioCloud(true)}>
                    <span className="wahioicon-cloud-upload-alt"></span>
                </DefaultButton>
                {ecommerceLabel.imageUrl && (
                    <Dropdown title={checkAndGetMessage(intl, messages, ecommerceLabel?.position)}>
                        <Flex column padding={15}>
                            {EcommerceLabelPositions.map((item, index) => (
                                <DefaultButton
                                    key={index}
                                    onClick={() => setEcommerceLabel({ ...ecommerceLabel, position: item })}
                                    className="dropdown-button"
                                >
                                    {checkAndGetMessage(intl, messages, item)}
                                </DefaultButton>
                            ))}
                        </Flex>
                    </Dropdown>
                )}
                {ecommerceLabel.imageUrl && (
                    <>
                        <FlexImageStyle width={30}>
                            <img src={ecommerceLabel.imageUrl} alt="" />
                        </FlexImageStyle>
                        <SquareButton onClick={() => setEcommerceLabel({ ...ecommerceLabel, imageUrl: undefined })}>
                            <span className="wahioicon-trash"></span>
                        </SquareButton>
                    </>
                )}
            </Flex>
        </Flex>
    );
};
