import { IPaginationRequest, WahioFile } from ".";
import { AccountingSummary } from "./accounting";
import { ProductPriceTypes } from "./product";

interface ICustomerLabel {
    id?: string;
    customerId?: string;
    label?: string;
}

export interface ContactLabelBase {
    color: string;
    name: string;
}

export interface CustomerContactLabelInput extends ContactLabel {
    customerId: string;
}

export interface ContactLabel extends ContactLabelBase {
    id?: string;
    accountId: string;
    dateCreated?: Date;
    dateUpdate?: Date;
}

export interface ContactLabelContact {
    id: string;
    contactId: string;
    contactLabelId: string;
    groupKey: string;
    contactLabel?: ContactLabel;
    createdAt: string;
}

export class ContactLabelImpl implements ContactLabel {
    id?: string;
    accountId: string;
    name: string = "";
    color: string = "";
    dateCreated?: Date;
    dateUpdate?: Date;

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export type ContactType =
    | "isCourier"
    | "isSupplier"
    | "isCustomer"
    | "isCompany"
    | "isSeller";

export const CONTACT_TYPE_LIST: ContactType[] = [
    "isCourier",
    "isSupplier",
    "isCustomer",
    "isCompany",
];

export interface IContactEmail extends ICustomerLabel {
    email: string;
    emailConfirmed?: boolean;
}

export interface IContactPhone extends ICustomerLabel {
    phoneNumber: string;
    phonePrefix: string;
}

export interface IContactAddress extends ICustomerLabel {
    description: string;
    latitude?: number;
    longitude?: number;
    countryCode?: string;
    stateCode?: string;
    country: string;
    state: string;
    city: string;
    contactName: string;
    phoneNumber: string;
    additionalInformation: string;
    neighborhood: string;
}
export class CustomerAddress implements IContactAddress {
    description: string = "";
    latitude?: number | undefined;
    longitude?: number | undefined;
    country: string = "";
    state: string = "";
    city: string = "";
    contactName: string = "";
    phoneNumber: string = "";
    additionalInformation: string = "";
    neighborhood: string = "";
    id?: string | undefined;
    auxid?: string | undefined;
    customerId?: string | undefined;
    label?: string | undefined;

    constructor(auxid: string) {
        this.auxid = auxid;
    }
}
export interface Contact {
    id?: string;
    accountId: string;
    isCompany: boolean;
    firstName: string;
    lastName: string;
    type?: string;
    verificationNumber?: string;
    isIvaResponsable?: boolean;
    fiscalRegime?: string;
    fiscalResponsabilities?: string;
    email: string;
    address: string;
    phoneNumber: string;
    phonePrefix?: string;
    identificationNumber: string;
    identificationType?: string;
    dateCreated?: Date;
    status?: string;
    emails: IContactEmail[];
    phones: IContactPhone[];
    addresses: IContactAddress[];
    labels?: ContactLabelContact[];
    labelsAsString?: string;
    tags?: string[];

    isCourier: boolean;
    isSupplier: boolean;
    isCustomer: boolean;
    isSeller: boolean;

    image?: string;
    files?: WahioFile[];
    birthday?: Date | string;

    typeDocumentIdentificationId?: string;
    typePersonId?: string;
    cityId?: string;
    typeRegimeId?: string;
    typeLiabilityId?: string;
    merchantRegistration?: string;
    observation?: string;

    contactDebtsBody?: AccountingSummary; // ONLY UI

    defaultPriceName?: ProductPriceTypes;
}
export class ContactImpl implements Contact {
    id?: string;
    accountId: string;
    isCompany: boolean = false;
    firstName: string = "";
    lastName: string = "";
    type?: string = "";
    verificationNumber?: string = "";
    isIvaResponsable?: boolean = undefined;
    fiscalRegime?: string = "";
    fiscalResponsabilities?: string = "";
    email: string = "";
    address: string = "";
    phoneNumber: string = "";
    phonePrefix?: string;
    identificationNumber: string = "";
    identificationType?: string;
    dateCreated?: Date;
    status?: string;
    emails: IContactEmail[] = [];
    phones: IContactPhone[] = [];
    addresses: IContactAddress[] = [];
    labels: ContactLabelContact[] = [];

    isCourier: boolean = false;
    isSupplier: boolean = false;
    isCustomer: boolean = false;
    isSeller: boolean = false;

    typeDocumentIdentificationId?: string;
    typePersonId?: string;
    cityId?: string;
    typeRegimeId?: string;
    typeLiabilityId?: string;
    merchantRegistration?: string;
    observation?: string;

    image?: string;
    files?: WahioFile[];

    defaultPriceName?: ProductPriceTypes;

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export interface ICustomerDebt {
    customerId: string;
    customer: Contact;
    value: number;
}

export interface ICustomerSummary {
    totalCreditAmount: number;
    paidAmount: number;
    paidCount: number;
    creditCount: number;
    partialCount: number;
}

export type SearchContactSortList = "default" | "date" | "name" | "identificationNumber" | "email" | "address";
export interface ISearchContactSort {
    sortDesc: boolean;
    sort: SearchContactSortList;
}
export interface ContactSearchModel extends IPaginationRequest, ISearchContactSort {
    accountId: string;
    searchValue: string;
    contactLabelId?: string;
    isCourier?: boolean;
    isSupplier?: boolean;
    isCompany?: boolean;
    isCustomer?: boolean;
    isSeller?: boolean;
    includeLabels?: boolean;
    includeAddresses?: boolean;
}
