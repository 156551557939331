import styled from "styled-components";

export const ProductContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    gap: 10px;

    .product-code {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
        .image {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    &.active {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    &.out {
        opacity: 0.3;
        cursor: default;
        &:hover {
            background: transparent;
            .image {
                background: transparent;
            }
        }
        .title {
            text-decoration: line-through;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    border-bottom: 3px solid transparent;
    &.key-focus {
        border-color: transparent;
        border-bottom: 3px solid ${(props) => props.theme.palette.primary};
        background: ${(props) => props.theme.palette.primary}20;
    }
`;

export const ProductTitle = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StockContainer = styled.div`
    margin-left: auto;
    width: 40px;
    min-width: 40px;
`;
