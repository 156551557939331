import React from "react";
import Modal, { IModalShow } from "../../modals/Modal";
import { useIntl } from "react-intl";
import messages from "./messages";
import { IWarehouse } from "../../../api/models/warehouse";
import WarehouseForm, { WarehouseFormProps } from ".";

interface IProps extends WarehouseFormProps, IModalShow {}

export default function WarehouseFormModal(props: IProps) {
    const intl = useIntl();
    const onCreate = (value: IWarehouse) => {
        props.onCreate(value);
        props.setShow(false);
    };
    return (
        <Modal
            scrollInside={false}
            title={props.warehouseUpdate ? "Editar Bodega" : intl.formatMessage(messages.createWarehouse)}
            showHeader={true}
            {...props}
        >
            <WarehouseForm {...props} onCreate={onCreate} onCancel={() => props.setShow(false)} />
        </Modal>
    );
}
