import _, { pick } from "lodash";
import React, { useEffect, useState } from "react";
import { AccountUserType } from "../../../api/models/accountUser";
import { BasePermissionLoaded, IBasePermission } from "../../../api/models/accountUserPermissions";
import appMessages from "../../../messages";
import { assignDeepValue } from "../../../utils";
import { PermissionCardListContainer } from "../styled";
import PermissionCard from "./PermissionCard";

interface PermissionFormProps {
    permission: IBasePermission;
    onChange: (value: IBasePermission) => void;
    blocked?: boolean;
    userType?: AccountUserType;
}

export const USER_SELLER_PERMISSION_LIST = ["order", "restaurant", "contact"];

const baseActions = ["create", "update", "delete"];

interface PermissionNameKey {
    key: string;
    name: string;
}

export default function PermissionForm(props: PermissionFormProps) {
    const [permissionNames, setPermissionNames] = useState<PermissionNameKey[]>([]);

    useEffect(() => {
        let permissionObj = new BasePermissionLoaded(false);
        if (props.userType === "seller") {
            permissionObj = pick(props.permission, USER_SELLER_PERMISSION_LIST);
        }

        var keys = Object.keys(permissionObj);
        var names = keys.map((key) => {
            let message = (appMessages as any)[key];
            let item: PermissionNameKey = {
                key,
                name: message?.defaultMessage ?? key,
            };
            return item;
        });
        names = _.sortBy(names, "name");
        setPermissionNames(names);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onActionChange = (e: any) => {
        let permission = props.permission;
        let splitValue = e.target.name.split(".");
        if (baseActions.find((x) => x === splitValue[1]) && e.target.checked) {
            splitValue[1] = "read";
            assignDeepValue(permission, splitValue.join("."), e.target.checked);
        }

        assignDeepValue(permission, e.target.name, e.target.checked);
        props.onChange(permission);
    };
    const setAll = (keyPermission: string, value: boolean) => {
        let permission = props.permission;

        let obj = (permission as any)[keyPermission];
        if (!obj) {
            obj = (new BasePermissionLoaded(false) as any)[keyPermission];
        }

        if (obj) {
            Object.keys(obj).forEach((v) => (obj[v] = value));

            assignDeepValue(permission, `${keyPermission}`, obj);
            props.onChange(permission);
        }
    };
    return (
        <PermissionCardListContainer style={{ opacity: props.blocked ? "0.3" : 1 }}>
            {props.blocked && <div className="blocked"></div>}
            {permissionNames.map((item, index) => {
                return (
                    <PermissionCard
                        setAll={setAll}
                        onActionChange={onActionChange}
                        key={index}
                        permission={props.permission}
                        keyPermission={item.key}
                    />
                );
            })}
        </PermissionCardListContainer>
    );
}
