import React, { createContext, useEffect, useState } from "react";
import { SCREEN_PREVIEW_PARAM } from "../../components/Orders/OrderMultiPanel";
import { useLocalStorage } from "../../utils/hooks";

interface OrderScreenPreviewContextProps {
    showPreviewScreen: boolean;
    setShowPreviewScreen: (show: boolean) => void;
    screenPreview: Window | undefined;
    setScreenPreview: (screen: Window | undefined) => void;
    openWindowScreen: () => void;
}

const OrderScreenPreviewContext = createContext({} as OrderScreenPreviewContextProps);

const OrderScreenPreviewContextProvider = (props: any) => {
    const [showPreviewScreen, setShowPreviewScreen] = useLocalStorage("wahio_preview_screen", false);
    const [screenPreview, setScreenPreview] = useState<Window>();

    useEffect(() => {
        return () => {
            if (screenPreview) {
                screenPreview.close();
                setScreenPreview(undefined);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openWindowScreen = () => {
        if (screenPreview) {
            screenPreview.close();
        }
        const url = `/?${SCREEN_PREVIEW_PARAM}=true`;
        var reattachedWindow = window.open(url, "OrderSummary", "width=800,height=600");
        if (reattachedWindow) setScreenPreview(reattachedWindow);
    };

    return (
        <OrderScreenPreviewContext.Provider
            value={{
                showPreviewScreen,
                setShowPreviewScreen,
                screenPreview,
                setScreenPreview,
                openWindowScreen,
            }}
        >
            {props.children}
        </OrderScreenPreviewContext.Provider>
    );
};

export const useOrderScreenPreviewContext = () => {
    return React.useContext(OrderScreenPreviewContext);
};

export default OrderScreenPreviewContextProvider;
