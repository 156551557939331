import React, { useState, useEffect } from "react";
import { useAlert } from "../../Alerts/Alert";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { IWarehouseLocation, IWarehouseProductLocation, IWarehouseProductLocationMovement } from "../../../api/models/warehouseLocation";
import { PrimaryButton, DefaultButton } from "../../_controls";
import LoadingDualRing from "../../LoadingDualRing";
import Modal, { IModalShow } from "../../modals/Modal";
import TextBoxNumeral from "../../TextBoxNumeral";
import { NewProductLocationContainer } from "./styled";
import WarehouseProductLocationCard from "./WarehouseProductLocationCard";
import Select from "react-select";
import { getSelectOption } from "../../../utils/select";
import { SelectBody } from "../../Select/styled";
import { getColorValue } from "../../../constants/colors";
import { FormGroup, FormFooter } from "../../_controls/forms/styled";

interface IProps extends IModalShow {
    productLocation: IWarehouseProductLocation;
    onFinish: () => void;
}

export default function WarehouseLocationMovementModal(props: IProps) {
    const alert = useAlert();
    const [state, setState] = useState<IWarehouseProductLocationMovement & { location?: IWarehouseLocation }>({
        quantity: 0,
        locationDestinyCode: "",
        locationOriginCode: props.productLocation.warehouseLocation.code,
        productId: props.productLocation.warehouseProduct.productId,
        warehouseId: props.productLocation.warehouseLocation.warehouseId,
    });
    const [warehouseLocations, setWarehouseLocations] = useState<IWarehouseLocation[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        wahioFetch.get(
            productEndpoint.get.warehouseLocationsByWarehouse(props.productLocation.warehouseProduct.warehouseId ?? ""),
            (success) => {
                setWarehouseLocations(success.data);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandleChange = () => {
        setIsFetching(true);
        wahioFetch.post(
            productEndpoint.post.warehouseProductLocationMovement,
            state,
            (success) => {
                alert.success("El movimiento fue creado con éxito.");
                setIsFetching(false);
                props.onFinish();
                setState({
                    quantity: 0,
                    locationDestinyCode: "",
                    locationOriginCode: props.productLocation.warehouseLocation.code,
                    productId: props.productLocation.warehouseProduct.productId,
                    warehouseId: props.productLocation.warehouseLocation.warehouseId,
                });
                props.setShow(true);
            },
            (error) => {
                if (error.data && error.data.message) {
                    alert.error(error.data.message);
                } else {
                    alert.error("Hubo un error al intentar crear el movimiento.");
                }
                setIsFetching(false);
            }
        );
    };

    return (
        <Modal
            id="modal-movement-location"
            scrollInside={false}
            ignoreOutsideClick
            title={"Movimiento de producto entre ubicaciones"}
            showHeader={true}
            {...props}
        >
            <NewProductLocationContainer>
                <WarehouseProductLocationCard hideDropdown={true} item={props.productLocation} setSelectedProductLocation={() => {}} />
                <div className="body mt-2">
                    <div className="body-item mr-1">
                        <FormGroup>
                            <label htmlFor="stock">Stock a Mover</label>
                            <TextBoxNumeral
                                format="number"
                                onNumberChange={(value) => setState({ ...state, quantity: value })}
                                value={state.quantity}
                            />
                        </FormGroup>
                    </div>

                    <div className="body-item">
                        <FormGroup>
                            <label htmlFor="stock">Ubicación de Destino</label>
                            <div className="flex-line">
                                <SelectBody className="select-body">
                                    <Select
                                        placeholder="Seleccionar"
                                        className="select-min-len"
                                        classNamePrefix="select"
                                        key={state.locationDestinyCode}
                                        defaultValue={
                                            state.locationDestinyCode
                                                ? getSelectOption({ id: state.locationDestinyCode, value: state.locationDestinyCode })
                                                : undefined
                                        }
                                        isDisabled={false}
                                        onChange={(value) =>
                                            setState({
                                                ...state,
                                                locationDestinyCode: value?.id ?? "",
                                                location: warehouseLocations.find((x) => x.code === value?.id),
                                            })
                                        }
                                        isRtl={false}
                                        isSearchable={true}
                                        name="location"
                                        options={warehouseLocations.map((item) =>
                                            getSelectOption({ id: item.code, value: `${item.code} (${item.globalStock})` })
                                        )}
                                    />
                                </SelectBody>
                                <div className="location" style={{ background: getColorValue(state.location?.color) }}></div>
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <FormFooter>
                    {isFetching ? (
                        <LoadingDualRing />
                    ) : (
                        <>
                            <DefaultButton onClick={() => props.setShow(false)} className="mr-1" rounded>
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton disabled={state.quantity <= 0 || !state.locationDestinyCode} onClick={onHandleChange} rounded>
                                Confirmar
                            </PrimaryButton>
                        </>
                    )}
                </FormFooter>
            </NewProductLocationContainer>
        </Modal>
    );
}
