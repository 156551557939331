import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { NotificationContainer } from "./templateStyled";
import { OrderMessageNotificationData } from "../../api/socket/models";
import { notificationAudio } from "../../pages/AppContainer";
import PATHS from "../../constants/paths";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";

var parse = require("html-react-parser");

export default function OrderMessageNotification(props: OrderMessageNotificationData) {
    const [redirect, setRedirect] = useState(false);
    const { appSettingsState } = useContext(AppSettingsContext)


    useEffect(() => {
        if (!appSettingsState.turnOfNotifications) {
            notificationAudio.play();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (redirect) {
        if (props.close) props.close();
        return <Navigate to={PATHS.orderShipmentProfile(props.orderId)} />;
    }

    return (
        <NotificationContainer>
            <div className="notification-icon red">{/* <span className="wahioicon-shopping-basket"></span> */}</div>
            <div className="FlexCol body">
                {props.userName && (
                    <div className="flex-row cursor" onClick={() => setRedirect(true)}>
                        <div className="image small" title={props.userName}>
                            {props.userImage ? (
                                <img src={props.userImage} alt={props.userName} />
                            ) : (
                                <span>{props.userName.substring(0, 1)}</span>
                            )}
                        </div>
                        <span className="title mr-1">{props.userName} ha hecho un comentario </span>
                    </div>
                )}

                <div className="flex-row mt-1 cursor" onClick={() => setRedirect(true)}>
                    <div className="FlexCol content">
                        <span>Orden #{props.orderNumber}</span>
                        <span>{parse(props.comment)}</span>
                    </div>
                </div>
            </div>
        </NotificationContainer>
    );
}
