import { defineMessages } from "react-intl";

const base = "components.wahioCloud";

//"image" | "text" | "video" | "audio" | "compression" | "presentation" | "editable" | "other"

export default defineMessages({
    image: {
        id: `${base}.image`,
        defaultMessage: "Imagenes",
    },
    text: {
        id: `${base}.text`,
        defaultMessage: "Texto",
    },
    video: {
        id: `${base}.video`,
        defaultMessage: "Video",
    },
    videoLink: {
        id: `${base}.videoLink`,
        defaultMessage: "Link de Video",
    },
    audio: {
        id: `${base}.audio`,
        defaultMessage: "Audio",
    },
    compression: {
        id: `${base}.compression`,
        defaultMessage: "Compresión",
    },
    presentation: {
        id: `${base}.presentation`,
        defaultMessage: "Presentaciones",
    },
    editable: {
        id: `${base}.editable`,
        defaultMessage: "Archivos Editables",
    },
    other: {
        id: `${base}.other`,
        defaultMessage: "Otros",
    },
    date: {
        id: `${base}.date`,
        defaultMessage: "Fecha Creación",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre",
    },
    size: {
        id: `${base}.size`,
        defaultMessage: "Tamaño",
    },
    default: {
        id: `${base}.default`,
        defaultMessage: "Por Defecto",
    },
    dateCreated: {
        id: `${base}.dateCreated`,
        defaultMessage: "Fecha de Creación",
    },
    type: {
        id: `${base}.type`,
        defaultMessage: "Tipo",
    },
    accountUserName: {
        id: `${base}.accountUserName`,
        defaultMessage: "Nombre de Usuario",
    },
    dateDesc: {
        id: `${base}.dateDesc`,
        defaultMessage: "Fecha ↓",
    },
    dateAsc: {
        id: `${base}.dateAsc`,
        defaultMessage: "Fecha ↑",
    },
    nameDesc: {
        id: `${base}.nameDesc`,
        defaultMessage: "Nombre ↓",
    },
    nameAsc: {
        id: `${base}.nameAsc`,
        defaultMessage: "Nombre ↑",
    },
    sizeDesc: {
        id: `${base}.sizeDesc`,
        defaultMessage: "Tamaño ↓",
    },
    sizeAsc: {
        id: `${base}.sizeAsc`,
        defaultMessage: "Tamaño ↑",
    },
    mainFolder: {
        id: `${base}.mainFolder`,
        defaultMessage: "Carpeta Principal",
    },
});
