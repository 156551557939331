import React, { KeyboardEventHandler, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { MultiValue } from "react-select";
import CreateTable from "react-select/creatable";
import { ProductCreationProps } from "..";
import { SelectBaseOptionColor } from "../../../../api/models";
import {
    IProductVariant,
    ProductVariantImp,
    VariantOption,
    VariantOptionImpl,
    VariantOptionValueImpl,
} from "../../../../api/models/product";
import { IProductTax, ITax } from "../../../../api/models/tax";
import { getColorValue } from "../../../../constants/colors";
import { VariantOptionExample, variantOptionsExamples } from "../../../../constants/variants";
import { TaxContext } from "../../../../store/contexts/TaxContext";
import { capitalize, getUniqueId } from "../../../../utils";
import { combineArrays } from "../../../../utils/combinations";
import { useAlert } from "../../../Alerts/Alert";
import Dropdown from "../../../Dropdown";
import DropdownClassicOptions from "../../../DropdownClassic/DropdownClassicOptions";
import { selectColorStyles } from "../../../Select/helper";
import { SelectBody } from "../../../Select/styled";
import { DefaultButton, DefaultInput, Flex, GridTemplate, SquareButton } from "../../../_controls";
import WahioTooltip from "../../../_controls/WahioTooltip";
import GroupButtonRow from "../../../_controls/buttons/GroupButtonRow";
import CheckBox from "../../../_controls/inputs/CheckBox";
import { useIsMobileListener } from "../../../index";
import { useModal } from "../../../modals/Modal";
import TaxFormModal from "../../Taxes/TaxForm/TaxFormModal";
import { TaxSelector } from "../PricesForm";
import { mergeProductVariants, updateProductVariantLines } from "../helpers";
import messages from "../messages";
import {
    FormInputProduct,
    ProductVariantCardListContainer,
    ProductVariantOptionCard,
    ProductVariantsFormContainer,
    TextWithLine,
} from "../styled";
import ProductVariantOptionPreview from "./ProductVariantOptionPreview";
import { ProductVariantRow } from "./ProductVariantRow";

const components = {
    DropdownIndicator: null,
};

interface VariantOptionCardProps {
    variantOption: VariantOption;
    variantOptionExamples: VariantOptionExample[];
    onChange: (option: VariantOption) => void;
    onAddVariantOption: (name: string) => void;
    onRemoveVariantOption: (option: VariantOption) => void;
}

const VariantOptionCard = (props: VariantOptionCardProps) => {
    const { variantOption } = props;
    const alert = useAlert();
    const [inputValue, setInputValue] = useState("");
    const intl = useIntl();

    const getSelectOption = (item?: { name: string }) => {
        let option: SelectBaseOptionColor = {
            id: item?.name ?? "",
            value: item?.name ?? "",
            label: item?.name ?? "",
            color: getColorValue(item?.name),
        };
        return option;
    };

    const onAddNewVariantOptionValue = (value: string) => {
        let values = variantOption.values ?? [];
        if (props.variantOption.name === "") {
            alert.error(intl.formatMessage(messages.nameOfVariantCannotBeEmpty));
            return;
        }
        if (values.find((item) => item.toLocaleLowerCase() === value.toLocaleLowerCase())) {
            alert.error(intl.formatMessage(messages.nameOfVariantAlreadyExists));
            return;
        }
        props.onChange({ ...variantOption, values: [...values, value] });
    };

    const onAddNewVariant = (value: string) => {
        props.onChange({ ...variantOption, name: value });
        props.onAddVariantOption(value);
    };

    const onChangeVariantValue = (items: MultiValue<SelectBaseOptionColor>) => {
        let isRemove = items.length < variantOption.values.length;
        if (isRemove) {
            let values = props.variantOption.values.filter((x) => items.find((item) => item.id === x));
            props.onChange({ ...variantOption, values: values });
        } else {
            let last = items.find((x) => !variantOption.values.find((item) => item === x.id));
            if (last) {
                props.onChange({ ...variantOption, values: [...variantOption.values, last.value] });
            }
        }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        let value = inputValue.trim();
        if (value) {
            switch (event.key) {
                case "Enter":
                case "Tab":
                    onAddNewVariantOptionValue(value);
                    setInputValue("");
                    event.preventDefault();
            }
        }
    };

    const isVariantColor = variantOption.name.toUpperCase() === "COLOR";

    return (
        <ProductVariantOptionCard>
            <Flex gap10 className="variant-options">
                <SelectBody>
                    <CreateTable
                        className={`react-select-basic ${props.variantOption.name ? "item-active" : ""}`}
                        classNamePrefix="select"
                        key={variantOption.key}
                        defaultValue={props.variantOption.name ? getSelectOption({ name: props.variantOption.name }) : undefined}
                        isDisabled={false}
                        isRtl={false}
                        onCreateOption={onAddNewVariant}
                        onChange={(value) => props.onChange({ ...props.variantOption, name: value?.value ?? "" })}
                        isClearable={false}
                        isSearchable={true}
                        placeholder={intl.formatMessage(messages.exColor)}
                        options={props.variantOptionExamples.map((item) => {
                            return getSelectOption({ name: item.name });
                        })}
                    />
                </SelectBody>

                <SelectBody className={`${isVariantColor ? "" : "multi-value-theme-color"} select-body`}>
                    <CreateTable
                        className="react-select-basic"
                        classNamePrefix="select"
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={(value) => onChangeVariantValue(value)}
                        onInputChange={(value) => setInputValue(value)}
                        onKeyDown={handleKeyDown}
                        placeholder={intl.formatMessage(messages.addValuesAndPressEnter)}
                        styles={isVariantColor ? selectColorStyles : undefined}
                        value={variantOption.values?.map((item) => getSelectOption({ name: item }))}
                    />
                </SelectBody>
            </Flex>
            <SquareButton onClick={() => props.onRemoveVariantOption(variantOption)}>
                <span className="wahioicon-trash"></span>
            </SquareButton>
        </ProductVariantOptionCard>
    );
};

interface VariantGroupItem {
    variant: IProductVariant;
    index: number;
}

export interface OptionsConfigVariantProps {
    useSameImage: boolean;
    useSamePrice: boolean;
    showTaxSelector: boolean;
    showOptionReference: boolean;
}

export default function ProductVariantsForm(props: ProductCreationProps) {
    const { product: currentProduct } = props;
    const alert = useAlert();
    const intl = useIntl();
    const productVariants = props.product.variants;
    const variantOptions = props.product.variantOptions;
    const { taxActions } = useContext(TaxContext);
    const [openTaxModal, setOpenTaxModal] = useState(false);
    const [useSameImageInVariant, setUseSameImageInVariant] = useState<string>("");
    const [useSamePriceInVariant, setUseSamePriceInVariant] = useState<string>("");
    const [useSameImage, setUseSameImage] = useState(false);
    const [useSamePrice, setUseSamePrice] = useState(false);
    const [variantOptionExamples, setVariantOptionExamples] = useState(() =>
        variantOptionsExamples.map((item) => {
            const name = intl.formatMessage((messages as any)[item.name]);
            return { ...item, name };
        })
    );
    const [variantsGroup, setVariantsGroup] = useState<{ [key: string]: VariantGroupItem[] }>({});
    const previewModal = useModal();
    const isMobile = useIsMobileListener({ size: 542 });

    useEffect(() => {
        taxActions.fetchIfNotExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let index = 0;

        var groupData = currentProduct.variants.reduce((prev: { [key: string]: VariantGroupItem[] }, item) => {
            let title = item.options.length > 0 ? item.options[0].value : "none";
            if (variantOptions?.length === 1) {
                title = variantOptions[0].name;
            }
            let list = prev[title] ?? [];
            let groupItem = { variant: item, index };
            prev[title] = [...list, groupItem];
            index++;
            return prev;
        }, {});
        setVariantsGroup(groupData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct.variants]);

    useEffect(() => {
        if (props.product.includesVariants && (!variantOptions || variantOptions.length === 0)) {
            handleAddNewOption();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product.includesVariants]);

    const handleAddNewOption = () => {
        let newOptions = props.product.variantOptions ?? [];
        newOptions = [...newOptions, new VariantOptionImpl()];
        props.onChange({ ...props.product, variantOptions: newOptions });
    };

    const onVariantOptionChange = (option: VariantOption) => {
        let newOptions: VariantOption[] = [];

        let variantAlreadyExists = false;
        let lastNames: string[] = [];
        props.product.variantOptions?.forEach((item) => {
            const element = { ...item };
            if (lastNames.includes(option.name) && element.key === option.key) {
                element.key = getUniqueId();
                element.name = "";
                newOptions.push(element);
                variantAlreadyExists = true;
            } else if (element.key === option.key) {
                newOptions.push(option);
            } else {
                newOptions.push(element);
            }
            lastNames.push(item.name);
        });

        if (variantAlreadyExists) {
            alert.info(intl.formatMessage(messages.thereCannotBeTwoVariantsWithTheSameName));
        }

        createProductVariants(newOptions);
    };

    const createProductVariants = (variantOptions: VariantOption[]) => {
        if (variantOptions) {
            let arraysOfArray: any[] = [];

            variantOptions.forEach((option) => {
                if (option.values && option.values.length > 0) {
                    arraysOfArray.push(option.values);
                }
            });

            if (arraysOfArray.length > 0) {
                let combinationResult = combineArrays(arraysOfArray);
                let newProductVariants: IProductVariant[] = [];

                combinationResult.forEach((values) => {
                    let optionValues = values.map((value, index) => {
                        let optionValue = new VariantOptionValueImpl(value, variantOptions[index].name);
                        optionValue.index = index;
                        return optionValue;
                    });
                    let variant = new ProductVariantImp(optionValues, props.product);
                    variant = updateProductVariantLines(variant);
                    variant.sku = props.product.reference + "-" + variant.valuesLine.toUpperCase();
                    newProductVariants.push(variant);
                });
                props.onChange({
                    ...props.product,
                    variants: mergeProductVariants(props.product.variants, newProductVariants),
                    variantOptions: variantOptions,
                    variantOptionsLine: variantOptions.map((item) => item.name).join("-"),
                });
            } else {
                props.onChange({
                    ...props.product,
                    variants: [],
                    variantOptions: variantOptions,
                    variantOptionsLine: variantOptions.map((item) => item.name).join("-"),
                });
            }
        }
    };

    const onClickTax = (value: ITax) => {
        const newProductTax: IProductTax = {
            productId: currentProduct.id,
            taxId: value.id ?? "",
            tax: value,
        };

        let taxes = currentProduct.taxes ? [...currentProduct.taxes, newProductTax] : [newProductTax];
        props.onChange({
            ...props.product,
            taxes,
        });
    };

    const onRemoveVariantOption = (variantOption: VariantOption) => {
        let newVariants = variantOptions?.filter((x) => x.key !== variantOption.key);
        createProductVariants(newVariants ?? []);
    };

    const onAddNewVariantOption = (name: string) => {
        setVariantOptionExamples([...variantOptionExamples, { name }]);
    };

    const onProductVariantChange = (productVariant: IProductVariant, index: number) => {
        let variants = [...productVariants];
        variants[index] = productVariant;
        props.onChange({ ...props.product, variants });
    };

    return (
        <ProductVariantsFormContainer>
            {openTaxModal && (
                <TaxFormModal
                    onCancel={() => setOpenTaxModal(false)}
                    onCreate={(result) => {
                        setOpenTaxModal(false);
                        onClickTax(result);
                    }}
                    show={openTaxModal}
                    setShow={setOpenTaxModal}
                />
            )}

            <previewModal.Modal useButtonClose>
                {currentProduct.variantOptions && currentProduct.variantOptions.length > 0 && (
                    <Flex padding={25} className="template-card" column gap15>
                        <Flex column gap5>
                            <h3 className="title text-bold m-0">{intl.formatMessage(messages.productPreview)}</h3>
                            <p className="m-0 text-light text-small">
                                {intl.formatMessage(
                                    messages.chooseHowYouWantTheVariantsToLookLikeYouCanClickTheImageIconToChangeTheTypeOfView
                                )}
                            </p>
                        </Flex>
                        {currentProduct.variantImageForOption && (
                            <Flex alignCenter gap5>
                                <span className="wahioicon-image text-primary"></span>
                                <span className="text-light text-small">
                                    {intl.formatMessage(messages.previewIn)} {currentProduct.variantImageForOption ?? "---"}
                                </span>
                            </Flex>
                        )}
                        <ProductVariantOptionPreview
                            product={currentProduct}
                            onChangeProduct={props.onChange}
                            activeVariantOption={{}}
                            setActiveVariantOption={() => {}}
                            allowEdit
                        />
                    </Flex>
                )}
            </previewModal.Modal>

            <GridTemplate size={!isMobile ? 500 : 300} gap={20}>
                <Flex className="template-card" column>
                    {window.innerWidth > 568 ? (
                        <Flex w100 className="mb-1">
                            <span className="title text-bold">{intl.formatMessage(messages.variantOptions)}</span>
                        </Flex>
                    ) : (
                        <span className="title text-bold mb-1 text-center">
                            {intl.formatMessage(messages.selectVariantOptionsAndValues)}
                        </span>
                    )}
                    {props.product.variantOptions &&
                        props.product.variantOptions.map((option, index) => (
                            <VariantOptionCard
                                key={index}
                                variantOption={option}
                                onRemoveVariantOption={onRemoveVariantOption}
                                variantOptionExamples={variantOptionExamples}
                                onChange={onVariantOptionChange}
                                onAddVariantOption={onAddNewVariantOption}
                            />
                        ))}
                    <div>
                        <DefaultButton small bgLight rounded onClick={() => handleAddNewOption()}>
                            <span className="wahioicon-plus"></span> {intl.formatMessage(messages.adOption)}
                        </DefaultButton>
                    </div>
                </Flex>
                <Flex className="template-card" column gap10>
                    <Flex gap15 alignCenter>
                        <span className="title text-bold">{intl.formatMessage(messages.details)}</span>
                    </Flex>
                    {variantOptions && (
                        <Flex column gap15 justifyCenter>
                            {variantOptions.length > 1 ? (
                                <Flex alignCenter gap10>
                                    <span className="text-small text-light">{intl.formatMessage(messages.useSameImageIn)}:</span>
                                    <Dropdown title={useSameImageInVariant ? useSameImageInVariant : intl.formatMessage(messages.none)}>
                                        <DropdownClassicOptions>
                                            <Flex column paddingTop={10} paddingBottom={10}>
                                                {useSameImageInVariant && (
                                                    <>
                                                        <li onClick={() => setUseSameImageInVariant("")}>
                                                            {intl.formatMessage(messages.none)}
                                                        </li>
                                                        <hr />
                                                    </>
                                                )}
                                                {variantOptions.map((item, index) => {
                                                    return (
                                                        <li onClick={() => setUseSameImageInVariant(item.name)} key={index}>
                                                            {item.name}
                                                        </li>
                                                    );
                                                })}
                                            </Flex>
                                        </DropdownClassicOptions>
                                    </Dropdown>
                                    <WahioTooltip timeout={100} small width={300}>
                                        <Flex column gap5>
                                            <span className="text-light text-small">
                                                {intl.formatMessage(
                                                    messages.theSameImageWillBeAppliedForAllVariantsThatMatchTheSelectedOne
                                                )}
                                            </span>
                                            <hr />
                                            <span className="text-small">{intl.formatMessage(messages.example)}</span>
                                            <Flex column className="text-small text-light">
                                                <span>{`${intl.formatMessage(messages.useSameImageIn)}: ${variantOptions[0].name}`}</span>
                                                {variantOptions[0].values.slice(0, 4).map((value, index) => (
                                                    <span key={index}>{`${value}: ${intl.formatMessage(messages.sameImage)}`}</span>
                                                ))}
                                            </Flex>
                                        </Flex>
                                    </WahioTooltip>
                                </Flex>
                            ) : (
                                <Flex column justifyCenter gap10>
                                    <span className="text-light text-small">{intl.formatMessage(messages.useSameImageIn)}</span>
                                    <Flex alignCenter gap10>
                                        <CheckBox
                                            title={intl.formatMessage(messages.allVariants)}
                                            name="sameImage"
                                            onChange={(e) => setUseSameImage(e.target.checked)}
                                        />
                                        <WahioTooltip
                                            text={intl.formatMessage(
                                                messages.ifYouApplyThisOptionTheImageYouSelectInAnyVariantWillBeAppliedToTheOthers
                                            )}
                                            timeout={100}
                                            small
                                        />
                                    </Flex>
                                </Flex>
                            )}
                            {variantOptions.length > 1 ? (
                                <Flex alignCenter gap10>
                                    <span className="text-small text-light">{intl.formatMessage(messages.useSamePriceIn)}:</span>
                                    <Dropdown title={useSamePriceInVariant ? useSamePriceInVariant : intl.formatMessage(messages.none)}>
                                        <DropdownClassicOptions>
                                            <Flex column paddingTop={10} paddingBottom={10}>
                                                {useSamePriceInVariant && (
                                                    <>
                                                        <li onClick={() => setUseSamePriceInVariant("")}>
                                                            {intl.formatMessage(messages.none)}
                                                        </li>
                                                        <hr />
                                                    </>
                                                )}
                                                {variantOptions.map((item, index) => {
                                                    return (
                                                        <li onClick={() => setUseSamePriceInVariant(item.name)} key={index}>
                                                            {item.name}
                                                        </li>
                                                    );
                                                })}
                                            </Flex>
                                        </DropdownClassicOptions>
                                    </Dropdown>
                                    <WahioTooltip timeout={100} small width={300}>
                                        <Flex column gap5>
                                            <span className="text-light text-small">
                                                {intl.formatMessage(
                                                    messages.theSamePriceWillBeAppliedForAllVariantsThatMatchTheSelectedOne
                                                )}
                                            </span>
                                            <hr />
                                            <span className="text-small">{intl.formatMessage(messages.example)}</span>
                                            <Flex column className="text-small text-light">
                                                <span>{`${intl.formatMessage(messages.useSamePriceIn)}: ${variantOptions[1].name}`}</span>
                                                {variantOptions[1].values.slice(0, 4).map((value, index) => (
                                                    <span key={index}>{`${value}: ${intl.formatMessage(messages.samePrice)}`}</span>
                                                ))}
                                            </Flex>
                                        </Flex>
                                    </WahioTooltip>
                                </Flex>
                            ) : (
                                <Flex column justifyCenter gap10>
                                    <span className="text-light text-small">{intl.formatMessage(messages.useSamePriceIn)}</span>
                                    <Flex alignCenter gap10>
                                        <CheckBox
                                            title={intl.formatMessage(messages.allVariants)}
                                            name="sameImage"
                                            onChange={(e) => setUseSamePrice(e.target.checked)}
                                        />
                                        <WahioTooltip
                                            text={intl.formatMessage(
                                                messages.ifYouApplyThisOptionThePriceYouSelectInAnyVariantWillBeAppliedToTheOthers
                                            )}
                                            timeout={100}
                                            small
                                        />
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                    )}
                    <Flex gap10 flexWrap className="mt-1">
                        <TaxSelector {...props} />
                        <FormInputProduct>
                            <label htmlFor="name">{intl.formatMessage(messages.productReference)}</label>
                            <DefaultInput
                                name="name"
                                type="text"
                                onChange={(e) => props.onChange({ ...currentProduct, reference: e.target.value })}
                                value={props.product.reference}
                                placeholder={intl.formatMessage(messages.referenceProduct)}
                            />
                        </FormInputProduct>
                    </Flex>
                </Flex>
            </GridTemplate>

            <Flex alignCenter gap15>
                {variantOptions && variantOptions.length > 1 && (
                    <GroupButtonRow
                        activeOption={!!currentProduct.variantsInGroups}
                        onClickOption={(option) => props.onChange({ ...currentProduct, variantsInGroups: option.value as boolean })}
                        options={[
                            {
                                title: intl.formatMessage(messages.ungrouped),
                                value: false,
                            },
                            {
                                icon: "wahioicon-layer-group",
                                title: `${intl.formatMessage(messages.grouped)}`,
                                value: true,
                            },
                        ]}
                    />
                )}
                <DefaultButton rounded bgLight onClick={() => previewModal.show()}>
                    <span className="wahioicon-pen"></span> {intl.formatMessage(messages.preview)}
                </DefaultButton>
                {currentProduct.variantImageForOption && (
                    <Flex alignCenter gap5>
                        <span className="wahioicon-image text-primary"></span>
                        <span className="text-light text-small">Vista de Imagen en: {currentProduct.variantImageForOption}</span>
                    </Flex>
                )}
            </Flex>
            {!currentProduct.variantsInGroups ? (
                <ProductVariantCardListContainer>
                    {productVariants.map((item, index) => (
                        <ProductVariantRow
                            key={index}
                            repeatImageInVariant={useSameImageInVariant}
                            repeatImageForAll={useSameImage}
                            repeatPriceForAll={useSamePrice}
                            productVariant={item}
                            onUpdateVariant={(value) => onProductVariantChange(value, index)}
                            product={props.product}
                            onUpdateProduct={props.onChange}
                            useSameImageInVariant={useSameImageInVariant}
                            useSamePriceInVariant={useSamePriceInVariant}
                        />
                    ))}
                </ProductVariantCardListContainer>
            ) : (
                <Flex column w100 gap15>
                    {Object.keys(variantsGroup).map((key) => {
                        let variants = variantsGroup[key];
                        return (
                            <Flex column key={key} gap10>
                                <TextWithLine>
                                    <span className="line-text">{capitalize(key)}</span>
                                </TextWithLine>

                                <ProductVariantCardListContainer>
                                    {variants.map((item, index) => (
                                        <ProductVariantRow
                                            key={index}
                                            repeatImageInVariant={useSameImageInVariant}
                                            repeatImageForAll={useSameImage}
                                            repeatPriceForAll={useSamePrice}
                                            productVariant={item.variant}
                                            onUpdateVariant={(value) => onProductVariantChange(value, item.index)}
                                            product={props.product}
                                            onUpdateProduct={props.onChange}
                                            useSameImageInVariant={useSameImageInVariant}
                                            useSamePriceInVariant={useSamePriceInVariant}
                                        />
                                    ))}
                                </ProductVariantCardListContainer>
                            </Flex>
                        );
                    })}
                </Flex>
            )}
        </ProductVariantsFormContainer>
    );
}
