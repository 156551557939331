import wahioFetchApi from "../fetchApi";
import {
    EBillingAccountCompany,
    EBillingCompanyUpdateResponse,
    EBillingGetAccountCompany,
    EBillingAccountCompanyResolution,
    EBillingAccountCompanyBase,
    ElectronicBilling,
    ElectronicBillingBodyInput,
    AccountCompanyEnvironment,
    AccountCompanyResolutionNextNumberInput,
} from "../models/electronicBilling";
import { eBillingEndpoint } from "../restApiEndpoint";

export const getEBillingCompany = (data: EBillingGetAccountCompany): Promise<EBillingAccountCompany> => {
    return wahioFetchApi.get(eBillingEndpoint.get.accountCompany(data.nit, data.accountId)).then((res: any) => res.data);
};
export const getEBillingResolutions = (data: EBillingGetAccountCompany): Promise<EBillingAccountCompanyResolution[]> => {
    return wahioFetchApi.get(eBillingEndpoint.get.resolutionsByCompany(data.nit, data.accountId)).then((res: any) => res.data);
};
export const getEBillingByOrder = (orderId: string): Promise<ElectronicBilling> => {
    return wahioFetchApi.get(eBillingEndpoint.get.electronicBillingByOrder(orderId)).then((res: any) => res.data);
};
export const createElectronicBillingFromOrder = (data: ElectronicBillingBodyInput): Promise<ElectronicBilling> => {
    return wahioFetchApi.post(eBillingEndpoint.post.createElectronicBilling, data).then((res: any) => res.data);
};

export const updateEBillingAccountCompany = (data: EBillingAccountCompanyBase): Promise<EBillingCompanyUpdateResponse> => {
    return wahioFetchApi.put(eBillingEndpoint.put.accountCompany, data).then((res: any) => res.data);
};

export const updateEBillingDefaultResolutions = (data: EBillingAccountCompany): Promise<EBillingAccountCompany> => {
    return wahioFetchApi.put(eBillingEndpoint.put.accountCompanyDefaultResolutions, data).then((res: any) => res.data);
};

export const updateEBillingDefaultResolutionNextNumber = (data: AccountCompanyResolutionNextNumberInput): Promise<EBillingAccountCompanyResolution> => {
    return wahioFetchApi.put(eBillingEndpoint.put.accountCompanyDefaultResolutionNextNumber, data).then((res: any) => res.data);
};

export const createUpdateElectronicBillingEnvironment = (data: AccountCompanyEnvironment): Promise<AccountCompanyEnvironment> => {
    return wahioFetchApi.put(eBillingEndpoint.put.accountCompanyEnvironment, data).then((res: any) => res.data);
};

export const updateAccountCompanyTestSet = (accountId: string): Promise<EBillingAccountCompany> => {
    return wahioFetchApi.put(eBillingEndpoint.put.accountCompanyTestSet(accountId), {}).then((res: any) => res.data);
};
