import { Dispatch } from "react";
import {
    AddInventoryAdjustment,
    ADD_INVENTORY_ADJUSTMENT,
    InventoryAdjustmentTypes,
    IInventoryAdjustment,
    IInventoryAdjustmentState,
    InvalidRequest,
    INVALID_REQUEST_INVENTORY_ADJUSTMENTS,
    ReceiveInventoryAdjustments,
    RECEIVE_INVENTORY_ADJUSTMENTS,
    RequestInventoryAdjustments,
    REQUEST_INVENTORY_ADJUSTMENTS,
    UpdateInventoryAdjustment,
    UPDATE_INVENTORY_ADJUSTMENT,
} from "../../api/models/inventoryAdjustment";

export interface IInventoryAdjustmentActions {
    request: () => void;
    receive: (items: IInventoryAdjustment[]) => void;
    fetchInvalid: (error: any) => void;
    add: (inventoryAdjustment: IInventoryAdjustment) => void;
    update: (inventoryAdjustment: IInventoryAdjustment) => void;
}

export const actionRequest = (): RequestInventoryAdjustments => {
    return {
        type: REQUEST_INVENTORY_ADJUSTMENTS,
    };
};

export const actionReceive = (items: IInventoryAdjustment[]): ReceiveInventoryAdjustments => {
    return {
        type: RECEIVE_INVENTORY_ADJUSTMENTS,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_INVENTORY_ADJUSTMENTS,
        value: er,
    };
};

export const ActionAdd = (inventoryAdjustment: IInventoryAdjustment): AddInventoryAdjustment => {
    return {
        type: ADD_INVENTORY_ADJUSTMENT,
        value: inventoryAdjustment,
    };
};

export const ActionUpdate = (inventoryAdjustment: IInventoryAdjustment): UpdateInventoryAdjustment => {
    return {
        type: UPDATE_INVENTORY_ADJUSTMENT,
        value: inventoryAdjustment,
    };
};

export const useActions = (state: IInventoryAdjustmentState, dispatch: Dispatch<InventoryAdjustmentTypes>): IInventoryAdjustmentActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: IInventoryAdjustment[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: IInventoryAdjustment) => {
        dispatch(ActionAdd(value));
    },
    update: (value: IInventoryAdjustment) => {
        dispatch(ActionUpdate(value));
    },
});
