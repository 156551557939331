import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectOptionObject } from "../../api/models";
import { IWarehouse } from "../../api/models/warehouse";
import { useWarehouseContext } from "../../store/contexts/WarehouseContext";
import { SelectBody } from "../Select/styled";

interface WarehouseSelectProps {
    defaultWarehouseId?: string;
    onWarehouseChange: (value: IWarehouse | undefined) => void;
    hideTitle?: boolean;
    small?: boolean;
    allWarehouses?: boolean;
    isClearable?: boolean;
}

const getWarehouseName = (warehouse: IWarehouse) => {
    if (warehouse.allowLogisticsService) {
        return `${warehouse.name} (logística) `;
    }
    if (warehouse.allowNegativeStock) {
        return `${warehouse.name} (Negativo)`;
    }
    return warehouse.name;
};

const getOption = (item: IWarehouse, simpleName?: boolean) => {
    let option: SelectOptionObject<IWarehouse> = {
        label: simpleName ? item.name : getWarehouseName(item),
        value: item.id ?? "NA",
        object: item,
    };
    return option;
};

interface WarehouseBaseSelectProps extends WarehouseSelectProps {
    items: IWarehouse[];
    isLoading?: boolean;
}

const WarehouseBaseSelect = (props: WarehouseBaseSelectProps) => {
    const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouse>();
    const { items } = props;

    useEffect(() => {
        if (props.defaultWarehouseId) {
            var warehouse = items.find((x) => x.id === props.defaultWarehouseId);
            setSelectedWarehouse(warehouse);
        } else {
            setSelectedWarehouse(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.defaultWarehouseId]);

    const onWarehouseChange = (warehouse?: IWarehouse) => {
        if (warehouse) {
            setSelectedWarehouse(warehouse);
            props.onWarehouseChange(warehouse);
        } else {
            setSelectedWarehouse(undefined);
        }
    };

    return (
        <SelectBody menuMinWidth={200}>
            {!props.hideTitle && <span className="label">Bodega</span>}
            <Select
                placeholder="Bodega"
                className={`${props.small ? "select-small" : ""} ${selectedWarehouse ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedWarehouse?.name}
                defaultValue={selectedWarehouse ? getOption(selectedWarehouse, true) : undefined}
                isDisabled={false}
                onChange={(value) => onWarehouseChange(value?.object)}
                isLoading={props.isLoading}
                isRtl={false}
                isSearchable={true}
                name="warehouse"
                options={items.map((item) => getOption(item))}
                isClearable={props.isClearable}
            />
        </SelectBody>
    );
};

export default function WarehouseSelect(props: WarehouseSelectProps) {
    const { warehouseState } = useWarehouseContext();
    const items = props.allWarehouses ? warehouseState.allWarehouses : warehouseState.orgWarehouses;

    return <WarehouseBaseSelect {...props} items={items} isLoading={warehouseState.isFetching} />;
}
