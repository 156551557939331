import React from "react";
import EmptyResultViews from ".";
import { CloudImages } from "../../assets";

interface EmptyUserStoresProps {
    userIsAdmin: boolean;
}

export default function EmptyUserStores(props: EmptyUserStoresProps) {
    return (
        <EmptyResultViews
            image={CloudImages.confusedMan}
            title={props.userIsAdmin ? "Aun no tienes una tienda creada." : "No tienes permiso para acceder a ninguna tienda."}
            description={
                props.userIsAdmin ? "Crea una tienda para continuar." : "para acceder a las tiendas debes ser agregado por el administrador"
            }
        />
    );
}
