export type TaxType = "percentage" | "amount";

export interface ITax {
    id?: string;
    accountId: string;
    name: string;
    description: string;
    value: number;
    isInclude: boolean;
    dateCreated?: Date;
    dateUpdated?: Date;
    type: TaxType;
    tributeType: string;
}

export interface IProductTax {
    id?: string;
    productId: string;
    taxId: string;
    tax: ITax;
}

export interface ProductByTax extends IProductTax {
    productName?: string;
    productImage?: string;
    taxPercentage: number;
}

export interface UpdateProductTaxInput {
    accountId: string;
    newTaxId: string;
    productTaxId: string;
}

export interface ProductTaxBulkInput {
    accountId: string;
    taxId: string;
    productIds: string[];
}

export class Tax implements ITax {
    id?: string;
    accountId: string;

    name: string = "";
    description: string = "";
    value: number = 0;
    isInclude: boolean = true;
    dateCreated?: Date;
    dateUpdated?: Date;
    type: TaxType = "percentage";
    tributeType: string = "";

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export interface ITaxDebt {
    taxId: string;
    tax: ITax;
    value: number;
}

export interface ITaxSummary {
    totalCreditAmount: number;
    paidAmount: number;
    paidCount: number;
    creditCount: number;
    partialCount: number;
}
