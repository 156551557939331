import React, { createContext, useEffect, useState } from "react";
import { LocalStoreKeys, saveData } from "..";
import { IPaginationResponse, PaginationResponse } from "../../api/models";
import { Contact, ContactLabel, ContactType } from "../../api/models/contact";

export interface ContactState {
    pagination: IPaginationResponse<Contact>;
    labels: ContactLabel[];
    contactType?: ContactType;
    paramContacts?: Contact[];
}

interface ContactContextProps {
    contactState: ContactState;
    setContactState: (value: ContactState) => void;
    contactActions: ICustomerActions;
}

export interface ICustomerActions {
    receive: (pagination: IPaginationResponse<Contact>) => void;
    receiveLabels: (items: ContactLabel[]) => void;
    add: (customer: Contact) => void;
    addLabel: (value: ContactLabel) => void;
    updateLabel: (value: ContactLabel) => void;
    update: (customer: Contact) => void;
}

export const ContactContext = createContext({} as ContactContextProps);

const ContactContextProvider = (props: any) => {
    const [contactState, setContactState] = useState<ContactState>({
        pagination: new PaginationResponse<Contact>(),
        labels: [],
    });

    useEffect(() => {
        saveData(LocalStoreKeys.contacts, contactState);
    }, [contactState]);

    const contactActions = {
        receiveLabels: (items: ContactLabel[]) => {
            setContactState({ ...contactState, labels: items });
        },
        receive: (pagination: IPaginationResponse<Contact>) => {
            setContactState({ ...contactState, pagination });
        },
        add: (value: Contact) => {
            let pagination = { ...contactState.pagination };
            pagination.items = [value, ...pagination.items];
            setContactState({ ...contactState, pagination });
        },
        update: (value: Contact) => {
            let pagination = { ...contactState.pagination };
            pagination.items = pagination.items.reduce(
                (items, item) => (item.id === value.id ? [...items, value] : [...items, item]),
                [] as Contact[]
            );
            setContactState({ ...contactState, pagination });
        },
        addLabel: (value: ContactLabel) => {
            setContactState({ ...contactState, labels: [...contactState.labels, value] });
        },
        updateLabel: (value: ContactLabel) => {
            let labels = contactState.labels.reduce(
                (items, item) => (item.id === value.id ? [...items, value] : [...items, item]),
                [] as ContactLabel[]
            );
            setContactState({ ...contactState, labels });
        },
    };

    return <ContactContext.Provider value={{ contactState, setContactState, contactActions }}>{props.children}</ContactContext.Provider>;
};

export const useContactContext = () => {
    return React.useContext(ContactContext);
};

export default ContactContextProvider;
