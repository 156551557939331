import { Dispatch } from "react";
import { getAuthBody } from "../../auth";
import { wahioFetch } from "../../api";
import { productEndpoint } from "../../api/restApiEndpoint";
import { actionFetchInvalid, actionReceive, actionRequest } from "../actions/inventoryAdjustment";
import { InventoryAdjustmentTypes, REQUEST_INVENTORY_ADJUSTMENTS } from "../../api/models/inventoryAdjustment";

export const applyMiddleware = (dispatch: Dispatch<InventoryAdjustmentTypes>) => (action: InventoryAdjustmentTypes) => {
    switch (action.type) {
        case REQUEST_INVENTORY_ADJUSTMENTS:
            dispatch(actionRequest());
            const authBody = getAuthBody();
            return wahioFetch.get(
                productEndpoint.get.inventoryAdjustmentByAccount(authBody.accountId),
                (success) => {
                    dispatch(actionReceive(success.data));
                },
                (error) => {
                    dispatch(actionFetchInvalid(error));
                }
            );
        default:
            dispatch(action);
    }
};
