import { clearAuthSession, getAuthBody } from "../auth";
import appConfig from "./appConfig";
const wahioFetchApi = require("axios");

wahioFetchApi.default.headers = {
    accept: "application/json",
    "content-type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
};

wahioFetchApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
wahioFetchApi.defaults.headers.common["Ocp-Apim-Subscription-Key"] = appConfig.OCP_APIM_SUBSCRIPTION_KEY;

export const setDefaultToken = (token: string) => {
    wahioFetchApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const initializeWahioFetchApi = () => {
    const authBody = getAuthBody();

    if (authBody && authBody.token) {
        wahioFetchApi.defaults.headers.common["Authorization"] = `Bearer ${authBody.token}`;
    }
};

export const getFetchError = (e: any) => {
    if (e.response) {
        if (e.response.status === 401) {
            clearAuthSession();
        }
        return e.response;
    }
    return {
        status: 500,
        message: e.error,
    };
};

export function waitFetch(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default wahioFetchApi;
