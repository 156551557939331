import { useState } from "react";
import { useMutation } from "react-query";
import { useAlert } from "../../components/Alerts/Alert";
import { useUserContext } from "../../store/contexts/UserContext";
import { getUniqueId } from "../../utils";
import { stripeCreatePaymentIntentConfirm } from "../account/stripeApi";
import { AccountPaymentMethod, PaymentMethodSource } from "../models/accountUser";
import { StripePaymentIntentConfirmInput } from "../models/stripe";
import { useWompiPayments } from "../wompi/hooks";

export interface TransactionBodyResult {
    transactionId: string;
    source: PaymentMethodSource;
    amount: number;
}

export default function useBillingPayment() {
    const alert = useAlert();
    const wompiPayments = useWompiPayments();
    const { userState } = useUserContext();
    const stripeIntentMutation = useMutation((data: StripePaymentIntentConfirmInput) => stripeCreatePaymentIntentConfirm(data));

    const [transactionIsLoading, setTransactionIsLoading] = useState(false);

    const createTransaction = async (method: AccountPaymentMethod, amount: number): Promise<TransactionBodyResult | undefined> => {
        const amountInCents = Math.trunc(amount) * 100;

        if (method.source === "wompi") {
            const referenceId = getUniqueId();
            var transaction = await wompiPayments.createWompiTransaction(referenceId, amountInCents, method.token);
            if (!transaction) return undefined;
            return {
                transactionId: transaction.data.id,
                source: method.source,
                amount,
            };
        } else {
            const stripeCustomerId = userState.user?.account?.stripeCustomerId;

            if (!stripeCustomerId) {
                alert.error("Imposible  procesar el pago, intente nuevamente más tarde. Stripe no esta configurado para esta cuenta.");
                return undefined;
            }

            let bodyData: StripePaymentIntentConfirmInput = {
                amount: amountInCents,
                currency: "cop",
                paymentMethod: method.sourceId,
                customer: stripeCustomerId,
            };

            try {
                const transactionResult = await stripeIntentMutation.mutateAsync(bodyData);
                if (transactionResult.status === "succeeded") {
                    return {
                        transactionId: transactionResult.id,
                        source: method.source,
                        amount,
                    };
                } else {
                    alert.error("Imposible  procesar el pago, intente nuevamente más tarde.");
                    return undefined;
                }
            } catch (err) {
                alert.error("Imposible  procesar el pago, intente nuevamente más tarde.");
                return undefined;
            }
        }
    };

    const createTransactionWithPaymentMethod = async (
        method: AccountPaymentMethod,
        amount: number
    ): Promise<TransactionBodyResult | undefined> => {
        setTransactionIsLoading(true);
        const result = await createTransaction(method, amount);
        setTransactionIsLoading(false);
        return result;
    };

    return { createTransactionWithPaymentMethod, transactionIsLoading };
}
