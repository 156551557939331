import styled from "styled-components";
import { device } from "../../theme/themeProvider";

export const AlertTemplateView = styled.div`
    display: flex;
    background: ${(props) => props.theme.palette.background};
    padding: 15px 20px;
    margin-bottom: 15px !important;
    border-radius: 100px;
    align-items: center;
    .message {
        max-width: 500px;
    }

    &.info {
        background: ${(props) => props.theme.palette.backgroundDark};
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowColor};
        color: white;
        background: #607d8b;
        .close {
            background: #537180;
            color: white;
        }
    }
    &.success {
        background: ${(props) => props.theme.palette.primary};

        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowColor};
        color: white;
        .close {
            background: ${(props) => props.theme.palette.primaryDark};
            color: white;
        }
    }
    &.error {
        background: ${(props) => props.theme.palette.dangerColor};
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowColor};
        color: white;
        .close {
            background: ${(props) => props.theme.palette.dangerColorDark};
            color: white;
        }
    }
    .icon {
        margin-right: 20px;
        font-size: 2rem;
    }
    .close {
        margin-left: 10px;
    }
`;

export const AlertTemplateNotificationView = styled.div`
    display: flex;
    background: ${(props) => props.theme.palette.background};
    color: ${(props) => props.theme.palette.text};
    overflow: hidden;
    padding-right: 20px;
    margin-top: 25px !important;
    margin-right: 25px !important;
    border-radius: 10px;
    align-items: center;
    position: relative;

    box-shadow: -3px 4px 15px 5px ${(props) => props.theme.palette.shadowColor};

    .icon {
        margin-right: 20px;
        font-size: 2rem;
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        margin-left: 10px;
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
    }
    .message {
        max-width: 500px;
    }
`;

export const NotificationSuccessContainer = styled.div`
    display: flex;
    align-items: center;
    .bold {
        font-weight: 500;
    }
    .panel-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        background: ${(props) => props.theme.palette.primary};
        color: white;
        margin-left: 10px;
        border-radius: 28px;
    }

    .body {
        padding: 10px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }
`;

export const NotificationContainer = styled.div`
    display: flex;
    background: ${(props) => props.theme.palette.background};
    box-shadow: 1px 1px 9px ${(props) => props.theme.palette.shadowColor};
    border-radius: 15px;
    overflow: hidden;

    .cursor {
        cursor: pointer;
    }
    .notification-icon {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8px;
        margin-right: 15px;
        &.red {
            background: #ff5722;
        }
    }
    .actions {
        margin-top: 12px;
        justify-content: flex-end;
        button {
            font-size: 13px;
        }
    }
    .flex-row {
        display: flex;
        align-items: center;
    }
    .FlexCol {
        display: flex;
        flex-direction: column;
    }
    .content {
        max-width: 173px;
        @media ${device.sm} {
            max-width: 220px;
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }
    .notification-body {
        display: flex;
        align-items: center;
    }
    .body {
        padding: 15px 0px;
        color: ${(props) => props.theme.palette.textLight};
        font-size: 14px;
    }
    .image {
        width: 60px;
        height: 60px;

        &.small {
            width: 30px;
            height: 30px;
        }
        overflow: hidden;
        margin-right: 15px;
        border-radius: 100px;

        &.square {
            border-radius: 10px;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        }
        justify-content: center;
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
        align-items: center;
        display: flex;
        font-size: 1.5rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .title {
        color: ${(props) => props.theme.palette.text};
        font-size: 14px;
    }
`;

export const AlertContainer = styled.div`
    padding: 11px;
    margin: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.textLight};
    p {
        margin: 0px;
    }

    &.withoutMargin {
        margin: 0px;
        width: 100%;
    }

    &.danger {
        border: 1px solid #f44336;
        background: #f4433629;
        color: #f44336;
    }
    &.warning {
        border: 1px solid #ff572230;
        background: #ff572210;
        color: #ff5722;
    }
    &.success {
        border: 1px solid ${(props) => props.theme.palette.primary}50;
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};
    }
`;
