import { defineMessages } from "react-intl";

const base = "components.cashRegisterLog";

export default defineMessages({
    cashRegisters: {
        id: `${base}.cashRegisters`,
        defaultMessage: "Registros de caja",
    },
    searchRegisters: {
        id: `${base}.searchRegisters`,
        defaultMessage: "Buscar Registros",
    },
    createdBy: {
        id: `${base}.createdBy`,
        defaultMessage: "Creado por:",
    },
    summary: {
        id: `${base}.summary`,
        defaultMessage: "Resumen",
    },
    startingMoney: {
        id: `${base}.startingMoney`,
        defaultMessage: "Dinero inicial",
    },
    totalIncome: {
        id: `${base}.totalIncome`,
        defaultMessage: "Total ingresos",
    },
    totalExpenses: {
        id: `${base}.totalExpenses`,
        defaultMessage: "Total Egresos",
    },
    onBench: {
        id: `${base}.onBench`,
        defaultMessage: "En banco",
    },
    registers: {
        id: `${base}.registers`,
        defaultMessage: "Registros",
    },
    cashInBox: {
        id: `${base}.cashInBox`,
        defaultMessage: "Dinero en Caja",
    },
    finalCountdown: {
        id: `${base}.finalCountdown`,
        defaultMessage: "Conteo Final",
    },
    surplus: {
        id: `${base}.surplus`,
        defaultMessage: "Sobrante: ",
    },
    missing: {
        id: `${base}.missing`,
        defaultMessage: "Faltante: ",
    },
    successfulCashOpening: {
        id: `${base}.successfulCashOpening`,
        defaultMessage: "Apertura de caja exitosa",
    },
    confirmCashOpening: {
        id: `${base}.confirmCashOpening`,
        defaultMessage: "Confirmar apertura de caja",
    },
    cashEnabledAmount: {
        id: `${base}.cashEnabledAmount`,
        defaultMessage: "La Caja se habilitará a partir de la hora actual con el monto actual de la Caja",
    },
    startCashOpening: {
        id: `${base}.startCashOpening`,
        defaultMessage: "Iniciar apertura de Caja: ",
    },
    responsibleOpening: {
        id: `${base}.responsibleOpening`,
        defaultMessage: "Iniciar apertura de Caja: ",
    },
    starCash: {
        id: `${base}.starCash`,
        defaultMessage: "Iniciar Caja",
    },
    passwordValidationSuccessful: {
        id: `${base}.passwordValidationSuccessful`,
        defaultMessage: "Validación de contraseña exitosa",
    },
    errorValidateData: {
        id: `${base}.errorValidateData`,
        defaultMessage: "Hubo un error al intentar validar los datos",
    },
    changeAssociatedCashRegister: {
        id: `${base}.changeAssociatedCashRegister`,
        defaultMessage: "Cambiar caja asociada",
    },
    confirmationRequired: {
        id: `${base}.confirmationRequired`,
        defaultMessage: "Confirmación del administrador es requerida",
    },
    administratorPassword: {
        id: `${base}.administratorPassword`,
        defaultMessage: "Contraseña de administrador",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    confirmValidation: {
        id: `${base}.confirmValidation`,
        defaultMessage: "Confirmar Validación",
    },
    doNotActivate: {
        id: `${base}.doNotActivate`,
        defaultMessage: "Como no tienes para activar las cajas, no puedes ver los registros de caja",
    },
    cashClosingCompleted: {
        id: `${base}.cashClosingCompleted`,
        defaultMessage: "Cierre de caja completado",
    },
    errorOpenCash: {
        id: `${base}.errorOpenCash`,
        defaultMessage: "Error al intentar abrir la caja",
    },
    confirmCashClosing: {
        id: `${base}.confirmCashClosing`,
        defaultMessage: "Confirmar cierre de caja",
    },
    cashClosedCurrent: {
        id: `${base}.cashClosedCurrent`,
        defaultMessage: "La Caja se cerrará a partir de la hora actual",
    },
    date: {
        id: `${base}.date`,
        defaultMessage: "Fecha: ",
    },
    activeTime: {
        id: `${base}.activeTime`,
        defaultMessage: "Tiempo Activo: ",
    },
    responsable: {
        id: `${base}.responsable`,
        defaultMessage: "Responsable: ",
    },
    enterMoneyInCash: {
        id: `${base}.enterMoneyInCash`,
        defaultMessage: "Total en Efectivo",
    },
    enterMoneyInBank: {
        id: `${base}.enterMoneyInBank`,
        defaultMessage: "Total en Banco",
    },
    finishCashRegister: {
        id: `${base}.finishCashRegister`,
        defaultMessage: "Finalizar Caja",
    },
    selectCashRegisterDescription: {
        id: `${base}.selectCashRegisterDescription`,
        defaultMessage: "Selecciona una caja para asociarla a este dispositivo",
    },
    registration: {
        id: `${base}.registration`,
        defaultMessage: "Registro",
    },
    whatDoYouWantToDo: {
        id: `${base}.whatDoYouWantToDo`,
        defaultMessage: "¿Qué deseas hacer?",
    },
    finishCashAndViewRegisters: {
        id: `${base}.finishCashAndViewRegisters`,
        defaultMessage: "Finalizar caja y ver registros",
    },
    cashRecords: {
        id: `${base}.cashRecords`,
        defaultMessage: "Registro de caja",
    },
    remaining: {
        id: `${base}.remaining`,
        defaultMessage: "Restante",
    },
    incomes: {
        id: `${base}.incomes`,
        defaultMessage: "Ingresos",
    },
    incomeBanks: {
        id: `${base}.incomeBanks`,
        defaultMessage: "Ingresos Bancos",
    },
    expenses: {
        id: `${base}.expenses`,
        defaultMessage: "Egresos",
    },
    expensesBanks: {
        id: `${base}.expensesBanks`,
        defaultMessage: "Egresos Bancos",
    },
    cashRegister: {
        id: `${base}.cashRegister`,
        defaultMessage: "Caja",
    },
    start: {
        id: `${base}.start`,
        defaultMessage: "Inicio",
    },
    end: {
        id: `${base}.end`,
        defaultMessage: "Fin",
    },
    duration: {
        id: `${base}.duration`,
        defaultMessage: "Duración",
    },

    initialBalance: {
        id: `${base}.initialBalance`,
        defaultMessage: "Saldo Inicial",
    },
    finalBalance: {
        id: `${base}.finalBalance`,
        defaultMessage: "Saldo Final",
    },
});
