import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { DefaultButton, SquareButton } from "../../components/_controls";
import { horMenuHeight, menuWidth, menuWidthMinus, smallMenu } from "../../constants/defaultValues";
import { breakpoints, device, deviceMax } from "../../theme/themeProvider";

export const HorizontalMenu = styled.div`
    width: 100%;
    height: ${horMenuHeight};
    display: flex;
    padding: 0px 20px;
    box-sizing: border-box;
    align-items: center;
    z-index: 10;
    padding-left: 20px;
    top: 0px;

    .dropdown-btn,
    .MENU_BTN_HELP {
        background: transparent;
        color: ${(props) => props.theme.palette.text};
        :hover {
            background: ${(props) => props.theme.palette.menuHoverColor};
        }
    }

    .hide-mobile {
        display: none;
    }
    @media ${device.md} {
        .hide-mobile {
            display: flex;
        }
    }

    .hide-mobile-xs {
        display: none;
    }
    @media ${device.xs} {
        .hide-mobile-xs {
            display: flex;
        }
    }
`;

export const ActiveUsersContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const AccountPlanLabel = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 0px;
    transition: padding 0.3s ease-in-out;
    margin-left: 5px;
    .assent-message {
        gap: 2px;
        padding: 0 6px;
        font-size: 13px;
        background: #00bfa520;
        color: #00bfa5;
        font-weight: 500;
        border-radius: 6px;
        padding: 2px 5px;
        display: flex;
    }

    :hover {
        background: ${(props) => props.theme.palette.backgroundLight};
        padding: 4px 6px;
        border-radius: 10px;
    }

    .hide-mobile {
        display: none;
    }
    .show-mobile {
        display: inherit;
    }
    @media ${device.sm} {
        .hide-mobile {
            display: inherit;
        }
        .show-mobile {
            display: none;
        }
    }
`;

export const ClearCacheContainer = styled.div`
    position: fixed;
    top: 21px;
    z-index: 37;
    background: #171717;
    color: white;
    padding: 13px;
    border-radius: 10px;
    box-shadow: 1px 1px 19px #a2a2a2;
    width: 300px;
    left: calc(50% - 150px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        margin: 0;
        margin-bottom: 12px;
    }
`;

interface IVerticalProps {
    menuOpen: boolean;
}

export const VerticalMenuContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.small-menu {
        max-width: ${smallMenu};
        transition: max-width 0.3s;
    }

    max-width: ${menuWidth};
    border-right: 1px solid transparent;

    z-index: 4;
    left: ${(props: IVerticalProps) => (props.menuOpen ? 0 : menuWidthMinus)};
    transition: left 0.2s ease-in-out;
    box-sizing: border-box;
    height: calc(100% - 55px);

    .menu-flex {
        padding: 10px 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        overflow-x: auto;
        overflow-y: auto;
        position: relative;
        height: 100%;
        background: ${(props) => props.theme.palette.background};
    }

    @media ${device.md} {
        position: fixed;
        left: 0;
        .menu-flex {
            background: transparent;
        }
    }

    .btn-size {
        visibility: hidden;
    }
    &:hover {
        .btn-size {
            visibility: visible;
        }
    }

    &.menu-open {
        :before {
            content: " ";
            background: #525252b5;
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 0;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
`;

export const AppGlobalContainer = styled.div`
    display: grid;
    grid-template-rows: auto 1fr; /* auto para el encabezado, y 1fr para el contenido */
    height: 100vh;
`;

export const AppSubContainer = styled.div`
    display: flex;
    position: relative;
    height: calc(100% - 15px);
    overflow: auto;

    @media ${deviceMax.md} {
        height: 100%;
    }
`;

export const ChooseStoreContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-left: 0px;
    margin: auto;
    padding: 15px 20px;
    background: ${(props) => props.theme.palette.backgroundLight}60;
    overflow: auto;
    height: calc(100vh - 55px);
`;

export const AppContentContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-left: 0px;
    background: ${(props) => props.theme.palette.background};
    overflow: auto;
    scroll-behavior: smooth;

    ${(props) => props.theme.palette.borderAppTheme};

    @media ${device.md} {
        margin-left: ${menuWidth};

        &.small-menu {
            margin-left: ${smallMenu};
        }

        &.no-vertical-menu {
            margin-left: 10px;
        }
    }

    margin-right: 10px;
    border-radius: 15px;

    @media ${deviceMax.md} {
        margin-right: 0px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const ButtonMessageIcon = styled(DefaultButton)`
    width: 30px;
    height: 30px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const AppTitle = styled.div`
    font-weight: 600;
    color: ${(props) => props.theme.palette.text};
    line-height: 1;
    white-space: nowrap;
    .subtitle {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const RightPanel = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const MenuButton = styled(SquareButton)`
    display: flex;
    @media ${device.md} {
        display: none;
    }
`;

interface MenuButtonDeskProps {
    notifications?: number;
}
export const MenuButtonDesk = styled.div`
    font-size: 18px;
    background: transparent;
    width: 35px;
    aspect-ratio: 1;
    border-radius: 9px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark}40;
    }
    img {
        width: 20px;
    }

    &.mobile {
        display: none;
    }
    &.desk {
        display: flex;
    }

    @media (max-width: ${breakpoints.md}px) {
        &.mobile {
            display: flex;
        }
        &.desk {
            display: none;
        }

        ::after {
            ${(props: MenuButtonDeskProps) => (props.notifications && props.notifications > 0 ? `content: '${props.notifications}'` : "")};
            background: ${(props) => props.theme.palette.primary};
            color: white;
            position: absolute;
            top: 0;
            font-size: 12px;
            right: 0;
            font-weight: 500;
            padding: 0px 3px;
            border-radius: 14px;
        }
    }
`;

export const ChatMenuTab = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding-left: 25px;
    box-sizing: border-box;
    &.menu-small {
        width: auto;
        padding-left: 0px;
    }
`;

export const UserPanel = styled.div`
    width: 34px;
    height: 34px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 50px;
    transition: all 0.3s;
    &:hover {
        border-color: transparent;
        box-shadow: 0px 2px 4px ${(props) => props.theme.palette.shadow};
    }
`;

export const UserImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const CashRegisterCard = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 5px 12px;
    border-radius: 17px;
    border: 1px solid #a0a0a033;

    color: ${(props) => props.theme.palette.text};

    font-weight: 600;
    .icon {
        .active {
            color: #4caf50;
        }
        display: flex;
    }
    .content {
        display: flex;
        line-height: 1;

        flex-direction: column;
        .title {
            text-transform: uppercase;
            font-size: 0.7rem;
        }
        .small {
            font-size: 0.8rem;
            color: ${(props) => props.theme.palette.textLight};
            font-weight: 400;
        }
    }
`;

export const MenuBackgroundGlobal = styled.div`
    width: 100%;
    height: 100%;
    background: #525252b5;
    position: fixed;
    z-index: 4;
`;

export const UserTitleLink = styled(NavLink)`
    font-weight: 500;
    margin-right: 15px;
    color: ${(props) => props.theme.palette.text};
    cursor: pointer;
    &:visited {
        color: ${(props) => props.theme.palette.text};
    }

    .name-body {
        display: flex;
        flex-direction: column;
    }

    display: flex;
    align-items: center;

    .username {
        display: none;
    }

    @media ${device.sm} {
        .username {
            display: block;
        }
    }
    .alert-text {
        font-size: 13px;
        color: ${(props) => props.theme.palette.dangerColor};
    }
    .image {
        width: 35px;
        height: 35px;
        overflow: hidden;
        border-radius: 50px;
        margin-right: 0px;

        @media ${device.sm} {
            margin-right: 10px;
        }

        &.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${(props) => props.theme.palette.dangerColor};
            color: white;
        }
        &.name {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
            color: white;
            background: ${(props) => props.theme.palette.primary};
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const OrganizationTabContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    .dropdown-btn {
        background: transparent;
        :hover {
            background: ${(props) => props.theme.palette.menuHoverColor};
        }
    }

    &:hover {
        .link {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
        .btn-change {
        }
    }
`;

export const OrganizationTabContent = styled.div`
    padding: 20px 20px;
    display: flex;
    flex-direction: column;

    .store-list {
        padding-bottom: 10px;
        overflow-y: auto;
    }
`;

export const StoreTab = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 3px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 10px;
    color: ${(props) => props.theme.palette.text};

    .store-link {
        margin-left: auto;
        display: none;
    }

    .store-tab-icon {
        background: ${(props) => props.theme.palette.background};
    }

    &.active {
        background: ${(props) => props.theme.palette.primary}20;
        .store-tab-icon {
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }
        .store-link {
            display: flex;
        }
    }

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
        .store-link {
            display: flex;
        }
        &.active {
            background: ${(props) => props.theme.palette.primary}30;
            .store-tab-icon {
                background: ${(props) => props.theme.palette.primary};
                color: white;
            }
        }
    }

    .small-text {
        font-size: 12px;
        color: ${(props) => props.theme.palette.textLight};
    }

    .store-image {
        width: 40px;
        height: 40px;
        min-width: 40px;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .store-content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin-right: 10px;
        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
`;

export const OrganizationSelectorContainer = styled.div`
    background: blue;
    width: 100px;
    height: 200px;
`;

export const OrgImageContainer = styled.div`
    width: 35px;
    height: 35px;
    min-width: 35px;
    border-radius: 50px;
    overflow: hidden;
    &.namebody {
        display: flex;
        align-items: center;
        background: ${(props) => props.theme.palette.primary};
        justify-content: center;
        .name {
            color: white;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const OrgTitle = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin: 0;
    padding-left: 15px;
`;

export const AlertLineContainer = styled.div`
    background: #d32f2f20;
    color: #d32f2f;
    padding: 7px 20px;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    box-sizing: border-box;

    a {
        color: white;
        &:visited {
            color: white;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &.danger {
        background: #f44336;
    }
`;

export const CustomAlert = styled.div`
    padding: 15px;
    background: #ffffff;
    position: fixed;
    right: 25px;
    min-width: 300px;
    border-radius: 8px;
    top: 70px;
    box-shadow: 2px 2px 8px #8c8c8c59;

    display: flex;
    align-items: center;
    .iconbody {
        background: #009688;
        color: white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 37px;
        font-size: 1.5rem;
        margin-right: 15px;
    }
    .content {
        .title {
            margin: 0px;
        }
    }
`;

export const DisabledAccountViewContainer = styled.div`
    padding: 15px;
    margin-top: 30px;

    .title {
        color: #f44336;
    }
    hr {
        color: #f44336;
    }
    .logo {
        margin-top: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-weight: 500;
        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }
    .subContainer {
        padding: 10px 30px;
        margin-top: 30px;
        /* background: #f4433608; */
        max-width: 600px;
        margin: auto;
        border-radius: 19px;
        padding-bottom: 39px;
        /* border: 2px solid #cecece2e; */
        box-shadow: 1px 1px 20px ${(props) => props.theme.palette.shadowColor};
    }
    .small-text {
        cursor: pointer;
        font-size: 10px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
