import styled from "styled-components";
import { device } from "../../theme/themeProvider";

export const OrderSummaryItemsContainer = styled.div`
    display: grid;
    .swiper {
        max-width: 100%;
        width: 100%;
    }
    .summary-items {
        display: flex;
        align-items: center;
        overflow: overlay;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }
    .swiper-button-disabled {
        visibility: hidden;
    }
    .swiper-button-next {
        right: 0px;
        :after {
            font-size: 21px;
        }
    }
    .swiper-button-prev {
        left: 0px;
        :after {
            font-size: 21px;
        }
    }
    .swiper-container {
        width: 100%;
    }
    .swiper-slide {
        overflow: hidden;
    }
    .item-card {
        padding: 15px;
        display: flex;
        flex-direction: column;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        border-radius: 15px;
        height: 68px;
        justify-content: space-between;

        .item-body {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        .item-value {
            font-weight: 500;
        }

        .comparison {
            display: flex;
            width: fit-content;
            font-size: 13px;
            font-weight: 500;
            gap: 5px;

            &.positive {
                color: #009688;
            }
            &.negative {
                color: #f44336;
            }
        }
    }
`;

export const SummaryFilterContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .title {
        margin: 0;
        font-weight: 500;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-auto-rows: 1fr;
`;

export const HomeGridCard = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 20px;
    border-radius: 15px;
`;

export const GridChartCol = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    overflow: auto;

    @media ${device.xl} {
        grid-template-columns: minmax(300px, 1fr) minmax(600px, 2fr);
    }
`;
export const GridChartColInv = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    overflow: auto;

    @media ${device.xl} {
        grid-template-columns: minmax(600px, 2fr) minmax(300px, 1fr);
    }
`;

export const GridTwoColumn = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    overflow: auto;

    @media ${device.md} {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
`;

export const ChartContainerGroup = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: min-content;
`;

export const ChartContainerItem = styled.div`
    height: 220px;
    width: 100%;
    box-sizing: border-box;
    &.container {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 14px;
        padding: 12px 12px;
    }
`;

export const ChartContainer = styled.div`
    min-height: 250px;
    width: 100%;
`;

export const ContainerStatisticsHeader = styled.div`
    display: flex;
    align-items: center;
    .title-header {
        margin: 0px;
        font-size: 14px;
        margin-right: 15px;
        font-weight: 500;
    }
    .filters {
        display: flex;
        align-items: center;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    overflow: auto;

    .row-image {
        width: 45px;
        height: 45px;
        border-radius: 10px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;
