import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useEventListenerWindow } from "..";
import { useAppContentEventListener } from "../../pages/AppContainer";
import LoadingDualRing from "../LoadingDualRing";
import { DefaultButton } from "../_controls";
import { ClickResult, getButtonStyle, getContentShowStyle, ignoreInternalClick } from "./dropdownHelper";
import { DropdownContainer, DropdownContent } from "./styled";

export type DropdownPositionX = "left" | "right" | "center";
export type DropdownPositionY = "top" | "bottom" | "inline";

export interface DropdownRects {
    button: ElementRect;
    content: ElementRect;
}
export class ElementRect {
    x: number = 0;
    y: number = 0;
    width: number = 0;
    height: number = 0;
}

export interface IContentPosition {
    contentHorizontal?: DropdownPositionX;
    contentVertical?: DropdownPositionY;
    contentOutside?: boolean;
}

export interface DropdownStyleOption extends IContentPosition {
    styleContent?: React.CSSProperties;
    styleContainer?: React.CSSProperties;
    styleButton?: React.CSSProperties;
}
export interface DropdownProps extends DropdownStyleOption {
    tourName?: string;
    ignoreInternalClicks?: boolean;
    children: React.ReactNode;
    title?: string;
    icon?: string;
    customIcon?: React.ReactNode;
    iconPosition?: "left" | "right";
    imageIcon?: string;
    image?: any;
    hideIcon?: boolean;
    hideBorder?: boolean;
    prefix?: string;
    onClose?: () => void;
    smallImage?: boolean;
    small?: boolean;
    letterAsIcon?: string;
    initialOpen?: boolean;
    bgLight?: boolean;
    textLight?: boolean;
    renderOnShow?: boolean;
    w100?: boolean;
    fitContentToRight?: number;
    isLoading?: boolean;
    dropIsFilter?: boolean;
    withOutBackgroundButton?: boolean;
    functionClick?: () => void;
    borderRadius?: number;
    colorText?: boolean;
    disabled?: boolean;
    btnClassName?: string;
    notificationCount?: number;
}

export default function Dropdown(props: DropdownProps) {
    const { styleContent = {}, styleButton = {}, iconPosition = "right" } = props;
    const location = useLocation();
    const [ID] = useState(uuid());
    const BUTTON_ID: string = "button_" + ID;
    const CONTENT_ID: string = "content_" + ID;

    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const [showContent, setShowContent] = useState(!!props.initialOpen);

    useEffect(() => {
        setShowContent(false);
    }, [location, props.initialOpen]);

    useEventListenerWindow("click", (e) => {
        const isDropdownButton = e.target.id === BUTTON_ID;
        if (!isDropdownButton) {
            if (props.ignoreInternalClicks) {
                if (e.target.id === CONTENT_ID) return;
                let value = new ClickResult();
                ignoreInternalClick(e.target, value, CONTENT_ID);
                if (value.result) return;
            }
            if (showContent) {
                setShowContent(false);
                if (props.onClose) props.onClose();
            }
        }
    });

    useAppContentEventListener("scroll", (e) => {
        updateDropdownPositions();
    });

    useEventListenerWindow("resize", () => {
        updateDropdownPositions();
    });

    const updateDropdownPositions = () => {
        let btnElement = buttonRef.current;
        let contentElement = contentRef.current;

        if (btnElement && contentElement) {
            const buttonRect = btnElement.getBoundingClientRect();
            const contentRect = contentElement.getBoundingClientRect();

            let left = buttonRect.x;
            let top = buttonRect.y + buttonRect.height + 10;

            const position = props;

            if (contentRect.left < 0) {
                left += props.fitContentToRight ?? Math.abs(contentRect.left);
            }

            if (position.contentVertical === "inline") {
                top = buttonRect.y;
            } else if (position.contentVertical === "top") {
                top = buttonRect.y -= contentRect.height / 2 + 5;
                top = top - buttonRect.height * 1.5;
            }

            if (position.contentHorizontal === "right" && position.contentOutside) {
                left = buttonRect.x + buttonRect.width + 10;
            }
            if (position.contentHorizontal === "left") {
                let widthToRemove = contentRect.width - buttonRect.width;
                left -= widthToRemove;
                if (position.contentOutside) {
                    left -= buttonRect.width;
                }
            }
            if (position.contentHorizontal === "center") {
                left -= contentRect.width / 2 - buttonRect.width / 2;
            }

            if (contentRect.width + left > window.innerWidth) {
                let widthLeft = contentRect.width + left;
                let diff = window.innerWidth - widthLeft;
                left += diff - 10;
            }

            const diffTop = window.innerHeight - (contentRect.top + contentRect.height);

            if (diffTop < 0) {
                top -= Math.abs(diffTop);
            }

            contentElement.style.left = left + "px";
            contentElement.style.top = top + "px";
        }
    };

    const onClickOpenDropdown = () => {
        updateDropdownPositions();
        setShowContent(!showContent);
        if (props.functionClick) props.functionClick();

        setTimeout(() => {
            updateDropdownPositions();
        }, 20);
    };

    const includesTitle = props.title;
    const isSquareButton = !includesTitle || props.image || props.letterAsIcon;
    const contentShowStyle = getContentShowStyle(showContent);
    const buttonStyle = getButtonStyle(isSquareButton, props.small);

    const notificationCount = props.notificationCount ?? 0;

    return (
        <DropdownContainer w100={props.w100} className="dropdown" style={props.styleContainer}>
            <DefaultButton
                colorText={props.colorText}
                ref={buttonRef}
                type="button"
                id={BUTTON_ID}
                rounded
                className={`${props.btnClassName ?? ""} ${props.tourName ?? ""} dropdown-btn`}
                hideBorder={props.hideBorder}
                disabled={props.disabled || props.isLoading}
                bgLight={props.bgLight || isSquareButton}
                style={{ ...styleButton, ...buttonStyle }}
                colorLight={props.textLight}
                onClick={onClickOpenDropdown}
                small={props.small}
                borderRadius={props.borderRadius}
                w100
                removeBackground={props.withOutBackgroundButton}
            >
                {props.isLoading ? (
                    <LoadingDualRing small />
                ) : (
                    <>
                        {props.imageIcon && (
                            <div className={`dropdown-image-icon ${props.smallImage ? "small" : ""}`}>
                                <img id={BUTTON_ID} src={props.imageIcon} alt="" />
                            </div>
                        )}
                        {props.letterAsIcon && <span id={BUTTON_ID}>{props.letterAsIcon}</span>}
                        {props.image && <img className={`dropdown-image-circle`} id={BUTTON_ID} src={props.image} alt="" />}

                        {!!(iconPosition === "left" && !props.hideIcon && props.icon) && (
                            <span id={`span-icon ${BUTTON_ID}`} className={props.icon}></span>
                        )}

                        {props.customIcon}

                        {includesTitle && (
                            <>
                                {props.prefix && <span id={BUTTON_ID}>{props.prefix}</span>}
                                <span className={`${props.dropIsFilter ? "text-primary" : ""} dropdown-title`} id={BUTTON_ID}>
                                    {props.title}
                                </span>
                            </>
                        )}
                        {iconPosition === "right" && !props.hideIcon && (
                            <span
                                id={BUTTON_ID}
                                className={`${props.icon ?? "fa-regular fa-angle-down"} ${props.dropIsFilter ? "text-primary" : ""} ${
                                    isSquareButton ? "" : "dropdown-right-icon"
                                }`}
                            />
                        )}
                    </>
                )}
                {notificationCount > 0 && <span className="notification-counter">{notificationCount}</span>}
            </DefaultButton>
            <DropdownContent ref={contentRef} style={{ ...styleContent, ...contentShowStyle }} id={CONTENT_ID}>
                {(!props.renderOnShow || showContent) && props.children}
            </DropdownContent>
        </DropdownContainer>
    );
}
