import moment from "moment";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { IInvoiceFormatPurchaseProps } from ".";
import { getAccountUserFullName } from "../../../api/models/accountUser";
import translate from "../../../i18n/translate";
import globalMessages from "../../../messages";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { formatMoney, getDateFormatll } from "../../../utils";
import OrderProfileMessages from "../../Orders/OrderProfile/messages";
import { CardBodySmall, FlexColumn, InvoiceDesignCardSmall, InvoiceTableSmall } from "../styled";

export default function InvoiceFormatSmall(props: IInvoiceFormatPurchaseProps) {
    const inlt = useIntl();
    const { order, testMode, template } = props;
    const { accountUserState } = useContext(AccountUserContext);

    const customer = order.supplier;

    const getCustomer = () => {
        if (customer) {
            return customer.firstName + " " + customer.lastName;
        }
        return "General";
    };

    const needPayments = order.totalAmount - order.totalPaid > 0;

    return (
        <InvoiceDesignCardSmall>
            <CardBodySmall>
                {props.image && (
                    <div className="image-container">
                        <div className="image">
                            <img src={props.image} alt="Org" />
                        </div>
                    </div>
                )}
                <div className="border">
                    {template.invoiceTemplateTitle1 && <span className="bold center mb-1">{template?.invoiceTemplateTitle1}</span>}
                    {template.invoiceTemplateTitle2 && <span className="center mb-1">Orden de compra</span>}

                    <span className="bold">Compra: #{order.number}</span>
                    {order.dateCreatedCustom
                        ? <span>Fecha: {getDateFormatll(order.dateCreatedCustom)}</span>
                        : <span>Creado: {getDateFormatll(order.dateCreated)} </span>
                    }
                    <span>Creada Por: {getAccountUserFullName(accountUserState.items.find((x) => x.id === order.accountUserId))}</span>

                    {!needPayments ? (
                        <span className="">Estado: {translate(OrderProfileMessages[order.status]).props.defaultMessage}</span>
                    ) : (
                        <span className="status-card mt-1">
                            ••• {translate(OrderProfileMessages[order.status]).props.defaultMessage} •••
                        </span>
                    )}
                </div>
                <div className="border mt-1">
                    <span className="bold">Proveedor: {getCustomer()}</span>
                    {customer?.phoneNumber && <span>Télefono {customer?.phoneNumber ? customer.phoneNumber : " - - -"}</span>}
                    {customer?.address && <span>Dirección: {customer.address}</span>}
                </div>

                <div className="border mt-1">
                    {order.payments && order.payments.length > 0 && (
                        <>
                            <span className="bold">Total pagado: {formatMoney(order.totalPaid)}</span>
                            {order.payments.map((item, index) => (
                                <span key={index}>
                                    Pago: {inlt.formatMessage(globalMessages[item.method])}: {formatMoney(item.amount)},{" "}
                                    {getDateFormatll(item.dateCreated)}
                                </span>
                            ))}
                        </>
                    )}
                    {order.status === "partial" && needPayments && (
                        <span>Saldo Pendiente: {formatMoney(order.totalAmount - order.totalPaid)}</span>
                    )}
                    {order.totalAmount > order.totalPaid && order.status !== "paid" && (
                        <>
                            <span className="bold mt-1">Vencimiento: Término {order.paymentTermsDays} Días</span>
                            <span>Vence: {moment(order.dateCreated).add(order.paymentTermsDays, "days").format("LL")}</span>
                        </>
                    )}
                </div>
                {!testMode && (
                    <>
                        <InvoiceTableSmall className="mt-2" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th>Descripción</th>
                                    <th className="right">Total</th>
                                </tr>
                                {order.items &&
                                    order.items.map((item, index) => {
                                        if (item.product) {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col">
                                                            <span>{item.product.name}</span>
                                                            <span>
                                                                {formatMoney(item.unitPrice)} x {item.quantity}{" "}
                                                                {item.manualPercentageDiscount
                                                                    ? " -" + item.manualPercentageDiscount + "%"
                                                                    : ""}{" "}
                                                                Recebido: {item.quantityReceived}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="right">{formatMoney(item.unitPrice * item.quantity)}</td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                            </tbody>
                        </InvoiceTableSmall>
                    </>
                )}
                <div className={"border mt-1"}>
                    <span className="bold">Almacen:</span>
                    <span>{order.warehouse?.name}</span>
                    <span className="bold">Cantidad:</span>
                    <span>Total Cantidad: {order.totalQuantity}</span>
                    <span>Total Recibido: {order.totalQuantityReceived}</span>
                </div>
                {template.invoiceTemplateTitle3 && (
                    <div className="border mt-1">
                        <span>{template.invoiceTemplateTitle3}</span>
                    </div>
                )}

                <div className="border mt-1">
                    {order.moneyReceived > 0 && (
                        <FlexColumn className="invoice-details">
                            <span>Cantidad recibida: {formatMoney(order.moneyReceived)}</span>
                            <span className="bold">Cambio: {formatMoney(order.moneyReturned)}</span>
                        </FlexColumn>
                    )}
                    <table>
                        <tbody>
                            <tr>
                                <td>Subtotal</td>
                                <td>{formatMoney(order.subTotal)}</td>
                            </tr>
                            <tr>
                                <td>Impuestos</td>
                                <td>{formatMoney(order.totalTaxItems)}</td>
                            </tr>
                            <tr>
                                <td>Retenciones</td>
                                <td>{formatMoney(order.taxWithholdingValue)}</td>
                            </tr>
                            <tr>
                                <td>Descuento</td>
                                <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                            </tr>
                            <tr>
                                <td className="bold">TOTAL</td>
                                <td className="bold">{formatMoney(order.totalAmount)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {!testMode && <div className="footer-subtitle mt-1">Software By Wahio, suite.wahio.com</div>}
            </CardBodySmall>
        </InvoiceDesignCardSmall>
    );
}
