import {
    AppGripShape,
    AppSettingsTypes,
    CHANGE_MENU_SMALL,
    CHANGE_THEME,
    IAppSettingsState,
    SET_ALL_SETTINGS,
    SET_DEFAULT_LOUNGE,
    SET_DEFAULT_ORGANIZATION,
    SET_DEFAULT_PRICE,
    SET_DEFAULT_WAREHOUSE,
    SET_DELIVERY_SUMMARY,
    SET_LANGUAGE,
    SET_LIST_PRODUCT_SHAPE,
} from "./types";
import { Dispatch } from "react";
import { ProductPriceTypes } from "../api/models/product";
import { IDeliverySummary } from "../api/models/deliveryOrder";

export const changeTheme = (dispatch: Dispatch<AppSettingsTypes>) => {
    return dispatch({ type: CHANGE_THEME });
};

export interface ISettingsActions {
    changeTheme: () => void;
    setLanguage: (value: string) => void;
    setProductShape: (shape: AppGripShape) => void;
    setDefaultPrice: (value: ProductPriceTypes) => void;
    setDefaultOrganization: (value: string) => void;
    setDefaultWarehouse: (value: string) => void;
    setDefaultLounge: (value: string) => void;
    setDeliverySummary: (value: IDeliverySummary) => void;
    changeMenuSmall: () => void;
    setAllSettings: (value: IAppSettingsState) => void;
}

export const useActions = (state: IAppSettingsState, dispatch: Dispatch<AppSettingsTypes>): ISettingsActions => ({
    changeTheme: () => {
        return dispatch({ type: CHANGE_THEME });
    },
    setLanguage: (value: string) => {
        return dispatch({ type: SET_LANGUAGE, value });
    },
    setProductShape: (shape: AppGripShape) => {
        return dispatch({
            type: SET_LIST_PRODUCT_SHAPE,
            shape,
        });
    },
    setDefaultPrice: (value: ProductPriceTypes) => {
        return dispatch({
            type: SET_DEFAULT_PRICE,
            value,
        });
    },
    setDefaultOrganization: (value: string) => {
        return dispatch({
            type: SET_DEFAULT_ORGANIZATION,
            value,
        });
    },
    setDefaultWarehouse: (value: string) => {
        return dispatch({
            type: SET_DEFAULT_WAREHOUSE,
            value,
        });
    },
    setDefaultLounge: (value: string) => {
        return dispatch({
            type: SET_DEFAULT_LOUNGE,
            value,
        });
    },
    setDeliverySummary: (value: IDeliverySummary) => {
        return dispatch({
            type: SET_DELIVERY_SUMMARY,
            value,
        });
    },
    changeMenuSmall: () => {
        return dispatch({
            type: CHANGE_MENU_SMALL,
        });
    },
    setAllSettings: (value: IAppSettingsState) => {
        return dispatch({
            type: SET_ALL_SETTINGS,
            value,
        });
    },
});
