import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { getUserPermissions } from "../../../api/models/accountUser";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { UserContext } from "../../../store/contexts/UserContext";
import Modal, { IModalShow } from "../../modals/Modal";
import PermissionDenied from "../../PermissionDenied";
import CreateRegisterLog, { CreateRegisterLogProps } from "./CreateRegisterLog";
import messages from "./messages";
import RegisterLogView from "./RegisterLogView";

export function CashRegisterLogPanel(props: ICurrentRegisterModal) {
    const { cashRegisterState } = useContext(CashRegisterContext);
    const intl = useIntl();

    const { userState } = useContext(UserContext);

    const user = userState.user;

    if (user?.type !== "seller" && !getUserPermissions(userState.user).cashRegister?.activateCashRegister) {
        return <PermissionDenied message={intl.formatMessage(messages.doNotActivate)} />;
    }

    if (!cashRegisterState.logActive) {
        return <CreateRegisterLog {...props} />;
    }

    return <RegisterLogView hideCloseBtn={props.hideHomeLink} blockScreen={props.blockScreen} setShowModal={props.setShow} />;
}

interface ICurrentRegisterModal extends IModalShow, CreateRegisterLogProps {
    useButtonClose?: boolean;
}
export function CurrentRegisterLogModal(props: ICurrentRegisterModal) {
    const { cashRegisterState } = useContext(CashRegisterContext);
    return (
        <Modal
            scrollInside={false}
            overflowAuto={false}
            sizeType="sm2"
            ignoreOutsideClick
            title={"Caja Registradora"}
            {...props}
            useButtonClose={!cashRegisterState.logActive && props.useButtonClose}
        >
            <CashRegisterLogPanel {...props} />
        </Modal>
    );
}
