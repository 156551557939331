import styled from "styled-components";

interface IInputGroup {
    bottomSpace?: boolean;
}

export const ShippingLabelContainer = styled.div`
    font-size: 13px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;

    textarea {
        &:disabled {
            padding: 0px;
            border: 0px;
            background: ${(props) => props.theme.palette.background};
        }
    }
    .left-panel {
        width: 40%;
    }
    .right-panel {
        width: 60%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        .left-panel {
            width: auto;
        }
        .right-panel {
            width: 100%;
        }
    }
`;

export const ButtonsLabel = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: auto;
    overflow: hidden;
`;

export const InputGroupLabel = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    margin-bottom: ${(props: IInputGroup) => (props.bottomSpace ? "10px" : "0")};
    &.bottom-space {
        margin: 10px;
    }

    input {
        width: 100%;
    }
    label {
        margin-bottom: 5px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const SelectedLabelOptions = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    height: auto;
    font-size: 13px;
`;

export const ContainerQr = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
`;

export const FooterLabel = styled.div`
    display: flex;
`;
