import React from "react";

export class ClickResult {
    result: boolean = false;
}

export const ignoreInternalClick = (element: any, value: ClickResult, elementId: string) => {
    let parentElement = element.parentElement;
    if (parentElement) {
        if (parentElement.id === elementId) {
            value.result = true;
        } else ignoreInternalClick(parentElement, value, elementId);
    }
};

export const getContentShowStyle = (show: boolean) => {
    let style: React.CSSProperties = {
        visibility: show ? "visible" : "hidden",
        opacity: show ? 1 : 0,
        height: show ? "auto" : "0px",
        transition: show ? "height 0ms 0ms, opacity 400ms 0ms" : "height 0ms 300ms, opacity 300ms 0ms",
    };
    return style;
};
export const getButtonStyle = (square?: boolean, small?: boolean) => {
    let len = small ? 28 : 33;
    let lenStr = `${len}px`;

    if (!square) {
        let style: React.CSSProperties = {
            justifyContent: "flex-start",
        };
        return style;
    }
    let style: React.CSSProperties = {
        justifyContent: "center",
        width: lenStr,
        minWidth: lenStr,
        height: lenStr,
        padding: 0,
        overflow: "hidden",
    };

    if (small) {
        style.fontSize = "12px";
    }
    return style;
};
