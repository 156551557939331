import React, { useContext, useEffect, useState } from "react";
import { PurchaseItemFormProps, QuantityCard } from ".";
import { ITaxOrderItem, convertTaxOrderItem } from "../../../api/models/orderBase";
import { IPurchaseItem } from "../../../api/models/purchase";
import { ITax } from "../../../api/models/tax";
import { TaxContext } from "../../../store/contexts/TaxContext";
import { formatMoney } from "../../../utils";
import { calculateItemBasePrice, extractTaxInformation, getItemTotalTax } from "../../../utils/taxes";
import TextareaChat from "../../Chats/ChatContainer/TextareaChat";
import { OrderItemCardModalContainer } from "../../Orders/OrderItemRow/styled";
import { ProductProfileModal } from "../../Products/ProductProfileNavigation";
import TaxOrderItemModal from "../../Products/Taxes/TaxOrderItem/TaxOrderItemModal";
import { getProductSku, getProductThumbnailUrl } from "../../Products/helper";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal, { IModalShow } from "../../modals/Modal";
import { onPurchaseItemPriceChange } from "../CreatePurchaseForm/helpers";

interface PurchaseItemModalProps extends IModalShow, PurchaseItemFormProps {}

export default function PurchaseItemModal(props: PurchaseItemModalProps) {
    const [purchaseItem, setPurchaseItem] = useState(props.purchaseItem);
    const [showTaxesModal, setShowTaxesModal] = useState(false);
    const { product } = purchaseItem;
    const [showProductProfile, setShowProductProfile] = useState(false);
    const { taxState, taxActions } = useContext(TaxContext);

    const onPriceChange = (newValue: number) => {
        onPurchaseItemPriceChange(purchaseItem, newValue, (result) => {
            calculateChangesValues(result);
        });
    };

    useEffect(() => {
        taxActions.fetchIfNotExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickRemoveTax = (tax: ITaxOrderItem) => {
        let item = { ...purchaseItem };
        let taxes = item.taxes.filter((x) => x.taxId !== tax.taxId);
        item.taxes = taxes;
        calculateChangesValues(item);
    };

    const calculateChangesValues = (item: IPurchaseItem) => {
        let [taxPercentage] = extractTaxInformation(item.taxes);
        let basePriceResult = calculateItemBasePrice(item, item.taxes, true, false);
        item.basePrice = basePriceResult.basePrice;
        item.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;

        let extraPrice = 0;
        if (item.optionalsConfig && item.optionalsConfig.totalExtraPrice > 0) {
            extraPrice = item.optionalsConfig.totalExtraPrice;
        }

        const taxAmount = getItemTotalTax(item.taxes, item, true, false);
        const subTotal = (item.basePrice + extraPrice) * item.quantity;
        const manualPercentageDiscountValue = (item.manualPercentageDiscount * item.unitPrice) / 100;
        const total = (item.basePrice + extraPrice + taxAmount - manualPercentageDiscountValue) * item.quantity;

        setPurchaseItem({
            ...item,
            basePrice: basePriceResult.basePrice,
            taxes: item.taxes,
            totalTax: taxAmount,
            totalTaxPercentage: taxPercentage,
            subTotal,
            manualPercentageDiscount: item.manualPercentageDiscount,
            manualPercentageDiscountValue,
            total,
        });
    };

    const onClickTax = (tax: ITax) => {
        let item = { ...purchaseItem };
        let existingTax = item.taxes.filter((x) => x.taxId === tax.id);

        if (existingTax.length === 0) {
            let taxes = [...item.taxes, convertTaxOrderItem(tax, item.basePrice, false)];
            item.taxes = taxes;
            calculateChangesValues(item);
        }
    };

    const updateQuantity = (value: IPurchaseItem) => {
        calculateChangesValues(value);
    };

    const onChangeDiscount = (value: number) => {
        let item = { ...purchaseItem };
        item.manualPercentageDiscount = value;
        calculateChangesValues(item);
    };

    let containerClass = "";
    if (purchaseItem.product?.includesOptionals) {
        containerClass = "with-optionals";
    }

    let isDisabled = false;
    if (purchaseItem.optionalsConfig && !purchaseItem.optionalsConfig.isCompleted) {
        isDisabled = true;
    }

    const productSku = getProductSku(product, purchaseItem.productVariant);

    return (
        <Modal
            {...props}
            title={product?.name}
            sizeType={purchaseItem.product?.includesOptionals ? "md2" : "sm"}
            useButtonClose
            ignoreOutsideClick
        >
            <OrderItemCardModalContainer className={containerClass}>
                {showProductProfile && (
                    <ProductProfileModal
                        onUpdateProduct={(product) => {
                            let newItem = { ...purchaseItem, product: product };
                            setPurchaseItem(newItem);
                            props.onUpdateItem(newItem);
                        }}
                        onWarehouseProductChange={(wp) => {
                            if (purchaseItem.warehouseId === wp.warehouseId && purchaseItem.productId === wp.productId) {
                                let newItem = {
                                    ...purchaseItem,
                                    warehouseProduct: wp,
                                    stockTemp: wp.stock - wp.reserved,
                                    availableStock: wp.stock - wp.reserved,
                                };
                                setPurchaseItem(newItem);
                                props.onUpdateItem(newItem);
                            }
                        }}
                        product={product}
                        show={showProductProfile}
                        setShow={setShowProductProfile}
                    />
                )}
                {showTaxesModal && (
                    <TaxOrderItemModal
                        show={showTaxesModal}
                        setShow={setShowTaxesModal}
                        basePrice={purchaseItem.basePrice}
                        orderTaxes={purchaseItem.taxes}
                        taxesAvailable={taxState.items}
                        taxAmount={purchaseItem.totalTax}
                        totalPrice={purchaseItem.unitPrice}
                        onClickTax={onClickTax}
                        onClickRemoveTax={onClickRemoveTax}
                    />
                )}

                <Flex column gap={30} w100>
                    <Flex
                        gap10
                        alignCenter
                        className="panel-product-card"
                        title="Ver perfil del producto"
                        onClick={() => setShowProductProfile(!showProductProfile)}
                    >
                        <FlexImageStyle borderRadius={15} width={40} fitCover>
                            <img src={getProductThumbnailUrl(purchaseItem.product, purchaseItem.productVariant)} alt="" />
                        </FlexImageStyle>
                        <Flex column>
                            <TextField bold>
                                {product?.name} {productSku ? ` • ${getProductSku(product, purchaseItem.productVariant)}` : ""}
                            </TextField>

                            <TextField light small>
                                Stock Disponible {purchaseItem.stockTemp}
                            </TextField>
                        </Flex>
                    </Flex>

                    <div className="modal-grid">
                        <Flex column w100 gap5>
                            <TextField small light>
                                Precio Unitario
                            </TextField>
                            <TextBoxNumeral
                                w100
                                className="textbox-order-item input-rounded input-price"
                                format="money"
                                value={purchaseItem.unitPrice}
                                onNumberChange={(value) => onPriceChange(value)}
                            />
                        </Flex>

                        <Flex column w100 gap5>
                            <TextField small light>
                                Descuento
                            </TextField>
                            <Flex gap5 alignCenter w100>
                                <TextBoxNumeral
                                    w100
                                    className="textbox-order-item input-rounded min"
                                    format="number"
                                    value={purchaseItem.manualPercentageDiscount}
                                    onNumberChange={onChangeDiscount}
                                    maxLength={3}
                                />
                                <i className="fa-regular fa-percent"></i>
                            </Flex>
                        </Flex>

                        <Flex column w100 gap5>
                            <TextField small light>
                                Impuesto
                            </TextField>
                            <DefaultButton w100 rounded onClick={() => setShowTaxesModal(true)}>
                                <span className="tax-percentage">{purchaseItem.totalTaxPercentage}%</span>
                            </DefaultButton>
                        </Flex>
                    </div>

                    <Flex column gap5>
                        <TextField small light>
                            Observación
                        </TextField>

                        <TextareaChat
                            removePadding
                            withBorder
                            hideSendButton
                            placeholder="Agregar observación"
                            initialValue={purchaseItem.comment}
                            onInputChange={(value) => setPurchaseItem({ ...purchaseItem, comment: value })}
                            onSendMessage={() => {}}
                        />
                    </Flex>

                    <QuantityCard onUpdateItem={updateQuantity} purchaseItem={purchaseItem} bigView />

                    <Table cellPadding={0} cellSpacing={0} tdPadding={5} hideBorde tdPaddingRight={20} alignRight>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField light>Sub total</TextField>
                                </td>
                                <td className="min-width">{formatMoney(purchaseItem.subTotal)}</td>
                            </tr>

                            {purchaseItem.manualPercentageDiscount > 0 && (
                                <tr>
                                    <td>
                                        <TextField light>Descuento</TextField>
                                    </td>
                                    <td>{formatMoney(purchaseItem.manualPercentageDiscountValue * purchaseItem.quantity)}</td>
                                </tr>
                            )}
                            {purchaseItem.totalTax > 0 && (
                                <tr>
                                    <td>
                                        <TextField light>Impuestos</TextField>
                                    </td>
                                    <td>{formatMoney(purchaseItem.totalTax * purchaseItem.quantity)}</td>
                                </tr>
                            )}
                            <tr className="border-top-dashed">
                                <td>
                                    <TextField bold fontSize={16}>
                                        Total
                                    </TextField>
                                </td>
                                <td>
                                    <TextField bold fontSize={16}>
                                        {formatMoney(purchaseItem.total)}
                                    </TextField>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Flex gap10 justifyEnd alignCenter w100 style={{ marginTop: "auto" }}>
                        <DefaultButton borderRadius={10} w100 onClick={() => props.setShow(false)}>
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton
                            borderRadius={10}
                            w100
                            disabled={isDisabled}
                            onClick={() => {
                                props.setShow(false);
                                props.onUpdateItem(purchaseItem);
                            }}
                        >
                            Guardar
                        </PrimaryButton>
                    </Flex>
                </Flex>
            </OrderItemCardModalContainer>
        </Modal>
    );
}
