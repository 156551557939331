import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { CountryPhoneCode, COUNTRY_PHONES_CODES } from "../../constants/countryPhoneCodes";
import { TextField } from "../_controls";
import { SelectBody } from "../Select/styled";

interface CountryPhoneSelectorProps {
    defaultValue?: string;
    onChangeState: (value?: CountryPhoneCode) => void;
    hideTitle?: boolean;
    small?: boolean;
    title?: string;
    disabled?: boolean;
    w100?: boolean;
}

const CustomOptionLine = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
    cursor: pointer;
    &.center {
        text-align: center;
        justify-content: center;
        font-weight: 500;
    }
`;

export interface SelectOption {
    label: string;
    value: string;
    center?: boolean;
    object: CountryPhoneCode;
}

const CustomOption = (props: SelectOption) => {
    return (
        <CustomOptionLine className={props.center ? "center" : ""}>
            {props.value} <TextField>{props.label}</TextField>
        </CustomOptionLine>
    );
};

export function CountryPhoneSelector(props: CountryPhoneSelectorProps) {
    const getOption = (item: CountryPhoneCode) => {
        let option: SelectOption = {
            label: `+${item.dial_code}`,
            value: item.name ?? "NA",
            object: item,
        };
        return option;
    };

    const getOptionFromValue = (value: string) => {
        let country: CountryPhoneCode = {
            name: "",
            dial_code: value,
            code: "",
        };
        let option: SelectOption = {
            label: "+" + value,
            value: "",
            object: country,
            center: true,
        };
        return option;
    };

    return (
        <SelectBody className={`hover ${props.w100 ? "w100" : ""}`} menuMinWidth={200} selectMinWidth={70} hideIndicator>
            {!props.hideTitle && <span className="label">{props.title ? props.title : "Phone Code"}</span>}
            <Select
                formatOptionLabel={CustomOption}
                placeholder={"Seleccionar"}
                className={`${props.defaultValue ? "" : ""}`}
                classNamePrefix="select"
                key={props.defaultValue}
                value={props.defaultValue ? getOptionFromValue(props.defaultValue) : undefined}
                isDisabled={props.disabled}
                onChange={(value) => props.onChangeState(value?.object)}
                isSearchable={true}
                name="phoneCodes"
                options={COUNTRY_PHONES_CODES.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
