import { defineMessages } from "react-intl";

const base = "components.warehouseForm";

export default defineMessages({
    required: {
        id: `${base}.required`,
        defaultMessage: "Campo requerido",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre de la bodega",
    },
    address: {
        id: `${base}.address`,
        defaultMessage: "Dirección",
    },
    createWarehouse: {
        id: `${base}.createWarehouse`,
        defaultMessage: "Crear Bodega",
    }, 
    warehouseCreatedSuccessfully: {
        id: `${base}.warehouseCreatedSuccessfully`,
        defaultMessage: "Bodega creada correctamente",
    },
    warehouseUpdatedSuccessfully: {
        id: `${base}.warehouseUpdatedSuccessfully`,
        defaultMessage: "Bodega actualizada correctamente",
    }
});
