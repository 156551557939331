import React from "react";
import OrderProfile from ".";
import { Order } from "../../../api/models/order";
import Modal, { IModalShow } from "../../modals/Modal";
import { Flex } from "../../_controls";

interface OrderProfileModalProps extends IModalShow {
    order: Order;
    onOrderChange?: (order: Order) => void;
}

export default function OrderProfileModal(props: OrderProfileModalProps) {
    return (
        <Modal {...props} sizeType="md" useMobileView removeVerticalAlign showHeader title={`Orden #${props.order.number}`}>
            <Flex padding={15}>
                <OrderProfile onUpdateOrder={props.onOrderChange} showSimpleView order={props.order} />
            </Flex>
        </Modal>
    );
}
