import styled from "styled-components";

export const FormContainer = styled.form`
    padding: 15px 20px;
`;

interface FormGroupProps {
    topSpace?: boolean;
    bottomSpace?: boolean;
    bottomSpaceSmall?: boolean;
    rightSpace?: boolean;
    leftSpace?: boolean;
}

export const FormGroupRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    &.nowrap {
        flex-wrap: nowrap;
    }
    &.gap {
        gap: 15px;
    }

    &.space-between {
        justify-content: space-between;
    }

    ${(props: FormGroupProps) => (props.topSpace ? "margin-top: 20px;" : "")}
    ${(props: FormGroupProps) => (props.bottomSpace ? "margin-bottom: 20px;" : "")}
    ${(props: FormGroupProps) => (props.bottomSpaceSmall ? "margin-bottom: 10px;" : "")}
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;

    &.width-100 {
        width: 100%;
        input {
            width: 100%;
        }
    }

    .label,
    label {
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 4px;
        font-size: 14px;
        &.mb {
            margin-bottom: 10px;
        }
        &.small {
            font-size: 13px;
        }
    }
    ${(props: FormGroupProps) => (props.topSpace ? "margin-top: 20px;" : "")}
    ${(props: FormGroupProps) => (props.bottomSpace ? "margin-bottom: 20px;" : "")}
    ${(props: FormGroupProps) => (props.rightSpace ? "margin-right: 20px;" : "")}
    ${(props: FormGroupProps) => (props.leftSpace ? "margin-left: 20px;" : "")}
    ${(props: FormGroupProps) => (props.bottomSpaceSmall ? "margin-bottom: 10px;" : "")}
`;

export const SmallDescription = styled.span`
    display: flex;
    font-size: 13px;
    color: ${(props) => props.theme.palette.textLight};
    ${(props: FormGroupProps) => (props.topSpace ? "margin-top: 20px;" : "")}
    ${(props: FormGroupProps) => (props.bottomSpace ? "margin-bottom: 20px;" : "")}
    ${(props: FormGroupProps) => (props.rightSpace ? "margin-right: 20px;" : "")}
    ${(props: FormGroupProps) => (props.leftSpace ? "margin-left: 20px;" : "")}
    ${(props: FormGroupProps) => (props.bottomSpaceSmall ? "margin-bottom: 10px;" : "")}
`;

export const FormFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;

    &.margin-none {
        margin-top: 0px;
    }
`;
