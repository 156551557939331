import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { postCashRegisterEntry } from "../../../api/account/cashRegisterApi";
import { ICashRegisterEntry } from "../../../api/models/cashRegister";
import { Order } from "../../../api/models/order";
import { IOrderPayment } from "../../../api/models/orderPayment";
import { createUpdateOrder } from "../../../api/orders/orderApi";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { CloudImages } from "../../../assets";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { useOrganizationContext } from "../../../store/contexts/OrganizationContext";
import { formatMoney, getUniqueId } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import { useNotificationPayment } from "../../CashRegisters/CashRegisterEntries/PaymentReceipt/NotificationPaymentReceipt";
import { getEntryFromOrderPayment } from "../../CashRegisters/CashRegisterEntries/entryHelper";
import { getOrgCashRegisterOrDefault } from "../../CashRegisters/cashRegisterUtils";
import LoadingDualRing from "../../LoadingDualRing";
import PaymentsForm from "../../Payments/PaymentsForm";
import usePaymentHook from "../../Payments/paymentHooks";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, TextField } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";
import { NotificationCard, NotificationItem } from "../CreateOrderForm/FinalizeOrderForm/NotificationOrderCard";
import { getTotalPayments } from "../CreateOrderForm/FinalizeOrderForm/finalizeHelper";
import messages from "./messages";
import { SinglePaymentModalContainer } from "./styled";
import { PaymentReceiptPrint } from "../../CashRegisters/CashRegisterEntries/PaymentReceipt/PaymentReceiptPrint";

interface CreateOrderPaymentModalProps extends IModalShow {
    order: Order;
    onOrderChange: (order: Order) => void;
}

interface PaymentResult {
    payment?: IOrderPayment;
    entry?: ICashRegisterEntry;
}

export const CreateSinglePayment = (props: CreateOrderPaymentModalProps) => {
    const alert = useAlert();
    const { order } = props;
    const intl = useIntl();
    const { appSettingsState } = useContext(AppSettingsContext);
    const { cashRegisterState } = useContext(CashRegisterContext);

    const { organizationState } = useOrganizationContext();

    const [paymentResult, setPaymentResult] = useState<PaymentResult>({});

    const paymentHook = usePaymentHook();

    const gatewayId = React.useMemo(() => {
        return getUniqueId();
    }, []);

    const [notificationState, setNotificationState] = useState<NotificationItem>({
        phonePrefix: order.customer?.phonePrefix ?? "57",
        phoneNumber: order.customer?.phoneNumber ?? "",
        email: order.customer?.email ?? "",
        contactName: order.customer?.firstName ?? "",
    });

    const [payments, setPayments] = useState<IOrderPayment[]>(() => {
        let payment = paymentHook.getNewPayment({ gatewayId: gatewayId, ignoreCashRegisterEntry: true });
        return payment ? [payment] : [];
    });

    const totalPay = getTotalPayments([...order.payments, ...payments]);
    const absoluteAmount = Math.round(order.totalAmount * 100) / 100;
    const differencePrices = absoluteAmount - totalPay;

    const notificationPayment = useNotificationPayment({
        organization: organizationState.currentOrganization,
        trackingNumber: order.trackingNumber,
    });

    const entryMutation = useMutation((data: ICashRegisterEntry) => postCashRegisterEntry(data), {
        onSuccess: (data) => {
            setPaymentResult((prev) => ({ ...prev, entry: data }));
            if (!notificationState.isDisabled) {
                notificationPayment.onSendSms(notificationState, data);
                notificationPayment.onSendEmail(notificationState, data);
            }
        },
    });

    const saveMutation = useMutation((data: Order) => createUpdateOrder(data), {
        onSuccess: (data) => {
            props.onOrderChange(data);
            alert.success(intl.formatMessage(messages.paymentCreatedSuccessfully));

            var payment = data.payments?.find((x) => x.paymentGatewayId === gatewayId);
            if (payment) {
                entryMutation.mutate(getEntryFromOrderPayment(data, payment, true));
                setPaymentResult((prev) => ({ ...prev, payment: payment }));
            }
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        if (organizationState.currentOrganization) {
            let cashRegisterId = getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization);

            if (!cashRegisterId) {
                alert.error("La Tienda actual debe tener una caja asignada, o debes activar una caja para poder agregar pagos");
                return;
            }

            let paymentsCopy: IOrderPayment[] = [];
            payments.forEach((element) => {
                element.cashRegisterId = cashRegisterId ?? "";
                paymentsCopy.push(element);
            });
            setPayments(paymentsCopy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationState.currentOrganization]);

    const onUpdatePayment = (payments: IOrderPayment[]) => {
        if (payments.length === 0) {
            alert.error("Debes agregar al menos un pago");
            return;
        }

        if(payments.some(x => x.amount === 0)){
            alert.error("El pago debe ser mayor a 0")
            return;
        } 

        let orderObj = {
            ...order,
            payments: [...order.payments, ...payments],
        };

        saveMutation.mutate(orderObj);
    };

    //getEntryFromOrderPayment
    const blockPayments = organizationState.currentOrganization?.requiredCashRegister && !cashRegisterState.logActive;
    if (blockPayments) {
        return (
            <Flex column alignCenter padding={30} gap15>
                <Flex>
                    <FlexImageStyle>
                        <img src={CloudImages.lockPayment} alt="" />
                    </FlexImageStyle>
                </Flex>
                <Flex column alignCenter>
                    <span className="text-light">Pago bloqueado</span>
                    <span className="">Se requiere activar la caja para crear pagos</span>
                </Flex>
            </Flex>
        );
    }

    const isLoading = saveMutation.isLoading || entryMutation.isLoading;

    if (paymentResult.payment) {
        return (
            <Flex column alignCenter padding={30} gap15>
                <TextField fontSize={40} primaryColor className="fa-regular fa-circle-check"></TextField>
                <Flex column alignCenter>
                    <TextField bold>Pago creado</TextField>
                    <TextField small light>
                        El pago se ha creado correctamente
                    </TextField>
                </Flex>

                {entryMutation.isLoading && <LoadingDualRing small />}
                {paymentResult.entry && <PaymentReceiptPrint btnTitle="Imprimir Recibo" entry={paymentResult.entry} />}
            </Flex>
        );
    }

    return (
        <SinglePaymentModalContainer>
            <Flex gap={20} flexWrap>
                <Flex column>
                    <TextField small light>
                        Total orden
                    </TextField>
                    <TextField>{formatMoney(absoluteAmount)}</TextField>
                </Flex>
                <Flex column>
                    <TextField small light>
                        Pago Pendiente
                    </TextField>
                    <TextField>{formatMoney(differencePrices)}</TextField>
                </Flex>
                <Flex column>
                    <TextField small light>
                        Pago acumulado
                    </TextField>
                    <TextField>{formatMoney(totalPay)}</TextField>
                </Flex>
            </Flex>

            <PaymentsForm payments={payments ?? []} onChange={setPayments} uniqueMethod={false} totalAmount={differencePrices} />

            <NotificationCard showDisabledButton showContactName item={notificationState} onChange={setNotificationState} />

            <Flex justifyEnd gap10 marginTop={10}>
                <DefaultButton disabled={isLoading} borderRadius={10} onClick={() => props.setShow(false)}>
                    Cancel
                </DefaultButton>
                <PrimaryButton disabled={isLoading} borderRadius={10} onClick={() => onUpdatePayment(payments)}>
                    Crear Pago {isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </SinglePaymentModalContainer>
    );
};

export default function CreateSinglePaymentModal(props: CreateOrderPaymentModalProps) {
    return (
        <Modal {...props} showHeader title="Crear Pago">
            <CreateSinglePayment {...props} />
        </Modal>
    );
}
