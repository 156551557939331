import React from "react";
import Modal, { IModalShow } from "../../../modals/Modal";
import FinalizeOrderForm, { FinalizeOrderFormProps } from ".";

interface ModalFinalizeOrderProps extends IModalShow, FinalizeOrderFormProps {}

export default function ModalFinalizeOrder(props: ModalFinalizeOrderProps) {
    return (
        <Modal
            removeVerticalAlign={true}
            ignoreOutsideClick
            overflowBodyNormal
            useButtonClose
            borderRadius={30}
            title={"Finalizar"}
            useMobileView
            {...props}
        >
            <FinalizeOrderForm {...props} />
        </Modal>
    );
}
