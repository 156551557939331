import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreateTable from "react-select/creatable";
import { ProductCreationProps } from ".";
import { IProduct, IProductVariant } from "../../../api/models/product";
import { IProductTax, ITax } from "../../../api/models/tax";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import { useTaxContext } from "../../../store/contexts/TaxContext";
import { PriceSettingsPanelButton } from "../../Account/ClientSettings/PriceSettingsPanel";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, DefaultInput, SquareButton } from "../../_controls";
import { Flex, TextField } from "../../_controls/containers";
import TaxFormModal from "../Taxes/TaxForm/TaxFormModal";
import messages from "./messages";
import { ColumnContainerProduct, FormInputProduct, TablePrices } from "./styled";

export type PricesTypes = "price" | "price2" | "price3" | "price4" | "price5" | "price6" | "price7" | "price8" | "price9" | "price10";

export const pricesNames: PricesTypes[] = [
    "price",
    "price2",
    "price3",
    "price4",
    "price5",
    "price6",
    "price7",
    "price8",
    "price9",
    "price10",
];

export const filteredTaxes = (taxes: ITax[], productTaxes?: IProductTax[]) => {
    if (!productTaxes) return taxes;

    return taxes.filter((tx) => !productTaxes.find((x) => x.taxId === tx.id));
};
interface InputNumberProductProps {
    product: IProduct | IProductVariant;
    onChangeProduct: (value: any) => void;
}

export const InputNumberProduct = (props: InputNumberProductProps) => {
    const { product, onChangeProduct } = props;
    const { priceSettings } = useClientSettings();

    const priceListNames = priceSettings.priceList ?? {};

    const [priceListState, setPriceListState] = useState<PricesTypes[]>([]);

    useEffect(() => {
        const priceValues = Object.values(_.pick(product, pricesNames));
        let lastPriceIndex = _.findLastIndex(priceValues, (x) => x !== 0);
        if (lastPriceIndex <= 0) lastPriceIndex = 0;
        setPriceListState(pricesNames.slice(0, lastPriceIndex + 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    const onNumberChange = (value: number, name: string) => {
        onChangeProduct({
            ...product,
            [name]: value,
        });
    };

    const getValue = (name: PricesTypes) => {
        return product[name] ?? 0;
    };

    const deletePrice = (index: number) => {
        product[priceListState[index]] = 0;
        const priceList = priceListState.filter((x, i) => i !== index);
        setPriceListState(priceList);
    };

    const onAddPrice = () => {
        if (pricesNames.length === priceListState.length) return;
        setPriceListState([...priceListState, pricesNames[priceListState.length]]);
    };

    const onChangePriceWithPercentage = (value: string, name: string) => {
        const percentage = parseInt(value) / 100;
        const valueNum = product.basePrice * (1 + percentage);
        onNumberChange(valueNum, name);
    };

    const getValuePercentage = (name: PricesTypes) => {
        const baseNum = Math.floor(product.basePrice);
        const valueNum = product[name];
        const percentage = (valueNum - baseNum) / baseNum;
        const customPercentage = isNaN(percentage) || !isFinite(percentage) ? 0 : Math.round(percentage * 100);
        return customPercentage;
    };

    return (
        <Flex column gap15>
            <TablePrices cellPadding={0} cellSpacing={0} tdPadding={3} tdPaddingRight={15}>
                <tbody>
                    {priceListState.map((name, index) => (
                        <tr key={index}>
                            <td className="min-width">
                                <TextField ellipsis light small>
                                    {priceListNames[name].name ?? name}
                                </TextField>
                            </td>
                            <td>
                                <TextBoxNumeral
                                    rounded
                                    name={name}
                                    w100
                                    format="money"
                                    onNumberChange={(value) => onNumberChange(value, name)}
                                    value={getValue(name)}
                                    className="input-price input-price-value"
                                />
                            </td>
                            <td className="min-width">
                                <Flex gap5>
                                    <DefaultInput
                                        className={`input-price input-price-percentage`}
                                        value={getValuePercentage(name)}
                                        onChange={(e) => onChangePriceWithPercentage(e.target.value, name)}
                                        background
                                        small
                                        maxLength={4}
                                        alignEnd
                                    />
                                    <Flex alignCenter justifyCenter>
                                        <TextField bold color={getValuePercentage(name) >= 0 ? "blue" : "red"}>
                                            %
                                        </TextField>
                                    </Flex>
                                </Flex>
                            </td>
                            <td className="min-width">
                                {priceListState.length - 1 === index && (
                                    <SquareButton className="btn-trash" onClick={() => deletePrice(index)}>
                                        <span className="fa-regular fa-trash" />
                                    </SquareButton>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </TablePrices>

            <Flex alignCenter spaceBetween gap10>
                <PriceSettingsPanelButton small title="Re Nombrar" />
                {pricesNames.length !== priceListState.length && (
                    <DefaultButton small bgLight borderRadius={10} onClick={onAddPrice}>
                        <span className="fa-regular fa-plus" /> Precio
                    </DefaultButton>
                )}
            </Flex>
        </Flex>
    );
};

export default function PricesForm(props: ProductCreationProps) {
    const intl = useIntl();
    const { product: currentProduct } = props;

    return (
        <Flex column gap={20}>
            <Flex column gap5>
                <span className="title text-bold">{intl.formatMessage(messages.basePrice)}</span>
                <FormInputProduct>
                    <TextBoxNumeral
                        value={currentProduct.basePrice}
                        format="money"
                        onNumberChange={(value) => props.onChange({ ...currentProduct, basePrice: value })}
                    />
                </FormInputProduct>
            </Flex>

            <Flex gap10 spaceBetween alignCenter>
                <TextField>
                    <FormattedMessage {...messages.salePrices} />:
                </TextField>
            </Flex>
            <ColumnContainerProduct>
                <InputNumberProduct product={currentProduct} onChangeProduct={props.onChange} />
            </ColumnContainerProduct>
            <Flex>
                <TaxSelector {...props} />
            </Flex>
        </Flex>
    );
}

interface TaxSelectorProps extends ProductCreationProps {}

export const TaxSelector = (props: TaxSelectorProps) => {
    const { product: currentProduct } = props;
    const [openTaxModal, setOpenTaxModal] = useState(false);
    const [nameCreateNewTax, setNameCreateNewTax] = useState<string>();
    const { taxState, taxActions } = useTaxContext();
    const intl = useIntl();

    useEffect(() => {
        taxActions.fetchIfNotExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreateTax = (value: ITax) => {
        if (value) {
            const newProductTax: IProductTax = {
                productId: currentProduct.id,
                taxId: value.id ?? "",
                tax: value,
            };

            let taxes = currentProduct.taxes ? [...currentProduct.taxes, newProductTax] : [newProductTax];
            props.onChange({
                ...props.product,
                taxes,
            });
        }
    };

    const getSelectOptionObject = (tax: ITax) => {
        return {
            value: tax.id,
            label: tax.name,
            object: tax,
        };
    };

    return (
        <SelectBody className="multi-value-theme-color">
            {openTaxModal && (
                <TaxFormModal
                    onCancel={() => setOpenTaxModal(false)}
                    onCreate={(result) => {
                        setOpenTaxModal(false);
                        onCreateTax(result);
                    }}
                    show={openTaxModal}
                    setShow={setOpenTaxModal}
                    newTaxName={nameCreateNewTax}
                />
            )}
            <span className="label">{intl.formatMessage(messages.selectTaxes)}</span>
            <CreateTable
                placeholder="Seleccionar"
                className="react-select-basic"
                classNamePrefix="select"
                value={props.product.taxes ? props.product.taxes.map((item) => getSelectOptionObject(item.tax)) : []}
                isDisabled={false}
                isMulti
                isRtl={false}
                onChange={(value) => {
                    props.onChange({
                        ...currentProduct,
                        taxes: value.map((item) => {
                            return {
                                productId: currentProduct.id,
                                taxId: item.object.id ?? "",
                                tax: item.object,
                            };
                        }),
                    });
                }}
                onCreateOption={(value) => {
                    setNameCreateNewTax(value);
                    setOpenTaxModal(true);
                }}
                isClearable={false}
                isSearchable={true}
                name="seller"
                options={filteredTaxes(taxState.items, currentProduct.taxes).map((item) => getSelectOptionObject(item))}
            />
        </SelectBody>
    );
};
