import { removeFirstCapitalize } from "../utils";

export type WahioColorOption =
    | "red"
    | "pink"
    | "deepPurple"
    | "indigo"
    | "blue"
    | "lightBlue"
    | "cyan"
    | "teal"
    | "green"
    | "lightGreen"
    | "yellow"
    | "orange"
    | "deepOrange"
    | "brown"
    | "black";

export const LIGHT_COLORS: WahioColorOption[] = ["lightGreen", "yellow", "teal", "lightBlue"];

export const COLOR_LIST = {
    red: "#f44336",
    pink: "#FF4081",
    deepPurple: "#673ab7",
    purple: "#7C4DFF",
    gold: "#FBC02D",
    indigo: "#303F9F",
    blue: "#2962FF",
    lightBlue: "#03A9F4",
    cyan: "#0097A7",
    teal: "#00BFA5",
    green: "#4caf50",
    lightGreen: "#B2FF59",
    yellow: "#FFD600",
    orange: "#FF6D00",
    deepOrange: "#FF3D00",
    brown: "#9d6c5a",
    black: "#000000",
};

export const colorListES = {
    rojo: COLOR_LIST.red,
    rosa: COLOR_LIST.pink,
    rosado: COLOR_LIST.pink,
    morado: COLOR_LIST.deepPurple,
    azul: COLOR_LIST.blue,
    dora: COLOR_LIST.gold,
    azulClaro: COLOR_LIST.blue,
    verde: COLOR_LIST.green,
    verdeClaro: COLOR_LIST.lightGreen,
    amarillo: COLOR_LIST.yellow,
    naranjado: COLOR_LIST.orange,
    naranja: COLOR_LIST.orange,
    cafe: COLOR_LIST.brown,
    negro: COLOR_LIST.black,
};

export const colorListChat = ["#FF1744", "#7C4DFF", "#00E676", "#2979FF", "#F57F17", "#00B8D4", "#F44336", "#00C853"];

const defaultColor = "#8b8b8b";

export const getColorValue = (colorName?: string) => {
    if (!colorName) return defaultColor;

    let colors = { ...COLOR_LIST, ...colorListES };
    let color = (colors as any)[removeFirstCapitalize(colorName)];
    if (color) return color;
    return defaultColor;
};

export const getColorValueFromLetter = (letter: string) => {
    let index = letter.toLowerCase().charCodeAt(0) - 97;
    if (index < 0 || index > 25) index = 0;
    return getColorFromIndex(index);
};

export const getColorFromIndex = (index: number) => {
    let names = Object.keys(COLOR_LIST);
    let key = names[Math.trunc(index % names.length)];
    let value = (COLOR_LIST as any)[key];
    return value ?? defaultColor;
};

export const getRandomColorName = () => {
    let names = Object.keys(COLOR_LIST);
    var randomIndex = Math.floor(Math.random() * names.length);
    return names[randomIndex];
};

export const getRandomColorValue = () => {
    let names = Object.values(COLOR_LIST);
    var randomIndex = Math.floor(Math.random() * names.length);
    return names[randomIndex];
};
