import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { LocalStoreKeys, getStoredData, saveData } from "..";
import { getCashRegisterEntryCategories, getCashRegisters } from "../../api/account/cashRegisterApi";
import { getCashRegistersByOrganization } from "../../api/account/organizationApi";
import { CashRegisterEntryCategory, ICashRegister } from "../../api/models/cashRegister";
import { CashRegisterLog } from "../../api/models/cashRegisterLog";
import { OrganizationCashRegister } from "../../api/models/organizationTypes";
import { getAuthBody } from "../../auth";
import { useOrganizationContext } from "./OrganizationContext";

export interface ICashRegisterState {
    orgItems: ICashRegister[];
    allItems: ICashRegister[];
    currentCashRegister?: ICashRegister;
    logActive?: CashRegisterLog;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

interface CategoriesState {
    items: CashRegisterEntryCategory[];
    isFetching: boolean;
}

export interface ICashRegisterActions {
    add: (value: ICashRegister) => void;
    update: (value: ICashRegister) => void;
    setCurrentCashRegister: (value: ICashRegister) => void;
    set: (value: ICashRegisterState) => void;
    refetch: () => void;
    loadCategoriesIfNotExists: () => void;
}

export class InitStateBase implements ICashRegisterState {
    orgItems: ICashRegister[] = [];
    allItems: ICashRegister[] = [];
    globalItems: ICashRegister[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ICashRegisterState>(new InitStateBase(), LocalStoreKeys.cashRegister);

interface IContextProps {
    cashRegisterState: ICashRegisterState;
    getCashRegister: (id: string) => ICashRegister | undefined;
    setCashRegisterState: (value: ICashRegisterState) => void;
    cashRegisterActions: ICashRegisterActions;
    entryCategoryState: CategoriesState;
    setEntryCategoryState: (value: CategoriesState) => void;
}

export const CashRegisterContext = createContext({} as IContextProps);

const CashRegisterContextProvider = (props: any) => {
    const [cashRegisterState, setCashRegisterState] = useState(initState);
    const [entryCategoryState, setEntryCategoryState] = useState<CategoriesState>({ items: [], isFetching: false });
    const { organizationState } = useOrganizationContext();

    const cashRegisterKeyValue = React.useMemo(() => {
        return cashRegisterState.allItems.reduce((prev, curr) => {
            prev[curr.id ?? "NA"] = curr;
            return prev;
        }, {} as { [key: string]: ICashRegister });
    }, [cashRegisterState.allItems]);

    useEffect(() => {
        saveData(LocalStoreKeys.cashRegister, cashRegisterState);
    }, [cashRegisterState]);

    useEffect(() => {
        if (organizationState.currentOrganization) {
            requestCashRegisters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationState.currentOrganization]);

    const categoriesMutation = useMutation((accountId: string) => getCashRegisterEntryCategories(accountId), {
        onSuccess: (items) => {
            setEntryCategoryState({ ...entryCategoryState, items, isFetching: false });
        },
        onError: (err) => {
            setEntryCategoryState({ ...entryCategoryState, isFetching: false });
        },
    });

    const loadCategoriesIfNotExists = () => {
        if (categoriesMutation.isLoading) return;

        categoriesMutation.reset();

        if (entryCategoryState.items.length === 0) {
            setEntryCategoryState({ ...entryCategoryState, isFetching: true });
            categoriesMutation.mutate(getAuthBody().accountId);
        }
    };

    const allCashRegisterMutation = useMutation((accountId: string) => getCashRegisters(accountId));
    const organizationMutation = useMutation((orgId: string) => getCashRegistersByOrganization(orgId));

    const getOrgItems = (orgItems: OrganizationCashRegister[]) => {
        let items = orgItems.reduce((items: ICashRegister[], item) => {
            if (item.cashRegister) {
                items.push(item.cashRegister);
            }
            return items;
        }, []);
        return items;
    };

    const requestCashRegisters = async () => {
        const orgId = organizationState.currentOrganization?.id;
        if (cashRegisterState.isFetching) return;
        if (orgId) {
            try {
                setCashRegisterState({ ...cashRegisterState, isFetching: true });
                var orgItems = getOrgItems(await organizationMutation.mutateAsync(orgId));
                var allItems = await allCashRegisterMutation.mutateAsync(getAuthBody().accountId);

                setCashRegisterState({ ...cashRegisterState, orgItems, allItems, isFetching: false });
            } catch (err) {
                console.log(err);
                setCashRegisterState({ ...cashRegisterState, isFetching: false });
            }
        }
    };

    const cashRegisterActions: ICashRegisterActions = {
        refetch: () => {
            requestCashRegisters();
        },
        setCurrentCashRegister: function (value: ICashRegister): void {
            setCashRegisterState({ ...cashRegisterState, currentCashRegister: value });
        },

        set: function (value: ICashRegisterState): void {
            setCashRegisterState(value);
        },
        add: function (value: ICashRegister): void {
            let items = cashRegisterState.orgItems;
            setCashRegisterState({ ...cashRegisterState, orgItems: [...items, value] });
        },
        update: function (value: ICashRegister): void {
            var items = cashRegisterState.orgItems.reduce(
                (prev: ICashRegister[], item) => (item.id === value.id ? [...prev, value] : [...prev, item]),
                []
            );
            setCashRegisterState({ ...cashRegisterState, orgItems: items });
        },
        loadCategoriesIfNotExists,
    };

    const getCashRegister = (id: string) => {
        return cashRegisterKeyValue[id];
    };

    return (
        <CashRegisterContext.Provider
            value={{
                getCashRegister,
                cashRegisterState,
                cashRegisterActions,
                setCashRegisterState,
                entryCategoryState,
                setEntryCategoryState,
            }}
        >
            {props.children}
        </CashRegisterContext.Provider>
    );
};

export const useCashRegisterContext = () => {
    return useContext(CashRegisterContext);
};

export default CashRegisterContextProvider;
