import { defineMessages } from "react-intl";

const base = "components.chats";

export default defineMessages({
    popular: {
        id: `${base}.popular`,
        defaultMessage: "Populares",
    },
    smileysEmotion: {
        id: `${base}.smileys&emotion`,
        defaultMessage: "Emociones",
    },
    peopleBody: {
        id: `${base}.people&body`,
        defaultMessage: "Personas y cuerpo",
    },

    animalsNature: {
        id: `${base}.animals&nature`,
        defaultMessage: "Animales y naturaleza",
    },
    foodDrink: {
        id: `${base}.food&drink`,
        defaultMessage: "Comida y bebida",
    },
    travelPlaces: {
        id: `${base}.travel&places`,
        defaultMessage: "Viajes y lugares",
    },
    activities: {
        id: `${base}.activities`,
        defaultMessage: "Actividades",
    },
    objects: {
        id: `${base}.objects`,
        defaultMessage: "Objetos",
    },

    symbols: {
        id: `${base}.symbols`,
        defaultMessage: "Símbolos",
    },
    flags: {
        id: `${base}.flags`,
        defaultMessage: "Banderas",
    },
});
