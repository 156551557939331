import styled from "styled-components";
import { IThemePalette } from "../../theme/themePalette";
import { SquareButtonOutline } from "../_controls";

interface IWahioModalProps {
    show: boolean;
    theme: {
        palette: IThemePalette;
    };
    zIndex?: number;
}

export const WahioModal = styled.div`
    display: ${(props: IWahioModalProps) => (props.show ? "grid" : "none")};
    position: fixed;
    z-index: ${(props: IWahioModalProps) => (props.zIndex ? props.zIndex : 99)};
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background: ${(props) => props.theme.palette.backgroundModal};

    &.danger {
        .modal-header {
            background: #f44336;
            color: white;
            border: 0;
            .btn-close {
                background: #c7372d;
                color: white;
            }
        }
        .modal-body {
        }
    }

    &.success {
        .modal-header {
            background: ${(props) => props.theme.palette.primary2};
            color: white;
            border: 0;
            .btn-close {
                background: ${(props) => props.theme.palette.primary2}70;
                color: white;
            }
        }
    }

    .m-auto {
        margin: auto;
        box-sizing: border-box;
    }

    .overflow-auto {
        overflow: auto !important;
    }

    &.mobile-view {
        background: ${(props) => props.theme.palette.text}20;
        .modal-body-parent {
            max-width: 100vw;
            padding: 0;
            width: 100%;
            .modal-body {
                border: 0;
                border-radius: 0;
                top: 55px;
                min-height: calc(100% - 55px);
            }
        }
    }

    &.hideScrollBar {
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }
`;

export interface ModalBodyProps {
    heightFitContent?: boolean;
}

export const WahioModalBodyParent = styled.div`
    margin: 0px auto;
    width: 100%;
    display: flow-root;
    padding: 14px;
    box-sizing: border-box;

    ${(props: ModalBodyProps) => (props.heightFitContent ? "height: fit-content;" : "")}

    transition: max-width .3s ease-in-out;
    &.wahio-modal-sm {
        max-width: 500px;
    }
    &.wahio-modal-sm2 {
        max-width: 600px;
    }
    &.wahio-modal-md {
        max-width: 700px;
    }
    &.wahio-modal-md2 {
        max-width: 900px;
    }
    &.wahio-modal-md3 {
        max-width: 1000px;
    }
    &.wahio-modal-auto {
        max-width: fit-content;
    }
    &.wahio-modal-lg {
        max-width: 100%;
        max-width: 1200px;
    }

    &.wahio-modal-lg {
        max-width: 100%;
        max-width: 1500px;
    }

    &.wahio-modal-content-auto-scroll {
        overflow-y: auto;
        max-height: calc(100vh - 180px);
    }
`;

export const WahioModalBody = styled.div`
    background: ${(props) => props.theme.palette.background};
    box-shadow: 1px 2px 12px ${(props) => props.theme.palette.shadowColor};
    border-radius: ${(props: { borderRadius?: number }) => (props.borderRadius ? props.borderRadius + "px" : "20px")};
    ${(props: { borderRadius?: number }) => (props.borderRadius ? "overflow: hidden;" : "")}
    box-sizing: border-box;
    position: relative;

    .btn-close-rounded {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
    }
`;

export const ModalHeader = styled.div`
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    button {
        border-color: transparent;
    }
`;

export const CloseContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 13px;
`;

export const ButtonClose = styled(SquareButtonOutline)`
    height: 35px;
    width: 35px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
`;

export const ModalTitle = styled.div`
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding: 13px;
`;

export const SingleInputContainer = styled.form`
    display: flex;
    padding: 15px;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    input {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 28px;
        font-size: 15px;
    }
`;

export const ModalConfirmationBody = styled.div`
    padding: 30px 24px;
    text-align: center;
    .title {
        margin: 0;
        margin-bottom: 4px;
    }
    .description {
        margin: 0;
    }
    .btns {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
`;
