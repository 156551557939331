import React, { useEffect, useState } from "react";
import { IProduct } from "../../../../api/models/product";
import { CloudImages } from "../../../../assets";
import { Flex, SquareButton } from "../../../_controls";
import WahioTooltip from "../../../_controls/WahioTooltip";
import { ProductVariantOptionContainer } from "../styled";

interface VariantImage {
    image?: any | string;
    isEmpty: boolean;
}

interface ProductVariantOptionPreviewProps {
    product: IProduct;
    onChangeProduct: (value: IProduct) => void;
    activeVariantOption: { [key: string]: string };
    setActiveVariantOption: (value: { [key: string]: string }) => void;
    allowEdit?: boolean;
}

export default function ProductVariantOptionPreview(props: ProductVariantOptionPreviewProps) {
    const { product, activeVariantOption, setActiveVariantOption } = props;
    const [defaultVariantImages, setDefaultVariantImages] = useState<{ [key: string]: VariantImage }>({});

    useEffect(() => {
        loadInitialVariants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.variants, product.variantImageForOption]);

    const loadInitialVariants = () => {
        let defaultVariantName = product.variantOptions?.find((x) => x.name === product.variantImageForOption)?.name;
        if (defaultVariantName) {
            if (product.variants) {
                let objResult = product.variants.reduce((obj: { [key: string]: VariantImage }, item) => {
                    var option = item.options.find((x) => x.variantName === product.variantImageForOption);
                    if (option) {
                        let image = item.images && item.images.length > 0 ? item.images[0].url : undefined;
                        let existing = obj[option.value];
                        if (existing?.image) {
                            return obj;
                        }
                        obj[option.value] = { image: image, isEmpty: !image };
                    }
                    return obj;
                }, {});
                setDefaultVariantImages(objResult);
            }
        }
    };

    return (
        <ProductVariantOptionContainer>
            {product.variantOptions?.map((option, index) => {
                let imageActive = option.name === product.variantImageForOption;
                return (
                    <Flex gap15 alignEnd key={index}>
                        {props.allowEdit && (
                            <WahioTooltip
                                timeout={400}
                                text="Activar/Desactivar vista de variante como imagen"
                                trigger={
                                    <SquareButton
                                        className={`btn-variant-image ${imageActive ? "active" : ""}`}
                                        onClick={() =>
                                            props.onChangeProduct({
                                                ...product,
                                                variantImageForOption: imageActive ? undefined : option.name,
                                            })
                                        }
                                    >
                                        <span className="wahioicon-image"></span>
                                    </SquareButton>
                                }
                            />
                        )}
                        <Flex key={index} column gap5>
                            <span className="title">{option.name}</span>
                            <div className="items">
                                {option.values.map((value, index) => {
                                    let image: any | string | undefined;
                                    if (option.name === product.variantImageForOption) {
                                        image = defaultVariantImages[value]?.image ?? CloudImages.defaultImage;
                                    }
                                    return (
                                        <div
                                            className={`item ${image ? "image" : ""} ${
                                                activeVariantOption[option.name] === value ? "active" : ""
                                            }`}
                                            key={index}
                                            onClick={() => setActiveVariantOption({ ...activeVariantOption, [option.name]: value })}
                                        >
                                            {image ? <img src={image} alt={value} /> : value}
                                        </div>
                                    );
                                })}
                            </div>
                        </Flex>
                    </Flex>
                );
            })}
        </ProductVariantOptionContainer>
    );
}
