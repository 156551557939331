import { defineMessages } from "react-intl";

const base = "components.accountingPayments";

export default defineMessages({
    accountsPayable: {
        id: `${base}.accountsPayable`,
        defaultMessage: "Cuentas por pagar",
    },
    accountsReceivable: {
        id: `${base}.accountsReceivable`,
        defaultMessage: "Cuentas por cobrar",
    },
    payable: {
        id: `${base}.payable`,
        defaultMessage: "Por pagar",
    },
    receivable: {
        id: `${base}.receivable`,
        defaultMessage: "Por cobrar",
    },
    paymentsMade: {
        id: `${base}.paymentsMade`,
        defaultMessage: "Pagos realizados",
    },
    paymentsReceived: {
        id: `${base}.paymentsReceived`,
        defaultMessage: "Pagos recibidos",
    },
    balance: {
        id: `${base}.balance`,
        defaultMessage: "Saldo",
    },
    balanceDue: {
        id: `${base}.balanceDue`,
        defaultMessage: "Saldo vencido",
    },
    lastPaymentDate: {
        id: `${base}.lastPaymentDate`,
        defaultMessage: "Último pago",
    },
    orderCount: {
        id: `${base}.numberOfOrders`,
        defaultMessage: "Cantidad de ordenes",
    },
    order: {
        id: `${base}.order`,
        defaultMessage: "Orden",
    },
    purchase: {
        id: `${base}.purchase`,
        defaultMessage: "Compra",
    },
    expense: {
        id: `${base}.expense`,
        defaultMessage: "Gasto",
    },
    transactionAccount: {
        id: `${base}.transactionAccount`,
        defaultMessage: "Cuenta Externa",
    },
    price: {
        id: `${base}.price`,
        defaultMessage: "Precio",
    },
    date: {
        id: `${base}.date`,
        defaultMessage: "Fecha",
    },
    contactName: {
        id: `${base}.contactName`,
        defaultMessage: "Contacto",
    },
    contactEmail: {
        id: `${base}.contactEmail`,
        defaultMessage: "Email",
    },
    contactPhone: {
        id: `${base}.contactPhone`,
        defaultMessage: "Teléfono",
    },
    contactDocument: {
        id: `${base}.contactDocument`,
        defaultMessage: "Número Documento",
    },
    orderStatus: {
        id: `${base}.orderStatus`,
        defaultMessage: "Estado",
    },
    orderNumber: {
        id: `${base}.orderNumber`,
        defaultMessage: "Número Orden",
    },
    partial: {
        id: `${base}.partial`,
        defaultMessage: "Parcial",
    },
    credit: {
        id: `${base}.credit`,
        defaultMessage: "Crédito",
    },
    orderCreatedAt: {
        id: `${base}.orderCreatedAt`,
        defaultMessage: "Fecha",
    },
    originName: {
        id: `${base}.originName`,
        defaultMessage: "Origen",
    },
    newPayment: {
        id: `${base}.newPayment`,
        defaultMessage: "Nuevo pago",
    },
    remaining: {
        id: `${base}.remaining`,
        defaultMessage: "Restante",
    },
    accumulated: {
        id: `${base}.accumulated`,
        defaultMessage: "Acumulado",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    createPayment: {
        id: `${base}.createPayment`,
        defaultMessage: "Crear pago",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    paymentSuccessfullyCreated: {
        id: `${base}.paymentSuccessfullyCreated`,
        defaultMessage: "Pago creado con éxito",
    },
    partialPaymentOrder: {
        id: `${base}.partialPaymentOrder`,
        defaultMessage: "Pago parcial a orden",
    },
    orderPayment: {
        id: `${base}.orderPayment`,
        defaultMessage: "Pago de orden",
    },
    partialPaymentPurchase: {
        id: `${base}.partialPaymentPurchase`,
        defaultMessage: "Pago parcial de compra",
    },
    purchasePayment : {
        id: `${base}.purchasePayment`,
        defaultMessage: "Pago de compra",
    },
    all: {
        id: `${base}.all`,
        defaultMessage: "Todos",
    },

});
