import { defineMessages } from "react-intl";
const base = "components.productSearchList";
export default defineMessages({
    moveTable: {
        id: `${base}.moveTable`,
        defaultMessage: "Mover",
    },
    deleteLounge: {
        id: `${base}.deleteLounge`,
        defaultMessage: "Eliminar Salon",
    },
    editLounge: {
        id: `${base}.editLounge`,
        defaultMessage: "Editar Salon",
    },
    tables: {
        id: `${base}.tables`,
        defaultMessage: "Mesas",
    },
    occupied: {
        id: `${base}.occupied`,
        defaultMessage: "Ocupadas",
    },
    roomCannotBeDeletedBecauseSomeTablesAreActive: {
        id: `${base}.roomCannotBeDeletedBecauseSomeTablesAreActive`,
        defaultMessage: "El salón no puede ser eliminado porque algunas mesas están activas",
    },
    updated: {
        id: `${base}.updated`,
        defaultMessage: "Actualizada",
    },
    by: {
        id: `${base}.by`,
        defaultMessage: "Por",
    },
    loungeSuccessfullyDeleted: {
        id: `${base}.roomDeletedSuccessfully`,
        defaultMessage: "Salón eliminado correctamente",
    },
    areYouSureYouWantToDeleteLounge: {
        id: `${base}.areYouSureYouWantToDeleteLounge`,
        defaultMessage: "¿Estás seguro de que quieres eliminar el salón?",
    },
    thisActionCannotBeReversed: {
        id: `${base}.thisActionCannotBeReversed`,
        defaultMessage: "Esta acción no se puede revertir",
    },
    user: {
        id: `${base}.user`,
        defaultMessage: "Usuario",
    },
    seller: {
        id: `${base}.seller`,
        defaultMessage: "Vendedor",
    },
    customer: {
        id: `${base}.customer`,
        defaultMessage: "Cliente",
    },
    takeout: {
        id: `${base}.takeout`,
        defaultMessage: "Para llevar",
    },
    waiting: {
        id: `${base}.waiting`,
        defaultMessage: "Pendiente",
    },
    inPreparation: {
        id: `${base}.inPreparation`,
        defaultMessage: "En preparación",
    },
    ready: {
        id: `${base}.ready`,
        defaultMessage: "Completado",
    },
});
