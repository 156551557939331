import React from "react";
import { useAlert } from "../../Alerts/Alert";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { sendConfirmationEmail } from "../../../api/account/accountUser";
import { AccountUser } from "../../../api/models/accountUser";
import { AlertLineContainer } from "../../../pages/AppContainer/styled";
import { useUserContext } from "../../../store/contexts/UserContext";
import LoadingDualRing from "../../LoadingDualRing";
import { DangerButtonOutline } from "../../_controls";
import { ConfirmEmailContainer } from "./styled";

interface ConfirmEmailProps {
    user: AccountUser;
}

export const useSendEmailConfirmation = () => {
    const alert = useAlert();
    const emailMutation = useMutation((id: string) => sendConfirmationEmail(id), {
        onSuccess: (data) => {
            alert.success("Se ha enviado un correo de verificación, por favor revisa tu email");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const sendEmailConfirmation = (id: string) => {
        emailMutation.mutate(id);
    };

    return { sendEmailConfirmation, isLoading: emailMutation.isLoading };
};

export const AlertEmailConfirmationLine = () => {
    const emailConfirmation = useSendEmailConfirmation();
    const { userState } = useUserContext();

    if (!userState.user) return null;

    return (
        <AlertLineContainer>
            <span>Es necesario confirmar tu email</span>{" "}
            <DangerButtonOutline
                rounded
                small
                disabled={emailConfirmation.isLoading}
                onClick={() => emailConfirmation.sendEmailConfirmation(userState.user?.id ?? "-")}
            >
                Confirmar Ahora {emailConfirmation.isLoading && <LoadingDualRing small />}
            </DangerButtonOutline>
        </AlertLineContainer>
    );
};

export default function ConfirmEmail(props: ConfirmEmailProps) {
    const emailConfirmation = useSendEmailConfirmation();

    return (
        <ConfirmEmailContainer>
            <h3 className="title">Verificación de email pendiente</h3>
            <p>Es necesario confirmar el email</p>

            <DangerButtonOutline
                className="mt-1"
                rounded
                disabled={emailConfirmation.isLoading}
                onClick={() => emailConfirmation.sendEmailConfirmation(props.user.id)}
            >
                Confirmar Ahora {emailConfirmation.isLoading && <LoadingDualRing small />}
            </DangerButtonOutline>
        </ConfirmEmailContainer>
    );
}
