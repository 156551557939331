import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import { SelectBaseOption } from "../../api/models";
import { IProductSort, ProductSortPair, productSortOptionsMultiple } from "../../api/models/product";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { UserContext } from "../../store/contexts/UserContext";
import { useAlert } from "../Alerts/Alert";
import { SelectBody } from "../Select/styled";
import TextBoxNumeral from "../TextBoxNumeral";
import WarehouseSelect from "../Warehouses/WarehouseSelect";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../_controls";
import { FormGroup } from "../_controls/forms/styled";
import CheckBox from "../_controls/inputs/CheckBox";
import Modal, { IModalShow } from "../modals/Modal";
import messages from "./messages";

interface DeviceSettingsModalProps extends IModalShow {
    origin?: string;
    defaultWarehouseId: string;
    showWarehouse?: boolean;
}

export default function DeviceSettingsModal(props: DeviceSettingsModalProps) {
    const alert = useAlert();
    const { userState } = useContext(UserContext);
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const clientSettings = userState.user?.account?.clientSettings;
    const [currentSettings, setCurrentSettings] = useState(appSettingsState);
    const intl = useIntl();

    const saveChanges = () => {
        appSettingsActions.setAllSettings(currentSettings);
        alert.success("Los cambios se guardaron correctamente");
        props.setShow(false);
    };
    const priceNameExists = !!(clientSettings && clientSettings.price);
    const priceName = priceNameExists ? "precio " + clientSettings?.price : "Precio 1";

    const getSelectOption = (item: IProductSort) => {
        let option: SelectBaseOption = {
            id: item.name,
            value: item.name,
            label: item.name,
        };
        return option;
    };

    const onChangeSort = (sortName?: string) => {
        let value = productSortOptionsMultiple.find((x) => x.name === sortName);
        if (!value) value = productSortOptionsMultiple[0];
        setCurrentSettings({ ...currentSettings, productSearchSort: value.sort, productSearchSortDesc: value.sortDesc });
    };

    const getDefaultValue = () => {
        let productSort: ProductSortPair = {
            sort: currentSettings.productSearchSort,
            sortDesc: currentSettings.productSearchSortDesc,
        };
        let value = productSortOptionsMultiple.find((x) => x.sort === productSort.sort && x.sortDesc === productSort.sortDesc);
        if (value) return getSelectOption(value);
        return getSelectOption(productSortOptionsMultiple[0]);
    };

    return (
        <Modal
            scrollInside={false}
            overflowAuto={false}
            borderRadius={20}
            {...props}
            ignoreOutsideClick
            title="Configuración de búsqueda del dispositivo"
            showHeader
        >
            <Flex column gap={20} padding={25}>
                {props.showWarehouse && (
                    <WarehouseSelect
                        onWarehouseChange={(value) => {
                            if (value?.id) {
                                setCurrentSettings({ ...currentSettings, defaultWarehouseId: value.id });
                            }
                        }}
                        defaultWarehouseId={appSettingsState.defaultWarehouseId}
                    />
                )}

                <SelectBody>
                    <span className="label">Ordenar búsqueda por:</span>
                    <Select
                        placeholder="Seleccionar"
                        className="react-select-basic"
                        classNamePrefix="select"
                        key={currentSettings.productSearchSort}
                        defaultValue={getDefaultValue()}
                        isDisabled={false}
                        onChange={(value) => onChangeSort(value?.id)}
                        isRtl={false}
                        isSearchable={true}
                        name="seller"
                        options={productSortOptionsMultiple.map((item) => getSelectOption(item))}
                    />
                </SelectBody>
                <CheckBox
                    id="onlystockproducto"
                    name="showsotckonlyproductos"
                    title="Mostrar solo productos con Stock"
                    checked={currentSettings.productSearchOnlyStockProducts}
                    onChange={(e) => setCurrentSettings({ ...currentSettings, productSearchOnlyStockProducts: e.target.checked })}
                />
                <CheckBox
                    id="showimageproduct"
                    name="showsotad234ckonlyproductos"
                    title="Mostrar solo productos con Imagen"
                    checked={currentSettings.productSearchOnlyProductWithImage}
                    onChange={(e) =>
                        setCurrentSettings({
                            ...currentSettings,
                            productSearchOnlyProductWithImage: e.target.checked,
                            productSearchOnlyProductWithoutImage: false,
                        })
                    }
                />
                <CheckBox
                    id="showimageproduct"
                    name="showsotad234ckonlyproductos"
                    title="Mostrar solo productos sin Imagen"
                    checked={currentSettings.productSearchOnlyProductWithoutImage}
                    onChange={(e) =>
                        setCurrentSettings({
                            ...currentSettings,
                            productSearchOnlyProductWithoutImage: e.target.checked,
                            productSearchOnlyProductWithImage: false,
                        })
                    }
                />

                <TextField light small>
                    El rango solo aplica para el {priceName}, $0 no limita la búsqueda
                </TextField>

                <Flex gap10 flexWrap>
                    <FormGroup>
                        <span className="label small">{intl.formatMessage(messages.minimalPrice)}</span>
                        <TextBoxNumeral
                            id="textminprice"
                            format="money"
                            placeholder="Precio"
                            autoComplete="off"
                            value={currentSettings.productSearchMinPrice}
                            onNumberChange={(value) => setCurrentSettings({ ...currentSettings, productSearchMinPrice: value })}
                        />
                    </FormGroup>

                    <FormGroup>
                        <span className="label small">{intl.formatMessage(messages.maximumPrice)}</span>
                        <TextBoxNumeral
                            id="textminprice"
                            format="money"
                            placeholder="Precio"
                            autoComplete="off"
                            value={currentSettings.productSearchMaxPrice}
                            onNumberChange={(value) => setCurrentSettings({ ...currentSettings, productSearchMaxPrice: value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <span className="label small">{intl.formatMessage(messages.maximumStock)}</span>
                        <TextBoxNumeral
                            id="stockMaxbox"
                            format="number"
                            placeholder="Stock"
                            autoComplete="off"
                            value={currentSettings.productSearchMaxStock}
                            onNumberChange={(value) => setCurrentSettings({ ...currentSettings, productSearchMaxStock: value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <span className="label small">{intl.formatMessage(messages.minimumStock)}</span>
                        <TextBoxNumeral
                            id="stockminimobox"
                            format="number"
                            placeholder="Stock"
                            autoComplete="off"
                            value={currentSettings.productSearchMinStock}
                            onNumberChange={(value) => setCurrentSettings({ ...currentSettings, productSearchMinStock: value })}
                        />
                    </FormGroup>
                </Flex>

                <CheckBox
                    id="checkshowaddproductmodal"
                    name="showmodaladdproduct"
                    title="Mostrar modal para agregar productos"
                    checked={currentSettings.productSearchShowAddModal}
                    onChange={(e) => setCurrentSettings({ ...currentSettings, productSearchShowAddModal: e.target.checked })}
                />
                {currentSettings.productSearchShowAddModal && (
                    <Flex gap10 alignCenter>
                        <FormGroup>
                            <span className="label">Delta de Cantidad</span>
                            <TextBoxNumeral
                                id="textboxdeltaquantity"
                                format="number"
                                placeholder="Delta cantidad"
                                autoComplete="off"
                                value={currentSettings.productSearchQuantityDelta}
                                onNumberChange={(value) => setCurrentSettings({ ...currentSettings, productSearchQuantityDelta: value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <span className="label">Delta de Precio</span>
                            <TextBoxNumeral
                                id="textboxdeltaquantity"
                                format="money"
                                placeholder="Delta Price"
                                value={currentSettings.productSearchPriceDelta}
                                onNumberChange={(value) => setCurrentSettings({ ...currentSettings, productSearchPriceDelta: value })}
                            />
                        </FormGroup>
                    </Flex>
                )}

                <Flex gap10 justifyEnd marginTop={15}>
                    <DefaultButton w100 bgLight borderRadius={10} onClick={() => props.setShow(false)}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton w100 borderRadius={10} onClick={() => saveChanges()}>
                        Guardar Cambios
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
}
