import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { METHOD_ICONS, WahioFile, WahioFileImpl } from "../../../api/models";
import { IOrderPayment, IPurchasePayment } from "../../../api/models/orderPayment";
import { IPurchase } from "../../../api/models/purchase";
import { WahioCloudItem } from "../../../api/models/wahioCloud";
import { purchaseEndpoint } from "../../../api/restApiEndpoint";
import { checkAndGetMessage } from "../../../i18n/helper";
import globalMessages from "../../../messages";
import { formatMoney, getDateFormat } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import { PaymentCardView } from "../../Orders/OrderProfile/styled";
import WahioCloudItemsModal from "../../WahioCloud/WahioCloudItemsModal";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";


interface PurchasePaymentsProps {
    purchase: IPurchase;
    onUpdateOrder: (value: IPurchase) => void;
}

export default function OrderPayments(props: PurchasePaymentsProps) {
    const { purchase } = props;
    const alert = useAlert();
    const [payments, setPayments] = useState(purchase.payments ?? []);
    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setPayments(purchase.payments ?? []);
    }, [purchase.payments]);

    const handleSaveChanges = () => {
        let purchaseToUpdate = { ...purchase, payments };
        wahioFetch.post(
            purchaseEndpoint.post.purchaseAll,
            { ...purchaseToUpdate },
            (success) => {
                setIsFetching(false);
                alert.success("Los cambios se guardaron correctamente");
                setShowSaveChanges(false);
                props.onUpdateOrder(success.data);
            },
            (error) => {
                setIsFetching(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const onPaymentChange = (value: IOrderPayment) => {
        let paymentsCopy: IOrderPayment[] = [];
        payments.forEach((element) => {
            if (element.id === value.id) {
                paymentsCopy.push(value);
            } else {
                paymentsCopy.push(element);
            }
        });
        setPayments(paymentsCopy);
        setShowSaveChanges(true);
    };

    const handleCancelChanges = () => {
        setPayments(purchase.payments ?? []);
        setShowSaveChanges(false);
    };

    return (
        <Flex column w100>
            <Flex column gap10>
                <Flex column gap15>
                    {payments.map((item, index) => (
                        <OrderPaymentItemOption
                            key={item.id ?? index}
                            payment={item}
                            onOrderChange={props.onUpdateOrder}
                            onPaymentChange={onPaymentChange}
                        />
                    ))}
                </Flex>
            </Flex>

            {isFetching && <LoadingDualRing />}
            {!isFetching && showSaveChanges && (
                <Flex gap10 marginTop={10} justifyEnd>
                    <DefaultButton onClick={handleCancelChanges} borderRadius={10}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton borderRadius={10} onClick={() => handleSaveChanges()}>
                        Guardar Cambios
                    </PrimaryButton>
                </Flex>
            )}
        </Flex>
    );
}

interface OrderPaymentItemOptionProps {
    payment: IPurchasePayment;
    onPaymentChange: (value: IPurchasePayment) => void;
    onOrderChange: (order: IPurchase) => void;
}

const OrderPaymentItemOption = (props: OrderPaymentItemOptionProps) => {
    const { payment } = props;
    const intl = useIntl();
    const [showWahioCloud, setShowWahioCloud] = useState(false);

    const onItemSelected = (items: WahioCloudItem[]) => {
        let newFiles: WahioFile[] = [];
        items.forEach((item) => {
            let file = new WahioFileImpl(item.url, item.type);
            file.name = item.name;
            newFiles.push(file);
        });
        let resultFiles = [...(payment.files ?? []), ...newFiles];
        props.onPaymentChange({ ...payment, files: resultFiles });
    };

    const onFilesChanges = (files: WahioFile[]) => {
        props.onPaymentChange({ ...payment, files });
    };

    const icon = (METHOD_ICONS as any)[payment.method];

    return (
        <PaymentCardView>
            <Flex w100 column gap15 flexWrap>
                {showWahioCloud && (
                    <WahioCloudItemsModal
                        show={showWahioCloud}
                        setShow={setShowWahioCloud}
                        onClickItems={onItemSelected}
                        allowSelectionMultiple
                        allowSelection
                    />
                )}
                <Flex alignCenter gap15>
                    <div className="status">
                        <span className={icon ? icon : "wahioicon-check"}></span>
                    </div>
                    <Flex column>
                        <Flex alignCenter gap10>
                            <h3 className="amount">{formatMoney(payment.amount)}</h3>
                            <TextField small light>
                                {" "}
                                {getDateFormat(payment.dateCreated, "DD MMM YYYY hh:mm:a")}
                            </TextField>
                        </Flex>
                        <TextField>{checkAndGetMessage(intl, globalMessages, payment.method)}</TextField>
                    </Flex>

                </Flex>
                {payment.files && payment.files.length > 0 && (
                    <FilesViewer cardMaxHeight={80} cardMaxWidth={80} smallCard files={payment.files} onUpdateFiles={onFilesChanges} />
                )}
            </Flex>
            <SquareButton removeBackground colorText onClick={() => setShowWahioCloud(true)}>
                <span className="wahioicon-paperclip"></span>
            </SquareButton>
        </PaymentCardView>
    );
};