import React from "react";
import { IProduct, IProductVariant } from "../../api/models/product";
import { getProductThumbnailUrl, getProductSkuAndCode } from "./helper";
import { ProductCardContainer } from "./styled";

interface ProductCardViewProps {
    product: IProduct;
    productVariant?: IProductVariant;
    showCardDesign?: boolean;
}

export default function ProductCardView(props: ProductCardViewProps) {
    const { product, productVariant } = props;

    return (
        <ProductCardContainer className={props.showCardDesign ? "card-design" : ""}>
            <div className="image">
                <img src={getProductThumbnailUrl(product, productVariant)} alt={product.name} />
            </div>
            <div className="product-body">
                <span>{product.name}</span>
                <span className="product-light">{getProductSkuAndCode(product, productVariant)}</span>
            </div>
        </ProductCardContainer>
    );
}
