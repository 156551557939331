import React from "react";
import { Switch } from "./styled";

export interface ToggleSwitchProps {
    name?: string;
    checked: boolean;
    onChecked: (value: boolean) => void;
}

export default function ToggleSwitch(props: ToggleSwitchProps) {
    return (
        <Switch>
            <input name={props.name} checked={props.checked} onChange={(e) => props.onChecked(e.target.checked)} type="checkbox" />
            <span className="slider round"></span>
        </Switch>
    );
}
