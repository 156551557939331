import styled from "styled-components";

export const FilesViewContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;

    .file-container {
        width: 100%;
        height: 100%;
        max-width: 1100px;
        max-height: 750px;
        display: grid;
    }

    .file-image {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`;
//export const FilesSliderContainer = styled
