import React from "react";
import { ProductCreationProps } from ".";
import { Flex } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";

export default function ProductStockControl(props: ProductCreationProps) {
    const { product, onChange: onChangeProduct } = props;
    return (
        <Flex column gap5>
            <CheckBox
                checked={product.controlInventory ?? false}
                title="Control de Stock"
                onChange={(e) => {
                    onChangeProduct({ ...product, controlInventory: !product.controlInventory });
                }}
            />
        </Flex>
    );
}
