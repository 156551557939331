import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { BillingConfigState, DefaultPlanOption } from ".";
import { AccountBilling, CurrencyType, PlanDiscountItem } from "../../../../api/models/accountBilling";
import { AccountPlanBase } from "../../../../api/models/accountUser";
import { formatMoney } from "../../../../utils";
import { Flex, PrimaryButton } from "../../../_controls";
import { BillingConfigData } from "../billingConfigDataModel";
import messages from "../messages";
import { AccountPlanCardContainer, AccountPlanHeader, ShowDetailsLabel } from "../styled";
import { getValueWidthDiscount } from "./helpers";

interface BillingPlanCardProps {
    plan: AccountPlanBase;
    billing: AccountBilling;
    configState: BillingConfigState;
    billingConfig: BillingConfigData;
    currency: CurrencyType;
    existingPlanOptions?: DefaultPlanOption;
    isMobile: boolean;
    onSelectedPlan: (plan: AccountPlanBase, price: number) => void;
}

export default function BillingPlanCard(props: BillingPlanCardProps) {
    const { plan, billing, configState, billingConfig, currency, existingPlanOptions, isMobile } = props;
    const intl = useIntl();

    const [discount, setDiscount] = useState<PlanDiscountItem>();
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        let discount = billing?.planDiscounts?.items?.find((x) => x.plan === plan && x.cycle === configState.billingCycle);
        setDiscount(discount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billing, configState]);

    const selectedPrice = billingConfig.prices[configState.billingCycle];
    const valueWithDiscount = getValueWidthDiscount(selectedPrice[plan][currency], discount?.discountPercentage ?? 0);

    return (
        <AccountPlanCardContainer>
            <Flex column gap15>
                <AccountPlanHeader className={`${plan} ${plan === configState.plan ? "active" : ""}`}>
                    <span className="title">{intl.formatMessage(messages[plan])}</span>

                    <Flex column w100>
                        {discount ? (
                            <Flex column className="mt-2">
                                <Flex gap5 alignEnd>
                                    <span className="text-light text-small price-line">{formatMoney(selectedPrice[plan][currency])}</span>
                                    <span className="discount-label">Ahorra {discount.discountPercentage} %</span>
                                </Flex>
                                <Flex gap5 alignEnd>
                                    <span className="price">{formatMoney(valueWithDiscount)}</span>
                                    <span className="text-light">{currency}</span>
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex gap5 alignEnd>
                                <span className="price">{formatMoney(selectedPrice[plan][currency])}</span>
                                <span className="text-light">{currency}</span>
                            </Flex>
                        )}

                        <span className="text-small">Por usuario/{intl.formatMessage(messages[configState.billingCycle])}</span>

                        <PrimaryButton
                            disabled={existingPlanOptions?.plan === plan && existingPlanOptions.cycle === configState.billingCycle}
                            className="btn-action"
                            rounded
                            onClick={() => {
                                props.onSelectedPlan(plan, valueWithDiscount);
                            }}
                        >
                            {existingPlanOptions?.plan && existingPlanOptions.cycle === configState.billingCycle &&
                                existingPlanOptions.plan === plan &&
                                existingPlanOptions.cycle === configState.billingCycle ? "Plan Actual" : "Elegir"}
                        </PrimaryButton>

                        {isMobile && (
                            <ShowDetailsLabel onClick={() => setShowDetails(!showDetails)}>
                                {!showDetails ? "Ocultar Detalles" : "Ver Detalles"}
                            </ShowDetailsLabel>
                        )}
                    </Flex>
                </AccountPlanHeader>
                {(!isMobile || showDetails) && !configState.hideDetails && (
                    <Flex column className="items mt-1">
                        {billingConfig.items.map((item, index) => {
                            if (!item[plan].status) return null;
                            let planItem = item[plan];
                            return (
                                <Flex gap15 className="item" key={index}>
                                    <span className="wahioicon-check-circle"></span>
                                    <span>{planItem.description ? planItem.description : item.value}</span>
                                </Flex>
                            );
                        })}
                    </Flex>
                )}
            </Flex>
        </AccountPlanCardContainer>
    );
}
