import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { OrderSearchDateRange } from "../../../api/models/order";
import { getOrderCountByDateRange } from "../../../api/orders/orderApi";
import { getAuthBody } from "../../../auth";
import PATHS from "../../../constants/paths";
import { useUserContext } from "../../../store/contexts/UserContext";
import { capitalize } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { PLAN_MONTHLY_LIMIT } from "../../Account/Billing/Constants";
import { dateDefaultOptionPairs } from "../../DatePicker/filesRange";
import { DefaultButton, Flex, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import { OrderLimitAlertContainer } from "./styled";

export default function OrderLimitAlert() {
    const { userState } = useUserContext();
    const [orderCount, setOrderCount] = useState(0);

    const user = userState.user;
    const account = user?.account;

    const orderCountMutation = useMutation((data: OrderSearchDateRange) => getOrderCountByDateRange(data), {
        onSuccess: (data) => {
            setOrderCount(data.count);
        },
    });

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = () => {
        const dateRange = dateDefaultOptionPairs.thisMonth;
        let data: OrderSearchDateRange = {
            accountId: getAuthBody().accountId,
            dateStart: dateRange.startDate,
            dateEnd: dateRange.endDate,
            limit: 0,
            minutesOffset: new Date().getTimezoneOffset(),
        };

        orderCountMutation.mutate(data);
    };

    const plan = account?.plan ?? "free";
    const orderLimit = PLAN_MONTHLY_LIMIT[plan].orders ?? 50000;

    if (orderCount < orderLimit) {
        return null;
    }

    return (
        <Modal show={true} setShow={() => {}}>
            <OrderLimitAlertContainer>
                <Flex alignCenter column gap10 className="alert-header">
                    <TextField fontSize={40} className="fa-regular fa-siren-on"></TextField>
                    <h2 className="text-center m-0 text-bold">Superaste el limite de ordenes de tu plan</h2>
                </Flex>

                <Flex column className="alert-content">
                    <Table tdPadding={5} tdPaddingRight={20}>
                        <tbody>
                            <tr>
                                <td className="min-width">
                                    <TextField bold>Plan {capitalize(plan)}</TextField>
                                </td>
                                <td>{PLAN_MONTHLY_LIMIT[plan].orders} Ordenes/Mes</td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField bold nowrap>
                                        Consumo en {customMoment().format("MMMM")}
                                    </TextField>
                                </td>
                                <td>{orderCount} Ordenes</td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField bold>Disponible</TextField>
                                </td>
                                <td>
                                    <TextField color="#F44336">{PLAN_MONTHLY_LIMIT[plan].orders - orderCount} Ordenes</TextField>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <TextField>
                        Para continuar con la creación de ordenes debes cambiar el plan de tu cuenta, o esperar a que inicie el proximo mes
                    </TextField>

                    <Flex alignCenter gap10>
                        <NavLink to={PATHS.orderSalesList}>
                            <DefaultButton bgLight w100 borderRadius={10}>
                                Ir al Inicio
                            </DefaultButton>
                        </NavLink>

                        <NavLink to={PATHS.accountBilling}>
                            <DefaultButton bgLight borderRadius={10} w100>
                                Cambiar Plan
                            </DefaultButton>
                        </NavLink>
                    </Flex>
                </Flex>
            </OrderLimitAlertContainer>
        </Modal>
    );
}
