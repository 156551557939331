import styled from "styled-components";

export const HoverCardContentStyle = styled.div`
    .HoverCardContent {
        border-radius: 16px;
        padding: 20px;
        width: 300px;
        background-color: ${(props) => props.theme.palette.background};
        box-shadow: ${(props) => props.theme.palette.boxShadowHsl};
        animation-duration: 400ms;
        animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        will-change: transform, opacity;
        box-sizing: border-box;
        z-index: 99;

    }
    .HoverCardContent[data-side="top"] {
        animation-name: slideDownAndFade;
    }
    .HoverCardContent[data-side="right"] {
        animation-name: slideLeftAndFade;
    }
    .HoverCardContent[data-side="bottom"] {
        animation-name: slideUpAndFade;
    }
    .HoverCardContent[data-side="left"] {
        animation-name: slideRightAndFade;
    }

    .HoverCardArrow {
        fill: ${(props) => props.theme.palette.background};
    }

    @keyframes slideUpAndFade {
        0% {
            opacity: 0;
            transform: translateY(2px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideRightAndFade {
        0% {
            opacity: 0;
            transform: translateX(-2px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes slideDownAndFade {
        0% {
            opacity: 0;
            transform: translateY(-2px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideLeftAndFade {
        0% {
            opacity: 0;
            transform: translateX(2px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;
