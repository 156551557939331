import wahioFetchApi from "../fetchApi";
import { MessageOptionBody, NotificationSummary, NotificationSummaryRequest, PaymentReceiptInput } from "../models/notification";
import { marketingEndpoint } from "../restApiEndpoint";

export const sendNotificationSms = (body: MessageOptionBody): Promise<{ response: string }> => {
    return wahioFetchApi.post(marketingEndpoint.post.sendSms, body).then((res: any) => res.data);
};

export const notificationSummaryByAccount = (body: NotificationSummaryRequest): Promise<NotificationSummary> => {
    return wahioFetchApi.post(marketingEndpoint.post.notificationSummaryByAccount, body).then((res: any) => res.data);
};

export const sendNotificationEmailPaymentReceipt = (body: PaymentReceiptInput): Promise<string> => {
    return wahioFetchApi.post(marketingEndpoint.post.sendReceiptPaymentEmail, body).then((res: any) => res.data);
};
