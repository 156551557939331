import React from "react";
import { DeliveryStatus, IDeliveryOrder } from "../../../api/models/deliveryOrder";
import { Flex, PrimaryButton, TextField } from "../../_controls";
import { useShipmentStatusList } from "./hook";
import { ShipmentStatusVerticalStyle, StatusVerticalCard } from "./styled";

interface ShipmentStatusVerticalProps {
    allowLogisticsService: boolean;
    deliveryOrder: IDeliveryOrder;
    onMoveStatus?: (status: DeliveryStatus) => void;
}

export const ShipmentStatusVertical = (props: ShipmentStatusVerticalProps) => {
    const { deliveryOrder } = props;
    const { getHistoryStatusList, getClosestVisibleStatus } = useShipmentStatusList();

    const currentStatusIndex = React.useMemo(() => {
        return getClosestVisibleStatus(deliveryOrder.status, props.allowLogisticsService);
    }, [deliveryOrder.status, getClosestVisibleStatus, props.allowLogisticsService]);

    const shipmentItems = React.useMemo(() => {
        return getHistoryStatusList(props.allowLogisticsService, deliveryOrder.history);
    }, [deliveryOrder.history, getHistoryStatusList, props.allowLogisticsService]);

    return (
        <ShipmentStatusVerticalStyle>
            {shipmentItems.map((item, index) => {
                const statusActive = index <= currentStatusIndex;

                let className = "";
                if (statusActive) {
                    className = "active";
                } else {
                    className = "inactive";
                }

                let iconLine = "";
                if (index < currentStatusIndex) {
                    iconLine = "show-line";
                }

                const onClickMove = () => {
                    if (props.onMoveStatus && !statusActive) props.onMoveStatus(item.status);
                };

                return (
                    <StatusVerticalCard key={index} className={className}>
                        <div className={`status-icon ${iconLine}`} onClick={onClickMove}>
                            <span className={item.icon}></span>
                        </div>
                        <Flex column>
                            <TextField className="status-title">{item.title}</TextField>
                            <TextField light small>
                                {item.subTitle}
                            </TextField>
                        </Flex>
                        <div className="left-content">
                            {!statusActive && props.onMoveStatus && (
                                <PrimaryButton onClick={onClickMove} className="btn-move" small borderRadius={10} bgLight>
                                    <span className="fa-regular fa-arrow-right"></span> Mover
                                </PrimaryButton>
                            )}
                        </div>
                    </StatusVerticalCard>
                );
            })}
        </ShipmentStatusVerticalStyle>
    );
};
