import { getAuthBody } from "../../auth";
import { IProductImportItem } from "../../components/ImportExport/ExcelImport/helper";
import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { IInventoryProductAdjustmentModel } from "../models/inventoryAdjustment";
import {
    IProduct,
    IProductSearchModel,
    ProductAiContent,
    ProductCheckItem,
    ProductCheckItemResponse,
    ProductEcommerceStatus,
    ProductEcommerceStatusInput,
    ProductListingItem,
    ProductListingSearchInput,
    ProductPartialUpdateBulkItem,
    ProductPartialUpdateData,
    ProductPartialUpdateInput,
    ProductSearchEngineInput,
    ProductSerialSearchInput,
    ProductSerialSearchResponse,
    WarehouseProductSummaryInput,
} from "../models/product";
import { IWarehouseMovement } from "../models/warehouseMovement";
import { IWarehouseProduct } from "../models/warehouseProduct";
import { productEndpoint } from "../restApiEndpoint";

export const createUpdateProduct = (product: IProduct): Promise<IProduct> => {
    return wahioFetchApi.post(productEndpoint.post.product, product).then((res: any) => res.data);
};

export const importProductLine = (product: IProductImportItem): Promise<IProduct> => {
    return wahioFetchApi.post(productEndpoint.post.productImportLine, product).then((res: any) => res.data);
};

export const updateProductEcommerceStatus = (data: ProductEcommerceStatusInput): Promise<ProductEcommerceStatus> => {
    return wahioFetchApi.put(productEndpoint.put.productEcommerceStatus(data), {}).then((res: any) => res.data);
};

export const deleteWarehouseMovement = (warehouseMovementId: string): Promise<IWarehouseMovement> => {
    return wahioFetchApi.delete(productEndpoint.delete.warehouseMovement(warehouseMovementId)).then((res: any) => res.data);
};

export const getParentProducts = (productId: string): Promise<IProduct[]> => {
    return wahioFetchApi.get(productEndpoint.get.getParentProducts(productId)).then((res: any) => res.data);
};

export const getProductSummary = (input: WarehouseProductSummaryInput): Promise<IWarehouseProduct> => {
    return wahioFetchApi.post(productEndpoint.post.warehouseProductSummary, input).then((res: any) => res.data);
};
export const getProductById = (id: string): Promise<IProduct> => {
    return wahioFetchApi.get(productEndpoint.get.product(id)).then((res: any) => res.data);
};

export const getWarehouseProductCheckParentStock = (
    input: WarehouseProductSummaryInput
): Promise<{ stock: number; controlInventory: boolean }> => {
    return wahioFetchApi
        .get(productEndpoint.get.warehouseProductCheckParentStock(input.productId, input.warehouseId))
        .then((res: any) => res.data);
};

export const getProductsCheckStock = (checkList: ProductCheckItem[]): Promise<ProductCheckItemResponse[]> => {
    return wahioFetchApi.post(productEndpoint.post.warehouseProductCheckStockSearchQuery, checkList).then((res: any) => res.data);
};

export const getProductsBySerialCode = (data: ProductSerialSearchInput): Promise<ProductSerialSearchResponse> => {
    return wahioFetchApi.post(productEndpoint.post.productSearchBySerial, data).then((res: any) => res.data);
};

export const productQuerySearch = (data: IProductSearchModel): Promise<IPaginationResponse<IProduct>> => {
    return wahioFetchApi.post(productEndpoint.post.productSearchQuery, data).then((res: any) => res.data);
};

export const productPartialUpdate = (body: ProductPartialUpdateData): Promise<ProductPartialUpdateInput> => {
    var url = body.isVariant
        ? productEndpoint.patch.productVariantPartialUpdate(body.id)
        : productEndpoint.patch.productPartialUpdate(body.id);

    return wahioFetchApi.patch(url, body.data).then((res: any) => res.data);
};

export const productPartialUpdateMultiple = (body: ProductPartialUpdateBulkItem[]): Promise<ProductPartialUpdateBulkItem[]> => {
    return wahioFetchApi
        .patch(productEndpoint.patch.productPartialUpdateMultiple(getAuthBody().accountId), body)
        .then((res: any) => res.data);
};

export const productListingBasic = (data: ProductListingSearchInput): Promise<IPaginationResponse<ProductListingItem>> => {
    return wahioFetchApi.post(productEndpoint.post.productListingBasic, data).then((res: any) => res.data);
};

export const getProductAiContent = (productId: string): Promise<ProductAiContent> => {
    return wahioFetchApi.get(productEndpoint.get.productIdContent(productId)).then((res: any) => res.data);
};

export const putProductAiContent = (data: ProductAiContent): Promise<ProductAiContent> => {
    return wahioFetchApi.put(productEndpoint.put.productAiContent, data).then((res: any) => res.data);
};

export const putProductSearchEngine = (data: ProductSearchEngineInput): Promise<ProductSearchEngineInput> => {
    return wahioFetchApi.put(productEndpoint.put.productSearchEngine, data).then((res: any) => res.data);
};

export const stockProductAdjustment = (data: IInventoryProductAdjustmentModel): Promise<IWarehouseProduct> => {
    return wahioFetchApi.post(productEndpoint.post.inventoryProductAdjustment, data).then((res: any) => res.data);
};
