import React, { useState } from "react";
import DefaultJoyride from "./DefaultJoyride";
import { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { Flex, TextField } from "../_controls";

export default function QuickStartShowHelpCenterRecovery() {
    const [tourState, setTourState] = useState({
        run: true,
        stepIndex: 0,
    });

    const [steps] = useState<Step[]>(() => {
        const stepList: Step[] = [
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField>Para abrir nuevamente el centro de ayuda, selecciona tu icono de Ayuda (?)</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                locale: { next: "Siguiente", last: "Entendido" },
                target: ".MENU_BTN_HELP",
            },
        ];
        return stepList;
    });

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, action, index } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setTourState({ ...tourState, run: false });
        }

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
            setTourState({ ...tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        }
    };

    return (
        <>
            <DefaultJoyride
                callback={handleJoyrideCallback}
                continuous={true}
                run={tourState.run}
                stepIndex={tourState.stepIndex}
                scrollToFirstStep={true}
                locale={{ next: "Siguiente", back: "Atrás" }}
                showProgress={true}
                disableOverlay
                showSkipButton={true}
                steps={steps}
                defaultBackground={"#7C4DFF"}
                small
            />
        </>
    );
}
