import React, { useEffect, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useMutation } from "react-query";
import Select from "react-select";
import { getServiceMessageError } from "../../../api";
import { updateEBillingDefaultResolutionNextNumber } from "../../../api/eBillings/eBillingApi";
import { SelectOptionObject } from "../../../api/models";
import { AccountCompanyResolutionNextNumberInput, EBillingAccountCompanyResolution } from "../../../api/models/electronicBilling";
import { useEBillingContext } from "../../../store/contexts/EBillingContext";
import LoadingDualRing from "../../LoadingDualRing";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import { ResolutionCardStyled } from "./styled";

export type ResolutionDocumentType = "order" | "orderReturn" | "documentSupport";

const OrderDocumentType = {
    order: 1,
    orderReturn: 5,
    documentSupport: 12,
};

interface ResolutionSelectorProps {
    type: ResolutionDocumentType;
    selectedCompanyResolutionId?: string;
    onSelectedCompanyResolution: (value: EBillingAccountCompanyResolution) => void;
    title: string;
    disabled?: boolean;
    showNextNumber?: boolean;
}

export default function EBillingResolutionSelector(props: ResolutionSelectorProps) {
    const [selectedValue, setSelectedValue] = useState<EBillingAccountCompanyResolution>();
    const [items, setItems] = useState<EBillingAccountCompanyResolution[]>([]);
    const alert = useAlert();
    const [newNumberValue, setNewNumberValue] = useState(0);
    const [editNextNumber, setEditNextNumber] = useState(false);

    const { eBillingState, loadResolutions } = useEBillingContext();

    useEffect(() => {
        loadResolutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nextNumberMutation = useMutation(
        (data: AccountCompanyResolutionNextNumberInput) => updateEBillingDefaultResolutionNextNumber(data),
        {
            onSuccess: (data) => {
                setEditNextNumber(false);
                alert.success("Resolución actualizada con éxito");
            },
            onError: (err) => {
                setEditNextNumber(false);
                alert.error(getServiceMessageError(err));
            },
        }
    );

    useEffect(() => {
        let filterResult = eBillingState.resolutions.filter((x) => x.resolution?.type_document_id === OrderDocumentType[props.type]);
        setItems(filterResult);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eBillingState.resolutions, props.type]);

    useEffect(() => {
        setNewNumberValue(selectedValue?.nextNumber ?? 0);
    }, [selectedValue]);

    useEffect(() => {
        if (props.selectedCompanyResolutionId) {
            var selected = items.find((x) => x.id === props.selectedCompanyResolutionId);
            setSelectedValue(selected);
        } else {
            setSelectedValue(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.selectedCompanyResolutionId]);

    const getOption = (item: EBillingAccountCompanyResolution) => {
        let option: SelectOptionObject<EBillingAccountCompanyResolution> = {
            value: item.id,
            label: `Resolución: ${item.resolution?.resolution ?? "-"} Prefijo: ${item.resolution?.prefix ?? "-"}`,
            object: item,
        };
        return option;
    };

    const onEditNextNumber = (id: string, value: number) => {
        nextNumberMutation.mutate({ id, nextNumber: value });
    };

    const onNumerationChange = (value?: EBillingAccountCompanyResolution) => {
        if (value) {
            setSelectedValue(value);
            props.onSelectedCompanyResolution(value);
        }
    };

    return (
        <ResolutionCardStyled column gap={20}>
            <SelectBody>
                {props.title && <span className="label">{props.title}</span>}
                <Select
                    placeholder="Resolucion"
                    className={`react-select-basic ${selectedValue ? "item-active" : ""}`}
                    classNamePrefix="select"
                    key={selectedValue?.id}
                    defaultValue={selectedValue ? getOption(selectedValue) : undefined}
                    isDisabled={props.disabled}
                    onChange={(value) => {
                        onNumerationChange(value?.object);
                    }}
                    isRtl={false}
                    isSearchable={true}
                    name="warehouse"
                    isLoading={eBillingState.resolutionsIsLoading}
                    options={items.map((item) => getOption(item))}
                />
            </SelectBody>
            {props.showNextNumber && (
                <>
                    {selectedValue && (
                        <Flex column gap5>
                            <TextField small light>
                                Proximo numero
                            </TextField>
                            <Flex gap10>
                                <TextBoxNumeral
                                    disabled={!editNextNumber}
                                    w100
                                    rounded
                                    value={newNumberValue}
                                    onNumberChange={(value) => {
                                        setNewNumberValue(value);
                                    }}
                                    format={"number"}
                                    className={editNextNumber ? "active" : ""}
                                />
                                <SquareButton removeBackground onClick={() => setEditNextNumber(!editNextNumber)}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </SquareButton>
                            </Flex>
                        </Flex>
                    )}
                    {editNextNumber && (
                        <Flex gap10 alignCenter>
                            <DefaultButton
                                disabled={nextNumberMutation.isLoading}
                                borderRadius={10}
                                onClick={() => {
                                    setNewNumberValue(selectedValue?.nextNumber ?? 0);
                                    setEditNextNumber(false);
                                }}
                            >
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton
                                disabled={nextNumberMutation.isLoading}
                                borderRadius={10}
                                onClick={() => {
                                    onEditNextNumber(selectedValue?.id ?? "", newNumberValue);
                                }}
                            >
                                Guardar Cambios {nextNumberMutation.isLoading && <LoadingDualRing small />}
                            </PrimaryButton>
                        </Flex>
                    )}
                </>
            )}
        </ResolutionCardStyled>
    );
}
