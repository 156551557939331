import translate from "../../../../i18n/translate";
import messages from "../messages";

interface IButtonFinalizer {
    classname: string;
    title: any;
}

export const getButtonTitleOption = (totalAmount: number, totalPay: number): IButtonFinalizer => {
    if (totalAmount === totalPay)
        return {
            classname: "btn-primary",
            title: translate(messages.finishPaid),
        };
    else if (totalAmount > totalPay && totalPay > 0) {
        return {
            classname: "btn-warning",
            title: translate(messages.finishPartial),
        };
    } else {
        return {
            classname: "btn-danger",
            title: translate(messages.finishCredit),
        };
    }
};
