import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "./filesRange";
import spanish from "date-fns/locale/es";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangeContainerView } from "./styled";
import { DefaultButton, Flex, PrimaryButton } from "../_controls";

export interface DateRangeSelection {
    startDate: Date;
    endDate: Date;
}

export interface IRangeSelectionTitle extends DateRangeSelection {
    title: string;
}

export interface DateRangePickerProps {
    rangeSelection: DateRangeSelection;
    onChange: (range: DateRangeSelection) => void;
    onClose: () => void;
}

export default function CustomDateRangePicker(props: DateRangePickerProps) {
    const [rangeSelection, setRangeSelection] = useState<DateRangeSelection>(props.rangeSelection);

    const onChange = (range: any) => {
        if (range.selection.startDate && range.selection.endDate) {
            setRangeSelection({ startDate: range.selection.startDate, endDate: range.selection.endDate });
        }
    };
    return (
        <Flex column paddingBottom={20} gap10>
            <DateRangeContainerView>
                <DateRangePicker
                    locale={spanish}
                    staticRanges={defaultStaticRanges}
                    ranges={[
                        {
                            startDate: rangeSelection.startDate,
                            endDate: rangeSelection.endDate,
                            key: "selection",
                        },
                    ]}
                    onChange={onChange}
                />
            </DateRangeContainerView>
            <Flex justifyEnd gap10 paddingRight={20}>
                <DefaultButton rounded onClick={() => props.onClose()}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton rounded onClick={() => props.onChange(rangeSelection)}>
                    Aplicar Cambios
                </PrimaryButton>
            </Flex>
        </Flex>
    );
}
