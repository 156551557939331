import React, { Fragment, useContext, useEffect, useRef } from "react";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { getUniqueId } from "../../utils";
import { Flex, FlexImageStyle, SquareButton, TextField } from "../_controls";
import { FlexImageIcon } from "../_controls/containers/FlexImageLetter";
import { AlertItem, useAlertContext } from "./AlertContext";
import { AlertCardStyle, AlertContainerCard, AlertNotificationCardStyle, AlertNotificationContainerCard } from "./styled";

export interface NotificationAlertBody {
    image?: string;
    icon?: string;
    title: string;
    description: string;
    openLink?: string;
    color?: string;
}

export const useAlert = () => {
    const { alertState, setAlertState } = useAlertContext();
    const alertRef = useRef(alertState);

    useEffect(() => {
        alertRef.current = alertState;
    }, [alertState]);

    const onRemoveItem = (id: string) => {
        setAlertState({ ...alertRef.current, items: alertRef.current.items.filter((x) => x.id !== id) });
    };
    const onRemoveNotiItem = (id: string) => {
        setAlertState({ ...alertRef.current, notifications: alertRef.current.notifications.filter((x) => x.id !== id) });
    };

    const success = (value: string) => {
        let id = getUniqueId();
        let item: AlertItem = {
            id,
            description: value,
            content: <AlertCard type="success" value={value} icon="fa-regular fa-check" onRemove={() => onRemoveItem(id)} />,
        };
        handleAlert(item);
    };

    const error = (value: string) => {
        let id = getUniqueId();
        let item: AlertItem = {
            id,
            description: value,
            content: <AlertCard type="error" value={value} icon="fa-regular fa-triangle-exclamation" onRemove={() => onRemoveItem(id)} />,
        };
        handleAlert(item);
    };

    const info = (value: string) => {
        let id = getUniqueId();
        let item: AlertItem = {
            id,
            description: value,
            content: <AlertCard type="info" value={value} icon="fa-sharp fa-regular fa-info" onRemove={() => onRemoveItem(id)} />,
        };
        handleAlert(item);
    };

    const show = (content: React.ReactNode, options?: { timeout?: number }) => {
        let id = getUniqueId();
        let item: AlertItem = {
            id,
            description: "",
            content: content,
        };
        handleAlertNotification(item);
    };

    const notification = (body: NotificationAlertBody) => {
        let id = getUniqueId();
        let item: AlertItem = {
            id,
            description: body.description,
            content: <AlertNotificationCard body={body} onRemove={() => onRemoveNotiItem(id)} />,
        };
        handleAlertNotification(item);
    };

    const handleAlertNotification = (item: AlertItem) => {
        setAlertState({ ...alertRef.current, notifications: [...alertRef.current.notifications, item] });
        setTimeout(() => {
            setAlertState({ ...alertRef.current, notifications: alertRef.current.notifications.filter((x) => x.id !== item.id) });
        }, 7000);
    };

    const handleAlert = (item: AlertItem) => {
        setAlertState({ ...alertState, items: [...alertState.items, item] });
        setTimeout(() => {
            setAlertState({ ...alertRef.current, items: alertRef.current.items.filter((x) => x.id !== item.id) });
        }, 3000);
    };

    return { success, error, info, notification, show };
};

interface AlertCardProps {
    value: string;
    icon: string;
    type: string;
    onRemove: () => void;
}

const AlertCard = (props: AlertCardProps) => {
    const { value, icon } = props;
    return (
        <AlertCardStyle className={props.type}>
            <i className={`${icon} icon`}></i> <span>{value}</span>
            <SquareButton className="btn-remove" onClick={() => props.onRemove()}>
                <i className="fa-regular fa-xmark"></i>
            </SquareButton>
        </AlertCardStyle>
    );
};

interface AlertNotificationCardProps {
    body: NotificationAlertBody;
    onRemove: () => void;
}

const AlertNotificationCard = (props: AlertNotificationCardProps) => {
    const { body } = props;
    const { image, title, description, icon } = body;

    const goToLink = () => {
        if (props.body.openLink) {
            window.open(props.body.openLink, "_blank");
        }
    };

    return (
        <AlertNotificationCardStyle>
            <Flex gap10 alignCenter className={body.openLink ? "with-link" : ""} onClick={goToLink}>
                {image && (
                    <FlexImageStyle>
                        <img src={image} alt="" />
                    </FlexImageStyle>
                )}
                {icon && <FlexImageIcon fontSize={18} width={40} color={body.color} icon={icon} />}
                <Flex column>
                    <TextField bold>{title}</TextField>
                    <TextField light>{description}</TextField>
                </Flex>
            </Flex>
            <SquareButton className="btn-remove" onClick={() => props.onRemove()}>
                <i className="fa-regular fa-xmark"></i>
            </SquareButton>
        </AlertNotificationCardStyle>
    );
};

export const AlertContainer = () => {
    const { alertState, setAlertState } = useAlertContext();
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    return (
        <>
            {alertState.items.length > 0 && (
                <AlertContainerCard>
                    {alertState.items.map((x, i) => {
                        return <Fragment key={x.id}>{x.content}</Fragment>;
                    })}
                </AlertContainerCard>
            )}
            {alertState.notifications.length > 0 && (
                <AlertNotificationContainerCard>
                    {alertState.notifications.map((x, i) => {
                        return <Fragment key={x.id}>{x.content}</Fragment>;
                    })}
                    <Flex gap5 marginRight={10}>
                        <SquareButton
                            className="clear-btn"
                            rounded
                            onClick={() =>
                                appSettingsActions.setAllSettings({
                                    ...appSettingsState,
                                    turnOfNotifications: !appSettingsState.turnOfNotifications,
                                })
                            }
                        >
                            <TextField
                                className={
                                    !appSettingsState.turnOfNotifications
                                        ? "fa-regular fa-volume-high text-primary"
                                        : "fa-regular fa-volume-slash"
                                }
                            />
                        </SquareButton>
                        {alertState.notifications.length > 1 && (
                            <SquareButton
                                onClick={() => {
                                    setAlertState({ ...alertState, notifications: [] });
                                }}
                                className="clear-btn"
                            >
                                <TextField className="fa-regular fa-xmark" />
                            </SquareButton>
                        )}
                    </Flex>
                </AlertNotificationContainerCard>
            )}
        </>
    );
};
