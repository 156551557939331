import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../api";
import { updateClientSettings } from "../../api/account/clientSettingsApi";
import {
    AccountClientSettings,
    AccountClientSettingsImpl,
    COMMERCE_TYPE_LIST,
    ClientSettingColor,
    CommerceType,
} from "../../api/models/accountUser";
import { getAuthBody } from "../../auth";
import { useUserContext } from "../../store/contexts/UserContext";
import { loadDefaultNumeralSymbol } from "../../utils/numberFormat/moneyFormat";
import { PriceSettingsPanelButton } from "../Account/ClientSettings/PriceSettingsPanel";
import { useAlert } from "../Alerts/Alert";
import { CountryCurrencySelector, CountrySelector } from "../GeoLocation/CountrySelector";
import LoadingDualRing from "../LoadingDualRing";
import NavigationCard from "../NavigationPanel/NavigationCard";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, SquareButton, TextField } from "../_controls";
import GroupButtonRow from "../_controls/buttons/GroupButtonRow";
import messages from "./messages";
import { FlexAddItem, FlexInputAddContainer } from "./styled";

export const RESTAURANT_ICONS = {
    restaurant: "fa-regular fa-utensils",
    bar: "fa-regular fa-cocktail",
    kiosk: "fa-regular fa-store",
    cart: "fa-regular fa-shopping-cart",
};

export default function AccountSettings() {
    const { userState, setUserState } = useUserContext();
    const account = userState.user?.account;
    const alert = useAlert();
    const intl = useIntl();

    const [clientSettings, setClientSettings] = useState(account?.clientSettings ?? new AccountClientSettingsImpl(getAuthBody().accountId));

    const [clientSettingsHasChanges, setClientSettingsHasChanges] = useState(false);

    const updateClientSettingsMutation = useMutation((data: AccountClientSettings) => updateClientSettings(data), {
        onSuccess: (data) => {
            alert.success("Configuración actualizada con éxito");
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const mutateIsLoading = updateClientSettingsMutation.isLoading;

    const onUpdate = (settings: AccountClientSettings) => {
        let accountUser = userState.user;
        if (accountUser) {
            let account = accountUser.account;
            if (account) {
                account.clientSettings = settings;
                accountUser.account = account;
                setUserState({ ...userState, user: accountUser });
            }
            updateClientSettingsMutation.mutate(settings);
        }
    };

    useEffect(() => {
        const colors: ClientSettingColor[] = clientSettings.defaultColorsAsString ? JSON.parse(clientSettings.defaultColorsAsString) : [];
        setClientSettings({ ...clientSettings, defaultColors: colors });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClientSettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setClientSettings({ ...clientSettings, [e.target.name]: e.target.value });
        setClientSettingsHasChanges(true);
    };

    const handleUpdateClientSettings = () => {
        onUpdate(clientSettings);
        setClientSettingsHasChanges(false);
    };

    const onAddColor = () => {
        let colors = clientSettings.defaultColors ?? [];
        colors.push({ name: `Color${colors.length + 1}`, value: "#ff0001" });
        setClientSettings({ ...clientSettings, defaultColors: colors, defaultColorsAsString: JSON.stringify(colors) });
        setClientSettingsHasChanges(true);
    };

    const onRemoveColor = (index: number) => {
        let colors = clientSettings.defaultColors?.filter((x, i) => i !== index);
        setClientSettings({ ...clientSettings, defaultColors: colors, defaultColorsAsString: JSON.stringify(colors) });
        setClientSettingsHasChanges(true);
    };

    const onColorChange = (item: ClientSettingColor, index: number) => {
        let colors = clientSettings.defaultColors ?? [];
        colors[index] = item;
        setClientSettings({ ...clientSettings, defaultColors: colors, defaultColorsAsString: JSON.stringify(colors) });
        setClientSettingsHasChanges(true);
    };

    const commerceTypeChange = (value: CommerceType) => {
        setClientSettings((prevSettings) => ({ ...prevSettings, commerceType: value }));
        setClientSettingsHasChanges(true);
    };

    return (
        <NavigationCard
            title="Configuración de cuenta"
            actionsComponent={
                <Flex alignCenter gap10 justifyCenter>
                    {mutateIsLoading ? (
                        <LoadingDualRing center />
                    ) : (
                        <PrimaryButton disabled={!clientSettingsHasChanges} borderRadius={10} onClick={() => handleUpdateClientSettings()}>
                            Guardar Cambios
                        </PrimaryButton>
                    )}
                </Flex>
            }
        >
            <Flex column gap={30}>
                <Flex gap15>
                    <CountrySelector
                        countryCode={clientSettings.countryCode}
                        onChange={(value) => {
                            setClientSettings({
                                ...clientSettings,
                                countryCode: value.iso2,
                                currency: value.currency,
                                currencySymbol: value.currency_symbol,
                            });
                            setClientSettingsHasChanges(true);
                        }}
                    />
                    <CountryCurrencySelector
                        currency={clientSettings.currency}
                        onChange={(value) => {
                            setClientSettings({ ...clientSettings, currency: value.currency, currencySymbol: value.currency_symbol });
                            loadDefaultNumeralSymbol(value.currency_symbol);
                            setClientSettingsHasChanges(true);
                        }}
                    />
                </Flex>

                <Flex column gap10>
                    <span className="text-small text-bold">Colores predeterminados</span>

                    <Flex gap15 flexWrap>
                        {clientSettings.defaultColors?.map((item, index) => (
                            <FlexInputAddContainer key={index} alignCenter>
                                <FlexAddItem>
                                    <div className="item-container">
                                        <input
                                            className="color-value"
                                            onChange={(e) => onColorChange({ ...item, value: e.target.value }, index)}
                                            type="color"
                                            id="head"
                                            name="head"
                                            value={item.value}
                                        />
                                    </div>
                                    <DefaultInput
                                        className="item-name"
                                        placeholder="Nombre"
                                        value={item.name}
                                        onChange={(e) => onColorChange({ ...item, name: e.target.value }, index)}
                                    />
                                </FlexAddItem>
                                <SquareButton onClick={() => onRemoveColor(index)} className="content-move" small>
                                    <span className="wahioicon-trash"></span>
                                </SquareButton>
                            </FlexInputAddContainer>
                        ))}
                        <DefaultButton rounded bgLight onClick={() => onAddColor()}>
                            Agregar Color
                        </DefaultButton>
                    </Flex>
                </Flex>
                <Flex column gap={30}>
                    <Flex column gap5>
                        <TextField>Lista de Precios</TextField>
                        <PriceSettingsPanelButton />
                    </Flex>

                    <Flex column gap10>
                        <span className="text-bold">Plazo en días para el pago</span>
                        <Flex fitContent>
                            <DefaultInput
                                rounded
                                placeholder="Plazo en días para el pago"
                                name={"defaultPaymentTermsDays"}
                                value={clientSettings.defaultPaymentTermsDays}
                                onChange={handleClientSettingsChange}
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <Flex column gap5>
                    <TextField small light>
                        Mostrar Restaurante
                    </TextField>
                    <GroupButtonRow
                        activeOption={clientSettings.enabledRestaurant}
                        onClickOption={(option) => {
                            if (option) {
                                setClientSettings({ ...clientSettings, enabledRestaurant: option.value });
                                setClientSettingsHasChanges(true);
                            }
                        }}
                        options={[
                            { title: "Ocultar", value: false, icon: "fa-regular fa-lock" },
                            { title: "Mostrar", value: true, icon: "fa-regular fa-lock-open" },
                        ]}
                    />
                </Flex>

                <Flex column gap5>
                    <TextField small light>
                        Tipo de Comercio
                    </TextField>
                    <GroupButtonRow
                        activeOption={clientSettings.commerceType}
                        onClickOption={(option) => {
                            if (option) {
                                commerceTypeChange(option.value);
                            }
                        }}
                        options={COMMERCE_TYPE_LIST.map((commerceType) => ({
                            title: intl.formatMessage(messages[commerceType]),
                            value: commerceType,
                            icon: RESTAURANT_ICONS[commerceType],
                        }))}
                    />
                </Flex>
            </Flex>
        </NavigationCard>
    );
}
