import { EntryCategoryBase } from "../../../api/models/cashRegister";

export const INTERNAL_CATEGORIES: EntryCategoryBase[] = [
    { name: "sale", label: "Venta", icon: "fa-regular fa-bag-shopping" },
    { name: "purchase", label: "Compra", icon: "fa-regular fa-cart-plus" },
    { name: "transfer", label: "Transferencia", icon: "fa-regular fa-exchange-alt" },
];

export const DEFAULT_CATEGORIES: EntryCategoryBase[] = [
    { name: "Servicios", icon: "fa-regular fa-lightbulb" },
    { name: "Arriendo", icon: "fa-regular fa-home" },
    { name: "Negocio", icon: "fa-regular fa-briefcase" },
    { name: "Alimentación", icon: "fa-regular fa-utensils" },
    { name: "Transporte", icon: "fa-regular fa-bus" },
    { name: "Seguros", icon: "fa-regular fa-shield-alt" },
    { name: "Comunicación", icon: "fa-regular fa-phone" },
    { name: "Educación", icon: "fa-regular fa-book" },
    { name: "Salud", icon: "fa-regular fa-heartbeat" },
    { name: "Vestuario", icon: "fa-regular fa-tshirt" },
    { name: "Entretenimiento", icon: "fa-regular fa-film" },
    { name: "Vehículo", icon: "fa-regular fa-car" },
    { name: "Hogar", icon: "fa-regular fa-couch" },
    { name: "Gimnasio", icon: "fa-regular fa-dumbbell" },
    { name: "Mascotas", icon: "fa-regular fa-paw" },
    { name: "Viajes", icon: "fa-regular fa-plane" },
    { name: "Ahorros", icon: "fa-regular fa-piggy-bank" },
    { name: "Personal", icon: "fa-regular fa-user-circle" },
    { name: "Regalos", icon: "fa-regular fa-gift" },
    { name: "Ajuste", icon: "fa-regular fa-balance-scale" },
];

export const ALL_ICONS = [
    "fa-regular fa-address-book",
    "fa-regular fa-address-card",
    "fa-regular fa-adjust",
    "fa-regular fa-alarm-clock",
    "fa-regular fa-align-center",
    "fa-regular fa-align-justify",
    "fa-regular fa-align-left",
    "fa-regular fa-align-right",
    "fa-regular fa-allergies",
    "fa-regular fa-ambulance",
    "fa-regular fa-american-sign-language-interpreting",
    "fa-regular fa-anchor",
    "fa-regular fa-angle-double-down",
    "fa-regular fa-angle-double-left",
    "fa-regular fa-angle-double-right",
    "fa-regular fa-angle-double-up",
    "fa-regular fa-angle-down",
    "fa-regular fa-angle-left",
    "fa-regular fa-angle-right",
    "fa-regular fa-angle-up",
    "fa-regular fa-archive",
    "fa-regular fa-arrow-alt-circle-down",
    "fa-regular fa-arrow-alt-circle-left",
    "fa-regular fa-arrow-alt-circle-right",
    "fa-regular fa-arrow-alt-circle-up",
    "fa-regular fa-arrow-alt-down",
    "fa-regular fa-arrow-alt-from-bottom",
    "fa-regular fa-arrow-alt-from-left",
    "fa-regular fa-arrow-alt-from-right",
    "fa-regular fa-arrow-alt-from-top",
    "fa-regular fa-arrow-alt-left",
    "fa-regular fa-arrow-alt-right",
    "fa-regular fa-arrow-alt-square-down",
    "fa-regular fa-arrow-alt-square-left",
    "fa-regular fa-arrow-alt-square-right",
    "fa-regular fa-arrow-alt-square-up",
    "fa-regular fa-arrow-alt-to-bottom",
    "fa-regular fa-arrow-alt-to-left",
    "fa-regular fa-arrow-alt-to-right",
    "fa-regular fa-arrow-alt-to-top",
    "fa-regular fa-arrow-alt-up",
    "fa-regular fa-arrow-circle-down",
    "fa-regular fa-arrow-circle-left",
    "fa-regular fa-arrow-circle-right",
    "fa-regular fa-arrow-circle-up",
    "fa-regular fa-arrow-down",
    "fa-regular fa-arrow-from-bottom",
    "fa-regular fa-arrow-from-left",
    "fa-regular fa-arrow-from-right",
    "fa-regular fa-arrow-from-top",
    "fa-regular fa-arrow-left",
    "fa-regular fa-arrow-right",
    "fa-regular fa-arrow-square-down",
    "fa-regular fa-arrow-square-left",
    "fa-regular fa-arrow-square-right",
    "fa-regular fa-arrow-square-up",
    "fa-regular fa-arrow-to-bottom",
    "fa-regular fa-arrow-to-left",
    "fa-regular fa-arrow-to-right",
    "fa-regular fa-arrow-to-top",
    "fa-regular fa-arrow-up",
    "fa-regular fa-arrows",
    "fa-regular fa-arrows-alt",
    "fa-regular fa-arrows-alt-h",
    "fa-regular fa-arrows-alt-v",
    "fa-regular fa-arrows-h",
    "fa-regular fa-arrows-v",
    "fa-regular fa-assistive-listening-systems",
    "fa-regular fa-asterisk",
    "fa-regular fa-at",
    "fa-regular fa-audio-description",
    "fa-regular fa-backward",
    "fa-regular fa-badge",
    "fa-regular fa-badge-check",
    "fa-regular fa-balance-scale",
    "fa-regular fa-ban",
    "fa-regular fa-band-aid",
    "fa-regular fa-barcode",
    "fa-regular fa-barcode-alt",
    "fa-regular fa-barcode-read",
    "fa-regular fa-barcode-scan",
    "fa-regular fa-bars",
    "fa-regular fa-baseball",
    "fa-regular fa-baseball-ball",
    "fa-regular fa-basketball-ball",
    "fa-regular fa-basketball-hoop",
    "fa-regular fa-bath",
    "fa-regular fa-battery-bolt",
    "fa-regular fa-battery-empty",
    "fa-regular fa-battery-full",
    "fa-regular fa-battery-half",
    "fa-regular fa-battery-quarter",
    "fa-regular fa-battery-slash",
    "fa-regular fa-battery-three-quarters",
    "fa-regular fa-bed",
    "fa-regular fa-beer",
    "fa-regular fa-bell",
    "fa-regular fa-bell-slash",
    "fa-regular fa-bicycle",
    "fa-regular fa-binoculars",
    "fa-regular fa-birthday-cake",
    "fa-regular fa-blanket",
    "fa-regular fa-blind",
    "fa-regular fa-bold",
    "fa-regular fa-bolt",
    "fa-regular fa-bomb",
    "fa-regular fa-book",
    "fa-regular fa-book-heart",
    "fa-regular fa-bookmark",
    "fa-regular fa-bowling-ball",
    "fa-regular fa-bowling-pins",
    "fa-regular fa-box",
    "fa-regular fa-box-alt",
    "fa-regular fa-box-check",
    "fa-regular fa-box-fragile",
    "fa-regular fa-box-full",
    "fa-regular fa-box-heart",
    "fa-regular fa-box-open",
    "fa-regular fa-box-up",
    "fa-regular fa-box-usd",
    "fa-regular fa-boxes",
    "fa-regular fa-boxes-alt",
    "fa-regular fa-boxing-glove",
    "fa-regular fa-braille",
    "fa-regular fa-briefcase",
    "fa-regular fa-briefcase-medical",
    "fa-regular fa-browser",
    "fa-regular fa-bug",
    "fa-regular fa-building",
    "fa-regular fa-bullhorn",
    "fa-regular fa-bullseye",
    "fa-regular fa-burn",
    "fa-regular fa-bus",
    "fa-regular fa-calculator",
    "fa-regular fa-calendar",
    "fa-regular fa-calendar-alt",
    "fa-regular fa-calendar-check",
    "fa-regular fa-calendar-edit",
    "fa-regular fa-calendar-exclamation",
    "fa-regular fa-calendar-minus",
    "fa-regular fa-calendar-plus",
    "fa-regular fa-calendar-times",
    "fa-regular fa-camera",
    "fa-regular fa-camera-alt",
    "fa-regular fa-camera-retro",
    "fa-regular fa-capsules",
    "fa-regular fa-car",
    "fa-regular fa-caret-circle-down",
    "fa-regular fa-caret-circle-left",
    "fa-regular fa-caret-circle-right",
    "fa-regular fa-caret-circle-up",
    "fa-regular fa-caret-down",
    "fa-regular fa-caret-left",
    "fa-regular fa-caret-right",
    "fa-regular fa-caret-square-down",
    "fa-regular fa-caret-square-left",
    "fa-regular fa-caret-square-right",
    "fa-regular fa-caret-square-up",
    "fa-regular fa-caret-up",
    "fa-regular fa-cart-arrow-down",
    "fa-regular fa-cart-plus",
    "fa-regular fa-certificate",
    "fa-regular fa-chart-area",
    "fa-regular fa-chart-bar",
    "fa-regular fa-chart-line",
    "fa-regular fa-chart-pie",
    "fa-regular fa-check",
    "fa-regular fa-check-circle",
    "fa-regular fa-check-square",
    "fa-regular fa-chess",
    "fa-regular fa-chess-bishop",
    "fa-regular fa-chess-bishop-alt",
    "fa-regular fa-chess-board",
    "fa-regular fa-chess-clock",
    "fa-regular fa-chess-clock-alt",
    "fa-regular fa-chess-king",
    "fa-regular fa-chess-king-alt",
    "fa-regular fa-chess-knight",
    "fa-regular fa-chess-knight-alt",
    "fa-regular fa-chess-pawn",
    "fa-regular fa-chess-pawn-alt",
    "fa-regular fa-chess-queen",
    "fa-regular fa-chess-queen-alt",
    "fa-regular fa-chess-rook",
    "fa-regular fa-chess-rook-alt",
    "fa-regular fa-chevron-circle-down",
    "fa-regular fa-chevron-circle-left",
    "fa-regular fa-chevron-circle-right",
    "fa-regular fa-chevron-circle-up",
    "fa-regular fa-chevron-double-down",
    "fa-regular fa-chevron-double-left",
    "fa-regular fa-chevron-double-right",
    "fa-regular fa-chevron-double-up",
    "fa-regular fa-chevron-down",
    "fa-regular fa-chevron-left",
    "fa-regular fa-chevron-right",
    "fa-regular fa-chevron-square-down",
    "fa-regular fa-chevron-square-left",
    "fa-regular fa-chevron-square-right",
    "fa-regular fa-chevron-square-up",
    "fa-regular fa-chevron-up",
    "fa-regular fa-child",
    "fa-regular fa-circle",
    "fa-regular fa-circle-notch",
    "fa-regular fa-clipboard",
    "fa-regular fa-clipboard-check",
    "fa-regular fa-clipboard-list",
    "fa-regular fa-clock",
    "fa-regular fa-clone",
    "fa-regular fa-closed-captioning",
    "fa-regular fa-cloud",
    "fa-regular fa-cloud-download",
    "fa-regular fa-cloud-download-alt",
    "fa-regular fa-cloud-upload",
    "fa-regular fa-cloud-upload-alt",
    "fa-regular fa-club",
    "fa-regular fa-code",
    "fa-regular fa-code-branch",
    "fa-regular fa-code-commit",
    "fa-regular fa-code-merge",
    "fa-regular fa-coffee",
    "fa-regular fa-cog",
    "fa-regular fa-cogs",
    "fa-regular fa-columns",
    "fa-regular fa-comment",
    "fa-regular fa-comment-alt",
    "fa-regular fa-comment-alt-check",
    "fa-regular fa-comment-alt-dots",
    "fa-regular fa-comment-alt-edit",
    "fa-regular fa-comment-alt-exclamation",
    "fa-regular fa-comment-alt-lines",
    "fa-regular fa-comment-alt-minus",
    "fa-regular fa-comment-alt-plus",
    "fa-regular fa-comment-alt-slash",
    "fa-regular fa-comment-alt-smile",
    "fa-regular fa-comment-alt-times",
    "fa-regular fa-comment-check",
    "fa-regular fa-comment-dots",
    "fa-regular fa-comment-edit",
    "fa-regular fa-comment-exclamation",
    "fa-regular fa-comment-lines",
    "fa-regular fa-comment-minus",
    "fa-regular fa-comment-plus",
    "fa-regular fa-comment-slash",
    "fa-regular fa-comment-smile",
    "fa-regular fa-comment-times",
    "fa-regular fa-comments",
    "fa-regular fa-comments-alt",
    "fa-regular fa-compass",
    "fa-regular fa-compress",
    "fa-regular fa-compress-alt",
    "fa-regular fa-compress-wide",
    "fa-regular fa-container-storage",
    "fa-regular fa-conveyor-belt",
    "fa-regular fa-conveyor-belt-alt",
    "fa-regular fa-copy",
    "fa-regular fa-copyright",
    "fa-regular fa-couch",
    "fa-regular fa-credit-card",
    "fa-regular fa-credit-card-blank",
    "fa-regular fa-credit-card-front",
    "fa-regular fa-cricket",
    "fa-regular fa-crop",
    "fa-regular fa-crosshairs",
    "fa-regular fa-cube",
    "fa-regular fa-cubes",
    "fa-regular fa-curling",
    "fa-regular fa-cut",
    "fa-regular fa-database",
    "fa-regular fa-deaf",
    "fa-regular fa-desktop",
    "fa-regular fa-desktop-alt",
    "fa-regular fa-diagnoses",
    "fa-regular fa-diamond",
    "fa-regular fa-dna",
    "fa-regular fa-dollar-sign",
    "fa-regular fa-dolly",
    "fa-regular fa-dolly-empty",
    "fa-regular fa-dolly-flatbed",
    "fa-regular fa-dolly-flatbed-alt",
    "fa-regular fa-dolly-flatbed-empty",
    "fa-regular fa-donate",
    "fa-regular fa-dot-circle",
    "fa-regular fa-dove",
    "fa-regular fa-download",
    "fa-regular fa-dumbbell",
    "fa-regular fa-edit",
    "fa-regular fa-eject",
    "fa-regular fa-ellipsis-h",
    "fa-regular fa-ellipsis-h-alt",
    "fa-regular fa-ellipsis-v",
    "fa-regular fa-ellipsis-v-alt",
    "fa-regular fa-envelope",
    "fa-regular fa-envelope-open",
    "fa-regular fa-envelope-square",
    "fa-regular fa-eraser",
    "fa-regular fa-euro-sign",
    "fa-regular fa-exchange",
    "fa-regular fa-exchange-alt",
    "fa-regular fa-exclamation",
    "fa-regular fa-exclamation-circle",
    "fa-regular fa-exclamation-square",
    "fa-regular fa-exclamation-triangle",
    "fa-regular fa-expand",
    "fa-regular fa-expand-alt",
    "fa-regular fa-expand-arrows",
    "fa-regular fa-expand-arrows-alt",
    "fa-regular fa-expand-wide",
    "fa-regular fa-external-link",
    "fa-regular fa-external-link-alt",
    "fa-regular fa-external-link-square",
    "fa-regular fa-external-link-square-alt",
    "fa-regular fa-eye",
    "fa-regular fa-eye-dropper",
    "fa-regular fa-eye-slash",
    "fa-regular fa-fast-backward",
    "fa-regular fa-fast-forward",
    "fa-regular fa-fax",
    "fa-regular fa-female",
    "fa-regular fa-field-hockey",
    "fa-regular fa-fighter-jet",
    "fa-regular fa-file",
    "fa-regular fa-file-alt",
    "fa-regular fa-file-archive",
    "fa-regular fa-file-audio",
    "fa-regular fa-file-check",
    "fa-regular fa-file-code",
    "fa-regular fa-file-edit",
    "fa-regular fa-file-excel",
    "fa-regular fa-file-exclamation",
    "fa-regular fa-file-image",
    "fa-regular fa-file-medical",
    "fa-regular fa-file-medical-alt",
    "fa-regular fa-file-minus",
    "fa-regular fa-file-pdf",
    "fa-regular fa-file-plus",
    "fa-regular fa-file-powerpoint",
    "fa-regular fa-file-times",
    "fa-regular fa-file-video",
    "fa-regular fa-file-word",
    "fa-regular fa-film",
    "fa-regular fa-film-alt",
    "fa-regular fa-filter",
    "fa-regular fa-fire",
    "fa-regular fa-fire-extinguisher",
    "fa-regular fa-first-aid",
    "fa-regular fa-flag",
    "fa-regular fa-flag-checkered",
    "fa-regular fa-flask",
    "fa-regular fa-folder",
    "fa-regular fa-folder-open",
    "fa-regular fa-font",
    "fa-regular fa-football-ball",
    "fa-regular fa-football-helmet",
    "fa-regular fa-forklift",
    "fa-regular fa-forward",
    "fa-regular fa-fragile",
    "fa-regular fa-frown",
    "fa-regular fa-futbol",
    "fa-regular fa-gamepad",
    "fa-regular fa-gavel",
    "fa-regular fa-gem",
    "fa-regular fa-genderless",
    "fa-regular fa-gift",
    "fa-regular fa-glass-martini",
    "fa-regular fa-globe",
    "fa-regular fa-golf-ball",
    "fa-regular fa-golf-club",
    "fa-regular fa-graduation-cap",
    "fa-regular fa-h-square",
    "fa-regular fa-h1",
    "fa-regular fa-h2",
    "fa-regular fa-h3",
    "fa-regular fa-hand-heart",
    "fa-regular fa-hand-holding",
    "fa-regular fa-hand-holding-box",
    "fa-regular fa-hand-holding-heart",
    "fa-regular fa-hand-holding-seedling",
    "fa-regular fa-hand-holding-usd",
    "fa-regular fa-hand-holding-water",
    "fa-regular fa-hand-lizard",
    "fa-regular fa-hand-paper",
    "fa-regular fa-hand-peace",
    "fa-regular fa-hand-point-down",
    "fa-regular fa-hand-point-left",
    "fa-regular fa-hand-point-right",
    "fa-regular fa-hand-point-up",
    "fa-regular fa-hand-pointer",
    "fa-regular fa-hand-receiving",
    "fa-regular fa-hand-rock",
    "fa-regular fa-hand-scissors",
    "fa-regular fa-hand-spock",
    "fa-regular fa-hands",
    "fa-regular fa-hands-heart",
    "fa-regular fa-hands-helping",
    "fa-regular fa-hands-usd",
    "fa-regular fa-handshake",
    "fa-regular fa-handshake-alt",
    "fa-regular fa-hashtag",
    "fa-regular fa-hdd",
    "fa-regular fa-heading",
    "fa-regular fa-headphones",
    "fa-regular fa-heart",
    "fa-regular fa-heart-circle",
    "fa-regular fa-heart-square",
    "fa-regular fa-heartbeat",
    "fa-regular fa-hexagon",
    "fa-regular fa-history",
    "fa-regular fa-hockey-puck",
    "fa-regular fa-hockey-sticks",
    "fa-regular fa-home",
    "fa-regular fa-home-heart",
    "fa-regular fa-hospital",
    "fa-regular fa-hospital-alt",
    "fa-regular fa-hospital-symbol",
    "fa-regular fa-hourglass",
    "fa-regular fa-hourglass-end",
    "fa-regular fa-hourglass-half",
    "fa-regular fa-hourglass-start",
    "fa-regular fa-i-cursor",
    "fa-regular fa-id-badge",
    "fa-regular fa-id-card",
    "fa-regular fa-id-card-alt",
    "fa-regular fa-image",
    "fa-regular fa-images",
    "fa-regular fa-inbox",
    "fa-regular fa-inbox-in",
    "fa-regular fa-inbox-out",
    "fa-regular fa-indent",
    "fa-regular fa-industry",
    "fa-regular fa-industry-alt",
    "fa-regular fa-info",
    "fa-regular fa-info-circle",
    "fa-regular fa-info-square",
    "fa-regular fa-inventory",
    "fa-regular fa-italic",
    "fa-regular fa-jack-o-lantern",
    "fa-regular fa-key",
    "fa-regular fa-keyboard",
    "fa-regular fa-lamp",
    "fa-regular fa-language",
    "fa-regular fa-laptop",
    "fa-regular fa-leaf",
    "fa-regular fa-leaf-heart",
    "fa-regular fa-lemon",
    "fa-regular fa-level-down",
    "fa-regular fa-level-down-alt",
    "fa-regular fa-level-up",
    "fa-regular fa-level-up-alt",
    "fa-regular fa-life-ring",
    "fa-regular fa-lightbulb",
    "fa-regular fa-link",
    "fa-regular fa-lira-sign",
    "fa-regular fa-list",
    "fa-regular fa-list-alt",
    "fa-regular fa-list-ol",
    "fa-regular fa-list-ul",
    "fa-regular fa-location-arrow",
    "fa-regular fa-lock",
    "fa-regular fa-lock-alt",
    "fa-regular fa-lock-open",
    "fa-regular fa-lock-open-alt",
    "fa-regular fa-long-arrow-alt-down",
    "fa-regular fa-long-arrow-alt-left",
    "fa-regular fa-long-arrow-alt-right",
    "fa-regular fa-long-arrow-alt-up",
    "fa-regular fa-long-arrow-down",
    "fa-regular fa-long-arrow-left",
    "fa-regular fa-long-arrow-right",
    "fa-regular fa-long-arrow-up",
    "fa-regular fa-loveseat",
    "fa-regular fa-low-vision",
    "fa-regular fa-luchador",
    "fa-regular fa-magic",
    "fa-regular fa-magnet",
    "fa-regular fa-male",
    "fa-regular fa-map",
    "fa-regular fa-map-marker",
    "fa-regular fa-map-marker-alt",
    "fa-regular fa-map-pin",
    "fa-regular fa-map-signs",
    "fa-regular fa-mars",
    "fa-regular fa-mars-double",
    "fa-regular fa-mars-stroke",
    "fa-regular fa-mars-stroke-h",
    "fa-regular fa-mars-stroke-v",
    "fa-regular fa-medkit",
    "fa-regular fa-meh",
    "fa-regular fa-mercury",
    "fa-regular fa-microchip",
    "fa-regular fa-microphone",
    "fa-regular fa-microphone-alt",
    "fa-regular fa-microphone-slash",
    "fa-regular fa-minus",
    "fa-regular fa-minus-circle",
    "fa-regular fa-minus-hexagon",
    "fa-regular fa-minus-octagon",
    "fa-regular fa-minus-square",
    "fa-regular fa-mobile",
    "fa-regular fa-mobile-alt",
    "fa-regular fa-mobile-android",
    "fa-regular fa-mobile-android-alt",
    "fa-regular fa-money-bill",
    "fa-regular fa-money-bill-alt",
    "fa-regular fa-moon",
    "fa-regular fa-motorcycle",
    "fa-regular fa-mouse-pointer",
    "fa-regular fa-music",
    "fa-regular fa-neuter",
    "fa-regular fa-newspaper",
    "fa-regular fa-notes-medical",
    "fa-regular fa-object-group",
    "fa-regular fa-object-ungroup",
    "fa-regular fa-octagon",
    "fa-regular fa-outdent",
    "fa-regular fa-paint-brush",
    "fa-regular fa-pallet",
    "fa-regular fa-pallet-alt",
    "fa-regular fa-paper-plane",
    "fa-regular fa-paperclip",
    "fa-regular fa-parachute-box",
    "fa-regular fa-paragraph",
    "fa-regular fa-paste",
    "fa-regular fa-pause",
    "fa-regular fa-pause-circle",
    "fa-regular fa-paw",
    "fa-regular fa-pen",
    "fa-regular fa-pen-alt",
    "fa-regular fa-pen-square",
    "fa-regular fa-pencil",
    "fa-regular fa-pencil-alt",
    "fa-regular fa-pennant",
    "fa-regular fa-people-carry",
    "fa-regular fa-percent",
    "fa-regular fa-person-carry",
    "fa-regular fa-person-dolly",
    "fa-regular fa-person-dolly-empty",
    "fa-regular fa-phone",
    "fa-regular fa-phone-plus",
    "fa-regular fa-phone-slash",
    "fa-regular fa-phone-square",
    "fa-regular fa-phone-volume",
    "fa-regular fa-piggy-bank",
    "fa-regular fa-pills",
    "fa-regular fa-plane",
    "fa-regular fa-plane-alt",
    "fa-regular fa-play",
    "fa-regular fa-play-circle",
    "fa-regular fa-plug",
    "fa-regular fa-plus",
    "fa-regular fa-plus-circle",
    "fa-regular fa-plus-hexagon",
    "fa-regular fa-plus-octagon",
    "fa-regular fa-plus-square",
    "fa-regular fa-podcast",
    "fa-regular fa-poo",
    "fa-regular fa-portrait",
    "fa-regular fa-pound-sign",
    "fa-regular fa-power-off",
    "fa-regular fa-prescription-bottle",
    "fa-regular fa-prescription-bottle-alt",
    "fa-regular fa-print",
    "fa-regular fa-procedures",
    "fa-regular fa-puzzle-piece",
    "fa-regular fa-qrcode",
    "fa-regular fa-question",
    "fa-regular fa-question-circle",
    "fa-regular fa-question-square",
    "fa-regular fa-quidditch",
    "fa-regular fa-quote-left",
    "fa-regular fa-quote-right",
    "fa-regular fa-racquet",
    "fa-regular fa-ramp-loading",
    "fa-regular fa-random",
    "fa-regular fa-rectangle-landscape",
    "fa-regular fa-rectangle-portrait",
    "fa-regular fa-rectangle-wide",
    "fa-regular fa-recycle",
    "fa-regular fa-redo",
    "fa-regular fa-redo-alt",
    "fa-regular fa-registered",
    "fa-regular fa-repeat",
    "fa-regular fa-repeat-1",
    "fa-regular fa-repeat-1-alt",
    "fa-regular fa-repeat-alt",
    "fa-regular fa-reply",
    "fa-regular fa-reply-all",
    "fa-regular fa-retweet",
    "fa-regular fa-retweet-alt",
    "fa-regular fa-ribbon",
    "fa-regular fa-road",
    "fa-regular fa-rocket",
    "fa-regular fa-route",
    "fa-regular fa-rss",
    "fa-regular fa-rss-square",
    "fa-regular fa-ruble-sign",
    "fa-regular fa-rupee-sign",
    "fa-regular fa-save",
    "fa-regular fa-scanner",
    "fa-regular fa-scanner-keyboard",
    "fa-regular fa-scanner-touchscreen",
    "fa-regular fa-scrubber",
    "fa-regular fa-search",
    "fa-regular fa-search-minus",
    "fa-regular fa-search-plus",
    "fa-regular fa-seedling",
    "fa-regular fa-server",
    "fa-regular fa-share",
    "fa-regular fa-share-all",
    "fa-regular fa-share-alt",
    "fa-regular fa-share-alt-square",
    "fa-regular fa-share-square",
    "fa-regular fa-shekel-sign",
    "fa-regular fa-shield",
    "fa-regular fa-shield-alt",
    "fa-regular fa-shield-check",
    "fa-regular fa-ship",
    "fa-regular fa-shipping-fast",
    "fa-regular fa-shipping-timed",
    "fa-regular fa-shopping-bag",
    "fa-regular fa-shopping-basket",
    "fa-regular fa-shopping-cart",
    "fa-regular fa-shower",
    "fa-regular fa-shuttlecock",
    "fa-regular fa-sign",
    "fa-regular fa-sign-in",
    "fa-regular fa-sign-in-alt",
    "fa-regular fa-sign-language",
    "fa-regular fa-sign-out",
    "fa-regular fa-sign-out-alt",
    "fa-regular fa-signal",
    "fa-regular fa-sitemap",
    "fa-regular fa-sliders-h",
    "fa-regular fa-sliders-h-square",
    "fa-regular fa-sliders-v",
    "fa-regular fa-sliders-v-square",
    "fa-regular fa-smile",
    "fa-regular fa-smile-plus",
    "fa-regular fa-smoking",
    "fa-regular fa-snowflake",
    "fa-regular fa-sort",
    "fa-regular fa-sort-alpha-down",
    "fa-regular fa-sort-alpha-up",
    "fa-regular fa-sort-amount-down",
    "fa-regular fa-sort-amount-up",
    "fa-regular fa-sort-down",
    "fa-regular fa-sort-numeric-down",
    "fa-regular fa-sort-numeric-up",
    "fa-regular fa-sort-up",
    "fa-regular fa-space-shuttle",
    "fa-regular fa-spade",
    "fa-regular fa-spinner",
    "fa-regular fa-spinner-third",
    "fa-regular fa-square",
    "fa-regular fa-square-full",
    "fa-regular fa-star",
    "fa-regular fa-star-exclamation",
    "fa-regular fa-star-half",
    "fa-regular fa-step-backward",
    "fa-regular fa-step-forward",
    "fa-regular fa-stethoscope",
    "fa-regular fa-sticky-note",
    "fa-regular fa-stop",
    "fa-regular fa-stop-circle",
    "fa-regular fa-stopwatch",
    "fa-regular fa-street-view",
    "fa-regular fa-strikethrough",
    "fa-regular fa-subscript",
    "fa-regular fa-subway",
    "fa-regular fa-suitcase",
    "fa-regular fa-sun",
    "fa-regular fa-superscript",
    "fa-regular fa-sync",
    "fa-regular fa-sync-alt",
    "fa-regular fa-syringe",
    "fa-regular fa-table",
    "fa-regular fa-table-tennis",
    "fa-regular fa-tablet",
    "fa-regular fa-tablet-alt",
    "fa-regular fa-tablet-android",
    "fa-regular fa-tablet-android-alt",
    "fa-regular fa-tablet-rugged",
    "fa-regular fa-tablets",
    "fa-regular fa-tachometer",
    "fa-regular fa-tachometer-alt",
    "fa-regular fa-tag",
    "fa-regular fa-tags",
    "fa-regular fa-tape",
    "fa-regular fa-tasks",
    "fa-regular fa-taxi",
    "fa-regular fa-tennis-ball",
    "fa-regular fa-terminal",
    "fa-regular fa-text-height",
    "fa-regular fa-text-width",
    "fa-regular fa-th",
    "fa-regular fa-th-large",
    "fa-regular fa-th-list",
    "fa-regular fa-thermometer",
    "fa-regular fa-thermometer-empty",
    "fa-regular fa-thermometer-full",
    "fa-regular fa-thermometer-half",
    "fa-regular fa-thermometer-quarter",
    "fa-regular fa-thermometer-three-quarters",
    "fa-regular fa-thumbs-down",
    "fa-regular fa-thumbs-up",
    "fa-regular fa-thumbtack",
    "fa-regular fa-ticket",
    "fa-regular fa-ticket-alt",
    "fa-regular fa-times",
    "fa-regular fa-times-circle",
    "fa-regular fa-times-hexagon",
    "fa-regular fa-times-octagon",
    "fa-regular fa-times-square",
    "fa-regular fa-tint",
    "fa-regular fa-toggle-off",
    "fa-regular fa-toggle-on",
    "fa-regular fa-trademark",
    "fa-regular fa-train",
    "fa-regular fa-transgender",
    "fa-regular fa-transgender-alt",
    "fa-regular fa-trash",
    "fa-regular fa-trash-alt",
    "fa-regular fa-tree",
    "fa-regular fa-tree-alt",
    "fa-regular fa-triangle",
    "fa-regular fa-trophy",
    "fa-regular fa-trophy-alt",
    "fa-regular fa-truck",
    "fa-regular fa-truck-container",
    "fa-regular fa-truck-couch",
    "fa-regular fa-truck-loading",
    "fa-regular fa-truck-moving",
    "fa-regular fa-truck-ramp",
    "fa-regular fa-tty",
    "fa-regular fa-tv",
    "fa-regular fa-tv-retro",
    "fa-regular fa-umbrella",
    "fa-regular fa-underline",
    "fa-regular fa-undo",
    "fa-regular fa-undo-alt",
    "fa-regular fa-universal-access",
    "fa-regular fa-university",
    "fa-regular fa-unlink",
    "fa-regular fa-unlock",
    "fa-regular fa-unlock-alt",
    "fa-regular fa-upload",
    "fa-regular fa-usd-circle",
    "fa-regular fa-usd-square",
    "fa-regular fa-user",
    "fa-regular fa-user-alt",
    "fa-regular fa-user-circle",
    "fa-regular fa-user-md",
    "fa-regular fa-user-plus",
    "fa-regular fa-user-secret",
    "fa-regular fa-user-times",
    "fa-regular fa-users",
    "fa-regular fa-utensil-fork",
    "fa-regular fa-utensil-knife",
    "fa-regular fa-utensil-spoon",
    "fa-regular fa-utensils",
    "fa-regular fa-utensils-alt",
    "fa-regular fa-venus",
    "fa-regular fa-venus-double",
    "fa-regular fa-venus-mars",
    "fa-regular fa-vial",
    "fa-regular fa-vials",
    "fa-regular fa-video",
    "fa-regular fa-video-plus",
    "fa-regular fa-video-slash",
    "fa-regular fa-volleyball-ball",
    "fa-regular fa-volume-down",
    "fa-regular fa-volume-mute",
    "fa-regular fa-volume-off",
    "fa-regular fa-volume-up",
    "fa-regular fa-warehouse",
    "fa-regular fa-warehouse-alt",
    "fa-regular fa-watch",
    "fa-regular fa-weight",
    "fa-regular fa-wheelchair",
    "fa-regular fa-whistle",
    "fa-regular fa-wifi",
    "fa-regular fa-window",
    "fa-regular fa-window-alt",
    "fa-regular fa-window-close",
    "fa-regular fa-window-maximize",
    "fa-regular fa-window-minimize",
    "fa-regular fa-window-restore",
    "fa-regular fa-wine-glass",
    "fa-regular fa-won-sign",
    "fa-regular fa-wrench",
    "fa-regular fa-x-ray",
    "fa-regular fa-yen-sign",
];
