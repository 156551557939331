import _ from "lodash";
import { Order, OrderItem } from "../../api/models/order";
import { IPurchase, IPurchaseItem } from "../../api/models/purchase";
import { IWarehouseProduct } from "../../api/models/warehouseProduct";
import customMoment from "../../utils/momentFormat/dateFormat";

export const stockIsValid = (orderItem: OrderItem) => {
    const { product } = orderItem;
    if (!product) return true;
    if (orderItem.warehouse && orderItem.warehouse.allowNegativeStock) return true;
    if (!product.controlInventory) return true;

    if ((orderItem.stockTemp || orderItem.stockTemp === 0) && orderItem.quantity > orderItem.stockTemp) return false;

    return true;
};

export const getAvailableStock = (warehouseProduct?: IWarehouseProduct) => {
    if (warehouseProduct) {
        return warehouseProduct.stock - warehouseProduct.reserved;
    }
    return 0;
};

export const checkIfOrderIsValid = (order?: any) => {
    return order?.status && order.accountId && order.accountUserId;
};

export const getOrderCopyOmit = (order: Order, ignoreList?: string[]) => {
    ignoreList = ignoreList ?? [];
    order.orderChannelId = order.orderChannel?.id;

    let orderBase = _.omit(order, [
        ...ignoreList,
        "account",
        "userSeller",
        "contactSeller",
        "orderChannel",
        "orderTable",
        "paymentTermsDaysDefaultLoaded",
        "accountUser",
        "accountUserEntitiesLoaded",
    ]);

    if (orderBase.customer) {
        orderBase.customer = _.omit(order.customer, ["contactDebtsBody"]);
        orderBase.customer.addresses = [];
    }

    if (orderBase.dateCreatedCustom) {
        orderBase.dateCreatedCustom = customMoment(orderBase.dateCreatedCustom).utc().format();
    }

    let numberFields: (keyof Order)[] = ["totalDiscount", "totalTip", "percentageDiscount", "percentageTip"];
    numberFields.forEach((key) => {
        (orderBase as any)[key] = setNumberField(orderBase[key]);
    });

    let items: OrderItem[] = [];
    orderBase.items?.forEach((element) => {
        let item = _.omit(element, ["warehouseProduct", "product", "warehouse", "productVariant"]);
        items.push(item);
        orderBase.warehouseId = item.warehouseId;
    });
    orderBase.items = items;

    return orderBase;
};

export const setNumberField = (value: any): number => {
    const num = Number(value);
    if (isNaN(num)) {
        return 0;
    }
    return num;
};

export const getPurchaseCopyOmit = (order: IPurchase, ignoreList?: string[]) => {
    ignoreList = ignoreList ?? [];
    let orderBase = _.omit(order, [
        ...ignoreList,
        "account",
        "userSeller",
        "customer",
        "supplier",
        "warehouse",
        "paymentTermsDaysDefaultLoaded",
        "accountUser",
        "accountUserEntitiesLoaded",
    ]);

    let items: IPurchaseItem[] = [];
    orderBase.items?.forEach((element) => {
        let item = _.omit(element, ["warehouseProduct", "product", "warehouse", "productVariant"]);
        items.push(item);
    });
    orderBase.items = items;

    return orderBase;
};
