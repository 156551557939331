import React, { PureComponent, useContext } from "react";
import { ContactImpl } from "../../api/models/contact";
import { Order, OrderImpl, OrderItemImpl } from "../../api/models/order";
import { OrderInvoiceNumeration } from "../../api/models/orderInvoiceNumeration";
import { OrderPayment } from "../../api/models/orderPayment";
import { IOrderTable } from "../../api/models/orderTable";
import { IOrganizationTemplate, OrderPrintSettings } from "../../api/models/organizationTypes";
import { ProductImpl } from "../../api/models/product";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../auth";
import customMoment from "../../utils/momentFormat/dateFormat";
import { InvoiceFormatHalfPage } from "./InvoiceFormatHalfPage";
import InvoiceFormatNormal from "./InvoiceFormatNormal";
import InvoiceFormatSmall from "./InvoiceFormatSmall";
import { DeliveryOrder } from "../../api/models/deliveryOrder";
import moment from "moment";

export interface OrderInvoiceParams {
    order: Order;
    orderTable?: IOrderTable;
}

export interface OrderInvoiceFormatProps extends OrderInvoiceParams {
    template: IOrganizationTemplate;
    printSettings?: OrderPrintSettings;
    image?: any;
    testMode?: boolean;
    numeration?: OrderInvoiceNumeration;
    completeOrder?: boolean;
}

export const getExampleOrder = (): Order => {
    let order: Order = new OrderImpl("ac1000", "us1000", "or1000");
    order.number = 1000;
    order.totalAmountItems = 24000;
    order.subTotal = 24000;
    order.totalAmount = 21600;
    order.totalPaid = 17600;
    order.paymentTermsDays = 30;
    order.dateCreated = customMoment().toDate();
    order.status = "credit";

    order.eBillingNumber = "FE-1234567";
    order.eBillingGenerated = true;

    order.accountUserId = getAuthBody().accountUserId;

    order.orderInvoice = {
        id: "string",
        prefix: "XT",
        realNumber: 1200,
        number: "XT1200",
        orderId: "string;",
        dateCreated: customMoment().toDate(),
        orderInvoiceNumerationId: "string",
        accountUserId: "string;",
    };

    var deliveryOrder = new DeliveryOrder("ac", "ac");
    deliveryOrder.address = "Calle 123";
    deliveryOrder.city = "Medellin";
    deliveryOrder.state = "Antioquia";
    deliveryOrder.country = "Colombia";
    deliveryOrder.dateCreated = customMoment().toDate();
    deliveryOrder.contactName = "Cliente Ejemplo";
    deliveryOrder.phoneNumber = "123456789";

    order.deliveryOrder = deliveryOrder;

    order.contactSeller = new ContactImpl("ac");
    order.contactSeller.firstName = "Vendedor";
    order.contactSeller.lastName = "Ejemplo";
    order.contactSellerId = "AC";

    order.customerId = "ac";
    order.customer = new ContactImpl("ac");
    order.customer.firstName = "Cliente";
    order.customer.lastName = "Ejemplo";
    order.customer.phoneNumber = "123456789";
    order.itemsCount = 1
    
    order.totalManualPercentageDiscountItems = 2400;
    order.totalTaxItems = 3832;
    
    let firsPayment = new OrderPayment(10000, "cash", "", "", moment().subtract(15, "days").toDate());
    let secondPayment = new OrderPayment(7600, "transfer", "", "", moment().subtract(1, "days").toDate());
    order.payments = [firsPayment, secondPayment];
    
    let product1 = new ProductImpl("ac1000");
    product1.name = "Description del producto";
    product1.sku = "PR-1234";
    product1.code = "CO-9423";

    let saleItem1 = new OrderItemImpl(product1, 2, "price", 2);
    saleItem1.unitPrice = 12000;
    saleItem1.totalTaxPercentage = 19;
    saleItem1.totalTax = 1916;
    saleItem1.manualPercentageDiscountValue = 500;
    saleItem1.manualPercentageDiscount = 10;

    order.items = [saleItem1];
    return order;
};

const InvoiceFormModel = (props: OrderInvoiceFormatProps) => {
    const { appSettingsState } = useContext(AppSettingsContext);

    if (props.order) {
        if (appSettingsState.printType === "80mm") {
            return <InvoiceFormatSmall {...props} />;
        }

        if (appSettingsState.printType === "halfPage") {
            return <InvoiceFormatHalfPage {...props} />;
        }

        return <InvoiceFormatNormal {...props} />;
    }
    return null;
};

export default class OrderInvoiceFormat extends PureComponent<OrderInvoiceFormatProps> {
    render() {
        return <InvoiceFormModel {...this.props} />;
    }
}
