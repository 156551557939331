import { ThemeType } from "../../../appSettings/types";

interface ThemeColorGroup {
    background: string;
    hover: string;
    text?: string;
}
export interface ThemeColor {
    name: string;
    light?: ThemeColorGroup;
    dark?: ThemeColorGroup;
}

export const mainColorObj: ThemeColor[] = [
    {
        name: "Solid_Default",
    },
    {
        name: "Solid_Blue",
        light: { background: "rgb(41 98 255 / 13%)", hover: "rgb(63 81 181 / 16%)", text: "rgb(41 98 255 / 80%)" },
        dark: { background: "rgb(30 35 51)", hover: "rgb(33 150 243 / 10%)" },
    },
    {
        name: "Solid_Green",
        light: { background: "rgb(0 191 165 / 11%)", hover: "rgb(0 191 165 / 30%)", text: "#009688" },
        dark: { background: "rgb(0 61 52)", hover: "rgb(0 191 165 / 30%)", text: "#009688" },
    },
    {
        name: "Solid_Pink",
        light: { background: "rgb(233 30 99 / 11%)", hover: "rgb(233 30 99 / 15%)", text: "rgb(233 30 99)" },
        dark: { background: "rgb(37 4 15)", hover: "rgb(233 30 99 / 15%)", text: "rgb(233 30 99)" },
    },
    {
        name: "Solid_Orange",
        light: { background: "rgb(245 127 23 / 23%)", hover: "rgb(245 127 23 / 33%)", text: "rgb(165 77 0)" },
        dark: { background: "rgb(49 26 6)", hover: "rgb(245 127 23 / 14%)", text: "rgb(220 103 0)" },
    },
    {
        name: "Solid_Purple",
        light: { background: "rgb(101 31 255 / 17%)", hover: "rgb(101 31 255 / 27%)", text: "#651FFF" },
        dark: { background: "rgb(40 25 73)", hover: "rgb(101 31 255 / 27%)", text: "#651FFF" },
    },
];

export const gradientColorObj: ThemeColor[] = [
    {
        name: "Gradient_BluePink",
        light: { background: "linear-gradient(90deg, rgb(48 79 254 / 13%) 0%, rgb(252 70 208 / 14%) 100%)", hover: "rgb(63 81 181 / 20%)" },
        dark: { background: "linear-gradient(90deg, rgb(13 18 42) 0%, rgb(53 19 45) 100%)", hover: "rgb(63 81 181 / 20%)" },
    },
    {
        name: "Gradient_Orange",
        light: {
            background: "linear-gradient(90deg, rgb(247 186 43 / 15%) 0%, rgb(234 83 88 / 14%) 100%)",
            hover: "rgb(244 67 54 / 15%)",
            text: "rgb(244 67 54)",
        },
        dark: {
            background: "linear-gradient(90deg, rgb(42 24 0) 0%, rgb(71 11 13) 100%)",
            hover: "rgb(244 67 54 / 15%)",
            text: "rgb(244 67 54)",
        },
    },
    {
        name: "Gradient_BlueGreen",
        light: {
            background: "linear-gradient(134deg, #2196f32b 0%, #01c16570 100%)",
            hover: "rgb(33 150 243 / 20%)",
            text: "rgb(48, 79, 254)",
        },
        dark: {
            background: "linear-gradient(134deg, rgb(0 36 65) 0%, rgb(0 69 36) 100%)",
            hover: "rgb(33 150 243 / 20%)",
            text: "rgb(48, 79, 254)",
        },
    },
];

interface ThemeOption {
    type: ThemeType;
    icon: string;
}
export const themeOptions: ThemeOption[] = [
    {
        type: "light",
        icon: "fa-regular fa-sun-bright",
    },
    {
        type: "dark",
        icon: "fa-regular fa-moon",
    },
    {
        type: "device",
        icon: "fa-regular fa-laptop",
    },
];
