import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../../api";
import { TRACK_ENDPOINT } from "../../../../api/appConfig";
import { ICashRegisterEntry } from "../../../../api/models/cashRegister";
import { Contact } from "../../../../api/models/contact";
import { MessageOptionBody, PaymentReceiptInput } from "../../../../api/models/notification";
import { IOrganization } from "../../../../api/models/organizationTypes";
import { sendNotificationEmailPaymentReceipt, sendNotificationSms } from "../../../../api/notifications/notificationApi";
import { getAuthBody } from "../../../../auth";
import messages from "../../../../messages";
import { AccountUserContext } from "../../../../store/contexts/AccountUserContext";
import { CashRegisterContext } from "../../../../store/contexts/CashRegisterContext";
import { OrganizationContext } from "../../../../store/contexts/OrganizationContext";
import { formatMoney, getDateFormatll, getModelFullName, joinUrl } from "../../../../utils";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import { NotificationCard, NotificationItem } from "../../../Orders/CreateOrderForm/FinalizeOrderForm/NotificationOrderCard";
import { PaymentSuccessContainer } from "../../../Payments/styled";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../../_controls";
import TooltipRadix from "../../../_controls/WahioTooltip/TooltipRadix";
import Modal, { IModalShow } from "../../../modals/Modal";

interface SuccessfulPaymentReceiptProps extends IModalShow {
    cashRegisterEntry: ICashRegisterEntry;
}

export const SuccessfulPaymentReceiptModal = (props: SuccessfulPaymentReceiptProps) => {
    const entry = props.cashRegisterEntry;
    const { cashRegisterState } = useContext(CashRegisterContext);
    const { accountUserState } = useContext(AccountUserContext);
    const { organizationState } = useContext(OrganizationContext);
    const cashRegister = cashRegisterState.orgItems.find((x) => x.id === entry.cashRegisterId);
    const user = accountUserState.items.find((x) => x.id === entry.accountUserId);
    const intl = useIntl();
    const existNumber = entry.number || entry.referenceNumber;

    return (
        <>
            <Modal show={props.show} setShow={props.setShow} title="Comprobante de pago" showHeader>
                <PaymentSuccessContainer>
                    <Flex alignCenter justifyCenter column gap5>
                        <Flex className="CheckRoundSuccess">
                            <i className="iconCheck fa-regular fa-check" />
                        </Flex>
                        <TextField>{entry.isIncome ? "Pago recibido satisfactoriamente" : "Pago realizado satisfactoriamente"}</TextField>
                    </Flex>
                    <Flex className="container">
                        <Flex alignCenter justifyCenter gap5 column>
                            <TextField bold fontSize={18}>
                                {formatMoney(entry.amount)}
                            </TextField>
                            <TextField light>{getDateFormatll(entry.dateCreated)}</TextField>
                        </Flex>
                        <hr className="lineSeparation" />
                        <Flex spaceBetween padding={10}>
                            <Flex column gap5>
                                <TextField small>Empresa</TextField>
                                <TextField small>Cliente</TextField>
                                <TextField small>{entry.isIncome ? "Recibido por" : "Pagado por"}</TextField>
                                <TextField small>Caja registradora</TextField>
                                <TextField small>Método de pago</TextField>
                                {entry.category && <TextField small>Categoría</TextField>}
                            </Flex>
                            <Flex column gap5 alignEnd>
                                <TextField small>{organizationState.currentOrganization?.name ?? "-"}</TextField>
                                <TextField small>{entry.contact ? getModelFullName(entry.contact) : "-"}</TextField>
                                <TextField small>{getModelFullName(user)}</TextField>
                                <TextField small>{cashRegister?.name}</TextField>
                                <TextField small>{intl.formatMessage((messages as any)[entry.method])}</TextField>
                                {entry.category && <TextField small>{entry.category.name}</TextField>}
                            </Flex>
                        </Flex>
                        <hr className="lineSeparation" />
                        <Flex spaceBetween padding={10}>
                            <Flex column gap5>
                                {existNumber && <TextField small>{entry.referenceNumber ? "Numero de referencia" : "Numero de orden"}</TextField>}
                                <TextField small>Valor total</TextField>
                                {/* <TextField small>{entry.isIncome ? "Total recibido" : "Total pagado"}</TextField> */}
                                {/* {entry.totalAmount > 0 && <TextField small>Saldo pendiente</TextField>} */}
                            </Flex>
                            <Flex column gap5 alignEnd>
                                {existNumber && <TextField small>{entry.referenceNumber ?? "-"}</TextField>}
                                <TextField bold small>
                                    {formatMoney(entry.amount)}
                                </TextField>
                                {/* <TextField small>{formatMoney(entry.totalPaid)}</TextField> */}
                                {/* {entry.totalAmount > 0 && <TextField bold small>{formatMoney(entry.totalAmount - entry.totalPaid)}</TextField>} */}
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex gap15 justifyEnd alignEnd>
                        <NotificationPaymentReceiptButtons
                            entry={props.cashRegisterEntry}
                            currentOrganization={organizationState.currentOrganization!}
                        />
                    </Flex>
                </PaymentSuccessContainer>
            </Modal>
        </>
    );
};

interface NotificationPaymentReceiptProps extends IModalShow {
    entry: ICashRegisterEntry;
    contact?: Contact;
    organization: IOrganization;
    trackingNumber?: string;
}

const NotificationPaymentReceipt = (props: NotificationPaymentReceiptProps) => {
    const { contact, entry } = props;
    const alert = useAlert();

    const notificationPayment = useNotificationPayment({ organization: props.organization, trackingNumber: props.trackingNumber });

    const [notificationState, setNotificationState] = useState<NotificationItem>({
        phonePrefix: contact?.phonePrefix ?? "57",
        phoneNumber: contact?.phoneNumber ?? "",
        email: contact?.email ?? "",
        contactName: entry.contact?.firstName ?? "",
    });

    const onClickSendNotification = () => {
        //check if exists a least one contact info email or phone, if not, show error
        if (!notificationState.email && !notificationState.phoneNumber) {
            alert.info("Debe ingresar al menos un correo o un teléfono");
            return;
        }
        notificationPayment.onSendSms(notificationState, entry, () => {
            props.setShow(false);
        });
        notificationPayment.onSendEmail(notificationState, entry);
    };

    const isLoading = notificationPayment.isLoading;

    return (
        <Modal show={props.show} setShow={props.setShow} useButtonClose>
            <Flex column gap={20} padding={20}>
                <Flex justifyCenter column>
                    <TextField bold>{`Enviar notificación`}</TextField>
                </Flex>
                <NotificationCard showContactName item={notificationState} onChange={setNotificationState} />
                <Flex gap15 w100>
                    <DefaultButton disabled={isLoading} bgLight w100 borderRadius={10} onClick={() => props.setShow(false)}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton disabled={isLoading} w100 borderRadius={10} onClick={() => onClickSendNotification()}>
                        <i className="fa-regular fa-paper-plane"></i> {isLoading ? <LoadingDualRing small /> : "Enviar"}
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
};

export interface SuccessfulPaymentsProps {
    entry: ICashRegisterEntry;
    currentOrganization: IOrganization;
}

export const NotificationPaymentReceiptButtons = (props: SuccessfulPaymentsProps) => {
    const { entry, currentOrganization } = props;

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal && currentOrganization && (
                <NotificationPaymentReceipt
                    show={showModal}
                    setShow={setShowModal}
                    entry={entry}
                    contact={entry.contact}
                    organization={currentOrganization}
                />
            )}
            <Flex gap10>
                <Flex gap10 alignEnd justifyEnd>
                    <TooltipRadix
                        title="Enviar notificación"
                        trigger={
                            <SquareButton bgLight onClick={() => setShowModal(true)}>
                                <i className="fa-regular fa-paper-plane"></i>
                            </SquareButton>
                        }
                        delay={0}
                    />
                </Flex>
            </Flex>
        </>
    );
};

interface UseNotificationPaymentProps {
    organization?: IOrganization;
    trackingNumber?: string;
}

export const useNotificationPayment = (props: UseNotificationPaymentProps) => {
    const intl = useIntl();
    const alert = useAlert();

    const onSendEmailMutation = useMutation((value: PaymentReceiptInput) => sendNotificationEmailPaymentReceipt(value), {
        onSuccess: (response) => {},
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const smsMutation = useMutation((body: MessageOptionBody) => sendNotificationSms(body), {
        onSuccess: (response) => {},
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const onSendSms = (notificationState: NotificationItem, entry: ICashRegisterEntry, onSent?: () => void) => {
        if (!notificationState.phonePrefix || !notificationState.phoneNumber) return;

        const trackingUrl = `${joinUrl(TRACK_ENDPOINT, props.trackingNumber ?? "")}`;
        const contact = notificationState.contactName ? notificationState.contactName : "Estimado cliente";
        const message = `Hola, ${contact}. Tu pago por ${formatMoney(entry.amount)} ha sido recibido en ${props.organization?.name ?? ""}.`;

        const smsBody: MessageOptionBody = {
            countryCode: notificationState.phonePrefix ?? "57",
            message: `${message} ${props.trackingNumber ? trackingUrl : ""}`,
            phoneNumber: `${notificationState.phonePrefix}${notificationState.phoneNumber}`,
            accountId: getAuthBody().accountId,
        };
        smsMutation.mutateAsync(smsBody).then(() => {
            if (onSent) onSent();
        });
    };

    const onSendEmail = (notificationState: NotificationItem, entry: ICashRegisterEntry, onSent?: () => void) => {
        if (!notificationState.email) return;

        let description = "Pago recibido";
        if (entry.referenceNumber) {
            description = `Pago orden #${entry.referenceNumber}`;
        }

        const emailBody: PaymentReceiptInput = {
            id: entry.id ?? "",
            contactEmail: notificationState.email,
            contactName: notificationState.contactName ?? "Estimado cliente",
            amount: entry.amount,
            accountId: getAuthBody().accountId,
            organizationId: props.organization?.id ?? "",
            method: intl.formatMessage(messages[entry.method]),
            description: description,
            paymentNumber: entry.number.toString(),
            trackingNumber: props.trackingNumber ?? "",
            isIncome: entry.isIncome,
        };
        onSendEmailMutation.mutate(emailBody);
    };

    const isLoading = onSendEmailMutation.isLoading || smsMutation.isLoading;

    return { onSendSms, onSendEmail, isLoading };
};
