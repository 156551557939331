import { defineMessages } from "react-intl";

const base = "components.orderChannelForm";

export default defineMessages({
    required: {
        id: `${base}.required`,
        defaultMessage: "Campo requerido",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre del canal",
    },

    createOrderChannel: {
        id: `${base}.createOrderChannel`,
        defaultMessage: "Crear Canal",
    },
});
