import React from "react";
import { ChatContainerView } from "./styled";
import { useChatContext } from "../../../store/contexts/ChatContext";
import ChatMessageCard from "./ChatMessageCard";
import ChatMessageGroupCard from "./ChatMessageGroupCard";
import { useEventListenerWindow } from "../..";
import { useModal } from "../../modals/Modal";
import LoadingDualRing from "../../LoadingDualRing";
import { useMutation } from "react-query";
import { getOrderByNumber } from "../../../api/orders/orderApi";
import OrderProfile from "../../Orders/OrderProfile";
import { Flex } from "../../_controls";

export default function ChatContainer() {
    const { chatState } = useChatContext();
    const modalOrder = useModal();
    const orderNumberMutation = useMutation((number: string) => getOrderByNumber(number));

    useEventListenerWindow("click", (e) => {
        if (e.target.id === "MATCH_HASHTAG") {
            const value = e.target.getAttribute("value");
            orderNumberMutation.mutate(value.replace("#", ""));
            modalOrder.show();
        }
    });

    //if (chatCounter === 0) return null;
    const chatUsers = chatState.activeChats?.length ?? 0;
    const chatGroups = chatState.activeGroupChats?.length ?? 0;
    return (
        <>
            <modalOrder.Modal
                showHeader
                sizeType="md"
                title={orderNumberMutation.isLoading ? `Cargando orden` : `Orden: #${orderNumberMutation.data?.number ?? ""}`}
            >
                {orderNumberMutation.isLoading && <LoadingDualRing center />}
                {!orderNumberMutation.isLoading && orderNumberMutation.data && (
                    <OrderProfile order={orderNumberMutation.data} showSimpleView />
                )}
                {orderNumberMutation.isError && (
                    <Flex padding={20}>
                        <p>No se encontró ningún resultado</p>
                    </Flex>
                )}
            </modalOrder.Modal>
            <ChatContainerView items={chatUsers + chatGroups} className="chat-container">
                {chatState.activeGroupChats?.map((item, index) => (
                    <ChatMessageGroupCard item={item} key={index} />
                ))}
                {chatState.activeChats?.map((item, index) => (
                    <ChatMessageCard item={item} key={index} />
                ))}
            </ChatContainerView>
        </>
    );
}
