import React from "react";
import { NavLink } from "react-router-dom";
import { TRACK_ENDPOINT } from "../../../../api/appConfig";
import { Order } from "../../../../api/models/order";
import PATHS from "../../../../constants/paths";
import { joinUrl } from "../../../../utils";
import { Flex, SquareButton, TextField } from "../../../_controls";
import InvoicePrint from "../../OrderProfile/OrderInvoicePrint";
import { OrderCreatedPreviewStyle } from "../../OrderStyles/styled";

interface OrderCreatedPreviewProps {
    orderResult: Order;
    autoPrint: boolean;
}

export default function OrderCreatedPreview(props: OrderCreatedPreviewProps) {
    return (
        <OrderCreatedPreviewStyle>
            <Flex column>
                <TextField>Orden creada</TextField>
                <TextField bold>#{props.orderResult.number}</TextField>
            </Flex>
            <OrderCreatedActions {...props} />
        </OrderCreatedPreviewStyle>
    );
}

export const OrderCreatedActions = (props: OrderCreatedPreviewProps) => {
    return (
        <Flex gap10 alignCenter>
            <NavLink to={PATHS.orderPosProfile(props.orderResult.id)}>
                <SquareButton>
                    <i className="fa-regular fa-eye"></i>
                </SquareButton>
            </NavLink>
            <a
                href={joinUrl(TRACK_ENDPOINT, props.orderResult.trackingNumber ?? "NA")}
                rel="noopener noreferrer"
                title="Compartir orden"
                target="_blank"
            >
                <SquareButton>
                    <i className="fa-sharp fa-regular fa-share"></i>
                </SquareButton>
            </a>
            <InvoicePrint completeOrder autoOpen={props.autoPrint} order={props.orderResult} />
        </Flex>
    );
};
