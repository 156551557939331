import styled from "styled-components";
import { TableCustomTr } from "../../_controls/tables/styled";

export const ExcelTableContainer = styled.div`
    overflow: inherit;
    max-height: 500px;
    overflow: auto;
`;

export const CustomTr = styled(TableCustomTr)`
    td,
    th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
`;

export const DataInputForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;

    .label-body {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .label-row {
        display: flex;
        align-items: center;
    }

    .input-label {
        padding: 4px 10px;
        border-radius: 50px;
        font-size: 13px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .input-file {
        display: none;
    }
`;

export const DragDropFileContainer = styled.div`
    width: 100%;
    padding: 10px;
    border: 2px dashed ${(props) => props.theme.palette.backgroundDark};
    border-radius: 10px;
    overflow: hidden;
    &.dragover {
        border: 2px dashed ${(props) => props.theme.palette.primary};
        background: ${(props) => props.theme.palette.primary}30;
    }
`;

export const ErrorView = styled.div`
    padding: 20px;
    background: ${(props) => props.theme.palette.dangerColor}30;
    color: ${(props) => props.theme.palette.dangerColor};
    border-radius: 10px;
    .error-title {
        font-weight: 500;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: flex-end;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
`;
