import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { saveData, LocalStoreKeys } from "..";
import { useMutation } from "react-query";
import { IProductSearchModel } from "../../api/models/product";
import { IProductCategory } from "../../api/models/productCategory";
import { getCustomCategories } from "../../api/products/categoryApi";
import { getAuthBody } from "../../auth";

export interface CustomCategoryState {
    items: IProductCategory[];
    isFetching: boolean;
}

export interface ICustomCategoryActions {
    request: () => void;
    add: (value: IProductCategory) => void;
    update: (value: IProductCategory) => void;
    set: (items: IProductCategory[]) => void;
}

interface IContextProps {
    customCategoryState: CustomCategoryState;
    customCategoryActions: ICustomCategoryActions;
}

export const CustomCategoryContext = createContext({} as IContextProps);
const CustomCategoryContextProvider = (props: any) => {
    const [customCategoryState, setCustomCategoryState] = useState<CustomCategoryState>({ items: [], isFetching: false });
    const fetchingRef = useRef(false);

    useEffect(() => {
        saveData(LocalStoreKeys.customCategory, customCategoryState);
    }, [customCategoryState]);

    const mutation = useMutation((data: IProductSearchModel) => getCustomCategories(data));

    const request = () => {
        let body: IProductSearchModel = {
            accountId: getAuthBody().accountId,
            currentPage: 1,
            pageSize: 100,
            sortDesc: true,
            sort: "default",
        };
        if (fetchingRef.current) return;

        fetchingRef.current = true;
        setCustomCategoryState({ ...customCategoryState, isFetching: true });

        mutation
            .mutateAsync(body)
            .then((items) => {
                setCustomCategoryState({ ...customCategoryState, isFetching: false, items });
                fetchingRef.current = false;
            })
            .catch((err) => {
                setCustomCategoryState({ ...customCategoryState, isFetching: false });
                fetchingRef.current = false;
            });
    };
    const add = (value: IProductCategory) => {
        let newItems = [...customCategoryState.items, value];
        setCustomCategoryState({ ...customCategoryState, items: newItems });
    };

    const update = (value: IProductCategory) => {
        let newItems = customCategoryState.items.map((item) => (item.id === value.id ? value : item));
        setCustomCategoryState({ ...customCategoryState, items: newItems });
    };

    const customCategoryActions = {
        request,
        add,
        update,
        set: (items: IProductCategory[]) => {
            setCustomCategoryState({ ...customCategoryState, items });
        },
    };

    return (
        <CustomCategoryContext.Provider value={{ customCategoryState, customCategoryActions }}>
            {props.children}
        </CustomCategoryContext.Provider>
    );
};

export const useCustomCategoryContext = () => {
    return useContext(CustomCategoryContext);
};

export default CustomCategoryContextProvider;
