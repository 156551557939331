import React from 'react';
import { IOrderComment } from '../../../api/models/order';
import { IPurchase } from '../../../api/models/purchase';
import { getAuthBody } from '../../../auth';
import { OrderCommentContainer } from '../../Orders/OrderComments/styled';
import { Flex, TextField } from '../../_controls';
import PurchaseCommentFormRow from './PurchaseCommentFormRow';

interface OrderCommentsProps {
    purchase: IPurchase;
    onUpdatePurchase: (value: IPurchase) => void;
    smallView?: boolean;
}

export const getOrderComment = (description: string, orderId: string) => {
    let comment: IOrderComment = {
        description,
        accountUserId: getAuthBody().accountUserId,
        orderId,
    };
    return comment;
};

export default function PurchaseCommentsSummary(props: OrderCommentsProps) {

    const onRemoveComment = (value: IOrderComment) => {
        let comments = props.purchase.comments?.filter((x) => x.id !== value.id);
        props.onUpdatePurchase({ ...props.purchase, comments });
    };

    const onUpdateComment = (value: IOrderComment) => {
        let exists = props.purchase.comments?.find((x) => x.id === value.id);
        if (exists) {
            let newList: IOrderComment[] = [];
            props.purchase.comments?.forEach((item) => {
                if (item.id === value.id) {
                    newList.push(value);
                } else {
                    newList.push(item);
                }
            });
            props.onUpdatePurchase({ ...props.purchase, comments: newList, commentsCount: newList.length });
        } else {
            let comments = props.purchase.comments ?? [];
            comments = [value, ...comments];
            props.onUpdatePurchase({ ...props.purchase, comments, commentsCount: comments.length });
        }
    };

    return (
        <OrderCommentContainer>
            <Flex column gap={20}>
                <TextField bold>Comentarios</TextField>

                {/* {permission.purchase && permission.purchase.allowCreateComments && ( */}
                    <div className="mb-1">
                        <PurchaseCommentFormRow
                            purchase={props.purchase}
                            onRemove={onRemoveComment}
                            smallView={props.smallView}
                            onUpdate={onUpdateComment}
                            comment={getOrderComment("", props.purchase?.id ?? "")}
                        />
                    </div>
                {/* )} */}
                {props.purchase.comments &&
                    props.purchase.comments.map((item, index) => (
                        <PurchaseCommentFormRow
                            purchase={props.purchase}
                            onRemove={onRemoveComment}
                            smallView={props.smallView}
                            onUpdate={onUpdateComment}
                            comment={item}
                            key={index}
                        />
                    ))}
            </Flex>
        </OrderCommentContainer>
    );
}