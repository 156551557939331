import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import Select from "react-select";
import { useEventListenerWindow } from "../..";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { IPaginationResponse, PaginationResponse } from "../../../api/models";
import { AccountUser } from "../../../api/models/accountUser";
import { IProductCategory } from "../../../api/models/productCategory";
import { PRODUCT_LOG_ORIGIN_LIST, ProductLog, ProductLogOrigin, SearchModelProductLog } from "../../../api/models/productLog";
import { IWarehouse } from "../../../api/models/warehouse";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { CustomCategoryContext } from "../../../store/contexts/CustomCategoryContext";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { getSelectOption } from "../../../utils/select";
import { useAlert } from "../../Alerts/Alert";
import { DatePickerButton } from "../../DatePicker/DatePickerModal";
import Dropdown from "../../Dropdown";
import LoadingProgressBar from "../../Loadings/LoadingProgressBar";
import PaginationView from "../../PaginationView";
import { SelectBody } from "../../Select/styled";
import UserSelector from "../../Users/UserSelector";
import WarehouseSelect from "../../Warehouses/WarehouseSelect";
import { Flex, PanelHeaderContainer, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import ProductCategorySelector from "../ProductCategories/ProductCategorySelector";
import messages from "../messages";
import ProductLogRow from "./ProductLogRow";

export interface ProductLogFilterState {
    rangeSelection: { startDate: Date; endDate: Date };
    showDateRange?: boolean;
    searchValue?: string;
    user?: AccountUser;
    warehouse?: IWarehouse;
    origin?: ProductLogOrigin;
    customCategory?: IProductCategory;
}

interface ProductLogListProps {
    productId?: string;
    warehouseId?: string;
    pageSize?: number;
    hideHeader?: boolean;
    hideHelmet?: boolean;
}
export default function ProductLogList(props: ProductLogListProps) {
    const [state, setState] = useState<ProductLogFilterState>({
        rangeSelection: {
            startDate: customMoment().add(-30, "day").toDate(),
            endDate: customMoment().toDate(),
        },
        showDateRange: true,
        searchValue: "",
        user: undefined,
        warehouse: undefined,
    });
    return <ProductLogListBase {...props} setState={setState} state={state} />;
}

interface ProductLogListBaseProps extends ProductLogListProps {
    state: ProductLogFilterState;
    setState: (state: ProductLogFilterState) => void;
}

export function ProductLogListBase(props: ProductLogListBaseProps) {
    const { state, setState } = props;
    const alert = useAlert();
    const intl = useIntl();
    const [isFetching, setIsFetching] = useState(false);
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);
    const { customCategoryState, customCategoryActions } = useContext(CustomCategoryContext);
    const [pagination, setPagination] = useState<IPaginationResponse<ProductLog>>(new PaginationResponse<ProductLog>());

    const panelRef = React.useRef<HTMLDivElement>(null);
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        handleCalculatePanelWidth();
    }, []);

    useEventListenerWindow("resize", () => {
        handleCalculatePanelWidth();
    });

    const handleCalculatePanelWidth = () => {
        if (panelRef.current) {
            setIsMobileView(panelRef.current.clientWidth < 750);
        }
    };

    useEffect(() => {
        if (accountUserState.items.length > 0) {
            setPagination(getPaginationWithUser(pagination));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items]);

    useEffect(() => {
        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }

        if (customCategoryState.items.length === 0) {
            customCategoryActions.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleSearchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const getPaginationWithUser = (pagination: IPaginationResponse<ProductLog>) => {
        if (pagination.items.length > 0) {
            let newItems: ProductLog[] = [];
            pagination.items.forEach((element) => {
                element.accountUser = accountUserState.items.find((x) => x.id === element.accountUserId);
                newItems.push(element);
            });
            pagination.items = newItems;
            return pagination;
        } else {
            return pagination;
        }
    };

    const handleSearchLogs = (page: number = 1) => {
        setIsFetching(true);
        const searchBody: SearchModelProductLog = {
            accountId: getAuthBody().accountId,
            currentPage: page,
            pageSize: props.pageSize ?? 20,
            searchValue: state.searchValue,
            status: "active",
            onlyProductWithImage: false,
            onlyProductWithoutImage: false,
            accountUserId: state.user?.id,
            warehouseId: props.warehouseId ?? state.warehouse?.id,
            customCategoryId: state.customCategory?.id,
            origin: state.origin,
            dateRangeActive: state.showDateRange,
            dateEnd: state.rangeSelection.endDate,
            dateStart: state.rangeSelection.startDate,
            minutesOffset: new Date().getTimezoneOffset(),
            productId: props.productId,
        };
        wahioFetch.post(
            productEndpoint.post.productLogSearchQuery,
            searchBody,
            (success) => {
                setIsFetching(false);
                const pagination = getPaginationWithUser(success.data);
                setPagination(pagination);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setIsFetching(false);
            }
        );
    };

    return (
        <PanelHeaderContainer ref={panelRef}>
            {!props.hideHelmet && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{intl.formatMessage(messages.movement)} - Wahio</title>
                </Helmet>
            )}

            {!props.hideHeader && (
                <Flex padding={15} flexWrap alignCenter gap10 spaceBetween>
                    <Flex gap10 alignCenter>
                        <TextField bold>Movimientos</TextField>
                        <ProductLogFilterButton {...props} state={state} setState={setState} />
                    </Flex>
                    <PaginationView
                        pagination={pagination}
                        onPageChange={(page) => {
                            handleSearchLogs(page);
                        }}
                    />
                </Flex>
            )}

            <Flex overflowAuto positionRelative column>
                {isFetching && <LoadingProgressBar />}
                {pagination.totalItems === 0 && (
                    <TextField small light className="mt-1">
                        No hay movimientos para mostrar
                    </TextField>
                )}

                {!isMobileView ? (
                    <LogTableList pagination={pagination} isMobileView={isMobileView} />
                ) : (
                    <LogCardList pagination={pagination} isMobileView={isMobileView} />
                )}
            </Flex>
        </PanelHeaderContainer>
    );
}

interface ProductLogFilterProps {
    state: ProductLogFilterState;
    setState: (state: ProductLogFilterState) => void;
    productId?: string;
    warehouseId?: string;
}

export const ProductLogFilterButton = (props: ProductLogFilterProps) => {
    const intl = useIntl();
    const { state, setState } = props;
    return (
        <Flex gap10 alignCenter>
            <DatePickerButton
                w100
                activeRange={
                    state.rangeSelection
                        ? {
                              startDate: customMoment(state.rangeSelection.startDate, "YYYY-MM-DD").toDate(),
                              endDate: customMoment(state.rangeSelection.endDate, "YYYY-MM-DD").toDate(),
                          }
                        : undefined
                }
                onChange={(range) => {
                    setState({
                        ...state,
                        rangeSelection: {
                            startDate: customMoment(range.startDate).toDate(),
                            endDate: customMoment(range.endDate).toDate(),
                        },
                        showDateRange: true,
                    });
                }}
            />
            <Dropdown
                ignoreInternalClicks
                title="Filtro"
                icon="fa-regular fa-filter"
                styleContent={{
                    overflow: "initial",
                }}
            >
                <Flex column gap15 padding={20}>
                    <UserSelector userId={state.user?.id} onChange={(user) => setState({ ...state, user: user })} />
                    {!props.warehouseId && (
                        <WarehouseSelect
                            hideTitle
                            defaultWarehouseId={state.warehouse?.id}
                            onWarehouseChange={(value) => {
                                setState({ ...state, warehouse: value });
                            }}
                        />
                    )}
                    {!props.productId && (
                        <ProductCategorySelector
                            defaultCategoryId={state.customCategory?.id}
                            allowRemove={true}
                            onChangeCategory={(value) => {
                                setState({ ...state, customCategory: value });
                            }}
                        />
                    )}

                    <SelectBody className="select-custom">
                        <Select
                            placeholder="Filtrar Origen"
                            className="select-min-len"
                            classNamePrefix="select"
                            key={state.origin}
                            defaultValue={
                                state.origin
                                    ? getSelectOption({
                                          id: state.origin,
                                          value: `Origen: ${intl.formatMessage(messages[state.origin])}`,
                                      })
                                    : undefined
                            }
                            isDisabled={false}
                            isClearable
                            onChange={(value) => setState({ ...state, origin: value ? (value.id as ProductLogOrigin) : undefined })}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={PRODUCT_LOG_ORIGIN_LIST.map((origin) =>
                                getSelectOption({ id: origin, value: intl.formatMessage(messages[origin]) })
                            )}
                        />
                    </SelectBody>
                </Flex>
            </Dropdown>
        </Flex>
    );
};

export const LogTableList = ({ pagination, isMobileView }: { pagination: IPaginationResponse<ProductLog>; isMobileView: boolean }) => {
    return (
        <Table cellPadding={0} cellSpacing={0}>
            <tbody>
                {pagination.items.map((item, index) => (
                    <ProductLogRow key={index} log={item} isMobileView={isMobileView} />
                ))}
            </tbody>
        </Table>
    );
};

export const LogCardList = ({ pagination, isMobileView }: { pagination: IPaginationResponse<ProductLog>; isMobileView: boolean }) => {
    return (
        <Flex column>
            {pagination.items.map((item, index) => (
                <ProductLogRow key={index} log={item} isMobileView={isMobileView} />
            ))}
        </Flex>
    );
};
