import { defineMessages } from "react-intl";

const base = "components.orders.shipments";

export default defineMessages({
    pending: {
        id: `${base}.pending`,
        defaultMessage: "Pendiente",
    },
    processing: {
        id: `${base}.processing`,
        defaultMessage: "En preparación",
    },
    shipped: {
        id: `${base}.shipped`,
        defaultMessage: "En Camino",
    },
    readyToShip: {
        id: `${base}.readyToShip`,
        defaultMessage: "Preparado",
    },
    picking: {
        id: `${base}.picking`,
        defaultMessage: "Picking",
    },
    packing: {
        id: `${base}.packing`,
        defaultMessage: "Packing",
    },
    delivered: {
        id: `${base}.delivered`,
        defaultMessage: "Entregado",
    },
});
