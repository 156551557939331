import React from "react";
import { NavLink } from "react-router-dom";
import styled, { DefaultTheme, css } from "styled-components";
import SearchInputTimer from "../inputs/SearchInputTimer";
import { device } from "../../../theme/themeProvider";

export const RelativeContainer = styled.div`
    position: relative;
`;

export const GeneralContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

export const ContainerBorder = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 10px;
    padding: 20px;
    margin-top: ${(props: { hideTop?: boolean }) => (props.hideTop ? "0px" : "20px")};
`;

interface FlexProps {
    theme: DefaultTheme;
    column?: boolean;
    columnMobile?: boolean;
    columnDesktop?: boolean;
    row?: boolean;
    alignCenter?: boolean;
    justifyStart?: boolean;
    justifyCenter?: boolean;
    justifyEnd?: boolean;
    spaceBetween?: boolean;
    gap?: number;
    gap5?: boolean;
    gap10?: boolean;
    gap15?: boolean;
    gap20?: boolean;
    flexWrap?: boolean;
    w100?: boolean;
    h100?: boolean;
    mobileW100?: boolean;
    alignStart?: boolean;
    padding?: number;
    paddingLeft?: number;
    paddingTop?: number;
    paddingBottom?: number;
    borderDesktop?: boolean;
    paddingDesktop?: number;
    marginLeftAuto?: boolean;
    maxWidth?: number;
    minWidth?: number;
    maxHeight?: number;
    minHeight?: number;
    maxWidthPercentage?: number;
    minWidthPercentage?: number;
    alignEnd?: boolean;
    paddingRight?: number;
    fitContent?: boolean;
    borderBottom?: boolean;
    borderBottomBlack?: boolean;
    borderTop?: boolean;
    border?: boolean;
    borderBlack?: boolean;
    borderDashed?: boolean;
    borderRadius?: number;
    boxSizingBorderBox?: boolean;
    bgLight?: boolean;
    background?: boolean;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    overflowHidden?: boolean;
    overflowAuto?: boolean;
    overflowXAuto?: boolean;
    positionRelative?: boolean;
    nowrap?: boolean;
    zIndex?: number;
    pointer?: boolean;
    textAlignCenter?: boolean;
    textAlignLeft?: boolean;
    positionAbsolute?: boolean;
    rounded?: number;
    widthPercentage?: number;
    cursorPointer?: boolean;
}

export const Flex = styled.div`
    display: flex;

    ${(props: FlexProps) => (props.column ? "flex-direction: column;" : "")}
    ${(props: FlexProps) => (props.alignCenter ? "align-items: center;" : "")}
    ${(props: FlexProps) => (props.alignStart ? "align-items: flex-start;" : "")}
    ${(props: FlexProps) => (props.justifyCenter ? "justify-content: center;" : "")}
    ${(props: FlexProps) => (props.justifyEnd ? "justify-content: flex-end;" : "")}
    ${(props: FlexProps) => (props.justifyStart ? "justify-content: space-start" : "")}
    ${(props: FlexProps) => (props.spaceBetween ? "justify-content: space-between;" : "")}
    ${(props: FlexProps) => (props.gap5 ? "gap: 5px;" : "")}
    ${(props: FlexProps) => (props.gap10 ? "gap: 10px;" : "")}
    ${(props: FlexProps) => (props.gap15 ? "gap: 15px;" : "")}
    ${(props: FlexProps) => (props.gap20 ? "gap: 20px;" : "")}
    ${(props: FlexProps) => (props.gap ? `gap: ${props.gap}px;` : "")}
    ${(props: FlexProps) => (props.flexWrap ? "flex-wrap: wrap;" : "")}
    ${(props: FlexProps) => (props.w100 ? "width:  100%;" : "")}
    ${(props: FlexProps) => (props.h100 ? "height:  100%;" : "")}
    ${(props: FlexProps) => (props.row ? "flex-direction: row;" : "")}
    ${(props: FlexProps) => (props.paddingDesktop ? `padding: 0;` : "")}
    ${(props: FlexProps) => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : "")}
    ${(props: FlexProps) => (props.padding ? `padding: ${props.padding}px;` : "")}
    ${(props: FlexProps) => (props.paddingLeft ? `padding-left: ${props.paddingLeft}px;` : "")}
    ${(props: FlexProps) => (props.paddingTop ? `padding-top: ${props.paddingTop}px;` : "")}
    ${(props: FlexProps) => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : "")}
    ${(props: FlexProps) => (props.maxWidthPercentage ? `max-width: ${props.maxWidthPercentage}%;` : "")}
    ${(props: FlexProps) => (props.paddingRight ? `padding-right: ${props.paddingRight}px;` : "")}
    ${(props: FlexProps) => (props.alignEnd ? "align-items: flex-end;" : "")}
    ${(props: FlexProps) => (props.fitContent ? "width: fit-content;" : "")}
    ${(props: FlexProps) => (props.minWidth ? `min-width: ${props.minWidth}px;` : "")}
    ${(props: FlexProps) => (props.minWidthPercentage ? `min-width: ${props.minWidthPercentage}%;` : "")}
    ${(props: FlexProps) => (props.borderBottom ? `border-bottom: 1px solid ${props.theme.palette.backgroundDark};` : "")}
    ${(props: FlexProps) => (props.borderBottomBlack ? `border-bottom: 1px solid ${props.theme.palette.backgroundDarker};` : "")}
    ${(props: FlexProps) => (props.borderTop ? `border-top: 1px solid ${props.theme.palette.backgroundDark};` : "")}
    ${(props: FlexProps) => (props.widthPercentage ? `width: ${props.widthPercentage}%;` : "")}
    ${(props: FlexProps) => (props.border ? `border: 1px solid ${props.theme.palette.backgroundDark};` : "")}
    ${(props: FlexProps) => (props.borderBlack ? `border: 1px solid ${props.theme.palette.backgroundDarker};` : "")}
    ${(props: FlexProps) => (props.rounded ? `border-radius: ${props.rounded}px;` : "")}
    ${(props: FlexProps) => (props.borderDashed ? `border: 1px dashed ${props.theme.palette.backgroundDark};` : "")}
    ${(props: FlexProps) => (props.borderRadius ? `border-radius: ${props.borderRadius}px;` : "")}
    ${(props: FlexProps) => (props.borderDesktop ? `border: 0px solid ${props.theme.palette.backgroundDark};` : "")}
    ${(props: FlexProps) => (props.mobileW100 ? `width: 100%;` : "")}

    ${(props: FlexProps) => (props.marginLeftAuto ? `margin-left: auto;` : "")}
    ${(props: FlexProps) => (props.boxSizingBorderBox ? `box-sizing: border-box;` : "")}
    ${(props: FlexProps) => (props.bgLight ? `background: ${props.theme.palette.backgroundLight};` : "")}
    ${(props: FlexProps) => (props.background ? `background: ${props.theme.palette.background};` : "")}
    ${(props: FlexProps) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : "")}
    ${(props: FlexProps) => (props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : "")}
    ${(props: FlexProps) => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : "")}
    ${(props: FlexProps) => (props.marginRight ? `margin-right: ${props.marginRight}px;` : "")}
    ${(props: FlexProps) => (props.overflowHidden ? `overflow: hidden;` : "")}
    ${(props: FlexProps) => (props.columnMobile ? `flex-direction: column;` : "")}
    ${(props: FlexProps) => (props.columnDesktop ? `flex-direction: row;` : "")}
    ${(props: FlexProps) => (props.overflowAuto ? `overflow: auto;` : "")}
    ${(props: FlexProps) => (props.overflowXAuto ? `overflow-x: auto;` : "")}
    ${(props: FlexProps) => (props.positionRelative ? `position: relative;` : "")}
    ${(props: FlexProps) => (props.nowrap ? `white-space: nowrap;` : "")}
    ${(props: FlexProps) => (props.fitContent ? `width: fit-content;` : "")}
    ${(props: FlexProps) => (props.zIndex ? `z-index: ${props.zIndex};` : "")}
    ${(props: FlexProps) => (props.minHeight ? `min-height: ${props.minHeight}px;` : "")}
    ${(props: FlexProps) => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : "")}
    ${(props: FlexProps) => (props.pointer ? `cursor: pointer;` : "")}
    ${(props: FlexProps) => (props.textAlignCenter ? `text-align: center;` : "")}
    ${(props: FlexProps) => (props.textAlignLeft ? `text-align: left;` : "")}
    ${(props: FlexProps) => (props.positionAbsolute ? `position: absolute;` : "")}
    ${(props: FlexProps) => (props.cursorPointer ? `cursor: pointer;` : "")}

    @media ${device.md} {
        ${(props: FlexProps) => (props.columnMobile ? `flex-direction: row;` : "")}
        ${(props: FlexProps) => (props.columnDesktop ? `flex-direction: column;` : "")}
    ${(props: FlexProps) => (props.mobileW100 ? `width: auto;` : "")}
    ${(props: FlexProps) => (props.paddingDesktop ? `padding: ${props.paddingDesktop}px;` : "")}

           ${(props: FlexProps) =>
            props.borderDesktop ? `border: 1px solid ${props.theme.palette.backgroundDark}; border-radius:  15px;` : ""}
    }
`;

export const FlexDropdown = styled(Flex)`
    flex-direction: column;
    gap: 5px;
    .dropdown-title {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 5px;
    }
    button {
        width: 100%;
        border-radius: 10px;
        background: ${(props) => props.theme.palette.background};
        justify-content: start;
    }
`;

export const FlexSticky = styled(Flex)`
    position: sticky;
    top: 0;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 14px 12px;
    background: ${(props) => props.theme.palette.background};
    z-index: 3;
`;

export interface TextBaseProps {
    small?: boolean;
    light?: boolean;
    primaryColor?: boolean;
    bold?: boolean;
    bold600?: boolean;
    fontWeight?: string;
    ellipsis?: boolean;
    marginBottom?: number;
    fontSize?: number;
    nowrap?: boolean;
    color?: string;
    smaller?: boolean;
    cursorPointer?: boolean;
    fontTitle?: boolean;
    hover?: boolean;
    borderBottom?: boolean;
    paddingBottom?: number;
    paddingTop?: number;
    defaultColor?: boolean;
    alignCenter?: boolean;
}
interface TextProps extends TextBaseProps {
    theme: DefaultTheme;
}

const sharedStyleText = css`
    ${(props: TextProps) => (props.small ? "font-size:13px;" : "")};
    ${(props: TextProps) => (props.light ? `color: ${props.theme.palette.textLight}` : "")};
    ${(props: TextProps) => (props.ellipsis ? "text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" : "")};
    ${(props: TextProps) => (props.bold ? "font-weight: 500;" : "")};
    ${(props: TextProps) => (props.bold600 ? "font-weight: 600;" : "")};
    ${(props: TextProps) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : "")};
    ${(props: TextProps) => (props.marginBottom ? `margin-bottom: ${props.marginBottom}px` : "")};
    ${(props: TextProps) => (props.fontSize ? `font-size: ${props.fontSize}px` : "")};
    ${(props: TextProps) => (props.nowrap ? `white-space: nowrap;` : "")};
    ${(props: TextProps) => (props.primaryColor ? `color: ${props.theme.palette.primary};` : "")};
    ${(props: TextProps) => (props.color ? `color: ${props.color};` : "")};
    ${(props: TextProps) => (props.smaller ? `font-size: 10px;` : "")};
    ${(props: TextProps) => (props.cursorPointer ? `cursor: pointer;` : "")};
    ${(props: TextProps) => (props.fontTitle ? `font-size: 20px;` : "")};
    ${(props: TextProps) => (props.borderBottom ? `border-bottom: 1px solid ${props.theme.palette.backgroundLight};` : "")};
    ${(props: TextProps) => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : "")};
    ${(props: TextProps) => (props.paddingTop ? `padding-top: ${props.paddingTop}px;` : "")};
    ${(props: TextProps) => (props.defaultColor ? `color: ${props.theme.palette.text};` : "")};
    ${(props: TextProps) => (props.alignCenter ? `text-align: center;` : "")};

    ${(props: TextProps) =>
        props.hover
            ? `&:hover {
        color: ${props.theme.palette.primary};
    }`
            : ""};
`;

export const TextField = styled.span`
    ${sharedStyleText}
`;

export const Label = styled.label`
    ${sharedStyleText}
`;

const TextLinkBase = styled.div`
    a {
        text-decoration: none;
        color: ${(props: TextProps) => props.theme.palette.text};

        ${(props: TextProps) => (props.small ? "font-size:13px;" : "")};
        ${(props: TextProps) => (props.light ? `color: ${props.theme.palette.textLight}` : "")};
        ${(props: TextProps) => (props.ellipsis ? "text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" : "")};
        ${(props: TextProps) => (props.bold ? "font-weight: 500;" : "")};
        ${(props: TextProps) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : "")};
        ${(props: TextProps) => (props.marginBottom ? `margin-bottom: ${props.marginBottom}px` : "")};
        ${(props: TextProps) => (props.fontSize ? `font-size: ${props.fontSize}px` : "")};
        ${(props: TextProps) => (props.nowrap ? `white-space: nowrap;` : "")};
        ${(props: TextProps) => (props.primaryColor ? `color: ${props.theme.palette.primary};` : "")};
        ${(props: TextProps) => (props.color ? `color: ${props.color};` : "")};

        &:visited {
            color: ${(props: TextProps) => props.theme.palette.text};
            ${(props: TextProps) => (props.primaryColor ? `color: ${props.theme.palette.primary};` : "")};
            ${(props: TextProps) => (props.color ? `color: ${props.color};` : "")};
        }
        &:hover {
            text-decoration: underline;
        }
    }
`;

interface TextLinkProps extends TextBaseProps {
    to: string;
    title: string | React.ReactNode;
}

export const TextLink = (props: TextLinkProps) => {
    return (
        <TextLinkBase {...props} title="">
            <NavLink to={props.to}>{props.title}</NavLink>
        </TextLinkBase>
    );
};

interface FlexImageProps {
    theme: DefaultTheme;
    width?: number;
    borderRadius?: number;
    fitCover?: boolean;
    w100?: boolean;
    aspectRatioAuto?: boolean;
    maxHeight?: number;
    maxWidth?: number;
    bgLight?: boolean;
    mt?: number;
    mb?: number;
    shadowHover?: boolean;
    padding?: number;
    pointer?: boolean;
    scaleHover?: boolean;
}

export const FlexImageStyle = styled.div`
    display: flex;
    overflow: hidden;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    position: relative;

    ${(props: FlexImageProps) => (props.width ? `width: ${props.width}px; min-width: ${props.width}px;` : "width: 50px; min-width: 50px;")}
    ${(props: FlexImageProps) => (props.w100 ? `width: 100%;` : "")}
    ${(props: FlexImageProps) => (props.borderRadius ? `border-radius: ${props.borderRadius}px;` : "")}
    ${(props: FlexImageProps) => (props.aspectRatioAuto ? `aspect-ratio: 0;` : "")}
    ${(props: FlexImageProps) => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : "")}
    ${(props: FlexImageProps) => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : "")}
    ${(props: FlexImageProps) => (props.bgLight ? `background: ${props.theme.palette.backgroundLight};` : "")}
    ${(props: FlexImageProps) => (props.mt ? `margin-top: ${props.mt}px;` : "")}
    ${(props: FlexImageProps) => (props.mb ? `margin-bottom: ${props.mb}px;` : "")}
    ${(props: FlexImageProps) => (props.padding ? `padding: ${props.padding}px;` : "")}
    ${(props: FlexImageProps) => (props.pointer ? `cursor:pointer;` : "")}

    &:hover {
        ${(props: FlexImageProps) => (props.scaleHover ? `transform: scale(1.1);` : "")}
        cursor: pointer;
    }

    ${(props: FlexImageProps) =>
        props.shadowHover
            ? ` &:hover { 
            box-shadow: 0px 0px 10px 0px ${props.theme.palette.shadowColor};
            border-radius: 10px;
        }`
            : ""}

    img {
        width: 100%;
        height: 100%;
        ${(props: FlexImageProps) => (props.fitCover ? `object-fit: cover;` : "object-fit: contain;")}
    }
`;

export const FlexImageLetterStyle = styled.div`
    display: flex;
    overflow: hidden;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.text};
    font-size: 13px;
    font-weight: 500;

    .bg-light {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

interface GridTemplateProps {
    theme: DefaultTheme;
    size?: number;
    sizeLarge?: number;
    gap?: number;
    bgLight?: boolean;
    padding?: number;
    w100?: boolean;
}

export const GridTemplate = styled.div`
    display: grid;
    grid-gap: ${(props: GridTemplateProps) => (props.gap ? `${props.gap}px` : "15px")};
    grid-template-columns: repeat(auto-fill, minmax(${(props: GridTemplateProps) => (props.size ? `${props.size}px` : "200px")}, 1fr));
    grid-auto-rows: min-content;
    box-sizing: border-box;

    ${(props: GridTemplateProps) => (props.bgLight ? `background: ${props.theme.palette.backgroundLight};` : ``)}
    ${(props: GridTemplateProps) => (props.padding ? `padding:  ${props.padding}px;` : ``)}
    ${(props: GridTemplateProps) => (props.w100 ? `width:  100%;` : ``)}

    @media ${device.xxl2} {
        ${(props: GridTemplateProps) =>
            props.sizeLarge ? `grid-template-columns: repeat(auto-fill, minmax(${props.sizeLarge}px, 1fr))` : ""}
    }
`;

export const Title = styled.h3`
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
    margin-left: 4px;

    @media (max-width: 700px) {
        &.hide-title-mb {
            display: none;
        }
    }
`;
export const ToolbarView = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    padding: 10px 10px;
    background: ${(props) => props.theme.palette.background};
    flex-direction: column;

    &.border-none {
        border: 0px;
    }

    &.remove-padding {
        padding: 0px;
    }
    flex-wrap: wrap;
    z-index: 2;

    .filter-options {
        display: none;
        &.show {
            display: flex;
        }
    }

    .filter {
        display: flex;
        &.active {
            background: ${(props) => props.theme.palette.primary}20;
            color: ${(props) => props.theme.palette.primary};
        }
        .alert-counter {
            position: absolute;
            background: ${(props) => props.theme.palette.primary};
            color: white;
            top: -8px;
            right: -6px;
            padding: 0 4px;
            font-size: 12px;
            border-radius: 10px;
        }
    }

    @media ${device.md} {
        .filter-options {
            display: flex;
        }
        .filter {
            display: none;
        }
    }
`;

export const ToolbarItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    &.mobile-width {
        .search-input {
            max-width: 100%;
        }
        width: 100%;
    }
    .filter {
        display: flex;
    }

    @media ${device.md} {
        .filter {
            display: none;
        }
        &.mobile-width {
            .search-input {
                max-width: 400px;
            }
            width: auto;
        }
    }
`;

export const SearchCustom = styled(SearchInputTimer)`
    border-radius: 50px;
`;

export const AllContainer = styled.div``;

export const PanelHeaderContainer = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    width: 100%;
`;
