import { defineMessages } from "react-intl";

const base = "components.createCustomer";

export default defineMessages({
    label: {
        id: `${base}.label`,
        defaultMessage: "Etiqueta",
    },
    email: {
        id: `${base}.email`,
        defaultMessage: "Correo electrónico",
    },
    address: {
        id: `${base}.address`,
        defaultMessage: "Dirección",
    },
    phone: {
        id: `${base}.phone`,
        defaultMessage: "Teléfono",
    },
    firstName: {
        id: `${base}.firstName`,
        defaultMessage: "Nombre (Requerido)",
    },
    lastName: {
        id: `${base}.lastName`,
        defaultMessage: "Apellido",
    },
    identificationNumber: {
        id: `${base}.identificationNumber`,
        defaultMessage: "Identificación",
    },
    verificationNumber: {
        id: `${base}.verificationNumber`,
        defaultMessage: "Dígito de Verificación",
    },
    identificationType: {
        id: `${base}.identificationType`,
        defaultMessage: "Tipo de identificación",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    okay: {
        id: `${base}.okay`,
        defaultMessage: "Aceptar",
    },
    createContact: {
        id: `${base}.createCustomer`,
        defaultMessage: "Crear Contacto",
    },
    customerCreatedSuccessfully: {
        id: `${base}.customerCreatedSuccessfully`,
        defaultMessage: "El cliente fue creado con éxito",
    },
    errorSavingCustomer: {
        id: `${base}.errorSavingCustomer`,
        defaultMessage: "Hubo un error al guardar el cliente.",
    },
    personType: {
        id: `${base}.personType`,
        defaultMessage: "Tipo de Persona",
    },
    typeRegime: {
        id: `${base}.typeRegime`,
        defaultMessage: "Tipo de Régimen",
    },
    typeLiability: {
        id: `${base}.typeLiability`,
        defaultMessage: "Tipo Responsabilidad",
    },
    merchantRegistration: {
        id: `${base}.merchantRegistration`,
        defaultMessage: "Mercantil Nro",
    },
    editContact: {
        id: `${base}.editContact`,
        defaultMessage: "Editar Contacto",
    },
    clickAddAddress: {
        id: `${base}.clickAddAddress`,
        defaultMessage: "Haz clic aquí para agregar una dirección",
    },
    addressTwo: {
        id: `${base}.addressTwo`,
        defaultMessage: "Dirección",
    },
    addressThree: {
        id: `${base}.addressThree`,
        defaultMessage: "Dirección",
    },
    addressFour: {
        id: `${base}.addressFour`,
        defaultMessage: "Dirección",
    },
    contactName: {
        id: `${base}.contactName`,
        defaultMessage: "Nombre de contacto",
    },
    contactNameTwo: {
        id: `${base}.contactNameTwo`,
        defaultMessage: "Nombre de contacto",
    },
    phoneNumber: {
        id: `${base}.phoneNumber`,
        defaultMessage: "Número de contacto",
    },
    phoneNumberTwo: {
        id: `${base}.phoneNumberTwo`,
        defaultMessage: "Número de contacto",
    },
    state: {
        id: `${base}.state`,
        defaultMessage: "Departamento",
    },
    toSelect: {
        id: `${base}.toSelect`,
        defaultMessage: "Seleccionar",
    },
    toSelectTwo: {
        id: `${base}.toSelectTwo`,
        defaultMessage: "Seleccionar",
    },
    city: {
        id: `${base}.city`,
        defaultMessage: "Ciudad",
    },
    additionalData: {
        id: `${base}.additionalData`,
        defaultMessage: "Datos adicionales",
    },
    towerFloor: {
        id: `${base}.towerFloor`,
        defaultMessage: "Torre, piso...",
    },
    neighborhood: {
        id: `${base}.neighborhood`,
        defaultMessage: "Barrio",
    },
    neighborhoodTwo: {
        id: `${base}.neighborhoodTwo`,
        defaultMessage: "Barrio",
    },
    otherDirection: {
        id: `${base}.otherDirection`,
        defaultMessage: "Otra Dirección",
    },
    personalInformation: {
        id: `${base}.personalInformation`,
        defaultMessage: "Datos Personales",
    },
    clientUpdated: {
        id: `${base}.clientUpdated`,
        defaultMessage: "Cliente Actualizado con éxito",
    },
    errorUpdateClient: {
        id: `${base}.errorUpdateClient`,
        defaultMessage: "Hubo un error al intentar actualizar el cliente",
    },
    notPermission: {
        id: `${base}.notPermission`,
        defaultMessage: "No tienes permiso para crear contactos",
    },
    notUpdate: {
        id: `${base}.notUpdate`,
        defaultMessage: "No tienes permiso para actualizar contactos",
    },
    optionalFields: {
        id: `${base}.optionalFields`,
        defaultMessage: "Campos Opcionales",
    },
    invoiceElectronic: {
        id: `${base}.invoiceElectronic`,
        defaultMessage: "Campos para Fáctura Electronica",
    },
    birthday: {
        id: `${base}.birthday`,
        defaultMessage: "Fecha de cumpleaños",
    },
});
