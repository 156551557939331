import React, { useContext, useEffect, useRef, useState } from "react";
import { OrderFormOrigin } from "..";
import { useEventListenerWindow } from "../../..";
import { NON_ORDER_ITEM_UPDATE_STATUS_LIST } from "../../../../api/models/deliveryOrder";
import { Order, OrderItem } from "../../../../api/models/order";
import { ITax } from "../../../../api/models/tax";
import { useAppSettingsContext } from "../../../../appSettings/AppSettingsContext";
import { useClientSettings } from "../../../../store/contexts/ClientSettingsContext";
import { getTimeIntervalSeconds } from "../../../../utils";
import { Flex, SquareButton, TextField } from "../../../_controls";
import { TableCustomTr } from "../../../_controls/tables/styled";
import OrderItemRow from "../../OrderItemRow";
import { CardsContainer, TableCustomOrder } from "../../OrderStyles/styled";
import { FlexItemsView } from "../styled";
import OrderCreatedPreview from "./OrderCreatedPreview";
import { OrderItemsConfigModal } from "./OrderItemsConfig";
import { UserContext } from "../../../../store/contexts/UserContext";
import { getUserPermissions } from "../../../../api/models/accountUser";
import PopoverRadix from "../../../_controls/PopoverRadix";

export interface OrderSummaryProps {
    items: OrderItem[];
    order: Order;
    onUpdateOrder: (order: Order) => void;
    forceMobileView?: boolean;
    hideLocationCode: boolean;
    onUpdateItem: (item: OrderItem, index: number) => void;
    onRemoveItem: (index: number) => void;
    updateExistingItemsPrices: () => void;
    availableTaxes: ITax[];
    onUpdateItems: (items: OrderItem[]) => void;
    orderResult?: Order;
    isMobile: boolean;
    origin?: OrderFormOrigin;
}

interface OrderSummaryState {
    lastQuantity: number;
}

export default function OrderItemsSummary(props: OrderSummaryProps) {
    const { appSettingsState, appSettingsActions } = useAppSettingsContext();

    const [state, setState] = useState<OrderSummaryState>({
        lastQuantity: 0,
    });

    const { getStatusItem } = useClientSettings();

    const panelRef = useRef<any>();
    const timerRef = useRef<any>();

    const [mobileState, setMobileState] = useState({
        panelIsMobile: false,
        panelIsSmallMobile: false,
    });

    let requireMobile = mobileState.panelIsMobile;
    if (appSettingsState.orderSummaryDesktopSimpleView || props.isMobile || props.origin === "restaurant") {
        requireMobile = true;
    }

    useEffect(() => {
        if (props.items.length > state.lastQuantity) {
            let element = panelRef.current;
            if (element && props.origin !== "restaurant") {
                element.scrollTop += 1500;
            }
            setState({ ...state, lastQuantity: props.items.length });
        } else {
            setState({ ...state, lastQuantity: props.items.length });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items]);

    useEffect(() => {
        handleCalculateMobile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCalculateMobile = () => {
        if (panelRef.current) {
            let rect = panelRef.current?.getBoundingClientRect();
            let newMobile = rect.width < 600;
            let smallMobile = rect.width < 425;
            setMobileState({ ...mobileState, panelIsMobile: newMobile, panelIsSmallMobile: smallMobile });
        }
    };

    const handleUpdateMobile = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => handleCalculateMobile(), 100);
    };

    const onChangeItemsView = () => {
        appSettingsActions.setAllSettings({
            ...appSettingsState,
            orderSummaryDesktopSimpleView: !appSettingsState.orderSummaryDesktopSimpleView,
        });
    };

    useEventListenerWindow("resize", () => {
        handleUpdateMobile();
    });

    const deliveryOrder = props.order.deliveryOrder;
    const disabledItemsEdit = deliveryOrder && NON_ORDER_ITEM_UPDATE_STATUS_LIST.includes(deliveryOrder.status);

    return (
        <CardsContainer className={`summary-container ${disabledItemsEdit ? "disabled-edit" : ""}`} ref={panelRef}>
            {deliveryOrder && NON_ORDER_ITEM_UPDATE_STATUS_LIST.includes(deliveryOrder.status) && (
                <Flex gap10 alignCenter padding={15} paddingBottom={0}>
                    <span className={"fa-regular fa-lock"}></span>
                    <Flex column>
                        <TextField bold>Estado de Envió: {getStatusItem(deliveryOrder.status).title}</TextField>
                        <TextField small>No se pueden actualizar los items de la orden, porque los productos ya fuero procesados</TextField>
                    </Flex>
                </Flex>
            )}
            {props.orderResult && <OrderCreatedPreview orderResult={props.orderResult} autoPrint={appSettingsState.autoPrintOrder} />}
            {!props.isMobile && (
                <Flex paddingLeft={20} paddingRight={20} paddingTop={15} alignCenter gap15 spaceBetween>
                    <Flex gap10 alignCenter>
                        <TextField bold fontSize={17}>
                            Detalles
                        </TextField>
                    </Flex>
                    <Flex gap10 alignCenter>
                        <Flex alignEnd justifyEnd>
                            <OrderItemsConfigModal onUpdateAllItem={props.onUpdateItems} currentOrder={props.order} />
                        </Flex>

                        {!mobileState.panelIsMobile && (
                            <FlexItemsView>
                                <SquareButton
                                    colorText
                                    onClick={onChangeItemsView}
                                    className={!appSettingsState.orderSummaryDesktopSimpleView ? "active" : ""}
                                >
                                    <span className="fa-regular fa-list"></span>
                                </SquareButton>
                                <SquareButton
                                    colorText
                                    onClick={onChangeItemsView}
                                    className={appSettingsState.orderSummaryDesktopSimpleView ? "active" : ""}
                                >
                                    <span className="fa-regular fa-grid-2"></span>
                                </SquareButton>
                            </FlexItemsView>
                        )}
                    </Flex>
                </Flex>
            )}

            {props.items.length === 0 && (
                <Flex padding={20} column gap10 alignCenter>
                    <TextField light small>
                        Selecciona un producto para agregarlo a la orden
                    </TextField>
                </Flex>
            )}
            {requireMobile && <SummaryCards {...props} origin={props.origin} smallMobile={mobileState.panelIsSmallMobile} />}
            {!requireMobile && <SummaryRows {...props} smallMobile={mobileState.panelIsSmallMobile} />}
            {props.order.items.length > 0 && (
                <TextField small light style={{ marginLeft: "20px", marginBottom: "20px", marginTop: "10px" }}>
                    Items: {props.order.items.length} Cant: {props.order.itemsQuantityCount}
                </TextField>
            )}
        </CardsContainer>
    );
}

interface SummaryItemsProps extends OrderSummaryProps {
    smallMobile: boolean;
}

export const SummaryCards = (props: SummaryItemsProps) => {
    if (props.origin === "restaurant") return <SummaryCardsRestaurant {...props} />;
    return (
        <Flex paddingTop={10} column>
            {props.items.map((item, index) => {
                return (
                    <OrderItemRow
                        hideSerials={props.order.isDelivery}
                        isMobile={true}
                        isSmallMobile={props.smallMobile}
                        orderItem={item}
                        key={index + item.productId}
                        origin={props.origin}
                        hideLocationCode={props.hideLocationCode}
                        onUpdateItem={(item) => props.onUpdateItem(item, index)}
                        onRemoveItem={() => props.onRemoveItem(index)}
                        availableTaxes={props.availableTaxes}
                    />
                );
            })}
        </Flex>
    );
};

const SummaryCardsRestaurant = (props: SummaryItemsProps) => {
    const items = props.origin === "restaurant" ? [...props.items].reverse() : props.items;
    let lastDate: Date | string | undefined = "";
    const totalItems = items.length;
    return (
        <Flex paddingTop={10} column>
            {items.map((item, inverseIndex) => {
                let dateTitle: string | undefined;
                if (getTimeIntervalSeconds(item.createdAt, lastDate) > 120) {
                    dateTitle = item.createdAt?.toString();
                }
                lastDate = item.createdAt;
                const index = totalItems - (inverseIndex + 1);

                return (
                    <OrderItemRow
                        hideSerials={props.order.isDelivery}
                        isMobile={true}
                        dateTitle={dateTitle}
                        isSmallMobile={props.smallMobile}
                        orderItem={item}
                        key={index + item.productId}
                        origin={props.origin}
                        hideLocationCode={props.hideLocationCode}
                        onUpdateItem={(item) => props.onUpdateItem(item, index)}
                        onRemoveItem={() => props.onRemoveItem(index)}
                        availableTaxes={props.availableTaxes}
                    />
                );
            })}
        </Flex>
    );
};

export const SummaryRows = (props: SummaryItemsProps) => {
    const { userState } = useContext(UserContext)
    if (props.items.length === 0) return null;
    const permission = getUserPermissions(userState.user).order;

    return (
        <TableCustomOrder cellSpacing="0" cellPadding="0">
            <thead>
                <TableCustomTr>
                    <th></th>
                    <th></th>
                    <th>Nombre</th>
                    <th>Cant</th>
                    <th>
                        <Flex gap10 alignCenter>
                            <TextField>Precio</TextField>
                            {!permission?.orderFormAllowEditPrice &&
                                <PopoverRadix
                                    trigger={
                                        <SquareButton small removeBackground >
                                            <TextField light className="fa-regular fa-ban" />
                                        </SquareButton>
                                    }
                                >
                                    <TextField small>No tienes permisos para modificar precios</TextField>
                                </PopoverRadix>
                            }
                        </Flex>
                    </th>
                    <th>Total</th>
                </TableCustomTr>
            </thead>
            <tbody>
                {props.items.map((item, index) => (
                    <OrderItemRow
                        hideSerials={props.order.isDelivery}
                        orderItem={item}
                        isMobile={false}
                        isSmallMobile={false}
                        key={item.productId + item.productVariantId}
                        hideLocationCode={props.hideLocationCode}
                        onUpdateItem={(item) => props.onUpdateItem(item, index)}
                        onRemoveItem={() => props.onRemoveItem(index)}
                        availableTaxes={props.availableTaxes}
                    />
                ))}
            </tbody>
        </TableCustomOrder>
    );
};
