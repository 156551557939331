import styled from "styled-components";
import { Table } from "../../_controls/tables/styled";

export const FlexItemsView = styled.div`
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #83838340;
    box-sizing: border-box;

    button {
        border-radius: 0;
        background: transparent;
        &.active {
            color: ${(props) => props.theme.palette.primary};
            background: ${(props) => props.theme.palette.primary}20;
        }
    }
`;

export const OrderShipmentConfirmationStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 30px;

    .total-card {
        padding: 10px;
        background: ${(props) => props.theme.palette.backgroundLight};
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 12px;
        .total {
            font-weight: 500;
            font-size: 16px;
        }
    }
    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    p {
        margin: 0px;
    }

    .btn-actions {
        display: flex;
        margin-top: 20px;
        align-items: center;
        justify-content: flex-end;
        button {
            width: 100%;
        }
    }
`;

export const DeliveryOrderLineStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    z-index: 1;
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        width: 31px;
        height: 31px;
        font-size: 12px;
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.primary};
        border: 3px solid ${(props) => props.theme.palette.background};
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .col {
        display: grid;
        background: ${(props) => props.theme.palette.background};
        font-size: 13px;
    }
    .pr-1 {
        padding-right: 10px;
    }
    .pl-1 {
        padding-left: 10px;
    }

    .line {
        position: absolute;
        height: 2px;
        width: 100%;
        background: ${(props) => props.theme.palette.backgroundDark};
        z-index: -1;
    }
`;

export const OrderGridContainer = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: min-content;
    box-sizing: border-box;
`;

export const OrderDetailsContainer = styled(OrderGridContainer)`
    .delivery-body {
        padding: 12px 20px;
        border-radius: 15px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};

        .btn-delivery {
            color: ${(props) => props.theme.palette.green};
            background: ${(props) => props.theme.palette.green}20;
            border: 0;
            transition: background 0.2s ease-in-out;
            &:hover {
                background: ${(props) => props.theme.palette.green}40;
            }
        }

        .btn-cost {
            padding-left: 0px;
            padding-right: 0px;
            transition: padding 0.2s ease-in-out, background 0.2s ease-in-out, color 0.2s ease-in-out;
            &:hover {
                padding-left: 10px;
                padding-right: 10px;
                background: ${(props) => props.theme.palette.primary}20;
                color: ${(props) => props.theme.palette.primary};
            }
        }
        .delivery-table {
            td {
                font-size: 13px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 0;

                :first-child {
                    width: 45px;
                }
            }
        }
    }

    .select__control {
        background-color: transparent;
    }

    .item-active {
        .select__control {
            background-color: ${(props) => props.theme.palette.backgroundLight};
            border-color: transparent;
            font-weight: 500;
            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark}60;
                border-color: ${(props) => props.theme.palette.backgroundDark};
            }
        }
    }
    .item-card {
        background: ${(props) => props.theme.palette.backgroundLight};
        padding: 10px 13px;
        border-radius: 15px;
    }

    .title {
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
    }
    .icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        justify-content: center;
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const SelectWarehouseOrder = styled.div`
    width: fit-content;
    padding-left: 10px;
`;

export const AlertNumberLabel = styled.div`
    background: #0091ff;
    color: white;
    position: absolute;
    top: -4px;
    right: -15%;
    padding: 1px 4px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 11px;
`;

export const CardPercentage = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    padding: 8px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    .right {
        margin-left: auto;
    }
    &.active {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        text {
            color: white;
        }
    }
    .selection {
        width: 13px;
        height: 13px;
        border-radius: 15px;
        background: ${(props) => props.theme.palette.backgroundDark};
        &.active {
            background: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const DeliveryCostLine = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: ${(props) => props.theme.palette.background};

    input {
        border: 0;
        border-radius: 0;
        background: transparent;
    }
    .description {
        width: 130px;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
    .amount {
        width: 100%;
        font-weight: 600;
    }
`;
export const DropdownOrderTypeStyle = styled.div`
    .dropdown-btn {
        border: 0;
    }
    &.shipment {
        .dropdown-btn {
            color: #00bfa5;
            background: #00bfa520;
        }
    }
    &.sale {
        .dropdown-btn {
            color: #304ffe;
            background: #304ffe20;
        }
    }
    &.quotation {
        .dropdown-btn {
            color: #5e35b1;
            background: #5e35b120;
        }
    }
`;

export const InputTipContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .active-line {
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};
        font-weight: 600;
    }

    .icon {
        color: ${(props) => props.theme.palette.textLight};
    }

    .textbox-numeral {
        padding: 0;
        transition: padding 0.3s ease-in-out;
        width: 100%;
        border: 0;
        font-weight: 500;
        &:focus {
            padding: 0px 0px;
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    button {
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.background};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    .percentage {
        width: 50px;
        text-align: center;
    }

    &.active {
        .textbox-numeral {
            color: #304ffe;
        }
    }
`;

export const TotalTableStyle = styled(Table)`
    td {
        :nth-child(2) {
            font-weight: 500;
        }
    }
    td {
        :first-child {
            text-align: left;
        }
        :last-child {
            text-align: right;
        }
    }

    .padding-5 {
        td {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
`;

export const TotalContainer = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    max-width: 500px;
    overflow: auto;
    box-sizing: border-box;

    .total-values-container {
        background: ${(props) => props.theme.palette.background};
        padding-top: 20px;
        position: sticky;
        bottom: 0;
    }
    .action-btn-container {
        width: 100%;
        gap: 10px;
        justify-content: end;
        button {
            border-radius: 10px;
            font-size: 16px;
        }
        .btn-details {
            width: auto;
        }
        &.mobile {
            button {
                font-size: 15px;
            }
        }
    }

    &.mobile {
        .total-values-container {
            border-top: 0px dashed ${(props) => props.theme.palette.backgroundDark};
            padding-top: 0px;
            margin-top: 0px;
            position: relative;
        }
        .action-btn-container {
            button {
                width: 100%;
            }
            .btn-details {
                width: auto;
            }
        }
    }
`;

export const CreateOrderFormPanel = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

export const CreateOrderContainer = styled.div`
    position: relative;
    display: flex;
    height: 100%;

    &.full-screen {
        position: fixed;
        z-index: 5;
        left: 0;
        width: 100%;
        right: 0;
        box-sizing: border-box;
        background: ${(props) => props.theme.palette.background};
    }

    .display-block {
        display: block;
    }
    .display-none {
        display: none;
    }
    .order-panel {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 15px;
        height: calc(100vh - 95px);
    }
    &.origin-restaurant {
        .order-panel {
            height: calc(100vh - 115px);
        }
    }

    &.using-modal {
        .order-panel {
            height: calc(100vh - 115px);
        }
    }

    @media (max-width: 710px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 0px;
        gap: 1px;
        &.using-modal {
            .order-panel {
                height: auto;
            }
        }
        .order-panel {
            height: auto;
        }
        &.origin-restaurant {
            .order-panel {
                height: auto;
            }
        }
        .total-container {
            height: auto;
            max-height: auto;
            border-radius: 0;
            max-width: 100%;
            position: sticky;
            top: 0;
            overflow: initial;
            border: 0;
        }
        .summary-container {
            height: auto;
            max-height: auto;
            border-radius: 0;
            border: 0;
        }
    }
`;

export const CoverPanelLoading = styled.div`
    background: ${(props) => props.theme.palette.background98};
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 151;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    :after {
        content: "Cargando...";
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
        font-weight: 500;
    }
`;
