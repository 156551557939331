import { Dispatch } from "react";
import {
    AddOrderChannel,
    ADD_ORDER_CHANNEL,
    OrderChannelTypes,
    IOrderChannel,
    IOrderChannelState,
    InvalidRequest,
    INVALID_REQUEST_ORDER_CHANNELS,
    ReceiveOrderChannels,
    RECEIVE_ORDER_CHANNELS,
    RequestOrderChannels,
    REQUEST_ORDER_CHANNELS,
    UpdateOrderChannel,
    UPDATE_ORDER_CHANNEL,
} from "../../api/models/orderChannel";

export interface IOrderChannelActions {
    request: () => void;
    receive: (items: IOrderChannel[]) => void;
    fetchInvalid: (error: any) => void;
    add: (orderChannel: IOrderChannel) => void;
    update: (orderChannel: IOrderChannel) => void;
}

export const actionRequest = (): RequestOrderChannels => {
    return {
        type: REQUEST_ORDER_CHANNELS,
    };
};

export const actionReceive = (items: IOrderChannel[]): ReceiveOrderChannels => {
    return {
        type: RECEIVE_ORDER_CHANNELS,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_ORDER_CHANNELS,
        value: er,
    };
};

export const ActionAdd = (orderChannel: IOrderChannel): AddOrderChannel => {
    return {
        type: ADD_ORDER_CHANNEL,
        value: orderChannel,
    };
};

export const ActionUpdate = (orderChannel: IOrderChannel): UpdateOrderChannel => {
    return {
        type: UPDATE_ORDER_CHANNEL,
        value: orderChannel,
    };
};

export const useActions = (state: IOrderChannelState, dispatch: Dispatch<OrderChannelTypes>): IOrderChannelActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: IOrderChannel[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: IOrderChannel) => {
        dispatch(ActionAdd(value));
    },
    update: (value: IOrderChannel) => {
        dispatch(ActionUpdate(value));
    },
});
