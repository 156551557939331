import styled from "styled-components";

export const ImageContainerCover = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 9px;
    overflow: hidden;
    position: relative;

    &.cover-empty {
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    &.image-container {
        max-height: 200px;
    }

    input {
        display: none;
    }

    &:hover {
        .cover-view {
            visibility: visible;
        }
    }

    &.square {
        width: 100px;
        height: 100px;
        &.large {
            width: 200px;
            height: 200px;
        }
    }

    &.landscape {
        width: 100%;
        height: 100px;
        &.large {
            height: 200px;
        }
    }
    .cover-view {
        visibility: hidden;
        position: absolute;
        background: #1d1d1d52;
        width: 100%;
        text-align: center;
        align-content: center;
        vertical-align: middle;
        display: grid;
        top: 0;
        padding: 0 !important;
        font-size: 2rem;
        bottom: 0;
        border: 0;
        color: white;
        cursor: pointer;
    }
`;

export const EditableImageContainer = styled.div`
    padding: 0px;
    &.height-100 {
        height: 100%;
    }
`;

export const VideoContainer = styled.div`
    padding: 20px;
    .input-link {
        width: 100%;
    }
    .btn-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }

    .iframe-container {
        background: ${(props) => props.theme.palette.backgroundDark};
        height: 245px;
        margin-top: 21px;
        border-radius: 10px;
        overflow: hidden;
        iframe {
            border: 0px;
            width: 100%;
            height: 100%;
        }
    }
`;

export const ImageProfileCover = styled(ImageContainerCover)`
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 100px;
`;

export const ImageCover = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export const ImageContain = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`;

export const ButtonActionsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 11px;
    grid-gap: 11px;
    justify-content: center;
`;
