import React, { useContext } from "react";
import { getUserPermissions } from "../../../api/models/accountUser";
import { IOrderComment, Order } from "../../../api/models/order";
import { getAuthBody } from "../../../auth";
import { UserContext } from "../../../store/contexts/UserContext";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { Flex } from "../../_controls";
import { OrderNavigationProps } from "../OrderProfile";
import OrderCommentRow from "./OrderCommentFormRow";
import { OrderCommentContainer } from "./styled";

interface OrderCommentsProps {
    order: Order;
    onUpdateOrder: (value: Order) => void;
    smallView?: boolean;
}

export const getOrderComment = (description: string, orderId: string) => {
    let comment: IOrderComment = {
        description,
        accountUserId: getAuthBody().accountUserId,
        orderId,
    };
    return comment;
};

export default function OrderCommentsSummary(props: OrderCommentsProps) {
    const { userState } = useContext(UserContext);
    const permission = getUserPermissions(userState.user);

    const onRemoveComment = (value: IOrderComment) => {
        let comments = props.order.comments?.filter((x) => x.id !== value.id);
        props.onUpdateOrder({ ...props.order, comments });
    };

    const onUpdateComment = (value: IOrderComment) => {
        let exists = props.order.comments?.find((x) => x.id === value.id);
        if (exists) {
            let newList: IOrderComment[] = [];
            props.order.comments?.forEach((item) => {
                if (item.id === value.id) {
                    newList.push(value);
                } else {
                    newList.push(item);
                }
            });
            props.onUpdateOrder({ ...props.order, comments: newList, commentsCount: newList.length });
        } else {
            let comments = props.order.comments ?? [];
            comments = [value, ...comments];
            props.onUpdateOrder({ ...props.order, comments, commentsCount: comments.length });
        }
    };

    return (
        <OrderCommentContainer>
            <Flex column gap={20}>
                {permission.order && permission.order.allowCreateComments && (
                    <div className="mb-1">
                        <OrderCommentRow
                            order={props.order}
                            onRemove={onRemoveComment}
                            smallView={props.smallView}
                            onUpdate={onUpdateComment}
                            comment={getOrderComment("", props.order?.id ?? "")}
                        />
                    </div>
                )}
                {props.order.comments &&
                    props.order.comments.map((item, index) => (
                        <OrderCommentRow
                            order={props.order}
                            onRemove={onRemoveComment}
                            smallView={props.smallView}
                            onUpdate={onUpdateComment}
                            comment={item}
                            key={index}
                        />
                    ))}
            </Flex>
        </OrderCommentContainer>
    );
}

export const NavigationOrderComments = (props: OrderNavigationProps) => {
    return (
        <NavigationCard title="Comentarios">
            <OrderCommentsSummary order={props.order} onUpdateOrder={props.onOrderChange} />
        </NavigationCard>
    );
};
