const customNumeral = require("numeral");

export const loadDefaultNumeralSymbol = (moneySymbol) => {
    customNumeral.locales["col"] = undefined;

    customNumeral.register("locale", "col", {
        delimiters: {
            thousands: ".",
            decimal: ",",
        },
        abbreviations: {
            thousand: "k",
            million: "m",
            billion: "b",
            trillion: "t",
        },
        ordinal: function (number) {
            return number === 1 ? "er" : "ème";
        },
        currency: {
            symbol: moneySymbol ? moneySymbol : "$",
        },
    });
    customNumeral.locale("col");
};

export default customNumeral;
