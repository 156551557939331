import styled from "styled-components";

export const ProductCardContainer = styled.div`
    display: flex;
    align-items: center;
    .card-body {
        padding: 15px;
        border-radius: 15px;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    }
    .image {
        margin-right: 10px;
        width: 45px;
        height: 45px;
        border-radius: 10px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .product-body {
        display: flex;
        flex-direction: column;
    }
    .product-light {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
