import { useSearchParams } from "react-router-dom";
import { AccountingType } from "../../../api/models/accounting";
import { useMemo } from "react";

export interface AccountingParams {
    tab?: AccountingType;
    preview?: string;
}

export const useAccountingParams = () => {
    const [urlParams, setParams] = useSearchParams();

    const getAccountingParamsText = (params: AccountingParams) => {
        let search = "?";
        if (params.tab) {
            search += `tab=${params.tab}&`;
        }
        if (params.preview) {
            search += `preview=${params.preview}&`;
        }
        return search;
    };

    const setAccountingParams = (params: AccountingParams) => {
        setParams(getAccountingParamsText(params));
    };

    const accountingParams = useMemo(() => {
        let accParams: AccountingParams = {};
        accParams.tab = (urlParams.get("tab") as AccountingType) ?? "receivable";
        accParams.preview = urlParams.get("preview") ?? "";
        return accParams;
    }, [urlParams]);

    return { accountingParams, setAccountingParams, getAccountingParamsText };
};
