import { getOrderCopyOmit } from "../../components/Orders/orderUtils";
import wahioFetchApi from "../fetchApi";
import { ILounge, OrderTableMoveInput, OrderTableMovementResponse } from "../models/lounge";
import { IOrderTable } from "../models/orderTable";
import { localOrderEndpoint } from "../restApiEndpoint";

export const updateOrderTable = (orderTable: IOrderTable): Promise<IOrderTable> => {
    return wahioFetchApi
        .post(localOrderEndpoint.post.orderTable, { ...orderTable, order: getOrderCopyOmit(orderTable.order) })
        .then((res: any) => res.data);
};

export const getOrderTable = (orderTableId: string): Promise<IOrderTable> => {
    return wahioFetchApi.get(localOrderEndpoint.get.orderTableById(orderTableId)).then((res: any) => res.data);
};

export const getOrderTableByOrder = (orderId: string): Promise<IOrderTable> => {
    return wahioFetchApi.get(localOrderEndpoint.get.orderTableByOrder(orderId)).then((res: any) => res.data);
};

export const deleteLounge = (loungeId: string): Promise<ILounge> => {
    return wahioFetchApi.delete(localOrderEndpoint.delete.lounge(loungeId)).then((res: any) => res.data);
};

export const getLounge = (loungeId: string): Promise<ILounge> => {
    return wahioFetchApi.get(localOrderEndpoint.get.loungeById(loungeId)).then((res: any) => res.data);
};

export const createOrderTableMovement = (data: OrderTableMoveInput): Promise<OrderTableMovementResponse> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderTableMovement, data).then((res: any) => res.data);
};
