import styled from "styled-components";

export const CloudItemCreateFilesContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .container-items {
        overflow: auto;
        max-height: 310px;
    }

    .panel-info {
        flex-direction: column;
        gap: 10px;
        border: 1px solid ${(props) => props.theme.palette.warningColor}60;
        background-color: ${(props) => props.theme.palette.warningColor}10;
        color: ${(props) => props.theme.palette.warningColor}90;
        border-radius: 10px;
        padding: 10px;
        justify-content: center;
    }
`;

export const FileCardStyle = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;

    &.success {
        background: ${(props) => props.theme.palette.primary}30;

        .btn-status {
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }
    }

    &.error {
        background: ${(props) => props.theme.palette.dangerColor}30;
        .btn-status {
            background: ${(props) => props.theme.palette.dangerColor};
            color: white;
        }
    }

    .icon-type {
        font-size: 20px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-status {
        margin-left: auto;
    }
`;
