import styled from "styled-components";

export const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 50px;

    &.navigation-mobile {
        .navigation-content-wrapped {
            gap: 0;
        }
        .navigation-card {
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

            .card-header {
                padding-left: 0;
                padding-right: 0;
                border-radius: 0;
            }
            .card-content {
                padding-left: 0;
                padding-right: 0;
            }
            :last-child {
                border-bottom: 0;
            }
        }
    }
`;

export const ProductProfileNavigationWrapped = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NavigationContentWrapped = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

export const NavigationMenuContainer = styled.div`
    background: ${(props) => props.theme.palette.background};
    top: 0px;
    align-self: flex-start;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    min-width: 100%;
    position: sticky;
    z-index: 10;

    display: grid;
    align-items: center;

    .arrow-icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
        position: absolute;
        z-index: 1;
        background: ${(props) => props.theme.palette.background};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        &.left {
            left: -10px;
        }
        &.right {
            right: -10px;
        }
    }
    .menu-list {
        overflow: auto;
        ::-webkit-scrollbar {
            height: 0px;
        }
        scroll-behavior: smooth;

        .tab-option {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 6px 15px;
            border-radius: 10px 10px 0 0;
            cursor: pointer;
            transition: background 0.1s ease-in-out;
            color: ${(props) => props.theme.palette.textLight};
            border-bottom: 2px solid transparent;

            .tab-icon {
                width: 25px;
                text-align: center;
                display: none;
            }

            .tab-name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &.selected {
                font-weight: 500;
                color: ${(props) => props.theme.palette.text};
                border-bottom: 2px solid ${(props) => props.theme.palette.text};
            }

            &:hover {
                background: ${(props) => props.theme.palette.backgroundLight};
            }
        }
    }
`;
