export const QUERY_ORDER_LIST_KEY = "orderList";
export const QUERY_ORDER_SHIPMENT_LIST_KEY = "orderListShipments";
export const QUERY_ORDER_QUEUED_LIST_KEY = "orderListQueued";
export const QUERY_ORDER_DRAFT_LIST_KEY = "orderListDraft";
export const QUERY_ORDER_VOID_LIST_KEY = "orderListVoid";
export const QUERY_ORDER_RESTAURANT_LIST_KEY = "orderListRestaurant";
export const QUERY_ORDER_COMMENT_LIST_KEY = "orderCommentList";

export const QUERY_ORDER_GROUP_LIST_KEY = "orderListGroup";
export const QUERY_ORDER_GROUP_SHIPMENT_LIST_KEY = "orderListGroupShipments";
export const QUERY_ORDER_GROUP_QUEUED_LIST_KEY = "orderListGroupQueued";
export const QUERY_ORDER_GROUP_DRAFT_LIST_KEY = "orderListGroupDraft";
export const QUERY_ORDER_GROUP_VOID_LIST_KEY = "orderListGroupVoid";
export const QUERY_ORDER_GROUP_RESTAURANT_LIST_KEY = "orderListGroupRestaurant";

export const QUERY_ACCOUNT_PERMISSION_LIST_KEY = "accountPermissionList";
export const QUERY_WAHIOCLOUD_FOLDERS = "QUERY_WAHIOCLOUD_FOLDERS";

export const QUERY_CONTACT_LIST_SELECT = "contactListSelect";

export const QUERY_WAREHOUSE_PRODUCT_LIST = "warehouseProductList";
export const QUERY_WAREHOUSE_PRODUCT_SEARCH_LIST = "warehouseProductSearchList";

export const QUERY_REST_API_COUNTRIES = "apiCountries";
export const QUERY_REST_API_EXPENSES = "expenses";
export const QUERY_REST_API_EXPENSES_GROUP = "expensesGroup";
export const QUERY_REST_API_EXPENSES_SUMMARY_TOTAL = "expensesSummaryTotal";
export const QUERY_REST_API_EXPENSES_CATEGORIES = "expensesCategories";

export const QUERY_CASH_REGISTER_LOGS = "CASH_REGISTER_LOGS";
export const QUERY_CASH_REGISTER_ENTRIES = "CASH_REGISTER_ENTRIES";

export const QUERY_PRODUCT_CATALOG_LIST = "QUERY_PRODUCT_CATALOG_LIST";
