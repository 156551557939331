import styled from "styled-components";

interface IContainerProps {
    hideBorder?: boolean;
    adjustAndCenter?: boolean;
}

export const NavigationContainer = styled.div`
    display: grid;
    padding-top: 10px;
    padding-left: 14px;
    padding-right: 14px;
    width: 100%;
    overflow: overlay;
    box-sizing: border-box;

    .nav-tab-container {
        overflow: auto;
    }

    border-bottom: ${(props: IContainerProps) => (props.hideBorder ? "0px" : "1px")} solid ${(props) => props.theme.palette.backgroundDark};
    ${(props: IContainerProps) => (props.adjustAndCenter ? " max-width: fit-content;   margin: auto;" : "")}
    &::-webkit-scrollbar {
        height: 0px;
    }

    &.hide-padding {
        padding: 0;
    }
`;

export const NavTabs = styled.ul`
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        text-align: center;
        padding: 0.4rem 0.8rem 0.8rem 0.8rem;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        color: ${(props) => props.theme.palette.textLight};
    }
    .auto-width li {
        width: 100%;
    }
    .active {
        color: ${(props) => props.theme.palette.primary};
        font-weight: 500;
    }
    .active::before {
        content: "";
        height: 3px;
        background: ${(props) => props.theme.palette.primary};
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
`;
