import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { useAlert } from "../../Alerts/Alert";
import { ProductQuantityPanelStyle } from "./styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, SquareButton, TextField } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";
import { ProductAddOption } from "../ProductCard";
import ProductOptionalsSelector, { getOptionalsConfig } from "../ProductOptionals/ProductOptionalsSelector";
import { getProductSku, getProductThumbnailUrl } from "../helper";
import messages from "./messages";
import TextareaChat from "../../Chats/ChatContainer/TextareaChat";
import { ProductOriginTypes } from "../../../api/models/product";
import { ProductActiveObject } from ".";
import WahioTooltip from "../../_controls/WahioTooltip";

interface ProductQuantityModalProps extends IModalShow {
    item: ProductAddOption;
    onChange: (value: ProductAddOption) => void;
    origin?: ProductOriginTypes;
    activeProducts?: ProductActiveObject;
}

export default function ProductAddQuantityModal(props: ProductQuantityModalProps) {
    return (
        <Modal
            title={props.item.product.name}
            removeVerticalAlign={props.item.product.includesOptionals}
            borderRadius={35}
            ignoreOutsideClick
            {...props}
        >
            <ProductAddQuantity {...props} />
        </Modal>
    );
}

function ProductAddQuantity(props: ProductQuantityModalProps) {
    const intl = useIntl();
    const { item } = props;
    const alert = useAlert();
    const [selectedOrderItem, setSelectedOrderItem] = useState(() => {
        let newItem = { ...item };
        if (item.product.optionals && item.product.optionals.length > 0) {
            newItem.optionalsConfig = getOptionalsConfig(item.product.optionals);
        }
        return newItem;
    });
    const { appSettingsState } = useContext(AppSettingsContext);

    const itemActive = React.useMemo(() => {
        if (!props.activeProducts) return undefined;
        return props.activeProducts[props.item.product.id];
    }, [props.activeProducts, props.item]);

    if (!selectedOrderItem.product) return <h1>Producto no reconocido</h1>;

    const updateQuantity = (value: number) => {
        if (item.stock && value > item.stock) {
            setSelectedOrderItem({ ...selectedOrderItem, quantity: item.stock });
            alert.info(intl.formatMessage(messages.theStockHasReachedTheLimit));
        } else if (value < 1) {
            setSelectedOrderItem({ ...selectedOrderItem, quantity: 1 });
        } else {
            setSelectedOrderItem({ ...selectedOrderItem, quantity: value });
        }
    };

    const increaseQuantity = (value: number) => {
        if (!value) {
            value = 1;
        }
        var tempValue = selectedOrderItem.quantity ?? 0;
        updateQuantity(tempValue + value);
    };

    let isDisabled = false;

    if (selectedOrderItem.optionalsConfig &&
        !selectedOrderItem.optionalsConfig.isCompleted &&
        selectedOrderItem.optionalsConfig.minSelectionCount > 0) {
        isDisabled = true;
    }

    const productSku = getProductSku(item.product, item.productVariant);

    return (
        <ProductQuantityPanelStyle>
            {props.origin === "restaurant" && itemActive && (
                <Flex gap10 alignCenter className="product-quantity-added">
                    <WahioTooltip width={300} small timeout={300}>
                        <Flex column w100 gap10>
                            <TextField small>Los productos en la mesa no se agrupan</TextField>

                            <TextField small light>
                                Si no quieres que los productos se agreguen duplicados solo edita el producto que ya esta en la mesa
                            </TextField>
                        </Flex>
                    </WahioTooltip>
                    <Flex column>
                        <TextField>El producto ya esta agregado</TextField>
                        <TextField small light>
                            Agregado: {itemActive?.quantity}
                        </TextField>
                    </Flex>
                </Flex>
            )}
            <Flex alignCenter gap10>
                <FlexImageStyle width={40} borderRadius={10} fitCover>
                    <img src={getProductThumbnailUrl(item.product, item.productVariant)} alt="" />
                </FlexImageStyle>
                <Flex column>
                    <TextField>
                        {item.product.name} {productSku ? ` • ${productSku}` : ""}
                    </TextField>
                    <TextField light small>
                        Stock Disponible: {item.stock}
                    </TextField>
                </Flex>
            </Flex>

            {selectedOrderItem.optionalsConfig && (
                <>
                    <ProductOptionalsSelector
                        optionalsConfig={selectedOrderItem.optionalsConfig}
                        onChange={(value) => setSelectedOrderItem({ ...selectedOrderItem, optionalsConfig: value })}
                    />
                    <hr />
                </>
            )}
            <Flex className="quantity-card">
                <SquareButton
                    disabled={selectedOrderItem.quantity <= 1}
                    onClick={() => increaseQuantity(appSettingsState.productSearchQuantityDelta * -1)}
                >
                    <i className="fa-regular fa-minus"></i>
                </SquareButton>

                <TextBoxNumeral
                    className="input-quantity"
                    value={selectedOrderItem.quantity}
                    format="number"
                    autoFocus
                    onNumberChange={(value) => updateQuantity(value)}
                />

                <SquareButton onClick={() => increaseQuantity(appSettingsState.productSearchQuantityDelta)}>
                    <i className="fa-regular fa-plus"></i>
                </SquareButton>
            </Flex>
            <Flex column gap5>
                <TextField light small>
                    Observación
                </TextField>
                <TextareaChat
                    withBorder
                    removePadding
                    placeholder="Escriba una observación"
                    hideSendButton
                    initialValue={selectedOrderItem.comment}
                    onInputChange={(value) => {
                        setSelectedOrderItem({ ...selectedOrderItem, comment: value });
                    }}
                    onSendMessage={(value) => {}}
                />
            </Flex>

            <Flex column gap5>
                <TextField light small>
                    Valor Unidad
                </TextField>
                <TextBoxNumeral
                    className=""
                    rounded
                    w100
                    value={selectedOrderItem.customPrice}
                    format="money"
                    autoFocus
                    onNumberChange={(value) => setSelectedOrderItem({ ...selectedOrderItem, customPrice: value })}
                />
            </Flex>

            <Flex justifyEnd gap10>
                <DefaultButton w100 bgLight borderRadius={10} onClick={() => props.setShow(false)}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton disabled={isDisabled} borderRadius={10} w100 onClick={() => props.onChange(selectedOrderItem)}>
                    Confirmar
                </PrimaryButton>
            </Flex>
        </ProductQuantityPanelStyle>
    );
}
