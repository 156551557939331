import styled from "styled-components";

export const GridCard = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 0px;
    border-radius: 15px;
    border: 1px solid transparent;
    gap: 10px;
    position: relative;
    color: ${(props) => props.theme.palette.text};

    .label-soon {
        position: absolute;
        top: 7px;
        right: 7px;
        padding: 2px 10px;
        background: #4caf50;
        color: white;
        border-radius: 14px;
        font-size: 12px;
        font-weight: 500;
    }
    &:hover {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;
