import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "../Alerts/Alert";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getServiceMessageError } from "../../api";
import { createOrganization } from "../../api/account/organizationApi";
import { AccountUser, getUserPermissions } from "../../api/models/accountUser";
import { ICashRegister } from "../../api/models/cashRegister";
import { OrderInvoiceNumeration } from "../../api/models/orderInvoiceNumeration";
import { IOrganization, IOrganizationUser, Organization } from "../../api/models/organizationTypes";
import { IWarehouse } from "../../api/models/warehouse";
import { getAuthBody } from "../../auth";
import PATHS from "../../constants/paths";
import { useAccountUserContext } from "../../store/contexts/AccountUserContext";
import { useOrganizationContext } from "../../store/contexts/OrganizationContext";
import { UserContext } from "../../store/contexts/UserContext";
import CashRegisterSelect from "../CashRegisters/CashRegisterSelect/CashRegisterSelect";
import LoadingDualRing from "../LoadingDualRing";
import OrderInvoiceNumerationSelect from "../OrderInvoiceNumerations/OrderInvoiceNumerationSelector";
import PermissionDenied from "../PermissionDenied";
import { UserListSelectable } from "../Users/UserList/UserListSelectable";
import WahioCloudImageSelector from "../WahioCloud/WahioCloudImageSelector";
import WarehouseSelect from "../Warehouses/WarehouseSelect";
import { DefaultButton, DefaultInput, Flex, PrimaryButton } from "../_controls";
import ContainerCollapse from "../_controls/containers/ContainerCollapse";
import messages from "./messages";

interface StoreFormProps {
    onCreate: (value: IOrganization) => void;
    onCancel: () => void;
}

const StoreForm = (props: StoreFormProps) => {
    const intl = useIntl();
    const [currentStore, setCurrentStore] = useState(new Organization(getAuthBody().accountId, getAuthBody().accountUserId));
    const { accountUserState } = useAccountUserContext();
    const { organizationState, setOrganizationState } = useOrganizationContext();
    const [listUserSelected, setListUserSelected] = useState<string[]>([]);
    const { userState } = useContext(UserContext);
    const navigate = useNavigate();

    const alert = useAlert();

    const organizationMutation = useMutation((data: IOrganization) => createOrganization(data), {
        onSuccess: (data) => {
            setOrganizationState({ ...organizationState, items: [...organizationState.items, data] });
            alert.success(intl.formatMessage(messages.storeCreatedSuccessfully));
            props.onCreate(data);
            navigate(PATHS.storeProfile(data.id));
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        const userOwnerId = userState.user?.account?.accountOwnerId;
        const users: IOrganizationUser[] = currentStore.users ?? [];
        if (userOwnerId !== userState.user?.id) {
            users.push({ accountUserId: userOwnerId ?? "" });
        }
        setCurrentStore({ ...currentStore, users });
        setListUserSelected(users.map((u) => u.accountUserId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.user?.account?.accountOwnerId, userState.user?.id]);

    const handleSubmit = () => {
        organizationMutation.mutate(currentStore);
    };
    const onChange = (e: any) => {
        setCurrentStore({
            ...currentStore,
            [e.target.name]: e.target.value,
        });
    };
    const onChangeImage = (url?: string) => {
        setCurrentStore({
            ...currentStore,
            image: url ?? "",
        });
    };

    if (!getUserPermissions(userState.user).organization?.create) {
        return <PermissionDenied />;
    }

    const onUpdateCashRegisterFromSelect = (value?: ICashRegister) => {
        setCurrentStore({ ...currentStore, defaultCashRegisterId: value?.id ?? "" });
    };

    const onUpdateOrderNumerationFromSelect = (value?: OrderInvoiceNumeration) => {
        setCurrentStore({ ...currentStore, orderInvoiceNumerationId: value?.id ?? "" });
    };

    const onUpdateWarehouseFromSelect = (warehouse?: IWarehouse) => {
        setCurrentStore({ ...currentStore, ecommerceDefaultWarehouseId: warehouse?.id ?? "" });
    };

    const onAddUserToStore = (value: AccountUser) => {
        const listUsersIds: IOrganizationUser[] = currentStore.users ?? [];
        const newUser = { accountUserId: value.id };
        const userExist = listUsersIds.findIndex((x) => x.accountUserId === value.id);
        if (userExist > -1) {
            listUsersIds.splice(userExist, 1);
        } else {
            listUsersIds.push(newUser);
        }
        setListUserSelected(listUsersIds.map((x) => x.accountUserId));
        setCurrentStore({ ...currentStore, users: listUsersIds });
    };

    const permissions = getUserPermissions(userState.user);

    const allowCreate =
        currentStore.name &&
        currentStore.defaultCashRegisterId &&
        currentStore.orderInvoiceNumerationId &&
        currentStore.ecommerceDefaultWarehouseId;

    return (
        <Flex column gap={20} padding={25}>
            <WahioCloudImageSelector image={currentStore.image} onSelectImage={onChangeImage} />
            <Flex column gap5>
                <label htmlFor="title" className="text-light text-small">
                    {intl.formatMessage(messages.name)}
                </label>
                <DefaultInput
                    rounded
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={currentStore.name}
                    placeholder={intl.formatMessage(messages.storeIsName)}
                    autoComplete="off"
                />
            </Flex>
            <Flex column gap5>
                <label htmlFor="address" className="text-light text-small">
                    {intl.formatMessage(messages.address)}
                </label>
                <DefaultInput
                    rounded
                    type="text"
                    onChange={onChange}
                    name="address"
                    value={currentStore.address}
                    placeholder={intl.formatMessage(messages.address)}
                    autoComplete="off"
                />
            </Flex>
            <WarehouseSelect
                defaultWarehouseId={currentStore.ecommerceDefaultWarehouseId}
                onWarehouseChange={onUpdateWarehouseFromSelect}
                allWarehouses
            />
            <CashRegisterSelect
                title="Caja registradora"
                defaultCashRegisterId={currentStore.defaultCashRegisterId}
                onCashRegisterChange={onUpdateCashRegisterFromSelect}
                alternateSelector
            />
            <OrderInvoiceNumerationSelect
                orderInvoiceNumerationId={currentStore.orderInvoiceNumerationId}
                onNumerationChange={onUpdateOrderNumerationFromSelect}
                alternateSelector
            />
            {permissions.organization?.addUsers ? (
                <ContainerCollapse title="Asignar Usuarios" textLight>
                    {accountUserState.isFetching ? (
                        <LoadingDualRing center />
                    ) : (
                        <UserListSelectable onClickUser={onAddUserToStore} listUserSelected={listUserSelected} hideUserAdmin />
                    )}
                </ContainerCollapse>
            ) : null}
            <Flex alignCenter justifyEnd gap10 marginTop={10}>
                <DefaultButton type="button" rounded disabled={organizationMutation.isLoading} onClick={() => props.onCancel()}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton
                    onClick={() => handleSubmit()}
                    rounded
                    disabled={organizationMutation.isLoading || !allowCreate}
                    type="button"
                >
                    {intl.formatMessage(messages.createStore)}
                    {organizationMutation.isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </Flex>
    );
};

export default StoreForm;
