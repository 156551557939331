import React, { useState } from "react";
import { useIntl } from "react-intl";
import Modal from "../../modals/Modal";
import { DefaultButton, PrimaryButton } from "../../_controls/buttons/index";
import messages from "./messages";
import { IModalShow } from "../../modals/Modal";
import { DefaultInput } from "../../_controls/inputs/index";
import { ContainerPassword, FooterButtons, InputPassword } from "./styled";
import { useAlert } from "../../Alerts/Alert";
import { updateAccountUserPassword } from "../../../api/account/accountUser";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { AccountUser } from "../../../api/models/accountUser";

interface IUpdatePassword {
    accountUser: AccountUser;
}
interface IModalUpdatePassword extends IUpdatePassword, IModalShow {}

export interface IPasswordUpdateInput {
    accountUserId: string;
    currentPassword: string;
    newPassword: string;
}

export const UpdatePassword = (props: IUpdatePassword) => {
    const [showModalUpdatePassword, setShowModalUpdatePassword] = useState(false);
    const intl = useIntl();

    return (
        <>
            <DefaultButton rounded className="small" onClick={() => setShowModalUpdatePassword(!showModalUpdatePassword)}>
                {<span>{intl.formatMessage(messages.updatePassword)}</span>}
            </DefaultButton>
            {showModalUpdatePassword && (
                <UpdatePasswordModal setShow={setShowModalUpdatePassword} {...props} show={showModalUpdatePassword} />
            )}
        </>
    );
};

export const UpdatePasswordModal = (props: IModalUpdatePassword) => {
    const intl = useIntl();
    const alert = useAlert();
    const { accountUser } = props;
    const [user, setUser] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        noMatch: false,
    });

    const useMutationPassword = useMutation((user: IPasswordUpdateInput) => updateAccountUserPassword(user), {
        onSuccess: () => {
            alert.success(intl.formatMessage(messages.updatePasswordSuccess));
            props.setShow(false);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [event.target.name]: event.target.value, noMatch: false });
    };
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (user.newPassword !== user.confirmPassword) {
            setUser({ ...user, noMatch: true });
            return;
        }
        useMutationPassword.mutate({
            accountUserId: accountUser.id,
            currentPassword: user.currentPassword,
            newPassword: user.newPassword,
        });
    };

    return (
        <Modal {...props} title="Actualizar contraseña" showHeader ignoreOutsideClick>
            <ContainerPassword onSubmit={onSubmit}>
                <InputPassword>
                    <span className="mt-1">{intl.formatMessage(messages.currentPassword)}</span>
                    <DefaultInput
                        required
                        placeholder="Contraseña actual"
                        name="currentPassword"
                        type="password"
                        value={user.currentPassword}
                        onChange={onChange}
                    />
                    <span className="mt-1">{intl.formatMessage(messages.newPassword)}</span>
                    <DefaultInput
                        required
                        placeholder="Nueva Contraseña"
                        name="newPassword"
                        type="password"
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
                        value={user.newPassword}
                        onChange={onChange}
                    />
                    <span className="mt-1">{intl.formatMessage(messages.confirmPassword)}</span>
                    <DefaultInput
                        required
                        placeholder="Confirmar Contraseña"
                        name="confirmPassword"
                        type="password"
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
                        value={user.confirmPassword}
                        onChange={onChange}
                    />
                    {user.noMatch && (
                        <div className="notMatch">
                            <span>{intl.formatMessage(messages.passwordsDoNotMatch)}</span>
                        </div>
                    )}

                    <span className="mt-1 mb-1 text-small-light">
                        La contraseña debe tener un mínimo de ocho caracteres, una letra mayúscula, una letra minúscula y un número
                    </span>
                </InputPassword>
                <FooterButtons>
                    <DefaultButton onClick={() => props.setShow(false)}>{intl.formatMessage(messages.cancel)}</DefaultButton>
                    <PrimaryButton type="submit">{intl.formatMessage(messages.save)}</PrimaryButton>
                </FooterButtons>
            </ContainerPassword>
        </Modal>
    );
};
