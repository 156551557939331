import _, { capitalize } from "lodash";
import React from "react";
import { ProductFeature } from "../../../api/models/product";
import { useLocalStorage } from "../../../utils/hooks";
import { DefaultButton, DefaultInput, Flex, SquareButton, TextField } from "../../_controls";
import { getSelectOption } from "../../../utils/select";
import { SelectBody } from "../../Select/styled";
import { PRODUCT_FEATURES } from "./helpers";
import { ProductFeatureRowStyle, ProductFeaturesPanel } from "./styled";
import CreateTable from "react-select/creatable";

interface ProductFeaturesPanelProps {
    productId: string;
    features: ProductFeature[];
    onChange: (value: ProductFeature[]) => void;
}

export default function ProductFeaturesForm(props: ProductFeaturesPanelProps) {
    const [customOptions, setOptions] = useLocalStorage<string[]>("FEATURES_NAMES", []);

    const onChangeOptions = (value: string) => {
        if (value) {
            let newOptions = [capitalize(value), ...customOptions];
            newOptions = _.uniq(newOptions);
            setOptions(newOptions.slice(0, 20));
        }
    };

    const onChangeFeature = (value: ProductFeature, index: number) => {
        let featuresCopy = [...props.features];
        featuresCopy[index] = value;
        props.onChange(featuresCopy);
    };

    const addFeature = () => {
        const value: ProductFeature = {
            id: "",
            name: "",
            value: "",
            productId: props.productId,
        };
        let features = [...props.features, value];
        props.onChange(features);
    };

    const deleteFeature = (index: number) => {
        let features = [...props.features];
        features.splice(index, 1);
        props.onChange(features);
    };

    return (
        <ProductFeaturesPanel className="features-panel">
            <Flex column>
                {props.features?.map((item, index) => {
                    return (
                        <ProductFeatureRow
                            key={index}
                            customOptions={customOptions}
                            newCustomOption={onChangeOptions}
                            feature={item}
                            onChange={(value) => onChangeFeature(value, index)}
                            deleteFeature={() => deleteFeature(index)}
                        />
                    );
                })}
            </Flex>
            <DefaultButton borderRadius={10} bgLight onClick={() => addFeature()}>
                <i className="fa-regular fa-plus"></i> Característica
            </DefaultButton>
        </ProductFeaturesPanel>
    );
}

interface ProductFeatureRowProps {
    feature: ProductFeature;
    onChange: (value: ProductFeature) => void;
    customOptions: string[];
    newCustomOption: (value: string) => void;
    deleteFeature: () => void;
}

const ProductFeatureRow = (props: ProductFeatureRowProps) => {
    const { feature, onChange, customOptions, newCustomOption } = props;

    const featureOptions = [...customOptions, ...PRODUCT_FEATURES];

    return (
        <ProductFeatureRowStyle className="feature-row">
            <SelectBody className="multi-value-theme-color" selectMinWidth={130} hideIndicator>
                <CreateTable
                    placeholder="Seleccionar"
                    className={feature.name ? "" : ""}
                    classNamePrefix="select"
                    value={feature.name ? getSelectOption({ id: feature.name, value: feature.name }) : undefined}
                    isDisabled={false}
                    isRtl={false}
                    onCreateOption={(value) => {
                        newCustomOption(value);
                        onChange({ ...feature, name: value });
                    }}
                    onChange={(value) => onChange({ ...feature, name: value?.value ?? "" })}
                    isSearchable={true}
                    name="seller"
                    options={featureOptions.map((item) => getSelectOption({ id: item, value: item }))}
                />
            </SelectBody>
            <DefaultInput
                className="feature-input-value"
                value={feature.value}
                placeholder="Valor"
                onChange={(value) => onChange({ ...feature, value: value.target.value })}
            />
            <SquareButton small className="btn-trash" onClick={() => props.deleteFeature()}>
                <TextField light className="fa-regular fa-trash" />
            </SquareButton>
        </ProductFeatureRowStyle>
    );
};
