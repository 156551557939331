import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { IWarehouse } from "../../api/models/warehouse";
import { getWarehousesByAccount, getWarehousesByOrganization } from "../../api/products/warehouseApi";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { useOrganizationContext } from "./OrganizationContext";
import { getAuthBody } from "../../auth";

export interface WarehouseState {
    allWarehouses: IWarehouse[];
    orgWarehouses: IWarehouse[];
    warehouseObject: { [key: string]: IWarehouse };
    isFetching: boolean;
    isSuccess: boolean;
}

export interface IWarehouseActions {
    add: (value: IWarehouse) => void;
    update: (value: IWarehouse) => void;
    reload: () => void;
    onRemoveWarehouse: (id: string) => void;
}

interface IContextProps {
    warehouseState: WarehouseState;
    warehouseActions: IWarehouseActions;
    getWarehouseById: (id?: string) => IWarehouse | undefined;
}

export const WarehouseContext = createContext({} as IContextProps);

const WarehouseContextProvider = (props: any) => {
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const { organizationState } = useOrganizationContext();
    const [warehouseState, setWarehouseOrganizationState] = useState<WarehouseState>({
        allWarehouses: [],
        orgWarehouses: [],
        isFetching: false,
        isSuccess: false,
        warehouseObject: {},
    });

    useEffect(() => {
        handleAllWarehouses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleOrganizationWarehouses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationState.currentOrganization]);

    useEffect(() => {
        if (organizationState.currentOrganization && warehouseState.allWarehouses.length > 0) {
            var warehouseId = organizationState.currentOrganization.ecommerceDefaultWarehouseId ?? warehouseState.allWarehouses[0].id;
            appSettingsActions.setAllSettings({ ...appSettingsState, defaultWarehouseId: warehouseId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationState.currentOrganization, warehouseState.allWarehouses]);

    const organizationMutation = useMutation((orgId: string) => getWarehousesByOrganization(orgId));
    const warehouseMutation = useMutation((accountId: string) => getWarehousesByAccount(accountId));

    const getItemsObj = (items: IWarehouse[]) => {
        let obj = items.reduce((obj: { [key: string]: IWarehouse }, item) => {
            obj[item.id ?? "-"] = item;
            return obj;
        }, {});
        return obj;
    };

    const getWarehouseById = (id?: string) => {
        if (!id) return undefined;
        return warehouseState.warehouseObject ? warehouseState.warehouseObject[id] : undefined;
    };

    const handleAllWarehouses = () => {
        warehouseMutation.mutateAsync(getAuthBody().accountId).then((items) => {
            setWarehouseOrganizationState((prev) => {
                return { ...prev, allWarehouses: items };
            });
        });
    };

    const handleOrganizationWarehouses = () => {
        let organizationId = organizationState.currentOrganization?.id;
        if (organizationId) {
            organizationMutation.mutateAsync(organizationId).then((items) => {
                setWarehouseOrganizationState((prev) => {
                    return { ...prev, orgWarehouses: items, warehouseObject: getItemsObj(items) };
                });
            });
        }
    };

    const onRemoveWarehouse = (id: string) => {
        let warehouseItems = warehouseState.allWarehouses.filter((item) => item.id !== id);
        let orgItems = warehouseState.orgWarehouses.filter((item) => item.id !== id);

        setWarehouseOrganizationState({ ...warehouseState, allWarehouses: warehouseItems, orgWarehouses: orgItems });
    };

    const add = (value: IWarehouse) => {
        let newItems = [...warehouseState.allWarehouses, value];

        setWarehouseOrganizationState({ ...warehouseState, allWarehouses: newItems });
    };

    const reload = () => {
        handleAllWarehouses();
        handleOrganizationWarehouses();
    };

    const update = (value: IWarehouse) => {
        let newItems = warehouseState.allWarehouses.map((item) => (item.id === value.id ? value : item));
        setWarehouseOrganizationState({ ...warehouseState, allWarehouses: newItems });
    };

    const warehouseOrganizationActions = {
        add,
        update,
        onRemoveWarehouse,
        reload,
    };

    return (
        <WarehouseContext.Provider
            value={{
                warehouseState: {
                    ...warehouseState,
                    isFetching: organizationMutation.isLoading || warehouseMutation.isLoading,
                    isSuccess: organizationMutation.isSuccess && warehouseMutation.isSuccess,
                },
                warehouseActions: warehouseOrganizationActions,
                getWarehouseById,
            }}
        >
            {props.children}
        </WarehouseContext.Provider>
    );
};

export const useWarehouseContext = () => {
    return useContext(WarehouseContext);
};

export default WarehouseContextProvider;
