import { endOfMonth, startOfMonth } from "date-fns";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { DateRangeSelection } from "../../DatePicker/CustomDateRangePicker";

export const printConfig = `@page {
    size: auto;
    margin: 5mm;
    background: white;
}
@media print {
    html, body {
        background: white;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;                     
    }
    
}
h4.class-name
    @page{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    }
`;


export const initialRange: DateRangeSelection = {
    startDate: startOfMonth(customMoment().toDate()),
    endDate: endOfMonth(customMoment().toDate()),
};
