import { DateFilter, IPaginationRequest, IPaginationResponse, PaymentMethod, WahioFile } from ".";
import { getUniqueId } from "../../utils";
import { EcommerceSocketVisitResponse } from "../socket/models";
import { StatisticsSearchModel } from "./_statistics";
import { Account, AccountUser } from "./accountUser";
import { Contact } from "./contact";
import { DeliveryStatus, IDeliveryOrder } from "./deliveryOrder";
import { EBillingDocumentType, ElectronicBilling } from "./electronicBilling";
import {
    IDiscountOrderItem,
    IOrderBase,
    ITaxOrderItem,
    OrderBaseItem,
    OrderItemStatus,
    OrderSourceType,
    OrderStatus,
    OrderType,
} from "./orderBase";
import { IOrderChannel } from "./orderChannel";
import { OrderInvoiceNumeration } from "./orderInvoiceNumeration";
import { OrderMetadata } from "./orderMetadata";
import { OrderNotification, OrderNotificationInput } from "./orderNotification";
import { IOrderPayment, IOrderRefund } from "./orderPayment";
import { IOrderTable } from "./orderTable";
import { IOrganization } from "./organizationTypes";
import { IProduct, IProductVariant, ProductOptionalsConfig, ProductPriceTypes } from "./product";
import { IWarehouse } from "./warehouse";
import { IWarehouseLocation } from "./warehouseLocation";
import { IWarehouseProduct } from "./warehouseProduct";

export const UPDATE_CURRENT_ORDER = "UPDATE_CURRENT_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const REQUEST_ORDERS = "REQUEST_ORDERS";
export const RECEIVE_ORDERS = "RECEIVE_ORDERS";
export const INVALID_REQUEST_ORDERS = "INVALID_REQUEST_ORDERS";

export type OrderItemPreparationStatus = "waiting" | "inPreparation" | "ready";

export const OrderItemPreparationStatusList: OrderItemPreparationStatus[] = ["waiting", "inPreparation", "ready"];

export interface ICurrentOrderState {
    order?: Order;
    orderShipment?: Order;
    orderQuote?: Order;
}

export interface IOrderState {
    pagination: IPaginationResponse<Order>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface OrderItem extends OrderBaseItem {
    discount: number;
    orderId?: string;
    defaultPrice: ProductPriceTypes;
    warehouse?: IWarehouse;
    createdAt?: Date | string;
    accountUserId?: string;
    preparationStatus?: OrderItemPreparationStatus;
    stockTemp?: number; // ONLY UI
    quantityReturned: number;
    hasReturns: boolean;
    additionalInfoLoaded?: boolean; //ONLY UI
    isSelected?: boolean; //ONLY UI
    costWeightedAverage: number;
    costBaseWeightedAverage: number;
    costAverage: number;
    costBaseAverage: number;
    costLastPurchase: number;
    costBaseLastPurchase: number;
    productCategoryId?: string;

    details?: OrderItemDetails;
}

export interface OrderItemDetails {
    discountName?: string;
}

export interface OrderItemLog {
    id: string;
    orderId: string;
    productId: string;
    product?: IProduct;
    productVariantId: string;
    productVariant?: IProductVariant;
    accountUserId: string;
    quantity: number;
    createdAt: Date;
    action: OrderItemLogAction;
}

export interface OrderItemLogSearch extends IPaginationRequest {
    orderId: string;
}

export type OrderItemLogAction = "remove" | "add";

export interface IStatusHistory {
    id: string;
    orderId: string;
    status: OrderStatus;
    dateCreated: Date;
}
export type PickingStatus = "active" | "completed";
export interface IOrderPicking {
    id: string;
    orderId: string;
    binCode: string;
    accountUserId: string;
    endAccountUserId?: string;
    totalQuantity: number;
    totalQuantityReceived: number;
    dateCreated: Date;
    dateUpdated: Date;
    status: PickingStatus;
    items: IOrderPickingItem[];
}

export interface IOrderPickingItem {
    id: string;
    orderPickingId: string;
    productId: string;
    product?: IProduct;
    productVariantId?: string;
    productVariant?: IProductVariant;
    locationCode: string;
    location?: IWarehouseLocation;
    quantityReceived: number;
    quantityReceivedTemp?: number;
    quantity: number;
}

export interface IOrderPickingSetCompletedModel {
    orderPickingId: string;
    accountUserId: string;
}

export interface IOrderPickingUpdateModel {
    binCode: string;
    orderPickingId: string;
    accountUserId: string;
}

export interface IOrderDelete {
    id: string;
    accountUserId: string;
    accountUser?: AccountUser;
    cashRegisterId: string;
    orderId: string;
    dateDeleted: Date;
    comment: string;
    status: string;
}

export interface IOrderComment {
    id?: string;
    orderId: string;
    accountUserId: string;
    description: string;
    dateCreated?: Date;
    dateUpdate?: Date;
    orderNumber?: number; //SEARCH ONLY
    customerName?: string; //SEARCH ONLY
}

export interface OrderCommentSearchModel extends IPaginationRequest {
    accountId: string;
    accountUserId?: string;
    organizationId: string;
    searchValue?: string;
    requestUserId: string; //TO SAVE THE LAST ACCESS OF THE USER
}

export interface IOrderDeleteCreate {
    accountUserId: string;
    cashRegisterId: string;
    orderId: string;
    status: OrderStatus;
    method: PaymentMethod;
    comment?: string;
    organizationId?: string;
}

export interface IOrderInvoice {
    id?: string;
    prefix: string;
    realNumber: number;
    number: string;
    orderId: string;
    dateCreated: Date;
    orderInvoiceNumerationId: string;
    orderInvoiceNumeration?: OrderInvoiceNumeration;
    accountUserId: string;
    digitalInvoiceAvailable?: boolean;
}

export interface IOrderInvoiceCreateModel {
    orderId: string;
    accountUserId: string;
    orderInvoiceNumerationId: string;
    paymentTermsDays?: number;
    deliveryStatus?: DeliveryStatus;
}

export interface Order extends IOrderBase {
    auxId?: string; //UI ONLY
    userSellerId?: string;
    contactSellerId?: string;
    contactSeller?: Contact;
    account?: Account;
    userSeller?: AccountUser;
    customerId?: string;
    customer?: Contact;
    customerPayment?: CustomerPayment;
    orderChannelId?: string;
    orderChannel?: IOrderChannel;
    items: OrderItem[];
    payments: IOrderPayment[];
    refunds: IOrderRefund[];
    statusHistory: IStatusHistory[];
    deliveryOrder?: IDeliveryOrder;
    isDigitalInvoice?: boolean;
    isDelivery?: boolean;
    takeoutOrder?: boolean;
    isDeliveryClose?: boolean;
    isRestaurant?: boolean;
    isRestaurantClose?: boolean;
    accountUserEntitiesLoaded?: boolean;
    source: OrderSourceType;
    orderPicking?: IOrderPicking;
    pickingAvailable: boolean;
    allowLogisticsService: boolean;
    orderDelete?: IOrderDelete;
    orderInvoiceNumerationId?: string;
    orderInvoice?: IOrderInvoice;
    invoiceCreatedAt?: Date | string;
    invoiceAvailable: boolean;
    includeInvoice: boolean;
    trackingNumber?: string;
    dateCreatedCustom?: Date | string;
    comments?: IOrderComment[];
    commentsCount: number;
    notifications?: OrderNotification[];
    hasReturns?: boolean;
    totalAmountReturns: number;
    orderTable?: IOrderTable;
    totalDelivery: number;
    defaultPrice?: ProductPriceTypes;
    documentType: string;
    currency?: string;
    defaultWarehouse?: IWarehouse; //ONLY UI
    showNotificationCard?: boolean; //ONLY UI
    sendNotification?: OrderNotificationInput;
    eBillingGenerated: boolean;
    eBillingNumber?: string;
    eBillingDocumentType?: EBillingDocumentType;
    eBillingData?: ElectronicBilling;
    eBillingCreatedAt?: Date | string;
    orderTypeTemp?: OrderTypeTemp;
    warehouseId?: string;
    orderMetadata?: OrderMetadata;
    tipBeforeTax?: boolean;
    discountBeforeTax?: boolean;
}
export type OrderTypeTemp = "sale" | "quotation" | "shipment";
export class OrderItemImpl implements OrderItem {
    id?: string | undefined;
    discount: number = 0;
    productId: string;
    product: IProduct;
    warehouseId: string = "";
    warehouse?: IWarehouse;
    quantity: number;
    unitPrice: number;
    totalUnitPrice: number;
    comment?: string | undefined;
    position: number = 0;
    status: OrderItemStatus = "empty";
    defaultPrice: ProductPriceTypes = "price";
    deliveryOrder?: IDeliveryOrder;
    stockTemp?: number = 0;
    discounts: IDiscountOrderItem[] = [];
    taxes: ITaxOrderItem[] = [];
    manualPercentageDiscount: number = 0;
    manualPercentageDiscountValue: number = 0;
    basePrice: number = 0;
    totalTax: number = 0;
    totalTaxPercentage: number = 0;
    quantityReturned: number = 0;
    hasReturns: boolean = false;
    productVariantId?: string;
    productVariant?: IProductVariant;
    warehouseProduct?: IWarehouseProduct;
    optionalsConfig?: ProductOptionalsConfig;

    subTotal: number = 0;
    total: number = 0;
    locationCode?: string;

    costWeightedAverage: number = 0;
    costBaseWeightedAverage: number = 0;
    costAverage: number = 0;
    costBaseAverage: number = 0;
    costLastPurchase: number = 0;
    costBaseLastPurchase: number = 0;

    basePricePreTaxDiscount: number = 0;
    availableStock: number = 0;

    preparationStatus?: OrderItemPreparationStatus;

    createdAt?: Date | string;
    accountUserId?: string;

    productCategoryId?: string;
    details?: OrderItemDetails;

    constructor(product: IProduct, quantity: number, defaultPrice: ProductPriceTypes, currentStock?: number, warehouse?: IWarehouse) {
        this.productId = product.id;
        this.product = product;
        this.quantity = quantity;
        this.unitPrice = product[defaultPrice];
        this.totalUnitPrice = product[defaultPrice];
        this.defaultPrice = defaultPrice;
        this.stockTemp = currentStock;

        if (warehouse && warehouse.id) {
            this.warehouseId = warehouse.id;
            this.warehouse = warehouse;
        }
    }
}

export class OrderImpl implements Order {
    auxId?: string;
    userSellerId?: string;
    userSeller?: AccountUser;
    customerId?: string;
    customer?: Contact;
    items: OrderItem[] = [];
    payments: IOrderPayment[] = [];
    refunds: IOrderRefund[] = [];
    statusHistory: IStatusHistory[] = [];
    id?: string | undefined;
    accountId: string;
    organization?: IOrganization;
    account?: Account;
    dateCreated?: Date;
    dateUpdated?: Date;
    number: number = 0;
    accountUserId: string;
    totalAmount: number = 0;
    subTotal: number = 0;
    totalAmountItems: number = 0;
    totalItemsDiscount: number = 0;
    totalPaid: number = 0;
    totalPayment: number = 0;
    totalDiscount: number = 0;
    totalTip: number = 0;
    percentageDiscount: number = 0;
    percentageTip: number = 0;
    totalRefund: number = 0;
    organizationId: string = "";
    costCenterId: string = "";
    comment: string = "";
    status: OrderStatus = "draft";
    isDelivery?: boolean;
    takeoutOrder?: boolean;
    isDeliveryClose?: boolean;
    isRestaurant?: boolean;
    isRestaurantClose?: boolean;
    deliveryOrder?: IDeliveryOrder;
    moneyReceived: number = 0;
    moneyReturned: number = 0;
    accountUserEntitiesLoaded?: boolean;
    source: OrderSourceType = "manually";
    orderPicking?: IOrderPicking;
    allowLogisticsService: boolean = false;
    paymentTermsDays: number = 0;
    totalTaxItems: number = 0;
    orderDelete?: IOrderDelete;
    totalManualPercentageDiscountItems: number = 0;
    lastAccountUserId: string = "";
    orderTable?: IOrderTable;
    currency?: string = "COP";
    pickingAvailable: boolean = false;

    orderInvoiceNumerationId?: string;
    orderInvoice?: IOrderInvoice;
    invoiceAvailable: boolean = false;
    includeInvoice: boolean = false;
    trackingNumber?: string;

    itemsCount: number = 0;
    itemsQuantityCount: number = 0;
    dateCreatedCustom?: Date | string;
    totalAmountReturns: number = 0;

    totalDelivery: number = 0;

    files: WahioFile[] = [];

    taxIncluded: boolean = true;
    preTaxDiscount: boolean = false;

    includesTaxWithholding: boolean = false;
    taxWithholdingName: string = "";
    taxWithholdingPercentage: number = 0;
    taxWithholdingValue: number = 0;
    documentType: string = "VENTA";

    eBillingGenerated: boolean = false;
    eBillingNumber?: string;
    eBillingDocumentType?: EBillingDocumentType;
    eBillingCreatedAt?: Date | string;

    orderTypeTemp?: OrderTypeTemp;
    warehouseId?: string;
    orderMetadata?: OrderMetadata;
    tipBeforeTax?: boolean;
    discountBeforeTax?: boolean;

    constructor(accountId: string, accountUserId: string, organizationId: string) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
        this.organizationId = organizationId;
        this.auxId = getUniqueId();
    }
    orderChannelId?: string | undefined;
    orderChannel?: IOrderChannel | undefined;
    isDigitalInvoice?: boolean | undefined;
    comments?: IOrderComment[] | undefined;
    commentsCount: number = 0;
    hasReturns?: boolean | undefined;
    defaultPrice?: ProductPriceTypes | undefined;
    digitalInvoiceAlready?: boolean | undefined;

    accountUser?: AccountUser | undefined;
    paymentTermsDaysDefaultLoaded?: boolean | undefined;
    lastCashRegisterId?: string | undefined;
}

export interface OrderSearchInput extends IPaginationRequest {
    accountId: string;
    accountIds?: string[];
    searchValue?: string;
    orderChannelId?: string;
    source?: OrderSourceType;
    status?: string;
    statusList?: OrderStatus[];
    shipmentStatusList?: DeliveryStatus[];
    organizationId?: string;
    dateRangeActive?: boolean;
    dateStart?: Date | string;
    dateEnd?: Date | string;
    customerId?: string;
    supplierId?: string;
    courierId?: string;
    productId?: string;
    paymentMethod?: PaymentMethod;
    showOnlyWithInvoice?: boolean;
    showOnlyWithoutInvoice?: boolean;
    showOnlyWithEBilling?: boolean;
    showOnlyWithoutEBilling?: boolean;
    showOnlyWithReturns?: boolean;
    showOnlyWithoutReturns?: boolean;
    onlyWithLogistics?: boolean;
    accountUserId?: string;
    contactSellerId?: string;
    hideShipments?: boolean;
    minutesOffset: number;
    orderType?: OrderType;
    showArchivedShipments?: boolean;
    paymentTermsDays?: number;
    invoiceNumerationId?: string;
    orderMetadata?: OrderMetadata;
}

export interface AccountOrganizationFilter {
    accountId: string;
    organizationId?: string;
}

export interface OrderSearchStatusCount {
    accountId?: string;
    organizationId?: string;
    statusList: OrderStatus[];
}

export interface OrdersForBoard {
    pending: IPaginationResponse<Order>;
    processing: IPaginationResponse<Order>;
    picking: IPaginationResponse<Order>;
    packing: IPaginationResponse<Order>;
    readyToShip: IPaginationResponse<Order>;
    shipped: IPaginationResponse<Order>;
    delivered: IPaginationResponse<Order>;
}

export interface OrderSearchDateRange extends DateFilter {
    accountId: string;
}

export interface OrderSearchGroupInput extends OrderSearchInput, StatisticsSearchModel {}

export interface ProductReservedSearchSearch extends IPaginationRequest {
    productId: string;
    warehouseId: string;
    productVariantId?: string;
}

export interface DeliveryStatusCountByProductSearch extends ProductReservedSearchSearch, IPaginationRequest {
    shipmentStatusList: DeliveryStatus[];
}

export interface OrderCommentUnreadInput {
    organizationId: string;
    userId: string;
}

export interface OrderLocalStorage {
    queueCount: number;
    orderTempCount: number;
    unreadComments: number;
    lastOrderRequestId?: string;
    orderMetadata?: OrderMetadata;
    ecommerceVisit?: EcommerceSocketVisitResponse;
}

export interface UpdateCurrentOrder {
    type: typeof UPDATE_CURRENT_ORDER;
    value: ICurrentOrderState;
}

export interface RequestSales {
    type: typeof REQUEST_ORDERS;
    value: OrderSearchInput;
}
export interface ReceiveSales {
    type: typeof RECEIVE_ORDERS;
    value: IPaginationResponse<Order>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_ORDERS;
    value: any;
}
export interface UpdateSale {
    type: typeof UPDATE_ORDER;
    value: Order;
}

export interface AddSale {
    type: typeof ADD_ORDER;
    value: Order;
}

interface DeleteSale {
    type: typeof DELETE_ORDER;
    id: string;
}

export type OrderTypes = UpdateCurrentOrder | RequestSales | ReceiveSales | InvalidRequest | UpdateSale | AddSale | DeleteSale;

export interface CustomerPayment {
    amountGiven: number;
    changeReturned: number;
}

export interface OrderSearchNotificationCount {
    accountId: string;
    organizationId: string;
}

export interface OrderNotificationResponse {
    queueCount: number;
    orderTempCount: number;
}
