import styled from "styled-components";

export const MapMarkerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    .route-container {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .map-container {
        width: 100%;
        height: 300px;
        border-radius: 15px;
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const DropdownRouteContainer = styled.div`
    .btn-route-dropdown {
        border-radius: 20px;
        border: 0;
        box-shadow: 1px 1px 10px ${(props) => props.theme.palette.shadowColor};
        color: ${(props) => props.theme.palette.primary};
    }

    .btn-map {
        border-radius: 12px;
        border: 0;
        width: 100%;
        justify-content: start;
        img {
            border-radius: 10px;
        }
    }
`;
