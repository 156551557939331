import React, { useState } from "react";

import { WahioFileImpl } from "../../../../api/models";
import { IProduct, IProductVariant } from "../../../../api/models/product";
import { DefaultInput, Flex, SquareButton } from "../../../_controls";
import { ProductVariantCardContainer, ProductVariantInputGroup } from "../styled";

import { useAlert } from "../../../Alerts/Alert";
import { WahioCloudItem } from "../../../../api/models/wahioCloud";
import { CloudImages } from "../../../../assets";
import { capitalize } from "../../../../utils";
import TextBoxNumeral from "../../../TextBoxNumeral";
import WahioCloudItemsModal from "../../../WahioCloud/WahioCloudItemsModal";
import ProductVariantsPriceModal from "../ProductVariantsDetailsModal";

interface ProductVariantRowProps {
    product: IProduct;
    repeatImageInVariant?: string;
    repeatImageForAll?: boolean;
    repeatPriceForAll?: boolean;
    onUpdateProduct: (value: IProduct) => void;
    productVariant: IProductVariant;
    onUpdateVariant: (productVariant: IProductVariant) => void;
    useSameImageInVariant?: string;
    useSamePriceInVariant?: string;
}

export const ProductVariantRow = (props: ProductVariantRowProps) => {
    const { productVariant } = props;
    const [showPricesModal, setShowPricesModal] = useState(false);
    const [showWahioCloudModal, setShowWahioCloudModal] = useState(false);
    const alert = useAlert();

    const onInputChange = (e: any) => {
        if (e.target.value.match(/[ñÑ]/g)) {
            alert.error("No se permite el uso de Ñ o ñ en este campo");
            return;
        }
        props.onUpdateVariant({ ...productVariant, [e.target.name]: e.target.value });
    };

    const onItemSelected = (items: WahioCloudItem[]) => {
        if (items.length > 0) {
            if (props.repeatImageInVariant) {
                let currentValue = productVariant.options.find((x) => x.variantName === props.repeatImageInVariant);
                if (currentValue) {
                    let updatedVariants: IProductVariant[] = [];
                    props.product.variants.forEach((element) => {
                        if (element.options.find((x) => x.variantName === props.repeatImageInVariant && x.value === currentValue?.value)) {
                            let item = items[0];
                            let image = new WahioFileImpl(item.url, item.type);

                            if (element.images && element.images.length > 0) {
                                element.images[0] = image;
                            } else {
                                element.images = [image];
                            }

                            updatedVariants.push(element);
                        } else {
                            updatedVariants.push(element);
                        }
                    });
                    props.onUpdateProduct({ ...props.product, variants: updatedVariants });
                }
            } else if (props.repeatImageForAll) {
                let updatedVariants: IProductVariant[] = [];
                let item = items[0];
                let image = new WahioFileImpl(item.url, item.type);
                props.product.variants.forEach((element) => {
                    if (element.images && element.images.length > 0) {
                        element.images[0] = image;
                    } else {
                        element.images = [image];
                    }

                    updatedVariants.push(element);
                });
                props.onUpdateProduct({ ...props.product, variants: updatedVariants });
            } else {
                let item = items[0];
                let image = new WahioFileImpl(item.url, item.type);

                if (productVariant.images && productVariant.images.length > 0) {
                    productVariant.images[0] = image;
                } else {
                    productVariant.images = [image];
                }

                props.onUpdateVariant({ ...productVariant });
            }
        }
    };

    const onPriceVariantChance = (value: number) => {
        if (props.useSamePriceInVariant) {
            let currentValue = productVariant.options.find((x) => x.variantName === props.useSamePriceInVariant);
            if (currentValue) {
                let updatedVariants: IProductVariant[] = [];
                props.product.variants.forEach((variant) => {
                    if (variant.options.find((x) => x.variantName === props.useSamePriceInVariant && x.value === currentValue?.value)) {
                        variant.price = value;
                        updatedVariants.push(variant);
                    } else {
                        updatedVariants.push(variant);
                    }
                });
                props.onUpdateProduct({ ...props.product, variants: updatedVariants });
            }

        } else if (props.repeatPriceForAll) {
            let updatedVariants: IProductVariant[] = [];
            props.product.variants.forEach((variant) => {
                variant.price = value;
                updatedVariants.push(variant);
            });
            props.onUpdateProduct({ ...props.product, variants: updatedVariants });
        } else {
            props.onUpdateVariant({ ...productVariant, price: value })
        }
    }

    return (
        <>
            {showWahioCloudModal && (
                <WahioCloudItemsModal
                    show={showWahioCloudModal}
                    setShow={setShowWahioCloudModal}
                    allowSelection={true}
                    onClickItems={onItemSelected}
                    defaultType="image"
                />
            )}
            <ProductVariantCardContainer className="variant-card">
                {showPricesModal && (
                    <ProductVariantsPriceModal
                        product={props.product}
                        onUpdateProduct={props.onUpdateProduct}
                        show={showPricesModal}
                        setShow={setShowPricesModal}
                        selectedVariant={productVariant}
                        useSameImageInVariant={props.useSameImageInVariant ?? ""}
                    />
                )}
                <Flex alignCenter w100 alignStart>
                    <Flex column justifyCenter alignCenter>
                        <div className="image" onClick={() => setShowWahioCloudModal(true)}>
                            <div className="hover-edit">
                                <span className="wahioicon-camera"></span>
                            </div>
                            <img
                                src={
                                    productVariant.images && productVariant.images.length > 0 ? productVariant.images[0].url : CloudImages.defaultImage
                                }
                                alt=""
                            />
                        </div>
                    </Flex>
                    <Flex column w100 gap5>
                        <Flex gap10 alignCenter spaceBetween>
                            <Flex gap10 alignCenter flexWrap>
                                {props.productVariant.options.map((item, index) => (
                                    <span key={index} className="text-bold">
                                        {capitalize(item.value)}
                                    </span>
                                ))}
                            </Flex>
                            <SquareButton small onClick={() => setShowPricesModal(true)}>
                                <span className="wahioicon-pen"></span>
                            </SquareButton>
                        </Flex>

                        <Flex column gap5>
                            <ProductVariantInputGroup>
                                <span className="text-light text-small">Sku</span>
                                <DefaultInput placeholder="sku" name="sku" onChange={onInputChange} value={productVariant.sku} />
                            </ProductVariantInputGroup>
                            <ProductVariantInputGroup>
                                <span className="text-light text-small">Code</span>
                                <DefaultInput placeholder="code" name="code" onChange={onInputChange} value={productVariant.code} />
                            </ProductVariantInputGroup>
                            <ProductVariantInputGroup>
                                <span className="text-light text-small">Precio </span>
                                <TextBoxNumeral
                                    format="money"
                                    value={productVariant.price}
                                    onNumberChange={(value) => onPriceVariantChance(value)}
                                />
                            </ProductVariantInputGroup>
                        </Flex>
                    </Flex>
                </Flex>
            </ProductVariantCardContainer>
        </>
    );
};
