import { DateFilter, IPaginationRequest, WahioFile } from ".";

export interface EventCalendar {
    id: string;
    accountId: string;
    title: string;
    description?: string;
    location?: string;
    startDate: string | Date;
    endDate: string | Date;
    recurrenceEndDate?: string | Date;
    allDay: boolean;
    rRule?: string;
    hasRecurrence: boolean;
    category?: string;
    color?: string;
    timeZone: string;
    url?: string;
    notes?: string;
    relatedEntityId?: string;
    relatedEntityType?: string;
    files?: WahioFile[];
}

export class EventCalendarImpl implements EventCalendar {
    id: string = "";
    accountId: string;
    title: string = "";
    description?: string;
    location?: string;
    startDate: string | Date = new Date();
    endDate: string | Date = new Date();
    recurrenceEndDate?: string | Date;
    allDay: boolean = false;
    rRule?: string;
    hasRecurrence: boolean = false;
    category?: string;
    color?: string = "blue";
    timeZone: string = "";
    url?: string;
    notes?: string;
    relatedEntityId?: string;
    relatedEntityType?: string;
    files?: WahioFile[];

    constructor(accountId: string) {
        this.accountId = accountId;
    }
}

export interface EventSearchInput extends IPaginationRequest, DateFilter {
    accountId: string;
    includeMinutes?: boolean;
}

export interface EventSearchGroup {
    count: number;
    date: string | Date;
    firstEvent: EventCalendar;
}
