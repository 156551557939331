import React from "react";
import Modal, { IModalShow } from "../../modals/Modal";
import { useIntl } from "react-intl";
import CreateContactForm, { CreateContactFormProps } from ".";
import { Contact } from "../../../api/models/contact";
import messages from "./messages";

interface IProps extends CreateContactFormProps, IModalShow {}

export default function CreateContactModal(props: IProps) {
    const intl = useIntl();
    const onCreate = (value: Contact) => {
        props.onCreate(value);
        props.setShow(false);
    };
    return (
        <Modal
            sizeType="md"
            scrollInside={false}
            ignoreOutsideClick
            title={props.contact?.id ? intl.formatMessage(messages.editContact) : intl.formatMessage(messages.createContact)}
            removeVerticalAlign
            showHeader={true}
            useMobileView
            {...props}
        >
            <CreateContactForm {...props} onCreate={onCreate} />
        </Modal>
    );
}
