import React, { createContext, useContext, useEffect, useState } from "react";
import { LocalStoreKeys, getStoredData, saveData } from "..";
import { IOrganization, IOrganizationState } from "../../api/models/organizationTypes";

interface IContextProps {
    organizationState: IOrganizationState;
    setOrganizationState: (value: IOrganizationState) => void;
    setCurrentOrganizationState: (value?: IOrganization) => void;
    setOrganizationsState: (items: IOrganization[]) => void;
    setPartialOrganizationItem: (item: Partial<IOrganization>) => void;
}

export const OrganizationContext = createContext({} as IContextProps);

const initialState: IOrganizationState = {
    items: [],
    currentOrganization: undefined,
};

const OrganizationContextProvider = (props: any) => {
    const [organizationState, setOrganizationState] = useState(
        getStoredData<IOrganizationState>(initialState, LocalStoreKeys.organization)
    );

    useEffect(() => {
        saveData(LocalStoreKeys.organization, organizationState);
    }, [organizationState]);

    const setCurrentOrganizationState = (value?: IOrganization) => {
        setOrganizationState({ ...organizationState, currentOrganization: value });
    };

    const setOrganizationsState = (items: IOrganization[]) => {
        setOrganizationState({ ...organizationState, items });
    };

    const setPartialOrganizationItem = (item: Partial<IOrganization>) => {
        let org = organizationState.currentOrganization;
        if (!org) return;

        setOrganizationState({
            ...organizationState,
            currentOrganization: { ...org, ...item },
        });
    };

    return (
        <OrganizationContext.Provider
            value={{
                organizationState,
                setOrganizationState,
                setCurrentOrganizationState,
                setOrganizationsState,
                setPartialOrganizationItem,
            }}
        >
            {props.children}
        </OrganizationContext.Provider>
    );
};

export const useOrganizationContext = () => {
    return useContext(OrganizationContext);
};

export default OrganizationContextProvider;
