import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { KeyParamMapping } from "../../FilterParams/helper";

export type ContactParamType = "page" | "search";

export const IgnoreActiveFilters: (keyof ContactParams)[] = ["page"];
export const IgnoreFilterValues: (keyof ContactParams)[] = ["page"];

export interface ContactParams {
    search?: string;
    page?: number;
    sort?: string;
    sortAsc?: string;
}

export const ContactParamsKeyNames: KeyParamMapping<ContactParams, ContactParamType>[] = [
    { key: "page", param: "page" },
    { key: "search", param: "search" },
];

export const getContactParams = (params: ContactParams) => {
    let search = "?";

    ContactParamsKeyNames.forEach((item) => {
        const value = params[item.key];
        if (value) {
            const processed = value.toString();
            search += getSearchValue(item.param, processed);
        }
    });

    if (search[search.length - 1] === "&") {
        search = search.substring(0, search.length - 1);
    }
    return search;
};

export const calculateActiveParams = (productParams: ContactParams) => {
    let counter = ContactParamsKeyNames.filter((x) => !IgnoreActiveFilters.includes(x.key) && !!productParams[x.key]).length;
    return counter;
};

const getSearchValue = (key: keyof ContactParams, value: string) => {
    return `${key}=${encodeURIComponent(value)}&`;
};

export const useContactSearchParams = () => {
    const [urlParams, setParams] = useSearchParams();

    const setContactParams = (state: ContactParams) => {
        const search = getContactParams(state);
        setParams(search);
    };

    const getSearchParamsText = (state: ContactParams) => {
        const search = getContactParams(state);
        return search;
    };

    const getSearchParams = () => {
        let searchParams: ContactParams = {};
        ContactParamsKeyNames.forEach((item) => {
            const value = urlParams.get(item.param);
            if (value) {
                var processed = item.decode ? item.decode(value) : value;
                searchParams[item.key] = processed;
            }
        });

        searchParams.page = parseInt(urlParams.get("page") ?? "1") ?? 1;

        return searchParams;
    };

    const [contactParams, activeFilterCount] = useMemo(() => {
        const newParams = getSearchParams();
        const filterCount = calculateActiveParams(newParams);
        return [newParams, filterCount];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    return { contactParams, setContactParams, getSearchParamsText, activeFilterCount };
};
