/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { PrimaryButton } from "../_controls";
import { ViewContainerImage } from "./styled";

export interface EmptyResultGlobalProps {
    imageSize?: "sm" | "md" | "lg";
}

interface EmptyResultViewsProps extends EmptyResultGlobalProps {
    title: string;
    description: string;
    btnTitle?: string;
    btnOnClick?: () => void;
    btnLink?: string;
    image: any;
    notPadding?: boolean;
    btnIcon?: string;
}

export default function EmptyResultViews(props: EmptyResultViewsProps) {
    const { imageSize = "lg" } = props;

    return (
        <ViewContainerImage notPadding={props.notPadding}>
            {props.image && (
                <div className={`image ${imageSize}`}>
                    <img src={props.image} alt="confusedman" />
                </div>
            )}
            <div className="content">
                <h2 className="title">{props.title}</h2>
                <p className="subtitle">{props.description}</p>
                {props.btnLink && props.btnTitle && (
                    <div className="btncontainer">
                        <a target="_blank" href={props.btnLink}>
                            <PrimaryButton onClick={props.btnOnClick} rounded={true}>
                                <span className="wahioicon-external-link-alt"></span> {props.btnTitle}
                            </PrimaryButton>
                        </a>
                    </div>
                )}
                {!props.btnLink && props.btnOnClick && props.btnTitle && (
                    <div className="btncontainer">
                        <PrimaryButton onClick={props.btnOnClick} rounded={true}>
                            {props.btnIcon && <span>{props.btnIcon}</span>}
                            {props.btnTitle}
                        </PrimaryButton>
                    </div>
                )}
            </div>
        </ViewContainerImage>
    );
}
