import { StylesConfig } from "react-select";
import { SelectBaseOptionColor } from "../../api/models";
import chroma from "chroma-js";

export const selectColorStyles: StylesConfig<SelectBaseOptionColor, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color ?? "#818181");

        return {
            ...styles,
            backgroundColor: isDisabled ? undefined : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : undefined,
            color: isDisabled ? "#ccc" : isSelected ? (chroma.contrast(color, "white") > 2 ? "white" : "black") : data.color,
            cursor: isDisabled ? "not-allowed" : "default",

            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color ?? "#818181");
        return {
            ...styles,
            backgroundColor: data.color && data.color === "#000000" ? "#f3f3f37d" : color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
            backgroundColor: data.color,
            color: "white",
        },
    }),
};
