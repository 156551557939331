import _ from "lodash";
import { PrintType } from "../../appSettings/types";
import { AccountUser } from "./accountUser";
import { ICashRegister } from "./cashRegister";
import { IShippingLabelOrganization } from "./deliveryOrder";
import { IInvoiceDesign } from "./invoiceDesign";
import { OrderInvoiceNumeration } from "./orderInvoiceNumeration";
import { IWarehouse } from "./warehouse";

export const REQUEST_ORGANIZATIONS = "REQUEST_ORGANIZATIONS";
export const RECEIVE_ORGANIZATIONS = "RECEIVE_ORGANIZATIONS";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";

export const INVALID_REQUEST_ORGANIZATIONS = "INVALID_REQUEST_ORGANIZATIONS";

export interface IOrganizationTemplate {
    invoiceTemplateTitle1: string;
    invoiceTemplateTitle2: string;
    invoiceTemplateTitle3: string;
    invoiceTemplateTitle4: string;
}

export interface DefaultEBillingOption {
    resolutionOrderId?: string;
    resolutionDocumentSupportId?: string;
    resolutionOrderReturnId?: string;
}

export class DefaultEBillingOptionImpl implements DefaultEBillingOption {
    resolutionOrderId?: string = "";
    resolutionDocumentSupportId?: string = "";
    resolutionOrderReturnId?: string = "";
}

export const getOrganizationOmit = (org: IOrganization) => {
    const ignore = ["warehouses", "users", "cashRegisters", "defaultCashRegister", "shippingLabel"];
    return _.omit(org, ignore);
};

export interface OrganizationSettingsPartialInput {
    organizationId: string;
    body: OrganizationUpdateItemPartial;
}

export interface OrganizationUpdateItemPartial {
    settings?: OrganizationSettings;
    shippingLabel?: IShippingLabelOrganization;
}

export interface IOrganization extends IOrganizationTemplate {
    id?: string;
    accountId: string;
    invoiceDesignId: string;
    invoiceDesign?: IInvoiceDesign;
    warehouse?: IWarehouse;
    name: string;

    image?: string;
    imageFile: any;
    imageBytes: any;
    requiredCashRegister: boolean;
    defaultCashRegisterId: string;
    defaultCashRegister?: ICashRegister;
    users?: IOrganizationUser[];
    warehouses?: IOrganizationWarehouse[];

    ecommerceDefaultWarehouseId: string;
    ecommerceDefaultPrice: string;
    ecommerceShowStoreName: boolean;
    ecommerceShowOutOfStock: boolean;
    ecommerceShowStock: boolean;

    ecommerceHomeCategories?: IEcommerceHomeCategory[];
    ecommerceAdvertisingBanners?: IEcommerceAdvertisingBanner[];

    ecommerceAllowBuyOutOfStockProducts: boolean;
    ecommerceShowCategoriesInMenu: boolean;
    includeSocialNetworksInTracking: boolean;
    cashRegisters?: OrganizationCashRegister[];

    //address
    country: string;
    countryCode: string;
    state: string;
    stateCode: string;
    city: string;
    address: string;
    longitude: number;
    latitude: number;

    ecommerceFooter: string;
    storeEmail: string;
    storePhone: string;
    storePhonePrefix: string;
    storeAddress: string;
    storeWebsite?: string;

    socialFacebookLink: string;
    socialInstagramLink: string;
    socialTwitterLink: string;
    socialYoutubeLink: string;
    socialTiktokLink: string;
    settings?: OrganizationSettings;

    orderInvoiceNumerationId: string;
    orderInvoiceNumeration?: OrderInvoiceNumeration;

    shippingLabel?: IShippingLabelOrganization;

    wompiPublicKey?: string;
    wompiIntegritySecret?: string;
    paymentGatewayInTrackingNumber: boolean;
    defaultEBillingOptions?: DefaultEBillingOption;
}
export class Organization implements IOrganization {
    id?: string;
    accountId: string;
    defaultWarehouseId: string;
    invoiceDesignId: string;
    invoiceDesign?: IInvoiceDesign;
    warehouse?: IWarehouse;
    name: string;
    address: string;
    image: string;
    imageFile: any;
    imageBytes: any;
    requiredCashRegister: boolean = false;
    defaultCashRegisterId: string = "";
    defaultCashRegister?: ICashRegister;
    users?: IOrganizationUser[];
    warehouses?: IOrganizationWarehouse[];
    ecommerceDefaultWarehouseId: string = "";
    ecommerceDefaultPrice: string = "";
    ecommerceShowStoreName: boolean = false;

    country: string = "";
    countryCode: string = "";
    state: string = "";
    stateCode: string = "";
    city: string = "";
    longitude: number = 0;
    latitude: number = 0;

    ecommerceShowOutOfStock: boolean = false;
    ecommerceShowStock: boolean = false;

    ecommerceHomeCategories?: IEcommerceHomeCategory[];
    ecommerceAdvertisingBanners?: IEcommerceAdvertisingBanner[];
    includeSocialNetworksInTracking: boolean = false;

    ecommerceAllowBuyOutOfStockProducts: boolean = false;
    ecommerceShowCategoriesInMenu: boolean = false;

    ecommerceFooter: string = "";
    storeEmail: string = "";
    storePhone: string = "";
    storePhonePrefix: string = "";
    storeAddress: string = "";

    socialFacebookLink: string = "";
    socialInstagramLink: string = "";
    socialTwitterLink: string = "";
    socialYoutubeLink: string = "";
    socialTiktokLink: string = "";

    invoiceTemplateTitle1: string = "";
    invoiceTemplateTitle2: string = "";
    invoiceTemplateTitle3: string = "";
    invoiceTemplateTitle4: string = "";

    orderInvoiceNumerationId: string = "";
    orderInvoiceNumeration?: OrderInvoiceNumeration;

    shippingLabel?: IShippingLabelOrganization;
    wompiPublicKey?: string;
    paymentGatewayInTrackingNumber: boolean = false;
    defaultEBillingOptions?: DefaultEBillingOption;

    constructor(accountId: string, accountUserId: string) {
        this.name = "";
        this.image = "";
        this.address = "";
        this.accountId = accountId;
        this.defaultWarehouseId = "";
        this.invoiceDesignId = "";
        this.users = [{ accountUserId }];
    }
}

export interface IEcommerceHomeCategory {
    id?: string;
    categoryId: string;
    organizationId: string;
}

export interface OrganizationCashRegister {
    id: string;
    organizationId: string;
    cashRegisterId: string;
    cashRegister?: ICashRegister;
    organization?: IOrganization;
}

export interface OrganizationCashRegisterCreate {
    organizationId: string;
    cashRegisterId: string;
}

export interface IEcommerceAdvertisingBanner {
    id?: string;
    image: string;
    link: string;
    organizationId: string;
}

export interface IOrganizationUser {
    id?: string;
    accountUserId: string;
    accountUser?: AccountUser;
    organizationId?: string;
}
export interface IOrganizationWarehouse {
    id?: string;
    warehouseId: string;
    warehouse?: IWarehouse;
    organizationId?: string;
}

export interface TrackingBanner {
    imageUrl: string;
    link: string;
}

export interface OrganizationSettings {
    orderPrint?: OrderPrintSettings;
    purchasePrint?: PurchasePrintSettings;
    trackingSettings?: TrackingSettings;
}

export interface TitleTemplate {
    title1?: string;
    title2?: string;
    title3?: string;
}

export interface OrderPrintSettings {
    titleTemplate?: TitleTemplate;
    printType?: PrintType;
    disabledFields?: DisabledPrintFields;
}

export interface TrackingSettings {
    showSocialNetworks?: boolean;
    showBanners?: boolean;
    banners?: TrackingBanner[];
    bannerAutoplay?: boolean;
    bannerAutoplayDelay?: number;
}
export class TrackingSettingsImpl implements TrackingSettings {
    showSocialNetworks?: boolean;
    showBanners?: boolean;
    banners?: TrackingBanner[];
    bannerAutoplay?: boolean;
    bannerAutoplayDelay?: number;
}

const OrgTemplateTitleField: (keyof IOrganization)[] = [
    "invoiceTemplateTitle1",
    "invoiceTemplateTitle2",
    "invoiceTemplateTitle3",
    "invoiceTemplateTitle4",
];

export class OrderPrintSettingsImpl implements OrderPrintSettings {
    titleTemplate?: TitleTemplate = {};
    printType?: PrintType = "other";
    disabledFields?: DisabledPrintFields = {};

    constructor(org?: IOrganization) {
        if (org) {
            let newTemp: TitleTemplate = {};
            OrgTemplateTitleField.forEach((field) => {
                if (org[field]) {
                    let key = field.replaceAll("invoiceTemplate", "").toLowerCase() as keyof TitleTemplate;
                    if (key) {
                        newTemp[key as keyof TitleTemplate] = org[field];
                    }
                }
            });
        }
    }
}

export class DisabledPrintFields {
    qrTracking?: boolean;
    itemsCount?: boolean;

    orderStatus?: boolean;
    orderNumber?: boolean;
    eBillingNumber?: boolean;
    invoiceNumber?: boolean;

    orderDate?: boolean;
    invoiceDate?: boolean;
    dueDate?: boolean;

    user?: boolean;
    seller?: boolean;

    sign?: boolean;

    itemSku?: boolean;
    itemTax?: boolean;
    itemDiscount?: boolean;
    itemReference?: boolean;
    itemCode?: boolean;
    paymentsDate?: boolean;
}

export interface PurchasePrintSettings {}

export interface IOrganizationState {
    items: IOrganization[];
    currentOrganization?: IOrganization;
}
export interface RequestOrganizations {
    type: typeof REQUEST_ORGANIZATIONS;
    isAdmin: boolean;
}
export interface SetCurrentOrganization {
    type: typeof SET_CURRENT_ORGANIZATION;
    value?: IOrganization;
}
export interface ReceiveOrganizations {
    type: typeof RECEIVE_ORGANIZATIONS;
    items: IOrganization[];
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_ORGANIZATIONS;
    value: any;
}

export type OrganizationTypes = RequestOrganizations | ReceiveOrganizations | InvalidRequest | SetCurrentOrganization;
