import React, { createContext, useState } from "react";
import { useMutation } from "react-query";
import { getEBillingResolutions } from "../../api/eBillings/eBillingApi";
import { EBillingAccountCompanyResolution, EBillingGetAccountCompany } from "../../api/models/electronicBilling";
import { useUserContext } from "./UserContext";

export interface EBillingState {
    resolutions: EBillingAccountCompanyResolution[];
    resolutionsIsLoading: boolean;
}

interface EBillingContextProps {
    eBillingState: EBillingState;
    setEBillingState: (value: EBillingState) => void;
    loadResolutions: () => void;
}

export const EBillingContext = createContext({} as EBillingContextProps);

const EBillingContextProvider = (props: any) => {
    const { userState } = useUserContext();
    const [eBillingState, setEBillingState] = useState<EBillingState>({
        resolutions: [],
        resolutionsIsLoading: false,
    });

    const resolutionsMutation = useMutation((data: EBillingGetAccountCompany) => getEBillingResolutions(data));

    const loadResolutions = async () => {
        if (resolutionsMutation.isLoading || eBillingState.resolutions.length > 0) return;

        const account = userState.user?.account;
        if (account && account.companyNit) {
            setEBillingState({ ...eBillingState, resolutionsIsLoading: true });
            await resolutionsMutation
                .mutateAsync({ accountId: account.id, nit: account.companyNit })
                .then((data) => {
                    setEBillingState({ ...eBillingState, resolutionsIsLoading: false, resolutions: data });
                })
                .catch((er) => {
                    setEBillingState({ ...eBillingState, resolutionsIsLoading: false });
                });
        }
    };

    return (
        <EBillingContext.Provider
            value={{
                eBillingState,
                setEBillingState,
                loadResolutions,
            }}
        >
            {props.children}
        </EBillingContext.Provider>
    );
};

export const useEBillingContext = () => {
    return React.useContext(EBillingContext);
};

export default EBillingContextProvider;
