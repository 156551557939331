import { ITaxOrderItemBase, OrderBaseItem, convertTaxOrderItem } from "../../api/models/orderBase";
import { IProduct } from "../../api/models/product";
import { ITax } from "../../api/models/tax";

export const getValueWithoutTax = (value: number, percentage: number) => {
    const percentageCalculate = percentage / 100 + 1;
    return value / percentageCalculate;
};

export const extractTaxInformation = (taxes: ITaxOrderItemBase[]) => {
    let taxPercentage = 0;
    let taxAmount = 0;

    if (taxes && taxes.length > 0) {
        taxes.forEach((productTax) => {
            if (productTax.taxIsPercentage) {
                taxPercentage += productTax.taxPercentage;
            } else {
                taxAmount += productTax.taxValue;
            }
        });
    }

    return [taxPercentage, taxAmount];
};

interface BasePriceResponse {
    basePrice: number;
    basePricePreTaxDiscount: number;
}

export const calculateItemBasePrice = (
    orderItem: OrderBaseItem,
    taxes: ITaxOrderItemBase[],
    taxIncluded: boolean,
    preTaxDiscount: boolean
): BasePriceResponse => {
    let [taxPercentage, taxAmount] = extractTaxInformation(taxes);
    let basePrice = orderItem.unitPrice;

    let basePricePreTaxDiscount = basePrice;

    if (taxIncluded) {
        basePrice = basePrice / (taxPercentage / 100 + 1);
        basePrice -= taxAmount;

        if (preTaxDiscount) {
            let resultDiscount = basePrice * (orderItem.manualPercentageDiscount / 100);
            basePricePreTaxDiscount = basePrice - resultDiscount;
        }
    } else {
        basePrice = basePrice * (taxPercentage / 100 + 1);
        basePrice += taxAmount;

        let resultDiscount = orderItem.unitPrice * (orderItem.manualPercentageDiscount / 100);
        basePricePreTaxDiscount = orderItem.unitPrice - resultDiscount;
    }
    basePrice = basePrice < 0 ? 0 : basePrice;

    const result: BasePriceResponse = {
        basePrice,
        basePricePreTaxDiscount,
    };

    return result;
};

export const applyTaxToValue = (basePrice: number, percentage: number, additionalTax: number = 0) => {
    basePrice = basePrice * (percentage / 100 + 1);
    basePrice += additionalTax;
    return basePrice;
};

// item debe incluir el precio base calculado.
export const getItemTotalTax = (taxes: ITaxOrderItemBase[], item: OrderBaseItem, taxIncluded: boolean, preTaxDiscount: boolean) => {
    let [taxPercentage, taxAmount] = extractTaxInformation(taxes);
    let basePrice = taxIncluded ? item.basePrice : item.unitPrice;

    if (preTaxDiscount) {
        basePrice = item.basePricePreTaxDiscount;
    }

    let newValue = applyTaxToValue(basePrice, taxPercentage, taxAmount);
    return newValue - basePrice;
};

export const getItemTaxesFromProduct = (base: number, product?: IProduct): ITaxOrderItemBase[] => {
    if (!!product?.taxes) {
        let productTaxes: ITaxOrderItemBase[] = [];
        product.taxes.forEach((productTax) => {
            productTaxes.push(convertTaxOrderItem(productTax.tax, base, true));
        });
        return productTaxes;
    }
    return [];
};

export const getItemTaxesFromTaxes = (base: number, taxes: ITax[]): ITaxOrderItemBase[] => {
    if (taxes && taxes.length > 0) {
        let productTaxes: ITaxOrderItemBase[] = [];
        taxes.forEach((tax) => {
            productTaxes.push(convertTaxOrderItem(tax, base, true));
        });
        return productTaxes;
    }
    return [];
};
