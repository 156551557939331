import React, { useContext } from "react";
import Joyride, { Props } from "react-joyride";
import { ThemeContext } from "styled-components";

interface DefaultJoyrideProps extends Props {
    defaultBackground?: string;
    small?:boolean;
}

export default function DefaultJoyride(props: DefaultJoyrideProps) {
    const themeContext = useContext(ThemeContext);

    const background = props.defaultBackground ?? "#304FFE";

    return (
        <Joyride
            styles={{
                options: {
                    arrowColor: props.defaultBackground ?? themeContext.palette.background,
                    backgroundColor: props.defaultBackground ?? themeContext.palette.background,
                    beaconSize: 36,
                    overlayColor: "rgba(0, 0, 0, 0.5)",
                    primaryColor: background,
                    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                    textColor: props.defaultBackground ? "white" : themeContext.palette.text,
                    width: undefined,
                    zIndex: 100,
                },
                buttonNext: {
                    fontWeight: 500,
                    borderRadius: "10px",
                    padding: "10px 15px",
                    background: background,
                },
                tooltip: {
                    borderRadius: "20px",
                    background: props.defaultBackground ?? themeContext.palette.background,
                },
            }}
            {...props}
        />
    );
}
