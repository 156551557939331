import React, { useState } from "react";
import { DefaultButton, DefaultInput, Flex, PrimaryButton } from "../../_controls";
import Modal, { IModalShow } from "../Modal";
import {  MainContainer } from "./styled";
import { useEventListenerDocument } from "../..";

interface ConfirmationDetails {
    title?: string;
    description: string;
    subTitle?: string;
    showMessage?: boolean;
    placeholder?: string;
    initialMessage?: string;
    onOkay: (message?: string) => void;
    onCancel?: () => void;
    okayTitle?: string;
    cancelTitle?: string;
    hideButtonCancel?: boolean;
    classNameTitle?: string;
    zIndex?: number;
    ignoreOutsideClick?: boolean;
    trigger?: React.ReactNode;
}

interface ConfirmationModalProps extends IModalShow, ConfirmationDetails {}

export const useConfirmationModal = () => {
    const [showModal, setShow] = useState(false);

    const show = (value?: boolean) => {
        setShow(value !== undefined ? value : true);
    };
    const hide = () => {
        setShow(false);
    };

    const Modal = (props: ConfirmationDetails) => (showModal ? <ConfirmationModal {...props} show={showModal} setShow={setShow} /> : <></>);
    return { Modal, show, hide };
};

export default function ConfirmationModal(props: ConfirmationModalProps) {
    const [message, setMessage] = useState(props.initialMessage ?? "");

    const onOkay = () => {
        props.setShow(false);
        props.onOkay(message);
    };

    useEventListenerDocument("keydown", (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onOkay();
        }
    });

    return (
        <Modal ignoreOutsideClick={props.ignoreOutsideClick} zIndex={props.zIndex} {...props} showHeader={false} title="">
            <MainContainer>
                <Flex column alignCenter gap10>
                    <h2 className={`confirmation-title ${props.classNameTitle}`}>{props.title}</h2>
                    {props.subTitle && <h4 className="text-bold m-0">{props.subTitle}</h4>}
                    <p className="m-0">{props.description}</p>
                </Flex>

                {props.trigger && <Flex marginTop={20}>{props.trigger}</Flex>}
                {props.showMessage && (
                    <Flex marginTop={20}>
                        <DefaultInput
                            className="input-comment"
                            autoFocus
                            placeholder={props.placeholder ?? "Comentario"}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                    </Flex>
                )}

                <Flex gap10 marginTop={25}>
                    {!props.hideButtonCancel && (
                        <DefaultButton
                            borderRadius={10}
                            bgLight
                            type="button"
                            w100
                            onClick={() => {
                                if (props.onCancel) {
                                    props.onCancel();
                                } else {
                                    props.setShow(false);
                                }
                            }}
                            className="cancel"
                        >
                            {props.cancelTitle ?? "Cancelar"}
                        </DefaultButton>
                    )}
                    <PrimaryButton w100 borderRadius={10} onClick={onOkay} className="ml-1" type={"submit"}>
                        {props.okayTitle ?? "Aceptar"}
                    </PrimaryButton>
                </Flex>
            </MainContainer>
        </Modal>
    );
}
