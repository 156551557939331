import React, { useContext, useEffect, useState } from "react";
import { SelectBody } from "../../Select/styled";
import Select from "react-select";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { AccountUser } from "../../../api/models/accountUser";
import { getSelectOption } from "../../../utils/select";
import { getModelFullName } from "../../../utils";

interface UserSelectorProps {
    user?: AccountUser;
    userId?: string;
    onChange: (user?: AccountUser) => void;
    placeholder?: string;
    title?: string;
}

export default function UserSelector(props: UserSelectorProps) {
    const { accountUserState, accountUserActions, getUserById } = useContext(AccountUserContext);

    const [user, setUser] = useState(props.user);

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    useEffect(() => {
        if (!props.user) {
            setUser(getUserById(props.userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userId, accountUserState.items]);

    useEffect(() => {
        accountUserActions.requestAccountUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectBody className="select-custom" selectMinWidth={150}>
            {props.title && <span className="label">{props.title}</span>}
            <Select
                placeholder={props.placeholder ?? "Creado Por"}
                className={`select-min-len ${user ? "item-active" : ""}`}
                classNamePrefix="select"
                key={user?.id}
                defaultValue={
                    user
                        ? getSelectOption({
                              id: user.id ?? "",
                              value: getModelFullName(user),
                          })
                        : undefined
                }
                isDisabled={false}
                isClearable
                onChange={(value) => props.onChange(accountUserState.items.find((x) => x.id === value?.id))}
                isRtl={false}
                isSearchable={true}
                options={accountUserState.items.map((x) => getSelectOption({ id: x.id ?? "", value: getModelFullName(x) }))}
            />
        </SelectBody>
    );
}
