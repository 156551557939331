import React, { useEffect, useState } from "react";
import { CloudImages } from "../../../assets";

import { compressImage } from "../../../utils/images";
import { ImageContainer } from "./styled";

export interface IImageFile {
    file: any;
    bytes: any;
    hasImage: boolean;
    id: string;
}
export class ImageFileDefault implements IImageFile {
    file: any;
    bytes: any = CloudImages.defaultImage;
    hasImage: boolean = false;
    id: string;
    constructor(id: string, bytesValue?: any) {
        this.id = id;
        this.bytes = bytesValue ? bytesValue : CloudImages.defaultImage;
    }
}

interface IProps {
    id: string;
    width: number;
    height: number;
    defaultValue?: any;
    onChange: (value: IImageFile) => void;
    onRemove: (value: IImageFile) => void;
    className?: string;
}
export default function InputSelectableImage(props: IProps) {
    const [state, setState] = useState(new ImageFileDefault(props.id, props.defaultValue));

    useEffect(() => {
        setState(new ImageFileDefault(props.id, props.defaultValue));
    }, [props.defaultValue, props.id]);

    const fileSelecteHandler = (e: any) => {
        const input = e.target;
        if (input.files && input.files[0]) {
            compressImage(input.files[0], (file, result) => {
                let newState = { ...state, bytes: result, file, hasImage: true };
                setState(newState);
                props.onChange(newState);
            });
        }
    };
    const onRemove = (e: any) => {
        setState({ ...state, bytes: CloudImages.defaultImage, hasImage: false });
        props.onRemove(state);
    };
    return (
        <ImageContainer style={{ width: props.width, height: props.height }}>
            <label className="image-label" htmlFor={props.id}>
                <img src={state.bytes} alt="" />
            </label>
            <input id={props.id} type="file" onChange={fileSelecteHandler} />
            {state.bytes !== CloudImages.defaultImage ? (
                <div className="image-options">
                    <div className="option">
                        <button className="btn" onClick={() => setState({ ...state, bytes: CloudImages.defaultImage })}>
                            <span className="wahioicon-camera"></span>
                        </button>
                    </div>
                    <div className="option">
                        <button className="btn" onClick={onRemove}>
                            <span className="wahioicon-trash"></span>
                        </button>
                    </div>
                </div>
            ) : null}
        </ImageContainer>
    );
}
