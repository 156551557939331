import styled from "styled-components";

export const UserSimpleListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;

    .user-card {
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .image {
            width: 40px;
            height: 40px;
            border-radius: 10px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .col {
            display: flex;
            flex-direction: column;
        }
        .light {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 0.9rem;
        }
    }
`;

export const UserSelectableListContainer = styled.div`
    display: flex;
    flex-direction: column;

    .user-card {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
        .image {
            width: 40px;
            height: 40px;
            border-radius: 10px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .col {
            display: flex;
            flex-direction: column;
        }
        .light {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 0.9rem;
        }
    }
    .hover {
        &:hover {
            background-color: ${(props) => props.theme.palette.primaryLight};
            border-radius: 15px;
        }
    }

    .selected {
        background-color: ${(props) => props.theme.palette.primaryLight};
        border-radius: 15px;
    }
`;

export const UserRowTr = styled.tr`
    cursor: pointer;
    td {
        border-bottom: 0;
        :first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        :last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
    &:hover {
        td {
            background: ${(props) => props.theme.palette.primary}20;
        }
    }

    .align-right {
        text-align: right;
    }
    .align-center {
        text-align: center;
    }
    align-left {
        text-align: left;
    }
    &.disabled {
        td {
            background: ${(props) => props.theme.palette.backgroundLight}20;
        }
        .user-image,
        .user-title,
        .user-status {
            opacity: 0.4;
        }
    }
    .user-status {
        padding: 2px 20px;
        border-radius: 10px;
        display: flex;
        justify-content: end;
        font-size: 13px;
    }
`;