import { defineMessages } from "react-intl";

const base = "components.orderList";

export default defineMessages({
    orders: {
        id: `${base}.orders`,
        defaultMessage: "Órdenes",
    },
    searchOrders: {
        id: `${base}.searchOrders`,
        defaultMessage: "Buscar ventas",
    },
    results: {
        id: `${base}.results`,
        defaultMessage: "Resultados",
    },
    moreItems: {
        id: `${base}.moreItems`,
        defaultMessage: "Más items",
    },
    status: {
        id: `${base}.status`,
        defaultMessage: "Estado",
    },
    customer: {
        id: `${base}.customer`,
        defaultMessage: "Cliente",
    },
    createdAt: {
        id: `${base}.createdAt`,
        defaultMessage: "Creado",
    },
    updatedAt: {
        id: `${base}.updatedAt`,
        defaultMessage: "Actualizado",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    pageSale: {
        id: `${base}.pageSale`,
        defaultMessage: "Ventas",
    },
    pageShipment: {
        id: `${base}.pageShipment`,
        defaultMessage: "Pedidos",
    },
    pageQueued: {
        id: `${base}.pageQueued`,
        defaultMessage: "En Cola",
    },
    pageVoid: {
        id: `${base}.pageVoid`,
        defaultMessage: "Anulado",
    },
    pageDraft: {
        id: `${base}.pageDraft`,
        defaultMessage: "Borrador",
    },
    pageQuotation: {
        id: `${base}.pageQuotation`,
        defaultMessage: "Cotización",
    },
    pageRestaurant: {
        id: `${base}.pageRestaurant`,
        defaultMessage: "Restaurante",
    },
    createOrder: {
        id: `${base}.createOrder`,
        defaultMessage: "Crear venta",
    },
    createShipment: {
        id: `${base}.createShipment`,
        defaultMessage: "Crear Pedido",
    },
    editOrder: {
        id: `${base}.editOrder`,
        defaultMessage: "Editar orden",
    },
    changesSavedSuccessfully: {
        id: `${base}.changesSavedSuccessfully`,
        defaultMessage: "Los cambios se guardaron correctamente",
    },
    void: {
        id: `${base}.void`,
        defaultMessage: "Anulado",
    },
    paid: {
        id: `${base}.paid`,
        defaultMessage: "Pagado",
    },
    credit: {
        id: `${base}.credit`,
        defaultMessage: "Crédito",
    },
    open: {
        id: `${base}.open`,
        defaultMessage: "Order Abierta",
    },
    partial: {
        id: `${base}.partial`,
        defaultMessage: "Pago parcial",
    },
    queued: {
        id: `${base}.queued`,
        defaultMessage: "En cola",
    },
    draft: {
        id: `${base}.draft`,
        defaultMessage: "Borrador",
    },
    authorized: {
        id: `${base}.authorized`,
        defaultMessage: "Authorizado",
    },
    declined: {
        id: `${base}.declined`,
        defaultMessage: "Rechazada",
    },
    invalid: {
        id: `${base}.invalid`,
        defaultMessage: "Estado invalido",
    },
    shippingStatus: {
        id: `${base}.shippingStatus`,
        defaultMessage: "Estado Pedido",
    },
    startDate: {
        id: `${base}.startDate`,
        defaultMessage: "Desde",
    },
    endDate: {
        id: `${base}.endDate`,
        defaultMessage: "Hasta",
    },
    contact: {
        id: `${base}.contact`,
        defaultMessage: "Contacto",
    },
    paymentMethod: {
        id: `${base}.paymentMethod`,
        defaultMessage: "Método",
    },
    user: {
        id: `${base}.user`,
        defaultMessage: "Usuario",
    },
    seller: {
        id: `${base}.seller`,
        defaultMessage: "Vendedor",
    },
    courier: {
        id: `${base}.courier`,
        defaultMessage: "Mensajero",
    },
    invoiceNumeration: {
        id: `${base}.invoiceNumeration`,
        defaultMessage: "Numeración",
    },
    supplier: {
        id: `${base}.supplier`,
        defaultMessage: "Proveedor",
    },
    optionals: {
        id: `${base}.optionals`,
        defaultMessage: "Opcionales",
    },
    availableStock: {
        id: `${base}.availableStock`,
        defaultMessage: "Stock Disponible",
    },
    additionalOptionals: {
        id: `${base}.additionalOptionals`,
        defaultMessage: "Opciones Extra",
    },
    unitPrice: {
        id: `${base}.unitPrice`,
        defaultMessage: "Precio Unitario",
    },
    prices: {
        id: `${base}.prices`,
        defaultMessage: "Precios",
    },
    discounts: {
        id: `${base}.discounts`,
        defaultMessage: "Descuentos",
    },
    taxes: {
        id: `${base}.taxes`,
        defaultMessage: "Impuestos",
    },
    discount: {
        id: `${base}.discount`,
        defaultMessage: "Descuento",
    },
    tax: {
        id: `${base}.tax`,
        defaultMessage: "Impuesto",
    },
    quotation: {
        id: `${base}.quotation`,
        defaultMessage: "Cotización",
    },
    shipment: {
        id: `${base}.shipment`,
        defaultMessage: "Pedido",
    },
    sale: {
        id: `${base}.sale`,
        defaultMessage: "Venta",
    }
})
