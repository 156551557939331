import styled from "styled-components";

export const FinalizarContainer = styled.div`
    padding: 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
    .light {
        color: ${(props) => props.theme.palette.textLight};
    }

    .small {
        font-size: 13px;
    }

    .min-width {
        width: 1%;
        padding-right: 7px;
    }

    .text-warning {
        color: #ff9800;
    }

    .btn-add-payment {
        border-radius: 50px;
    }
    .btn-extra {
        border-radius: 50px;
        padding: 0.3rem 13px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .subtotal {
        font-size: 13px;
        text-align: right;
        width: 100%;
        display: block;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    grid-gap: 10px;
    align-items: center;
`;

export const FlexSuggestion = styled(FlexContainer)`
    display: flex;
    grid-gap: 10px;
    align-items: center;
    flex-wrap: wrap;
`;

export const AddPaymentView = styled(FlexContainer)`
    justify-content: space-between;
    margin-top: 0.7rem;
`;

export const AddChangeView = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    .positive {
        color: ${(props) => props.theme.palette.primary};
    }
    .negative {
        color: #f44336;
    }
    input {
        border-radius: 50px;
        padding-left: 15px;
        font-weight: 500;
    }
    .suggestion {
        padding: 3px 8px;
        border-radius: 50px;
        font-size: 13px;
        font-weight: 400;
        background: transparent;
        color: ${(props) => props.theme.palette.textLight};
        border: transparent;
    }
    p {
        margin: 0;
    }
    .suggestionbody {
        border-radius: 23px;
        padding: 5px 15px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const AmountContainer = styled.div`
    display: flex;
    background: ${(props) => props.theme.palette.backgroundLight};
    border-radius: 8px;
    padding: 12px;
    margin-top: 7px;
    align-items: center;
    justify-content: space-between;
    .total {
        font-size: 1.5rem;
    }
`;

export const PaymentRowContainer = styled.div`
    display: flex;
    .dropbtn,
    input {
        font-weight: 400;
        border: 0px;
        border-radius: 0px;
        &:hover {
            background: transparent;
        }
    }
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 20px;
    align-items: center;
    grid-gap: 5px;
    padding-right: 16px;
    input {
        padding-left: 0.8rem;
        &:focus {
            background: transparent;
        }
    }
    button {
        color: ${(props) => props.theme.palette.textLight};
    }
    .action {
        width: 25px;
        height: 25px;
        min-width: 25px;
        background: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const PaymentRowBody = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    input {
        width: 100%;
        font-weight: 500;
    }
`;

export const ActionsButtonsContainer = styled.div`
    margin-top: 14px;
    margin-bottom: 14px;
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    align-items: center;
    button {
        width: 100%;
    }
    .cancel {
        background: ${(props) => props.theme.palette.backgroundLight};
        border-color: transparent;
        color: ${(props) => props.theme.palette.textLight};
    }
    .btn-primary {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        border-color: transparent;
    }
    .btn-warning {
        background: #ff9800;
        color: white;
        border-color: transparent;
    }
    .btn-danger {
        background: #f44336;
        color: white;
        border-color: transparent;
    }
`;

export const PaymentMethodsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

    button {
        padding: 8px;
        width: 100%;
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.text};
        justify-content: flex-start;
        padding-left: 16px;
        border: 0;
        font-weight: 400;
    }
`;

export const ExtraPaymentRow = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;

    input {
        width: 100%;
        border: 0px;
        background: transparent;
        border-radius: 0px;
        padding: 7px;
        font-weight: 500;
        text-align: right;
    }
    .row-title {
        width: 250px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const SuccessContainer = styled.div`
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        font-size: 3rem;
    }
    p,
    h2 {
        margin: 0;
    }

    h2 {
        margin-top: 2rem;
    }
    p {
        margin-bottom: 14px;
    }
`;
