import { wahioFetch } from "../../../api";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { IProduct, IProductVariant, ProductVariantImp, VariantOptionValue } from "../../../api/models/product";
import { IProductCategory } from "../../../api/models/productCategory";
import { getUniqueId } from "../../../utils";

export class CreateCustomCategory {
    isFetching: boolean = false;
    run = (category: IProductCategory, onSuccess: (category: IProductCategory) => void, onFailed: (err: any) => void) => {
        this.isFetching = true;
        wahioFetch.post(
            productEndpoint.post.customCategory,
            category,
            (success) => {
                onSuccess(success.data);
                this.isFetching = false;
            },
            (error) => {
                onFailed(error);
                this.isFetching = false;
            }
        );
    };
}

export const getProductTypeTitle = (product: IProduct) => {
    let title = product.type === "service" ? "Servicio" : "Producto";
    if (product.isParent) {
        title += ": Compuesto";
    }
    if (product.includesVariants) {
        title += ": Variante";
    }
    if (product.includesOptionals) {
        title += ": Opcional";
    }
    if (product.includesSerials) {
        title += ": Serial";
    }

    return title;
};

export const loadExistingProduct = (product: IProduct) => {
    if (product.variantOptions) {
        product.variantOptions.forEach((element) => {
            element.key = getUniqueId();
        });
    }

    return product;
};

export const updateProductVariantLines = (productVariant: IProductVariant) => {
    productVariant.valuesLine = productVariant.options.map((item) => item.value).join("-");
    productVariant.variantsLine = productVariant.options.map((item) => item.variantName).join("-");
    return productVariant;
};

export const mergeProductVariants = (existingVariants: IProductVariant[], newProductVarianst: IProductVariant[]) => {
    let variantsArrayCopy: ProductVariantImp[] = [];
    newProductVarianst.forEach((variant) => {
        let existing = existingVariants.find((x) => x.valuesLine === variant.valuesLine);
        if (existing) {
            existing = updateProductVariantOptionsNames(existing, variant);
            variantsArrayCopy.push(existing);
        } else variantsArrayCopy.push(variant);
    });
    return variantsArrayCopy;
};

const updateProductVariantOptionsNames = (existingVariant: IProductVariant, newVariant: IProductVariant) => {
    let valuesCopy: VariantOptionValue[] = [];
    existingVariant.options.forEach((element) => {
        let newVariantName = newVariant.options.find((x) => x.value === element.value)?.variantName;
        if (newVariantName) {
            element.variantName = newVariantName;
        }
        valuesCopy.push(element);
    });
    existingVariant.options = valuesCopy;
    existingVariant = updateProductVariantLines(existingVariant);

    return existingVariant;
};

export const PRODUCT_OPTIONALS: string[] = [
    "Tamaño",
    "Sabor",
    "Salsa",
    "Bebida",
    "Acompañamiento",
    "Aderezo",
    "Cobertura",
    "Ingrediente",
    "Tipo de topping",
    "Guarnición",
    "Corte",
    "Cocción",
];

export const PRODUCT_FEATURES: string[] = [
    "Marca",
    "Modelo",
    "Peso",
    "Dimensiones",
    "Material",
    "Talla",
    "Color",
    "Capacidad",
    "Procesador",
    "Memoria",
    "Resolución",
    "Sistema operativo",
    "Conectividad",
    "Tipo de pantalla",
    "Batería",
    "Cámara",
    "Funciones especiales",
    "Garantía",
    "Accesorios incluidos",
    "Compatibilidad",
    "Calificación energética",
    "Certificaciones",
    "Instrucciones",
    "Comentarios",
    "Disponibilidad",
    "Puntuación",
    "Tiempo de entrega",
    "Tipo de envío",
    "Instrucciones de cuidado",
    "Edad recomendada",
    "Origen",
    "Tipo de garantía",
    "Nivel de ruido",
    "Compatibilidad",
    "Interfaz",
    "Sensor de movimiento",
    "Temperatura",
    "Estilo de diseño",
    "Tipo de teclado",
];
