import React, { useState } from "react";
import LoadingDualRing from "../LoadingDualRing";
import { NavigationCardStyled, NavigationTitle } from "../Products/ProductProfileNavigation/styled";
import { Flex, PrimaryButton, SquareButton, TextField } from "../_controls";

export interface NavigationTabProps {
    title?: string;
    subTitle?: string;
    allowClose?: boolean;
    defaultClose?: boolean;
}

interface NavigationCardProps extends NavigationTabProps {
    children?: React.ReactNode;
    actionsComponent?: React.ReactNode;
    hideHeader?: boolean;
}

export default function NavigationCard(props: NavigationCardProps) {
    const [isClose, setIsClose] = useState(!!props.defaultClose);
    const isContentHidden = props.allowClose && isClose;

    return (
        <NavigationCardStyled className={`navigation-card ${props.allowClose ? "allow-close" : ""}`}>
            {!props.hideHeader && props.title && (
                <div className="card-header" onClick={() => setIsClose(!isClose)}>
                    <Flex column>
                        <NavigationTitle>{props.title}</NavigationTitle>
                        {props.subTitle && (
                            <TextField small light>
                                {props.subTitle}
                            </TextField>
                        )}
                    </Flex>
                    <Flex gap10 alignCenter>
                        {props.actionsComponent}
                        {props.allowClose && (
                            <SquareButton>
                                <i className={!isClose ? "fa-regular fa-angle-down" : "fa-regular fa-angle-left"}></i>
                            </SquareButton>
                        )}
                    </Flex>
                </div>
            )}
            {!isContentHidden && <div className="card-content">{props.children}</div>}
        </NavigationCardStyled>
    );
}
interface NavigationActionsProps {
    onSave: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}
export const NavigationActions = (props: NavigationActionsProps) => {
    return (
        <Flex gap10 alignCenter>
            <PrimaryButton borderRadius={10} disabled={props.disabled || props.isLoading} onClick={() => props.onSave()}>
                Guardar {props.isLoading && <LoadingDualRing small />}
            </PrimaryButton>
        </Flex>
    );
};

interface NavigationCardGroupProps<T extends string> {
    children?: React.ReactNode;
    selectedGroup?: T;
    groupName: T;
}
export const NavigationCardGroup = <T extends string>(props: NavigationCardGroupProps<T>) => {
    const { selectedGroup, groupName } = props;

    if (selectedGroup !== groupName) {
        return null;
    }

    return <>{props.children}</>;
};
