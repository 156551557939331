import styled from "styled-components";

export const SearchTabFilterStyle = styled.div`
    position: sticky;
    top: 0;
    padding: 10px 10px;
    background: ${(props) => props.theme.palette.background};
    z-index: 2;

    display: flex;
    flex-direction: column;

    .ml-auto {
        margin-left: auto;
    }

    .search-input-filter {
        border-radius: 30px;
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        transition: width 0.2s ease-in-out;

        &:focus {
            border-color: ${(props) => props.theme.palette.primary}50;
            outline: 2px solid ${(props) => props.theme.palette.primary}30;
        }
        font-weight: 600;
        ::placeholder {
            font-weight: 400;
        }
    }

    @media (max-width: 700px) {
        .hide-mobile {
            display: none;
        }

        .search-input {
            width: 100%;
            max-width: 100%;
        }

        .search-input-filter {
            width: 100%;
        }
    }
`;

export const SearchInputContainerFilter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    .search-filter-component {
        button {
            border: 0;
        }
        position: absolute;
        right: 1px;
    }
`;
