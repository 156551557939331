import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const AccountBillingConfigContainer = styled.div`
    width: 100%;
    max-width: 900px;
    margin: auto;
    margin-top: 20px;
`;
export const PlanLabelLinkContainer = styled(NavLink)`
    width: 100%;
    margin: 5px 15px;
    text-align: center;
`;
export const PlanLabelLink = styled.div`
    padding: 7px 25px;
    display: flex;
    gap: 10px;
    align-items: center;
    background: linear-gradient(90deg, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 208, 1) 100%);
    cursor: pointer;
    color: white;
    justify-content: center;
    :visited {
        color: white;
    }
    border-radius: 33px;
    font-weight: 500;
    text-align: center;
    transition: padding 0.3s ease-in-out;
    &.animate {
        .title {
            display: block;
        }
        .upgrade {
            display: none;
        }
    }
    &.small{
        font-size: 12px;
        padding: 5px 11px;
    }
    &:hover {
        .title {
            display: none;
        }
        .upgrade {
            display: block;
        }
        &.animate {
            padding: 7px 45px;
        }
    }
`;

export const WhatsappButtonLink = styled.a`
    background: #00c853;
    padding: 5px 20px;
    color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background 0.3s ease-in-out;
    &:visited {
        color: white;
    }
    :hover {
        background: #00c85380;
    }
    .help-icon {
        font-size: 25px;
    }
`;

export const AccountPlanHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    line-height: 1.2;
    align-items: baseline;
    font-weight: 400;
    padding: 15px 15px;
    border-radius: 15px;
    color: ${(props) => props.theme.palette.text};
    position: relative;
    height: 165px;
    justify-content: space-between;

    .title {
        font-weight: 500;
    }
    .btn-action {
        width: 100%;
        margin-top: 15px;
        transition: background 0.2s;
    }
    .price {
        font-size: 35px;
        font-weight: 600;
        line-height: 0.9;
        padding-top: 10px;
    }
    .price-line {
        text-decoration: line-through;
    }
    .discount-label {
        background: #e91e63;
        color: white;
        font-size: 13px;
        font-weight: 500;
        border-radius: 16px;
        padding: 2px 7px;
    }
    &.basic {
    }
    &.standard {
    }
    &.premium {
    }
`;

export const AccountPlanCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .small-description {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }

    .items {
        padding: 0px 10px;
        .item {
            padding: 6px 0px;
        }
    }
`;

export const AccountPlanTable = styled.table`
    width: 100%;
    th {
        text-align: center;
        font-weight: 500;
        :first-child {
            text-align: left;
        }
    }
    td {
        min-width: 100px;
        text-align: center;
        padding: 7px 0px;
        :first-child {
            text-align: left;
            padding-left: 10px;
        }
    }
    tbody {
        tr {
            &:hover {
                td {
                    background: ${(props) => props.theme.palette.backgroundLight};
                }
            }
        }
    }
`;

export const BillingTable = styled.table`
    td {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
        padding: 10px 0px;
        :first-child {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 14px;
        }
        :last-child {
            font-weight: 500;
            text-align: right;
            font-size: 17px;
        }
    }
    tr {
        :last-child {
            td {
                border: 0;
            }
        }
    }
    .total {
        td {
            font-weight: 500;
            font-size: 20px;
            color: ${(props) => props.theme.palette.text};
        }
    }
`;

export const BillingInvoiceFormContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .total-line {
        border-radius: 8px;
    }
`;

export const ShowDetailsLabel = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.textLight};
    border-radius: 15px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    padding: 2px 8px;
`;
export const AccountUserRowView = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    display: flex;
    align-items: center;
    padding: 6px 15px;
    border-radius: 15px;
    justify-content: space-between;
`;

export const ChangeMethodContainer = styled.div`
    display: flex;
    padding: 7px 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    gap: 10px;
    border-radius: 3px;

    &.selected {
        cursor: pointer;
        &:hover {
            background-color: ${(props) => props.theme.palette.backgroundLight};
        }
    }
`;

export const PaymentMethodContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
`;

export const PaymentMethodCard = styled.div`
    height: 40px;
    border-radius: 7px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    font-size: 12px;
    gap: 15px;
    display: flex;
    padding: 10px 15px;
    font-weight: 500;

    &.selected {
        border: 1px solid #2962ff;
        background-color: #2962ff0a;
    }
    &:hover {
        border: 1px solid #2962ff;
        background: #2962ff0a;
    }
`;

export const UpdatePlanButton = styled.div`
    padding: 7px 25px;
    display: flex;
    gap: 10px;
    align-items: center;
    background: linear-gradient(90deg, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 208, 1) 100%);
    cursor: pointer;
    color: white;
    justify-content: center;
    :visited {
        color: white;
    }
    border-radius: 33px;
    font-weight: 500;
    text-align: center;

    &.small {
        font-size: 12px;
        padding: 5px 11px;
    }
`;
