import { WahioFile, WahioFileImpl } from "../../api/models";
import { OrderItem } from "../../api/models/order";
import { IProduct, IProductVariant } from "../../api/models/product";
import { CloudImages } from "../../assets";

export const getProductProfileImage = (product?: IProduct, productVariant?: IProductVariant) => {
    if (productVariant && productVariant.images && productVariant.images.length > 0) {
        return productVariant.images[0].url;
    } else if (product && product.images && product.images.length > 0) return product.images[0].url;
    return CloudImages.defaultImage;
};

interface ProductImageBase {
    images?: WahioFile[];
}

export const getProductThumbnailUrl = (product?: ProductImageBase, productVariant?: ProductImageBase) => {
    if (productVariant && productVariant.images && productVariant.images.length > 0) {
        const image = productVariant.images[0];
        return image.thumbnailUrl ?? image.url;
    } else if (product && product.images && product.images.length > 0) {
        const image = product.images[0];
        return image.thumbnailUrl ?? image.url;
    }
    return CloudImages.defaultImage;
};

export const getProductSku = (product?: IProduct, productVariant?: IProductVariant) => {
    var value = "";
    if (productVariant) {
        value = productVariant.sku;
    } else if (product) value = product.sku;
    return value?.trim().replace(/ñ/gi, "N");
};

export const getCodeAndReference = (item: OrderItem, isReference?: boolean) => {
    const type = isReference ? "reference" : "code";
    if (item.productVariant) {
        return item.productVariant[type];
    } else {
        return item.product?.[type];
    }
};

export const getProductImages = (product?: IProduct, productVariant?: IProductVariant): WahioFile[] => {
    if (!product) return [];
    if (productVariant) {
        if (productVariant.images && productVariant.images.length > 0) {
            return productVariant.images;
        } else {
            return [];
        }
    }
    if (product.includesVariants && product.variants && product.variants.length > 0) {
        return product.variants.map((item) => new WahioFileImpl(item.image, "image"));
    }
    return product.images ?? [];
};

export const getPriceWithoutDiscount = (productPrice: number, percentage: number) => {
    return productPrice - (productPrice * percentage) / 100;
};

export const getProductCode = (product?: IProduct, productVariant?: IProductVariant) => {
    var value = "";
    if (productVariant) {
        value = productVariant.code;
    } else if (product) value = product.code;
    return value?.trim().replace(/ñ/gi, "N");
};

export const getProductReference = (product?: IProduct, productVariant?: IProductVariant) => {
    if (productVariant) {
        return productVariant.reference;
    } else if (product) return product.reference;
    return "";
};

export const getProductSkuAndCode = (product?: IProduct, productVariant?: IProductVariant) => {
    let result = "";

    if (productVariant) {
        if (productVariant.sku) result += productVariant.sku;
        if (productVariant.code) result += " " + productVariant.code;
    } else if (product) {
        if (product.sku) result = product.sku;
        if (product.code) result += " " + product.code;
    }
    return result;
};

export const getReferenceCodes = (referenceCodes?: string) => {
    if (!referenceCodes) {
        return [];
    }
    try {
        var jsonList: string[] = JSON.parse(referenceCodes);
        return jsonList;
    } catch (e) {
        return [];
    }
};
