import _ from "lodash";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { getAuthBody } from "../../auth";
import { useAlert } from "../../components/Alerts/Alert";
import OrderAlert from "../../components/Alerts/OrderAlert";
import OrderMessageNotification from "../../components/Alerts/OrderNotificationMessages";
import { Message, MessageGroup } from "../../components/Chats/models";
import PATHS from "../../constants/paths";
import { useUserDevice } from "../../pages/AppContainer/UserDevice";
import { useChatContext } from "../../store/contexts/ChatContext";
import { useOrderDataSummaryContext } from "../../store/contexts/OrderDataSummaryContext";
import { IUserState, UserContext } from "../../store/contexts/UserContext";
import { getModelFullName, getUniqueId } from "../../utils";
import customMoment from "../../utils/momentFormat/dateFormat";
import { AccountUser, getAccountUserFullName, getUserPermissions } from "../models/accountUser";
import { ILoungeTable } from "../models/lounge";
import { Order, OrderItem } from "../models/order";
import { IOrderTable } from "../models/orderTable";
import {
    ITableUpdatePush,
    InventoryAdjustmentData,
    OrderBoardData,
    OrderMessageNotificationData,
    OrderNotificationData,
    OrderTempSocketMessage,
    SocketEventOrigin,
    UserPermissionChangeData,
    UserSessionChangeData,
    UserSessionResponseItem,
} from "./models";
import { EventKey, sendSocketEvent, socket, useSocketOnRoomChange } from "./sockedManager";

export const getAccountId = () => {
    let accountId = getAuthBody().accountId;
    if (_.isEmpty(accountId)) {
        accountId = getUniqueId();
    }
    return accountId;
};

const ROOM_ID_NEW_ORDER = "NEW_ORDER_" + getAccountId();
const ROOM_ID_NEW_ORDER_TEMP = "NEW_ORDER_TEMP_" + getAccountId();
const ROOM_ID_BOARD_EVENT = "ORDER_BOARD_" + getAccountId();
const ROOM_ID_USER_PERMISSION_CHANGE = "USER_PERMISSION_CHANGE_" + getAccountId();
const ROOM_ID_INVENTORY_ADJUSTMENT = "INVENTORY_ADJUSTMENT_" + getAccountId();
const ROOM_ID_ORDER_MESSAGE = "ORDER_MESSAGE_" + getAccountId();
const ROOM_ID_RESTAURANT_LOUNGE = "RESTAURANT_LOUNGE_" + getAccountId();
const ROOM_ID_USER_DISCONNECTED = "USER_DISCONNECTED_" + getAccountId();
const ROOM_ID_SESSION_ACCOUNT = "SESSION_" + getAccountId();
const ROOM_ID_ECOMMERCE_ACTIVE_USER = "ECOMMERCE_ACTIVE_USERS_" + getAccountId();
const ROOM_ID_CHAT_CONVERSATION_GROUP = "CHAT_GROUP_" + getAccountId();

export const useWahioSocket = () => {
    const { userState } = useContext(UserContext);
    const { chatState, setChatState } = useChatContext();
    const [subscription, setSubscription] = useState<string[]>([]);
    const deviceId = useUserDevice();

    const sendOrderEvent = (order: Order) => {
        let item: OrderItem | undefined = undefined;
        if (order.items.length > 0) {
            item = order.items[0];
        }

        let data: OrderNotificationData = {
            accountId: getAccountId(),
            userName: userState.user?.firstName ?? "Un usuario",
            userImage: userState.user?.image,
            orderNumber: order.number.toString(),
            orderStatus: order.status,
            orderId: order.id ?? "notfound",
            address: order.deliveryOrder?.address ?? "",
            origin: "newOrder",
            firstProduct: {
                name: item?.product?.name ?? "Not product",
                image: item?.product?.profilePicture,
                quantity: item?.quantity ?? 0,
            },

            totalItems: order.items.length,
        };

        sendSocketEvent(EventKey.messageRoom, {
            roomId: ROOM_ID_NEW_ORDER,
            data,
        });
    };

    const sendOrderBoardEvent = (data: OrderBoardData) => {
        sendSocketEvent(EventKey.messageRoom, { roomId: ROOM_ID_BOARD_EVENT, data });
    };

    const sendUserPermissionChange = (userId: string) => {
        let data: UserPermissionChangeData = {
            userName: getAccountUserFullName(userState.user),
            userId: userId,
            origin: "accountPermission",
            accountId: getAccountId(),
        };
        sendSocketEvent(EventKey.messageRoom, {
            roomId: ROOM_ID_USER_PERMISSION_CHANGE,
            data,
        });
    };

    const sendInventoryAdjustmentChanges = (include?: boolean) => {
        let data: InventoryAdjustmentData = { id: deviceId, include: !!include, accountId: getAccountId() };
        sendSocketEvent(EventKey.messageRoom, {
            roomId: ROOM_ID_INVENTORY_ADJUSTMENT,
            data,
        });
    };

    const sendUserConnected = (user: AccountUser) => {
        const callback = (value: UserSessionChangeData) => {
            setChatState({ ...chatState, activeUsers: value.users });
        };

        let data: UserSessionResponseItem = {
            accountId: user.accountId,
            userId: user.id,
            userName: user.firstName,
            image: user.image,
            date: customMoment().utc().toDate(),
            deviceId,
        };

        socket.emit(EventKey.login, data, callback);
    };

    const sendChatMessage = (message: Message) => {
        sendSocketEvent(EventKey.messageRoom, {
            roomId: rooms.chatGroup(message.userGroup),
            data: message,
        });
    };

    const sendChatMessageGroup = (message: MessageGroup) => {
        sendSocketEvent(EventKey.messageRoom, {
            roomId: rooms.chatGroup(message.group),
            data: message,
        });
    };

    const sendOrderMessageEvent = (order: Order, comment: string) => {
        let data: OrderMessageNotificationData = {
            userName: userState.user?.firstName ?? "Un usuario",
            userImage: userState.user?.image,
            comment: comment,
            orderId: order.id ?? "-",
            origin: "newOrderMessage",
            orderNumber: order.number.toString(),
            accountId: getAccountId(),
        };
        sendSocketEvent(EventKey.messageRoom, {
            roomId: ROOM_ID_ORDER_MESSAGE,
            data,
        });
    };

    const sendRestaurantTableEvent = (loungeTable: ILoungeTable, value: IOrderTable) => {
        const user = userState.user;
        const emitData: ITableUpdatePush = {
            orderTableId: value.id ?? "",
            tableNumber: loungeTable.number,
            userName: getModelFullName(user),
            userImage: user?.image ?? "-",
            status: value.status,
            accountId: getAccountId(),
        };
        sendSocketEvent(EventKey.messageRoom, { roomId: rooms.restaurant(loungeTable.loungeId ?? "-"), data: emitData });
    };

    const roomSuscribe = (roomId: string) => {
        let newSubscription = [...subscription, roomId];

        setSubscription(newSubscription);
        sendSocketEvent(EventKey.roomSuscribe, { roomId: roomId });
    };

    // function onRoomChange<T>(callback: (data: T) => void) {
    //     receiveSocketEvent(EventKey.roomChange, (data: T) => {
    //         if ((data as any).accountId === getAccountId()) {
    //             callback(data);
    //         }
    //     });
    // }

    // function onUserSessionChange<T>(callback: (data: T) => void) {
    //     receiveSocketEvent(EventKey.userSession, (data: T) => {
    //         callback(data);
    //     });
    // }

    const rooms = {
        newOrder: ROOM_ID_NEW_ORDER,
        newOrderTemp: ROOM_ID_NEW_ORDER_TEMP,
        orderBoard: ROOM_ID_BOARD_EVENT,
        userPermissionChange: ROOM_ID_USER_PERMISSION_CHANGE,
        inventoryAdjustment: ROOM_ID_INVENTORY_ADJUSTMENT,
        orderMessage: ROOM_ID_ORDER_MESSAGE,
        restaurant: (loungeId: string) => ROOM_ID_RESTAURANT_LOUNGE + loungeId,
        chatGroup: (chatGroupIds: string) => ROOM_ID_CHAT_CONVERSATION_GROUP + chatGroupIds,
        userDisconnected: ROOM_ID_USER_DISCONNECTED,
        sessionAccount: ROOM_ID_SESSION_ACCOUNT,
        ecommerceActiveUser: ROOM_ID_ECOMMERCE_ACTIVE_USER,
    };

    return {
        rooms,
        roomSuscribe,
        sendOrderEvent,
        sendOrderMessageEvent,
        sendOrderBoardEvent,
        sendUserPermissionChange,
        sendInventoryAdjustmentChanges,
        sendRestaurantTableEvent,
        sendUserConnected,
        sendChatMessage,
        sendChatMessageGroup,
    };
};

export const useWahioSocketRooms = () => {
    const { userState, userActions } = useContext(UserContext);
    const alert = useAlert();
    const wahioSocket = useWahioSocket();
    const userStateRef = useRef<IUserState | undefined>(undefined);
    const { orderLocalStorageState, setOrderLocalStorageState } = useOrderDataSummaryContext();

    const orderLocalStorageStateRef = useRef(orderLocalStorageState);

    useEffect(() => {
        orderLocalStorageStateRef.current = orderLocalStorageState;
    }, [orderLocalStorageState]);

    useEffect(() => {
        if (userState.user) {
            userStateRef.current = userState;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.user]);

    const handleGetRoomChanges = useCallback((data: any) => {
        if (!data) return;
        let origin: SocketEventOrigin = data.origin;

        if (origin === "userPermission") {
            handlePermissionChange(data);
        } else if (origin === "accountPermission") {
            handleAccountPermission(data);
        } else if (origin === "newOrder") {
            handleNewOrder(data);
        } else if (origin === "newOrderTemp") {
            handleNewOrderTemp(data);
        } else if (origin === "newOrderMessage") {
            handleNewOrderMessage(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useSocketOnRoomChange(handleGetRoomChanges);

    useEffect(() => {
        wahioSocket.roomSuscribe(wahioSocket.rooms.newOrderTemp);
        wahioSocket.roomSuscribe(wahioSocket.rooms.newOrder);
        wahioSocket.roomSuscribe(wahioSocket.rooms.userPermissionChange);
        wahioSocket.roomSuscribe(wahioSocket.rooms.orderMessage);
        wahioSocket.roomSuscribe(wahioSocket.rooms.sessionAccount);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewOrderMessage = (data: any) => {
        let tempData: OrderMessageNotificationData = data;
        if (tempData) {
            if (getUserPermissions(userStateRef.current?.user).order?.read) {
                alert.show(<OrderMessageNotification {...tempData} />);
            }
        }
    };

    const handleNewOrder = (data: any) => {
        let tempData: OrderNotificationData = data;
        if (tempData) {
            if (getUserPermissions(userStateRef.current?.user).order?.read) {
                setOrderLocalStorageState({ ...orderLocalStorageStateRef.current, lastOrderRequestId: getUniqueId() });
                alert.show(<OrderAlert {...tempData} />);
            }
        }
    };

    const handleAccountPermission = (data: any) => {
        let tempData: UserPermissionChangeData = data;
        if (tempData) {
            if (tempData.userId === userStateRef.current?.user?.id) {
                alert.info(`${tempData.userName} ha actualizado los roles de la organización y es necesario refrescar la pagina`);
                userActions.requestAccountUser(getAuthBody().accountUserId);
            }
        }
    };

    const handlePermissionChange = (data: any) => {
        let tempData: UserPermissionChangeData = data;
        if (tempData) {
            if (tempData.userId === userStateRef.current?.user?.id) {
                alert.info(`${tempData.userName} ha actualizado tus permisos`);
                userActions.requestAccountUser(getAuthBody().accountUserId);
            }
        }
    };

    const handleNewOrderTemp = (data: any) => {
        let tempData: OrderTempSocketMessage = data;
        if (tempData) {
            setOrderLocalStorageState({ ...orderLocalStorageStateRef.current, lastOrderRequestId: getUniqueId() });
            alert.notification({
                title: `Pedido  WhatsApp. #${tempData.orderCode}`,
                description: `por ${tempData.contactName}`,
                icon: "wahioicon-whatsapp",
                color: "#128C7E",
                openLink: `${window.location.origin}${PATHS.shipmentsListWhatsapp}?code=${tempData.orderCode}`,
            });
        }
    };

    return null;
};
