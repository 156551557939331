import { defineMessages } from "react-intl";

const base = "components.useProfile";

export default defineMessages({
    updatePassword: {
        id: `${base}.updatePassword`,
        defaultMessage: "Actualizar contraseña",
    },
    currentPassword: {
        id: `${base}.currentPassword`,
        defaultMessage: "Contraseña actual",
    },
    newPassword: {
        id: `${base}.newPassword`,
        defaultMessage: "Nueva contraseña",
    },
    confirmPassword: {
        id: `${base}.confirmPassword`,
        defaultMessage: "Confirmar contraseña",
    },
    fieldRequired: {
        id: `${base}.fieldRequired`,
        defaultMessage: "Este campo es obligatorio",
    },
    passwordNoMatch: {
        id: `${base}.passwordNoMatch`,
        defaultMessage: "Las contraseñas no coinciden",
    },
    updatePasswordSuccess: {
        id: `${base}.updatePasswordSuccess`,
        defaultMessage: "Contraseña actualizada correctamente",
    },
    passwordsDoNotMatch: {
        id: `${base}.passwordsDoNotMatch`,
        defaultMessage: "Las contraseñas no coinciden",
    },
    save: {
        id: `${base}.save`,
        defaultMessage: "Guardar",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    myAccount: {
        id: `${base}.myAccount`,
        defaultMessage: "Mi cuenta",
    },
    UserWasDeletedSuccessfully:{
        id: `${base}.UserWasDeletedSuccessfully`,
        defaultMessage: "Usuario eliminado correctamente",
    }
});
