import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { IProduct, IProductVariant, ProductPartialUpdateData, ProductPartialUpdateInput } from "../../../api/models/product";
import { productPartialUpdate } from "../../../api/products/productsApi";
import { useAlert } from "../../Alerts/Alert";
import { Flex } from "../../_controls";
import { CLIENT_PRODUCT_PRICES } from "../PriceList/helper";
import { InputNumberProduct } from "../ProductCreationForm/PricesForm";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationPrices(props: ProductNavigationProfileProps) {
    const { product, productVariant } = props;
    const [productWithPrices, setProductWithPrices] = useState(props.productVariant ?? props.product);
    const [hasChanges, setHasChanges] = useState(false);
    const alert = useAlert();

    const saveProductMutation = useMutation((body: ProductPartialUpdateData) => productPartialUpdate(body), {
        onSuccess: (data) => {
            alert.success("Los precios se han actualizado correctamente");
            setHasChanges(false);
            updateOriginalProduct();
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const updateOriginalProduct = () => {
        if (productVariant) {
            props.setProductVariant(productWithPrices as IProductVariant);
        } else {
            props.onUpdateProduct(productWithPrices as IProduct);
        }
    };

    useEffect(() => {
        setProductWithPrices(productVariant ?? product);
    }, [product, productVariant]);

    const handleSavePrices = () => {
        let data: ProductPartialUpdateInput = {};

        CLIENT_PRODUCT_PRICES.forEach((priceKey) => {
            data[priceKey] = productWithPrices[priceKey];
        });

        let updateData: ProductPartialUpdateData = {
            id: productVariant?.id ?? product.id,
            isVariant: productVariant ? true : false,
            data: data,
        };
        saveProductMutation.mutate(updateData);
    };

    return (
        <NavigationCard
            {...props}
            title={props.intl.formatMessage(messages.salePrices)}
            actionsComponent={
                <Flex gap10 alignCenter>
                    <NavigationActions isLoading={saveProductMutation.isLoading} disabled={!hasChanges} onSave={handleSavePrices} />
                </Flex>
            }
        >
            <Flex column gap20>
                <InputNumberProduct
                    product={productWithPrices}
                    onChangeProduct={(value) => {
                        setProductWithPrices(value);
                        setHasChanges(true);
                    }}
                />
            </Flex>
        </NavigationCard>
    );
}
