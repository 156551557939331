import React from "react";
import PurchaseProfile from ".";
import { IPurchase } from "../../../api/models/purchase";
import Modal, { IModalShow } from "../../modals/Modal";

interface PurchaseProfileModalProps extends IModalShow {
    purchase: IPurchase;
    onChange?: (purchase: IPurchase) => void;
}

export default function PurchaseProfileModal(props: PurchaseProfileModalProps) {
    const { purchase } = props;
    return (
        <Modal {...props} sizeType="md" showHeader title={`Compra #${purchase.number}`}>
            <PurchaseProfile onUpdateOrder={props.onChange} showSimpleView order={purchase} />
        </Modal>
    );
}
