import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IProduct, IProductVariant } from "../../../api/models/product";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultInput, Flex, PrimaryButton } from "../../_controls";
import { FormFooter } from "../../_controls/forms/styled";
import CheckBox from "../../_controls/inputs/CheckBox";
import Modal, { IModalShow } from "../../modals/Modal";
import ImagesForm from "./ImagesForm";
import { InputNumberProduct } from "./PricesForm";
import messages from "./messages";
import { ColumnContainerProduct, FormInputProduct, ProductVariantPricesContainer } from "./styled";

interface ModalProductFormProps extends IModalShow {
    selectedVariant: IProductVariant;
    product: IProduct;
    onUpdateProduct: (value: IProduct) => void;
    useSameImageInVariant?: string;
}

const ProductVariantsDetailsForm = (props: ModalProductFormProps) => {
    const [productVariant, setProductVariant] = useState(props.selectedVariant);
    const [pricesForAll, setPricesForAll] = useState(false);

    const intl = useIntl();

    const onNumberChange = (value: number, name: string) => {
        setProductVariant({
            ...productVariant,
            [name]: value,
        });
    };

    const onChange = (e: any) => {
        let value = e.target.value;

        setProductVariant({
            ...productVariant,
            [e.target.name]: value,
        });
    };

    const handleSaveChanges = () => {
        let pricesBody = {
            basePrice: productVariant.basePrice,
            price: productVariant.price,
            price2: productVariant.price2,
            price3: productVariant.price3,
            price4: productVariant.price4,
            price5: productVariant.price5,
            price6: productVariant.price6,
            price7: productVariant.price7,
            price8: productVariant.price8,
            price9: productVariant.price9,
            price10: productVariant.price10,
        };

        let variants = props.product.variants ?? [];

        if (pricesForAll) {
            variants = variants.map((variant) => {
                return { ...variant, ...pricesBody };
            });
        }

        if (!!props.useSameImageInVariant) {
            variants = variants.map((variant) => {
                const optionSameImage = variant.options.find((option) => option.variantName === props.useSameImageInVariant)?.value;
                const optionMatchProductVariant = productVariant.options.find(
                    (option) => option.variantName === props.useSameImageInVariant
                )?.value;
                if (optionSameImage === optionMatchProductVariant) {
                    return { ...variant, images: productVariant.images };
                }
                return variant;
            });
        }

        let variantCopy: IProductVariant[] = [];
        variants.forEach((value) => {
            let element = { ...value };
            if (element.valuesLine === productVariant.valuesLine) {
                variantCopy.push(productVariant);
            } else {
                variantCopy.push(element);
            }
        });

        props.onUpdateProduct({ ...props.product, variants: variantCopy });
        props.setShow(false);
    };

    return (
        <ProductVariantPricesContainer>
            <ImagesForm
                images={productVariant.images ?? []}
                onChangeImages={(images) => {
                    setProductVariant({ ...productVariant, images });
                }}
            />
            {!!props.useSameImageInVariant && (
                <Flex className="mt-2">
                    <span className="text-light text-small">
                        Las imágenes serán aplicadas a todas las variantes que coincidan con las características de este producto
                    </span>
                </Flex>
            )}
            <div className="mt-2">
                <CheckBox
                    id="checkboxpricecondition"
                    title="Aplicar precio a todas las variantes"
                    name="condition"
                    onChange={(e) => {
                        setPricesForAll(e.target.checked);
                    }}
                    checked={pricesForAll}
                />
            </div>

            <div className="mt-2">
                <FormInputProduct>
                    <label htmlFor="title">
                        <FormattedMessage {...messages.basePrice} />
                    </label>
                    <TextBoxNumeral
                        value={productVariant.basePrice}
                        format="money"
                        onNumberChange={(value) => setProductVariant({ ...productVariant, basePrice: value })}
                    />
                </FormInputProduct>
            </div>

            <p className="light">
                <FormattedMessage {...messages.salePrices} />:
            </p>
            <ColumnContainerProduct>
                <InputNumberProduct onChangeProduct={setProductVariant} product={productVariant} />
            </ColumnContainerProduct>

            <ColumnContainerProduct>
                <h4>Detalles</h4>
                <Flex flexWrap alignCenter gap10>
                    <FormInputProduct>
                        <label htmlFor="name">{intl.formatMessage(messages.productSku)}</label>
                        <DefaultInput
                            name="sku"
                            type="text"
                            onChange={onChange}
                            value={productVariant.sku}
                            placeholder={intl.formatMessage(messages.productSku)}
                        />
                    </FormInputProduct>
                    <FormInputProduct>
                        <label htmlFor="name">{intl.formatMessage(messages.productBarcode)}</label>
                        <DefaultInput
                            name="code"
                            type="text"
                            onChange={onChange}
                            value={productVariant.code}
                            placeholder={intl.formatMessage(messages.productBarcode)}
                        />
                    </FormInputProduct>
                </Flex>
                <Flex>
                    <FormInputProduct>
                        <label htmlFor="name">{intl.formatMessage(messages.productReference)}</label>
                        <DefaultInput
                            name="reference"
                            type="text"
                            onChange={onChange}
                            value={productVariant.reference}
                            placeholder={intl.formatMessage(messages.productReference)}
                        />
                    </FormInputProduct>
                </Flex>
                <h4>Tamaños y pesos</h4>
                <div>
                    <Flex alignCenter flexWrap gap10>
                        <FormInputProduct className="small-input4">
                            <label htmlFor="name">Ancho (cm)</label>
                            <TextBoxNumeral
                                format="number"
                                name="itemWidth"
                                type="text"
                                onNumberChange={(value) => onNumberChange(value, "itemWidth")}
                                value={productVariant.itemWidth}
                            />
                        </FormInputProduct>
                        <FormInputProduct className="small-input4">
                            <label htmlFor="name">Alto (cm)</label>
                            <TextBoxNumeral
                                format="number"
                                name="itemHeight"
                                type="text"
                                onNumberChange={(value) => onNumberChange(value, "itemHeight")}
                                value={productVariant.itemHeight}
                            />
                        </FormInputProduct>
                        <FormInputProduct className="small-input4">
                            <label htmlFor="name">Largo (cm)</label>
                            <TextBoxNumeral
                                format="number"
                                name="itemLength"
                                type="text"
                                onNumberChange={(value) => onNumberChange(value, "itemLength")}
                                value={productVariant.itemLength}
                            />
                        </FormInputProduct>
                        <FormInputProduct className="small-input4">
                            <label htmlFor="name">Peso (Kg)</label>
                            <TextBoxNumeral
                                format="number"
                                name="itemWeight"
                                type="text"
                                onNumberChange={(value) => onNumberChange(value, "itemWeight")}
                                value={productVariant.itemWeight}
                            />
                        </FormInputProduct>
                    </Flex>
                </div>
            </ColumnContainerProduct>

            <FormFooter>
                <PrimaryButton borderRadius={10} onClick={handleSaveChanges}>Guardar Cambios</PrimaryButton>
            </FormFooter>
        </ProductVariantPricesContainer>
    );
};

export default function ProductVariantsPriceModal(props: ModalProductFormProps) {
    return (
        <Modal
            sizeType="md"
            overflowAuto={false}
            removeVerticalAlign={true}
            title={`Editar Producto: ${props.selectedVariant.valuesLine}`}
            showHeader={true}
            useMobileView
            {...props}
        >
            <ProductVariantsDetailsForm {...props} />
        </Modal>
    );
}
