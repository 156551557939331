import { ProductPriceBody } from "../../../api/models/product";

export const CLIENT_PRODUCT_PRICES: (keyof ProductPriceBody)[] = [
    "price",
    "price2",
    "price3",
    "price4",
    "price5",
    "price6",
    "price7",
    "price8",
    "price9",
    "price10",
];
