import { defineMessages } from "react-intl";

const base = "containers.app";

export default defineMessages({
    itemHome: {
        id: `${base}.itemHome`,
        defaultMessage: "Inicio",
    },
    dashboard: {
        id: `${base}.dashboard`,
        defaultMessage: "Dashboard",
    },
    products: {
        id: `${base}.products`,
        defaultMessage: "Productos",
    },
    adjustment: {
        id: `${base}.adjustment`,
        defaultMessage: "Ajustes",
    },
    warehouses: {
        id: `${base}.warehouses`,
        defaultMessage: "Bodegas",
    },
    discounts: {
        id: `${base}.discounts`,
        defaultMessage: "Descuentos",
    },
    categories: {
        id: `${base}.categories`,
        defaultMessage: "Categorías",
    },
    accountsReceivable: {
        id: `${base}.accountsReceivable`,
        defaultMessage: "Cuentas por Cobrar",
    },
    debtsToPay: {
        id: `${base}.debtsToPay`,
        defaultMessage: "Cuentas por Pagar",
    },
    paymentsReceived: {
        id: `${base}.paymentsReceived`,
        defaultMessage: "Pagos Recibidos",
    },
    paymentsMade: {
        id: `${base}.paymentsMade`,
        defaultMessage: "Pagos Realizados",
    },
    conceptOfExpenses: {
        id: `${base}.conceptOfExpenses`,
        defaultMessage: "Conceptos de Gastos",
    },
    ordersBoard: {
        id: `${base}.ordersBoard`,
        defaultMessage: "Tablero",
    },
    queueOrders: {
        id: `${base}.queueOrders`,
        defaultMessage: "En Cola",
    },
    quotes: {
        id: `${base}.quotes`,
        defaultMessage: "Cotizaciones",
    },
    whatsApp: {
        id: `${base}.whatsApp`,
        defaultMessage: "WhatsApp",
    },
    orderReadyToShip: {
        id: `${base}.orderReadyToShip`,
        defaultMessage: "Panel Envíos",
    },
    comments: {
        id: `${base}.comments`,
        defaultMessage: "Comentarios",
    },
    barRestaurant: {
        id: `${base}.barRestaurant`,
        defaultMessage: "Bar / Restaurante",
    },
    returns: {
        id: `${base}.returns`,
        defaultMessage: "Devoluciones",
    },
    cashRecords: {
        id: `${base}.cashRecords`,
        defaultMessage: "Registros de Caja",
    },
    numberings: {
        id: `${base}.numberings`,
        defaultMessage: "Numeraciones",
    },
    mySubscription: {
        id: `${base}.mySubscription`,
        defaultMessage: "Mi suscripción",
    },
    taxes: {
        id: `${base}.taxes`,
        defaultMessage: "Impuestos",
    },
    movements: {
        id: `${base}.movements`,
        defaultMessage: "Movimientos",
    },
    bin: {
        id: `${base}.bin`,
        defaultMessage: "Papelera",
    },
    expenses: {
        id: `${base}.expenses`,
        defaultMessage: "Gastos",
    },
    orders: {
        id: `${base}.orders`,
        defaultMessage: "Pedidos",
    },
    picking: {
        id: `${base}.picking`,
        defaultMessage: "Picking",
    },
    null: {
        id: `${base}.null`,
        defaultMessage: "Anulados",
    },
    draft: {
        id: `${base}.draft`,
        defaultMessage: "Borradores",
    },
    myAccount: {
        id: `${base}.myAccount`,
        defaultMessage: "Mi Cuenta",
    },
    channels: {
        id: `${base}.channels`,
        defaultMessage: "Canales",
    },
    cashRegister: {
        id: `${base}.cashRegister`,
        defaultMessage: "Caja",
    },
    stores: {
        id: `${base}.stores`,
        defaultMessage: "Tiendas",
    },
    accounting: {
        id: `${base}.accounting`,
        defaultMessage: "Cajas & Cartera",
    },
    paymentsAndAccounting: {
        id: `${base}.paymentsAndAccounting`,
        defaultMessage: "Pagos & Cartera",
    },
    accountingPanel: {
        id: `${base}.accountingPanel`,
        defaultMessage: "Cartera",
    },

    accountingPayable: {
        id: `${base}.accountingPayable`,
        defaultMessage: "Cuentas por Pagar",
    },
    accountingReceivable: {
        id: `${base}.accountingReceivable`,
        defaultMessage: "Cuentas por Cobrar",
    },
    transactionAccounts: {
        id: `${base}.transactionAccounts`,
        defaultMessage: "Cuentas Externas",
    },
    payments: {
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },

    cashRegisterAndBank: {
        id: `${base}.cashRegisterAndBank`,
        defaultMessage: "Cajas & Bancos",
    },
    sales: {
        id: `${base}.sales`,
        defaultMessage: "Ventas",
    },
    saleOrders: {
        id: `${base}.saleOrders`,
        defaultMessage: "Ordenes",
    },
    electronicBilling: {
        id: `${base}.electronicBilling`,
        defaultMessage: "Factura Electronica",
    },
    deliveryOrders: {
        id: `${base}.deliveryOrders`,
        defaultMessage: "Pedidos",
    },
    ecommerce: {
        id: `${base}.ecommerce`,
        defaultMessage: "E-commerce",
    },
    calendar: {
        id: `${base}.calendar`,
        defaultMessage: "Calendario",
    },
    statistics: {
        id: `${base}.statistics`,
        defaultMessage: "Estadísticas",
    },
    purchases: {
        id: `${base}.purchases`,
        defaultMessage: "Compras",
    },
    config: {
        id: `${base}.config`,
        defaultMessage: "Configuración",
    },
    general: {
        id: `${base}.general`,
        defaultMessage: "General",
    },
    printers: {
        id: `${base}.printers`,
        defaultMessage: "Impresoras",
    },
    customers: {
        id: `${base}.customers`,
        defaultMessage: "Clientes",
    },
    contacts: {
        id: `${base}.contacts`,
        defaultMessage: "Contactos",
    },
    allContacts: {
        id: `${base}.allContacts`,
        defaultMessage: "Todos los Contactos",
    },
    suppliers: {
        id: `${base}.suppliers`,
        defaultMessage: "Proveedores",
    },
    sellers: {
        id: `${base}.sellers`,
        defaultMessage: "Vendedores",
    },
    couriers: {
        id: `${base}.couriers`,
        defaultMessage: "Mensajeros",
    },
    companies: {
        id: `${base}.companies`,
        defaultMessage: "Empresas",
    },
    reports: {
        id: `${base}.reports`,
        defaultMessage: "Informes",
    },
    itemSell: {
        id: `${base}.itemSell`,
        defaultMessage: "Vender",
    },
    itemDelivery: {
        id: `${base}.itemDelivery`,
        defaultMessage: "Envíos",
    },
    itemRestaurant: {
        id: `${base}.itemRestaurant`,
        defaultMessage: "Restaurante",
    },
    itemContacts: {
        id: `${base}.itemContacts`,
        defaultMessage: "Contactos",
    },
    itemCustomers: {
        id: `${base}.itemCustomers`,
        defaultMessage: "Clientes",
    },
    itemProducts: {
        id: `${base}.itemProducts`,
        defaultMessage: "Products",
    },
    itemStatistics: {
        id: `${base}.itemStatistics`,
        defaultMessage: "Estadísticas",
    },
    itemCloud: {
        id: `${base}.itemCloud`,
        defaultMessage: "Cloud",
    },
    itemCashRegisters: {
        id: `${base}.itemCashRegisters`,
        defaultMessage: "Cajas/Bancos",
    },
    itemSellers: {
        id: `${base}.itemSellers`,
        defaultMessage: "Vendedores",
    },
    itemAccount: {
        id: `${base}.itemAccount`,
        defaultMessage: "Cuenta",
    },
    itemConfig: {
        id: `${base}.itemConfig`,
        defaultMessage: "Configuración",
    },
    itemSettings: {
        id: `${base}.itemSettings`,
        defaultMessage: "Configuración",
    },
    paymentRequired: {
        id: `${base}.paymentRequired`,
        defaultMessage: "Pago Requerido",
    },
    signOut: {
        id: `${base}.signOut`,
        defaultMessage: "Cerrar Sesión",
    },
    users: {
        id: `${base}.users`,
        defaultMessage: "Usuarios",
    },
    localSettings: {
        id: `${base}.localSettings`,
        defaultMessage: "Configuración Local",
    },
});
