import { IContactAddress } from "../../api/models/contact";
import { IDeliveryOrder } from "../../api/models/deliveryOrder";
import { IOrganization } from "../../api/models/organizationTypes";

interface DestinationModel {
    state?: string;
    city?: string;
    address?: string;
}

export const getDestinationAddress = (value?: DestinationModel, short?: boolean) => {
    if (value) {
        let lines: string[] = [];
        if (value.state) {
            lines.push(value.state);
        }
        if (value.city) {
            lines.push(value.city);
        }
        if (!short) {
            if (value.address) {
                lines.push(value.address);
            }
        }
        return lines.join(", ");
    }
    return "...";
};

export const getContactAddressText = (value?: IContactAddress, short?: boolean) => {
    if (value) {
        let lines: string[] = [];
        if (value.state) {
            lines.push(value.state);
        }
        if (value.city) {
            lines.push(value.city);
        }
        if (!short) {
            if (value.description) {
                lines.push(value.description);
            }
        }
        return lines.join(", ");
    }
    return "...";
};

export const getOriginAddressOrganization = (value?: IOrganization) => {
    if (value) {
        let lines: string[] = [];
        if (value.state) {
            lines.push(value.state);
        }
        if (value.city) {
            lines.push(value.city);
        }
        if (value.address) {
            lines.push(value.address);
        }
        return lines.join(", ");
    }
    return "...";
};

export const getOriginAddress = (value?: IDeliveryOrder, short?: boolean) => {
    if (value) {
        let lines: string[] = [];
        if (value.originState) {
            lines.push(value.originState);
        }
        if (value.originCity) {
            lines.push(value.originCity);
        }
        if (!short) {
            if (value.originAddress) {
                lines.push(value.originAddress);
            }
        }
        return lines.join(", ");
    }
    return "...";
};
