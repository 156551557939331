import React from "react";
import { IPaginationList } from "../../api/models";
import { SquareButton, TextField } from "../_controls";
import styled from "styled-components";

export interface IPaginationChange {
    onPageChange: (newpage: number) => void;
}

interface IPaginationProps extends IPaginationChange {
    pagination: IPaginationList;
    className?: string;
}
export default function PaginationView(props: IPaginationProps) {
    const { pagination: pag } = props;
    if (!pag.totalItems) return <span />;
    else if (pag.totalItems < pag.pageSize) return <span />;
    const showLeft = pag.currentPage > 1;
    const shosRight = pag.totalItems > pag.currentPage * pag.pageSize;
    const start = (pag.currentPage - 1) * pag.pageSize;
    let end = start + pag.pageSize;

    if (end > pag.totalItems) {
        end = pag.totalItems;
    }

    const onChangePage = (increase: number) => {
        const newPage = pag.currentPage + increase;
        props.onPageChange(newPage);
    };

    return (
        <PaginationStyle className={props.className}>
            <TextField small className="pagination-title">
                {start} - {end} de {pag.totalItems}
            </TextField>
            <SquareButton title="Volver" className="small" onClick={() => onChangePage(-1)} disabled={!showLeft}>
                <i className="fa-regular fa-chevron-left"></i>
            </SquareButton>
            <SquareButton title="Siguiente Pagina" className="small" onClick={() => onChangePage(1)} disabled={!shosRight}>
                <i className="fa-regular fa-chevron-right"></i>
            </SquareButton>
        </PaginationStyle>
    );
}

const PaginationStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    .pagination-title {
        white-space: nowrap;
    }

    button {
        background: transparent;
        :disabled {
            background: transparent;
        }
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;
