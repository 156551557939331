import { IOrderPickingItem } from "../../../api/models/order";
import { IWarehouseProductLocation } from "../../../api/models/warehouseLocation";
import { ProductLocationValidationState } from "./PickingOrderView";

export const getPickingLocationValidation = (
    productLocationValidation: ProductLocationValidationState,
    item: IOrderPickingItem
): { isValid: boolean; locationProduct?: IWarehouseProductLocation } => {
    if (productLocationValidation.validationLoaded) {
        var match = productLocationValidation.validationResponses.find(
            (x) =>
                x.validationItem.productId === item.productId &&
                x.validationItem.locationCode === item.locationCode &&
                (!item.productVariantId || x.validationItem.productVariantId === item.productVariantId)
        );
        if (!match?.warehouseProductLocation) {
            return {
                isValid: false,
            };
        } else {
            //ESTO QUIERE DECIR QUE EL STOCK DE LA UBICACIÓN ES MENOR A LA CANTIDAD REQUERIDA
            let isValid = match && match.warehouseProductLocation && match.warehouseProductLocation.stock >= item.quantity;
            return {
                isValid,
                locationProduct: match.warehouseProductLocation,
            };
        }
    }
    return {
        isValid: true,
    };
};
