import React, { useEffect, useRef } from "react";
import { useEventListenerWindow } from "..";
import { Flex, SquareButton } from "../_controls";
import { NavigationOption } from "./navigationTypes";
import { NavigationMenuContainer } from "./styled";

export interface NavigationMenuPanelProps<T extends string> {
    menuOptions: NavigationOption<T>[];
    selectedTab: T;
    setSelectedTab: (value: T) => void;
}

export default function NavigationMenuPanel<T extends string>(props: NavigationMenuPanelProps<T>) {
    const { menuOptions, selectedTab, setSelectedTab } = props;

    const isMounted = React.useRef(true);
    const menuListRef = useRef<any>();
    const leftBtnRef = useRef<any>();
    const rightBtnRef = useRef<any>();

    useEffect(() => {
        if (menuListRef.current) {
            menuListRef.current.addEventListener("scroll", () => {
                handleCalculatePositions();
            });
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (selectedTab) {
            let element = document.getElementById(selectedTab as string);
            if (element) {
                menuListRef.current.scrollLeft = element.offsetLeft - element.clientWidth;
            }
        } else {
            menuListRef.current.scrollLeft = 0;
        }
    }, [menuOptions, selectedTab]);

    useEffect(() => {
        handleCalculatePositions();
    }, []);

    useEventListenerWindow("resize", () => handleCalculatePositions());

    const handleCalculatePositions = () => {
        if (!menuListRef.current || !leftBtnRef.current || !rightBtnRef.current) return;
        let leftScroll = menuListRef.current.scrollLeft;
        let widthScroll = menuListRef.current.scrollWidth;
        let containerWidth = menuListRef.current.getBoundingClientRect().width;

        let left = leftScroll;
        let right = widthScroll - containerWidth - leftScroll;

        const limit = 50;
        if (left >= limit) {
            leftBtnRef.current.style.display = "flex";
        } else {
            leftBtnRef.current.style.display = "none";
        }

        if (right <= limit) {
            rightBtnRef.current.style.display = "none";
        } else {
            rightBtnRef.current.style.display = "flex";
        }
    };

    const onScrollSum = (value: number) => {
        if (menuListRef.current) menuListRef.current.scrollLeft += value;
    };

    return (
        <NavigationMenuContainer>
            <SquareButton onClick={() => onScrollSum(-150)} ref={leftBtnRef} className="arrow-icon left">
                <i className="fa-sharp fa-regular fa-arrow-left"></i>
            </SquareButton>
            <Flex className="menu-list" ref={menuListRef}>
                {menuOptions.map((option, index) => {
                    const isSelected = option.tabName === selectedTab;
                    const title = option.title ? option.title : option.tabName;
                    return (
                        <div
                            id={selectedTab as string}
                            className={`tab-option ${isSelected ? "selected" : ""}`}
                            key={index}
                            onClick={() => setSelectedTab(option.tabName)}
                        >
                            <span className={`tab-icon ${option.icon}`}></span>
                            <span className="tab-name" title={title}>
                                {title}
                            </span>
                        </div>
                    );
                })}
            </Flex>
            <SquareButton onClick={() => onScrollSum(150)} ref={rightBtnRef} className="arrow-icon right">
                <i className="fa-sharp fa-regular fa-arrow-right"></i>
            </SquareButton>
        </NavigationMenuContainer>
    );
}
