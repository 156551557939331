import DefaultDataValues from "./listingValues.json";

export interface DataBaseModel {
    id: number;
    name: string;
    code: string | null;
    deleted_at: any;
    created_at: string;
    updated_at: string;
}

export interface TypeDocumentIdentification extends DataBaseModel {
    scope?: string | null;
}

export interface DefaultTaxModel extends DataBaseModel {
    description: string;
}

export interface TypeDocument extends DataBaseModel {
    prefix: string;
    type: string;
    cufe_algorithm: string;
}

export const DEFAULT_TAXES: DefaultTaxModel[] = DefaultDataValues.taxes;
export const DEFAULT_ORGANIZATIONS: DataBaseModel[] = DefaultDataValues.type_organizations;
export const DEFAULT_DISCOUNTS: DataBaseModel[] = DefaultDataValues.discounts;
export const DEFAULT_TYPE_DOCUMENT_IDENTIFICATIONS: TypeDocumentIdentification[] = DefaultDataValues.type_document_identifications;
export const DEFAULT_TYPE_REGIMES: DataBaseModel[] = DefaultDataValues.type_regimes;
export const DEFAULT_TYPE_LIABILITIES: DataBaseModel[] = DefaultDataValues.type_liabilities;
export const DEFAULT_TYPE_DELIVERIES: DataBaseModel[] = DefaultDataValues.type_deliveries;
export const DEFAULT_TYPE_DOCUMENTS: TypeDocument[] = DefaultDataValues.type_documents;

export type ListingType =
    | "taxes"
    | "discounts"
    | "typeDocumentIdentifications"
    | "typeOrganizations"
    | "typeRegimes"
    | "typeLiabilities"
    | "typeDeliveries";

export const DEFAULT_LISTING = {
    taxes: DEFAULT_TAXES,
    discounts: DEFAULT_DISCOUNTS,
    typeDocumentIdentifications: DEFAULT_TYPE_DOCUMENT_IDENTIFICATIONS,
    typeOrganizations: DEFAULT_ORGANIZATIONS,
    typeRegimes: DEFAULT_TYPE_REGIMES,
    typeLiabilities: DEFAULT_TYPE_LIABILITIES,
    typeDeliveries: DEFAULT_TYPE_DELIVERIES,
};
