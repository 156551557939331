import React from "react";
import { useIntl } from "react-intl";
import { checkIntlMessage } from "../../../i18n/helper";
import { useUserContext } from "../../../store/contexts/UserContext";
import { TextField } from "../../_controls";
import messages from "./messages";

export default function PriceNameLine({ defaultPrice }: { defaultPrice?: string }) {
    const { userState } = useUserContext();
    const clientSettings = userState.user?.account?.clientSettings;
    const intl = useIntl();

    const getClientSettingsPrice = () => {
        if (clientSettings && defaultPrice) {
            let priceName = (clientSettings as any)[defaultPrice];
            if (priceName) return priceName;
        }
        if (defaultPrice && checkIntlMessage(messages, defaultPrice)) {
            return intl.formatMessage((messages as any)[defaultPrice]);
        }
        return defaultPrice ?? "-";
    };

    const priceName = React.useMemo(() => {
        return getClientSettingsPrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultPrice, clientSettings]);

    return <TextField>{priceName}</TextField>;
}
