import wahioFetchApi from "../fetchApi";
import { GroupItem, SearchModelProductView } from "../models/_statistics";
import { productEndpoint } from "../restApiEndpoint";

export const productViewsByRange = (data: SearchModelProductView): Promise<{ viewsCounter: number }> => {
    return wahioFetchApi.post(productEndpoint.post.productViewsByRange, data).then((res: any) => res.data);
};

export const productViewsInGroup = (data: SearchModelProductView): Promise<GroupItem[]> => {
    return wahioFetchApi.post(productEndpoint.post.productViewsInGroup, data).then((res: any) => res.data);
};
