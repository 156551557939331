import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useAppSettingsContext } from "../appSettings/AppSettingsContext";
import { ThemeType } from "../appSettings/types";
import { gradientColorObj, mainColorObj } from "../components/Settings/ThemeSettings/helper";
import { darkTheme, lightTheme } from "./themePalette";

export const MOBILE_SIZE = 768;

export const breakpoints = {
    xs: 420,
    sm: 568,
    md: MOBILE_SIZE,
    modalSm: 900,
    lg: 991,
    lg2: 1100,
    xl: 1200,
    xl300: 1300,
    xxl: 1500,
    xxl2: 1700,
};

export const device = {
    xs: `(min-width: ${breakpoints.xs}px)`,
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    modalSm: `(min-width: ${breakpoints.modalSm}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    lg2: `(min-width: ${breakpoints.lg2}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
    xl300: `(min-width: ${breakpoints.xl300}px)`,
    xxl: `(min-width: ${breakpoints.xxl}px)`,
    xxl2: `(min-width: ${breakpoints.xxl2}px)`,
};

export const deviceMax = {
    xs: `(max-width: ${breakpoints.xs}px)`,
    sm: `(max-width: ${breakpoints.sm}px)`,
    md: `(max-width: ${breakpoints.md}px)`,
    modalSm: `(max-width: ${breakpoints.modalSm}px)`,
    lg: `(max-width: ${breakpoints.lg}px)`,
    lg2: `(max-width: ${breakpoints.lg2}px)`,
    xl: `(max-width: ${breakpoints.xl}px)`,
    xl300: `(max-width: ${breakpoints.xl300}px)`,
    xxl: `(max-width: ${breakpoints.xxl}px)`,
    xxl2: `(max-width: ${breakpoints.xxl2}px)`,
};

function StyledThemeProvider(props: any) {
    const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
    const { appSettingsState } = useAppSettingsContext();

    const [currentTheme, setCurrentTheme] = useState<ThemeType>(appSettingsState.themeSettings?.theme ?? "light");

    useEffect(() => {
        let themeName: ThemeType = "light";
        if (appSettingsState.themeSettings?.theme === "device") {
            themeName = isDarkTheme ? "dark" : "light";
        } else {
            themeName = appSettingsState.themeSettings?.theme ?? "light";
        }
        setCurrentTheme(themeName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDarkTheme, appSettingsState.themeSettings]);

    const mqListener = (e: any) => {
        setIsDarkTheme(e.matches);
    };

    useEffect(() => {
        const darkThemeMq: any = window.matchMedia("(prefers-color-scheme: dark)");
        if (darkThemeMq) darkThemeMq.addEventListener("change", mqListener);
        return () => darkThemeMq.removeEventListener("change", mqListener);
    }, []);

    const themePalette = React.useMemo(() => {
        let palette = currentTheme === "light" ? lightTheme : darkTheme;

        const colorName = appSettingsState.themeSettings?.colorName ?? "Solid_Default";
        const colorValue = getColorPalette(colorName, palette.themeName);

        if (colorValue) {
            palette = {
                ...palette,
                backgroundGradientBody: colorValue.background,
                menuHoverColor: colorValue.hover,
            };
            if (colorValue.text) {
                palette.menuIconColor = colorValue.text;
            }
        } else {
            palette = {
                ...palette,
                backgroundGradientBody: palette.background,
                menuHoverColor: palette.primary + "20",
                menuIconColor: palette.primary,
            };
        }

        return palette;
    }, [currentTheme, appSettingsState.themeSettings?.colorName]);

    return (
        <ThemeProvider
            theme={{
                breakpoints,
                palette: themePalette,
            }}
        >
            {props.children}
        </ThemeProvider>
    );
}

const getColorPalette = (colorName: string, themeName: "light" | "dark") => {
    var list = colorName.includes("Gradient") ? gradientColorObj : mainColorObj;
    const gradientValue = list.find((x) => x.name === colorName);
    if (gradientValue) {
        let color = gradientValue[themeName];
        return color;
    }
};

export default StyledThemeProvider;
