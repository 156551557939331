import styled from "styled-components";
import { device } from "../../theme/themeProvider";

export const CashRegisterMovementContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const CashRegisterEntryItemCard = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: ${(props) => props.theme.palette.background};

    input {
        border: 0;
        border-radius: 0;
        background: transparent;
    }
    .description {
        width: 100px;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
    .amount {
        width: 100%;
    }
`;

export const CashRegisterMovementGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;

    .btn-arrow {
        transition: transform 0.5s;
        transform: rotate(90deg);
        background: transparent;
        cursor: default;
    }

    @media ${device.md} {
        flex-direction: row;
        .btn-arrow {
            transform: rotate(0deg);
        }
    }

    .select-custom {
        width: 100%;
    }

    .middle-arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
    }
`;

export const EntryInputContainer = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    padding: 15px;
    border-radius: 15px;
    gap: 15px;
    display: flex;
    flex-direction: column;

    .input-number {
        border: 0;
        padding: 6px 0px;
        font-size: 20px;
        border-radius: 0;
        font-weight: 500;
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
`;
