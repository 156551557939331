import React from "react";
import { Flex } from "../containers";

interface TextGroupProps {
    text1: string;
    text2: string;
    text1Class?: string;
    text2Class?: string;
}

export default function TextGroup(props: TextGroupProps) {
    return (
        <Flex column>
            <span className={`text-light text-small ${props.text1Class}`}>{props.text1}</span>
            <span className={`${props.text2Class}`}>{props.text2}</span>
        </Flex>
    );
}
