import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { OrderNavigationProps } from ".";
import { getServiceMessageError } from "../../../api";
import { IOrderDeleteCreate } from "../../../api/models/order";
import { OrderStatus } from "../../../api/models/orderBase";
import { useOrderStatusMutation } from "../../../api/orders/hooks";
import { deleteRejectOrder } from "../../../api/orders/orderApi";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { useUserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { DefaultButton, Flex } from "../../_controls";
import RejectOrderModal, { RejectOrderState } from "../DeliveryCouriers/RejectOrderModal";
import messages from "./messages";
import ConfirmQueuedOrder from "./ConfirmQueuedOrder";
import GeneralDetails from "./GeneralDetails";
import OrderQuoteView from "./OrderQuoteView";
import { TotalDetails } from "./TotalDetails";

export default function NavigationOrderProfile(props: OrderNavigationProps) {
    const { order, onOrderChange } = props;
    const alert = useAlert();
    const orderStatusMutation = useOrderStatusMutation();
    const { appSettingsState } = useAppSettingsContext();

    const onUpdateOrderStatus = (status: OrderStatus) => {
        let organizationId = order.organizationId ? order.organizationId : appSettingsState.defaultOrganizationId;

        if (organizationId === "queued") {
            organizationId = appSettingsState.defaultOrganizationId;
        }

        let input = {
            status,
            organizationId: organizationId,
            orderId: order.id ?? "invalid",
            accountUserId: order.accountUserId ?? getAuthBody().accountUserId,
        };

        orderStatusMutation
            .updateOrderStatusAsync(input)
            .then((value) => {
                alert.success("Order Confirmada");
                onOrderChange(value);
            })
            .catch((error) => {
                alert.error(getServiceMessageError(error));
            });
    };
    return (
        <>
            {order.status === "queued" && (
                <ConfirmQueuedOrder
                    onConfirm={() => onUpdateOrderStatus("credit")}
                    rejectButton={<RejectOrder {...props} status="declined" title="Rechazar" />}
                    order={order}
                />
            )}
            {order.status === "quotation" && <OrderQuoteView order={order} onOrderUpdate={onOrderChange} />}

            <NavigationCard title="Información General">
                <Flex gap15 flexWrap alignStart spaceBetween>
                    <GeneralDetails currentOrder={order} />
                    <Flex border borderRadius={15} padding={15} mobileW100>
                        <TotalDetails order={order} />
                    </Flex>
                </Flex>

                {orderStatusMutation.isLoading && <LoadingDualRing center />}
            </NavigationCard>
        </>
    );
}

interface RejectOrderProps extends OrderNavigationProps {
    status: OrderStatus;
    title: string;
}

export const RejectOrder = (props: RejectOrderProps) => {
    const { order, onOrderChange, status } = props;
    const intl = useIntl();
    const alert = useAlert();

    const { userPermission } = useUserContext();
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);

    const deleteMutation = useMutation((data: any) => deleteRejectOrder(data), {
        onSuccess: (data) => {
            onOrderChange(data);
            setShowConfirmRemove(false);
            alert.success(intl.formatMessage(messages.TheOrderHasBeenSuccessfullyCancelled));
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleRejectOrder = (rejectOrder: RejectOrderState) => {
        if (!rejectOrder.cashRegisterId) {
            alert.error(intl.formatMessage(messages.theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterVoVoidTheSale));
            return;
        }

        let body: IOrderDeleteCreate = {
            accountUserId: getAuthBody().accountUserId,
            cashRegisterId: rejectOrder.cashRegisterId,
            method: rejectOrder.paymentMethod,
            orderId: order.id ?? "",
            status: status,
            comment: rejectOrder.message,
        };

        deleteMutation.mutate(body);
    };

    const onClickRemoveOrder = () => {
        if (!userPermission.order?.delete) {
            alert.info(intl.formatMessage(messages.youDoNotHavePermissionsToCancelOrders));
            return;
        }

        setShowConfirmRemove(true);
    };

    return (
        <>
            {showConfirmRemove && (
                <RejectOrderModal
                    onOkay={(value) => handleRejectOrder(value)}
                    title={`¿Estás seguro de que deseas ${status === "declined" ? "rechazar" : "anular"} la orden?`}
                    showRefundsOptions={order.totalPaid > 0}
                    description={
                        "Esta acción no se puede revertir, los productos regresarán al stock de nuevo y se creará un reembolso con la fecha actual en caso de ser necesario."
                    }
                    show={showConfirmRemove}
                    setShow={setShowConfirmRemove}
                />
            )}
            <DefaultButton onClick={onClickRemoveOrder} rounded className="dropdown-button">
                <span className="fa-regular fa-trash"></span>
                {props.title} {deleteMutation.isLoading && <LoadingDualRing small />}
            </DefaultButton>
        </>
    );
};
