import React, { useEffect, useState } from "react";
import { CloudImages } from "../../assets";
import { SquareButton } from "../_controls";
import WahioCloudItemsModal from "./WahioCloudItemsModal";
import { ImageSelectorContainer } from "./styled";
import { CSSProperties, useTheme } from "styled-components";

interface WahioCloudImageSelectorProps {
    image?: string;
    onSelectImage: (image?: string) => void;
    disallowEdit?: boolean;
    width?: number;
    square?: boolean;
    borderRadius?: number;
    useAspectRatio?: boolean;
    hideBorder?: boolean;
    useDashedBorder?: boolean;
}

export default function WahioCloudImageSelector(props: WahioCloudImageSelectorProps) {
    const [showWahioCloud, setShowWahioCloud] = useState(false);
    const { image, onSelectImage } = props;

    const valueTheme = useTheme();
    const [aspectRatio, setAspectRatio] = useState(1);

    useEffect(() => {
        if (props.useAspectRatio) {
            loadAspectRatio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    const loadAspectRatio = () => {
        if (image) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const ratio = width / height;
                setAspectRatio(ratio);
            };
        } else {
            setAspectRatio(1);
        }
    };

    let style: CSSProperties = {};
    if (props.width) {
        style.height = props.width + "px";
        style.width = props.width + "px";
        style.minWidth = props.width + "px";
    }

    let styleContainer: CSSProperties = {};
    if (props.borderRadius) {
        style.borderRadius = props.borderRadius + "px";
        styleContainer.borderRadius = props.borderRadius + "px";
    }

    if (props.useAspectRatio && image) {
        style.aspectRatio = `${aspectRatio}px`;
        style.width = "auto";
    }

    if (props.useDashedBorder && !image) {
        styleContainer.border = `2px dashed ${valueTheme.palette.backgroundDark}`;
    }

    if (props.hideBorder) {
        styleContainer.border = "0";
    }

    return (
        <ImageSelectorContainer style={styleContainer}>
            {showWahioCloud && (
                <WahioCloudItemsModal
                    show={showWahioCloud}
                    setShow={setShowWahioCloud}
                    onClickItems={(items) => onSelectImage(items[0].url)}
                    allowSelection
                    defaultType="image"
                />
            )}
            <div className="wahio-cloud-image" style={style}>
                <img onClick={() => setShowWahioCloud(true)} src={image ? image : CloudImages.defaultImage} alt="" />
            </div>
            {!props.disallowEdit && props.image && (
                <div className="wahio-cloud-actions">
                    {image && (
                        <SquareButton type="button" className="mr-1" onClick={() => onSelectImage(undefined)}>
                            <span className="fa-regular fa-trash"></span>
                        </SquareButton>
                    )}
                    <SquareButton type="button" onClick={() => setShowWahioCloud(true)}>
                        <i className="fa-regular fa-camera"></i>
                    </SquareButton>
                </div>
            )}
        </ImageSelectorContainer>
    );
}
