import React, { useContext, useState } from "react";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { getOrgCashRegisterOrDefault } from "../../CashRegisters/cashRegisterUtils";
import Modal, { IModalShow } from "../../modals/Modal";
import { DefaultButton, DefaultInput, PrimaryButton } from "../../_controls";
import { RejectOrderFormContainer } from "./styled";
import { FormFooter } from "../../_controls/forms/styled";
import { PaymentMethod } from "../../../api/models";
import PaymentRow from "../../Payments/PaymentRow";
import { OrderPayment } from "../../../api/models/orderPayment";
import { getAuthBody } from "../../../auth";

export interface RejectOrderState {
    cashRegisterId: string;
    paymentMethod: PaymentMethod;
    message: string;
}

interface RejectOrderModalProps extends IModalShow {
    title: string;
    description: string;
    showRefundsOptions: boolean;
    onOkay: (value: RejectOrderState) => void;
}

export default function RejectOrderModal(props: RejectOrderModalProps) {
    return (
        <Modal id="modal-reject-order" scrollInside={false} overflowAuto={false} showHeader={false} {...props}>
            <RejectOrderForm {...props} />
        </Modal>
    );
}

const RejectOrderForm = (props: RejectOrderModalProps) => {
    const { appSettingsState } = useContext(AppSettingsContext);
    const { organizationState } = useContext(OrganizationContext);
    const [payment, setPayment] = useState(
        new OrderPayment(
            0,
            "cash",
            getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization) ?? "",
            getAuthBody().accountUserId
        )
    );
    const [message, setMessage] = useState("");

    return (
        <RejectOrderFormContainer>
            <h3>{props.title}</h3>
            <p>{props.description}</p>

            <DefaultInput placeholder="Mensaje" value={message} onChange={(e) => setMessage(e.target.value)} />

            {props.showRefundsOptions && (
                <div className="payment-body">
                    <p className="small-text">Método de pago y Cuenta para reembolso</p>
                    <PaymentRow
                        hideFiles
                        hidePaymentInput
                        counter={0}
                        onRemovePayment={() => {}}
                        totalAmount={0}
                        totalPayment={0}
                        payment={payment}
                        onChangePayment={(value) => setPayment(value)}
                    />
                </div>
            )}

            <FormFooter>
                <DefaultButton className="mr-1" rounded onClick={() => props.setShow(false)}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton
                    rounded
                    onClick={() => props.onOkay({ message, cashRegisterId: payment.cashRegisterId, paymentMethod: payment.method })}
                >
                    Aceptar
                </PrimaryButton>
            </FormFooter>
        </RejectOrderFormContainer>
    );
};
