import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { IOrderComment, OrderCommentSearchModel } from "../models/order";
import { localOrderEndpoint } from "../restApiEndpoint";

export const orderCommentQuerySearch = (query: OrderCommentSearchModel): Promise<IPaginationResponse<IOrderComment>> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderCommentQuerySearch, query).then((res: any) => res.data);
};

export const createOrderComment = (data: IOrderComment): Promise<IOrderComment> => {
    return wahioFetchApi.post(localOrderEndpoint.post.orderComment, data).then((res: any) => res.data);
};
export const deleteOrderComment = (id: string): Promise<IOrderComment> => {
    return wahioFetchApi.delete(localOrderEndpoint.delete.orderComment(id)).then((res: any) => res.data);
};
