import React, { createContext, useContext, useState } from "react";

export interface AlertItem {
    id: string;
    description: string;
    content: JSX.Element | React.ReactNode;
}

export interface AlertState {
    items: AlertItem[];
    notifications: AlertItem[];
}

interface IContextProps {
    alertState: AlertState;
    setAlertState: (value: AlertState) => void;
}

const getDefaultValue = () => {
    let item: AlertState = {
        items: [],
        notifications: [],
    };
    return item;
};

const AlertContext = createContext({} as IContextProps);

const AlertContextProvider = (props: any) => {
    const [alertState, setAlertState] = useState(getDefaultValue());

    return <AlertContext.Provider value={{ alertState, setAlertState }}>{props.children}</AlertContext.Provider>;
};

export const useAlertContext = () => {
    return useContext(AlertContext);
};

export default AlertContextProvider;
