import styled from "styled-components";
import { DefaultButton } from "../_controls";
export const ContainerBody = styled.div`
    padding: 15px;
    .title {
        font-weight: 500;
        font-size: 14px;
        margin: 4px 7px;
        padding-left: 7px;
        padding-bottom: 5px;
    }
`;

export const QuickActionDropdownStyle = styled.div`
    .dropdown-btn {
        background: ${(props) => props.theme.palette.backgroundLight};
        border: 0;
        padding: 7px 20px;
        padding-right: 40px;
        color: ${(props) => props.theme.palette.text};
    }
`;

export const QuickActionButton = styled(DefaultButton)`
    padding: 8px 8px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: 0;
    width: 100%;
    justify-content: flex-start;

    .icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 21px;
        background: ${(props) => props.theme.palette.background};
        transition: background 0.2s;
    }

    &:hover {
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};
        transition: background 0.2s;
        .icon {
            background: ${(props) => props.theme.palette.primary}20;
        }
    }
`;
