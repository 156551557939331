import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { stripeGetSetupIntent } from "../../../../api/account/stripeApi";
import { STRIPE_API_KEY } from "../../../../api/appConfig";
import { useAppSettingsContext } from "../../../../appSettings/AppSettingsContext";
import { CloudImages } from "../../../../assets";
import { useUserContext } from "../../../../store/contexts/UserContext";
import LoadingDualRing from "../../../LoadingDualRing";
import { Flex, PrimaryButton, TextField } from "../../../_controls";

const stripePromise = loadStripe(STRIPE_API_KEY);

interface SetupFormProps {
    paramsReturnUrl?: string;
}

const SetupForm = (props: SetupFormProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>();

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null;
        }
        setIsLoading(true);
        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.href + props.paramsReturnUrl ?? "?newPaymentMethod=success",
            },
        });
        setIsLoading(false);

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <PaymentElement />
            {errorMessage && (
                <TextField bold color="red">
                    {errorMessage}
                </TextField>
            )}
            <Flex marginTop={15} spaceBetween alignCenter gap10>
                <img height={30} src={CloudImages.poweredByStripe} alt="Stripe icon" />
                <PrimaryButton borderRadius={10} disabled={!stripe || isLoading}>
                    Guardar Método {isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </form>
    );
};

const StripePaymentSetupForm = (props: SetupFormProps) => {
    const { userState } = useUserContext();
    const [clientSecret, setClientSecret] = useState("");

    const { appSettingsState } = useAppSettingsContext();

    const setupIntentMutation = useMutation((accountId: string) => stripeGetSetupIntent(accountId), {
        onSuccess: (data) => {
            setClientSecret(data.client_secret);
        },
    });

    useEffect(() => {
        setupIntentMutation.mutate(userState.user?.accountId ?? "NA");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (setupIntentMutation.isLoading) return <LoadingDualRing center />;

    if (!clientSecret) {
        return null;
    }

    return (
        <Elements
            stripe={stripePromise}
            options={{
                clientSecret,
                appearance: {
                    theme: appSettingsState.themeSettings?.theme === "dark" ? "night" : "stripe",
                },
            }}
        >
            <SetupForm {...props} />
        </Elements>
    );
};

export default StripePaymentSetupForm;
