import React from "react";
import { CSSProperties } from "styled-components";
import { FlexImageLetterStyle } from ".";
import { getColorValueFromLetter } from "../../../constants/colors";
import { validateUrlImage } from "../../WahioCloud/helper";

interface FlexImageProps {
    image?: string;
    width?: number;
    useAspectRatio?: boolean;
}

interface FlexImageLetterProps extends FlexImageProps {
    text: string;
    defaultIcon?: string;
    bgLight?: boolean;
    tooltipTitle?: string;
}

interface FlexImageIconProps extends FlexImageProps {
    icon?: string;
    fontSize?: number;
    color?: string;
}

export function FlexImageLetter(props: FlexImageLetterProps) {
    const { image } = props;
    const text = props.text ?? "A";
    const color = getColorValueFromLetter(text);

    let style: CSSProperties = {
        width: props.width || 30,
        minWidth: props.width || 30,
        height: props.width || 30,
    };

    if (!image) {
        style.background = `${color}20`;
        style.color = color;
    }

    return (
        <FlexImageLetterStyle title={props.tooltipTitle} style={style} className={`${props.bgLight ? "bg-light" : ""}`}>
            {image && validateUrlImage(image) ? <img src={image} alt={props.text} /> : <span>{text.substring(0, 1)}</span>}
        </FlexImageLetterStyle>
    );
}



export function FlexImageIcon(props: FlexImageIconProps) {
    const { image } = props;

    let style: CSSProperties = {
        width: props.width || 30,
        minWidth: props.width || 30,
        height: props.width || 30,
    };

    if (props.fontSize) style.fontSize = props.fontSize;
    if (props.color) {
        style.color = props.color;
        style.background = props.color + "20";
    }

    const icon = props.icon ? props.icon : "fa-light fa-image";

    return <FlexImageLetterStyle style={style}>{image ? <img src={image} alt={""} /> : <i className={icon}></i>}</FlexImageLetterStyle>;
}

export function FlexSquareIcon(props: { icon: string; color?: string; width?: number }) {
    const { icon } = props;

    let style: CSSProperties = {
        width: props.width || 30,
        minWidth: props.width || 30,
        height: props.width || 30,
    };

    if (props.color) {
        style.color = props.color;
        style.background = props.color + "20";
    }

    return (
        <FlexImageLetterStyle style={style}>
            <i className={icon}></i>
        </FlexImageLetterStyle>
    );
}
