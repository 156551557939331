export interface IAccountPermission {
    id?: string;
    accountId: string;
    name: string;
    color: string;
    permission: IBasePermission;
}

export interface IPermissionActionBase {
    read: boolean;
    update: boolean;
}
export interface IPermissionAction extends IPermissionActionBase {
    create: boolean;
    delete: boolean;
}

export interface IPermissionActionPurchase extends IPermissionAction {
    allowCreatePayments: boolean;
    allowCreateComments: boolean;
}
export interface IPermissionActionOrder extends IPermissionAction {
    createWithoutConfirmation: boolean;
    canConfirm: boolean;
    completeAllPicking: boolean;
    completePickingItem: boolean;
    viewOrdersFromOtherUsers: boolean;
    allowUseAllWarehouses: boolean;
    allowCreatePayments: boolean;
    allowCreateComments: boolean;
    orderFormAllowEditPrice: boolean;
    orderFormAllowEditDiscount: boolean;
    orderFormAllowEditTax: boolean;
}

export interface IPermissionBilling extends IPermissionActionBase {
    allowCreatePayments: boolean;
    allowCreatePaymentMethods: boolean;
    allowDeletePaymentMethods: boolean;
    allowBuyLicenses: boolean;
}

export interface IPermissionActionCashRegister extends IPermissionAction {
    activateCashRegister: boolean;
    allowSeeAllCashRegisters: boolean;
}

export interface IPermissionActionWarehouse extends IPermissionAction {
    createMovement: boolean;
    updateMovement: boolean;
    allowSeeAllWarehouses: boolean;
}
export interface IPermissionActionAccount extends IPermissionActionBase {
    seeAccountsPayable: boolean;
    seeAccountsReceivable: boolean;
}

export interface PermissionActionOrganization extends IPermissionAction {
    addCashRegisters: boolean;
    addWarehouses: boolean;
    addUsers: boolean;
}

export interface PermissionActionRestaurant extends IPermissionAction {
    viewOrdersFromOtherUsers: boolean;
}

export const getPermissionAction = (value: boolean): IPermissionAction => {
    return {
        read: value,
        update: value,
        create: value,
        delete: value,
    };
};

export const getPermissionActionBase = (value: boolean): IPermissionActionBase => {
    return {
        read: value,
        update: value,
    };
};

export interface IBasePermission {
    account?: IPermissionActionAccount;
    product?: IPermissionAction;
    payment?: IPermissionAction;
    accounting?: IPermissionAction;
    order?: IPermissionActionOrder;
    contact?: IPermissionAction;
    warehouse?: IPermissionActionWarehouse;
    statistics?: IPermissionActionBase;
    user?: IPermissionAction;
    organization?: PermissionActionOrganization;
    //invoiceDesign: IPermissionAction;
    category?: IPermissionAction;
    orderReturn?: IPermissionAction;
    purchase?: IPermissionActionPurchase;
    tax?: IPermissionAction;
    discount?: IPermissionAction;
    // orderPayment?: IPermissionAction;
    // purchasePayment?: IPermissionAction;
    catalog?: IPermissionAction;
    restaurant?: PermissionActionRestaurant;
    channel?: IPermissionAction;
    expense?: IPermissionAction;
    cashRegister?: IPermissionActionCashRegister;
    permission?: IPermissionAction;
    inventoryAdjustment?: IPermissionAction;
    invoiceNumeration?: IPermissionAction;
    cloud?: IPermissionAction;
    billing?: IPermissionBilling;
}

export class BasePermissionLoaded implements IBasePermission {
    constructor(defaultValue: boolean) {
        this.account = {
            ...getPermissionActionBase(defaultValue),
            seeAccountsPayable: defaultValue,
            seeAccountsReceivable: defaultValue,
        };
        this.statistics = getPermissionActionBase(defaultValue);
        this.organization = {
            ...getPermissionAction(defaultValue),
            addCashRegisters: defaultValue,
            addWarehouses: defaultValue,
            addUsers: defaultValue,
        };

        this.payment = getPermissionAction(defaultValue);
        this.accounting = getPermissionAction(defaultValue);
        this.user = getPermissionAction(defaultValue);
        this.product = getPermissionAction(defaultValue);
        this.warehouse = {
            ...getPermissionAction(defaultValue),
            createMovement: defaultValue,
            updateMovement: defaultValue,
            allowSeeAllWarehouses: defaultValue,
        };
        this.category = getPermissionAction(defaultValue);
        this.billing = {
            ...getPermissionActionBase(defaultValue),
            allowCreatePayments: defaultValue,
            allowCreatePaymentMethods: defaultValue,
            allowDeletePaymentMethods: defaultValue,
            allowBuyLicenses: defaultValue,
        };
        this.order = {
            ...getPermissionAction(defaultValue),
            canConfirm: defaultValue,
            createWithoutConfirmation: defaultValue,
            completeAllPicking: defaultValue,
            completePickingItem: defaultValue,
            viewOrdersFromOtherUsers: defaultValue,
            allowUseAllWarehouses: defaultValue,
            allowCreateComments: defaultValue,
            allowCreatePayments: defaultValue,
            orderFormAllowEditPrice: defaultValue,
            orderFormAllowEditDiscount: defaultValue,
            orderFormAllowEditTax: defaultValue
        };
        this.purchase = {
            ...getPermissionAction(defaultValue),
            allowCreatePayments: defaultValue,
            allowCreateComments: defaultValue,
        };
        this.tax = getPermissionAction(defaultValue);
        this.discount = getPermissionAction(defaultValue);
        this.catalog = getPermissionAction(defaultValue);
        this.restaurant = {
            ...getPermissionAction(defaultValue),
            viewOrdersFromOtherUsers: defaultValue,
        };
        this.contact = getPermissionAction(defaultValue);
        this.channel = getPermissionAction(defaultValue);
        this.expense = getPermissionAction(defaultValue);
        this.cashRegister = {
            ...getPermissionAction(defaultValue),
            activateCashRegister: defaultValue,
            allowSeeAllCashRegisters: defaultValue,
        };
        this.permission = getPermissionAction(defaultValue);
        this.inventoryAdjustment = getPermissionAction(defaultValue);
        this.invoiceNumeration = getPermissionAction(defaultValue);
        this.cloud = getPermissionAction(defaultValue);
        this.orderReturn = getPermissionAction(defaultValue);
    }

    order?: IPermissionActionOrder;
    product?: IPermissionAction;
    account?: IPermissionActionAccount;
    organization?: PermissionActionOrganization;
    payment?: IPermissionAction;
    accounting?: IPermissionAction;
    user?: IPermissionAction;
    warehouse?: IPermissionActionWarehouse;
    statistics?: IPermissionActionBase;
    category?: IPermissionAction;
    orderReturn?: IPermissionAction;
    purchase?: IPermissionActionPurchase;
    tax?: IPermissionAction;
    discount?: IPermissionAction;
    catalog?: IPermissionAction;
    restaurant?: PermissionActionRestaurant;
    contact?: IPermissionAction;
    channel?: IPermissionAction;
    expense?: IPermissionAction;
    cashRegister?: IPermissionActionCashRegister;
    permission?: IPermissionAction;
    inventoryAdjustment?: IPermissionAction;
    invoiceNumeration?: IPermissionAction;
    cloud?: IPermissionAction;
    billing?: IPermissionBilling;
}
