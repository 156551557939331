import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../api";
import { createEventCalendar } from "../../api/marketing/marketingApi";
import { EventCalendar, EventCalendarImpl } from "../../api/models/eventCalendar";
import { getOrder } from "../../api/orders/orderApi";
import { getAuthBody } from "../../auth";
import { getUtcDate } from "../../utils";
import customMoment from "../../utils/momentFormat/dateFormat";
import { useAlert } from "../Alerts/Alert";
import ColorDropdown from "../Colors/ColorDropdown";
import LoadingDualRing from "../LoadingDualRing";
import OrderCardView from "../Orders/OrderList/OrderCardView";
import { DefaultButton, DefaultInput, DefaultTextarea, Flex, PrimaryButton } from "../_controls";
import Modal, { IModalShow } from "../modals/Modal";
import EventInterval from "./EventInternal";
import { getNextHalfHour } from "./helper";
import { CreateEventContainer } from "./styled";

interface CreateEventProps {
    initialEventDate?: moment.Moment;
    defaultEvent?: EventCalendar;
    onCancel: () => void;
    onCreate: (event: EventCalendar) => void;
    stopSave?: boolean;
    defaultTitle?: string;
}

export default function CreateEvent(props: CreateEventProps) {
    const { initialEventDate } = props;
    const alert = useAlert();

    const [event, setEvent] = useState<EventCalendar>(() => {
        if (props.defaultEvent) {
            let eventValue = { ...props.defaultEvent };
            eventValue.title = props.defaultTitle ?? eventValue.title;
            eventValue.startDate = customMoment(getUtcDate(eventValue.startDate.toString())).toDate();
            eventValue.endDate = customMoment(getUtcDate(eventValue.endDate.toString())).toDate();
            return eventValue;
        }

        var data = new EventCalendarImpl(getAuthBody().accountId);
        data.title = props.defaultTitle ?? "";
        if (initialEventDate) {
            data.startDate = initialEventDate.toDate();
            data.endDate = initialEventDate.clone().add(30, "minute").toDate();
        } else {
            data.startDate = getNextHalfHour().toDate();
            data.endDate = getNextHalfHour().add(30, "minute").toDate();
        }
        return data;
    });

    const createEventMutation = useMutation((data: EventCalendar) => createEventCalendar(data), {
        onSuccess: (data) => {
            props.onCreate(data);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const handleCreateEvent = () => {
        const localStartDate = customMoment(event.startDate);
        const localEndDate = customMoment(event.endDate);

        const utcStartDate = localStartDate.clone().utc().format();
        const utcEndDate = localEndDate.clone().utc().format();

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        let eventCopy = {
            ...event,
            startDate: utcStartDate,
            endDate: utcEndDate,
            timeZone,
        };
        if (!props.stopSave) {
            createEventMutation.mutate(eventCopy);
        } else {
            props.onCreate(eventCopy);
        }
    };

    const onChangeInput = (e: any) => {
        setEvent({ ...event, [e.target.name]: e.target.value });
    };

    let btnTitle = event.id ? "Actualizar" : "Crear Evento";
    if (props.stopSave) {
        btnTitle = props.defaultEvent ? "Guardar Cambiar" : "Crear Evento";
    }

    return (
        <CreateEventContainer>
            <Flex gap10 alignCenter>
                <ColorDropdown
                    color={event.color}
                    onRemove={() => setEvent({ ...event, color: undefined })}
                    onSelected={(color) => setEvent({ ...event, color: color })}
                />
                <DefaultInput
                    className="input-title"
                    w100
                    name="title"
                    disabled={props.defaultTitle ? true : false}
                    value={event.title ?? ""}
                    placeholder="Titulo"
                    onChange={onChangeInput}
                />
            </Flex>

            <Flex>
                <EventInterval
                    initialStartDate={customMoment(event.startDate)}
                    initialEndDate={customMoment(event.endDate)}
                    onDateChange={({ startDate, endDate }) => {
                        setEvent({ ...event, startDate: startDate.toDate(), endDate: endDate.toDate() });
                    }}
                />
            </Flex>

            <Flex w100 column>
                <DefaultTextarea
                    borderRadius={15}
                    placeholder="Descripción"
                    name="description"
                    value={event.description ?? ""}
                    onChange={onChangeInput}
                />
            </Flex>

            {event.relatedEntityId && <RelatedDocumentView event={event} />}

            <Flex justifyEnd gap10 marginTop={10}>
                <DefaultButton borderRadius={10} bgLight onClick={() => props.onCancel()}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton onClick={handleCreateEvent} borderRadius={10} disabled={!event.title || createEventMutation.isLoading}>
                    {btnTitle}
                    {createEventMutation.isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </CreateEventContainer>
    );
}

const RelatedDocumentView = ({ event }: { event: EventCalendar }) => {
    const orderMutation = useMutation((id: string) => getOrder(id));

    useEffect(() => {
        if (event.relatedEntityId && event.relatedEntityType === "order") {
            orderMutation.mutate(event.relatedEntityId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event.relatedEntityId, event.relatedEntityType]);

    const order = orderMutation.data;

    if (order) {
        return <OrderCardView order={order} />;
    }
    return null;
};

interface CreateEventModalProps extends IModalShow, CreateEventProps {}

export const CreateEventModal = (props: CreateEventModalProps) => {
    return (
        <Modal {...props} useButtonClose borderRadius={30}>
            <CreateEvent
                {...props}
                onCancel={() => props.setShow(false)}
                onCreate={(value) => {
                    props.onCreate(value);
                }}
            />
        </Modal>
    );
};
