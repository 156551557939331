import React, { useEffect, useState } from "react";
import { IOrderPickingItem } from "../../../api/models/order";
import { Flex, TextField, FlexImageStyle, PrimaryButton, SquareButton } from "../../_controls";
import TextBoxNumeral from "../../TextBoxNumeral";
import { getProductSku, getProductThumbnailUrl } from "../../Products/helper";
import { ProductQuantityPanelStyle } from "../../Products/ProductSearchList/styled";

interface PickingItemSelectedModalProps {
    item: IOrderPickingItem;
    onChange: (value: IOrderPickingItem) => void;
}

const getItemLoad = (value: IOrderPickingItem) => {
    if (value.quantityReceived === 0) {
        return { ...value, quantityReceived: 1 };
    }
    return value;
};

export default function PickingItemSelectedModal(props: PickingItemSelectedModalProps) {
    const { item } = props;
    const { product, productVariant } = item;
    const [selectedOrderItem, setSelectedOrderItem] = useState(getItemLoad(item));

    useEffect(() => {
        setSelectedOrderItem(getItemLoad(item));
    }, [item]);

    if (!selectedOrderItem.product) return <h1>Producto no reconocido</h1>;

    const updateQuantity = (value: number) => {
        if (value > item.quantity) {
            setSelectedOrderItem({ ...selectedOrderItem, quantityReceived: item.quantity });
        } else if (value < 0) {
            setSelectedOrderItem({ ...selectedOrderItem, quantityReceived: 0 });
        } else {
            setSelectedOrderItem({ ...selectedOrderItem, quantityReceived: value });
        }
    };

    const increaseQuantity = (value: number) => {
        var tempValue = selectedOrderItem.quantityReceived ?? 0;
        updateQuantity(tempValue + value);
    };

    return (
        <ProductQuantityPanelStyle>
            <Flex alignCenter gap10>
                <FlexImageStyle width={40} borderRadius={10} fitCover>
                    <img src={getProductThumbnailUrl(product, productVariant)} alt="" />
                </FlexImageStyle>
                <Flex column>
                    <TextField>{product?.name}</TextField>
                    <TextField light small>
                        {getProductSku(product, productVariant)}
                    </TextField>
                </Flex>
            </Flex>
            <TextField bold>Pick {selectedOrderItem.quantity}</TextField>

            <Flex className="quantity-card">
                <SquareButton onClick={() => increaseQuantity(-1)}>
                    <i className="fa-regular fa-minus"></i>
                </SquareButton>

                <TextBoxNumeral
                    value={selectedOrderItem.quantityReceived}
                    format="number"
                    className="input-quantity"
                    autoFocus
                    onNumberChange={(value) => updateQuantity(value)}
                />

                <SquareButton onClick={() => increaseQuantity(1)}>
                    <i className="fa-regular fa-plus"></i>
                </SquareButton>
            </Flex>

            <Flex justifyEnd>
                <PrimaryButton borderRadius={10} onClick={() => props.onChange(selectedOrderItem)}>
                    Confirmar
                </PrimaryButton>
            </Flex>
        </ProductQuantityPanelStyle>
    );
}
