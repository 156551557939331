import React, { useEffect, useState } from "react";
import customNumeral from "../../utils/numberFormat/moneyFormat";
import { formatCurrency } from "./helper";
import { InputCustom } from "./styled";

type Formats = "number" | "money" | "percentage" | "none";

export interface ITextBoxNumeralProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onNumberChange: (value: number) => void;
    value?: string | number;
    format: Formats;
    rounded?: boolean;
    w100?: boolean;
    bgLight?: boolean;
    style?: React.CSSProperties;
    decimalsLimit?: number;
    small?: boolean;
}

const formatValues = {
    none: "0",
    number: "0,0.[00]",
    money: "$0,0.[00]",
    percentage: "0",
};

export const TextBoxNumeral = React.forwardRef<HTMLInputElement, ITextBoxNumeralProps>((props, ref) => {
    const format = formatValues[props.format];
    const [priceFormat, setPriceFormat] = useState(() => {
        return {
            value: customNumeral(props.value ?? "0").format(format),
            show: false,
        };
    });

    useEffect(() => {
        let extra = props.format === "percentage" ? "%" : "";
        setPriceFormat({ ...priceFormat, value: extra + customNumeral(props.value).format(format) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const getSymbol = () => {
        if (props.format === "percentage") {
            return "%";
        }
        if (props.format === "money") {
            return "$";
        }
        return "";
    };

    const onChangePrice = (e: any) => {
        var result = formatCurrency(e.target.value, undefined, props.decimalsLimit);
        let extra = getSymbol();
        setPriceFormat({ value: extra + result, show: true });
        let newValue = customNumeral(result).value() ?? 0;
        props.onNumberChange(newValue);
    };

    const onBlurPrice = (e: any) => {
        var result = formatCurrency(e.target.value, undefined, props.decimalsLimit);
        let extra = getSymbol();

        setPriceFormat({ value: extra + result, show: false });
    };

    const onFocusPrice = (e: any) => {
        var number = customNumeral(e.target.value.replace("%", ""));
        let value = number.value() ?? 0;
        value = value.toString().replace(".", ",");
        setPriceFormat({ value, show: true });
    };

    return (
        <InputCustom
            ref={ref}
            style={props.style}
            type="text"
            id={props.id}
            className={"textbox-numeral " + props.className}
            name={props.name ? props.name : "price"}
            rounded={props.rounded}
            onBlur={onBlurPrice}
            w100={props.w100}
            bgLight={props.bgLight}
            onFocus={onFocusPrice}
            value={priceFormat.value}
            onChange={onChangePrice}
            autoFocus={props.autoFocus}
            maxLength={props.maxLength}
            disabled={props.disabled}
            small={props.small}
        />
    );
});

export default TextBoxNumeral;
