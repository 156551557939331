import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import EmptyResultViews from ".";
import { CloudImages } from "../../assets";

import PATHS from "../../constants/paths";
import { OrganizationContext } from "../../store/contexts/OrganizationContext";

export default function EmptyOrderNumeration() {
    const { organizationState } = useContext(OrganizationContext);

    const navigate = useNavigate();

    const onClickNumeration = () => {
        if (organizationState.currentOrganization?.id) navigate(PATHS.storeProfile(organizationState.currentOrganization?.id));
    };

    return (
        <EmptyResultViews
            btnTitle="Agregar Numeración"
            imageSize="md"
            image={CloudImages.pcGirl}
            btnOnClick={onClickNumeration}
            title={`${organizationState.currentOrganization?.name} no contiene ninguna numeración`}
            description="La tienda actual debe tener una numeración para poder facturar"
        />
    );
}
