import React, { KeyboardEventHandler, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { MultiValue } from "react-select";
import CreateTable from "react-select/creatable";
import { ProductCreationProps } from ".";
import { SelectBaseOption } from "../../../api/models";
import { MEASUREMENT_LIST, SelectOption, getSelectOptions } from "../../../constants/unitsMeasure";
import { getSelectOption } from "../../../utils/select";
import { useAlert } from "../../Alerts/Alert";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultInput, Flex, GridTemplate } from "../../_controls";
import { getReferenceCodes } from "../helper";
import ProductFeaturesForm from "./ProductFeaturesForm";
import messages from "./messages";
import { FormInputProduct } from "./styled";

export default function MoreDetailsForm(props: ProductCreationProps) {
    const intl = useIntl();

    const alert = useAlert();

    const onChange = (e: any) => {
        let value = e.target.value;
        if ((e.target.name === "sku" || e.target.name === "code") && value.match(/[ñÑ]/g)) {
            alert.error("No se esta permitido el uso de Ñ o ñ en este campo");
            return;
        }
        props.onChange({
            ...props.product,
            [e.target.name]: value,
        });
    };

    return (
        <Flex column gap15>
            <GridTemplate size={200}>
                <FormInputProduct>
                    <label htmlFor="name">{intl.formatMessage(messages.productSku)}</label>
                    <DefaultInput
                        name="sku"
                        type="text"
                        onChange={onChange}
                        value={props.product.sku ?? ""}
                        placeholder={intl.formatMessage(messages.productSku)}
                    />
                </FormInputProduct>
                <FormInputProduct>
                    <label htmlFor="name">{intl.formatMessage(messages.productBarcode)}</label>
                    <DefaultInput
                        name="code"
                        type="text"
                        onChange={onChange}
                        value={props.product.code}
                        placeholder={intl.formatMessage(messages.productBarcode)}
                    />
                </FormInputProduct>
            </GridTemplate>
            <ProductExtraDetailsForm {...props} />
            <h5 className="text-bold m-0 mt-1 text-small">Características</h5>
            <ProductFeaturesForm
                productId={props.product.id ?? ""}
                features={props.product.features ?? []}
                onChange={(value) => {
                    props.onChange({ ...props.product, features: value });
                }}
            />
        </Flex>
    );
}

export const ProductExtraDetailsForm = (props: ProductCreationProps) => {
    const intl = useIntl();

    const [inputValue, setInputValue] = useState("");
    const [selectOptionMeasurement, setSelectOptionMeasurement] = useState<SelectOption[]>([]);

    const referenceCodes = React.useMemo(() => {
        return getReferenceCodes(props.product.referenceCodes);
    }, [props.product.referenceCodes]);

    useEffect(() => {
        setSelectOptionMeasurement(getSelectOptions());
    }, []);

    const onChange = (e: any) => {
        let value = e.target.value;
        props.onChange({
            ...props.product,
            [e.target.name]: value,
        });
    };

    const [unitMeasureSelected, setUnitMeasureSelected] = useState({
        measureType: "",
        prefix: "",
    });

    const onNumberChange = (value: number, name: string) => {
        props.onChange({
            ...props.product,
            [name]: value,
        });
    };

    const onAddNewReferenceValues = (value: string) => {
        let codes = referenceCodes;
        props.onChange({ ...props.product, referenceCodes: JSON.stringify([...codes, value]) });
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        let value = inputValue.trim();
        if (value) {
            switch (event.key) {
                case "Enter":
                case "Tab":
                    onAddNewReferenceValues(value);
                    setInputValue("");
                    event.preventDefault();
            }
        }
    };

    const onChangeReferenceValue = (items: MultiValue<SelectBaseOption>) => {
        let isRemove = items.length < referenceCodes.length;
        if (isRemove) {
            let values = referenceCodes.filter((x) => items.find((item) => item.id === x));
            props.onChange({ ...props.product, referenceCodes: JSON.stringify(values) });
        } else {
            let last = items.find((x) => !referenceCodes.find((item) => item === x.id));
            if (last) {
                props.onChange({ ...props.product, referenceCodes: JSON.stringify([...referenceCodes, last.value]) });
            }
        }
    };

    const onChangeMeasure = (value: any) => {
        props.onChange({ ...props.product, measurement: value.value });
        setUnitMeasureSelected({ measureType: value.category, prefix: value.value });
    };

    const nameMeasureSelected = () => {
        let measurement = MEASUREMENT_LIST.find((x) => x.value === props.product.measurement);
        if (props.product.measurement) {
            return getSelectOption({
                id: measurement?.value ?? props.product.measurement,
                value: measurement?.name ?? props.product.measurement,
            });
        } else {
            return undefined;
        }
    };

    const onCreateOptionMeasurement = (inputValue: string) => {
        setUnitMeasureSelected({ measureType: "", prefix: inputValue });
        props.onChange({ ...props.product, measurement: inputValue });
    };

    return (
        <Flex column gap20>
            <FormInputProduct>
                <label htmlFor="reference">{intl.formatMessage(messages.productReference)}</label>
                <DefaultInput
                    name="reference"
                    type="text"
                    onChange={onChange}
                    value={props.product.reference}
                    placeholder={intl.formatMessage(messages.productReference)}
                />
            </FormInputProduct>

            <FormInputProduct>
                <label htmlFor="reference">Multiples Referencias</label>
                <SelectBody className="multi-value-theme-color">
                    <CreateTable
                        className="react-select-basic"
                        classNamePrefix="select"
                        inputValue={inputValue}
                        components={{ DropdownIndicator: null }}
                        isMulti
                        menuIsOpen={false}
                        onChange={(value) => onChangeReferenceValue(value)}
                        onInputChange={(value) => setInputValue(value)}
                        onKeyDown={handleKeyDown}
                        styles={undefined}
                        placeholder="Agrega las referencias y presiona Enter"
                        value={referenceCodes.map((item) => getSelectOption({ id: item, value: item }))}
                    />
                </SelectBody>
            </FormInputProduct>

            <Flex gap={10} alignCenter flexWrap>
                <SelectBody>
                    <span className="label">Unidad de Medida</span>
                    <CreateTable
                        placeholder="Seleccionar"
                        className="react-select-basic"
                        classNamePrefix="select"
                        key={props.product.measurement}
                        defaultValue={undefined}
                        isDisabled={false}
                        isRtl={false}
                        onCreateOption={onCreateOptionMeasurement}
                        onChange={(value) => {
                            onChangeMeasure(value);
                        }}
                        isClearable={false}
                        value={nameMeasureSelected()}
                        isSearchable={true}
                        name="customCategory"
                        options={selectOptionMeasurement.map((item: any) => {
                            return { label: intl.formatMessage((messages as any)[item.label]), options: item.options };
                        })}
                    />
                </SelectBody>
                <Flex column gap5>
                    <span className="text-light text-small">
                        {intl.formatMessage(messages.weight)}{" "}
                        {unitMeasureSelected.measureType === "mass" ? `(${unitMeasureSelected.prefix})` : "(kg)"}
                    </span>
                    <TextBoxNumeral
                        format="number"
                        name="itemWeight"
                        type="text"
                        rounded
                        onNumberChange={(value) => onNumberChange(value, "itemWeight")}
                        value={props.product.itemWeight}
                    />
                </Flex>
                <Flex column gap5>
                    <span className="text-light text-small">
                        {intl.formatMessage(messages.length)}{" "}
                        {unitMeasureSelected.measureType === "unitLength" ? `(${unitMeasureSelected.prefix})` : "(cm)"}
                    </span>
                    <TextBoxNumeral
                        format="number"
                        name="itemLength"
                        type="text"
                        rounded
                        onNumberChange={(value) => onNumberChange(value, "itemLength")}
                        value={props.product.itemLength}
                    />
                </Flex>
                <Flex column gap5>
                    <span className="text-light text-small">
                        {intl.formatMessage(messages.heigh)}{" "}
                        {unitMeasureSelected.measureType === "unitLength" ? `(${unitMeasureSelected.prefix})` : "(cm)"}
                    </span>
                    <TextBoxNumeral
                        format="number"
                        name="itemHeight"
                        type="text"
                        rounded
                        onNumberChange={(value) => onNumberChange(value, "itemHeight")}
                        value={props.product.itemHeight}
                    />
                </Flex>
                <Flex column gap5>
                    <span className="text-light text-small">
                        {intl.formatMessage(messages.width)}{" "}
                        {unitMeasureSelected.measureType === "unitLength" ? `(${unitMeasureSelected.prefix})` : "(cm)"}
                    </span>
                    <TextBoxNumeral
                        format="number"
                        name="itemWidth"
                        type="text"
                        rounded
                        onNumberChange={(value) => onNumberChange(value, "itemWidth")}
                        value={props.product.itemWidth}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
