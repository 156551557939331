export const EMOJI_CATEGORIES = [
    {
        icon: "fa-regular fa-clock-rotate-left",
        category: "popular",
    },
    {
        icon: "fa-regular fa-smile",
        category: "smileysEmotion",
    },
    {
        icon: "fa-regular fa-thumbs-up",
        category: "peopleBody",
    },
    {
        icon: "fa-regular fa-paw",
        category: "animalsNature",
    },
    {
        icon: "fa-regular fa-utensils",
        category: "foodDrink",
    },

    {
        icon: "fa-regular fa-plane",
        category: "travelPlaces",
    },
    {
        icon: "fa-regular fa-football-ball",
        category: "activities",
    },
    {
        icon: "fa-regular fa-lightbulb",
        category: "objects",
    },
    {
        icon: "fa-regular fa-star",
        category: "symbols",
    },
    {
        icon: "fa-regular fa-flag",
        category: "flags",
    },
];

export interface EmojiItem {
    emoji: string;
    description: string;
}

export const POPULAR_EMOJIS: EmojiItem[] = [
    {
        emoji: "😂",
        description: "Cara con lágrimas de alegría",
    },
    {
        emoji: "❤️",
        description: "Corazón rojo",
    },
    {
        emoji: "😍",
        description: "Cara con ojos de corazón",
    },
    {
        emoji: "😭",
        description: "Cara llorando fuertemente",
    },
    {
        emoji: "😊",
        description: "Cara sonriendo con ojos sonrientes",
    },
    {
        emoji: "🥰",
        description: "Cara sonriendo con tres corazones",
    },
    {
        emoji: "😘",
        description: "Cara lanzando un beso",
    },
    {
        emoji: "🙏",
        description: "Manos juntas en señal de súplica o agradecimiento",
    },
    {
        emoji: "🤣",
        description: "Cara rodando por el suelo de risa",
    },
    {
        emoji: "😅",
        description: "Cara sonriendo con gota de sudor",
    },
    {
        emoji: "🤔",
        description: "Cara pensativa",
    },
    {
        emoji: "😁",
        description: "Cara sonriendo con ojos sonrientes",
    },
    {
        emoji: "😎",
        description: "Cara sonriendo con gafas de sol",
    },
    {
        emoji: "💩",
        description: "Excremento sonriente",
    },
    {
        emoji: "🚀",
        description: "Cohete",
    },
    {
        emoji: "🎉",
        description: "Cono de confeti",
    },
    {
        emoji: "😇",
        description: "Cara de ángel",
    },
    {
        emoji: "🤩",
        description: "Cara con ojos estrellados",
    },
    {
        emoji: "👍",
        description: "Pulgar hacia arriba",
    },
    {
        emoji: "😆",
        description: "Cara sonriendo con los ojos cerrados",
    },
];

export type EMOJI_LIST_TYPE = {
    [key: string]: EmojiItem[];
};

export const EMOJI_LIST_ALL: EMOJI_LIST_TYPE = {
    smileysEmotion: [
        {
            emoji: "😀",
            description: "grinning face",
        },
        {
            emoji: "😃",
            description: "grinning face with big eyes",
        },
        {
            emoji: "😄",
            description: "grinning face with smiling eyes",
        },
        {
            emoji: "😁",
            description: "beaming face with smiling eyes",
        },
        {
            emoji: "😆",
            description: "grinning squinting face",
        },
        {
            emoji: "😅",
            description: "grinning face with sweat",
        },
        {
            emoji: "🤣",
            description: "rolling on the floor laughing",
        },
        {
            emoji: "😂",
            description: "face with tears of joy",
        },
        {
            emoji: "🙂",
            description: "slightly smiling face",
        },
        {
            emoji: "🙃",
            description: "upside-down face",
        },
        {
            emoji: "😉",
            description: "winking face",
        },
        {
            emoji: "😊",
            description: "smiling face with smiling eyes",
        },
        {
            emoji: "😇",
            description: "smiling face with halo",
        },
        {
            emoji: "🥰",
            description: "smiling face with hearts",
        },
        {
            emoji: "😍",
            description: "smiling face with heart-eyes",
        },
        {
            emoji: "🤩",
            description: "star-struck",
        },
        {
            emoji: "😘",
            description: "face blowing a kiss",
        },
        {
            emoji: "😗",
            description: "kissing face",
        },
        {
            emoji: "☺️",
            description: "smiling face",
        },
        {
            emoji: "😚",
            description: "kissing face with closed eyes",
        },
        {
            emoji: "😙",
            description: "kissing face with smiling eyes",
        },
        {
            emoji: "🥲",
            description: "smiling face with tear",
        },
        {
            emoji: "😋",
            description: "face savoring food",
        },
        {
            emoji: "😛",
            description: "face with tongue",
        },
        {
            emoji: "😜",
            description: "winking face with tongue",
        },
        {
            emoji: "🤪",
            description: "zany face",
        },
        {
            emoji: "😝",
            description: "squinting face with tongue",
        },
        {
            emoji: "🤑",
            description: "money-mouth face",
        },
        {
            emoji: "🤗",
            description: "hugging face",
        },
        {
            emoji: "🤭",
            description: "face with hand over mouth",
        },
        {
            emoji: "🤫",
            description: "shushing face",
        },
        {
            emoji: "🤔",
            description: "thinking face",
        },
        {
            emoji: "🤐",
            description: "zipper-mouth face",
        },
        {
            emoji: "🤨",
            description: "face with raised eyebrow",
        },
        {
            emoji: "😐",
            description: "neutral face",
        },
        {
            emoji: "😑",
            description: "expressionless face",
        },
        {
            emoji: "😶",
            description: "face without mouth",
        },
        {
            emoji: "😶‍🌫️",
            description: "face in clouds",
        },
        {
            emoji: "😏",
            description: "smirking face",
        },
        {
            emoji: "😒",
            description: "unamused face",
        },
        {
            emoji: "🙄",
            description: "face with rolling eyes",
        },
        {
            emoji: "😬",
            description: "grimacing face",
        },
        {
            emoji: "😮‍💨",
            description: "face exhaling",
        },
        {
            emoji: "🤥",
            description: "lying face",
        },
        {
            emoji: "😌",
            description: "relieved face",
        },
        {
            emoji: "😔",
            description: "pensive face",
        },
        {
            emoji: "😪",
            description: "sleepy face",
        },
        {
            emoji: "🤤",
            description: "drooling face",
        },
        {
            emoji: "😴",
            description: "sleeping face",
        },
        {
            emoji: "😷",
            description: "face with medical mask",
        },
        {
            emoji: "🤒",
            description: "face with thermometer",
        },
        {
            emoji: "🤕",
            description: "face with head-bandage",
        },
        {
            emoji: "🤢",
            description: "nauseated face",
        },
        {
            emoji: "🤮",
            description: "face vomiting",
        },
        {
            emoji: "🤧",
            description: "sneezing face",
        },
        {
            emoji: "🥵",
            description: "hot face",
        },
        {
            emoji: "🥶",
            description: "cold face",
        },
        {
            emoji: "🥴",
            description: "woozy face",
        },
        {
            emoji: "😵",
            description: "knocked-out face",
        },
        {
            emoji: "😵‍💫",
            description: "face with spiral eyes",
        },
        {
            emoji: "🤯",
            description: "exploding head",
        },
        {
            emoji: "🤠",
            description: "cowboy hat face",
        },
        {
            emoji: "🥳",
            description: "partying face",
        },
        {
            emoji: "🥸",
            description: "disguised face",
        },
        {
            emoji: "😎",
            description: "smiling face with sunglasses",
        },
        {
            emoji: "🤓",
            description: "nerd face",
        },
        {
            emoji: "🧐",
            description: "face with monocle",
        },
        {
            emoji: "😕",
            description: "confused face",
        },
        {
            emoji: "😟",
            description: "worried face",
        },
        {
            emoji: "🙁",
            description: "slightly frowning face",
        },
        {
            emoji: "☹️",
            description: "frowning face",
        },
        {
            emoji: "😮",
            description: "face with open mouth",
        },
        {
            emoji: "😯",
            description: "hushed face",
        },
        {
            emoji: "😲",
            description: "astonished face",
        },
        {
            emoji: "😳",
            description: "flushed face",
        },
        {
            emoji: "🥺",
            description: "pleading face",
        },
        {
            emoji: "😦",
            description: "frowning face with open mouth",
        },
        {
            emoji: "😧",
            description: "anguished face",
        },
        {
            emoji: "😨",
            description: "fearful face",
        },
        {
            emoji: "😰",
            description: "anxious face with sweat",
        },
        {
            emoji: "😥",
            description: "sad but relieved face",
        },
        {
            emoji: "😢",
            description: "crying face",
        },
        {
            emoji: "😭",
            description: "loudly crying face",
        },
        {
            emoji: "😱",
            description: "face screaming in fear",
        },
        {
            emoji: "😖",
            description: "confounded face",
        },
        {
            emoji: "😣",
            description: "persevering face",
        },
        {
            emoji: "😞",
            description: "disappointed face",
        },
        {
            emoji: "😓",
            description: "downcast face with sweat",
        },
        {
            emoji: "😩",
            description: "weary face",
        },
        {
            emoji: "😫",
            description: "tired face",
        },
        {
            emoji: "🥱",
            description: "yawning face",
        },
        {
            emoji: "😤",
            description: "face with steam from nose",
        },
        {
            emoji: "😡",
            description: "pouting face",
        },
        {
            emoji: "😠",
            description: "angry face",
        },
        {
            emoji: "🤬",
            description: "face with symbols on mouth",
        },
        {
            emoji: "😈",
            description: "smiling face with horns",
        },
        {
            emoji: "👿",
            description: "angry face with horns",
        },
        {
            emoji: "💀",
            description: "skull",
        },
        {
            emoji: "☠️",
            description: "skull and crossbones",
        },
        {
            emoji: "💩",
            description: "pile of poo",
        },
        {
            emoji: "🤡",
            description: "clown face",
        },
        {
            emoji: "👹",
            description: "ogre",
        },
        {
            emoji: "👺",
            description: "goblin",
        },
        {
            emoji: "👻",
            description: "ghost",
        },
        {
            emoji: "👽",
            description: "alien",
        },
        {
            emoji: "👾",
            description: "alien monster",
        },
        {
            emoji: "🤖",
            description: "robot",
        },
        {
            emoji: "😺",
            description: "grinning cat",
        },
        {
            emoji: "😸",
            description: "grinning cat with smiling eyes",
        },
        {
            emoji: "😹",
            description: "cat with tears of joy",
        },
        {
            emoji: "😻",
            description: "smiling cat with heart-eyes",
        },
        {
            emoji: "😼",
            description: "cat with wry smile",
        },
        {
            emoji: "😽",
            description: "kissing cat",
        },
        {
            emoji: "🙀",
            description: "weary cat",
        },
        {
            emoji: "😿",
            description: "crying cat",
        },
        {
            emoji: "😾",
            description: "pouting cat",
        },
        {
            emoji: "🙈",
            description: "see-no-evil monkey",
        },
        {
            emoji: "🙉",
            description: "hear-no-evil monkey",
        },
        {
            emoji: "🙊",
            description: "speak-no-evil monkey",
        },
        {
            emoji: "💋",
            description: "kiss mark",
        },
        {
            emoji: "💌",
            description: "love letter",
        },
        {
            emoji: "💘",
            description: "heart with arrow",
        },
        {
            emoji: "💝",
            description: "heart with ribbon",
        },
        {
            emoji: "💖",
            description: "sparkling heart",
        },
        {
            emoji: "💗",
            description: "growing heart",
        },
        {
            emoji: "💓",
            description: "beating heart",
        },
        {
            emoji: "💞",
            description: "revolving hearts",
        },
        {
            emoji: "💕",
            description: "two hearts",
        },
        {
            emoji: "💟",
            description: "heart decoration",
        },
        {
            emoji: "❣️",
            description: "heart exclamation",
        },
        {
            emoji: "💔",
            description: "broken heart",
        },
        {
            emoji: "❤️‍🔥",
            description: "heart on fire",
        },
        {
            emoji: "❤️‍🩹",
            description: "mending heart",
        },
        {
            emoji: "❤️",
            description: "red heart",
        },
        {
            emoji: "🧡",
            description: "orange heart",
        },
        {
            emoji: "💛",
            description: "yellow heart",
        },
        {
            emoji: "💚",
            description: "green heart",
        },
        {
            emoji: "💙",
            description: "blue heart",
        },
        {
            emoji: "💜",
            description: "purple heart",
        },
        {
            emoji: "🤎",
            description: "brown heart",
        },
        {
            emoji: "🖤",
            description: "black heart",
        },
        {
            emoji: "🤍",
            description: "white heart",
        },
        {
            emoji: "💯",
            description: "hundred points",
        },
        {
            emoji: "💢",
            description: "anger symbol",
        },
        {
            emoji: "💥",
            description: "collision",
        },
        {
            emoji: "💫",
            description: "dizzy",
        },
        {
            emoji: "💦",
            description: "sweat droplets",
        },
        {
            emoji: "💨",
            description: "dashing away",
        },
        {
            emoji: "🕳️",
            description: "hole",
        },
        {
            emoji: "💣",
            description: "bomb",
        },
        {
            emoji: "💬",
            description: "speech balloon",
        },
        {
            emoji: "👁️‍🗨️",
            description: "eye in speech bubble",
        },
        {
            emoji: "🗨️",
            description: "left speech bubble",
        },
        {
            emoji: "🗯️",
            description: "right anger bubble",
        },
        {
            emoji: "💭",
            description: "thought balloon",
        },
        {
            emoji: "💤",
            description: "zzz",
        },
    ],
    peopleBody: [
        {
            emoji: "👋",
            description: "waving hand",
        },
        {
            emoji: "🤚",
            description: "raised back of hand",
        },
        {
            emoji: "🖐️",
            description: "hand with fingers splayed",
        },
        {
            emoji: "✋",
            description: "raised hand",
        },
        {
            emoji: "🖖",
            description: "vulcan salute",
        },
        {
            emoji: "👌",
            description: "OK hand",
        },
        {
            emoji: "🤌",
            description: "pinched fingers",
        },
        {
            emoji: "🤏",
            description: "pinching hand",
        },
        {
            emoji: "✌️",
            description: "victory hand",
        },
        {
            emoji: "🤞",
            description: "crossed fingers",
        },
        {
            emoji: "🤟",
            description: "love-you gesture",
        },
        {
            emoji: "🤘",
            description: "sign of the horns",
        },
        {
            emoji: "🤙",
            description: "call me hand",
        },
        {
            emoji: "👈",
            description: "backhand index pointing left",
        },
        {
            emoji: "👉",
            description: "backhand index pointing right",
        },
        {
            emoji: "👆",
            description: "backhand index pointing up",
        },
        {
            emoji: "🖕",
            description: "middle finger",
        },
        {
            emoji: "👇",
            description: "backhand index pointing down",
        },
        {
            emoji: "☝️",
            description: "index pointing up",
        },
        {
            emoji: "👍",
            description: "thumbs up",
        },
        {
            emoji: "👎",
            description: "thumbs down",
        },
        {
            emoji: "✊",
            description: "raised fist",
        },
        {
            emoji: "👊",
            description: "oncoming fist",
        },
        {
            emoji: "🤛",
            description: "left-facing fist",
        },
        {
            emoji: "🤜",
            description: "right-facing fist",
        },
        {
            emoji: "👏",
            description: "clapping hands",
        },
        {
            emoji: "🙌",
            description: "raising hands",
        },
        {
            emoji: "👐",
            description: "open hands",
        },
        {
            emoji: "🤲",
            description: "palms up together",
        },
        {
            emoji: "🤝",
            description: "handshake",
        },
        {
            emoji: "🙏",
            description: "folded hands",
        },
        {
            emoji: "✍️",
            description: "writing hand",
        },
        {
            emoji: "💅",
            description: "nail polish",
        },
        {
            emoji: "🤳",
            description: "selfie",
        },
        {
            emoji: "💪",
            description: "flexed biceps",
        },
        {
            emoji: "🦾",
            description: "mechanical arm",
        },
        {
            emoji: "🦿",
            description: "mechanical leg",
        },
        {
            emoji: "🦵",
            description: "leg",
        },
        {
            emoji: "🦶",
            description: "foot",
        },
        {
            emoji: "👂",
            description: "ear",
        },
        {
            emoji: "🦻",
            description: "ear with hearing aid",
        },
        {
            emoji: "👃",
            description: "nose",
        },
        {
            emoji: "🧠",
            description: "brain",
        },
        {
            emoji: "🫀",
            description: "anatomical heart",
        },
        {
            emoji: "🫁",
            description: "lungs",
        },
        {
            emoji: "🦷",
            description: "tooth",
        },
        {
            emoji: "🦴",
            description: "bone",
        },
        {
            emoji: "👀",
            description: "eyes",
        },
        {
            emoji: "👁️",
            description: "eye",
        },
        {
            emoji: "👅",
            description: "tongue",
        },
        {
            emoji: "👄",
            description: "mouth",
        },
        {
            emoji: "👶",
            description: "baby",
        },
        {
            emoji: "🧒",
            description: "child",
        },
        {
            emoji: "👦",
            description: "boy",
        },
        {
            emoji: "👧",
            description: "girl",
        },
        {
            emoji: "🧑",
            description: "person",
        },
        {
            emoji: "👱",
            description: "person: blond hair",
        },
        {
            emoji: "👨",
            description: "man",
        },
        {
            emoji: "🧔",
            description: "person: beard",
        },
        {
            emoji: "🧔‍♂️",
            description: "man: beard",
        },
        {
            emoji: "🧔‍♀️",
            description: "woman: beard",
        },
        {
            emoji: "👨‍🦰",
            description: "man: red hair",
        },
        {
            emoji: "👨‍🦱",
            description: "man: curly hair",
        },
        {
            emoji: "👨‍🦳",
            description: "man: white hair",
        },
        {
            emoji: "👨‍🦲",
            description: "man: bald",
        },
        {
            emoji: "👩",
            description: "woman",
        },
        {
            emoji: "👩‍🦰",
            description: "woman: red hair",
        },
        {
            emoji: "🧑‍🦰",
            description: "person: red hair",
        },
        {
            emoji: "👩‍🦱",
            description: "woman: curly hair",
        },
        {
            emoji: "🧑‍🦱",
            description: "person: curly hair",
        },
        {
            emoji: "👩‍🦳",
            description: "woman: white hair",
        },
        {
            emoji: "🧑‍🦳",
            description: "person: white hair",
        },
        {
            emoji: "👩‍🦲",
            description: "woman: bald",
        },
        {
            emoji: "🧑‍🦲",
            description: "person: bald",
        },
        {
            emoji: "👱‍♀️",
            description: "woman: blond hair",
        },
        {
            emoji: "👱‍♂️",
            description: "man: blond hair",
        },
        {
            emoji: "🧓",
            description: "older person",
        },
        {
            emoji: "👴",
            description: "old man",
        },
        {
            emoji: "👵",
            description: "old woman",
        },
        {
            emoji: "🙍",
            description: "person frowning",
        },
        {
            emoji: "🙍‍♂️",
            description: "man frowning",
        },
        {
            emoji: "🙍‍♀️",
            description: "woman frowning",
        },
        {
            emoji: "🙎",
            description: "person pouting",
        },
        {
            emoji: "🙎‍♂️",
            description: "man pouting",
        },
        {
            emoji: "🙎‍♀️",
            description: "woman pouting",
        },
        {
            emoji: "🙅",
            description: "person gesturing NO",
        },
        {
            emoji: "🙅‍♂️",
            description: "man gesturing NO",
        },
        {
            emoji: "🙅‍♀️",
            description: "woman gesturing NO",
        },
        {
            emoji: "🙆",
            description: "person gesturing OK",
        },
        {
            emoji: "🙆‍♂️",
            description: "man gesturing OK",
        },
        {
            emoji: "🙆‍♀️",
            description: "woman gesturing OK",
        },
        {
            emoji: "💁",
            description: "person tipping hand",
        },
        {
            emoji: "💁‍♂️",
            description: "man tipping hand",
        },
        {
            emoji: "💁‍♀️",
            description: "woman tipping hand",
        },
        {
            emoji: "🙋",
            description: "person raising hand",
        },
        {
            emoji: "🙋‍♂️",
            description: "man raising hand",
        },
        {
            emoji: "🙋‍♀️",
            description: "woman raising hand",
        },
        {
            emoji: "🧏",
            description: "deaf person",
        },
        {
            emoji: "🧏‍♂️",
            description: "deaf man",
        },
        {
            emoji: "🧏‍♀️",
            description: "deaf woman",
        },
        {
            emoji: "🙇",
            description: "person bowing",
        },
        {
            emoji: "🙇‍♂️",
            description: "man bowing",
        },
        {
            emoji: "🙇‍♀️",
            description: "woman bowing",
        },
        {
            emoji: "🤦",
            description: "person facepalming",
        },
        {
            emoji: "🤦‍♂️",
            description: "man facepalming",
        },
        {
            emoji: "🤦‍♀️",
            description: "woman facepalming",
        },
        {
            emoji: "🤷",
            description: "person shrugging",
        },
        {
            emoji: "🤷‍♂️",
            description: "man shrugging",
        },
        {
            emoji: "🤷‍♀️",
            description: "woman shrugging",
        },
        {
            emoji: "🧑‍⚕️",
            description: "health worker",
        },
        {
            emoji: "👨‍⚕️",
            description: "man health worker",
        },
        {
            emoji: "👩‍⚕️",
            description: "woman health worker",
        },
        {
            emoji: "🧑‍🎓",
            description: "student",
        },
        {
            emoji: "👨‍🎓",
            description: "man student",
        },
        {
            emoji: "👩‍🎓",
            description: "woman student",
        },
        {
            emoji: "🧑‍🏫",
            description: "teacher",
        },
        {
            emoji: "👨‍🏫",
            description: "man teacher",
        },
        {
            emoji: "👩‍🏫",
            description: "woman teacher",
        },
        {
            emoji: "🧑‍⚖️",
            description: "judge",
        },
        {
            emoji: "👨‍⚖️",
            description: "man judge",
        },
        {
            emoji: "👩‍⚖️",
            description: "woman judge",
        },
        {
            emoji: "🧑‍🌾",
            description: "farmer",
        },
        {
            emoji: "👨‍🌾",
            description: "man farmer",
        },
        {
            emoji: "👩‍🌾",
            description: "woman farmer",
        },
        {
            emoji: "🧑‍🍳",
            description: "cook",
        },
        {
            emoji: "👨‍🍳",
            description: "man cook",
        },
        {
            emoji: "👩‍🍳",
            description: "woman cook",
        },
        {
            emoji: "🧑‍🔧",
            description: "mechanic",
        },
        {
            emoji: "👨‍🔧",
            description: "man mechanic",
        },
        {
            emoji: "👩‍🔧",
            description: "woman mechanic",
        },
        {
            emoji: "🧑‍🏭",
            description: "factory worker",
        },
        {
            emoji: "👨‍🏭",
            description: "man factory worker",
        },
        {
            emoji: "👩‍🏭",
            description: "woman factory worker",
        },
        {
            emoji: "🧑‍💼",
            description: "office worker",
        },
        {
            emoji: "👨‍💼",
            description: "man office worker",
        },
        {
            emoji: "👩‍💼",
            description: "woman office worker",
        },
        {
            emoji: "🧑‍🔬",
            description: "scientist",
        },
        {
            emoji: "👨‍🔬",
            description: "man scientist",
        },
        {
            emoji: "👩‍🔬",
            description: "woman scientist",
        },
        {
            emoji: "🧑‍💻",
            description: "technologist",
        },
        {
            emoji: "👨‍💻",
            description: "man technologist",
        },
        {
            emoji: "👩‍💻",
            description: "woman technologist",
        },
        {
            emoji: "🧑‍🎤",
            description: "singer",
        },
        {
            emoji: "👨‍🎤",
            description: "man singer",
        },
        {
            emoji: "👩‍🎤",
            description: "woman singer",
        },
        {
            emoji: "🧑‍🎨",
            description: "artist",
        },
        {
            emoji: "👨‍🎨",
            description: "man artist",
        },
        {
            emoji: "👩‍🎨",
            description: "woman artist",
        },
        {
            emoji: "🧑‍✈️",
            description: "pilot",
        },
        {
            emoji: "👨‍✈️",
            description: "man pilot",
        },
        {
            emoji: "👩‍✈️",
            description: "woman pilot",
        },
        {
            emoji: "🧑‍🚀",
            description: "astronaut",
        },
        {
            emoji: "👨‍🚀",
            description: "man astronaut",
        },
        {
            emoji: "👩‍🚀",
            description: "woman astronaut",
        },
        {
            emoji: "🧑‍🚒",
            description: "firefighter",
        },
        {
            emoji: "👨‍🚒",
            description: "man firefighter",
        },
        {
            emoji: "👩‍🚒",
            description: "woman firefighter",
        },
        {
            emoji: "👮",
            description: "police officer",
        },
        {
            emoji: "👮‍♂️",
            description: "man police officer",
        },
        {
            emoji: "👮‍♀️",
            description: "woman police officer",
        },
        {
            emoji: "🕵️",
            description: "detective",
        },
        {
            emoji: "🕵️‍♂️",
            description: "man detective",
        },
        {
            emoji: "🕵️‍♀️",
            description: "woman detective",
        },
        {
            emoji: "💂",
            description: "guard",
        },
        {
            emoji: "💂‍♂️",
            description: "man guard",
        },
        {
            emoji: "💂‍♀️",
            description: "woman guard",
        },
        {
            emoji: "🥷",
            description: "ninja",
        },
        {
            emoji: "👷",
            description: "construction worker",
        },
        {
            emoji: "👷‍♂️",
            description: "man construction worker",
        },
        {
            emoji: "👷‍♀️",
            description: "woman construction worker",
        },
        {
            emoji: "🤴",
            description: "prince",
        },
        {
            emoji: "👸",
            description: "princess",
        },
        {
            emoji: "👳",
            description: "person wearing turban",
        },
        {
            emoji: "👳‍♂️",
            description: "man wearing turban",
        },
        {
            emoji: "👳‍♀️",
            description: "woman wearing turban",
        },
        {
            emoji: "👲",
            description: "person with skullcap",
        },
        {
            emoji: "🧕",
            description: "woman with headscarf",
        },
        {
            emoji: "🤵",
            description: "person in tuxedo",
        },
        {
            emoji: "🤵‍♂️",
            description: "man in tuxedo",
        },
        {
            emoji: "🤵‍♀️",
            description: "woman in tuxedo",
        },
        {
            emoji: "👰",
            description: "person with veil",
        },
        {
            emoji: "👰‍♂️",
            description: "man with veil",
        },
        {
            emoji: "👰‍♀️",
            description: "woman with veil",
        },
        {
            emoji: "🤰",
            description: "pregnant woman",
        },
        {
            emoji: "🤱",
            description: "breast-feeding",
        },
        {
            emoji: "👩‍🍼",
            description: "woman feeding baby",
        },
        {
            emoji: "👨‍🍼",
            description: "man feeding baby",
        },
        {
            emoji: "🧑‍🍼",
            description: "person feeding baby",
        },
        {
            emoji: "👼",
            description: "baby angel",
        },
        {
            emoji: "🎅",
            description: "Santa Claus",
        },
        {
            emoji: "🤶",
            description: "Mrs. Claus",
        },
        {
            emoji: "🧑‍🎄",
            description: "mx claus",
        },
        {
            emoji: "🦸",
            description: "superhero",
        },
        {
            emoji: "🦸‍♂️",
            description: "man superhero",
        },
        {
            emoji: "🦸‍♀️",
            description: "woman superhero",
        },
        {
            emoji: "🦹",
            description: "supervillain",
        },
        {
            emoji: "🦹‍♂️",
            description: "man supervillain",
        },
        {
            emoji: "🦹‍♀️",
            description: "woman supervillain",
        },
        {
            emoji: "🧙",
            description: "mage",
        },
        {
            emoji: "🧙‍♂️",
            description: "man mage",
        },
        {
            emoji: "🧙‍♀️",
            description: "woman mage",
        },
        {
            emoji: "🧚",
            description: "fairy",
        },
        {
            emoji: "🧚‍♂️",
            description: "man fairy",
        },
        {
            emoji: "🧚‍♀️",
            description: "woman fairy",
        },
        {
            emoji: "🧛",
            description: "vampire",
        },
        {
            emoji: "🧛‍♂️",
            description: "man vampire",
        },
        {
            emoji: "🧛‍♀️",
            description: "woman vampire",
        },
        {
            emoji: "🧜",
            description: "merperson",
        },
        {
            emoji: "🧜‍♂️",
            description: "merman",
        },
        {
            emoji: "🧜‍♀️",
            description: "mermaid",
        },
        {
            emoji: "🧝",
            description: "elf",
        },
        {
            emoji: "🧝‍♂️",
            description: "man elf",
        },
        {
            emoji: "🧝‍♀️",
            description: "woman elf",
        },
        {
            emoji: "🧞",
            description: "genie",
        },
        {
            emoji: "🧞‍♂️",
            description: "man genie",
        },
        {
            emoji: "🧞‍♀️",
            description: "woman genie",
        },
        {
            emoji: "🧟",
            description: "zombie",
        },
        {
            emoji: "🧟‍♂️",
            description: "man zombie",
        },
        {
            emoji: "🧟‍♀️",
            description: "woman zombie",
        },
        {
            emoji: "💆",
            description: "person getting massage",
        },
        {
            emoji: "💆‍♂️",
            description: "man getting massage",
        },
        {
            emoji: "💆‍♀️",
            description: "woman getting massage",
        },
        {
            emoji: "💇",
            description: "person getting haircut",
        },
        {
            emoji: "💇‍♂️",
            description: "man getting haircut",
        },
        {
            emoji: "💇‍♀️",
            description: "woman getting haircut",
        },
        {
            emoji: "🚶",
            description: "person walking",
        },
        {
            emoji: "🚶‍♂️",
            description: "man walking",
        },
        {
            emoji: "🚶‍♀️",
            description: "woman walking",
        },
        {
            emoji: "🧍",
            description: "person standing",
        },
        {
            emoji: "🧍‍♂️",
            description: "man standing",
        },
        {
            emoji: "🧍‍♀️",
            description: "woman standing",
        },
        {
            emoji: "🧎",
            description: "person kneeling",
        },
        {
            emoji: "🧎‍♂️",
            description: "man kneeling",
        },
        {
            emoji: "🧎‍♀️",
            description: "woman kneeling",
        },
        {
            emoji: "🧑‍🦯",
            description: "person with white cane",
        },
        {
            emoji: "👨‍🦯",
            description: "man with white cane",
        },
        {
            emoji: "👩‍🦯",
            description: "woman with white cane",
        },
        {
            emoji: "🧑‍🦼",
            description: "person in motorized wheelchair",
        },
        {
            emoji: "👨‍🦼",
            description: "man in motorized wheelchair",
        },
        {
            emoji: "👩‍🦼",
            description: "woman in motorized wheelchair",
        },
        {
            emoji: "🧑‍🦽",
            description: "person in manual wheelchair",
        },
        {
            emoji: "👨‍🦽",
            description: "man in manual wheelchair",
        },
        {
            emoji: "👩‍🦽",
            description: "woman in manual wheelchair",
        },
        {
            emoji: "🏃",
            description: "person running",
        },
        {
            emoji: "🏃‍♂️",
            description: "man running",
        },
        {
            emoji: "🏃‍♀️",
            description: "woman running",
        },
        {
            emoji: "💃",
            description: "woman dancing",
        },
        {
            emoji: "🕺",
            description: "man dancing",
        },
        {
            emoji: "🕴️",
            description: "person in suit levitating",
        },
        {
            emoji: "👯",
            description: "people with bunny ears",
        },
        {
            emoji: "👯‍♂️",
            description: "men with bunny ears",
        },
        {
            emoji: "👯‍♀️",
            description: "women with bunny ears",
        },
        {
            emoji: "🧖",
            description: "person in steamy room",
        },
        {
            emoji: "🧖‍♂️",
            description: "man in steamy room",
        },
        {
            emoji: "🧖‍♀️",
            description: "woman in steamy room",
        },
        {
            emoji: "🧗",
            description: "person climbing",
        },
        {
            emoji: "🧗‍♂️",
            description: "man climbing",
        },
        {
            emoji: "🧗‍♀️",
            description: "woman climbing",
        },
        {
            emoji: "🤺",
            description: "person fencing",
        },
        {
            emoji: "🏇",
            description: "horse racing",
        },
        {
            emoji: "⛷️",
            description: "skier",
        },
        {
            emoji: "🏂",
            description: "snowboarder",
        },
        {
            emoji: "🏌️",
            description: "person golfing",
        },
        {
            emoji: "🏌️‍♂️",
            description: "man golfing",
        },
        {
            emoji: "🏌️‍♀️",
            description: "woman golfing",
        },
        {
            emoji: "🏄",
            description: "person surfing",
        },
        {
            emoji: "🏄‍♂️",
            description: "man surfing",
        },
        {
            emoji: "🏄‍♀️",
            description: "woman surfing",
        },
        {
            emoji: "🚣",
            description: "person rowing boat",
        },
        {
            emoji: "🚣‍♂️",
            description: "man rowing boat",
        },
        {
            emoji: "🚣‍♀️",
            description: "woman rowing boat",
        },
        {
            emoji: "🏊",
            description: "person swimming",
        },
        {
            emoji: "🏊‍♂️",
            description: "man swimming",
        },
        {
            emoji: "🏊‍♀️",
            description: "woman swimming",
        },
        {
            emoji: "⛹️",
            description: "person bouncing ball",
        },
        {
            emoji: "⛹️‍♂️",
            description: "man bouncing ball",
        },
        {
            emoji: "⛹️‍♀️",
            description: "woman bouncing ball",
        },
        {
            emoji: "🏋️",
            description: "person lifting weights",
        },
        {
            emoji: "🏋️‍♂️",
            description: "man lifting weights",
        },
        {
            emoji: "🏋️‍♀️",
            description: "woman lifting weights",
        },
        {
            emoji: "🚴",
            description: "person biking",
        },
        {
            emoji: "🚴‍♂️",
            description: "man biking",
        },
        {
            emoji: "🚴‍♀️",
            description: "woman biking",
        },
        {
            emoji: "🚵",
            description: "person mountain biking",
        },
        {
            emoji: "🚵‍♂️",
            description: "man mountain biking",
        },
        {
            emoji: "🚵‍♀️",
            description: "woman mountain biking",
        },
        {
            emoji: "🤸",
            description: "person cartwheeling",
        },
        {
            emoji: "🤸‍♂️",
            description: "man cartwheeling",
        },
        {
            emoji: "🤸‍♀️",
            description: "woman cartwheeling",
        },
        {
            emoji: "🤼",
            description: "people wrestling",
        },
        {
            emoji: "🤼‍♂️",
            description: "men wrestling",
        },
        {
            emoji: "🤼‍♀️",
            description: "women wrestling",
        },
        {
            emoji: "🤽",
            description: "person playing water polo",
        },
        {
            emoji: "🤽‍♂️",
            description: "man playing water polo",
        },
        {
            emoji: "🤽‍♀️",
            description: "woman playing water polo",
        },
        {
            emoji: "🤾",
            description: "person playing handball",
        },
        {
            emoji: "🤾‍♂️",
            description: "man playing handball",
        },
        {
            emoji: "🤾‍♀️",
            description: "woman playing handball",
        },
        {
            emoji: "🤹",
            description: "person juggling",
        },
        {
            emoji: "🤹‍♂️",
            description: "man juggling",
        },
        {
            emoji: "🤹‍♀️",
            description: "woman juggling",
        },
        {
            emoji: "🧘",
            description: "person in lotus position",
        },
        {
            emoji: "🧘‍♂️",
            description: "man in lotus position",
        },
        {
            emoji: "🧘‍♀️",
            description: "woman in lotus position",
        },
        {
            emoji: "🛀",
            description: "person taking bath",
        },
        {
            emoji: "🛌",
            description: "person in bed",
        },
        {
            emoji: "🧑‍🤝‍🧑",
            description: "people holding hands",
        },
        {
            emoji: "👭",
            description: "women holding hands",
        },
        {
            emoji: "👫",
            description: "woman and man holding hands",
        },
        {
            emoji: "👬",
            description: "men holding hands",
        },
        {
            emoji: "💏",
            description: "kiss",
        },
        {
            emoji: "👩‍❤️‍💋‍👨",
            description: "kiss: woman, man",
        },
        {
            emoji: "👨‍❤️‍💋‍👨",
            description: "kiss: man, man",
        },
        {
            emoji: "👩‍❤️‍💋‍👩",
            description: "kiss: woman, woman",
        },
        {
            emoji: "💑",
            description: "couple with heart",
        },
        {
            emoji: "👩‍❤️‍👨",
            description: "couple with heart: woman, man",
        },
        {
            emoji: "👨‍❤️‍👨",
            description: "couple with heart: man, man",
        },
        {
            emoji: "👩‍❤️‍👩",
            description: "couple with heart: woman, woman",
        },
        {
            emoji: "👪",
            description: "family",
        },
        {
            emoji: "👨‍👩‍👦",
            description: "family: man, woman, boy",
        },
        {
            emoji: "👨‍👩‍👧",
            description: "family: man, woman, girl",
        },
        {
            emoji: "👨‍👩‍👧‍👦",
            description: "family: man, woman, girl, boy",
        },
        {
            emoji: "👨‍👩‍👦‍👦",
            description: "family: man, woman, boy, boy",
        },
        {
            emoji: "👨‍👩‍👧‍👧",
            description: "family: man, woman, girl, girl",
        },
        {
            emoji: "👨‍👨‍👦",
            description: "family: man, man, boy",
        },
        {
            emoji: "👨‍👨‍👧",
            description: "family: man, man, girl",
        },
        {
            emoji: "👨‍👨‍👧‍👦",
            description: "family: man, man, girl, boy",
        },
        {
            emoji: "👨‍👨‍👦‍👦",
            description: "family: man, man, boy, boy",
        },
        {
            emoji: "👨‍👨‍👧‍👧",
            description: "family: man, man, girl, girl",
        },
        {
            emoji: "👩‍👩‍👦",
            description: "family: woman, woman, boy",
        },
        {
            emoji: "👩‍👩‍👧",
            description: "family: woman, woman, girl",
        },
        {
            emoji: "👩‍👩‍👧‍👦",
            description: "family: woman, woman, girl, boy",
        },
        {
            emoji: "👩‍👩‍👦‍👦",
            description: "family: woman, woman, boy, boy",
        },
        {
            emoji: "👩‍👩‍👧‍👧",
            description: "family: woman, woman, girl, girl",
        },
        {
            emoji: "👨‍👦",
            description: "family: man, boy",
        },
        {
            emoji: "👨‍👦‍👦",
            description: "family: man, boy, boy",
        },
        {
            emoji: "👨‍👧",
            description: "family: man, girl",
        },
        {
            emoji: "👨‍👧‍👦",
            description: "family: man, girl, boy",
        },
        {
            emoji: "👨‍👧‍👧",
            description: "family: man, girl, girl",
        },
        {
            emoji: "👩‍👦",
            description: "family: woman, boy",
        },
        {
            emoji: "👩‍👦‍👦",
            description: "family: woman, boy, boy",
        },
        {
            emoji: "👩‍👧",
            description: "family: woman, girl",
        },
        {
            emoji: "👩‍👧‍👦",
            description: "family: woman, girl, boy",
        },
        {
            emoji: "👩‍👧‍👧",
            description: "family: woman, girl, girl",
        },
        {
            emoji: "🗣️",
            description: "speaking head",
        },
        {
            emoji: "👤",
            description: "bust in silhouette",
        },
        {
            emoji: "👥",
            description: "busts in silhouette",
        },
        {
            emoji: "🫂",
            description: "people hugging",
        },
        {
            emoji: "👣",
            description: "footprints",
        },
    ],
    animalsNature: [
        {
            emoji: "🐵",
            description: "monkey face",
        },
        {
            emoji: "🐒",
            description: "monkey",
        },
        {
            emoji: "🦍",
            description: "gorilla",
        },
        {
            emoji: "🦧",
            description: "orangutan",
        },
        {
            emoji: "🐶",
            description: "dog face",
        },
        {
            emoji: "🐕",
            description: "dog",
        },
        {
            emoji: "🦮",
            description: "guide dog",
        },
        {
            emoji: "🐕‍🦺",
            description: "service dog",
        },
        {
            emoji: "🐩",
            description: "poodle",
        },
        {
            emoji: "🐺",
            description: "wolf",
        },
        {
            emoji: "🦊",
            description: "fox",
        },
        {
            emoji: "🦝",
            description: "raccoon",
        },
        {
            emoji: "🐱",
            description: "cat face",
        },
        {
            emoji: "🐈",
            description: "cat",
        },
        {
            emoji: "🐈‍⬛",
            description: "black cat",
        },
        {
            emoji: "🦁",
            description: "lion",
        },
        {
            emoji: "🐯",
            description: "tiger face",
        },
        {
            emoji: "🐅",
            description: "tiger",
        },
        {
            emoji: "🐆",
            description: "leopard",
        },
        {
            emoji: "🐴",
            description: "horse face",
        },
        {
            emoji: "🐎",
            description: "horse",
        },
        {
            emoji: "🦄",
            description: "unicorn",
        },
        {
            emoji: "🦓",
            description: "zebra",
        },
        {
            emoji: "🦌",
            description: "deer",
        },
        {
            emoji: "🦬",
            description: "bison",
        },
        {
            emoji: "🐮",
            description: "cow face",
        },
        {
            emoji: "🐂",
            description: "ox",
        },
        {
            emoji: "🐃",
            description: "water buffalo",
        },
        {
            emoji: "🐄",
            description: "cow",
        },
        {
            emoji: "🐷",
            description: "pig face",
        },
        {
            emoji: "🐖",
            description: "pig",
        },
        {
            emoji: "🐗",
            description: "boar",
        },
        {
            emoji: "🐽",
            description: "pig nose",
        },
        {
            emoji: "🐏",
            description: "ram",
        },
        {
            emoji: "🐑",
            description: "ewe",
        },
        {
            emoji: "🐐",
            description: "goat",
        },
        {
            emoji: "🐪",
            description: "camel",
        },
        {
            emoji: "🐫",
            description: "two-hump camel",
        },
        {
            emoji: "🦙",
            description: "llama",
        },
        {
            emoji: "🦒",
            description: "giraffe",
        },
        {
            emoji: "🐘",
            description: "elephant",
        },
        {
            emoji: "🦣",
            description: "mammoth",
        },
        {
            emoji: "🦏",
            description: "rhinoceros",
        },
        {
            emoji: "🦛",
            description: "hippopotamus",
        },
        {
            emoji: "🐭",
            description: "mouse face",
        },
        {
            emoji: "🐁",
            description: "mouse",
        },
        {
            emoji: "🐀",
            description: "rat",
        },
        {
            emoji: "🐹",
            description: "hamster",
        },
        {
            emoji: "🐰",
            description: "rabbit face",
        },
        {
            emoji: "🐇",
            description: "rabbit",
        },
        {
            emoji: "🐿️",
            description: "chipmunk",
        },
        {
            emoji: "🦫",
            description: "beaver",
        },
        {
            emoji: "🦔",
            description: "hedgehog",
        },
        {
            emoji: "🦇",
            description: "bat",
        },
        {
            emoji: "🐻",
            description: "bear",
        },
        {
            emoji: "🐻‍❄️",
            description: "polar bear",
        },
        {
            emoji: "🐨",
            description: "koala",
        },
        {
            emoji: "🐼",
            description: "panda",
        },
        {
            emoji: "🦥",
            description: "sloth",
        },
        {
            emoji: "🦦",
            description: "otter",
        },
        {
            emoji: "🦨",
            description: "skunk",
        },
        {
            emoji: "🦘",
            description: "kangaroo",
        },
        {
            emoji: "🦡",
            description: "badger",
        },
        {
            emoji: "🐾",
            description: "paw prints",
        },
        {
            emoji: "🦃",
            description: "turkey",
        },
        {
            emoji: "🐔",
            description: "chicken",
        },
        {
            emoji: "🐓",
            description: "rooster",
        },
        {
            emoji: "🐣",
            description: "hatching chick",
        },
        {
            emoji: "🐤",
            description: "baby chick",
        },
        {
            emoji: "🐥",
            description: "front-facing baby chick",
        },
        {
            emoji: "🐦",
            description: "bird",
        },
        {
            emoji: "🐧",
            description: "penguin",
        },
        {
            emoji: "🕊️",
            description: "dove",
        },
        {
            emoji: "🦅",
            description: "eagle",
        },
        {
            emoji: "🦆",
            description: "duck",
        },
        {
            emoji: "🦢",
            description: "swan",
        },
        {
            emoji: "🦉",
            description: "owl",
        },
        {
            emoji: "🦤",
            description: "dodo",
        },
        {
            emoji: "🪶",
            description: "feather",
        },
        {
            emoji: "🦩",
            description: "flamingo",
        },
        {
            emoji: "🦚",
            description: "peacock",
        },
        {
            emoji: "🦜",
            description: "parrot",
        },
        {
            emoji: "🐸",
            description: "frog",
        },
        {
            emoji: "🐊",
            description: "crocodile",
        },
        {
            emoji: "🐢",
            description: "turtle",
        },
        {
            emoji: "🦎",
            description: "lizard",
        },
        {
            emoji: "🐍",
            description: "snake",
        },
        {
            emoji: "🐲",
            description: "dragon face",
        },
        {
            emoji: "🐉",
            description: "dragon",
        },
        {
            emoji: "🦕",
            description: "sauropod",
        },
        {
            emoji: "🦖",
            description: "T-Rex",
        },
        {
            emoji: "🐳",
            description: "spouting whale",
        },
        {
            emoji: "🐋",
            description: "whale",
        },
        {
            emoji: "🐬",
            description: "dolphin",
        },
        {
            emoji: "🦭",
            description: "seal",
        },
        {
            emoji: "🐟",
            description: "fish",
        },
        {
            emoji: "🐠",
            description: "tropical fish",
        },
        {
            emoji: "🐡",
            description: "blowfish",
        },
        {
            emoji: "🦈",
            description: "shark",
        },
        {
            emoji: "🐙",
            description: "octopus",
        },
        {
            emoji: "🐚",
            description: "spiral shell",
        },
        {
            emoji: "🐌",
            description: "snail",
        },
        {
            emoji: "🦋",
            description: "butterfly",
        },
        {
            emoji: "🐛",
            description: "bug",
        },
        {
            emoji: "🐜",
            description: "ant",
        },
        {
            emoji: "🐝",
            description: "honeybee",
        },
        {
            emoji: "🪲",
            description: "beetle",
        },
        {
            emoji: "🐞",
            description: "lady beetle",
        },
        {
            emoji: "🦗",
            description: "cricket",
        },
        {
            emoji: "🪳",
            description: "cockroach",
        },
        {
            emoji: "🕷️",
            description: "spider",
        },
        {
            emoji: "🕸️",
            description: "spider web",
        },
        {
            emoji: "🦂",
            description: "scorpion",
        },
        {
            emoji: "🦟",
            description: "mosquito",
        },
        {
            emoji: "🪰",
            description: "fly",
        },
        {
            emoji: "🪱",
            description: "worm",
        },
        {
            emoji: "🦠",
            description: "microbe",
        },
        {
            emoji: "💐",
            description: "bouquet",
        },
        {
            emoji: "🌸",
            description: "cherry blossom",
        },
        {
            emoji: "💮",
            description: "white flower",
        },
        {
            emoji: "🏵️",
            description: "rosette",
        },
        {
            emoji: "🌹",
            description: "rose",
        },
        {
            emoji: "🥀",
            description: "wilted flower",
        },
        {
            emoji: "🌺",
            description: "hibiscus",
        },
        {
            emoji: "🌻",
            description: "sunflower",
        },
        {
            emoji: "🌼",
            description: "blossom",
        },
        {
            emoji: "🌷",
            description: "tulip",
        },
        {
            emoji: "🌱",
            description: "seedling",
        },
        {
            emoji: "🪴",
            description: "potted plant",
        },
        {
            emoji: "🌲",
            description: "evergreen tree",
        },
        {
            emoji: "🌳",
            description: "deciduous tree",
        },
        {
            emoji: "🌴",
            description: "palm tree",
        },
        {
            emoji: "🌵",
            description: "cactus",
        },
        {
            emoji: "🌾",
            description: "sheaf of rice",
        },
        {
            emoji: "🌿",
            description: "herb",
        },
        {
            emoji: "☘️",
            description: "shamrock",
        },
        {
            emoji: "🍀",
            description: "four leaf clover",
        },
        {
            emoji: "🍁",
            description: "maple leaf",
        },
        {
            emoji: "🍂",
            description: "fallen leaf",
        },
        {
            emoji: "🍃",
            description: "leaf fluttering in wind",
        },
    ],
    foodDrink: [
        {
            emoji: "🍇",
            description: "grapes",
        },
        {
            emoji: "🍈",
            description: "melon",
        },
        {
            emoji: "🍉",
            description: "watermelon",
        },
        {
            emoji: "🍊",
            description: "tangerine",
        },
        {
            emoji: "🍋",
            description: "lemon",
        },
        {
            emoji: "🍌",
            description: "banana",
        },
        {
            emoji: "🍍",
            description: "pineapple",
        },
        {
            emoji: "🥭",
            description: "mango",
        },
        {
            emoji: "🍎",
            description: "red apple",
        },
        {
            emoji: "🍏",
            description: "green apple",
        },
        {
            emoji: "🍐",
            description: "pear",
        },
        {
            emoji: "🍑",
            description: "peach",
        },
        {
            emoji: "🍒",
            description: "cherries",
        },
        {
            emoji: "🍓",
            description: "strawberry",
        },
        {
            emoji: "🫐",
            description: "blueberries",
        },
        {
            emoji: "🥝",
            description: "kiwi fruit",
        },
        {
            emoji: "🍅",
            description: "tomato",
        },
        {
            emoji: "🫒",
            description: "olive",
        },
        {
            emoji: "🥥",
            description: "coconut",
        },
        {
            emoji: "🥑",
            description: "avocado",
        },
        {
            emoji: "🍆",
            description: "eggplant",
        },
        {
            emoji: "🥔",
            description: "potato",
        },
        {
            emoji: "🥕",
            description: "carrot",
        },
        {
            emoji: "🌽",
            description: "ear of corn",
        },
        {
            emoji: "🌶️",
            description: "hot pepper",
        },
        {
            emoji: "🫑",
            description: "bell pepper",
        },
        {
            emoji: "🥒",
            description: "cucumber",
        },
        {
            emoji: "🥬",
            description: "leafy green",
        },
        {
            emoji: "🥦",
            description: "broccoli",
        },
        {
            emoji: "🧄",
            description: "garlic",
        },
        {
            emoji: "🧅",
            description: "onion",
        },
        {
            emoji: "🍄",
            description: "mushroom",
        },
        {
            emoji: "🥜",
            description: "peanuts",
        },
        {
            emoji: "🌰",
            description: "chestnut",
        },
        {
            emoji: "🍞",
            description: "bread",
        },
        {
            emoji: "🥐",
            description: "croissant",
        },
        {
            emoji: "🥖",
            description: "baguette bread",
        },
        {
            emoji: "🫓",
            description: "flatbread",
        },
        {
            emoji: "🥨",
            description: "pretzel",
        },
        {
            emoji: "🥯",
            description: "bagel",
        },
        {
            emoji: "🥞",
            description: "pancakes",
        },
        {
            emoji: "🧇",
            description: "waffle",
        },
        {
            emoji: "🧀",
            description: "cheese wedge",
        },
        {
            emoji: "🍖",
            description: "meat on bone",
        },
        {
            emoji: "🍗",
            description: "poultry leg",
        },
        {
            emoji: "🥩",
            description: "cut of meat",
        },
        {
            emoji: "🥓",
            description: "bacon",
        },
        {
            emoji: "🍔",
            description: "hamburger",
        },
        {
            emoji: "🍟",
            description: "french fries",
        },
        {
            emoji: "🍕",
            description: "pizza",
        },
        {
            emoji: "🌭",
            description: "hot dog",
        },
        {
            emoji: "🥪",
            description: "sandwich",
        },
        {
            emoji: "🌮",
            description: "taco",
        },
        {
            emoji: "🌯",
            description: "burrito",
        },
        {
            emoji: "🫔",
            description: "tamale",
        },
        {
            emoji: "🥙",
            description: "stuffed flatbread",
        },
        {
            emoji: "🧆",
            description: "falafel",
        },
        {
            emoji: "🥚",
            description: "egg",
        },
        {
            emoji: "🍳",
            description: "cooking",
        },
        {
            emoji: "🥘",
            description: "shallow pan of food",
        },
        {
            emoji: "🍲",
            description: "pot of food",
        },
        {
            emoji: "🫕",
            description: "fondue",
        },
        {
            emoji: "🥣",
            description: "bowl with spoon",
        },
        {
            emoji: "🥗",
            description: "green salad",
        },
        {
            emoji: "🍿",
            description: "popcorn",
        },
        {
            emoji: "🧈",
            description: "butter",
        },
        {
            emoji: "🧂",
            description: "salt",
        },
        {
            emoji: "🥫",
            description: "canned food",
        },
        {
            emoji: "🍱",
            description: "bento box",
        },
        {
            emoji: "🍘",
            description: "rice cracker",
        },
        {
            emoji: "🍙",
            description: "rice ball",
        },
        {
            emoji: "🍚",
            description: "cooked rice",
        },
        {
            emoji: "🍛",
            description: "curry rice",
        },
        {
            emoji: "🍜",
            description: "steaming bowl",
        },
        {
            emoji: "🍝",
            description: "spaghetti",
        },
        {
            emoji: "🍠",
            description: "roasted sweet potato",
        },
        {
            emoji: "🍢",
            description: "oden",
        },
        {
            emoji: "🍣",
            description: "sushi",
        },
        {
            emoji: "🍤",
            description: "fried shrimp",
        },
        {
            emoji: "🍥",
            description: "fish cake with swirl",
        },
        {
            emoji: "🥮",
            description: "moon cake",
        },
        {
            emoji: "🍡",
            description: "dango",
        },
        {
            emoji: "🥟",
            description: "dumpling",
        },
        {
            emoji: "🥠",
            description: "fortune cookie",
        },
        {
            emoji: "🥡",
            description: "takeout box",
        },
        {
            emoji: "🦀",
            description: "crab",
        },
        {
            emoji: "🦞",
            description: "lobster",
        },
        {
            emoji: "🦐",
            description: "shrimp",
        },
        {
            emoji: "🦑",
            description: "squid",
        },
        {
            emoji: "🦪",
            description: "oyster",
        },
        {
            emoji: "🍦",
            description: "soft ice cream",
        },
        {
            emoji: "🍧",
            description: "shaved ice",
        },
        {
            emoji: "🍨",
            description: "ice cream",
        },
        {
            emoji: "🍩",
            description: "doughnut",
        },
        {
            emoji: "🍪",
            description: "cookie",
        },
        {
            emoji: "🎂",
            description: "birthday cake",
        },
        {
            emoji: "🍰",
            description: "shortcake",
        },
        {
            emoji: "🧁",
            description: "cupcake",
        },
        {
            emoji: "🥧",
            description: "pie",
        },
        {
            emoji: "🍫",
            description: "chocolate bar",
        },
        {
            emoji: "🍬",
            description: "candy",
        },
        {
            emoji: "🍭",
            description: "lollipop",
        },
        {
            emoji: "🍮",
            description: "custard",
        },
        {
            emoji: "🍯",
            description: "honey pot",
        },
        {
            emoji: "🍼",
            description: "baby bottle",
        },
        {
            emoji: "🥛",
            description: "glass of milk",
        },
        {
            emoji: "☕",
            description: "hot beverage",
        },
        {
            emoji: "🫖",
            description: "teapot",
        },
        {
            emoji: "🍵",
            description: "teacup without handle",
        },
        {
            emoji: "🍶",
            description: "sake",
        },
        {
            emoji: "🍾",
            description: "bottle with popping cork",
        },
        {
            emoji: "🍷",
            description: "wine glass",
        },
        {
            emoji: "🍸",
            description: "cocktail glass",
        },
        {
            emoji: "🍹",
            description: "tropical drink",
        },
        {
            emoji: "🍺",
            description: "beer mug",
        },
        {
            emoji: "🍻",
            description: "clinking beer mugs",
        },
        {
            emoji: "🥂",
            description: "clinking glasses",
        },
        {
            emoji: "🥃",
            description: "tumbler glass",
        },
        {
            emoji: "🥤",
            description: "cup with straw",
        },
        {
            emoji: "🧋",
            description: "bubble tea",
        },
        {
            emoji: "🧃",
            description: "beverage box",
        },
        {
            emoji: "🧉",
            description: "mate",
        },
        {
            emoji: "🧊",
            description: "ice",
        },
        {
            emoji: "🥢",
            description: "chopsticks",
        },
        {
            emoji: "🍽️",
            description: "fork and knife with plate",
        },
        {
            emoji: "🍴",
            description: "fork and knife",
        },
        {
            emoji: "🥄",
            description: "spoon",
        },
        {
            emoji: "🔪",
            description: "kitchen knife",
        },
        {
            emoji: "🏺",
            description: "amphora",
        },
    ],
    travelPlaces: [
        {
            emoji: "🌍",
            description: "globe showing Europe-Africa",
        },
        {
            emoji: "🌎",
            description: "globe showing Americas",
        },
        {
            emoji: "🌏",
            description: "globe showing Asia-Australia",
        },
        {
            emoji: "🌐",
            description: "globe with meridians",
        },
        {
            emoji: "🗺️",
            description: "world map",
        },
        {
            emoji: "🗾",
            description: "map of Japan",
        },
        {
            emoji: "🧭",
            description: "compass",
        },
        {
            emoji: "🏔️",
            description: "snow-capped mountain",
        },
        {
            emoji: "⛰️",
            description: "mountain",
        },
        {
            emoji: "🌋",
            description: "volcano",
        },
        {
            emoji: "🗻",
            description: "mount fuji",
        },
        {
            emoji: "🏕️",
            description: "camping",
        },
        {
            emoji: "🏖️",
            description: "beach with umbrella",
        },
        {
            emoji: "🏜️",
            description: "desert",
        },
        {
            emoji: "🏝️",
            description: "desert island",
        },
        {
            emoji: "🏞️",
            description: "national park",
        },
        {
            emoji: "🏟️",
            description: "stadium",
        },
        {
            emoji: "🏛️",
            description: "classical building",
        },
        {
            emoji: "🏗️",
            description: "building construction",
        },
        {
            emoji: "🧱",
            description: "brick",
        },
        {
            emoji: "🪨",
            description: "rock",
        },
        {
            emoji: "🪵",
            description: "wood",
        },
        {
            emoji: "🛖",
            description: "hut",
        },
        {
            emoji: "🏘️",
            description: "houses",
        },
        {
            emoji: "🏚️",
            description: "derelict house",
        },
        {
            emoji: "🏠",
            description: "house",
        },
        {
            emoji: "🏡",
            description: "house with garden",
        },
        {
            emoji: "🏢",
            description: "office building",
        },
        {
            emoji: "🏣",
            description: "Japanese post office",
        },
        {
            emoji: "🏤",
            description: "post office",
        },
        {
            emoji: "🏥",
            description: "hospital",
        },
        {
            emoji: "🏦",
            description: "bank",
        },
        {
            emoji: "🏨",
            description: "hotel",
        },
        {
            emoji: "🏩",
            description: "love hotel",
        },
        {
            emoji: "🏪",
            description: "convenience store",
        },
        {
            emoji: "🏫",
            description: "school",
        },
        {
            emoji: "🏬",
            description: "department store",
        },
        {
            emoji: "🏭",
            description: "factory",
        },
        {
            emoji: "🏯",
            description: "Japanese castle",
        },
        {
            emoji: "🏰",
            description: "castle",
        },
        {
            emoji: "💒",
            description: "wedding",
        },
        {
            emoji: "🗼",
            description: "Tokyo tower",
        },
        {
            emoji: "🗽",
            description: "Statue of Liberty",
        },
        {
            emoji: "⛪",
            description: "church",
        },
        {
            emoji: "🕌",
            description: "mosque",
        },
        {
            emoji: "🛕",
            description: "hindu temple",
        },
        {
            emoji: "🕍",
            description: "synagogue",
        },
        {
            emoji: "⛩️",
            description: "shinto shrine",
        },
        {
            emoji: "🕋",
            description: "kaaba",
        },
        {
            emoji: "⛲",
            description: "fountain",
        },
        {
            emoji: "⛺",
            description: "tent",
        },
        {
            emoji: "🌁",
            description: "foggy",
        },
        {
            emoji: "🌃",
            description: "night with stars",
        },
        {
            emoji: "🏙️",
            description: "cityscape",
        },
        {
            emoji: "🌄",
            description: "sunrise over mountains",
        },
        {
            emoji: "🌅",
            description: "sunrise",
        },
        {
            emoji: "🌆",
            description: "cityscape at dusk",
        },
        {
            emoji: "🌇",
            description: "sunset",
        },
        {
            emoji: "🌉",
            description: "bridge at night",
        },
        {
            emoji: "♨️",
            description: "hot springs",
        },
        {
            emoji: "🎠",
            description: "carousel horse",
        },
        {
            emoji: "🎡",
            description: "ferris wheel",
        },
        {
            emoji: "🎢",
            description: "roller coaster",
        },
        {
            emoji: "💈",
            description: "barber pole",
        },
        {
            emoji: "🎪",
            description: "circus tent",
        },
        {
            emoji: "🚂",
            description: "locomotive",
        },
        {
            emoji: "🚃",
            description: "railway car",
        },
        {
            emoji: "🚄",
            description: "high-speed train",
        },
        {
            emoji: "🚅",
            description: "bullet train",
        },
        {
            emoji: "🚆",
            description: "train",
        },
        {
            emoji: "🚇",
            description: "metro",
        },
        {
            emoji: "🚈",
            description: "light rail",
        },
        {
            emoji: "🚉",
            description: "station",
        },
        {
            emoji: "🚊",
            description: "tram",
        },
        {
            emoji: "🚝",
            description: "monorail",
        },
        {
            emoji: "🚞",
            description: "mountain railway",
        },
        {
            emoji: "🚋",
            description: "tram car",
        },
        {
            emoji: "🚌",
            description: "bus",
        },
        {
            emoji: "🚍",
            description: "oncoming bus",
        },
        {
            emoji: "🚎",
            description: "trolleybus",
        },
        {
            emoji: "🚐",
            description: "minibus",
        },
        {
            emoji: "🚑",
            description: "ambulance",
        },
        {
            emoji: "🚒",
            description: "fire engine",
        },
        {
            emoji: "🚓",
            description: "police car",
        },
        {
            emoji: "🚔",
            description: "oncoming police car",
        },
        {
            emoji: "🚕",
            description: "taxi",
        },
        {
            emoji: "🚖",
            description: "oncoming taxi",
        },
        {
            emoji: "🚗",
            description: "automobile",
        },
        {
            emoji: "🚘",
            description: "oncoming automobile",
        },
        {
            emoji: "🚙",
            description: "sport utility vehicle",
        },
        {
            emoji: "🛻",
            description: "pickup truck",
        },
        {
            emoji: "🚚",
            description: "delivery truck",
        },
        {
            emoji: "🚛",
            description: "articulated lorry",
        },
        {
            emoji: "🚜",
            description: "tractor",
        },
        {
            emoji: "🏎️",
            description: "racing car",
        },
        {
            emoji: "🏍️",
            description: "motorcycle",
        },
        {
            emoji: "🛵",
            description: "motor scooter",
        },
        {
            emoji: "🦽",
            description: "manual wheelchair",
        },
        {
            emoji: "🦼",
            description: "motorized wheelchair",
        },
        {
            emoji: "🛺",
            description: "auto rickshaw",
        },
        {
            emoji: "🚲",
            description: "bicycle",
        },
        {
            emoji: "🛴",
            description: "kick scooter",
        },
        {
            emoji: "🛹",
            description: "skateboard",
        },
        {
            emoji: "🛼",
            description: "roller skate",
        },
        {
            emoji: "🚏",
            description: "bus stop",
        },
        {
            emoji: "🛣️",
            description: "motorway",
        },
        {
            emoji: "🛤️",
            description: "railway track",
        },
        {
            emoji: "🛢️",
            description: "oil drum",
        },
        {
            emoji: "⛽",
            description: "fuel pump",
        },
        {
            emoji: "🚨",
            description: "police car light",
        },
        {
            emoji: "🚥",
            description: "horizontal traffic light",
        },
        {
            emoji: "🚦",
            description: "vertical traffic light",
        },
        {
            emoji: "🛑",
            description: "stop sign",
        },
        {
            emoji: "🚧",
            description: "construction",
        },
        {
            emoji: "⚓",
            description: "anchor",
        },
        {
            emoji: "⛵",
            description: "sailboat",
        },
        {
            emoji: "🛶",
            description: "canoe",
        },
        {
            emoji: "🚤",
            description: "speedboat",
        },
        {
            emoji: "🛳️",
            description: "passenger ship",
        },
        {
            emoji: "⛴️",
            description: "ferry",
        },
        {
            emoji: "🛥️",
            description: "motor boat",
        },
        {
            emoji: "🚢",
            description: "ship",
        },
        {
            emoji: "✈️",
            description: "airplane",
        },
        {
            emoji: "🛩️",
            description: "small airplane",
        },
        {
            emoji: "🛫",
            description: "airplane departure",
        },
        {
            emoji: "🛬",
            description: "airplane arrival",
        },
        {
            emoji: "🪂",
            description: "parachute",
        },
        {
            emoji: "💺",
            description: "seat",
        },
        {
            emoji: "🚁",
            description: "helicopter",
        },
        {
            emoji: "🚟",
            description: "suspension railway",
        },
        {
            emoji: "🚠",
            description: "mountain cableway",
        },
        {
            emoji: "🚡",
            description: "aerial tramway",
        },
        {
            emoji: "🛰️",
            description: "satellite",
        },
        {
            emoji: "🚀",
            description: "rocket",
        },
        {
            emoji: "🛸",
            description: "flying saucer",
        },
        {
            emoji: "🛎️",
            description: "bellhop bell",
        },
        {
            emoji: "🧳",
            description: "luggage",
        },
        {
            emoji: "⌛",
            description: "hourglass done",
        },
        {
            emoji: "⏳",
            description: "hourglass not done",
        },
        {
            emoji: "⌚",
            description: "watch",
        },
        {
            emoji: "⏰",
            description: "alarm clock",
        },
        {
            emoji: "⏱️",
            description: "stopwatch",
        },
        {
            emoji: "⏲️",
            description: "timer clock",
        },
        {
            emoji: "🕰️",
            description: "mantelpiece clock",
        },
        {
            emoji: "🕛",
            description: "twelve o’clock",
        },
        {
            emoji: "🕧",
            description: "twelve-thirty",
        },
        {
            emoji: "🕐",
            description: "one o’clock",
        },
        {
            emoji: "🕜",
            description: "one-thirty",
        },
        {
            emoji: "🕑",
            description: "two o’clock",
        },
        {
            emoji: "🕝",
            description: "two-thirty",
        },
        {
            emoji: "🕒",
            description: "three o’clock",
        },
        {
            emoji: "🕞",
            description: "three-thirty",
        },
        {
            emoji: "🕓",
            description: "four o’clock",
        },
        {
            emoji: "🕟",
            description: "four-thirty",
        },
        {
            emoji: "🕔",
            description: "five o’clock",
        },
        {
            emoji: "🕠",
            description: "five-thirty",
        },
        {
            emoji: "🕕",
            description: "six o’clock",
        },
        {
            emoji: "🕡",
            description: "six-thirty",
        },
        {
            emoji: "🕖",
            description: "seven o’clock",
        },
        {
            emoji: "🕢",
            description: "seven-thirty",
        },
        {
            emoji: "🕗",
            description: "eight o’clock",
        },
        {
            emoji: "🕣",
            description: "eight-thirty",
        },
        {
            emoji: "🕘",
            description: "nine o’clock",
        },
        {
            emoji: "🕤",
            description: "nine-thirty",
        },
        {
            emoji: "🕙",
            description: "ten o’clock",
        },
        {
            emoji: "🕥",
            description: "ten-thirty",
        },
        {
            emoji: "🕚",
            description: "eleven o’clock",
        },
        {
            emoji: "🕦",
            description: "eleven-thirty",
        },
        {
            emoji: "🌑",
            description: "new moon",
        },
        {
            emoji: "🌒",
            description: "waxing crescent moon",
        },
        {
            emoji: "🌓",
            description: "first quarter moon",
        },
        {
            emoji: "🌔",
            description: "waxing gibbous moon",
        },
        {
            emoji: "🌕",
            description: "full moon",
        },
        {
            emoji: "🌖",
            description: "waning gibbous moon",
        },
        {
            emoji: "🌗",
            description: "last quarter moon",
        },
        {
            emoji: "🌘",
            description: "waning crescent moon",
        },
        {
            emoji: "🌙",
            description: "crescent moon",
        },
        {
            emoji: "🌚",
            description: "new moon face",
        },
        {
            emoji: "🌛",
            description: "first quarter moon face",
        },
        {
            emoji: "🌜",
            description: "last quarter moon face",
        },
        {
            emoji: "🌡️",
            description: "thermometer",
        },
        {
            emoji: "☀️",
            description: "sun",
        },
        {
            emoji: "🌝",
            description: "full moon face",
        },
        {
            emoji: "🌞",
            description: "sun with face",
        },
        {
            emoji: "🪐",
            description: "ringed planet",
        },
        {
            emoji: "⭐",
            description: "star",
        },
        {
            emoji: "🌟",
            description: "glowing star",
        },
        {
            emoji: "🌠",
            description: "shooting star",
        },
        {
            emoji: "🌌",
            description: "milky way",
        },
        {
            emoji: "☁️",
            description: "cloud",
        },
        {
            emoji: "⛅",
            description: "sun behind cloud",
        },
        {
            emoji: "⛈️",
            description: "cloud with lightning and rain",
        },
        {
            emoji: "🌤️",
            description: "sun behind small cloud",
        },
        {
            emoji: "🌥️",
            description: "sun behind large cloud",
        },
        {
            emoji: "🌦️",
            description: "sun behind rain cloud",
        },
        {
            emoji: "🌧️",
            description: "cloud with rain",
        },
        {
            emoji: "🌨️",
            description: "cloud with snow",
        },
        {
            emoji: "🌩️",
            description: "cloud with lightning",
        },
        {
            emoji: "🌪️",
            description: "tornado",
        },
        {
            emoji: "🌫️",
            description: "fog",
        },
        {
            emoji: "🌬️",
            description: "wind face",
        },
        {
            emoji: "🌀",
            description: "cyclone",
        },
        {
            emoji: "🌈",
            description: "rainbow",
        },
        {
            emoji: "🌂",
            description: "closed umbrella",
        },
        {
            emoji: "☂️",
            description: "umbrella",
        },
        {
            emoji: "☔",
            description: "umbrella with rain drops",
        },
        {
            emoji: "⛱️",
            description: "umbrella on ground",
        },
        {
            emoji: "⚡",
            description: "high voltage",
        },
        {
            emoji: "❄️",
            description: "snowflake",
        },
        {
            emoji: "☃️",
            description: "snowman",
        },
        {
            emoji: "⛄",
            description: "snowman without snow",
        },
        {
            emoji: "☄️",
            description: "comet",
        },
        {
            emoji: "🔥",
            description: "fire",
        },
        {
            emoji: "💧",
            description: "droplet",
        },
        {
            emoji: "🌊",
            description: "water wave",
        },
    ],
    activities: [
        {
            emoji: "🎃",
            description: "jack-o-lantern",
        },
        {
            emoji: "🎄",
            description: "Christmas tree",
        },
        {
            emoji: "🎆",
            description: "fireworks",
        },
        {
            emoji: "🎇",
            description: "sparkler",
        },
        {
            emoji: "🧨",
            description: "firecracker",
        },
        {
            emoji: "✨",
            description: "sparkles",
        },
        {
            emoji: "🎈",
            description: "balloon",
        },
        {
            emoji: "🎉",
            description: "party popper",
        },
        {
            emoji: "🎊",
            description: "confetti ball",
        },
        {
            emoji: "🎋",
            description: "tanabata tree",
        },
        {
            emoji: "🎍",
            description: "pine decoration",
        },
        {
            emoji: "🎎",
            description: "Japanese dolls",
        },
        {
            emoji: "🎏",
            description: "carp streamer",
        },
        {
            emoji: "🎐",
            description: "wind chime",
        },
        {
            emoji: "🎑",
            description: "moon viewing ceremony",
        },
        {
            emoji: "🧧",
            description: "red envelope",
        },
        {
            emoji: "🎀",
            description: "ribbon",
        },
        {
            emoji: "🎁",
            description: "wrapped gift",
        },
        {
            emoji: "🎗️",
            description: "reminder ribbon",
        },
        {
            emoji: "🎟️",
            description: "admission tickets",
        },
        {
            emoji: "🎫",
            description: "ticket",
        },
        {
            emoji: "🎖️",
            description: "military medal",
        },
        {
            emoji: "🏆",
            description: "trophy",
        },
        {
            emoji: "🏅",
            description: "sports medal",
        },
        {
            emoji: "🥇",
            description: "1st place medal",
        },
        {
            emoji: "🥈",
            description: "2nd place medal",
        },
        {
            emoji: "🥉",
            description: "3rd place medal",
        },
        {
            emoji: "⚽",
            description: "soccer ball",
        },
        {
            emoji: "⚾",
            description: "baseball",
        },
        {
            emoji: "🥎",
            description: "softball",
        },
        {
            emoji: "🏀",
            description: "basketball",
        },
        {
            emoji: "🏐",
            description: "volleyball",
        },
        {
            emoji: "🏈",
            description: "american football",
        },
        {
            emoji: "🏉",
            description: "rugby football",
        },
        {
            emoji: "🎾",
            description: "tennis",
        },
        {
            emoji: "🥏",
            description: "flying disc",
        },
        {
            emoji: "🎳",
            description: "bowling",
        },
        {
            emoji: "🏏",
            description: "cricket game",
        },
        {
            emoji: "🏑",
            description: "field hockey",
        },
        {
            emoji: "🏒",
            description: "ice hockey",
        },
        {
            emoji: "🥍",
            description: "lacrosse",
        },
        {
            emoji: "🏓",
            description: "ping pong",
        },
        {
            emoji: "🏸",
            description: "badminton",
        },
        {
            emoji: "🥊",
            description: "boxing glove",
        },
        {
            emoji: "🥋",
            description: "martial arts uniform",
        },
        {
            emoji: "🥅",
            description: "goal net",
        },
        {
            emoji: "⛳",
            description: "flag in hole",
        },
        {
            emoji: "⛸️",
            description: "ice skate",
        },
        {
            emoji: "🎣",
            description: "fishing pole",
        },
        {
            emoji: "🤿",
            description: "diving mask",
        },
        {
            emoji: "🎽",
            description: "running shirt",
        },
        {
            emoji: "🎿",
            description: "skis",
        },
        {
            emoji: "🛷",
            description: "sled",
        },
        {
            emoji: "🥌",
            description: "curling stone",
        },
        {
            emoji: "🎯",
            description: "bullseye",
        },
        {
            emoji: "🪀",
            description: "yo-yo",
        },
        {
            emoji: "🪁",
            description: "kite",
        },
        {
            emoji: "🎱",
            description: "pool 8 ball",
        },
        {
            emoji: "🔮",
            description: "crystal ball",
        },
        {
            emoji: "🪄",
            description: "magic wand",
        },
        {
            emoji: "🧿",
            description: "nazar amulet",
        },
        {
            emoji: "🎮",
            description: "video game",
        },
        {
            emoji: "🕹️",
            description: "joystick",
        },
        {
            emoji: "🎰",
            description: "slot machine",
        },
        {
            emoji: "🎲",
            description: "game die",
        },
        {
            emoji: "🧩",
            description: "puzzle piece",
        },
        {
            emoji: "🧸",
            description: "teddy bear",
        },
        {
            emoji: "🪅",
            description: "piñata",
        },
        {
            emoji: "🪆",
            description: "nesting dolls",
        },
        {
            emoji: "♠️",
            description: "spade suit",
        },
        {
            emoji: "♥️",
            description: "heart suit",
        },
        {
            emoji: "♦️",
            description: "diamond suit",
        },
        {
            emoji: "♣️",
            description: "club suit",
        },
        {
            emoji: "♟️",
            description: "chess pawn",
        },
        {
            emoji: "🃏",
            description: "joker",
        },
        {
            emoji: "🀄",
            description: "mahjong red dragon",
        },
        {
            emoji: "🎴",
            description: "flower playing cards",
        },
        {
            emoji: "🎭",
            description: "performing arts",
        },
        {
            emoji: "🖼️",
            description: "framed picture",
        },
        {
            emoji: "🎨",
            description: "artist palette",
        },
        {
            emoji: "🧵",
            description: "thread",
        },
        {
            emoji: "🪡",
            description: "sewing needle",
        },
        {
            emoji: "🧶",
            description: "yarn",
        },
        {
            emoji: "🪢",
            description: "knot",
        },
    ],
    objects: [
        {
            emoji: "👓",
            description: "glasses",
        },
        {
            emoji: "🕶️",
            description: "sunglasses",
        },
        {
            emoji: "🥽",
            description: "goggles",
        },
        {
            emoji: "🥼",
            description: "lab coat",
        },
        {
            emoji: "🦺",
            description: "safety vest",
        },
        {
            emoji: "👔",
            description: "necktie",
        },
        {
            emoji: "👕",
            description: "t-shirt",
        },
        {
            emoji: "👖",
            description: "jeans",
        },
        {
            emoji: "🧣",
            description: "scarf",
        },
        {
            emoji: "🧤",
            description: "gloves",
        },
        {
            emoji: "🧥",
            description: "coat",
        },
        {
            emoji: "🧦",
            description: "socks",
        },
        {
            emoji: "👗",
            description: "dress",
        },
        {
            emoji: "👘",
            description: "kimono",
        },
        {
            emoji: "🥻",
            description: "sari",
        },
        {
            emoji: "🩱",
            description: "one-piece swimsuit",
        },
        {
            emoji: "🩲",
            description: "briefs",
        },
        {
            emoji: "🩳",
            description: "shorts",
        },
        {
            emoji: "👙",
            description: "bikini",
        },
        {
            emoji: "👚",
            description: "woman’s clothes",
        },
        {
            emoji: "👛",
            description: "purse",
        },
        {
            emoji: "👜",
            description: "handbag",
        },
        {
            emoji: "👝",
            description: "clutch bag",
        },
        {
            emoji: "🛍️",
            description: "shopping bags",
        },
        {
            emoji: "🎒",
            description: "backpack",
        },
        {
            emoji: "🩴",
            description: "thong sandal",
        },
        {
            emoji: "👞",
            description: "man’s shoe",
        },
        {
            emoji: "👟",
            description: "running shoe",
        },
        {
            emoji: "🥾",
            description: "hiking boot",
        },
        {
            emoji: "🥿",
            description: "flat shoe",
        },
        {
            emoji: "👠",
            description: "high-heeled shoe",
        },
        {
            emoji: "👡",
            description: "woman’s sandal",
        },
        {
            emoji: "🩰",
            description: "ballet shoes",
        },
        {
            emoji: "👢",
            description: "woman’s boot",
        },
        {
            emoji: "👑",
            description: "crown",
        },
        {
            emoji: "👒",
            description: "woman’s hat",
        },
        {
            emoji: "🎩",
            description: "top hat",
        },
        {
            emoji: "🎓",
            description: "graduation cap",
        },
        {
            emoji: "🧢",
            description: "billed cap",
        },
        {
            emoji: "🪖",
            description: "military helmet",
        },
        {
            emoji: "⛑️",
            description: "rescue worker’s helmet",
        },
        {
            emoji: "📿",
            description: "prayer beads",
        },
        {
            emoji: "💄",
            description: "lipstick",
        },
        {
            emoji: "💍",
            description: "ring",
        },
        {
            emoji: "💎",
            description: "gem stone",
        },
        {
            emoji: "🔇",
            description: "muted speaker",
        },
        {
            emoji: "🔈",
            description: "speaker low volume",
        },
        {
            emoji: "🔉",
            description: "speaker medium volume",
        },
        {
            emoji: "🔊",
            description: "speaker high volume",
        },
        {
            emoji: "📢",
            description: "loudspeaker",
        },
        {
            emoji: "📣",
            description: "megaphone",
        },
        {
            emoji: "📯",
            description: "postal horn",
        },
        {
            emoji: "🔔",
            description: "bell",
        },
        {
            emoji: "🔕",
            description: "bell with slash",
        },
        {
            emoji: "🎼",
            description: "musical score",
        },
        {
            emoji: "🎵",
            description: "musical note",
        },
        {
            emoji: "🎶",
            description: "musical notes",
        },
        {
            emoji: "🎙️",
            description: "studio microphone",
        },
        {
            emoji: "🎚️",
            description: "level slider",
        },
        {
            emoji: "🎛️",
            description: "control knobs",
        },
        {
            emoji: "🎤",
            description: "microphone",
        },
        {
            emoji: "🎧",
            description: "headphone",
        },
        {
            emoji: "📻",
            description: "radio",
        },
        {
            emoji: "🎷",
            description: "saxophone",
        },
        {
            emoji: "🪗",
            description: "accordion",
        },
        {
            emoji: "🎸",
            description: "guitar",
        },
        {
            emoji: "🎹",
            description: "musical keyboard",
        },
        {
            emoji: "🎺",
            description: "trumpet",
        },
        {
            emoji: "🎻",
            description: "violin",
        },
        {
            emoji: "🪕",
            description: "banjo",
        },
        {
            emoji: "🥁",
            description: "drum",
        },
        {
            emoji: "🪘",
            description: "long drum",
        },
        {
            emoji: "📱",
            description: "mobile phone",
        },
        {
            emoji: "📲",
            description: "mobile phone with arrow",
        },
        {
            emoji: "☎️",
            description: "telephone",
        },
        {
            emoji: "📞",
            description: "telephone receiver",
        },
        {
            emoji: "📟",
            description: "pager",
        },
        {
            emoji: "📠",
            description: "fax machine",
        },
        {
            emoji: "🔋",
            description: "battery",
        },
        {
            emoji: "🔌",
            description: "electric plug",
        },
        {
            emoji: "💻",
            description: "laptop",
        },
        {
            emoji: "🖥️",
            description: "desktop computer",
        },
        {
            emoji: "🖨️",
            description: "printer",
        },
        {
            emoji: "⌨️",
            description: "keyboard",
        },
        {
            emoji: "🖱️",
            description: "computer mouse",
        },
        {
            emoji: "🖲️",
            description: "trackball",
        },
        {
            emoji: "💽",
            description: "computer disk",
        },
        {
            emoji: "💾",
            description: "floppy disk",
        },
        {
            emoji: "💿",
            description: "optical disk",
        },
        {
            emoji: "📀",
            description: "dvd",
        },
        {
            emoji: "🧮",
            description: "abacus",
        },
        {
            emoji: "🎥",
            description: "movie camera",
        },
        {
            emoji: "🎞️",
            description: "film frames",
        },
        {
            emoji: "📽️",
            description: "film projector",
        },
        {
            emoji: "🎬",
            description: "clapper board",
        },
        {
            emoji: "📺",
            description: "television",
        },
        {
            emoji: "📷",
            description: "camera",
        },
        {
            emoji: "📸",
            description: "camera with flash",
        },
        {
            emoji: "📹",
            description: "video camera",
        },
        {
            emoji: "📼",
            description: "videocassette",
        },
        {
            emoji: "🔍",
            description: "magnifying glass tilted left",
        },
        {
            emoji: "🔎",
            description: "magnifying glass tilted right",
        },
        {
            emoji: "🕯️",
            description: "candle",
        },
        {
            emoji: "💡",
            description: "light bulb",
        },
        {
            emoji: "🔦",
            description: "flashlight",
        },
        {
            emoji: "🏮",
            description: "red paper lantern",
        },
        {
            emoji: "🪔",
            description: "diya lamp",
        },
        {
            emoji: "📔",
            description: "notebook with decorative cover",
        },
        {
            emoji: "📕",
            description: "closed book",
        },
        {
            emoji: "📖",
            description: "open book",
        },
        {
            emoji: "📗",
            description: "green book",
        },
        {
            emoji: "📘",
            description: "blue book",
        },
        {
            emoji: "📙",
            description: "orange book",
        },
        {
            emoji: "📚",
            description: "books",
        },
        {
            emoji: "📓",
            description: "notebook",
        },
        {
            emoji: "📒",
            description: "ledger",
        },
        {
            emoji: "📃",
            description: "page with curl",
        },
        {
            emoji: "📜",
            description: "scroll",
        },
        {
            emoji: "📄",
            description: "page facing up",
        },
        {
            emoji: "📰",
            description: "newspaper",
        },
        {
            emoji: "🗞️",
            description: "rolled-up newspaper",
        },
        {
            emoji: "📑",
            description: "bookmark tabs",
        },
        {
            emoji: "🔖",
            description: "bookmark",
        },
        {
            emoji: "🏷️",
            description: "label",
        },
        {
            emoji: "💰",
            description: "money bag",
        },
        {
            emoji: "🪙",
            description: "coin",
        },
        {
            emoji: "💴",
            description: "yen banknote",
        },
        {
            emoji: "💵",
            description: "dollar banknote",
        },
        {
            emoji: "💶",
            description: "euro banknote",
        },
        {
            emoji: "💷",
            description: "pound banknote",
        },
        {
            emoji: "💸",
            description: "money with wings",
        },
        {
            emoji: "💳",
            description: "credit card",
        },
        {
            emoji: "🧾",
            description: "receipt",
        },
        {
            emoji: "💹",
            description: "chart increasing with yen",
        },
        {
            emoji: "✉️",
            description: "envelope",
        },
        {
            emoji: "📧",
            description: "e-mail",
        },
        {
            emoji: "📨",
            description: "incoming envelope",
        },
        {
            emoji: "📩",
            description: "envelope with arrow",
        },
        {
            emoji: "📤",
            description: "outbox tray",
        },
        {
            emoji: "📥",
            description: "inbox tray",
        },
        {
            emoji: "📦",
            description: "package",
        },
        {
            emoji: "📫",
            description: "closed mailbox with raised flag",
        },
        {
            emoji: "📪",
            description: "closed mailbox with lowered flag",
        },
        {
            emoji: "📬",
            description: "open mailbox with raised flag",
        },
        {
            emoji: "📭",
            description: "open mailbox with lowered flag",
        },
        {
            emoji: "📮",
            description: "postbox",
        },
        {
            emoji: "🗳️",
            description: "ballot box with ballot",
        },
        {
            emoji: "✏️",
            description: "pencil",
        },
        {
            emoji: "✒️",
            description: "black nib",
        },
        {
            emoji: "🖋️",
            description: "fountain pen",
        },
        {
            emoji: "🖊️",
            description: "pen",
        },
        {
            emoji: "🖌️",
            description: "paintbrush",
        },
        {
            emoji: "🖍️",
            description: "crayon",
        },
        {
            emoji: "📝",
            description: "memo",
        },
        {
            emoji: "💼",
            description: "briefcase",
        },
        {
            emoji: "📁",
            description: "file folder",
        },
        {
            emoji: "📂",
            description: "open file folder",
        },
        {
            emoji: "🗂️",
            description: "card index dividers",
        },
        {
            emoji: "📅",
            description: "calendar",
        },
        {
            emoji: "📆",
            description: "tear-off calendar",
        },
        {
            emoji: "🗒️",
            description: "spiral notepad",
        },
        {
            emoji: "🗓️",
            description: "spiral calendar",
        },
        {
            emoji: "📇",
            description: "card index",
        },
        {
            emoji: "📈",
            description: "chart increasing",
        },
        {
            emoji: "📉",
            description: "chart decreasing",
        },
        {
            emoji: "📊",
            description: "bar chart",
        },
        {
            emoji: "📋",
            description: "clipboard",
        },
        {
            emoji: "📌",
            description: "pushpin",
        },
        {
            emoji: "📍",
            description: "round pushpin",
        },
        {
            emoji: "📎",
            description: "paperclip",
        },
        {
            emoji: "🖇️",
            description: "linked paperclips",
        },
        {
            emoji: "📏",
            description: "straight ruler",
        },
        {
            emoji: "📐",
            description: "triangular ruler",
        },
        {
            emoji: "✂️",
            description: "scissors",
        },
        {
            emoji: "🗃️",
            description: "card file box",
        },
        {
            emoji: "🗄️",
            description: "file cabinet",
        },
        {
            emoji: "🗑️",
            description: "wastebasket",
        },
        {
            emoji: "🔒",
            description: "locked",
        },
        {
            emoji: "🔓",
            description: "unlocked",
        },
        {
            emoji: "🔏",
            description: "locked with pen",
        },
        {
            emoji: "🔐",
            description: "locked with key",
        },
        {
            emoji: "🔑",
            description: "key",
        },
        {
            emoji: "🗝️",
            description: "old key",
        },
        {
            emoji: "🔨",
            description: "hammer",
        },
        {
            emoji: "🪓",
            description: "axe",
        },
        {
            emoji: "⛏️",
            description: "pick",
        },
        {
            emoji: "⚒️",
            description: "hammer and pick",
        },
        {
            emoji: "🛠️",
            description: "hammer and wrench",
        },
        {
            emoji: "🗡️",
            description: "dagger",
        },
        {
            emoji: "⚔️",
            description: "crossed swords",
        },
        {
            emoji: "🔫",
            description: "water pistol",
        },
        {
            emoji: "🪃",
            description: "boomerang",
        },
        {
            emoji: "🏹",
            description: "bow and arrow",
        },
        {
            emoji: "🛡️",
            description: "shield",
        },
        {
            emoji: "🪚",
            description: "carpentry saw",
        },
        {
            emoji: "🔧",
            description: "wrench",
        },
        {
            emoji: "🪛",
            description: "screwdriver",
        },
        {
            emoji: "🔩",
            description: "nut and bolt",
        },
        {
            emoji: "⚙️",
            description: "gear",
        },
        {
            emoji: "🗜️",
            description: "clamp",
        },
        {
            emoji: "⚖️",
            description: "balance scale",
        },
        {
            emoji: "🦯",
            description: "white cane",
        },
        {
            emoji: "🔗",
            description: "link",
        },
        {
            emoji: "⛓️",
            description: "chains",
        },
        {
            emoji: "🪝",
            description: "hook",
        },
        {
            emoji: "🧰",
            description: "toolbox",
        },
        {
            emoji: "🧲",
            description: "magnet",
        },
        {
            emoji: "🪜",
            description: "ladder",
        },
        {
            emoji: "⚗️",
            description: "alembic",
        },
        {
            emoji: "🧪",
            description: "test tube",
        },
        {
            emoji: "🧫",
            description: "petri dish",
        },
        {
            emoji: "🧬",
            description: "dna",
        },
        {
            emoji: "🔬",
            description: "microscope",
        },
        {
            emoji: "🔭",
            description: "telescope",
        },
        {
            emoji: "📡",
            description: "satellite antenna",
        },
        {
            emoji: "💉",
            description: "syringe",
        },
        {
            emoji: "🩸",
            description: "drop of blood",
        },
        {
            emoji: "💊",
            description: "pill",
        },
        {
            emoji: "🩹",
            description: "adhesive bandage",
        },
        {
            emoji: "🩺",
            description: "stethoscope",
        },
        {
            emoji: "🚪",
            description: "door",
        },
        {
            emoji: "🛗",
            description: "elevator",
        },
        {
            emoji: "🪞",
            description: "mirror",
        },
        {
            emoji: "🪟",
            description: "window",
        },
        {
            emoji: "🛏️",
            description: "bed",
        },
        {
            emoji: "🛋️",
            description: "couch and lamp",
        },
        {
            emoji: "🪑",
            description: "chair",
        },
        {
            emoji: "🚽",
            description: "toilet",
        },
        {
            emoji: "🪠",
            description: "plunger",
        },
        {
            emoji: "🚿",
            description: "shower",
        },
        {
            emoji: "🛁",
            description: "bathtub",
        },
        {
            emoji: "🪤",
            description: "mouse trap",
        },
        {
            emoji: "🪒",
            description: "razor",
        },
        {
            emoji: "🧴",
            description: "lotion bottle",
        },
        {
            emoji: "🧷",
            description: "safety pin",
        },
        {
            emoji: "🧹",
            description: "broom",
        },
        {
            emoji: "🧺",
            description: "basket",
        },
        {
            emoji: "🧻",
            description: "roll of paper",
        },
        {
            emoji: "🪣",
            description: "bucket",
        },
        {
            emoji: "🧼",
            description: "soap",
        },
        {
            emoji: "🪥",
            description: "toothbrush",
        },
        {
            emoji: "🧽",
            description: "sponge",
        },
        {
            emoji: "🧯",
            description: "fire extinguisher",
        },
        {
            emoji: "🛒",
            description: "shopping cart",
        },
        {
            emoji: "🚬",
            description: "cigarette",
        },
        {
            emoji: "⚰️",
            description: "coffin",
        },
        {
            emoji: "🪦",
            description: "headstone",
        },
        {
            emoji: "⚱️",
            description: "funeral urn",
        },
        {
            emoji: "🗿",
            description: "moai",
        },
        {
            emoji: "🪧",
            description: "placard",
        },
    ],
    symbols: [
        {
            emoji: "🏧",
            description: "ATM sign",
        },
        {
            emoji: "🚮",
            description: "litter in bin sign",
        },
        {
            emoji: "🚰",
            description: "potable water",
        },
        {
            emoji: "♿",
            description: "wheelchair symbol",
        },
        {
            emoji: "🚹",
            description: "men’s room",
        },
        {
            emoji: "🚺",
            description: "women’s room",
        },
        {
            emoji: "🚻",
            description: "restroom",
        },
        {
            emoji: "🚼",
            description: "baby symbol",
        },
        {
            emoji: "🚾",
            description: "water closet",
        },
        {
            emoji: "🛂",
            description: "passport control",
        },
        {
            emoji: "🛃",
            description: "customs",
        },
        {
            emoji: "🛄",
            description: "baggage claim",
        },
        {
            emoji: "🛅",
            description: "left luggage",
        },
        {
            emoji: "⚠️",
            description: "warning",
        },
        {
            emoji: "🚸",
            description: "children crossing",
        },
        {
            emoji: "⛔",
            description: "no entry",
        },
        {
            emoji: "🚫",
            description: "prohibited",
        },
        {
            emoji: "🚳",
            description: "no bicycles",
        },
        {
            emoji: "🚭",
            description: "no smoking",
        },
        {
            emoji: "🚯",
            description: "no littering",
        },
        {
            emoji: "🚱",
            description: "non-potable water",
        },
        {
            emoji: "🚷",
            description: "no pedestrians",
        },
        {
            emoji: "📵",
            description: "no mobile phones",
        },
        {
            emoji: "🔞",
            description: "no one under eighteen",
        },
        {
            emoji: "☢️",
            description: "radioactive",
        },
        {
            emoji: "☣️",
            description: "biohazard",
        },
        {
            emoji: "⬆️",
            description: "up arrow",
        },
        {
            emoji: "↗️",
            description: "up-right arrow",
        },
        {
            emoji: "➡️",
            description: "right arrow",
        },
        {
            emoji: "↘️",
            description: "down-right arrow",
        },
        {
            emoji: "⬇️",
            description: "down arrow",
        },
        {
            emoji: "↙️",
            description: "down-left arrow",
        },
        {
            emoji: "⬅️",
            description: "left arrow",
        },
        {
            emoji: "↖️",
            description: "up-left arrow",
        },
        {
            emoji: "↕️",
            description: "up-down arrow",
        },
        {
            emoji: "↔️",
            description: "left-right arrow",
        },
        {
            emoji: "↩️",
            description: "right arrow curving left",
        },
        {
            emoji: "↪️",
            description: "left arrow curving right",
        },
        {
            emoji: "⤴️",
            description: "right arrow curving up",
        },
        {
            emoji: "⤵️",
            description: "right arrow curving down",
        },
        {
            emoji: "🔃",
            description: "clockwise vertical arrows",
        },
        {
            emoji: "🔄",
            description: "counterclockwise arrows button",
        },
        {
            emoji: "🔙",
            description: "BACK arrow",
        },
        {
            emoji: "🔚",
            description: "END arrow",
        },
        {
            emoji: "🔛",
            description: "ON! arrow",
        },
        {
            emoji: "🔜",
            description: "SOON arrow",
        },
        {
            emoji: "🔝",
            description: "TOP arrow",
        },
        {
            emoji: "🛐",
            description: "place of worship",
        },
        {
            emoji: "⚛️",
            description: "atom symbol",
        },
        {
            emoji: "🕉️",
            description: "om",
        },
        {
            emoji: "✡️",
            description: "star of David",
        },
        {
            emoji: "☸️",
            description: "wheel of dharma",
        },
        {
            emoji: "☯️",
            description: "yin yang",
        },
        {
            emoji: "✝️",
            description: "latin cross",
        },
        {
            emoji: "☦️",
            description: "orthodox cross",
        },
        {
            emoji: "☪️",
            description: "star and crescent",
        },
        {
            emoji: "☮️",
            description: "peace symbol",
        },
        {
            emoji: "🕎",
            description: "menorah",
        },
        {
            emoji: "🔯",
            description: "dotted six-pointed star",
        },
        {
            emoji: "♈",
            description: "Aries",
        },
        {
            emoji: "♉",
            description: "Taurus",
        },
        {
            emoji: "♊",
            description: "Gemini",
        },
        {
            emoji: "♋",
            description: "Cancer",
        },
        {
            emoji: "♌",
            description: "Leo",
        },
        {
            emoji: "♍",
            description: "Virgo",
        },
        {
            emoji: "♎",
            description: "Libra",
        },
        {
            emoji: "♏",
            description: "Scorpio",
        },
        {
            emoji: "♐",
            description: "Sagittarius",
        },
        {
            emoji: "♑",
            description: "Capricorn",
        },
        {
            emoji: "♒",
            description: "Aquarius",
        },
        {
            emoji: "♓",
            description: "Pisces",
        },
        {
            emoji: "⛎",
            description: "Ophiuchus",
        },
        {
            emoji: "🔀",
            description: "shuffle tracks button",
        },
        {
            emoji: "🔁",
            description: "repeat button",
        },
        {
            emoji: "🔂",
            description: "repeat single button",
        },
        {
            emoji: "▶️",
            description: "play button",
        },
        {
            emoji: "⏩",
            description: "fast-forward button",
        },
        {
            emoji: "⏭️",
            description: "next track button",
        },
        {
            emoji: "⏯️",
            description: "play or pause button",
        },
        {
            emoji: "◀️",
            description: "reverse button",
        },
        {
            emoji: "⏪",
            description: "fast reverse button",
        },
        {
            emoji: "⏮️",
            description: "last track button",
        },
        {
            emoji: "🔼",
            description: "upwards button",
        },
        {
            emoji: "⏫",
            description: "fast up button",
        },
        {
            emoji: "🔽",
            description: "downwards button",
        },
        {
            emoji: "⏬",
            description: "fast down button",
        },
        {
            emoji: "⏸️",
            description: "pause button",
        },
        {
            emoji: "⏹️",
            description: "stop button",
        },
        {
            emoji: "⏺️",
            description: "record button",
        },
        {
            emoji: "⏏️",
            description: "eject button",
        },
        {
            emoji: "🎦",
            description: "cinema",
        },
        {
            emoji: "🔅",
            description: "dim button",
        },
        {
            emoji: "🔆",
            description: "bright button",
        },
        {
            emoji: "📶",
            description: "antenna bars",
        },
        {
            emoji: "📳",
            description: "vibration mode",
        },
        {
            emoji: "📴",
            description: "mobile phone off",
        },
        {
            emoji: "♀️",
            description: "female sign",
        },
        {
            emoji: "♂️",
            description: "male sign",
        },
        {
            emoji: "⚧️",
            description: "transgender symbol",
        },
        {
            emoji: "✖️",
            description: "multiply",
        },
        {
            emoji: "➕",
            description: "plus",
        },
        {
            emoji: "➖",
            description: "minus",
        },
        {
            emoji: "➗",
            description: "divide",
        },
        {
            emoji: "♾️",
            description: "infinity",
        },
        {
            emoji: "‼️",
            description: "double exclamation mark",
        },
        {
            emoji: "⁉️",
            description: "exclamation question mark",
        },
        {
            emoji: "❓",
            description: "red question mark",
        },
        {
            emoji: "❔",
            description: "white question mark",
        },
        {
            emoji: "❕",
            description: "white exclamation mark",
        },
        {
            emoji: "❗",
            description: "red exclamation mark",
        },
        {
            emoji: "〰️",
            description: "wavy dash",
        },
        {
            emoji: "💱",
            description: "currency exchange",
        },
        {
            emoji: "💲",
            description: "heavy dollar sign",
        },
        {
            emoji: "⚕️",
            description: "medical symbol",
        },
        {
            emoji: "♻️",
            description: "recycling symbol",
        },
        {
            emoji: "⚜️",
            description: "fleur-de-lis",
        },
        {
            emoji: "🔱",
            description: "trident emblem",
        },
        {
            emoji: "📛",
            description: "name badge",
        },
        {
            emoji: "🔰",
            description: "Japanese symbol for beginner",
        },
        {
            emoji: "⭕",
            description: "hollow red circle",
        },
        {
            emoji: "✅",
            description: "check mark button",
        },
        {
            emoji: "☑️",
            description: "check box with check",
        },
        {
            emoji: "✔️",
            description: "check mark",
        },
        {
            emoji: "❌",
            description: "cross mark",
        },
        {
            emoji: "❎",
            description: "cross mark button",
        },
        {
            emoji: "➰",
            description: "curly loop",
        },
        {
            emoji: "➿",
            description: "double curly loop",
        },
        {
            emoji: "〽️",
            description: "part alternation mark",
        },
        {
            emoji: "✳️",
            description: "eight-spoked asterisk",
        },
        {
            emoji: "✴️",
            description: "eight-pointed star",
        },
        {
            emoji: "❇️",
            description: "sparkle",
        },
        {
            emoji: "©️",
            description: "copyright",
        },
        {
            emoji: "®️",
            description: "registered",
        },
        {
            emoji: "™️",
            description: "trade mark",
        },
        {
            emoji: "#️⃣",
            description: "keycap: #",
        },
        {
            emoji: "*️⃣",
            description: "keycap: *",
        },
        {
            emoji: "0️⃣",
            description: "keycap: 0",
        },
        {
            emoji: "1️⃣",
            description: "keycap: 1",
        },
        {
            emoji: "2️⃣",
            description: "keycap: 2",
        },
        {
            emoji: "3️⃣",
            description: "keycap: 3",
        },
        {
            emoji: "4️⃣",
            description: "keycap: 4",
        },
        {
            emoji: "5️⃣",
            description: "keycap: 5",
        },
        {
            emoji: "6️⃣",
            description: "keycap: 6",
        },
        {
            emoji: "7️⃣",
            description: "keycap: 7",
        },
        {
            emoji: "8️⃣",
            description: "keycap: 8",
        },
        {
            emoji: "9️⃣",
            description: "keycap: 9",
        },
        {
            emoji: "🔟",
            description: "keycap: 10",
        },
        {
            emoji: "🔠",
            description: "input latin uppercase",
        },
        {
            emoji: "🔡",
            description: "input latin lowercase",
        },
        {
            emoji: "🔢",
            description: "input numbers",
        },
        {
            emoji: "🔣",
            description: "input symbols",
        },
        {
            emoji: "🔤",
            description: "input latin letters",
        },
        {
            emoji: "🅰️",
            description: "A button (blood type)",
        },
        {
            emoji: "🆎",
            description: "AB button (blood type)",
        },
        {
            emoji: "🅱️",
            description: "B button (blood type)",
        },
        {
            emoji: "🆑",
            description: "CL button",
        },
        {
            emoji: "🆒",
            description: "COOL button",
        },
        {
            emoji: "🆓",
            description: "FREE button",
        },
        {
            emoji: "ℹ️",
            description: "information",
        },
        {
            emoji: "🆔",
            description: "ID button",
        },
        {
            emoji: "Ⓜ️",
            description: "circled M",
        },
        {
            emoji: "🆕",
            description: "NEW button",
        },
        {
            emoji: "🆖",
            description: "NG button",
        },
        {
            emoji: "🅾️",
            description: "O button (blood type)",
        },
        {
            emoji: "🆗",
            description: "OK button",
        },
        {
            emoji: "🅿️",
            description: "P button",
        },
        {
            emoji: "🆘",
            description: "SOS button",
        },
        {
            emoji: "🆙",
            description: "UP! button",
        },
        {
            emoji: "🆚",
            description: "VS button",
        },
        {
            emoji: "🈁",
            description: "Japanese “here” button",
        },
        {
            emoji: "🈂️",
            description: "Japanese “service charge” button",
        },
        {
            emoji: "🈷️",
            description: "Japanese “monthly amount” button",
        },
        {
            emoji: "🈶",
            description: "Japanese “not free of charge” button",
        },
        {
            emoji: "🈯",
            description: "Japanese “reserved” button",
        },
        {
            emoji: "🉐",
            description: "Japanese “bargain” button",
        },
        {
            emoji: "🈹",
            description: "Japanese “discount” button",
        },
        {
            emoji: "🈚",
            description: "Japanese “free of charge” button",
        },
        {
            emoji: "🈲",
            description: "Japanese “prohibited” button",
        },
        {
            emoji: "🉑",
            description: "Japanese “acceptable” button",
        },
        {
            emoji: "🈸",
            description: "Japanese “application” button",
        },
        {
            emoji: "🈴",
            description: "Japanese “passing grade” button",
        },
        {
            emoji: "🈳",
            description: "Japanese “vacancy” button",
        },
        {
            emoji: "㊗️",
            description: "Japanese “congratulations” button",
        },
        {
            emoji: "㊙️",
            description: "Japanese “secret” button",
        },
        {
            emoji: "🈺",
            description: "Japanese “open for business” button",
        },
        {
            emoji: "🈵",
            description: "Japanese “no vacancy” button",
        },
        {
            emoji: "🔴",
            description: "red circle",
        },
        {
            emoji: "🟠",
            description: "orange circle",
        },
        {
            emoji: "🟡",
            description: "yellow circle",
        },
        {
            emoji: "🟢",
            description: "green circle",
        },
        {
            emoji: "🔵",
            description: "blue circle",
        },
        {
            emoji: "🟣",
            description: "purple circle",
        },
        {
            emoji: "🟤",
            description: "brown circle",
        },
        {
            emoji: "⚫",
            description: "black circle",
        },
        {
            emoji: "⚪",
            description: "white circle",
        },
        {
            emoji: "🟥",
            description: "red square",
        },
        {
            emoji: "🟧",
            description: "orange square",
        },
        {
            emoji: "🟨",
            description: "yellow square",
        },
        {
            emoji: "🟩",
            description: "green square",
        },
        {
            emoji: "🟦",
            description: "blue square",
        },
        {
            emoji: "🟪",
            description: "purple square",
        },
        {
            emoji: "🟫",
            description: "brown square",
        },
        {
            emoji: "⬛",
            description: "black large square",
        },
        {
            emoji: "⬜",
            description: "white large square",
        },
        {
            emoji: "◼️",
            description: "black medium square",
        },
        {
            emoji: "◻️",
            description: "white medium square",
        },
        {
            emoji: "◾",
            description: "black medium-small square",
        },
        {
            emoji: "◽",
            description: "white medium-small square",
        },
        {
            emoji: "▪️",
            description: "black small square",
        },
        {
            emoji: "▫️",
            description: "white small square",
        },
        {
            emoji: "🔶",
            description: "large orange diamond",
        },
        {
            emoji: "🔷",
            description: "large blue diamond",
        },
        {
            emoji: "🔸",
            description: "small orange diamond",
        },
        {
            emoji: "🔹",
            description: "small blue diamond",
        },
        {
            emoji: "🔺",
            description: "red triangle pointed up",
        },
        {
            emoji: "🔻",
            description: "red triangle pointed down",
        },
        {
            emoji: "💠",
            description: "diamond with a dot",
        },
        {
            emoji: "🔘",
            description: "radio button",
        },
        {
            emoji: "🔳",
            description: "white square button",
        },
        {
            emoji: "🔲",
            description: "black square button",
        },
    ],
    flags: [
        {
            emoji: "🏁",
            description: "chequered flag",
        },
        {
            emoji: "🚩",
            description: "triangular flag",
        },
        {
            emoji: "🎌",
            description: "crossed flags",
        },
        {
            emoji: "🏴",
            description: "black flag",
        },
        {
            emoji: "🏳️",
            description: "white flag",
        },
        {
            emoji: "🏳️‍🌈",
            description: "rainbow flag",
        },
        {
            emoji: "🏳️‍⚧️",
            description: "transgender flag",
        },
        {
            emoji: "🏴‍☠️",
            description: "pirate flag",
        },
        {
            emoji: "🇦🇨",
            description: "flag: Ascension Island",
        },
        {
            emoji: "🇦🇩",
            description: "flag: Andorra",
        },
        {
            emoji: "🇦🇪",
            description: "flag: United Arab Emirates",
        },
        {
            emoji: "🇦🇫",
            description: "flag: Afghanistan",
        },
        {
            emoji: "🇦🇬",
            description: "flag: Antigua & Barbuda",
        },
        {
            emoji: "🇦🇮",
            description: "flag: Anguilla",
        },
        {
            emoji: "🇦🇱",
            description: "flag: Albania",
        },
        {
            emoji: "🇦🇲",
            description: "flag: Armenia",
        },
        {
            emoji: "🇦🇴",
            description: "flag: Angola",
        },
        {
            emoji: "🇦🇶",
            description: "flag: Antarctica",
        },
        {
            emoji: "🇦🇷",
            description: "flag: Argentina",
        },
        {
            emoji: "🇦🇸",
            description: "flag: American Samoa",
        },
        {
            emoji: "🇦🇹",
            description: "flag: Austria",
        },
        {
            emoji: "🇦🇺",
            description: "flag: Australia",
        },
        {
            emoji: "🇦🇼",
            description: "flag: Aruba",
        },
        {
            emoji: "🇦🇽",
            description: "flag: Åland Islands",
        },
        {
            emoji: "🇦🇿",
            description: "flag: Azerbaijan",
        },
        {
            emoji: "🇧🇦",
            description: "flag: Bosnia & Herzegovina",
        },
        {
            emoji: "🇧🇧",
            description: "flag: Barbados",
        },
        {
            emoji: "🇧🇩",
            description: "flag: Bangladesh",
        },
        {
            emoji: "🇧🇪",
            description: "flag: Belgium",
        },
        {
            emoji: "🇧🇫",
            description: "flag: Burkina Faso",
        },
        {
            emoji: "🇧🇬",
            description: "flag: Bulgaria",
        },
        {
            emoji: "🇧🇭",
            description: "flag: Bahrain",
        },
        {
            emoji: "🇧🇮",
            description: "flag: Burundi",
        },
        {
            emoji: "🇧🇯",
            description: "flag: Benin",
        },
        {
            emoji: "🇧🇱",
            description: "flag: St. Barthélemy",
        },
        {
            emoji: "🇧🇲",
            description: "flag: Bermuda",
        },
        {
            emoji: "🇧🇳",
            description: "flag: Brunei",
        },
        {
            emoji: "🇧🇴",
            description: "flag: Bolivia",
        },
        {
            emoji: "🇧🇶",
            description: "flag: Caribbean Netherlands",
        },
        {
            emoji: "🇧🇷",
            description: "flag: Brazil",
        },
        {
            emoji: "🇧🇸",
            description: "flag: Bahamas",
        },
        {
            emoji: "🇧🇹",
            description: "flag: Bhutan",
        },
        {
            emoji: "🇧🇻",
            description: "flag: Bouvet Island",
        },
        {
            emoji: "🇧🇼",
            description: "flag: Botswana",
        },
        {
            emoji: "🇧🇾",
            description: "flag: Belarus",
        },
        {
            emoji: "🇧🇿",
            description: "flag: Belize",
        },
        {
            emoji: "🇨🇦",
            description: "flag: Canada",
        },
        {
            emoji: "🇨🇨",
            description: "flag: Cocos (Keeling) Islands",
        },
        {
            emoji: "🇨🇩",
            description: "flag: Congo - Kinshasa",
        },
        {
            emoji: "🇨🇫",
            description: "flag: Central African Republic",
        },
        {
            emoji: "🇨🇬",
            description: "flag: Congo - Brazzaville",
        },
        {
            emoji: "🇨🇭",
            description: "flag: Switzerland",
        },
        {
            emoji: "🇨🇮",
            description: "flag: Côte d’Ivoire",
        },
        {
            emoji: "🇨🇰",
            description: "flag: Cook Islands",
        },
        {
            emoji: "🇨🇱",
            description: "flag: Chile",
        },
        {
            emoji: "🇨🇲",
            description: "flag: Cameroon",
        },
        {
            emoji: "🇨🇳",
            description: "flag: China",
        },
        {
            emoji: "🇨🇴",
            description: "flag: Colombia",
        },
        {
            emoji: "🇨🇵",
            description: "flag: Clipperton Island",
        },
        {
            emoji: "🇨🇷",
            description: "flag: Costa Rica",
        },
        {
            emoji: "🇨🇺",
            description: "flag: Cuba",
        },
        {
            emoji: "🇨🇻",
            description: "flag: Cape Verde",
        },
        {
            emoji: "🇨🇼",
            description: "flag: Curaçao",
        },
        {
            emoji: "🇨🇽",
            description: "flag: Christmas Island",
        },
        {
            emoji: "🇨🇾",
            description: "flag: Cyprus",
        },
        {
            emoji: "🇨🇿",
            description: "flag: Czechia",
        },
        {
            emoji: "🇩🇪",
            description: "flag: Germany",
        },
        {
            emoji: "🇩🇬",
            description: "flag: Diego Garcia",
        },
        {
            emoji: "🇩🇯",
            description: "flag: Djibouti",
        },
        {
            emoji: "🇩🇰",
            description: "flag: Denmark",
        },
        {
            emoji: "🇩🇲",
            description: "flag: Dominica",
        },
        {
            emoji: "🇩🇴",
            description: "flag: Dominican Republic",
        },
        {
            emoji: "🇩🇿",
            description: "flag: Algeria",
        },
        {
            emoji: "🇪🇦",
            description: "flag: Ceuta & Melilla",
        },
        {
            emoji: "🇪🇨",
            description: "flag: Ecuador",
        },
        {
            emoji: "🇪🇪",
            description: "flag: Estonia",
        },
        {
            emoji: "🇪🇬",
            description: "flag: Egypt",
        },
        {
            emoji: "🇪🇭",
            description: "flag: Western Sahara",
        },
        {
            emoji: "🇪🇷",
            description: "flag: Eritrea",
        },
        {
            emoji: "🇪🇸",
            description: "flag: Spain",
        },
        {
            emoji: "🇪🇹",
            description: "flag: Ethiopia",
        },
        {
            emoji: "🇪🇺",
            description: "flag: European Union",
        },
        {
            emoji: "🇫🇮",
            description: "flag: Finland",
        },
        {
            emoji: "🇫🇯",
            description: "flag: Fiji",
        },
        {
            emoji: "🇫🇰",
            description: "flag: Falkland Islands",
        },
        {
            emoji: "🇫🇲",
            description: "flag: Micronesia",
        },
        {
            emoji: "🇫🇴",
            description: "flag: Faroe Islands",
        },
        {
            emoji: "🇫🇷",
            description: "flag: France",
        },
        {
            emoji: "🇬🇦",
            description: "flag: Gabon",
        },
        {
            emoji: "🇬🇧",
            description: "flag: United Kingdom",
        },
        {
            emoji: "🇬🇩",
            description: "flag: Grenada",
        },
        {
            emoji: "🇬🇪",
            description: "flag: Georgia",
        },
        {
            emoji: "🇬🇫",
            description: "flag: French Guiana",
        },
        {
            emoji: "🇬🇬",
            description: "flag: Guernsey",
        },
        {
            emoji: "🇬🇭",
            description: "flag: Ghana",
        },
        {
            emoji: "🇬🇮",
            description: "flag: Gibraltar",
        },
        {
            emoji: "🇬🇱",
            description: "flag: Greenland",
        },
        {
            emoji: "🇬🇲",
            description: "flag: Gambia",
        },
        {
            emoji: "🇬🇳",
            description: "flag: Guinea",
        },
        {
            emoji: "🇬🇵",
            description: "flag: Guadeloupe",
        },
        {
            emoji: "🇬🇶",
            description: "flag: Equatorial Guinea",
        },
        {
            emoji: "🇬🇷",
            description: "flag: Greece",
        },
        {
            emoji: "🇬🇸",
            description: "flag: South Georgia & South Sandwich Islands",
        },
        {
            emoji: "🇬🇹",
            description: "flag: Guatemala",
        },
        {
            emoji: "🇬🇺",
            description: "flag: Guam",
        },
        {
            emoji: "🇬🇼",
            description: "flag: Guinea-Bissau",
        },
        {
            emoji: "🇬🇾",
            description: "flag: Guyana",
        },
        {
            emoji: "🇭🇰",
            description: "flag: Hong Kong SAR China",
        },
        {
            emoji: "🇭🇲",
            description: "flag: Heard & McDonald Islands",
        },
        {
            emoji: "🇭🇳",
            description: "flag: Honduras",
        },
        {
            emoji: "🇭🇷",
            description: "flag: Croatia",
        },
        {
            emoji: "🇭🇹",
            description: "flag: Haiti",
        },
        {
            emoji: "🇭🇺",
            description: "flag: Hungary",
        },
        {
            emoji: "🇮🇨",
            description: "flag: Canary Islands",
        },
        {
            emoji: "🇮🇩",
            description: "flag: Indonesia",
        },
        {
            emoji: "🇮🇪",
            description: "flag: Ireland",
        },
        {
            emoji: "🇮🇱",
            description: "flag: Israel",
        },
        {
            emoji: "🇮🇲",
            description: "flag: Isle of Man",
        },
        {
            emoji: "🇮🇳",
            description: "flag: India",
        },
        {
            emoji: "🇮🇴",
            description: "flag: British Indian Ocean Territory",
        },
        {
            emoji: "🇮🇶",
            description: "flag: Iraq",
        },
        {
            emoji: "🇮🇷",
            description: "flag: Iran",
        },
        {
            emoji: "🇮🇸",
            description: "flag: Iceland",
        },
        {
            emoji: "🇮🇹",
            description: "flag: Italy",
        },
        {
            emoji: "🇯🇪",
            description: "flag: Jersey",
        },
        {
            emoji: "🇯🇲",
            description: "flag: Jamaica",
        },
        {
            emoji: "🇯🇴",
            description: "flag: Jordan",
        },
        {
            emoji: "🇯🇵",
            description: "flag: Japan",
        },
        {
            emoji: "🇰🇪",
            description: "flag: Kenya",
        },
        {
            emoji: "🇰🇬",
            description: "flag: Kyrgyzstan",
        },
        {
            emoji: "🇰🇭",
            description: "flag: Cambodia",
        },
        {
            emoji: "🇰🇮",
            description: "flag: Kiribati",
        },
        {
            emoji: "🇰🇲",
            description: "flag: Comoros",
        },
        {
            emoji: "🇰🇳",
            description: "flag: St. Kitts & Nevis",
        },
        {
            emoji: "🇰🇵",
            description: "flag: North Korea",
        },
        {
            emoji: "🇰🇷",
            description: "flag: South Korea",
        },
        {
            emoji: "🇰🇼",
            description: "flag: Kuwait",
        },
        {
            emoji: "🇰🇾",
            description: "flag: Cayman Islands",
        },
        {
            emoji: "🇰🇿",
            description: "flag: Kazakhstan",
        },
        {
            emoji: "🇱🇦",
            description: "flag: Laos",
        },
        {
            emoji: "🇱🇧",
            description: "flag: Lebanon",
        },
        {
            emoji: "🇱🇨",
            description: "flag: St. Lucia",
        },
        {
            emoji: "🇱🇮",
            description: "flag: Liechtenstein",
        },
        {
            emoji: "🇱🇰",
            description: "flag: Sri Lanka",
        },
        {
            emoji: "🇱🇷",
            description: "flag: Liberia",
        },
        {
            emoji: "🇱🇸",
            description: "flag: Lesotho",
        },
        {
            emoji: "🇱🇹",
            description: "flag: Lithuania",
        },
        {
            emoji: "🇱🇺",
            description: "flag: Luxembourg",
        },
        {
            emoji: "🇱🇻",
            description: "flag: Latvia",
        },
        {
            emoji: "🇱🇾",
            description: "flag: Libya",
        },
        {
            emoji: "🇲🇦",
            description: "flag: Morocco",
        },
        {
            emoji: "🇲🇨",
            description: "flag: Monaco",
        },
        {
            emoji: "🇲🇩",
            description: "flag: Moldova",
        },
        {
            emoji: "🇲🇪",
            description: "flag: Montenegro",
        },
        {
            emoji: "🇲🇫",
            description: "flag: St. Martin",
        },
        {
            emoji: "🇲🇬",
            description: "flag: Madagascar",
        },
        {
            emoji: "🇲🇭",
            description: "flag: Marshall Islands",
        },
        {
            emoji: "🇲🇰",
            description: "flag: North Macedonia",
        },
        {
            emoji: "🇲🇱",
            description: "flag: Mali",
        },
        {
            emoji: "🇲🇲",
            description: "flag: Myanmar (Burma)",
        },
        {
            emoji: "🇲🇳",
            description: "flag: Mongolia",
        },
        {
            emoji: "🇲🇴",
            description: "flag: Macao SAR China",
        },
        {
            emoji: "🇲🇵",
            description: "flag: Northern Mariana Islands",
        },
        {
            emoji: "🇲🇶",
            description: "flag: Martinique",
        },
        {
            emoji: "🇲🇷",
            description: "flag: Mauritania",
        },
        {
            emoji: "🇲🇸",
            description: "flag: Montserrat",
        },
        {
            emoji: "🇲🇹",
            description: "flag: Malta",
        },
        {
            emoji: "🇲🇺",
            description: "flag: Mauritius",
        },
        {
            emoji: "🇲🇻",
            description: "flag: Maldives",
        },
        {
            emoji: "🇲🇼",
            description: "flag: Malawi",
        },
        {
            emoji: "🇲🇽",
            description: "flag: Mexico",
        },
        {
            emoji: "🇲🇾",
            description: "flag: Malaysia",
        },
        {
            emoji: "🇲🇿",
            description: "flag: Mozambique",
        },
        {
            emoji: "🇳🇦",
            description: "flag: Namibia",
        },
        {
            emoji: "🇳🇨",
            description: "flag: New Caledonia",
        },
        {
            emoji: "🇳🇪",
            description: "flag: Niger",
        },
        {
            emoji: "🇳🇫",
            description: "flag: Norfolk Island",
        },
        {
            emoji: "🇳🇬",
            description: "flag: Nigeria",
        },
        {
            emoji: "🇳🇮",
            description: "flag: Nicaragua",
        },
        {
            emoji: "🇳🇱",
            description: "flag: Netherlands",
        },
        {
            emoji: "🇳🇴",
            description: "flag: Norway",
        },
        {
            emoji: "🇳🇵",
            description: "flag: Nepal",
        },
        {
            emoji: "🇳🇷",
            description: "flag: Nauru",
        },
        {
            emoji: "🇳🇺",
            description: "flag: Niue",
        },
        {
            emoji: "🇳🇿",
            description: "flag: New Zealand",
        },
        {
            emoji: "🇴🇲",
            description: "flag: Oman",
        },
        {
            emoji: "🇵🇦",
            description: "flag: Panama",
        },
        {
            emoji: "🇵🇪",
            description: "flag: Peru",
        },
        {
            emoji: "🇵🇫",
            description: "flag: French Polynesia",
        },
        {
            emoji: "🇵🇬",
            description: "flag: Papua New Guinea",
        },
        {
            emoji: "🇵🇭",
            description: "flag: Philippines",
        },
        {
            emoji: "🇵🇰",
            description: "flag: Pakistan",
        },
        {
            emoji: "🇵🇱",
            description: "flag: Poland",
        },
        {
            emoji: "🇵🇲",
            description: "flag: St. Pierre & Miquelon",
        },
        {
            emoji: "🇵🇳",
            description: "flag: Pitcairn Islands",
        },
        {
            emoji: "🇵🇷",
            description: "flag: Puerto Rico",
        },
        {
            emoji: "🇵🇸",
            description: "flag: Palestinian Territories",
        },
        {
            emoji: "🇵🇹",
            description: "flag: Portugal",
        },
        {
            emoji: "🇵🇼",
            description: "flag: Palau",
        },
        {
            emoji: "🇵🇾",
            description: "flag: Paraguay",
        },
        {
            emoji: "🇶🇦",
            description: "flag: Qatar",
        },
        {
            emoji: "🇷🇪",
            description: "flag: Réunion",
        },
        {
            emoji: "🇷🇴",
            description: "flag: Romania",
        },
        {
            emoji: "🇷🇸",
            description: "flag: Serbia",
        },
        {
            emoji: "🇷🇺",
            description: "flag: Russia",
        },
        {
            emoji: "🇷🇼",
            description: "flag: Rwanda",
        },
        {
            emoji: "🇸🇦",
            description: "flag: Saudi Arabia",
        },
        {
            emoji: "🇸🇧",
            description: "flag: Solomon Islands",
        },
        {
            emoji: "🇸🇨",
            description: "flag: Seychelles",
        },
        {
            emoji: "🇸🇩",
            description: "flag: Sudan",
        },
        {
            emoji: "🇸🇪",
            description: "flag: Sweden",
        },
        {
            emoji: "🇸🇬",
            description: "flag: Singapore",
        },
        {
            emoji: "🇸🇭",
            description: "flag: St. Helena",
        },
        {
            emoji: "🇸🇮",
            description: "flag: Slovenia",
        },
        {
            emoji: "🇸🇯",
            description: "flag: Svalbard & Jan Mayen",
        },
        {
            emoji: "🇸🇰",
            description: "flag: Slovakia",
        },
        {
            emoji: "🇸🇱",
            description: "flag: Sierra Leone",
        },
        {
            emoji: "🇸🇲",
            description: "flag: San Marino",
        },
        {
            emoji: "🇸🇳",
            description: "flag: Senegal",
        },
        {
            emoji: "🇸🇴",
            description: "flag: Somalia",
        },
        {
            emoji: "🇸🇷",
            description: "flag: Suriname",
        },
        {
            emoji: "🇸🇸",
            description: "flag: South Sudan",
        },
        {
            emoji: "🇸🇹",
            description: "flag: São Tomé & Príncipe",
        },
        {
            emoji: "🇸🇻",
            description: "flag: El Salvador",
        },
        {
            emoji: "🇸🇽",
            description: "flag: Sint Maarten",
        },
        {
            emoji: "🇸🇾",
            description: "flag: Syria",
        },
        {
            emoji: "🇸🇿",
            description: "flag: Eswatini",
        },
        {
            emoji: "🇹🇦",
            description: "flag: Tristan da Cunha",
        },
        {
            emoji: "🇹🇨",
            description: "flag: Turks & Caicos Islands",
        },
        {
            emoji: "🇹🇩",
            description: "flag: Chad",
        },
        {
            emoji: "🇹🇫",
            description: "flag: French Southern Territories",
        },
        {
            emoji: "🇹🇬",
            description: "flag: Togo",
        },
        {
            emoji: "🇹🇭",
            description: "flag: Thailand",
        },
        {
            emoji: "🇹🇯",
            description: "flag: Tajikistan",
        },
        {
            emoji: "🇹🇰",
            description: "flag: Tokelau",
        },
        {
            emoji: "🇹🇱",
            description: "flag: Timor-Leste",
        },
        {
            emoji: "🇹🇲",
            description: "flag: Turkmenistan",
        },
        {
            emoji: "🇹🇳",
            description: "flag: Tunisia",
        },
        {
            emoji: "🇹🇴",
            description: "flag: Tonga",
        },
        {
            emoji: "🇹🇷",
            description: "flag: Turkey",
        },
        {
            emoji: "🇹🇹",
            description: "flag: Trinidad & Tobago",
        },
        {
            emoji: "🇹🇻",
            description: "flag: Tuvalu",
        },
        {
            emoji: "🇹🇼",
            description: "flag: Taiwan",
        },
        {
            emoji: "🇹🇿",
            description: "flag: Tanzania",
        },
        {
            emoji: "🇺🇦",
            description: "flag: Ukraine",
        },
        {
            emoji: "🇺🇬",
            description: "flag: Uganda",
        },
        {
            emoji: "🇺🇲",
            description: "flag: U.S. Outlying Islands",
        },
        {
            emoji: "🇺🇳",
            description: "flag: United Nations",
        },
        {
            emoji: "🇺🇸",
            description: "flag: United States",
        },
        {
            emoji: "🇺🇾",
            description: "flag: Uruguay",
        },
        {
            emoji: "🇺🇿",
            description: "flag: Uzbekistan",
        },
        {
            emoji: "🇻🇦",
            description: "flag: Vatican City",
        },
        {
            emoji: "🇻🇨",
            description: "flag: St. Vincent & Grenadines",
        },
        {
            emoji: "🇻🇪",
            description: "flag: Venezuela",
        },
        {
            emoji: "🇻🇬",
            description: "flag: British Virgin Islands",
        },
        {
            emoji: "🇻🇮",
            description: "flag: U.S. Virgin Islands",
        },
        {
            emoji: "🇻🇳",
            description: "flag: Vietnam",
        },
        {
            emoji: "🇻🇺",
            description: "flag: Vanuatu",
        },
        {
            emoji: "🇼🇫",
            description: "flag: Wallis & Futuna",
        },
        {
            emoji: "🇼🇸",
            description: "flag: Samoa",
        },
        {
            emoji: "🇽🇰",
            description: "flag: Kosovo",
        },
        {
            emoji: "🇾🇪",
            description: "flag: Yemen",
        },
        {
            emoji: "🇾🇹",
            description: "flag: Mayotte",
        },
        {
            emoji: "🇿🇦",
            description: "flag: South Africa",
        },
        {
            emoji: "🇿🇲",
            description: "flag: Zambia",
        },
        {
            emoji: "🇿🇼",
            description: "flag: Zimbabwe",
        },
        {
            emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
            description: "flag: England",
        },
        {
            emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
            description: "flag: Scotland",
        },
        {
            emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
            description: "flag: Wales",
        },
    ],
};
