import { IDeliveryOrder } from "../../../api/models/deliveryOrder";
import { Order, OrderItem, OrderItemImpl } from "../../../api/models/order";
import { ITaxOrderItem } from "../../../api/models/orderBase";
import { IOrderPayment } from "../../../api/models/orderPayment";
import { IOrderReturn } from "../../../api/models/orderReturn";
import { IProduct, IProductVariant, ProductOptionalsConfig, ProductPriceTypes } from "../../../api/models/product";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { ITax } from "../../../api/models/tax";
import { IWarehouse } from "../../../api/models/warehouse";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { getAuthBody } from "../../../auth";
import { getRoundNumber } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import {
    calculateItemBasePrice,
    extractTaxInformation,
    getItemTaxesFromProduct,
    getItemTaxesFromTaxes,
    getItemTotalTax,
} from "../../../utils/taxes";
import { getTotalPayments } from "./FinalizeOrderForm/finalizeHelper";

export const getAbsoluteAmountOrderReturn = (currentSale: IOrderReturn) => {
    return currentSale.totalAmountItems + currentSale.totalManualPercentageDiscountItems;
};

export const headerTitleOptions = {
    queued: "Nuevo Pedido",
    quotation: "Nueva Cotización",
    sale: "Nueva Venta",
};

interface InsertProductOption {
    items: OrderItem[];
    product: IProduct;
    productVariant?: IProductVariant;
    optionalsConfig?: ProductOptionalsConfig;
    warehouseProduct?: IWarehouseProduct;
    defaultPrice: ProductPriceTypes;
    currentStock?: number;
    warehouse?: IWarehouse;
    taxes?: ITax[];
    quantity?: number;
    price?: number;
    taxIncluded: boolean;
    preTaxDiscount: boolean;
    isRestaurant?: boolean;
    comment?: string;
}

export const onOrderItemPriceChange = (orderItem: OrderItem, newValue: number, onChange: (value: OrderItem) => void) => {
    orderItem.unitPrice = newValue;
    let basePriceResult = calculateItemBasePrice(orderItem, orderItem.taxes, true, false);
    orderItem.basePrice = basePriceResult.basePrice;
    orderItem.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;
    let taxAmount = getItemTotalTax(orderItem.taxes, orderItem, true, false);
    let subTotal = orderItem.basePrice;
    let total = subTotal + taxAmount;
    onChange({ ...orderItem, totalTax: taxAmount, basePrice: basePriceResult.basePrice, unitPrice: newValue, subTotal, total });
};

const compareOrderItem = (element: OrderItem, insert: InsertProductOption) => {
    return (
        !element.product?.includesOptionals &&
        element.product?.id === insert.product.id &&
        element.warehouseId === insert.warehouse?.id &&
        (!insert.productVariant?.id || element.productVariantId === insert.productVariant?.id)
    );
};

export const insertProductInOrderList = (props: InsertProductOption) => {
    const { items, product, defaultPrice, warehouse } = props;
    let copy: OrderItem[] = [];
    let exists = false;

    if (!props.isRestaurant) {
        items.forEach((element) => {
            if (compareOrderItem(element, props) && !exists) {
                exists = true;
                element.quantity += 1;
                element = getOrderItemTotals(element);
                copy.push({ ...element });
            } else {
                copy.push(element);
            }
        });
    }

    let newItems: OrderItem[];
    if (exists) {
        newItems = copy;
    } else {
        let newItem = new OrderItemImpl(product, props.quantity ?? 1, defaultPrice, props.currentStock, warehouse);

        newItem.position = items.length + 1;
        newItem.productVariant = props.productVariant;
        newItem.productVariantId = props.productVariant?.id;
        newItem.unitPrice = props.price ? props.price : newItem.unitPrice;
        newItem.warehouseProduct = props.warehouseProduct;

        newItem.comment = props.comment;
        newItem.optionalsConfig = props.optionalsConfig;
        newItem.createdAt = customMoment().utc().format();

        newItem.accountUserId = getAuthBody().accountUserId;
        newItem.productCategoryId = product.customCategoryId;

        newItem.costWeightedAverage = props.product.costAverage;
        newItem.costBaseWeightedAverage = props.product.costBaseWeightedAverage;
        newItem.costAverage = props.product.costAverage;
        newItem.costBaseAverage = props.product.costBaseAverage;
        newItem.costLastPurchase = props.productVariant?.costLastPurchase ?? props.product.costLastPurchase;
        newItem.costBaseLastPurchase = props.productVariant?.costBaseLastPurchase ?? props.product.costBaseLastPurchase;

        newItem.preparationStatus = props.isRestaurant ? "waiting" : undefined;

        let itemTaxes: ITaxOrderItem[] = [];
        if (props.taxes && props.taxes.length > 0) {
            itemTaxes = getItemTaxesFromTaxes(0, props.taxes);
        } else if (product.taxes && product.taxes.length > 0) {
            itemTaxes = newItem.taxes != null && newItem.taxes.length > 0 ? newItem.taxes : getItemTaxesFromProduct(0, product);
        }

        if (product.discounts && product.discounts.length > 0) {
            var sumPercentage = product.discounts.reduce((a, b) => a + (b.percentage || 0), 0);
            newItem.manualPercentageDiscount = sumPercentage;
            newItem.details = newItem.details ?? {};
            newItem.details.discountName = product.discounts[0].discountName;
        }

        let [taxPercentage] = extractTaxInformation(itemTaxes);
        let basePriceResult = calculateItemBasePrice(newItem, itemTaxes, true, false);
        newItem.basePrice = basePriceResult.basePrice;
        newItem.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;
        newItem.taxes = itemTaxes;
        let taxAmount = getItemTotalTax(itemTaxes, newItem, true, false);

        newItem.totalTax = taxAmount;
        newItem.totalTaxPercentage = taxPercentage;
        newItems = [...items, getOrderItemTotals(newItem)];
    }

    return newItems;
};

export const getOrderItemTotals = (item: OrderItem) => {
    var discountBase = 0;
    const taxIncluded = true;
    const preTaxDiscount = false;

    let extraPrice = 0;
    if (item.optionalsConfig && item.optionalsConfig.totalExtraPrice > 0) {
        extraPrice = item.optionalsConfig.totalExtraPrice;
    }

    if (taxIncluded) {
        discountBase = preTaxDiscount ? item.basePrice : item.unitPrice;
        let totalAmountManualDiscount = discountBase * (item.manualPercentageDiscount / 100);
        item.manualPercentageDiscountValue = totalAmountManualDiscount;
        item.subTotal = (item.basePrice + extraPrice) * item.quantity;
        item.total = getRoundNumber((item.unitPrice + extraPrice - totalAmountManualDiscount) * item.quantity);
    } else {
        discountBase = preTaxDiscount ? item.unitPrice : item.basePrice;
        let totalAmountManualDiscount = discountBase * (item.manualPercentageDiscount / 100);
        item.manualPercentageDiscountValue = totalAmountManualDiscount;
        item.subTotal = (item.unitPrice + extraPrice) * item.quantity;
        item.total = getRoundNumber((item.basePrice + extraPrice - totalAmountManualDiscount) * item.quantity);
    }
    return item;
};

export const calculateTotal = (items: { quantity: number; unitPrice: number }[]) => {
    let sum = 0;
    items.forEach((element) => {
        sum += element.quantity * element.unitPrice;
    });
    return sum;
};

export const calculateTotalManualDiscount = (items: { quantity: number; unitPrice: number; manualPercentageDiscount: number }[]) => {
    let sum = 0;
    items.forEach((element) => {
        let value = element.quantity * element.unitPrice;
        sum += (value * element.manualPercentageDiscount) / 100;
    });
    return sum;
};

export const calculateTotalTax = (items: { totalTax: number; quantity: number }[]) => {
    let sum = 0;
    items.forEach((element) => {
        sum += element.totalTax * element.quantity;
    });
    return sum;
};

export const getTotalValueTipDiscount = (amountItems: number, totalTax: number, beforeTax: boolean, percentage: number) => {
    let base = amountItems;
    if (beforeTax) {
        base = amountItems - totalTax;
    }
    return base * (percentage / 100);
};

export const calculateOrderTotalManualDiscount = (order: Order | IPurchase) => {
    let sum = 0;
    let items = order.items;
    items.forEach((element: OrderItem | IPurchaseItem) => {
        let baseDiscount = 0;
        if (order.taxIncluded) {
            baseDiscount = order.preTaxDiscount ? element.basePrice : element.unitPrice;
        } else {
            baseDiscount = order.preTaxDiscount ? element.unitPrice : element.basePrice;
        }
        let value = element.quantity * baseDiscount;
        sum += (value * element.manualPercentageDiscount) / 100;
    });
    return sum;
};

export const checkDeliveryOrder = (value: IDeliveryOrder) => {
    if (!value.address || !value.contactName || !value.phoneNumber) {
        return false;
    }
    return true;
};

export const updateOrderItemsTotals = (order: Order, defaultPrice?: ProductPriceTypes) => {
    let items: OrderItem[] = [];
    //let defaultPrice = order.defaultPrice;

    order.items.forEach((element, index) => {
        let product = element.productVariant ?? element.product;

        if (product && defaultPrice) {
            element.unitPrice = product ? product[defaultPrice] : 0;
            element.defaultPrice = defaultPrice;
        }

        element.position = index + 1;
        let basePriceResult = calculateItemBasePrice(element, element.taxes, true, false);
        element.basePrice = basePriceResult.basePrice;
        element.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;
        let totalTax = getItemTotalTax(element.taxes, element, true, false);
        element.totalTax = totalTax;
        element = getOrderItemTotals(element);
        items.push(element);
    });

    return items;
};

export const getTotalItemsQuantityCounter = (items?: OrderItem[]) => {
    if (!items) return 0;
    let value = 0;
    items.forEach((element) => {
        value += element.quantity;
    });
    return value;
};

interface ClearPaymentResult {
    payments: IOrderPayment[];
    amountChange: number;
}

export const getClearOrderPayments = (order: Order): ClearPaymentResult => {
    if (order.payments && order.payments.length > 0) {
        const totalPayments = getTotalPayments(order.payments);
        const absoluteAmount = order.totalAmount;
        const differencePrices = totalPayments - absoluteAmount;

        if (differencePrices > 0 && order.payments && order.payments.length === 1) {
            let payment = { ...order.payments[0] };
            payment.amount = order.totalAmount;
            return {
                amountChange: payment.amount,
                payments: [payment],
            };
        }
        return {
            payments: order.payments ?? [],
            amountChange: 0,
        };
    }
    return {
        payments: [],
        amountChange: 0,
    };
};
