import { BillingConfigData } from "../../components/Account/Billing/billingConfigDataModel";
import { default as fetchApi, default as wahioFetchApi } from "../fetchApi";
import { IPaginationResponse } from "../models";
import { AccountBillingSearchModel, BillingInvoice, BillingInvoicePayment } from "../models/accountBilling";
import {
    Account,
    AccountAddNewLicensesInput,
    AccountBillingNextDateInput,
    AccountBillingNextDateResponse,
    AccountCancelled,
    AccountCreateContractInput,
    AccountCreateContractResult,
    AccountInvoiceResponse,
    AccountPaymentMethod,
    AccountRecoverRequestInput,
    AccountUpdatePlanInput,
    BillingDueInvoices,
    IAuthObjectBody,
    ISignData,
    ISocialPayload,
} from "../models/accountUser";
import { IAccountPermission } from "../models/accountUserPermissions";
import { accountEndpoint, loginEndpoint } from "../restApiEndpoint";
import { AccountApi, AssociatedAccount } from "./../models/accountUser";

export const updateAccount = (user: Account): Promise<Account> => {
    return wahioFetchApi.put(accountEndpoint.put.updateAccount, user).then((res: any) => res.data);
};

export const createAccountCancellation = (data: AccountCancelled): Promise<AccountCancelled> => {
    return wahioFetchApi.post(accountEndpoint.post.accountCancellation, data).then((res: any) => res.data);
};

export const recoverCanceledAccount = (data: AccountRecoverRequestInput): Promise<Account> => {
    return wahioFetchApi.post(accountEndpoint.post.recoverCanceledAccount, data).then((res: any) => res.data);
};

export const getAccountPermissions = (accountId: string): Promise<IAccountPermission[]> => {
    return wahioFetchApi.get(accountEndpoint.get.accountPermissions(accountId)).then((res: any) => res.data);
};

export const createInitialAccountContract = (data: AccountCreateContractInput): Promise<AccountCreateContractResult> => {
    return wahioFetchApi.put(accountEndpoint.put.createInitialContract, data).then((res: any) => res.data);
};

export const createAccountPaymentMethod = (data: AccountPaymentMethod): Promise<AccountPaymentMethod> => {
    return wahioFetchApi.post(accountEndpoint.post.accountPaymentMethod, data).then((res: any) => res.data);
};

export const deleteAccountPaymentMethod = (id: string): Promise<AccountPaymentMethod> => {
    return wahioFetchApi.delete(accountEndpoint.delete.accountPaymentMethod(id)).then((res: any) => res.data);
};

export const getAccountBillingInvoices = (data: AccountBillingSearchModel): Promise<IPaginationResponse<BillingInvoice>> => {
    return wahioFetchApi.post(accountEndpoint.post.billingInvoiceSearch, data).then((res: any) => res.data);
};

export const getAccountBillingNextDate = (accountId: string): Promise<AccountBillingNextDateResponse> => {
    return wahioFetchApi.get(accountEndpoint.get.accountBillingNextDate(accountId)).then((res: any) => res.data);
};
export const getAccountBillingNextDatePlanChange = (data: AccountBillingNextDateInput): Promise<AccountBillingNextDateResponse> => {
    return wahioFetchApi
        .get(accountEndpoint.get.accountBillingNextDatePlanChange(data.accountId, data.newCostPerUser))
        .then((res: any) => res.data);
};

export const getAccountBillingDueInvoices = (accountId: string): Promise<BillingDueInvoices> => {
    return wahioFetchApi.get(accountEndpoint.get.accountBillingDueInvoices(accountId)).then((res: any) => res.data);
};

export const createBillingInvoicePayment = (data: BillingInvoicePayment): Promise<BillingInvoice> => {
    return wahioFetchApi.post(accountEndpoint.post.billingInvoicePayment, data).then((res: any) => res.data);
};

export const getBillingConfigData = (): Promise<BillingConfigData> => {
    return wahioFetchApi.get(accountEndpoint.get.billingConfigData).then((res: any) => res.data);
};

export const putAccountUpdatePlan = (data: AccountUpdatePlanInput): Promise<AccountInvoiceResponse> => {
    return wahioFetchApi.put(accountEndpoint.put.updatePlan, data).then((res: any) => res.data);
};

export const accountAddNewLicenses = (data: AccountAddNewLicensesInput): Promise<AccountInvoiceResponse> => {
    return wahioFetchApi.put(accountEndpoint.put.addNewLicenses, data).then((res: any) => res.data);
};

export const createAssociatedAccount = (data: AssociatedAccount): Promise<AssociatedAccount> => {
    return wahioFetchApi.post(accountEndpoint.post.createAssociatedAccount, data).then((res: any) => res.data);
};

export const getAssociatedAccountsByAccount = (id: string): Promise<AssociatedAccount[]> => {
    return wahioFetchApi.get(accountEndpoint.get.getAssociatedAccountsByAccount(id)).then((res: any) => res.data);
};

export const deleteAssociatedAccount = (id: string): Promise<AssociatedAccount[]> => {
    return wahioFetchApi.delete(accountEndpoint.delete.associatedAccount(id)).then((res: any) => res.data);
};

export const wahioGetApiKey = (accountId: string): Promise<AccountApi> => {
    return wahioFetchApi.get(accountEndpoint.get.accountApiKeyByAccount(accountId)).then((res: any) => res.data);
};

export const socialLogin = (data: ISocialPayload): Promise<IAuthObjectBody> => {
    return fetchApi.post(loginEndpoint.socialLogin, data).then((res: any) => res.data);
};
export const loginApi = (data: ISignData): Promise<IAuthObjectBody> => {
    return fetchApi.post(loginEndpoint.login, data).then((res: any) => res.data);
};
