import React from "react";
import { useIsMobileListener } from "..";
import { IPaginationList } from "../../api/models";
import LoadingProgressBar from "../Loadings/LoadingProgressBar";
import PaginationView from "../PaginationView";
import MobileButton from "../_controls/buttons/MobileButton";
import { Flex, Title } from "../_controls/containers";
import SearchInputTimer from "../_controls/inputs/SearchInputTimer";
import { SearchInputContainerFilter, SearchTabFilterStyle } from "./styled";

export interface SearchTabFilterProps {
    placeholder: string;
    searchValue?: string;
    setSearchValue: (value: string) => void;
    loading: boolean;
    onPageChange?: (value: number) => void;
    pagination?: IPaginationList;
    btnTitle?: string;
    btnIcon?: string;
    onClickBtn?: () => void;
    title?: string;
    btnTourName?: string;
    btnClassName?: string;
    customSearch?: React.ReactNode;
    rowComponent?: React.ReactNode;
    children?: React.ReactNode;
    hideTitleMobile?: boolean;
    outsideComp?: React.ReactNode;
    titleComponent?: React.ReactNode;
    usePlusIcon?: boolean;
    searchFilterComponent?: React.ReactNode;
}

export default function SearchTabFilter(props: SearchTabFilterProps) {
    const { setSearchValue } = props;

    const isMobile = useIsMobileListener({ size: 700 });

    const btnIcon = props.btnIcon ?? props.usePlusIcon ? "fa-regular fa-circle-plus" : undefined;

    return (
        <SearchTabFilterStyle>
            {props.loading && <LoadingProgressBar />}
            {props.titleComponent}
            {props.title && <Title className={props.hideTitleMobile ? "hide-title-mb" : ""}>{props.title}</Title>}
            <Flex gap10 column>
                <Flex gap10 flexWrap={!isMobile} alignCenter w100>
                    {props.customSearch}
                    {!props.customSearch && (
                        <SearchInputContainerFilter>
                            <SearchInputTimer
                                className="search-input-filter"
                                initialValue={props.searchValue}
                                milliseconds={500}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder={props.placeholder}
                            />
                            {props.searchFilterComponent && <div className="search-filter-component">{props.searchFilterComponent}</div>}
                        </SearchInputContainerFilter>
                    )}
                    {!!props.onClickBtn && props.btnTitle && (
                        <MobileButton
                            tourName={props.btnTourName}
                            className={`${props.btnClassName ?? ""} search-tab-button`}
                            title={props.btnTitle}
                            icon={btnIcon}
                            onClick={props.onClickBtn && props.onClickBtn}
                            rounded={true}
                        />
                    )}
                    <Flex flexWrap={!isMobile} gap10 alignCenter>
                        {!isMobile && props.outsideComp}
                        {props.children}
                    </Flex>

                    {!isMobile && props.pagination && props.onPageChange && (
                        <PaginationView className="ml-auto" onPageChange={props.onPageChange} pagination={props.pagination} />
                    )}
                </Flex>
                {isMobile && props.pagination && props.onPageChange && (
                    <PaginationView className="ml-auto" onPageChange={props.onPageChange} pagination={props.pagination} />
                )}
                {props.rowComponent}
            </Flex>
        </SearchTabFilterStyle>
    );
}
