import React, { createContext, useState } from "react";
import { IPaginationResponse } from "../../api/models";
import { WahioCloudFolder, WahioCloudItem } from "../../api/models/wahioCloud";

export interface CloudState {
    pagination?: IPaginationResponse<WahioCloudItem>;
    paginationFolder?: IPaginationResponse<WahioCloudFolder>;
    searchValue?: string;
}

interface CloudContextProps {
    cloudState: CloudState;
    setCloudState: (value: CloudState) => void;
    addNewWahioCloudItem: (value: WahioCloudItem) => void;
}

export const CloudContext = createContext({} as CloudContextProps);

const CloudContextProvider = (props: any) => {
    const [cloudState, setCloudState] = useState<CloudState>({});

    const addNewWahioCloudItem = (value: WahioCloudItem) => {
        const items = cloudState.pagination?.items ?? []
        const newItems = [...items, value]
        if (cloudState.pagination) {
            setCloudState({
                ...cloudState, pagination: {
                    ...cloudState.pagination,
                    items: newItems
                }
            })
        }
    }

    return (
        <CloudContext.Provider
            value={{
                cloudState,
                setCloudState,
                addNewWahioCloudItem
            }}
        >
            {props.children}
        </CloudContext.Provider>
    );
};

export const useCloudContext = () => {
    return React.useContext(CloudContext);
};

export default CloudContextProvider;
