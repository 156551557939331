import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { wahioFetch } from "../../../api";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import { IOrderChannel, OrderChannel } from "../../../api/models/orderChannel";
import { DefaultButton, DefaultInput, PrimaryButton } from "../../_controls";
import LoadingDualRing from "../../LoadingDualRing";
import messages from "./messages";
import { ButtonsContainer, FormFieldsContainer } from "../../_controls/formStyled";
import { getAuthBody } from "../../../auth";
import { OrderChannelContext } from "../../../store/contexts/OrderChannelContext";
import { InputGroup } from "./styled";
import { useAlert } from "../../Alerts/Alert";
import { capitalize } from "../../../utils";

export interface OrderChannelFormProps {
    onCreate: (value: IOrderChannel) => void;
    onCancel: () => void;
    orderChannelUpdate?: IOrderChannel;
    channelName?: string;
}

const getOrderChannelLoaded = (name?: string) => {
    const auth = getAuthBody();
    let orderChannel = new OrderChannel(auth.accountId);
    if (name) {
        orderChannel.name = name;
    }
    return orderChannel;
};

export default function OrderChannelForm(props: OrderChannelFormProps) {
    const intl = useIntl();
    const { orderChannelState, orderChannelActions } = useContext(OrderChannelContext);
    const alert = useAlert();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentOrderChannel, setCurrentOrderChannel] = useState(
        props.orderChannelUpdate ? props.orderChannelUpdate : getOrderChannelLoaded(props.channelName)
    );

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (currentOrderChannel.name.length === 0) return;

        setIsSubmitting(true);

        let channel = currentOrderChannel;
        channel.name = capitalize(channel.name);

        wahioFetch.post(
            localOrderEndpoint.post.orderChannel,
            { ...channel },
            (success) => {
                setIsSubmitting(false);
                const resultOrderChannel: IOrderChannel = success.data;

                alert.success(currentOrderChannel.id ? "El canal fue actualizado con éxito" : "El canal fue creado con éxito");
                if (currentOrderChannel.id) {
                    orderChannelActions.update(resultOrderChannel);
                } else {
                    orderChannelActions.receive([...orderChannelState.items, resultOrderChannel]);
                }
                setCurrentOrderChannel(getOrderChannelLoaded());
                props.onCreate(resultOrderChannel);
            },
            (error) => {
                setIsSubmitting(false);
                alert.success("Hubo un error al intentar crear el canal");
            }
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentOrderChannel({ ...currentOrderChannel, [e.target.name]: e.target.value });
    };

    return (
        <FormFieldsContainer onSubmit={onSubmit}>
            <InputGroup bottomSpace={true}>
                <label htmlFor="name">{intl.formatMessage(messages.name)}</label>
                <DefaultInput
                    name="name"
                    value={currentOrderChannel.name}
                    onChange={handleChange}
                    type="text"
                    placeholder={intl.formatMessage(messages.name)}
                />
            </InputGroup>

            {!isSubmitting && (
                <ButtonsContainer>
                    <DefaultButton type="button" onClick={() => props.onCancel()}>
                        Cancelar
                    </DefaultButton>

                    <PrimaryButton type="submit">Guardar</PrimaryButton>
                </ButtonsContainer>
            )}
            {isSubmitting && <LoadingDualRing center />}
        </FormFieldsContainer>
    );
}
