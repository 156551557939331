import fetchApi, { getFetchError } from "./fetchApi";

export const getServiceErrorMessageObject = (error: any) => {
    if (error.data && error.data.message && isObject(error.data.message)) {
        const ArrayMessageError = JSON.parse(error.data.message);
        return ArrayMessageError.map((x: any) => x.message);
    } else {
        getServiceMessageError(error);
    }
};

const isObject = (message: string) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let obj = JSON.parse(message);
        return true;
    } catch (error) {
        return false;
    }
};
export const getServiceMessageError = (error: any) => {
    if (!error) return "Hubo un error desconocido";
    if (error.response) {
        error = getFetchError(error);
    }

    if (error.data && error.data.message) {
        return error.data.message;
    }
    const list: any[] = error.data;

    try {
        if (list && list.length > 0 && typeof list[0] === "string" && typeof list.map === "function") {
            return list.join(", ");
        }

        if (list && list.length > 0 && typeof list.map === "function") {
            const values = list.map((item: any) => (item.message ? item.message : `Error Desconocido`));
            return values.join(", ");
        }

        if (typeof error.data === "string") {
            return error.data;
        }
    } catch (e) {
        console.log(e);
    }
    return "Hubo un error desconocido";
};

export const wahioFetch = {
    get: (url: string, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .get(url)
            .then((res: any) => onSuccess(res))
            .catch((error: any) => {
                onError(getFetchError(error));
            });
    },
    put: (url: string, data: any, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .put(url, data)
            .then((response: any) => onSuccess(response))
            .catch((error: any) => onError(getFetchError(error)));
    },
    post: (url: string, data: any, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .post(url, data)
            .then((response: any) => onSuccess(response))
            .catch((error: any) => onError(getFetchError(error)));
    },
    delete: (url: string, onSuccess: (value: any) => void, onError: (value: any) => void) => {
        return fetchApi
            .delete(url)
            .then((res: any) => onSuccess(res))
            .catch((error: any) => onError(getFetchError(error)));
    },
};

export const handleDownloadExcel = (url: string, fileName: string, onFinish: () => void) => {
    fetchApi({
        url: url, //your url
        method: "GET",
        responseType: "blob", // important
    }).then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        onFinish();
    });
};

export const handleDownloadObject = (object: any, fileName: string, onFinish: () => void) => {
    const url = window.URL.createObjectURL(new Blob([object]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    onFinish();
};
