import styled from "styled-components";

export const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    ${(props: { maxWidth?: boolean }) => (props.maxWidth ? "max-width: 240px;" : "")}

    .progress-title {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .bg-container {
        background: ${(props) => props.theme.palette.primary}20;
        border-radius: 20px;
        overflow: hidden;
    }
    .bg-green {
        background: ${(props) => props.theme.palette.primary};
    }

    .bg-danger {
        background: ${(props) => props.theme.palette.dangerColor};
    }

    &.horizontal {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 10px;
        .bg-container {
            width: 50px;
        }
    }
    &.w100 {
        width: 100%;
    }
`;
