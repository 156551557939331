import { defineMessages } from "react-intl";

const base = "components.profileSettingsMenu";

export default defineMessages({
    changeTheme: {
        id: `${base}.changeTheme`,
        defaultMessage: "Cambiar Tema",
    },
    personalize: {
        id: `${base}.personalize`,
        defaultMessage: "Personalizar",
    },
    useDeviceTheme: {
        id: `${base}.useDeviceTheme`,
        defaultMessage: "Tema Dispositivo",
    },
    switchTo: {
        id: `${base}.switchTo`,
        defaultMessage: "Cambiar a",
    },
    english: {
        id: `${base}.english`,
        defaultMessage: "Inglés",
    },
    spanish: {
        id: `${base}.spanish`,
        defaultMessage: "Español",
    },
    helpCenter: {
        id: `${base}.helpCenter`,
        defaultMessage: "Centro de Ayuda",
    },
    signOut: {
        id: `${base}.signOut`,
        defaultMessage: "Cerrar Sesión",
    },
    settings: {
        id: `${base}.settings`,
        defaultMessage: "Configuración",
    },
    refreshDevice: {
        id: `${base}.refreshDevice`,
        defaultMessage: "Refrescar Dispositivo",
    },
});
