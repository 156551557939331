import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { IProduct } from "../../../api/models/product";
import { createUpdateProduct } from "../../../api/products/productsApi";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import ProductVariantsForm from "../ProductCreationForm/ProductVariantsForm";

export default function NavigationVariants(props: ProductNavigationProfileProps) {
    const [product, setProduct] = useState(props.product);

    const [hasChanges, setHasChanges] = useState(false);

    const updateMutation = useMutation((data: IProduct) => createUpdateProduct(data), {
        onSuccess: (data) => {
            setHasChanges(false);
            props.onUpdateProduct(data);
        },
    });

    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    const onSaveChanges = () => {
        updateMutation.mutate(product);
    };

    const onChange = (product: IProduct) => {
        setProduct(product);
        setHasChanges(true);
    };

    return (
        <NavigationCard
            title="Variantes"
            actionsComponent={<NavigationActions disabled={!hasChanges} isLoading={updateMutation.isLoading} onSave={onSaveChanges} />}
        >
            <ProductVariantsForm product={product} onChange={onChange} />
        </NavigationCard>
    );
}
