import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { IProduct, ProductPartialUpdateData, ProductPartialUpdateInput } from "../../../api/models/product";
import { productPartialUpdate } from "../../../api/products/productsApi";
import { useAlert } from "../../Alerts/Alert";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import { ProductExtraDetailsForm } from "../ProductCreationForm/MoreDetailsForm";

export default function NavigationExtraDetails(props: ProductNavigationProfileProps) {
    const [product, setProduct] = useState(props.product);

    const [hasChanges, setHasChanges] = useState(false);
    const alert = useAlert();

    const saveProductMutation = useMutation((body: ProductPartialUpdateData) => productPartialUpdate(body), {
        onSuccess: (data) => {
            alert.success("Los precios se han actualizado correctamente");
            setHasChanges(false);
            props.onUpdateProduct(product);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    const handleSaveChanges = () => {
        let data: ProductPartialUpdateInput = {
            reference: product.reference,
            referenceCodes: product.referenceCodes,
            measurement: product.measurement,
            itemWeight: product.itemWeight,
            itemWidth: product.itemWidth,
            itemLength: product.itemLength,
            itemHeight: product.itemHeight,
        };

        let updateData: ProductPartialUpdateData = {
            id: product.id,
            isVariant: false,
            data: data,
        };
        saveProductMutation.mutate(updateData);
    };

    const onDetailsChange = (product: IProduct) => {
        setProduct(product);
        setHasChanges(true);
    };

    if (props.productVariant) return null;

    return (
        <NavigationCard
            title="Detalles"
            actionsComponent={
                <NavigationActions isLoading={saveProductMutation.isLoading} disabled={!hasChanges} onSave={handleSaveChanges} />
            }
        >
            <ProductExtraDetailsForm product={product} onChange={onDetailsChange} />
        </NavigationCard>
    );
}
