import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { chatCreateGroupMessage, messagesGroupQuerySearch } from "../../../api/chats/chatApi";
import { useWahioSocket } from "../../../api/socket";
import { useSocketOnRoomChange } from "../../../api/socket/sockedManager";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { messageAudio } from "../../../pages/AppContainer";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { GroupChat, useChatContext } from "../../../store/contexts/ChatContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import { getModelFullName } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import { MessageGroup, MessageGroupSearchInput } from "../models";
import ChatConversationBase, { ChatContent, ChatContentPosition } from "./ChatConversationBase";

interface ConversationCardProps {
    item: GroupChat;
}

export default function ChatMessageGroupCard(props: ConversationCardProps) {
    const { chatState, setChatState, removeGroupToChat } = useChatContext();
    const { userState } = useUserContext();
    const { appSettingsState } = useContext(AppSettingsContext);
    const accountUserContext = useAccountUserContext();
    const wahioSocket = useWahioSocket();
    const alert = useAlert();

    const [chatItems, setChatItems] = useState<MessageGroup[]>([]);
    const chatItemsRef = useRef<MessageGroup[]>([]);

    const [messageQuerySearch] = useState<MessageGroupSearchInput>({
        group: props.item.group,
        userId: userState.user?.id ?? "INVALID",
        pageSize: 50,
        currentPage: 1,
    });

    const handleSearch = () => {
        return messagesGroupQuerySearch(messageQuerySearch);
    };

    const messageQuery = useQuery(`QUERY_KEY-${props.item.group}`, handleSearch, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setChatItems(data.items);
        },
    });

    const createMessageMutation = useMutation((data: MessageGroup) => chatCreateGroupMessage(data), {
        onSuccess: (data) => {},
        onError: (data) => {
            alert.error(`No se puedo enviar el mensaje...`);
        },
    });

    useEffect(() => {
        chatItemsRef.current = chatItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatItems]);

    useSocketOnRoomChange((data: MessageGroup) => {
        if (data && data.group === props.item.group) {
            setChatItems([data, ...chatItemsRef.current]);
            if (!appSettingsState.turnOfNotifications) messageAudio.play();
        }
    });

    useEffect(() => {
        setChatState({ ...chatState, messagesGroupCounter: 0 });
        if (userState.user) {
            wahioSocket.roomSuscribe(wahioSocket.rooms.chatGroup(props.item.group));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSendChatMessage = (text: string) => {
        if (userState.user) {
            let message: MessageGroup = {
                id: "",
                group: props.item.group,
                fromUserId: userState.user.id,
                description: text,
                accountId: getAuthBody().accountId,
                createdAt: customMoment().toISOString(),
            };
            createMessageMutation.mutate(message);
            wahioSocket.sendChatMessageGroup(message);
            setChatItems([message, ...chatItems]);
        }
    };

    return (
        <ChatConversationBase
            headerImage={props.item.image}
            headerTitle={props.item.title}
            onRemoveChat={() => removeGroupToChat(props.item.group)}
            onClickSendMessage={onSendChatMessage}
            isLoading={messageQuery.isLoading}
        >
            {chatItems.map((item, index) => {
                const isActualUser = item.fromUserId === userState.user?.id;

                let position: ChatContentPosition = isActualUser ? "right" : "left";
                let spaceTop = false;
                const itemsObj = accountUserContext.accountUserState.itemsObj;
                let accountUser = !isActualUser && itemsObj ? itemsObj[item.fromUserId] : undefined;

                let hasMoreItems = chatItems.length > index + 2;
                if (!isActualUser && hasMoreItems) {
                    accountUser = chatItems[index + 1].fromUserId === item.fromUserId ? undefined : accountUser;
                }
                if (index > 0) {
                    spaceTop = chatItems[index - 1].fromUserId !== item.fromUserId ? true : false;
                }
                return (
                    <ChatContent
                        key={index}
                        spaceTop={spaceTop}
                        position={position}
                        userName={getModelFullName(accountUser)}
                        userColor={accountUser?.color}
                        description={item.description}
                        createdAt={item.createdAt}
                        hasMoreItems={hasMoreItems}
                        nextCreatedAt={hasMoreItems ? chatItems[index + 1].createdAt : undefined}
                    />
                );
            })}
        </ChatConversationBase>
    );
}
