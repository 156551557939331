import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { getServiceMessageError } from "../../../api";
import { createElectronicBillingFromOrder, getEBillingByOrder } from "../../../api/eBillings/eBillingApi";
import { EBillingDocumentType, ElectronicBilling, ElectronicBillingBodyInput } from "../../../api/models/electronicBilling";
import { Order } from "../../../api/models/order";
import { CloudImages } from "../../../assets/index";
import { getAuthBody } from "../../../auth";
import PATHS from "../../../constants/paths";
import { useOrganizationContext } from "../../../store/contexts/OrganizationContext";
import { getDateFormat } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import EBillingResolutionSelector from "../../ElectronicBillings/EBillingProfile/EBillingResolutionSelector";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationTabs from "../../NavigationTabs";
import { DefaultButton, Flex, FlexImageStyle, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import { useConfirmationModal } from "../../modals/ConfirmationModal";
import PDFViewModal from "../../modals/PDFViewModal";
import NavigationCard from "../../NavigationPanel/NavigationCard";

interface ElectronicBillingOrderProps {
    order: Order;
    onChangeEBilling?: (value: ElectronicBilling) => void;
}

export default function NavigationEBilling(props: ElectronicBillingOrderProps) {
    const [showEBillingPdf, setShowEBillingPdf] = useState(false);
    const [requiredEBilling, setRequiredEBilling] = useState(!props.order.eBillingGenerated);

    const [eBilling, setEBilling] = useState<ElectronicBilling>();
    const alert = useAlert();

    const eBillingMutation = useMutation((orderId: string) => getEBillingByOrder(orderId), {
        onSuccess: (data) => {
            setEBilling(data);
            setRequiredEBilling(false);
            setEBillingDataToOrder(data);
        },
        onError: (err) => {
            setRequiredEBilling(true);
        },
    });

    const eBillingCreateMutation = useMutation((data: ElectronicBillingBodyInput) => createElectronicBillingFromOrder(data), {
        onSuccess: (data) => {
            setEBilling(data);
            setRequiredEBilling(false);
            if (data.is_valid) {
                alert.success("Factura electronica creada con éxito");
                setEBillingDataToOrder(data);
            } else {
                alert.info("La factura fue creada con errores");
            }
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const setEBillingDataToOrder = (data: ElectronicBilling) => {
        if (props.onChangeEBilling) props.onChangeEBilling(data);
    };

    useEffect(() => {
        if (props.order.id && props.order.eBillingGenerated) {
            eBillingMutation.mutate(props.order.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateEBilling = (documentType: EBillingDocumentType, resolutionId?: string) => {
        const data: ElectronicBillingBodyInput = {
            accountUserId: getAuthBody().accountUserId,
            order: props.order,
            documentType: documentType,
            resolutionId,
        };
        eBillingCreateMutation.mutate(data);
    };

    if (!props.order.orderInvoice) {
        return (
            <TextField small light>
                <i className="fa-regular fa-lock"></i> Es necesario facturar la orden para generar la factura electronica
            </TextField>
        );
    }

    if (eBillingMutation.isLoading) {
        return <LoadingDualRing center />;
    }

    return (
        <NavigationCard>
            {eBilling && (
                <>
                    {showEBillingPdf && (
                        <PDFViewModal base64={eBilling?.pdf_base64_bytes} show={showEBillingPdf} setShow={setShowEBillingPdf} />
                    )}

                    <Table cellPadding={0} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td>Estado</td>
                                <td>{eBilling.is_valid ? "Valido" : "Invalido"}</td>
                            </tr>
                            <tr>
                                <td>Fecha</td>
                                <td>{getDateFormat(eBilling.createdAt, "DD MMM YYYY, hh:mm:a")}</td>
                            </tr>
                            <tr>
                                <td>Documento</td>
                                <td>
                                    {eBilling.is_valid ? (
                                        <Flex alignCenter gap10>
                                            <a target="_blank" rel="noopener noreferrer" href={eBilling.qr_link}>
                                                <span className="wahioicon-external-link-square-alt"></span> {eBilling.number}
                                            </a>
                                        </Flex>
                                    ) : (
                                        <span>{eBilling.number}</span>
                                    )}
                                </td>
                            </tr>
                            {eBilling.is_valid && (
                                <tr>
                                    <td>Algorithm</td>
                                    <td>{eBilling.algorithm}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Descripción</td>
                                <td>{eBilling.status_message}</td>
                            </tr>
                            {!eBilling.is_valid && eBilling.errors_messages && eBilling.errors_messages.length > 0 && (
                                <tr>
                                    <td>Errores</td>
                                    <td>
                                        <Flex column gap5>
                                            {eBilling.errors_messages.map((err) => (
                                                <span className="text-small">{err}</span>
                                            ))}
                                        </Flex>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {eBilling.is_valid && (
                        <div className="mt-2">
                            <Flex
                                pointer
                                fitContent
                                onClick={() => setShowEBillingPdf(true)}
                                alignCenter
                                gap10
                                borderRadius={15}
                                border
                                padding={10}
                            >
                                <FlexImageStyle width={25}>
                                    <img src={CloudImages.pdf} alt="" />
                                </FlexImageStyle>
                                <Flex>
                                    <span className="text-light text-small text-bold">{eBilling.number}.pdf</span>
                                </Flex>
                            </Flex>
                        </div>
                    )}
                </>
            )}

            {(!eBilling?.is_valid || requiredEBilling) && (
                <EBillingTabs handleCreateEBilling={handleCreateEBilling} isLoading={eBillingCreateMutation.isLoading} />
            )}
        </NavigationCard>
    );
}

enum Tabs {
    order,
    document,
}

interface EBillingTabsProps {
    handleCreateEBilling: (documentType: EBillingDocumentType, resolutionId?: string) => void;
    isLoading: boolean;
}

const EBillingTabs = (props: EBillingTabsProps) => {
    const { handleCreateEBilling, isLoading } = props;

    const {
        organizationState: { currentOrganization },
    } = useOrganizationContext();

    const [selectedResolution, setSelectedResolution] = useState({
        order: currentOrganization?.defaultEBillingOptions?.resolutionOrderId ?? "",
        document: currentOrganization?.defaultEBillingOptions?.resolutionDocumentSupportId ?? "",
    });
    const ConfirmationInvoiceModal = useConfirmationModal();
    const ConfirmationSupportModal = useConfirmationModal();

    return (
        <>
            <ConfirmationInvoiceModal.Modal
                title="¿Estas seguro?"
                description="Para generar la factura electronica el cliente debe tener lo siguientes datos: Nombre, Tipo de identificación,
                numero de identificación, Correo electrónico"
                onOkay={() => handleCreateEBilling("invoice", selectedResolution.order)}
            />
            <ConfirmationSupportModal.Modal
                title="¿Estas seguro?"
                description="Para generar el documento soporte el cliente debe tener lo siguientes datos: Nombre, Tipo de identificación,
                numero de identificación, Correo electrónico"
                onOkay={() => handleCreateEBilling("documentSupport", selectedResolution.document)}
            />
            <Flex column gap={20}>
                <NavigationTabs
                    hideBorder
                    hidePadding
                    defaultKey={Tabs.order}
                    options={[
                        {
                            key: Tabs.order,
                            title: "Factura De Venta",
                            component: (
                                <Flex flexWrap gap={20} alignCenter>
                                    <DefaultButton disabled={isLoading} onClick={() => ConfirmationInvoiceModal.show()} rounded>
                                        <span className="wahioicon-signature"></span>
                                        Generar Factura Electronica {isLoading && <LoadingDualRing small />}
                                    </DefaultButton>
                                    <EBillingResolutionSelector
                                        type={"order"}
                                        selectedCompanyResolutionId={selectedResolution.order}
                                        onSelectedCompanyResolution={(value) =>
                                            setSelectedResolution({ ...selectedResolution, order: value.id })
                                        }
                                        title={""}
                                    />
                                </Flex>
                            ),
                        },
                        {
                            key: Tabs.document,
                            title: "Documento Soporte",
                            component: (
                                <Flex column gap={20}>
                                    <EBillingResolutionSelector
                                        type={"documentSupport"}
                                        selectedCompanyResolutionId={selectedResolution.document}
                                        onSelectedCompanyResolution={(value) =>
                                            setSelectedResolution({ ...selectedResolution, document: value.id })
                                        }
                                        title={"Resolución de Documento Soporte"}
                                    />
                                    <DefaultButton disabled={isLoading} onClick={() => ConfirmationSupportModal.show()} rounded>
                                        <span className="wahioicon-signature"></span>
                                        Documento Soporte {isLoading && <LoadingDualRing small />}
                                    </DefaultButton>
                                </Flex>
                            ),
                        },
                    ]}
                    onChange={(value) => {}}
                />

                <Flex gap5 flexWrap alignCenter className="mt-1">
                    <NavLink className="text-small text-light" to={PATHS.configEBilling}>
                        <span className="wahioicon-cog"></span> <span>Configurar facturación electronica</span>
                    </NavLink>
                </Flex>
            </Flex>
        </>
    );
};
