import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { TableCustomTr } from "../_controls/tables/styled";

export const StoresContainer = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: 1fr;
`;

export const StoresMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;

    .store-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;
        margin-top: 16px;

        .title {
            font-weight: 300;
            text-align: center;
        }

        .image {
            width: 95px;
            height: 95px;
            background: ${(props) => props.theme.palette.background};
            min-width: 95px;
            border-radius: 60px;
            margin-right: 20px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            font-weight: 500;
            color: ${(props) => props.theme.palette.textLight};
            box-shadow: 1px 1px 5px ${(props) => props.theme.palette.shadowColor};
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .sub-container {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-auto-rows: 1fr;
    }
`;

export const FlexTopView = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    background: ${(props) => props.theme.palette.background};
    z-index: 2;
`;

export const StoreSelectMainView = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 130px;
    padding: 20px;
    cursor: pointer;
    background: ${(props) => props.theme.palette.background};
    border-radius: 12px;
    color: ${(props) => props.theme.palette.text};
    border: 0px solid ${(props) => props.theme.palette.backgroundDark};
    box-shadow: 1px 1px 7px ${(props) => props.theme.palette.shadowColor};
    justify-content: space-between;
    .header {
        display: flex;
        align-items: center;
    }

    .image {
        width: 45px;
        height: 45px;
        min-width: 45px;
        border-radius: 8px;
        margin-right: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .user-container {
        display: flex;
        overflow: hidden;

        .user-card-container {
            width: 35px;
        }
        .user-card {
            width: 40px;
            height: 40px;
            border-radius: 60px;
            overflow: hidden;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 3px solid ${(props) => props.theme.palette.background};
            background: ${(props) => props.theme.palette.backgroundDark};
            color: ${(props) => props.theme.palette.textLight};
            font-weight: 500;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .content {
        display: flex;
        flex-direction: column;
    }
    .title {
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
`;

export const StoreCardView = styled(NavLink)`
    display: flex;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    color: ${(props) => props.theme.palette.text};
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    &:visited {
        color: ${(props) => props.theme.palette.text};
    }
    &:hover {
        transition: 0.5s all;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    .image {
        width: 45px;
        height: 45px;
        min-width: 45px;
        border-radius: 8px;
        margin-right: 10px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .titles {
        overflow: hidden;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
`;

export const ProfileContainer = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    padding: 18px 25px;
    padding-bottom: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card-title {
        margin: 0px;
        font-weight: 500;
        font-size: 15px;
    }

    .background-hidde {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .sub-title {
        font-weight: 400;
        margin: 0px;
        margin-top: 10px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .title-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
            font-weight: 500;
            margin: 0px;
            padding: 0px;
        }
    }

    &.mb-2 {
        margin-bottom: 50px;
    }
`;

export const StoreUserContainer = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    padding: 10px 25px;
    padding-bottom: 24px;

    .text-light {
        color: ${(props) => props.theme.palette.textLight};
        margin: 0px;
        font-size: 13px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        margin: 0px;
    }
`;

export const StoreTableTr = styled(TableCustomTr)`
    :last-child {
        td {
            border-bottom: 0;
        }
    }
    .icon-remove {
        background: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
    .image {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .col {
        display: flex;
        flex-direction: column;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
`;

export const CashRegisterToggleContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 7px;
    justify-content: space-between;
`;
export const EcommerceHomeCategoryRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .image {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .ml-auto {
        margin-left: auto;
    }
`;

export const EcommerceHomeBannerRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .image {
        width: 100%;
        height: 120px;
        border-radius: 10px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .ml-auto {
        margin-left: auto;
    }
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .label {
        margin-bottom: 3px;
        font-size: 13px;
    }
`;

export const AllStoresLink = styled(NavLink)`
    color: ${(props) => props.theme.palette.textLight} !important;
    margin-top: 6px;
    display: block;
`;

export const SuccessContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StoreCashRegisterContainer = styled.div`
    padding: 10px 25px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    .title {
        margin: 0px;
    }
`;
export const StoreSaveHeader = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
    position: sticky;
    top: 0px;
    padding: 10px 5px;
    background: ${(props) => props.theme.palette.background};
    z-index: 1;
`;
export const CashRegisterCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    padding: 18px 25px;
    max-width: 400px;
    max-height: 400px;
    height: fit-content;
`;

export const SocialMediaContainer = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;

    .active {
        background: ${(props) => props.theme.palette.primary}20;
    }

    &:hover {
        background: ${(props) => props.theme.palette.primary}20;
    }
`;

export const BannerTrackingGridView = styled.div`
    grid-gap: 15px;
    display: grid;
    flex-direction: row;
    grid-template-columns: inherit;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
`;
