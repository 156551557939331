import styled from "styled-components";

export const ComposeIconContainer = styled.div`
    position: absolute;
    background: ${(props) => props.theme.palette.background};
    padding: 5px;

    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const ActiveQuantity = styled.div`
    position: absolute;
    background: #2962ff;
    color: white;
    padding: 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    right: 10px;
    top: 10px;
    font-weight: 500;
    min-width: 11px;
    z-index: 1;
    .active-icon {
        margin-right: 5px;
    }
`;

export const ProductCardFakeContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    .fake-image {
        background: ${(props) => props.theme.palette.backgroundDark};
        width: 100%;
        aspect-ratio: 1;
        animation: background-animation 1s ease infinite;
        border-radius: 15px;
    }

    .fake-name {
        width: 60%;
        height: 10px;
        background: ${(props) => props.theme.palette.backgroundDark};
        margin-left: 10px;
    }
    .fake-price {
        width: 30%;
        height: 10px;
        background: ${(props) => props.theme.palette.backgroundDark};
        margin-left: 10px;
    }
    &.row {
        flex-direction: row;
        padding-left: 20px;
        .fake-name {
            width: 90%;
        }
        .fake-image {
            width: 45px;
            border-radius: 8px;
        }
    }

    @keyframes background-animation {
        0% {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
        50% {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        100% {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const CardContainerInfo = styled.div`
    width: 100px;
    height: 100px;
    background: red;
    visibility: hidden;
    position: fixed;
    z-index: 99;
`;

export const CardContainer = styled.div`
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: border 0.2s;
    box-sizing: border-box;
    border-radius: 10px;

    .btn-open-product {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        visibility: hidden;
        font-size: 0;
        transition: all 0.2s ease-in-out;
        opacity: 0;
        background: ${(props) => props.theme.palette.background}50;
    }

    .select {
        margin-bottom: 5px;
        background-color: transparent;
        padding: 0px 5px;
    }

    &.active {
        background: ${(props) => props.theme.palette.backgroundDark}80;

        .image-container {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    border-bottom: 3px solid transparent;
    &.key-focus {
        border-color: transparent;
        border-bottom: 3px solid ${(props) => props.theme.palette.primary};
        background: ${(props) => props.theme.palette.primary}20;
    }

    &:hover {
        box-shadow: 1px 1px 8px ${(props) => props.theme.palette.shadowColor};
        border-color: transparent;
        .image-container {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .btn-open-product {
            display: flex;
            font-size: 13px;
            visibility: visible;
            opacity: 1;
        }
    }

    &:focus {
        background: ${(props) => props.theme.palette.primary};
    }
`;

export const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;

    .small-description {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .image-container {
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        background: ${(props) => props.theme.palette.background};
        border-radius: 10px;
        transition: border-radius 0.4s;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        .main {
            z-index: 0;
        }

        .background {
            position: absolute;
            object-fit: cover;
            filter: blur(30px);
            width: 110%;
            height: 110%;
        }
    }
    &.out {
        border-color: transparent;
        opacity: 0.3;
        cursor: default;
        &:hover {
            background: transparent;
            box-shadow: 0px 0px 0px transparent;
            .image {
                background: transparent;
            }
        }
        .title {
            text-decoration: line-through;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 95px;
    overflow: hidden;
    background: ${(props) => props.theme.palette.background};
    border-radius: 5px;
`;

export const CustomImage = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
`;

export const ProductTitle = styled.span`
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.out {
        color: red;
    }
`;

export const ProductPriceContainer = styled.div`
    display: flex;
    flex-direction: column;

    &.direction-row {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .discount-line {
        text-decoration: line-through;
        color: ${(props) => props.theme.palette.textLight};
        font-size: 12px;
    }
    .discount-value {
        background: #4caf50;
        color: white;
        border-radius: 20px;
        padding: 0px 2px;
        font-size: 12px;
        font-weight: 500;
    }
    .discount-name {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        padding: 1px 7px;
        font-size: 13px;
        font-weight: 500;
        width: fit-content;
        border-radius: 15px;
        margin: 5px 0px;
    }
    .product-price {
        font-size: 13px;
        font-weight: 500;
    }

    &.price-large {
        .product-price {
            font-size: 30px;
            font-weight: 600;
        }
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    width: 100%;
    padding: 0px 8px;
    padding-bottom: 3px;
    box-sizing: border-box;
`;
