import styled from "styled-components";

export const FormFieldsContainer = styled.form`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 20px 0px 20px;
    height: auto;

    .subtitle {
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 5px;
        font-size: 13px;
        display: block;
    }
    .passtext {
        margin-bottom: 15px;
        display: block;
        color: ${(props) => props.theme.palette.dangerColor};
    }

    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }

    .expense-input {
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 30px;
    }
    .message-loading {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
        margin-top: 10px;
        text-align: center;
        transition: all 0.3s ease-in-out;

        &.hover {
            color: ${(props) => props.theme.palette.textDark};
            font-size: scale(1.2);
        }
    }
`;

export const FieldsGroup = styled.div`
    display: flex;
    align-items: baseline;
`;

export const ButtonsContainer = styled(FieldsGroup)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 0px;

    button {
        width: 100%;
    }
`;

export const CheckboxFlexView = styled.div`
    display: flex;
    .checkbox {
        margin-right: 20px;
    }
`;

export const AlertContainer = styled.div`
    text-align: center;
    padding: 22px 24px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .icon {
        span {
            font-size: 3rem;
            color: ${(props) => props.theme.palette.text};
        }
    }
    h2 {
        margin: 0px;
        margin-top: 2rem;
    }
    p {
        margin: 0px;
    }
    button {
        margin-top: 2rem;
    }
`;

export const SuccessContainer = styled(AlertContainer)`
    padding: 20px;
    .icon {
        span {
            font-size: 3rem;
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;
export const ErrorContainer = styled(AlertContainer)`
    .icon {
        span {
            font-size: 3rem;
            color: #f44336;
        }
    }
`;
