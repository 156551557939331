import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { OrderInvoiceNumeration } from "../../api/models/orderInvoiceNumeration";
import { orderInvoiceNumerationByAccount } from "../../api/orders/orderApi";
import { getAuthBody } from "../../auth";
import { useTaxContext } from "./TaxContext";

export interface IOrderInvoiceNumerationState {
    items: OrderInvoiceNumeration[];
    isFetching: boolean;
}

export interface IOrderInvoiceNumerationActions {
    request: () => void;
    add: (orderInvoiceNumeration: OrderInvoiceNumeration) => void;
    update: (orderInvoiceNumeration: OrderInvoiceNumeration) => void;
}

interface IContextProps {
    orderInvoiceNumerationState: IOrderInvoiceNumerationState;
    setOrderInvoiceNumerationState: (value: IOrderInvoiceNumerationState) => void;
    orderInvoiceNumerationActions: IOrderInvoiceNumerationActions;
    selectedNumeration?: OrderInvoiceNumeration;
    setSelectedNumeration: (value?: OrderInvoiceNumeration) => void;
}

export const OrderInvoiceNumerationContext = createContext({} as IContextProps);

const OrderInvoiceNumerationContextProvider = (props: any) => {
    const [orderInvoiceNumerationState, setOrderInvoiceNumerationState] = useState<IOrderInvoiceNumerationState>({
        items: [],
        isFetching: false,
    });

    const { taxState, getTaxById } = useTaxContext();

    const [selectedNumeration, setSelectedNumeration] = useState<OrderInvoiceNumeration>();
    const numerationMutation = useMutation((id: string) => orderInvoiceNumerationByAccount(id));

    useEffect(() => {
        if (selectedNumeration && selectedNumeration.defaultTaxId) {
            const tax = getTaxById(selectedNumeration.defaultTaxId);
            if (tax) {
                setSelectedNumeration({ ...selectedNumeration, defaultTax: tax });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxState.items, selectedNumeration]);

    const request = () => {
        setOrderInvoiceNumerationState({ ...orderInvoiceNumerationState, isFetching: true });
        numerationMutation
            .mutateAsync(getAuthBody().accountId)
            .then((items) => {
                setOrderInvoiceNumerationState({ ...orderInvoiceNumerationState, isFetching: false, items });
            })
            .catch((err) => {
                setOrderInvoiceNumerationState({ ...orderInvoiceNumerationState, isFetching: false });
            });
    };
    const add = (value: OrderInvoiceNumeration) => {
        let items = orderInvoiceNumerationState.items;
        items.push(value);
        setOrderInvoiceNumerationState({ ...orderInvoiceNumerationState, items });
    };
    const update = (value: OrderInvoiceNumeration) => {
        let items = orderInvoiceNumerationState.items.map((item) => {
            return item.id === value.id ? value : item;
        });
        setOrderInvoiceNumerationState({ ...orderInvoiceNumerationState, items });
    };

    return (
        <OrderInvoiceNumerationContext.Provider
            value={{
                orderInvoiceNumerationState,
                setOrderInvoiceNumerationState,
                selectedNumeration,
                setSelectedNumeration,
                orderInvoiceNumerationActions: {
                    request,
                    add,
                    update,
                },
            }}
        >
            {props.children}
        </OrderInvoiceNumerationContext.Provider>
    );
};

export const useOrderInvoiceNumerationContext = () => {
    return useContext(OrderInvoiceNumerationContext);
};

export default OrderInvoiceNumerationContextProvider;
