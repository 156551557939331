import React, { createContext, useEffect, useState } from "react";
import { LocalStoreKeys, getStoredData, saveData } from "..";
import { Discount, IDiscountState } from "../../api/models/discount";

interface IContextProps {
    discountState: IDiscountState;
    setDiscountState: (value: IDiscountState) => void;
}

export const DiscountContext = createContext({} as IContextProps);

export class InitStateBase implements IDiscountState {
    items: Discount[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<IDiscountState>(new InitStateBase(), LocalStoreKeys.discount);

const DiscountContextProvider = (props: any) => {
    const [discountState, setDiscountState] = useState(initState);

    useEffect(() => {
        saveData(LocalStoreKeys.discount, discountState);
    }, [discountState]);

    return <DiscountContext.Provider value={{ discountState, setDiscountState }}>{props.children}</DiscountContext.Provider>;
};

export default DiscountContextProvider;
