import React, { useEffect, useState } from "react";
import { IWarehouse } from "../../../api/models/warehouse";
import Modal, { IModalShow } from "../../modals/Modal";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import CardIcon from "../../_Cards/CardIcon";
import { FormFooter, FormGroup } from "../../_controls/forms/styled";
import { NewProductLocationContainer } from "./styled";
import Select from "react-select";
import { getSelectOption } from "../../../utils/select";
import { IWarehouseLocation, IWarehouseProductLocationAdjustmentInput } from "../../../api/models/warehouseLocation";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { useAlert } from "../../Alerts/Alert";
import { DefaultButton, PrimaryButton } from "../../_controls";
import { getColorValue } from "../../../constants/colors";
import ProductCardView from "../../Products/ProductCardView";
import LoadingDualRing from "../../LoadingDualRing";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";

interface NewProductLocationModalProps extends IModalShow {
    warehouse: IWarehouse;
    warehouseProduct: IWarehouseProduct;
    onProductAdded: () => void;
}

export const NewProductLocationModal = (props: NewProductLocationModalProps) => {
    return (
        <Modal
            id="modal-create-mul"
            scrollInside={false}
            overflowAuto={false}
            ignoreOutsideClick
            title={"Agregar producto a una ubicación"}
            showHeader={false}
            {...props}
        >
            <NewProductLocationForm {...props} />
        </Modal>
    );
};

interface NewProductLocationFormState {
    stock: number;
    locationCode?: string;
    location?: IWarehouseLocation;
}

export function NewProductLocationForm(props: NewProductLocationModalProps) {
    const { warehouse, warehouseProduct } = props;
    const { product } = warehouseProduct;
    const alert = useAlert();
    const [warehouseLocations, setWarehouseLocations] = useState<IWarehouseLocation[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [state, setState] = useState<NewProductLocationFormState>({
        stock: 1,
        locationCode: "",
    });

    useEffect(() => {
        wahioFetch.get(
            productEndpoint.get.warehouseLocationsByWarehouse(props.warehouse.id ?? ""),
            (success) => {
                setWarehouseLocations(success.data);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const HandleAddProduct = () => {
        if (!state.locationCode || state.stock <= 0) {
            alert.info(`El ubicación y el stock son requeridos`);
            return;
        }
        setIsFetching(true);

        const body: IWarehouseProductLocationAdjustmentInput = {
            quantity: state.stock,
            locationCode: state.locationCode,
            productId: warehouseProduct.productId,
            productVariantId: warehouseProduct.productVariantId,
            warehouseId: warehouse.id ?? "",
            includedInWarehouse: false,
        };

        wahioFetch.post(
            productEndpoint.post.warehouseProductLocationAddProduct,
            body,
            (success) => {
                alert.success("El ajuste fue creado con éxito.");
                setIsFetching(false);
                props.setShow(false);
                props.onProductAdded();
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setIsFetching(false);
            }
        );
    };

    return (
        <NewProductLocationContainer>
            <div>
                <ProductCardView product={product} productVariant={warehouseProduct.productVariant} />
            </div>
            <div className="mt-2">
                <CardIcon smallTitle="Bodega" title={warehouse.name} />
            </div>
            <div className="body mt-2">
                <div className="body-item mr-1">
                    <FormGroup>
                        <label htmlFor="stock">Stock Inicial</label>
                        <TextBoxNumeral
                            format="number"
                            onNumberChange={(value) => setState({ ...state, stock: value })}
                            value={state.stock}
                        />
                    </FormGroup>
                </div>

                <div className="body-item">
                    <FormGroup>
                        <label htmlFor="stock">Ubicación</label>
                        <div className="flex-line">
                            <SelectBody className="select-body">
                                <Select
                                    placeholder="Seleccionar"
                                    className="select-min-len"
                                    classNamePrefix="select"
                                    key={state.locationCode}
                                    defaultValue={
                                        state.locationCode
                                            ? getSelectOption({ id: state.locationCode, value: state.locationCode })
                                            : undefined
                                    }
                                    isDisabled={false}
                                    onChange={(value) =>
                                        setState({
                                            ...state,
                                            locationCode: value?.id ?? "",
                                            location: warehouseLocations.find((x) => x.code === value?.id),
                                        })
                                    }
                                    isRtl={false}
                                    isSearchable={true}
                                    name="location"
                                    options={warehouseLocations.map((item) =>
                                        getSelectOption({ id: item.code, value: `${item.code} (${item.globalStock})` })
                                    )}
                                />
                            </SelectBody>
                            <div className="location" style={{ background: getColorValue(state.location?.color) }}></div>
                        </div>
                    </FormGroup>
                </div>
            </div>
            <FormFooter>
                {isFetching ? (
                    <LoadingDualRing />
                ) : (
                    <>
                        <DefaultButton onClick={() => props.setShow(false)} className="mr-1" rounded>
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton disabled={state.stock <= 0 || !state.locationCode} onClick={() => HandleAddProduct()} rounded>
                            Guardar Cambios
                        </PrimaryButton>
                    </>
                )}
            </FormFooter>
        </NewProductLocationContainer>
    );
}
