import { defineMessages } from "react-intl";

const base = "components.ecommerce";

export default defineMessages({
    assignWarehouse: {
        id: `${base}.assignWarehouse`,
        defaultMessage: "Asignar bodega"
    },
    createWarehouse:{
        id: `${base}.createWarehouse`,
        defaultMessage: "Crear bodega"
    },
    thisOrganizationDoesNotHaveAssignedWarehouse:{
        id: `${base}.thisOrganizationDoesNotHaveAssignedWarehouse`,
        defaultMessage: "Esta tienda no tiene una bodega asignada"
    },
    youHaveNotCreatedWarehouseYet:{
        id: `${base}.youHaveNotCreatedWarehouseYet`,
        defaultMessage: "Aún no has creado una bodega"
    },
    toManageYourOrdersYouMustAssignOrCreateWarehouse:{
        id: `${base}.toManageYourOrdersYouMustAssignOrCreateWarehouse`,
        defaultMessage: "Para gestionar tus ordenes debes asignar o crear una bodega"
    },
    toManageYourProductsYouMustAssignOrCreateWarehouse:{
        id: `${base}.toManageYourProductsYouMustAssignOrCreateWarehouse`,
        defaultMessage: "Para gestionar tus productos debes asignar o crear una bodega"
    },
    createYourFirstWarehouseAndStartManagingYourOrders:{
        id: `${base}.createYourFirstWarehouseAndStartManagingYourOrders`,
        defaultMessage: "Crea tu primera bodega y empieza a gestionar tus ordenes"
    },
    createYourFirstWarehouseAndStartManagingYourProducts:{
        id: `${base}.createYourFirstWarehouseAndStartManagingYourProducts`,
        defaultMessage: "Crea tu primera bodega y empieza a gestionar tus productos"
    },




});