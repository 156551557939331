import React, { useState } from "react";
import Select from "react-select";
import { SelectBaseOption } from "../../../api/models";
import { ITaxOrderItemBase, convertTaxOrderItem } from "../../../api/models/orderBase";
import { IProductVariant } from "../../../api/models/product";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { ITax } from "../../../api/models/tax";
import { CloudImages } from "../../../assets";
import { formatMoney, ignoreElementClick } from "../../../utils";
import { calculateItemBasePrice, extractTaxInformation, getItemTotalTax } from "../../../utils/taxes";
import { ButtonPercentage, FlexCol, PercentageCard } from "../../Orders/OrderItemRow/styled";
import {
    CardContainer,
    ImageContainer,
    PriceContainer,
    QuantityContainer,
    QuantityContainerBody,
    RowContainer
} from "../../Orders/OrderStyles/RowStyles";
import { getProductSku, getProductSkuAndCode, getProductThumbnailUrl } from "../../Products/helper";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import TextBoxNumberTimer from "../../TextBoxNumeral/TextBoxNumeralTimer";
import { Flex, FlexImageStyle, SquareButton, TextField } from "../../_controls";
import PurchaseItemModal from "./PurchaseItemModal";

const getSelectOption = (item?: IProductVariant) => {
    let option: SelectBaseOption = {
        id: item?.id ?? "",
        value: item?.id ?? "",
        label: item?.valuesLine ?? "",
    };
    return option;
};

export interface PurchaseItemActionsProps {
    purchase: IPurchase;
    onUpdateItem: (item: IPurchaseItem) => void;
    onRemoveItem: (item: IPurchaseItem) => void;
}

interface IPurchaseItemReceive {
    purchaseItem: IPurchaseItem;
    onChange: (item: IPurchaseItem) => void;
}

interface PurchaseItemRowProps extends PurchaseItemActionsProps {
    purchaseItem: IPurchaseItem;
    isMobile: boolean;
    smallMobile?: boolean;
}

export interface PurchaseItemFormProps extends PurchaseItemRowProps {
    showTaxesModal: boolean;
    setShowTaxesModal: (value: boolean) => void;
    onClickTax: (tax: ITax) => void;
    onClickRemoveTax: (tax: ITaxOrderItemBase) => void;
    onChangeProductVariant: (productVariant?: IProductVariant) => void;
    changeManualDiscount: (value: number) => void;
    onPriceChange: (newValue: number) => void;
}

export interface QuantityPurchaseCard {
    purchaseItem: IPurchaseItem;
    isMobile?: boolean;
    onUpdateItem: (value: IPurchaseItem) => void;
    bigView?: boolean;
    smallView?: boolean;
}

export const QuantityCard = (props: QuantityPurchaseCard) => {

    const inputRef = React.useRef<any>();

    const increaseQuantity = (value: number) => {
        const newQuantity = props.purchaseItem.quantity + value;
        if (newQuantity < 1) return;
        props.onUpdateItem({ ...props.purchaseItem, quantity: newQuantity });
    };

    const updateQuantity = (value: number) => {
        if (value <= 0) return;
        props.onUpdateItem({ ...props.purchaseItem, quantity: value });
    };

    return (
        <QuantityContainerBody className={`${props.bigView ? "big-view" : ""}`}>
            <QuantityContainer className={`${props.smallView ? "small" : ""} ${props.bigView ? "big-view" : ""}`}>
                <SquareButton className="btn-quantity" onClick={() => increaseQuantity(-1)}>
                    <i className="fa-regular fa-minus"></i>
                </SquareButton>
                <TextBoxNumberTimer
                    id="textOrderQuantity"
                    ref={inputRef}
                    milliseconds={500}
                    className="textbox-quantity textbox-order-item"
                    format="number"
                    type="text"
                    value={props.purchaseItem.quantity}
                    onNumberChange={updateQuantity}
                    maxLength={9}
                />
                <SquareButton className="btn-quantity" onClick={() => increaseQuantity(1)}>
                    <i className="fa-regular fa-plus"></i>
                </SquareButton>
            </QuantityContainer>
        </QuantityContainerBody>
    );
};

interface PurchaseItemContainerProps {
    items: IPurchaseItem[];
    currentPurchase: IPurchase;
    onChange: (items: IPurchaseItem[]) => void;
}

export const PurchaseItemRowContainer = (props: PurchaseItemContainerProps) => {
    const onRemoveItem = (index: number) => {
        props.onChange(props.items.filter((x, i) => i !== index));
    };

    return (
        <Flex column gap5 minHeight={300}>
            {props.items.map((item, index) => {
                return (
                    <PurchaseItemFormRow
                        purchaseItem={item}
                        smallMobile={true}
                        key={index}
                        onUpdateItem={() => { }}
                        onRemoveItem={() => onRemoveItem(index)}
                        isMobile={true}
                        purchase={props.currentPurchase}
                    />
                );
            })}
        </Flex>
    );
};

export function PurchaseItemFormRow(props: PurchaseItemRowProps) {
    const { purchaseItem } = props;
    const [showTaxesModal, setShowTaxesModal] = useState(false);

    const onPriceChange = (newValue: number) => {
        purchaseItem.unitPrice = newValue;
        let basePriceResponse = calculateItemBasePrice(
            purchaseItem,
            purchaseItem.taxes,
            props.purchase.taxIncluded,
            props.purchase.preTaxDiscount
        );
        purchaseItem.basePrice = basePriceResponse.basePrice;
        purchaseItem.basePricePreTaxDiscount = basePriceResponse.basePricePreTaxDiscount;
        let totalTax = getItemTotalTax(purchaseItem.taxes, purchaseItem, props.purchase.taxIncluded, props.purchase.preTaxDiscount);
        props.onUpdateItem({ ...purchaseItem, totalTax, basePrice: basePriceResponse.basePrice, unitPrice: newValue });
    };

    const changeManualDiscount = (value: number) => {
        if (value > 100) {
            value = 100;
        }
        if (value < 0) {
            value = 0;
        }

        purchaseItem.manualPercentageDiscount = value;

        let basePriceResponse = calculateItemBasePrice(
            purchaseItem,
            purchaseItem.taxes,
            props.purchase.taxIncluded,
            props.purchase.preTaxDiscount
        );
        purchaseItem.basePrice = basePriceResponse.basePrice;
        purchaseItem.basePricePreTaxDiscount = basePriceResponse.basePricePreTaxDiscount;
        let totalTax = getItemTotalTax(purchaseItem.taxes, purchaseItem, props.purchase.taxIncluded, props.purchase.preTaxDiscount);

        props.onUpdateItem({
            ...purchaseItem,
            manualPercentageDiscount: value,
            basePrice: basePriceResponse.basePrice,
            totalTax: totalTax,
        });
    };

    const onChangeProductVariant = (productVariant?: IProductVariant) => {
        props.onUpdateItem({ ...props.purchaseItem, productVariant: productVariant, productVariantId: productVariant?.id });
    };

    const onClickRemoveTax = (tax: ITaxOrderItemBase) => {
        let taxes = purchaseItem.taxes.filter((x) => x.taxId !== tax.taxId);
        calculateChangesFromTaxes(taxes);
    };
    const calculateChangesFromTaxes = (taxes: ITaxOrderItemBase[]) => {
        let [taxPercentage] = extractTaxInformation(taxes);
        let basePriceResponse = calculateItemBasePrice(purchaseItem, taxes, props.purchase.taxIncluded, props.purchase.preTaxDiscount);
        purchaseItem.basePrice = basePriceResponse.basePrice;
        purchaseItem.basePricePreTaxDiscount = basePriceResponse.basePricePreTaxDiscount;
        let taxAmount = getItemTotalTax(taxes, purchaseItem, props.purchase.taxIncluded, props.purchase.preTaxDiscount);
        props.onUpdateItem({
            ...purchaseItem,
            basePrice: basePriceResponse.basePrice,
            taxes: taxes,
            totalTax: taxAmount,
            totalTaxPercentage: taxPercentage,
        });
    };
    const onClickTax = (tax: ITax) => {
        let existingTax = purchaseItem.taxes.filter((x) => x.taxId === tax.id);

        if (existingTax.length === 0) {
            let taxes = [...purchaseItem.taxes, convertTaxOrderItem(tax, purchaseItem.basePrice, false)];
            calculateChangesFromTaxes(taxes);
        }
    };

    const baseProps = {
        showTaxesModal,
        setShowTaxesModal,
        onClickTax,
        onClickRemoveTax,
        onChangeProductVariant,
        changeManualDiscount,
        onPriceChange,
    };

    return props.isMobile ? <PurchaseItemRowMobile {...props} {...baseProps} /> : <PurchaseItemRowDesktop {...props} {...baseProps} />;
}

function PurchaseItemRowDesktop(props: PurchaseItemFormProps) {
    const { purchaseItem, onChangeProductVariant, onPriceChange } = props;
    const { product, productVariant } = purchaseItem;
    const [showPurchaseItemModal, setShowPurchaseItemModal] = useState(false)

    return (
        <RowContainer className={""}>
            <td className="min-width">
                <Flex className="icon-card">
                    <TextField className="position-index" small bold>
                        {purchaseItem.position}
                    </TextField>
                    <SquareButton removeBackground className="btn-delete" onClick={() => props.onRemoveItem(purchaseItem)}>
                        <i className="fa-regular fa-trash"></i>
                    </SquareButton>
                </Flex>
            </td>
            <td className="min-width">
                <ImageContainer className="image-container" onClick={() => setShowPurchaseItemModal(true)}>
                    <span className="wahioicon-pen edit-icon"></span>
                    <img src={getProductThumbnailUrl(product, productVariant)} alt={product ? product.name : "ELIMINADO"} />
                </ImageContainer>
                {showPurchaseItemModal && <PurchaseItemModal {...props} show={showPurchaseItemModal} setShow={setShowPurchaseItemModal} />}
            </td>

            <td>
                <FlexCol>
                    <span className="product-title">{product?.name}</span>
                    <TextField small>{getProductSkuAndCode(product, props.purchaseItem.productVariant)}</TextField>

                    {(purchaseItem.totalTaxPercentage > 0 || purchaseItem.manualPercentageDiscount > 0) && (
                        <Flex gap5>
                            {purchaseItem.totalTaxPercentage > 0 && (
                                <ButtonPercentage title="Impuesto" className="tax" onClick={() => setShowPurchaseItemModal(true)}>
                                    <span className="tax-percentage">{purchaseItem.totalTaxPercentage}%</span>
                                </ButtonPercentage>
                            )}
                            {purchaseItem.manualPercentageDiscount > 0 && (
                                <ButtonPercentage title="Descuento" className="discount" onClick={() => setShowPurchaseItemModal(true)}>
                                    <span className="tax-percentage">{purchaseItem.manualPercentageDiscount}%</span>
                                </ButtonPercentage>
                            )}
                        </Flex>
                    )}
                    {props.purchaseItem.productVariant && (
                        <SelectBody className="border-none">
                            <Select
                                placeholder="Seleccionar"
                                className="select-min-len"
                                classNamePrefix="select"
                                key={props.purchaseItem.productVariantId}
                                defaultValue={
                                    props.purchaseItem.productVariant ? getSelectOption(props.purchaseItem?.productVariant) : undefined
                                }
                                isDisabled={false}
                                onChange={(value) => onChangeProductVariant(product?.variants?.find((x) => x.id === value?.id))}
                                isRtl={false}
                                isSearchable={true}
                                name="seller"
                                options={product?.variants.map((item) => getSelectOption(item))}
                            />
                        </SelectBody>
                    )}
                </FlexCol>
            </td>
            <td>
                <QuantityCard {...props} />
            </td>
            <td>
                <PriceContainer>
                    <TextBoxNumeral
                        tabIndex={0}
                        className="input-price"
                        format="money"
                        value={purchaseItem.unitPrice}
                        onNumberChange={onPriceChange}
                    />
                </PriceContainer>
            </td>
            <td>
                <TextField bold600>{formatMoney(purchaseItem.total)}</TextField>
            </td>
        </RowContainer>
    );
}

function PurchaseItemRowMobile(props: PurchaseItemFormProps) {
    const { purchaseItem, onChangeProductVariant } = props;
    const { product, productVariant } = purchaseItem;

    const [showPurchaseItemModal, setShowPurchaseItemModal] = useState(false);

    const onClickCard = (e: any) => {
        if (ignoreElementClick(e.target, "ignore")) return;
        setShowPurchaseItemModal(true);
    };

    return (
        <>
            {showPurchaseItemModal && <PurchaseItemModal {...props} show={showPurchaseItemModal} setShow={setShowPurchaseItemModal} />}
            <CardContainer onClick={onClickCard}>
                <Flex alignCenter w100 gap10>
                    <Flex alignCenter gap5>
                        {!props.smallMobile && (
                            <SquareButton id="ignore" className="btn-position" onClick={() => props.onRemoveItem(purchaseItem)}>
                                <span className="btn-text">{purchaseItem.position}</span>
                                <i className="fa-regular fa-trash btn-icon"></i>
                            </SquareButton>
                        )}
                        <FlexImageStyle width={45} fitCover borderRadius={15}>
                            <img src={getProductThumbnailUrl(product, productVariant)} alt={product?.name ?? "Producto Eliminado"} />
                        </FlexImageStyle>
                    </Flex>

                    <Flex column>
                        <Flex alignCenter spaceBetween>
                            <TextField>{product?.name}</TextField>
                        </Flex>
                        <TextField small light>
                            {getProductSku(product, purchaseItem.productVariant)}
                        </TextField>
                        <Flex alignCenter gap10 flexWrap>
                            <span>{formatMoney(purchaseItem.total)}</span>
                            {purchaseItem.totalTaxPercentage > 0 && <PercentageCard>{purchaseItem.totalTaxPercentage}%</PercentageCard>}

                            {purchaseItem.manualPercentageDiscount > 0 && (
                                <PercentageCard className="discount">{purchaseItem.manualPercentageDiscount}%</PercentageCard>
                            )}
                        </Flex>
                        {props.purchaseItem.productVariant && (
                            <SelectBody className="border-none">
                                <Select
                                    placeholder="Seleccionar"
                                    className="select-min-len"
                                    classNamePrefix="select"
                                    key={props.purchaseItem.productVariantId}
                                    defaultValue={
                                        props.purchaseItem.productVariant
                                            ? getSelectOption(props.purchaseItem.productVariant)
                                            : undefined
                                    }
                                    isDisabled={false}
                                    onChange={(value) => onChangeProductVariant(product?.variants?.find((x) => x.id === value?.id))}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="seller"
                                    options={product?.variants.map((item) => getSelectOption(item))}
                                />
                            </SelectBody>
                        )}
                    </Flex>
                </Flex>
                {!props.smallMobile && (
                    <div id="ignore">
                        <QuantityCard smallView {...props} />
                    </div>
                )}
                {props.smallMobile && (
                    <SquareButton
                        style={{ marginRight: "10px" }}
                        id="ignore"
                        className="btn-position"
                        onClick={() => props.onRemoveItem(purchaseItem)}
                    >
                        <span className="btn-text">{props.purchaseItem.quantity}</span>
                        <i className="fa-regular fa-trash btn-icon"></i>
                    </SquareButton>
                )}

            </CardContainer>
        </>
    );
}

export function PurchaseItemRowReceive(props: IPurchaseItemReceive) {
    const { purchaseItem } = props;
    const { product } = purchaseItem;

    const image = product && product.profilePicture ? product.profilePicture : CloudImages.defaultImage;

    const updateQuantity = (value: number) => {
        const difference = purchaseItem.quantity - (purchaseItem.quantityReceived + value);
        let copyValue = value;
        if (difference < 0) {
            copyValue = purchaseItem.quantity - purchaseItem.quantityReceived;
        }
        let copyItem = { ...purchaseItem, quantityReceivedTemp: copyValue };
        props.onChange(copyItem);
    };
    const isComplete = purchaseItem.quantity === purchaseItem.quantityReceived;
    return (
        <RowContainer>
            <td className="min-width">
                <ImageContainer>
                    <img src={image} alt="" />
                </ImageContainer>
            </td>
            <td>
                <span>{product?.name ?? "ELIMINADO"}</span>
                <br />
                {purchaseItem.warehouse && <span className="light">{purchaseItem.warehouse.name}</span>}
            </td>
            <td>{purchaseItem.quantity}</td>
            <td>{purchaseItem.quantityReceived}</td>
            <td>
                {!isComplete && (
                    <TextBoxNumeral format="number" onNumberChange={updateQuantity} value={purchaseItem.quantityReceivedTemp} />
                )}
            </td>
        </RowContainer>
    );
}
