import styled from "styled-components";
import { SubContainerStyleView } from "../../ViewContainer/styled";

export const ProfileViewContainer = styled(SubContainerStyleView)`
    padding: 15px 25px;
    border-radius: 15px;
    margin-top: 15px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
`;

export const ConfirmPhoneContainer = styled.div`
    padding: 10px;

    .action-clic {
        cursor: pointer;
    }

    .text-danger {
        color: ${(props) => props.theme.palette.dangerColor};
    }
`;

export const ConfirmEmailContainer = styled.div`
    color: ${(props) => props.theme.palette.dangerColorDark};
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.palette.dangerColor}30;
    padding-bottom: 25px;
    margin-bottom: 10px;

    .title {
        margin: 0px;
    }
    p {
        margin: 0px;
    }
`;

export const ContainerPassword = styled.form`
    display: flex;
    width: inherent;
    flex-direction: column;

    .notMatch {
        color: ${(props) => props.theme.palette.dangerColor};
        margin-bottom: 16px;
        font-size: 0.9rem;
    }
`;

export const InputPassword = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 20px;
    gap: 10px;
`;

export const FooterButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    padding: 0px 20px 30px 0px;
`;

export const CoverForm = styled.div`
    background: ${(props) => props.theme.palette.background};
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
`;
