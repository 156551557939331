import styled from "styled-components";
import { DefaultButton } from "../../_controls";
import { Table } from "../../_controls/tables/styled";

export const NewProductLocationContainer = styled.div`
    padding: 25px 25px;

    .body {
        display: flex;
    }

    .body-item {
        width: 100%;
    }

    .textbox-numeral {
        border-radius: 40px;
        width: 100%;
        padding: 7px 15px;
    }

    .flex-line {
        display: flex;
        align-items: center;
    }
    .select-body {
        width: 100%;
    }
    .location {
        min-width: 25px;
        width: 25px;
        height: 25px;
        margin-left: 10px;
        border-radius: 32px;
    }
`;

export const LocationsTable = styled(Table)`
    .row-color {
        width: 25px;
        height: 25px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-actions {
        button {
            background: transparent;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    tr {
        td:first-child {
            padding-left: 10px;
        }
        td:last-child {
            text-align: right;
        }

        :hover {
            td {
                background: ${(props) => props.theme.palette.backgroundLight};
            }
            .btn-actions {
                button {
                    background: ${(props) => props.theme.palette.background};
                    color: ${(props) => props.theme.palette.text};
                }
            }
        }
    }
`;

export const WarehouseLocationSelectorStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

export const WarehouseLocationSelectButton = styled(DefaultButton)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    border-radius: 37px;
    font-weight: 600;
    padding: 9px 17px;
    border: 0px;
    color: ${(props) => (props.color ? props.color : "black")};
    background: ${(props) => (props.color ? `${props.color}15` : "black")};

    &:hover {
        background: ${(props) => (props.color ? `${props.color}30` : "transparent")};
    }
`;

export const WarehouseLocationFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    .error-message {
        font-size: 13px;
        margin-top: 20px;
        color: ${(props) => props.theme.palette.dangerColor};
    }

    .labelcolor {
        font-weight: 500;
    }
    .btn-remove {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btn-actions {
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }
`;

export const WarehouseLocationCardContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(77px, 1fr));
    grid-auto-rows: 1fr;
    margin-top: 20px;
`;

export const WarehouseLocationCard = styled.div`
    border-radius: 10px;
    border: 2px solid ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
    border-bottom: 8px solid ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    transition: height 0.2s;
    position: relative;
    overflow: hidden;

    .body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 4px;
        justify-content: flex-end;
        min-height: 30px;
    }

    .stock {
        margin-bottom: 3px;
        color: ${(props) => props.theme.palette.textLight};
        min-width: 30px;
        height: 30px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
    }

    .label {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${(props) => props.theme.palette.textLight};
        vertical-align: middle;
        margin: 0;
    }

    &.active {
        .stock {
            font-size: 14px;
            background: ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
            color: ${(props) => (props.color ? "white" : props.theme.palette.textDark)};
        }

        .label {
            color: ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
        }
    }

    .selection {
        position: absolute;
        right: -40px;
        bottom: 8px;
        background: ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
        color: white;
        transition: right 0.2s;
        box-shadow: 1px 1px 7px ${(props) => props.theme.palette.shadowColor};
    }

    &:hover {
        background: ${(props) => (props.color ? props.color + "10" : props.theme.palette.backgroundDark)};
        .selection {
            right: 6px;
        }
    }
    &.selected {
        background: ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
        color: ${(props) => (props.color ? "white" : props.theme.palette.textDark)};

        .label {
            color: ${(props) => (props.color ? "white" : props.theme.palette.textDark)};
        }
    }
`;

export const WarehouseLocationCardSmall = styled.div`
    border-radius: 50px;
    background: ${(props) => (props.color ? props.color + "20" : props.theme.palette.backgroundDark)};
    display: flex;
    align-items: center;
    padding: 4px 6px;
    justify-content: space-between;

    .card-item {
        display: flex;
        align-items: center;
    }

    color: ${(props) => (props.color ? props.color : props.theme.palette.text)};

    .btn-action {
        color: ${(props) => (props.color ? props.color : props.theme.palette.text)};
        background: transparent;

        &:hover {
            color: ${(props) => (props.color ? props.color : props.theme.palette.text)};
            background: ${(props) => (props.color ? props.color + "30" : props.theme.palette.backgroundLight)};
        }
    }

    .mr-small {
        margin-right: 5px;
    }

    .stock {
        min-width: 20px;
        height: 17px;
        border-radius: 100px;
        font-size: 12px;
        margin-right: 6px;
        padding: 2px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
        color: ${(props) => (props.color ? "white" : props.theme.palette.textDark)};
    }

    .label {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const WarehouseLocationProfileCard = styled.div`
    padding: 15px;
    .flex-space {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .search-input {
        max-width: initial;
        input {
            background: transparent;
            border-radius: 50px;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            &:hover {
                border: 1px solid ${(props) => props.theme.palette.primary};
            }
        }
    }
    .header-body {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;
        justify-content: space-between;

        .header-title {
            border: 0px solid ${(props) => (props.color ? props.color : props.theme.palette.backgroundDark)};
            background: ${(props) => (props.color ? props.color + "50" : props.theme.palette.backgroundDark)};
            color: ${(props) => (props.color ? props.color : props.theme.palette.textDark)};
            padding: 10px;
            border-radius: 9px;
            margin-bottom: 14px;
            font-size: 1.2rem;
            font-weight: 500;
            width: fit-content;
        }
    }
`;

export const WarehouseProductLocationCardView = styled.div`
    display: flex;
    padding: 10px;
    margin-bottom: 3px;
    align-items: center;
    font-size: 13px;
    color: ${(props) => props.theme.palette.textLight};
    border-radius: 8px;
    transition: all 0.1s;
    width: 100%;

    box-sizing: border-box;

    &:hover {
        box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.palette.backgroundLight};
    }

    .title {
        color: ${(props) => props.theme.palette.text};
        font-size: 14px;
    }

    .image {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border-radius: 8px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .dropbtn {
        width: 33px;
        height: 33px;
        border-radius: 5px;
        color: ${(props) => props.theme.palette.textLight};
    }

    .content {
        display: flex;
        flex-direction: column;
    }

    .row {
        align-items: center;
        flex-direction: row;
    }

    .left {
        margin-left: auto;
    }

    .product-stock {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
        font-weight: 500;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;

        margin-right: 10px;
    }
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;

    .label {
        font-size: 13px;
        margin-bottom: 4px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const FlexRow = styled.div`
    display: flex;
`;

export const BarcodeContainerView = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 17px 0px;
    .code-card {
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-title {
            margin-bottom: 5px;
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};
        }

        canvas,
        img {
            padding: 10px;
            background: white;
            border-radius: 10px;
            height: 100px;
        }
    }
`;

export const AddProductContainer = styled.div`
    padding: 15px;
    .search-header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .checkbox-warehouse {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .checkbox {
            margin-right: 15px;
        }
    }
    .card {
        display: flex;
        align-items: center;
        margin-top: 5px;
        cursor: pointer;
        padding: 5px;
        border-radius: 10px;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
        .image {
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin-right: 15px;
            border-radius: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;
