import React, { useState } from "react";
import { DeliveryStatus, getShipmentStatusList } from "../../../api/models/deliveryOrder";
import { ALL_ORDER_STATUS_LIST, OrderStatus } from "../../../api/models/orderBase";
import { Flex, PrimaryButton } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";
import { GridContainer, SummaryFilterContainer } from "../styled";
import { OrderGroupSummaryState } from "./OrderStatisticsSummary";
import orderMessage from "../../Orders/OrderProfile/messages";
import { useIntl } from "react-intl";
import { checkAndGetMessage } from "../../../i18n/helper";

interface FilterFormProps {
    state: OrderGroupSummaryState;
    setState: (value: OrderGroupSummaryState) => void;
}

export default function FilterForm(props: FilterFormProps) {
    const [state, setState] = useState<OrderGroupSummaryState>(props.state);
    const intl = useIntl();

    const onHandleState = () => {
        props.setState(state);
    };

    const onClickOrderStatus = (item: OrderStatus) => {
        let newList = state.statusList.filter((x) => x !== item);
        if (newList.length === state.statusList.length) {
            newList.push(item);
        }
        setState({ ...state, statusList: newList });
    };

    const onClickShipmentStatus = (item: DeliveryStatus) => {
        let newList = state.shipmentList.filter((x) => x !== item);
        if (newList.length === state.shipmentList.length) {
            newList.push(item);
        }
        setState({ ...state, shipmentList: newList });
    };

    return (
        <SummaryFilterContainer>
            <h4 className="title">Estado de la orden</h4>
            <GridContainer>
                {ALL_ORDER_STATUS_LIST.map((item, index) => {
                    return (
                        <CheckBox
                            name={item}
                            key={index}
                            checked={state.statusList.includes(item)}
                            title={checkAndGetMessage(intl, orderMessage, item)}
                            onChange={() => {
                                onClickOrderStatus(item);
                            }}
                        />
                    );
                })}
            </GridContainer>

            <div className="mt-1">
                <h4 className="title">Estado del pedido</h4>
            </div>
            <GridContainer>
                {getShipmentStatusList(true).map((item, index) => {
                    return (
                        <CheckBox
                            name={item}
                            key={index}
                            checked={state.shipmentList.includes(item)}
                            title={checkAndGetMessage(intl, orderMessage, item)}
                            onChange={() => onClickShipmentStatus(item)}
                        />
                    );
                })}
            </GridContainer>
            <Flex className="mt-2" onClick={onHandleState}>
                <PrimaryButton>Guardar Cambios</PrimaryButton>
            </Flex>
        </SummaryFilterContainer>
    );
}
