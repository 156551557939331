import { defineMessages } from "react-intl";

const base = "components.reports";

export default defineMessages({
    quickActions: {
        id: `${base}.quickActions`,
        defaultMessage: "Acciones rápidas",
    },
    createProduct: {
        id: `${base}.createProduct`,
        defaultMessage: "Crear producto",
    },
    createSale: {
        id: `${base}.CreateSale`,
        defaultMessage: "Crear venta",
    },
    createOrder: {
        id: `${base}.createOrder`,
        defaultMessage: "Crear pedido",
    },
    createPurchase: {
        id: `${base}.createPurchase`,
        defaultMessage: "Crear compra",
    },
    createContact: {
        id: `${base}.createContact`,
        defaultMessage: "Crear contacto",
    },
});
