import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import CreatableSelect from "react-select/creatable";
import { getCountryStates } from "../../api/geoLocation/geoLocationApi";
import { SelectOptionObject } from "../../api/models";
import { State } from "../../api/models/geoLocation";
import { SelectBody } from "../Select/styled";

interface CountrySelectorProps {
    defaultValue?: string;
    countryCode?: string;
    onChangeState: (value?: State) => void;
    onCreateValue: (value: string) => void;
    hideTitle?: boolean;
    small?: boolean;
    title?: string;
    disabled?: boolean;
    w100?: boolean;
}

export function CountryStateSelector(props: CountrySelectorProps) {
    const [queryKey, setQueryKey] = useState(() => {
        return ["query_states", props.countryCode];
    });

    const allowSearchRef = useRef(false);
    const queryClient = useQueryClient();

    const getCountryStatesByCode = () => {
        return getCountryStates(props.countryCode ?? "CO");
    };

    const stateQuery = useQuery(queryKey, () => getCountryStatesByCode(), {
        refetchOnWindowFocus: false,
        enabled: allowSearchRef.current,
    });

    useEffect(() => {
        if (!props.countryCode) {
            stateQuery.data = [];
            return;
        }
        let _queryKey = ["query_states", props.countryCode];
        setQueryKey(_queryKey);
        allowSearchRef.current = false;
        stateQuery.data = queryClient.getQueryData<State[]>(_queryKey);
        if (!stateQuery.data || stateQuery.data.length === 0) {
            allowSearchRef.current = true;
            stateQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.countryCode]);

    const getOption = (item: State) => {
        let option: SelectOptionObject<State> = {
            label: `${item.name}`,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    const onCreateOption = (optionName: string) => {
        props.onCreateValue(optionName);
    };

    const getOptionFromValue = (value: string) => {
        let state: State = {
            id: 0,
            name: value,
            state_code: "",
            latitude: "",
            longitude: "",
            country_id: 0,
            cities: [],
        };
        let option: SelectOptionObject<State> = {
            label: value,
            value: value,
            object: state,
        };
        return option;
    };

    return (
        <SelectBody className={props.w100 ? "w100" : ""}>
            {!props.hideTitle && (
                <span className="label">
                    {props.title ? props.title : props.countryCode === "CO" ? "Seleccionar Departamento" : "Seleccionar un Estado"}
                </span>
            )}
            <CreatableSelect
                placeholder={"Seleccionar"}
                className={`${props.defaultValue ? "item-active" : ""}`}
                classNamePrefix="select"
                key={props.defaultValue}
                defaultValue={props.defaultValue ? getOptionFromValue(props.defaultValue) : undefined}
                isDisabled={props.disabled}
                onChange={(value) => props.onChangeState(value?.object)}
                onCreateOption={onCreateOption}
                isLoading={stateQuery.isFetching}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="supplier"
                options={stateQuery.data?.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
