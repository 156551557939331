import React, { useContext, useEffect, useState } from 'react';
import { useIsMobileListener } from '../..';
import { AccountUser, getAccountUserFullName } from '../../../api/models/accountUser';
import { CloudImages } from '../../../assets';
import { AccountUserContext } from '../../../store/contexts/AccountUserContext';
import { UserContext } from '../../../store/contexts/UserContext';
import LoadingDualRing from '../../LoadingDualRing';
import { Flex, FlexImageStyle } from '../../_controls';
import { UserSelectableListContainer } from './styled';

export interface UserSelectableListProps {
    onClickUser: (value: AccountUser) => void;
    listUserSelected: string[];
    hideUserAdmin?: boolean;
}

export const UserListSelectable = (props: UserSelectableListProps) => {
    const { accountUserActions, accountUserState } = useContext(AccountUserContext);
    const [userListState, setUserListState] = useState<AccountUser[]>([])
    const { userState } = useContext(UserContext);
    const userAdminId = userState.user?.account?.accountOwnerId;
    const currentUserId = userState.user?.id;
    const isMobile = useIsMobileListener();

    useEffect(() => {
        if (accountUserState.items.length === 0) accountUserActions.requestAccountUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const users: AccountUser[] = [];
        const usersActive = accountUserState.items.filter((user) => user.status !== "disabled");
        usersActive.forEach((user) => {
            if (props.hideUserAdmin) {
                if (user.id !== userAdminId) {
                    users.push(user);
                }
            } else {
                users.push(user);
            }
        });

        setUserListState(users);

    }, [accountUserState.items, props.hideUserAdmin, currentUserId, userAdminId])

    if (accountUserState.isFetching) return <LoadingDualRing center={true} />;

    return (
        <UserSelectableListContainer>
            {userListState.map((user, index) => {
                return (
                    <Flex
                        gap10
                        onClick={() => props.onClickUser(user)}
                        className={`${props.listUserSelected?.includes(user.id) ? "selected" : ""}
                                    ${!isMobile ? "hover" : ""} user-card`} 
                        key={index}
                    >
                        <FlexImageStyle>
                            <img src={user.image ?? CloudImages.defaultImage} alt={user.firstName} />
                        </FlexImageStyle>

                        <div className="col">
                            <span>{getAccountUserFullName(user)}</span>
                            <span className="light">{user.email}</span>
                        </div>
                    </Flex>
                );
            })}

        </UserSelectableListContainer>
    )
}
