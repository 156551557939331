import { getUniqueId } from "../../utils";
import { IOrderTable } from "./orderTable";

export const ADD_LOUNGE = "ADD_LOUNGE";
export const DELETE_LOUNGE = "DELETE_LOUNGE";
export const UPDATE_LOUNGE = "UPDATE_LOUNGE";
export const REQUEST_LOUNGES = "REQUEST_LOUNGES";
export const RECEIVE_LOUNGES = "RECEIVE_LOUNGES";
export const INVALID_REQUEST_LOUNGES = "INVALID_REQUEST_LOUNGES";

export type TableShape = "invalid" | "decor" | "square" | "circle" | "chair" | "hrect" | "vrect" | "hrect2" | "vrect2";

export type LoungeSize = "small" | "medium" | "large";

export interface IPosition {
    x: number;
    y: number;
}

export interface ISizePosition extends IPosition {
    width: number;
    height: number;
}

export interface ILoungeTable {
    id?: string;
    auxId: string;
    loungeId?: string;
    Lounge?: string;
    number: number;
    positionX: number;
    positionY: number;
    width: number;
    height: number;
    shape: TableShape;
    orderTableId?: string;
    orderTable?: IOrderTable;
    dateCreated?: Date;
}

export class LoungeTable implements ILoungeTable {
    id?: string | undefined;
    auxId: string;
    loungeId?: string | undefined;
    Lounge?: string | undefined;
    number: number;
    positionX: number;
    positionY: number;
    width: number;
    height: number;
    shape: TableShape;
    orderTableId?: string | undefined;
    orderTable?: IOrderTable;
    dateCreated?: Date;

    constructor(number: number, sizePosition: ISizePosition, shape: TableShape) {
        this.number = number;
        this.positionX = sizePosition.x;
        this.positionY = sizePosition.y;
        this.width = sizePosition.width;
        this.height = sizePosition.height;
        this.shape = shape;
        this.auxId = getUniqueId();
    }
}

export interface ILounge {
    id?: string;
    name: string;
    accountId: string;
    organizationId: string;
    initialNumber: number;
    size: LoungeSize;
    dateCreated?: Date;
    status?: string;
    tables: ILoungeTable[];
    usersLoaded?: boolean;
}

export class Lounge implements ILounge {
    id?: string;
    name: string;
    accountId: string;
    organizationId: string;
    initialNumber: number;
    size: LoungeSize;
    dateCreated?: Date;
    status?: string;
    tables: ILoungeTable[] = [];

    constructor(name: string, accountId: string, organizationId: string, size: LoungeSize = "medium") {
        this.name = name;
        this.accountId = accountId;
        this.organizationId = organizationId;
        this.initialNumber = 1;
        this.size = size;
    }
}

export interface ILoungeState {
    items: ILounge[];
    error: any;
    isFetching: boolean;
    fetchSuccess: boolean;
    isError: boolean;
}

export interface OrderTableMoveInput {
    loungeId: string;
    originNumber: number;
    destinyNumber: number;
}

export interface OrderTableMovementResponse {
    origin: ILoungeTable;
    destiny: ILoungeTable;
}

export interface RequestLounges {
    type: typeof REQUEST_LOUNGES;
}
export interface ReceiveLounges {
    type: typeof RECEIVE_LOUNGES;
    items: Array<ILounge>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_LOUNGES;
    value: any;
}
export interface UpdateLounge {
    type: typeof UPDATE_LOUNGE;
    value: ILounge;
}

export interface AddLounge {
    type: typeof ADD_LOUNGE;
    value: ILounge;
}

interface DeleteLounge {
    type: typeof DELETE_LOUNGE;
    id: string;
}

export type LoungeTypes = RequestLounges | ReceiveLounges | InvalidRequest | UpdateLounge | AddLounge | DeleteLounge;
