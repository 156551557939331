import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { RestCountry } from "../../api/_externalApis/model";
import { getCountries, RestCountryField } from "../../api/_externalApis/restCountriesApi";

interface IContextProps {
    countryCodesState: CountryCodesState;
    setCountryCodesState: (value: CountryCodesState) => void;
}

interface CountryCodesState {
    items: RestCountry[];
    isFetching: boolean;
}

const getCountryList = () => {
    let fields: RestCountryField[] = ["name", "callingCodes", "flags", "alpha2Code"];
    return getCountries(fields);
};

export const CountryCodesContext = createContext({} as IContextProps);

const CountryCodesContextProvider = (props: any) => {
    const [countryCodesState, setCountryCodesState] = useState<CountryCodesState>({
        items: [],
        isFetching: false,
    });

    useEffect(() => {
        handleFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const apiMutation = useMutation(() => getCountryList());

    const handleFetch = () => {
        setCountryCodesState({ ...countryCodesState, isFetching: true });
        apiMutation
            .mutateAsync()
            .then((data) => {
                setCountryCodesState({ ...countryCodesState, isFetching: false, items: data });
            })
            .catch((er) => {
                setCountryCodesState({ ...countryCodesState, isFetching: false });
            });
    };

    return (
        <CountryCodesContext.Provider value={{ countryCodesState, setCountryCodesState }}>{props.children}</CountryCodesContext.Provider>
    );
};

export const useCountryCodesContext = () => {
    return useContext(CountryCodesContext);
};

export default CountryCodesContextProvider;
