import QRCode from "qrcode.react";
import React, { PureComponent, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TRACK_ENDPOINT } from "../../../api/appConfig";
import { Order, OrderImpl } from "../../../api/models/order";
import { IOrganization } from "../../../api/models/organizationTypes";
import { checkIntlMessage } from "../../../i18n/helper";
import messages from "../../../messages";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { formatMoney, joinUrl } from "../../../utils/index";
import { getShippingLabelOrganization } from "../../Shipments/helper";
import { Flex, TextField } from "../../_controls";
import messagesStatus from "../OrderStatusFilter/messages";
import { FullPagePrint, OrderLabelCard } from "./styled";
import { getAuthBody } from "../../../auth";

interface OrderLabelProps {
    order?: Order;
    organization?: IOrganization;
}

export default class OrderLabel extends PureComponent<OrderLabelProps> {
    render() {
        return <OrderLabelPrintPage order={this.props.order} />;
    }
}

function OrderLabelPrintPage(props: OrderLabelProps) {
    return (
        <div>
            <FullPagePrint>
                <OrderLabelContent {...props} />
            </FullPagePrint>
        </div>
    );
}

export const OrderLabelContent = (props: OrderLabelProps) => {
    const { organizationState } = useContext(OrganizationContext);
    const organization = props.organization ?? organizationState.currentOrganization;
    let account = getAuthBody();

    const [order, setOrder] = useState<Order>(props.order ?? new OrderImpl(account.accountId, account.accountUserId, organization?.id!));
    const { deliveryOrder } = order;

    const { currentOrganization } = organizationState;

    useEffect(() => {
        let currentOrder: Order | undefined = props.order;
        if (!currentOrder) {
            currentOrder = new OrderImpl(account.accountId, account.accountUserId, organization?.id!);
        }
        setOrder(currentOrder);
    }, [account.accountId, account.accountUserId, organization?.id, props.order]);

    const shippingLabel = React.useMemo(() => {
        return getShippingLabelOrganization(order.deliveryOrder?.shippingLabel, currentOrganization);
    }, [currentOrganization, order.deliveryOrder?.shippingLabel]);

    const intl = useIntl();

    return (
        <OrderLabelCard>
            <Flex gap10 alignCenter padding={10}>
                {organization?.image && (
                    <div className="image">
                        <img src={organization?.image} alt={organization?.name} />
                    </div>
                )}
                <Flex column>
                    <TextField fontSize={15} bold>
                        {currentOrganization?.name}
                    </TextField>
                    {shippingLabel.organizationAddress && <span>{currentOrganization?.address}</span>}
                    {shippingLabel.organizationPhone && <span>{currentOrganization?.storePhone}</span>}
                </Flex>
            </Flex>
            <hr />
            {shippingLabel?.title1 && <div className="padding">{shippingLabel?.title1 && <span>{shippingLabel.title1}</span>}</div>}
            <div className="col padding">
                <span className="subtitle">Enviado a:</span>
                <span className="bold">{deliveryOrder?.contactName}</span>
                {shippingLabel.customerDocumentNumber && order.customer && <span>Documento: {order.customer.identificationNumber}</span>}
                <span className="">Teléfono: {deliveryOrder?.phoneNumber}</span>
                <span>{deliveryOrder?.state}</span>
                <span>
                    {deliveryOrder?.address}, {deliveryOrder?.city}
                </span>
                <span>{deliveryOrder?.additionalInformation}</span>
                {shippingLabel?.title2 && <span className="mt-1 mb-1">{shippingLabel.title2}</span>}
                {shippingLabel?.observation && shippingLabel?.comment && (
                    <div className="observation flex-col">
                        <span>Observaciones:</span>
                        <span>{shippingLabel.comment}</span>
                    </div>
                )}
            </div>
            <hr />
            <div className="flex space-between center">
                <div className="col">
                    {shippingLabel?.orderNumber && <span>Orden: #{order.number}</span>}
                    {order.orderInvoice && shippingLabel?.invoiceNumber && <span>Factura: {order.orderInvoice.number}</span>}
                    {shippingLabel?.shippingValue && <span>Valor de envío: {formatMoney(order.deliveryOrder?.amount)}</span>}
                    {shippingLabel?.paymentStatus && order.status && (
                        <span>Estado: {intl.formatMessage(messagesStatus[order.status])}</span>
                    )}
                    {shippingLabel?.paymentMethod && order.payments && order.payments.length > 0 && (
                        <span>
                            Método de pago:{" "}
                            {checkIntlMessage(messages, order.payments[0].method) &&
                                intl.formatMessage((messages as any)[order.payments[0].method])}
                        </span>
                    )}
                    {shippingLabel?.trackingNumber && (
                        <div className="flex-col">
                            <span>Tracking number: </span>
                            <span>{order.trackingNumber}</span>
                        </div>
                    )}
                    {shippingLabel?.orderValue && <span className="bold">Valor Total: {formatMoney(order.totalAmount)}</span>}

                    {shippingLabel?.trackingQr && (
                        <div className="mt-2">
                            <QRCode size={90} value={joinUrl(TRACK_ENDPOINT, order.trackingNumber ?? "")} />
                        </div>
                    )}
                    {shippingLabel?.title3 && <span className="mt-2">{shippingLabel.title3}</span>}
                </div>
            </div>
        </OrderLabelCard>
    );
};
