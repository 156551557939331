import { defineMessages } from "react-intl";

const base = "components.priceList";

export default defineMessages({
    costLastPurchase: {
        id: `${base}.costLastPurchase`,
        defaultMessage: "Ultima compra",
    },
    basePrice: {
        id: `${base}.basePrice`,
        defaultMessage: "Precio base",
    },
    price: {
        id: `${base}.price`,
        defaultMessage: "Precio 1",
    },
    price2: {
        id: `${base}.price2`,
        defaultMessage: "Precio 2",
    },
    price3: {
        id: `${base}.price3`,
        defaultMessage: "Precio 3",
    },
    price4: {
        id: `${base}.price4`,
        defaultMessage: "Precio 4",
    },
    price5: {
        id: `${base}.price5`,
        defaultMessage: "Precio 5",
    },
    price6: {
        id: `${base}.price6`,
        defaultMessage: "Precio 6",
    },
    price7: {
        id: `${base}.price7`,
        defaultMessage: "Precio 7",
    },
    price8: {
        id: `${base}.price8`,
        defaultMessage: "Precio 8",
    },
    price9: {
        id: `${base}.price9`,
        defaultMessage: "Precio 9",
    },
    price10: {
        id: `${base}.price10`,
        defaultMessage: "Precio 10",
    },
});
