import React from "react";
import { IWarehouseLocation } from "../../../api/models/warehouseLocation";
import { SelectBody } from "../../Select/styled";
import Select from "react-select";
import { SelectBaseOption } from "../../../api/models";

interface WarehouseLocationSelectProps {
    locations: IWarehouseLocation[];
    selectedLocation?: IWarehouseLocation;
    onLocationChange: (value?: IWarehouseLocation) => void;
    showNormalSize?: boolean;
}

export default function WarehouseLocationSelect(props: WarehouseLocationSelectProps) {
    const { selectedLocation, locations } = props;

    const getSelectOption = (item?: IWarehouseLocation) => {
        let option: SelectBaseOption = {
            id: item?.id ?? "",
            value: item?.code ? `${item.code} (${item.globalStock})`: "",
            label: item?.code ? `${item.code} (${item.globalStock})`: "",
        };
        return option;
    };

    const onLocationChange = (id?: string) => {
        let location = locations.find((x) => x.id === id);
        props.onLocationChange(location);
    };

    return (
        <SelectBody className="">
            <Select
                placeholder="Seleccionar"
                className={` ${props.showNormalSize ? "" : "select-min-len select-small"}`}
                classNamePrefix="select"
                key={selectedLocation?.id}
                defaultValue={selectedLocation ? getSelectOption(selectedLocation) : undefined}
                isDisabled={false}
                onChange={(value) => onLocationChange(value?.id)}
                isRtl={false}
                isSearchable={true}
                name="seller"
                options={locations.map((item) => getSelectOption(item))}
            />
        </SelectBody>
    );
}
