import React, { useContext, useState } from "react";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { IInventoryProductAdjustmentModel } from "../../../api/models/inventoryAdjustment";
import { IProductVariant } from "../../../api/models/product";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { UserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, SquareButton, TextField } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";
import { ProductQuantityPanelStyle } from "../ProductSearchList/styled";
import ProductSerials from "../ProductSerials";
import { getProductSku, getProductThumbnailUrl } from "../helper";

interface InventoryProductAdjustmentModalProps extends IModalShow {
    warehouseProduct: IWarehouseProduct;
    productVariant?: IProductVariant;
    onCompleted: () => void;
    onCancel: () => void;
}

export default function InventoryProductAdjustmentModal(props: InventoryProductAdjustmentModalProps) {
    const { warehouseProduct, productVariant } = props;
    const { product } = warehouseProduct;
    const { userState: currentAccountUserState } = useContext(UserContext);

    const [isFetching, setIsFetching] = useState(false);
    const alert = useAlert();
    
    const [updateModel, setUpdateModel] = useState<IInventoryProductAdjustmentModel>({
        productId: props.warehouseProduct.productId,
        warehouseId: props.warehouseProduct.warehouseId,
        quantity: props.warehouseProduct.stock,
        accountUserId: currentAccountUserState.user?.id ?? "",
        productVariantId: props.productVariant?.id ?? "",
        serials: props.warehouseProduct.serials ?? [],
    });

    const increaseQuantity = (value: number) => {
        let quantity = updateModel.quantity + value;
        if (quantity < 0) {
            quantity = 0;
        }
        setUpdateModel({ ...updateModel, quantity });
    };

    const onHandleChange = () => {
        setIsFetching(true);
        wahioFetch.post(
            productEndpoint.post.inventoryProductAdjustment,
            updateModel,
            () => {
                setIsFetching(false);
                alert.success("Ajuste realizado con éxito");
                props.setShow(false);
                props.onCompleted();
            },
            (error) => {
                setIsFetching(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    return (
        <Modal
            borderRadius={30}
            ignoreOutsideClick
            useButtonClose
            sizeType="sm"
            removeVerticalAlign={false}
            title={"Ajustar producto"}
            {...props}
        >
            <ProductQuantityPanelStyle>
                <Flex alignCenter gap10>
                    <FlexImageStyle width={40} borderRadius={10} fitCover>
                        <img src={getProductThumbnailUrl(product, productVariant)} alt="" />
                    </FlexImageStyle>
                    <Flex column>
                        <TextField>{product.name}</TextField>
                        <TextField light small>
                            {getProductSku(product, productVariant)}
                        </TextField>
                    </Flex>
                </Flex>

                {warehouseProduct.product.includesSerials && (
                    <Flex column w100 gap10>
                        <h3 className="text-bold m-0">Seriales</h3>
                        <ProductSerials
                            serials={updateModel.serials}
                            onChange={(serials) => setUpdateModel({ ...updateModel, serials, quantity: serials.length })}
                        />
                    </Flex>
                )}

                {!warehouseProduct.product.includesSerials && (
                    <Flex className="quantity-card">
                        <SquareButton onClick={() => increaseQuantity(-1)}>
                            <i className="fa-regular fa-minus"></i>
                        </SquareButton>

                        <TextBoxNumeral
                            value={updateModel.quantity}
                            format="number"
                            className="input-quantity"
                            autoFocus
                            onNumberChange={(value) => setUpdateModel({ ...updateModel, quantity: value })}
                        />

                        <SquareButton onClick={() => increaseQuantity(1)}>
                            <i className="fa-regular fa-plus"></i>
                        </SquareButton>
                    </Flex>
                )}

                <Flex justifyEnd gap10 w100 className="mt-2">
                    <DefaultButton w100 disabled={isFetching} rounded onClick={() => props.onCancel()}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton w100 disabled={isFetching} rounded onClick={() => onHandleChange()}>
                        Confirmar {isFetching && <LoadingDualRing small />}
                    </PrimaryButton>
                </Flex>
            </ProductQuantityPanelStyle>
        </Modal>
    );
}
