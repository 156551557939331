import React from "react";
import { DefaultInput } from "./index";

import styled from "styled-components";

export const SearchCustom = styled(DefaultInput)`
    padding-left: 37px;
    width: 100%;
    border-radius: 30px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    background: ${(props) => props.theme.palette.background};

`;

export const SearchIcon = styled.span`
    position: absolute;
    left: 14px;
    color: ${(props) => props.theme.palette.textLight};
`;

export const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    max-width: 400px;
`;

export default function SearchInput({ icon, ...props }: any) {
    return (
        <SearchContainer className="search-input">
            <SearchIcon className={icon}></SearchIcon>
            <SearchCustom {...props} />
        </SearchContainer>
    );
}
