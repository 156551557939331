import React from "react";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { ProductNavigationProfileProps } from ".";
import QRCode from "qrcode.react";
import { useIntl } from "react-intl";
import BarcodeView from "../../BarcodeView";
import { getProductCode, getProductSku } from "../helper";
import messages from "./messages";
import { BarcodeContainerView } from "./styled";

export default function NavigationBarcodeFile(props: ProductNavigationProfileProps) {
    const { product, productVariant } = props;
    const intl = useIntl();
    const productCode = getProductCode(product, productVariant);
    const productSku = getProductSku(product, productVariant);
    return (
        <NavigationCard {...props} title={intl.formatMessage(messages.barcode)}>
            <BarcodeContainerView>
                {product.code || productVariant?.code || product.sku || productVariant?.sku ? (
                    <>
                        {productCode && (
                            <div className="code-card">
                                <span className="card-title">{intl.formatMessage(messages.barcode)}</span>
                                <QRCode size={100} value={productCode} />
                            </div>
                        )}
                        {productSku && (
                            <div className="code-card">
                                <span className="card-title">{intl.formatMessage(messages.sku)}</span>
                                <QRCode size={100} value={productSku} />
                            </div>
                        )}
                        {productCode && (
                            <div className="code-card">
                                <span className="card-title">{intl.formatMessage(messages.barcode)}</span>
                                <BarcodeView value={productCode} />
                            </div>
                        )}
                        {productSku && (
                            <div className="code-card">
                                <span className="card-title">{intl.formatMessage(messages.sku)}</span>
                                <BarcodeView value={productSku} />
                            </div>
                        )}
                    </>
                ) : (
                    <span className="text-light">Este producto no cuenta con código SKU o código de barras</span>
                )}
            </BarcodeContainerView>
        </NavigationCard>
    );
}
