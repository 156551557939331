/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { AccountUser } from "../../api/models/accountUser";
import { clearAuthSession } from "../../auth";
import PATHS from "../../constants/paths";
import { useStoreClearDeviceData } from "../../store";
import { UserContext } from "../../store/contexts/UserContext";
import billingMessages from "../Account/Billing/messages";
import { PlanLabelLink, PlanLabelLinkContainer } from "../Account/Billing/styled";
import Dropdown from "../Dropdown";
import { useQuickStartUpdate } from "../QuickStart/quickStartHook";
import ThemeSettings from "../Settings/ThemeSettings";
import { Flex } from "../_controls";
import Modal from "../modals/Modal";
import messages from "./messages";
import { ElementTitle, MainContainerSettings, SettingsButtonItem } from "./styled";

export default function ProfileSettingsMenu() {
    const intl = useIntl();
    const { userState } = useContext(UserContext);

    const user = userState.user;
    const useClearDevice = useStoreClearDeviceData();
    const quickStartUpdate = useQuickStartUpdate();

    const [showThemeSettings, setShowThemeSettings] = useState(false);

    const getProfileImage = (customer: AccountUser) => {
        let res = {
            url: "",
            text: "",
        };
        if (customer && customer.image) {
            res.url = customer.image;
        } else {
            res.text = customer.firstName.substring(0, 1);
        }
        return res;
    };

    let userImage = user ? getProfileImage(user) : undefined;

    return (
        <>
            {showThemeSettings && (
                <Modal show={showThemeSettings} setShow={setShowThemeSettings}>
                    <ThemeSettings />
                </Modal>
            )}
            <Dropdown
                tourName="PROFILE_SETTINGS"
                ignoreInternalClicks
                image={userImage?.url}
                hideIcon
                letterAsIcon={userImage?.text}
                contentHorizontal="left"
            >
                <MainContainerSettings column gap10>
                    {user && (
                        <Flex column gap10>
                            <NavLink to={PATHS.settingsAccountUserProfile}>
                                <ElementTitle gap10 alignCenter>
                                    {userImage?.url && (
                                        <div className="image">
                                            <img src={userImage.url} alt="" />
                                        </div>
                                    )}
                                    <Flex column>
                                        <span className="title">
                                            {user.firstName} {user.lastName}
                                        </span>
                                        <span className="sub">{user.email}</span>
                                    </Flex>
                                </ElementTitle>
                            </NavLink>
                        </Flex>
                    )}
                    <Flex>
                        <PlanLabelLinkContainer to={PATHS.accountBilling}>
                            <PlanLabelLink>
                                <span>
                                    Plan{" "}
                                    {userState.user?.account?.plan
                                        ? intl.formatMessage(billingMessages[userState.user.account.plan])
                                        : "---"}
                                </span>
                            </PlanLabelLink>
                        </PlanLabelLinkContainer>
                    </Flex>
                    {userState.user?.quickStart?.hidePermanently && (
                        <SettingsButtonItem
                            onClick={() => {
                                quickStartUpdate.setHidePermanently(false);
                            }}
                        >
                            <div className="icon">
                                <span className="wahioicon-question"></span>
                            </div>
                            <span>{intl.formatMessage(messages.helpCenter)}</span>
                        </SettingsButtonItem>
                    )}
                    <SettingsButtonItem
                        type="button"
                        onClick={(e) => {
                            setShowThemeSettings(true);
                        }}
                    >
                        <div className="icon">
                            <i className="fa-regular fa-palette"></i>
                        </div>
                        <span>{intl.formatMessage(messages.personalize)}</span>
                    </SettingsButtonItem>
                    <SettingsButtonItem onClick={() => useClearDevice.handleClearDeviceData()}>
                        <div className="icon">
                            <i className="fa-regular fa-rotate"></i>
                        </div>
                        <span>{intl.formatMessage(messages.refreshDevice)}</span>
                    </SettingsButtonItem>

                    <SettingsButtonItem onClick={() => clearAuthSession()}>
                        <div className="icon">
                            <i className="fa-regular fa-arrow-right-from-bracket"></i>
                        </div>
                        <span>{intl.formatMessage(messages.signOut)}</span>
                    </SettingsButtonItem>

                    <span className="app-version">Version: 3.1.1</span>
                </MainContainerSettings>
            </Dropdown>
        </>
    );
}
