import styled from "styled-components";

export const ProductSerialsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .serial-line {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
        position: relative;

        .btn-remove {
            position: absolute;
            right: 5px;
            display: none;
        }

        &:hover { 
            .btn-remove { 
                display: flex;
            }
        }

        .index {
            color: ${(props) => props.theme.palette.textLight};
        }
        .input-serial {
            width: 100%;
            border-radius: 40px;
        }

        &.repeated {
            .input-serial {
                border: 1px solid ${(props) => props.theme.palette.dangerColor};
                background: ${(props) => props.theme.palette.dangerColor}20;
                position: relative;
            }
            position: relative;
            text-align: center;
            justify-content: center;
            ::after {
                content: "Serial repetido";
                position: absolute;
                color: ${(props) => props.theme.palette.dangerColor};
                font-size: 12px;
                display: flex;
                align-items: center;
                background-color: ${(props) => props.theme.palette.background};
                z-index: 8;
                top: -6px;
                text-align: center;
                border-radius: 40px;
                padding: 0px 8px;
            }
        }
    }
`;
