import React, { useEffect, useState } from "react";
import { IOrderPaymentBase } from "../../api/models/orderPayment";
import { useCashRegisterContext } from "../../store/contexts/CashRegisterContext";
import { formatMoney } from "../../utils";
import { DefaultButton, Flex, TextField } from "../_controls";
import PaymentRow, { PaymentChangeType } from "./PaymentRow";
import usePaymentHook from "./paymentHooks";

interface PaymentsFormProps<T extends IOrderPaymentBase> {
    payments: T[];
    onChange: (items: T[]) => void;
    uniqueMethod: boolean;
    totalAmount: number;
    removePadding?: boolean;
    hidePaymentInput?: boolean;
    blockExistingPayments?: boolean;
    showAddPaymentButton?: boolean;
    columnRows?: boolean;

    hideTitle?: boolean;
}

export default function PaymentsForm<T extends IOrderPaymentBase>(props: PaymentsFormProps<T>) {
    const { payments } = props;
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
    const { cashRegisterState } = useCashRegisterContext();
    const paymentHooks = usePaymentHook();

    useEffect(() => {
        calculateTotalPaid(payments);
    }, [payments]);

    const activeCashRegister = React.useMemo(() => {
        if (cashRegisterState.logActive?.cashRegisterId) {
            let value = cashRegisterState.orgItems.find((x) => x.id === cashRegisterState.logActive?.cashRegisterId);
            return value;
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.logActive]);

    const calculateTotalPaid = (payments: IOrderPaymentBase[]) => {
        let value = payments.reduce((sum, item) => {
            sum += item.amount;
            return sum;
        }, 0);
        setTotalPaymentAmount(value);
    };

    const onChangePayment = (payment: T, type: PaymentChangeType, index: number) => {
        if (props.uniqueMethod && type === "method") {
            const existing = props.payments.find((x) => x.method === payment.method);
            if (existing) return;
        }
        let paymentsNew = [...payments];
        paymentsNew[index] = payment;
        props.onChange(paymentsNew);
        //calculateTotalPaid(paymentsNew);
    };

    const onRemovePayment = (index: number) => {
        let paymentsCopy = payments.filter((item, itemIndex) => itemIndex !== index);
        props.onChange(paymentsCopy);
    };

    const addPayment = () => {
        let payment = paymentHooks.getNewPayment();
        if (payment) {
            var newPayments: any = [...payments, payment];
            props.onChange(newPayments);
        }
    };

    return (
        <Flex column gap10>
            {!props.hideTitle && (
                <Flex alignCenter spaceBetween>
                    <TextField bold small>
                        Pagos
                    </TextField>
                    <TextField light small>
                        {formatMoney(totalPaymentAmount)}
                    </TextField>
                </Flex>
            )}
            {props.payments.map((item, index) => (
                <PaymentRow
                    removePadding={props.removePadding}
                    hidePaymentInput={props.hidePaymentInput}
                    totalPayment={totalPaymentAmount}
                    totalAmount={props.totalAmount}
                    onRemovePayment={() => onRemovePayment(index)}
                    counter={payments.length}
                    onChangePayment={(payment, type) => onChangePayment({ ...item, ...payment }, type, index)}
                    key={index}
                    payment={item}
                    readonly={props.blockExistingPayments}
                    activeCashRegister={activeCashRegister}
                    columnRows={props.columnRows}
                />
            ))}

            {props.showAddPaymentButton && (props.totalAmount === 0 || props.totalAmount > totalPaymentAmount) && (
                <DefaultButton type="button" borderRadius={10} bgLight onClick={addPayment}>
                    <i className="fa-regular fa-plus"></i> Agregar Pago
                </DefaultButton>
            )}
        </Flex>
    );
}
