import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectOptionObject } from "../../../api/models";
import { SelectBody } from "../../Select/styled";
import { ListingState, ListingStateCity } from "./defaultValues";
import tempCities from "./tempCities.json";

interface OrderInvoiceNumerationProps {
    onCityChange: (value: ListingStateCity) => void;
    initialCityId?: number;
    required?: boolean;
    disabled?: boolean;
}

export default function ColombiaStatesSelector(props: OrderInvoiceNumerationProps) {
    const [selectedCity, setSelectedCity] = useState<ListingStateCity>();
    const [selectedState, setSelectedState] = useState<ListingState>();
    const [cityItems, setCityItems] = useState<ListingStateCity[]>([]);

    useEffect(() => {
        if (props.initialCityId) {
            if (selectedCity?.id === props.initialCityId) return;
            const city: ListingStateCity | undefined = tempCities.municipalities.find((x) => x.id === props.initialCityId);
            if (city) {
                setSelectedCity(city);

                const state: ListingState | undefined = tempCities.departments.find((x) => x.code === city?.code.substring(0, 2));
                if (state) {
                    setSelectedState(state);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialCityId]);

    useEffect(() => {
        let cities: ListingStateCity[] = [];
        if (selectedState) {
            cities = tempCities.municipalities.filter((x) => x.code.startsWith(selectedState.code));
            if (selectedCity && !selectedCity.code.startsWith(selectedState.code)) {
                setSelectedCity(undefined);
            }
        }
        setCityItems(cities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState]);

    const getStateOption = (item: ListingState) => {
        let option: SelectOptionObject<ListingState> = {
            value: item.code,
            label: item.name,
            object: item,
        };
        return option;
    };

    const getCityOption = (item: ListingStateCity) => {
        let option: SelectOptionObject<ListingStateCity> = {
            value: item.code,
            label: item.name,
            object: item,
        };
        return option;
    };

    const onStateChange = (value?: ListingState) => {
        if (value) {
            setSelectedState(value);
        }
    };
    const onCityChange = (value?: ListingStateCity) => {
        if (value) {
            props.onCityChange(value);
            setSelectedCity(value);
        }
    };

    return (
        <>
            <SelectBody>
                <span className="label">Departamento{props.required ? "*" : ""}</span>
                <Select
                    placeholder="Departamento"
                    className={`react-select-basic ${selectedState ? "item-active" : ""}`}
                    classNamePrefix="select"
                    key={selectedState?.code}
                    value={selectedState ? getStateOption(selectedState) : undefined}
                    isDisabled={props.disabled}
                    onChange={(value) => {
                        onStateChange(value?.object);
                    }}
                    isRtl={false}
                    isSearchable={true}
                    name="warehouse"
                    options={tempCities.departments.map((item) => getStateOption(item))}
                />
            </SelectBody>
            <SelectBody>
                <span className="label">Ciudad{props.required ? "*" : ""}</span>
                <Select
                    placeholder="Ciudad"
                    className={`react-select-basic ${selectedCity ? "item-active" : ""}`}
                    classNamePrefix="select"
                    key={selectedCity?.code}
                    value={selectedCity ? getCityOption(selectedCity) : undefined}
                    isDisabled={props.disabled}
                    onChange={(value) => {
                        onCityChange(value?.object);
                    }}
                    isRtl={false}
                    isSearchable={true}
                    name="warehouse"
                    options={cityItems.map((item) => getCityOption(item))}
                />
            </SelectBody>
        </>
    );
}
