import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { wahioFetch } from "../../../../api";
import { ITax, Tax } from "../../../../api/models/tax";
import { productEndpoint } from "../../../../api/restApiEndpoint";
import { getAuthBody } from "../../../../auth";
import { TaxContext } from "../../../../store/contexts/TaxContext";
import { useAlert } from "../../../Alerts/Alert";
import EBillingDataSelector from "../../../ElectronicBillings/EBillingData/EBillingDataSelector";
import LoadingDualRing from "../../../LoadingDualRing";
import TextBoxNumeral from "../../../TextBoxNumeral";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, TextField } from "../../../_controls";
import messages from "./messages";

export interface TaxFormProps {
    onCreate: (value: ITax) => void;
    onCancel: () => void;
    taxUpdate?: ITax;
    closeOnCreate?: boolean;
    newTaxName?: string;
}

const getTaxLoaded = () => {
    const auth = getAuthBody();
    return new Tax(auth.accountId);
};

export default function TaxForm(props: TaxFormProps) {
    const intl = useIntl();
    const { taxActions } = useContext(TaxContext);
    const alert = useAlert();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentTax, setCurrentTax] = useState(props.taxUpdate ? props.taxUpdate : getTaxLoaded());
    let isEdit = !!props.taxUpdate;

    useEffect(() => {
        if (props.newTaxName) {
            setCurrentTax({ ...currentTax, name: props.newTaxName });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.newTaxName]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (currentTax.name.length === 0 || currentTax.tributeType === "" || currentTax.value === 0) {
            alert.error("Todos los campos son requeridos para crear el impuesto");
            return;
        }

        setIsSubmitting(true);

        wahioFetch.post(
            productEndpoint.post.tax,
            { ...currentTax, isInclude: true },
            (success) => {
                setIsSubmitting(false);
                const resultTax: ITax = success.data;

                alert.success(currentTax.id ? "El impuesto fue actualizado con éxito" : "El impuesto fue creado con éxito");
                if (currentTax.id) {
                    taxActions.update(resultTax);
                } else {
                    taxActions.add(resultTax);
                }
                setCurrentTax(getTaxLoaded());
                props.onCreate(resultTax);
            },
            (error) => {
                setIsSubmitting(false);
                alert.error(intl.formatMessage(messages.errorWhenTryingCreateTax));
            }
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentTax({ ...currentTax, [e.target.name]: e.target.value });
    };

    return (
        <form onSubmit={onSubmit}>
            <Flex column gap={20} padding={20}>
                <Flex column gap5>
                    <label htmlFor="name" className="text-small text-light">
                        {intl.formatMessage(messages.name)}
                    </label>
                    <DefaultInput
                        rounded
                        name="name"
                        value={currentTax.name}
                        onChange={handleChange}
                        type="text"
                        placeholder={intl.formatMessage(messages.name)}
                    />
                </Flex>
                <Flex column>
                    <EBillingDataSelector
                        type={"taxes"}
                        selectedId={currentTax.tributeType ? parseInt(currentTax.tributeType) : undefined}
                        onSelectedChange={(data, taxDescription) => {
                            setCurrentTax({ ...currentTax, tributeType: data.id.toString(), description: taxDescription ?? "" });
                        }}
                        title={intl.formatMessage(messages.tributeType)}
                    />
                </Flex>

                <Flex gap5 column>
                    <label htmlFor="name" className="text-light text-small">{`${
                        currentTax.tributeType === "BOLSAS" ? "Valor" : "Porcentaje"
                    } ${intl.formatMessage(messages.value)}`}</label>
                    <Flex gap5 alignCenter>
                        <TextBoxNumeral
                            name="percentage"
                            format="number"
                            rounded
                            required
                            value={currentTax.value}
                            onNumberChange={(value) => setCurrentTax({ ...currentTax, value })}
                            type="text"
                            placeholder={intl.formatMessage(messages.description)}
                        />
                        <TextField fontSize={20}>%</TextField>
                    </Flex>
                </Flex>
                {!isSubmitting && (
                    <Flex gap10 paddingTop={10} justifyEnd>
                        <DefaultButton borderRadius={10} type="button" onClick={() => props.onCancel()}>
                            Cancelar
                        </DefaultButton>

                        <PrimaryButton borderRadius={10} type="submit">
                            {isEdit ? "Editar Impuesto" : "Guardar Impuesto"}
                        </PrimaryButton>
                    </Flex>
                )}
                {isSubmitting && <LoadingDualRing center />}
            </Flex>
        </form>
    );
}
