import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { IOrderInvoiceCreateModel } from "../../../api/models/order";
import { createOrderInvoice } from "../../../api/orders/orderApi";
import { getAuthBody } from "../../../auth";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { getDateFormat, getUtcDate } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../Alerts/Alert";
import CustomDateInput from "../../DatePicker/CustomDateInput";
import LoadingDualRing from "../../LoadingDualRing";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";
import { OrderInvoiceViewProps } from "./OrderInvoiceView";

interface ConfirmInvoiceStateProps extends IModalShow, OrderInvoiceViewProps {}

export const ModalConfirmCreateInvoice = (props: ConfirmInvoiceStateProps) => {
    const { order } = props;
    const { organizationState } = useContext(OrganizationContext);

    const initialDate = React.useMemo(() => {
        let date = order.dateCreated ?? new Date();
        return customMoment(getUtcDate(date.toString())).startOf("day");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    const [bodyDate, setBodyDate] = useState({
        dueDate: customMoment(initialDate).add(30, "day").startOf("day").format(),
        paymentTermsDays: 30,
    });

    const alert = useAlert();

    const orderInvoiceMutation = useMutation((data: IOrderInvoiceCreateModel) => createOrderInvoice(data), {
        onSuccess: (data) => {
            alert.success("Factura creada correctamente");
            props.onOrderChange(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const handleCreateInvoice = () => {
        let model: IOrderInvoiceCreateModel = {
            orderId: order.id ?? "",
            accountUserId: getAuthBody().accountUserId,
            orderInvoiceNumerationId: organizationState.currentOrganization?.orderInvoiceNumerationId ?? "",
            paymentTermsDays: bodyDate.paymentTermsDays,
        };
        orderInvoiceMutation.mutate(model);
    };

    return (
        <Modal {...props}>
            <Flex column gap15 padding={30}>
                <Flex column gap={20}>
                    <TextField fontSize={18} alignCenter bold>
                        ¿Está seguro de que desea facturar este pedido?
                    </TextField>

                    <Flex column gap5>
                        <TextField small light>
                            Fecha de Vencimiento: {customMoment(bodyDate.dueDate).format("DD MMM YYYY")}
                        </TextField>

                        <Flex gap10 alignCenter>
                            <CustomDateInput
                                hideTimeSelector
                                date={customMoment(bodyDate.dueDate).toDate()}
                                onDateChange={(value) =>
                                    setBodyDate({
                                        ...bodyDate,
                                        dueDate: customMoment(value).startOf("day").format(),
                                        paymentTermsDays: Math.abs(initialDate.diff(value, "day")),
                                    })
                                }
                            />
                            <TextBoxNumeral
                                rounded
                                className="text-center"
                                value={bodyDate.paymentTermsDays}
                                onNumberChange={(days) =>
                                    setBodyDate({
                                        ...bodyDate,
                                        dueDate: customMoment(initialDate).add(days, "days").startOf("day").format(),
                                        paymentTermsDays: days,
                                    })
                                }
                                format={"number"}
                            />
                            <TextField light>Dias desde {getDateFormat(order.dateCreated, "MMM DD")}</TextField>
                        </Flex>
                    </Flex>
                    <Flex gap10 paddingTop={10}>
                        <DefaultButton
                            disabled={orderInvoiceMutation.isLoading}
                            bgLight
                            w100
                            borderRadius={10}
                            onClick={() => props.setShow(false)}
                        >
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton
                            disabled={orderInvoiceMutation.isLoading}
                            w100
                            borderRadius={10}
                            onClick={() => handleCreateInvoice()}
                        >
                            Continuar {orderInvoiceMutation.isLoading && <LoadingDualRing small />}
                        </PrimaryButton>
                    </Flex>
                </Flex>
            </Flex>
        </Modal>
    );
};
