import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { getServiceMessageError } from "../../../api";
import { accountUserDelete, updateAccountUser } from "../../../api/account/accountUser";
import { AccountUser, accountUserIsAdmin, getUserPermissions } from "../../../api/models/accountUser";
import PATHS from "../../../constants/paths";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { BackAction } from "../../BackLink/BackAction";
import DropdownPhoneCodes from "../../Contacts/DropdownPhoneCodes";
import LoadingDualRing from "../../LoadingDualRing";
import ConfirmationModal from "../../modals/ConfirmationModal";
import ViewContainer from "../../ViewContainer";
import WahioCloudImageSelector from "../../WahioCloud/WahioCloudImageSelector";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, TextField } from "../../_controls";
import UserTypeLabel from "../UserList/UserTypeLabel";
import ConfirmEmail from "./ConfirmEmail";
import ConfirmPhone from "./ConfirmPhone";
import messages from "./messages";
import { ProfileViewContainer } from "./styled";
import { UpdatePassword } from "./UpdatePassword";

export interface IUserProfileProps {
    accountUser: AccountUser;
    onUpdateUser: (value: AccountUser) => void;
    onDeleted: () => void;
    hideBackLink?: boolean;
}

export default function UserProfile(props: IUserProfileProps) {
    const alert = useAlert();
    const { accountUser } = props;
    const { userState } = useContext(UserContext);
    const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
    const { accountUserActions } = useContext(AccountUserContext);
    const [currentUser, setCurrentUser] = useState(props.accountUser);
    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const intl = useIntl();

    const deleteMutation = useMutation((id: string) => accountUserDelete(id), {
        onSuccess: (data) => {
            alert.success("Usuario eliminado con éxito");
            accountUserActions.requestAccountUsers();
            props.onDeleted();
        },
        onError: (err) => {
            alert.error("Hubo un error al intentar eliminar el usuario");
        },
    });

    const userMutation = useMutation((user: AccountUser) => updateAccountUser(user), {
        onSuccess: (user) => {
            props.onUpdateUser({
                ...accountUser,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                emailConfirmed: user.emailConfirmed,
                phoneNumber: user.phoneNumber,
                phoneConfirmed: user.phoneConfirmed,
                image: user.image,
            });
            alert.success("Usuario actualizado con éxito");
            setShowSaveChanges(false);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        setCurrentUser({ ...accountUser });
    }, [accountUser]);

    if (!accountUser) return null;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        userMutation.mutate(currentUser);
        setCurrentUser(currentUser);
    };
    const onCancelChanges = () => {
        setCurrentUser(accountUser);
        setShowSaveChanges(false);
    };
    const onChangeValue = (e: any) => {
        setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
        setShowSaveChanges(true);
    };

    const onAccountUserImageChange = (url?: string) => {
        setCurrentUser({ ...currentUser, image: url });
        setShowSaveChanges(true);
    };

    const onUpdatePhonePrefix = (prefix: string) => {
        setCurrentUser({ ...currentUser, phonePrefix: prefix });
        setShowSaveChanges(true);
    };

    const handleConfirmPhoneNumber = () => {
        userMutation.mutate({ ...currentUser, phoneConfirmed: true });
    };

    const deleteUser = () => {
        setShowConfirmDeleteUser(false);
        deleteMutation.mutate(currentUser.id);
    };

    if (deleteMutation.data && deleteMutation.isSuccess) {
        return (
            <ProfileViewContainer>
                <h2>{intl.formatMessage(messages.UserWasDeletedSuccessfully)}</h2>
                <NavLink to={PATHS.account}>
                    <DefaultButton rounded={true}>Ver Cuenta</DefaultButton>
                </NavLink>
            </ProfileViewContainer>
        );
    }

    const getIsOwnerValue = (id: string) => {
        return userState.user?.account?.accountOwnerId === id;
    };

    const isAdmin = getIsOwnerValue(currentUser.id);

    const canBeDeleted = () => {
        return !showSaveChanges && !isAdmin && userState.user?.id !== currentUser.id;
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Perfil - ${currentUser.firstName} ${currentUser.lastName}`}</title>
            </Helmet>
            {showConfirmDeleteUser && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmDeleteUser(false)}
                    onOkay={() => deleteUser()}
                    title="¿Estás seguro?"
                    description="Esta acción no se puede revertir, el usuario se eliminará de forma permanente."
                    show={showConfirmDeleteUser}
                    setShow={setShowConfirmDeleteUser}
                />
            )}
            {!props.hideBackLink && <BackAction />}
            <ProfileViewContainer addBackgroundHide={!getUserPermissions(userState.user).user?.update}>
                {!currentUser.emailConfirmed && <ConfirmEmail user={currentUser} />}
                <form onSubmit={handleSubmit}>
                    <Flex column gap={20}>
                        <Flex gap10 alignStart>
                            <WahioCloudImageSelector image={currentUser.image} onSelectImage={onAccountUserImageChange} />
                            <Flex column gap5>
                                <h3 className="m-0">
                                    {currentUser.firstName} {currentUser.lastName}
                                </h3>
                                <UserTypeLabel isAdmin={isAdmin} accountPermission={currentUser.accountPermission} />
                            </Flex>
                        </Flex>
                        <Flex column gap={20}>
                            <Flex gap10>
                                <Flex column gap5 w100>
                                    <TextField light small>
                                        Nombre
                                    </TextField>
                                    <DefaultInput
                                        rounded
                                        required={true}
                                        name="firstName"
                                        onChange={onChangeValue}
                                        value={currentUser.firstName}
                                        placeholder="Nombre"
                                        w100
                                    />
                                </Flex>
                                <Flex column gap5 w100>
                                    <TextField light small>
                                        Apellido
                                    </TextField>
                                    <DefaultInput
                                        rounded
                                        name="lastName"
                                        required={true}
                                        onChange={onChangeValue}
                                        value={currentUser.lastName}
                                        placeholder="Apellido"
                                        w100
                                    />
                                </Flex>
                            </Flex>
                            <Flex column gap5 w100>
                                <TextField light small>
                                    Correo
                                </TextField>
                                <DefaultInput
                                    className="mr-1"
                                    required={true}
                                    type="email"
                                    name="email"
                                    onChange={onChangeValue}
                                    value={currentUser.email}
                                    placeholder="Tu correo electrónico"
                                />
                            </Flex>
                            <Flex column gap5>
                                <TextField light small>
                                    Numero de teléfono
                                </TextField>
                                <Flex gap10 alignCenter>
                                    <DropdownPhoneCodes
                                        callingCode={currentUser.phonePrefix ?? "---"}
                                        onCallingCodeChange={onUpdatePhonePrefix}
                                    />
                                    <DefaultInput
                                        rounded
                                        name="phoneNumber"
                                        onChange={onChangeValue}
                                        value={currentUser.phoneNumber}
                                        placeholder="Ingresa tu número"
                                    />
                                </Flex>
                            </Flex>
                            {!showSaveChanges && !currentUser.phoneConfirmed && (
                                <ConfirmPhone onHandleConfirm={handleConfirmPhoneNumber} user={currentUser} />
                            )}
                        </Flex>
                        <Flex gap10 justifyEnd>
                            {canBeDeleted() && (
                                <DefaultButton
                                    disabled={deleteMutation.isLoading}
                                    type="button"
                                    onClick={() => setShowConfirmDeleteUser(true)}
                                    rounded={true}
                                >
                                    <span className="wahioicon-trash-alt1"></span> Desactivar{" "}
                                    {deleteMutation.isLoading && <LoadingDualRing small />}
                                </DefaultButton>
                            )}
                            {showSaveChanges && (
                                <DefaultButton
                                    disabled={userMutation.isLoading}
                                    rounded={true}
                                    onClick={() => onCancelChanges()}
                                    type="button"
                                >
                                    Cancelar
                                </DefaultButton>
                            )}
                            {showSaveChanges && (
                                <PrimaryButton disabled={userMutation.isLoading} rounded={true} type="submit">
                                    Guardar Cambios {userMutation.isLoading && <LoadingDualRing small />}
                                </PrimaryButton>
                            )}
                        </Flex>
                    </Flex>
                </form>
                {(userState.user?.id === accountUser.id || accountUserIsAdmin(userState.user)) && (
                    <UpdatePassword accountUser={accountUser} />
                )}
            </ProfileViewContainer>
        </>
    );
}

export const UserProfilePage = (props: IUserProfileProps) => {
    return (
        <ViewContainer size="sm">
            <UserProfile {...props} />
        </ViewContainer>
    );
};
