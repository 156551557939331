import React, { useEffect, useState } from "react";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { DeliveryStatus } from "../../../api/models/deliveryOrder";
import { DeliveryStatusCountByProductInput, ProductCountResponse } from "../../../api/models/product";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";

interface ShipmentOrderCountProps {
    warehouseProduct: IWarehouseProduct;
    status: DeliveryStatus;
}

export default function ShipmentOrderCount(props: ShipmentOrderCountProps) {
    const alert = useAlert();

    const [shippedStock, setShippedStock] = useState({
        count: 0,
        success: false,
        isFetching: false,
    });

    useEffect(() => {
        handleSearchShippedCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.warehouseProduct.id]);

    const handleSearchShippedCount = () => {
        setShippedStock({ ...shippedStock, isFetching: true, success: false });
        handleSearchDeliveryStatusCount(
            props.status,
            (count) => {
                setShippedStock({ ...shippedStock, isFetching: false, success: true, count });
            },
            () => {
                setShippedStock({ ...shippedStock, isFetching: false, success: false });
            }
        );
    };

    const getDeliveryStatusCountInput = (wp: IWarehouseProduct, status: DeliveryStatus) => {
        const data: DeliveryStatusCountByProductInput = {
            productId: wp.productId,
            warehouseId: wp.warehouseId,
            productVariantId: wp.productVariantId,
            shipmentStatusList: [status],
        };
        return data;
    };

    const handleSearchDeliveryStatusCount = (status: DeliveryStatus, onSuccess: (result: number) => void, onError: () => void) => {
        const data = getDeliveryStatusCountInput(props.warehouseProduct, status);

        wahioFetch.post(
            localOrderEndpoint.post.productCountByDeliveryStatus,
            data,
            (success) => {
                const data: ProductCountResponse = success.data;
                onSuccess(data.count);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                onError();
            }
        );
    };

    return <div>{shippedStock.isFetching ? <LoadingDualRing small /> : shippedStock.count}</div>;
}
