import React, { useEffect, useState } from "react";
import { Flex } from "../_controls";
import { NavTabs, NavigationContainer } from "./styled";

export interface INavigationTabOption<T> {
    key: T;
    title: string;
    path?: string;
    icon?: string;
    component?: React.ReactNode;
    hide?: boolean;
}

interface ITabOption<T> {
    option: INavigationTabOption<T>;
    selectedKey: T;
    setSelectedKey: (stage: INavigationTabOption<T>) => void;
}
export const TabOption = <T,>(props: ITabOption<T>) => {
    const { option } = props;
    const activeClass = option.key === props.selectedKey ? "active" : "";

    return (
        <li className={activeClass} onClick={() => props.setSelectedKey(option)}>
            {option.icon && <span className={option.icon + " mr-1"}></span>} <span>{option.title}</span>
        </li>
    );
};

export interface ISelectedTag<T> {
    onChange: (selected: INavigationTabOption<T>) => void;
    defaultKey?: T;
}
interface INavigationTabs<T> extends ISelectedTag<T> {
    options: INavigationTabOption<T>[];
    hideBorder?: boolean;
    hidePadding?: boolean;
    adjustAndCenter?: boolean;
    removeAutoWidth?: boolean;
}
const NavigationTabs = <T,>(props: INavigationTabs<T>) => {
    const [selectedKey, setSelectedKey] = useState(props.defaultKey ? props.defaultKey : props.options[0].key);

    useEffect(() => {
        if (props.defaultKey && props.defaultKey !== selectedKey)
            setSelectedKey(props.defaultKey)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultKey])


    const autoWidth = props.removeAutoWidth ? "" : "auto-width";

    let classList = "";
    if (!props.hideBorder) {
        classList += "border-bottom";
    }
    if (props.adjustAndCenter) {
        classList += " adjust-width-center";
    }
    if (props.hidePadding) {
        classList += " hide-padding";
    }

    const onChangeOption = (selected: INavigationTabOption<T>) => {
        setSelectedKey(selected.key);
        props.onChange(selected);
    };

    return (
        <>
            <NavigationContainer hideBorder={props.hideBorder} adjustAndCenter={props.adjustAndCenter} className={`${classList}`}>
                <Flex w100 spaceBetween className="nav-tab-container">
                    <NavTabs className={`${autoWidth}`}>
                        {props.options.map((option, index) => {
                            if (option.hide) return null;
                            return <TabOption selectedKey={selectedKey} setSelectedKey={onChangeOption} option={option} key={index} />;
                        })}
                    </NavTabs>
                </Flex>
            </NavigationContainer>
            {props.options.map((comp, index) => {
                if (comp.key === selectedKey) {
                    return <React.Fragment key={index}>{comp.component}</React.Fragment>;
                }
                return null;
            })}
        </>
    );
};
export default NavigationTabs;
