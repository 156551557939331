import React, { useEffect, useState } from "react";
import { RestCountry } from "../../api/_externalApis/model";
import { useCountryCodesContext } from "../../store/contexts/CountryCodesContext";
import Dropdown from "../Dropdown";
import LoadingDualRing from "../LoadingDualRing";
import SearchInputTimer from "../_controls/inputs/SearchInputTimer";
import { CountriesPhoneCodeContainer, CountryPhoneRow, DropdownPhoneCodesStyle } from "./styled";
import { DefaultButton } from "../_controls";

const DEFAULT_CALLING_CODE = "57";

interface DropdownPhoneCodesProps {
    callingCode?: string;
    onCallingCodeChange: (value: string) => void;
}

export default function DropdownPhoneCodes(props: DropdownPhoneCodesProps) {
    const { callingCode = DEFAULT_CALLING_CODE } = props;

    const { countryCodesState } = useCountryCodesContext();

    const [selectedCountry, setSelectedCountry] = useState<RestCountry>();
    const [searchValue, setSearchValue] = useState<string>("");

    const [items, setItems] = useState<RestCountry[]>([]);

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (searchValue) {
            let search = searchValue.toLowerCase();
            setItems(countryCodesState.items.filter((x) => x.name.toLowerCase().includes(search) || x.callingCodes.includes(search)));
        } else {
            setItems(countryCodesState.items);
        }
        setShowAll(false)
    }, [countryCodesState.items, searchValue]);

    useEffect(() => {
        if (!selectedCountry && props.callingCode && countryCodesState.items.length > 0) {
            setSelectedCountry(countryCodesState.items.find((x) => x.callingCodes.includes(callingCode)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCodesState.items]);

    let title = selectedCountry ? selectedCountry.callingCodes[0] : callingCode;
    let image = selectedCountry?.flags.svg ?? countryCodesState.items.find((x) => x.alpha2Code === "CO")?.flags.svg;

    const itemsResult = showAll ? items : items.slice(0, 20);

    return (
        <DropdownPhoneCodesStyle>
            <Dropdown smallImage imageIcon={image} key={selectedCountry?.name} hideIcon title={"+" + title} ignoreInternalClicks>
                <CountriesPhoneCodeContainer>
                    <SearchInputTimer
                        autoFocus
                        autoComplete="none"
                        initialValue={searchValue}
                        placeholder={"Buscar país"}
                        type="search"
                        milliseconds={400}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {countryCodesState.isFetching && <LoadingDualRing center />}
                    {itemsResult.map((item, index) => {
                        return (
                            <CountryPhoneRow
                                onClick={() => {
                                    setSelectedCountry(item);
                                    props.onCallingCodeChange(item.callingCodes[0]);
                                }}
                                key={index}
                            >
                                <div className="image">{item.flags && <img src={item.flags.svg} alt="" />}</div>
                                <span className="name">{item.name}</span>
                                <span className="code">{item.callingCodes.length > 0 ? `+${item.callingCodes[0]}` : ""}</span>
                            </CountryPhoneRow>
                        );
                    })}
                    {!showAll && items.length > 20 && (
                        <DefaultButton
                            onClick={(e) => {
                                e.preventDefault();
                                setShowAll(true);
                            }}
                            className="mt-1 ml-1"
                            rounded
                            bgLight
                        >
                            Mostrar mas
                        </DefaultButton>
                    )}
                </CountriesPhoneCodeContainer>
            </Dropdown>
        </DropdownPhoneCodesStyle>
    );
}
