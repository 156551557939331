import translate from "../../../../i18n/translate";
import { PAYMENT_METHOD_LIST, PaymentMethod } from "../../../../api/models";
import { IOrderPaymentBase } from "../../../../api/models/orderPayment";
import messages from "../messages";

interface IButtonStyle {
    className: string;
    title: any;
    paidAll: boolean;
}

export const getButtonStyle = (totalAmount: number, totalPay: number): IButtonStyle => {
    if (totalPay >= totalAmount)
        return {
            className: "btn-primary",
            title: translate(messages.finishPaid),
            paidAll: true,
        };
    else if (totalAmount > totalPay && totalPay > 0) {
        return {
            className: "btn-warning",
            title: translate(messages.finishPartial),
            paidAll: false,
        };
    } else {
        return {
            className: "btn-danger",
            title: translate(messages.finishCredit),
            paidAll: false,
        };
    }
};

export const getTotalPayments = (payments: IOrderPaymentBase[]) => {
    let sum = 0;
    payments.forEach((element) => (sum += element.amount));

    return Math.round(sum * 100) / 100;
};

export const getNextMethod = (payments: IOrderPaymentBase[]): PaymentMethod | undefined => {
    let result: PaymentMethod | undefined = undefined;
    for (let index = 0; index < PAYMENT_METHOD_LIST.length; index++) {
        const element = PAYMENT_METHOD_LIST[index];
        const match = payments.find((x) => x.method === element);
        if (!match) {
            return element;
        }
    }

    return result;
};
