import React from "react";
import { AlertContainer } from "./templateStyled";

export type AlertType = "danger" | "warning" | "success" | "info";

export interface IAlertProps {
    title: string;
    description: string;
    type?: AlertType;
    withoutMargin?: boolean;
}
export default function Alert(props: IAlertProps) {
    return (
        <AlertContainer className={`${props.type} ${props.withoutMargin ? "withoutMargin" : ""}`}>
            <p>
                <strong>{props.title}</strong>
            </p>
            <p>{props.description}</p>
        </AlertContainer>
    );
}
