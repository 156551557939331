import styled from "styled-components";

export const BackActionCustom = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    span {
        color: ${(props) => props.theme.palette.textDark};
        margin-right: 10px;
    }
    .icon {
        font-size: 13px;
        margin-right: 10px;
    }
`;

export const BackActionLineStyle = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    font-weight: 500;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    button {
        border-radius: 20px;
        border: 0;
        padding: 0px 10px;
    }
    .icon {
    }
`;
