import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useIsMobileListener } from "../..";
import { getUserPermissions } from "../../../api/models/accountUser";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import PATHS from "../../../constants/paths";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { CurrentPurchaseContext } from "../../../store/contexts/CurrentPurchaseContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { getRoundNumber } from "../../../utils";
import { calculateItemBasePrice, getItemTotalTax } from "../../../utils/taxes";
import { useAlert } from "../../Alerts/Alert";
import { CurrentRegisterLogModal } from "../../CashRegisters/CashRegisterLog";
import { calculateOrderTotalManualDiscount, calculateTotalTax } from "../../Orders/CreateOrderForm/helpers";
import { CreateOrderContainer, CreateOrderFormPanel } from "../../Orders/CreateOrderForm/styled";
import { DraftContainer, DraftSubContainer } from "../../Orders/OrderStyles/styled";
import PermissionDenied from "../../PermissionDenied";
import { ProductAddOption } from "../../Products/ProductCard";
import ProductSearchList, { ProductActiveInput, ProductActiveObject } from "../../Products/ProductSearchList";
import { ISaveResponse } from "./FinalizeOrderForm";
import OrderDetails from "./OrderDetails";
import OrderSummary from "./OrderSummary";
import { getPurchaseItemTotals, insertProductInOrderList } from "./helpers";
import messages from "./messages";

export interface CreatePurchaseFormProps {
    isModal?: boolean;
    order?: IPurchase;
    onOrderChange?: (order: IPurchase) => void;
    forceMobileView?: boolean;
}

const CreatePurchaseForm = (props: CreatePurchaseFormProps) => {
    const intl = useIntl();
    const alert = useAlert();

    const [productActiveObject, setProductActiveObject] = useState<ProductActiveObject>({});

    const { appSettingsState } = useContext(AppSettingsContext);
    const { userState } = useContext(UserContext);
    const { currentPurchaseState, currentPurchaseActions } = useContext(CurrentPurchaseContext);
    const { cashRegisterState } = useContext(CashRegisterContext);
    const { organizationState } = useContext(OrganizationContext);
    const [currentEditPurchase, setCurrentEditPurchase] = useState(props.order);
    const [purchaseCreateResult, setPurchaseCreateResult] = useState<IPurchase | undefined>();

    const [responseResult, setResponseResult] = useState<ISaveResponse>({
        isFetching: false,
        isError: false,
    });

    let isMobile = useIsMobileListener({ size: 710 });
    const currentPurchase = currentEditPurchase ? currentEditPurchase : currentPurchaseState.currentPurchase;
    const requiredCashRegister = organizationState.currentOrganization?.requiredCashRegister && !cashRegisterState.logActive;

    useEffect(() => {
        let items: IPurchaseItem[] = [];
        currentPurchase.items.forEach((element) => {
            let basePriceResult = calculateItemBasePrice(
                element,
                element.taxes,
                currentPurchase.taxIncluded,
                currentPurchase.preTaxDiscount
            );
            element.basePrice = basePriceResult.basePrice;
            element.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;

            let totalTax = getItemTotalTax(element.taxes, element, currentPurchase.taxIncluded, currentPurchase.preTaxDiscount);
            element.totalTax = totalTax;
            element = getPurchaseItemTotals(element, currentPurchase.taxIncluded, currentPurchase.preTaxDiscount);
            items.push(element);
        });
        calculateTotalsUpdateItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPurchase.taxIncluded, currentPurchase.preTaxDiscount, currentPurchase.includesTaxWithholding]);

    useEffect(() => {
        calculateTotalsUpdateItems(currentPurchase.items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPurchase.taxWithholdingPercentage]);

    useEffect(() => {
        loadProductActiveObject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPurchase.items]);

    const onUpdateCurrentPurchase = (value: IPurchase) => {
        if (currentEditPurchase) {
            setCurrentEditPurchase(value);
        } else currentPurchaseActions.update(value);
    };

    const onClickProduct = (value: ProductAddOption) => {
        setResponseResult({ ...responseResult, isSave: false });
        let newItems = insertProductInOrderList({
            items: currentPurchase.items,
            product: value.product,
            warehouse: value.warehouse,
            productVariant: value.productVariant,
            price: value.customPrice,
            quantity: value.quantity,
            taxIncluded: currentPurchase.taxIncluded,
            preTaxDiscount: currentPurchase.preTaxDiscount,
        });

        alert.success(`${value.product.name} Agregado`);
        calculateTotalsUpdateItems(newItems);
        setPurchaseCreateResult(undefined)
    };

    const onRemoveItem = (value: IPurchaseItem) => {
        let newItems: IPurchaseItem[] = [];
        let counter = 1;
        currentPurchase.items.forEach((item) => {
            if (
                item.productId === value.productId &&
                item.warehouseId === value.warehouseId &&
                item.productVariantId === value.productVariantId
            ) {
            } else {
                item.position = counter;
                newItems.push(item);
                counter += 1;
            }
        });

        calculateTotalsUpdateItems(newItems);
    };

    const onUpdateItem = (value: IPurchaseItem) => {
        let newItems: IPurchaseItem[] = [];
        value = getPurchaseItemTotals(value, currentPurchase.taxIncluded, currentPurchase.preTaxDiscount);

        currentPurchase.items.forEach((item) => {
            if (
                item.productId === value.productId &&
                item.warehouseId === value.warehouseId &&
                (!value.productVariantId || item.position === value.position)
            ) {
                newItems.push(value);
            } else {
                newItems.push(item);
            }
        });

        calculateTotalsUpdateItems(newItems);
    };

    const calculateTotalsUpdateItems = (items: IPurchaseItem[]) => {
        const totalAmountItems = getRoundNumber(items.reduce((sum, item) => sum + item.total, 0));
        const percentageDiscount = currentPurchase.percentageDiscount ?? 0;
        const totalDiscount = totalAmountItems * (percentageDiscount / 100);
        const totalManualDiscount = calculateOrderTotalManualDiscount({ ...currentPurchase, items });
        const totalTaxItems = calculateTotalTax(items);
        const subTotal = items.reduce(
            (previous, value) => (!currentPurchase.taxIncluded ? previous + value.subTotal : previous + value.subTotal),
            0
        );

        const totalDelivery = currentPurchase.totalDelivery ?? 0;
        const totalTip = currentPurchase.totalTip ?? 0;

        const preTotal = getRoundNumber(totalAmountItems + totalDelivery + totalTip - totalDiscount);
        const taxWithHolding = getRoundNumber(
            currentPurchase.includesTaxWithholding ? subTotal * (currentPurchase.taxWithholdingPercentage / 100) : 0
        );
        const total = getRoundNumber(preTotal - taxWithHolding);

        onUpdateCurrentPurchase({
            ...currentPurchase,
            items,
            totalAmountItems,
            percentageDiscount,
            totalDiscount,
            totalTaxItems,
            taxWithholdingValue: taxWithHolding,
            subTotal,
            totalAmount: total,
            organizationId: appSettingsState.defaultOrganizationId,
            totalManualPercentageDiscountItems: totalManualDiscount,
        });
    };

    const orderProps = {
        onRemoveItem,
        onUpdateItem,
        isMobile,
    };

    const loadProductActiveObject = () => {
        let object: ProductActiveObject = {};
        currentPurchase.items.forEach((item) => {
            let useProduct: ProductActiveInput = {
                productId: item.productId,
                warehouseId: item.warehouseId,
                quantity: item.quantity,
                productVariantId: item.productVariantId,
            };
            let existing = object[item.productId];
            if (existing) {
                useProduct.quantity += existing.quantity;
            }
            object[item.productId] = useProduct;
        });
        setProductActiveObject(object);
    };

    const onFinishOrder = (value: IPurchase) => {
        props.onOrderChange && props.onOrderChange(value);
        setPurchaseCreateResult(value);
    };

    const ViewDraftResult = () => {
        if (responseResult.isSave && responseResult.value) {
            return (
                <DraftContainer>
                    <DraftSubContainer>
                        <span>
                            La orden fue guardada con éxito:{" "}
                            <strong className="number">
                                <NavLink to={PATHS.productPurchasesListProfileId(responseResult.value.id)}>
                                    #{responseResult.value.number}
                                </NavLink>
                            </strong>
                        </span>
                    </DraftSubContainer>
                </DraftContainer>
            );
        }
        return null;
    };

    let containerClass = "";
    if (props.isModal) containerClass += "using-modal";

    if (appSettingsState.createOrderPanelFullScreen) {
        containerClass += " full-screen";
    }

    if (!currentPurchase.id && !getUserPermissions(userState.user).purchase?.create) {
        return <PermissionDenied message="No tienes permiso para crear compras" />;
    }

    if (currentPurchase.id && !getUserPermissions(userState.user).purchase?.update) {
        return <PermissionDenied message="No tienes permiso para actualizar compras" />;
    }

    return (
        <CreateOrderFormPanel >
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {currentEditPurchase
                        ? `${intl.formatMessage(messages.editOrden)} #${currentPurchase.number}`
                        : intl.formatMessage(messages.createOrder)}{" "}
                    - Wahio
                </title>
            </Helmet>
            {requiredCashRegister && (
                <CurrentRegisterLogModal useButtonClose={!requiredCashRegister} show={requiredCashRegister} setShow={() => {}} />
            )}
            {isMobile && <ViewDraftResult />}
            <CreateOrderContainer className={containerClass}>
                <ProductSearchList
                    activeProducts={productActiveObject}
                    isMobile={isMobile}
                    onClickProduct={onClickProduct}
                    origin="purchase"
                    showInsertProductButton
                    showLeftPanel
                    isBasePrice={true}
                    onlyInventoryProducts={true}
                    showAllProducts={true}
                    searchId={currentPurchase.auxid}
                    fixedHeight
                    title="Agregar productos"
                    isModal={props.isModal}
                />
                <OrderSummary
                    {...orderProps}
                    purchase={currentPurchase}
                    items={currentPurchase.items}
                    forceMobileView={props.forceMobileView}
                    onUpdatePurchase={onUpdateCurrentPurchase}
                    onUpdateItems={calculateTotalsUpdateItems}
                    purchaseCreateResult={purchaseCreateResult}
                />
                <OrderDetails
                    onUpdatePurchase={onUpdateCurrentPurchase}
                    currentPurchase={currentPurchase}
                    hide={false}
                    hideMaxWidth={false}
                    isMobile={isMobile}
                    onFinishOrder={onFinishOrder}
                    calculateTotalsUpdateItems={calculateTotalsUpdateItems}
                />
            </CreateOrderContainer>
        </CreateOrderFormPanel>
    );
};

export default CreatePurchaseForm;
