import { defineMessages } from "react-intl";

const base = "components.productSearchList";

export default defineMessages({
    searchProduct: {
        id: `${base}.searchProduct`,
        defaultMessage: "Buscar productos",
    },
    results: {
        id: `${base}.results`,
        defaultMessage: "Resultados",
    },
    moreItems: {
        id: `${base}.moreItems`,
        defaultMessage: "Más items",
    },
    noProductWithTheBardcodeWasFound: {
        id: `${base}.noProductWithTheBardcodeWasFound`,
        defaultMessage: "No se encontró ningún producto con código de barras",
    },
    selectAWarehouse: {
        id: `${base}.selectAWarehouse`,
        defaultMessage: "Selecciona una bodega",
    },
    theStockHasReachedTheLimit: {
        id: `${base}.theStockHasReachedTheLimit`,
        defaultMessage: "El stock ha llegado al límite",
    },
    customPrice: {
        id: `${base}.customPrice`,
        defaultMessage: "Precio personalizado",
    },
    filterProducts: {
        id: `${base}.filterProducts`,
        defaultMessage: "Filtrar productos",
    },
    all: {
        id: `${base}.all`,
        defaultMessage: "Todos",
    },
    assignWarehouse: {
        id: `${base}.assignWarehouse`,
        defaultMessage: "Asignar bodega",
    },
    createWarehouse: {
        id: `${base}.createWarehouse`,
        defaultMessage: "Crear bodega",
    },
});
