import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { DeliveryOrderGroupItem, DeliveryStatus, IDeliveryOrder, IShippingLabel, ShipmentSearch } from "../models/deliveryOrder";
import { AccountOrganizationFilter, OrderSearchInput, OrdersForBoard } from "../models/order";
import { localOrderEndpoint } from "../restApiEndpoint";

export const setDeliveryOrderToArchived = (organizationId: string): Promise<{ count: number }> => {
    return wahioFetchApi.put(localOrderEndpoint.put.setDeliveryOrderToArchived(organizationId), {}).then((res: any) => res.data);
};
export interface UpdateLabelInput {
    shippingLabel: IShippingLabel;
    deliveryOrderId: string;
}
export const updateDeliveryOrderLabel = (input: UpdateLabelInput): Promise<IDeliveryOrder> => {
    return wahioFetchApi
        .put(localOrderEndpoint.put.deliveryOrderLabel(input.deliveryOrderId), input.shippingLabel)
        .then((res: any) => res.data);
};

export const getOrdersForBoard = (input: OrderSearchInput): Promise<OrdersForBoard> => {
    return wahioFetchApi.post(localOrderEndpoint.post.getOrderShipmentGroupQuerySearch, input).then((res: any) => res.data);
};

export const shipmentsQuerySearch = (input: OrderSearchInput): Promise<IPaginationResponse<ShipmentSearch>> => {
    return wahioFetchApi.post(localOrderEndpoint.post.shipmentsQuerySearch, input).then((res: any) => res.data);
};

export interface DeliveryOrderStatusData {
    deliveryOrderId: string;
    newStatus: DeliveryStatus;
}

export const shipmentUpdateStatus = (input: DeliveryOrderStatusData): Promise<any> => {
    return wahioFetchApi
        .put(localOrderEndpoint.put.updateDeliveryStatus(input.deliveryOrderId, input.newStatus), {})
        .then((res: any) => res.data);
};

export const shipmentsStatusGroup = (input: AccountOrganizationFilter): Promise<DeliveryOrderGroupItem[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.shipmentsStatusGroup, input).then((res: any) => res.data);
};
