import React from "react";
import { TextField, TextBaseProps } from "../_controls";
import customMoment from "../../utils/momentFormat/dateFormat";
import { getDateFormat, getUtcDate } from "../../utils";

interface DateTimeLabelProps extends TextBaseProps {
    date?: Date | string;
    className?: string;
    includeDayAndTime?: boolean;
}

export const getDateTimeLabel = (date?: Date | string, includeDayAndTime?: boolean) => {
    if (!date) return "-";
    const today = customMoment().startOf("day");
    const dateUtc = customMoment(getUtcDate(date.toString()));

    if (today.diff(dateUtc.startOf("day"), "days") === 0) {
        return getDateFormat(date, "hh:mm a");
    } else if (includeDayAndTime) {
        return getDateFormat(date, "MMM DD. hh:mm a");
    } else {
        return getDateFormat(date, "MMM DD");
    }
};

export default function DateTimeLabel(props: DateTimeLabelProps) {
    return (
        <TextField {...props} nowrap className={props.className}>
            {getDateTimeLabel(props.date, props.includeDayAndTime)}
        </TextField>
    );
}
