import { defineMessages } from "react-intl";

const base = "components.orderProfile";

export default defineMessages({
    shippingBoard: {
        id: `${base}.shippingBoard`,
        defaultMessage: "Tablero",
    },
    order: {
        id: `${base}.order`,
        defaultMessage: "Orden",
    },
    void: {
        id: `${base}.void`,
        defaultMessage: "Anulado",
    },
    paid: {
        id: `${base}.paid`,
        defaultMessage: "Pagado",
    },
    credit: {
        id: `${base}.credit`,
        defaultMessage: "Crédito",
    },
    quotation: {
        id: `${base}.quotation`,
        defaultMessage: "Cotización",
    },
    open: {
        id: `${base}.open`,
        defaultMessage: "Order Abierta",
    },
    partial: {
        id: `${base}.partial`,
        defaultMessage: "Pago parcial",
    },
    queued: {
        id: `${base}.queued`,
        defaultMessage: "En cola",
    },
    draft: {
        id: `${base}.draft`,
        defaultMessage: "Borrador",
    },
    authorized: {
        id: `${base}.authorized`,
        defaultMessage: "Authorizado",
    },
    declined: {
        id: `${base}.declined`,
        defaultMessage: "Rechazada",
    },
    invalid: {
        id: `${base}.invalid`,
        defaultMessage: "Estado invalido",
    },
    in: {
        id: `${base}.in`,
        defaultMessage: "en",
    },
    generalCustomer: {
        id: `${base}.generalCustomer`,
        defaultMessage: "Cliente general",
    },
    customer: {
        id: `${base}.customer`,
        defaultMessage: "Cliente",
    },
    channel: {
        id: `${base}.channel`,
        defaultMessage: "Canal",
    },
    continueEdit: {
        id: `${base}.continueEdit`,
        defaultMessage: "Continuar editando",
    },
    createdAt: {
        id: `${base}.createdAt`,
        defaultMessage: "Creado",
    },
    createBy: {
        id: `${base}.createBy`,
        defaultMessage: "Creado por",
    },
    seller: {
        id: `${base}.seller`,
        defaultMessage: "Vendedor",
    },
    delivery: {
        id: `${base}.delivery`,
        defaultMessage: "Envío",
    },
    active: {
        id: `${base}.active`,
        defaultMessage: "Activo",
    },
    restaurant: {
        id: `${base}.restaurant`,
        defaultMessage: "Restaurante",
    },
    newPayment: {
        id: `${base}.newPayment`,
        defaultMessage: "Nuevo pago",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    accumulatedPayment: {
        id: `${base}.accumulatedPayment`,
        defaultMessage: "Pago acumulado",
    },
    remainingPayment: {
        id: `${base}.remainingPayment`,
        defaultMessage: "Pago restante",
    },
    payments: {
        id: `${base}.payments`,
        defaultMessage: "Pagos",
    },
    refunds: {
        id: `${base}.refunds`,
        defaultMessage: "Reembolsos",
    },
    statusHistory: {
        id: `${base}.statusHistory`,
        defaultMessage: "Historial estados",
    },
    addPayment: {
        id: `${base}.addPayment`,
        defaultMessage: "Agregar pago",
    },
    addOtherPayment: {
        id: `${base}.addOtherPayment`,
        defaultMessage: "Agregar otro pago",
    },
    organizationAddress: {
        id: `${base}.organizationAddress`,
        defaultMessage: "Dirección",
    },
    shippingAddress: {
        id: `${base}.shippingAddress`,
        defaultMessage: "Dirección de envío",
    },
    organizationPhone: {
        id: `${base}.organizationPhone`,
        defaultMessage: "Teléfono",
    },
    paymentStatus: {
        id: `${base}.paymentStatus`,
        defaultMessage: "Estado de pago",
    },
    invoiceNumber: {
        id: `${base}.invoiceNumber`,
        defaultMessage: "Número de factura",
    },
    paymentMethod: {
        id: `${base}.paymentMethod`,
        defaultMessage: "Método de pago",
    },
    orderNumber: {
        id: `${base}.orderNumber`,
        defaultMessage: "Número de orden",
    },
    orderValue: {
        id: `${base}.orderValue`,
        defaultMessage: "Valor de la orden",
    },
    shippingValue: {
        id: `${base}.shippingValue`,
        defaultMessage: "Valor de envío",
    },
    observations: {
        id: `${base}.observations`,
        defaultMessage: "Observaciones",
    },
    trackingNumber: {
        id: `${base}.trackingNumber`,
        defaultMessage: "Tracking",
    },
    trackingQr: {
        id: `${base}.trackingQr`,
        defaultMessage: "QR Tracking",
    },
    editOrder: {
        id: `${base}.editOrder`,
        defaultMessage: "Editar orden",
    },
    duplicateOrder: {
        id: `${base}.duplicateOrder`,
        defaultMessage: "Duplicar orden",
    },
    deleteOrder: {
        id: `${base}.deleteOrder`,
        defaultMessage: "Eliminar orden",
    },
    costDelivery: {
        id: `${base}.costDelivery`,
        defaultMessage: "Costo de envío",
    },
    responsibleForDelivery: {
        id: `${base}.ResponsibleForDelivery`,
        defaultMessage: "Responsable de realizar la entrega",
    },
    printOrder: {
        id: `${base}.printOrder`,
        defaultMessage: "Imprimir orden",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre",
    },
    customDate: {
        id: `${base}.customDate`,
        defaultMessage: "Fecha personalizada",
    },
    moreOrderDetails: {
        id: `${base}.moreOrderDetails`,
        defaultMessage: "Detalles de la orden",
    },
    noInvoice: {
        id: `${base}.noInvoice`,
        defaultMessage: "No hay factura",
    },
    manually: {
        id: `${base}.manually`,
        defaultMessage: "Manual",
    },
    catalog: {
        id: `${base}.catalog`,
        defaultMessage: "Catálogo",
    },
    ecommerce: {
        id: `${base}.ecommerce`,
        defaultMessage: "Ecommerce",
    },
    quantities: {
        id: `${base}.quantities`,
        defaultMessage: "Cantidades",
    },
    showQuantities: {
        id: `${base}.showQuantities`,
        defaultMessage: "Mostrar cantidades",
    },
    invoiceMadeBy: {
        id: `${base}.invoiceMadeBy`,
        defaultMessage: "Facturado por",
    },
    invoiceCreatedAt: {
        id: `${base}.invoiceCreatedAt`,
        defaultMessage: "Fecha",
    },
    numberingType: {
        id: `${base}.numberingType`,
        defaultMessage: "Numeración",
    },
    TheOrderHasBeenSuccessfullyCancelled: {
        id: `${base}.TheOrderHasBeenSuccessfullyCancelled`,
        defaultMessage: "La orden ha sido cancelada exitosamente",
    },
    theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterVoVoidTheSale: {
        id: `${base}.theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterVoVoidTheSale`,
        defaultMessage: "La tienda actual debe tener caja asignada, o debe activar una caja para anular la venta",
    },
    paymentCreatedSuccessfully: {
        id: `${base}.paymentCreatedSuccessfully`,
        defaultMessage: "Pago creado exitosamente",
    },
    youDoNotHavePermissionsToCancelOrders: {
        id: `${base}.youDoNotHavePermissionsToCancelOrders`,
        defaultMessage: "No tienes permisos para anular ordenes",
    },
    youDoNotHavePermissionsToViewThisOrder: {
        id: `${base}.youDoNotHavePermissionsToViewThisOrder`,
        defaultMessage: "No tienes permisos para ver esta orden",
    },
    TheOrderHasBeenUpdatedSuccessfully: {
        id: `${base}.TheOrderHasBeenUpdatedSuccessfully`,
        defaultMessage: "La orden ha sido actualizada exitosamente",
    },
    theCurrentDateIsSetAsTheDeliveryTime: {
        id: `${base}.theCurrentDateIsSetAsTheDeliveryTime`,
        defaultMessage: "La fecha actual se establece como la hora de entrega",
    },
    youDoNotHavePermissionsToAddPayments: {
        id: `${base}.youDoNotHavePermissionsToAddPayments`,
        defaultMessage: "No tienes permisos para agregar pagos",
    },
    youDoNotHavePermissionsToEditOrders: {
        id: `${base}.youDoNotHavePermissionsToEditOrders`,
        defaultMessage: "No tienes permisos para editar ordenes",
    },
    shippingInformation: {
        id: `${base}.shippingInformation`,
        defaultMessage: "Información de envío",
    },
    updateOrderSuccessfully: {
        id: `${base}.updateOrderSuccessfully`,
        defaultMessage: "Orden actualizada con éxito",
    },
});
