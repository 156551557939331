import { NavigationOption } from "../../NavigationPanel/navigationTypes";

export enum NavigationGroupName {
    Profile = "profile",
    Files = "files",
    EBilling = "eBilling",
    Details = "details",
    DeliveryInfo = "deliveryInfo",
    Picking = "picking",
}

export const DELIVERY_INFO_MENU: NavigationOption<NavigationGroupName> = {
    tabName: NavigationGroupName.DeliveryInfo,
    icon: "fa-regular fa-truck",
    title: "Información de Envío",
};
export const DELIVERY_PICKING_MENU: NavigationOption<NavigationGroupName> = {
    tabName: NavigationGroupName.Picking,
    icon: "fa-regular fa-truck",
    title: "Picking",
};

export const MENU_OPTIONS: NavigationOption<NavigationGroupName>[] = [
    {
        tabName: NavigationGroupName.Profile,
        icon: "fa-regular fa-home",
        title: "Perfil",
    },
    {
        tabName: NavigationGroupName.EBilling,
        icon: "fa-regular fa-file-invoice",
        title: "Factura Electrónica",
    },
    {
        tabName: NavigationGroupName.Files,
        icon: "fa-regular fa-file",
        title: "Archivos",
    },

    {
        tabName: NavigationGroupName.Details,
        icon: "fa-regular fa-file",
        title: "Detalles",
    },
];
