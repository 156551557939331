import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { SearchContainer, SearchCustom, SearchIcon } from "./SearchInput";

interface SearchInputTimerProps extends InputHTMLAttributes<HTMLInputElement> {
    inputId?: string;
    placeholder: string;
    milliseconds: number;
    initialValue?: string;
    icon?: string;
    onChange: (e: any) => void;
    rounded?: boolean;
}

export default function SearchInputTimer(props: SearchInputTimerProps) {
    const [inputId] = useState(props.inputId ?? uuid());
    const [searchState, setSearchState] = useState({
        value: props.initialValue ? props.initialValue : "",
        waiting: false,
    });

    const inputRef = React.useRef<any>();
    const timeValue = useRef(setTimeout(() => {}, 10));

    useEffect(() => {
        let element = inputRef.current;
        if (element && props.initialValue !== undefined && props.initialValue !== element.value) {
            element.value = props.initialValue;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValue]);

    function timeWaiting(e: any) {
        var target = { ...e.target, name: props.name };
        var value = target.value;

        clearTimeout(timeValue.current);
        timeValue.current = setTimeout(() => {
            setSearchState({ value, waiting: false });
            props.onChange({ target });
        }, props.milliseconds);
    }

    const onChangeHandle = (e: any) => {
        timeWaiting(e);
        if (!searchState.waiting) {
            setSearchState({ ...searchState, waiting: true });
        }
    };

    const onBlur = (e: any) => {
        if (props.onBlur) {
            props.onBlur(e.target.value);
        }
    };

    return (
        <SearchContainer className="search-input">
            <SearchIcon className={props.icon ?? "fa-regular fa-magnifying-glass"}></SearchIcon>
            <SearchCustom {...props} id={inputId} ref={inputRef} onBlur={onBlur} rounded={props.rounded} onChange={onChangeHandle} />
        </SearchContainer>
    );
}
