import styled from "styled-components";
import { DefaultButton } from "../../_controls";

export const ImageContainer = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;

    margin-right: 0.8rem;
    border-radius: 4px;
    overflow: hidden;
    margin: 6px;

    &.big {
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
        margin-right: 15px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const ProductLogCard = styled.div`
    display: flex;    
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    border-bottom: 1px solid ${props => props.theme.palette.backgroundLight};
    :last-child {
        border-bottom: none;
    }
    :hover { 
        background: ${props => props.theme.palette.backgroundLight};
        cursor: pointer;
    }
`;

export const QuantityCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 35px;
    border-radius: 15px;
    height: 35px;
    padding: 0px 10px;
    background: ${(props) => props.theme.palette.primary}25;
    color: ${(props) => props.theme.palette.primary};
    box-sizing: border-box;
    font-weight: 600;
    width: fit-content;
    &.negative {
        color: ${(props) => props.theme.palette.dangerColor};
        background: ${(props) => props.theme.palette.dangerColor}25;
    }
`;

export const OriginCard = styled.div`
    color: ${(props) => props.theme.palette.primary};
    background: ${(props) => props.theme.palette.primary}25;
    padding: 3px 9px;
    /* display: block; */
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    width: fit-content;

    &.origin-warehouseLocation {
        color: #00796b;
        background: #00796b25;
    }
    &.origin-adjustment {
        color: #00bfa5;
        background: #00bfa525;
    }
    &.origin-movement {
        color: #ff6d00;
        background: #ff6d0025;
    }
    &.origin-purchase {
        color: #7c4dff;
        background: #7c4dff25;
    }
`;

export const ButtonLocation = styled(DefaultButton)`
    padding: 1px 5px;
    font-size: 13px;
    color: ${(props) => props.theme.palette.primary};
    background: ${(props) => props.theme.palette.primary}20;
    ${(props) => (props.color ? `color: ${props.color}; background: ${props.color}25` : "")}
`;
