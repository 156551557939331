import React, { useState } from "react";
import { OrderFormOrigin } from "..";
import { Order, OrderItem } from "../../../../api/models/order";
import { OrderStatus } from "../../../../api/models/orderBase";
import { ITax } from "../../../../api/models/tax";
import { useAlert } from "../../../Alerts/Alert";
import { DefaultButton, Flex } from "../../../_controls";
import Modal from "../../../modals/Modal";
import { OrderItemsConfigModal } from "../OrderItemsSummary/OrderItemsConfig";
import { TotalContainer } from "../styled";
import ActionButton from "./ActionButton";
import OrderDetails from "./OrderDetails";
import OrderPanelHeaderTop from "./OrderPanelHeaderTop";
import OrderSummaryTotals from "./OrderSummaryTotals";

export interface OrderChangeProps {
    onOrderChange: (value: Order) => void;
    order: Order;
}

export interface OrderDetailsPanelProps extends OrderChangeProps {
    origin?: OrderFormOrigin;
    status?: OrderStatus;
    setOpenDraftModal: (value: boolean) => void;
    setConfirmClearOrder: (value: boolean) => void;
    setOpenCreateStatusOrder: (value: boolean) => void;
    updateExistingItemsPrices: () => void;
    taxItems: ITax[];
    onUpdateItems: (items: OrderItem[]) => void;
    onClickFinalizeMiddleware: () => void;
    isMobile: boolean;
}

export default function OrderDetailsPanel(props: OrderDetailsPanelProps) {
    const { isMobile } = props;

    const alert = useAlert();
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const onClickStatus = () => {
        if (props.order.orderTypeTemp === "shipment") {
            let delivery = props.order.deliveryOrder;
            if (!delivery?.contactName || !delivery.city || !delivery.address || !delivery.state) {
                alert.info("Faltan detalles de envío: Nombre de contacto, Teléfono, Ciudad, State, Dirección son requeridos");
                return;
            }
        }

        props.setOpenCreateStatusOrder(true);
    };

    return (
        <>
            <TotalContainer className={`total-container ${isMobile ? "mobile" : ""}`}>
                {isMobile && showDetailsModal && (
                    <Modal show={showDetailsModal} setShow={setShowDetailsModal} showHeader title="Detalles">
                        <Flex padding={20} column gap={20}>
                            <OrderPanelHeaderTop {...props} />

                            <OrderItemsConfigModal onUpdateAllItem={props.onUpdateItems} currentOrder={props.order} showTitle />

                            <OrderDetails
                                statusFiltered={props.status}
                                onOrderChange={props.onOrderChange}
                                order={props.order}
                                totalQuantity={props.order.itemsQuantityCount}
                                origin={props.origin}
                                availableTaxes={props.taxItems}
                                onUpdateItems={props.onUpdateItems}
                                updateExistingItemsPrices={props.updateExistingItemsPrices}
                            />

                            <OrderSummaryTotals order={props.order} onOrderChange={props.onOrderChange} />
                        </Flex>
                    </Modal>
                )}
                {!isMobile && (
                    <Flex column gap={20}>
                        <OrderPanelHeaderTop {...props} />

                        <OrderDetails
                            statusFiltered={props.status}
                            onOrderChange={props.onOrderChange}
                            order={props.order}
                            totalQuantity={props.order.itemsQuantityCount}
                            origin={props.origin}
                            availableTaxes={props.taxItems}
                            onUpdateItems={props.onUpdateItems}
                            updateExistingItemsPrices={props.updateExistingItemsPrices}
                        />
                    </Flex>
                )}
                <Flex column gap15 className="total-values-container">
                    {!isMobile && <OrderSummaryTotals order={props.order} onOrderChange={props.onOrderChange} />}
                    <Flex className={`action-btn-container ${isMobile ? "mobile" : ""}`}>
                        {isMobile && (
                            <DefaultButton
                                disabled={props.order.itemsQuantityCount === 0}
                                title="Detalles"
                                onClick={() => setShowDetailsModal(true)}
                                className="btn-details"
                            >
                                <i className="fa-regular fa-filter-list"></i>
                            </DefaultButton>
                        )}

                        <ActionButton
                            onCompleted={() => {}}
                            pageTitle={""}
                            totalItemsQuantity={props.order.itemsQuantityCount}
                            onClickFinalize={props.onClickFinalizeMiddleware}
                            onClickSaveStatus={() => onClickStatus()}
                            order={props.order}
                            onOrderChange={props.onOrderChange}
                            origin={props.origin}
                            status={props.order.status}
                        />
                    </Flex>
                </Flex>
            </TotalContainer>
        </>
    );
}
