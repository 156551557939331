import React, { useContext, useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { getServiceMessageError, wahioFetch } from "../../../api";
import { PaymentMethod } from "../../../api/models";
import { Order, OrderItem } from "../../../api/models/order";
import { IOrderReturnPayment, OrderReturnPayment } from "../../../api/models/orderPayment";
import { IOrderReturn, IOrderReturnItem, OrderReturn, loadOrderReturnItem } from "../../../api/models/orderReturn";
import { ProductPriceTypes } from "../../../api/models/product";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { formatMoney, getUniqueId } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import AlertView from "../../Alerts/AlertView";
import { CurrentRegisterLogModal } from "../../CashRegisters/CashRegisterLog";
import { getOrgCashRegisterOrDefault } from "../../CashRegisters/cashRegisterUtils";
import LoadingDualRing from "../../LoadingDualRing";
import PaymentsForm from "../../Payments/PaymentsForm";
import { PriceListDropdown } from "../../Products/PriceList";
import { ProductAddOption } from "../../Products/ProductCard";
import ProductSearchListModal from "../../Products/ProductSearchList/ProductSearchListModal";
import { DefaultButton, DefaultInput, Flex, GridTemplate, PrimaryButton, SquareButton } from "../../_controls";
import InputTimer from "../../_controls/inputs/InputTimer";
import { Table } from "../../_controls/tables/styled";
import Modal, { IModalShow } from "../../modals/Modal";
import { getNextMethod } from "../CreateOrderForm/FinalizeOrderForm/finalizeHelper";
import { calculateTotal, calculateTotalManualDiscount, calculateTotalTax, insertProductInOrderList } from "../CreateOrderForm/helpers";
import { OrderItemsRowContainer } from "../OrderItemRow";
import OrderProductCard, { OrderReturnItemCard } from "./OrderProductCard";
import messages from "./messages";
import {
    GridTabLine,
    OrderReturnFormContainer,
    OrderReturnFormStyle,
    OrderReturnProductStyle,
    OrderReturnSearchOrderStyle,
} from "./styled";

interface OrderReturnFormProps {
    orderId?: string;
    onCreate: (value: IOrderReturn) => void;
    onCloseModal?: () => void;
}

interface OrderReturnFormState {
    order?: Order;
    isFetching: boolean;
    searchOrderNumber: string;
}

const getOrderReturnWithEmptyPayments = (cashRegisterId?: string) => {
    let orderReturn = new OrderReturn(getAuthBody().accountId, getAuthBody().accountUserId);

    if (cashRegisterId) {
        let payment = new OrderReturnPayment(0, "cash", cashRegisterId, getAuthBody().accountUserId);
        payment.helperId = getUniqueId();
        orderReturn.payments = [payment];
        let returnPayment = { ...payment };
        returnPayment.helperId = getUniqueId();
        orderReturn.returnPayments = [payment];
    }
    return orderReturn;
};

export default function OrderReturnForm(props: OrderReturnFormProps) {
    const intl = useIntl();
    const { appSettingsState } = useContext(AppSettingsContext);
    const { organizationState } = useContext(OrganizationContext);
    const { cashRegisterState } = useContext(CashRegisterContext);
    const [showProductSearchModal, setShowProductSearchModal] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState<ProductPriceTypes>("price");
    const [orderReturn, setOrderReturn] = useState<IOrderReturn>(
        getOrderReturnWithEmptyPayments(getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization))
    );
    const [isCreating, setIsCreating] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [state, setState] = useState<OrderReturnFormState>({
        isFetching: false,
        searchOrderNumber: "",
    });
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [orderReturnTotals, setOrderReturnTotals] = useState({ returnAmount: 0, returnNewItemsAmount: 0 });
    let requiredCashRegister = organizationState.currentOrganization?.requiredCashRegister && !cashRegisterState.logActive;

    const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

    const alert = useAlert();

    useEffect(() => {
        calculateOrderReturnTotals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderReturn]);

    useEffect(() => {
        setOrderItems(getOrderItems());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.order, searchValue]);

    const getOrderItems = () => {
        if (searchValue) {
            return state.order?.items.filter((x) => x.product?.name.toUpperCase().includes(searchValue.toUpperCase())) ?? [];
        }
        return state.order?.items ?? [];
    };

    const calculateOrderReturnTotals = () => {
        let returnAmount = orderReturn.items.reduce((sum, element) => {
            sum += element.quantity * (element.unitPrice - (element.unitPrice * element.manualPercentageDiscount) / 100);
            return sum;
        }, 0);
        let returnNewItemsAmount = orderReturn.newItems.reduce((sum, element) => {
            sum += element.quantity * (element.unitPrice - (element.unitPrice * element.manualPercentageDiscount) / 100);
            return sum;
        }, 0);
        setOrderReturnTotals({
            returnAmount,
            returnNewItemsAmount,
        });
    };

    useEffect(() => {
        if (props.orderId) {
            setState({ ...state, isFetching: true });
            wahioFetch.get(
                localOrderEndpoint.get.orderById(props.orderId),
                (success) => {
                    let order: Order = success.data;
                    setFetchOrder(order);
                },
                (error) => {
                    alert.error(getServiceMessageError(error));
                    setState({ ...state, isFetching: false });
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orderId]);

    const handleGetOrderByNumber = (e: any) => {
        e.preventDefault();
        setState({ ...state, isFetching: true });
        wahioFetch.get(
            localOrderEndpoint.get.orderByNumber(state.searchOrderNumber, getAuthBody().accountId),
            (success) => {
                let order: Order = success.data;
                setFetchOrder(order);
            },
            (error) => {
                alert.error(intl.formatMessage(messages.theOrderCouldNotBeFound));
                setState({ ...state, isFetching: false });
            }
        );
    };

    const setFetchOrder = (order: Order) => {
        setState({ ...state, isFetching: false, order });
    };

    const onClickProduct = (value: ProductAddOption) => {
        let newItems = insertProductInOrderList({
            items: orderReturn.newItems,
            product: value.product,
            defaultPrice: appSettingsState.defaultPrice,
            warehouseProduct: value.warehouseProduct,
            productVariant: value.productVariant,
            currentStock: value.stock,
            warehouse: value.warehouse,
            quantity: value.quantity,
            price: value.customPrice,
            taxIncluded: true,
            preTaxDiscount: false,
        });
        alert.success(`${value.product.name} ${intl.formatMessage(messages.added)}`);
        setOrderReturn({ ...orderReturn, newItems });
    };

    const onClickOrderItem = (value: OrderItem) => {
        let items: IOrderReturnItem[] = [];
        let exists = false;
        orderReturn.items.forEach((element) => {
            if (element.orderItemId === value.id) {
                let newQuantity = element.quantity + 1;
                if (newQuantity > value.quantity) {
                    alert.info(intl.formatMessage(messages.noAdditionalUnitsAvailable));
                    items.push({ ...element });
                } else {
                    items.push({ ...element, quantity: newQuantity });
                }
                exists = true;
            } else {
                items.push(element);
            }
        });
        if (!exists) {
            items.push(loadOrderReturnItem(value));
            alert.success(`${value.product?.name} ${intl.formatMessage(messages.added)}`);
        }
        setOrderReturn({ ...orderReturn, items });
    };

    const onChangeReturnItem = (value: IOrderReturnItem) => {
        let items: IOrderReturnItem[] = [];

        orderReturn.items.forEach((element) => {
            if (element.orderItemId === value.orderItemId) {
                items.push(value);
            } else {
                items.push(element);
            }
        });

        setOrderReturn({ ...orderReturn, items });
    };

    const onRemoveReturnItem = (value: IOrderReturnItem) => {
        let items: IOrderReturnItem[] = orderReturn.items.filter((x) => x.orderItemId !== value.orderItemId);
        setOrderReturn({ ...orderReturn, items });
        if (items.length === 0) {
            alert.info(intl.formatMessage(messages.youMustIncludeAtLeastOneItemToCreateTheReturn));
        }
    };

    if (!state.order && !props.orderId) {
        return (
            <OrderReturnSearchOrderStyle>
                <label htmlFor="search">{intl.formatMessage(messages.searchOrder)}</label>
                <form className="input-group" onSubmit={handleGetOrderByNumber}>
                    <DefaultInput
                        className="mr-1"
                        disabled={state.isFetching}
                        value={state.searchOrderNumber}
                        onChange={(e) => setState({ ...state, searchOrderNumber: e.target.value })}
                        name="search"
                        placeholder={`# ${intl.formatMessage(messages.searchByOrderOrInvoice)}`}
                    />
                    {state.isFetching ? <LoadingDualRing /> : <PrimaryButton type="submit">{intl.formatMessage(messages.search)}</PrimaryButton>}
                </form>
            </OrderReturnSearchOrderStyle>
        );
    }

    if (state.isFetching) {
        return <LoadingDualRing center />;
    }
    const handleCreateOrderReturn = () => {
        let orderReturnBody = { ...orderReturn };

        if (orderReturnBody.newItems && orderReturnBody.newItems.length > 0) {
            let hasInvalidItem = false;
            orderReturnBody.newItems.forEach((element) => {
                if (element.warehouse?.allowLogisticsService && !element.locationCode) {
                    alert.info(intl.formatMessage(messages.theLocationCodeIsMandatory));
                    hasInvalidItem = true;
                    return;
                }
            });
            if (hasInvalidItem) return;
        }

        setIsCreating(true);

        let cashRegisterId = getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization);

        if (!cashRegisterId) {
            alert.error(intl.formatMessage(messages.theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterInOrderToAddPayments));
            setIsCreating(false);
            return;
        }

        orderReturnBody.orderId = state.order?.id ?? "";
        orderReturnBody.cashRegisterId = cashRegisterId;
        orderReturnBody.orderInvoiceNumerationId = organizationState.currentOrganization?.orderInvoiceNumerationId ?? "";
        orderReturnBody.documentType = "NOTA_CREDITO";

        wahioFetch.post(
            localOrderEndpoint.post.orderReturnCreate,
            orderReturnBody,
            (success) => {
                alert.success(intl.formatMessage(messages.returnCreatedSuccessfully));
                props.onCreate(success.data);

                setIsCreating(false);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setIsCreating(false);
            }
        );
    };

    const setPayments = (pays: IOrderReturnPayment[]) => {
        setOrderReturn({ ...orderReturn, payments: pays });
    };

    const addPayment = (paymentKey: "payments" | "returnPayments") => {
        let payments = orderReturn[paymentKey];

        const nextMethod: PaymentMethod | undefined = getNextMethod(payments);
        if (!nextMethod) return;

        let newTotalAmountDifference = orderReturnTotals.returnAmount - orderReturnTotals.returnNewItemsAmount;
        let totalNewPaymentAmount = newTotalAmountDifference < 0 ? newTotalAmountDifference * -1 : 0;

        let priceDf = totalNewPaymentAmount;
        const payAmount = priceDf > 0 ? priceDf : 0;

        let cashRegisterId = getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization);

        if (!cashRegisterId) {
            alert.error(intl.formatMessage(messages.theCurrentStoreMustHaveCashRegisterAssignedOrYouMustActivateCashRegisterInOrderToAddPayments));
            return;
        }

        let payment = new OrderReturnPayment(payAmount, nextMethod, cashRegisterId, getAuthBody().accountUserId);
        payment.helperId = getUniqueId();

        var newPayments = [...payments, payment];

        if (paymentKey === "payments") {
            setPayments(newPayments);
        } else {
            setOrderReturn({ ...orderReturn, returnPayments: newPayments });
        }
    };

    const updateReturnNewItems = (items: OrderItem[]) => {
        const totalAmountItems = calculateTotal(items);
        const totalTaxItems = calculateTotalTax(items);
        const totalManualDiscount = calculateTotalManualDiscount(items);
        setOrderReturn({
            ...orderReturn,
            newItems: items,
            totalAmountItems,
            totalTaxItems,
            totalManualPercentageDiscountItems: totalManualDiscount,
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };

    const getTotalAmountReturn = (value: number) => {
        if (state?.order) {
            let diff = state.order.totalAmount - state.order?.totalPaid;
            if (state.order?.status === "partial" && value > diff) {
                return formatMoney(value - diff);
            } else return formatMoney(value);
        }
    };

    if (state.order) {
        let newTotalAmountDifference = orderReturnTotals.returnAmount - orderReturnTotals.returnNewItemsAmount;
        let totalNewPaymentAmount = newTotalAmountDifference < 0 ? newTotalAmountDifference * -1 : 0;
        return (
            <OrderReturnFormStyle>
                {showProductSearchModal && (
                    <ProductSearchListModal
                        selectedPrice={selectedPrice}
                        show={showProductSearchModal}
                        setShow={setShowProductSearchModal}
                        onClickProduct={onClickProduct}
                        onlyWarehouseProduct={true}
                        preventCloseOnClick
                    />
                )}
                {requiredCashRegister && (
                    <CurrentRegisterLogModal useButtonClose={!requiredCashRegister} show={requiredCashRegister} setShow={() => {}} />
                )}


                <OrderReturnFormContainer>
                    {state.order && state.order.hasReturns && (
                        <Flex w100 marginBottom={10}>
                            <AlertView
                                type="warning"
                                withoutMargin
                                title={intl.formatMessage(messages.beCareful)}
                                description={intl.formatMessage(messages.thisOrderAlreadyContainsReturns)}
                            />
                        </Flex>
                    )}
                    <GridTemplate size={390} gap={20}>
                        <Flex column border gap5 borderRadius={15} padding={15}>
                            <span className="text-bold">Resumen Orden #{state.order.number}</span>
                            <Table cellPadding={0} cellSpacing={0} hideBorde tdPadding={0}>
                                <tbody>
                                    <tr>
                                        <td>{intl.formatMessage(messages.orderTotal)}:</td>
                                        <td>{formatMoney(state.order.totalAmount)}</td>
                                    </tr>
                                    {state.order.status === "partial" && (
                                        <tr>
                                            <td>{intl.formatMessage(messages.totalPaid)}:</td>
                                            <td>{formatMoney(state.order.totalPaid)}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>{intl.formatMessage(messages.totalReturns)}:</td>
                                        <td>
                                            <Flex>
                                                <span>{formatMoney(orderReturnTotals.returnAmount)}</span>
                                            </Flex>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{intl.formatMessage(messages.totalExchanges)}:</td>
                                        <td>{formatMoney(orderReturnTotals.returnNewItemsAmount)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Flex>
                        {totalNewPaymentAmount > 0 && (
                            <Flex column w100 gap15 borderRadius={15} border padding={15} boxSizingBorderBox>
                                <Flex gap15 alignCenter spaceBetween>
                                    <span className="">{intl.formatMessage(messages.payments)}</span>
                                    <SquareButton
                                        disabled={totalNewPaymentAmount === 0}
                                        rounded
                                        small
                                        onClick={() => addPayment("payments")}
                                    >
                                        <span className="wahioicon-plus-circle"></span>
                                    </SquareButton>
                                </Flex>
                                <PaymentsForm
                                    removePadding
                                    payments={orderReturn.payments ?? []}
                                    onChange={(payments) => setOrderReturn({ ...orderReturn, payments })}
                                    uniqueMethod={false}
                                    hideTitle
                                    totalAmount={totalNewPaymentAmount}
                                />
                            </Flex>
                        )}
                        {state.order.status !== "credit" &&
                            state.order.totalAmount - state.order.totalPaid < newTotalAmountDifference &&
                            newTotalAmountDifference > 0 && (
                                <Flex column w100 gap15 borderRadius={15} border padding={15} boxSizingBorderBox>
                                    <Flex gap15 alignCenter spaceBetween>
                                        <span className="text-bold">
                                            Reembolso{newTotalAmountDifference > 0 && `: ${getTotalAmountReturn(newTotalAmountDifference)}`}
                                        </span>
                                        {orderReturn.returnPayments && orderReturn.returnPayments.length === 0 && (
                                            <SquareButton
                                                disabled={newTotalAmountDifference === 0}
                                                small
                                                rounded
                                                onClick={() => addPayment("returnPayments")}
                                            >
                                                <span className="wahioicon-plus"></span>
                                            </SquareButton>
                                        )}
                                    </Flex>

                                    <PaymentsForm
                                        removePadding
                                        hidePaymentInput
                                        hideTitle
                                        payments={orderReturn.returnPayments ?? []}
                                        onChange={(payments) => setOrderReturn({ ...orderReturn, returnPayments: payments })}
                                        uniqueMethod
                                        totalAmount={newTotalAmountDifference}
                                    />
                                </Flex>
                            )}
                    </GridTemplate>

                    <Flex marginTop={15}>
                        <GridTemplate size={290} gap={15} w100>
                            <OrderReturnProductStyle>
                                <Flex column gap10 marginBottom={10}>
                                    <GridTabLine alignCenter spaceBetween>
                                        <span>{`${intl.formatMessage(messages.order)} #${state.order.number}`}</span>
                                        <SquareButton
                                            disabled={searchValue.trim().length > 0}
                                            small
                                            onClick={() => setShowSearchInput(true)}
                                        >
                                            <span className="wahioicon-search" />
                                        </SquareButton>
                                    </GridTabLine>

                                    {showSearchInput && (
                                        <Flex paddingLeft={10} paddingRight={10}>
                                            <InputTimer
                                                rounded
                                                type="search"
                                                milliseconds={400}
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value);
                                                }}
                                                placeholder={intl.formatMessage(messages.searchProducts)}
                                            />
                                        </Flex>
                                    )}
                                </Flex>

                                <Flex column gap5 padding={10} className="flex-fixed-height">
                                    {orderItems.map((item, index) => {
                                        return (
                                            <OrderProductCard
                                                added={!!orderReturn.items.find((x) => x.orderItemId === item.id)}
                                                onClick={onClickOrderItem}
                                                item={item}
                                                key={index}
                                            />
                                        );
                                    })}
                                </Flex>
                            </OrderReturnProductStyle>
                            <OrderReturnProductStyle>
                                <GridTabLine>
                                    <span>{`${intl.formatMessage(messages.returns)}${orderReturn.items.length > 0 ? `(${orderReturn.items.length})` : ""}`}</span>
                                </GridTabLine>

                                {orderReturn.items.length === 0 && (
                                    <Flex gap10 column padding={20} w100 alignCenter boxSizingBorderBox className="text-light text-small">
                                        <span>{intl.formatMessage(messages.selectTheProductsYouWantToReturn)}</span>
                                        <span className="wahioicon-arrow-left"></span>
                                    </Flex>
                                )}
                                <Flex column padding={10}>
                                    {orderReturn.items.map((item, index) => {
                                        return (
                                            <OrderReturnItemCard
                                                item={item}
                                                key={index}
                                                onRemove={onRemoveReturnItem}
                                                onChange={onChangeReturnItem}
                                            />
                                        );
                                    })}
                                </Flex>
                            </OrderReturnProductStyle>
                            <OrderReturnProductStyle>
                                {state.order.isDelivery ? (
                                    <div>
                                        <AlertView
                                            type="info"
                                            title={intl.formatMessage(messages.changesCannotBeAddedToOrders)}
                                            description={intl.formatMessage(messages.toAddChangesToAnOrderYouMustCreateNewOrderOrdersCanOnlyHaveReturns)}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Flex column gap15>
                                            <GridTabLine alignCenter spaceBetween>
                                                <span>Cambios{orderReturn.newItems.length > 0 && ` (${orderReturn.newItems.length})`}</span>
                                                <SquareButton rounded small onClick={() => setShowProductSearchModal(true)}>
                                                    <span className="wahioicon-plus"></span>
                                                </SquareButton>
                                            </GridTabLine>

                                            <Flex paddingLeft={15} paddingRight={15}>
                                                <PriceListDropdown small selectedPrice={selectedPrice} onSelected={setSelectedPrice} />
                                            </Flex>

                                            {orderReturn.newItems.length === 0 && (
                                                <Flex padding={20} w100 alignCenter justifyCenter boxSizingBorderBox>
                                                    <div>
                                                        <span>{intl.formatMessage(messages.clickOn)}</span>
                                                        {"  "}
                                                        <span className="wahioicon-plus"></span>
                                                        {"  "}
                                                        <span>{intl.formatMessage(messages.toAddAnExchange)}</span>
                                                    </div>
                                                </Flex>
                                            )}

                                            <Flex column className="flex-fixed-height">
                                                <OrderItemsRowContainer
                                                    items={orderReturn.newItems ?? []}
                                                    onChange={updateReturnNewItems}
                                                />
                                            </Flex>
                                        </Flex>
                                    </>
                                )}
                            </OrderReturnProductStyle>
                        </GridTemplate>
                    </Flex>
                    {orderReturn.items.length > 0 &&
                        <Flex gap15 justifyEnd alignEnd marginTop={15} >
                            {props.onCloseModal &&
                                <DefaultButton borderRadius={10} onClick={props.onCloseModal}>
                                    {intl.formatMessage(messages.cancel)}
                                </DefaultButton>
                            }
                            <Flex minWidth={150}>
                                <PrimaryButton w100 borderRadius={10} onClick={handleCreateOrderReturn}>
                                    {isCreating ? <LoadingDualRing small /> : intl.formatMessage(messages.createOrderReturns)}
                                </PrimaryButton>
                            </Flex>
                        </Flex>
                    }
                </OrderReturnFormContainer>
            </OrderReturnFormStyle>
        );
    }
    return <p>Not found</p>;
}

interface OrderReturnFormModalProps extends IModalShow, OrderReturnFormProps {
    useButtonClose?: boolean;
}

export const OrderReturnFormModal = (props: OrderReturnFormModalProps) => {
    const onCreate = (value: IOrderReturn) => {
        props.onCreate(value);
        props.setShow(false);
    };
    const onCloseModal = () => {
        props.setShow(false);
    };
    const intl = useIntl();
    return (
        <Modal
            scrollInside={false}
            overflowAuto={false}
            removeVerticalAlign={true}
            ignoreOutsideClick
            sizeType="md3"
            title={intl.formatMessage(messages.returnAndExchanges)}
            showHeader={true}
            useMobileView
            useButtonClose={props.useButtonClose}
            {...props}
        >
            <OrderReturnForm {...props} onCloseModal={onCloseModal} onCreate={onCreate} />
        </Modal>
    );
};
