import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { LocalStoreKeys, getStoredData, saveData } from "..";
import { ITax } from "../../api/models/tax";
import { getTaxesByAccount } from "../../api/products/taxApi";
import { getAuthBody } from "../../auth";
import { useLocalStorage } from "../../utils/hooks";

export interface ITaxState {
    items: ITax[];
}

interface ITaxActions {
    fetchIfNotExists: () => void;
    removeItem: (itemId: string) => void;
    update: (item: ITax) => void;
    add: (item: ITax) => void;
}

interface IContextProps {
    taxState: ITaxState;
    setTaxState?: (value: ITaxState) => void;
    localTaxes: ITax[];
    setLocalTaxes: (value: ITax[]) => void;
    taxActions: ITaxActions;
    isFetching: boolean;
    getTaxById: (id: string) => ITax | undefined;
}

export class InitStateBase implements ITaxState {
    items: ITax[] = [];
    error: any = undefined;
    isFetching: boolean = false;
    isError: boolean = false;
}

export const initState = getStoredData<ITaxState>(new InitStateBase(), LocalStoreKeys.tax);

export const TaxContext = createContext({} as IContextProps);

const TaxContextProvider = (props: any) => {
    const [taxState, setTaxState] = useState(initState);

    const taxObj = useMemo(() => {
        return taxState.items.reduce((acc, curr) => {
            acc[curr.id ?? "na"] = curr;
            return acc;
        }, {} as { [key: string]: ITax });
    }, [taxState.items]);

    const getTaxById = (id: string): ITax | undefined => {
        return taxObj[id];
    };

    const [localTaxIds, setLocalTaxIds] = useLocalStorage<string[]>("LOCAL_TAXES_LIST", []);

    const localTaxes = React.useMemo(() => {
        return taxState.items.filter((x) => localTaxIds.includes(x.id ?? "na"));
    }, [localTaxIds, taxState.items]);

    const setLocalTaxes = (value: ITax[]) => {
        const ids = value.map((x) => x.id ?? "na");
        setLocalTaxIds(ids);
    };

    useEffect(() => {
        saveData(LocalStoreKeys.tax, taxState);
    }, [taxState]);

    const taxesMutation = useMutation((accountId: string) => getTaxesByAccount(accountId), {
        onSuccess: (data) => {
            setTaxState({ ...taxState, items: data });
        },
    });

    const fetchIfNotExists = () => {
        if (taxesMutation.isLoading) return;
        if (taxState.items.length > 0) return;
        taxesMutation.mutate(getAuthBody().accountId);
    };

    const taxActions = {
        fetchIfNotExists,
        removeItem: (itemId: string) => {
            setTaxState({ ...taxState, items: taxState.items.filter((x) => x.id !== itemId) });
        },
        update: (item: ITax) => {
            setTaxState({ ...taxState, items: taxState.items.map((x) => (x.id === item.id ? item : x)) });
        },
        add: (item: ITax) => {
            setTaxState({ ...taxState, items: [...taxState.items, item] });
        },
    };

    return (
        <TaxContext.Provider
            value={{ taxState, setTaxState, getTaxById, taxActions, isFetching: taxesMutation.isLoading, localTaxes, setLocalTaxes }}
        >
            {props.children}
        </TaxContext.Provider>
    );
};

export const useTaxContext = () => {
    return useContext(TaxContext);
};

export default TaxContextProvider;
