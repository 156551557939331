import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useEventListenerDocument } from "../..";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { DeliveryStatus } from "../../../api/models/deliveryOrder";
import {
    Order,
    IOrderPicking,
    IOrderPickingItem,
    IOrderPickingSetCompletedModel,
    IOrderPickingUpdateModel,
} from "../../../api/models/order";
import { IWarehouseLocation, WarehouseLocationProductSearch } from "../../../api/models/warehouseLocation";
import { ProductLocationValidationItem, ProductLocationValidationItemResponse } from "../../../api/models/warehouseProduct";
import { localOrderEndpoint, productEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import { COLOR_LIST } from "../../../constants/colors";
import PATHS from "../../../constants/paths";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { getProductThumbnailUrl } from "../../Products/helper";
import WarehouseLocationSelectModal from "../../Warehouses/WarehouseLocations/WarehouseLocationSelectModal";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, SquareButton } from "../../_controls";
import SearchInputTimer from "../../_controls/inputs/SearchInputTimer";
import ConfirmationModal from "../../modals/ConfirmationModal";
import Modal from "../../modals/Modal";
import PickingItemSelectedModal from "./PickingItemSelectedModal";
import { getPickingLocationValidation } from "./orderProfileUtils";
import { LocationListContainer, LocationSelectedCard, OrderPickingContainer, OrderPickingHeaderCards, OrderPickingItem } from "./styled";

import QRCode from "qrcode.react";

export interface ProductLocationValidationState {
    validationLoaded: boolean;
    validationResponses: ProductLocationValidationItemResponse[];
}
interface PickingOrderViewProps {
    order: Order;
    onMoveShipmentStatus: (status: DeliveryStatus) => void;
    onPickingCompleted: () => void;
    onUpdate?: (orderPicking: IOrderPicking) => void;
}

interface PickingItemProps {
    item: IOrderPickingItem;
    orderPicking: IOrderPicking;
    onChange: (item: IOrderPickingItem) => void;
    onClickItem: (item: IOrderPickingItem) => void;
    onUpdateLocation: (item: IOrderPickingItem) => void;
    productLocationValidation: ProductLocationValidationState;
    warehouseId?: string;
}

const PickingItem = (props: PickingItemProps) => {
    const { item, productLocationValidation } = props;
    const { product, productVariant } = item;
    const alert = useAlert();
    const { userState } = useContext(UserContext);
    const isCompleted = item.quantity === item.quantityReceived;
    const permission = getUserPermissions(userState.user).order;
    const [warehouseLocations, setWarehouseLocations] = useState<IWarehouseLocation[]>([]);
    const [showWarehouseLocationSelectorModal, setShowWarehouseLocationSelectorModal] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const onHandleCompletedAll = () => {
        if (!getUserPermissions(userState.user).order?.completePickingItem) {
            alert.info("No tienes permiso para completar Picking Item automáticamente");
            return;
        }
        props.onChange({ ...item, quantityReceived: item.quantity });
    };

    const image = getProductThumbnailUrl(product, productVariant);

    const fetchWarehouseLocationsByProduct = (onResult?: (locations: IWarehouseLocation[]) => void) => {
        const warehouseId = props.warehouseId;

        if (warehouseId) {
            let input: WarehouseLocationProductSearch = {
                productId: item.productId,
                warehouseId: warehouseId,
                productVariantId: item.productVariantId,
            };
            setIsFetching(true);
            wahioFetch.post(
                productEndpoint.post.warehouseLocationByProduct,
                input,
                (success) => {
                    setWarehouseLocations(success.data);
                    setIsFetching(false);
                    if (onResult) {
                        onResult(success.data);
                    }
                },
                (error) => {
                    alert.error(getServiceMessageError(error));
                    setIsFetching(false);
                }
            );
        }
    };

    const handleUpdateLocation = () => {
        const onResult = (locations: IWarehouseLocation[]) => {
            const matchLocation = locations.find((x) => x.globalStock >= item.quantity);
            if (matchLocation) {
                props.onUpdateLocation({ ...item, location: matchLocation, locationCode: matchLocation.code });
            } else {
                alert.info("No se encontró ninguna ubicación con stock suficiente");
            }
        };
        fetchWarehouseLocationsByProduct(onResult);
    };

    const handleShowWarehouseLocationsModal = () => {
        const onResult = (locations: IWarehouseLocation[]) => {
            setShowWarehouseLocationSelectorModal(true);
        };
        fetchWarehouseLocationsByProduct(onResult);
    };

    const locationResult = getPickingLocationValidation(productLocationValidation, item);
    const hasInvalidLocation = !locationResult.isValid;

    return (
        <OrderPickingItem
            locationColor={(COLOR_LIST as any)[item.location?.color ?? "notColor"]}
            className={`picking-item ${isCompleted ? "completed" : ""}`}
        >
            {showWarehouseLocationSelectorModal && (
                <WarehouseLocationSelectModal
                    show={showWarehouseLocationSelectorModal}
                    setShow={setShowWarehouseLocationSelectorModal}
                    warehouseLocations={warehouseLocations}
                    stockMin={item.quantity}
                    onClickLocation={(location) => {
                        props.onUpdateLocation({ ...item, location, locationCode: location.code });
                        setShowWarehouseLocationSelectorModal(false);
                    }}
                />
            )}
            <div>
                {props.orderPicking.status !== "completed" && hasInvalidLocation && (
                    <div className="item-alert">
                        <span className="wahioicon-exclamation-triangle mr-1"></span>
                        <span className="mr-1">
                            {item.locationCode} No contiene stock suficiente ({locationResult.locationProduct?.stock})
                        </span>
                        <DefaultButton rounded className="small filled" onClick={() => handleUpdateLocation()}>
                            <span className="wahioicon-redo-alt"></span> Actualizar Ubicación
                        </DefaultButton>
                    </div>
                )}
            </div>
            <div className="item-body">
                <div className="FlexCol pick">
                    <span className="light">Pick</span>
                    <span className="quantity">{item.quantity}</span>
                </div>
                <div className="image mr-1">
                    <img src={image} alt={item.product?.name} />
                </div>
                <div className="item-card">
                    <div className="FlexCol">
                        <span className="item-title">
                            {product?.name}
                            {productVariant && `: ${productVariant.valuesLine}`}
                        </span>
                        <span className="light">Code: {productVariant ? productVariant.code : product?.code}</span>
                        <span className="light">Sku: {productVariant ? productVariant.sku : product?.sku}</span>
                    </div>
                    <div className="card-actions">
                        <div className={`${isCompleted ? "check" : ""} location-item  `}>
                            <span className="location-code">{item.quantityReceived} on Hand</span>
                        </div>

                        <div onClick={handleShowWarehouseLocationsModal} className="location-item locationcolor">
                            <span className="wahioicon-exchange-alt mr-1"></span> <span className="location-code">{item.locationCode}</span>
                        </div>
                        {isFetching && <LoadingDualRing small className="mr-1" />}
                        {!hasInvalidLocation && (
                            <>
                                <SquareButton disabled={!permission?.update} className="edit mr-1" onClick={() => props.onClickItem(item)}>
                                    <span className="wahioicon-pen"></span>
                                </SquareButton>
                                {item.quantity !== item.quantityReceived && (
                                    <SquareButton disabled={!permission?.update} className="edit mr-1" onClick={onHandleCompletedAll}>
                                        <span className="wahioicon-check"></span>
                                    </SquareButton>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </OrderPickingItem>
    );
};

export default function PickingOrderView(props: PickingOrderViewProps) {
    const { order } = props;
    const alert = useAlert();
    const [openFinalizeModal, setOpenFinalizeModal] = useState(false);
    const [currentPicking, setCurrentPicking] = useState(order.orderPicking);
    const [searchValue, setSearchValue] = useState("");
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);
    const { userState } = useContext(UserContext);
    const [binCodeCreation, setBinCodeCreation] = useState({
        binCode: "",
        isFetching: false,
    });
    const [locationList, setLocationList] = useState<IWarehouseLocation[]>([]);
    const [matchItemResult, setMatchItemResult] = useState<IOrderPickingItem[]>([]);
    const permission = getUserPermissions(userState.user).order;

    const [productLocationValidationState, setProductLocationValidationState] = useState<ProductLocationValidationState>({
        validationLoaded: false,
        validationResponses: [],
    });

    const [isFetchingPicking, setIsFetchingPicking] = useState(false);
    const warehouseId = order.items && order.items.length > 0 ? order.items[0].warehouseId : undefined;

    useEffect(() => {
        setCurrentPicking(order.orderPicking);
    }, [order]);

    useEffect(() => {
        validateOrderStatus();
        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }
        if (warehouseId) {
            wahioFetch.get(
                productEndpoint.get.warehouseLocationsByWarehouse(warehouseId),
                (success) => {
                    setLocationList(success.data);
                },
                (error) => {
                    alert.error("Error al leer las ubicaciones");
                }
            );
        }
        if (currentPicking) handleValidateProductLocation(currentPicking.items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentPicking && locationList.length > 0) {
            let items: IOrderPickingItem[] = [];
            currentPicking.items.forEach((element) => {
                let location = locationList.find((x) => x.code.toUpperCase() === element.locationCode.toUpperCase());
                element.location = location;
                items.push(element);
            });

            setCurrentPicking({ ...currentPicking, items });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationList]);

    let barcodeValue = "";
    let barcodeInterval: any = undefined;

    const validateOrderStatus = () => {
        if (currentPicking?.binCode && props.order.deliveryOrder?.status === "processing") {
            props.onMoveShipmentStatus("picking");
        }
    };

    const handler = (e: any) => {
        if (!permission?.update) return;
        if (barcodeInterval) {
            clearInterval(barcodeInterval);
        }
        if (e.code === "Enter") {
            if (barcodeValue) {
                barcodeValue = barcodeValue.replaceAll("Shift", "");
                barcodeValue = barcodeValue.toUpperCase();

                if (matchItemResult.length > 1) {
                    var item = matchItemResult.find((x) => x.locationCode === barcodeValue);
                    if (item) {
                        alert.success("Producto " + item.product?.name + ": " + barcodeValue);
                        setMatchItemResult([item]);
                    } else {
                        alert.info("Ninguna ubicación hace match con el código " + barcodeValue);
                    }
                } else {
                    if (currentPicking?.status === "completed") {
                        barcodeValue = "";
                        alert.info("Picking ya fue completado");
                        return;
                    }
                    let selectedOrderItem = matchItemResult.length === 1 ? matchItemResult[0] : undefined;
                    if (selectedOrderItem) {
                        let newQuantity = selectedOrderItem.quantityReceived + 1;
                        if (newQuantity <= selectedOrderItem.quantity) {
                            setMatchItemResult([{ ...selectedOrderItem, quantityReceived: newQuantity }]);
                        } else {
                            alert.info("No puedes agregar mas unidades de este producto");
                        }
                    } else {
                        let existingProductList = currentPicking?.items.filter(
                            (x) => x.product?.code.toUpperCase() === barcodeValue || x.product?.sku.toUpperCase() === barcodeValue
                        );

                        if (existingProductList && existingProductList.length > 0) {
                            setMatchItemResult(existingProductList);
                        } else {
                            alert.error(`Ningún producto con el código ${barcodeValue} fue encontrado en el pedido.`);
                        }
                    }
                }

                barcodeValue = "";
                return;
            }
            return;
        }
        if (e.code !== "Shift") {
            barcodeValue += e.key;
        }
        barcodeInterval = setInterval(() => (barcodeValue = ""), 20);
    };
    // Add event listener using our hook

    useEventListenerDocument("keydown", handler);

    if (!currentPicking) return null;

    const onClickLocation = (code: string) => {
        var item = matchItemResult.find((x) => x.locationCode === code);
        if (item) {
            setMatchItemResult([item]);
        } else {
            alert.info("Ninguna ubicación hace match con el código " + barcodeValue);
        }
    };

    const handleBinCode = (binCode: string) => {
        setBinCodeCreation({
            ...binCodeCreation,
            isFetching: true,
        });

        let pickingUpdate: IOrderPickingUpdateModel = {
            binCode: binCode,
            accountUserId: getAuthBody().accountUserId,
            orderPickingId: currentPicking?.id ?? "",
        };

        wahioFetch.put(
            localOrderEndpoint.put.orderPickingSetBincode,
            pickingUpdate,
            (success) => {
                setBinCodeCreation({
                    ...binCodeCreation,
                    isFetching: false,
                });
                const picking = { ...currentPicking, binCode: binCode, endAccountUserId: success.data.endAccountUserId };
                setCurrentPicking(picking);
                if (props.onUpdate) {
                    props.onUpdate(picking);
                }
                props.onMoveShipmentStatus("picking");
                alert.success("Contenedor actualizado con éxito.");
            },
            (error) => {
                setBinCodeCreation({
                    ...binCodeCreation,
                    isFetching: false,
                });
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const onChangeItem = (item: IOrderPickingItem, updateProductLocation?: boolean) => {
        let newItems: IOrderPickingItem[] = [];
        currentPicking.items.forEach((value) => {
            if (value.id === item.id) {
                newItems.push(item);
            } else {
                if (value.quantityReceived > value.quantity) {
                    value.quantityReceived = value.quantity;
                }
                newItems.push(value);
            }
        });
        let totalReceived = 0;
        newItems.forEach((element) => {
            totalReceived += element.quantityReceived;
        });
        let newPicking = { ...currentPicking, items: newItems, totalQuantityReceived: totalReceived };

        handleUpdatePicking(newPicking, updateProductLocation);
    };

    const onUpdateItemLocation = (item: IOrderPickingItem) => {
        onChangeItem(item, true);
    };

    const handleUpdateAllItems = () => {
        if (!getUserPermissions(userState.user).order?.completeAllPicking) {
            alert.info("No tienes permiso para completar el picking, debes solicitar permisos");
            return;
        }

        let newItems: IOrderPickingItem[] = [];
        currentPicking.items.forEach((value) => {
            value.quantityReceived = value.quantity;
            newItems.push(value);
        });
        let totalReceived = 0;

        newItems.forEach((element) => {
            totalReceived += element.quantityReceived;
        });
        let newPicking = { ...currentPicking, items: newItems, totalQuantityReceived: totalReceived };

        handleUpdatePicking(newPicking);
    };

    const getPickerUserName = (userId: string) => {
        let user = accountUserState.items.find((x) => x.id === userId);
        if (user) {
            return user.firstName + " " + user.lastName;
        }
        return "---";
    };

    const handleValidateProductLocation = (pickingItems: IOrderPickingItem[]) => {
        let items: ProductLocationValidationItem[] = [];
        const warehouseId = order.items && order.items.length > 0 ? order.items[0].warehouseId : undefined;

        if (warehouseId) {
            pickingItems.forEach((element) => {
                items.push({
                    productId: element.productId,
                    productVariantId: element.productVariantId,
                    warehouseId,
                    locationCode: element.locationCode,
                });
            });

            wahioFetch.post(
                productEndpoint.post.productLocationValidation,
                { items },
                (success) => {
                    setProductLocationValidationState({
                        ...productLocationValidationState,
                        validationLoaded: true,
                        validationResponses: success.data,
                    });
                },
                (error) => {
                    setProductLocationValidationState({
                        ...productLocationValidationState,
                        validationLoaded: false,
                        validationResponses: [],
                    });
                    alert.error(getServiceMessageError(error));
                }
            );
        }
    };

    const handleUpdatePicking = (pickingModel: IOrderPicking, updateProductLocations?: boolean) => {
        setIsFetchingPicking(true);

        wahioFetch.put(
            localOrderEndpoint.put.orderPickingUpdateBin,
            pickingModel,
            (success) => {
                setIsFetchingPicking(false);
                if (updateProductLocations) {
                    handleValidateProductLocation(pickingModel.items);
                }
                const picking = { ...pickingModel, status: success.data.status };
                setCurrentPicking(picking);
                if (props.onUpdate) {
                    props.onUpdate(picking);
                }
                alert.success("Picking actualizado con éxito.");
            },
            (error) => {
                setIsFetchingPicking(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const handleFinalizePicking = (pickingModel: IOrderPicking) => {
        setIsFetchingPicking(true);

        let pickingUpdate: IOrderPickingSetCompletedModel = {
            accountUserId: getAuthBody().accountUserId,
            orderPickingId: currentPicking?.id ?? "",
        };

        wahioFetch.put(
            localOrderEndpoint.put.orderPickingSetCompleted,
            pickingUpdate,
            (success) => {
                setIsFetchingPicking(false);
                setCurrentPicking({ ...pickingModel, status: success.data.status });
                props.onPickingCompleted();
                alert.success("El Picking fue completado con éxito.");
            },
            (error) => {
                setIsFetchingPicking(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const getSvgUrl = () => {
        var svg = document.getElementById("svg-image");

        if (!svg) return "";

        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg);

        //add name spaces.
        if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        //convert svg source to URI data scheme.
        var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
        return url;
    };

    if (!currentPicking.binCode) {
        return (
            <OrderPickingContainer>
                <NavLink to={PATHS.shipmentsListPickingPreview(order.id ?? "invalid")}>
                    <h3 className="picking-title">Picking order {currentPicking.status === "completed" && ": (Completado)"}</h3>
                </NavLink>
                <p>Ingresa el código del contenedor para continuar con el Picking.</p>
                <Flex alignCenter flexWrap gap10>
                    <DefaultInput
                        disabled={!permission?.update}
                        value={binCodeCreation.binCode}
                        onChange={(e) => setBinCodeCreation({ ...binCodeCreation, binCode: e.target.value })}
                        placeholder="Código del contenedor"
                    />
                    <PrimaryButton borderRadius={8} disabled={!permission?.update} onClick={() => handleBinCode(binCodeCreation.binCode)}>
                        Confirmar e Iniciar Picking
                    </PrimaryButton>
                    <DefaultButton borderRadius={8} disabled={!permission?.update} onClick={() => handleBinCode(uuid())}>
                        Crear Random code
                    </DefaultButton>
                    {binCodeCreation.isFetching && <LoadingDualRing small={true} />}
                </Flex>
            </OrderPickingContainer>
        );
    }

    var pickingListSelected = matchItemResult.length > 0;

    const getPickingItems = () => {
        if (!currentPicking.items) return [];
        if (searchValue) {
            let search = searchValue.toLowerCase();
            let items = currentPicking.items.filter((x) => x.product?.name.toLowerCase().includes(search));
            return items;
        } else {
            return currentPicking.items;
        }
    };

    return (
        <OrderPickingContainer>
            {openFinalizeModal && (
                <ConfirmationModal
                    onCancel={() => setOpenFinalizeModal(false)}
                    onOkay={() => {
                        handleFinalizePicking(currentPicking);
                        setOpenFinalizeModal(false);
                    }}
                    title="¿Estás seguro de que quieres completar el Picking?"
                    description={`Esta acción no se puede revertir, el envío se moverá a Packing y el pedido no se podrá editar`}
                    show={openFinalizeModal}
                    setShow={setOpenFinalizeModal}
                />
            )}
            {pickingListSelected && (
                <Modal
                    borderRadius={35}
                    show={pickingListSelected}
                    setShow={() => setMatchItemResult([])}
                    id="modal-order-picking-item"
                    showHeader={false}
                    title=""
                >
                    {matchItemResult.length === 1 ? (
                        <PickingItemSelectedModal
                            item={matchItemResult[0]}
                            onChange={(item) => {
                                onChangeItem(item);
                                setMatchItemResult([]);
                            }}
                        />
                    ) : (
                        <LocationListContainer>
                            <h1 className="title">
                                <span className="wahioicon-barcode mr-1"></span> Selecciona una ubicación
                            </h1>
                            <hr />
                            <div className="card-list">
                                {matchItemResult.map((item, index) => (
                                    <LocationSelectedCard
                                        onClick={() => {
                                            onClickLocation(item.locationCode);
                                        }}
                                        locationColor={(COLOR_LIST as any)[item.location?.color ?? "notColor"]}
                                        className="card"
                                        key={index}
                                    >
                                        <span>
                                            {item.locationCode} {item.quantity === item.quantityReceived ? " PICKING COMPLETO" : ""}
                                        </span>
                                    </LocationSelectedCard>
                                ))}
                            </div>
                        </LocationListContainer>
                    )}
                </Modal>
            )}

            <NavLink to={PATHS.shipmentsListPickingPreview(order.id ?? "invalid")}>
                <h3 className="picking-title">Picking order{currentPicking.status === "completed" && ": (Completado)"}</h3>
            </NavLink>

            <div className="display-none">
                <QRCode size={500} value={currentPicking.binCode} />
            </div>
            <OrderPickingHeaderCards>
                <div className="header-card">
                    <div className="mr-1">
                        <QRCode size={40} value={currentPicking.binCode} />
                    </div>

                    <div className="col">
                        <span className="bintitle">{currentPicking.binCode}</span>
                        <span className="subtitle">Picking Bin</span>
                    </div>

                    <a download={"BIN-" + currentPicking.binCode + ".svg"} href={getSvgUrl()}>
                        <SquareButton className="ml-1">
                            <span className="wahioicon-download"></span>
                        </SquareButton>
                    </a>
                </div>
                <div className="header-card">
                    <span className="wahioicon-user1 icon mr-1"></span>
                    <div className="col">
                        <span className="bintitle">{getPickerUserName(currentPicking.accountUserId)}</span>
                        <span className="subtitle">Picker</span>
                    </div>
                </div>
                {currentPicking.endAccountUserId && (
                    <div className="header-card">
                        <span className="wahioicon-user1 icon mr-1"></span>
                        <div className="col">
                            <span className="bintitle">{getPickerUserName(currentPicking.endAccountUserId)}</span>
                            <span className="subtitle">Completado por</span>
                        </div>
                    </div>
                )}

                <div className="header-card">
                    <div className="col">
                        <span>
                            {currentPicking.totalQuantityReceived} de {currentPicking.totalQuantity}
                        </span>
                        <span className="subtitle">Total Empacado</span>
                    </div>
                    <span className="percentage">
                        {Math.round((100 * currentPicking.totalQuantityReceived) / currentPicking.totalQuantity)}%
                    </span>
                </div>
                {isFetchingPicking && <LoadingDualRing small={true} />}
            </OrderPickingHeaderCards>
            <Flex alignCenter gap10>
                <SearchInputTimer
                    placeholder="Buscar productos"
                    milliseconds={500}
                    inputId="searchpickupitems"
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                />
                {currentPicking.totalQuantityReceived !== currentPicking.totalQuantity && (
                    <DefaultButton disabled={!permission?.update} className="ml-1" rounded onClick={() => handleUpdateAllItems()}>
                        Completar Todos los Items
                    </DefaultButton>
                )}
            </Flex>

            <div>
                {getPickingItems().map((item) => (
                    <PickingItem
                        productLocationValidation={productLocationValidationState}
                        onClickItem={(item) => setMatchItemResult([item])}
                        key={item.id}
                        item={item}
                        warehouseId={warehouseId}
                        onUpdateLocation={onUpdateItemLocation}
                        onChange={onChangeItem}
                        orderPicking={currentPicking}
                    />
                ))}
            </div>

            {currentPicking.status !== "completed" &&
                currentPicking.totalQuantityReceived === currentPicking.totalQuantity &&
                !isFetchingPicking && (
                    <div className="actions">
                        <PrimaryButton disabled={!permission?.update} onClick={() => setOpenFinalizeModal(true)}>
                            Completar Picking y mover a Packing
                        </PrimaryButton>
                    </div>
                )}
        </OrderPickingContainer>
    );
}
