import React, { useContext } from "react";
import { IWarehouseProductLocation } from "../../../api/models/warehouseLocation";
import DropdownClassic from "../../DropdownClassic";
import DropdownClassicOptions from "../../DropdownClassic/DropdownClassicOptions";
import { WarehouseProductLocationCardView } from "./styled";
import { getProductSkuAndCode, getProductThumbnailUrl } from "../../Products/helper";
import { UserContext } from "../../../store/contexts/UserContext";
import { getUserPermissions } from "../../../api/models/accountUser";

interface WarehouseProductLocationCardProps {
    item: IWarehouseProductLocation;
    setSelectedProductLocation: (type: SelectedProductLocationType, value: IWarehouseProductLocation) => void;
    hideDropdown?: boolean;
}

export type SelectedProductLocationType = "movement" | "adjustment";

export default function WarehouseProductLocationCard(props: WarehouseProductLocationCardProps) {
    const { item } = props;
    const { warehouseProduct } = item;
    const { product, productVariant } = warehouseProduct;
    const { userState } = useContext(UserContext);

    const image = getProductThumbnailUrl(product, productVariant);
    if (item.warehouseProduct && item.warehouseProduct.product) {
        let product = item.warehouseProduct.product;
        const permissionProductLocation = getUserPermissions(userState.user).warehouse?.update;
        return (
            <WarehouseProductLocationCardView>
                <div className="image">
                    <img src={image} alt={product.name} />
                </div>
                <div className="content">
                    <span className="title">{item.warehouseProduct.product.name}</span>
                    <span>{getProductSkuAndCode(product, productVariant)}</span>
                </div>
                <div className="content left row">
                    <span className="product-stock">{item.stock}</span>

                    {!props.hideDropdown && permissionProductLocation && (
                        <DropdownClassic minWidthAuto={true} id={item.id} mainIcon="wahioicon-ellipsis-h" right={true} value="">
                            <DropdownClassicOptions>
                                <li onClick={() => props.setSelectedProductLocation("movement", item)}>
                                    <span className="wahioicon-dolly icon"></span>
                                    Mover stock
                                </li>
                                <li onClick={() => props.setSelectedProductLocation("adjustment", item)}>
                                    <span className="wahioicon-calculator icon"></span> Ajustar Stock
                                </li>
                            </DropdownClassicOptions>
                        </DropdownClassic>
                    )}
                </div>
            </WarehouseProductLocationCardView>
        );
    }
    return null;
}
