import { IOrderReturnPayment } from "./orderPayment";
import { IPurchaseReturnItem } from "./orderReturn";
import { IPurchase, IPurchaseItem } from "./purchase";

export interface IPurchaseReturn {
    id?: string;
    purchaseId: string;
    purchase?: IPurchase;
    newPurchaseId?: string;
    accountId: string;
    accountUserId: string;
    cashRegisterId: string;
    newItems: IPurchaseItem[];
    payments: IOrderReturnPayment[];
    number: number;
    totalAmountNewItems: number;
    warehouseId: string;
    items: IPurchaseReturnItem[];
    itemsQuantityCount: number;
    dateCreated?: Date;
    totalAmount: number;
    totalManualPercentageDiscountItems: number;
    totalTaxItems: number;
    totalItems: number;
    returnPayments: IOrderReturnPayment[];
    organizationId: string;
    documentType: string;
}

export class PurchaseReturn implements IPurchaseReturn {
    purchaseId: string = "";
    purchase?: IPurchase | undefined;
    number: number = 0;
    cashRegisterId: string = "";
    warehouseId: string = "";
    accountId: string;
    accountUserId: string;
    items: IPurchaseReturnItem[] = [];
    itemsQuantityCount: number = 0;
    newItems: IPurchaseItem[] = [];
    dateCreated?: Date | undefined;
    totalAmount: number = 0;
    totalAmountNewItems: number = 0;
    totalManualPercentageDiscountItems: number = 0;
    totalTaxItems: number = 0;
    totalItems: number = 0;
    payments: IOrderReturnPayment[] = [];
    returnPayments: IOrderReturnPayment[] = [];
    organizationId: string = "";

    constructor(accountId: string, accountUserId: string) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
    }
    id?: string | undefined;
    newPurchaseId?: string | undefined;
    documentType: string = "NOTA_DEBITO";
}

export const loadPurchaseReturnItem = (orderItem: IPurchaseItem) => {
    let item: IPurchaseReturnItem = {
        purchaseItemId: orderItem.id ?? "",
        purchaseReturnId: "",
        product: orderItem.product,
        productId: orderItem.productId,
        stockLimit: orderItem.quantity,
        quantity: 1,
        unitPrice: orderItem.unitPrice,
        totalUnitPrice:orderItem.unitPrice -(orderItem.manualPercentageDiscount * 100) / orderItem.unitPrice,
        productVariant: orderItem.productVariant,
        productVariantId: orderItem.productVariantId,
        position: 0,
        warehouseId: orderItem.warehouseId,
        manualPercentageDiscount: orderItem.manualPercentageDiscount,
        totalTax: 0,
        basePrice: 0,
        taxes: [],
        originalItem: orderItem,
    };
    if (orderItem.serials && orderItem.serials.length === 1) {
        item.serials = orderItem.serials;
    }
    return item;
};

export interface ResponseResultPurchaseReturn {
    newPurchase: IPurchase;
    purchaseReturn: IPurchaseReturn;
}