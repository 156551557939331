import { SelectBaseOption, SelectObject } from "../api/models";

export const getSelectOption = (body: { id: string; value: string }) => {
    let option: SelectBaseOption = {
        id: body.id,
        value: body.value,
        label: body.value,
    };
    return option;
};

export const getSelectObject = <T>(obj: T, label: string): SelectObject<T> => {
    return {
        value: label,
        label,
        object: obj,
    };
};

export const getSelectOptionObject = (value: any) => {
    return {
        value: value.id,
        label: value.name,
        object: value,
    };
};
