import styled from "styled-components";
import { device } from "../../../theme/themeProvider";
import { DefaultButton } from "../../_controls";
import { Table } from "../../_controls/tables/styled";

export const MainContainer = styled.div`
    padding: 16px 25px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .show {
        display: block;
    }
    .hidde {
        display: none;
    }
    .required-data {
        color: #f44336;
        margin-top: 10px;
        display: block;
        font-size: 13px;
    }
    .image-rounded-sm {
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        overflow: hidden;
    }

    .image-rounded-sm img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .image-rounded-sm > input {
        display: none;
    }

    .list-font-small li {
        font-size: 0.8rem;
    }
    .list-style-disc {
        padding: 0px;
    }
    .list-style-disc li {
        list-style-type: disc;
        display: list-item;
        padding-left: 0;
        list-style-position: inside;
    }
`;

export const ProductFeatureRowStyle = styled.div`
    display: flex;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    align-items: center;
    position: relative;
    min-height: 40px;

    .select__control {
        border-radius: 30px;
        background: transparent;
        border: 0;
        .select__single-value {
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    .feature-input-value {
        border-radius: 30px;
        width: 100%;
        background: transparent;
        border: 0;

        :hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }

        :first-child {
            max-width: 160px;
        }
    }
    .btn-trash {
        margin-left: 5px;
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.background};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
`;

export const ProductOptionalCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px;
    border-bottom: 1px dashed ${(props) => props.theme.palette.textLight};
    border-radius: 0;
    padding-bottom: 10px;

    .card-header {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .select__control {
        background: transparent;
        font-weight: 500;
        border-radius: 10px;

        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }

    .btn-remove-option {
        margin-left: auto;
    }

    .line-group {
        display: flex;
        align-items: center;
        gap: 5px;
        border: 0px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        padding: 1px;
        padding-left: 10px;
        line-height: 1;
        .line-name {
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};
        }
        input {
            padding: 4px;
            border: 0;
            width: 43px;
            text-align: center;
            border-radius: 15px;
        }
    }
`;

export const DragProductOptionalItem = styled.div`
    background: transparent;
    border-radius: 10px;
    user-select: none;
    margin-bottom: 5px;
    border: 1px solid transparent;
    padding: 3px;
    &:hover {
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    }

    ${(props: { isDragging: any }) => (props.isDragging ? "background: #2196f32e" : "")}
`;

export const ProductOptionalItemRowStyle = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 15px;
    gap: 10px;

    input {
        border-radius: 10px;
        background: transparent;
        border: 0;
        padding: 4px;

        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }

    .item-price {
        font-weight: 500;
    }
    .item-quantity {
        font-weight: 500;
        padding: 4px 0px;
        width: 40px;
        text-align: center;
    }
    .btn-item {
        background: ${(props) => props.theme.palette.background};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
    .active-product {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const SuccessMessage = styled.div`
    text-align: center;
    padding: 30px;
    .icon {
        font-size: 3rem;
    }
    .actions {
        display: flex;
        grid-gap: 15px;
        align-items: center;
        justify-content: center;
    }
`;

export const ProductButtonCheck = styled(DefaultButton)`
    color: ${(props) => props.theme.palette.textLight};
    border: 0;
    padding: 5px 8px;
    padding-right: 15px;
    &:hover {
        background: transparent;
        text-decoration: underline;
    }
    .icon {
        background: ${(props) => props.theme.palette.backgroundLight};
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        box-sizing: border-box;
        width: 25px;
        aspect-ratio: 1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.active {
        .icon {
            border-color: ${(props) => props.theme.palette.primary};
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }

        color: ${(props) => props.theme.palette.primary};
    }
`;

export const TextWithLine = styled.div`
    position: relative;
    .line-text {
        background: ${(props) => props.theme.palette.background};
        font-weight: 500;
        position: relative;
        margin-left: 19px;
        padding: 1px 8px;
    }
    ::before {
        content: " ";
        width: 100%;
        background: ${(props) => props.theme.palette.backgroundDark};
        height: 1px;
        position: absolute;
        top: 50%;
    }
`;

export const ProductVariantsFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .template-card {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 15px;
        padding: 15px;
    }
    .options-header {
        display: flex;
        margin-bottom: 10px;
        .title {
            min-width: 220px;
            font-weight: 500;
        }
    }
    .variant-options {
        display: flex;
        flex-direction: column;
    }
`;

export const ProductVariantCardListContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-auto-rows: min-content;
`;

export const ProductVariantOptionCard = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 15px;

    .variant-options {
        flex-direction: column;
        width: 100%;
    }

    .select-body {
        width: 100%;
    }

    @media ${device.sm} {
        .variant-options {
            flex-direction: row;
            width: 100%;
        }
    }
`;

export const ProductVariantCardContainer = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: color-interpolation-filters;
    align-items: center;
    background: ${(props) => props.theme.palette.backgroundLight};

    .image {
        cursor: pointer;
        width: 90px;
        min-width: 90px;
        height: 90px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundLight}20;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        .hover-edit {
            display: none;
        }

        &:hover {
            .hover-edit {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: ${(props) => props.theme.palette.background}60;
                font-size: 1.5rem;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .button-edit {
        margin-left: 10px;
    }
`;

export const ProductVariantInputGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0px;
    overflow: hidden;
    background: transparent;
    width: 100%;
    padding-right: 10px;
    box-sizing: border-box;
    flex-direction: row-reverse;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    input,
    .textbox-numeral {
        width: 100%;
        border: 0;
        background: transparent;
        padding-left: 12px;
        &:focus {
            border-color: transparent;
        }
    }
`;
export const ProductVariantPricesContainer = styled.div`
    padding: 20px 33px;
`;

export const ImagesSampleView = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
    gap: 10px;
`;

export const ProductChildrenCardForm = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    gap: 10px;

    a {
        color: ${(props) => props.theme.palette.text};
        &:hover {
            text-decoration: underline;
        }
        &:visited {
            color: ${(props) => props.theme.palette.text};
        }
    }
`;

export const DragImageCardContainer = styled.div`
    background: transparent;
    border-radius: 6px;
    user-select: none;

    ${(props: { isDragging: any }) => (props.isDragging ? "background: #2196f32e" : "")}
`;

export const FormInputProduct = styled.div`
    display: flex;
    flex-direction: column;

    input {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
        border-radius: 50px;
        width: 100%;
    }

    &.small-input3 {
        input {
            max-width: 127px;
        }
    }

    &.small-input4 {
        input {
            min-width: 88px;
        }
    }

    textarea {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
    }

    label {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
        margin-bottom: 4px;
    }
    .mb4 {
        margin-bottom: 4px;
    }
    .label-category {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
`;

export const CategoryCardOption = styled.div`
    display: flex;
    padding: 2px;
    align-items: center;
    background: ${(props) => props.theme.palette.backgroundLight};
    margin-bottom: 7px;
    border-radius: 37px;

    .not-category {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }

    max-width: 226px;
    justify-content: space-between;
    padding: 5px 15px;
`;

export const ColumnContainerProduct = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
`;

export const InputNumberContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .textbox-numeral {
        margin-right: 10px;
    }
    span {
        width: 120px;
    }
    input {
        width: 100%;
    }
`;

export const TablePrices = styled(Table)`
    .input-price {
        width: 100%;
        width: 100%;
        font-weight: 500;
        padding-left: 13px;
    }
    .input-price-value {
        border: 1px solid transparent;
    }

    .input-price-percentage {
        width: 70px;
        max-width: fit-content;
        font-size: 13px;
    }
`;

export const RowResponsiveMin = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
    grid-auto-rows: 1fr;
`;

export const AddImageFlexView = styled.div`
    display: flex;
    align-items: center;
    margin-top: 7px;
    gap: 15px;
    padding-right: 10px;
    justify-content: center;

    .card {
        width: 50px;
        aspect-ratio: 1;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 20px;

        .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &.small {
        .card {
            width: 100%;
            height: 100px;
            max-height: 100px;
        }
    }
`;

export const AllImageContainer = styled.div`
    border-radius: 15px;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: background 0.1s ease-in-out, padding 0.1s ease-in-out;
    min-height: 100px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    border: 2px dashed transparent;
    padding: 0px;
    border-radius: 0;
    justify-content: center;

    &.show-border {
        border: 2px dashed ${(props) => props.theme.palette.backgroundDark};
        padding: 10px;
        border-radius: 15px;
    }

    &.drag-active {
        border: 2px dashed ${(props) => props.theme.palette.primary};
        background: ${(props) => props.theme.palette.primary}50;
        padding: 10px;
        border-radius: 15px;

        &:after {
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const ImageViewCard = styled.div`
    height: 120px;
    width: 120px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.add-hover {
        cursor: pointer;
        color: ${(props) => props.theme.palette.textLight};
        :hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    iframe {
        border: 0px;
        width: 100%;
        height: 100%;
    }

    .remove {
        position: absolute;
        bottom: -56px;
        left: calc(50% - 15px);
        transition: bottom 0.2s;
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.text};
        box-shadow: 1px 1px 20px #020202d9;
    }
    &:hover {
        .remove {
            bottom: 6px;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.with-hover {
        :hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
`;

export const TaxContainerViewBaseProduct = styled.div`
    margin-top: 25px;
    padding-top: 20px;

    .header {
        display: flex;
    }
    .dropbtn {
        border-radius: 40px;
    }
    .taxtitle {
        font-size: 13px;
        margin-bottom: 7px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .taxcontainer {
        display: flex;
        flex-direction: column;

        .tax-item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 6px;
            align-items: center;
            /* padding: 4px; */
            padding-left: 16px;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            border-radius: 28px;
            box-sizing: border-box;

            color: ${(props) => props.theme.palette.textLight};
            font-weight: 500;
            background: ${(props) => props.theme.palette.backgroundDark};

            button {
                background: transparent;
                &:hover {
                    background: ${(props) => props.theme.palette.backgroundDarker};
                    color: ${(props) => props.theme.palette.text};
                }
            }
        }
    }
    .btntax {
        background: ${(props) => props.theme.palette.primary};
        border: 0px;
        color: white;
    }
`;

export const ProductPriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    .title {
        width: 30%;
        min-width: 30%;
    }
    .buttons {
        display: flex;
        position: absolute;
        width: 10%;
        align-items: center;
        right: 20px;
        padding: 3px 8px;
        justify-content: flex-end;
    }
    .text-warning {
        color: ${(props) => props.theme.palette.dangerColor};
    }
    .text-secondary {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const ProductVariantOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    .btn-variant-image {
        &.active {
            background: ${(props) => props.theme.palette.primary};
            color: white;
            border-color: transparent;
        }
    }
    .title {
        margin: 0%;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .items {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .item {
            cursor: pointer;
            box-sizing: border-box;
            background: ${(props) => props.theme.palette.background};
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            padding: 5px 10px;
            border-radius: 8px;

            &:hover {
                background: ${(props) => props.theme.palette.primary};
                border-color: transparent;
                color: white;
            }

            &.active {
                background: ${(props) => props.theme.palette.primary};
                border-color: transparent;
                color: white;
            }
            &.image {
                padding: 0px;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                border: 2px solid ${(props) => props.theme.palette.backgroundDark};
                overflow: hidden;

                &.active {
                    background: transparent;
                    border-color: ${(props) => props.theme.palette.primary};
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
`;

export const ProductFeaturesPanel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    .feature-row {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
        :last-child {
            border-bottom: 0;
        }
    }
`;
