import React, { useState } from "react";
import { useIntl } from "react-intl";

import { ISearchCloudSort, SORT_CODE_CLOUD_LIST, SortCodeCloud } from "../../api/models/wahioCloud";
import { checkAndGetMessage } from "../../i18n/helper";
import Dropdown from "../Dropdown";
import { DefaultButton, Flex, PrimaryButton } from "../_controls";
import messages from "./messages";

interface CloudSortDropdownProps {
    value: ISearchCloudSort;
    setValue: (value: ISearchCloudSort) => void;
    small?: boolean;
    removeBg?: boolean;
}

export default function CloudSortDropdown(props: CloudSortDropdownProps) {
    const intl = useIntl();
    const [localValue, setLocalValue] = useState(props.value);

    const hasChanges = React.useMemo(() => {
        let json1 = JSON.stringify(localValue);
        let json2 = JSON.stringify(props.value);
        return json1 !== json2;
    }, [localValue, props.value]);

    const onClickValue = (item: SortCodeCloud) => {
        let desc = localValue.sortDesc;
        if (item === localValue.sort) {
            desc = !localValue.sortDesc;
        }
        setLocalValue({ ...localValue, sort: item, sortDesc: desc });
    };

    const icon = props.value.sortDesc ? "fa-regular fa-arrow-down-small-big" : "fa-regular fa-arrow-up-small-big";

    return (
        <Dropdown
            small={props.small}
            icon={icon}
            contentHorizontal="center"
            iconPosition="left"
            withOutBackgroundButton={props.removeBg}
            title={`${checkAndGetMessage(intl, messages, props.value.sort)}`}
            ignoreInternalClicks
        >
            <Flex column padding={15} gap={20} minWidth={160}>
                <Flex column gap5>
                    {SORT_CODE_CLOUD_LIST.map((item, key) => {
                        const descIcon = localValue.sortDesc ? "wahioicon-arrow-down" : "wahioicon-arrow-up";
                        const isActive = localValue.sort === item;

                        return (
                            <DefaultButton
                                justifyStart
                                hideBorder
                                className={`btn-item ${isActive ? "btn-active" : ""}`}
                                onClick={() => onClickValue(item)}
                                w100
                                bgLight={isActive}
                                borderRadius={10}
                                key={key}
                            >
                                {checkAndGetMessage(intl, messages, item)}{" "}
                                {localValue.sort === item && <span style={{ marginLeft: "auto" }} className={descIcon}></span>}
                            </DefaultButton>
                        );
                    })}
                </Flex>
                <PrimaryButton disabled={!hasChanges} borderRadius={10} w100 onClick={() => props.setValue(localValue)}>
                    Aplicar
                </PrimaryButton>
            </Flex>
        </Dropdown>
    );
}
