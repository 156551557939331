import styled, { DefaultTheme } from "styled-components";

export interface TooltipContentProps {
    theme: DefaultTheme;
    width?: number;
    iconPrimaryColor?: boolean;
}

export const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    .btn-tooltip {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .tooltip-arrow {
        display: none;
        opacity: 0;
        position: fixed;
        width: 15px;
        aspect-ratio: 1;
        transform: rotate(45deg);
        transition: all 0.2s ease-out;
        z-index: 21;
        background: ${(props) => props.theme.palette.background};

        &.show {
            display: flex;
            opacity: 1;
        }
    }
    .tooltip-content {
        visibility: hidden;
        opacity: 0;
        box-sizing: border-box;
        position: fixed;
        background: ${(props) => props.theme.palette.background};
        z-index: 9990;
        border-radius: 10px;
        padding: 15px;
        box-shadow: 2px 1px 20px ${(props) => props.theme.palette.shadowColor};
        transform: translate(10px, 0px);
        transition: all 0.2s ease-out;

        &.show {
            visibility: visible;
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
`;

export const TooltipRadixContainer = styled.div`
    .TooltipContent {
        z-index: 990;
        border-radius: 15px;
        padding: 10px 15px;
        max-width: 400px;
        font-size: 15px;
        line-height: 1.3;
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.background};
        box-shadow: 1px 2px 8px ${(props) => props.theme.palette.shadowColor};
        user-select: none;
        animation-duration: 400ms;
        animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        will-change: transform, opacity;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    }
    .TooltipContent[data-state="delayed-open"][data-side="top"] {
        animation-name: slideDownAndFade;
    }
    .TooltipContent[data-state="delayed-open"][data-side="right"] {
        animation-name: slideLeftAndFade;
    }
    .TooltipContent[data-state="delayed-open"][data-side="bottom"] {
        animation-name: slideUpAndFade;
    }
    .TooltipContent[data-state="delayed-open"][data-side="left"] {
        animation-name: slideRightAndFade;
    }

    .TooltipArrow {
        fill: ${(props) => props.theme.palette.background};
    }

    @keyframes slideUpAndFade {
        from {
            opacity: 0;
            transform: translateY(2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideRightAndFade {
        from {
            opacity: 0;
            transform: translateX(-2px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes slideDownAndFade {
        from {
            opacity: 0;
            transform: translateY(-2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideLeftAndFade {
        from {
            opacity: 0;
            transform: translateX(2px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;
