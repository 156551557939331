import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../auth";
import { OrderCommentListState } from "../../components/Orders/OrderCommentList";
import { OrderListProps } from "../../components/Orders/OrderList";
import { OrderListParams } from "../../components/Orders/OrderList/orderParamHelper";
import { useOrderDataSummaryContext } from "../../store/contexts/OrderDataSummaryContext";
import { useOrganizationContext } from "../../store/contexts/OrganizationContext";
import { UserContext } from "../../store/contexts/UserContext";
import { getUniqueId } from "../../utils";
import customMoment from "../../utils/momentFormat/dateFormat";
import { PaymentMethod } from "../models";
import { GroupItemType } from "../models/_statistics";
import { AccountUser, getUserPermissions } from "../models/accountUser";
import { DeliveryStatus } from "../models/deliveryOrder";
import { OrderCommentSearchModel, OrderSearchInput } from "../models/order";
import { OrderSourceType, OrderStatus, OrderType } from "../models/orderBase";
import { QUERY_ORDER_COMMENT_LIST_KEY, QUERY_ORDER_GROUP_LIST_KEY, QUERY_ORDER_LIST_KEY } from "../queryKeys";
import { OrderStatusUpdateInput, getOrders, getOrdersGroup, putOrderStatus } from "./orderApi";
import { orderCommentQuerySearch } from "./orderCommentApi";

export interface QueryOrdersProps extends OrderListProps {}

export const useOrderStatusMutation = () => {
    const { orderLocalStorageState, setOrderLocalStorageState } = useOrderDataSummaryContext();

    const orderStatusMutation = useMutation((input: OrderStatusUpdateInput) => putOrderStatus(input), {
        onSuccess: () => {
            setOrderLocalStorageState({ ...orderLocalStorageState, lastOrderRequestId: getUniqueId() });
        },
    });

    const updateOrderStatusAsync = async (input: OrderStatusUpdateInput) => {
        return await orderStatusMutation.mutateAsync(input);
    };

    return {
        orderStatusMutation,
        updateOrderStatusAsync,
        isLoading: orderStatusMutation.isLoading,
    };
};

export const useQueryOrderComments = (props: OrderCommentListState) => {
    const { orderLocalStorageState, setOrderLocalStorageState } = useOrderDataSummaryContext();
    const { organizationState } = useOrganizationContext();
    const handleSearchComments = () => {
        let data: OrderCommentSearchModel = {
            accountId: getAuthBody().accountId,
            accountUserId: props.user?.id,
            currentPage: props.page,
            organizationId: organizationState.currentOrganization?.id ?? "",
            searchValue: props.searchValue,
            pageSize: 20,
            requestUserId: getAuthBody().accountUserId,
        };
        return orderCommentQuerySearch(data);
    };

    return useQuery(QUERY_ORDER_COMMENT_LIST_KEY, () => handleSearchComments(), {
        refetchOnWindowFocus: false,
        onSuccess: () => {
            setOrderLocalStorageState({ ...orderLocalStorageState, unreadComments: 0 });
        },
    });
};

export interface OrderSearchModelProps {
    statusList?: OrderStatus[];
    deliveryStatusList?: DeliveryStatus[];
    orderType?: OrderType;
    pageSize?: number;
    ordersByProductId?: string;
    orderParams: OrderListParams;
    organizationId?: string;
    user?: AccountUser;
}

export const getOrderSearchModel = (props: OrderSearchModelProps) => {
    const { orderParams, user, organizationId } = props;
    const shippingStatus = orderParams.shippingStatus;

    let searchModel: OrderSearchInput = {
        accountId: getAuthBody().accountId,
        currentPage: orderParams.page ?? 1,
        pageSize: props.pageSize ?? 20,
        shipmentStatusList: shippingStatus ? [shippingStatus] : [],
        statusList: orderParams.status ? [orderParams.status] : props.statusList,
        orderChannelId: orderParams.channel,
        searchValue: orderParams.search,
        organizationId: organizationId,
        dateRangeActive: false,
        productId: props.ordersByProductId,
        accountUserId: !getUserPermissions(user).order?.viewOrdersFromOtherUsers ? user?.id : orderParams.user,
        contactSellerId: orderParams.seller,
        customerId: orderParams.customer,
        courierId: orderParams.courier,
        source: orderParams.source as OrderSourceType,
        hideShipments: true,
        minutesOffset: new Date().getTimezoneOffset(),
        orderType: props.orderType,
        showOnlyWithInvoice: !!orderParams.withInvoice,
        showOnlyWithoutInvoice: !!orderParams.withoutInvoice,
        showOnlyWithEBilling: !!orderParams.withEBilling,
        showOnlyWithoutEBilling: !!orderParams.withoutEBilling,
        showOnlyWithReturns: !!orderParams.withReturn,
        showOnlyWithoutReturns: !!orderParams.withoutReturn,
        paymentMethod: orderParams.paymentMethod as PaymentMethod,
        invoiceNumerationId: orderParams.invoiceNumeration,
    };

    if (orderParams.startDate && orderParams.endDate) {
        searchModel.dateStart = customMoment(orderParams.startDate, "'YYYY-MM-DD'").format();
        searchModel.dateEnd = customMoment(orderParams.endDate, "'YYYY-MM-DD'").format();
        searchModel.dateRangeActive = true;
    }

    return searchModel;
};

export const getQuerySearchModel = (props: QueryOrdersProps, params: OrderListParams, organizationId?: string, user?: AccountUser) => {
    let searchModel = getOrderSearchModel({
        ...props,
        orderParams: params,
        organizationId: organizationId,
        user: user,
    });

    if (props.defaultCustomer) {
        searchModel.customerId = props.defaultCustomer.id;
    }

    if (props.defaultSeller) {
        searchModel.contactSellerId = props.defaultSeller.id;
    }

    return searchModel;
};

export const useQueryOrders = (props: QueryOrdersProps, params: OrderListParams, onlyShipment?: boolean) => {
    const { userState } = useContext(UserContext);
    const { appSettingsState } = useContext(AppSettingsContext);
    if (onlyShipment) {
        props.orderType = "shipment";
        params.withoutInvoice = "1";
    }

    const searchOrders = () => {
        let searchModel = getQuerySearchModel(props, params, appSettingsState.defaultOrganizationId, userState.user);

        return getOrders(searchModel);
    };
    return useQuery(props.queryKey ?? QUERY_ORDER_LIST_KEY, searchOrders, { refetchOnWindowFocus: false, refetchOnMount: false });
};

export const useQueryOrdersGroup = (props: QueryOrdersProps, groupType: GroupItemType, params: OrderListParams) => {
    const { userState } = useContext(UserContext);
    const { appSettingsState } = useContext(AppSettingsContext);

    const searchOrders = () => {
        let searchModel = getQuerySearchModel(props, params, appSettingsState.defaultOrganizationId, userState.user);
        return getOrdersGroup({ ...searchModel, groupType, limit: 12 });
    };
    return useQuery(props.queryKey ?? QUERY_ORDER_GROUP_LIST_KEY, searchOrders, { refetchOnWindowFocus: false });
};
