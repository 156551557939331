import _ from "lodash";
import { getStoredData, LocalStoreKeys } from "..";
import { DeliveryOrder } from "../../api/models/deliveryOrder";
import { ICurrentOrderState, Order, OrderImpl, OrderTypes, OrderTypeTemp, UPDATE_CURRENT_ORDER } from "../../api/models/order";
import { OrderStatus } from "../../api/models/orderBase";
import { getAuthBody } from "../../auth";

export const getEmptyOrder = (status?: OrderStatus, type?: OrderTypeTemp) => {
    const authBody = getAuthBody();
    let order = new OrderImpl("", "", "");
    order.auxId = _.uniqueId();
    if (authBody) {
        order.accountId = authBody.accountId;
        order.accountUserId = authBody.accountUserId;
        order.orderTypeTemp = type ?? "sale";
        if (status === "queued" || type === "shipment") {
            order.deliveryOrder = new DeliveryOrder(authBody.accountId, "");
            order.orderTypeTemp = "shipment";
            order.isDelivery = true;
            order.status = "queued";
        }
        if (status === "quotation" || type === "quotation") {
            order.orderTypeTemp = "quotation";
            order.status = "quotation";
        }
    }

    return order;
};

export const getEmptyOrderForTable = () => {
    const authBody = getAuthBody();
    if (authBody) {
        let order = new OrderImpl(authBody.accountId, authBody.accountUserId, "");
        order.status = "open";
        order.defaultPrice = "price";
        order.source = "restaurant";
        order.isDelivery = false;
        order.deliveryOrder = undefined;
        order.auxId = _.uniqueId();
        return order;
    }
    return new OrderImpl("", "", "");
};

export class InitStateBase implements ICurrentOrderState {
    order: Order = getEmptyOrder();
    orderShipment: Order = getEmptyOrder("queued");
}

export const initState = getStoredData<ICurrentOrderState>(new InitStateBase(), LocalStoreKeys.currentSale);

export const currentOrderReducer = (state: ICurrentOrderState, action: OrderTypes): ICurrentOrderState => {
    switch (action.type) {
        case UPDATE_CURRENT_ORDER:
            return {
                ...action.value,
            };

        default:
            return state;
    }
};
