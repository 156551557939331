import React, { useState } from "react";
import { OrderNavigationProps } from ".";
import { OrderItem } from "../../../api/models/order";
import { formatMoney } from "../../../utils";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { ProductProfileModal } from "../../Products/ProductProfileNavigation";
import { getProductSku, getProductThumbnailUrl } from "../../Products/helper";
import { Flex, FlexImageStyle, TextField } from "../../_controls";
import { OrderItemCardStyle, OrderItemsContainer } from "./styled";

export default function NavigationItems(props: OrderNavigationProps) {
    const { order } = props;
    const items = order.items ?? [];
    return (
        <NavigationCard title={"Productos"}>
            <OrderItems items={items} />
        </NavigationCard>
    );
}

export const OrderItems = ({ items }: { items: OrderItem[] }) => {
    return (
        <OrderItemsContainer>
            {items.map((item, index) => {
                return <OrderItemCard item={item} key={index} />;
            })}
        </OrderItemsContainer>
    );
};

const OrderItemCard = ({ item }: { item: OrderItem }) => {
    const { product, productVariant } = item;
    const [showProductModal, setShowProductModal] = useState(false);
    const [showSerials, setShowSerials] = useState(false);

    const unitPrice = (item.optionalsConfig?.totalExtraPrice ?? 0) + item.unitPrice;

    return (
        <OrderItemCardStyle>
            {showProductModal && <ProductProfileModal show={showProductModal} setShow={setShowProductModal} product={product} />}
            <Flex w100 gap10 alignCenter>
                <FlexImageStyle className="cursor-pointer" width={40} fitCover borderRadius={10} onClick={() => setShowProductModal(true)}>
                    <img src={getProductThumbnailUrl(item.product, item.productVariant)} alt="" />
                </FlexImageStyle>
                <Flex column>
                    <TextField className="product-name" onClick={() => setShowProductModal(true)}>
                        {item.product?.name}
                    </TextField>
                    {item.optionalsConfig && item.optionalsConfig.description && (
                        <TextField small light>
                            {item.optionalsConfig.description}
                        </TextField>
                    )}
                    <Flex gap10 alignCenter>
                        <TextField small light>
                            {getProductSku(product, productVariant)}
                        </TextField>
                        {item.manualPercentageDiscount > 0 && (
                            <TextField className="item-discount" title="Descuento">
                                {item.details?.discountName} {item.manualPercentageDiscount}%
                            </TextField>
                        )}
                        {item.totalTaxPercentage > 0 && (
                            <TextField className="item-tax" title="Impuesto">
                                {item.totalTaxPercentage}%
                            </TextField>
                        )}
                        {item.quantityReturned > 0 && (
                            <TextField bold color="#3F51B5" title="Devuelto">
                                {item.quantityReturned} Devolución
                            </TextField>
                        )}
                    </Flex>
                    {item.serials && item.serials.length > 0 && (
                        <Flex cursorPointer gap5 onClick={() => setShowSerials(!showSerials)}>
                            <TextField light small>
                                Seriales
                            </TextField>
                            <span className={`fa-regular ${showSerials ? "fa-angle-up" : "fa-angle-down"} text-light`} />
                        </Flex>
                    )}
                </Flex>
                <Flex className="item-total" column alignEnd>
                    <TextField small>
                        {formatMoney(unitPrice)} x {item.quantity}
                    </TextField>
                    <TextField bold>{formatMoney(item.total)}</TextField>
                </Flex>
            </Flex>
            {showSerials && item.serials && item.serials?.length > 0 && (
                <Flex column gap5 alignStart w100>
                    {item.serials.map((x, index) => (
                        <Flex alignCenter gap5 justifyCenter paddingLeft={50}>
                            <Flex alignCenter justifyCenter h100 minWidth={10}>
                                <TextField small light>
                                    {index + 1}.
                                </TextField>
                            </Flex>
                            <TextField light small>
                                {x}
                            </TextField>
                        </Flex>
                    ))}
                </Flex>
            )}
        </OrderItemCardStyle>
    );
};
