import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { productEndpoint } from "../restApiEndpoint";
import {
    IInventoryAdjustment,
    IInventoryAdjustmentDetail,
    IInventoryAdjustmentSearchModel,
    InventoryDetailProductCount,
    InventoryDetailsProductRequest,
    InventoryDetailsRemoveRequest,
    InventoryLocation
} from "./../models/inventoryAdjustment";

export const getInventoryAdjustment = (id: string): Promise<IInventoryAdjustment> => {
    return wahioFetchApi.get(productEndpoint.get.inventoryAdjustmentById(id)).then((res: any) => res.data);
};

export const setInventoryAdjustmentToFinish = (id: string): Promise<IInventoryAdjustment> => {
    return wahioFetchApi.get(productEndpoint.get.inventoryAdjustmentFinalize(id)).then((res: any) => res.data);
};

export const createUpdateInventoryDetail = (data: IInventoryAdjustmentDetail): Promise<IInventoryAdjustmentDetail> => {
    return wahioFetchApi.post(productEndpoint.post.inventoryAdjustmentDetail, data).then((res: any) => res.data);
};

export const searchInventoryDetails = (data: IInventoryAdjustmentSearchModel): Promise<IPaginationResponse<IInventoryAdjustmentDetail>> => {
    return wahioFetchApi.post(productEndpoint.post.inventoryAdjustmentDetailsQuerySearch, data).then((res: any) => res.data);
};

export const deleteInventoryAdjustment = (id: string): Promise<IInventoryAdjustment> => {
    return wahioFetchApi.delete(productEndpoint.delete.inventoryAdjustmentById(id)).then((res: any) => res.data);
};

export const deleteInventoryDetail = (id: string): Promise<IInventoryAdjustmentDetail> => {
    return wahioFetchApi.delete(productEndpoint.delete.inventoryAdjustmentDetail(id)).then((res: any) => res.data);
};

export const deleteInventoryDetailList = (data: InventoryDetailsRemoveRequest): Promise<IInventoryAdjustmentDetail[]> => {
    return wahioFetchApi.post(productEndpoint.post.deleteInventoryAdjustmentDetailList, data).then((res: any) => res.data);
};

export const createUpdateInventoryLocation = (data: InventoryLocation): Promise<InventoryLocation> => {
    return wahioFetchApi.post(productEndpoint.post.inventoryLocation, data).then((res: any) => res.data);
};
export const deleteInventoryLocation = (id: string): Promise<InventoryLocation> => {
    return wahioFetchApi.delete(productEndpoint.delete.inventoryLocation(id)).then((res: any) => res.data);
};

export const getInventoryLocationsByAccount = (accountId: string): Promise<InventoryLocation[]> => {
    return wahioFetchApi.get(productEndpoint.get.inventoryLocationsByAccount(accountId)).then((res: any) => res.data);
};

export const getInventoryDetailsByProduct = (data: InventoryDetailsProductRequest): Promise<IInventoryAdjustmentDetail[]> => {
    return wahioFetchApi.post(productEndpoint.get.inventoryAdjustmentDetailsByProduct, data).then((res: any) => res.data);
};
export const getInventoryProductCount = (id: string): Promise<InventoryDetailProductCount[]> => {
    return wahioFetchApi.get(productEndpoint.get.inventoryDetailsProductCount(id)).then((res: any) => res.data);
};
