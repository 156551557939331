import { defineMessages } from "react-intl";

const base = "components.taxForm";

export default defineMessages({
    taxes: {
        id: `${base}.taxes`,
        defaultMessage: "Impuestos",
    },
    basePrice: {
        id: `${base}.basePrice`,
        defaultMessage: "Precio Base",
    },
    taxTotal: {
        id: `${base}.taxTotal`,
        defaultMessage: "Total Impuestos",
    },
    total: {
        id: `${base}.total`,
        defaultMessage: "Total",
    },
    selectTaxes: {
        id: `${base}.selectTaxes`,
        defaultMessage: "Seleccionar Impuestos",
    },
    totalPrice: {
        id: `${base}.totalPrice`,
        defaultMessage: "Precio Total",
    },
    selectedTaxes: {
        id: `${base}.selectedTaxes`,
        defaultMessage: "Impuestos Seleccionados",
    },
    noTaxes: {
        id: `${base}.noTaxes`,
        defaultMessage: "No hay impuestos seleccionados",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    save: {
        id: `${base}.save`,
        defaultMessage: "Guardar",
    },
});
