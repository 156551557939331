import React from "react";
import { IconCardStyle } from "./styled";

interface IconCardProps {
    icon?: string;
    title: string | number | React.ReactNode;
    subTitle?: string | number | React.ReactNode;
    titleBold?: boolean;
    className?: string;
    smallTitle?: string;
    iconColor?: string;
    smallIcon?: boolean;
    disabled?: boolean;
    removeMargin?: boolean;
    onClick?: () => void;
}

export default function CardIcon(props: IconCardProps) {
    const icon = props.disabled ? "wahioicon-lock" : props.icon;

    const margin = props.removeMargin ? "" : "margin";

    return (
        <IconCardStyle
            iconColor={props.iconColor}
            className={`icon-card ${props.disabled ? "disabled" : ""} ${props.className ? props.className : ""} ${margin}`}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
        >
            {icon && <span className={`${icon} icon ${props.smallIcon ? "small" : ""}`}></span>}
            <div className="card-content">
                {props.smallTitle && <span className="sub-title">{props.smallTitle}</span>}
                <span className={`card-title ${props.titleBold ? "bold" : ""}`}>{props.title}</span>
                {props.subTitle && <span className="sub-title">{props.subTitle}</span>}
            </div>
        </IconCardStyle>
    );
}
