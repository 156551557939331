import styled from "styled-components";
import { DefaultButton } from "../../_controls";

export const LabelPairCard = styled.div`
    background: ${(props) => props.theme.palette.background};

    width: fit-content;
    padding: 4px 10px;
    border-radius: 10px;

    &.with-hover {
        background: ${(props) => props.theme.palette.backgroundLight};
        cursor: pointer;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const PercentageCard = styled.span`
    background: #3f51b5;
    padding: 1px 5px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    &.discount {
        background: #ff5722;
    }
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
`;

export const ProductSerialsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4px;
    .serial-line {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: 500;
        background: ${(props) => props.theme.palette.backgroundDark};
        padding: 1px 7px;
        padding-right: 0px;
        border-radius: 19px;

        &.view {
            padding: 2px 7px;
        }

        .index {
            margin-right: 10px;
            color: ${(props) => props.theme.palette.textLight};
        }
        .serial {
            width: 100%;
            border-radius: 40px;
        }
    }
`;

export const OrderItemCardModalContainer = styled.div`
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .panel-product-card {
        padding: 5px 0px;
        border-radius: 15px;
        width: auto;

        transition: padding 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            padding: 5px 6px;
            background: ${(props) => props.theme.palette.primary}20;
        }
    }

    .input-rounded {
        border-radius: 30px;
        text-align: center;
        width: 91px;
    }

    .textbox-order-item {
        width: 100%;
        font-weight: 600;
    }

    .modal-grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    }
    &.with-optionals {
        flex-direction: row;
        .panel-optionals {
            width: 100%;
            max-width: 370px;
            border-right: 1px solid ${(props) => props.theme.palette.backgroundLight};
            padding-right: 15px;
        }
    }

    .optionals-collapse {
        display: none;
        align-items: center;
        padding: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 15px;
        cursor: pointer;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }

    @media (max-width: 900px) {
        .optionals-collapse {
            display: flex;
        }

        &.with-optionals {
            flex-direction: column;
            .panel-optionals {
                max-width: 100%;
                border-right: 0px solid ${(props) => props.theme.palette.backgroundLight};
                padding-right: 0;
            }
        }

        .optionals-collapse-panel {
            &.hide {
                display: none;
            }
        }
    }
`;

export const NegativeQuantityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 8px;
    background: red;
    color: white;
    font-size: 10px;
    border-radius: 30px;
    padding: 2px 3px;
    position: absolute;
    font-weight: 600;
    top: -9px;
    cursor: default;
`;

export const DiscountsAndTaxesContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    .discounts {
        background: #4caf5030;
        color: #4caf50;
        border-radius: 10px;
        font-weight: 500;
        padding: 1px 5px;
        font-size: 13px;
    }
    .taxes {
        background: #7690c642;
        color: #4b8aa8;
        border-radius: 10px;
        font-weight: 500;
        padding: 1px 5px;
        font-size: 13px;
    }
`;

export const RowImage = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    .item-index {
        background: #1d1d1d;
        background-color: #ffffff0a;
        min-width: 13px;
        font-size: 10px;
        font-weight: 500;
        border-radius: 50%;
        margin-right: 10px;
    }
`;

export const DateTitleLine = styled.div`
    margin: 5px 15px;
    font-size: 13px;
    color: ${(props) => props.theme.palette.textLight};
    position: relative;
    box-sizing: border-box;
    span {
        background: ${(props) => props.theme.palette.background};
        padding-right: 15px;
        position: relative;
        z-index: 1;
    }

    &.large {
        margin: 0;
        span {
            font-weight: 500;
            color: ${(props) => props.theme.palette.text};
            font-size: 15px;
            padding-right: 8px;
        }
    }
    ::after {
        content: " ";
        border-bottom: 1px dashed ${(props) => props.theme.palette.backgroundDark};
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
    }
`;

export const ButtonPercentage = styled(DefaultButton)`
    padding: 0px 4px;
    font-size: 13px;
    border: 0;
    font-weight: 500;
    border-radius: 20px;
    transition: padding 0.3s ease-in-out;

    &.tax {
        color: #3f51b5;
    }
    &.discount {
        color: #ff5722;
    }
`;
