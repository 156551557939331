export const IDENTIFICATION_TYPES = [
    {
        id: "REGISTRO_CIVIL",
        value: "Registro civil",
    },
    {
        id: "TARJETA_IDENTIDAD",
        value: "Tarjeta de identidad",
    },
    {
        id: "CEDULA_CIUDADANIA",
        value: "CC",
    },
    {
        id: "TARJETA_EXTRANJERIA",
        value: "Tarjeta de extranjería",
    },
    {
        id: "CEDULA_EXTRANJERIA",
        value: "Cédula de extranjería",
    },
    {
        id: "NIT",
        value: "NIT",
    },
    {
        id: "PASAPORTE",
        value: "Pasaporte",
    },
    {
        id: "DOC_ID_EXTRANJERO",
        value: "Documento de identificación extranjero",
    },
    {
        id: "NIT_OTRO_PAIS",
        value: "NIT de otro país",
    },
];
