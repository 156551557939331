import { defineMessages } from "react-intl";

const base = "pages.sellerProfile";

export default defineMessages({
    customers: {
        id: `${base}.customers`,
        defaultMessage: "Clientes",
    },
    contacts:{
        id: `${base}.contacts`,
        defaultMessage: "Contactos",
    },
    edit: {
        id: `${base}.edit`,
        defaultMessage: "Editar",
    },
    CEDULA_CIUDADANIA: {
        id: `${base}.CEDULA_CIUDADANIA`,
        defaultMessage: "Cédula de Ciudadanía",
    },
    TARJETA_IDENTIDAD: {
        id: `${base}.TARJETA_IDENTIDAD`,
        defaultMessage: "Tarjeta de Identidad",
    },
    REGISTRO_CIVIL: {
        id: `${base}.REGISTRO_CIVIL`,
        defaultMessage: "Registro Civil",
    },
    TARJETA_EXTRANJERIA: {
        id: `${base}.TARJETA_EXTRANJERIA`,
        defaultMessage: "Tarjeta Extranjería",
    },
    CEDULA_EXTRANJERIA: {
        id: `${base}.CEDULA_EXTRANJERIA`,
        defaultMessage: "Cédula de Extranjería",
    },
    NIT: {
        id: `${base}.NIT`,
        defaultMessage: "NIT",
    },
    PASAPORTE: {
        id: `${base}.PASAPORTE`,
        defaultMessage: "Pasaporte",
    },
    DOC_ID_EXTRANJERO: {
        id: `${base}.DOC_ID_EXTRANJERO`,
        defaultMessage: "Id Extranjero",
    },
    NIT_OTRO_PAIS: {
        id: `${base}.NIT_OTRO_PAIS`,
        defaultMessage: "Nit Otro País",
    },
    observation:{
        id: `${base}.observation`,
        defaultMessage: "Observación",
    },
    addObservation:{
        id: `${base}.addObservation`,
        defaultMessage: "Agregar Observación",
    },
});
