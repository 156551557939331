import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { getServiceMessageError } from '../../api';
import { deleteWahioCloudFiles, getWahioCloudItemsQuery } from '../../api/account/wahioCloudApi';
import { WahioCloudFolder, WahioCloudItem, WahioCloudSearchModel } from '../../api/models/wahioCloud';
import { getAuthBody } from '../../auth';
import { useAlert } from '../Alerts/Alert';
import { DefaultButton, Flex, TextField } from '../_controls';
import Modal, { IModalShow } from '../modals/Modal';
import { FolderStateProps } from './WahioCloudItemList';
import { getCloudItemExtensionImage } from './extensionImage';
import { CloudItemCardContainer, GridFilesContainer } from './styled';

interface ErrorDeletingFolderModalProps extends IModalShow {
    foldersItemsQuery?: WahioCloudFolder[];
    folderState: FolderStateProps;
    confirmDeleteFolder: () => void;
}

interface ItemResult {
    cloudItem?: WahioCloudItem;
    index: number;
    status: "success" | "error";
    errorMessage?: string;
}

export const ErrorDeletingFolderModal = (props: ErrorDeletingFolderModalProps) => {
    const { folderState, foldersItemsQuery } = props
    const { objectError } = folderState
    const [filesDeleteInFolders, setFilesDeleteInFolders] = useState<WahioCloudItem[]>([])
    const deleteMutation = useMutation((data: WahioCloudItem[]) => deleteWahioCloudFiles(data), {});
    const [existingFiles, setExistingFiles] = useState(true)
    const [results, setResults] = useState<ItemResult[]>([]);
    const alert = useAlert();

    const wahioCloudItemsMutation = useMutation((data: WahioCloudSearchModel) => getWahioCloudItemsQuery(data), {});

    const runDeleteFiles = (index: number) => {
        const nextIndex = index + 1;
        const file = filesDeleteInFolders[index];
        deleteFile(file, index, (result) => {
            setResults([...results, result]);
            if (result.status === 'success') {
                setFilesDeleteInFolders((prevFiles) => prevFiles.filter((file) => file.id !== result.cloudItem?.id));
            }
            if (nextIndex <= filesDeleteInFolders.length - 1) {
                runDeleteFiles(nextIndex);
            } else {
                if (filesDeleteInFolders.length > 0) {
                    setExistingFiles(false)
                }
            }
        });
    }

    const deleteFile = (file: WahioCloudItem, index: number, onResult: (value: ItemResult) => void) => {
        deleteMutation.mutateAsync([file]).then((data) => {
            onResult({
                status: "success",
                index,
                cloudItem: data[0],
            });
        }).catch((err) => {
            onResult({
                status: "error",
                index,
                cloudItem: undefined,
            });
        });
    }

    const handleSearchCloudFiles = (folderId: string) => {
        const searchBody: WahioCloudSearchModel = {
            accountId: getAuthBody().accountId,
            currentPage: 1,
            pageSize: 50,
            searchValue: "",
            sort: 'name',
            sortDesc: false,
            organizationId: "",
            folderId: folderId,
        };

        wahioCloudItemsMutation.mutateAsync(searchBody).then((response) => {
            let items = [...filesDeleteInFolders, ...response.items];
            setFilesDeleteInFolders(items);
        }).catch((error) => {
            alert.error(getServiceMessageError(error))
        });
    };

    const getFoldersWithFiles = () => {
        let folderList: WahioCloudFolder[] = [];
        if (objectError?.foldersIds && foldersItemsQuery) {
            foldersItemsQuery.forEach((folder) => {
                if (folder.id && objectError.foldersIds.includes(folder.id)) {
                    folderList.push(folder);
                }
            });
        }
        return folderList;
    };

    const validationShowFiles = (folderId: string) => {
        let showButton = true
        const existFolder = filesDeleteInFolders.find(x => x.folderId === folderId);
        if (existFolder) showButton = false;
        return showButton
    }

    const onCloseModal = () => {
        props.setShow(false)
        setFilesDeleteInFolders([])
    }

    const getImage = (item: WahioCloudItem) => {
        const useUrl = item.type === "image" && item.fileExtension !== "svg";
        const image = useUrl ? item?.thumbnailUrl : getCloudItemExtensionImage(item?.fileExtension ?? "");
        return image
    }

    return (
        <Modal {...props}
            showHeader
            title={existingFiles ? 'Error al intentar eliminar carpeta' : 'Archivos eliminados'}
            colorStyle={existingFiles ? 'danger' : 'success'}
            functionClose={onCloseModal}
        >
            <Flex padding={20} gap15 column>
                {existingFiles ?
                    <>
                        <TextField light>{objectError?.message}</TextField>
                        {objectError?.foldersIds?.length === 1 ?
                            validationShowFiles(objectError.foldersIds[0]) &&
                            <Flex alignEnd justifyEnd>
                                <DefaultButton borderRadius={10} onClick={() => handleSearchCloudFiles(objectError?.foldersIds[0])}>
                                    <TextField small>Ver archivos</TextField>
                                </DefaultButton>
                            </Flex>
                            :
                            <Flex column gap10>
                                <TextField>Carpetas con archivos</TextField>
                                {getFoldersWithFiles().map((folder) => {
                                    return (
                                        <Flex column gap5 key={folder?.id}>
                                            <Flex border borderRadius={10} padding={10} spaceBetween>
                                                <TextField>{folder?.name}</TextField>
                                                {validationShowFiles(folder.id ?? "") &&
                                                    <Flex
                                                        cursorPointer
                                                        gap5
                                                        justifyEnd
                                                        onClick={() => handleSearchCloudFiles(folder?.id ?? "")}
                                                    >
                                                        <TextField small light>Ver archivos</TextField>
                                                        <TextField small light className='fa-regular fa-chevron-down' />
                                                    </Flex>
                                                }
                                            </Flex>
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        }
                        {filesDeleteInFolders && filesDeleteInFolders.length > 0 &&
                            <Flex column gap5>
                                <GridFilesContainer>
                                    {filesDeleteInFolders.map((item) => {
                                        return (
                                            <CloudItemCardContainer key={item.id}>
                                                <Flex className="image-container">
                                                    <Flex className={`image-cloud ${!item.url ? "file-extention" : ""}`}>
                                                        <img src={getImage(item)} alt={item.name} />
                                                    </Flex>
                                                </Flex>
                                                <TextField className='ml-1' light smaller ellipsis>{item.name}</TextField>
                                            </CloudItemCardContainer>
                                        );
                                    })}
                                </GridFilesContainer>
                                <Flex alignEnd gap10 marginTop={20}>
                                    <DefaultButton
                                        borderRadius={10}
                                        onClick={() => onCloseModal()}
                                        w100
                                    >
                                        Cancelar
                                    </DefaultButton>
                                    <DefaultButton borderRadius={10} onClick={() => runDeleteFiles(0)} w100>
                                        Eliminar Archivos
                                    </DefaultButton>
                                </Flex>
                            </Flex>
                        }
                    </>
                    :
                    <Flex column gap15>
                        <TextField>Los archivos fueron eliminados correctamente</TextField>
                        <Flex gap10 marginTop={20}>
                            <DefaultButton w100 borderRadius={10} onClick={() => onCloseModal()}>
                                Cancelar
                            </DefaultButton>
                            <DefaultButton w100 borderRadius={10}
                                onClick={() => {
                                    props.confirmDeleteFolder()
                                    onCloseModal()
                                }}
                            >
                                Eliminar carpeta
                            </DefaultButton>
                        </Flex>
                    </Flex>
                }
            </Flex>
        </Modal >
    )
}
