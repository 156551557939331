import styled from "styled-components";

export const PermissionDeniedContainer = styled.div`
    padding: 15px;
`;

export const PermissionDeniedView = styled.div`
    padding: 10px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
        width: 40px;
        height: 40px;
        background: #ff980024;
        color: #ff9800;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        margin-top: 10px;
        font-weight: 300;
        margin-bottom: 0;
    }
    .text {
        margin-top: 4px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const PermissionActionDeniedContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: #ff98001a;
    color: #ff9800;
    padding: 10px 20px;
    border-radius: 30px;
    margin: 12px 0px;

    .icon-card {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }
    .content {
        display: flex;
        flex-direction: column;
        font-size: 13px;
    }
`;
