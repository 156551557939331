import React, { useContext, useState } from "react";
import { SearchTabTitleProps } from ".";
import { IProductCategory } from "../../api/models/productCategory";
import { IWarehouse } from "../../api/models/warehouse";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import LoadingProgressBar from "../Loadings/LoadingProgressBar";
import PaginationView from "../PaginationView";
import SearchWithCategories from "../Products/ProductSearchList/SearchWithCategories";
import WarehouseSelect from "../Warehouses/WarehouseSelect";
import { SquareButton } from "../_controls";
import MobileButton from "../_controls/buttons/MobileButton";
import { Flex, Title, ToolbarItem, ToolbarView } from "../_controls/containers";

export interface SearchProductCategoryProps extends SearchTabTitleProps {
    selectedCategoryId?: string;
    setSelectedCategory: (value?: IProductCategory) => void;
    selectedWarehouseId?: string;
    setSelectedWarehouse: (value?: IWarehouse) => void;
    hideWarehouse?: boolean;
}

export const TOUR_NAME_CREATE_PRODUCT = "TOUR_NAME_CREATE_PRODUCT";
export const CREATE_PRODUCT_BTN = "CREATE_PRODUCT_BTN";

export default function SearchProductTabTitle(props: SearchProductCategoryProps) {
    const { setSearchValue, setSelectedCategory, setSelectedWarehouse } = props;
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const [showFilters, setShowFilters] = useState(false);

    const updateWarehouseFromSelect = (warehouse?: IWarehouse) => {
        if (warehouse && warehouse.id) {
            appSettingsActions.setAllSettings({ ...appSettingsState, defaultWarehouseId: warehouse?.id });
        }
        setSelectedWarehouse(warehouse);
    };

    return (
        <ToolbarView className={`${props.hideBorder ? "border-none" : ""}`}>
            {props.loading && <LoadingProgressBar />}
            {props.title && <Title>{props.title}</Title>}
            <Flex gap10 column>
                <Flex gap10 alignCenter flexWrap>
                    <ToolbarItem className="mobile-width">
                        <SearchWithCategories
                            searchValue={props.searchValue}
                            selectedCategoryId={props.selectedCategoryId}
                            onChangeCategory={setSelectedCategory}
                            setSearchValue={setSearchValue}
                        />
                        <SquareButton onClick={() => setShowFilters(!showFilters)} className="filter">
                            <span className="wahioicon-filter"></span>
                        </SquareButton>
                    </ToolbarItem>
                    {props.onClickBtn && props.btnTitle && (
                        <ToolbarItem>
                            <MobileButton
                                id={CREATE_PRODUCT_BTN}
                                tourName={TOUR_NAME_CREATE_PRODUCT}
                                title={props.btnTitle}
                                onClick={props.onClickBtn && props.onClickBtn}
                                rounded={true}
                            />
                        </ToolbarItem>
                    )}
                    <Flex alignCenter gap10 flexWrap className={`filter-options ${showFilters && "show"}`}>
                        <ToolbarItem>
                            <WarehouseSelect
                                hideTitle
                                defaultWarehouseId={props.selectedWarehouseId}
                                onWarehouseChange={(value) => updateWarehouseFromSelect(value)}
                            />
                        </ToolbarItem>
                        {props.children}
                    </Flex>
                </Flex>
                {props.pagination && props.onPageChange && (
                    <PaginationView onPageChange={props.onPageChange} pagination={props.pagination} />
                )}
            </Flex>
        </ToolbarView>
    );
}
