import React from "react";
import { useIntl } from "react-intl";
import { ProductPriceTypes } from "../../../api/models/product";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useClientSettings } from "../../../store/contexts/ClientSettingsContext";
import Dropdown from "../../Dropdown";
import { DefaultButton } from "../../_controls";
import messages from "./messages";
import { Container } from "./styled";

interface PriceListProps {
    onClick: (value: ProductPriceTypes) => void;
}

export function PriceTitle({ item }: { item: ProductPriceTypes }) {
    const { priceSettings } = useClientSettings();
    const priceList = priceSettings?.priceList ?? {};
    const intl = useIntl();

    let priceName = priceList[item]?.name;

    if (priceName) {
        return priceName;
    } else {
        return checkAndGetMessage(intl, messages, item);
    }
}

export default function PriceList(props: PriceListProps) {
    const { priceSettings } = useClientSettings();
    const priceList = priceSettings?.priceList ?? {};

    const intl = useIntl();

    const onClick = (item: ProductPriceTypes) => {
        props.onClick(item);
    };

    return (
        <Container>
            <DefaultButton type="button" className="dropdown-button" onClick={() => onClick("basePrice")}>
                {intl.formatMessage(messages.basePrice)}
            </DefaultButton>
            <hr />

            {Object.keys(priceList).map((item) => {
                let priceName = priceList[item].name;
                return (
                    <DefaultButton type="button" className="dropdown-button" onClick={() => onClick(item as ProductPriceTypes)} key={item}>
                        {priceName}
                    </DefaultButton>
                );
            })}
        </Container>
    );
}

interface PriceListDropdownProps {
    selectedPrice: ProductPriceTypes;
    onSelected: (value: ProductPriceTypes) => void;
    label?: string;
    hideIcon?: boolean;
    small?: boolean;
    w100?: boolean;
    bgLight?: boolean;
}

export const PriceListDropdown = (props: PriceListDropdownProps) => {
    // startIcon="wahioicon-dollar-sign"
    return (
        <Dropdown bgLight={props.bgLight} w100={props.w100} small={props.small} title={PriceTitle({ item: props.selectedPrice })}>
            <PriceList onClick={(value) => props.onSelected(value)} />
        </Dropdown>
    );
};
