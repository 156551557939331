import styled, { css } from "styled-components";
import { SquareButton } from "../../_controls";
import { TableCustomTr } from "../../_controls/tables/styled";

const editIconStyle = (props: any) => css`
    .position-index {
        display: flex;
    }
    .btn-delete {
        display: none;
    }
    .icon-card {
        width: 30px;
        justify-content: center;
    }
    .edit-icon {
        position: absolute;
        display: none;
        font-size: 12px;
        color: ${props.theme.palette.text};
        background: ${props.theme.palette.background};
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        top: 2px;
        left: 2px;
    }

    &:hover {
        .edit-icon {
            display: flex;
        }
        .position-index {
            display: none;
        }
        .btn-delete {
            display: flex;
        }
    }
`;

export const RowContainer = styled(TableCustomTr)`
    .input-price {
        min-width: 100px;
    }

    &.refresh {
        th,
        td {
            background: blue;
        }
    }

    ${(props) => editIconStyle(props)}

    .col-name-width {
        max-width: 215px;
    }
    .returned-label {
        background: #f443364a;
        width: fit-content;
        font-size: 0.8rem;
        border-radius: 10px;
        padding: 3px 7px;
        color: #f44336;
    }

    .textbox-numerar {
        padding: 0;
    }

    &.selected-from-arrow {
        border-left: 2px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    td {
        padding: 8px 4px;
    }

    &.selected-from-arrow {
        border-left: 2px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const IconButton = styled(SquareButton)`
    width: 30px;
    height: 30px;
    min-width: 30px;
    font-size: 13px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0px;
    margin: 0px;
    &.delete {
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const IconQuantityButton = styled(IconButton)`
    border-radius: 50px;
    margin: 0px;
    width: 30px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
`;

export const ImageContainer = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.big {
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 10px;
        margin-right: 15px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const ImageCardContainer = styled(ImageContainer)`
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 15px;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const TaxCardLine = styled.div`
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.palette.backgroundLight};
    padding: 5px 10px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;

    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .tax-percentage {
        background: ${(props) => props.theme.palette.background};
        font-size: 12px;
        font-weight: 500;
        padding: 2px 5px;
        border-radius: 26px;
    }
`;

export const FlexWrapContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.center {
        width: 100%;
        justify-content: center;
    }
`;

export const FlexSpaceBetween = styled(FlexContainer)`
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const QuantityContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    &.big-view {
        width: 100%;
    }
`;

export const QuantityContainer = styled(FlexContainer)`
    gap: 5px;
    input {
        width: 50px;
        height: 25px;
        text-align: center;
        font-weight: 500;
        border-radius: 21px;
        background: transparent;
    }
    button {
        width: 25px;
        height: 25px;
        min-width: 25px;
        background: ${(props) => props.theme.palette.background};
    }

    &.big-view {
        width: 100%;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        padding: 5px 12px;
        box-sizing: border-box;
        border-radius: 15px;
        button {
            width: 35px;
            min-width: 35px;
            height: 35px;
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        input {
            font-weight: 600;
            font-size: 18px;
            border: 0;
        }
    }
`;
export const PriceContainer = styled(FlexContainer)`
    .textbox-numeral {
        border-radius: 40px;
        text-align: center;
    }
    .min {
        width: 60px;
    }
`;

export const EditSpan = styled.span`
    font-size: 13px;
    margin-left: 4px;
    color: ${(props) => props.theme.palette.textLight};
`;

export const CardContainer = styled.div`
    padding: 10px;
    &:last-child {
        border-bottom: 0;
    }
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    cursor: pointer;

    ${(props) => editIconStyle(props)}

    &.selected-from-arrow {
        border-left: 2px solid #304ffe;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    .btn-remove {
        position: absolute;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
    }

    input {
        border-radius: 40px;
    }
    .textbox-numeral {
        padding: 5px;
        border: 0;
        &:focus,
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .action {
        border-radius: 40px;
    }
    .total {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    .light {
        font-size: 12px;
        color: ${(props) => props.theme.palette.textLight};
    }

    .btn-position {
        color: ${(props) => props.theme.palette.text};
        background: transparent;
        font-weight: 600;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    .btn-text {
        display: block;
    }
    .btn-icon {
        display: none;
    }

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};

        .btn-text {
            display: none;
        }
        .btn-icon {
            display: block;
        }
    }
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn-hover {
        .trash-icon {
            display: none;
        }
        .position {
            display: block;
        }
    }

    &:hover {
        .btn-hover {
            .trash-icon {
                display: block;
            }
            .position {
                display: none;
            }
        }
    }

    .flex {
        display: flex;
        align-items: center;
    }
    .title-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title-card {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .product-title {
        cursor: pointer;
        &:hover {
            color: ${(props) => props.theme.palette.primary};
            text-decoration: underline;
        }
    }
`;
