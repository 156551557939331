import React from "react";
import Modal, { IModalShow } from "../../modals/Modal";
import FinalizeOrderForm, { FinalizeOrderFormProps } from "./FinalizeOrderForm";

interface ModalFinalizeOrderProps extends IModalShow, FinalizeOrderFormProps {}

export default function ModalFinalizeOrder(props: ModalFinalizeOrderProps) {
    return (
        <Modal ignoreOutsideClick removeVerticalAlign={true} title={"Finalizar Compra"} showHeader={true} {...props}>
            <FinalizeOrderForm {...props} />
        </Modal>
    );
}
