import styled from "styled-components";

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;

    .search-input {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
        padding: 8px 15px;
        border-radius: 12px;
        font-weight: 500;

        ::placeholder {
            font-weight: 400;
        }
    }
`;

export const CardSuggestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    background: ${(props) => props.theme.palette.background};
    box-shadow: 2px 4px 10px ${(props) => props.theme.palette.shadowColor};
    padding: 10px;
    top: 70px;
    width: 90%;
    border-radius: 10px;

    .suggestion-item {
        padding: 3px 10px;
        border-radius: 10px;
        cursor: pointer;
        color: ${(props) => props.theme.palette.text};

        :hover {
            background: #7c4dff;
            color: white;
        }
    }
`;
