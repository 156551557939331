import React, { useEffect } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import { EditorContainer } from "./styled";

import IconBold from "../../assets/icons/editor/bold.png";
import IconBulletList from "../../assets/icons/editor/bullet-list.png";
import IconCode from "../../assets/icons/editor/code.png";
import IconItalic from "../../assets/icons/editor/italic.png";
import IconLink from "../../assets/icons/editor/link.png";
import IconNumber from "../../assets/icons/editor/number.png";
import IconPaintBucket from "../../assets/icons/editor/paint-bucket.png";
import IconEmoji from "../../assets/icons/editor/smiling-emoticon-square-face.png";
import IconStrikethrough from "../../assets/icons/editor/strikethrough.png";
import IconSubscript from "../../assets/icons/editor/subscript.png";
import IconSuperscript from "../../assets/icons/editor/superscript.png";
import IconUnderline from "../../assets/icons/editor/underline.png";

interface DraftEditorProps extends EditorProps {
    w100?: boolean;
    hideToolbar?: boolean;
}

const CONTAINER_ID = "CONTAINER-EDITOR-PAGE";

export default function DraftEditor(props: DraftEditorProps) {
    const editRef = React.useRef<any>();

    useEffect(() => {
        if (editRef.current) {
            editRef.current.focus();
        }
    }, []);

    let containerClass = "";
    if (props.w100) {
        containerClass += " allWidth";
    }
    if (props.hideToolbar) {
        containerClass += " hide-toolbar";
    }

    return (
        <EditorContainer className={containerClass} id={CONTAINER_ID}>
            <Editor
                {...props}
                locale="es"
                editorRef={(ref) => (editRef.current = ref)}
                toolbarClassName={"toolbarClassName"}
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{
                    options: ["inline", "blockType", "list", "colorPicker", "link", "emoji"],
                    inline: {
                        inDropdown: false,
                        className: "edit-toolbar",
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["bold", "italic", "underline", "strikethrough"],
                        bold: { icon: IconBold, className: undefined },
                        italic: { icon: IconItalic, className: undefined },
                        underline: { icon: IconUnderline, className: undefined },
                        strikethrough: { icon: IconStrikethrough, className: undefined },
                        monospace: { icon: IconCode, className: undefined },
                        superscript: { icon: IconSuperscript, className: undefined },
                        subscript: { icon: IconSubscript, className: undefined },
                    },
                    blockType: {
                        inDropdown: true,
                        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
                        className: "edit-toolbar",
                        component: undefined,
                        dropdownClassName: undefined,
                    },

                    list: {
                        inDropdown: false,
                        className: "edit-toolbar",
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                        unordered: { icon: IconBulletList, className: undefined },
                        ordered: { icon: IconNumber, className: undefined },
                    },

                    colorPicker: {
                        icon: IconPaintBucket,
                        className: "edit-toolbar",
                        component: undefined,
                        popupClassName: undefined,
                        colors: [
                            "rgb(97,189,109)",
                            "rgb(26,188,156)",
                            "rgb(84,172,210)",
                            "rgb(44,130,201)",
                            "rgb(147,101,184)",
                            "rgb(71,85,119)",
                            "rgb(204,204,204)",
                            "rgb(65,168,95)",
                            "rgb(0,168,133)",
                            "rgb(61,142,185)",
                            "rgb(41,105,176)",
                            "rgb(85,57,130)",
                            "rgb(40,50,78)",
                            "rgb(0,0,0)",
                            "rgb(247,218,100)",
                            "rgb(251,160,38)",
                            "rgb(235,107,86)",
                            "rgb(226,80,65)",
                            "rgb(163,143,132)",
                            "rgb(239,239,239)",
                            "rgb(255,255,255)",
                            "rgb(250,197,28)",
                            "rgb(243,121,52)",
                            "rgb(209,72,65)",
                            "rgb(184,49,47)",
                            "rgb(124,112,107)",
                            "rgb(209,213,216)",
                        ],
                    },
                    link: {
                        inDropdown: false,
                        className: "edit-toolbar",
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: "_self",
                        options: ["link"],
                        link: { icon: IconLink, className: undefined },
                        unlink: { icon: "unlink", className: undefined },
                        linkCallback: undefined,
                    },
                    emoji: {
                        icon: IconEmoji,
                        className: "edit-toolbar",
                        component: undefined,
                        popupClassName: undefined,
                        emojis: [
                            "😀",
                            "😁",
                            "😂",
                            "😃",
                            "😉",
                            "😋",
                            "😎",
                            "😍",
                            "😗",
                            "🤗",
                            "🤔",
                            "😣",
                            "😫",
                            "😴",
                            "😌",
                            "🤓",
                            "😛",
                            "😜",
                            "😠",
                            "😇",
                            "😷",
                            "😈",
                            "👻",
                            "😺",
                            "😸",
                            "😹",
                            "😻",
                            "😼",
                            "😽",
                            "🙀",
                            "🙈",
                            "🙉",
                            "🙊",
                            "👼",
                            "👮",
                            "🕵",
                            "💂",
                            "👳",
                            "🎅",
                            "👸",
                            "👰",
                            "👲",
                            "🙍",
                            "🙇",
                            "🚶",
                            "🏃",
                            "💃",
                            "⛷",
                            "🏂",
                            "🏌",
                            "🏄",
                            "🚣",
                            "🏊",
                            "⛹",
                            "🏋",
                            "🚴",
                            "👫",
                            "💪",
                            "👈",
                            "👉",
                            "👉",
                            "👆",
                            "🖕",
                            "👇",
                            "🖖",
                            "🤘",
                            "🖐",
                            "👌",
                            "👍",
                            "👎",
                            "✊",
                            "👊",
                            "👏",
                            "🙌",
                            "🙏",
                            "🐵",
                            "🐶",
                            "🐇",
                            "🐥",
                            "🐸",
                            "🐌",
                            "🐛",
                            "🐜",
                            "🐝",
                            "🍉",
                            "🍄",
                            "🍔",
                            "🍤",
                            "🍨",
                            "🍪",
                            "🎂",
                            "🍰",
                            "🍾",
                            "🍷",
                            "🍸",
                            "🍺",
                            "🌍",
                            "🚑",
                            "⏰",
                            "🌙",
                            "🌝",
                            "🌞",
                            "⭐",
                            "🌟",
                            "🌠",
                            "🌨",
                            "🌩",
                            "⛄",
                            "🔥",
                            "🎄",
                            "🎈",
                            "🎉",
                            "🎊",
                            "🎁",
                            "🎗",
                            "🏀",
                            "🏈",
                            "🎲",
                            "🔇",
                            "🔈",
                            "📣",
                            "🔔",
                            "🎵",
                            "🎷",
                            "💰",
                            "🖊",
                            "📅",
                            "✅",
                            "❎",
                            "💯",
                        ],
                    },
                    embedded: {
                        icon: "embedded",
                        className: "edit-toolbar",
                        component: undefined,
                        popupClassName: undefined,
                        embedCallback: undefined,
                        defaultSize: {
                            height: "auto",
                            width: "auto",
                        },
                    },
                }}
            />
        </EditorContainer>
    );
}
