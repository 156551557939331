import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { IPurchase } from '../../../api/models/purchase';
import { formatMoney } from '../../../utils';
import { TextField } from '../../_controls';
import { Table } from '../../_controls/tables/styled';
import messages from './messages';

interface TotalDetailsProps {
    currentOrder: IPurchase;
}

export const TotalDetails = (props: TotalDetailsProps) => {

    const { currentOrder } = props
    const intl = useIntl();

    return (
        <Table cellSpacing={0} cellPadding={0} tdPadding={5} tdPaddingRight={15} hideBorde>
            <tbody>
                {currentOrder.status !== "paid" && !!currentOrder.paymentTermsDays && (
                    <>
                        <tr>
                            <td>Término</td>
                            <td>
                                {`${currentOrder.paymentTermsDays} Días, Resta ${currentOrder.paymentTermsDays -
                                    moment(currentOrder.dateCreated).diff(moment().toDate(), "days")
                                    }`}
                            </td>
                        </tr>
                        <tr>
                            <td>Vence</td>
                            <td>
                                {`${moment(currentOrder.dateCreated)
                                    .add(currentOrder.paymentTermsDays, "days")
                                    .format("DD MMM YYYY")}`}
                            </td>
                        </tr>
                    </>
                )}

                <tr>
                    <td>SubTotal</td>
                    <td>{formatMoney(currentOrder.subTotal)}</td>
                </tr>
                {currentOrder.totalTaxItems > 0 &&
                    <tr>
                        <td>Impuesto</td>
                        <td>{formatMoney(currentOrder.totalTaxItems)}</td>
                    </tr>
                }
                {currentOrder.includesTaxWithholding && (
                    <tr>
                        <td>{currentOrder.taxWithholdingName ?? "---"}</td>
                        <td>{`${formatMoney(
                            (currentOrder.totalAmountItems * currentOrder.taxWithholdingPercentage) / 100
                        )} (${currentOrder.taxWithholdingPercentage}%)`}</td>
                    </tr>
                )}
                {currentOrder.totalManualPercentageDiscountItems > 0 && (
                    <tr>
                        <td>Descuento Manual</td>
                        <td>{formatMoney(currentOrder.totalManualPercentageDiscountItems)}</td>
                    </tr>
                )}
                {currentOrder.totalDiscount > 0 && (
                    <tr>
                        <td>Descuento</td>
                        <td>{formatMoney(currentOrder.totalDiscount)}</td>
                    </tr>
                )}

                {currentOrder.totalTip > 0 && (
                    <tr>
                        <td>Propina</td>
                        <td>{formatMoney(currentOrder.totalTip)}</td>
                    </tr>
                )}
                {currentOrder.totalAmountReturns > 0 && (
                    <tr>
                        <td>Devolución:</td>
                        <td>{formatMoney(currentOrder.totalAmountReturns)}</td>
                    </tr>
                )}

                <tr className="border-top-dashed">
                    <td>
                        <TextField bold fontSize={16}>
                            {intl.formatMessage(messages.total)}
                        </TextField>
                    </td>
                    <td>
                        <TextField bold fontSize={16}>
                            {formatMoney(currentOrder.totalAmount)}
                        </TextField>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}
