import styled from "styled-components";
import { CloudImages } from "../../../assets";
import { device } from "../../../theme/themeProvider";
interface ChatContainerViewProps {
    items: number;
}

export const ChatContainerView = styled.div`
    position: fixed;
    z-index: 6;
    bottom: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    z-index: 11;
    ${(props: ChatContainerViewProps) => (props.items > 0 ? `height: 100%;` : "height: 0px;")}
    width: 100%;

    @media ${device.sm} {
        width: auto;
        top: initial;
        height: auto;
        padding-right: 4%;
    }
`;

export const TextareaChatContainer = styled.div`
    border-top: 1px solid ${(props) => props.theme.palette.backgroundLight};
    margin-top: auto;
    padding: 10px;
    align-items: end;
    display: flex;
    gap: 10px;
    align-items: center;

    &.padding-none {
        padding: 0px;
        border: 0;
        margin: 0;
    }

    .emojis-container {
        height: 200px;
    }

    .message-input-body {
        background: ${(props) => props.theme.palette.backgroundLight};
        position: relative;
        width: 100%;
        border-radius: 18px;
        align-items: end;

        .message-textarea {
            border-radius: 18px;
            resize: none;
            height: 35px;
            border-color: transparent;
            min-height: 35px;
            max-height: 200px;
            width: 100%;
            background: transparent;
            -webkit-appearance: none !important;
            transition: height 0.2s ease-in-out;
            padding-right: 32px;
            ::-webkit-scrollbar {
                width: 0px;
            }

            &.with-border {
                border: 1px solid ${(props) => props.theme.palette.backgroundDark};
                background: ${(props) => props.theme.palette.background};
            }
        }

        .btn-emoji {
            position: absolute;
            background: transparent;
            right: 5px;
            bottom: 4px;
            width: 30px;
            height: 30px;
            min-width: 30px;
            font-size: 16px;

            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark};
            }
        }
    }
`;

export const ChatMessageCardView = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    border-bottom: 0;
    box-shadow: 1px 1px 20px 1px ${(props) => props.theme.palette.shadowColor};
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;

    .header {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
        padding: 10px;
        .btn-remove {
            background: ${(props) => props.theme.palette.backgroundLight}40;
            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark};
            }
        }
    }

    .message-bubble-container {
        height: 100%;
        flex-direction: column-reverse;
        gap: 5px;
        padding: 15px 15px;
        overflow: auto;
        background-image: url(${CloudImages.emojiBackground});
        background-size: 260px;

        .message-date {
            text-align: center;
            margin-bottom: 10px;

            .date {
                background: ${(props) => props.theme.palette.backgroundLight};
                color: ${(props) => props.theme.palette.textLight};
                border-radius: 15px;
                font-size: 12px;
                font-weight: 500;
                padding: 1px 8px;
            }
        }
        .message-bubble {
            display: flex;

            .content {
                border-radius: 10px;
                background: ${(props) => props.theme.palette.backgroundLight};
                padding: 2px;
                max-width: 80%;
                padding: 5px 10px;
                position: relative;
                display: flex;
                flex-direction: column;
                .description {
                    margin: 0px;
                    overflow: hidden;
                    .match {
                        color: ${(props) => props.theme.palette.primary};
                        font-weight: 500;
                        &.pointer {
                            cursor: pointer;
                        }
                    }
                }

                .user {
                    font-size: 13px;
                    font-weight: 500;
                    color: #a55959;
                }
            }
            .message-time {
                font-size: 11px;
                margin-left: 7px;
                float: right;
                color: ${(props) => props.theme.palette.textLight};
                margin-top: 1px;
            }
            &.left {
                .content {
                    ::after {
                        content: "";
                        position: absolute;
                        left: 1px;
                        bottom: 6px;
                        width: 0;
                        height: 0;
                        border: 8px solid transparent;
                        border-right-color: ${(props) => props.theme.palette.backgroundLight};
                        border-left: 0;
                        margin-top: -20px;
                        margin-left: -8px;
                    }
                }
            }
            &.right {
                justify-content: end;
                .content {
                    background: #2196f338;
                    ::after {
                        content: "";
                        position: absolute;
                        right: 2px;
                        bottom: 8px;
                        width: 0;
                        height: 0;
                        border: 6px solid transparent;
                        border-left-color: #2196f338;
                        border-right: 0;
                        margin-top: -20px;
                        margin-right: -8px;
                    }
                }
                .message-time {
                }
            }
        }
    }

    @media ${device.sm} {
        border-radius: 15px 15px 0px 0px;
        width: 310px;
        height: 480px;
    }
`;

export const EmojisContainer = styled.div`
    background: ${(props) => props.theme.palette.background};
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 10px;
    position: fixed;
    padding: 12px;
    z-index: 10;
    right: 10px;
    bottom: 10px;
    overflow: auto;
    border-radius: 15px;
    box-shadow: 1px 1px 19px ${(props) => props.theme.palette.shadowColor};

    opacity: 0;
    transform: translate(10px, 10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    .emoji-grid {
    }

    .category-line {
        position: sticky;
        padding: 5px;
        background: aqua;
        top: 0;
        background: ${(props) => props.theme.palette.background};
        display: flex;
    }

    .emoji-grid-items {
        box-sizing: border-box;
        max-height: 350px;
        overflow: auto;
    }

    &.show {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    .category-emojis {
        display: flex;
        gap: 10px;
        font-size: 20px;
        justify-content: space-between;
        a {
            color: ${(props) => props.theme.palette.textLight};
        }
        .category-active {
            color: ${(props) => props.theme.palette.primary};
        }
    }

    .emoji-list {
        font-family: Segoe UI Emoji;
        font-size: 30px;
    }
    .emoji-item {
        text-align: center;
        cursor: pointer;
        overflow: hidden;
    }
`;
