import { getStoredData, LocalStoreKeys } from "..";
import { getAuthBody } from "../../auth";
import { OrderStatus } from "../../api/models/orderBase";

import { ICurrentPurchaseState, IPurchase, Purchase, PurchaseTypes, UPDATE_CURRENT_PURCHASE } from "../../api/models/purchase";
import { PurchaseFormSettings } from "../../appSettings/types";

export const emptyPurchase = (purchaseSettings?: PurchaseFormSettings, status?: OrderStatus) => {
    const authBody = getAuthBody();
    if (authBody) {
        let order = new Purchase(authBody.accountId, authBody.accountUserId, "");
        order.status = status ?? "draft";
        order.taxIncluded = !!purchaseSettings?.purchaseTaxIncluded;
        order.includesTaxWithholding = !!purchaseSettings?.purchaseIncludesTaxWithholding;
        order.preTaxDiscount = !!purchaseSettings?.purchasePreTaxDiscount;
        return order;
    }
    return new Purchase("", "", "");
};

export class InitStateBase implements ICurrentPurchaseState {
    currentPurchase: IPurchase = emptyPurchase({
        purchaseTaxIncluded: true,
        purchasePreTaxDiscount: false,
        purchaseIncludesTaxWithholding: false,
    });
}

export const initState = getStoredData<ICurrentPurchaseState>(new InitStateBase(), LocalStoreKeys.currentPurchase);

export const currentPurchaseReducer = (state: ICurrentPurchaseState, action: PurchaseTypes): ICurrentPurchaseState => {
    switch (action.type) {
        case UPDATE_CURRENT_PURCHASE:
            return {
                ...state,
                currentPurchase: action.value,
            };

        default:
            return state;
    }
};
