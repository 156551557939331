import React, { useContext, useEffect, useRef } from "react";
import { IProductCategory } from "../../../api/models/productCategory";
import { CustomCategoryContext } from "../../../store/contexts/CustomCategoryContext";
import { DefaultButton, Flex, GridTemplate, SquareButton, TextField } from "../../_controls";
import { CategoriesPanelStyle, CategoryGridCard } from "./styled";
import { CloudImages } from "../../../assets";

interface CategoriesPanelProps {
    selectedCategory?: IProductCategory;
    onSelectedCategory: (value?: IProductCategory) => void;
    onClickShowCards: () => void;
}

export default function CategoriesPanel(props: CategoriesPanelProps) {
    const isMounted = React.useRef(true);
    const categoryListRef = useRef<any>();
    const leftBtnRef = useRef<any>();
    const rightBtnRef = useRef<any>();

    const { customCategoryState } = useContext(CustomCategoryContext);

    useEffect(() => {
        if (categoryListRef.current) {
            categoryListRef.current.addEventListener("scroll", () => {
                handleCalculatePositions();
            });
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (props.selectedCategory?.id) {
            let element = document.getElementById(props.selectedCategory?.id);
            if (element) {
                categoryListRef.current.scrollLeft = element.offsetLeft - element.clientWidth;
            }
        } else {
            categoryListRef.current.scrollLeft = 0;
        }
    }, [customCategoryState.items, props.selectedCategory]);

    useEffect(() => {
        handleCalculatePositions();
    }, []);

    const handleCalculatePositions = () => {
        if (!categoryListRef.current || !leftBtnRef.current || !rightBtnRef.current) return;
        let leftScroll = categoryListRef.current.scrollLeft;
        let widthScroll = categoryListRef.current.scrollWidth;
        let containerWidth = categoryListRef.current.getBoundingClientRect().width;

        let left = leftScroll;
        let right = widthScroll - containerWidth - leftScroll;

        const limit = 50;
        if (left >= limit) {
            leftBtnRef.current.style.display = "flex";
        } else {
            leftBtnRef.current.style.display = "none";
        }

        if (right <= limit) {
            rightBtnRef.current.style.display = "none";
        } else {
            rightBtnRef.current.style.display = "flex";
        }
    };

    const onScrollSum = (value: number) => {
        if (categoryListRef.current) categoryListRef.current.scrollLeft += value;
    };

    return (
        <CategoriesPanelStyle>
            <SquareButton onClick={() => onScrollSum(-150)} ref={leftBtnRef} className="arrow-icon left">
                <i className="fa-sharp fa-regular fa-arrow-left"></i>
            </SquareButton>
            <Flex className="category-list" ref={categoryListRef}>
                <DefaultButton onClick={() => props.onClickShowCards()}>
                    <i className="fa-regular fa-grid-2"></i>
                </DefaultButton>
                <DefaultButton
                    onClick={() => {
                        props.onSelectedCategory(undefined);
                    }}
                    className={props.selectedCategory?.id ? "" : "active"}
                >
                    All
                </DefaultButton>
                {customCategoryState.items.map((item, index) => {
                    const isActive = item.id === props.selectedCategory?.id;
                    return (
                        <DefaultButton
                            id={item.id}
                            onClick={() => (isActive ? props.onSelectedCategory(undefined) : props.onSelectedCategory(item))}
                            className={isActive ? "active" : ""}
                            key={index}
                        >
                            {item.name}
                        </DefaultButton>
                    );
                })}
            </Flex>
            <SquareButton onClick={() => onScrollSum(150)} ref={rightBtnRef} className="arrow-icon right">
                <i className="fa-sharp fa-regular fa-arrow-right"></i>
            </SquareButton>
        </CategoriesPanelStyle>
    );
}

interface CategoryGridPanelProps {
    selectedCategory?: IProductCategory;
    onSelectedCategory: (value?: IProductCategory) => void;
    onBack: () => void;
}

export const CategoryGridPanel = (props: CategoryGridPanelProps) => {
    const { customCategoryState } = useContext(CustomCategoryContext);

    return (
        <Flex column gap15 padding={20}>
            <GridTemplate size={110}>
                <CategoryGridCard onClick={() => props.onSelectedCategory(undefined)}>
                    <div className="category-image">
                        <i className="fa-regular fa-grid-2"></i>
                    </div>
                    <div className="category-name">
                        <TextField>Todo</TextField>
                    </div>
                </CategoryGridCard>
                {customCategoryState.items.map((item, index) => {
                    return (
                        <CategoryGridCard
                            className={item.id === props.selectedCategory?.id ? "active" : ""}
                            key={index}
                            onClick={() => props.onSelectedCategory(item)}
                        >
                            <div className="category-image">
                                <img src={item.image ? item.image : CloudImages.defaultImage} alt="" />
                            </div>
                            <div className="category-name">
                                <TextField>{item.name}</TextField>
                            </div>
                        </CategoryGridCard>
                    );
                })}
            </GridTemplate>
        </Flex>
    );
};
