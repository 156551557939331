import { IPaginationRequest } from ".";
import { ImageAnalysisResult } from "./wahioAiModels";

export type WahioCloudItemType = "image" | "text" | "video" | "audio" | "compression" | "presentation" | "editable" | "other" | "videoLink";
export type SortCodeCloud = "default" | "date" | "name" | "size";

export const SORT_CODE_CLOUD_LIST: SortCodeCloud[] = ["date", "name", "size"];

export const wahioCloudItemTypeList: WahioCloudItemType[] = [
    "image",
    "text",
    "video",
    "audio",
    "compression",
    "presentation",
    "editable",
    "other",
];

export interface WahioCloudItem {
    id: string;
    accountId: string;
    name: string;
    accountUserId: string;
    type?: WahioCloudItemType;
    url: string;
    thumbnailUrl?: string;
    containerName: string;
    fileExtension: string;
    size: number;
    organizationId: string;
    dateCreated?: Date;
    fileTagsAsString?: string;
    fileTags?: string[];
    imageAnalysis?: ImageAnalysisResult;
    folderId?: string;
}

export interface ISearchCloudSort {
    sortDesc: boolean;
    sort: SortCodeCloud;
}

export interface WahioCloudSearchModel extends IPaginationRequest, ISearchCloudSort {
    accountId: string;
    organizationId: string;
    accountUserId?: string;
    type?: WahioCloudItemType;
    searchValue: string;
    sort: SortCodeCloud;
    sortDesc: boolean;
    folderId?: string;
}
export interface WahioCloudSearchFolderModel extends IPaginationRequest, ISearchCloudSort {
    accountId: string;
    organizationId: string;
    accountUserId?: string;
    searchValue: string;
    sort: SortCodeCloud;
    sortDesc: boolean;
    folderParentId?: string;
}

export interface WahioCloudSummaryResponse {
    accountId: string;
    totalSize: number;
    sizeType: string;
}

export interface WahioCloudFolder {
    id?: string;
    accountId: string;
    name: string;
    accountUserId: string;
    organizationId: string;
    folderParentId?: string | null;
    image?: string;
    createAt: Date;
    updateAt?: Date;
    childs: WahioCloudFolder[];
}
export class WahioCloudFolderImpl implements WahioCloudFolder {
    id?: string;
    name: string = "";
    image: string = "";
    accountId: string = "";
    accountUserId: string = "";
    organizationId: string = "";
    folderParentId: string | null = null;
    createAt: Date = new Date();
    childs: WahioCloudFolder[] = [];

    constructor(accountId: string, name?: string) {
        this.name = name ? name : "";
        this.accountId = accountId;
    }
}

export interface ErrorObjectFolderDeleteState {
    foldersIds: string[];
    message: string;
}