import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useFaviconDetector = () => {
    const location = useLocation();
    useEffect(() => {
        // const favicon = document.getElementById("favicon");
        // if (favicon) {
        //     let iconUrl = "";
        //     if (location.pathname.startsWith(PATHS.payments)) {
        //         iconUrl = "https://stowahioimagelibrary.blob.core.windows.net/common/finance-icon.png";
        //     }
        //     favicon.setAttribute("href", iconUrl ?? "/favicon.ico");
        //     lastIconUsedRef.current = iconUrl;
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
};
