import React from 'react';
import { getAccountUserFullName } from '../../../api/models/accountUser';
import { IPurchase } from '../../../api/models/purchase';
import { useAccountUserContext } from '../../../store/contexts/AccountUserContext';
import { ContactProfileButtonModal } from '../../Contacts/ContactProfile';
import { TableDetails } from '../../Orders/OrderProfile/styled';
import { Flex, TextField } from '../../_controls';
import { FlexImageLetter } from '../../_controls/containers/FlexImageLetter';

interface GeneralDetailsProps {
    currentOrder: IPurchase;
}

export const GeneralDetails = (props: GeneralDetailsProps) => {

    const { currentOrder } = props;
    const { getUserById } = useAccountUserContext();
    const user = getUserById(currentOrder.accountUserId);

    return (
        <Flex column gap15>
            <TableDetails cellSpacing={0} cellPadding={0} tdPadding={4} tdPaddingRight={15} hideBorde>
                <tbody>
                    <tr>
                        <td>
                            <TextField light>Creado Por</TextField>
                        </td>
                        <td>
                            <Flex alignCenter gap5>
                                <FlexImageLetter width={25} text={user?.firstName ?? "-"} image={user?.image} />
                                <span>{getAccountUserFullName(getUserById(currentOrder.accountUserId))}</span>
                            </Flex>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TextField light>Proveedor</TextField>
                        </td>
                        {currentOrder.supplier &&
                            <td>
                                <ContactProfileButtonModal contact={currentOrder.supplier} />
                            </td>
                        }
                    </tr>
                    <tr>
                        <td>
                            <TextField light>Bodega</TextField>
                        </td>
                        <td>{currentOrder.warehouse?.name}</td>
                    </tr>

                    {currentOrder.supplierInvoiceNumber && (
                        <tr>
                            <td>
                                <TextField light>Numero de factura</TextField>
                            </td>
                            <td>{currentOrder.supplierInvoiceNumber}</td>
                        </tr>
                    )}
                </tbody>
            </TableDetails>
        </Flex>
    )
}
