import React, { createContext, useReducer, useEffect } from "react";
import { saveData, LocalStoreKeys } from "..";
import { IOrderChannelActions, useActions } from "../actions/orderChannel";
import { applyMiddleware } from "../middlewares/orderChannel";
import { orderChannelReducer, initState } from "../reducers/orderChannel";
import { IOrderChannelState } from "../../api/models/orderChannel";

interface IContextProps {
    orderChannelState: IOrderChannelState;
    orderChannelActions: IOrderChannelActions;
}

export const OrderChannelContext = createContext({} as IContextProps);

const OrderChannelContextProvider = (props: any) => {
    const [orderChannelState, dispatch] = useReducer(orderChannelReducer, initState);

    useEffect(() => {
        saveData(LocalStoreKeys.orderChannel, orderChannelState);
    }, [orderChannelState]);

    const orderChannelActions = useActions(orderChannelState, applyMiddleware(dispatch));

    return <OrderChannelContext.Provider value={{ orderChannelState, orderChannelActions }}>{props.children}</OrderChannelContext.Provider>;
};

export default OrderChannelContextProvider;
