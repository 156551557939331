import { IPaginationRequest, PaymentMethod, WahioFile } from ".";
import { getAuthBody } from "../../auth";
import { Account } from "./accountUser";
import { AccountingType } from "./accounting";
import { Contact } from "./contact";
import { OrderStatus } from "./orderBase";
import { IOrderPaymentBase } from "./orderPayment";
import { IOrganization } from "./organizationTypes";

export interface TransactionAccount {
    id: string;
    accountId: string;
    account?: Account;
    organizationId: string;
    organization?: IOrganization;
    contactId: string;
    contact?: Contact;
    number: number;
    createdAt: string | Date;
    updatedAt: string | Date;
    dueDate: string | Date;
    paymentTermsDays: number; //ONLY UI
    deleteDate: string | Date;
    totalAmount: number;
    totalPaid: number;
    conceptId: string;
    concept?: TransactionAccountConcept;
    type: AccountingType;
    status: OrderStatus;
    notes: string;
    payments?: TransactionAccountPayment[];
    files?: WahioFile[];
    loaded?: boolean; //ONLY UI
    referenceId?: string;
    referenceType?: string;
}

export interface TransactionAccountPayment extends IOrderPaymentBase {
    transactionAccountId: string;
}

export interface TransactionAccountConcept {
    id: string;
    accountId: string;
    name: string;
    createdAt: string | Date;
}

export interface TransactionAccountDelete {
    accountUserId: string;
    cashRegisterId: string;
    method?: PaymentMethod;
    transactionAccountId: string;
    comment: string;
}

//export type TransactionType = "accountsReceivable" | "accountsPayable";

export interface TransactionAccountSearchModel extends IPaginationRequest {
    accountId: string;
    organizationId: string;
    contactId?: string;
    contact?: Contact;
    conceptId?: string;
    concept?: TransactionAccountConcept;
    searchValue: string;
    dateRangeActive: boolean;
    statusList: OrderStatus[];
    transactionType: AccountingType;
}

export class TransactionAccountImp implements TransactionAccount {
    id = "";
    accountId = "";
    organizationId = "";
    contactId = "";
    number = 0;
    createdAt = new Date();
    updatedAt = new Date();
    dueDate = new Date();
    paymentTermsDays: number = 0;
    deleteDate = new Date();
    orderId? = "";
    totalAmount = 0;
    totalPaid = 0;
    conceptId = "";
    type: AccountingType = "receivable";
    status: OrderStatus = "credit";
    notes = "";
    referenceId?: string;
    referenceType?: string;

    constructor(organizationId: string, contactId: string, conceptId: string, type: AccountingType) {
        this.accountId = getAuthBody().accountId;
        this.organizationId = organizationId;
        this.contactId = contactId;
        this.conceptId = conceptId;
        this.type = type;
    }
}
