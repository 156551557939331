import React, { useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useAppSettingsContext } from "../../appSettings/AppSettingsContext";
import PATHS from "../../constants/paths";
import { CashRegisterManualEntryModal } from "../CashRegisters/CashRegisterEntries/CashRegisterEntryForm";
import CreateContactModal from "../Contacts/ContactForm/CreateContactModal";
import Dropdown from "../Dropdown";
import ProductFormModal from "../Products/ProductCreationForm/ProductFormModal";
import { Flex } from "../_controls";
import messages from "./messages";
import { QuickActionButton, QuickActionDropdownStyle } from "./styled";

export enum quickOption {
    product,
    sale,
    order,
    purchase,
    customer,
    cashRegisterEntry,
}

export const TOUR_NAME_QUICK_ACTIONS = "TOUR_NAME_QUICK_ACTIONS";

export default function QuickActionsMenu() {
    const { appSettingsState } = useAppSettingsContext();

    return (
        <>
            <Dropdown
                icon="fa-regular fa-plus"
                iconPosition="left"
                contentHorizontal="right"
                contentVertical="inline"
                contentOutside
                title={appSettingsState.menuSmall ? "" : "Crear"}
            >
                <QuickActionsPanel />
            </Dropdown>
        </>
    );
}

export const QuickActionsPanel = () => {
    const intl = useIntl();
    const [currentQuickAction, setCurrentQuickAction] = useState<quickOption | undefined>(undefined);

    return (
        <>
            {currentQuickAction === quickOption.cashRegisterEntry && (
                <CashRegisterManualEntryModal
                    onCancel={() => {}}
                    onCreate={() => {}}
                    show={currentQuickAction === quickOption.cashRegisterEntry}
                    setShow={() => setCurrentQuickAction(undefined)}
                />
            )}
            {currentQuickAction === quickOption.product && (
                <ProductFormModal
                    onCancel={() => {}}
                    onCreate={() => {}}
                    show={currentQuickAction === quickOption.product}
                    setShow={() => setCurrentQuickAction(undefined)}
                />
            )}
            {currentQuickAction === quickOption.customer && (
                <CreateContactModal
                    onCreate={() => {}}
                    show={currentQuickAction === quickOption.customer}
                    setShow={() => setCurrentQuickAction(undefined)}
                />
            )}
            <QuickActionDropdownStyle>
                <Flex column padding={15}>
                    <QuickActionButton justifyStart rounded w100 hideBorder onClick={() => setCurrentQuickAction(quickOption.product)}>
                        <i className="fa-regular fa-box icon"></i>
                        {intl.formatMessage(messages.createProduct)}
                    </QuickActionButton>
                    <NavLink to={PATHS.orderPosNew("sale")}>
                        <QuickActionButton justifyStart rounded w100 hideBorder onClick={() => setCurrentQuickAction(quickOption.sale)}>
                            <i className="fa-regular fa-cart-shopping icon"></i>
                            {intl.formatMessage(messages.createSale)}
                        </QuickActionButton>
                    </NavLink>

                    <NavLink to={PATHS.orderShipmentNew("shipment")}>
                        <QuickActionButton justifyStart rounded w100 hideBorder onClick={() => setCurrentQuickAction(quickOption.order)}>
                            <i className="fa-regular fa-truck icon"></i>
                            {intl.formatMessage(messages.createOrder)}
                        </QuickActionButton>
                    </NavLink>
                    <NavLink to={PATHS.productPurchasesNew}>
                        <QuickActionButton justifyStart rounded w100 hideBorder onClick={() => setCurrentQuickAction(quickOption.purchase)}>
                            <i className="fa-regular fa-basket-shopping icon"></i>
                            {intl.formatMessage(messages.createPurchase)}
                        </QuickActionButton>
                    </NavLink>
                    <QuickActionButton justifyStart rounded w100 hideBorder onClick={() => setCurrentQuickAction(quickOption.customer)}>
                        <i className="fa-regular fa-user-plus icon"></i>
                        {intl.formatMessage(messages.createContact)}
                    </QuickActionButton>

                    <QuickActionButton
                        justifyStart
                        rounded
                        w100
                        hideBorder
                        onClick={() => setCurrentQuickAction(quickOption.cashRegisterEntry)}
                    >
                        <i className="fa-regular fa-dollar-sign icon"></i>
                        Crear Pago
                    </QuickActionButton>
                </Flex>
            </QuickActionDropdownStyle>
        </>
    );
};
