import React, { useEffect, useState } from "react";
import { SquareButton } from "..";
import { CollapseProps, ContainerCollapseBody } from "./styled";

interface ContainerCollapseProps extends CollapseProps {
    id?: string;
    children: React.ReactNode;
    initialStateOpen?: boolean;
    title?: string;
    icon?: string;
    renderOnShow?: boolean;
    hidePaddingMobile?: boolean;
    textLight?: boolean;
    removeBorder?: boolean;
    removeAllBorderPadding?: boolean;
}

export default function ContainerCollapse(props: ContainerCollapseProps) {
    const [showContent, setShowContent] = useState(!props.title || props.initialStateOpen);
    const [showAnimation, setShowAnimation] = useState<boolean>();

    useEffect(() => {
        setShowContent(!props.title || props.initialStateOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialStateOpen]);

    useEffect(() => {
        setShowAnimation(showContent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showContent]);

    let className = "container-body ";
    if (!showContent) className += "small-padding ";
    if (props.removeBorder) className += "remove-border ";
    if (props.removeAllBorderPadding) className += "remove-all-border-padding ";
    if (props.isMobile) className += "mobile ";

    return (
        <ContainerCollapseBody paddingTop={props.paddingTop} id={props.id} className={className} border={props.border}>
            {props.title && (
                <div className="container-header" onClick={() => setShowContent(!showContent)}>
                    <span className={`${props.textLight ? "text-light" : ""} title-header`}>{props.title}</span>
                    <SquareButton className="btn-arrow" type="button">
                        <span className={showContent ? "wahioicon-angle-down" : "wahioicon-angle-right"}></span>
                    </SquareButton>
                </div>
            )}
            {(showContent || !props.renderOnShow) && (
                <div className={`container-content ${showContent ? "show" : ""} ${showAnimation ? "animate" : ""}`}>{props.children}</div>
            )}
        </ContainerCollapseBody>
    );
}
