import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { Order, OrderTypeTemp } from "../../../api/models/order";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useOrderLocalContext } from "../../../store/contexts/OrderLocalContext";
import { useOrderScreenPreviewContext } from "../../../store/contexts/OrderScreenPreviewContext";
import { getEmptyOrder } from "../../../store/reducers/currentOrder";
import { formatMoney } from "../../../utils";
import { getProductThumbnailUrl } from "../../Products/helper";
import { DefaultButton, Flex, PanelHeaderContainer, SquareButton, TextField } from "../../_controls";
import TooltipRadix from "../../_controls/WahioTooltip/TooltipRadix";
import { FlexImageIcon } from "../../_controls/containers/FlexImageLetter";
import ConfirmationModal from "../../modals/ConfirmationModal";
import CreateOrderForm from "../CreateOrderForm";
import messages from "../messages";
import { OrderTabsGridHeader, OrderTabsHeader } from "./styled";

export const MultipleOrderPanel = () => {
    return (
        <>
            <MultipleOrderPanelBase />
        </>
    );
};

export const SCREEN_PREVIEW_PARAM = "wahioScreenPreview";

const MultipleOrderPanelBase = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramType = searchParams.get("type");

    const { orders, onAddOrder, onUpdateOrder, onDeleteOrder } = useOrderLocalContext();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const screenPreviewContext = useOrderScreenPreviewContext();

    useEffect(() => {
        if (paramType && paramType !== "new") {
            const index = orders.findIndex((order) => order.orderTypeTemp === paramType);
            if (index === selectedTabIndex) return;

            if (index >= 0) {
                setSelectedTabIndex(index);
            } else if (paramType === "shipment") {
                addOrder(paramType);
            } else if (paramType === "quotation") {
                addOrder(paramType);
            } else if (paramType === "last") {
                setSelectedTabIndex(orders.length - 1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramType]);

    useEffect(() => {
        if (orders.length === 1 && selectedTabIndex !== 0) {
            setSelectedTabIndex(0);
        }
    }, [orders, selectedTabIndex]);

    const addOrder = (type?: OrderTypeTemp) => {
        setSearchParams({});
        setSelectedTabIndex(orders.length);
        let order = getEmptyOrder("partial", type ?? "sale");
        onAddOrder(order);
        sendOrderToPreview(order);
    };

    const sendOrderToPreview = (order: Order) => {
        if (screenPreviewContext.screenPreview) {
            const message = {
                type: "order",
                order,
            };
            screenPreviewContext.screenPreview?.postMessage(message, window.location.origin);
        }
    };

    const onClickTab = (e: any, index: number) => {
        setSearchParams({});
        setSelectedTabIndex(index);
        let order = orders[index];
        sendOrderToPreview(order);
    };

    const onRemoveTab = (index: number) => {
        onDeleteOrder(index);
        if (selectedTabIndex >= index) {
            setSelectedTabIndex(selectedTabIndex - 1);
        }
    };

    const handleUpdateOrder = (order: Order) => {
        onUpdateOrder(selectedTabIndex, order);
        sendOrderToPreview(order);
    };

    const selectedOrder = selectedTabIndex >= 0 && selectedTabIndex <= orders.length ? orders[selectedTabIndex] : undefined;
    const tabsNumber = orders.length;
    return (
        <PanelHeaderContainer>
            <OrderTabsGridHeader>
                <OrderTabsHeader>
                    {orders?.map((order, index) => (
                        <TabButton
                            key={index}
                            allowRemove={tabsNumber > 1}
                            order={order}
                            index={index}
                            onClickTab={onClickTab}
                            onRemoveTab={onRemoveTab}
                            selectedTabIndex={selectedTabIndex}
                        />
                    ))}
                    <Flex gap10 alignCenter marginLeft={10}>
                        <TooltipRadix
                            title="Nuevo Tab"
                            trigger={
                                <SquareButton small removeBackground onClick={() => addOrder("sale")}>
                                    <i className="fa-regular fa-plus"></i>
                                </SquareButton>
                            }
                        />
                        {/* <SquareButton small removeBackground onClick={() => screenPreviewContext.openWindowScreen()}>
                            <i className="fa-regular fa-external-link"></i>
                        </SquareButton> */}
                    </Flex>
                </OrderTabsHeader>
            </OrderTabsGridHeader>
            {selectedOrder && (
                <Flex positionRelative overflowAuto w100>
                    <CreateOrderForm
                        panelTabIndex={selectedTabIndex}
                        order={selectedOrder}
                        onOrderChange={handleUpdateOrder}
                        onCompleted={() => onUpdateOrder(selectedTabIndex, getEmptyOrder("partial", selectedOrder.orderTypeTemp))}
                        pageTitle={`Order: ${selectedTabIndex + 1}`}
                    />
                </Flex>
            )}
        </PanelHeaderContainer>
    );
};

interface TabButtonProps {
    index: number;
    order: Order;
    selectedTabIndex: number;
    allowRemove?: boolean;
    onRemoveTab: (index: number) => void;
    onClickTab: (e: any, index: number) => void;
}

const TabButton = ({ index, order, selectedTabIndex, onClickTab, allowRemove, onRemoveTab }: TabButtonProps) => {
    const intl = useIntl();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    let tabTitle = order.orderTypeTemp
        ? `${allowRemove ? `${index + 1}. ` : ""}${checkAndGetMessage(intl, messages, order.orderTypeTemp)}${
              order.customer ? ` ${order.customer.firstName}` : ""
          }`
        : "Tab";

    const isSelected = selectedTabIndex === index;

    const onClickTabInternal = (e: any) => {
        if (e.target.id === "REMOVE_TAB") return;
        onClickTab(e, index);
    };

    const onClickRemove = (e: any) => {
        e.preventDefault();
        onRemoveTab(index);
    };

    return (
        <>
            {showConfirmDelete && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmDelete(false)}
                    onOkay={() => onRemoveTab(index)}
                    title="¿Estas seguro que deseas eliminar esta pestaña?"
                    description="Las pestaña contiene items que se perderán si la eliminas."
                    show={showConfirmDelete}
                    setShow={setShowConfirmDelete}
                />
            )}
            <TooltipRadix
                delay={400}
                side="bottom"
                align="start"
                trigger={
                    <DefaultButton
                        className={`order-tab-button ${isSelected ? "selected" : ""} tab-type-${order.orderTypeTemp ?? "order"}`}
                        onClick={(e) => onClickTabInternal(e)}
                    >
                        {tabTitle}{" "}
                        {allowRemove && (
                            <>
                                {order.items.length > 0 && (
                                    <i
                                        id="REMOVE_TAB"
                                        onClick={() => setShowConfirmDelete(true)}
                                        className="remove-tab fa-regular fa-times"
                                    ></i>
                                )}
                                {order.items.length === 0 && (
                                    <i id="REMOVE_TAB" onClick={onClickRemove} className="remove-tab fa-regular fa-times"></i>
                                )}
                            </>
                        )}
                    </DefaultButton>
                }
                content={<TabContent order={order} tabTitle={tabTitle} />}
            />
        </>
    );
};

interface TabContentProps {
    order: Order;
    tabTitle?: string;
}

const TabContent = ({ order }: TabContentProps) => {
    const items = order.items ?? [];

    return (
        <Flex column gap10 maxWidth={200} padding={5}>
            {items.length === 0 && (
                <TextField small light>
                    No hay items
                </TextField>
            )}
            {items.map((item, index) => (
                <Flex key={index} gap10 alignCenter>
                    <FlexImageIcon image={getProductThumbnailUrl(item.product, item.productVariant)} />
                    <Flex column overflowHidden w100>
                        <TextField ellipsis small>
                            {item.product?.name}
                        </TextField>
                        <Flex spaceBetween gap10 flexWrap alignCenter>
                            <TextField small light>
                                {formatMoney(item.total)}
                            </TextField>
                            <TextField small light>
                                x{item.quantity}
                            </TextField>
                        </Flex>
                    </Flex>
                </Flex>
            ))}
        </Flex>
    );
};

export default MultipleOrderPanel;
