import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import customMoment from "../../utils/momentFormat/dateFormat";
import Modal, { IModalShow } from "../modals/Modal";
import { DefaultButton, Flex, SquareButton } from "../_controls";
import CustomDateRangePicker, { DateRangeSelection, IRangeSelectionTitle } from "./CustomDateRangePicker";
import { dateDefaultOptionPairsTitle } from "./filesRange";
import messages from "./messages";
import { DateRangeSelectedView } from "./styled";

interface DatePickerModalProps extends IModalShow {
    rangeSelection: DateRangeSelection;
    onChange: (range: DateRangeSelection) => void;
}

export default function DatePickerModal(props: DatePickerModalProps) {
    const intl = useIntl();

    const onChange = (range: DateRangeSelection) => {
        props.onChange(range);
        props.setShow(false);
    };

    return (
        <Modal
            scrollInside={true}
            overflowAuto={true}
            title={intl.formatMessage(messages.calendar)}
            sizeType="auto"
            borderRadius={20}
            {...props}
        >
            <CustomDateRangePicker
                {...props}
                onChange={onChange}
                onClose={() => props.setShow(false)}
                rangeSelection={props.rangeSelection}
            />
        </Modal>
    );
}

interface useDatePickerModalProps {
    title?: string;
    showSelectionTitle?: boolean;
    preventRemove?: boolean;
    onChange?: (range: IRangeSelectionTitle) => void;
    small?: boolean;
    bgLight?: boolean;
    hideBtnBorder?: boolean;
}

interface DataPicketModalProps {
    defaultRange?: DateRangeSelection;
}

const getRangeSelection = (defaultRange?: DateRangeSelection) => {
    if (defaultRange) {
        return { ...defaultRange, isActive: true };
    }

    return { startDate: new Date(), endDate: new Date(), isActive: false };
};

interface DateRangeTitleProps {
    rangeSelection: DateRangeSelection;
}
export const DateRangeTitle = (props: DateRangeTitleProps) => {
    const { rangeSelection } = props;
    return (
        <Flex gap10 alignCenter>
            <span>{customMoment(rangeSelection.startDate).format("DD MMM YY")}</span>
            <i className="fa-light fa-arrows-left-right"></i>
            <span>{customMoment(rangeSelection.endDate).format("DD MMM YY")}</span>
        </Flex>
    );
};

interface DatePickerButtonProps {
    activeRange?: DateRangeSelection;
    onChange: (range: IRangeSelectionTitle) => void;
    w100?: boolean;
}

export const DatePickerButton = (props: DatePickerButtonProps) => {
    const [showModal, setShowModal] = useState(false);

    const dateTitle = React.useMemo(() => {
        if (props.activeRange) {
            return `${customMoment(props.activeRange.startDate).format("MMM DD")} - ${customMoment(props.activeRange.endDate).format(
                "MMM DD"
            )}`;
        }
        return "Fecha";
    }, [props.activeRange]);

    return (
        <>
            {showModal && (
                <DatePickerModal
                    rangeSelection={getRangeSelection(props?.activeRange)}
                    onChange={(range) => {
                        if (props.onChange) props.onChange({ ...range, title: dateDefaultOptionPairsTitle(range) });
                    }}
                    show={showModal}
                    setShow={setShowModal}
                />
            )}
            <DefaultButton
                bgLight={!!props.activeRange}
                colorLight={!props.activeRange}
                onClick={() => setShowModal(true)}
                w100={props.w100}
                justifyStart
                rounded
            >
                {dateTitle}
                <i className="left-icon fa-regular fa-calendar"></i>
            </DefaultButton>
        </>
    );
};

export const useDatePickerModal = (props?: DataPicketModalProps) => {
    const [showModal, setShowModal] = useState(false);
    const [rangeSelection, setRangeSelection] = useState(getRangeSelection(props?.defaultRange));

    useEffect(() => {
        setRangeSelection(getRangeSelection(props?.defaultRange));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.defaultRange]);

    const removeSelection = () => {
        setRangeSelection({ ...rangeSelection, isActive: false });
    };

    const handleSelectDate = (range: DateRangeSelection) => {
        setRangeSelection({
            ...range,
            isActive: true,
        });
        setShowModal(false);
    };

    const ModalResult = (props: useDatePickerModalProps) => (
        <>
            {showModal && (
                <DatePickerModal
                    rangeSelection={rangeSelection}
                    onChange={(range) => {
                        if (props.onChange) props.onChange({ ...range, title: dateDefaultOptionPairsTitle(range) });
                        handleSelectDate(range);
                    }}
                    show={showModal}
                    setShow={setShowModal}
                />
            )}
            <Flex>
                <DateRangeSelectedView className={`${props.bgLight ? "remove-border" : ""}`}>
                    <DefaultButton
                        className={`btn-date-picket-text ${props.bgLight ? "bg-light" : ""} ${props.small ? "small" : ""}`}
                        hideBorder={props.hideBtnBorder}
                        colorLight={!rangeSelection.isActive}
                        onClick={() => setShowModal(true)}
                    >
                        <span>
                            {rangeSelection.isActive && props.showSelectionTitle ? (
                                <DateRangeTitle rangeSelection={rangeSelection} />
                            ) : (
                                props.title ?? "Fecha"
                            )}
                        </span>
                        <i className="fa-regular fa-calendar"></i>
                    </DefaultButton>
                    {!props.preventRemove && rangeSelection.isActive && (
                        <SquareButton small onClick={() => removeSelection()} className="remove-date-filter">
                            <span className="wahioicon-times"></span>
                        </SquareButton>
                    )}
                </DateRangeSelectedView>
            </Flex>
        </>
    );

    return { Modal: ModalResult, rangeSelection, removeSelection };
};
