import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../../api";
import { createBillingInvoicePayment } from "../../../../api/account/accountApi";
import { waitFetch } from "../../../../api/fetchApi";
import { BillingInvoice, BillingInvoicePayment } from "../../../../api/models/accountBilling";
import { AccountPaymentMethod, getUserPermissions } from "../../../../api/models/accountUser";
import { IWompiCheckTransactionResponse } from "../../../../api/models/wompi";
import { useWompiPayments } from "../../../../api/wompi/hooks";
import { getAuthBody } from "../../../../auth";
import { useUserContext } from "../../../../store/contexts/UserContext";
import { getDateFormat, getRoundNumber } from "../../../../utils";
import { formatMoney } from "../../../../utils/index";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import PermissionDenied from "../../../PermissionDenied";
import { Flex } from "../../../_controls";
import { DefaultButton, PrimaryButton } from "../../../_controls/buttons/index";
import Modal from "../../../modals/Modal";
import { BillingPaymentMethodSelector } from "../BillingPaymentMethod/BillingPaymentMethodSelector";
import StripePaymentSetupForm from "../BillingPaymentMethod/StripePaymentSetupForm";
import { getSignatureInput, getTransactionSourceInput } from "../helpers";
import messages from "./messages";

interface PayInvoiceProps {
    onCancel: () => void;
    onCreate: (invoice: BillingInvoice) => void;
    invoice: BillingInvoice;
}

export const PayInvoicePendingForm = (props: PayInvoiceProps) => {
    const { userState, userActions, methodList } = useUserContext();
    const invoice = props.invoice;
    const intl = useIntl();
    const alert = useAlert();
    const account = userState.user?.account;
    const wompiPayments = useWompiPayments();
    const [paymentMethodSelected, setPaymentMethodSelected] = useState<AccountPaymentMethod>();
    const [paymentAmount, setPaymentAmount] = useState({
        value: 0,
        valueInCents: 0,
    });
    const invoicePaymentMutation = useMutation((data: BillingInvoicePayment) => createBillingInvoicePayment(data));
    const [isFetchingTransaction, setIsFetchingTransaction] = useState(false);
    const [showModalCreatePaymentMethod, setShowModalCreatePaymentMethod] = useState(false);

    useEffect(() => {
        let paymentAmount: number;
        if (invoice.totalPaid < invoice.amount) {
            paymentAmount = getRoundNumber(invoice.amount - invoice.totalPaid);
        } else {
            paymentAmount = getRoundNumber(invoice.amount);
        }

        setPaymentAmount({ value: paymentAmount, valueInCents: Math.trunc(paymentAmount) * 100 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invoice]);

    useEffect(() => {
        if (account && account.defaultPaymentMethodId && methodList) {
            const matchMethodResult = methodList.find((x) => x.id === account?.defaultPaymentMethodId);
            if (matchMethodResult) {
                setPaymentMethodSelected(matchMethodResult);
            } else {
                setPaymentMethodSelected(methodList[0]);
            }
        } else {
            setPaymentMethodSelected(account && methodList?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.defaultPaymentMethodId, methodList]);

    const getTransactionAndPayInvoiceRetry = (transactionId: string, paymentAmount: number, tries: number) => {
        const onError = (err: any): any => {
            tries -= 1;
            if (tries <= 0) {
                alert.error("Hubo un error al obtener el pago");
                throw err;
            }

            return waitFetch(3000).then(() => getTransactionAndPayInvoiceRetry(transactionId, paymentAmount, tries));
        };

        return wompiPayments.getTransactionMutation
            .mutateAsync(transactionId)
            .then((transaction) => {
                if (transaction.data.status === "DECLINED") {
                    alert.error("Pago rechazado");
                } else if (transaction.data.status !== "APPROVED") {
                    throw new Error("No fue posible obtener un estado aprobado del pago");
                } else {
                    handleCreateInvoicePayment(transaction);
                }
            })
            .catch((err) => onError(err));
    };

    const handleCreateInvoicePayment = (transaction: IWompiCheckTransactionResponse) => {
        let data: BillingInvoicePayment = {
            id: "",
            createdAt: new Date(),
            billingInvoiceId: props.invoice.id,
            amount: paymentAmount.value,
            paymentMethod: "CARD",
            paymentSource: "wompi",
            paymentSourceTransactionId: transaction.data.id,
            currency: transaction.data.currency,
        };

        invoicePaymentMutation
            .mutateAsync(data)
            .then((response) => {
                alert.success("La factura ha sido pagada con éxito");
                userActions.requestAccountUser(getAuthBody().accountUserId);
                props.onCreate(response);
            })
            .catch((err) => {
                alert.error(getServiceMessageError(err));
            });
    };

    const onCreatePayment = async () => {
        if (!paymentMethodSelected) {
            alert.info("Debes agregar un método para realizar el pago");
            return;
        }

        setIsFetchingTransaction(true);
        try {
            const amountInCents = paymentAmount.valueInCents;
            const referenceId = `${invoice.id}-${new Date().getTime()}`;

            const inputIntegrity = getSignatureInput(referenceId, amountInCents.toString());
            const signatureRes = await wompiPayments.wompiIntegrityMutation.mutateAsync(inputIntegrity);

            const paymentMethod = paymentMethodSelected;

            const transactionPaymentSource = getTransactionSourceInput(
                amountInCents,
                userState.user?.email ?? "NA",
                signatureRes.signature,
                referenceId,
                paymentMethod.token
            );
            const transactionResult = await wompiPayments.createTransactionMutation.mutateAsync(transactionPaymentSource);
            await getTransactionAndPayInvoiceRetry(transactionResult.data.id, paymentAmount.value, 6);
        } catch (err) {
            alert.error(getServiceMessageError(err));
        }
        setIsFetchingTransaction(false);
    };

    const paymentIsLoading = wompiPayments.createTransactionMutation.isLoading || isFetchingTransaction || invoicePaymentMutation.isLoading;

    const permission = getUserPermissions(userState.user);

    if (!permission.billing?.allowCreatePayments) return <PermissionDenied message="No tienes permisos para realizar pagos" />;

    return (
        <Flex w100 column padding={5} gap15>
            {showModalCreatePaymentMethod && (
                <Modal show={showModalCreatePaymentMethod} useButtonClose ignoreOutsideClick setShow={setShowModalCreatePaymentMethod}>
                    <Flex padding={20} w100 boxSizingBorderBox>
                        <StripePaymentSetupForm />
                    </Flex>
                </Modal>
            )}
            <Flex w100 column gap10>
                <Flex w100 spaceBetween>
                    <Flex column>
                        <span className="text-light text-small">{intl.formatMessage(messages.generationDate)}</span>
                        <span>{getDateFormat(invoice.createdAt, "MMMM, DD YYYY")}</span>
                    </Flex>
                    <Flex column justifyCenter alignCenter>
                        <span className="text-light text-small">{intl.formatMessage(messages.number)}</span>
                        <span>{invoice.number}</span>
                    </Flex>
                </Flex>
                <Flex column>
                    <span className="text-light text-small">{intl.formatMessage(messages.dueDate)}</span>
                    <span>{getDateFormat(invoice.dueDate, "MMMM DD YYYY")}</span>
                </Flex>
                <Flex column>
                    <span className="text-light text-small">{intl.formatMessage(messages.billingPeriod)}</span>
                    <span>{`Desde ${getDateFormat(invoice.initialIntervalDate, "MM/DD/YYYY")} Hasta ${getDateFormat(
                        invoice.finalIntervalDate,
                        "MM/DD/YYYY"
                    )}`}</span>
                </Flex>
            </Flex>

            <Flex column gap10 marginTop={15} marginBottom={20}>
                <Flex paddingBottom={10} minWidth={200} alignStart spaceBetween className="border-dashed">
                    <span className="text-small text-light">Valor facturado</span>
                    <span>{formatMoney(invoice.amount)}</span>
                </Flex>
                {invoice.totalPaid < invoice.amount && (
                    <>
                        <Flex paddingBottom={10} minWidth={200} alignStart spaceBetween className="border-dashed">
                            <span className="text-small text-light">Valor pagado</span>
                            <span>{formatMoney(invoice.totalPaid)}</span>
                        </Flex>
                        <Flex paddingBottom={10} minWidth={200} alignStart spaceBetween className="border-dashed">
                            <span className="text-small text-light">Valor pendiente</span>
                            <span>{formatMoney(paymentAmount.value)}</span>
                        </Flex>
                    </>
                )}
            </Flex>

            {methodList && methodList.length > 0 && paymentMethodSelected && (
                <BillingPaymentMethodSelector
                    methodSelected={paymentMethodSelected}
                    onChangeMethodSelected={setPaymentMethodSelected}
                    setShowModalCreatePaymentMethod={setShowModalCreatePaymentMethod}
                />
            )}
            {account && methodList?.length === 0 && (
                <Flex padding={15} alignCenter justifyCenter border borderRadius={15} borderDashed gap10>
                    <DefaultButton onClick={() => setShowModalCreatePaymentMethod(true)} rounded hideBorder colorLight>
                        <span className="wahioicon-plus"></span>
                        Agregar método de pago
                    </DefaultButton>
                </Flex>
            )}

            {paymentIsLoading && <LoadingDualRing center />}
            {!paymentIsLoading && (
                <Flex gap15 justifyEnd className="mt-2">
                    <DefaultButton onClick={() => props.onCancel()}>Cancelar</DefaultButton>
                    <PrimaryButton onClick={() => onCreatePayment()}>
                        <span className="wahioicon-credit-card"></span>
                        Pagar factura
                    </PrimaryButton>
                </Flex>
            )}
        </Flex>
    );
};
