import styled from "styled-components";

export const PreparationStatusLine = styled.div`
    color: ${(props) => props.theme.palette.text};
    &.waiting {
        color: #ff5722;
    }
    &.inPreparation {
        color: #009688;
    }
`;
