import styled from "styled-components";

export const CountriesPhoneCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .search-input {
        margin: 10px;
        margin-left: 15px;
        margin-top: 15px;
        width: auto;
        position: sticky;
        top: 10px;
        box-shadow: 1px 1px 14px ${(props) => props.theme.palette.shadowColor};
    }
`;

export const DropdownPhoneCodesStyle = styled.div`
    .dropdown-btn {
        gap: 10px !important;
    }
`;

export const CountryPhoneRow = styled.div`
    display: flex;
    padding: 6px 10px;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .code {
        width: 60px;
    }
    .image {
        width: 35px;
        margin-right: 10px;
        display: flex;
        border-radius: 3px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .name {
        width: 100%;
    }
`;
