import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CreateTable from "react-select/creatable";
import { ProductCreationProps } from ".";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { SelectOptionObject } from "../../../api/models";
import { IProductSubCategory, ProductSubCategory } from "../../../api/models/product";
import { IProductCategory, ProductCategory } from "../../../api/models/productCategory";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import { CustomCategoryContext } from "../../../store/contexts/CustomCategoryContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultInput, Flex, SquareButton, TextField } from "../../_controls";
import ProductCategorySelector from "../ProductCategories/ProductCategorySelector";
import ImagesForm from "./ImagesForm";
import ProductStockControl from "./ProductStockControl";
import ProductTypeSelect from "./ProductTypeSelect";
import messages from "./messages";
import { FormInputProduct, ProductButtonCheck } from "./styled";

export default function DetailsForm(props: ProductCreationProps) {
    const isMounted = React.useRef(true);
    const intl = useIntl();
    const alert = useAlert();
    const [categoryIsInProgress, setCategoryIsInProgress] = useState(false);
    const { userState } = useContext(UserContext);
    const { customCategoryState, customCategoryActions } = useContext(CustomCategoryContext);
    const { product, onChange: onChangeProduct } = props;
    const [showSubCategories, setShowSubCategories] = useState(false);

    const clientSettings = userState.user?.account?.clientSettings;

    useEffect(() => {
        if (customCategoryState.items.length === 0) {
            customCategoryActions.request();
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (e: any) => {
        props.onChange({
            ...props.product,
            [e.target.name]: e.target.value,
        });
    };

    const onCategoryChange = (value?: IProductCategory) => {
        if (value) {
            props.onChange({ ...product, customCategory: value, customCategoryId: value.id });
        }
    };

    const onCreateCustomCategory = (name: string) => {
        setCategoryIsInProgress(true);
        wahioFetch.post(
            productEndpoint.post.customCategory,
            new ProductCategory(getAuthBody().accountId, name),
            (success) => {
                setCategoryIsInProgress(false);
                let category: IProductCategory = success.data;
                customCategoryActions.add(category);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const getSelectSubCategory = (item: IProductSubCategory) => {
        let option: SelectOptionObject<IProductSubCategory> = {
            value: item.customCategoryId ?? "",
            label: item.customCategory.name,
            object: item,
        };
        return option;
    };

    return (
        <Flex column gap={20}>
            {!props.product.includesVariants && (
                <ImagesForm
                    images={props.product.images ?? []}
                    onChangeImages={(images) => {
                        props.onChange({ ...props.product, images: images });
                    }}
                />
            )}

            <FormInputProduct>
                <label htmlFor="name">{intl.formatMessage(messages.inputName)}</label>
                <DefaultInput
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={props.product.name}
                    placeholder={intl.formatMessage(messages.inputName)}
                />
            </FormInputProduct>

            <Flex column gap5>
                <TextField small light>
                    {intl.formatMessage(messages.mainCategory)}
                </TextField>
                <Flex gap10 alignCenter>
                    <ProductCategorySelector
                        defaultCategoryId={props.product.customCategoryId}
                        onChangeCategory={onCategoryChange}
                        hideTitle
                        selectBodyClass="w100"
                        title={intl.formatMessage(messages.mainCategory)}
                    />
                    {!showSubCategories && (product.productCategories ?? []).length === 0 && (
                        <SquareButton bgLight colorLight onClick={() => setShowSubCategories(true)}>
                            <i className="fa-regular fa-plus"></i>
                        </SquareButton>
                    )}
                </Flex>
            </Flex>

            {(showSubCategories || (product.productCategories ?? []).length > 0) && (
                <SelectBody className="multi-value-theme-color">
                    <span className="label">{intl.formatMessage(messages.secondaryCategories)}</span>
                    <CreateTable
                        placeholder="Seleccionar"
                        className="react-select-basic"
                        classNamePrefix="select"
                        value={product.productCategories ? product.productCategories?.map((item) => getSelectSubCategory(item)) : []}
                        isDisabled={false}
                        isMulti
                        isRtl={false}
                        onCreateOption={onCreateCustomCategory}
                        onChange={(value) => props.onChange({ ...props.product, productCategories: value.map((x) => x.object) })}
                        isClearable={false}
                        isLoading={categoryIsInProgress}
                        isSearchable={true}
                        name="seller"
                        options={customCategoryState.items.map((item) => getSelectSubCategory(new ProductSubCategory(product.id, item)))}
                    />
                </SelectBody>
            )}

            {!product.includesVariants && (
                <FormInputProduct>
                    <label htmlFor="title">
                        {intl.formatMessage(messages.inputSalePrice)} {clientSettings?.price ? `(${clientSettings.price})` : ""}
                    </label>
                    <TextBoxNumeral
                        value={product.price}
                        format="money"
                        onNumberChange={(value) => onChangeProduct({ ...product, price: value })}
                    />
                </FormInputProduct>
            )}

            <Flex gap={20} alignCenter w100 spaceBetween>
                <ProductTypeSelect {...props} />
                <ProductStockControl {...props} />
            </Flex>
        </Flex>
    );
}

interface ProductDetailCheckProps {
    disabled?: boolean;
    title?: string;
    onClick: () => void;
    checked?: boolean;
}

export const ProductDetailCheck = (props: ProductDetailCheckProps) => {
    const icon = props.checked ? "wahioicon-check" : "";
    return (
        <ProductButtonCheck rounded disabled={props.disabled} className={props.checked ? "active" : ""} onClick={props.onClick}>
            <span className={`icon ${icon}`}></span>
            {props.title}
        </ProductButtonCheck>
    );
};
