import React, { useCallback, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { getAccountId } from ".";
import { DefaultButton, Flex, TextField } from "../../components/_controls";
import TooltipRadix from "../../components/_controls/WahioTooltip/TooltipRadix";
import { SOCKETIO_SERVER_ENDPOINT } from "../appConfig";

export const EventKey = {
    deviceActive: "on_device_active",
    useHere: "session_device_use_here",
    roomSuscribe: "room_subscribe",
    roomChange: "on_room_changed",
    messageRoom: "message_room",
    login: "login",
    userSession: "on_user_session",
};

let socketActives: Set<string> = new Set();

export const socket = socketIOClient(SOCKETIO_SERVER_ENDPOINT, {
    timeout: 5000,
    forceNew: true,
    reconnection: true, // habilitar la reconexión automática
});

// Emitir eventos con manejo de errores
export const sendSocketEvent = (eventKey: string, data: any) => {
    if (eventKey === EventKey.roomSuscribe) {
        if (socketActives.has(data.roomId)) {
            return;
        } else {
            socketActives.add(data.roomId);
        }
    }

    try {
        socket.emit(eventKey, data);
    } catch (error) {
        console.error(`Error al emitir el evento ${eventKey}: `, error);
    }
};

const useSocketOnEvent = (eventKey: string, listener: (data: any) => void) => {
    useEffect(() => {
        socket.on(eventKey, listener);

        return () => {
            socket.off(eventKey, listener);
        };
    }, [eventKey, listener]);
};

export const useSocketDeviceOnEvent = (eventKey: string, listener: (data: any) => void) => {
    useEffect(() => {
        socket.on(eventKey, listener);

        return () => {
            socket.off(eventKey, listener);
        };
    }, [eventKey, listener]);
};

export const useSocketAccountOnEvent = (eventKey: string, listener: (data: any) => void) => {
    useEffect(() => {
        socket.on(eventKey, (data) => {
            if (data.accountId === getAccountId()) {
                listener(data);
            }
        });

        return () => {
            socket.off(eventKey, listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useSocketOnRoomChange = (listener: (data: any) => void) => {
    useEffect(() => {
        socket.on(EventKey.roomChange, (data) => {
            if (data.accountId === getAccountId()) {
                listener(data);
            }
        });

        return () => {
            socket.off(EventKey.roomChange, listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

type ConnectionStatus = "connected" | "disconnected";

export const ConnectionStatusIndicator: React.FC = () => {
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>("connected");
    const [lastPing, setLastPing] = useState<number>(0);

    const handleConnect = useCallback(() => {
        setConnectionStatus("connected");
        // ... other code ...
    }, []);

    const handleDisconnect = useCallback(() => {
        setConnectionStatus("disconnected");
        // ... other code ...
    }, []);

    // Use the custom hook for socket events
    useSocketOnEvent("connect", handleConnect);
    useSocketOnEvent("disconnect", handleDisconnect);

    useEffect(() => {
        // Lógica para hacer un ping cada dos minutos
        const pingInterval = setInterval(() => {
            socket.emit("ping", { timestamp: Date.now() });
        }, 120000); // 120000 ms = 2 minutos

        socket.on("pong", (data) => {
            const latency = Date.now() - data.timestamp;
            setLastPing(latency);
        });

        // Limpiar intervalo y listeners cuando el componente se desmonte
        return () => {
            clearInterval(pingInterval);
            socket.off("pong");
        };
    }, []);

    // Función para intentar la reconexión manualmente
    const handleReconnect = () => {
        if (!socket.connected) {
            socket.connect();
        }
    };

    // Determinar el color basado en el estado de la conexión
    const color = connectionStatus === "connected" ? "green" : "red";

    return (
        <Flex gap10 alignCenter>
            <TooltipRadix
                trigger={
                    <TextField fontSize={18} bold style={{ color }}>
                        <i className="fa-regular fa-circle-dot"></i>
                    </TextField>
                }
                delay={0}
                content={
                    <Flex column>
                        <TextField bold>Conexión con KDS </TextField>
                        <Flex gap10 alignCenter>
                            <TextField>Estado: </TextField>
                            <TextField bold style={{ color }}>
                                {connectionStatus}
                            </TextField>
                        </Flex>
                        <TextField small light>{`Ping ${lastPing} ms`}</TextField>
                    </Flex>
                }
            />

            {connectionStatus === "disconnected" && (
                <DefaultButton small rounded onClick={handleReconnect}>
                    Reconectar
                </DefaultButton>
            )}
        </Flex>
    );
};
