import React, { useContext, useEffect, useState } from "react";
import { ContactContext } from "../../../store/contexts/ContactContext";
import SearchTabTitle from "../../SearchTabTitle";
import messages from "./messages";
import { useIntl } from "react-intl";
import { TableCustom, TableCustomTr } from "../../_controls/tables/styled";
import ContactLabelFormModal from "./ContactLabelFormModal";
import ContactLabelRow from "./CustomerLabelRow";
import { LabelListContainer } from "./styled";
import { useMutation } from "react-query";
import { contactLabelsByAccount } from "../../../api/orders/contactApi";
import { useAlert } from "../../Alerts/Alert";
import { getServiceMessageError } from "../../../api";
import { getAuthBody } from "../../../auth";

interface ContactLabelFormModalState {
    showContactLabelModal: boolean;
    searchValue: string;
    selectedProductIds: string[];
}

export default function ContactLabelList() {
    const intl = useIntl();
    const alert = useAlert();
    const { contactState, contactActions } = useContext(ContactContext);
    const [state, setState] = useState<ContactLabelFormModalState>({
        showContactLabelModal: false,
        searchValue: "",
        selectedProductIds: [],
    });

    const labelMutation = useMutation((accountId: string) => contactLabelsByAccount(accountId), {
        onSuccess: (data) => {
            contactActions.receiveLabels(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        labelMutation.mutate(getAuthBody().accountId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LabelListContainer>
            <SearchTabTitle
                title=""
                setSearchValue={(value) => setState({ ...state, searchValue: value })}
                btnTitle={intl.formatMessage(messages.newLabel)}
                placeholder={intl.formatMessage(messages.searchLabels)}
                onClickBtn={() => setState({ ...state, showContactLabelModal: true })}
                loading={labelMutation.isLoading}
                hideBorder={true}
            ></SearchTabTitle>
            <div>
                {state.showContactLabelModal && (
                    <ContactLabelFormModal
                        onCreate={() => {}}
                        onCancel={() => setState({ ...state, showContactLabelModal: false })}
                        setShow={() => setState({ ...state, showContactLabelModal: false })}
                        show={state.showContactLabelModal}
                    />
                )}
            </div>
            <div>
                <TableCustom cellSpacing="0" cellPadding="0">
                    <thead>
                        <TableCustomTr>
                            <th>{intl.formatMessage(messages.nameTwo)}</th>
                            <th>{intl.formatMessage(messages.color)}</th>
                            <th>{intl.formatMessage(messages.created)}</th>
                        </TableCustomTr>
                    </thead>
                    <tbody>
                        {contactState.labels
                            .filter((x) => x.name.toUpperCase().includes(state.searchValue.toUpperCase()))
                            .map((item, index) => (
                                <ContactLabelRow key={index} label={item} />
                            ))}
                    </tbody>
                </TableCustom>
            </div>
        </LabelListContainer>
    );
}
