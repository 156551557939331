import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { GroupItem, ProductSearchGroupModel } from "../../../api/models/_statistics";
import { productSalesByRange, productSalesInGroup } from "../../../api/statistics/statisticsApi";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { formatMoney } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useDatePickerModal } from "../../DatePicker/DatePickerModal";
import LoadingDualRing from "../../LoadingDualRing";
import { OrderGroupSummaryView } from "../../Statistics/OrderStatisticsSummary/OrderStatisticsSummary";
import { Flex } from "../../_controls";
import { ProductStatisticsCard } from "./styled";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationStatistics(props: ProductNavigationProfileProps) {
    return (
        <NavigationCard {...props} title={props.intl.formatMessage(messages.statistics)}>
            <Content {...props} />
        </NavigationCard>
    );
}

const Content = (props: ProductNavigationProfileProps) => {
    const [items, setItems] = useState<GroupItem[]>([]);

    const [rangeResult, setRangeResult] = useState<GroupItem>();
    const datePicker = useDatePickerModal();

    const { appSettingsState } = useAppSettingsContext();
    const groupType = appSettingsState.orderListStatisticsGroupType ?? "day";

    const rangeMutation = useMutation((data: ProductSearchGroupModel) => productSalesByRange(data), {
        onSuccess: (data) => {
            setRangeResult(data);
        },
    });

    const groupMutation = useMutation((data: ProductSearchGroupModel) => productSalesInGroup(data), {
        onSuccess: (data) => {
            setItems(data);
        },
    });

    useEffect(() => {
        handleGetProductSales();
        return () => {
            groupMutation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product, groupType]);

    useEffect(() => {
        handleGetByRange();
        return () => {
            rangeMutation.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product, datePicker.rangeSelection]);

    const handleGetProductSales = () => {
        let data: ProductSearchGroupModel = {
            groupType: groupType,
            limit: 12,
            productId: props.product.id,
            productVariantId: props.productVariant?.id,
            organizationId: "",
            dateStart: new Date(),
            dateEnd: new Date(),
            accountId: "",
            minutesOffset: new Date().getTimezoneOffset(),
        };

        groupMutation.mutate(data);
    };

    const handleGetByRange = () => {
        if (!datePicker.rangeSelection.isActive) return;
        let data: ProductSearchGroupModel = {
            groupType: groupType,
            limit: 12,
            productId: props.product.id,
            productVariantId: props.productVariant?.id,
            organizationId: "",
            dateStart: datePicker.rangeSelection.startDate.toDateString(),
            dateEnd: datePicker.rangeSelection.endDate.toDateString(),
            accountId: "",
            minutesOffset: new Date().getTimezoneOffset(),
        };
        rangeMutation.mutate(data);
    };
    return (
        <Flex gap15 column>
            <datePicker.Modal title="Filtro personalizado" />
            {rangeMutation.isLoading && <LoadingDualRing />}
            {rangeResult && datePicker.rangeSelection.isActive && (
                <ProductStatisticsCard column gap10 className="mt-1 mb-1">
                    <p className="m-0 text-text-light">
                        Resumen desde{" "}
                        {`${customMoment(datePicker.rangeSelection.startDate).format("DD MMMM")} hasta ${customMoment(
                            datePicker.rangeSelection.endDate
                        ).format("DD MMMM")}`}
                    </p>
                    <Flex alignCenter gap15>
                        <Flex gap10 column className="card">
                            <span className="text-light text-small">Valor Vendido</span>
                            <span className="value-large">{formatMoney(rangeResult.value)}</span>
                        </Flex>
                        <Flex gap10 column className="card">
                            <span className="text-light text-small">Cantidad de ventas</span>
                            <span className="value-large">{rangeResult.count}</span>
                        </Flex>
                    </Flex>
                </ProductStatisticsCard>
            )}
            {items.length > 0 && <OrderGroupSummaryView statisticsItems={items} />}
            {groupMutation.isLoading && <LoadingDualRing center />}
        </Flex>
    );
};
