import { defineMessages } from "react-intl";

const base = "components.organizations";

export default defineMessages({
    organizationName: {
        id: `${base}.organizationName`,
        defaultMessage: "Nombre",
    },
    organizationAddress: {
        id: `${base}.organizationAddress`,
        defaultMessage: "Dirección",
    },
    organizationWarehouse: {
        id: `${base}.organizationWarehouse`,
        defaultMessage: "Bodega",
    },
    organizationTemplate: {
        id: `${base}.organizationTemplate`,
        defaultMessage: "Template predeterminado",
    },
    errorUpdatingStore: {
        id: `${base}.errorUpdatingStore`,
        defaultMessage: "Error al actualizar la tienda",
    },
    cancel: {
        id: `${base}.cancel`,
        defaultMessage: "Cancelar",
    },
    saveChanges: {
        id: `${base}.saveChanges`,
        defaultMessage: "Guardar Cambios",
    },
    ecommerceConfiguration: {
        id: `${base}.ecommerceConfiguration`,
        defaultMessage: "Configuración Ecommerce",
    },
    showStoreName: {
        id: `${base}.showStoreName`,
        defaultMessage: "Mostrar Nombre de la tienda",
    },
    showCategoriesMenu: {
        id: `${base}.showCategoriesMenu`,
        defaultMessage: "Mostrar categorías en el Menu",
    },
    showStockProducts: {
        id: `${base}.showStockProducts`,
        defaultMessage: "Mostrar productos agotados",
    },
    showStockoOfProducts: {
        id: `${base}.showStockOfProducts`,
        defaultMessage: "Mostrar Stock de productos",
    },
    allowBuyStock: {
        id: `${base}.allowBuyStock`,
        defaultMessage: "Permitir comprar productos agotados",
    },
    categoriesDisplayHome: {
        id: `${base}.categoriesDisplayHome`,
        defaultMessage: "Categorías para mostrar en el Home",
    },
    addCategories: {
        id: `${base}.addCategories`,
        defaultMessage: "Agregar categorías",
    },
    homeBanners: {
        id: `${base}.homeBanners`,
        defaultMessage: "Banners para el Home",
    },
    newShop: {
        id: `${base}.newShop`,
        defaultMessage: "Nueva Tienda",
    },
    notSelectedWarehouse: {
        id: `${base}.notSelectedWarehouse`,
        defaultMessage: "No ha seleccionado nínguna bodega",
    },
    failedReadWarehouse: {
        id: `${base}.failedReadWarehouse`,
        defaultMessage: "Error a leer la bodega",
    },
    notSelectedTemplate: {
        id: `${base}.notSelectedTemplate`,
        defaultMessage: "No ha seleccionado níngun template",
    },
    errorReadingTemplate: {
        id: `${base}.errorReadingTemplate`,
        defaultMessage: "Error a leer la lista de templates",
    },
    storeSuccessfullyUpdated: {
        id: `${base}.storeSuccessfullyUpdated`,
        defaultMessage: "La tienda fue actualizada con éxito",
    },
    storeCreatedSuccessfully: {
        id: `${base}.storeCreatedSuccessfully`,
        defaultMessage: "La tienda fue creada con éxito",
    },
    errorSavingInformation: {
        id: `${base}.errorSavingInformation`,
        defaultMessage: "Hubo un error al guardar la información",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre",
    },
    storeIsName: {
        id: `${base}.storeIsName`,
        defaultMessage: "Nombre de la tienda",
    },
    address: {
        id: `${base}.address`,
        defaultMessage: "Dirección",
    },
    createStore: {
        id: `${base}.createStore`,
        defaultMessage: "Crear Tienda",
    },
    seeStores: {
        id: `${base}.seeStores`,
        defaultMessage: "Ver Tiendas",
    },
    notPermissionEditStore: {
        id: `${base}.notPermissionEditStore`,
        defaultMessage: "No tienes permiso para editar la tienda",
    },
    storeProfile: {
        id: `${base}.storeProfile`,
        defaultMessage: "Perfil de la tienda",
    },
    withoutDirection: {
        id: `${base}.withoutDirection`,
        defaultMessage: "Sin dirección",
    },
    defaultWarehouse: {
        id: `${base}.defaultWarehouse`,
        defaultMessage: "Bodega predeterminada",
    },
    defaultCash: {
        id: `${base}.defaultCash`,
        defaultMessage: "Caja predeterminada",
    },
    toSelect: {
        id: `${base}.toSelect`,
        defaultMessage: "Seleccionar",
    },
    defaultInvoiceNumbering: {
        id: `${base}.defaultInvoiceNumbering`,
        defaultMessage: "Numeración predeterminada",
    },
    invoiceDesign: {
        id: `${base}.invoiceDesign`,
        defaultMessage: "Diseño de Factura",
    },
    RotuloDesign: {
        id: `${base}.RotuloDesign`,
        defaultMessage: "Diseño de Rotulo",
    },
    editTemplate: {
        id: `${base}.editTemplate`,
        defaultMessage: "Editar Template",
    },
    activateCashInvoice: {
        id: `${base}.activateCashInvoice`,
        defaultMessage: "Se requiere activar caja para facturar",
    },
    storeInformation: {
        id: `${base}.storeInformation`,
        defaultMessage: "Información de la Tienda",
    },
    storeEmail: {
        id: `${base}.storeEmail`,
        defaultMessage: "Email de la tienda",
    },
    storeAddress: {
        id: `${base}.storeAddress`,
        defaultMessage: "Dirección de la tienda",
    },
    telephone: {
        id: `${base}.telephone`,
        defaultMessage: "Teléfono",
    },
    storePhone: {
        id: `${base}.storePhone`,
        defaultMessage: "Teléfono de la tienda",
    },
    storeFooter: {
        id: `${base}.storeFooter`,
        defaultMessage: "Pie de página de la tienda",
    },
    socialNetworks: {
        id: `${base}.socialNetworks`,
        defaultMessage: "Redes Sociales",
    },
    hello: {
        id: `${base}.hello`,
        defaultMessage: "Hola",
    },
    selectStoreContinue: {
        id: `${base}.selectStoreContinue`,
        defaultMessage: "Selecciona una tienda para continuar",
    },
    addedUser: {
        id: `${base}.addedUser`,
        defaultMessage: "Usuario agregado",
    },
    userDeleted: {
        id: `${base}.userDeleted`,
        defaultMessage: "Usuario eliminado",
    },
    youAreSure: {
        id: `${base}.youAreSure`,
        defaultMessage: "¿Estas seguro?",
    },
    actionCannotReversed: {
        id: `${base}.actionCannotReversed`,
        defaultMessage: "Esta acción no se puede revertir",
    },
    listUsersStore: {
        id: `${base}.listUsersStore`,
        defaultMessage: "Lista de usuarios que tienen permiso para acceder a la tienda",
    },
    users: {
        id: `${base}.users`,
        defaultMessage: "Usuarios",
    },
    storeUsers: {
        id: `${base}.storeUsers`,
        defaultMessage: "Usuarios de la tienda",
    },
    addUser: {
        id: `${base}.addUser`,
        defaultMessage: "Agregar Usuario",
    },
    warehouses: {
        id: `${base}.warehouses`,
        defaultMessage: "Bodegas",
    },
    warehouseAdded: {
        id: `${base}.warehouseAdded`,
        defaultMessage: "Bodega agregada",
    },
    warehouseRemoved: {
        id: `${base}.warehouseRemoved`,
        defaultMessage: "Bodega eliminada",
    },
    warehousesPermissionStore: {
        id: `${base}.warehousesPermissionStore`,
        defaultMessage: "Lista de bodegas que tienen permiso para acceder a la tienda",
    },
    storeWarehouses: {
        id: `${base}.storeWarehouses`,
        defaultMessage: "Bodegas de la tienda",
    },
    addWarehouse: {
        id: `${base}.addWarehouse`,
        defaultMessage: "Agregar Bodega",
    },
    includeSocialNetworksInTracking: {
        id: `${base}.includeSocialNetworksInTracking`,
        defaultMessage: "Incluir redes sociales en Tracking Number",
    },
    searchCashRegister: {
        id: `${base}.searchCashRegister`,
        defaultMessage: "Buscar Caja",
    },
    currentBalance: {
        id: `${base}.currentBalance`,
        defaultMessage: "Balance Actual",
    },
    cashRegisters: {
        id: `${base}.cashregisters`,
        defaultMessage: "Cajas registradoras",
    },
    cashRegistersAssignedToTheOrganization: {
        id: `${base}.cashRegistersAssignedToTheOrganization`,
        defaultMessage: "Cajas registradoras asignadas a la tienda",
    },
    addCashRegister:{
        id: `${base}.addCashRegister`,
        defaultMessage: "Agregar Caja",
    },
    cashRegisterAdded: {
        id: `${base}.cashRegisterAdded`,
        defaultMessage: "Caja registradora agregada",
    },
    cashRegisterDeleted:{
        id: `${base}.cashRegisterDeleted`,
        defaultMessage: "Caja registradora eliminada",
    }
});
