// Jquery Dependency

function formatNumber(n: string) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatCurrency(input_val: string, blur?: string, decimalsLimit?: number | null): string {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // don't validate empty input
    if (input_val === "") {
        return "";
    }

    // check for decimal
    if (input_val.indexOf(",") >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);
        if (left_side.length > 1 && left_side.startsWith("0")) {
            left_side = left_side.substring(1);
        }

        // validate right side
        right_side = right_side.replace(/\D/g, "");

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "00";
        }

        // Limit decimal to only 2 digits
        const decimals = decimalsLimit || 2;
        right_side = right_side.substring(0, decimals);

        // join number by .
        input_val = left_side + "," + right_side;
    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);

        if (input_val.length > 1 && input_val.startsWith("0")) {
            input_val = input_val.substring(1);
        }

        // final formatting
        if (blur === "blur") {
            input_val += ",00";
        }
    }
    if (!input_val) return "0";
    return input_val;
}
