import styled from "styled-components";

export const OrderLimitAlertContainer = styled.div`
    display: flex;
    flex-direction: column;



    .alert-header {
        background: #f44336;
        padding: 15px;
        /* border-bottom: 5px solid red; */
        border-radius: 18px 18px 0px 0px;
        color: white;
    }

    .alert-content {
        padding: 30px;
        gap: 30px;
    }
`;
