import React from "react";
import { InvoicePrintPreviewContainer } from "./styled";
import Modal from "../modals/Modal";

interface InvoicePrintPreviewProps {
    onClick: (e: any) => void;
}

export default function InvoicePrintPreview(props: InvoicePrintPreviewProps) {
    return (
        <Modal show={true} setShow={() => {}}>
            <InvoicePrintPreviewContainer onClick={props.onClick}>
                <div className="body">
                    <span className="wahioicon-print"></span>
                    <span>Cargando impresora</span>
                </div>
            </InvoicePrintPreviewContainer>
        </Modal>
    );
}
