import { MessageSearchInput, Message, MessageStatusItem, MessageGroupSearchInput, MessageGroup } from "../../components/Chats/models";
import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { messageEndpoint } from "../restApiEndpoint";

export interface MessageGroupStatusInput {
    group: string;
    userId: string;
}
export interface MessageAsReadData {
    userGroup: string;
    toUserId: string;
}

export const messagesQuerySearch = (query: MessageSearchInput): Promise<IPaginationResponse<Message>> => {
    return wahioFetchApi.post(messageEndpoint.post.messageQuerySearch, query).then((res: any) => res.data);
};

export const messagesGroupQuerySearch = (query: MessageGroupSearchInput): Promise<IPaginationResponse<MessageGroup>> => {
    return wahioFetchApi.post(messageEndpoint.post.messageGroupQuerySearch, query).then((res: any) => res.data);
};

export const chatCreateMessage = (data: Message): Promise<Message> => {
    return wahioFetchApi.post(messageEndpoint.post.createMessage, data).then((res: any) => res.data);
};
export const chatCreateGroupMessage = (data: MessageGroup): Promise<MessageGroup> => {
    return wahioFetchApi.post(messageEndpoint.post.createGroupMessage, data).then((res: any) => res.data);
};

export const getMessagesStatusByUser = (id: string): Promise<MessageStatusItem[]> => {
    return wahioFetchApi.get(messageEndpoint.get.messagesStatusByUser(id)).then((res: any) => res.data);
};

export const getMessagesGroupStatusByUser = (data: MessageGroupStatusInput): Promise<{ count: number }> => {
    return wahioFetchApi.get(messageEndpoint.get.messagesGroupStatusByUser(data.group, data.userId)).then((res: any) => res.data);
};

export const markMessagesAsRead = (data: MessageAsReadData): Promise<{ counter: number }> => {
    return wahioFetchApi.put(messageEndpoint.put.markMessagesAsRead(data.userGroup, data.toUserId), {}).then((res: any) => res.data);
};
