import React from "react";
import { AccountPaymentMethod } from "../../../../api/models/accountUser";
import { useUserContext } from "../../../../store/contexts/UserContext";
import LoadingDualRing from "../../../LoadingDualRing";
import { DefaultButton, Flex, FlexImageStyle } from "../../../_controls";
import { useModal } from "../../../modals/Modal";
import { getCreditCardImageByBrand } from "../helpers";
import { PaymentMethodCard, PaymentMethodContainer } from "../styled";

interface IPaymentMethodProps {
    setShowModalCreatePaymentMethod?: (value: boolean) => void;
    methodSelected: AccountPaymentMethod;
    onChangeMethodSelected: (value: AccountPaymentMethod) => void;
}

export const BillingPaymentMethodSelector = (props: IPaymentMethodProps) => {
    const SelectPaymentMethodModal = useModal();

    const { methodList, methodIsFetching } = useUserContext();

    const onPaymentMethodSelectedChange = (method: AccountPaymentMethod) => {
        props.onChangeMethodSelected(method);
    };

    const brand = props.methodSelected.brand ?? props.methodSelected.publicData?.brand;
    const last4 = props.methodSelected.last4 ?? props.methodSelected.publicData?.last_four;
    const expMonth = props.methodSelected.expMonth ?? props.methodSelected.publicData?.exp_month;
    const expYear = props.methodSelected.expYear ?? props.methodSelected.publicData?.exp_year;

    return (
        <>
            <SelectPaymentMethodModal.Modal showHeader title="Métodos de pago disponibles">
                <PaymentMethodContainer>
                    {methodList.map((method: AccountPaymentMethod) => {
                        const brand = method.brand ?? method.publicData?.brand;
                        const last4 = method.last4 ?? method.publicData?.last_four;
                        const expMonth = method.expMonth ?? method.publicData?.exp_month;
                        const expYear = method.expYear ?? method.publicData?.exp_year;

                        return (
                            <PaymentMethodCard
                                key={method.id}
                                className={method.id === props.methodSelected.id ? "selected" : ""}
                                onClick={() => onPaymentMethodSelectedChange(method)}
                            >
                                <FlexImageStyle maxHeight={25} maxWidth={35} bgLight aspectRatioAuto borderRadius={8}>
                                    <img src={getCreditCardImageByBrand(brand)} alt="" />
                                </FlexImageStyle>
                                <Flex column>
                                    <span>
                                        {brand} ****{last4}
                                    </span>
                                    <span className="text-light text-small">
                                        exp {expMonth}/{expYear}
                                    </span>
                                </Flex>
                            </PaymentMethodCard>
                        );
                    })}
                    <Flex padding={15} alignCenter justifyCenter border borderRadius={15} borderDashed gap10>
                        <DefaultButton
                            onClick={() => {
                                props.setShowModalCreatePaymentMethod && props.setShowModalCreatePaymentMethod(true);
                                SelectPaymentMethodModal.show(false);
                            }}
                            rounded
                            hideBorder
                            colorLight
                            small
                        >
                            <span className="wahioicon-plus"></span>
                            Agregar método
                        </DefaultButton>
                    </Flex>
                </PaymentMethodContainer>
            </SelectPaymentMethodModal.Modal>
            {methodList && methodList.length > 0 && props.methodSelected && (
                <Flex column gap10>
                    <Flex gap10 alignCenter>
                        <span>Métodos de pago</span>
                        {methodIsFetching && <LoadingDualRing small />}
                    </Flex>
                    <Flex w100 spaceBetween>
                        <Flex gap15 alignCenter>
                            <img
                                height={20}
                                src={getCreditCardImageByBrand(props.methodSelected.brand ?? props.methodSelected.publicData?.brand)}
                                alt=""
                            />
                            <Flex column>
                                <span className="text-small">
                                    {brand} ****
                                    {last4}
                                </span>
                                <span className="text-light text-small">
                                    exp {expMonth}/{expYear}
                                </span>
                            </Flex>
                        </Flex>
                        <Flex gap10 alignCenter>
                            <DefaultButton hideBorder onClick={() => SelectPaymentMethodModal.show(true)}>
                                Cambiar
                                <span className="wahioicon-angle-right"></span>
                            </DefaultButton>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    );
};
