import React, { useContext } from "react";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { Container } from "./styled";

export interface ViewContainerProps {
    border?: boolean;
    size?: "sm" | "sm2" | "md" | "lg";
    children: React.ReactNode;
    padding?: number;
    className?: string;
}

export default function ViewContainer(props: ViewContainerProps) {
    const { size = "sm", padding = 10 } = props;
    const { appSettingsState } = useContext(AppSettingsContext);
    return (
        <Container
            padding={padding}
            menuSmall={appSettingsState.menuSmall}
            className={`size-${size} ${props.className}`}
            withBorder={props.border}
        >
            {props.children}
        </Container>
    );
}
