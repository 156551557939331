import React, { useState } from "react";
import { OrderNavigationProps } from ".";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { WahioFile } from "../../../api/models";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import { CloudImages } from "../../../assets";
import { useAlert } from "../../Alerts/Alert";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { DefaultButton, Flex, PrimaryButton } from "../../_controls";
import { EmptyOrderFilesContainer, OrderFilesContainerStyle } from "./styled";

export default function NavigationOrderFiles(props: OrderNavigationProps) {
    const { order } = props;
    const alert = useAlert();
    const [files, setFiles] = useState(order.files ?? []);
    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const handleSaveChanges = () => {
        setIsFetching(true);
        wahioFetch.post(
            localOrderEndpoint.post.orderUpdateCreateAll,
            {
                ...order,
                files,
            },
            (success) => {
                setIsFetching(false);
                alert.success("Los cambios se guardaron correctamente");
                setShowSaveChanges(false);
                props.onOrderChange(success.data);
            },
            (error) => {
                setIsFetching(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const onFilesChange = (files: WahioFile[]) => {
        setFiles(files);
        setShowSaveChanges(true);
    };

    const handleCancelChanges = () => {
        setFiles(order.files ?? []);
        setShowSaveChanges(false);
    };

    return (
        <NavigationCard>
            <OrderFilesContainerStyle>
                {files.length === 0 && (
                    <EmptyOrderFilesContainer>
                        <div className="image">
                            <img src={CloudImages.openFolder} alt="" />
                        </div>
                    </EmptyOrderFilesContainer>
                )}
                <FilesViewer cardMaxWidth={140} files={files} onUpdateFiles={onFilesChange} showAccessType showAttachButton />
                {showSaveChanges && (
                    <Flex gap10 alignCenter marginTop={20}>
                        <DefaultButton disabled={isFetching} onClick={handleCancelChanges} rounded>
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton disabled={isFetching} rounded onClick={() => handleSaveChanges()}>
                            Guardar Cambios {isFetching && <LoadingDualRing small />}
                        </PrimaryButton>
                    </Flex>
                )}
            </OrderFilesContainerStyle>
        </NavigationCard>
    );
}
