/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { WahioFile } from "../../api/models";
import Dropdown from "../Dropdown";
import { DefaultButton, Flex } from "../_controls";
import InputModal from "../modals/InputModal";
import PDFViewModal from "../modals/PDFViewModal";
import { getCloudItemExtensionImage } from "./extensionImage";
import { getFileExtensionFromString } from "./helper";
import { CloudItemCardContainer } from "./styled";

interface WahioFileCardProps {
    item: WahioFile;
    smallItem?: boolean;
    onRemove: (item: WahioFile) => void;
    onUpdate: (item: WahioFile) => void;
    onClick?: () => void;
    showAccessType?: boolean;
}

export default function WahioFileCard(props: WahioFileCardProps) {
    const { item } = props;
    const [showUpdateName, setShowUpdateName] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const extension = getFileExtensionFromString(item.url);
    const useUrl = item.type === "image" && extension !== "svg";
    const image = useUrl ? item.url : getCloudItemExtensionImage(extension);

    const onClickCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (item.type === "image" && props.onClick) props.onClick();
    };

    const isPublic = item.accessType === "public";

    return (
        <CloudItemCardContainer className={props.smallItem ? "small-card" : ""}>
            {showPreview && <PDFViewModal show={showPreview} setShow={setShowPreview} url={item.url} />}
            {showUpdateName && (
                <InputModal
                    show={showUpdateName}
                    setShow={setShowUpdateName}
                    placeholder="Cambiar nombre"
                    value={item.name}
                    title={"Cambiar Nombre al archivo"}
                    onOkay={(value) => props.onUpdate({ ...props.item, name: value })}
                />
            )}
            <div className="image-container hover-icon">
                <div
                    className={`image-cloud ${!useUrl ? "file-extention" : ""} `}
                    style={{ cursor: props.onClick ? "pointer" : "default" }}
                    onClick={onClickCard}
                >
                    <img src={image} alt={item.name} />
                    {props.showAccessType && item.accessType === "public" && (
                        <>
                            <div className="access-type-icon">
                                <i className={`fa-regular fa-lock-keyhole-open`} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="body-cloud">
                {!props.smallItem && (
                    <span className="name" title={item.name}>
                        {item.name}.{extension}
                    </span>
                )}

                <Dropdown small icon="fa-regular fa-ellipsis-vertical" contentHorizontal="left">
                    <Flex padding={10} column gap5>
                        {props.showAccessType && (
                            <DefaultButton
                                small
                                className="dropdown-button"
                                onClick={() => {
                                    props.onUpdate({ ...props.item, accessType: isPublic ? "private" : "public" });
                                }}
                            >
                                {props.item.accessType === "private" ? (
                                    <>
                                        <i className="fa-regular fa-lock"></i> Privado
                                    </>
                                ) : (
                                    <>
                                        <i className="fa-regular fa-lock-open"></i> Publico
                                    </>
                                )}
                            </DefaultButton>
                        )}
                        <DefaultButton small className="dropdown-button" onClick={() => setShowUpdateName(true)}>
                            <i className="fa-regular fa-pen-to-square"></i>Renombrar
                        </DefaultButton>
                        <DefaultButton small className="dropdown-button" onClick={() => props.onRemove(item)}>
                            <i className="fa-regular fa-trash"></i> Eliminar
                        </DefaultButton>
                        <a href={item.url} target="_blank">
                            <DefaultButton small className="dropdown-button">
                                <i className="fa-regular fa-cloud-arrow-down"></i>Descargar
                            </DefaultButton>
                        </a>
                        {extension === "pdf" && (
                            <DefaultButton small className="dropdown-button" onClick={() => setShowPreview(true)}>
                                <i className="fa-regular fa-eye"></i> Ver
                            </DefaultButton>
                        )}
                    </Flex>
                </Dropdown>
            </div>
        </CloudItemCardContainer>
    );
}
