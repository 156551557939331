import algoliasearch from "algoliasearch";
import React, { useEffect, useState } from "react";
import { ALGOLIA_ADMIN_API_KEY, ALGOLIA_APPLICATION_ID } from "../../../api/appConfig";
import { DefaultInput, TextField } from "../../_controls";
import { HelpCenterItem } from "./helpCenterDb";
import { CardSuggestionsContainer, SearchContainer } from "./styled";

const client = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_ADMIN_API_KEY);
const searchIndex = client.initIndex("helpcenteritems");

export default function HelpCenterSearch() {
    const [results, setResults] = useState<HelpCenterItem[]>([]);

    const timeRef = React.useRef<any>();

    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        clearTimeout(timeRef.current);
        if (searchValue === "") return setResults([]);
        timeRef.current = setTimeout(() => {
            searchIndex
                .search<HelpCenterItem>(searchValue)
                .then(({ hits }) => {
                    setResults(hits);
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <SearchContainer>
            <TextField>Buscar artículos de ayuda</TextField>
            <DefaultInput
                className="search-input"
                placeholder={"Crear producto, venta, pedido, etc."}
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
            />
            {results.length > 0 && (
                <CardSuggestionsContainer>
                    {results.map((item, index) => {
                        return (
                            <a href={item.link} target="_blank" rel="noreferrer" key={index} className={`suggestion-item`}>
                                {item.title}
                            </a>
                        );
                    })}
                </CardSuggestionsContainer>
            )}
        </SearchContainer>
    );
}
