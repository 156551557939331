import { defineMessages } from "react-intl";

const base = "components.products";

export default defineMessages({
    unknown: {
        id: `${base}.unknown`,
        defaultMessage: "Desconocido",
    },
    order: {
        id: `${base}.order`,
        defaultMessage: "Orden",
    },
    purchase: {
        id: `${base}.purchase`,
        defaultMessage: "Compra",
    },
    movement: {
        id: `${base}.movements`,
        defaultMessage: "Movimientos",
    },
    adjustment: {
        id: `${base}.adjustment`,
        defaultMessage: "Ajuste",
    },
    warehouseLocation: {
        id: `${base}.warehouseLocation`,
        defaultMessage: "Ubicaciones",
    },
    percentage: {
        id: `${base}.percentage`,
        defaultMessage: "Porcentaje",
    },
    amount: {
        id: `${base}.amount`,
        defaultMessage: "Valor",
    },
});
