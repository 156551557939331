import React, { PureComponent, useContext } from "react";
import { IOrganizationTemplate } from "../../../api/models/organizationTypes";

import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import InvoiceFormatNormal from "./InvoiceFormatNormal";
import InvoiceFormatSmall from "./InvoiceFormatSmall";
import { IPurchase } from "../../../api/models/purchase";

export interface IInvoiceFormatPurchaseProps {
    template: IOrganizationTemplate;
    image?: any;
    order: IPurchase;
    testMode?: boolean;
}

const InvoiceFormModel = (props: IInvoiceFormatPurchaseProps) => {
    const { appSettingsState } = useContext(AppSettingsContext);

    if (appSettingsState.printType === "80mm") {
        return <InvoiceFormatSmall {...props} />;
    }

    return <InvoiceFormatNormal {...props} />;
};

export default class InvoiceFormat extends PureComponent<IInvoiceFormatPurchaseProps> {
    render() {
        return <InvoiceFormModel {...this.props} />;
    }
}
