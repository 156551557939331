import { Dispatch } from "react";
import {
    AddLounge,
    ADD_LOUNGE,
    LoungeTypes,
    ILounge,
    ILoungeState,
    InvalidRequest,
    INVALID_REQUEST_LOUNGES,
    ReceiveLounges,
    RECEIVE_LOUNGES,
    RequestLounges,
    REQUEST_LOUNGES,
    UpdateLounge,
    UPDATE_LOUNGE,
} from "../../api/models/lounge";

export interface ILoungeActions {
    request: () => void;
    receive: (items: ILounge[]) => void;
    fetchInvalid: (error: any) => void;
    add: (lounge: ILounge) => void;
    update: (lounge: ILounge) => void;
}

export const actionRequest = (): RequestLounges => {
    return {
        type: REQUEST_LOUNGES,
    };
};

export const actionReceive = (items: ILounge[]): ReceiveLounges => {
    return {
        type: RECEIVE_LOUNGES,
        items,
    };
};

export const actionFetchInvalid = (er: any): InvalidRequest => {
    return {
        type: INVALID_REQUEST_LOUNGES,
        value: er,
    };
};

export const ActionAdd = (lounge: ILounge): AddLounge => {
    return {
        type: ADD_LOUNGE,
        value: lounge,
    };
};

export const ActionUpdate = (lounge: ILounge): UpdateLounge => {
    return {
        type: UPDATE_LOUNGE,
        value: lounge,
    };
};

export const useActions = (state: ILoungeState, dispatch: Dispatch<LoungeTypes>): ILoungeActions => ({
    request: () => {
        dispatch(actionRequest());
    },
    receive: (items: ILounge[]) => {
        dispatch(actionReceive(items));
    },
    fetchInvalid: (error: any) => {
        dispatch(actionFetchInvalid(error));
    },
    add: (value: ILounge) => {
        dispatch(ActionAdd(value));
    },
    update: (value: ILounge) => {
        dispatch(ActionUpdate(value));
    },
});
