import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getServiceMessageError } from "../../api";
import {
    deleteWahioCloudFiles,
    deleteWahioCloudFolders,
    getWahioCloudFoldersQuery,
    getWahioCloudItemsQuery,
} from "../../api/account/wahioCloudApi";
import { IPaginationResponse, PaginationResponse } from "../../api/models";
import { AccountUser, getUserPermissions } from "../../api/models/accountUser";
import {
    ErrorObjectFolderDeleteState,
    ISearchCloudSort,
    WahioCloudFolder,
    WahioCloudFolderImpl,
    WahioCloudItem,
    WahioCloudItemType,
    WahioCloudSearchFolderModel,
    WahioCloudSearchModel,
    wahioCloudItemTypeList,
} from "../../api/models/wahioCloud";
import { CloudImages } from "../../assets";
import { getAuthBody } from "../../auth";
import { checkAndGetMessage } from "../../i18n/helper";
import { AccountUserContext } from "../../store/contexts/AccountUserContext";
import { useCloudContext } from "../../store/contexts/CloudContext";
import { UserContext } from "../../store/contexts/UserContext";
import { useFirstRender } from "../../utils";
import { useAlert } from "../Alerts/Alert";
import Dropdown from "../Dropdown";
import DropdownList, { getListItem } from "../Dropdown/DropdownList";
import EmptyResultViews from "../EmptyResultViews";
import LoadingDualRing from "../LoadingDualRing";
import PermissionDenied from "../PermissionDenied";
import SearchTabFilter from "../SearchTabTitle/SearchTabFilter";
import { DefaultButton, PrimaryButton } from "../_controls";
import MobileButton from "../_controls/buttons/MobileButton";
import { Flex, TextField, ToolbarItem } from "../_controls/containers";
import { useIsMobileListener } from "../index";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useModal } from "../modals/Modal";
import CloudSortDropdown from "./CloudItemsSort";
import { CloudItemCreateFilesModal } from "./CreateCloudItems/CloudItemCreateFiles";
import { ErrorDeletingFolderModal } from "./ErrorDeletingFolderModal";
import { WahioCloudCreateFolderModal } from "./WahioCloudCreateFolderModal";
import { WahioCloudFolderContent } from "./WahioCloudFolderContent";
import WahioCloudItemCard from "./WahioCloudItemCard";
import messages from "./messages";
import {
    AllImageContainer,
    CloudImagenModalFooter,
    CloudItemCardContainerFake,
    CloudItemListContainer,
    InputFileContainer,
} from "./styled";

const INPUT_OPEN_ID = "input-open-file";

export interface WahioCloudItemListProps {
    allowSelection?: boolean;
    allowSelectionMultiple?: boolean;
    defaultType?: WahioCloudItemType;
    usingModal?: boolean;
    onClickItems?: (items: WahioCloudItem[]) => void;
    onCloseModal?: () => void;
}

export interface StateCloudProps {
    selectedType?: WahioCloudItemType;
    searchValue: string;
    selectedSort: ISearchCloudSort;
    userFilter?: AccountUser;
    folderOpen?: WahioCloudFolder;
    folderParentId: string;
}

export interface FolderStateProps {
    showModal: boolean;
    objectError?: ErrorObjectFolderDeleteState;
}

export default function WahioCloudItemList(props: WahioCloudItemListProps) {
    const alert = useAlert();
    const intl = useIntl();
    const [showConfirmDeleteFiles, setShowConfirmDeleteFiles] = useState(false);
    const [showConfirmDeleteFolders, setShowConfirmDeleteFolders] = useState(false);
    const [selectedCloudItems, setSelectedCloudItems] = useState<WahioCloudItem[]>([]);
    const [selectedCloudFolders, setSelectedCloudFolders] = useState<WahioCloudFolder[]>([]);
    const [, setParams] = useSearchParams();
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);

    const [dragEnterActive, setDragEnterActive] = useState(false);
    const [showFolderModal, setShowFolderModal] = useState(false);
    const { userState } = useContext(UserContext);
    const isMobile = useIsMobileListener();
    const [filesToCreated, setFilesToCreated] = useState<File[]>([]);
    const AddActionMobileModal = useModal();
    const [folderState, setFolderState] = useState<FolderStateProps>({
        showModal: false,
    });

    const cloudContext = useCloudContext();
    const [state, setState] = useState<StateCloudProps>({
        selectedType: undefined,
        searchValue: cloudContext.cloudState.searchValue ?? "",
        selectedSort: {
            sort: "date",
            sortDesc: true,
        },
        userFilter: undefined,
        folderOpen: undefined,
        folderParentId: "",
    });

    const [paginationWahioCloudItems, setPaginationWahioCloudItems] = useState<IPaginationResponse<WahioCloudItem>>(
        cloudContext.cloudState.pagination ?? new PaginationResponse<WahioCloudItem>()
    );
    const [paginationWahioCloudFolders, setPaginationWahioCloudFolders] = useState<IPaginationResponse<WahioCloudFolder>>(
        cloudContext.cloudState.paginationFolder ?? new PaginationResponse<WahioCloudFolderImpl>()
    );

    const getCloudParams = (folderId: string) => {
        let search = "?";
        if (folderId) {
            search += `folderId=${folderId}&`;
        }
        return search;
    };

    const setFolderOpenedWithParams = (folder?: WahioCloudFolder, folderParentId?: string) => {
        setParams(getCloudParams(folder?.id ?? ""));
        setState({ ...state, folderOpen: folder, folderParentId: folderParentId ?? folder?.id ?? "" });
    };

    const changeSelectedFolder = (item?: WahioCloudFolder) => {
        if (item) {
            const index = selectedCloudFolders.findIndex((x) => x.id === item.id);
            if (index >= 0) {
                setSelectedCloudFolders(selectedCloudFolders.filter((x, i) => i !== index));
            } else {
                setSelectedCloudFolders([...selectedCloudFolders, item]);
            }
        } else {
            setSelectedCloudFolders([]);
        }
    };

    const wahioCloudItemsMutation = useMutation((data: WahioCloudSearchModel) => getWahioCloudItemsQuery(data), {
        onSuccess: (pageResult) => {
            let items = pageResult.currentPage === 1 ? pageResult.items : [...paginationWahioCloudItems.items, ...pageResult.items];
            pageResult.items = items;
            setPaginationWahioCloudItems(pageResult);
            cloudContext.setCloudState({ pagination: pageResult, searchValue: state.searchValue });
            searchFolders();
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });
    const wahioCloudFoldersMutation = useMutation((data: WahioCloudSearchFolderModel) => getWahioCloudFoldersQuery(data), {
        onSuccess: (pageResult) => {
            setPaginationWahioCloudFolders(pageResult);
            cloudContext.setCloudState({ paginationFolder: pageResult, searchValue: state.searchValue })
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const wahioCloudDeleteMutation = useMutation((data: WahioCloudItem[]) => deleteWahioCloudFiles(data), {});

    const wahioCloudFolderDeleteMutation = useMutation((folders: WahioCloudFolder[]) => deleteWahioCloudFolders(folders), {
        onSuccess: (data) => {
            if (data.length > 1) {
                alert.success("Carpetas eliminadas con éxito");
            } else {
                alert.success("Carpeta eliminada con éxito");
            }
            setSelectedCloudFolders([]);
            setSelectedCloudItems([]);
            searchFolders();
            handleSearchCloudFiles();
        },
        onError: (error: any) => {
            if (error.response.data) {
                const data = error.response.data;
                if (data.foldersIds) {
                    setFolderState({ ...folderState, objectError: error.response.data, showModal: true });
                } else {
                    let obj = {
                        foldersIds: [],
                        message: getServiceMessageError(error),
                    };
                    setFolderState({ ...folderState, objectError: obj, showModal: true });
                }
            } else {
                alert.error(getServiceMessageError(error));
            }
        },
    });

    const searchFolders = () => {
        const searchBody: WahioCloudSearchFolderModel = {
            accountId: getAuthBody().accountId,
            currentPage: 1,
            pageSize: 25,
            searchValue: state?.searchValue ?? "",
            organizationId: "",
            accountUserId: state?.userFilter?.id ?? "",
            sort: "name",
            sortDesc: false,
        };
        wahioCloudFoldersMutation.mutate(searchBody);
    };

    useEffect(() => {
        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }
        searchFolders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (firstRender && cloudContext.cloudState.pagination && cloudContext.cloudState.pagination.items.length > 0) {
            setPaginationWahioCloudItems(cloudContext.cloudState.pagination);
            return;
        }
        handleSearchCloudFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const dragOver = (e: any) => {
        setDragEnterActive(true);
        e.preventDefault();
    };

    const dragEnter = (e: any) => {
        setDragEnterActive(true);
        e.preventDefault();
    };

    const dragLeave = (e: any) => {
        setDragEnterActive(false);
        e.preventDefault();
    };

    const fileDrop = (e: any) => {
        setDragEnterActive(false);
        e.preventDefault();
        const files = e.dataTransfer.files;
        processFiles(files);
    };

    const processFiles = (files: File[]) => {
        setFilesToCreated(files);
    };

    const fileSelectedHandler = (e: any) => {
        const input = e.target;
        if (input.files && input.files[0]) {
            processFiles(input.files);
        }
    };

    const handleSearchCloudFiles = (page: number = 1) => {
        const searchBody: WahioCloudSearchModel = {
            accountId: getAuthBody().accountId,
            currentPage: page,
            pageSize: 25,
            searchValue: state.searchValue,
            sort: state.selectedSort.sort,
            sortDesc: state.selectedSort.sortDesc,
            organizationId: "",
            type: props.defaultType ?? state.selectedType,
            accountUserId: state.userFilter?.id,
            folderId: state?.folderOpen?.id,
        };

        wahioCloudItemsMutation.mutate(searchBody);
    };

    const onClickInputFile = () => {
        let element = document.getElementById(INPUT_OPEN_ID);
        if (element) {
            element.click();
        }
    };

    const handleDeleteSelectedCloudFiles = () => {
        if (selectedCloudItems.length === 0) return;

        setShowConfirmDeleteFiles(false);
        wahioCloudDeleteMutation
            .mutateAsync(selectedCloudItems)
            .then((data) => {
                alert.success("Archivos eliminados con éxito");
                setSelectedCloudItems([]);
                handleSearchCloudFiles();
            })
            .catch((error) => {
                alert.error(getServiceMessageError(error));
            });
    };

    const handleDeleteSelectedCloudFolders = (folder?: WahioCloudFolder) => {
        let folderDeleteList = selectedCloudFolders;
        if (folder) {
            folderDeleteList = [folder];
        }
        setShowConfirmDeleteFolders(false);
        wahioCloudFolderDeleteMutation.mutate(folderDeleteList);
    };

    const onRemoveCloudItem = (item: WahioCloudItem) => {
        let items = paginationWahioCloudItems.items.filter((x) => x.id !== item.id);
        setPaginationWahioCloudItems({ ...paginationWahioCloudItems, items, totalItems: paginationWahioCloudItems.totalItems - 1 });
    };

    const onSelectedItem = (item: WahioCloudItem) => {
        if (selectedCloudItems.find((x) => x.id === item.id)) {
            setSelectedCloudItems(selectedCloudItems.filter((x) => x.id !== item.id));
        } else {
            if (props.allowSelection && !props.allowSelectionMultiple) {
                setSelectedCloudItems([item]);
            } else {
                setSelectedCloudItems([...selectedCloudItems, item]);
            }
        }
    };

    const selectedFilesIds = selectedCloudItems.map((x) => x.id);

    if (!getUserPermissions(userState.user).cloud?.read) {
        return <PermissionDenied />;
    }
    const permissionCloud = getUserPermissions(userState.user).cloud;

    const getFakeCards = () => {
        let arrays: React.ReactNode[] = [];
        for (let i = 0; i < 40; i++) {
            arrays.push(<CloudItemCardContainerFake key={i} />);
        }
        return arrays;
    };

    const isFetching = wahioCloudItemsMutation.isLoading;

    const itemsEmpty = wahioCloudItemsMutation.isSuccess && paginationWahioCloudItems.totalItems === 0;

    return (
        <Flex column w100>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cloud - Wahio</title>
            </Helmet>
            {filesToCreated.length > 0 && (
                <CloudItemCreateFilesModal
                    onItemCreated={(item) => {
                        setPaginationWahioCloudItems((prevItems) => {
                            const items = [item, ...prevItems.items];
                            return { ...prevItems, items, totalItems: prevItems.totalItems + 1 };
                        });
                    }}
                    files={filesToCreated}
                    onClose={() => setFilesToCreated([])}
                    folderId={state.folderParentId}
                />
            )}
            {showFolderModal && (
                <WahioCloudCreateFolderModal
                    showModal={showFolderModal}
                    setShowModal={setShowFolderModal}
                    folderParent={state.folderOpen}
                    onCreate={() => searchFolders()}
                />
            )}
            {folderState.showModal && (
                <ErrorDeletingFolderModal
                    show={folderState.showModal}
                    setShow={(value) => setFolderState({ ...folderState, showModal: value })}
                    foldersItemsQuery={paginationWahioCloudFolders.items}
                    folderState={folderState}
                    confirmDeleteFolder={() => handleDeleteSelectedCloudFolders()}
                />
            )}
            <AddActionMobileModal.Modal showHeader title="Crear">
                <Flex padding={20} gap10>
                    <DefaultButton
                        w100
                        borderRadius={10}
                        onClick={() => {
                            setShowFolderModal(true);
                            AddActionMobileModal.show(false);
                        }}
                    >
                        <span className="fa-regular fa-folder"></span>
                        Carpeta
                    </DefaultButton>
                    <PrimaryButton
                        w100
                        borderRadius={10}
                        onClick={() => {
                            onClickInputFile();
                            AddActionMobileModal.show(false);
                        }}
                    >
                        <span className="fa-regular fa-file"></span>
                        Archivo
                    </PrimaryButton>
                </Flex>
            </AddActionMobileModal.Modal>
            {showConfirmDeleteFiles && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmDeleteFiles(false)}
                    onOkay={() => handleDeleteSelectedCloudFiles()}
                    title="¿Estas seguro?"
                    description="Esta acción no se puede revertir"
                    show={showConfirmDeleteFiles}
                    setShow={setShowConfirmDeleteFiles}
                />
            )}
            {showConfirmDeleteFolders && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmDeleteFolders(false)}
                    onOkay={() => handleDeleteSelectedCloudFolders()}
                    title="¿Estas seguro?"
                    description="Esta acción no se puede revertir"
                    show={showConfirmDeleteFolders}
                    setShow={setShowConfirmDeleteFolders}
                />
            )}
            <InputFileContainer>
                <input id={INPUT_OPEN_ID} type="file" onChange={fileSelectedHandler} multiple />
            </InputFileContainer>

            <Flex gap10>
                <SearchTabFilter
                    btnTitle={permissionCloud?.create ? "Subir" : ""}
                    btnIcon="wahioicon-cloud-upload-alt"
                    searchValue={state.searchValue}
                    title="Cloud"
                    setSearchValue={(value) => setState({ ...state, searchValue: value })}
                    placeholder={"Buscar archivos"}
                    onPageChange={handleSearchCloudFiles}
                    loading={isFetching}
                >
                    <Dropdown icon="fa-regular fa-filter" ignoreInternalClicks>
                        <Flex column gap={20} padding={20}>
                            {!props.defaultType && (
                                <DropdownList
                                    w100
                                    title={state.selectedType ? checkAndGetMessage(intl, messages, state.selectedType) : "Filtrar"}
                                    items={wahioCloudItemTypeList.map((item) =>
                                        getListItem(item, intl.formatMessage((messages as any)[item]))
                                    )}
                                    showRemove={!!state.selectedType}
                                    onRemoveItem={() => setState({ ...state, selectedType: undefined })}
                                    onClickItem={(item) => setState({ ...state, selectedType: item.id as WahioCloudItemType })}
                                />
                            )}
                            <Flex column gap5>
                                <TextField light small>
                                    Ordenar
                                </TextField>
                                <CloudSortDropdown
                                    value={state.selectedSort}
                                    setValue={(value) => {
                                        setState({ ...state, selectedSort: value });
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </Dropdown>

                    {!props.allowSelection && selectedCloudItems.length > 0 && permissionCloud?.delete && (
                        <DefaultButton
                            disabled={wahioCloudDeleteMutation.isLoading}
                            rounded
                            onClick={() => setShowConfirmDeleteFiles(true)}
                        >
                            <span className="fa-regular fa-trash" />
                            {selectedCloudItems.length > 1 ? "Archivos" : "Archivo"} ({selectedCloudItems.length})
                            {wahioCloudDeleteMutation.isLoading && <LoadingDualRing small />}
                        </DefaultButton>
                    )}
                    {selectedCloudFolders.length > 0 && permissionCloud?.delete && (
                        <DefaultButton
                            disabled={wahioCloudFolderDeleteMutation.isLoading}
                            rounded
                            onClick={() => setShowConfirmDeleteFolders(true)}
                        >
                            <span className="fa-regular fa-trash" />
                            {selectedCloudFolders.length > 1 ? "Carpetas" : "Carpeta"} ({selectedCloudFolders.length})
                            {wahioCloudFolderDeleteMutation.isLoading && <LoadingDualRing small />}
                        </DefaultButton>
                    )}
                    {isMobile ? (
                        <MobileButton title={""} rounded onClick={() => AddActionMobileModal.show(true)} />
                    ) : (
                        <ToolbarItem>
                            <Dropdown icon="fa-regular fa-plus" title="Nuevo" iconPosition="left">
                                <Flex column gap10 padding={15}>
                                    <PrimaryButton className="dropdown-button" onClick={() => onClickInputFile()}>
                                        <span className="fa-regular fa-plus"></span>
                                        Archivo
                                    </PrimaryButton>
                                    <DefaultButton className="dropdown-button" onClick={() => setShowFolderModal(true)}>
                                        <span className="fa-regular fa-folder"></span>
                                        Carpeta
                                    </DefaultButton>
                                </Flex>
                            </Dropdown>
                        </ToolbarItem>
                    )}
                </SearchTabFilter>
            </Flex>
            <div
                style={{
                    marginLeft: itemsEmpty ? "15px" : "0px",
                    marginRight: itemsEmpty ? "15px" : "0px",
                }}
            >
                <AllImageContainer
                    className={`${itemsEmpty ? "show-drag" : ""} ${dragEnterActive ? "dragactive" : ""} ${props.usingModal ? "modal" : ""}`}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    <WahioCloudFolderContent
                        folders={paginationWahioCloudFolders.items ?? []}
                        folderOpen={state.folderOpen}
                        listOfSelectedFolders={selectedCloudFolders}
                        updateOpenedFolder={setFolderOpenedWithParams}
                        changeListSelectedFolder={changeSelectedFolder}
                        deleteFolder={handleDeleteSelectedCloudFolders}
                    />
                    {paginationWahioCloudItems.items.length > 0 ? (
                        <CloudItemListContainer className={props.usingModal ? "modal" : ""}>
                            {isFetching && paginationWahioCloudItems.items.length === 0 && getFakeCards()}

                            {paginationWahioCloudItems.items.map((item) => (
                                <WahioCloudItemCard
                                    key={item.id}
                                    item={item}
                                    onRemove={onRemoveCloudItem}
                                    onSelected={onSelectedItem}
                                    isSelected={selectedFilesIds.indexOf(item.id) > -1}
                                    folders={paginationWahioCloudFolders?.items ?? []}
                                />
                            ))}
                        </CloudItemListContainer>
                    ) : wahioCloudItemsMutation.isLoading ? (
                        <LoadingDualRing center />
                    ) : (
                        state.folderOpen &&
                        !state.folderOpen?.childs && (
                            <EmptyResultViews
                                image={CloudImages.openFolder}
                                title="Carpeta Vacía"
                                description="Esta carpeta no contiene archivos"
                                imageSize="sm"
                            />
                        )
                    )}
                    {itemsEmpty && (
                        <Flex alignCenter justifyCenter column gap15>
                            <TextField small>Arrastra y suelta tus archivos aquí</TextField>

                            <DefaultButton borderRadius={10} onClick={() => onClickInputFile()}>
                                <span className="fa-regular fa-cloud" />
                                <TextField>Agregar archivo</TextField>
                            </DefaultButton>
                        </Flex>
                    )}
                    {paginationWahioCloudItems.currentPage < paginationWahioCloudItems.totalPages && (
                        <Flex alignCenter justifyCenter paddingTop={20}>
                            <PrimaryButton
                                disabled={isFetching}
                                bgLight
                                borderRadius={10}
                                onClick={() => handleSearchCloudFiles(paginationWahioCloudItems.currentPage + 1)}
                            >
                                <span className="wahioicon-plus"></span> Cargar mas {isFetching && <LoadingDualRing small />}
                            </PrimaryButton>
                        </Flex>
                    )}
                </AllImageContainer>
            </div>
            {props.allowSelection && props.onClickItems && (
                <CloudImagenModalFooter>
                    <DefaultButton bgLight borderRadius={10} onClick={() => (props.onCloseModal ? props.onCloseModal() : {})}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton
                        borderRadius={10}
                        disabled={selectedCloudItems.length === 0}
                        onClick={() => props.onClickItems && props.onClickItems(selectedCloudItems)}
                    >
                        Insertar: {selectedCloudItems.length}
                    </PrimaryButton>
                </CloudImagenModalFooter>
            )}
        </Flex>
    );
}
