import React, { useEffect, useState } from "react";
import { DefaultInputDate, DefaultInputTime, Flex } from "../_controls";

interface CustomDateProps {
    date?: Date;
    onDateChange: (date: Date) => void;
    hideTimeSelector?: boolean;
}

function CustomDateInput(props: CustomDateProps) {
    const [date, setDate] = useState(() => {
        return props.date ?? new Date();
    });

    useEffect(() => {
        if (props.date) {
            setDate(props.date);
        }
    }, [props.date]);

    function handleDateChange(event: any) {
        const newDate = new Date(event.target.value + "T" + getTime());
        setDate(newDate);
        props.onDateChange(newDate);
    }

    function handleTimeChange(event: any) {
        const newDate = new Date(getDate() + "T" + event.target.value);
        props.onDateChange(newDate);
        setDate(newDate);
    }

    function getDate() {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    function getTime() {
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    return (
        <Flex gap10>
            <DefaultInputDate rounded type="date" id="date" onChange={handleDateChange} value={getDate()} />
            {!props.hideTimeSelector && (
                <DefaultInputTime rounded type="time" id="time" step="43200" onChange={handleTimeChange} value={getTime()} />
            )}
        </Flex>
    );
}

export default CustomDateInput;
