import styled from "styled-components";
import { DefaultButton } from "../_controls";

export const ColorListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .btn-color {
        border: 2px solid;
        border-radius: 50px;
        margin: 4px;
    }
`;

export const ColorDropdownContainer = styled.div`
    margin-right: 10px;
    button {
        border-radius: 40px;
    }
`;

export const DropdownColorOption = styled(DefaultButton)`
    border: 0;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    :hover {
        cursor: pointer;
        box-shadow: 1px 1px 6px ${(props) => props.theme.palette.shadowColor};
    }
`;
