import React, { useEffect, useState } from "react";
import { OrderChangeProps } from "..";
import { ProductPriceTypes } from "../../../../../api/models/product";
import { useAppSettingsContext } from "../../../../../appSettings/AppSettingsContext";
import { getModelFullName } from "../../../../../utils";
import { PriceListDropdown } from "../../../../Products/PriceList";
import PriceNameLine from "../../../../Products/PriceList/PriceNameLine";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../../../../_controls";
import ConfirmationModal from "../../../../modals/ConfirmationModal";
import Modal from "../../../../modals/Modal";

interface OrderUpdatePriceProps extends OrderChangeProps {
    updateExistingItemsPrices: (defaultPrice?: ProductPriceTypes) => void;
}

const OrderPriceDropdown = (props: OrderUpdatePriceProps) => {
    const { order } = props;
    const [showPricesAlert, setShowPricesAlert] = useState(false);
    const { appSettingsState, appSettingsActions } = useAppSettingsContext();
    const [updatePricesWithItems, setUpdatePriceWithItems] = useState<ProductPriceTypes>()

    const [customerPriceModal, setCustomerPriceModal] = useState(false);

    const { customer } = order;

    useEffect(() => {
        const defaultPriceName = order.customer?.defaultPriceName;
        if (order.customer && !order.isRestaurant && defaultPriceName && appSettingsState.defaultPrice !== defaultPriceName) {
            setCustomerPriceModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.customer]);

    const handleChangePrices = (price: ProductPriceTypes) => {
        if (order.items.length > 0) {
            setShowPricesAlert(true);
            setUpdatePriceWithItems(price)
        } else {
            appSettingsActions.setAllSettings({ ...appSettingsState, defaultPrice: price });
            props.onOrderChange({ ...order, defaultPrice: price });
        }
    };

    const updateCustomerPrice = () => {
        const defaultPriceName = order.customer?.defaultPriceName;
        setCustomerPriceModal(false);
        if (defaultPriceName) {
            appSettingsActions.setAllSettings({ ...appSettingsState, defaultPrice: defaultPriceName });
            props.updateExistingItemsPrices(defaultPriceName);
        }
    };

    return (
        <>
            {customerPriceModal && (
                <Modal title="Hola" useButtonClose show={customerPriceModal} setShow={setCustomerPriceModal}>
                    <Flex column padding={30} gap15>
                        <Flex column gap10>
                            <TextField bold fontSize={18}>
                                Actualizar el precio del cliente
                            </TextField>
                            <TextField>
                                ¿Quieres actualizar el precio de <strong>{getModelFullName(customer)}</strong> al ultimo precio que usó?
                            </TextField>
                        </Flex>

                        <Flex gap10 alignCenter spaceBetween padding={15} borderRadius={15} border>
                            <PriceNameLine defaultPrice={appSettingsState.defaultPrice} />
                            <span className="fa-regular fa-arrow-right"></span>

                            <PriceNameLine defaultPrice={customer?.defaultPriceName} />
                        </Flex>

                        <Flex gap10 marginTop={15}>
                            <DefaultButton bgLight w100 borderRadius={10} onClick={() => setCustomerPriceModal(false)}>
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton w100 borderRadius={10} onClick={() => updateCustomerPrice()}>
                                Confirmar
                            </PrimaryButton>
                        </Flex>
                    </Flex>
                </Modal>
            )}
            {showPricesAlert && (
                <ConfirmationModal
                    onCancel={() => setShowPricesAlert(false)}
                    onOkay={() => {
                        props.updateExistingItemsPrices(updatePricesWithItems);
                        setShowPricesAlert(false);
                    }}
                    title="La orden ya tiene productos"
                    description="Quieres actualizar el precio de los productos que ya están agregados?"
                    okayTitle="SI"
                    cancelTitle="NO"
                    show={showPricesAlert}
                    setShow={setShowPricesAlert}
                />
            )}
            <Flex column gap5>
                <TextField light small>
                    Precio
                </TextField>
                <PriceListDropdown
                    w100
                    bgLight
                    selectedPrice={order.defaultPrice ? order.defaultPrice : appSettingsState.defaultPrice}
                    onSelected={(value) => handleChangePrices(value)}
                />
            </Flex>
        </>
    );
};

export default OrderPriceDropdown;
