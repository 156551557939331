import React, { useContext, useState } from "react";
import { OrderChangeProps } from "..";
import { OrderFormOrigin } from "../..";
import { WahioFile } from "../../../../../api/models";
import { Contact } from "../../../../../api/models/contact";
import { LocationInfo } from "../../../../../api/models/deliveryOrder";
import { OrderItem } from "../../../../../api/models/order";
import { OrderStatus } from "../../../../../api/models/orderBase";
import { OrderInvoiceNumeration } from "../../../../../api/models/orderInvoiceNumeration";
import { ITax } from "../../../../../api/models/tax";
import { IWarehouse } from "../../../../../api/models/warehouse";
import { AppSettingsContext } from "../../../../../appSettings/AppSettingsContext";
import { getUtcDate } from "../../../../../utils";
import customMoment from "../../../../../utils/momentFormat/dateFormat";
import { getDestinationAddress } from "../../../../../utils/orders";
import ContactSelect from "../../../../Contacts/ContactSelect";
import CustomDateInput from "../../../../DatePicker/CustomDateInput";
import FilesViewer from "../../../../Files/FilesViewer";
import OrderInvoiceNumerationSelect from "../../../../OrderInvoiceNumerations/OrderInvoiceNumerationSelector";
import WarehouseSelect from "../../../../Warehouses/WarehouseSelect";
import { Flex, SquareButton, TextField } from "../../../../_controls";
import { FlexImageIcon } from "../../../../_controls/containers/FlexImageLetter";
import { Table } from "../../../../_controls/tables/styled";
import RouteMap from "../../../OrderProfile/OrderMap/RouteMap";
import { DetailCard } from "../../../OrderStyles/styled";
import { OrderDetailsContainer } from "../../styled";
import ContactDetails from "./ContactDetails";
import OrderChannelSelect from "./OrderChannelSelect";
import OrderDeliveryModal from "./OrderDeliveryModal";
import { EditShippingCostModal } from "./OrderEditShippingCostModal";
import OrderPriceDropdown from "./OrderPriceDropdown";
import ShipmentEventCalendar from "./ShipmentScheduleEvent";

export interface OrderDetailsProps extends OrderChangeProps {
    statusFiltered?: OrderStatus;
    totalQuantity?: number;
    origin?: OrderFormOrigin;
    availableTaxes: ITax[];
    onUpdateItems: (items: OrderItem[]) => void;
    updateExistingItemsPrices: () => void;
}

const ShipmentMapButton = (props: OrderDetailsProps) => {
    const { order } = props;
    const { deliveryOrder } = order;
    const destiny = getDestinationAddress(deliveryOrder);

    if (!destiny || !deliveryOrder) return null;

    const handleUpdateLocation = (location: LocationInfo) => {
        let delivery = { ...deliveryOrder };
        delivery.locationInfo = location;
        delivery.locationLat = location.lat;
        delivery.locationLng = location.lng;
        props.onOrderChange({ ...order, deliveryOrder: delivery });
    };

    return (
        <RouteMap
            location={deliveryOrder?.locationInfo}
            iconSize={30}
            useModalMap
            onLocationChange={handleUpdateLocation}
            destination={destiny}
        />
    );
};

const ShipmentDetails = (props: OrderDetailsProps) => {
    const [showOriginAddressModal, setShowOriginAddressModal] = useState(false);
    const { order } = props;
    const { deliveryOrder } = order;

    const detailsCount = React.useMemo(() => {
        return Object.keys(deliveryOrder?.details ?? {}).length;
    }, [deliveryOrder?.details]);

    if (!deliveryOrder) return null;

    const isDisabled = !order.customer;

    const onCourierChange = (contact?: Contact) => {
        props.onOrderChange({ ...order, deliveryOrder: { ...deliveryOrder, courier: contact, courierId: contact?.id } });
    };

    const courierImage = deliveryOrder.courier?.image;

    const details = deliveryOrder.details ?? {};

    return (
        <>
            {showOriginAddressModal && <OrderDeliveryModal show={showOriginAddressModal} setShow={setShowOriginAddressModal} {...props} />}

            <Flex column gap10 className="delivery-body">
                <Flex gap10 alignCenter spaceBetween>
                    <TextField bold small light>
                        Envío
                    </TextField>
                    <Flex gap10>
                        <ShipmentMapButton {...props} />
                        <SquareButton
                            bgLight
                            borderRadius={10}
                            disabled={isDisabled}
                            rounded
                            onClick={() => setShowOriginAddressModal(true)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i>
                        </SquareButton>
                    </Flex>
                </Flex>
                {isDisabled && (
                    <TextField small light>
                        Debes seleccionar un cliente para ingresar los detalles de envío
                    </TextField>
                )}
                {!isDisabled && (
                    <Table className="delivery-table" cellPadding={0} cellSpacing={0} hideBorde>
                        <tbody>
                            <tr>
                                <td>
                                    <i className="icon fa-regular fa-location-dot"></i>
                                </td>
                                <td>
                                    <TextField small light={!deliveryOrder.address}>
                                        {deliveryOrder.address ? deliveryOrder.address : "Dirección"}
                                    </TextField>{" "}
                                    <TextField small light={!deliveryOrder.city}>
                                        {deliveryOrder.city ? deliveryOrder.city : "Ciudad"}
                                    </TextField>{" "}
                                    <TextField small light={!deliveryOrder.state}>
                                        {deliveryOrder.state ? deliveryOrder.state : "Estado"}
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <i className="icon fa-regular fa-user"></i>
                                </td>
                                <td>
                                    <TextField small light={!deliveryOrder.contactName}>
                                        {deliveryOrder.contactName ? deliveryOrder.contactName : "Contacto (Requerido)"}
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <i className="icon fa-regular fa-phone"></i>
                                </td>
                                <td>
                                    <TextField small light={!deliveryOrder.phoneNumber}>
                                        {deliveryOrder.phoneNumber ? deliveryOrder.phoneNumber : "Teléfono (Requerido)"}
                                    </TextField>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                )}
            </Flex>
            <Flex column gap10 className="delivery-body">
                <Flex gap10 alignCenter spaceBetween>
                    <TextField bold small light>
                        Mensajero/Costo
                    </TextField>
                </Flex>

                <Table className="delivery-table" cellPadding={0} cellSpacing={0} hideBorde>
                    <tbody>
                        <tr>
                            <td>
                                {courierImage ? (
                                    <FlexImageIcon width={30} image={courierImage} />
                                ) : (
                                    <i className="icon fa-regular fa-location-dot"></i>
                                )}
                            </td>
                            <td>
                                <ContactSelect
                                    title="Mensajero"
                                    hideLabel
                                    placeholder="Mensajero"
                                    contact={deliveryOrder.courier}
                                    onUpdateContact={onCourierChange}
                                    isCourier
                                    w100
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <i className="icon fa-regular fa-dollar-sign"></i>
                            </td>
                            <td>
                                <EditShippingCostModal order={order} deliveryOrder={deliveryOrder} onUpdateOrder={props.onOrderChange} />
                            </td>
                        </tr>
                        {deliveryOrder.includesTransactionAccount && (
                            <tr>
                                <td>
                                    <i className="icon fa-regular fa-file"></i>
                                </td>
                                <td>Cuenta por Pagar</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Flex>
            {details && detailsCount > 0 && (
                <Flex column gap10 className="delivery-body">
                    <TextField bold small light>
                        Campos Adicionales
                    </TextField>
                    <Flex>
                        <Table tdPadding={4} cellPadding={0} cellSpacing={0}>
                            <tbody>
                                {Object.keys(details).map((key) => {
                                    let value = details[key];
                                    if (typeof value === "boolean") {
                                        value = value ? "Si" : "No";
                                    }
                                    return (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>{value}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Flex>
                </Flex>
            )}
            {deliveryOrder.isGift && (
                <Flex column gap10 className="delivery-body">
                    <TextField bold small light>
                        Entregar a:
                    </TextField>
                    <Flex>
                        <Table tdPadding={4} cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>
                                    <td>Contacto</td>
                                    <td>{deliveryOrder.destinationContactName}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono</td>
                                    <td>
                                        {deliveryOrder.destinationPhonePrefix} {deliveryOrder.destinationPhoneNumber}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default function OrderDetails(props: OrderDetailsProps) {
    const { order } = props;
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);

    const [customDate] = useState(() => {
        return customMoment(getUtcDate(order.dateCreatedCustom?.toString()) ?? new Date()).toDate();
    });

    const warehouseId = React.useMemo(() => {
        if (order.items) {
            let item = order.items.find((x) => !!x.warehouseId);
            if (item) {
                return item.warehouseId;
            }
        }
        return appSettingsState.defaultWarehouseId;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.items]);

    const onSelectedFiles = (files: WahioFile[] = []) => {
        props.onOrderChange({
            ...order,
            files,
        });
    };

    const handleUpdateDateCustom = (date: Date) => {
        props.onOrderChange({
            ...order,
            dateCreatedCustom: date,
        });
    };

    const onSelectWarehouse = (warehouse?: IWarehouse) => {
        if (warehouse?.id) {
            let newItems = props.order.items.map((item) => {
                item.warehouseId = warehouse?.id ?? "";
                item.warehouse = warehouse;
                item.stockTemp = undefined;
                return item;
            });
            props.onOrderChange({ ...props.order, items: newItems });
            if (!order.id) {
                appSettingsActions.setDefaultWarehouse(warehouse.id);
            }
        }
    };

    const onNumerationChange = (value?: OrderInvoiceNumeration) => {
        props.onOrderChange({ ...props.order, orderInvoiceNumerationId: value?.id });
        appSettingsActions.setAllSettings({ ...appSettingsState, selectedInvoiceNumerationId: value?.id });
    };

    const orderFiles = props.order.files ?? [];

    const showDelivery = props.order.deliveryOrder && props.origin !== "restaurant";

    const showButtonFlex = orderFiles.length === 0 || !order.id;
    const orderFormSettings = appSettingsState.orderForm ?? {};

    return (
        <>
            {showButtonFlex && (
                <Flex gap={15} flexWrap>
                    {!order.id && <ShipmentEventCalendar {...props} />}
                    {orderFiles.length === 0 && (
                        <FilesViewer
                            cardMaxHeight={80}
                            cardMaxWidth={80}
                            smallCard
                            showAttachButton
                            onUpdateFiles={onSelectedFiles}
                            files={orderFiles}
                        />
                    )}
                </Flex>
            )}

            {orderFiles.length > 0 && (
                <Flex padding={15} border borderRadius={15}>
                    <FilesViewer
                        cardMaxHeight={80}
                        cardMaxWidth={80}
                        smallCard
                        showAttachButton
                        onUpdateFiles={onSelectedFiles}
                        files={orderFiles}
                    />
                </Flex>
            )}

            <OrderDetailsContainer>
                {orderFormSettings.showPriceSelector && (
                    <OrderPriceDropdown
                        updateExistingItemsPrices={props.updateExistingItemsPrices}
                        order={props.order}
                        onOrderChange={props.onOrderChange}
                    />
                )}

                <ContactDetails {...props} w100 />

                {orderFormSettings.showWarehouseSelector && warehouseId && (
                    <DetailCard>
                        <span className="label">Bodega</span>
                        <WarehouseSelect
                            key={warehouseId}
                            defaultWarehouseId={warehouseId}
                            hideTitle
                            onWarehouseChange={onSelectWarehouse}
                        />
                    </DetailCard>
                )}

                {orderFormSettings.showSellerSelector && (
                    <ContactSelect
                        title="Vendedor"
                        hidePadding
                        w100
                        placeholder="Seleccionar Vendedor"
                        contact={props.order.contactSeller}
                        onUpdateContact={(contact) =>
                            props.onOrderChange({ ...order, contactSeller: contact, contactSellerId: contact?.id })
                        }
                        allowCreate
                        isSeller
                        loadCompleteContact
                    />
                )}
                {orderFormSettings.showChannelSelector && props.origin !== "restaurant" && <OrderChannelSelect {...props} />}

                {orderFormSettings.showNumerationSelector && !order.orderInvoice && (
                    <DetailCard>
                        <span className="label">Numeración</span>
                        <OrderInvoiceNumerationSelect
                            orderInvoiceNumerationId={appSettingsState.selectedInvoiceNumerationId || order.orderInvoiceNumerationId}
                            hideTitle
                            onNumerationChange={onNumerationChange}
                        />
                    </DetailCard>
                )}
                {props.origin !== "restaurant" && orderFormSettings.allowCustomDate && (
                    <Flex column gap5>
                        <TextField small light>
                            Fecha Personalizada
                        </TextField>
                        <CustomDateInput date={customDate} onDateChange={(value) => handleUpdateDateCustom(value)} />
                    </Flex>
                )}
            </OrderDetailsContainer>

            {showDelivery && (
                <OrderDetailsContainer>
                    <ShipmentDetails {...props} />
                </OrderDetailsContainer>
            )}
        </>
    );
}
