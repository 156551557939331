import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useEventListenerWindow } from "../../..";
import { getBillingConfigData } from "../../../../api/account/accountApi";
import { BillingCycle, CurrencyType } from "../../../../api/models/accountBilling";
import { AccountPlan, AccountPlanBase } from "../../../../api/models/accountUser";
import { useUserContext } from "../../../../store/contexts/UserContext";
import LoadingDualRing from "../../../LoadingDualRing";
import { Flex, GridTemplate } from "../../../_controls";
import GroupButtonRow from "../../../_controls/buttons/GroupButtonRow";
import Modal from "../../../modals/Modal";
import AccountBillingInvoiceForm from "../AccountBillingInvoiceForm";
import { ChangeAccountPlanForm } from "../ChangeAccountPlanForm";
import { BillingConfigData } from "../billingConfigDataModel";
import messages from "../messages";
import { AccountBillingConfigContainer } from "../styled";
import BillingPlanCard from "./BillingPlanCard";
import PlanComparison from "./PlanComparison";
import WhatsappButton from "./WhatsappButton";

const CYCLE_ANNUAL: BillingCycle = "annual";
const CYCLE_MONTHLY: BillingCycle = "monthly";

export interface BillingConfigState {
    billingCycle: BillingCycle;
    plan: AccountPlanBase;
    price: number;
    hideDetails: boolean;
}

export interface DefaultPlanOption {
    plan?: AccountPlan;
    cycle?: BillingCycle;
}

export interface AccountBillingPlanFormProps {
    existingPlanOptions?: DefaultPlanOption;
    paramsOptions?: DefaultPlanOption;
}

export default function AccountBillingPlanForm(props: AccountBillingPlanFormProps) {
    const intl = useIntl();
    const { userState } = useUserContext();
    const account = userState.user?.account;
    const billing = account?.billing;

    const [configState, setConfigState] = useState<BillingConfigState>({
        billingCycle: props.existingPlanOptions?.cycle ?? "monthly",
        plan: "standard",
        price: 0,
        hideDetails: false,
    });

    const [billingConfig, setBillingConfig] = useState<BillingConfigData>();

    const billingConfigMutation = useMutation(() => getBillingConfigData(), {
        onSuccess: (data) => {
            setBillingConfig(data);
        },
    });

    const timerRef = React.useRef<any>();
    const [isMobile, setIsMobile] = useState(false);

    const [showModaInvoiceForm, setShowModaInvoiceForm] = useState(false);
    const [showModalPlanChange, setShowModalPlanChange] = useState(false);

    //const [selectedPlan, setSelectedPlan] = useState<AccountPlanBase>("standard");
    const [currency] = useState<CurrencyType>("cop");

    useEffect(() => {
        billingConfigMutation.mutate();
        updateMobileView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDefaultConfigState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingConfig, props.paramsOptions]);

    const setDefaultConfigState = () => {
        try {
            let optionPlan = props.paramsOptions?.plan;
            let optionCycle = props.paramsOptions?.cycle;
            if (billingConfig?.prices && optionPlan && optionPlan !== "free" && optionCycle) {
                const selectedBillingPrice = billingConfig.prices[optionCycle];
                var price = selectedBillingPrice[optionPlan];

                setConfigState({ ...configState, plan: optionPlan, price: price.cop });
                setShowModaInvoiceForm(true);
            }
        } catch (e) {}
    };

    const updateMobileView = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setIsMobile(window.innerWidth < 850);
        }, 100);
    };
    useEventListenerWindow("resize", () => {
        updateMobileView();
    });

    const handleShowInvoiceForm = () => {
        if (props.existingPlanOptions) {
            setShowModalPlanChange(true);
        } else {
            setShowModaInvoiceForm(true);
        }
    };

    if (billingConfigMutation.isLoading) return <LoadingDualRing center />;

    if (!billingConfig) {
        return (
            <Flex padding={15}>
                <h1>Billing Config Data Empty</h1>
            </Flex>
        );
    }

    const selectedBillingPrice = billingConfig.prices[configState.billingCycle];

    return (
        <>
            {showModaInvoiceForm && (
                <Modal show={showModaInvoiceForm} setShow={setShowModaInvoiceForm} ignoreOutsideClick useButtonClose>
                    <AccountBillingInvoiceForm
                        currency={currency}
                        billingCycle={configState.billingCycle}
                        plan={configState.plan}
                        costPerUser={configState.price}
                    />
                </Modal>
            )}
            {showModalPlanChange && (
                <Modal show={showModalPlanChange} setShow={setShowModalPlanChange} ignoreOutsideClick useButtonClose>
                    <Flex padding={20} column>
                        <ChangeAccountPlanForm
                            plan={configState.plan}
                            costPerUser={configState.price}
                            onCancel={() => setShowModalPlanChange(false)}
                            onCreate={() => {}}
                            cycle={configState.billingCycle}
                        />
                    </Flex>
                </Modal>
            )}
            <AccountBillingConfigContainer>
                <Flex padding={20} gap15 column>
                    <Flex column w100 gap5 alignCenter>
                        <Flex column>
                            <span>Ciclo de Facturación</span>
                        </Flex>
                        <GroupButtonRow
                            activeOption={configState.billingCycle}
                            onClickOption={(option) => setConfigState({ ...configState, billingCycle: option.value })}
                            options={[
                                {
                                    title: intl.formatMessage(messages.monthly),
                                    value: CYCLE_MONTHLY,
                                },
                                { title: intl.formatMessage(messages.annual), value: CYCLE_ANNUAL },
                            ]}
                        />
                        <span className="text-light text-small">Ahorra un mes con el plan Anual</span>
                    </Flex>

                    <Flex w100 justifyCenter alignCenter className="mt-1">
                        <WhatsappButton />
                    </Flex>
                    {billing && (
                        <GridTemplate gap={25} size={230} className="mt-2">
                            {Object.keys(selectedBillingPrice).map((plan, index) => {
                                return (
                                    <BillingPlanCard
                                        key={index}
                                        plan={plan as AccountPlanBase}
                                        billing={billing}
                                        configState={configState}
                                        billingConfig={billingConfig}
                                        currency={currency}
                                        isMobile={isMobile}
                                        existingPlanOptions={props.existingPlanOptions}
                                        onSelectedPlan={(plan: AccountPlanBase, price: number) => {
                                            setConfigState({ ...configState, plan, price });
                                            handleShowInvoiceForm();
                                        }}
                                    />
                                );
                            })}
                        </GridTemplate>
                    )}

                    <p className="text-small text-light">NOTA: Cada email extra a $0.0005 usd, Cada sms extra a $0.005 usd</p>

                    <h2 className="m-0 text-bold mt-2">Compara los planes</h2>

                    <PlanComparison billingConfig={billingConfig} />
                </Flex>
            </AccountBillingConfigContainer>
        </>
    );
}
