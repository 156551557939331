import React from "react";
import { useIntl } from "react-intl";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { ThemeType } from "../../../appSettings/types";
import { DefaultButton, Flex, GridTemplate, TextField } from "../../_controls";
import { ThemeColor, gradientColorObj, mainColorObj, themeOptions } from "./helper";
import messages from "./messages";
import { ButtonColor } from "./styled";

export default function ThemeSettings() {
    const { appSettingsState, appSettingsActions } = useAppSettingsContext();
    const intl = useIntl();
    const themeSettings = appSettingsState.themeSettings ?? {};

    const onThemeChange = (theme: ThemeType) => {
        appSettingsActions.setAllSettings({ ...appSettingsState, themeSettings: { ...themeSettings, theme } });
    };

    const onColorChange = (colorName: string) => {
        appSettingsActions.setAllSettings({ ...appSettingsState, themeSettings: { ...themeSettings, colorName } });
    };

    const theme = themeSettings.theme ?? "light";
    const colorName = themeSettings.colorName ?? "Solid_Blue";

    return (
        <Flex column padding={20} gap={30}>
            <h3 className="m-0">Configuración de Tema</h3>
            <Flex column gap5>
                <TextField>Elegir Tema</TextField>
                <Flex flexWrap gap10>
                    {themeOptions.map((option) => {
                        return (
                            <DefaultButton
                                primaryOutline={option.type === theme}
                                borderRadius={10}
                                bgLight
                                key={option.type}
                                onClick={() => onThemeChange(option.type)}
                            >
                                <i className={option.icon}></i>
                                {intl.formatMessage(messages[option.type])}
                            </DefaultButton>
                        );
                    })}
                </Flex>
            </Flex>

            <ColorList title="Color Solido" colorList={mainColorObj} colorName={colorName} onColorChange={onColorChange} />
            <ColorList title="Color Gradiente" colorList={gradientColorObj} colorName={colorName} onColorChange={onColorChange} />
        </Flex>
    );
}
interface ColorListProps {
    title: string;
    colorList: ThemeColor[];
    onColorChange: (colorName: string) => void;
    colorName: string;
}
const ColorList = (props: ColorListProps) => {
    const { title, colorList, onColorChange, colorName } = props;
    return (
        <Flex column gap5>
            <TextField>{title}</TextField>
            <GridTemplate size={100}>
                {colorList.map((item) => {
                    return (
                        <ButtonColor
                            removeBackground
                            primaryOutline={colorName === item.name}
                            borderRadius={10}
                            bgLight
                            key={item.name}
                            onClick={() => onColorChange(item.name)}
                        >
                            <div className="color-circle" style={{ background: item.light?.background }}></div> {item.name.split("_")[1]}
                        </ButtonColor>
                    );
                })}
            </GridTemplate>
        </Flex>
    );
};
