import React from "react";
import { TransactionAccountStateProps } from ".";
import FilesViewer from "../../../Files/FilesViewer";
import { TransactionAccountTabBody } from "../styled";

export default function Files(props: TransactionAccountStateProps) {
    const { body, setBody } = props;
    return (
        <TransactionAccountTabBody>
            <FilesViewer
                onUpdateFiles={(files) => {
                    setBody({ ...body, files });
                }}
                files={body.files ?? []}
                showAttachButton
                cardMaxWidth={200}
            />
        </TransactionAccountTabBody>
    );
}
