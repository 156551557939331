import React, { createContext, useState } from "react";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../api";
import { InventoryLocation } from "../../api/models/inventoryAdjustment";
import { createUpdateInventoryLocation, getInventoryLocationsByAccount } from "../../api/products/inventoryAdjustment";
import { getAuthBody } from "../../auth";

export interface InventoryLocationState {
    items: InventoryLocation[];
    isFetching: boolean;
}

interface InventoryLocationContextProps {
    items: InventoryLocation[];
    setItems: (value: InventoryLocation[]) => void;
    fetchItems: () => void;
    fetchItemsIfNotExits: () => void;
    isFetching: boolean;
    lastItem?: InventoryLocation;
    setLastItem: (data: InventoryLocation) => void;
    createUpdateItem: (value: InventoryLocation, onCreate: (value: InventoryLocation) => void) => void;
}

export const InventoryLocationContext = createContext({} as InventoryLocationContextProps);

const InventoryLocationContextProvider = (props: any) => {
    const [items, setItems] = useState<InventoryLocation[]>([]);

    const [lastItem, setLastItem] = useState<InventoryLocation>();

    const searchMutation = useMutation((id: string) => getInventoryLocationsByAccount(id), {
        onSuccess: (data) => {
            setItems(data);
        },
        onError: (err) => {
            console.log(getServiceMessageError(err));
        },
    });

    const createUpdateMutation = useMutation((data: InventoryLocation) => createUpdateInventoryLocation(data));

    const handleCreateUpdate = (data: InventoryLocation, onCreate: (data: InventoryLocation) => void) => {
        createUpdateMutation
            .mutateAsync(data)
            .then((result) => {
                let some = items.some((x) => x.id === result.id);
                if (some) {
                    setItems(items.filter((x) => (x.id === result.id ? result : x)));
                } else {
                    setItems([result, ...items]);
                }
                onCreate(result);
            })
            .catch((err) => {
                console.log(getServiceMessageError(err));
            });
    };

    const handleSearchList = () => {
        searchMutation.mutate(getAuthBody().accountId);
    };
    const fetchItemsIfNotExits = () => {
        if (items.length === 0) {
            searchMutation.mutate(getAuthBody().accountId);
        }
    };

    return (
        <InventoryLocationContext.Provider
            value={{
                items,
                setItems,
                isFetching: searchMutation.isLoading || createUpdateMutation.isLoading,
                fetchItems: handleSearchList,
                fetchItemsIfNotExits,
                createUpdateItem: handleCreateUpdate,
                lastItem,
                setLastItem,
            }}
        >
            {props.children}
        </InventoryLocationContext.Provider>
    );
};

export const useInventoryLocationContext = () => {
    return React.useContext(InventoryLocationContext);
};

export default InventoryLocationContextProvider;
