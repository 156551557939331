import styled from "styled-components";
import { Flex } from "../../_controls";

export const ChatUserListContainer = styled(Flex)`
    padding: 15px 10px;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

interface DropProps {
    counter?: number;
}
export const ChatListContainer = styled.div`
    display: flex;
    gap: 8px;
    .preview-chats {
        max-width: 100%;
        gap: 5px;
    }
`;
interface ChatActiveUserCardProps {
    isOnline?: boolean;
    counter?: number;
}
export const ChatActiveUserCard = styled.div`
    display: flex;
    width: 33px;
    height: 33px;
    min-width: 33px;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .card-name {
        background: ${(props) => props.theme.palette.backgroundLight};
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 27px;
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        box-sizing: border-box;
        font-weight: 500;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
        border: 2px solid ${(props) => props.theme.palette.backgroundLight};
        ${(props: ChatActiveUserCardProps) => (props.isOnline ? `` : "")};
        box-sizing: border-box;
    }
    ::before {
        ${(props: ChatActiveUserCardProps) => (props.counter && props.counter > 0 ? `content: '${props.counter}'` : "")};

        background: #f44336;
        position: absolute;
        color: white;
        border-radius: 30px;
        position: absolute;
        right: -1px;
        top: -3px;
        z-index: 4;
        font-size: 12px;
        font-weight: 500;
        padding: 1px 3px;
    }
    ::after {
        ${(props: ChatActiveUserCardProps) => (props.isOnline ? "content: ' '" : "")};
        width: 10px;
        height: 10px;
        background: #4caf50;
        position: absolute;
        border: 2px solid ${(props) => props.theme.palette.background};
        border-radius: 30px;
        position: absolute;
        left: -1px;
        bottom: -1px;
        z-index: 4;
    }
`;

export const ChatListDropdownContainer = styled.div`
    position: relative;

    ::after {
        ${(props: DropProps) => (props.counter && props.counter > 0 ? `content: '${props.counter}';` : ``)}
        background: ${(props) => props.theme.palette.dangerColor};
        color: white;
        position: absolute;
        padding: 1px 3px;
        top: 0px;
        right: -3px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 20px;
    }
`;

export const ChatUserRow = styled(Flex)`
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    .pending-message-date {
        font-size: 12px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .user-card {
        overflow: auto;
        width: 100%;
    }
    .pending-messages {
        color: ${(props) => props.theme.palette.primary};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .pending-message-bubble {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 5px;
        border-radius: 15px;
    }
    &.online {
        .image-container {
            position: relative;
            ::after {
                content: " ";
                width: 10px;
                height: 10px;
                background: #4caf50;
                position: absolute;
                right: 0;
                bottom: 1px;
                border-radius: 14px;
                border: 2px solid ${(props) => props.theme.palette.background};
            }
        }
    }

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;
