import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getServiceMessageError } from "../../../../api";
import { IPurchase } from "../../../../api/models/purchase";
import { formatMoney } from "../../../../utils";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import { getTotalPayments } from "../../../Orders/CreateOrderForm/FinalizeOrderForm/finalizeHelper";
import { FinalizeContainer, PaymentTermView, TotalAmountCard } from "../../../Orders/CreateOrderForm/FinalizeOrderForm/styled";
import PaymentsForm from "../../../Payments/PaymentsForm";
import usePaymentHook from "../../../Payments/paymentHooks";
import TextBoxNumeral from "../../../TextBoxNumeral";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../../../_controls";
import CheckBox from "../../../_controls/inputs/CheckBox";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import messages from "../messages";
import { getButtonTitleOption } from "./finalizeHelper";
import { ActionsButtonsContainer, SuccessContainer } from "./styled";

export type PaymentChangeType = "method" | "value";

export interface ISaveResponse {
    isFetching: boolean;
    isError: boolean;
    error?: any;
    value?: IPurchase;
    isSave?: boolean;
}

export interface FinalizeOrderFormProps {
    onCancel: () => void;
    onFinalize: (receiveAllProducts?: boolean) => void;
    onFinalizeClose: () => void;
    responseResult: ISaveResponse;
    setResponseResult: (value: ISaveResponse) => void;
    updatePurchase: (value: IPurchase) => void;
    currentPurchase: IPurchase;
}

export default function FinalizeOrderForm(props: FinalizeOrderFormProps) {
    const intl = useIntl();
    const alert = useAlert();
    const { currentPurchase } = props;
    const [receiveAllProducts, setReceiveAllProducts] = useState(false);
    const [showConfirmationToFinalizeOrder, setShowConfirmationToFinalizeOrder] = useState(false);
    const [defaultDataLoad, setDefaultDataLoad] = useState(false);
    const paymentHooks = usePaymentHook();

    const { responseResult, setResponseResult } = props;

    const { payments } = currentPurchase;

    const getInitialPayments = () => {
        if (payments.length === 0) {
            let payAmount = currentPurchase.totalAmount;
            let payment = paymentHooks.getNewPayment({ amount: payAmount });
            if (payment) {
                return [...payments, payment];
            } 
        }
        return payments ?? [];
    };

    const totalPayments = React.useMemo(() => {
        if (!defaultDataLoad) {
            let initialPayments = getInitialPayments();
            props.updatePurchase({ ...currentPurchase, payments: initialPayments });
            setDefaultDataLoad(true)
            return getTotalPayments(initialPayments);
        } else {
            return getTotalPayments(payments)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payments]);

    const differencePrices = currentPurchase.totalAmount - totalPayments;
    const buttonFinish = getButtonTitleOption(currentPurchase.totalAmount, totalPayments);

    const onCloseFinalize = () => {
        setResponseResult({ isError: false, isFetching: false });
        props.onFinalizeClose();
    };

    if (!currentPurchase.supplierId || !currentPurchase.warehouseId) {
        return (
            <SuccessContainer>
                <span className="wahioicon-exclamation-circle"></span>
                <br />
                <p>
                    <FormattedMessage {...messages.supplierWarehouseRequired} />
                </p>
                <DefaultButton onClick={onCloseFinalize}>Aceptar</DefaultButton>
            </SuccessContainer>
        );
    }

    if (responseResult.isSave && responseResult.value) {
        return (
            <SuccessContainer>
                <span className="icon wahioicon-check-circle"></span>
                <h2>Bien!</h2>
                <PrimaryButton onClick={onCloseFinalize}>Aceptar</PrimaryButton>
            </SuccessContainer>
        );
    }

    if (responseResult.isError) {
        return (
            <SuccessContainer>
                <span className="icon wahioicon-exclamation-circle"></span>
                <h2>Ops!</h2>
                {responseResult.error?.data ? (
                    <p>{getServiceMessageError(responseResult.error.data)}</p>
                ) : (
                    <p>No se pudo crear la orden correctamente.</p>
                )}

                <PrimaryButton onClick={() => setResponseResult({ isFetching: false, isError: false })}>Aceptar</PrimaryButton>
            </SuccessContainer>
        );
    }
    if (responseResult?.isFetching) {
        return <LoadingDualRing center={true} />;
    }

    return (
        <FinalizeContainer>
            <ConfirmationModal
                show={showConfirmationToFinalizeOrder}
                setShow={() => setShowConfirmationToFinalizeOrder(false)}
                title="¿Estás seguro de finalizar la orden?"
                description="Recibirás los productos de forma automática"
                onOkay={() => props.onFinalize(receiveAllProducts)}
                zIndex={100}
            />

            <TotalAmountCard>
                <TextField small bold>
                    Total
                </TextField>
                <TextField bold600 fontSize={25} className="title-amount">
                    {formatMoney(currentPurchase.totalAmount)}
                </TextField>
            </TotalAmountCard>

            <PaymentsForm
                payments={currentPurchase.payments ?? []}
                onChange={(items) => props.updatePurchase({ ...currentPurchase, payments: items })}
                uniqueMethod={false}
                showAddPaymentButton
                totalAmount={currentPurchase.totalAmount}
                blockExistingPayments
            />

            {currentPurchase.totalAmount > totalPayments && (
                <PaymentTermView className="mt-1">
                    <span className="payment-title">Plazo en días para el pago</span>
                    <div className="input-group">
                        <span className="wahioicon-user-clock"></span>
                        <div className="flex">
                            <TextBoxNumeral
                                value={currentPurchase.paymentTermsDays}
                                onNumberChange={(value) => {
                                    props.updatePurchase({ ...currentPurchase, paymentTermsDays: value });
                                }}
                                format={"number"}
                                placeholder="Plazo"
                            />
                            <div className="date">
                                <span className="light">Vence</span>
                                <span>{moment().add(currentPurchase.paymentTermsDays, "days").format("LL")}</span>
                            </div>
                        </div>
                    </div>
                </PaymentTermView>
            )}
            {!currentPurchase.warehouse?.allowLogisticsService && (
                <Flex marginTop={20}>
                    <CheckBox
                        name="receiveAllProducts"
                        onChange={(e) => setReceiveAllProducts(e.target.checked)}
                        checked={receiveAllProducts}
                        title="Recibir todos los productos al finalizar la orden"
                    />
                </Flex>
            )}

            {differencePrices < 0 ? (
                <p className="font-md text-warning">
                    <span className="wahioicon-exclamation-triangle"></span> {intl.formatMessage(messages.paymentExceedsOrderValue)}{" "}
                    <strong>{formatMoney(differencePrices * -1)}</strong>
                </p>
            ) : (
                <ActionsButtonsContainer>
                    <DefaultButton borderRadius={10} onClick={props.onCancel} className="cancel">
                        {intl.formatMessage(messages.cancelModal)}
                    </DefaultButton>
                    <DefaultButton borderRadius={10}
                        onClick={() => {
                            if (receiveAllProducts) {
                                if (currentPurchase.items.map((x) => x.product?.includesSerials).includes(true)) {
                                    alert.error("no puedes recibir productos con seriales de forma automática");
                                } else {
                                    setShowConfirmationToFinalizeOrder(true);
                                }
                            } else props.onFinalize();
                        }}
                        className={buttonFinish.classname}
                    >
                        {buttonFinish.title}
                    </DefaultButton>
                </ActionsButtonsContainer>
            )}
        </FinalizeContainer>
    );
}
