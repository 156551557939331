import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useContext, useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ProductCreationProps } from ".";
import { UserContext } from "../../../store/contexts/UserContext";
import { removeHtmlTags } from "../../../utils";
import DraftEditor from "../../HmtlEditor/DraftEditor";
import { ChatResponsePlayground } from "../../OpenAi";
import { Flex, TextField } from "../../_controls";

export default function ProductDescriptionForm(props: ProductCreationProps) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { userState } = useContext(UserContext);
    const account = userState.user?.account;

    const [prompt, setPrompt] = useState("");

    useEffect(() => {
        const companyName = userState.user?.account?.companyName ?? "Mi empresa";
        const category = props.product.customCategory?.name ?? "Sin Categoría";
        const productName = props.product.name ?? "Sin Nombre";

        setPrompt(
            `Genera una descripción en español para uno de nuestros productos en la categoría '${category}', el nombre del producto es '${productName}', La descripción debe resaltar las características del producto, y cualquier detalle que lo haga atractivo para los clientes interesados en productos de '${category}', el nombre de mi tienda en linea es '${companyName}'`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getInitialEditorState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product.id]);

    const getInitialEditorState = (description?: string) => {
        const sampleMarkup = description ?? props.product.description;
        if (sampleMarkup) {
            const blocksFromHTML = convertFromHTML(sampleMarkup);
            const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            setEditorState(EditorState.createWithContent(state));
            props.onChange({ ...props.product, description: sampleMarkup });
        }
    };

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        let description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        description = removeHtmlTags(description);
        props.onChange({ ...props.product, description });
    };

    const authorized = account?.plan === "premium" || account?.plan === "free";
    const autoGenerateDisabled = !props.product.name || !props.product.customCategory?.name;

    return (
        <Flex column gap15>
            <Flex column gap5>
                <Flex flexWrap gap10>
                    <ChatResponsePlayground
                        btnTitle="Generar con AI"
                        disabled={autoGenerateDisabled || !authorized}
                        onResponse={(response) => {
                            getInitialEditorState(response);
                        }}
                        prompt={prompt}
                    />
                </Flex>
                {autoGenerateDisabled && (
                    <TextField small light>
                        Para generar la descripción automática debes tener el nombre y la categoría del producto
                    </TextField>
                )}
            </Flex>

            <DraftEditor editorState={editorState} onEditorStateChange={onEditorStateChange} />
        </Flex>
    );
}
