import styled from "styled-components";
import { device } from "../../../theme/themeProvider";
import { Flex, GridTemplate } from "../../_controls";
import { Table, TableCustomTr } from "../../_controls/tables/styled";

export const ConfirmQueuedContainer = styled.div`
    padding: 30px 20px;
    background: ${(props) => props.theme.palette.primary}30;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    .panel-body {
        box-sizing: border-box;
        padding: 20px 20px;
        width: 100%;
        max-width: 400px;
        background: ${(props) => props.theme.palette.background};
        border-radius: 20px;
        box-shadow: 1px 1px 15px ${(props) => props.theme.palette.shadowColor};

        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        .table-details {
            max-width: 450px;
            width: 100%;

            td {
                :first-child {
                    color: ${(props) => props.theme.palette.textLight};
                }
            }
        }
    }
`;

export const OrderSerialsConfigView = styled.div`
    .serial-summary {
        max-width: 170px;
        width: 100%;
    }
    .items {
        .order-item {
            border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .order-item:last-child {
            border-bottom: 0;
        }
    }
`;

export const SerialLine = styled(Flex)`
    .select-body {
        width: 100%;
    }

    input {
        padding: 3px;
        border-radius: 0;
        background: transparent;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    &.active {
        .select__control {
            background-color: ${(props) => props.theme.palette.backgroundDark};
        }
        input {
            font-weight: 500;
        }
    }
`;

export const QuoteActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 16px;
    border-radius: 15px;
    background: #7c4dff20;

    gap: 15px;

    button {
        background: #7c4dff30;
        color: #7c4dff;
        border: 0;
        &:hover {
            background: #7c4dff;
            color: white;
        }
    }

    .small-description {
        margin: 0px;
        font-size: 12px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .action-title {
        margin: 0px;
        font-weight: 500;
        font-size: 15px;
    }

    .flex-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
`;

export const OrderInvoiceCard = styled.div`
    padding: 20px;

    &.info {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .numeration-card {
        display: flex;
        flex-direction: column;
        ul li {
            list-style: decimal;
        }
    }
`;

export const OrderRejectedCard = styled.div`
    border-radius: 15px;
    background: #f443361f;
    padding: 10px 20px;

    font-size: 13px;
`;

export const TableCustom = styled.table`
    width: fit-content;

    .table-tr:last-child {
        td {
            border-bottom: 0px solid ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const TableDetails = styled(Table)`
    td {
        :first-child {
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const OrderButtonsFlex = styled(Flex)`
    button {
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.background};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const CustomTableTrFromOrder = styled(TableCustomTr)`
    padding: 10px 17px;
    border-bottom: 0px;

    .total-card {
        font-size: 18px;
        font-weight: 600;
    }

    .btn-light {
        padding: 3px 12px;
        border-radius: 40px;
        border: 0px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    th,
    td {
        border-bottom: 0px solid blue;
        text-align: left;
        line-height: 1.2;
        padding: 0px 4px;
        padding-left: 0px;
        padding-right: 10px;
        font-weight: 400;
        a {
            display: block;
            color: ${(props) => props.theme.palette.primary};
        }
    }
    .whiOutMargin {
        margin: 0px;
    }
`;

export const ProfileContainerGrid = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(310px), 1fr);

    @media ${device.md} {
        grid-template-columns: repeat(auto-fill, minmax(${(props: { large?: boolean }) => (props.large ? "420px" : "310px")}, 1fr));
    }
`;

export const NormalContainer = styled.div`
    width: 100%;
    .container-body {
        border: 0px;
    }
`;

export const OrderWarehouseProfileContainer = styled.div`
    display: flex;
    padding: 6px 12px;
    align-items: center;
    border: 0px solid blue;
    width: fit-content;
    border-radius: 18px;
    color: ${(props) => props.theme.palette.textLight};

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        font-size: 12px;
    }
    .container-body {
        display: flex;
        flex-direction: column;
        line-height: 1;
    }
    .title {
        font-size: 12px;
        font-weight: 500;
    }
    .sub-title {
        font-size: 12px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const ActiveRestaurantContainer = styled.div`
    padding: 15px 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    font-size: 13px;
    background: #2962ff2c;
    font-weight: 500;
    color: #2962ff;
    .icon {
        margin-right: 15px;
    }
`;

export const FlexRowOrder = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexTop = styled(FlexRowOrder)`
    justify-content: space-between;
`;

export const SummaryPaymentContainerOrder = styled(FlexRowOrder)`
    width: 100%;
    justify-content: space-between;
`;

export const RegularContainer = styled(FlexRowOrder)`
    margin: 1.5rem 0rem;
    margin-bottom: 0.2rem;
`;

export const OrderItemsContainer = styled.div`
    padding: 0px;
    overflow: auto;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    .sticky {
        background: ${(props) => props.theme.palette.background};
        position: sticky;
        top: 0;
        height: 100%;
        width: 100%;
        min-height: 40px;
        padding: 10px;
        z-index: 1;
    }
`;

export const OrderItemCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.palette.background};
    border-radius: 15px;
    padding: 5px;
    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .item-total {
        margin-left: auto;
    }
    .item-discount {
        color: #ff5722;
        font-size: 13px;
        font-weight: 500;
    }
    .item-tax {
        color: #673ab7;
        font-size: 13px;
        font-weight: 500;
    }
    .product-name {
        color: ${(props) => props.theme.palette.text};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: ${(props) => props.theme.palette.primary};
            cursor: pointer;
        }
    }
`;

export const TotalContainer = styled(FlexRowOrder)`
    padding: 0rem 7px;
    border-radius: 5px;
    .subtitle {
        margin-right: 10px;
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    h2,
    h3 {
        margin: 0;
    }
`;

export const SinglePaymentModalContainer = styled.div`
    padding: 30px 18px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .btn-rounded {
        background: ${(props) => props.theme.palette.backgroundDark};
        padding: 3px 10px;
        border-radius: 20px;
    }
`;

export const DeliveryOrderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    box-sizing: border-box;

    .shipment-title {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
    }
`;

export const OrderInvoiceContainerStyle = styled.div`
    background: ${(props) => props.theme.palette.greenLight};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding: 20px;

    .action-btn {
        background: ${(props) => props.theme.palette.green};
        color: white;
        border: 0;
        &:hover {
            background: #009a85;
        }
    }
`;

export const DeliveryInfoGridStyle = styled(GridTemplate)`
    .icon {
        font-size: 15px;
        margin-left: 5px;
    }
    .internal-card {
        box-sizing: border-box;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        border-radius: 15px;
        width: 100%;
        padding: 15px;
    }

    &.grid-none {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .internal-card {
            padding: 0;
            border: 0;
            border-radius: 0;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        button {
            display: none;
        }
        .internal-card {
        }
    }
`;

export const OrderPickingContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    .picking-item {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
    .display-none {
        display: none;
    }
    .picking-item:last-child {
        border: 0px;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .search-input {
        max-width: fit-content;
        input {
            border-radius: 27px;
            background: transparent;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        }
    }

    .picking-title {
        margin: 0px;
        color: ${(props) => props.theme.palette.text};
        font-weight: 500;
        &:hover {
            color: ${(props) => props.theme.palette.primary};
            text-decoration: underline;
        }
    }
    .card-completed {
        margin-left: 15px;
        background: ${(props) => props.theme.palette.primary}20;
        color: ${(props) => props.theme.palette.primary};
        padding: 3px 5px;
        border-radius: 18px;
        font-size: 13px;
        font-weight: 500;
    }
`;

export const OrderPickingHeaderCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .header-card {
        padding: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        display: flex;
        border-radius: 8px;
        font-size: 1.2rem;
        font-weight: 500;
        align-items: center;

        .bintitle {
            font-size: 14px;
            font-weight: 400;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
        .percentage {
            margin-left: 18px;
            color: ${(props) => props.theme.palette.primary};
            background: ${(props) => props.theme.palette.primary}20;
            padding: 4px 10px;
            border-radius: 25px;
            font-size: 14px;
        }
        .col {
            display: flex;
            flex-direction: column;
        }
        .subtitle {
            font-size: 13px;
            font-weight: 400;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;

export const OrderPickingItem = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    .item-alert {
        color: ${(props) => props.theme.palette.dangerColor};
        padding: 0px 16px;
        margin-bottom: 7px;
        border-radius: 31px;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .item-body {
        display: flex;
        align-items: center;
        padding: 15px 0px;
        padding-top: 0px;
    }

    .item-card {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        flex-wrap: wrap;
    }

    .card-actions {
        margin-left: 0px;
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .image {
        overflow: hidden;
        width: 70px;
        min-width: 70px;
        height: 70px;
        border-radius: 8px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media ${device.md} {
        .item-card {
            flex-direction: row;
            align-items: center;
        }
        .card-actions {
            margin-left: auto;
            margin-top: 0px;
        }
        .image {
            width: 60px;
            min-width: 60px;
            height: 60px;
        }
    }

    .edit {
        background: transparent;

        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .FlexCol {
        display: flex;
        flex-direction: column;
    }
    .pick {
        text-align: center;
        margin: 0px 10px;
        .quantity {
            font-size: 1.5rem;
        }
    }

    .location-item {
        padding: 7px;
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
        border-radius: 8px;
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        .icon {
            margin-right: 10px;
        }
        .location-code {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .locationcolor {
        ${(props: { locationColor?: string }) =>
            props.locationColor
                ? ` background: ${props.locationColor}30;     
       color: ${props.locationColor}; 
    `
                : ""}
    }

    .quantity-view {
        margin-top: 7px;
        display: flex;
        align-items: center;
        button {
            margin-right: 5px;
        }
        .textbox-numeral {
            margin-right: 5px;
            text-align: center;
            border-radius: 35px;
        }
    }
    .check {
        background: ${(props) => props.theme.palette.primary};
        color: white;
    }
`;

export const LocationListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;

    .title {
        color: ${(props) => props.theme.palette.textLight};
        padding: 10px 13px;
        font-weight: 400;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
    }
`;

export const LocationSelectedCard = styled.div`
    background: ${(props: { locationColor: string }) => props.locationColor};
    color: white;
    padding: 19px;
    border-radius: 13px;
    font-size: 1.2rem;
    margin: 10px;
    font-weight: 500;
    cursor: pointer;
`;

export const TrackingNumberContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    transition: all 0.5s;

    .active {
        color: ${(props) => props.theme.palette.primary};
        transition: all 0.5s;
    }
    a {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const FullPagePrint = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    background: white;
`;

export const OrderLabelCard = styled.div`
    font-size: 13px;
    border: 2px solid black;
    border-radius: 10px;
    max-width: 400px;
    background-color: white;
    color: black;

    hr {
        background: black;
        height: 2px;
    }
    .bold {
        font-weight: 500;
    }
    .image {
        height: 50px;
        max-width: 100px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .subtitle {
        font-size: 13px;
        color: grey;
        margin-bottom: 4px;
    }
    .w-content {
        width: fit-content;
    }
    .grid {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 1fr;
        padding: 10px;
    }
    .padding {
        padding: 10px;
    }
    .flex {
        display: flex;
        padding: 10px;
    }
    .center {
        align-items: center;
    }
    .space-between {
        justify-content: space-between;
    }

    .col {
        display: flex;
        flex-direction: column;
    }
    .observation {
        margin: 10px 0px;
    }
`;

export const OrderFilesContainerStyle = styled.div`
    .files-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .actions {
            margin-top: 10px;
            display: flex;
        }
    }
`;

export const PaymentCardView = styled.div`
    color: ${(props) => props.theme.palette.text};
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border-radius: 15px;
    justify-content: space-between;
    width: 100%;

    box-sizing: border-box;
    .table-details {
        margin-left: 10px;
        margin-bottom: 12px;
        td {
            padding-right: 15px;
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    .amount {
        font-weight: 500;
        font-size: 16px;
        margin: 0px;
    }

    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        aspect-ratio: 1;

        font-weight: 500;
        border-radius: 12px;
        text-transform: uppercase;
        font-size: 15px;

        &.APPROVED {
            background: #4caf50;
            color: white;
        }
        &.PENDING {
            background: #ff5722;
            color: white;
        }
    }
`;

export const EmptyOrderFilesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
        width: 80px;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
`;

export const TitleSelectedOptions = styled.div`
    font-size: 13px;
    font-weight: 500;
    padding: 10px 0px 0px 20px;
    color: ${(props) => props.theme.palette.textLight};
    margin-bottom: 5px;
`;

export const SelectedOptionRotulo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
    padding: 0px 20px;
    font-size: 13px;
`;

export const ButtonsRotulo = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
    padding: 0px 20px 20px 0;
`;

export const FooterBarcode = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    padding: 0px 20px 20px 0;
`;

export const PaymentPending = styled.div`
    background: ${(props) => props.theme.palette.dangerColor};
    color: white;
    width: fit-content;
    border-radius: 10px;
    padding: 3px 5px;
    font-weight: 500;
    font-size: 12px;

    .sub-title {
        color: #f4433680;
    }
`;

export const RemainingPaymentCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: ${(props) => props.theme.palette.backgroundLight};
    padding: 10px;
`;
