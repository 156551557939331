import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { getCountries } from "../../api/geoLocation/geoLocationApi";
import { SelectOptionObject } from "../../api/models";
import { Country } from "../../api/models/geoLocation";
import { SelectBody } from "../Select/styled";

interface CountrySelectorProps {
    countryCode?: string;
    currency?: string;
    onChange: (value: Country) => void;
    onLoadCountry?: (value: Country) => void;
    hideTitle?: boolean;
    small?: boolean;
    title?: string;
    w100?: boolean;
}

const QUERY_KEY = "query_countries";

interface CountrySelectorBaseProps extends CountrySelectorProps {
    getOption: (item: Country) => SelectOptionObject<Country>;
    getOptionValue: (item: Country) => SelectOptionObject<Country>;
    type: "country" | "currency";
}

export function CountrySelectorBase(props: CountrySelectorBaseProps) {
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [items, setItems] = useState<Country[]>([]);
    const allowSearchRef = useRef(false);
    const queryClient = useQueryClient();

    const countryQuery = useQuery(QUERY_KEY, () => getCountries(), {
        refetchOnWindowFocus: false,
        enabled: allowSearchRef.current,
    });

    useEffect(() => {
        countryQuery.data = queryClient.getQueryData<Country[]>(QUERY_KEY);
        if (!countryQuery.data) {
            allowSearchRef.current = true;
            countryQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (countryQuery.data) {
            setItems(countryQuery.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryQuery.data, props.type]);

    useEffect(() => {
        if (props.countryCode) {
            var country = items.find((x) => x.iso2.toUpperCase() === props.countryCode?.toUpperCase());
            if (country && props.onLoadCountry) props.onLoadCountry(country);
            setSelectedCountry(country);
        } else if (props.currency) {
            setSelectedCountry(items.find((x) => x.currency.toUpperCase() === props.currency?.toUpperCase()));
        } else {
            setSelectedCountry(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.countryCode, props.currency]);

    const onCountryChange = (cashRegister?: Country) => {
        if (cashRegister) {
            setSelectedCountry(cashRegister);
            props.onChange(cashRegister);
        }
    };

    return (
        <SelectBody className={props.w100 ? "w100" : ""}>
            {!props.hideTitle && <span className="label">{props.title ?? "Seleccionar un país"}</span>}
            <Select
                placeholder="Seleccionar"
                className={`${props.small ? "select-small" : ""} ${selectedCountry ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedCountry?.name}
                defaultValue={selectedCountry ? props.getOptionValue(selectedCountry) : undefined}
                isDisabled={false}
                onChange={(value) => onCountryChange(value?.object)}
                isRtl={false}
                isSearchable={true}
                isLoading={countryQuery.isFetching}
                name="countries"
                options={items.map((item) => props.getOption(item))}
            />
        </SelectBody>
    );
}

export function CountrySelector(props: CountrySelectorProps) {
    const getOption = (item: Country) => {
        let option: SelectOptionObject<Country> = {
            label: item.name,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    return <CountrySelectorBase {...props} getOption={getOption} getOptionValue={getOption} type="country" />;
}

export function CountryCurrencySelector(props: CountrySelectorProps) {
    const getOption = (item: Country) => {
        let option: SelectOptionObject<Country> = {
            label: `${item.currency}`,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    const getOptionValue = (item: Country) => {
        let option: SelectOptionObject<Country> = {
            label: `${item.currency} - ${item.currency_symbol}`,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    return (
        <CountrySelectorBase {...props} getOption={getOption} getOptionValue={getOptionValue} title="Seleccionar moneda" type="currency" />
    );
}
