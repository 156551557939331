import moment from "moment";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderNavigationProps } from ".";
import { getAccountUserFullName } from "../../../api/models/accountUser";
import { checkAndGetMessage } from "../../../i18n/helper";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { useOrderInvoiceNumerationContext } from "../../../store/contexts/OrderInvoiceNumerationContext";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { formatMoney, getDateFormat, getDateFormatllll } from "../../../utils";
import { getDestinationAddress } from "../../../utils/orders";
import PriceNameLine from "../../Products/PriceList/PriceNameLine";
import { DefaultButton, Flex, GridTemplate, TextField } from "../../_controls";
import ContainerCollapse from "../../_controls/containers/ContainerCollapse";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import { Table, TableCustomTr } from "../../_controls/tables/styled";
import { OrderItemLogs } from "../OrderItemLogs";
import { ORDER_STATUS_STYLE } from "../OrderStatusFilter/helpers";
import OrderStatusLine from "../OrderStatusLine";
import { DeliveryInfoCard } from "./NavigationDeliveryOrder";
import OrderNotificationHistory from "./OrderNotificationHistory";
import OrderTableView from "./OrderTableView";
import messages from "./messages";
import { TableCustom } from "./styled";

export default function NavigationDetails(props: OrderNavigationProps) {
    const { order } = props;
    return (
        <Flex column gap={20}>
            <ContainerCollapse initialStateOpen title="Detalles de la orden">
                <MoreDetailsView {...props} />
            </ContainerCollapse>

            {order.notifications && order.notifications.length > 0 && (
                <ContainerCollapse initialStateOpen title="Notificaciones">
                    <OrderNotificationHistory order={order} />
                </ContainerCollapse>
            )}

            <ContainerCollapse renderOnShow initialStateOpen title="Historial de items">
                <OrderItemLogs orderId={order.id ?? "invalid"} />
            </ContainerCollapse>
            {order.statusHistory && order.statusHistory.length > 0 && (
                <ContainerCollapse initialStateOpen title="Historial de estados">
                    <TableCustom cellSpacing="0" cellPadding="0">
                        <tbody>
                            {order.statusHistory &&
                                order.statusHistory.map((item, index) => (
                                    <TableCustomTr key={index}>
                                        <td className="min-width">
                                            <DefaultButton className={`${ORDER_STATUS_STYLE[item.status].class} btn-status`}>
                                                <span className={ORDER_STATUS_STYLE[item.status].icon}></span>{" "}
                                                <span>
                                                    <FormattedMessage {...messages[item.status]} />
                                                </span>
                                            </DefaultButton>
                                        </td>
                                        <td>
                                            <span className="light"> {getDateFormatllll(item.dateCreated)}</span>
                                        </td>
                                    </TableCustomTr>
                                ))}
                        </tbody>
                    </TableCustom>
                </ContainerCollapse>
            )}
        </Flex>
    );
}

interface ItemCardProps {
    title: string;
    children: React.ReactNode;
}
const ItemCard = (props: ItemCardProps) => {
    return (
        <Flex column gap={2}>
            <TextField light small>
                {props.title}
            </TextField>
            {props.children}
        </Flex>
    );
};

interface MoreDetailsViewProps extends OrderNavigationProps {
    showDeliveryInfo?: boolean;
}

export const MoreDetailsView = (props: MoreDetailsViewProps) => {
    const { order } = props;
    const intl = useIntl();
    const { getUserById } = useAccountUserContext();
    const { getWarehouseById } = useWarehouseContext();

    const { orderInvoice } = order;
    const { orderInvoiceNumerationState, orderInvoiceNumerationActions } = useOrderInvoiceNumerationContext();

    const warehouseId = React.useMemo(() => {
        if (order.warehouseId) return order.warehouseId;

        let warehouseId = "";
        if (order.items && order.items.length > 0) {
            warehouseId = order.items[0].warehouseId ?? "";
        }
        return warehouseId;
    }, [order]);

    useEffect(() => {
        if (orderInvoiceNumerationState.items.length === 0) {
            orderInvoiceNumerationActions.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const user = getUserById(order.accountUserId);

    return (
        <Flex gap={30} column w100>
            <GridTemplate gap={20} size={150}>
                <ItemCard title="Creado por">
                    <Flex alignCenter gap5>
                        <FlexImageLetter width={25} text={user?.firstName ?? "-"} image={user?.image} />
                        <span>{getAccountUserFullName(getUserById(order.accountUserId))}</span>
                    </Flex>
                </ItemCard>

                <ItemCard title="Fecha">
                    <TextField>{getDateFormat(order.dateCreated, "DD MMM YYYY hh:mm a")}</TextField>
                </ItemCard>
                {order.dateCreatedCustom && (
                    <ItemCard title="Fecha Personalizada">
                        <TextField>{getDateFormat(order.dateCreatedCustom, "DD MMM YYYY hh:mm:a")}</TextField>
                    </ItemCard>
                )}
                <ItemCard title="Precio">
                    <PriceNameLine defaultPrice={order.defaultPrice} />
                </ItemCard>
                <ItemCard title="Artículos">
                    <TextField>
                        {order.itemsCount} / {order.itemsQuantityCount} Unidades
                    </TextField>
                </ItemCard>
                {warehouseId && (
                    <ItemCard title="Bodega">
                        <TextField>{getWarehouseById(warehouseId)?.name}</TextField>
                    </ItemCard>
                )}
                <ItemCard title="Origen">
                    <TextField>{checkAndGetMessage(intl, messages, order.source) ?? "Sin origen"}</TextField>
                </ItemCard>
                {orderInvoice && (
                    <ItemCard title="Factura">
                        <Flex column>
                            <TextField>{order.eBillingNumber}</TextField>
                            <TextField>{orderInvoice.number}</TextField>
                        </Flex>
                    </ItemCard>
                )}

                {order.paymentTermsDays > 0 && (
                    <ItemCard title="Vencimiento">
                        <TextField>
                            {moment(order.dateCreatedCustom ?? order.dateCreated)
                                .add(order.paymentTermsDays, "days")
                                .format("DD MMM YYYY")}
                        </TextField>
                    </ItemCard>
                )}

                <ItemCard title="Tracking">
                    <TextField>{order.trackingNumber}</TextField>
                </ItemCard>

                {order.totalTaxItems > 0 && (
                    <ItemCard title="Impuestos">
                        <TextField>{formatMoney(order.totalTaxItems)}</TextField>
                    </ItemCard>
                )}
                {order.totalManualPercentageDiscountItems > 0 && (
                    <ItemCard title="Descuento Items">
                        <TextField>{formatMoney(order.totalManualPercentageDiscountItems)}</TextField>
                    </ItemCard>
                )}
                {order.totalDiscount > 0 && (
                    <ItemCard title="Descuento General">
                        <TextField>{formatMoney(order.totalDiscount)}</TextField>
                    </ItemCard>
                )}
                {order.totalTip > 0 && (
                    <ItemCard title="Propina">
                        <TextField>{formatMoney(order.totalTip)}</TextField>
                    </ItemCard>
                )}
            </GridTemplate>

            {props.showDeliveryInfo && <DeliveryInfoCard removeGrid order={order} />}

            <OrderTableView {...props} />
        </Flex>
    );
};

export const MoreDetailsTableView = (props: MoreDetailsViewProps) => {
    const { order } = props;

    const { getUserById } = useAccountUserContext();

    const { orderInvoice, deliveryOrder } = order;
    const user = getUserById(order.accountUserId);

    return (
        <Flex column gap15>
            <Flex spaceBetween>
                <OrderStatusLine status={order.status} text={`#${order.number}`} />
                {orderInvoice && <TextField>{orderInvoice.number}</TextField>}
            </Flex>

            <Table tdPadding={4} tdPaddingRight={10}>
                <tbody>
                    {user && (
                        <tr>
                            <td>Creado</td>
                            <td>
                                <Flex alignCenter gap5>
                                    <FlexImageLetter width={25} text={user?.firstName ?? "-"} image={user?.image} />
                                    <span>{getAccountUserFullName(getUserById(order.accountUserId))}</span>
                                </Flex>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Fecha</td>
                        <td>{getDateFormat(order.dateCreated, "DD MMM YYYY hh:mm a")}</td>
                    </tr>
                    {order.dateCreatedCustom && (
                        <tr>
                            <td>Fecha Personalizada</td>
                            <td>{getDateFormat(order.dateCreatedCustom, "DD MMM YYYY hh:mm:a")}</td>
                        </tr>
                    )}
                    {order.defaultPrice && (
                        <tr>
                            <td>Precio</td>
                            <td>
                                <PriceNameLine defaultPrice={order.defaultPrice} />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Artículos</td>
                        <td>
                            {order.itemsCount} / {order.itemsQuantityCount} Unidades
                        </td>
                    </tr>

                    {order.totalTaxItems > 0 && (
                        <tr>
                            <td>Impuestos</td>
                            <td>{formatMoney(order.totalTaxItems)}</td>
                        </tr>
                    )}
                    {order.totalManualPercentageDiscountItems > 0 && (
                        <tr>
                            <td>Descuento Items</td>
                            <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                        </tr>
                    )}
                    {order.totalDiscount > 0 && (
                        <tr>
                            <td>Descuento General</td>
                            <td>{formatMoney(order.totalDiscount)}</td>
                        </tr>
                    )}
                    {order.totalTip > 0 && (
                        <tr>
                            <td>Propina</td>
                            <td>{formatMoney(order.totalTip)}</td>
                        </tr>
                    )}
                    {props.showDeliveryInfo && deliveryOrder && (
                        <>
                            <tr>
                                <td>
                                    <h4 className="m-0">Envío</h4>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="min-width">Contacto</td>
                                <td>{deliveryOrder.contactName}</td>
                            </tr>
                            <tr>
                                <td>Teléfono</td>
                                <td>{deliveryOrder.phoneNumber}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{props.order.customer?.email}</td>
                            </tr>
                            <tr>
                                <td>Dirección</td>
                                <td>{getDestinationAddress(deliveryOrder)}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </Table>
        </Flex>
    );
};
