import React from "react";
import { TransactionAccountStateProps } from ".";
import { AccountingType } from "../../../../api/models/accounting";
import { getUtcDate } from "../../../../utils";
import customMoment from "../../../../utils/momentFormat/dateFormat";
import ContactSelect from "../../../Contacts/ContactSelect";
import CustomDateInput from "../../../DatePicker/CustomDateInput";
import TextBoxNumeral from "../../../TextBoxNumeral";
import { DefaultTextarea, Flex, TextField } from "../../../_controls";
import GroupButtonRow from "../../../_controls/buttons/GroupButtonRow";
import TransactionAccountConceptSelector from "../TransactionAccountConcepts/TransactionAccountConceptSelector";
import { TransactionAccountTabBody } from "../styled";

export default function Details(props: TransactionAccountStateProps) {
    const { body, setBody } = props;

    const initialDate = React.useMemo(() => {
        return customMoment(getUtcDate(body.createdAt.toString())).startOf("day");
    }, [body.createdAt]);

    return (
        <TransactionAccountTabBody>
            <Flex column gap5>
                <TextField light>Tipo de cuenta</TextField>
                <GroupButtonRow
                    w100
                    disabled={!!body.id}
                    activeOption={body.type}
                    onClickOption={(option) => setBody({ ...body, type: option.value as AccountingType })}
                    options={[
                        { title: "Cuenta por Cobrar", value: "receivable" },
                        { title: "Cuenta por Pagar", value: "payable" },
                    ]}
                />
            </Flex>

            <Flex column gap5>
                <TextField light>Valor de la cuenta</TextField>
                <TextBoxNumeral
                    value={body.totalAmount}
                    className="transaction-amount"
                    onNumberChange={(value) => setBody({ ...body, totalAmount: value })}
                    format={"money"}
                />
            </Flex>

            <Flex column gap5 w100>
                <TextField light>Selecciona el contacto</TextField>
                <ContactSelect
                    w100
                    title="Contacto"
                    hideLabel
                    allowCreate
                    contact={body.contact}
                    onUpdateContact={(value) => {
                        setBody({ ...body, contact: value, contactId: value?.id ?? "" });
                    }}
                    placeholder={"Seleccionar contacto"}
                />
            </Flex>

            <Flex column gap5>
                <TextField small light>
                    Fecha de vencimiento: {customMoment(body.dueDate).format("DD MMM YYYY")}
                </TextField>

                <Flex gap10 alignCenter>
                    <CustomDateInput
                        hideTimeSelector
                        date={customMoment(body.dueDate).toDate()}
                        onDateChange={(value) =>
                            setBody({
                                ...body,
                                dueDate: customMoment(value).startOf("day").format(),
                                paymentTermsDays: Math.abs(initialDate.diff(value, "day")),
                            })
                        }
                    />
                    <TextBoxNumeral
                        rounded
                        className="text-center"
                        value={body.paymentTermsDays}
                        onNumberChange={(days) =>
                            setBody({
                                ...body,
                                dueDate: initialDate.add(days, "days").startOf("day").format(),
                                paymentTermsDays: days,
                            })
                        }
                        format={"number"}
                    />
                    <TextField light>Dias</TextField>
                </Flex>
            </Flex>

            <TransactionAccountConceptSelector
                onChange={(value) => setBody({ ...body, concept: value, conceptId: value?.id ?? "" })}
                title={"Conceptos"}
                defaultValue={body.concept}
            />
            <Flex column gap5>
                <TextField light>Notas</TextField>
                <DefaultTextarea
                    borderRadius={15}
                    rows={4}
                    value={body.notes}
                    placeholder="Notas"
                    onChange={(e) => setBody({ ...body, notes: e.target.value })}
                />
            </Flex>
        </TransactionAccountTabBody>
    );
}
