import React, { useEffect, useState } from "react";
import { WahioFile, WahioFileImpl } from "../../api/models";
import { WahioCloudItem } from "../../api/models/wahioCloud";
import Modal, { IModalShow } from "../modals/Modal";
import { DefaultButton, DefaultInput, PrimaryButton, SquareButton } from "../_controls";
import { getCloudItemExtensionImage } from "./extensionImage";
import { getFileExtensionFromString } from "./helper";
import { CustomFilesContainerView, FileItemRowContainer } from "./styled";
import WahioCloudItemsModal from "./WahioCloudItemsModal";

interface CustomFilesModalModalProps extends IModalShow {
    files: WahioFile[];
    onFilesChanges: (files: WahioFile[]) => void;
    modalTitle: string;
}

export default function CustomFilesModalModal(props: CustomFilesModalModalProps) {
    return (
        <Modal
            scrollInside={true}
            overflowAuto={true}
            id="reportmodalconfig"
            title={props.modalTitle}
            showHeader={true}
            borderRadius={20}
            {...props}
        >
            <CustomFilesView {...props} />
        </Modal>
    );
}

const CustomFilesView = (props: CustomFilesModalModalProps) => {
    const [showWahioCloud, setShowWahioCloud] = useState(false);

    useEffect(() => {
        if (!props.files || props.files.length === 0) {
            setShowWahioCloud(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onItemSelected = (items: WahioCloudItem[]) => {
        let files: WahioFile[] = [];
        items.forEach((item) => {
            let file = new WahioFileImpl(item.url, item.type);
            file.name = item.name;
            files.push(file);
        });
        let existing = props.files ?? [];
        props.onFilesChanges([...existing, ...files]);
    };

    const onFileItemChange = (file: WahioFile) => {
        let files: WahioFile[] = [];
        props.files.forEach((item) => {
            if (item.url === file.url) {
                files.push(file);
            } else {
                files.push(item);
            }
        });
        props.onFilesChanges(files);
    };

    const onRemoveFile = (file: WahioFile) => {
        props.onFilesChanges(props.files.filter((x) => x.url !== file.url));
    };

    return (
        <CustomFilesContainerView>
            {showWahioCloud && (
                <WahioCloudItemsModal
                    show={showWahioCloud}
                    setShow={setShowWahioCloud}
                    onClickItems={onItemSelected}
                    allowSelectionMultiple
                    allowSelection
                />
            )}
            <div className="container-list">
                {props.files.map((item, index) => (
                    <CustomFileRow onRemove={onRemoveFile} onUpdate={onFileItemChange} item={item} key={index} />
                ))}
            </div>
            <div className="header">
                <DefaultButton className="mr-1" rounded onClick={() => setShowWahioCloud(!showWahioCloud)}>
                    <span className="wahioicon-plus"></span> Agrear archivos
                </DefaultButton>
                <PrimaryButton rounded onClick={() => props.setShow(false)}>
                    Guardar
                </PrimaryButton>
            </div>
        </CustomFilesContainerView>
    );
};

interface CustomFileRowProps {
    item: WahioFile;
    onUpdate: (value: WahioFile) => void;
    onRemove: (value: WahioFile) => void;
}

const CustomFileRow = (props: CustomFileRowProps) => {
    const { item } = props;
    const extension = getFileExtensionFromString(item.url);
    const useUrl = item.type === "image" && extension !== "svg";
    const image = useUrl ? item.url : getCloudItemExtensionImage(extension);
    const fileName = item.url.replace(/^.*[\\/]/, "");

    return (
        <FileItemRowContainer className="file-item-row">
            <div className="image-container">
                <div className={`image-cloud ${!useUrl ? "small" : ""}`}>
                    <img src={image} alt={fileName} />
                </div>
            </div>
            <div className="body-cloud">
                <DefaultInput value={item.name} onChange={(e) => props.onUpdate({ ...item, name: e.target.value })} placeholder="Nombre" />
                <span className="filename">{fileName}</span>
            </div>
            <div className="delete-view">
                <SquareButton onClick={() => props.onRemove(item)}>
                    <span className="wahioicon-trash"></span>
                </SquareButton>
            </div>
        </FileItemRowContainer>
    );
};
