import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { OrganizationContext } from "../../store/contexts/OrganizationContext";
import { UserContext } from "../../store/contexts/UserContext";
import EmptyUserStores from "../EmptyResultViews/EmptyUserStores";
import messages from "./messages";

import { accountUserIsAdmin } from "../../api/models/accountUser";
import { IOrganization } from "../../api/models/organizationTypes";
import { Flex } from "../_controls";
import { DefaultButton } from "../_controls/buttons/index";
import ModalStoreForm from "./StoreFormModal";
import StoreSelectMainCard from "./StoreSelectMainCard";
import { StoresMainContainer } from "./styled";

interface IOrganizationListProps {
    limit?: number;
    filter?: string;
}

export default function StoreSelectMain(props: IOrganizationListProps) {
    const intl = useIntl();
    const { organizationState } = useContext(OrganizationContext);
    const [stores, setStores] = useState<IOrganization[]>();
    const [showCreateModalStore, setShowCreateModalStore] = useState(false);
    const { userState } = useContext(UserContext);
    const account = userState.user?.account;

    const { filter } = props;

    useEffect(() => {
        let items = organizationState?.items;
        if (items.length > 0) {
            setStores(organizationState.items);
        } else if (props.limit && items.length > props.limit) {
            setStores(items.slice(0, props.limit));
        } else if (filter && items.length > 0) {
            setStores(items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase())));
        } else {
            setStores(undefined);
        }
    }, [organizationState, filter, props.limit]);

    const userIsAdmin = accountUserIsAdmin(userState.user);

    return (
        <StoresMainContainer>
            {stores && stores !== undefined ? (
                <>
                    <div className="store-header">
                        <div className="image">
                            {account?.companyProfileImage ? (
                                <img src={account.companyProfileImage} alt={account.companyName} />
                            ) : (
                                <span>{account?.companyName?.substring(0, 1)}</span>
                            )}
                        </div>
                        <h3 className="title">
                            {intl.formatMessage(messages.hello)} {userState.user?.firstName}, {intl.formatMessage(messages.newShop)}
                        </h3>
                    </div>
                    <div className="sub-container">
                        {stores &&
                            stores.map((item, index) => {
                                return <StoreSelectMainCard key={index} organization={item} />;
                            })}
                    </div>
                </>
            ) : (
                <>
                    <EmptyUserStores
                       userIsAdmin={userIsAdmin}
                    />
                    {userIsAdmin && (
                        <Flex justifyCenter alignCenter>
                            <DefaultButton onClick={() => setShowCreateModalStore(true)} rounded>
                                Crear tienda
                            </DefaultButton>
                            <ModalStoreForm show={showCreateModalStore} setShow={setShowCreateModalStore} />
                        </Flex>
                    )}
                </>
            )}
        </StoresMainContainer>
    );
}
