import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getServiceMessageError } from "../../api";
import { getUserPermissions } from "../../api/models/accountUser";
import { Order } from "../../api/models/order";
import { OrderStatus } from "../../api/models/orderBase";
import { useOrderStatusMutation } from "../../api/orders/hooks";
import { OrderNotificationData } from "../../api/socket/models";
import { useAppSettingsContext } from "../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../auth";
import PATHS from "../../constants/paths";
import { notificationAudio } from "../../pages/AppContainer";
import { useUserContext } from "../../store/contexts/UserContext";
import LoadingDualRing from "../LoadingDualRing";
import { DefaultButton, PrimaryButton } from "../_controls";
import { useAlert } from "./Alert";
import { NotificationContainer, NotificationSuccessContainer } from "./templateStyled";

export default function OrderAlert(props: OrderNotificationData) {
    const [redirect, setRedirect] = useState(false);
    const { appSettingsState } = useAppSettingsContext();
    const { userState } = useUserContext();
    const alert = useAlert();

    const orderMutation = useOrderStatusMutation();

    useEffect(() => {        
        if (!appSettingsState.turnOfNotifications) {
            notificationAudio.play();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (redirect) {
        if (props.close) props.close();
        return <Navigate to={PATHS.orderShipmentProfile(props.orderId)} />;
    }

    const handleOrderCreditStatus = (status: OrderStatus) => {
        let body = {
            status,
            orderId: props.orderId,
            accountUserId: getAuthBody().accountUserId,
            organizationId: appSettingsState.defaultOrganizationId,
        };

        orderMutation
            .updateOrderStatusAsync(body)
            .then((result) => {
                if (props.close) props.close();
                alert.success("Pedido actualizado con éxito");
            })
            .catch((error) => {
                alert.error(getServiceMessageError(error));
            });
    };

    let moreProducts = "";
    if (props.totalItems > 1) {
        moreProducts = ", más otros productos";
    }

    return (
        <NotificationContainer>
            <div className="notification-icon">{/* <span className="wahioicon-shopping-basket"></span> */}</div>
            <div className="FlexCol body">
                {props.userName && (
                    <div className="flex-row cursor" onClick={() => setRedirect(true)}>
                        <div className="image small" title={props.userName}>
                            {props.userImage ? (
                                <img src={props.userImage} alt={props.userName} />
                            ) : (
                                <span>{props.userName.substring(0, 1)}</span>
                            )}
                        </div>
                        <span className="title">{props.userName} a creado un pedido</span>
                    </div>
                )}
                {props.appOrigin === "ecommerce" && (
                    <div className="FlexCol cursor" onClick={() => setRedirect(true)}>
                        <span className="title">Ecommerce: {props.ecommerce?.ecommerceName}</span>
                        <span>{props.ecommerce?.contactName} ha creado un nuevo pedido</span>
                    </div>
                )}
                <div className="flex-row mt-1 cursor" onClick={() => setRedirect(true)}>
                    {props.firstProduct.image && (
                        <div className="image square">
                            <img src={props.firstProduct.image} alt={props.firstProduct.name} title={props.firstProduct.name} />
                        </div>
                    )}
                    <div className="FlexCol content">
                        <span>
                            {props.firstProduct.quantity} {props.firstProduct.name}
                            {moreProducts}
                        </span>
                        <span>
                            Order #{props.orderNumber}, Items: {props.totalItems}
                        </span>
                        <span>Enviar a: {props.address}</span>
                    </div>
                </div>
                {props.orderStatus === "queued" && getUserPermissions(userState.user).order?.canConfirm && (
                    <>
                        {orderMutation.orderStatusMutation.isLoading ? (
                            <LoadingDualRing small center />
                        ) : (
                            <div className="flex-row actions">
                                    <DefaultButton small className="mr-1" borderRadius={10} onClick={() => setRedirect(true)}>
                                    Abrir
                                </DefaultButton>
                                    <PrimaryButton small borderRadius={10} onClick={() => handleOrderCreditStatus("credit")}>
                                    Confirmar
                                </PrimaryButton>
                            </div>
                        )}
                    </>
                )}
            </div>
        </NotificationContainer>
    );
}
interface OrderSuccessAlertProps {
    order: Order;
    close?: () => void;
}
export function OrderSuccessAlert(props: OrderSuccessAlertProps) {
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        if (props.close) props.close();
        return <Navigate to={PATHS.orderShipmentProfile(props.order.id)} />;
    }

    return (
        <NotificationSuccessContainer onClick={() => setRedirect(true)}>
            <div className="panel-icon">
                <span className="wahioicon-check"></span>
            </div>
            <div className="body">
                <span>Order creada correctamente</span>
                <span className="bold">Orden #{props.order.number}</span>
            </div>
        </NotificationSuccessContainer>
    );
}
