import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { getAccountUserFullName } from "../../../api/models/accountUser";
import { Order } from "../../../api/models/order";
import { orderStatusListWithoutInvoice } from "../../../api/models/orderBase";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { getDateFormat } from "../../../utils";
import { ContactProfileButtonModal } from "../../Contacts/ContactProfile";
import { Flex } from "../../_controls";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import OrderStatusLine from "../OrderStatusLine";
import shipmentMessages from "../Shipments/messages";
import OrderTrackingNumber from "./OrderTrackingNumber";
import messages from "./messages";
import { TableDetails } from "./styled";

interface GeneralDetailsProps {
    currentOrder: Order;
}

export default function GeneralDetails(props: GeneralDetailsProps) {
    const intl = useIntl();
    const { currentOrder } = props;
    const { customer } = currentOrder;
    const { getUserById } = useAccountUserContext();

    const user = getUserById(currentOrder.accountUserId);

    return (
        <Flex column gap15>
            <TableDetails cellSpacing={0} cellPadding={0} tdPadding={4} tdPaddingRight={15} hideBorde>
                <tbody>
                    <tr>
                        <td>Estatus</td>
                        <td>
                            <OrderStatusLine size="sm" status={currentOrder.status} />
                        </td>
                    </tr>
                    {currentOrder.accountUserId && (
                        <tr>
                            <td className="min-width">
                                <span>Creado</span>
                            </td>
                            <td>
                                <Flex alignCenter gap5>
                                    <FlexImageLetter width={25} text={user?.firstName ?? "-"} image={user?.image} />
                                    <span>{getAccountUserFullName(getUserById(currentOrder.accountUserId))}</span>
                                </Flex>
                            </td>
                        </tr>
                    )}
                    {currentOrder.contactSeller && (
                        <tr>
                            <td>
                                <span>{intl.formatMessage(messages.seller)} </span>
                            </td>
                            <td>
                                <ContactProfileButtonModal contact={currentOrder.contactSeller} />
                            </td>
                        </tr>
                    )}
                    {customer && (
                        <tr>
                            <td>
                                <span>{intl.formatMessage(messages.customer)}</span>
                            </td>
                            <td>
                                <ContactProfileButtonModal contact={customer} />
                            </td>
                        </tr>
                    )}
                    {currentOrder.orderInvoice && !orderStatusListWithoutInvoice.includes(currentOrder.status) && (
                        <tr>
                            <td>
                                <span>Factura</span>
                            </td>
                            <td>{currentOrder.orderInvoice.number}</td>
                        </tr>
                    )}
                    {currentOrder.eBillingNumber && (
                        <tr>
                            <td>
                                <span>F.Electronica</span>
                            </td>
                            <td>{currentOrder.eBillingNumber}</td>
                        </tr>
                    )}
                    {currentOrder.orderChannel && (
                        <tr>
                            <td>
                                <span>{intl.formatMessage(messages.channel)}</span>
                            </td>
                            <td>
                                <span> {currentOrder.orderChannel.name}</span>
                            </td>
                        </tr>
                    )}
                    {currentOrder.status !== "paid" && !!currentOrder.paymentTermsDays && (
                        <>
                            <tr>
                                <td>
                                    <span>Vence</span>
                                </td>
                                <td>
                                    <span>
                                        {moment(currentOrder.dateCreatedCustom ?? currentOrder.dateCreated)
                                            .add(currentOrder.paymentTermsDays, "days")
                                            .format("LL")}
                                    </span>
                                </td>
                            </tr>
                        </>
                    )}
                    {currentOrder.trackingNumber && (
                        <tr>
                            <td>
                                <span>Tracking</span>
                            </td>
                            <td>
                                <OrderTrackingNumber trackingNumber={currentOrder.trackingNumber} />
                            </td>
                        </tr>
                    )}
                    {currentOrder.deliveryOrder && (
                        <tr>
                            <td>
                                <span>Pedido</span>
                            </td>
                            <td>{intl.formatMessage(shipmentMessages[currentOrder.deliveryOrder.status])}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Creado</td>
                        <td>{getDateFormat(currentOrder.dateCreated, "DD MMM YYYY hh:mm:a")}</td>
                    </tr>

                    {currentOrder.dateCreatedCustom && (
                        <tr>
                            <td>FechaPersonalizada</td>
                            <td>{getDateFormat(currentOrder.dateCreatedCustom, "DD MMM YYYY hh:mm:a")}</td>
                        </tr>
                    )}
                </tbody>
            </TableDetails>
        </Flex>
    );
}
