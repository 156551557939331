import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectOptionObject } from "../../../api/models";
import { CashRegisterType, ICashRegister } from "../../../api/models/cashRegister";
import { useCashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { SelectBody } from "../../Select/styled";
import { TextField } from "../../_controls";

interface CashRegisterSelectProps {
    defaultCashRegisterId?: string;
    onCashRegisterChange: (value: ICashRegister) => void;
    hideTitle?: boolean;
    small?: boolean;
    title?: string;
    typeFilter?: CashRegisterType;
    alternateSelector?: boolean;
    disabled?: boolean;
    hideIndicator?: boolean;
    readonly?: boolean;
}

export default function CashRegisterOrganizationSelect(props: CashRegisterSelectProps) {
    const [selectedCashRegister, setSelectedCashRegister] = useState<ICashRegister>();
    const [items, setItems] = useState<ICashRegister[]>([]);
    const { cashRegisterState } = useCashRegisterContext();

    useEffect(() => {
        if (props.typeFilter) {
            setItems(cashRegisterState.orgItems.filter((x) => x.type === props.typeFilter));
        } else {
            setItems(cashRegisterState.orgItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRegisterState.orgItems, props.typeFilter]);

    useEffect(() => {
        if (props.defaultCashRegisterId && items.length > 0) {
            const cashRegister = items.find((x) => x.id === props.defaultCashRegisterId);
            setSelectedCashRegister(cashRegister);
        } else {
            setSelectedCashRegister(undefined);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.defaultCashRegisterId]);

    const onCashRegisterChange = (cashRegister?: ICashRegister) => {
        if (cashRegister) {
            setSelectedCashRegister(cashRegister);
            props.onCashRegisterChange(cashRegister);
        }
    };

    const getOption = (item: ICashRegister) => {
        let option: SelectOptionObject<ICashRegister> = {
            label: item.name,
            value: item.id ?? "NA",
            object: item,
        };
        return option;
    };

    if (props.readonly) {
        return <TextField>{selectedCashRegister ? selectedCashRegister.name : "Sin Caja"}</TextField>;
    }

    return (
        <SelectBody hideIndicator={props.hideIndicator} menuMinWidth={150} className={props.alternateSelector ? "alternate-selector" : ""}>
            {!props.hideTitle && <span className="label">{props.title ?? "Cajas"}</span>}
            <Select
                placeholder="Caja"
                className={`${props.small ? "select-small" : ""} ${selectedCashRegister ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedCashRegister?.name}
                defaultValue={selectedCashRegister ? getOption(selectedCashRegister) : undefined}
                isDisabled={props.disabled}
                onChange={(value) => onCashRegisterChange(value?.object)}
                isRtl={false}
                isSearchable={true}
                isLoading={cashRegisterState.isFetching}
                name="cashRegister"
                options={items.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
