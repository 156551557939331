import React, { createContext, useState } from "react";
import { useMutation } from "react-query";
import { ProductSearchListState } from ".";
import { IPaginationResponse, PaginationResponse } from "../../../api/models";
import { IProduct } from "../../../api/models/product";
import { IWarehouseProduct, IWarehouseProductSearchModel } from "../../../api/models/warehouseProduct";
import { getProducts, getWarehouseProducts } from "../../../api/products";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";

export interface ProductSearchContextProps {
    productPagination: IPaginationResponse<IProduct>;
    warehouseProductPagination: IPaginationResponse<IWarehouseProduct>;
    isFetching: boolean;
    handleProductSearch: (state: ProductSearchListState) => void;
    hasNextPage: () => boolean;
    fetchNextPage: () => void;
}

const ProductSearchContext = createContext({} as ProductSearchContextProps);

interface ProductSearchContextProviderProps {
    children: React.ReactNode;
}

const ProductSearchContextProvider = (props: ProductSearchContextProviderProps) => {
    const { appSettingsState } = useAppSettingsContext();
    const [productPagination, setProductPagination] = useState<IPaginationResponse<IProduct>>(new PaginationResponse<IProduct>());
    const [warehouseProductPagination, setWarehouseProductPagination] = useState<IPaginationResponse<IWarehouseProduct>>(
        new PaginationResponse<IWarehouseProduct>()
    );

    const lastSearchState = React.useRef<ProductSearchListState>();

    const hasNextPage = () => {
        if (lastSearchState.current?.searchType === "warehouseProduct") {
            return warehouseProductPagination.currentPage < warehouseProductPagination.totalPages;
        } else {
            return productPagination.currentPage < productPagination.totalPages;
        }
    };

    const productMutation = useMutation((data: IWarehouseProductSearchModel) => getProducts(data), {
        onSuccess: (data) => {
            let newPagination = data;
            if (data.currentPage > 1) {
                newPagination = {
                    ...data,
                    items: [...productPagination.items, ...data.items],
                };
            }
            setProductPagination(newPagination);
        },
    });
    const warehouseProductMutation = useMutation((data: IWarehouseProductSearchModel) => getWarehouseProducts(data), {
        onSuccess: (data) => {
            let newPagination = data;
            if (data.currentPage > 1) {
                newPagination = {
                    ...data,
                    items: [...warehouseProductPagination.items, ...data.items],
                };
            }
            setWarehouseProductPagination(newPagination);
        },
    });

    const fetchNextPage = () => {
        if (lastSearchState.current) {
            handleProductSearch({ ...lastSearchState.current, page: lastSearchState.current.page + 1 });
        }
    };

    const handleProductSearch = (state: ProductSearchListState) => {
        if (JSON.stringify(lastSearchState.current) === JSON.stringify(state)) {
            return;
        }

        const searchBody: IWarehouseProductSearchModel = {
            accountId: getAuthBody().accountId,
            currentPage: state.page,
            pageSize: state.pageSize,
            searchValue: state.searchValue?.trim(),
            customCategoryId: state.selectedCategory?.id,
            warehouseId: state.selectedWarehouse?.id ?? "INVALID_ID",
            sort: appSettingsState.productSearchSort ?? "default",
            sortDesc: appSettingsState.productSearchSortDesc,
            status: "active",
            onlyInventoryProducts: state.onlyInventoryProducts,
            inStockOnly: appSettingsState.productSearchOnlyStockProducts,
            minPrice: appSettingsState.productSearchMinPrice ?? 0,
            maxPrice: appSettingsState.productSearchMaxPrice ?? 0,
            minStock: appSettingsState.productSearchMinStock,
            maxStock: appSettingsState.productSearchMaxStock,
            onlyProductWithImage: appSettingsState.productSearchOnlyProductWithImage,
            onlyProductWithoutImage: appSettingsState.productSearchOnlyProductWithoutImage,
            productIdsExcluded: state.hideThisProductIds,
        };

        if (state.searchType === "warehouseProduct") {
            warehouseProductMutation.mutate(searchBody);
        } else {
            productMutation.mutate(searchBody);
        }

        lastSearchState.current = state;
    };

    const isFetching = productMutation.isLoading || warehouseProductMutation.isLoading;

    return (
        <ProductSearchContext.Provider
            value={{ productPagination, warehouseProductPagination, isFetching, handleProductSearch, hasNextPage, fetchNextPage }}
        >
            {props.children}
        </ProductSearchContext.Provider>
    );
};

export const useProductSearchContext = () => {
    return React.useContext(ProductSearchContext);
};

export default ProductSearchContextProvider;
