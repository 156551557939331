import styled from "styled-components";

export const RejectOrderFormContainer = styled.div`
    padding: 15px 22px;
    display: flex;
    flex-direction: column;
    .payment-body {
        margin-top: 15px;
    }

    .small-text {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
