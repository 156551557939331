import { formatMoney } from "..";
import { OrderItem } from "../../api/models/order";

export const getItemManualDiscount = (saleItem: OrderItem, hiddeValue: boolean = true) => {
    if (saleItem.manualPercentageDiscount > 0) {
        let discount = (saleItem.unitPrice * saleItem.quantity * saleItem.manualPercentageDiscount) / 100;
        return ` - ${formatMoney(discount)}  (${saleItem.manualPercentageDiscount}%)`;
    }
    return "0%";
};

export const getItemTaxPercentage = (saleItem: OrderItem) => {
    let taxPer = 0;
    if (saleItem.taxes && saleItem.taxes.length > 0) {
        saleItem.taxes.forEach((itemTax) => {
            taxPer += itemTax.taxPercentage;
        });
    }
    return taxPer;
};
