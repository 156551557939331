import styled from "styled-components";
import { device } from "../../../theme/themeProvider";

export const ContactMainContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .subtitle {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
    }
`;

export const ContactTypeCard = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    border: 0px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 8px 13px;
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.textLight};
    cursor: pointer;

    ${(props: { isActive?: boolean }) => (props.isActive ? "background: #2962FF20;color: #2962FF; border-color: #2962FF; " : "")}

    .icon {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
`;

export const AddressContainerFormView = styled.form`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    input {
        width: 100%;
    }
`;

export const InsideDropdownList = styled.div`
    display: flex;
    .dropbtn {
        border: 0px;
        height: 100%;
        border-radius: 3px 0px 0px 3px;
        border-right: 1px solid ${(props) => props.theme.palette.backgroundDark};
    }
    .dropdownvalue {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const PhoneRowItem = styled.li`
    display: flex;
    .dialcode {
        width: 39px;
        margin-left: auto;
    }
`;

export const AddressLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0 10px;

    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    :last-child {
        border-bottom: 0;
    }

    .actions {
        display: flex;
        margin-left: 5px;
    }

    .close-modal-panel {
        display: flex;
        justify-content: flex-end;
    }
`;

export const InputDirections = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 13px;
    padding: 10px 5px;
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    padding-left: 10px;
    align-items: center;

    @media ${device.sm} {
        font-size: 15px;
    }
`;

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .label {
        font-size: 13px;
        margin-bottom: 4px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const LabelOptionContact = styled.label`
    color: ${(props) => props.theme.palette.textLight};
    font-size: 13px;
    margin-bottom: 3px;
`;

export const FinalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 6px;
    .icon {
        font-size: 2rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const HeaderOverFlowContent = styled.div`
    display: flex;
    gap: 10px;
    overflow: auto;
    align-items: center;

    .arrow {
        position: absolute;
        color: ${(props) => props.theme.palette.primary};
        display: flex;
        align-items: center;
        width: fit-content;
        background: transparent;
        padding: 0px 10px;
    }

    .right {
        right: 8px;
        justify-content: end;
        cursor: pointer;
        padding: 10px;
        border-radius: 50%;
        background: linear-gradient(
            90deg,
            ${(props) => props.theme.palette.background}90 0%,
            ${(props) => props.theme.palette.background} 32%,
            ${(props) => props.theme.palette.background} 100%
        );
    }
`;
