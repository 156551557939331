import { defineMessages } from "react-intl";

const base = "constants.messages";

export default defineMessages({
    notColor: {
        id: `${base}.notColor`,
        defaultMessage: "Sin Color",
    },
    red: {
        id: `${base}.red`,
        defaultMessage: "Rojo",
    },
    pink: {
        id: `${base}.pink`,
        defaultMessage: "Rosa",
    },
    deepPurple: {
        id: `${base}.deepPurple`,
        defaultMessage: "Morado Oscuro",
    },
    purple: {
        id: `${base}.purple`,
        defaultMessage: "Morado",
    },
    gold: {
        id: `${base}.gold`,
        defaultMessage: "Gold",
    },
    indigo: {
        id: `${base}.indigo`,
        defaultMessage: "Índigo",
    },
    blue: {
        id: `${base}.blue`,
        defaultMessage: "Azul",
    },
    lightBlue: {
        id: `${base}.lightBlue`,
        defaultMessage: "Azul Claro",
    },
    cyan: {
        id: `${base}.cyan`,
        defaultMessage: "Cian",
    },
    teal: {
        id: `${base}.teal`,
        defaultMessage: "Verde Azulado",
    },
    green: {
        id: `${base}.green`,
        defaultMessage: "Verde",
    },
    lightGreen: {
        id: `${base}.lightGreen`,
        defaultMessage: "Verde Claro",
    },
    yellow: {
        id: `${base}.yellow`,
        defaultMessage: "Amarillo",
    },
    orange: {
        id: `${base}.orange`,
        defaultMessage: "Naranja",
    },
    deepOrange: {
        id: `${base}.deepOrange`,
        defaultMessage: "Naranja Oscuro",
    },
    brown: {
        id: `${base}.brown`,
        defaultMessage: "Café",
    },
    black: {
        id: `${base}.black`,
        defaultMessage: "Negro",
    },
});
