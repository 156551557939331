import React from "react";
import { getDateFormat } from "../../../utils";
import LoadingDualRing from "../../LoadingDualRing";
import { Flex, FlexImageStyle, SquareButton } from "../../_controls";
import TextareaChat from "./TextareaChat";
import { ChatMessageCardView } from "./styled";

interface ChatConversationBaseProps {
    headerImage: any | string;
    headerTitle: string;
    onRemoveChat: () => void;
    children: React.ReactNode;
    isLoading: boolean;
    onClickSendMessage: (text: string) => void;
}

export default function ChatConversationBase(props: ChatConversationBaseProps) {
    return (
        <ChatMessageCardView>
            <Flex className="header" alignCenter gap10 spaceBetween>
                <Flex alignCenter gap10>
                    <FlexImageStyle width={30} borderRadius={30} bgLight>
                        {props.headerImage ? <img src={props.headerImage} alt="" /> : <span className="wahioicon-user"></span>}
                    </FlexImageStyle>
                    <span>{props.headerTitle}</span>
                </Flex>
                <SquareButton className="btn-remove" onClick={props.onRemoveChat}>
                    <span className="wahioicon-times"></span>
                </SquareButton>
            </Flex>
            <Flex className="message-bubble-container">
                {props.isLoading && <LoadingDualRing center />}
                {props.children}
            </Flex>
            <TextareaChat autofocus onSendMessage={props.onClickSendMessage} />
        </ChatMessageCardView>
    );
}

export type ChatContentPosition = "left" | "right";
interface ChatContentProps {
    spaceTop: boolean;
    position: ChatContentPosition;
    userName?: string;
    userColor?: string;
    description: string;
    createdAt: Date | string;
    nextCreatedAt?: Date | string;
    hasMoreItems: boolean;
}
let dateFormatComp = "DDMMYYYY";

var parse = require("html-react-parser");

export const ChatContent = (props: ChatContentProps) => {
    const { spaceTop, position, hasMoreItems } = props;

    let dateStr = "";

    if (
        hasMoreItems &&
        props.nextCreatedAt &&
        getDateFormat(props.createdAt, dateFormatComp) !== getDateFormat(props.nextCreatedAt, dateFormatComp)
    ) {
        dateStr = getDateFormat(props.createdAt, dateFormatComp);
    }

    function replaceUrl(text: string) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url) => {
            return '<a target="_blank" href="' + url + '">' + url + "</a>";
        });
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    const getDescription = () => {
        let text = replaceUrl(props.description);
        text = text.replace(/[@][a-z0-9_]+/g, (value) => '<span class="match">' + value + "</span>");
        text = text.replace(
            /[#][a-z0-9_]+/g,
            (value) => '<span id="MATCH_HASHTAG" class="match pointer" value="' + value + '">' + value + "</span>"
        );
        return text;
    };

    return (
        <>
            <div className={`message-bubble ${position} ${spaceTop ? "mb-1" : ""}`}>
                <div className="content">
                    {props.userName && (
                        <>
                            <span className={`user ${props.userColor ?? ""}`} style={{ color: props.userColor }}>
                                {props.userName}
                            </span>
                        </>
                    )}
                    <p className="description">
                        {parse(getDescription())}
                        <span className="message-time">{getDateFormat(props.createdAt, "hh:mm A")}</span>
                    </p>
                </div>
            </div>
            {dateStr && (
                <div className="message-date">
                    <span className="date">{getDateFormat(props.createdAt, "ddd DD MMM")}</span>
                </div>
            )}
        </>
    );
};
