import React from "react";
import Modal, { IModalShow } from "../../modals/Modal";
import ExcelReader, { ExcelReaderProps } from ".";
import { Flex } from "../../_controls";

interface ExcelImportModalProps extends ExcelReaderProps, IModalShow {
    modalTitle: string;
}

export default function ExcelImportModal(props: ExcelImportModalProps) {
    return (
        <Modal sizeType="lg" scrollInside overflowAuto title={props.modalTitle} showHeader {...props}>
            <Flex padding={20}>
                <ExcelReader {...props} />
            </Flex>
        </Modal>
    );
}
