import { wahioFetch } from "..";
import wahioFetchApi from "../fetchApi";
import { IPaginationResponse } from "../models";
import { GroupItem } from "../models/_statistics";
import {
    CashRegisterEntryCategory,
    CashRegisterEntrySearchGroupModel,
    CashRegisterEntrySearchModel,
    CashRegisterEntrySummaryPairResult,
    CashRegisterMovementInput,
    CashRegisterMovementResponse,
    CashRegisterPartialBody,
    CashRegisterPartialUpdate,
    CashRegisterRecalculateResponse,
    GroupItemPair,
    ICashRegister,
    ICashRegisterEntry,
} from "../models/cashRegister";
import {
    CashRegisterLog,
    CashRegisterLogCreateInput,
    CashRegisterLogEntriesSearch,
    CashRegisterLogFinishInput,
    CashRegisterLogUpdateInput,
    ICashRegisterLogSearch,
} from "../models/cashRegisterLog";
import { accountEndpoint } from "../restApiEndpoint";

export const deleteCashRegister = (cashRegisterId: string): Promise<ICashRegister> => {
    return wahioFetchApi.delete(accountEndpoint.delete.cashRegisters(cashRegisterId)).then((res: any) => res.data);
};
export const getCashRegister = (cashRegisterId: string): Promise<ICashRegister> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterById(cashRegisterId)).then((res: any) => res.data);
};

export const getCashRegisterLogActive = (cashRegisterId: string): Promise<CashRegisterLog> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterLogActive(cashRegisterId)).then((res: any) => res.data);
};
export const getCashRegisters = (accountId: string): Promise<ICashRegister[]> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisters(accountId)).then((res: any) => res.data);
};

export const createCashRegisterLog = (data: CashRegisterLogCreateInput): Promise<CashRegisterLog> => {
    return wahioFetchApi.put(accountEndpoint.put.putCashRegisterStartLog, data).then((res: any) => res.data);
};

export const getCashRegisterLogs = (data: ICashRegisterLogSearch): Promise<IPaginationResponse<CashRegisterLog>> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterLogSearch, data).then((res: any) => res.data);
};

export const getCashRegisterLog = (id: string): Promise<CashRegisterLog> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterLogById(id)).then((res: any) => res.data);
};

export const getCashRegisterEntries = (body: CashRegisterEntrySearchModel): Promise<IPaginationResponse<ICashRegisterEntry>> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntryQuerySearch, body).then((res: any) => res.data);
};

export const putCashRegisterLogPreview = (body: CashRegisterLogFinishInput): Promise<CashRegisterLog> => {
    return wahioFetchApi.put(accountEndpoint.put.putCashRegisterLogPreview, body).then((res: any) => res.data);
};

export const putCashRegisterLogFinish = (body: CashRegisterLogFinishInput): Promise<CashRegisterLog> => {
    return wahioFetchApi.put(accountEndpoint.put.putCashRegisterLogFinish, body).then((res: any) => res.data);
};

export const putCashRegisterLogUpdate = (body: CashRegisterLogUpdateInput): Promise<CashRegisterLog> => {
    return wahioFetchApi.put(accountEndpoint.put.putCashRegisterLogUpdate, body).then((res: any) => res.data);
};

export const putCashRegisterMovement = (body: CashRegisterMovementInput): Promise<CashRegisterMovementResponse> => {
    return wahioFetchApi.put(accountEndpoint.put.cashRegisterMovement, body).then((res: any) => res.data);
};
export const postCashRegisterEntry = (body: ICashRegisterEntry): Promise<ICashRegisterEntry> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntry, body).then((res: any) => res.data);
};

export const putCashRegisterEntry = (body: ICashRegisterEntry): Promise<ICashRegisterEntry> => {
    return wahioFetchApi.put(accountEndpoint.put.cashRegisterEntry, body).then((res: any) => res.data);
};

export const postCashRegisterEntriesSummaryRange = (body: CashRegisterEntrySearchModel): Promise<GroupItem> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntriesSummaryRange, body).then((res: any) => res.data);
};
export const postCashRegisterEntriesSummaryRangePair = (
    body: CashRegisterEntrySearchModel
): Promise<CashRegisterEntrySummaryPairResult> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntriesSummaryRangePair, body).then((res: any) => res.data);
};

export const postCashRegisterEntriesTypeGroupRange = (body: CashRegisterEntrySearchModel): Promise<GroupItem[]> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntriesTypeGroupRange, body).then((res: any) => res.data);
};
export const postCashRegisterEntriesMethodGroupRange = (body: CashRegisterEntrySearchModel): Promise<GroupItem[]> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntriesMethodGroupRange, body).then((res: any) => res.data);
};

export const postCashRegisterEntriesGroup = (body: CashRegisterEntrySearchGroupModel): Promise<GroupItem[]> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntriesGroup, body).then((res: any) => res.data);
};
export const postCashRegisterEntriesGroupComparison = (body: CashRegisterEntrySearchGroupModel): Promise<GroupItemPair[]> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntriesGroupComparison, body).then((res: any) => res.data);
};

export const postCashRegisterLogGetEntries = (body: CashRegisterLogEntriesSearch): Promise<IPaginationResponse<ICashRegisterEntry>> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterLogGetEntries, body).then((res: any) => res.data);
};

export const getCashRegisterEntryCategories = (accountId: string): Promise<CashRegisterEntryCategory[]> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterEntryCategories(accountId)).then((res: any) => res.data);
};

export const createCashRegisterEntryCategory = (body: CashRegisterEntryCategory): Promise<CashRegisterEntryCategory> => {
    return wahioFetchApi.post(accountEndpoint.post.cashRegisterEntryCategory, body).then((res: any) => res.data);
};

export const getCashRegisterRecalculateAmount = (cashRegisterId: string): Promise<CashRegisterRecalculateResponse> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterRecalculate(cashRegisterId)).then((res: any) => res.data);
};

export const getCashRegisterEntryByReference = (id: string): Promise<ICashRegisterEntry[]> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterEntryByReference(id)).then((res: any) => res.data);
};
export const getCashRegisterEntryByReferencePayment = (id: string): Promise<ICashRegisterEntry[]> => {
    return wahioFetchApi.get(accountEndpoint.get.cashRegisterEntryByReferencePayment(id)).then((res: any) => res.data);
};

export const cashRegisterPartialUpdate = (data: CashRegisterPartialUpdate): Promise<CashRegisterPartialBody> => {
    return wahioFetchApi.patch(accountEndpoint.patch.cashRegisterPartial(data.id), data.body).then((res: any) => res.data);
};

export interface handleDownloadCashRegisterLogEntriesOptions {
    body: CashRegisterLogEntriesSearch;
    onFinish: (items: ICashRegisterEntry[]) => void;
    onFailed: (error: any) => void;
}

export const handleDownloadCashRegisterLogEntries = (
    option: handleDownloadCashRegisterLogEntriesOptions,
    page: number = 1,
    items: ICashRegisterEntry[] = []
) => {
    const body = { ...option.body };
    body.pageSize = 100;
    body.currentPage = page;

    wahioFetch.post(
        accountEndpoint.post.cashRegisterLogGetEntries,
        body,
        (success) => {
            let res: IPaginationResponse<ICashRegisterEntry> = success.data;
            items = [...items, ...res.items];
            console.log("PAGE: ", page, " OF ", res.totalPages);
            if (res.totalPages > page) {
                handleDownloadCashRegisterLogEntries(option, page + 1, items);
            } else {
                option.onFinish(items);
            }
        },
        (error) => {
            option.onFailed(error);
        }
    );
};
