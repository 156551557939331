import React, { useContext } from "react";
import { OrderInvoiceFormatProps } from ".";
import { getAccountUserFullName } from "../../api/models/accountUser";
import { IDENTIFICATION_TYPES } from "../../constants";
import { AccountUserContext } from "../../store/contexts/AccountUserContext";
import { formatMoney } from "../../utils";
import { Flex, TextField } from "../_controls";
import { CardBodySmall, FlexColumn, InvoiceDesignCardSmall, InvoiceTableSmall } from "./styled";

export default function InvoiceFormatRestaurantActive(props: OrderInvoiceFormatProps) {
    const { order, testMode, template } = props;
    const { accountUserState } = useContext(AccountUserContext);

    if (!order) {
        return null;
    }

    const customer = order.customer;

    const getCustomer = () => {
        if (customer) {
            return customer.firstName + " " + customer.lastName;
        }
        return "Cliente General";
    };

    const getCustomerIdentification = () => {
        let type = customer?.identificationType;
        if (customer?.identificationType) {
            type = IDENTIFICATION_TYPES.find((x) => x.id === customer.identificationType)?.value ?? type;
        }
        return type + ": " + customer?.identificationNumber;
    };

    return (
        <InvoiceDesignCardSmall>
            <CardBodySmall>
                {props.image && (
                    <div className="image-container">
                        <div className="image">
                            <img src={props.image} alt="Org" />
                        </div>
                    </div>
                )}
                <Flex alignCenter gap5 column>
                    {template.invoiceTemplateTitle1 && <TextField bold>{template?.invoiceTemplateTitle1}</TextField>}
                    {template.invoiceTemplateTitle2 && <TextField >{template.invoiceTemplateTitle2}</TextField>}
                </Flex>
                <Flex column gap5 marginTop={20}>
                    {props.order?.takeoutOrder &&
                        <Flex fitContent gap10 alignCenter justifyCenter >
                            <TextField bold fontSize={18} className="fa-regular fa-bag-shopping" />
                            <TextField bold>Orden para llevar</TextField>
                        </Flex>
                    }
                    <TextField>Atiende: {getAccountUserFullName(accountUserState.items.find((x) => x.id === order.accountUserId))}</TextField>
                    <TextField bold>Cliente: {getCustomer()}</TextField>
                    {customer?.phoneNumber && <TextField>Teléfono {customer?.phoneNumber ? customer.phoneNumber : " - - -"}</TextField>}
                    {customer?.identificationNumber && <TextField>{getCustomerIdentification()}</TextField>}
                    {customer?.address && <TextField>Dirección: {customer.address}</TextField>}
                </Flex>

                {props.orderTable && (
                    <div className="border mt-1">
                        <TextField>Mesa: {props.orderTable.tableNumber}</TextField>
                    </div>
                )}
                <InvoiceTableSmall className="mt-2" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th>Descripción</th>
                            <th className="right">Total</th>
                        </tr>
                        {order.items &&
                            order.items.map((item, index) => {
                                if (item.product) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="col">
                                                    <span>{item.product.name}</span>
                                                    <span>
                                                        {formatMoney(item.unitPrice)} x {item.quantity}{" "}
                                                        {item.manualPercentageDiscount ? " -" + item.manualPercentageDiscount + "%" : ""}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="right">{formatMoney(item.unitPrice * item.quantity)}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                    </tbody>
                </InvoiceTableSmall>

                <div className="border mt-1">
                    {order.moneyReceived > 0 && (
                        <FlexColumn className="invoice-details">
                            <span>Cantidad recibida: {formatMoney(order.moneyReceived)}</span>
                            <span className="bold">Cambio: {formatMoney(order.moneyReturned)}</span>
                        </FlexColumn>
                    )}
                    <table>
                        <tbody>
                            <tr>
                                <td>Subtotal</td>
                                <td>{formatMoney(order.subTotal)}</td>
                            </tr>
                            <tr>
                                <td>Impuestos</td>
                                <td>{formatMoney(order.totalTaxItems)}</td>
                            </tr>
                            <tr>
                                <td>Descuento</td>
                                <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                            </tr>
                            {order.percentageTip > 0 &&
                                <tr>
                                    <td>Propina {order.percentageTip}%</td>
                                    <td>{formatMoney(order.totalTip)}</td>
                                </tr>
                            }
                            <tr>
                                <td className="bold">TOTAL</td>
                                <td className="bold">{formatMoney(order.totalAmount)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {!testMode && <div className="footer-subtitle mt-1">Software By Wahio, suite.wahio.com</div>}
            </CardBodySmall>
        </InvoiceDesignCardSmall>
    );
}
