import styled from "styled-components";
import { device } from "../../../theme/themeProvider";

export const WarehouseProfileBody = styled.div`
    margin-bottom: 20px;
    padding: 15px 20px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    display: flex;
    align-items: center;
    position: relative;
    .title {
        font-weight: 500;
        font-size: 16px;
        margin: 0px;
    }
`;

export const LocationViewContainer = styled.div`
    width: 100%;
`;

export const ListContainerWarehouse = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 1fr;
    padding: 14px 1.5rem;
    @media ${device.lg} {
        display: grid;
    }
`;

export const WarehouseCardBody = styled.div`
    padding: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.palette.textLight};
    position: relative;

    .editcontainer {
        padding-top: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actions {
            display: flex;
            align-items: center;
        }
    }

    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        font-weight: 500;
        color: ${(props) => props.theme.palette.text};
        font-size: 17px;
        margin-bottom: 5px;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    .small {
        font-size: 13px;
    }
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const WarehouseCardCostView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .line-value {
        font-size: 13px;
        font-weight: 500;
    }
    .green {
        color: #009688;
    }
    .orange {
        color: #ff5722;
    }
`;

export const ListingItemsContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    max-height: 300px;
    background: ${(props) => props.theme.palette.backgroundLight};
    padding: 10px;
    border-radius: 15px;
`;

export const CardTableContainer = styled.div`
    padding: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const SearchLocationHeader = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .checkbox {
        margin-right: 15px;
    }
    .search-input {
        max-width: 180px;
        input {
            border-radius: 50px;
        }
    }
`;

export const HeaderItem = styled.div`
    margin-bottom: 10px;
`;

export const StockView = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-align: right;
    text-align: center;
    background: ${(props) => props.theme.palette.backgroundLight};
    /* border: 1px solid #adadad; */
    width: 60px;
    height: 60px;
    min-width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    .count {
        font-size: 1.5rem;
        font-weight: 500;
        color: ${(props) => props.theme.palette.text};
    }
    .text {
        font-size: 0.7rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
