import React from "react";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { OrderNavigationProps } from ".";
import { getServiceMessageError } from "../../../api";
import { DeliveryStatus, IDeliveryOrder, LocationInfo } from "../../../api/models/deliveryOrder";
import { Order } from "../../../api/models/order";
import { PatchDeliveryOrderBody, patchDeliveryOrder } from "../../../api/orders/deliveryOrder";
import PATHS from "../../../constants/paths";
import { formatMoney, getModelFullName } from "../../../utils";
import { getDestinationAddress, getOriginAddress } from "../../../utils/orders";
import { TransactionAccountProfileModalButton } from "../../AccountingPayments/TransactionAccount/TransactionAccountProfile";
import { useAlert } from "../../Alerts/Alert";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { ProductExceptionFormModal } from "../../Products/ProductExceptionForm";
import { Flex, TextField } from "../../_controls";
import ContainerCollapse from "../../_controls/containers/ContainerCollapse";
import { FlexImageIcon } from "../../_controls/containers/FlexImageLetter";
import { Table } from "../../_controls/tables/styled";
import RouteMap from "./OrderMap/RouteMap";
import OrderSerialsConfig from "./OrderSerialsConfig";
import PickingOrderView from "./PickingOrderView";
import ShipmentView from "./ShipmentView";
import { useDeliveryOrderStatus } from "./hooks";
import { DeliveryInfoGridStyle } from "./styled";

export function NavigationShipmentView(props: OrderNavigationProps) {
    const { order, onOrderChange: onChange } = props;
    const alert = useAlert();
    const { deliveryOrder } = order;

    const deliveryStatusHook = useDeliveryOrderStatus({
        onOrderChange: (data) => {
            onChange(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const updateDeliveryOrder = (deliveryOrder: IDeliveryOrder) => {
        onChange({ ...order, deliveryOrder });
    };

    const handleUpdateShipmentStatus = (pickingId: string, destinationStatus: DeliveryStatus, sourceStatus: DeliveryStatus) => {
        deliveryStatusHook.handleUpdateShipmentStatus(pickingId, destinationStatus, sourceStatus);
    };

    if (!deliveryOrder) return null;

    return (
        <Flex column gap={20}>
            {deliveryStatusHook.showModalErrors && deliveryStatusHook.errorList.length > 0 && (
                <ProductExceptionFormModal
                    show={deliveryStatusHook.showModalErrors}
                    setShow={deliveryStatusHook.setShowModalErrors}
                    items={deliveryStatusHook.errorList}
                />
            )}

            {deliveryOrder && order.status !== "queued" && order.status !== "declined" && (
                <ShipmentView
                    order={order}
                    shipmentStatusFetching={deliveryStatusHook.isLoading}
                    handleUpdateShipmentStatus={handleUpdateShipmentStatus}
                    key={order.status}
                    deliveryOrder={deliveryOrder}
                    onUpdateOrder={updateDeliveryOrder}
                />
            )}
        </Flex>
    );
}

export const NavigationDeliveryInfo = (props: OrderNavigationProps) => {
    const { order, onOrderChange: onChange } = props;
    const { deliveryOrder } = order;
    const allowLogisticService = order?.allowLogisticsService;
    const alert = useAlert();

    const patchDeliveryMut = useMutation((data: PatchDeliveryOrderBody) => patchDeliveryOrder(data));
    const destiny = getDestinationAddress(deliveryOrder);

    const deliveryStatusHook = useDeliveryOrderStatus({
        onOrderChange: (data) => {
            onChange(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const handleUpdateLocation = (location: LocationInfo) => {
        let data: PatchDeliveryOrderBody = {
            id: deliveryOrder?.id ?? "INVALID",
            data: {
                locationLat: location.lat,
                locationLng: location.lng,
                locationInfo: location,
            },
        };
        if (deliveryOrder) {
            props.onOrderChange({
                ...order,
                deliveryOrder: {
                    ...deliveryOrder,
                    locationInfo: location,
                    locationLat: data.data.locationLat ?? 0,
                    locationLng: data.data.locationLng ?? 0,
                },
            });
        }
        patchDeliveryMut.mutate(data);
    };

    return (
        <>
            {deliveryStatusHook.showModalErrors && deliveryStatusHook.errorList.length > 0 && (
                <ProductExceptionFormModal
                    show={deliveryStatusHook.showModalErrors}
                    setShow={deliveryStatusHook.setShowModalErrors}
                    items={deliveryStatusHook.errorList}
                />
            )}
            {deliveryOrder && <DeliveryInfoCard order={props.order} />}
            {!order.allowLogisticsService &&
                deliveryOrder?.status === "processing" &&
                !!order.items?.find((x) => x.product?.includesSerials) && (
                    <ContainerCollapse title="Configurar Seriales" initialStateOpen>
                        <OrderSerialsConfig order={order} onChange={onChange} />
                    </ContainerCollapse>
                )}
            {props.showSimpleView &&
                allowLogisticService &&
                (deliveryOrder?.status === "processing" || deliveryOrder?.status === "picking") && (
                    <ContainerCollapse>
                        <TextField>Para mover al siguiente estado debes completar el Picking: </TextField>
                        <NavLink className="ml-1" to={PATHS.orderPosProfile(order.id)} target="_blank" rel="noopener noreferrer">
                            <TextField small className="wahioicon-external-link-alt" />
                            <TextField className="ml-1" small>
                                Abrir orden
                            </TextField>
                        </NavLink>
                    </ContainerCollapse>
                )}

            {destiny && deliveryOrder && (
                <ContainerCollapse removeAllBorderPadding>
                    <RouteMap location={deliveryOrder?.locationInfo} onLocationChange={handleUpdateLocation} destination={destiny} />
                </ContainerCollapse>
            )}
        </>
    );
};

export const NavigationPicking = (props: OrderNavigationProps) => {
    const { order, onOrderChange } = props;
    const { deliveryOrder } = order;
    const allowLogisticService = order?.allowLogisticsService;
    const alert = useAlert();

    const deliveryStatusHook = useDeliveryOrderStatus({
        onOrderChange: (data) => {
            onOrderChange(data);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const handleUpdateShipmentStatus = (pickingId: string, destinationStatus: DeliveryStatus, sourceStatus: DeliveryStatus) => {
        deliveryStatusHook.handleUpdateShipmentStatus(pickingId, destinationStatus, sourceStatus);
    };

    return (
        <div>
            {!props.showSimpleView && allowLogisticService && deliveryOrder && order.orderPicking && (
                <NavigationCard title="Picking Order">
                    <PickingOrderView
                        key={order.orderPicking.items?.length}
                        onPickingCompleted={() => props.fetchOrder()}
                        onMoveShipmentStatus={(status) => handleUpdateShipmentStatus(deliveryOrder.id ?? "", status, deliveryOrder.status)}
                        order={order}
                    />
                </NavigationCard>
            )}
        </div>
    );
};

interface DeliveryInfoCardProps {
    order: Order;
    removeGrid?: boolean;
    customComp?: React.ReactNode;
}
export const DeliveryInfoCard = (props: DeliveryInfoCardProps) => {
    const { deliveryOrder } = props.order;

    const deliveryCost = React.useMemo(() => {
        if (deliveryOrder?.costItems) {
            const cost = deliveryOrder.costItems.reduce((x, y) => x + y.value, 0);
            return cost;
        }
        return deliveryOrder?.amount;
    }, [deliveryOrder]);

    if (!deliveryOrder) return null;

    const details = deliveryOrder.details ?? {};

    return (
        <DeliveryInfoGridStyle size={300} className={props.removeGrid ? "grid-none" : ""}>
            <ContainerCollapse>
                <TextField bold small>
                    Información de Contacto
                </TextField>
                <Table className="fist-column-light" tdPadding={3} tdPaddingRight={15} hideBorde>
                    <tbody>
                        <tr>
                            <td className="min-width">Contacto</td>
                            <td>{deliveryOrder.contactName}</td>
                        </tr>
                        <tr>
                            <td>Teléfono</td>
                            <td>{deliveryOrder.phoneNumber}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{props.order.customer?.email}</td>
                        </tr>
                        <tr>
                            <td>Dirección</td>
                            <td>{getDestinationAddress(deliveryOrder)}</td>
                        </tr>
                    </tbody>
                </Table>
            </ContainerCollapse>

            <ContainerCollapse>
                <TextField bold small>
                    Detalles de envio
                </TextField>
                <Table className="fist-column-light" tdPadding={3} tdPaddingRight={15} hideBorde>
                    <tbody>
                        <tr>
                            <td>Mensajero</td>
                            <td>
                                <Flex gap5 alignCenter>
                                    {deliveryOrder.courier?.image && <FlexImageIcon width={25} image={deliveryOrder.courier?.image} />}
                                    {deliveryOrder.courier ? getModelFullName(deliveryOrder.courier) : "---"}{" "}
                                </Flex>
                            </td>
                        </tr>
                        <tr>
                            <td>Costo</td>
                            <td>
                                <Flex gap10 alignCenter>
                                    <Flex column>
                                        <TextField bold>{formatMoney(deliveryCost)}</TextField>
                                        {deliveryOrder.excludesCostItemsInOrder && (
                                            <TextField small light>
                                                No Incluido
                                            </TextField>
                                        )}
                                    </Flex>
                                    {deliveryOrder.includesTransactionAccount && (
                                        <TransactionAccountProfileModalButton referenceId={deliveryOrder.orderId} />
                                    )}
                                </Flex>
                            </td>
                        </tr>
                        {deliveryOrder.originCity && (
                            <tr>
                                <td>Despacho</td>
                                <td>{getOriginAddress(deliveryOrder)}</td>
                            </tr>
                        )}
                        {Object.keys(details).map((key, index) => {
                            let value = details[key];
                            if (typeof value === "boolean") {
                                value = value ? "Si" : "No";
                            }
                            return (
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                </tr>
                            );
                        })}

                        {deliveryOrder.isGift && (
                            <>
                                <tr>
                                    <td>
                                        <TextField bold>Entregar a:</TextField>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Contacto</td>
                                    <td>{deliveryOrder.destinationContactName}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono</td>
                                    <td>
                                        {deliveryOrder.destinationPhonePrefix} {deliveryOrder.destinationPhoneNumber}
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
                {props.customComp}
            </ContainerCollapse>
        </DeliveryInfoGridStyle>
    );
};
