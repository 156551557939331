import { Wrapper } from "@googlemaps/react-wrapper";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { GOOGLE_MAP_API_KEY } from "../../../../api/appConfig";
import { LocationInfo } from "../../../../api/models/deliveryOrder";
import { CloudImages } from "../../../../assets";
import Dropdown from "../../../Dropdown";
import { DefaultButton, Flex, SquareButton, TextField } from "../../../_controls";
import Modal from "../../../modals/Modal";
import { MAP_STYLE_DARK } from "../../OrderReadyToShip/ShipMap/Map/mapStyle";
import { DropdownRouteContainer, MapMarkerContainer } from "./styled";

interface RouteMapProps {
    origin: string;
    destination: string;
}

interface MapMarkerProps {
    destination: string;
    location?: LocationInfo;
    onLocationChange: (location: LocationInfo) => void;
    mapHeight?: number;
    iconSize?: number;
    useModalMap?: boolean;
}

export const DEFAULT_MAP_STYLES: google.maps.MapTypeStyle[] = [
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
    },
];

type MapType = "google" | "waze" | "apple";

const getLocationInfo = () => {
    let data: LocationInfo = {
        inputAddress: "",
        formattedAddress: "",
        lat: 0,
        lng: 0,
        type: "",
    };
    return data;
};

export const RouteMap: React.FC<RouteMapProps> = ({ origin, destination }) => {
    const mapRef = useRef<HTMLDivElement>(null);

    const { palette } = useTheme();
    const [currentTheme, setCurrentTheme] = useState(palette.themeName);

    useEffect(() => {
        if (!mapRef.current || currentTheme !== palette.themeName) return;

        const map = new google.maps.Map(mapRef.current, {
            center: { lat: 0, lng: 0 }, // Puedes definir un centro por defecto o tomar el origen
            zoom: 14,
            styles: palette.themeName === "light" ? (DEFAULT_MAP_STYLES as any) : MAP_STYLE_DARK,
        });

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();

        directionsRenderer.setMap(map);
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsRenderer.setDirections(result);

                    const bounds = new google.maps.LatLngBounds();
                    bounds.extend(result.routes[0].overview_path[0]);
                    bounds.extend(result.routes[0].overview_path[result.routes[0].overview_path.length - 1]);
                    map.fitBounds(bounds);
                } else {
                    console.error(`Error fetching directions ${result}`);
                }
            }
        );
        setCurrentTheme(palette.themeName);
    }, [origin, destination, palette.themeName, currentTheme]);

    return <div ref={mapRef} className="map-container" style={{ width: "100%", height: "400px" }} />;
};

const MapMarker = (props: MapMarkerProps) => {
    const { destination } = props;
    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<google.maps.Map>();
    const [locationInfo, setLocationInfo] = useState<LocationInfo>(props.location || getLocationInfo());

    const [showModalMap, setShowModalMap] = useState(false);

    const { palette } = useTheme();
    const [currentTheme, setCurrentTheme] = useState(palette.themeName);

    const addMarkerFromAddress = (address: string, icon: string) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && mapInstance.current) {
                const position = results[0].geometry.location;

                const result = results[0];

                const location: LocationInfo = {
                    lat: position.lat(),
                    lng: position.lng(),
                    type: result.geometry.location_type,
                    formattedAddress: result.formatted_address,
                    inputAddress: address,
                };

                setLocationInfo(location);
                if (props.onLocationChange) {
                    props.onLocationChange(location);
                }

                addMarker(location, icon);
            } else {
                console.error(`Geocode was not successful for address ${address}. Reason:`, status);
            }
        });
    };

    const addMarker = (location: LocationInfo, icon: string) => {
        mapInstance.current?.setCenter({ lat: location.lat, lng: location.lng });

        const iconSize = props.iconSize || 40;
        new google.maps.Marker({
            map: mapInstance.current,
            position: {
                lat: location.lat,
                lng: location.lng,
            },
            icon: {
                url: icon,
                scaledSize: new google.maps.Size(iconSize, iconSize),
            },
        });
    };

    useEffect(() => {
        if (mapRef.current) {
            if (!mapInstance.current || currentTheme !== palette.themeName) {
                const map = new google.maps.Map(mapRef.current, {
                    center: { lat: 0, lng: 0 }, // Puedes definir un centro por defecto o tomar el origen
                    zoom: 16,
                    zoomControl: false, // Oculta el control de zoom
                    mapTypeControl: false, // Oculta el control de tipo de mapa (Satélite, Mapa, etc.)
                    scaleControl: false, // Oculta el control de escala
                    streetViewControl: false, // Oculta el botón de Street View
                    rotateControl: false, // Oculta el control de rotación
                    fullscreenControl: false, // Oculta el botón de pantalla completa
                    styles: palette.themeName === "light" ? (DEFAULT_MAP_STYLES as any) : MAP_STYLE_DARK,
                    clickableIcons: false,
                });

                mapInstance.current = map;
            }

            if (props.location && props.location.inputAddress === destination) {
                setLocationInfo(props.location);
                addMarker(props.location, "https://stowahioimagelibrary.blob.core.windows.net/map/map-point.png");
            } else {
                addMarkerFromAddress(destination, "https://stowahioimagelibrary.blob.core.windows.net/map/map-point.png");
            }
            if (currentTheme !== palette.themeName) setCurrentTheme(palette.themeName);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destination, props.location, palette.themeName]);

    if (props.useModalMap) {
        return (
            <>
                <Modal show={showModalMap} setShow={setShowModalMap} useButtonClose sizeType="md">
                    <Flex gap10 column padding={20}>
                        <TextField bold>{props.destination}</TextField>
                        <MapView {...props} ref={mapRef} locationInfo={locationInfo} />
                    </Flex>
                </Modal>
                {props.useModalMap && (
                    <SquareButton borderRadius={10} bgLight onClick={() => setShowModalMap(true)}>
                        <i className="fa-regular fa-location-dot"></i>
                    </SquareButton>
                )}
            </>
        );
    }

    return (
        <>
            <MapView {...props} ref={mapRef} locationInfo={locationInfo} />
        </>
    );
};

interface MapViewProps extends MapMarkerProps {
    locationInfo: LocationInfo;
}

export const getMapsDirectionUrl = (mapType: MapType, locationInfo: LocationInfo) => {
    const { lat, lng } = locationInfo;
    switch (mapType) {
        case "google":
            return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        case "apple":
            return `http://maps.apple.com/?daddr=${lat},${lng}`;
        case "waze":
            return `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;
        default:
            return "";
    }
};

export const DropdownMapRoute = ({ locationInfo }: { locationInfo: LocationInfo }) => {
    return (
        <DropdownRouteContainer className="route-container">
            <Dropdown title="Ver Rutas" btnClassName="btn-route-dropdown" iconPosition="left" icon="fa-regular fa-route">
                <Flex gap10 column padding={15}>
                    <a href={getMapsDirectionUrl("google", locationInfo)} target="_blank" rel="noreferrer">
                        <DefaultButton className="btn-map">
                            <img width={30} src={CloudImages.mapGoogle} alt="" /> Abrir en Google Maps
                        </DefaultButton>
                    </a>
                    <a href={getMapsDirectionUrl("waze", locationInfo)} target="_blank" rel="noreferrer">
                        <DefaultButton className="btn-map">
                            <img width={30} src={CloudImages.mapWaze} alt="" /> Abrir en Waze
                        </DefaultButton>
                    </a>
                    <a href={getMapsDirectionUrl("apple", locationInfo)} target="_blank" rel="noreferrer">
                        <DefaultButton className="btn-map">
                            <img width={30} src={CloudImages.mapApple} alt="" /> Abrir en Apple Maps
                        </DefaultButton>
                    </a>
                </Flex>
            </Dropdown>
        </DropdownRouteContainer>
    );
};

const MapView = React.forwardRef((props: MapViewProps, ref: React.Ref<HTMLDivElement>) => {
    const { locationInfo } = props;
    const hasLocation = locationInfo.lat !== 0 && locationInfo.lng !== 0;

    let mapStyle: CSSProperties = {};
    if (props.mapHeight) {
        mapStyle.height = props.mapHeight + "px";
    }

    return (
        <>
            <MapMarkerContainer>
                <div ref={ref} className="map-container" style={mapStyle} />
                {hasLocation && <DropdownMapRoute locationInfo={locationInfo} />}
            </MapMarkerContainer>
        </>
    );
});

const RouteMapWrapper = (props: MapMarkerProps) => {
    return (
        <Wrapper apiKey={GOOGLE_MAP_API_KEY}>
            <MapMarker {...props} />
        </Wrapper>
    );
};

export default RouteMapWrapper;
