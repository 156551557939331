import { useContext } from "react";
import { AppSettingsContext } from "../appSettings/AppSettingsContext";
import { getDefaultAppSettings } from "../appSettings/types";

export const LocalStoreKeys = {
    appSettings: "appSettings",
    todo: "todos",
    accountUser: "accountUser",
    product: "product",
    warehouseProduct: "warehouseProduct",
    organization: "organization",
    currentSale: "currentSale",
    currentPurchase: "currentPurchase",
    productCategory: "productCategory",
    seller: "seller",
    contacts: "contacts",
    warehouse: "warehouse",
    sale: "sale",
    lounge: "lounge",
    localInfoOrder: "localInfoOrder",
    purchase: "purchase",
    supplier: "supplier",
    customCategory: "customCategory",
    productCompany: "productCompany",
    productCatalog: "productCatalog",
    invoiceDesign: "invoiceDesign",
    inventoryAdjustment: "inventoryAdjustment",
    tax: "tax",
    discount: "discount",
    orderChannel: "orderChannel",
    expense: "expense",
    expenseCategory: "expenseCategory",
    warehouseMovement: "warehouseMovement",
    cashRegister: "cashRegister",
    cashRegisterLog: "cashRegisterLog",
    warehouseLocation: "warehouseLocation",
    orderInvoiceNumeration: "orderInvoiceNumeration",
    keyEvent: "keyEvent",
    chat: "chat",
};

export const persistOptions = [
    LocalStoreKeys.appSettings,
    LocalStoreKeys.currentPurchase,
    LocalStoreKeys.sale,
    LocalStoreKeys.product,
    LocalStoreKeys.currentSale,
    LocalStoreKeys.warehouse,
    LocalStoreKeys.keyEvent,
    LocalStoreKeys.chat,
];

const PREFIX = "wahioapp";

export const clearPersistOptions = () => {
    persistOptions.forEach((option) => {
        localStorage.removeItem(getKeyName(option));
    });
};

const checkKey = (key: string) => {
    return persistOptions.find((x) => x === key);
};

const getKeyName = (keyName: string) => {
    return PREFIX + keyName;
};

export function getStoredData<T>(defaultState: T, keyName: string): T {
    if (!checkKey(keyName)) return defaultState;
    let storeItem = localStorage.getItem(getKeyName(keyName)) as string;
    storeItem = decodeValue(storeItem);
    if (storeItem) {
        try {
            const localData = JSON.parse(storeItem);
            if (localData.isFetching) {
                localData.isFetching = false;
            }
            return localData as T;
        } catch (e) {
            console.error(`Invalid storage value for ${keyName}`);
        }
    }
    return defaultState;
}

export function saveData<T>(keyName: string, obj: T) {
    if (checkKey(keyName)) {
        localStorage.setItem(getKeyName(keyName), encodingObject(obj));
    }
}

export function deleteData(keyName: string) {
    if (checkKey(keyName)) localStorage.removeItem(getKeyName(keyName));
}

function encodingObject(obj: any): string {
    let stringifyObject = JSON.stringify(obj);
    try {
        return btoa(stringifyObject);
    } catch (error) {
        try {
            return btoa(encodeURIComponent(stringifyObject));
        } catch (error) {
            return stringifyObject;
        }
    }
}

function decodeValue(value: string) {
    try {
        return decodeURIComponent(atob(value));
    } catch (error) {
        try {
            return decodeURIComponent(escape(atob(value)));
        } catch (error) {
            return value;
        }
    }
}

export const useStoreClearDeviceData = () => {
    const { appSettingsActions } = useContext(AppSettingsContext);
    const handleClearDeviceData = () => {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        appSettingsActions.setAllSettings(getDefaultAppSettings());
        clearPersistOptions();
        setTimeout(() => {
            window.location.assign("/");
        }, 500);
    };

    return {
        handleClearDeviceData,
    };
};
