import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContextProviders from "./store/ContextProviders";
import { clearAuthSession, resolveAuthSession } from "./auth";
import LoadingDualRing from "./components/LoadingDualRing";
import PATHS from "./constants/paths";
import GlobalStyled from "./globalStyled";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClient, QueryClientProvider } from "react-query";

import { initializeWahioFetchApi } from "./api/fetchApi";
import { AlertContainer } from "./components/Alerts/Alert";

import { ContactListCompany, ContactListCourier, ContactListSeller, ContactListSupplier } from "./components/Contacts/ContactList";

//lazy components
const AppContainer = React.lazy(() => import("./pages/AppContainer"));
const HomeLazy = React.lazy(() => import("./pages/Home"));
const EcommerceProfilePage = React.lazy(() => import("./components/Ecommerce/EcommerceProfile/EcommerceProfilePage"));
const EcommerceList = React.lazy(() => import("./components/Ecommerce/EcommerceList"));
const AccountingList = React.lazy(() => import("./components/AccountingPayments/AccountingPanel/AccountingList"));
const AccountingContactProfilePage = React.lazy(
    () => import("./components/AccountingPayments/AccountingPanel/AccountingContactProfilePage")
);
const CashRegisterEntryContainerList = React.lazy(
    () => import("./components/CashRegisters/CashRegisterEntries/CashRegisterEntryContainerList")
);
const ProductProfilePage = React.lazy(() => import("./pages/ProductProfilePage"));
const ProductRecycleBinList = React.lazy(() => import("./components/Products/ProductList/ProductRecycleBinList"));
const CreateWarehouseMovementForm = React.lazy(() => import("./components/Warehouses/CreateWarehouseMovementForm"));
const InventoryAdjustmentProfile = React.lazy(() => import("./components/InventoryAdjustments/InventoryAdjustmentProfile"));
const InventoryAdjustmentUpdateForm = React.lazy(() => import("./components/InventoryAdjustments/InventoryAdjustmentUpdateForm"));
const WarehouseMovementProfile = React.lazy(() => import("./components/Warehouses/WarehouseMovementProfile"));
const WarehouseProfile = React.lazy(() => import("./components/Warehouses/WarehouseProfile"));
const WarehouseContainerView = React.lazy(() => import("./components/Warehouses/WarehouseContainerView"));
const CustomCategoryProfile = React.lazy(() => import("./components/Products/ProductCategories/CustomCategoryProfile"));
const CustomCategoryList = React.lazy(() => import("./components/Products/ProductCategories/CustomCategoryList"));
const TaxList = React.lazy(() => import("./components/Products/Taxes/TaxList"));
const TaxProfilePage = React.lazy(() => import("./components/Products/Taxes/TaxProfile/TaxProfilePage"));
const DiscountProfilePage = React.lazy(() => import("./components/Discounts/DiscountProfile/DiscountProfilePage"));

const CreatePurchaseForm = React.lazy(() => import("./components/Purchases/CreatePurchaseForm"));
const Reports = React.lazy(() => import("./components/Reports"));
const RestaurantView = React.lazy(() => import("./components/Restaurants/RestaurantView"));
const OrganizationProfile = React.lazy(() => import("./components/Stores/StoreProfile/StoreContainerView"));
const UserProfileEdit = React.lazy(() => import("./components/Users/UserProfileEdit"));

const OrderChannelList = React.lazy(() => import("./components/Orders/OrderChannelList"));
const BillingProfile = React.lazy(() => import("./components/Account/Billing/BillingProfile"));
const DiscountList = React.lazy(() => import("./components/Discounts/DiscountList"));
const EBillingProfilePage = React.lazy(() => import("./components/ElectronicBillings/EBillingProfile/EBillingProfilePage"));
const AccountProfile = React.lazy(() => import("./pages/AccountProfile"));
const CurrentUserProfile = React.lazy(() => import("./pages/CurrentUserProfile"));
const Customers = React.lazy(() => import("./pages/Customers"));
const Products = React.lazy(() => import("./pages/Products"));
const PurchaseProfile = React.lazy(() => import("./pages/PurchaseProfilePage"));
const Purchases = React.lazy(() => import("./pages/Purchases"));
const Statistics = React.lazy(() => import("./pages/Statistics"));
const Organization = React.lazy(() => import("./pages/Stores"));
const CashRegisterProfile = React.lazy(() => import("./components/CashRegisters/CashRegisterProfile"));
const OrderInvoiceNumerationList = React.lazy(
    () => import("./components/OrderInvoiceNumerations/OrderInvoiceNumeration/OrderInvoiceNumerationList")
);
const DeliveryOrderBoard = React.lazy(() => import("./components/Orders/DeliveryOrderBoard"));
const StoreHomeProfile = React.lazy(() => import("./components/Stores/StoreHomeProfile"));
const CashRegisterList = React.lazy(() => import("./components/CashRegisters/CashRegisterList"));
const OrderListInvoice = React.lazy(() => import("./components/Orders/OrderList/OrderListInvoice"));
const OrderListRestaurant = React.lazy(() => import("./components/Orders/OrderList/OrderListRestaurant"));
const OrderListShipment = React.lazy(() => import("./components/Orders/OrderList/OrderListShipment"));
const OrderListShipmentQueue = React.lazy(() => import("./components/Orders/OrderList/OrderListShipmentQueue"));
const OrderListVoid = React.lazy(() => import("./components/Orders/OrderList/OrderListVoid"));
const OrderReturnList = React.lazy(() => import("./components/Orders/OrderReturns/OrderReturnList"));
const WahioCloudItemList = React.lazy(() => import("./components/WahioCloud/WahioCloudItemList"));
const OrderProfilePage = React.lazy(() => import("./pages/OrderProfilePage"));
const CashRegisterLogList = React.lazy(() => import("./components/CashRegisters/CashRegisterLog/CashRegisterLogList"));
const CashRegisterLogProfilePage = React.lazy(() => import("./components/CashRegisters/CashRegisterLog/CashRegisterLogProfilePage"));
const ContactProfilePage = React.lazy(() => import("./components/Contacts/ContactProfile/ContactProfilePage"));
const OrderListDraft = React.lazy(() => import("./components/Orders/OrderList/OrderListDraft"));
const OrderListQuotation = React.lazy(() => import("./components/Orders/OrderList/OrderListQuotation"));
const OrderPickingList = React.lazy(() => import("./components/Orders/OrderPickingList"));
const ProductLogsPage = React.lazy(() => import("./pages/ProductLogsPage"));
const CashRegisterBoard = React.lazy(() => import("./components/CashRegisters/CashRegisterBoard"));
const OrderCommentList = React.lazy(() => import("./components/Orders/OrderCommentList"));
const TransactionAccountPanel = React.lazy(() => import("./components/AccountingPayments/TransactionAccount/TransactionAccountList"));
const TransactionAccountProfilePage = React.lazy(
    () => import("./components/AccountingPayments/TransactionAccount/TransactionAccountProfile/TransactionAccountProfilePage")
);
const CalendarContainer = React.lazy(() => import("./components/EventCalendar/CalendarContainer"));
const OrderReadyToShip = React.lazy(() => import("./components/Orders/OrderReadyToShip"));
const OrderTempList = React.lazy(() => import("./components/Orders/OrderTemps/OrderTempList"));
const Users = React.lazy(() => import("./components/Account/Users"));
const OrderMultiPanel = React.lazy(() => import("./components/Orders/OrderMultiPanel"));
const LocalSettings = React.lazy(() => import("./components/Settings/LocalSettings"));

//END lazy components

const queryClient = new QueryClient();

const SuspenseFallback = () => {
    return (
        <div>
            <LoadingDualRing center={true} />
        </div>
    );
};

export const App = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authParam = urlParams.get("auth");
    const authId = urlParams.get("authUserId");

    const authBody = resolveAuthSession(authParam);
    initializeWahioFetchApi();

    useEffect(() => {
        if (authId && authBody && authBody?.accountUserId !== authId) {
            clearAuthSession();
        }
        if (authParam) {
            window.history.replaceState({}, document.title, window.location.origin);
        }
    }, [authBody, authId, authParam]);

    return (
        <QueryClientProvider client={queryClient}>
            <ContextProviders>
                <DndProvider backend={HTML5Backend}>
                    <GlobalStyled />
                    <BrowserRouter>
                        <AlertContainer />
                        <AppContainer authBody={authBody} authParam={authParam}>
                            <Suspense fallback={<SuspenseFallback />}>
                                <Routes>
                                    <Route path="/" Component={HomeLazy} />
                                    <Route path={PATHS.home} Component={HomeLazy} />
                                    <Route path={PATHS.ecommerceProfile()} Component={EcommerceProfilePage} />
                                    <Route path={PATHS.ecommerce} Component={EcommerceList} />
                                    <Route path={PATHS.paymentsAccountingPanel} Component={AccountingList} />
                                    <Route path={PATHS.paymentsAccountingContactProfile()} Component={AccountingContactProfilePage} />
                                    <Route path={PATHS.paymentsAll} Component={CashRegisterEntryContainerList} />
                                    <Route path={PATHS.productProfileId()} Component={ProductProfilePage} />
                                    <Route path={PATHS.productRecycleBin} Component={ProductRecycleBinList} />
                                    <Route path={PATHS.productWarehousesMovementNew} Component={CreateWarehouseMovementForm} />
                                    <Route path={PATHS.productInventoryAdjustmentProfileId()} Component={InventoryAdjustmentProfile} />
                                    <Route path={PATHS.productInventoryAdjustmentEditId()} Component={InventoryAdjustmentUpdateForm} />
                                    <Route path={PATHS.productWarehousesMovementUpdate()} Component={CreateWarehouseMovementForm} />
                                    <Route path={PATHS.productWarehousesMovementProfile()} Component={WarehouseMovementProfile} />
                                    <Route path={PATHS.productWarehousesProfile()} Component={WarehouseProfile} />
                                    <Route path={PATHS.productWarehouses} Component={WarehouseContainerView} />
                                    <Route path={PATHS.productCategoryProfile()} Component={CustomCategoryProfile} />
                                    <Route path={PATHS.productCategories} Component={CustomCategoryList} />
                                    <Route path={PATHS.productTax} Component={TaxList} />
                                    <Route path={PATHS.productTaxProfile()} Component={TaxProfilePage} />
                                    <Route path={PATHS.productDiscountProfile()} Component={DiscountProfilePage} />
                                    <Route path={PATHS.productDiscount} Component={DiscountList} />
                                    <Route path={PATHS.productPurchasesNew} Component={CreatePurchaseForm} />
                                    <Route path={PATHS.productPurchasesEditId()} Component={CreatePurchaseForm} />
                                    <Route path={PATHS.productPurchasesListProfileId()} Component={PurchaseProfile} />
                                    <Route path={PATHS.productPurchasesList} Component={Purchases} />
                                    <Route path={PATHS.productLogs} Component={ProductLogsPage} />
                                    <Route path={PATHS.products} Component={Products} />
                                    <Route path={PATHS.productList} Component={Products} />
                                    <Route path={PATHS.orderSaleNewQuotation()} Component={OrderMultiPanel} />
                                    <Route path={PATHS.orderPosNew()} Component={OrderMultiPanel} />
                                    <Route path={PATHS.orderPosProfile()} Component={OrderProfilePage} />
                                    <Route path={PATHS.orderReturns} Component={OrderReturnList} />
                                    <Route path={PATHS.orderRestaurant} Component={OrderListRestaurant} />
                                    <Route path={PATHS.orderQuoteList} Component={OrderListQuotation} />
                                    <Route path={PATHS.orderVoidList} Component={OrderListVoid} />
                                    <Route path={PATHS.orderDraft} Component={OrderListDraft} />
                                    <Route path={PATHS.orderPos} Component={OrderListInvoice} />
                                    <Route path={PATHS.orderSalesList} Component={OrderListInvoice} />
                                    <Route path={PATHS.orderShipmentNew()} Component={OrderMultiPanel} />
                                    <Route path={PATHS.orderShipmentProfile()} Component={OrderProfilePage} />
                                    <Route path={PATHS.orderShipmentsBoard} Component={DeliveryOrderBoard} />
                                    <Route path={PATHS.orderShipmentsQueue} Component={OrderListShipmentQueue} />
                                    <Route path={PATHS.shipmentsList} Component={OrderListShipment} />
                                    <Route path={PATHS.shipmentsListWhatsapp} Component={OrderTempList} />
                                    <Route path={PATHS.shipmentsListPicking} Component={OrderPickingList} />
                                    <Route path={PATHS.orderShipmentReadyToShip} Component={OrderReadyToShip} />
                                    <Route path={PATHS.orderChannels} Component={OrderChannelList} />
                                    <Route path={PATHS.orderCommentList} Component={OrderCommentList} />
                                    <Route path={PATHS.accountCalendar} Component={CalendarContainer} />

                                    <Route path={PATHS.restaurant} Component={RestaurantView} />
                                    <Route path={PATHS.paymentsTransactionAccount} Component={TransactionAccountPanel} />
                                    <Route path={PATHS.paymentsTransactionAccountProfile()} Component={TransactionAccountProfilePage} />
                                    <Route path={PATHS.accountStatistics} Component={Statistics} />
                                    <Route path={PATHS.accountReports} Component={Reports} />
                                    <Route path={PATHS.storeProfile()} Component={StoreHomeProfile} />
                                    <Route path={PATHS.contactProfile()} Component={ContactProfilePage} />
                                    <Route path={PATHS.contactSellers} Component={ContactListSeller} />
                                    <Route path={PATHS.contactCompany} Component={ContactListCompany} />
                                    <Route path={PATHS.contactSuppliers} Component={ContactListSupplier} />
                                    <Route path={PATHS.contactCourier} Component={ContactListCourier} />
                                    <Route path={PATHS.contacts} Component={Customers} />
                                    <Route path={PATHS.contactList} Component={Customers} />
                                    <Route path={PATHS.configInvoiceNumeration} Component={OrderInvoiceNumerationList} />
                                    <Route path={PATHS.accountOrganizationsProfile()} Component={OrganizationProfile} />
                                    <Route path={PATHS.settingsAccountProfileEditUserId()} Component={UserProfileEdit} />
                                    <Route path={PATHS.accountBilling} Component={BillingProfile} />
                                    <Route path={PATHS.configEBilling} Component={EBillingProfilePage} />
                                    <Route path={PATHS.accountStores} Component={Organization} />
                                    <Route path={PATHS.cashRegisterLogProfile()} Component={CashRegisterLogProfilePage} />
                                    <Route path={PATHS.cashRegisterLogList} Component={CashRegisterLogList} />
                                    <Route path={PATHS.cashRegistersProfile()} Component={CashRegisterProfile} />
                                    <Route path={PATHS.cashRegistersDashboard} Component={CashRegisterBoard} />
                                    <Route path={PATHS.cashRegisters} Component={CashRegisterList} />
                                    <Route path={PATHS.cashRegistersList} Component={CashRegisterList} />
                                    <Route path={PATHS.settingsAccountUserProfile} Component={CurrentUserProfile} />
                                    <Route path={PATHS.account} Component={AccountProfile} />
                                    <Route path={PATHS.accountUsers} Component={Users} />
                                    <Route path={PATHS.settingsAccountProfile} Component={AccountProfile} />
                                    <Route path={PATHS.accountCloud} Component={WahioCloudItemList} />
                                    <Route path={PATHS.configGeneral} Component={LocalSettings} />
                                </Routes>
                            </Suspense>
                        </AppContainer>
                    </BrowserRouter>
                </DndProvider>
            </ContextProviders>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
    );
};
export default App;
