import React from "react";
import { useIntl } from "react-intl";
import ProductSearchList, { ProductActiveObject } from ".";
import { ProductPriceTypes } from "../../../api/models/product";
import Modal, { IModalShow } from "../../modals/Modal";
import { ProductAddOption } from "../ProductCard";
import messages from "./messages";

interface ProductSearchListModalProps extends IModalShow {
    onClickProduct: (value: ProductAddOption) => void;
    onlyWarehouseProduct?: boolean;
    preventCloseOnClick?: boolean;
    activeProducts?: ProductActiveObject;
    disabledActiveProducts?: boolean;
    selectedPrice?: ProductPriceTypes;
    onlyInventoryProducts?: boolean;
}

export default function ProductSearchListModal(props: ProductSearchListModalProps) {
    const intl = useIntl();
    const onClickProduct = (value: ProductAddOption) => {
        if (!props.preventCloseOnClick) props.setShow(false);
        props.onClickProduct(value);
    };
    return (
        <Modal
            id="ProductSearchListModal"
            scrollInside={false}
            overflowAuto={false}
            title={intl.formatMessage(messages.filterProducts)}
            showHeader={true}
            {...props}
        >
            <ProductSearchList
                disabledActiveProducts
                activeProducts={props.activeProducts}
                selectedPrice={props.selectedPrice}
                showAllProducts={!props.onlyWarehouseProduct}
                onClickProduct={onClickProduct}
                onlyInventoryProducts={props.onlyInventoryProducts}
            />
        </Modal>
    );
}
