import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { IPaginationResponse, PaginationResponse } from "../../../api/models";
import {
    ProductCostMultipleSearchInput,
    ProductListingItem,
    ProductListingSearchInput,
    ProductPartialUpdateBulkItem,
} from "../../../api/models/product";

import { getServiceMessageError } from "../../../api";
import { productListingBasic, productPartialUpdateMultiple } from "../../../api/products/productsApi";
import { getWarehouseCostSummary } from "../../../api/products/warehouseApi";
import { getProductCostByOnPurchasesMultiple } from "../../../api/purchases/purchaseApi";
import { getAuthBody } from "../../../auth";
import { formatMoney } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultButton, Flex, GridTemplate, PrimaryButton, TextField } from "../../_controls";
import { ProgressBarValue } from "../../_controls/progressBars/ProgressBar";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import { getProductMultipleSearchInput, getProductUpdateBulkItem } from "./helper";
import messages from "./messages";
import { CardTableContainer, WarehouseCardCostView } from "./styled";

interface WarehouseCostViewProps {
    warehouseId: string;
}

export const COST_LIST = ["basePrice", "costAverage", "costWeightedAverage", "costLastPurchase"];
export const COST_BASE_LIST = ["basePrice", "costBaseAverage", "costBaseWeightedAverage", "costBaseLastPurchase"];

export default function WarehouseCostView(props: WarehouseCostViewProps) {
    const [showModal, setShowModal] = useState(false);

    const costMutation = useMutation((id: string) => getWarehouseCostSummary(id));
    const intl = useIntl();
    useEffect(() => {
        costMutation.mutate(props.warehouseId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const summary = costMutation.data;

    return (
        <Flex column gap={20} w100>
            {showModal && (
                <Modal ignoreOutsideClick title="Re-Calcular Costos" showHeader show={showModal} setShow={setShowModal}>
                    <ProductCostUpdatePanel onCompleted={() => costMutation.mutate(props.warehouseId)} />
                </Modal>
            )}
            <Flex alignCenter spaceBetween>
                <TextField bold>Inventario/Costos</TextField>
                <DefaultButton borderRadius={10} bgLight onClick={() => setShowModal(true)}>
                    Re-Calcular Costos
                </DefaultButton>
            </Flex>

            <GridTemplate size={210}>
                <CardTableContainer>
                    <TextField small light>
                        Total Costo
                    </TextField>
                    <Table cellSpacing="0" cellPadding="0" hideBorde tdPadding={4} tdPaddingRight={15}>
                        <tbody>
                            <tr>
                                <td>{intl.formatMessage(messages.basePrice)}</td>
                                <td>{formatMoney(getNumber(summary?.basePrice))}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(messages.costLastPurchase)}</td>
                                <td>{formatMoney(getNumber(summary?.costLastPurchase))}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(messages.costAverage)}</td>
                                <td>{formatMoney(getNumber(summary?.costAverage))}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(messages.costWeightedAverage)}</td>
                                <td>{formatMoney(getNumber(summary?.costWeightedAverage))}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardTableContainer>

                <CardTableContainer>
                    <TextField small light>
                        Total Costo Base
                    </TextField>
                    <Table cellSpacing="0" cellPadding="0" hideBorde tdPadding={4} tdPaddingRight={15}>
                        <tbody>
                            <tr>
                                <td>{intl.formatMessage(messages.basePrice)}</td>
                                <td>{formatMoney(getNumber(summary?.basePrice))}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(messages.costLastPurchase)}</td>
                                <td>{formatMoney(getNumber(summary?.costBaseLastPurchase))}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(messages.costAverage)}</td>
                                <td>{formatMoney(getNumber(summary?.costBaseAverage))}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(messages.costWeightedAverage)}</td>
                                <td>{formatMoney(getNumber(summary?.costBaseWeightedAverage))}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardTableContainer>

                <CardTableContainer>
                    <TextField small light>
                        Stock de la bodega
                    </TextField>
                    <Table cellSpacing="0" cellPadding="0" hideBorde tdPadding={4} tdPaddingRight={15}>
                        <tbody>
                            <tr>
                                <td>Total</td>
                                <td>{summary?.totalStock}</td>
                            </tr>
                            <tr>
                                <td>Reservado</td>
                                <td>
                                    <WarehouseCardCostView>
                                        <TextField>{summary?.totalStockReserved}</TextField>
                                        <TextField className="line-value orange">
                                            {Math.round((getNumber(summary?.totalStockReserved) * 100) / getNumber(summary?.totalStock))}%
                                        </TextField>
                                    </WarehouseCardCostView>
                                </td>
                            </tr>
                            <tr>
                                <td>Disponible</td>
                                <td>
                                    <WarehouseCardCostView>
                                        <TextField>{getNumber(summary?.totalStock) - getNumber(summary?.totalStockReserved)}</TextField>
                                        <TextField className="line-value green">
                                            {Math.round(
                                                ((getNumber(summary?.totalStock) - getNumber(summary?.totalStockReserved)) * 100) /
                                                    getNumber(summary?.totalStock)
                                            )}
                                            %
                                        </TextField>
                                    </WarehouseCardCostView>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </CardTableContainer>
            </GridTemplate>

            {costMutation.isLoading && <LoadingDualRing center />}

            <ProductCostLink />
        </Flex>
    );
}

export const ProductCostLink = () => {
    return (
        <a href="https://support.wahio.com/docs/como-funcionan-los-costos-de-los-productos/" target="_blank" rel="noreferrer">
            <TextField small className="fa-regular fa-circle-exclamation"></TextField>{" "}
            <TextField small light>
                Ayuda con los costos
            </TextField>
        </a>
    );
};

interface StateModel {
    itemsLoad: number;
    totalItems: number;
    itemsProcessed: number;
    activeProductName: string;
    initialLoad?: boolean;
    isCompleted?: boolean;
    limitSet?: boolean;
    items: ProductListingItem[];
}

const getNumber = (value?: number) => {
    return value ? Math.round(value) : 0;
};

interface ProductCostUpdatePanelProps {
    onCompleted: () => void;
}

const ITEMS_LIMIT = 500;
const ITEMS_SLICE_GROUP = 10;

const ProductCostUpdatePanel = (props: ProductCostUpdatePanelProps) => {
    const isMounted = React.useRef(true);

    const alert = useAlert();
    const listingMutation = useMutation((data: ProductListingSearchInput) => productListingBasic(data));

    const stateRef = React.useRef<StateModel>({
        itemsLoad: 0,
        totalItems: 0,
        itemsProcessed: 0,
        activeProductName: "",
        items: [],
    });

    const [state, setState] = useState<StateModel>({
        itemsLoad: 0,
        totalItems: 0,
        itemsProcessed: 0,
        activeProductName: "",
        items: [],
    });

    const [errorList, setErrorList] = useState<string[]>([]);

    const saveProductCostMutation = useMutation((body: ProductPartialUpdateBulkItem[]) => productPartialUpdateMultiple(body), {
        onSuccess: (data) => {},
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });
    const getProductCostMutation = useMutation((data: ProductCostMultipleSearchInput) => getProductCostByOnPurchasesMultiple(data));

    useEffect(() => {
        initialSearch();

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialSearch = async () => {
        let data: ProductListingSearchInput = {
            accountId: getAuthBody().accountId,
            currentPage: 1,
            pageSize: 2,
            filterCostUpdatedAt: true,
            includeVariants: true,
        };
        var result = await listingMutation.mutateAsync(data);

        updateState({
            ...stateRef.current,
            totalItems: result.totalItems,
            initialLoad: true,
        });
    };

    const handleSearchItems = async (page: number = 1, paginationValue: IPaginationResponse<ProductListingItem>) => {
        let data: ProductListingSearchInput = {
            accountId: getAuthBody().accountId,
            currentPage: page,
            pageSize: 100,
            filterCostUpdatedAt: true,
            includeVariants: true,
        };

        var listingResult = await listingMutation.mutateAsync(data);

        let items = [...paginationValue.items, ...listingResult.items];
        listingResult.items = items;

        let stopped = false;
        if (items.length >= ITEMS_LIMIT) {
            stopped = true;

            updateState({
                ...stateRef.current,
                totalItems: ITEMS_LIMIT,
                itemsLoad: ITEMS_LIMIT,
                limitSet: true,
            });
        } else {
            updateState({
                ...stateRef.current,
                itemsLoad: items.length,
            });
        }

        if (listingResult.currentPage < listingResult.totalPages && !stopped) {
            handleSearchItems(listingResult.currentPage + 1, listingResult);
        } else {
            const allItems = listingResult.items.slice(0, ITEMS_LIMIT);
            updateState({ ...stateRef.current, items: allItems });
            initProductLoad(allItems);
        }
    };

    const updateState = (state: StateModel) => {
        stateRef.current = state;
        setState(stateRef.current);
    };

    const initProductLoad = async (items: ProductListingItem[], itemsProcessed: number = 0) => {
        if (!isMounted.current) {
            alert.info("Actualización cancelada");
            return;
        }

        if (items.length === 0) {
            updateState({
                ...stateRef.current,
                isCompleted: true,
                itemsProcessed: stateRef.current.itemsLoad,
            });

            props.onCompleted();
            alert.success("Todos los productos fueron procesados correctamente");
            return;
        }

        var partitionGroup = items.slice(0, ITEMS_SLICE_GROUP);
        var item = items[0];

        try {
            updateState({
                ...stateRef.current,
                activeProductName: `${item.name} ${item.variantValues ?? ""} (+${partitionGroup.length})`,
                itemsProcessed: itemsProcessed,
            });

            var productIds: string[] = [];
            var productVariantIds: string[] = [];

            partitionGroup.forEach((item) => {
                if (item.productVariantId) {
                    productVariantIds.push(item.productVariantId);
                } else {
                    productIds.push(item.productId);
                }
            });

            let costMultipleInput = getProductMultipleSearchInput(productIds, productVariantIds);
            var costSummaryItems = await getProductCostMutation.mutateAsync(costMultipleInput);
            let bulkItems: ProductPartialUpdateBulkItem[] = [];
            costSummaryItems.forEach((costSummary) => {
                let updateValue = getProductUpdateBulkItem(costSummary);
                bulkItems.push(updateValue);
            });
            await saveProductCostMutation.mutateAsync(bulkItems);
        } catch (ex) {
            setErrorList([...errorList, `${item.name} ${item.variantValues}`]);
        }

        if (items.length > 0) {
            initProductLoad(items.slice(ITEMS_SLICE_GROUP), itemsProcessed + partitionGroup.length);
        }
    };

    const extraProducts = state.totalItems < state.itemsLoad ? state.itemsLoad - state.totalItems : 0;
    const totalAndExtra = state.totalItems > state.itemsLoad ? state.totalItems : state.itemsLoad;

    const isLoading = listingMutation.isLoading || getProductCostMutation.isLoading || saveProductCostMutation.isLoading;

    let progress = 0;
    if (state.itemsLoad > 0) {
        progress = Math.round((state.itemsProcessed * 100) / state.itemsLoad);
    }

    return (
        <Flex column gap={20} padding={20} paddingBottom={30}>
            <TextField small light>
                Solo se actualizaran los productos que no se hayan actualizado las ultimas 24 horas
            </TextField>

            <Flex column gap10>
                <TextField>
                    Total Productos: {state.totalItems} {extraProducts > 0 && `(+${extraProducts} Variantes)`}
                </TextField>
                {!state.isCompleted && state.itemsProcessed > 0 && <TextField ellipsis>Progreso: {state.activeProductName}</TextField>}
            </Flex>

            {state.limitSet && (
                <Flex gap10 alignStart>
                    <TextField small color={"#FF6F00"} className="fa-regular fa-circle-info"></TextField>
                    <TextField small color={"#FF6F00"}>
                        El sistema a limitado el conteo a {ITEMS_LIMIT} unidades, para calcular las siguientes unidades solo debes
                        re-calcular
                    </TextField>
                </Flex>
            )}

            {state.itemsProcessed > 0 && (
                <Flex justifyCenter alignCenter>
                    <Flex gap10 alignCenter>
                        {state.isCompleted && <TextField fontSize={20} color={"#0062ff"} className="fa-regular fa-check"></TextField>}
                        <TextField bold fontSize={20}>
                            {progress}%
                        </TextField>
                    </Flex>
                </Flex>
            )}

            {state.itemsProcessed > 0 && (
                <Flex column gap15 maxHeight={300} overflowAuto>
                    <ProgressBarValue currentValue={state.itemsProcessed} limitValue={totalAndExtra} title={"Progreso de Actualización"} />
                </Flex>
            )}

            {state.itemsProcessed === 0 && (
                <PrimaryButton
                    w100
                    disabled={isLoading || state.totalItems === 0}
                    borderRadius={10}
                    bgLight
                    onClick={() => handleSearchItems(1, new PaginationResponse<ProductListingItem>())}
                >
                    <i className="fa-regular fa-play"></i> Iniciar Calculo {isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            )}

            {state.initialLoad && state.totalItems === 0 && (
                <Flex gap5 alignCenter>
                    <TextField small color={"#FF6F00"} className="fa-regular fa-circle-info"></TextField>
                    <TextField small color={"#FF6F00"}>
                        No hay productos disponibles para actualizar
                    </TextField>
                </Flex>
            )}
        </Flex>
    );
};
