import { IGenericState } from ".";

export const ADD_WAREHOUSE = "ADD_WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";
export const REQUEST_WAREHOUSES = "REQUEST_WAREHOUSES";
export const RECEIVE_WAREHOUSES = "RECEIVE_WAREHOUSES";
export const INVALID_REQUEST_WAREHOUSES = "INVALID_REQUEST_WAREHOUSES";

export const CLEAR_INVALID_DATA = "CLEAR_INVALID_DATA";

export interface IWarehouse {
    id?: string;
    accountId: string;
    accountUserId: string;
    allowLogisticsService: boolean;
    allowNegativeStock: boolean;
    name: string;
    address?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    stockDateUpdated?: Date;
    totalProductCount?: number;
    totalReservedStock?: number;
    stockValue?: number;
}

export class Warehouse implements IWarehouse {
    id?: string;
    accountId: string;
    accountUserId: string;
    name: string;
    address?: string;
    allowLogisticsService: boolean = false;
    allowNegativeStock: boolean = false;
    dateCreated?: Date;
    dateUpdated?: Date;
    totalProductCount?: number;

    constructor(accountId: string, accountUserId: string, name: string) {
        this.accountId = accountId;
        this.accountUserId = accountUserId;
        this.name = name;
    }
}

export interface WarehouseCostSummary {
    basePrice: number;
    costWeightedAverage: number;
    costBaseWeightedAverage: number;
    costAverage: number;
    costBaseAverage: number;
    costLastPurchase: number;
    costBaseLastPurchase: number;
    totalStock: number;
    totalStockReserved: number;
}

export interface IWarehouseState extends IGenericState<IWarehouse> {}

export interface RequestWarehouses {
    type: typeof REQUEST_WAREHOUSES;
}
export interface ReceiveWarehouses {
    type: typeof RECEIVE_WAREHOUSES;
    items: Array<IWarehouse>;
}
export interface InvalidRequest {
    type: typeof INVALID_REQUEST_WAREHOUSES;
    value: any;
}
export interface UpdateWarehouses {
    type: typeof UPDATE_WAREHOUSE;
    value: IWarehouse;
}
export interface AddWarehouse {
    type: typeof ADD_WAREHOUSE;
    value: IWarehouse;
}
export interface DeleteWarehouse {
    type: typeof DELETE_WAREHOUSE;
    id: string;
}
export interface ClearInvalidData {
    type: typeof CLEAR_INVALID_DATA;
}

export type WarehouseTypes =
    | RequestWarehouses
    | ReceiveWarehouses
    | InvalidRequest
    | UpdateWarehouses
    | AddWarehouse
    | DeleteWarehouse
    | ClearInvalidData;
