import styled from "styled-components";

export const OptionalsContainer = styled.div`
    max-height: 600px;
    overflow: auto;
    .optional-item {
        &:last-child {
            border-bottom: none;
        }
    }

    .optional-card {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
        &:last-child {
            border-bottom: none;
        }
    }

    .header {
        :hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
`;

export const OptionalCardStyle = styled.div`
    .optional-items {
        max-height: 500px;
        transition: max-height 0.2s ease-in-out;
    }

    .collapse-icon {
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
    }

    &.close {
        .optional-items {
            max-height: 0;
            overflow: hidden;
        }
        .collapse-icon {
            transform: rotate(180deg);
        }
    }
`;

export const OptionalItemCardStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    padding-right: 10px;
    padding-left: 5px;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .required-label {
        background: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        font-size: 12px;
        padding: 1px 4px;
        border-radius: 15px;
        &.done {
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }
    }
    .item-checkbox {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.backgroundLight};
        width: 20px;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
            color: ${(props) => props.theme.palette.backgroundDark};
        }

        &.selected {
            background: ${(props) => props.theme.palette.primary};
            border-color: ${(props) => props.theme.palette.primary};
            color: white;
        }
    }
`;

export const QuantityCardStyle = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;

    .input-quantity,
    .textbox-numeral {
        width: 40px;
        border-radius: 50px;
        text-align: center;
        font-weight: 600;
        border: 0;
        padding: 3px;
    }
`;
