import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Order } from "../../api/models/order";
import Modal, { IModalShow } from "../modals/Modal";
import CreateOrderForm from "./CreateOrderForm";
import messages from "./messages";

interface EditOrderProps {
    onCompleted: (order: Order) => void;
    order: Order;
}

interface CreateOrderModalProps extends IModalShow, EditOrderProps {}

export const EditOrder = (props: EditOrderProps) => {
    const [order, setOrder] = useState(props.order);
    const intl = useIntl();
    return (
        <CreateOrderForm
            order={order}
            onOrderChange={setOrder}
            onCompleted={props.onCompleted}
            pageTitle={`${intl.formatMessage(messages.editOrder)} ${props.order.number} -Wahio`}
        />
    );
};

export default function CreateOrderModal(props: CreateOrderModalProps) {
    const [order, setOrder] = useState(props.order);
    const intl = useIntl();
    return (
        <Modal
            removeVerticalAlign={true}
            id="modal-create-update-order"
            title={props.order ? "Editar Orden" : "Crear Orden"}
            showHeader={true}
            ignoreOutsideClick
            overflowAuto
            useMobileView
            sizeType="lg"
            {...props}
        >
            <CreateOrderForm
                isModal
                pageTitle={`${intl.formatMessage(messages.editOrder)} ${props.order.number}`}
                order={order}
                onOrderChange={setOrder}
                status={order.status}
                onCompleted={props.onCompleted}
            />
        </Modal>
    );
}
