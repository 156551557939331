import { Dispatch } from "react";
import { ICurrentOrderState, UpdateCurrentOrder, OrderTypes, UPDATE_CURRENT_ORDER } from "../../api/models/order";

export interface ICurrentSaleActions {
    updateOrder: (state: ICurrentOrderState) => void;
}

export const actionUpdateOrder = (orderState: ICurrentOrderState): UpdateCurrentOrder => {
    return {
        type: UPDATE_CURRENT_ORDER,
        value: orderState,
    };
};

export const useActions = (state: ICurrentOrderState, dispatch: Dispatch<OrderTypes>): ICurrentSaleActions => ({
    updateOrder: (orderState: ICurrentOrderState) => {
        dispatch(actionUpdateOrder(orderState));
    },
});
