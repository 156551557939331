import React from "react";
import styled from "styled-components";
import { Order } from "../../../api/models/order";
import { OrderNotification } from "../../../api/models/orderNotification";
import { getDateFormatCalendar } from "../../../utils";

interface OrderNotificationHistoryProps {
    order: Order;
}

const NotificationRowStyle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: ${(props) => props.theme.palette.backgroundDark};
        margin-right: 10px;
    }
    .body {
        display: flex;
        flex-direction: column;
    }
`;

export default function OrderNotificationHistory(props: OrderNotificationHistoryProps) {
    const { order } = props;
    const { notifications } = order;
    if (!notifications) return null;

    return (
        <div>
            {notifications.map((item, index) => (
                <NotificationCard item={item} key={index} />
            ))}
        </div>
    );
}

interface NotificationCardProps {
    item: OrderNotification;
}

const NotificationCard = (props: NotificationCardProps) => {
    const { item } = props;

    const isSms = item.type === "sms";
    const icon = isSms ? "wahioicon-mobile-alt" : "wahioicon-envelope";
    return (
        <NotificationRowStyle>
            <span className={`${icon} icon`}></span>
            <div className="body">
                <span>{isSms ? item.phoneNumber : item.email}</span>
                <span className="text-light text-small">{getDateFormatCalendar(item.createdAt)}</span>
            </div>
        </NotificationRowStyle>
    );
};
