import styled from "styled-components";
import { TableCustomTr } from "../../_controls/tables/styled";


export const LabelListContainer = styled.div`
    padding: 20px;
`;

export const LabelRow = styled(TableCustomTr)`
    .input-name { 
        background: transparent;
    }
`