import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { postCashRegisterLogGetEntries } from "../../../api/account/cashRegisterApi";
import { IPaginationResponse } from "../../../api/models";
import { ICashRegisterEntry } from "../../../api/models/cashRegister";
import { CashRegisterLog, CashRegisterLogEntriesSearch } from "../../../api/models/cashRegisterLog";
import { checkAndGetMessage } from "../../../i18n/helper";
import globalMessages from "../../../messages";
import { formatMoney, getDateFormat } from "../../../utils";
import LoadingDualRing from "../../LoadingDualRing";
import PaginationView from "../../PaginationView";
import { DefaultButton, Flex, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import CashRegisterEntryCard from "../CashRegisterEntries/CashRegisterEntryCard";
import { LogNavigationTitle } from "./styled";

interface CashRegisterLogPreviewProps {
    log: CashRegisterLog;
    gridSize?: number;
    printMode?: boolean;
}

interface ValueSymbolProps {
    value?: number;
    text?: string;
    negative?: boolean;
}

const ValueSymbol = (props: ValueSymbolProps) => {
    const { value, negative } = props;

    const isNegative = negative ?? (value ?? 0) < 0;

    const color = isNegative ? "#d32f2f" : "#4caf50";
    let symbol = isNegative ? "fa-regular fa-arrow-down" : "fa-regular fa-arrow-up";
    if (!isNegative && value === 0) symbol = "fa-regular fa-arrow-right";

    const textContent = props.value !== undefined ? formatMoney(Math.abs(value ?? 0)) : props.text ?? "";

    //<i class="fa-regular fa-arrow-up"></i>

    return (
        <span>
            <TextField className={symbol} fontSize={12} color={color} /> <TextField color={color}>{textContent}</TextField>
        </span>
    );
};

export const CashRegisterLogPreview = (props: CashRegisterLogPreviewProps) => {
    const { log, printMode } = props;
    const intl = useIntl();

    const [showModal, setShowModal] = useState(false);

    const items = React.useMemo(() => {
        return (
            log.methodItems?.sort((a, b) => {
                if (a.method === "cash") return -1;
                if (b.method === "cash") return 1;
                return 0;
            }) ?? []
        );
    }, [log.methodItems]);

    const balance = log.cashTotalIncome - log.cashTotalExpense;

    const flexProps = {
        borderRadius: printMode ? 0 : 15,
        padding: printMode ? 0 : 15,
        border: !printMode,
        column: true,
        gap: 20,
    };

    return (
        <>
            {showModal && (
                <Modal show={showModal} showHeader title="Movimientos" setShow={setShowModal}>
                    <CashRegisterLogEntriesView log={log} />
                </Modal>
            )}
            <Flex {...flexProps}>
                <LogNavigationTitle>Resumen de movimientos por método</LogNavigationTitle>

                <Table cellPadding={0} cellSpacing={0} tdPadding={8}>
                    <thead className="header-light">
                        <tr>
                            <th>Método</th>
                            <th>
                                <ValueSymbol text="Entradas" />
                            </th>
                            <th>
                                <ValueSymbol negative text="Salidas" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            const income = item.totalIncome;
                            const incomeCount = item.totalIncomeCount;

                            const expense = item.totalExpense;
                            const expenseCount = item.totalExpenseCount;

                            return (
                                <tr key={index}>
                                    <td>{checkAndGetMessage(intl, globalMessages, item.method)}</td>
                                    <td>
                                        <span>
                                            {formatMoney(income)} {incomeCount > 0 ? `(${incomeCount})` : ""}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {formatMoney(expense)} {expenseCount > 0 ? `(${expenseCount})` : ""}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                {!props.printMode && (
                    <DefaultButton bgLight borderRadius={10} onClick={() => setShowModal(true)}>
                        <i className="fa-regular fa-arrow-right"></i>
                        Ver movimientos
                    </DefaultButton>
                )}
            </Flex>
            <CorrectionsPanel log={props.log} flexProps={flexProps} printMode={printMode} />
            <Flex {...flexProps}>
                <LogNavigationTitle>
                    Balance general ({checkAndGetMessage(intl, globalMessages, log.cashRegister?.defaultPaymentMethod ?? "cash")})
                </LogNavigationTitle>
                <Table cellPadding={0} cellSpacing={0} tdPadding={6}>
                    <tbody>
                        <tr>
                            <td>Saldo Inicial</td>
                            <td>
                                <ValueSymbol value={log.cashInitialAmount} />
                            </td>
                        </tr>
                        <tr>
                            <td>Entradas ({log.cashTotalIncomeCount})</td>
                            <td>
                                <ValueSymbol value={log.cashTotalIncome} />
                            </td>
                        </tr>
                        <tr>
                            <td>Salidas ({log.cashTotalExpenseCount})</td>
                            <td>
                                <ValueSymbol negative value={log.cashTotalExpense} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Flex column>
                                    <TextField>Balance</TextField>
                                    {!printMode && (
                                        <TextField small light>
                                            Entradas - Salidas
                                        </TextField>
                                    )}
                                </Flex>
                            </td>
                            <td>
                                <ValueSymbol value={balance} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Flex column>
                                    <TextField>Debe haber</TextField>
                                    {!printMode && (
                                        <TextField small light>
                                            Saldo Inicial + Balance
                                        </TextField>
                                    )}
                                </Flex>
                            </td>
                            <td>
                                <ValueSymbol value={log.cashInitialAmount + balance} />
                            </td>
                        </tr>
                        <tr>
                            <td>Saldo Final</td>
                            <td>
                                <ValueSymbol value={log.cashFinalAmount} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Flex column>
                                    <TextField>Diferencia</TextField>
                                    {!printMode && (
                                        <TextField small light>
                                            Debe haber - Saldo final
                                        </TextField>
                                    )}
                                </Flex>
                            </td>
                            <td>
                                <ValueSymbol value={log.cashDifferenceAmount} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Flex>
        </>
    );
};

const CorrectionsPanel = (props: { log: CashRegisterLog; flexProps: any; printMode?: boolean }) => {
    const { log, flexProps } = props;
    const correctionItems = log.correctionItems ?? [];

    if (correctionItems.length === 0) return null;

    return (
        <Flex {...flexProps}>
            <Flex column gap5>
                <LogNavigationTitle>Correcciones</LogNavigationTitle>
                {!props.printMode && (
                    <TextField>
                        Las correcciones se crean cuando se modifica o elimina un pago, o cuando se anula una orden, Las correcciones no
                        afectan el balance general.
                    </TextField>
                )}
            </Flex>
            {correctionItems.map((item) => {
                let entries = item.entries ?? [];
                return (
                    <Flex gap15>
                        <Flex column>
                            {!props.printMode && <TextField>Orden</TextField>}
                            <TextField bold>#{item.referenceNumber}</TextField>
                        </Flex>
                        {entries.map((entry, index) => {
                            let entryValue = entry.cashRegisterEntry;
                            if (!entryValue) return null;

                            const isLast = index === entries.length - 1;

                            return (
                                <React.Fragment key={index}>
                                    <Flex column alignStart>
                                        <Flex gap10 alignCenter nowrap>
                                            <ValueSymbol value={entryValue.amount} negative={!entryValue.isIncome} />
                                            <span>
                                                <i className="fa-regular fa-clock"></i> {getDateFormat(entryValue.dateCreated, "HH:mm")}
                                            </span>
                                        </Flex>
                                        {!props.printMode && (
                                            <Flex gap5 alignCenter>
                                                <TextField small light>
                                                    {entryValue.description}
                                                </TextField>
                                            </Flex>
                                        )}
                                    </Flex>
                                    {!isLast && <span className="fa-regular fa-arrow-right"></span>}
                                </React.Fragment>
                            );
                        })}
                    </Flex>
                );
            })}
        </Flex>
    );
};

export const CashRegisterLogEntriesView = ({ log }: { log: CashRegisterLog }) => {
    const [pagination, setPagination] = useState<IPaginationResponse<ICashRegisterEntry>>();

    const listMutation = useMutation((data: CashRegisterLogEntriesSearch) => postCashRegisterLogGetEntries(data), {
        onSuccess: (data) => {
            setPagination(data);
        },
    });

    const correctionEntriesIds = React.useMemo(() => {
        const correctionItems = log.correctionItems ?? [];
        const entriesIds: { [key: string]: boolean } = {};
        correctionItems.forEach((item) => {
            const entries = item.entries ?? [];
            entries.forEach((entry) => {
                entriesIds[entry.cashRegisterEntryId] = true;
            });
        });

        return entriesIds;
    }, [log.correctionItems]);

    const handleSearch = (page: number = 1) => {
        listMutation.mutate({
            cashRegisterLogId: log.id,
            pageSize: 10,
            currentPage: page,
        });
    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Flex padding={20} column w100 boxSizingBorderBox>
            {pagination?.items.map((item) => {
                const isCorrection = correctionEntriesIds[item.id ?? "NA"];
                if (isCorrection) return null;

                return <CashRegisterEntryCard key={item.id} entry={item} onEntryChange={() => {}} />;
            })}
            <Flex gap10 alignCenter>
                {pagination && <PaginationView pagination={pagination} onPageChange={(page) => handleSearch(page)} />}
                {listMutation.isLoading && <LoadingDualRing small />}
            </Flex>
        </Flex>
    );
};
