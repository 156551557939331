import { LocalStoreKeys, getStoredData } from "../store/index";
import {
    AppSettingsTypes,
    CHANGE_MENU_SMALL,
    IAppSettingsState,
    SET_ALL_SETTINGS,
    SET_DEFAULT_LOUNGE,
    SET_DEFAULT_ORGANIZATION,
    SET_DEFAULT_PRICE,
    SET_DEFAULT_WAREHOUSE,
    SET_DELIVERY_SUMMARY,
    SET_LANGUAGE,
    SET_LIST_PRODUCT_SHAPE,
    getDefaultAppSettings,
} from "./types";

const getInitialState = () => {
    const defaultSettings = getDefaultAppSettings();

    var existing: IAppSettingsState = getStoredData<IAppSettingsState>(defaultSettings, LocalStoreKeys.appSettings);
    if (existing) {
        if (!existing.deliverySummary) {
            existing.deliverySummary = defaultSettings.deliverySummary;
        }

        return existing;
    }
    return defaultSettings;
};

export const initState: IAppSettingsState = getInitialState();

export const appSettingsReducer = (state: IAppSettingsState, action: AppSettingsTypes): IAppSettingsState => {
    switch (action.type) {
        case SET_LIST_PRODUCT_SHAPE:
            return {
                ...state,
                productListShape: action.shape,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.value,
            };
        case SET_DEFAULT_PRICE:
            return {
                ...state,
                defaultPrice: action.value,
            };
        case SET_DEFAULT_ORGANIZATION:
            return {
                ...state,
                defaultOrganizationId: action.value,
            };
        case SET_DEFAULT_WAREHOUSE:
            return {
                ...state,
                defaultWarehouseId: action.value,
            };
        case SET_DEFAULT_LOUNGE:
            return {
                ...state,
                defaultLoungeId: action.value,
            };
        case SET_DELIVERY_SUMMARY:
            return {
                ...state,
                deliverySummary: action.value,
            };
        case CHANGE_MENU_SMALL:
            return {
                ...state,
                menuSmall: !state.menuSmall,
            };
        case SET_ALL_SETTINGS:
            return action.value;

        default:
            return state;
    }
};
