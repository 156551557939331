import React, { useContext, useEffect, useState } from "react";
import { IOrderPayment } from "../../../api/models/orderPayment";
import { IPurchase } from "../../../api/models/purchase";
import { CloudImages } from "../../../assets";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { formatMoney } from "../../../utils";
import { getTotalPayments } from "../../Orders/CreateOrderForm/FinalizeOrderForm/finalizeHelper";
import PaymentsForm from "../../Payments/PaymentsForm";
import usePaymentHook from "../../Payments/paymentHooks";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, TextField } from "../../_controls";
import Modal, { IModalShow } from "../../modals/Modal";

interface SinglePaymentModalProps extends IModalShow {
    purchase: IPurchase;
    onAddPayment: (value: IOrderPayment[]) => void;
}

export default function SinglePaymentModal(props: SinglePaymentModalProps) {
    const { purchase } = props;
    const { organizationState } = useContext(OrganizationContext);
    const { cashRegisterState } = useContext(CashRegisterContext);

    const [payments, setPayments] = useState<IOrderPayment[]>([]);

    const paymentHook = usePaymentHook();

    useEffect(() => {
        let payment = paymentHook.getNewPayment();
        if (payment) setPayments([payment]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const totalPay = getTotalPayments([...purchase.payments, ...payments]);
    const absoluteAmount = Math.round(purchase.totalAmount * 100) / 100;
    const differencePrices = absoluteAmount - totalPay;

    const blockPayments = organizationState.currentOrganization?.requiredCashRegister && !cashRegisterState.logActive;
    if (blockPayments) {
        return (
            <Modal {...props} showHeader={false} useButtonClose title="">
                <Flex column alignCenter padding={30} gap15>
                    <Flex>
                        <FlexImageStyle>
                            <img src={CloudImages.lockPayment} alt="" />
                        </FlexImageStyle>
                    </Flex>
                    <Flex column alignCenter>
                        <span className="text-light">Pago bloqueado</span>
                        <span className="">Se requiere activar la caja para crear pagos</span>
                    </Flex>
                </Flex>
            </Modal>
        );
    }

    return (
        <Modal {...props} showHeader={true} title="Crear nuevo pago">
            <Flex column gap15 padding={20}>
                <Flex gap15>
                    <Flex w100 column>
                        <TextField light small>
                            Total Compra
                        </TextField>
                        <TextField>{formatMoney(purchase.totalAmount)}</TextField>
                    </Flex>
                    <Flex w100 column>
                        <TextField light small>
                            Pago Pendiente
                        </TextField>
                        <TextField>{formatMoney(differencePrices)}</TextField>
                    </Flex>
                    <Flex w100 column>
                        <TextField light small>
                            Pago Acumulado
                        </TextField>
                        <TextField>{formatMoney(purchase.totalPayment)}</TextField>
                    </Flex>
                </Flex>
                <PaymentsForm payments={payments ?? []} onChange={setPayments} uniqueMethod={false} totalAmount={absoluteAmount} />

                <Flex gap5 alignEnd justifyEnd marginTop={10} marginBottom={10}>
                    <DefaultButton borderRadius={10} className="mr-1" onClick={() => props.setShow(false)}>
                        Cancel
                    </DefaultButton>
                    <PrimaryButton
                        borderRadius={10}
                        onClick={() => {
                            props.onAddPayment(payments);
                            props.setShow(false);
                        }}
                    >
                        {payments.length > 1 ? "Agregar pagos" : "Agregar pago"}
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
}
