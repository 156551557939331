import { NavigationOption } from "../../NavigationPanel/navigationTypes";

export enum NavigationGroupName {
    Profile = "profile",
    Files = "files",
    Details = "details",
    Receive = "receive",
}

export const MENU_OPTIONS: NavigationOption<NavigationGroupName>[] = [
    {
        tabName: NavigationGroupName.Profile,
        icon: "fa-regular fa-home",
        title: "Perfil",
    },
    {
        tabName: NavigationGroupName.Receive,
        icon: "fa-regular fa-truck",
        title: "Recibir",
    },
    {
        tabName: NavigationGroupName.Files,
        icon: "fa-regular fa-file",
        title: "Archivos",
    },

    {
        tabName: NavigationGroupName.Details,
        icon: "fa-regular fa-file",
        title: "Detalles",
    },
];
