import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { getAccountUserFullName } from "../../../api/models/accountUser";
import { IOrderDelete, Order } from "../../../api/models/order";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { getDateFormat } from "../../../utils";
import CardIcon from "../../_Cards/CardIcon";
import { Table } from "../../_controls/tables/styled";
import { ORDER_STATUS_STYLE } from "../OrderStatusFilter/helpers";
import messages from "./messages";
import { OrderRejectedCard } from "./styled";

interface OrderRejectedProps {
    order: Order;
    orderDelete?: IOrderDelete;
}

export default function OrderVoidPanel(props: OrderRejectedProps) {
    const { order, orderDelete } = props;
    const { cashRegisterState } = useContext(CashRegisterContext);
    const { getUserById } = useAccountUserContext();

    const orderStatus = ORDER_STATUS_STYLE[order.status];

    const intl = useIntl();
    return (
        <OrderRejectedCard>
            <CardIcon
                iconColor="#f44336"
                icon={orderStatus.icon}
                title={`#${order.number}`}
                smallTitle={intl.formatMessage(messages[order.status])}
            />

            <Table tdPadding={3} hideBorde tdPaddingRight={15} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td className="min-width">
                            <span className="fa-light fa-user"></span>
                        </td>
                        <td>
                            <span>{getAccountUserFullName(getUserById(orderDelete?.accountUserId))}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="min-width">
                            <span className="fa-light fa-calendar"></span>
                        </td>
                        <td>
                            <span>{getDateFormat(orderDelete?.dateDeleted, "DD MMM YYYY hh:mm:a")}</span>
                        </td>
                    </tr>

                    {orderDelete?.comment && (
                        <tr>
                            <td className="min-width">
                                <i className="fa-light fa-message"></i>
                            </td>
                            <td>
                                <span>{orderDelete?.comment ?? "---"}</span>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td className="min-width">
                            <span className="fa-light fa-landmark"></span>
                        </td>
                        <td>
                            <span>{cashRegisterState.orgItems.find((x) => x.id === orderDelete?.cashRegisterId)?.name}</span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </OrderRejectedCard>
    );
}
