import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../../api";
import { getAccountBillingNextDatePlanChange, putAccountUpdatePlan } from "../../../../api/account/accountApi";
import { waitFetch } from "../../../../api/fetchApi";
import { BillingCycle, BillingInvoice } from "../../../../api/models/accountBilling";
import {
    AccountBillingNextDateInput,
    AccountBillingNextDateResponse,
    AccountPaymentMethod,
    AccountPlan,
    AccountUpdatePlanInput,
    getUserPermissions,
} from "../../../../api/models/accountUser";
import { IWompiCheckTransactionResponse } from "../../../../api/models/wompi";
import { useWompiPayments } from "../../../../api/wompi/hooks";
import { getAuthBody } from "../../../../auth";
import { useUserContext } from "../../../../store/contexts/UserContext";
import { formatMoney, getDateFormat, getRoundNumber, getUniqueId } from "../../../../utils/index";
import customMoment from "../../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import PermissionDenied from "../../../PermissionDenied";
import { Flex } from "../../../_controls";
import { DefaultButton, PrimaryButton } from "../../../_controls/buttons/index";
import Modal from "../../../modals/Modal";
import { BillingPaymentMethodSelector } from "../BillingPaymentMethod/BillingPaymentMethodSelector";
import StripePaymentSetupForm from "../BillingPaymentMethod/StripePaymentSetupForm";
import { getSignatureInput, getTransactionSourceInput } from "../helpers";
import messages from "../messages";

interface ChangeAccountPlanFormProps {
    onCancel: () => void;
    onCreate: (invoice: BillingInvoice) => void;
    costPerUser: number;
    plan: AccountPlan;
    cycle: BillingCycle;
}

export const ChangeAccountPlanForm = (props: ChangeAccountPlanFormProps) => {
    const { userState, userActions, methodList } = useUserContext();
    const alert = useAlert();
    const account = userState.user?.account;
    const billing = account?.billing;
    const intl = useIntl();
    const wompiPayments = useWompiPayments();
    const [paymentMethodSelected, setPaymentMethodSelected] = useState<AccountPaymentMethod>();

    const costPerUserSeller = billing && billing.costPerUserSeller > 0 ? billing?.costPerUserSeller : 15000;

    const [planTotals, setPlanTotals] = useState({
        previousCostUser: 0,
        previousCostSeller: 0,
        actualCostUser: 0,
        actualCostSeller: 0,
        totalPreviousCost: 0,
        totalActualCost: 0,
        totalCredits: 0,
        costPerBilledDays: 0,
        totalAmountWithoutCredit: 0,
        totalAmount: 0,
        totalAmountInCents: 0,
        billedDays: 0,
    });

    const accountPlanMutation = useMutation((data: AccountUpdatePlanInput) => putAccountUpdatePlan(data));
    const [isFetchingTransaction, setIsFetchingTransaction] = useState(false);
    const [showModalCreatePaymentMethod, setShowModalCreatePaymentMethod] = useState(false);

    const nextBillingDate = useMutation((data: AccountBillingNextDateInput) => getAccountBillingNextDatePlanChange(data), {
        onSuccess: (data) => {
            handleCalculateTotals(data);
        },
        onError: (data) => {
            console.log("ERROR: ", data);
        },
    });

    const handleCalculateTotals = (data: AccountBillingNextDateResponse) => {
        if (billing && account) {
            const previousUserCost = billing.costPerUser * account.userLicenses;
            const previousSellerCost = costPerUserSeller * account.sellerLicenses;

            const actualUserCost = props.costPerUser * account.userLicenses;
            const actualSellerCost = costPerUserSeller * account.sellerLicenses;

            const totalPreviousCost = previousUserCost + previousSellerCost;
            const totalActualCost = actualUserCost + actualSellerCost;

            let costPerBilledDays = getRoundNumber(data.costPerBilledDays + data.costPerBilledDaysSeller);

            let totalAmount = getRoundNumber(costPerBilledDays * account.userLicenses);

            const totalAmountWithoutCredit = (totalActualCost / data.totalDays) * data.billedDays;

            let totalCredits = totalAmountWithoutCredit - totalAmount;

            if (totalAmount < 0) {
                totalAmount = 0;
                totalCredits = 0;
                costPerBilledDays = 0;
            }

            setPlanTotals({
                previousCostUser: previousUserCost,
                previousCostSeller: previousSellerCost,
                actualCostUser: actualUserCost,
                actualCostSeller: actualSellerCost,
                totalPreviousCost: totalPreviousCost,
                totalActualCost: totalActualCost,
                totalCredits,
                costPerBilledDays,
                totalAmount,
                totalAmountWithoutCredit,
                billedDays: data.billedDays,
                totalAmountInCents: Math.trunc(getRoundNumber(totalAmount)) * 100,
            });
        }
    };

    useEffect(() => {
        const data: AccountBillingNextDateInput = {
            accountId: getAuthBody().accountId,
            newCostPerUser: props.costPerUser,
        };
        nextBillingDate.mutate(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (account && account.defaultPaymentMethodId && methodList) {
            const matchMethodResult = methodList.find((x) => x.id === account?.defaultPaymentMethodId);
            if (matchMethodResult) {
                setPaymentMethodSelected(matchMethodResult);
            } else {
                setPaymentMethodSelected(methodList[0]);
            }
        } else {
            setPaymentMethodSelected(account && methodList?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.defaultPaymentMethodId, methodList]);

    const getTransactionAndPayInvoiceRetry = (transactionId: string, paymentAmount: number, tries: number) => {
        const onError = (err: any): any => {
            tries -= 1;
            if (tries <= 0) {
                alert.error("Hubo un error al obtener el pago");
                throw err;
            }

            return waitFetch(3000).then(() => getTransactionAndPayInvoiceRetry(transactionId, paymentAmount, tries));
        };

        return wompiPayments.getTransactionMutation
            .mutateAsync(transactionId)
            .then((transaction) => {
                if (transaction.data.status !== "APPROVED") {
                    throw new Error("No fue posible obtener un estado aprobado del pago");
                }
                handleUpdateAccountPlan(transaction);
            })
            .catch((err) => onError(err));
    };

    const handleUpdateAccountPlan = (transaction?: IWompiCheckTransactionResponse) => {
        if (!account || !billing) return;

        let data: AccountUpdatePlanInput = {
            licenses: account?.userLicenses,
            costPerUser: props.costPerUser,
            costPerBilledDays: planTotals.costPerBilledDays,
            accountId: getAuthBody().accountId,
            billedDays: planTotals.billedDays,
            paymentAmount: planTotals.totalAmount,
            newPlan: props.plan,
            cycle: props.cycle,
            paymentMethod: "CARD",
            paymentSource: "wompi",
            paymentSourceTransactionId: transaction?.data.id ?? "NA",
        };

        accountPlanMutation
            .mutateAsync(data)
            .then((response) => {
                alert.success("La factura ha sido pagada con éxito");
                userActions.requestAccountUser(getAuthBody().accountUserId);
                props.onCreate(response.invoice);
            })
            .catch((err) => {
                alert.error(getServiceMessageError(err));
            });
    };

    const onCreatePayment = async () => {
        if (!paymentMethodSelected) {
            alert.info("Debes agregar un método para realizar el pago");
            return;
        }

        if (planTotals.totalAmount === 0) {
            handleUpdateAccountPlan();
            return;
        }

        setIsFetchingTransaction(true);

        try {
            const referenceId = getUniqueId();
            const amountInCents = planTotals.totalAmountInCents;
            const inputIntegrity = getSignatureInput(referenceId, amountInCents.toString());
            const signatureRes = await wompiPayments.wompiIntegrityMutation.mutateAsync(inputIntegrity);
            const paymentMethod = paymentMethodSelected;
            const transactionPaymentSource = getTransactionSourceInput(
                amountInCents,
                userState.user?.email ?? "NA",
                signatureRes.signature,
                referenceId,
                paymentMethod.token
            );

            const transactionResult = await wompiPayments.createTransactionMutation.mutateAsync(transactionPaymentSource);
            await getTransactionAndPayInvoiceRetry(transactionResult.data.id, planTotals.totalAmount, 6);
        } catch (err) {
            alert.error(getServiceMessageError(err));
        }
        setIsFetchingTransaction(false);
    };

    const paymentIsLoading = wompiPayments.createTransactionMutation.isLoading || isFetchingTransaction || accountPlanMutation.isLoading;

    const permission = getUserPermissions(userState.user);

    if (!account || !billing) return null;
    if (!permission.billing?.allowCreatePayments) return <PermissionDenied message="No tienes permisos para realizar pagos" />;

    if (nextBillingDate.isLoading) return <LoadingDualRing center />;
    if (!nextBillingDate.data) return null;
    if (billing.cycle === "annual" && props.cycle === "monthly" && nextBillingDate.data && nextBillingDate.data.billedDays > 28) {
        const daysMissing = nextBillingDate.data.billedDays - 28;

        return (
            <PermissionDenied
                message={`No es posible cambiar el plan a mensual aun, podrás cambiar el contrato anual a mensual a partir de: ${getDateFormat(
                    customMoment().add(daysMissing, "days").toDate(),
                    "DD/MM/YY"
                )}`}
            />
        );
    }

    return (
        <Flex w100 column padding={5} gap15>
            <h2 className="m-0">Cambiar Plan</h2>
            {showModalCreatePaymentMethod && (
                <Modal show={showModalCreatePaymentMethod} useButtonClose ignoreOutsideClick setShow={setShowModalCreatePaymentMethod}>
                    <Flex padding={20} w100 boxSizingBorderBox>
                        <StripePaymentSetupForm />
                    </Flex>
                </Modal>
            )}

            <Flex column gap10 marginTop={15} marginBottom={20}>
                <Flex paddingBottom={10} minWidth={200} alignCenter spaceBetween className="border-dashed">
                    <Flex column>
                        <span className="text-light ">Plan Anterior</span>
                        <span>Usuarios</span>
                        <span>Vendedores</span>
                    </Flex>
                    <Flex column>
                        <span className="text-light ">{formatMoney(planTotals.totalPreviousCost)}</span>
                        <span className="text-light ">{formatMoney(planTotals.previousCostUser)}</span>
                        <span className="text-light ">{formatMoney(planTotals.previousCostSeller)}</span>
                    </Flex>
                </Flex>
                <Flex paddingBottom={10} minWidth={200} alignCenter spaceBetween className="border-dashed">
                    <Flex column>
                        <span className="text-light ">
                            {formatMoney(props.costPerUser)} x {account.userLicenses} usuarios x 1 {props.cycle}
                        </span>
                        <span className="text-light ">
                            {formatMoney(costPerUserSeller)} x {account.sellerLicenses} vendedores x 1 {props.cycle}
                        </span>
                        <span>Nuevo costo a partir del {getDateFormat(nextBillingDate.data.nextDate, "DD MMM YY")}</span>
                    </Flex>

                    <Flex column>
                        <span className="text-light ">{formatMoney(planTotals.actualCostUser)}</span>
                        <span className="text-light ">{formatMoney(planTotals.actualCostSeller)}</span>
                        <span className="text-light ">{formatMoney(planTotals.totalActualCost)}</span>
                    </Flex>
                </Flex>

                <Flex paddingBottom={10} minWidth={200} alignCenter spaceBetween className="border-dashed">
                    <Flex column>
                        <span className="text-light ">Plan {intl.formatMessage(messages[props.plan])}</span>
                    </Flex>
                    <span className="text-light ">{formatMoney(planTotals.totalActualCost)}</span>
                </Flex>
                <Flex paddingBottom={10} minWidth={200} alignCenter spaceBetween className="border-dashed">
                    <Flex column>
                        <span className="text-light ">Total ({planTotals.billedDays} días)</span>
                    </Flex>
                    <span className="text-light ">{formatMoney(planTotals.totalAmountWithoutCredit)}</span>
                </Flex>
                <Flex paddingBottom={10} minWidth={200} alignCenter spaceBetween className="border-dashed">
                    <Flex column>
                        <span className="text-light ">Créditos ({planTotals.billedDays} días)</span>
                    </Flex>
                    <span className="text-light ">-{formatMoney(planTotals.totalCredits)}</span>
                </Flex>

                <Flex paddingBottom={10} minWidth={200} alignCenter spaceBetween className="border-dashed">
                    <Flex column>
                        <span className="">Total - Créditos ({planTotals.billedDays} días)</span>
                    </Flex>
                    <Flex column>
                        <span className="text-bold">{formatMoney(planTotals.totalAmount)}</span>
                    </Flex>
                </Flex>
            </Flex>

            {planTotals.totalAmount > 0 && methodList && methodList.length > 0 && paymentMethodSelected && (
                <BillingPaymentMethodSelector
                    methodSelected={paymentMethodSelected}
                    onChangeMethodSelected={setPaymentMethodSelected}
                    setShowModalCreatePaymentMethod={setShowModalCreatePaymentMethod}
                />
            )}
            {planTotals.totalAmount > 0 && account && methodList?.length === 0 && (
                <Flex padding={15} alignCenter justifyCenter border borderRadius={15} borderDashed gap10>
                    <DefaultButton onClick={() => setShowModalCreatePaymentMethod(true)} rounded hideBorder colorLight>
                        <span className="wahioicon-plus"></span>
                        Agregar método de pago
                    </DefaultButton>
                </Flex>
            )}

            {planTotals.totalAmount === 0 && (
                <Flex className="mt-1">
                    <span>No tienes que pagar nada</span>
                </Flex>
            )}

            {paymentIsLoading && <LoadingDualRing center />}
            {!paymentIsLoading && (
                <Flex gap15 justifyEnd className="mt-2">
                    <DefaultButton rounded onClick={() => props.onCancel()}>
                        Cancelar
                    </DefaultButton>

                    <PrimaryButton rounded onClick={() => onCreatePayment()}>
                        {planTotals.totalAmount > 0 ? (
                            <>
                                <span className="wahioicon-credit-card" /> Pagar y Cambiar
                            </>
                        ) : (
                            <>Cambiar Plan</>
                        )}
                    </PrimaryButton>
                </Flex>
            )}
        </Flex>
    );
};
