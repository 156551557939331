import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useEventListenerWindow } from "../..";
import { getUniqueId, ignoreElementClickByIds } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
import LoadingDualRing from "../../LoadingDualRing";
import { DefaultTextarea, Flex, GridTemplate, SquareButton, TextField } from "../../_controls";
import { EMOJI_CATEGORIES, POPULAR_EMOJIS, EMOJI_LIST_ALL, EmojiItem } from "../compactEmojiList";

import messages from "./messages";
import { EmojisContainer, TextareaChatContainer } from "./styled";

interface TextareaChatProps {
    placeholder?: string;
    onSendMessage: (text: string) => void;
    onInputChange?: (text: string) => void;
    removePadding?: boolean;
    initialValue?: string;
    isLoading?: boolean;
    hideSendButton?: boolean;
    withBorder?: boolean;
    autofocus?: boolean;
}

export default function TextareaChat(props: TextareaChatProps) {
    const [cardId] = useState(() => getUniqueId());
    const [showEmojis, setShowEmojis] = useState(false);
    const [emojiLoaded, setEmojiLoaded] = useState(false);
    const [inputValue, setInputValue] = useState(props.initialValue ?? "");
    const inputRef = React.useRef<any>();

    const [categoryActive, setCategoryActive] = useState("popular");
    const [recentEmojis, setRecentEmojis] = useLocalStorage<string[]>("recentEmojis", []);

    const emojiListCategory = React.useMemo(() => {
        if (!categoryActive) {
            return [];
        }
        return EMOJI_LIST_ALL[categoryActive] ?? [];
    }, [categoryActive]);

    const popularList = React.useMemo(() => {
        let recentList: EmojiItem[] = recentEmojis.map((item) => {
            return { emoji: item, description: item };
        });

        return [...recentList, ...POPULAR_EMOJIS];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [POPULAR_EMOJIS, recentEmojis]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = `auto`;
            inputRef.current.style.height = `${inputRef.current.scrollHeight + 2}px`;
        }
    }, []);

    useEffect(() => {
        onUpdateEmojisContainer();
        if (!showEmojis && emojiLoaded) {
            setEmojiLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEmojis]);

    useEventListenerWindow("click", (e) => {
        var ignore = ignoreElementClickByIds(e.target, [`button_emoji_${cardId}`, `emojis_${cardId}`]);
        if (!ignore) {
            setShowEmojis(false);
        }
    });

    const onUpdateEmojisContainer = () => {
        let container = document.getElementById(`emojis_${cardId}`);
        let button = document.getElementById(`button_emoji_${cardId}`);

        if (container && button) {
            container.style.right = `${window.innerWidth - button.getBoundingClientRect().x}px`;
            container.style.bottom = `${window.innerHeight - button.getBoundingClientRect().y}px`;

            setEmojiLoaded(true);
        }
    };

    const onShowEmojis = () => {
        setShowEmojis(!showEmojis);
    };

    const onClickEmoji = (item: EmojiItem) => {
        setShowEmojis(false);

        let newValue = inputValue + item.emoji;
        setInputValue(newValue);
        if (props.onInputChange) props.onInputChange(newValue);
        let input = document.getElementById(`input_${cardId}`);
        if (input) input.focus();
    };

    const onClickSendMessage = () => {
        if (_.isEmpty(inputValue.trim())) return;
        props.onSendMessage(inputValue);
        setInputValue("");
    };

    return (
        <TextareaChatContainer className={props.removePadding ? "padding-none" : ""}>
            {showEmojis && (
                <EmojisContainer id={`emojis_${cardId}`} className={emojiLoaded ? "show" : ""}>
                    <Flex className="category-emojis">
                        {EMOJI_CATEGORIES.map((item, index) => {
                            return (
                                <a
                                    key={index}
                                    className={item.category === categoryActive ? "category-active" : ""}
                                    href={`#${item.category}`}
                                    onClick={() => setCategoryActive(item.category)}
                                >
                                    <i className={item.icon} />
                                </a>
                            );
                        })}
                    </Flex>

                    <div className="emoji-grid">
                        {categoryActive === "popular" && (
                            <EmojiContainer list={popularList} category={"popular"} onClickEmoji={onClickEmoji} />
                        )}
                        {emojiListCategory.length > 0 && (
                            <EmojiContainer
                                list={emojiListCategory}
                                category={categoryActive}
                                onClickEmoji={(item) => {
                                    onClickEmoji(item);
                                    if (!recentEmojis.includes(item.emoji)) {
                                        let newItems = [item.emoji, ...recentEmojis].slice(0, 10);
                                        setRecentEmojis(newItems);
                                    }
                                }}
                            />
                        )}
                    </div>
                </EmojisContainer>
            )}
            <Flex className="message-input-body" alignCenter>
                <DefaultTextarea
                    id={`input_${cardId}`}
                    className={`message-textarea ${props.withBorder ? "with-border" : ""}`}
                    value={inputValue}
                    ref={inputRef}
                    disabled={props.isLoading}
                    rows={1}
                    autoFocus={props.autofocus}
                    autoComplete="off"
                    onClick={(e: any) => {
                        if (inputRef.current) {
                            inputRef.current.style.height = `auto`;
                            inputRef.current.style.height = `${e.target.scrollHeight + 2}px`;
                        }
                    }}
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            onClickSendMessage();
                        }
                    }}
                    onKeyUp={(e: any) => {
                        if (inputRef.current) {
                            inputRef.current.style.height = `auto`;
                            inputRef.current.style.height = `${e.target.scrollHeight + 2}px`;
                        }
                    }}
                    tabIndex={-1}
                    onChange={(e) => {
                        if (props.onInputChange) props.onInputChange(e.target.value);
                        setInputValue(e.target.value);
                    }}
                    placeholder={props.placeholder ?? "Escribir mensaje"}
                />
                <SquareButton className="btn-emoji" onClick={onShowEmojis} id={`button_emoji_${cardId}`}>
                    <i className="fa-regular fa-face-smile"></i>
                </SquareButton>
            </Flex>

            {!props.hideSendButton && (
                <SquareButton disabled={props.isLoading} onClick={onClickSendMessage} id={`button_send_${cardId}`}>
                    {props.isLoading ? <LoadingDualRing small /> : <i className="fa-regular fa-paper-plane-top"></i>}
                </SquareButton>
            )}
        </TextareaChatContainer>
    );
}

interface EmojiContainerProps {
    category: string;
    list: EmojiItem[];
    onClickEmoji: (item: EmojiItem) => void;
}

export const EmojiContainer = (props: EmojiContainerProps) => {
    const { category, list, onClickEmoji } = props;
    const intl = useIntl();
    return (
        <div className="emoji-grid-items" id={category}>
            <TextField light bold id={category} className="category-line">
                {intl.formatMessage((messages as any)[category])}
            </TextField>
            <GridTemplate size={40} className="emoji-list mt-1" gap={5}>
                {list.map((emoji: EmojiItem, key) => (
                    <span
                        className="emoji-item"
                        onClick={() => {
                            onClickEmoji(emoji);
                        }}
                        key={key}
                    >
                        {emoji.emoji}
                    </span>
                ))}
            </GridTemplate>
        </div>
    );
};
