import { SIGNIN_ENDPOINT, SIGNOUT_ENDPOINT } from "../api/appConfig";
import { setDefaultToken } from "../api/fetchApi";
import { addParamToUrl, deleteLocalStorage, deleteCookie, getEncryptedCookie, setCookieAlreadyEncrypted } from "../utils";

export const AUTH_KEY = "auth";

export interface IAuthBody {
    accountId: string;
    accountUserId: string;
    token: string;
}

export const saveAuthBody = (value: string) => {
    deleteCookie(AUTH_KEY);
    setCookieAlreadyEncrypted(AUTH_KEY, value);
    const session = getAuthBody();
    setDefaultToken(session.token);
};

export const getAuthCookie = () => {
    const value = getEncryptedCookie(AUTH_KEY, true);
    return value;
};

export const getAuthBody = () => {
    let auth: IAuthBody = getEncryptedCookie(AUTH_KEY);
    if (!auth) {
        return {
            accountId: "",
            accountUserId: "",
            token: "",
        };
    }
    return auth;
};

export const clearLocalAuth = () => {
    deleteCookie(AUTH_KEY);
};

export const clearAuthSession = () => {
    const authBody = getAuthBody();
    deleteCookie(AUTH_KEY);
    deleteLocalStorage();
    const newurl = addParamToUrl(SIGNOUT_ENDPOINT, "id", authBody.accountUserId);
    window.location.replace(newurl);
};

export const goToSignIn = () => {
    const thisUrl = window.location.origin;
    const newurl = addParamToUrl(SIGNIN_ENDPOINT, "origin", thisUrl);
    window.location.replace(newurl);
};

export const checkAuthBody = () => {
    if (!getAuthBody()) {
        goToSignIn();
    }
};

export const resolveAuthSession = (authParam?: string | null): IAuthBody | undefined => {
    const authBody = getAuthBody();

    if (authParam) {
        saveAuthBody(authParam);
        return getAuthBody();
    }

    if (authBody && authBody.accountId && authBody.token) {
        return authBody;
    }

    goToSignIn();
};
