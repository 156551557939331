import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { IOrderReturnPayment } from "../../../api/models/orderPayment";
import { IPurchaseReturnItem } from "../../../api/models/orderReturn";
import { ProductPriceTypes } from "../../../api/models/product";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { IPurchaseReturn, PurchaseReturn, loadPurchaseReturnItem } from '../../../api/models/purchaseReturns';
import { purchaseCreateReturn } from "../../../api/purchases/purchaseApi";
import { purchaseEndpoint } from "../../../api/restApiEndpoint";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { formatMoney } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import Alert from "../../Alerts/AlertView";
import { getOrgCashRegisterOrDefault } from "../../CashRegisters/cashRegisterUtils";
import LoadingDualRing from "../../LoadingDualRing";
import { calculateTotal, calculateTotalManualDiscount, calculateTotalTax } from "../../Orders/CreateOrderForm/helpers";
import { GridTabLine, OrderReturnFormContainer, OrderReturnProductStyle } from "../../Orders/OrderReturns/styled";
import PaymentsForm from "../../Payments/PaymentsForm";
import usePaymentHook from "../../Payments/paymentHooks";
import { PriceListDropdown } from "../../Products/PriceList";
import { ProductAddOption } from "../../Products/ProductCard";
import ProductSearchListModal from "../../Products/ProductSearchList/ProductSearchListModal";
import { DefaultButton, Flex, GridTemplate, PrimaryButton, SquareButton, TextField } from "../../_controls";
import InputTimer from "../../_controls/inputs/InputTimer";
import { Table } from "../../_controls/tables/styled";
import Modal, { IModalShow } from "../../modals/Modal";
import { insertProductInOrderList } from "../CreatePurchaseForm/helpers";
import { PurchaseItemRowContainer } from "../PurchaseItemRow";
import { PurchaseProductCard, PurchaseReturnItemCard } from "./PurchaseProductCard";
import messages from "../../Orders/OrderReturns/messages";

interface PurchaseReturnFormProps extends IModalShow {
    purchaseProps?: IPurchase;
    onCreate: (order:IPurchaseReturn) => void;
    onCloseModal?: () => void;
}

interface PurchaseReturnFormState {
    purchase?: IPurchase;
    isFetching: boolean;
    searchOrderNumber: string;
}

export const PurchaseReturnForm = (props: PurchaseReturnFormProps) => {
    const intl = useIntl();
    const alert = useAlert();
    const paymentHook = usePaymentHook();
    const { appSettingsState } = useContext(AppSettingsContext);
    const { organizationState } = useContext(OrganizationContext);
    const [purchaseReturnTotals, setPurchaseReturnTotals] = useState({ returnAmount: 0, returnNewItemsAmount: 0 });
    const [purchaseReturn, setPurchaseReturn] = useState<IPurchaseReturn>(() => {
        const newPurchaseReturn = new PurchaseReturn(getAuthBody().accountId, getAuthBody().accountUserId)
        let newReturnPayment = paymentHook.getNewPayment();
        if (newReturnPayment) {
            newPurchaseReturn.returnPayments.push(newReturnPayment);
            newPurchaseReturn.payments.push(newReturnPayment);
        }
        return newPurchaseReturn;
    });
    const [state, setState] = useState<PurchaseReturnFormState>({
        isFetching: false,
        searchOrderNumber: "",
    })
    const [showProductSearchModal, setShowProductSearchModal] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState<ProductPriceTypes>("price");
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [purchaseItems, setPurchaseItems] = useState<IPurchaseItem[]>([]);

    useEffect(() => {
        calculatePurchaseReturnTotals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseReturn]);

    useEffect(() => {
        setPurchaseItems(getPurchaseItems());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.purchase, searchValue]);

    const getPurchaseItems = () => {
        let items = state.purchase?.items.filter(x => x.quantityReceived === x.quantity) ?? []
        if (searchValue) {
            return items.filter((x) => x.product?.name.toUpperCase().includes(searchValue.toUpperCase())) ?? [];
        }
        return items;
    };

    const purchaseCreateReturnMutation = useMutation((value: IPurchaseReturn) => purchaseCreateReturn(value), {
        onSuccess: (data) => {
            alert.success("Devolución creada correctamente");
            props.onCreate(data.purchaseReturn);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const calculatePurchaseReturnTotals = () => {
        let returnAmount = purchaseReturn.items.reduce((sum, element) => {
            sum += element.quantity * (element.unitPrice - (element.unitPrice * element.manualPercentageDiscount) / 100);
            return sum;
        }, 0);
        let returnNewItemsAmount = purchaseReturn.newItems.reduce((sum, element) => {
            sum += element.quantity * (element.unitPrice - (element.unitPrice * element.manualPercentageDiscount) / 100);
            return sum;
        }, 0);
        setPurchaseReturnTotals({
            returnAmount,
            returnNewItemsAmount,
        });
    };

    useEffect(() => {
        if (props.purchaseProps?.id) {
            setState({ ...state, isFetching: true });
            wahioFetch.get(
                purchaseEndpoint.get.purchaseById(props.purchaseProps.id),
                (success) => {
                    setState({ ...state, purchase: success.data, isFetching: false });
                },
                (error) => {
                    alert.error(getServiceMessageError(error));
                    setState({ ...state, isFetching: false });
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.purchaseProps]);

    const onClickProduct = (value: ProductAddOption) => {
        let newItems = insertProductInOrderList({
            items: purchaseReturn.newItems,
            product: value.product,
            productVariant: value.productVariant,
            warehouse: value.warehouse,
            price: value.customPrice,
            quantity: value.quantity,
            taxIncluded: true,
            preTaxDiscount: false,
        });

        alert.success(`${value.product.name} Agregado`);
        setPurchaseReturn({ ...purchaseReturn, newItems, totalAmountNewItems: calculateTotal(newItems), totalTaxItems: calculateTotalTax(newItems) });
    };

    const onClickPurchaseItem = (value: IPurchaseItem) => {
        let items: IPurchaseReturnItem[] = [];
        let exists = false;
        purchaseReturn.items.forEach((element) => {
            if (element.purchaseItemId === value.id) {
                let newQuantity = element.quantity + 1;
                if (newQuantity > value.quantity - value.quantityReturned) {
                    alert.info("No hay mas unidades disponibles");
                    items.push({ ...element });
                } else {
                    items.push({ ...element, quantity: newQuantity });
                }
                exists = true;
            } else {
                items.push(element);
            }
        });
        if (!exists) {
            items.push(loadPurchaseReturnItem(value));
            alert.success(value.product?.name + " agregado");
        }
        setPurchaseReturn({ ...purchaseReturn, items: items });
    };

    const onChangeReturnItem = (value: IPurchaseReturnItem) => {
        let items: IPurchaseReturnItem[] = [];

        purchaseReturn.items.forEach((element) => {
            if (element.purchaseItemId === value.purchaseItemId) {
                items.push(value);
            } else {
                items.push(element);
            }
        });

        setPurchaseReturn({ ...purchaseReturn, items: items });
    };

    const onRemoveReturnItem = (value: IPurchaseReturnItem) => {
        let items: IPurchaseReturnItem[] = purchaseReturn.items.filter((x) => x.purchaseItemId !== value.purchaseItemId);
        setPurchaseReturn({ ...purchaseReturn, items: items });
        if (items.length === 0) {
            alert.info("Debes incluir al menos un item para crear la devolucion");
        }
    };

    const handleCreateOrderReturn = () => {
        let purchaseReturnBody = { ...purchaseReturn };

        
        let cashRegisterId = getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization);

        if (!cashRegisterId) {
            alert.error("La Tienda actual debe tener una caja asignada, o debes activar una caja para poder agregar pagos");
            return;
        }

        purchaseReturnBody.purchaseId = state.purchase?.id ?? "";
        purchaseReturnBody.cashRegisterId = cashRegisterId;
        purchaseReturnBody.organizationId = organizationState.currentOrganization?.id ?? "";
        purchaseReturnBody.warehouseId = state.purchase?.warehouseId ?? "";

        purchaseCreateReturnMutation.mutate(purchaseReturnBody)
    };

    const addPayment = (paymentKey: "payments" | "returnPayments") => {
        let payment = paymentHook.getNewPayment();
        if (payment) {
            var newPayments: IOrderReturnPayment[] = [];
            if (paymentKey === "payments") {
                newPayments = [...purchaseReturn.payments, payment];
                setPurchaseReturn({ ...purchaseReturn, payments: newPayments });
            } else {
                newPayments = [...purchaseReturn.returnPayments, payment];
                setPurchaseReturn({ ...purchaseReturn, returnPayments: newPayments });
            }
        }
    };

    if (state.isFetching) {
        return <LoadingDualRing center />;
    }

    const updateReturnNewItems = (items: IPurchaseItem[]) => {
        const totalAmountItems = calculateTotal(items);
        const totalTaxItems = calculateTotalTax(items);
        const totalManualDiscount = calculateTotalManualDiscount(items);
        setPurchaseReturn({
            ...purchaseReturn,
            newItems: items,
            totalAmountNewItems: totalAmountItems,
            totalTaxItems,
            totalManualPercentageDiscountItems: totalManualDiscount,
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };

    const getTotalAmountReturn = (value: number) => {
        if (state?.purchase) {
            let diff = state.purchase.totalAmount - state.purchase?.totalPaid;
            if (state.purchase?.status === "partial" && value > diff) {
                return formatMoney(value - diff);
            } else return formatMoney(value);
        }
    };

    if (state.purchase) {
        let newTotalAmountDifference = purchaseReturnTotals.returnAmount - purchaseReturnTotals.returnNewItemsAmount;
        let totalNewPaymentAmount = newTotalAmountDifference < 0 ? newTotalAmountDifference * -1 : 0;

        let paymentsValue = purchaseReturn.payments.reduce((sum, item) => {
            sum += item.amount;
            return sum;
        }, 0);

        return (
            <Flex column>
                {showProductSearchModal && (
                    <ProductSearchListModal
                        selectedPrice={selectedPrice}
                        show={showProductSearchModal}
                        setShow={setShowProductSearchModal}
                        onClickProduct={onClickProduct}
                        onlyWarehouseProduct={false}
                        onlyInventoryProducts={false}
                        preventCloseOnClick
                    />
                )}

                <OrderReturnFormContainer>
                    {state.purchase && state.purchase.hasReturns && (
                        <Flex w100>
                            <Alert
                                withoutMargin
                                type="warning" 
                                title={intl.formatMessage(messages.beCareful)}
                                description={intl.formatMessage(messages.thisOrderAlreadyContainsReturns)} 
                            />
                        </Flex>
                    )}
                    <GridTemplate size={390} gap={20}>
                        <Flex column border gap5 borderRadius={15} padding={15}>
                            <span className="text-bold">Resumen Compra #{state.purchase.number}</span>
                            <Table cellPadding={0} cellSpacing={0} hideBorde tdPadding={0}>
                                <tbody>
                                    <tr>
                                        <td>Total Compra:</td>
                                        <td>{formatMoney(state.purchase.totalAmount)}</td>
                                    </tr>
                                    {state.purchase.status === "partial" && (
                                        <tr>
                                            <td>Total Pagado:</td>
                                            <td>{formatMoney(state.purchase.totalPaid)}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>Total Devoluciones:</td>
                                        <td>
                                            <Flex>
                                                <span>{formatMoney(purchaseReturnTotals.returnAmount)}</span>
                                            </Flex>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Cambios:</td>
                                        <td>{formatMoney(purchaseReturnTotals.returnNewItemsAmount)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Flex>
                        {totalNewPaymentAmount > 0 && (
                            <Flex column w100 gap15 borderRadius={15} border padding={15} boxSizingBorderBox>
                                <Flex gap15 alignCenter spaceBetween>
                                    <span className="">Pagos</span>
                                    <SquareButton
                                        disabled={totalNewPaymentAmount === 0 || paymentsValue === totalNewPaymentAmount}
                                        rounded
                                        small
                                        onClick={() => addPayment("payments")}
                                    >
                                        <span className="wahioicon-plus-circle"></span>
                                    </SquareButton>
                                </Flex>
                                <PaymentsForm
                                    removePadding
                                    payments={purchaseReturn.payments}
                                    onChange={(payments) => setPurchaseReturn({ ...purchaseReturn, payments })}
                                    uniqueMethod={false}
                                    hideTitle
                                    totalAmount={totalNewPaymentAmount}
                                />
                            </Flex>
                        )}
                        {state.purchase.status !== "credit" &&
                            state.purchase.totalAmount - state.purchase.totalPaid < newTotalAmountDifference &&
                            newTotalAmountDifference > 0 && (
                                <Flex column w100 gap15 borderRadius={15} border padding={15} boxSizingBorderBox>
                                    <Flex gap15 alignCenter spaceBetween>
                                        <span className="text-bold">
                                            Reembolso{newTotalAmountDifference > 0 && `: ${getTotalAmountReturn(newTotalAmountDifference)}`}
                                        </span>
                                        {purchaseReturn.returnPayments && purchaseReturn.returnPayments.length === 0 && (
                                            <SquareButton
                                                disabled={newTotalAmountDifference === 0}
                                                small
                                                rounded
                                                onClick={() => addPayment("returnPayments")}
                                            >
                                                <span className="wahioicon-plus"></span>
                                            </SquareButton>
                                        )}
                                    </Flex>
                                    <PaymentsForm
                                        removePadding
                                        hidePaymentInput
                                        hideTitle
                                        payments={purchaseReturn.returnPayments}
                                        onChange={(payments) => setPurchaseReturn({ ...purchaseReturn, returnPayments: payments })}
                                        uniqueMethod
                                        totalAmount={newTotalAmountDifference}
                                    />
                                </Flex>
                        )}
                    </GridTemplate>
                    <Flex marginTop={15}>
                        <GridTemplate size={290} gap={15} w100>
                            <OrderReturnProductStyle>
                                <Flex column gap10 marginBottom={10}>
                                    <GridTabLine alignCenter spaceBetween>
                                        <span>Orden #{state.purchase.number}</span>
                                        <SquareButton
                                            disabled={searchValue.trim().length > 0}
                                            small
                                            onClick={() => setShowSearchInput(!showSearchInput)}
                                        >
                                            <span className="wahioicon-search" />
                                        </SquareButton>
                                    </GridTabLine>

                                    {showSearchInput && (
                                        <Flex paddingLeft={10} paddingRight={10}>
                                            <InputTimer
                                                rounded
                                                type="search"
                                                milliseconds={400}
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value);
                                                }}
                                                placeholder="Buscar Productos"
                                            />
                                        </Flex>
                                    )}
                                </Flex>

                                <Flex column gap5 padding={10} className="flex-fixed-height">
                                    {purchaseItems.length > 0 ?
                                        purchaseItems.map((item, index) => {
                                            return (
                                                <PurchaseProductCard
                                                    added={!!purchaseReturn.items.find((x) => x.purchaseItemId === item.id)}
                                                    onClick={onClickPurchaseItem}
                                                    item={item}
                                                    key={item.id}
                                                />
                                            );
                                        })
                                        :
                                        <Flex>
                                            <TextField light>No hay productos con cantidades totales recibidos para hacer devolución</TextField>
                                        </Flex>
                                    }
                                </Flex>
                            </OrderReturnProductStyle>
                            <OrderReturnProductStyle>
                                <GridTabLine>
                                    <span>Devoluciones{purchaseReturn.items.length > 0 && ` (${purchaseReturn.items.length})`}</span>
                                </GridTabLine>

                                {purchaseReturn.items.length === 0 && (
                                    <Flex gap10 column padding={20} w100 alignCenter boxSizingBorderBox className="text-light text-small">
                                        <span>Selecciona los productos que quieres devolver</span>
                                        <span className="wahioicon-arrow-left"></span>
                                    </Flex>
                                )}
                                <Flex column padding={10}>
                                    {purchaseReturn.items.map((item, index) => {
                                        let itemMatch = purchaseItems.find((x) => x.id === item.purchaseItemId)
                                        if (itemMatch) {
                                            item.stockLimit = itemMatch.quantity - itemMatch.quantityReturned;
                                        }
                                        return (
                                            <PurchaseReturnItemCard
                                                item={item}
                                                key={item.id}
                                                onRemove={onRemoveReturnItem}
                                                onChange={onChangeReturnItem}
                                            />
                                        );
                                    })}
                                </Flex>
                            </OrderReturnProductStyle>
                            <OrderReturnProductStyle>
                                <Flex column gap15>
                                    <GridTabLine alignCenter spaceBetween>
                                        <span>Cambios{purchaseReturn.newItems.length > 0 && ` (${purchaseReturn.newItems.length})`}</span>
                                        <SquareButton rounded small onClick={() => setShowProductSearchModal(true)}>
                                            <span className="wahioicon-plus"></span>
                                        </SquareButton>
                                    </GridTabLine>

                                    <Flex paddingLeft={15} paddingRight={15}>
                                        <PriceListDropdown small selectedPrice={selectedPrice} onSelected={setSelectedPrice} />
                                    </Flex>

                                    {purchaseReturn.newItems.length === 0 && (
                                        <Flex padding={20} w100 alignCenter justifyCenter boxSizingBorderBox>
                                            <div>
                                                <span>Haz clic en</span>
                                                {"  "}
                                                <span className="wahioicon-plus"></span>
                                                {"  "}
                                                <span>para agregar un cambio</span>
                                            </div>
                                        </Flex>
                                    )}

                                    <Flex column className="flex-fixed-height">
                                        <PurchaseItemRowContainer
                                            items={purchaseReturn.newItems ?? []}
                                            onChange={updateReturnNewItems}
                                            currentPurchase={state.purchase}
                                        />
                                    </Flex>
                                </Flex>
                            </OrderReturnProductStyle>
                        </GridTemplate>
                    </Flex>
                    {purchaseReturn.items.length > 0 &&
                        <Flex alignEnd justifyEnd gap15 marginTop={15}>
                            <DefaultButton borderRadius={10} onClick={props.onCloseModal}>
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton borderRadius={10} onClick={() => handleCreateOrderReturn()}>
                                {purchaseCreateReturnMutation.isLoading ? <LoadingDualRing /> : "Crear Devolución"}
                            </PrimaryButton>
                        </Flex>
                    }
                </OrderReturnFormContainer>
            </Flex >
        )
    }
    return <p>Not found</p>;
}

export const PurchaseReturnFormModal = (props: PurchaseReturnFormProps) => {
    const onCreate = (order:IPurchaseReturn) => {
        props.onCreate(order);
        props.setShow(false);
    };
    const onCloseModal = () => {
        props.setShow(false);
    };

    return (
        <Modal
            scrollInside={false}
            overflowAuto={false}
            removeVerticalAlign={true}
            ignoreOutsideClick
            sizeType="md3"
            title={"Devoluciones y cambios"}
            showHeader
            useMobileView
            {...props}
        >
            <PurchaseReturnForm {...props} onCloseModal={onCloseModal} onCreate={onCreate} />
        </Modal>
    );
};