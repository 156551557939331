import { AccountPaymentMethod } from "../../../api/models/accountUser";
import {
    WompiCreditCardResponse,
    WompiMerchantData,
    WompiPaymentSourceResponse,
    WompiSignatureIntegrityInputBase,
    WompiTransactionWithPaymentSource,
} from "../../../api/models/wompi";
import { CloudImages } from "../../../assets";
import { getAuthBody } from "../../../auth";

export interface CreditCardImage {
    name: string;
    image: string;
    initial: string;
}

export const CreditCardImages: CreditCardImage[] = [
    { name: "amex", initial: "3", image: CloudImages.americanExpress },
    { name: "Visa", initial: "4", image: CloudImages.visa },
    { name: "MasterCard", initial: "5", image: CloudImages.masterCard },
    { name: "Others", initial: "", image: CloudImages.othersCards },
];

export const getCreditCardImageByBrand = (brand?: string) => {
    if (!brand) return CloudImages.othersCards;
    var result = CreditCardImages.find((x) => x.name.toUpperCase() === brand.toUpperCase());
    return result?.image ?? CloudImages.othersCards;
};

export const getCreditCardImageByInitialNumber = (initialNumber?: string) => {
    if (!initialNumber) return CloudImages.othersCards;
    var result = CreditCardImages.find((x) => x.initial === initialNumber);
    return result?.image ?? CloudImages.othersCards;
};

export const getAccountPaymentMethod = (paymentSource: WompiPaymentSourceResponse, creditCard: WompiCreditCardResponse) => {
    const paymentMethod: AccountPaymentMethod = {
        accountId: getAuthBody().accountId,
        source: "wompi",
        type: paymentSource.data.type,
        token: paymentSource.data.id.toString(),
        status: paymentSource.data.status,
        createdAt: new Date(),
        publicData: { ...paymentSource.data.public_data, brand: creditCard.data.brand },
        id: "",
        sourceId: "",
        brand: "",
        expMonth: "",
        expYear: "",
        last4: "",
    };
    return paymentMethod;
};

export const getSignatureInput = (referenceId: string, amountInCents: string) => {
    const inputIntegrity: WompiSignatureIntegrityInputBase = {
        transactionReferences: referenceId,
        transactionAmountInCents: amountInCents,
        transactionCurrency: "COP",
    };
    return inputIntegrity;
};

export const getTransactionSourceInput = (
    amountInCents: number,
    email: string,
    signature: string,
    referenceId: string,
    paymentMethodToken: string
) => {
    const transactionPaymentSource: WompiTransactionWithPaymentSource = {
        amount_in_cents: amountInCents,
        currency: "COP",
        customer_email: email ?? "INVALID",
        signature: signature,
        payment_method: {
            installments: 1,
        },
        reference: referenceId,
        payment_source_id: parseInt(paymentMethodToken),
    };
    return transactionPaymentSource;
};

export interface CreatePaymentSourceResult {
    creditCard: WompiCreditCardResponse;
    wompiToken: WompiMerchantData;
    paymentSource: WompiPaymentSourceResponse;
}
