import _ from "lodash";
import React, { useState } from "react";
import { ProductOptional, ProductOptionalItem, ProductOptionalsConfig } from "../../../api/models/product";
import { formatMoney } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import TextBoxNumeral from "../../TextBoxNumeral";
import { Flex, FlexImageStyle, SquareButton, TextField } from "../../_controls";
import { getOptionalSelectionText, getOptionalsSelectedMessages } from "./helper";
import { OptionalCardStyle, OptionalItemCardStyle, OptionalsContainer, QuantityCardStyle } from "./styled";

interface ProductOptionalsSelectorProps {
    optionalsConfig: ProductOptionalsConfig;
    onChange: (value: ProductOptionalsConfig) => void;
}

export const getOptionalsConfig = (optionals: ProductOptional[]) => {
    let config: ProductOptionalsConfig = {
        optionals: optionals,
        minSelectionCount: _.sumBy(optionals, (o) => o.minSelection),
        currentSelectionCount: 0,
        isCompleted: false,
        totalExtraPrice: 0,
    };
    return config;
};

export default function ProductOptionalsSelector(props: ProductOptionalsSelectorProps) {
    const { optionalsConfig } = props;
    const { optionals } = optionalsConfig;

    const onOptionalChange = (index: number, optional: ProductOptional) => {
        const newOptionals = [...optionals];
        newOptionals[index] = optional;
        const minSelectionCount = _.sumBy(newOptionals, (o) => o.minSelection);
        const currentSelectionCount = _.sumBy(newOptionals, (o) => o.selectedCount ?? 0);
        const totalExtraPrice = _.sumBy(newOptionals, (o) => o.extraPrice);

        const isCompleted = newOptionals.every((o) => o.isCompleted);

        const description = getOptionalsSelectedMessages(newOptionals);

        const newConfig = {
            ...optionalsConfig,
            optionals: newOptionals,
            minSelectionCount,
            currentSelectionCount,
            isCompleted: isCompleted,
            totalExtraPrice,
            description,
        };
        props.onChange(newConfig);
    };

    return (
        <OptionalsContainer>
            <Flex column>
                {optionalsConfig.totalExtraPrice > 0 && (
                    <TextField marginBottom={10}>Precio Extra: {formatMoney(optionalsConfig.totalExtraPrice)}</TextField>
                )}
            </Flex>
            {optionals?.map((item, index) => {
                return <OptionalCard optional={item} key={index} onChange={(value) => onOptionalChange(index, value)} />;
            })}
        </OptionalsContainer>
    );
}

interface OptionalCardProps {
    optional: ProductOptional;
    onChange: (optional: ProductOptional) => void;
}

const OptionalCard = (props: OptionalCardProps) => {
    const { optional } = props;
    const alert = useAlert();
    const [isOpen, setIsOpen] = useState(true);

    const onItemChange = (index: number, item: ProductOptionalItem) => {
        const newItems = [...optional.items];
        newItems[index] = item;

        const selectedCount = newItems.reduce((acc, item) => {
            if (item.selected) {
                acc += item.selectedQuantity ?? 0;
            }
            return acc;
        }, 0);

        const extraPrice = newItems.reduce((acc, item) => {
            if (item.selected) {
                acc += item.price * (item.selectedQuantity ?? 0);
            }
            return acc;
        }, 0);

        const newOptional: ProductOptional = {
            ...optional,
            items: newItems,
            selectedCount,
            isCompleted: selectedCount >= optional.minSelection,
            extraPrice,
        };

        if (selectedCount > optional.maxSelection) {
            alert.info("No puedes seleccionar más opciones de las permitidas");
            return;
        }

        props.onChange(newOptional);
    };

    let message = getOptionalSelectionText(optional);

    return (
        <OptionalCardStyle className={`optional-card ${isOpen ? "" : "close"}`}>
            <OptionalItemCardStyle className="header" onClick={() => setIsOpen(!isOpen)}>
                <Flex column>
                    <Flex alignCenter gap5>
                        <TextField bold>{optional.name}</TextField>
                        {optional.minSelection > 0 && (
                            <TextField bold small className={`required-label ${optional.isCompleted ? "done" : ""}`}>
                                {optional.isCompleted ? `Listo` : "Requerido"}
                            </TextField>
                        )}
                    </Flex>
                    <TextField light small>
                        {message}
                    </TextField>
                </Flex>
                <SquareButton small className="collapse-icon">
                    <i className="fa-sharp fa-regular fa-angle-up "></i>
                </SquareButton>
            </OptionalItemCardStyle>
            <div className={`optional-items`}>
                {optional.items?.map((item, index) => {
                    return <OptionalItemRow key={index} item={item} onChange={(value) => onItemChange(index, value)} />;
                })}
            </div>
        </OptionalCardStyle>
    );
};

interface OptionalItemRowProps {
    item: ProductOptionalItem;
    onChange: (item: ProductOptionalItem) => void;
}

const OptionalItemRow = (props: OptionalItemRowProps) => {
    const { item, onChange } = props;

    return (
        <OptionalItemCardStyle className="optional-item">
            <Flex gap15 alignCenter>
                {item.image && (
                    <FlexImageStyle width={30} borderRadius={10} fitCover>
                        <img src={item.image} alt="" />
                    </FlexImageStyle>
                )}
                <Flex column>
                    <TextField key={item.id} light>
                        {item.name}
                    </TextField>
                    {item.price > 0 && <TextField>+ {formatMoney(item.price)}</TextField>}
                </Flex>
            </Flex>
            {item.maxSelection > 1 ? (
                <QuantityCard
                    quantity={item.selectedQuantity ?? 0}
                    onChange={(value) => onChange({ ...item, selected: value > 0, selectedQuantity: value })}
                />
            ) : (
                <div
                    className={`item-checkbox ${item.selected ? "selected" : ""}`}
                    onClick={() => onChange({ ...item, selected: !item.selected, selectedQuantity: 1 })}
                >
                    <i className="fa-regular fa-check"></i>
                </div>
            )}
        </OptionalItemCardStyle>
    );
};

interface QuantityCardProps {
    quantity: number;
    onChange: (value: number) => void;
}

const QuantityCard = (props: QuantityCardProps) => {
    const { quantity, onChange } = props;

    const increaseQuantity = (value: number) => {
        if (!value) {
            value = 1;
        }
        onChange(quantity + value);
    };

    return (
        <QuantityCardStyle>
            <SquareButton small disabled={quantity <= 0} onClick={() => increaseQuantity(-1)}>
                <i className="fa-regular fa-minus"></i>
            </SquareButton>

            <TextBoxNumeral
                className="input-quantity"
                value={quantity}
                format="number"
                autoFocus
                onNumberChange={(value) => onChange(value)}
            />

            <SquareButton small onClick={() => increaseQuantity(1)}>
                <i className="fa-regular fa-plus"></i>
            </SquareButton>
        </QuantityCardStyle>
    );
};
