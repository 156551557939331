import wahioFetchApi from "../fetchApi";
import { DateFilter, IPaginationResponse } from "../models";
import { Contact, ContactLabel, ContactLabelContact, ContactSearchModel } from "../models/contact";
import { GroupItem } from "../models/_statistics";
import { localOrderEndpoint } from "../restApiEndpoint";

export const getContact = (contactId: string): Promise<Contact> => {
    return wahioFetchApi.get(localOrderEndpoint.get.customersById(contactId)).then((res: any) => res.data);
};

export const updateContactAll = (data: Contact): Promise<Contact> => {
    return wahioFetchApi.put(localOrderEndpoint.put.customerAll, data).then((res: any) => res.data);
};

export const contactDeleteList = (contactIds: string[]): Promise<Contact[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.contactDeleteList, { contactIds }).then((res: any) => res.data);
};

export const updateContactBase = (data: Contact): Promise<Contact> => {
    return wahioFetchApi.put(localOrderEndpoint.put.customer, data).then((res: any) => res.data);
};

export const contactQuerySearch = (query: ContactSearchModel): Promise<IPaginationResponse<Contact>> => {
    return wahioFetchApi.post(localOrderEndpoint.post.customerQuerySearch, query).then((res: any) => res.data);
};

export const contactLabelsByAccount = (accountId: string): Promise<ContactLabel[]> => {
    return wahioFetchApi.get(localOrderEndpoint.get.contactLabelsByAccount(accountId)).then((res: any) => res.data);
};

export const contactSellerSummary = (data: DateFilter): Promise<GroupItem[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.contactSummarySellers, data).then((res: any) => res.data);
};

export const createUpdateContactLabel = (value: ContactLabel): Promise<ContactLabel> => {
    return wahioFetchApi.post(localOrderEndpoint.post.contactLabel, value).then((res: any) => res.data);
};
export const createContactLabelContactMulti = (values: ContactLabelContact[]): Promise<ContactLabelContact[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.contactLabelContactMultiCreate, values).then((res: any) => res.data);
};
export const deleteContactLabelContactMulti = (values: ContactLabelContact[]): Promise<ContactLabelContact[]> => {
    return wahioFetchApi.post(localOrderEndpoint.post.contactLabelContactMultiDelete, values).then((res: any) => res.data);
};

export const deleteContactLabel = (id: string): Promise<ContactLabel> => {
    return wahioFetchApi.delete(localOrderEndpoint.delete.contactLabel(id)).then((res: any) => res.data);
};
