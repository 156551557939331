import { defineMessages } from "react-intl";

const base = "components.account.billing";

export default defineMessages({
    free: {
        id: `${base}.free`,
        defaultMessage: "Free",
    },
    basic: {
        id: `${base}.basic`,
        defaultMessage: "Básico",
    },
    standard: {
        id: `${base}.standard`,
        defaultMessage: "Estándar",
    },
    premium: {
        id: `${base}.premium`,
        defaultMessage: "Premium",
    },
    annual: {
        id: `${base}.annual`,
        defaultMessage: "Anual",
    },
    monthly: {
        id: `${base}.monthly`,
        defaultMessage: "Mensual",
    },
    increase: {
        id: `${base}.increase`,
        defaultMessage: "Nuevas licencias",
    },
    planChange: {
        id: `${base}.planChange`,
        defaultMessage: "Cambio de Plan",
    },
    paid: {
        id: `${base}.paid`,
        defaultMessage: "Pagado",
    },
    open: {
        id: `${base}.open`,
        defaultMessage: "Sin Pagar",
    },
    licenses:{
        id: `${base}.licenses`,
        defaultMessage: "Licencias",
    },
});
