import React from "react";
import { PermissionDeniedView, PermissionDeniedContainer } from "./styled";

interface PermissionDeniedProps {
    title?: string;
    message?: string;
}

export default function PermissionDenied(props: PermissionDeniedProps) {
    return (
        <PermissionDeniedContainer>
            <PermissionDeniedView>
                <div className="icon">
                    <span className="wahioicon-user-lock"></span>
                </div>

                <h1 className="title">{props.title ?? "Acceso denegado"}</h1>
                <p className="text">{props.message ?? "Parece que no tienes permiso para acceder a esta página"}</p>
            </PermissionDeniedView>
        </PermissionDeniedContainer>
    );
}

