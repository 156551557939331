import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectBaseOption } from "../../../api/models";
import { IProductVariant } from "../../../api/models/product";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { ignoreElementClick } from "../../../utils";
import { SelectBody } from "../../Select/styled";
import { Flex, TextField, FlexImageStyle } from "../../_controls";
import { ProductCardProps, ProductPriceLine } from "../ProductCard";
import ComposeIcon from "../ProductCard/ComposeIcon";
import { ActiveQuantity } from "../ProductCard/styled";
import { ID_IGNORE_CLICK } from "../ProductSearchList/helper";
import { getProductSkuAndCode, getProductThumbnailUrl } from "../helper";
import { ProductContainer, ProductTitle } from "./styled";

export default function ProductRow(props: ProductCardProps) {
    const { warehouseProduct } = props;
    const [warehouseProductVariant, setWarehouseProductVariant] = useState<IWarehouseProduct | undefined>();
    const { product } = props;
    const [variantFromProduct, setVariantFromProduct] = useState<IProductVariant | undefined>(undefined);
    const productVariant = warehouseProductVariant?.productVariant ?? variantFromProduct;

    useEffect(() => {
        if (
            !productVariant &&
            props.warehouseProduct &&
            props.warehouseProduct.warehouseProductVariants &&
            props.warehouseProduct.warehouseProductVariants.length > 0
        ) {
            setWarehouseProductVariant(props.warehouseProduct.warehouseProductVariants[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.warehouseProduct]);

    useEffect(() => {
        if (!productVariant && product?.variants && product.variants.length > 0) {
            setVariantFromProduct(product.variants[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!product) return null;

    const getPrice = () => {
        if (productVariant) {
            return productVariant[props.defaultPrice];
        } else if (product) {
            return product[props.defaultPrice];
        }
        return 0;
    };

    const getStock = () => {
        if (warehouseProductVariant) {
            return warehouseProductVariant.stock;
        }
        return props.stock;
    };

    const getSelectOption = (item?: IProductVariant) => {
        let option: SelectBaseOption = {
            id: item?.id ?? "",
            value: item?.id ?? "",
            label: item?.valuesLine ?? "",
        };
        return option;
    };

    const stock = getStock();

    const preventClickProduct =
        !props.warehouse?.allowNegativeStock &&
        !props.allowOutOfStock &&
        !warehouseProductVariant &&
        !product.isParent &&
        product.controlInventory &&
        stock === 0;

    const outclass = preventClickProduct ? "out" : "";

    const onClick = (e: any) => {
        if (ignoreElementClick(e.target, ID_IGNORE_CLICK)) {
            return;
        }

        if (preventClickProduct) return;
        else {
            props.onClickProduct({
                product,
                warehouse: props.warehouse,
                stock: stock,
                quantity: 1,
                customPrice: getPrice(),
                productVariant: productVariant,
                warehouseProduct: props.warehouseProduct,
                productIndex: props.index,
            });
        }
    };

    const getVariants = () => {
        if (warehouseProduct && warehouseProduct.warehouseProductVariants) {
            let variants: IProductVariant[] = [];
            warehouseProduct.warehouseProductVariants.forEach((element) => {
                if (element.productVariant) {
                    variants.push(element.productVariant);
                }
            });
            return variants;
        } else {
            return product.variants ?? [];
        }
    };

    const onVariantChange = (productVariantId?: string) => {
        if (warehouseProduct && warehouseProduct.warehouseProductVariants) {
            setWarehouseProductVariant(warehouseProduct.warehouseProductVariants.find((x) => x.productVariantId === productVariantId));
        } else if (product.variants) {
            setVariantFromProduct(product.variants.find((x) => x.id === productVariantId));
        }
    };

    const hasActiveProduct = !!(props.activeUnits && props.activeUnits !== 0);

    return (
        <ProductContainer onClick={onClick} className={`${outclass} ${hasActiveProduct ? "active" : ""}`}>
            {hasActiveProduct && (
                <ActiveQuantity>
                    <span className="wahioicon-shopping-basket active-icon"></span>
                    {props.activeUnits}
                </ActiveQuantity>
            )}
            {product.isParent && <ComposeIcon right={10} />}
            <FlexImageStyle borderRadius={15} fitCover>
                <img src={getProductThumbnailUrl(product, productVariant)} alt="" />
            </FlexImageStyle>

            <Flex column w100>
                <ProductTitle className="title">{product.name}</ProductTitle>
                <span className="product-code">{getProductSkuAndCode(product, productVariant)}</span>
                {productVariant && (
                    <SelectBody id={ID_IGNORE_CLICK} className="bg-light">
                        <Select
                            placeholder="Seleccionar"
                            className="select-small"
                            classNamePrefix="select"
                            key={productVariant?.id}
                            defaultValue={productVariant ? getSelectOption(productVariant) : undefined}
                            isDisabled={false}
                            onChange={(value) => onVariantChange(value?.id)}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={getVariants().map((item) => getSelectOption(item))}
                        />
                    </SelectBody>
                )}
                <Flex spaceBetween gap10 alignCenter>
                    {!props.hidePrice && <ProductPriceLine direction="row" price={getPrice()} discounts={product.discounts} />}
                    <TextField small light>
                        {stock}
                    </TextField>
                </Flex>
            </Flex>
        </ProductContainer>
    );
}
