import wahioFetchApi from "../fetchApi";
import { AccountPaymentMethod } from "../models/accountUser";
import {
    ClientSecretResponse,
    ClientSessionResponse,
    SetupIntentResponse,
    StripePaymentIntentConfirmInput,
    StripePaymentIntentInput,
    StripeSessionInput,
} from "../models/stripe";
import { accountEndpoint } from "../restApiEndpoint";

import { PaymentIntent } from "@stripe/stripe-js";

export const stripeCreatePaymentIntent = (user: StripePaymentIntentInput): Promise<PaymentIntent> => {
    return wahioFetchApi.post(accountEndpoint.post.stripeCreatePaymentIntent, user).then((res: any) => res.data);
};

export const stripeCreatePaymentIntentConfirm = (data: StripePaymentIntentConfirmInput): Promise<PaymentIntent> => {
    return wahioFetchApi.post(accountEndpoint.post.stripeCreatePaymentIntentConfirm, data).then((res: any) => res.data);
};

export const stripeCreateSession = (user: StripeSessionInput): Promise<ClientSecretResponse> => {
    return wahioFetchApi.post(accountEndpoint.post.stripeCreateSession, user).then((res: any) => res.data);
};

export const stripeGetSession = (id: string): Promise<ClientSessionResponse> => {
    return wahioFetchApi.get(accountEndpoint.get.stripeGetSession(id)).then((res: any) => res.data);
};

export const stripeGetCustomer = (accountId: string): Promise<ClientSessionResponse> => {
    return wahioFetchApi.get(accountEndpoint.get.stripeGetCustomer(accountId)).then((res: any) => res.data);
};

export const stripeGetSetupIntent = (accountId: string): Promise<SetupIntentResponse> => {
    return wahioFetchApi.get(accountEndpoint.get.stripeGetSetupIntent(accountId)).then((res: any) => res.data);
};

export const stripeGetPaymentMethodList = (accountId: string): Promise<AccountPaymentMethod[]> => {
    return wahioFetchApi.get(accountEndpoint.get.stripeGetPaymentMethodList(accountId)).then((res: any) => res.data);
};
