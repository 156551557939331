import React from "react";
import { CloudImages } from "../../../assets";
import { ComposeIconContainer } from "./styled";

interface ComposeIconProps {
    left?: number;
    right?: number;
    top?: number;
    position?: "relative" | "absolute";
}

export default function ComposeIcon(props: ComposeIconProps) {
    const { position = "absolute" } = props;
    return (
        <ComposeIconContainer
            style={{ left: props.left, top: props.top, right: props.right, position: position }}
            title="Este es un producto compuesto"
        >
            <img src={CloudImages.boxCompose} alt="Compose" />
        </ComposeIconContainer>
    );
}
