import styled from "styled-components";

export const ViewContainerImage = styled.div`
    text-align: center;
    padding: ${(props: { notPadding?: boolean }) => (props.notPadding ? "0px" : "25px")};
    .image {
        max-width: 450px;
        margin: auto;
        margin-top: ${(props: { notPadding?: boolean }) => (props.notPadding ? "0px" : "15px")};
        margin-bottom: 8px;
        img {
            width: 100%;
        }
        &.sm {
            max-width: 150px;
        }
    }
    .btncontainer {
        display: flex;
        justify-content: center;
    }
    .content {
        .title {
            margin-bottom: 0px;
        }
        .subtitle {
            margin-top: 3px;
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};
        }
    }
`;
