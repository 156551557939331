import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { OrderNavigationProps } from ".";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { getCashRegisterEntryByReference } from "../../../api/account/cashRegisterApi";
import { METHOD_ICONS, WahioFile, WahioFileImpl } from "../../../api/models";
import { getUserPermissions } from "../../../api/models/accountUser";
import { ICashRegisterEntry } from "../../../api/models/cashRegister";
import { orderStatusListWithoutInvoice } from "../../../api/models/orderBase";
import { IOrderPayment } from "../../../api/models/orderPayment";
import { WahioCloudItem } from "../../../api/models/wahioCloud";
import { checkOrderPaymentAndUpdate } from "../../../api/orders/orderApi";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import messagesWompi from "../../../api/wompi/messages";
import { getWompiTransaction } from "../../../api/wompi/wompiApi";
import { checkAndGetMessage } from "../../../i18n/helper";
import globalMessages from "../../../messages";
import { useUserContext } from "../../../store/contexts/UserContext";
import { formatMoney, getDateFormat, getDateFormatCalendar } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import CashRegisterEntryCard from "../../CashRegisters/CashRegisterEntries/CashRegisterEntryCard";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import WahioCloudItemsModal from "../../WahioCloud/WahioCloudItemsModal";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import WahioTooltip from "../../_controls/WahioTooltip";
import CreateOrderPaymentModal from "./CreateSinglePaymentModal";
import OrderInvoiceView from "./OrderInvoiceView";
import messages from "./messages";
import { PaymentCardView, SummaryPaymentContainerOrder } from "./styled";

export default function NavigationPayments(props: OrderNavigationProps) {
    const { order, onOrderChange } = props;
    const [showCreatePayment, setShowCreatePayment] = useState(false);
    const { userState } = useUserContext();
    const intl = useIntl();
    const restaurantActive = order.isRestaurant && !order.isRestaurantClose;

    const showPayment = React.useMemo(() => {
        return ["draft", "void", "quotation"].includes(order.status) ? false : true;
    }, [order.status]);

    const showInvoiceView = React.useMemo(() => {
        return orderStatusListWithoutInvoice.includes(order.status) ? false : true;
    }, [order.status]);

    if (!showPayment) return null;

    return (
        <NavigationCard title="Pagos">
            <Flex column gap={20}>
                {!order.orderInvoice && showInvoiceView && <OrderInvoiceView onOrderChange={onOrderChange} order={order} />}

                {order.orderInvoice && (order.status === "partial" || order.status === "credit") && (
                    <SummaryPaymentContainerOrder>
                        {showCreatePayment && (
                            <CreateOrderPaymentModal
                                onOrderChange={onOrderChange}
                                order={order}
                                show={showCreatePayment}
                                setShow={setShowCreatePayment}
                            />
                        )}
                        <Flex gap15>
                            <Flex column>
                                <TextField small light>
                                    {intl.formatMessage(messages.accumulatedPayment)}
                                </TextField>
                                <TextField bold fontSize={16}>
                                    {formatMoney(order.totalPayment)}
                                </TextField>
                            </Flex>
                            <Flex column>
                                <TextField small light>
                                    {intl.formatMessage(messages.remainingPayment)}
                                </TextField>
                                <TextField bold fontSize={16}>
                                    {formatMoney(order.totalAmount - order.totalPayment)}
                                </TextField>
                            </Flex>
                        </Flex>
                        {!restaurantActive && (
                            <Flex alignCenter gap10>
                                <DefaultButton
                                    borderRadius={10}
                                    bgLight
                                    disabled={!getUserPermissions(userState.user).order?.allowCreatePayments}
                                    onClick={() => setShowCreatePayment(true)}
                                >
                                    <i className="fa-regular fa-plus"></i> {intl.formatMessage(messages.newPayment)}
                                </DefaultButton>
                                {!getUserPermissions(userState.user).order?.allowCreatePayments && (
                                    <div>
                                        <span className="wahioicon-lock mr-1"></span>
                                        {intl.formatMessage(messages.youDoNotHavePermissionsToAddPayments)}
                                    </div>
                                )}
                            </Flex>
                        )}
                    </SummaryPaymentContainerOrder>
                )}
                {order.payments && order.payments.length > 0 && <OrderPayments {...props} />}
            </Flex>
        </NavigationCard>
    );
}

interface PaymentEntry {
    payment: IOrderPayment;
    entry?: ICashRegisterEntry;
}

function OrderPayments(props: OrderNavigationProps) {
    const { order } = props;
    const alert = useAlert();
    const [payments, setPayments] = useState(order.payments ?? []);
    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [orderEntries, setOrderEntries] = useState<ICashRegisterEntry[]>([]);

    const entriesMutation = useMutation((id: string) => getCashRegisterEntryByReference(id), {
        onSuccess: (data) => {
            setOrderEntries(data);
        },
    });

    const paymentEntriesPair = React.useMemo(() => {
        let result: PaymentEntry[] = [];
        order.payments?.forEach((payment) => {
            let entry = orderEntries.find((entry) => entry.referencePaymentId === payment.id);
            if (entry) {
                result.push({ payment, entry });
            } else {
                result.push({ payment });
            }
        });
        return result;
    }, [orderEntries, order.payments]);

    useEffect(() => {
        if (order.payments && order.payments.length > 0) {
            entriesMutation.mutate(order.id ?? "NA");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.payments]);

    useEffect(() => {
        setPayments(order.payments ?? []);
    }, [order.payments]);

    const handleSaveChanges = () => {
        setIsFetching(true);
        wahioFetch.post(
            localOrderEndpoint.post.orderUpdateCreateAll,
            {
                ...order,
                payments,
            },
            (success) => {
                setIsFetching(false);
                alert.success("Los cambios se guardaron correctamente");
                setShowSaveChanges(false);
                props.onOrderChange(success.data);
            },
            (error) => {
                setIsFetching(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const onPaymentChange = (value: IOrderPayment) => {
        let paymentsCopy: IOrderPayment[] = [];
        payments.forEach((element) => {
            if (element.id === value.id) {
                paymentsCopy.push(value);
            } else {
                paymentsCopy.push(element);
            }
        });
        setPayments(paymentsCopy);
        setShowSaveChanges(true);
    };

    const handleCancelChanges = () => {
        setPayments(order.payments ?? []);
        setShowSaveChanges(false);
    };

    if (entriesMutation.isLoading) return <LoadingDualRing center />;

    return (
        <Flex column w100>
            <Flex column>
                {paymentEntriesPair.map((pair) => {
                    if (pair.entry) {
                        return <CashRegisterEntryCard key={pair.entry.id} entry={pair.entry} onEntryChange={() => {}} hideType />;
                    } else {
                        return (
                            <OrderPaymentItemOption
                                {...props}
                                key={pair.payment.id}
                                payment={pair.payment}
                                onPaymentChange={onPaymentChange}
                            />
                        );
                    }
                })}
            </Flex>

            {isFetching && <LoadingDualRing />}
            {!isFetching && showSaveChanges && (
                <Flex gap10 className="mt-1">
                    <DefaultButton onClick={handleCancelChanges} rounded>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton rounded onClick={() => handleSaveChanges()}>
                        Guardar Cambios
                    </PrimaryButton>
                </Flex>
            )}
        </Flex>
    );
}

interface OrderPaymentItemOptionProps extends OrderNavigationProps {
    payment: IOrderPayment;
    onPaymentChange: (value: IOrderPayment) => void;
}

const OrderPaymentItemOption = (props: OrderPaymentItemOptionProps) => {
    const { payment } = props;
    const intl = useIntl();
    const [showWahioCloud, setShowWahioCloud] = useState(false);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);

    const checkOrderPaymentMutation = useMutation((id: string) => checkOrderPaymentAndUpdate(id), {
        onSuccess: (data) => {
            props.onOrderChange(data);
        },
    });

    const wompiMutation = useMutation((id: string) => getWompiTransaction(id), {
        onSuccess: (data) => {
            if (payment.status && data.data.status === "APPROVED" && payment.orderId) {
                checkOrderPaymentMutation.mutate(payment.orderId);
            }
        },
    });
    const wompiData = wompiMutation.data?.data;

    useEffect(() => {
        if (payment.paymentGateway === "wompi" && payment.paymentGatewayId) {
            wompiMutation.mutate(payment.paymentGatewayId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onItemSelected = (items: WahioCloudItem[]) => {
        let newFiles: WahioFile[] = [];
        items.forEach((item) => {
            let file = new WahioFileImpl(item.url, item.type);
            file.name = item.name;
            newFiles.push(file);
        });
        let resultFiles = [...(payment.files ?? []), ...newFiles];
        props.onPaymentChange({ ...payment, files: resultFiles });
    };

    const onFilesChanges = (files: WahioFile[]) => {
        props.onPaymentChange({ ...payment, files });
    };

    const icon = (METHOD_ICONS as any)[payment.method];

    return (
        <PaymentCardView>
            <Flex w100 column gap15 flexWrap>
                {showWahioCloud && (
                    <WahioCloudItemsModal
                        show={showWahioCloud}
                        setShow={setShowWahioCloud}
                        onClickItems={onItemSelected}
                        allowSelectionMultiple
                        allowSelection
                    />
                )}
                <Flex alignCenter gap15>
                    <div className={`status  ${wompiData?.status ?? ""}`}>
                        <span className={icon ? icon : "wahioicon-check"}></span>
                    </div>

                    <Flex column>
                        <Flex alignCenter gap10>
                            {payment.paymentGateway && payment.paymentGatewayId && wompiData && (
                                <TextField small light>
                                    {checkAndGetMessage(intl, messagesWompi, wompiData.payment_method_type)}
                                </TextField>
                            )}
                            <TextField>{checkAndGetMessage(intl, globalMessages, payment.method)}</TextField>
                        </Flex>
                        <TextField small light>
                            {getDateFormat(payment.dateCreated, "DD MMM hh:mm:a")}
                        </TextField>
                    </Flex>

                    {wompiData && (
                        <WahioTooltip
                            timeout={500}
                            trigger={
                                <SquareButton
                                    onClick={() => setShowPaymentDetails(!showPaymentDetails)}
                                    rounded
                                    className="small bg-light text-light"
                                >
                                    <span className={!showPaymentDetails ? "wahioicon-eye" : "wahioicon-eye-slash"} />
                                </SquareButton>
                            }
                            text={
                                !showPaymentDetails
                                    ? intl.formatMessage(messagesWompi.showDetails)
                                    : intl.formatMessage(messagesWompi.hideDetails)
                            }
                            fitContent
                        ></WahioTooltip>
                    )}
                </Flex>

                {showPaymentDetails && wompiData && (
                    <div>
                        <table className="table-details">
                            <tbody>
                                <tr>
                                    <td>ID:</td>
                                    <td>{wompiData.id}</td>
                                </tr>
                                <tr>
                                    <td>Estado</td>
                                    <td>{checkAndGetMessage(intl, messagesWompi, wompiData.status)}</td>
                                </tr>
                                {wompiData.status_message && (
                                    <tr>
                                        <td>Descripción</td>
                                        <td>{wompiData.status_message}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td>Valor</td>
                                    <td>{formatMoney(wompiData.amount_in_cents / 100)}</td>
                                </tr>
                                <tr>
                                    <td>Método</td>
                                    <td>{checkAndGetMessage(intl, messagesWompi, wompiData.payment_method_type)}</td>
                                </tr>
                                <tr>
                                    <td>Fecha</td>
                                    <td>{getDateFormatCalendar(wompiData.created_at)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {payment.files && payment.files.length > 0 && (
                    <FilesViewer cardMaxHeight={80} cardMaxWidth={80} smallCard files={payment.files} onUpdateFiles={onFilesChanges} />
                )}
            </Flex>
            <SquareButton removeBackground colorText onClick={() => setShowWahioCloud(true)}>
                <span className="wahioicon-paperclip"></span>
            </SquareButton>
            <TextField bold>{formatMoney(payment.amount)}</TextField>
        </PaymentCardView>
    );
};
