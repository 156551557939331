import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import { useIsMobileListener } from "../..";
import { getServiceMessageError } from "../../../api";
import { createUpdateTransactionAccount } from "../../../api/account/transactionAccount";
import { WahioFile } from "../../../api/models";
import { getUserPermissions } from "../../../api/models/accountUser";
import { DeliveryOrder, NON_ORDER_ITEM_UPDATE_STATUS_LIST } from "../../../api/models/deliveryOrder";
import { CustomerPayment, Order, OrderItem } from "../../../api/models/order";
import { OrderStatus } from "../../../api/models/orderBase";
import { ProductPriceTypes } from "../../../api/models/product";
import { TransactionAccount } from "../../../api/models/transactionAccount";
import { createUpdateOrder } from "../../../api/orders/orderApi";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import PATHS from "../../../constants/paths";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { CashRegisterContext } from "../../../store/contexts/CashRegisterContext";
import { useOrderDataSummaryContext } from "../../../store/contexts/OrderDataSummaryContext";
import { useOrderInvoiceNumerationContext } from "../../../store/contexts/OrderInvoiceNumerationContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { useTaxContext } from "../../../store/contexts/TaxContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { getEmptyOrder } from "../../../store/reducers/currentOrder";
import { getRoundNumber } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import { CurrentRegisterLogModal } from "../../CashRegisters/CashRegisterLog";
import EmptyOrderNumeration from "../../EmptyResultViews/EmptyOrderNumeration";
import PermissionDenied from "../../PermissionDenied";
import { ProductAddOption } from "../../Products/ProductCard";
import { ProductExceptionFormModal } from "../../Products/ProductExceptionForm";
import ProductSearchList, { ProductActiveInput, ProductActiveObject } from "../../Products/ProductSearchList";
import CustomFilesModalModal from "../../WahioCloud/CustomFilesModal";
import ConfirmationModal from "../../modals/ConfirmationModal";
import OrderLimitAlert from "../OrderLimitAlert";
import { useWarehouseProductException } from "../OrderProfile/hooks";
import { checkIfOrderIsValid } from "../orderUtils";
import ModalFinalizeOrder from "./FinalizeOrderForm/ModalFinalizeOrder";
import OrderShipmentConfirmationModal from "./FinalizeOrderForm/OrderShipmentConfirmationModal";
import OrderDetailsPanel from "./OrderDetailsPanel";
import OrderItemsSummary from "./OrderItemsSummary";
import {
    calculateOrderTotalManualDiscount,
    calculateTotalTax,
    getClearOrderPayments,
    getOrderItemTotals,
    getTotalValueTipDiscount,
    insertProductInOrderList,
    updateOrderItemsTotals,
} from "./helpers";
import { CoverPanelLoading, CreateOrderContainer, CreateOrderFormPanel } from "./styled";

export type OrderFormOrigin = "restaurant" | "quotation";

export interface CreatePropsFormProps {
    isModal?: boolean;
    status?: OrderStatus;
    order: Order;
    onCompleted: (order: Order) => void;
    onOrderChange: (order: Order) => void;
    forceMobileView?: boolean;
    pageTitle: string;
    origin?: OrderFormOrigin;
    modalFinalizeIsOpen?: (value: boolean) => void;
    onClickFinalizeMiddleware?: (callback: () => void) => void;
    setStopAutoSaveOrderTable?: (value: boolean) => void;
    showCoverPanelLoading?: boolean;
    containerStyle?: React.CSSProperties;
    panelTabIndex?: number;
}

export type CreateOrderType = "order" | "draft";

const CreateOrderForm = (props: CreatePropsFormProps) => {
    const alert = useAlert();
    const [confirmClearOrder, setConfirmClearOrder] = useState(false);
    const { appSettingsState } = useContext(AppSettingsContext);
    const { taxState, taxActions, localTaxes } = useTaxContext();
    const { userState } = useContext(UserContext);
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);
    const { organizationState } = useContext(OrganizationContext);
    const { cashRegisterState } = useContext(CashRegisterContext);
    const [openFinalizeModal, setOpenFinalizeModal] = useState(false);
    const [openCreateStatusOrder, setOpenCreateStatusOrder] = useState<CreateOrderType>();
    const [showFilesModal, setShowFilesModal] = useState(false);
    const [itemsExceedAllowedQuantity, setItemsExceedAllowedQuantity] = useState(false);
    const { orderLocalStorageState } = useOrderDataSummaryContext();
    const [productActiveObject, setProductActiveObject] = useState<ProductActiveObject>({});
    const { selectedNumeration } = useOrderInvoiceNumerationContext();
    const warehouseException = useWarehouseProductException();

    const [orderResult, setOrderResult] = useState<Order>();

    const { getWarehouseById } = useWarehouseContext();

    const orderMutation = useMutation((data: Order) => createUpdateOrder(data), {
        onSuccess: (data) => {
            setOrderResult(data);
        },
    });

    const createAccountingMutation = useMutation((data: TransactionAccount) => createUpdateTransactionAccount(data), {
        onSuccess: (data) => {
            alert.notification({
                title: "Cuenta por cobrar creada",
                description: `Cuenta #${data.number}`,
                icon: "fa-regular fa-money-check-dollar-pen",
                openLink: `${window.location.origin}${PATHS.paymentsTransactionAccountProfile(data.id)}`,
            });
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const onCreateTransactionAccount = (value: TransactionAccount) => {
        createAccountingMutation.mutate(value);
    };

    let isMobile = useIsMobileListener({ size: 710 });
    if (!!props.forceMobileView) isMobile = true;

    const currentOrder = props.order;

    useEffect(() => {
        setOrderResult(undefined);
    }, [props.panelTabIndex]);

    useEffect(() => {
        let items = updateOrderItemsTotals(currentOrder);
        calculateTotalsUpdateItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentOrder.taxIncluded,
        currentOrder.totalDelivery,
        currentOrder.preTaxDiscount,
        currentOrder.percentageDiscount,
        currentOrder.percentageTip,
        currentOrder.defaultPrice,
    ]);

    useEffect(() => {
        if (!currentOrder.defaultPrice) {
            props.onOrderChange({ ...currentOrder, defaultPrice: appSettingsState.defaultPrice });
        }
        taxActions.fetchIfNotExists();

        if (accountUserState.items.length === 0) accountUserActions.requestAccountUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadAccountUserEntities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items]);

    useEffect(() => {
        updateDefaultPaymentTermsDays();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder.auxId, currentOrder.id]);

    const itemsTimeRef = useRef<any>();

    useEffect(() => {
        loadProductActiveObject();

        clearTimeout(itemsTimeRef.current);
        itemsTimeRef.current = setTimeout(() => {
            if (currentOrder.items.length > 300) {
                currentOrder.items = currentOrder.items.slice(0, 300);
                setItemsExceedAllowedQuantity(true);
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder.items]);

    const updateDefaultPaymentTermsDays = () => {
        if (!currentOrder.paymentTermsDaysDefaultLoaded) {
            var defaultPaymentTermsDays = userState.user?.account?.clientSettings?.defaultPaymentTermsDays;

            if (defaultPaymentTermsDays && defaultPaymentTermsDays > 0) {
                props.onOrderChange({ ...currentOrder, paymentTermsDays: defaultPaymentTermsDays, paymentTermsDaysDefaultLoaded: true });
            }
        }
    };

    const updateExistingItemsPrices = (defaultPrice?: ProductPriceTypes) => {
        let items = updateOrderItemsTotals(currentOrder, defaultPrice ?? currentOrder.defaultPrice);
        calculateTotalsUpdateItems(items, defaultPrice);
    };

    const loadAccountUserEntities = () => {
        if (accountUserState.items.length > 0 && currentOrder && !currentOrder.accountUserEntitiesLoaded) {
            const items = accountUserState.items;
            let accountUser = undefined;
            let userSeller = undefined;
            let deliveryOrder = currentOrder.deliveryOrder;
            if (currentOrder.accountUserId) {
                accountUser = items.find((x) => x.id === currentOrder.accountUserId);
            }
            if (currentOrder.userSellerId) {
                userSeller = items.find((x) => x.id === currentOrder.userSellerId);
            }
            props.onOrderChange({ ...currentOrder, accountUser, userSeller, deliveryOrder, accountUserEntitiesLoaded: true });
        }
    };

    const onClickFinalizeMiddleware = () => {
        if (props.onClickFinalizeMiddleware) {
            props.onClickFinalizeMiddleware(onClickFinalize);
        } else {
            onClickFinalize();
        }
    };

    const onClickFinalize = () => {
        let stop = false;

        if (!currentOrder.id) {
            currentOrder.items.forEach((element) => {
                if (element.product?.controlInventory && element.warehouse?.allowLogisticsService && !element.locationCode) {
                    alert.info("Debes seleccionar las ubicaciones");
                    stop = true;
                    return;
                }
            });
        }
        if (stop) {
            return;
        }

        let itemsWithQuantity = currentOrder.items.filter((x) => x.quantity > 0);
        setOpenFinalizeModal(true);

        if (props.origin === "restaurant" && props.setStopAutoSaveOrderTable) {
            props.setStopAutoSaveOrderTable(true);
        }
        props.onOrderChange({
            ...currentOrder,
            items: itemsWithQuantity,
            status: props.status ?? currentOrder.status ?? "paid",
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };
    const onClickCancel = () => {
        setOpenFinalizeModal(false);
        if (props.setStopAutoSaveOrderTable) props.setStopAutoSaveOrderTable(false);
    };

    const onClickProduct = (value: ProductAddOption) => {
        if (orderResult) {
            setOrderResult(undefined);
        }

        if (value.product.includesVariants && !value.productVariant) {
            alert.error("Es necesario seleccionar una variante primero");
        }

        const warehouseIds = currentOrder.items.filter((x) => x.warehouseId).map((x) => x.warehouseId);
        if (props.status === "queued" || props.status === "quotation") {
            if (currentOrder.items.length > 0 && warehouseIds.filter((x) => x !== value.warehouse?.id).length > 0) {
                alert.error("No se pueden agregar productos de diferentes bodegas");
                return;
            }
        }

        let localTaxesList = [...localTaxes];
        if (selectedNumeration?.defaultTax) {
            localTaxesList.push(selectedNumeration.defaultTax);
        }

        let newItems = insertProductInOrderList({
            items: currentOrder.items,
            product: value.product,
            defaultPrice: appSettingsState.defaultPrice,
            currentStock: value.stock,
            warehouse: value.warehouse,
            quantity: value.quantity,
            price: value.customPrice,
            productVariant: value.productVariant,
            warehouseProduct: value.warehouseProduct,
            optionalsConfig: value.optionalsConfig,
            taxIncluded: true,
            preTaxDiscount: false,
            isRestaurant: props.origin === "restaurant",
            taxes: localTaxesList,
            comment: value.comment,
        });

        calculateTotalsUpdateItems(newItems);
    };

    const onRemoveItem = (itemIndex: number) => {
        let counter = 1;
        let newItems: OrderItem[] = [];
        currentOrder.items.forEach((item, index) => {
            if (itemIndex !== index) {
                item.position = counter;
                newItems.push(item);
                counter += 1;
            }
        });

        calculateTotalsUpdateItems(newItems);
    };

    const onSaveOrder = () => {
        if (!checkIfOrderIsValid(currentOrder)) {
            alert.error("No se puede procesar la orden porque la información relacionada con la cuenta parece estar incompleta");
            return;
        }

        if (getAuthBody().accountId !== userState.user?.accountId) {
            alert.error(
                "No se puede crear la orden porque se ha combinado la información de dos cuentas, por favor cierra sesión y vuelve a intentar"
            );
            return;
        }

        let order = { ...currentOrder };
        order.account = userState.user?.account;

        order.tipBeforeTax = appSettingsState.orderForm?.tipBeforeTax ?? false;
        order.discountBeforeTax = appSettingsState.orderForm?.discountBeforeTax ?? false;

        order.lastAccountUserId = getAuthBody().accountUserId;
        order.accountId = getAuthBody().accountId;
        order.organizationId = appSettingsState.defaultOrganizationId;

        order.orderMetadata = orderLocalStorageState.orderMetadata;

        let warehouse = getWarehouseById(appSettingsState.defaultWarehouseId);
        if (!warehouse) {
            alert.error("No se encontró la  bodega");
        }

        order.allowLogisticsService = !!warehouse?.allowLogisticsService;
        order.deliveryOrder = order.isDelivery ? order.deliveryOrder : undefined;

        order.defaultPrice = appSettingsState.defaultPrice;

        order.source = currentOrder.source ? currentOrder.source : "manually";

        let clearPaymentResult = getClearOrderPayments(order);
        order.payments = clearPaymentResult.payments;
        order.moneyReceived = clearPaymentResult.amountChange;

        if (!order.accountUserId) {
            order.accountUserId = getAuthBody().accountUserId;
        }

        if (!order.id || order.status === "draft" || props.origin === "restaurant") {
            order.includeInvoice = true;
            order.orderInvoiceNumerationId =
                appSettingsState.selectedInvoiceNumerationId || organizationState.currentOrganization?.orderInvoiceNumerationId;
        }

        order.status = "partial";
        order.totalTaxItems = order.totalTaxItems ? order.totalTaxItems : 0;

        const valuePayments = currentOrder.payments.reduce((total, payment) => total + payment.amount, 0);
        if (currentOrder.totalAmount < valuePayments) {
            const customerPayment: CustomerPayment = {
                amountGiven: valuePayments,
                changeReturned: valuePayments - currentOrder.totalAmount,
            };
            order.customerPayment = customerPayment;
        }

        if (!order.id) {
            order.dateCreatedCustom = appSettingsState.orderForm?.allowCustomDate ? order.dateCreatedCustom : undefined;
        }

        orderMutation
            .mutateAsync(order)
            .then((data) => {
                props.onCompleted(data);
                onFinalizeClose();
            })
            .catch((error) => {
                alert.error(getServiceMessageError(error));
                warehouseException.checkError(error);
            });
    };

    const onUpdateItem = (value: OrderItem, index: number) => {
        value = getOrderItemTotals(value);
        let newItems = [...currentOrder.items];
        newItems[index] = value;

        calculateTotalsUpdateItems(newItems);
    };

    const calculateTotalsUpdateItems = (items: OrderItem[], defaultPrice?: ProductPriceTypes) => {
        items = items.map((item) => getOrderItemTotals(item));
        const totalAmountItems = getRoundNumber(items.reduce((sum, item) => sum + item.total, 0));

        const totalManualDiscount = calculateOrderTotalManualDiscount({ ...currentOrder, items });
        const totalTaxItems = calculateTotalTax(items);
        const subTotal = items.reduce((previous, value) => previous + value.subTotal, 0);

        const totalDelivery = currentOrder.deliveryOrder?.excludesCostItemsInOrder ? 0 : currentOrder.totalDelivery;
        const totalTip = currentOrder.totalTip ?? 0;

        const percentageDiscount = currentOrder.percentageDiscount ?? 0;
        const totalDiscount = getTotalValueTipDiscount(
            totalAmountItems,
            totalTaxItems,
            !!appSettingsState.orderForm?.tipBeforeTax,
            percentageDiscount
        );

        const itemsQuantityCount = items.reduce((prev, value) => prev + value.quantity, 0);

        const preTotal = getRoundNumber(totalAmountItems + totalDelivery + totalTip - totalDiscount);
        const taxWithHolding = getRoundNumber(
            currentOrder.includesTaxWithholding ? preTotal * (currentOrder.taxWithholdingPercentage / 100) : 0
        );

        const total = getRoundNumber(preTotal - taxWithHolding);

        props.onOrderChange({
            ...currentOrder,
            items,
            defaultPrice: defaultPrice ?? currentOrder.defaultPrice,
            subTotal,
            totalAmount: total,
            totalAmountItems,
            percentageDiscount,
            itemsQuantityCount,
            totalDiscount,
            totalTaxItems,
            totalManualPercentageDiscountItems: totalManualDiscount,
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };

    const handleClearOrder = () => {
        let typeTemp = currentOrder.orderTypeTemp;
        let newOrder = getEmptyOrder();
        if (props.status === "queued" || typeTemp === "shipment") {
            newOrder.isDelivery = true;
            newOrder.deliveryOrder = new DeliveryOrder(getAuthBody().accountId, "");
            newOrder.orderTypeTemp = "shipment";
            newOrder.status = "queued";
        }

        if (props.status === "quotation" || typeTemp === "quotation") {
            newOrder.orderTypeTemp = "quotation";
            newOrder.status = "quotation";
        }

        if (props.status) {
            newOrder.status = props.status;
        }

        props.onOrderChange(newOrder);
        setConfirmClearOrder(false);
    };

    const orderProps = {
        onRemoveItem,
        onUpdateItem,
        isMobile,
    };

    const onFinalizeClose = () => {
        setOpenFinalizeModal(false);
    };

    const loadProductActiveObject = () => {
        let object: ProductActiveObject = {};
        currentOrder.items.forEach((item) => {
            let useProduct: ProductActiveInput = {
                productId: item.productId,
                warehouseId: item.warehouseId,
                quantity: item.quantity,
                productVariantId: item.productVariantId,
            };
            let existing = object[item.productId];
            if (existing) {
                useProduct.quantity += existing.quantity;
            }
            object[item.productId] = useProduct;
        });
        setProductActiveObject(object);
    };

    const onSelectedFiles = (files: WahioFile[] = []) => {
        props.onOrderChange({
            ...currentOrder,
            files,
        });
    };

    if (!organizationState.currentOrganization) return <h1>Error: no se ha seleccionado ninguna tienda</h1>;

    if (!organizationState.currentOrganization?.orderInvoiceNumerationId) {
        return <EmptyOrderNumeration />;
    }

    const requiredCashRegister = organizationState.currentOrganization?.requiredCashRegister && !cashRegisterState.logActive;

    const orderPermission = getUserPermissions(userState.user).order;

    if (props.origin !== "restaurant") {
        if (props.status === "queued") {
            if (!currentOrder.id && !orderPermission?.create) {
                return <PermissionDenied message="No tienes permisos para crear pedidos" />;
            }
            if (currentOrder.id && !orderPermission?.update) {
                return <PermissionDenied message="No tienes permisos para editar pedidos" />;
            }
        } else {
            if (!currentOrder.id && !orderPermission?.create) {
                return <PermissionDenied message="No tienes permisos para crear ventas" />;
            }
            if (currentOrder.id && !orderPermission?.update) {
                return <PermissionDenied message="No tienes permisos para editar ventas" />;
            }
        }
    }

    const disabledItemsEdit = currentOrder.deliveryOrder && NON_ORDER_ITEM_UPDATE_STATUS_LIST.includes(currentOrder.deliveryOrder.status);

    let containerClass = "";
    if (props.isModal) containerClass += "using-modal";
    if (props.origin === "restaurant") containerClass += " origin-restaurant";

    if (appSettingsState.createOrderPanelFullScreen && props.origin !== "restaurant") {
        containerClass += " full-screen";
    }

    return (
        <CreateOrderFormPanel style={props.containerStyle}>
            {props.showCoverPanelLoading && <CoverPanelLoading />}
            {showFilesModal && (
                <CustomFilesModalModal
                    modalTitle="Archivos de la Orden"
                    files={currentOrder.files ?? []}
                    onFilesChanges={onSelectedFiles}
                    show={showFilesModal}
                    setShow={setShowFilesModal}
                />
            )}

            {warehouseException.showModalErrors && warehouseException.errorList.length > 0 && (
                <ProductExceptionFormModal
                    show={warehouseException.showModalErrors}
                    setShow={warehouseException.setShowModalErrors}
                    items={warehouseException.errorList}
                />
            )}
            {!currentOrder.id && <OrderLimitAlert />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.pageTitle}</title>
            </Helmet>
            {requiredCashRegister && (
                <CurrentRegisterLogModal useButtonClose={!requiredCashRegister} show={requiredCashRegister} setShow={() => {}} />
            )}
            {confirmClearOrder && (
                <ConfirmationModal
                    onCancel={() => setConfirmClearOrder(false)}
                    onOkay={() => handleClearOrder()}
                    title="¿Estas seguro?"
                    description="Esta acción no se puede revertir, el progreso de la orden se perderá"
                    show={confirmClearOrder}
                    setShow={setConfirmClearOrder}
                />
            )}
            {itemsExceedAllowedQuantity && (
                <ConfirmationModal
                    show={itemsExceedAllowedQuantity}
                    setShow={setItemsExceedAllowedQuantity}
                    hideButtonCancel={true}
                    title={"Superaste la cantidad de productos permitidos por orden"}
                    description={"Para agregar mas items te sugerimos que crees una nueva orden"}
                    onOkay={() => {}}
                />
            )}

            {openCreateStatusOrder && (
                <OrderShipmentConfirmationModal
                    {...props}
                    onCreateTransactionAccount={onCreateTransactionAccount}
                    show={!!openCreateStatusOrder}
                    onCompleted={(order) => {
                        setOrderResult(order);
                        props.onCompleted(order);
                    }}
                    setShow={() => setOpenCreateStatusOrder(undefined)}
                    status={openCreateStatusOrder === "draft" ? "draft" : props.status}
                />
            )}

            {openFinalizeModal && (
                <ModalFinalizeOrder
                    {...{ order: currentOrder, onFinalizeClose }}
                    onOrderChange={props.onOrderChange}
                    isSaving={orderMutation.isLoading}
                    onFinalize={onSaveOrder}
                    onCancel={onClickCancel}
                    show={openFinalizeModal}
                    setShow={(value) => {
                        setOpenFinalizeModal(value);
                        if (props.setStopAutoSaveOrderTable) props.setStopAutoSaveOrderTable(false);
                    }}
                    calculateItemsTotal={calculateTotalsUpdateItems}
                    origin={props.origin}
                />
            )}

            <CreateOrderContainer className={containerClass}>
                <ProductSearchList
                    activeProducts={productActiveObject}
                    isMobile={isMobile}
                    searchId={currentOrder.auxId}
                    selectedPrice={currentOrder.defaultPrice}
                    allowOutOfStock={currentOrder.status === "quotation" || currentOrder.orderTypeTemp === "quotation"}
                    onClickProduct={onClickProduct}
                    fixedHeight={props.origin !== "restaurant"}
                    isModal={props.isModal}
                    origin={props.origin}
                    showInsertProductButton
                    showLeftPanel
                    title="Agregar productos"
                    disabled={disabledItemsEdit}
                />
                <OrderItemsSummary
                    {...orderProps}
                    order={currentOrder}
                    onUpdateItems={calculateTotalsUpdateItems}
                    updateExistingItemsPrices={updateExistingItemsPrices}
                    onUpdateOrder={props.onOrderChange}
                    items={currentOrder.items}
                    hideLocationCode={!!props.status || !!currentOrder.isDelivery}
                    availableTaxes={taxState.items}
                    forceMobileView={props.forceMobileView}
                    orderResult={orderResult}
                    origin={props.origin}
                />
                <OrderDetailsPanel
                    order={currentOrder}
                    onOrderChange={props.onOrderChange}
                    updateExistingItemsPrices={updateExistingItemsPrices}
                    origin={props.origin}
                    status={props.status}
                    setOpenDraftModal={() => setOpenCreateStatusOrder("draft")}
                    setConfirmClearOrder={setConfirmClearOrder}
                    setOpenCreateStatusOrder={() => setOpenCreateStatusOrder("order")}
                    taxItems={taxState.items}
                    onUpdateItems={calculateTotalsUpdateItems}
                    onClickFinalizeMiddleware={onClickFinalizeMiddleware}
                    isMobile={isMobile}
                />
            </CreateOrderContainer>
        </CreateOrderFormPanel>
    );
};

export default CreateOrderForm;
