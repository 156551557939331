import React, { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";

const FlexImageStyle = styled.div`
    display: flex;
    overflow: hidden;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

interface FlexImageProps {
    image?: string;
    width: number;
    useAspectRatio?: boolean;
    radius?: number;
}

export const FlexImage = (props: FlexImageProps) => {
    const { image } = props;

    const [aspectRatio, setAspectRatio] = useState(1);

    useEffect(() => {
        if (props.useAspectRatio) {
            loadAspectRatio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    const loadAspectRatio = () => {
        if (image) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const ratio = width / height;
                setAspectRatio(ratio);
            };
        } else {
            setAspectRatio(1);
        }
    };

    let style: CSSProperties = {
        width: props.width || 36,
        minWidth: props.width || 36,
        height: props.width || 36,
        borderRadius: props.radius || 0,
    };

    if (props.useAspectRatio && image) {
        style.aspectRatio = `${aspectRatio}`;
        style.width = "auto";
    }

    return (
        <FlexImageStyle style={style}>{image ? <img src={image} alt={""} /> : <span className="fa-light fa-image"></span>}</FlexImageStyle>
    );
};
export default FlexImage;
