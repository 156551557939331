import styled from "styled-components";
import { DefaultButton, Flex } from "../_controls";

export const MainContainerSettings = styled(Flex)`
    width: 100%;
    max-width: 335px;
    padding: 15px;
    box-sizing: border-box;
    .app-version {
        padding: 6px 13px;
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
`;

export const SettingsButtonItem = styled(DefaultButton)`
    display: flex;
    align-items: center;
    padding: 4px 9px;
    border-radius: 8px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.text};
    font-weight: 500;
    border: 0;
    width: 100%;
    justify-content: flex-start;

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .icon {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.primary};
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    &:disabled {
        .icon {
            background: transparent;
            color: ${(props) => props.theme.palette.textLight}20;
        }
    }
`;

export const ElementTitle = styled(Flex)`
    border-radius: 10px;
    color: ${(props) => props.theme.palette.text};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .image {
        width: 38px;
        aspect-ratio: 1;
        display: flex;
        border-radius: 40px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .title {
        font-weight: 500;
        font-size: 16px;
    }
    .sub {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;
