import React, { useState } from "react";
import { IPaginationList } from "../../api/models";
import LoadingProgressBar from "../Loadings/LoadingProgressBar";
import PaginationView from "../PaginationView";
import { SquareButton } from "../_controls";
import MobileButton from "../_controls/buttons/MobileButton";
import { Flex, SearchCustom, Title, ToolbarView } from "../_controls/containers";

export interface SearchTabTitleProps {
    placeholder: string;
    searchValue?: string;
    setSearchValue: (value: string) => void;
    children?: any;
    loading: boolean;
    onPageChange?: (value: number) => void;
    pagination?: IPaginationList;
    btnTitle?: string;
    btnIcon?: string;
    onClickBtn?: () => void;
    title?: string;
    hideBorder?: boolean;
    removeTopSpace?: boolean;
    titleComponent?: React.ReactNode;
    outsideItem?: React.ReactNode;
    hideSearchInput?: boolean;
    hideMainButton?: boolean;
    hideButtonFilter?: boolean;
    btnTourName?: string;
    btnClassName?: string;
    removePadding?: boolean;
    filterCounter?: number;
    customSearch?: React.ReactNode;
    rowComponent?: React.ReactNode;
}

export default function SearchTabTitle(props: SearchTabTitleProps) {
    const { setSearchValue } = props;
    const [showFilters, setShowFilters] = useState(false);
    const filterCount = props.filterCounter ?? 0;

    return (
        <ToolbarView className={`${props.hideBorder ? "border-none" : ""} ${props.removePadding ? "remove-padding" : ""}`}>
            {props.loading && <LoadingProgressBar />}
            {props.titleComponent}
            {props.title && <Title>{props.title}</Title>}
            <Flex gap10 column>
                <Flex flexWrap={!props.hideButtonFilter} gap10 alignCenter w100>
                    <Flex gap10 alignCenter>
                        {props.customSearch}
                        {!props.customSearch && !props.hideSearchInput && (
                            <SearchCustom
                                initialValue={props.searchValue}
                                milliseconds={500}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder={props.placeholder}
                            />
                        )}

                        {!props.hideButtonFilter && (
                            <SquareButton
                                onClick={() => setShowFilters(!showFilters)}
                                className={`filter ${filterCount > 0 ? "active" : ""}`}
                            >
                                <i className="fa-regular fa-filter"></i>
                                {filterCount > 0 && <span className="alert-counter">{filterCount}</span>}
                            </SquareButton>
                        )}
                    </Flex>

                    {!props.hideMainButton && !!props.onClickBtn && props.btnTitle && (
                        <MobileButton
                            tourName={props.btnTourName}
                            className={`${props.btnClassName ?? ""} search-tab-button`}
                            title={props.btnTitle}
                            icon={props.btnIcon}
                            onClick={props.onClickBtn && props.onClickBtn}
                            rounded={true}
                        />
                    )}

                    <Flex
                        flexWrap
                        gap10
                        alignCenter
                        className={`${props.hideButtonFilter ? "" : "filter-options"}  ${showFilters && "show"}`}
                    >
                        {props.children}
                    </Flex>
                    {props.outsideItem}
                </Flex>
                {props.pagination && props.onPageChange && (
                    <PaginationView onPageChange={props.onPageChange} pagination={props.pagination} />
                )}
                {props.rowComponent}
            </Flex>
        </ToolbarView>
    );
}
