import React, { useEffect, useState } from "react";
import { ProductNavigationProfileProps } from ".";
import { IProductVariant } from "../../../api/models/product";
import { DefaultButton, Flex, FlexImageStyle, TextField } from "../../_controls";
import Modal from "../../modals/Modal";
import { getProductProfileImage } from "../helper";
import { VariantsTable, SelectedVariantCard } from "./styled";

export default function ProductVariantSelector(props: ProductNavigationProfileProps) {
    const { product, productVariant } = props;

    const [showVariants, setShowVariants] = useState(false);

    useEffect(() => {
        setShowVariants(false);
    }, [productVariant]);

    if (!product.includesVariants) {
        return null;
    }

    return (
        <Flex gap10 column>
            {showVariants && (
                <Modal overflowAuto removeVerticalAlign show={showVariants} setShow={setShowVariants} title="Seleccionar Variante" showHeader>
                    <VariantList {...props} />
                </Modal>
            )}
            <SelectedVariantCard onClick={() => setShowVariants(!showVariants)}>
                <Flex>
                    <TextField>Variante: <b>{productVariant?.valuesLine}</b></TextField>
                </Flex>
                <span className="fa-regular fa-angle-down"></span>
            </SelectedVariantCard>
        </Flex>
    );
}

const VariantList = (props: ProductNavigationProfileProps) => {
    const { product, productVariant } = props;

    const [optionObj, setOptionObj] = useState<{ [key: string]: string }>({});
    const variants = React.useMemo(() => {
        let optionKeys = Object.keys(optionObj);
        if (optionKeys.length > 0) {
            let items: IProductVariant[] = product.variants ?? [];
            optionKeys.forEach((optionName) => {
                const matchItems =
                    items.filter((x) => x.options.some((x) => x.variantName === optionName && x.value === optionObj[optionName])) ?? [];
                items = matchItems;
            });
            return items;
        } else {
            return product.variants;
        }
    }, [product.variants, optionObj]);

    return (
        <Flex column gap20 padding={20}>
            {product.variantOptions?.map((item, index) => {
                return (
                    <Flex column key={index} gap5>
                        <TextField>{item.name}</TextField>
                        <Flex gap5 flexWrap>
                            {item.values.map((value) => {
                                const isSelected = optionObj[item.name] === value;
                                return (
                                    <DefaultButton
                                        small
                                        bgLight
                                        primaryOutline={isSelected}
                                        rounded
                                        key={value}
                                        onClick={() => {
                                            if (isSelected) {
                                                let copyObj = { ...optionObj };
                                                delete copyObj[item.name];
                                                setOptionObj(copyObj);
                                            } else {
                                                setOptionObj({ ...optionObj, [item.name]: value });
                                            }
                                        }}
                                    >
                                        {value}
                                    </DefaultButton>
                                );
                            })}
                        </Flex>
                    </Flex>
                );
            })}
            <hr />
            <VariantsTable cellPadding={0} cellSpacing={0}>
                <tbody>
                    {variants?.map((item, index) => {
                        const image = getProductProfileImage(undefined, item);
                        const isSelected = item.valuesLine === productVariant?.valuesLine;
                        return (
                            <tr
                                key={index}
                                onClick={() => {
                                    props.setProductVariant(item);
                                }}
                                className={isSelected ? "bg-primary-light" : ""}
                            >
                                <td className="min-width">
                                    <FlexImageStyle width={35} borderRadius={10}>
                                        <img src={image} alt="" />
                                    </FlexImageStyle>
                                </td>
                                <td>{item.valuesLine}</td>
                                <td>{item.sku}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </VariantsTable>
        </Flex>
    );
};
