import styled from "styled-components";

export const AlertContainerCard = styled.div`
    left: 50%;
    transform: translate(-50%, 0px);
    bottom: 34px;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .alert-example {
        background: blue;
        width: 100px;
        height: 40px;
    }
`;

export const AlertNotificationContainerCard = styled.div`
    right: 30px;
    bottom: 34px;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: end;
    .clear-btn {
        background: ${(props) => props.theme.palette.background};
        box-shadow: 1px 1px 14px ${(props) => props.theme.palette.shadowColor};
    }
`;

export const AlertCardStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-radius: 15px;
    overflow: hidden;
    max-width: 500px;
    min-width: 200px;
    transform: scale(1);
    animation: animation1 0.2s ease-in;
    animation-iteration-count: 1;

    @keyframes animation1 {
        0% {
            transform: scale(0);
        }
        33% {
            transform: scale(0.5);
        }
        66% {
            transform: scale(1);
        }
    }

    .icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #4c6672;
    }
    .btn-remove {
        margin-left: auto;
        background: transparent;
        color: white;
    }
    background: #607d8b;
    color: white;
    &.error {
        color: white;
        background: ${(props) => props.theme.palette.dangerColor};
        box-shadow: 1px 1px 12px #2c2c2c;
        .icon {
            background: ${(props) => props.theme.palette.dangerColor};
        }
    }
    &.success {
        color: white;
        background: ${(props) => props.theme.palette.primary};
        box-shadow: 1px 1px 12px #2c2c2c;
        .icon {
            background: ${(props) => props.theme.palette.primaryDark};
        }
    }
`;

export const AlertNotificationCardStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px;
    border-radius: 15px;
    overflow: hidden;
    max-width: 300px;
    min-width: 150px;
    transform: scale(1);
    animation: animation1 0.2s ease-in;
    animation-iteration-count: 1;
    box-shadow: 1px 1px 14px ${(props) => props.theme.palette.shadowColor};
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .with-link {
        border-radius: 15px;
        padding: 10px;
        cursor: pointer;
        :hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }

    @keyframes animation1 {
        0% {
            transform: scale(0);
        }
        33% {
            transform: scale(0.5);
        }
        66% {
            transform: scale(1);
        }
    }

    .btn-remove {
        margin-left: auto;
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.text};
    }
    background: ${(props) => props.theme.palette.background};
    color: ${(props) => props.theme.palette.text};
`;
