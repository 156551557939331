import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useBarcodeListener, useEventListenerWindow, useIsMobileListener } from "../..";
import { wahioFetch } from "../../../api";
import { IPaginationResponse } from "../../../api/models";
import { IProduct, ProductOriginTypes, ProductPriceTypes } from "../../../api/models/product";
import { IProductCategory } from "../../../api/models/productCategory";
import { IWarehouse } from "../../../api/models/warehouse";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { getWarehouse } from "../../../api/products/warehouseApi";
import { productEndpoint } from "../../../api/restApiEndpoint";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { KEYS_FILTER_PRODUCT_SEARCH } from "../../../appSettings/types";
import { getAuthBody } from "../../../auth";
import { CustomCategoryContext } from "../../../store/contexts/CustomCategoryContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { useFirstRender } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
import { useAlert } from "../../Alerts/Alert";
import DeviceSettingsModal from "../../DeviceSettings/ProductSearchSettingsModal";
import EmptyWarehouseView from "../../EmptyResultViews/EmptyWarehouseView";
import LoadingDualRing from "../../LoadingDualRing";
import LoadingProgressBar from "../../Loadings/LoadingProgressBar";
import { DefaultButton, Flex, PanelHeaderContainer, SquareButton, TextField } from "../../_controls";
import SearchInputTimer from "../../_controls/inputs/SearchInputTimer";
import { ProductAddOption, ProductClickProps } from "../ProductCard";
import CategoriesPanel, { CategoryGridPanel } from "./CategoriesPanel";
import ProductAddQuantityModal from "./ProductAddQuantityModal";
import { ProductList } from "./ProductCardList";
import { ProductSearchContextProps, useProductSearchContext } from "./ProductSearchContext";
import { calculateProductGridColumns } from "./helper";
import messages from "./messages";
import {
    AddProductFloatCard,
    ControlsContainer,
    CounterFilter,
    ExpandableContainer,
    LoadMoreDetailsContainer,
    NotMoreItems,
    ProductItemsContainer,
    WarehouseSelectorContainer,
} from "./styled";

export interface ListProductProps extends ProductClickProps {
    productPagination?: IPaginationResponse<IProduct>;
    warehouseProductPagination?: IPaginationResponse<IWarehouseProduct>;
    defaultPrice: ProductPriceTypes;
    searchType: ProductSearchType;
    activeProducts?: ProductActiveObject;
    disabledActiveProducts?: boolean;
    warehouse?: IWarehouse;
    showFakeItems?: boolean;
    origin?: ProductOriginTypes;
    isExpandible: boolean;
}

export interface ProductActiveObject {
    [key: string]: ProductActiveInput;
}

export interface ProductActiveInput {
    productId: string;
    productVariantId?: string;
    warehouseId?: string;
    quantity: number;
}

export interface ProductListProps extends ProductClickProps {
    searchId?: string;
    showAllProducts?: boolean;
    defaultWarehouse?: IWarehouse;
    hideWarehouseSelect?: boolean;
    onlyInventoryProducts?: boolean;
    isMobile?: boolean;
    origin?: ProductOriginTypes;
    isModal?: boolean;
    isBasePrice?: boolean;
    selectedPrice?: ProductPriceTypes;
    activeProducts?: ProductActiveObject;
    disabledActiveProducts?: boolean;
    fixedHeight?: boolean;
    hideThisProductIds?: string[];
    showInsertProductButton?: boolean;
    showLeftPanel?: boolean;
    title?: string;
    customComponent?: React.ReactNode;
    disabled?: boolean;
}
export type ProductSearchType = "warehouseProduct" | "product";
export interface ProductSearchListState {
    showDeviceSettings: boolean;
    selectedCategory?: IProductCategory;
    selectedWarehouse?: IWarehouse;
    defaultWarehouse?: IWarehouse;
    hideThisProductIds?: string[];
    searchValue?: string;
    page: number;
    pageSize: number;
    onlyInventoryProducts?: boolean;
    searchType: ProductSearchType;
    allowOutOfStock?: boolean;
    origin?: string;
}

export default function ProductSearchList(props: ProductListProps) {
    if (props.showInsertProductButton) {
        return <ExpandableContainerPanel {...props} />;
    }
    return <ProductSearchListBase {...props} onAllowMinimize={() => {}} isExpandible={false} />;
}

const ExpandableContainerPanel = (props: ProductListProps) => {
    const [localSearchPanel, setLocalSearchPanel] = useLocalStorage<boolean>("PRODUCT_SEARCH_PANEL", false);

    const [showProductSearch, setShowProductSearch] = useState(localSearchPanel);
    const [expandibleModeActive, setExpandibleModeActive] = useState(localSearchPanel);

    let isMobile = useIsMobileListener({ size: 1000 });
    if (props.isMobile) isMobile = true;

    const onAllowMinimize = (value: boolean) => {
        setExpandibleModeActive(value);
        setShowProductSearch(value);
        setLocalSearchPanel(value);
    };

    const isExpandible = expandibleModeActive || !(!isMobile && props.showLeftPanel);
    let className = isExpandible ? "expandible" : "left-panel";
    const isVisible = !isExpandible || showProductSearch;
    const hideExpandibleButton = props.showInsertProductButton && !props.showLeftPanel;

    if (props.disabled) {
        className += " disabled";
    }

    if (!isExpandible) {
        return (
            <ProductSearchListBase
                {...props}
                onAllowMinimize={onAllowMinimize}
                fixedHeight={isExpandible || props.fixedHeight}
                minimizeButtonOpen={showProductSearch}
                isExpandible={isExpandible}
                maxWidth={500}
            />
        );
    }

    return (
        <>
            <ExpandableContainer className={className} style={{ visibility: isVisible ? "visible" : "hidden" }}>
                <div
                    className="product-search-layout"
                    id="SEARCH-LAYOUT"
                    onClick={(e: any) => {
                        if (e.target.id === "SEARCH-LAYOUT") setShowProductSearch(false);
                    }}
                >
                    <div className="product-search-body" style={{ transform: isVisible ? "translate(0px, 0px)" : "translate(0px, 34px)" }}>
                        {showProductSearch && isExpandible && (
                            <Flex className="search-header-top" spaceBetween alignCenter paddingLeft={10}>
                                {props.customComponent ?? <TextField bold>{props.title}</TextField>}

                                <Flex gap10>
                                    {showProductSearch && !hideExpandibleButton && (
                                        <SquareButton removeBackground colorText onClick={() => onAllowMinimize(!expandibleModeActive)}>
                                            <span
                                                className={
                                                    showProductSearch
                                                        ? "fa-regular fa-arrow-up-right-and-arrow-down-left-from-center"
                                                        : "fa-regular fa-arrow-down-left-and-arrow-up-right-to-center"
                                                }
                                            ></span>
                                        </SquareButton>
                                    )}
                                    <SquareButton removeBackground colorText onClick={() => setShowProductSearch(false)}>
                                        <i className="fa-regular fa-xmark"></i>
                                    </SquareButton>
                                </Flex>
                            </Flex>
                        )}
                        <ProductSearchListBase
                            {...props}
                            onAllowMinimize={onAllowMinimize}
                            fixedHeight={isExpandible || props.fixedHeight}
                            minimizeButtonOpen={showProductSearch}
                            isExpandible={isExpandible}
                        />
                    </div>
                </div>
            </ExpandableContainer>
            {isExpandible && (
                <AddProductFloatCard onClick={() => setShowProductSearch(!showProductSearch)}>
                    <i className="fa-regular fa-plus"></i>
                    Insertar Producto
                </AddProductFloatCard>
            )}
        </>
    );
};

interface ProductListBaseProps extends ProductListProps {
    minimizeButtonOpen?: boolean;
    onAllowMinimize: (value: boolean) => void;
    isExpandible: boolean;
    maxWidth?: number;
}

function ProductSearchListBase(props: ProductListBaseProps) {
    const intl = useIntl();
    const { onAllowMinimize } = props;
    const authBody = getAuthBody();
    const alert = useAlert();

    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const { customCategoryState, customCategoryActions } = useContext(CustomCategoryContext);
    const [barcodeFetching, setBarcodeFetching] = useState(false);
    const [selectedProductOption, setSelectedProductOption] = useState<ProductAddOption | undefined>(undefined);
    const [filterActiveProducts, setFilterActiveProducts] = useState<number>(0);
    const fetchRef = useRef(new Date());

    const [showCategoryGrid, setShowCategoryGrid] = useState(false);

    const productCardsGridColumns = useRef(0);
    const [state, setState] = useState<ProductSearchListState>({
        showDeviceSettings: false,
        defaultWarehouse: props.warehouse,
        onlyInventoryProducts: props.onlyInventoryProducts,
        page: 1,
        pageSize: 20,
        searchType: props.showAllProducts ? "product" : "warehouseProduct",
        hideThisProductIds: props.hideThisProductIds,
    });

    const changeShape = () => {
        const newShape = appSettingsState.productListShape === "row" ? "square" : "row";
        appSettingsActions.setProductShape(newShape);
    };
    const shape = appSettingsState.productListShape;

    const productSearchContext = useProductSearchContext();

    //const productSearchQuery = useInfinityQueryProductSearchList(state, state.searchType);

    const { warehouseState } = useWarehouseContext();

    const warehouseMutation = useMutation((warehouseId: string) => getWarehouse(warehouseId), {
        onSuccess: (data) => {
            setState({ ...state, selectedWarehouse: data });
        },
    });

    const firstRender = useFirstRender();

    const handleUpdateContainerHeight = () => {
        onCalculateGridColumns();
    };

    useEffect(() => {
        handleUpdateContainerHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isExpandible]);

    useEffect(() => {
        if (props.defaultWarehouse) {
            setState({ ...state, defaultWarehouse: props.defaultWarehouse, selectedWarehouse: props.defaultWarehouse });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultWarehouse]);

    useEffect(() => {
        if (!firstRender) setState({ ...state, hideThisProductIds: props.hideThisProductIds });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.hideThisProductIds]);

    useEffect(() => {
        if (!props.defaultWarehouse && !state.selectedWarehouse && warehouseState.allWarehouses.length > 0) {
            let match = warehouseState.allWarehouses.find((x) => x.id === appSettingsState.defaultWarehouseId);
            setState({ ...state, selectedWarehouse: match });
        }
        setTimeout(onCalculateGridColumns, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseState.allWarehouses]);

    useEffect(() => {
        onCalculateGridColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettingsState.productListShape]);

    const onCalculateGridColumns = () => {
        if (appSettingsState.productListShape === "row") {
            productCardsGridColumns.current = 1;
        } else {
            calculateProductGridColumns((result) => (productCardsGridColumns.current = result));
        }
    };

    const handleFetchNextPage = () => {
        let pagination =
            state.searchType === "product" ? productSearchContext.productPagination : productSearchContext.warehouseProductPagination;
        if (state.page < pagination.totalPages) {
            setState({ ...state, page: state.page + 1 });
        }
    };

    const scrollFetchingQuery = (container: any) => {
        if (container && !showCategoryGrid) {
            let scroll = {
                top: Math.trunc(container.scrollTop),
                height: Math.trunc(container.scrollHeight),
                offset: Math.trunc(container.offsetHeight),
                operation: 0,
            };

            scroll.operation = Math.trunc(scroll.height - scroll.offset) - scroll.top;
            if (scroll.operation - 15 < 0) {
                const newDate = new Date();
                const diffDate = moment(newDate).diff(fetchRef.current);
                if (diffDate > 500) {
                    handleFetchNextPage();
                }
                fetchRef.current = newDate;
            }
        }
    };

    useEffect(() => {
        if (!props.defaultWarehouse && appSettingsState.defaultWarehouseId) {
            let warehouseItems = warehouseState.allWarehouses;
            let warehouse = warehouseItems.find((x) => x.id === appSettingsState.defaultWarehouseId);
            if (warehouseItems && warehouse) {
                setState({ ...state, selectedWarehouse: warehouse });
            } else if (!warehouseItems) {
                setState({ ...state, selectedWarehouse: undefined, defaultWarehouse: undefined });
            } else {
                warehouseMutation.mutate(appSettingsState.defaultWarehouseId);
            }
        } else if (!props.defaultWarehouse && !appSettingsState.defaultWarehouseId) {
            setState({ ...state, selectedWarehouse: undefined, defaultWarehouse: undefined });
        }
        if (customCategoryState.items.length === 0) customCategoryActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettingsState.defaultWarehouseId]);

    useEffect(() => {
        productSearchContext.handleProductSearch(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.searchId,
        state.selectedCategory,
        state.selectedWarehouse,
        state.searchValue,
        state.page,
        state.searchType,
        state.hideThisProductIds,
        appSettingsState.productSearchOnlyStockProducts,
        appSettingsState.productSearchOnlyProductWithImage,
        appSettingsState.productSearchOnlyProductWithoutImage,
        appSettingsState.productSearchSort,
        appSettingsState.productSearchMinPrice,
        appSettingsState.productSearchMaxPrice,
        appSettingsState.productSearchMinStock,
        appSettingsState.productSearchQuantityDelta,
        appSettingsState.productSearchPriceDelta,
        appSettingsState.productSearchMaxPrice,
        appSettingsState.productSearchSort,
    ]);

    useEffect(() => {
        const configProductFilter = Object.keys(_.pick(appSettingsState, KEYS_FILTER_PRODUCT_SEARCH)).filter(
            (key) => !!(appSettingsState as any)[key]
        );
        setFilterActiveProducts(configProductFilter.length);
    }, [appSettingsState]);

    useBarcodeListener((barcode) => searchByBarcode(barcode));

    useEventListenerWindow("resize", () => {
        handleUpdateContainerHeight();
    });

    const searchByBarcode = (barcode: string) => {
        if (!barcode) return;
        if (state.selectedWarehouse) {
            searchByCodeWarehouse(barcode);
        } else {
            searchByCodeProduct(barcode);
        }
    };

    const getProductPrice = (): ProductPriceTypes => {
        if (props.isBasePrice) {
            return "costLastPurchase";
        } else if (props.selectedPrice) {
            return props.selectedPrice;
        } else if (appSettingsState.defaultPrice) {
            return appSettingsState.defaultPrice;
        }
        return "price";
    };

    const searchByCodeWarehouse = (barcode: string) => {
        setBarcodeFetching(true);
        wahioFetch.get(
            productEndpoint.get.warehouseProductByBarcode(authBody.accountId, appSettingsState.defaultWarehouseId, barcode),
            (success) => {
                const warehouseProduct: IWarehouseProduct = success.data;
                const { product } = warehouseProduct;

                props.onClickProduct({
                    product,
                    warehouseProduct,
                    productVariant: warehouseProduct.productVariant,
                    warehouse: state.selectedWarehouse,
                    stock: warehouseProduct.stock,
                    quantity: 1,
                    customPrice: product[getProductPrice()],
                    productIndex: 0,
                });
                clearBarcode();
            },
            (error) => {
                alert.info(intl.formatMessage(messages.noProductWithTheBardcodeWasFound));
                clearBarcode();
            }
        );
    };
    const searchByCodeProduct = (barcode: string) => {
        setBarcodeFetching(true);
        wahioFetch.get(
            productEndpoint.get.productByBarcode(authBody.accountId, barcode),
            (success) => {
                const product: IProduct = success.data;
                props.onClickProduct({
                    product,
                    warehouse: state.selectedWarehouse,
                    stock: 0,
                    quantity: 1,
                    customPrice: product[getProductPrice()],
                    productIndex: 0,
                });
                clearBarcode();
            },
            (error) => {
                alert.info(intl.formatMessage(messages.noProductWithTheBardcodeWasFound));
                clearBarcode();
            }
        );
    };

    const clearBarcode = () => {
        let input: any = document.getElementById("searchbarcodeproduct");
        if (input) {
            input.value = "";
        }
        setBarcodeFetching(false);
    };

    const localOnClick = (productOption: ProductAddOption) => {
        if (productOption.product.includesOptionals) {
            setSelectedProductOption(productOption);
        } else if (props.origin === "restaurant" || appSettingsState.productSearchShowAddModal) {
            setSelectedProductOption(productOption);
        } else {
            props.onClickProduct(productOption);
        }
    };

    const onProductModalChange = (productOption: ProductAddOption) => {
        setSelectedProductOption(undefined);
        props.onClickProduct(productOption);
    };

    return (
        <>
            {selectedProductOption && (
                <ProductAddQuantityModal
                    show={!!selectedProductOption}
                    setShow={(value) => setSelectedProductOption(undefined)}
                    item={selectedProductOption}
                    onChange={onProductModalChange}
                    origin={props.origin}
                    activeProducts={props.activeProducts}
                />
            )}

            {state.showDeviceSettings && (
                <DeviceSettingsModal
                    show={state.showDeviceSettings}
                    setShow={(value) => setState({ ...state, showDeviceSettings: value })}
                    origin={props.origin}
                    defaultWarehouseId={appSettingsState.defaultWarehouseId}
                    showWarehouse={!props.showAllProducts && !props.hideWarehouseSelect}
                />
            )}
            <PanelHeaderContainer
                style={{
                    maxWidth: props.maxWidth ? `500px` : "auto",
                }}
            >
                <ControlsContainer className="product-search-header">
                    {productSearchContext.isFetching && <LoadingProgressBar />}
                    <Flex alignCenter gap10>
                        <SearchInputTimer
                            className="search-input-product"
                            initialValue={state.searchValue}
                            onChange={(e) => setState({ ...state, searchValue: e.target.value, page: 1 })}
                            placeholder={"Buscar productos"}
                            milliseconds={400}
                        />
                        <SquareButton onClick={changeShape}>
                            <span className={shape === "square" ? "fa-regular fa-list" : "fa-regular fa-grid-2"}></span>
                        </SquareButton>
                        <SquareButton onClick={() => setState({ ...state, showDeviceSettings: true })}>
                            <Flex spaceBetween>
                                <i className="fa-regular fa-gear"></i>
                                {filterActiveProducts > 0 && (
                                    <CounterFilter>
                                        <span>{filterActiveProducts}</span>
                                    </CounterFilter>
                                )}
                            </Flex>
                        </SquareButton>
                        {props.showInsertProductButton && !props.minimizeButtonOpen && (
                            <SquareButton onClick={() => onAllowMinimize(!props.minimizeButtonOpen)}>
                                <span className={"fa-regular fa-arrow-down-left-and-arrow-up-right-to-center"}></span>
                            </SquareButton>
                        )}

                        {barcodeFetching && <LoadingDualRing small={true} />}
                    </Flex>

                    {!showCategoryGrid && (
                        <CategoriesPanel
                            onClickShowCards={() => {
                                setShowCategoryGrid(!showCategoryGrid);
                            }}
                            selectedCategory={state.selectedCategory}
                            onSelectedCategory={(value) => setState({ ...state, selectedCategory: value, page: 1 })}
                        />
                    )}
                </ControlsContainer>

                <ProductItemsContainer
                    className="productitems-bs23333"
                    onScroll={(e) => {
                        scrollFetchingQuery(e.target);
                    }}
                >
                    {showCategoryGrid && (
                        <CategoryGridPanel
                            onBack={() => setShowCategoryGrid(false)}
                            selectedCategory={state.selectedCategory}
                            onSelectedCategory={(value) => {
                                setState({ ...state, selectedCategory: value });
                                setShowCategoryGrid(false);
                            }}
                        />
                    )}
                    {!showCategoryGrid && (
                        <ProductItemsResult
                            {...props}
                            state={state}
                            localOnClick={localOnClick}
                            getProductPrice={getProductPrice}
                            productContextState={productSearchContext}
                        />
                    )}
                </ProductItemsContainer>
            </PanelHeaderContainer>
        </>
    );
}

interface ProductItemsResultProps extends ProductListProps {
    isExpandible: boolean;
    state: ProductSearchListState;
    localOnClick: (productOption: ProductAddOption) => void;
    getProductPrice: () => ProductPriceTypes;
    productContextState: ProductSearchContextProps;
}

const ProductItemsResult = (props: ProductItemsResultProps) => {
    const { state, localOnClick, getProductPrice, productContextState } = props;
    const { warehouseState } = useWarehouseContext();
    const { organizationState } = useContext(OrganizationContext);

    const pagination =
        state.searchType === "product" ? productContextState.productPagination : productContextState.warehouseProductPagination;

    const productListProps: ListProductProps = {
        onClickProduct: localOnClick,
        productPagination: productContextState.productPagination,
        warehouseProductPagination: productContextState.warehouseProductPagination,
        warehouse: state.selectedWarehouse,
        defaultPrice: getProductPrice(),
        searchType: props.showAllProducts ? "product" : "warehouseProduct",
        activeProducts: props.activeProducts,
        allowOutOfStock: props.allowOutOfStock,
        origin: props.origin,
        isExpandible: props.isExpandible,
    };

    const getTotalItems = () => {
        return pagination.totalItems;
    };

    return (
        <>
            {warehouseState.allWarehouses.length === 0 && !warehouseState.isFetching && (
                <Flex column padding={20}>
                    <EmptyWarehouseView organizationId={organizationState?.currentOrganization?.id} />
                </Flex>
            )}

            {state.selectedWarehouse && (
                <>
                    <ProductList {...productListProps} showFakeItems={productContextState.isFetching && pagination.totalItems === 0} />

                    {productContextState.isFetching && <LoadingDualRing center />}
                    {!productContextState.isFetching && pagination.totalItems > 0 && productContextState.hasNextPage() && (
                        <LoadMoreDetailsContainer>
                            <DefaultButton borderRadius={10} bgLight onClick={() => productContextState.fetchNextPage()}>
                                <i className="fa-regular fa-plus"></i> Cargar mas
                            </DefaultButton>
                        </LoadMoreDetailsContainer>
                    )}

                    {pagination.totalItems > 0 && !productContextState.hasNextPage() && (
                        <NotMoreItems>No hay mas items para mostrar</NotMoreItems>
                    )}

                    <WarehouseSelectorContainer>
                        <TextField light small>
                            {getTotalItems()} <FormattedMessage {...messages.results} />
                        </TextField>
                        {!props.showAllProducts && !props.hideWarehouseSelect && (
                            <Flex alignCenter gap5 title="Bodega seleccionada">
                                <TextField className="fa-regular fa-garage" small light></TextField>
                                <TextField light small>
                                    {state.selectedWarehouse?.name}
                                </TextField>
                            </Flex>
                        )}
                        {props.hideWarehouseSelect && props.defaultWarehouse && (
                            <TextField light small>
                                {props.defaultWarehouse.name}
                            </TextField>
                        )}
                    </WarehouseSelectorContainer>
                </>
            )}
        </>
    );
};
