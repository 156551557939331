import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../api";
import { getAccountBillingDueInvoices, recoverCanceledAccount } from "../../api/account/accountApi";
import { BillingInvoice } from "../../api/models/accountBilling";
import { Account, AccountRecoverRequestInput, BillingDueInvoices } from "../../api/models/accountUser";
import { CloudImages } from "../../assets";
import { clearAuthSession, getAuthBody } from "../../auth";
import { PayInvoicePendingForm } from "../../components/Account/Billing/PayInvoicePendingForm";
import LoadingDualRing from "../../components/LoadingDualRing";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Modal from "../../components/modals/Modal";
import { Flex, FlexImageStyle, PrimaryButton, TextField } from "../../components/_controls";
import { checkAndGetMessage } from "../../i18n/helper";
import { useUserContext } from "../../store/contexts/UserContext";
import { formatMoney, getDateFormat } from "../../utils";
import messages from "./messages";
import { DisabledAccountViewContainer } from "./styled";
import { useAlert } from "../../components/Alerts/Alert";

interface DisabledAccountViewProps {
    account: Account;
}

export default function DisabledAccountView(props: DisabledAccountViewProps) {
    const { account } = props;
    const intl = useIntl();
    const [selectedInvoice, setSelectedInvoice] = useState<BillingInvoice>();
    const { userActions } = useUserContext();
    const alert = useAlert();
    const [dueInfo, setDueInfo] = useState<BillingDueInvoices>();
    const invoiceMutation = useMutation((accountId: string) => getAccountBillingDueInvoices(accountId), {
        onSuccess: (data) => {
            setDueInfo(data);
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    const [showRecoveryConfirmation, setShowRecoveryConfirmation] = useState(false);

    const recoveryMutation = useMutation((data: AccountRecoverRequestInput) => recoverCanceledAccount(data), {
        onSuccess: (data) => {
            userActions.requestAccountUser(getAuthBody().accountUserId);
            alert.success("Cuenta reactivada con éxito");
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        if (account.status === "paymentRequired") {
            invoiceMutation.mutate(getAuthBody().accountId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    if (account.status === "paymentRequired") {
        return (
            <DisabledAccountViewContainer>
                {selectedInvoice && (
                    <Modal showHeader title="Pagar Factura" show={!!selectedInvoice} setShow={() => setSelectedInvoice(undefined)}>
                        <Flex padding={20}>
                            <PayInvoicePendingForm
                                onCancel={() => setSelectedInvoice(undefined)}
                                onCreate={() => {
                                    userActions.requestAccountUser(getAuthBody().accountUserId);
                                }}
                                invoice={selectedInvoice}
                            />
                        </Flex>
                    </Modal>
                )}
                <div className="subContainer">
                    <Flex column gap={20}>
                        <div className="logo">
                            <img src={CloudImages.wahioLogo100} alt="Wahio Logo" />
                            <span>Wahio</span>
                        </div>
                        <hr />
                        <Flex alignCenter justifyCenter w100 column gap10>
                            <FlexImageStyle width={200}>
                                <img src={CloudImages.feelingSorry} alt="" />
                            </FlexImageStyle>
                            <Flex column gap5>
                                <h2 className="text-bold m-0 text-center">Cuenta desactivada por falta de pago</h2>
                                <span className="text-small text-light">
                                    Lo sentimos, tuvimos que desactivar tu cuenta porque no se recibió a tiempo el pago de algunas facturas.
                                    Pero no te preocupes, una vez realices el pago podrás continuar con todos los servicios
                                </span>
                            </Flex>
                        </Flex>
                        <div className="mt-1">
                            <span>Estado: {checkAndGetMessage(intl, messages, props.account.status)}</span>
                        </div>

                        {dueInfo && (
                            <Flex column gap15>
                                <span>Tienes ({dueInfo.dueInvoices.length}) Facturas pendientes</span>
                                {dueInfo.dueInvoices.map((item) => {
                                    return (
                                        <Flex spaceBetween alignCenter border borderRadius={15} padding={15}>
                                            <Flex column>
                                                <span>Valor: {formatMoney(item.amount)}</span>
                                                <span className="text-light text-small">{getDateFormat(item.createdAt, "DD/MM/YYYY")}</span>
                                            </Flex>
                                            <PrimaryButton onClick={() => setSelectedInvoice(item)}>
                                                <span className="wahioicon-credit-card"></span>
                                                Pagar Ahora
                                            </PrimaryButton>
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        )}

                        <div className="mt-2">
                            <hr />
                            <p>Si tienes dudas, comunícate con el equipo de soporte.</p>
                            <a target="_blank" rel="noopener noreferrer" href="https://support.wahio.com">
                                Ir a la página de soporte
                            </a>
                        </div>
                        <TextField smaller cursorPointer light onClick={() => clearAuthSession()}>{intl.formatMessage(messages.signOut)}</TextField>
                    </Flex>
                </div>
            </DisabledAccountViewContainer>
        );
    }

    if (account.status === "cancelled") {
        return (
            <DisabledAccountViewContainer>
                {showRecoveryConfirmation && (
                    <ConfirmationModal
                        show={showRecoveryConfirmation}
                        setShow={setShowRecoveryConfirmation}
                        description={""}
                        title="¿Estás seguro que quieres reactivar tu cuenta?"
                        onOkay={() => {
                            recoveryMutation.mutate({
                                accountId: getAuthBody().accountId,
                                accountUserId: getAuthBody().accountUserId,
                            });
                        }}
                    />
                )}
                <div className="subContainer">
                    <Flex spaceBetween borderBottom paddingBottom={10}>
                        <div className="logo">
                            <img src={CloudImages.wahioLogo100} alt="Wahio Logo" />
                            <span>Wahio</span>
                        </div>
                        <TextField smaller cursorPointer light onClick={() => clearAuthSession()}>{intl.formatMessage(messages.signOut)}</TextField>
                    </Flex>
                    <h1 className="title">La cuenta se encuentra cancelada</h1>
                    <div>
                        <p>
                            Puedes volver a activar tu cuenta en cualquier momento. pero ten en cuenta que no podrás acceder a los datos de
                            tu cuenta si ultima factura se encuentra vencida
                            <br /> <br />
                            Si tienes dudas, comunícate con el equipo de soporte.
                        </p>
                        <a href="https://support.wahio.com">Ir a la página de soporte</a>
                    </div>

                    <Flex className="mt-2">
                        <PrimaryButton disabled={recoveryMutation.isLoading} rounded onClick={() => setShowRecoveryConfirmation(true)}>
                            Reactivar Cuenta {recoveryMutation.isLoading && <LoadingDualRing small />}
                        </PrimaryButton>
                    </Flex>
                </div>
            </DisabledAccountViewContainer>
        );
    }

    return (
        <DisabledAccountViewContainer>
            <div className="subContainer">
                <Flex borderBottom paddingBottom={10} spaceBetween>
                    <div className="logo">
                        <img src={CloudImages.wahioLogo100} alt="Wahio Logo" />
                        <span>Wahio</span>
                    </div>
                    <TextField smaller cursorPointer light onClick={() => clearAuthSession()}>{intl.formatMessage(messages.signOut)}</TextField>
                </Flex>
                <h1 className="title">La cuenta está desactivada</h1>

                <div>
                    <span>Estado: {checkAndGetMessage(intl, messages, props.account.status)}</span>
                </div>
                <div>
                    <p>
                        La cuenta fue desactivada automáticamente por Wahio, esto puede ocurrir por incumpliendo de pago o uso inadecuado de
                        la plataforma.
                        <br /> <br />
                        Si tienes dudas, comunícate con el equipo de soporte.
                    </p>
                    <a href="https://support.wahio.com">Ir a la página de soporte</a>
                </div>
            </div>
        </DisabledAccountViewContainer>
    );
}
