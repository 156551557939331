import { ProductCategory, IProductCategory } from "./productCategory";
import { IPaginationResponse, IPaginationRequest, WahioFile, DateFilter } from ".";
import { IProductTax } from "./tax";
import { IWarehouse } from "./warehouse";
import { IWarehouseProduct } from "./warehouseProduct";
import { IWarehouseProductLocation } from "./warehouseLocation";
import { getUniqueId } from "../../utils";
import { v4 } from "uuid";
import { ProductDiscount } from "./discount";

export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const INVALID_REQUEST_PRODUCTS = "INVALID_REQUEST_PRODUCTS";

export type ProductType = "product" | "service";

export type ProductPriceTypes =
    | "basePrice"
    | "costLastPurchase"
    | "price"
    | "price2"
    | "price3"
    | "price4"
    | "price5"
    | "price6"
    | "price7"
    | "price8"
    | "price9"
    | "price10";

export type ProductUnitMeasureTypes =
    | "kg"
    | "g"
    | "lb"
    | "oz"
    | "mg"
    | "t"
    | "cm"
    | "m"
    | "km"
    | "mm"
    | "in"
    | "L"
    | "mL"
    | "gal"
    | "oz"
    | "qt"
    | "C"
    | "F"
    | "K"
    | "m2"
    | "cm2"
    | "km2"
    | "ha"
    | "m/s"
    | "m/h"
    | "km/h"
    | "km/m";

export type ProductOriginTypes = "movement" | "purchase" | "sale" | "restaurant" | "quotation";

export type ProductEcommerceStatusType = "visible" | "hidden" | "outOfStock";
export const ProductEcommerceStatusList: ProductEcommerceStatusType[] = ["visible", "hidden", "outOfStock"];

export type EcommerceLabelPosition = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
export const EcommerceLabelPositions: EcommerceLabelPosition[] = ["topLeft", "topRight", "bottomLeft", "bottomRight"];

export interface ProductEcommerceStatus {
    id: string;
    productId: string;
    ecommerceId: string;
    status: ProductEcommerceStatusType;
}

export interface ProductEcommerceStatusInput {
    status: ProductEcommerceStatusType;
    productId: string;
    catalogId: string;
}

export interface ProductEcommerceLabel {
    imageUrl?: string;
    title: string;
    position: EcommerceLabelPosition;
    discountValue: number;
    startDate: string | Date;
    endDate: string | Date;
}

export type ProductStatus = "active" | string;

export interface ProductPriceBody {
    price: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    price6: number;
    price7: number;
    price8: number;
    price9: number;
    price10: number;
}

export interface IProduct {
    id: string;
    accountId: string;
    name: string;
    description: string;
    characteristics: string;
    categoryId?: string;
    category: IProductCategory;
    customCategoryId?: string;
    customCategory: IProductCategory;
    basePrice: number;
    controlInventory: boolean;
    profilePicture: string;
    code: string;
    codeType: number;
    sku: string;
    globalStock: number;
    dateCreated: Date;
    dateUpdated: Date;
    status: ProductStatus;
    reference: string;
    referenceCodes: string;
    measurement?: string;

    reviewRating: number;
    reviewCount: number;
    itemWidth: number;
    itemLength: number;
    itemHeight: number;
    itemWeight: number;

    //search engine
    slug: string;
    searchEngineTitle?: string;
    searchEngineDescription?: string;

    //COSTS
    purchaseCount: number;
    purchaseQuantity: number;
    costWeightedAverage: number;
    costBaseWeightedAverage: number;
    costAverage: number;
    costBaseAverage: number;
    costLastPurchase: number;
    costBaseLastPurchase: number;
    lastPurchaseDate?: Date;
    costUpdatedAt?: Date | string;

    //SALES
    totalSaleTotalPriceSum: number;
    totalSalePriceSum: number;
    totalSaleCount: number;
    totalSaleStockSum: number;
    saleWeightedAverage: number;
    saleAverageCost: number;
    lastSalePrice: number;
    lastSaleDate?: Date;

    price: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    price6: number;
    price7: number;
    price8: number;
    price9: number;
    price10: number;

    isParent: boolean;
    hasTaxes: boolean;
    availableOnline: boolean;
    fastShipping: boolean;
    childs: IProductChild[];
    hideChildsInEcommerce: boolean;
    taxes: IProductTax[];
    images?: WahioFile[];
    files?: WahioFile[];
    discounts?: ProductDiscount[];
    productCategories: IProductSubCategory[];

    variants: IProductVariant[];
    variantOptions?: VariantOption[];
    variantOptionsLine: string;

    includesVariants: boolean;
    variantImageForOption?: string;
    variantsInGroups?: boolean;

    includesSerials: boolean;
    includesOptionals: boolean;

    optionals?: ProductOptional[];

    fileTagsAsString?: string;
    fileTags?: string[];

    ecommerceStatus: ProductEcommerceStatusType;
    ecommerceLabels?: ProductEcommerceLabel[];
    ecommerceLabelsAsString?: string;

    settings?: ProductSettings;

    features?: ProductFeature[];
    includesFeatures: boolean;

    type?: ProductType;
}

export interface ProductAiContent {
    id: string;
    productId: string;
    contentGeneratedAsString: string; //JSON RESULT: ProductAiResponse[]
    createdAt: string;
    updatedAt: string;
}

export interface ProductAiResponse {
    id: string;
    createdAt: string;
    updatedAt: string;
    theme: string;
    prompt: string;
    content: string;
}

export interface ProductPartialUpdateData {
    id: string;
    isVariant: boolean;
    data: ProductPartialUpdateInput;
}
export interface ProductPartialUpdateInput {
    costWeightedAverage?: number;
    costBaseWeightedAverage?: number;
    costAverage?: number;
    costBaseAverage?: number;
    costLastPurchase?: number;
    costBaseLastPurchase?: number;
    costUpdatedAt?: Date | string;
    basePrice?: number;
    ecommerceStatus?: ProductEcommerceStatusType;
    hideChildsInEcommerce?: boolean;
    images?: WahioFile[];
    name?: string;
    price?: number;
    price2?: number;
    price3?: number;
    price4?: number;
    price5?: number;
    price6?: number;
    price7?: number;
    price8?: number;
    price9?: number;
    price10?: number;
    description?: string;
    reference?: string;
    referenceCodes?: string;
    measurement?: string;
    itemWeight?: number;
    itemWidth?: number;
    itemLength?: number;
    itemHeight?: number;
}

export interface ProductPartialUpdateBulkItem {
    productId: string;
    productVariantId?: string;
    body: ProductPartialUpdateInput;
}

export interface ProductListingSearchInput extends IPaginationRequest {
    accountId: string;
    searchValue?: string;
    includeVariants?: boolean;
    filterCostUpdatedAt?: boolean;
}

export interface ProductListingItem {
    productId: string;
    productVariantId: string;
    name: string;
    variantValues: string;
    image: string;
}

export interface ProductCostSearchInput extends DateFilter {
    productId: string;
    productVariantId?: string;
    accountId: string;
}

export interface ProductCostMultipleSearchInput extends DateFilter {
    productIds: string[];
    productVariantIds?: string[];
    accountId: string;
}

export interface ProductFeature {
    id: string;
    createdAt?: Date | string;
    name: string;
    value: string;
    productId: string;
}

export interface ProductSettings {
    discountRules?: ProductDiscountRule[];
}

export class ProductSettingsImpl implements ProductSettings {
    discountRules?: ProductDiscountRule[] = [];
}

export interface ProductSearchEngineInput {
    productId: string;
    slug: string;
    title: string;
    description: string;
}

export interface ProductDiscountRule {
    id: string;
    minimumQuantity: number;
    maximumQuantity: number;
    discountPercentage: number;
    createdAt: string; //Date
}

export class ProductDiscountRileImpl implements ProductDiscountRule {
    id: string;
    minimumQuantity: number;
    maximumQuantity: number;
    discountPercentage: number;
    createdAt: string;
    constructor() {
        this.id = getUniqueId();
        this.minimumQuantity = 0;
        this.maximumQuantity = 0;
        this.discountPercentage = 0;
        this.createdAt = new Date().toISOString();
    }
}

export class ProductImpl implements IProduct {
    id: string = "";
    accountId: string = "";
    name: string = "";
    description: string = "";
    categoryId?: string;
    category: IProductCategory = new ProductCategory("");
    customCategoryId?: string;
    customCategory: IProductCategory = new ProductCategory("");
    basePrice: number = 0;
    controlInventory: boolean = true;
    profilePicture: string = "";
    code: string = "";
    codeType: number = 0;
    sku: string = "";
    globalStock: number = 0;
    dateCreated: Date = new Date();
    dateUpdated: Date = new Date();
    status: string = "";
    reference: string = "";
    referenceCodes: string = "";
    fastShipping: boolean = false;
    price: number = 0;
    price2: number = 0;
    price3: number = 0;
    price4: number = 0;
    price5: number = 0;
    price6: number = 0;
    price7: number = 0;
    price8: number = 0;
    price9: number = 0;
    price10: number = 0;
    isParent: boolean = false;
    hasTaxes: boolean = false;
    availableOnline: boolean = false;
    childs: IProductChild[] = [];
    hideChildsInEcommerce: boolean = true;
    images?: WahioFile[] = [];
    files?: WahioFile[] = [];
    taxes: IProductTax[] = [];
    productCategories: IProductSubCategory[] = [];
    measurement?: string = "";

    slug: string = "";
    searchEngineTitle?: string = "";
    searchEngineDescription?: string = "";

    characteristics: string = "";
    reviewRating: number = 0;
    reviewCount: number = 0;
    itemWidth: number = 0;
    itemLength: number = 0;
    itemHeight: number = 0;
    itemWeight: number = 0;

    purchaseCount: number = 0;
    purchaseQuantity: number = 0;
    costWeightedAverage: number = 0;
    costBaseWeightedAverage: number = 0;
    costAverage: number = 0;
    costBaseAverage: number = 0;
    costLastPurchase: number = 0;
    costBaseLastPurchase: number = 0;
    lastPurchaseDate?: Date;

    totalSaleTotalPriceSum: number = 0;
    totalSalePriceSum: number = 0;
    totalSaleCount: number = 0;
    totalSaleStockSum: number = 0;
    saleWeightedAverage: number = 0;
    saleAverageCost: number = 0;
    lastSalePrice: number = 0;
    lastSaleDate?: Date;

    includesVariants: boolean = false;
    includesSerials: boolean = false;

    includesOptionals: boolean = false;
    optionals?: ProductOptional[] = [];

    variantOptions?: VariantOption[] | undefined;
    variants: IProductVariant[] = [];
    variantOptionsLine: string = "";

    ecommerceStatus: ProductEcommerceStatusType = "visible";
    ecommerceLabels?: ProductEcommerceLabel[] = [];

    features?: ProductFeature[];
    includesFeatures: boolean = false;

    type?: ProductType = "product";

    constructor(accountId: string) {
        this.accountId = accountId;
        this.reference = v4().substring(0, 4).toUpperCase();
    }
}

export interface IProductVariant {
    id: string;
    productId: string;
    code: string;
    sku: string;
    image: string;
    basePrice: number;
    price: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    price6: number;
    price7: number;
    price8: number;
    price9: number;
    price10: number;
    dateCreated?: Date;
    valuesLine: string;
    variantsLine: string;
    warehouseProducts: IWarehouseProduct[];

    reference: string;
    itemWidth: number;
    itemLength: number;
    itemHeight: number;
    itemWeight: number;

    //COSTS
    purchaseCount: number;
    purchaseQuantity: number;
    costWeightedAverage: number;
    costBaseWeightedAverage: number;
    costAverage: number;
    costBaseAverage: number;
    costLastPurchase: number;
    costBaseLastPurchase: number;
    lastPurchaseDate?: Date;

    costUpdatedAt?: Date;

    options: VariantOptionValue[];

    images?: WahioFile[];
    files?: WahioFile[];

    fileTagsAsString?: string;
    fileTags?: string[];
}

export class ProductVariantImp implements IProductVariant {
    id: string = "";
    productId: string = "";
    code: string = "";
    sku: string = "";
    image: string = "";
    price: number = 1;
    basePrice: number = 0;
    price2: number = 0;
    price3: number = 0;
    price4: number = 0;
    price5: number = 0;
    price6: number = 0;
    price7: number = 0;
    price8: number = 0;
    price9: number = 0;
    price10: number = 0;
    dateCreated?: Date | undefined;
    options: VariantOptionValue[] = [];
    valuesLine: string = "";
    variantsLine: string = "";
    reference: string = "";
    itemWidth: number = 0;
    itemLength: number = 0;
    itemHeight: number = 0;
    itemWeight: number = 0;
    files?: WahioFile[];
    warehouseProducts: IWarehouseProduct[] = [];

    purchaseCount: number = 0;
    purchaseQuantity: number = 0;
    costWeightedAverage: number = 0;
    costBaseWeightedAverage: number = 0;
    costAverage: number = 0;
    costBaseAverage: number = 0;
    costLastPurchase: number = 0;
    costBaseLastPurchase: number = 0;
    lastPurchaseDate?: Date;

    constructor(options: VariantOptionValue[], product: IProduct) {
        this.options = options;
        this.basePrice = product.basePrice;
        this.price = product.price;
        this.price2 = product.price2;
        this.price3 = product.price3;
        this.price4 = product.price4;
        this.price5 = product.price5;
        this.price6 = product.price6;
        this.price7 = product.price7;
        this.price8 = product.price8;
        this.price9 = product.price9;
        this.price10 = product.price10;
    }
}

export interface VariantOption {
    key: string;
    name: string;
    index: number;
    dateCreated?: Date;
    values: string[];
}

export class VariantOptionImpl implements VariantOption {
    name: string;
    index: number;
    dateCreated?: Date | undefined;
    values: string[];
    key: string;

    constructor() {
        this.name = "";
        this.index = 0;
        this.values = [];
        this.key = getUniqueId();
    }
}

export interface VariantOptionValue {
    key: string;
    variantName: string;
    value: string;
    index: number;
}

export class VariantOptionValueImpl implements VariantOptionValue {
    variantName: string = "";
    key: string = "";
    value: string = "";
    index: number = 0;

    constructor(value: string, variantName: string) {
        this.value = value;
        this.variantName = variantName;
        this.key = getUniqueId();
    }
}

export interface IProductChild {
    id?: string;
    parentId?: string;
    product?: IProduct;
    productVariantId?: string;
    productVariant?: IProductVariant;
    childId: string;
    child?: IProduct;
    quantity: number;
}

export interface IProductSubCategory {
    id?: string;
    productId: string;
    customCategoryId: string;
    customCategory: IProductCategory;
    dateCreated?: Date;
}

export class ProductSubCategory implements IProductSubCategory {
    id?: string;
    productId: string;
    customCategoryId: string;
    customCategory: IProductCategory;
    dateCreated?: Date | undefined;

    constructor(productId: string, customCategory: IProductCategory) {
        this.productId = productId;
        this.customCategoryId = customCategory.id ?? "";
        this.customCategory = customCategory;
    }
}

export interface IProductOrderImport {
    productId?: string;
    codeOrSku: string;
    product?: IProduct;
    quantity: number;
    price: number;
    message: string;
}

export interface IProductState {
    pagination: IPaginationResponse<IProduct>;
    error: any;
    isFetching: boolean;
    isError: boolean;
}

export interface IProductCreationState {
    product: IProduct;
}

//DEFAULT MEANS SORT BY SALES
export type ProductSortType = "default" | "price" | "date" | "stock" | "name" | "category" | "updated";

export const PRODUCT_SORT_TYPES: ProductSortType[] = ["default", "price", "date", "stock", "name", "category", "updated"];

export interface ProductSortPair {
    sortDesc: boolean;
    sort: ProductSortType;
}
export interface IProductSearchModel extends IPaginationRequest, ProductSortPair {
    accountId: string;
    brandId?: string;
    manufacturerId?: string;
    categoryId?: string;
    customCategoryId?: string;
    searchValue?: string;
    status?: string;
    onlyInventoryProducts?: boolean;
    onlyProductWithImage?: boolean;
    onlyProductWithoutImage?: boolean;
    minPrice?: number;
    maxPrice?: number;
    productIdsExcluded?: string[];
}

export interface IWarehouseProductSummary {
    warehouse: IWarehouse;
    warehouseProduct: IWarehouseProduct;
    warehouseProductLocations: IWarehouseProductLocation[];
    warehouseLocationTotalStock: number;
}

export interface WarehouseProductSummaryInput {
    productId: string;
    productVariantId?: string;
    warehouseId: string;
}

export interface ProductCheckItem {
    productId: string;
    warehouseId: string;
    productVariantId?: string;
}

export interface ProductCheckItemResponse {
    stockAvailable: number;
    stockReserved: number;
    lastUpdated: Date;
    productId: string;
    warehouseId: string;
    productVariantId?: string | null;
}

export interface ProductCostResponse {
    basePrice?: number;
    costWeightedAverage: number;
    costBaseWeightedAverage: number;
    costAverage: number;
    costBaseAverage: number;
    costLastPurchase: number;
    costBaseLastPurchase: number;
    productId: string;
    productVariantId?: string;
    preventUpdate?: boolean;
}

export interface ProductReservedSearchInput {
    productId: string;
    warehouseId: string;
    productVariantId?: string;
}

export interface DeliveryStatusCountByProductInput {
    productId: string;
    productVariantId?: string;
    warehouseId: string;
    shipmentStatusList: string[];
}
export interface ProductCountResponse {
    count: number;
}

export interface IProductSort extends ProductSortPair {
    name: string;
}

export const productSortOptionsMultiple: IProductSort[] = [
    {
        name: "Más vendido",
        sortDesc: true,
        sort: "default",
    },
    {
        name: "Menos vendido",
        sortDesc: false,
        sort: "default",
    },
    {
        name: "Precio menor a mayor",
        sortDesc: false,
        sort: "price",
    },
    {
        name: "Precio mayor a menor",
        sortDesc: true,
        sort: "price",
    },
    {
        name: "Mayor Stock",
        sortDesc: true,
        sort: "stock",
    },
    {
        name: "Menor Stock",
        sortDesc: false,
        sort: "stock",
    },
    {
        name: "Nombre Descendente",
        sortDesc: true,
        sort: "name",
    },
    {
        name: "Nombre Ascendente",
        sortDesc: false,
        sort: "name",
    },
    {
        name: "Categoria Descendente",
        sortDesc: true,
        sort: "category",
    },
    {
        name: "Categoria Ascendente",
        sortDesc: false,
        sort: "category",
    },
];

export interface ProductSerialSearchInput {
    accountId: string;
    serial: string;
}

export interface SerialResponseBase {
    serials: string[];
    warehouseName: string;
    productName: string;
    productImage: string;
    productCode: string;
    productSku: string;
    productId: string;
}

export interface AdjustmentSerialResponse extends SerialResponseBase {
    adjustmentId: string;
    adjustmentStatus: string;
    accountUserId: string;
    adjustmentNumber: string;
    adjustmentDetailId: string;
    dateStart: string;
    dateEnd: string;
}

export interface WarehouseProductSerialResponse extends SerialResponseBase {
    warehouseProductId: string;
    stock: number;
    reserved: number;
}

export interface ProductSerialSearchResponse {
    warehouseProducts: WarehouseProductSerialResponse[];
    inventoryAdjustments: AdjustmentSerialResponse[];
}

export interface OrderSerialSearchResponse extends SerialResponseBase {
    orderId: string;
    orderNumber: number;
    contactName: string;
    contactId: string;
    accountUserId: string;
    itemId: string;
    itemPosition: number;
    createdAt: string;
}

export interface ProductOptionalsConfig {
    optionals: ProductOptional[];
    minSelectionCount: number;
    currentSelectionCount: number;
    isCompleted: boolean;
    totalExtraPrice: number;
    description?: string;
}

export interface ProductOptional {
    id: string;
    number: number;
    name: string;
    minSelection: number;
    maxSelection: number;
    items: ProductOptionalItem[];
    selectedCount?: number;
    isCompleted?: boolean;
    extraPrice: number;
}

export interface ProductOptionalItem {
    id: string;
    position: number;
    image?: string;
    name: string;
    price: number;
    relatedProductId?: string;
    relatedProductQuantity?: number;
    maxSelection: number;
    selectedQuantity?: number;
    selected?: boolean;
}
