import React, { useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { ProductLog, ProductLogOrigin } from "../../../api/models/productLog";
import { getColorValue } from "../../../constants/colors";
import PATHS from "../../../constants/paths";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { getDateFormat, getDateFromNow, getModelFullName } from "../../../utils";
import { Flex, FlexImageStyle, SquareButton, TextField } from "../../_controls";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import messages from "../messages";
import { ButtonLocation, OriginCard, ProductLogCard, QuantityCard } from "./styled";

interface ProductLogRowProps {
    log: ProductLog;
    isMobileView: boolean;
}

const getOriginPathLink = (origin: ProductLogOrigin, documentId: string) => {
    if (origin === "order") {
        return PATHS.orderPosProfile(documentId);
    } else if (origin === "purchase") return PATHS.productPurchasesListProfileId(documentId);
    else if (origin === "adjustment") return PATHS.productInventoryAdjustmentEditId(documentId);
    else if (origin === "movement") return PATHS.productWarehousesMovementProfile(documentId);
    else {
        return PATHS.productLogs;
    }
};

export default function ProductLogRow(props: ProductLogRowProps) {
    if (props.isMobileView) {
        return <LogCard {...props} />;
    } else {
        return <LogRow {...props} />;
    }
}

const LogRow = (props: ProductLogRowProps) => {
    const intl = useIntl();
    const { log } = props;

    const image = log.productVariantImage ?? log.productImage;
    const sku = log.productVariantSku ?? log.productSku;

    const { getUserById } = useAccountUserContext();

    const [showModalInfo, setShowModalInfo] = useState(false);

    const user = getUserById(log.accountUserId);

    return (
        <>
            {showModalInfo && (
                <Modal show={showModalInfo} setShow={setShowModalInfo} showHeader title="Movimiento de producto">
                    <ProductLogInfo log={log} />
                </Modal>
            )}
            <tr>
                <td className="min-width">
                    <FlexImageStyle className="ml-1" fitCover width={30} borderRadius={15}>
                        <img src={image} alt={log.productName} />
                    </FlexImageStyle>
                </td>
                <td>
                    <Flex column>
                        <TextField title={log.productName}>
                            {log.productName} {log.productVariantValuesLine}
                        </TextField>
                        <TextField light small>
                            {sku}
                        </TextField>
                    </Flex>
                </td>

                <td>
                    <OriginCard className={`origin-${log.origin}`}>
                        {messages[log.origin] ? intl.formatMessage(messages[log.origin]) : log.origin}
                    </OriginCard>
                </td>
                <td>
                    <QuantityCard className={`${log.quantity < 0 ? "negative" : ""}`}>{log.quantity}</QuantityCard>
                </td>

                <td>{log.warehouseName}</td>

                <td>
                    <TextField small light>
                        {getDateFormat(log.createdAt, "DD MMM YYYY hh:mm:a")}
                    </TextField>
                </td>

                <td>
                    {user && (
                        <FlexImageLetter
                            width={30}
                            image={user.image}
                            tooltipTitle={getModelFullName(user)}
                            text={user.firstName ?? "NA"}
                        />
                    )}
                </td>
                <td>
                    <SquareButton onClick={() => setShowModalInfo(true)}>
                        <span className="fa-regular fa-eye"></span>
                    </SquareButton>
                </td>
            </tr>
        </>
    );
};

const LogCard = (props: ProductLogRowProps) => {
    const intl = useIntl();
    const { log } = props;

    const image = log.productVariantImage ?? log.productImage;
    const sku = log.productVariantSku ?? log.productSku;

    const { getUserById } = useAccountUserContext();

    const [showModalInfo, setShowModalInfo] = useState(false);

    const user = getUserById(log.accountUserId);

    return (
        <>
            {showModalInfo && (
                <Modal show={showModalInfo} setShow={setShowModalInfo} showHeader title="Movimiento de producto">
                    <ProductLogInfo log={log} />
                </Modal>
            )}
            <ProductLogCard onClick={() => setShowModalInfo(true)}>
                <Flex spaceBetween gap10 alignStart>
                    <Flex gap10 alignStart>
                        <FlexImageStyle fitCover width={30} borderRadius={15}>
                            <img src={image} alt={log.productName} />
                        </FlexImageStyle>
                        <Flex column>
                            <TextField title={log.productName}>{log.productName} {log.productVariantValuesLine}</TextField>
                            <TextField light small>
                                {sku}
                            </TextField>
                            <TextField light>{getDateFormat(log.createdAt, "DD MMM YYYY hh:mm:a")}</TextField>
                        </Flex>
                    </Flex>
                    <Flex gap10 alignCenter>
                        {user && (
                            <FlexImageLetter
                                width={30}
                                image={user.image}
                                tooltipTitle={getModelFullName(user)}
                                text={user.firstName ?? "NA"}
                            />
                        )}
                        <OriginCard className={`origin-${log.origin}`}>
                            {messages[log.origin] ? intl.formatMessage(messages[log.origin]) : log.origin}
                        </OriginCard>
                        <QuantityCard className={`${log.quantity < 0 ? "negative" : ""}`}>{log.quantity}</QuantityCard>
                    </Flex>
                </Flex>
            </ProductLogCard>
        </>
    );
};

interface ProductLogInfoProps {
    log: ProductLog;
}
const ProductLogInfo = (props: ProductLogInfoProps) => {
    const { log } = props;

    const image = log.productVariantImage ?? log.productImage;
    const sku = log.productVariantSku ?? log.productSku;

    const intl = useIntl();

    return (
        <Flex padding={20} column>
            <Flex gap10 w100>
                <FlexImageStyle borderRadius={15}>
                    <img src={image} alt={log.productName} />
                </FlexImageStyle>
                <Flex spaceBetween w100>
                    <Flex column>
                        <TextField>{log.productName}</TextField>
                        <TextField light small>
                            {sku}
                        </TextField>
                        <TextField light small>
                            {getDateFromNow(log.createdAt)}
                        </TextField>
                    </Flex>
                    <QuantityCard className={`${log.quantity < 0 ? "negative" : ""}`}>{log.quantity}</QuantityCard>
                </Flex>
            </Flex>

            <Flex marginTop={20}>
                <Table cellPadding={0} cellSpacing={0} tdPadding={8} tdPaddingRight={20}>
                    <tbody>
                        <tr>
                            <td>
                                <TextField light>Origen</TextField>
                            </td>
                            <td>
                                <Flex alignStart>
                                    <OriginCard className={`origin-${log.origin}`}>
                                        {messages[log.origin] ? intl.formatMessage(messages[log.origin]) : log.origin}
                                    </OriginCard>
                                </Flex>
                            </td>
                        </tr>
                        <tr>
                            <td className="min-width">
                                <TextField light>Fecha</TextField>
                            </td>
                            <td>
                                <TextField>{getDateFormat(log.createdAt, "DD MMM YYYY hh:mm:a")}</TextField>
                            </td>
                        </tr>
                        {log.accountUser && (
                            <tr>
                                <td>
                                    <TextField light>Responsable</TextField>
                                </td>
                                <td>{getModelFullName(log.accountUser)}</td>
                            </tr>
                        )}

                        <tr>
                            <td>
                                <TextField light>Bodega</TextField>
                            </td>
                            <td>
                                <Flex gap10 alignCenter>
                                    <span>{log.warehouseName}</span>
                                </Flex>
                            </td>
                        </tr>

                        {log.locationCode && (
                            <tr>
                                <td>
                                    <TextField light>Locación</TextField>
                                </td>
                                <td>
                                    <ButtonLocation rounded color={getColorValue(log.locationColor)}>
                                        {log.locationCode}
                                    </ButtonLocation>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <TextField light>Documento</TextField>
                            </td>
                            <td>
                                {log.documentId ? (
                                    <NavLink className="document-link" to={getOriginPathLink(log.origin, log.documentId)}>
                                        <span className="wahioicon-external-link-alt mr-1"></span> {log.documentNumber}
                                    </NavLink>
                                ) : (
                                    <div className="document-link">{log.documentNumber}</div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Flex>
        </Flex>
    );
};
