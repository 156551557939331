import styled, { DefaultTheme } from "styled-components";

export interface DefaultInputProps {
    theme: DefaultTheme;
    rounded?: boolean;
    borderRadius?: number;
    w100?: boolean;
    customColor?: string;
    bold?: boolean;
    background?: boolean;
    small?: boolean;
    alignEnd?: boolean;
    border?: boolean;
}

export const DefaultInput = styled.input`
    padding: ${(props: DefaultInputProps) => (props.small ? "5px 5px" : "6px 14px")};
    border: ${(props: DefaultInputProps) => (props.border ? `1px solid ${props.theme.palette.backgroundDark}` : "1px solid transparent")};
    outline-style: none;
    color: ${(props: DefaultInputProps) => (props.customColor ? props.customColor : props.theme.palette.text)};
    background: ${(props: DefaultInputProps) => (props.background ? props.theme.palette.background : props.theme.palette.backgroundLight)};
    font-size: 14px;
    box-sizing: border-box;

    border-radius: 10px;

    ${(props: DefaultInputProps) => props.borderRadius && `border-radius: ${props.borderRadius}px;`}
    ${(props: DefaultInputProps) => props.rounded && `border-radius: 50px`};

    text-align: ${(props: DefaultInputProps) => (props.alignEnd ? "end" : "start")};
    ${(props: DefaultInputProps) => (props.w100 ? `width: 100%;` : "")};
    ${(props: DefaultInputProps) => (props.bold ? `font-weight: 500;` : "")};

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: url("https://stowahioimagelibrary.blob.core.windows.net/common/dateicon.png");
        background-size: contain;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-clear-button {
        display: none;
    }

    &:focus {
        border-color: ${(props) => props.theme.palette.backgroundDark};
    }

    :disabled {
        color: ${(props) => props.theme.palette.textLight};
    }
    &.size-md {
        background: ${(props) => props.theme.palette.background};
        border-color: ${(props) => props.theme.palette.backgroundDark};
        font-weight: 500;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
`;

export const DefaultTextarea = styled.textarea`
    padding: 7px 14px;
    border: 1px solid transparent;
    outline-style: none;
    color: ${(props) => props.theme.palette.text};
    background: ${(props) => props.theme.palette.backgroundLight};
    font-size: 14px;
    box-sizing: border-box;
    border-radius: ${(props: { borderRadius?: number }) => (props.borderRadius ? `${props.borderRadius}px` : "5px")};
    resize: vertical;

    &:focus {
        border-color: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const DefaultTextAreaCode = styled(DefaultTextarea)`
    font-family: "Monaco", "Consolas", "Inconsolata", monospace;
`;

export const DefaultInputDate = styled(DefaultInput)`
    padding: 5px 14px;
    font-weight: 500;

    &::-webkit-calendar-picker-indicator {
        background: url("https://stowahioimagelibrary.blob.core.windows.net/common/dateicon.png");
        background-size: contain;
    }
`;
export const DefaultInputTime = styled(DefaultInput)`
    padding: 5px 14px;
    font-weight: 500;

    &::-webkit-calendar-picker-indicator {
        background: url("https://stowahioimagelibrary.blob.core.windows.net/common/timeicon.png");
        background-size: contain;
    }
`;

export const InputSearchCustom = styled.input`
    padding: 7px 14px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    outline-style: none;
    color: ${(props) => props.theme.palette.text};
    background: ${(props) => props.theme.palette.background};
    font-size: 14px;
    &:focus {
        box-shadow: 0px 0px 4px #2196f369;
        border-color: ${(props) => props.theme.palette.primary};
    }
`;
