import React, { useContext, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { ContactLabel } from "../../../api/models/contact";
import { deleteContactLabel, createUpdateContactLabel } from "../../../api/orders/contactApi";
import { ContactContext } from "../../../store/contexts/ContactContext";
import { getDateFromNow } from "../../../utils";
import ColorDropdown from "../../Colors/ColorDropdown";
import LoadingDualRing from "../../LoadingDualRing";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { SquareButton } from "../../_controls";
import InputTimer from "../../_controls/inputs/InputTimer";
import messages from "./messages";
import { LabelRow } from "./styled";

interface ContactLabelProps {
    label: ContactLabel;
}

interface ContactLabelRowState {
    showRemoveConfirmation: boolean;
}

export default function ContactLabelRow(props: ContactLabelProps) {
    const intl = useIntl();
    const { label } = props;
    const { contactState, contactActions } = useContext(ContactContext);

    const [state, setState] = useState<ContactLabelRowState>({
        showRemoveConfirmation: false,
    });

    const alert = useAlert();

    const updateMutation = useMutation((data: any) => createUpdateContactLabel(data), {
        onSuccess: (label) => {
            alert.success(intl.formatMessage(messages.labelList));
            contactActions.updateLabel(label);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const removeMutation = useMutation((labelId: string) => deleteContactLabel(labelId), {
        onSuccess: (data) => {
            alert.success(intl.formatMessage(messages.labelList));
            let items = contactState.labels.filter((item) => item.id !== data.id);
            contactActions.receiveLabels(items);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const onUpdateLabel = (label: ContactLabel) => {
        updateMutation.mutate(label);
    };

    const onHandleRemove = () => {
        if (label.id) removeMutation.mutate(label.id);
    };

    const isLoading = updateMutation.isLoading || removeMutation.isLoading;

    return (
        <LabelRow>
            <td>
                {state.showRemoveConfirmation && (
                    <ConfirmationModal
                        show={state.showRemoveConfirmation}
                        setShow={() => setState({ ...state, showRemoveConfirmation: false })}
                        title={intl.formatMessage(messages.labelList)}
                        description={intl.formatMessage(messages.labelList)}
                        onCancel={() => setState({ ...state, showRemoveConfirmation: false })}
                        onOkay={() => onHandleRemove()}
                    />
                )}
                <InputTimer
                    className="input-name"
                    initialValue={label.name}
                    placeholder={intl.formatMessage(messages.labelList)}
                    onChange={(e) => onUpdateLabel({ ...label, name: e.target.value })}
                    milliseconds={1000}
                />
            </td>
            <td>
                <ColorDropdown
                    hideRemove={true}
                    color={label.color}
                    onRemove={() => {}}
                    onSelected={(color) => onUpdateLabel({ ...label, color })}
                />
            </td>
            <td>
                <span>{getDateFromNow(label.dateCreated)}</span>
            </td>
            <td>
                {isLoading ? (
                    <LoadingDualRing />
                ) : (
                    <SquareButton onClick={() => setState({ ...state, showRemoveConfirmation: true })}>
                        <span className="wahioicon-trash"></span>
                    </SquareButton>
                )}
            </td>
        </LabelRow>
    );
}
