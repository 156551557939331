import React, { createContext, useContext, useEffect, useState } from "react";
import { LocalStoreKeys, saveData } from "..";
import { ProductSearchFilters } from "../../api/models/productSearch";

export interface WarehouseProductState {
    productSearchFilters?: ProductSearchFilters;
}

interface IContextProps {
    warehouseProductState: WarehouseProductState;
    setWarehouseProductState: (value: WarehouseProductState) => void;
}

const WarehouseProductContext = createContext({} as IContextProps);

const WarehouseProductContextProvider = (props: any) => {
    const [warehouseProductState, setWarehouseProductState] = useState<WarehouseProductState>({});

    useEffect(() => {
        saveData(LocalStoreKeys.warehouseProduct, warehouseProductState);
    }, [warehouseProductState]);

    return (
        <WarehouseProductContext.Provider value={{ warehouseProductState, setWarehouseProductState }}>
            {props.children}
        </WarehouseProductContext.Provider>
    );
};

export const useWarehouseProductContext = () => {
    return useContext(WarehouseProductContext);
};

export default WarehouseProductContextProvider;
