import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { IProduct } from "../../../api/models/product";
import { createUpdateProduct } from "../../../api/products/productsApi";
import ProductFeaturesForm from "../ProductCreationForm/ProductFeaturesForm";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationFeatures(props: ProductNavigationProfileProps) {
    const intl = useIntl();
    const [features, setFeatures] = useState(props.product.features ?? []);
    const [hasChanges, setHasChanges] = useState(false);

    const updateMutation = useMutation((data: IProduct) => createUpdateProduct(data), {
        onSuccess: (data) => {
            setHasChanges(false);
            props.onUpdateProduct(data);
        },
    });

    useEffect(() => {
        setFeatures(props.product.features ?? []);
    }, [props.product]);

    return (
        <NavigationCard
            {...props}
            title={intl.formatMessage(messages.features)}
            actionsComponent={
                <NavigationActions
                    isLoading={updateMutation.isLoading}
                    disabled={!hasChanges}
                    onSave={() => {
                        updateMutation.mutate({ ...props.product, features: features });
                    }}
                />
            }
        >
            <ProductFeaturesForm
                productId={props.product.id ?? ""}
                features={features}
                onChange={(value) => {
                    setFeatures(value);
                    setHasChanges(true);
                }}
            />
        </NavigationCard>
    );
}
