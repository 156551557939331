import React from "react";
import { ErrorLabel, FormField, FormGroupTextField, FormLabel, ParentContainer } from "./styled";
import { useField } from "formik";

export default function InputTextField(props: any) {
    const { name } = props;
    const initId = props.id ? props.id : name;

    const [field, meta] = useField(props);
    const isError = meta.touched && meta.error;

    const setFocusInput = () => {
        var element = document.getElementById(props.id);
        if (element) element.focus();
    };
    if (!name) throw new Error("Name is required");
    return (
        <ParentContainer>
            <FormGroupTextField>
                <FormField error={isError} {...field} {...props} id={initId} />
                <FormLabel onClick={setFocusInput} htmlFor={props.name} error={isError} className="form__label">
                    {props.placeholder}
                </FormLabel>
            </FormGroupTextField>
            {isError ? <ErrorLabel>{meta.error}</ErrorLabel> : null}
        </ParentContainer>
    );
}

export function InputTextFieldSimple(props: any) {
    const { name } = props;
    const initId = props.id ? props.id : name;

    const setFocusInput = () => {
        var element = document.getElementById(props.id);
        if (element) element.focus();
    };
    if (!name) throw new Error("Name is required");

    const { error } = props;

    return (
        <ParentContainer className={props.className ?? ""}>
            <FormGroupTextField>
                <FormField error={error} {...props} id={initId} />
                <FormLabel onClick={setFocusInput} htmlFor={props.name} error={error} className="form__label">
                    {props.placeholder}
                </FormLabel>
            </FormGroupTextField>
        </ParentContainer>
    );
}
