import React from "react";
import { Order } from "../../../../api/models/order";
import { OrderNotificationInput } from "../../../../api/models/orderNotification";
import { getModelFullName } from "../../../../utils";
import { CountryPhoneSelector } from "../../../GeoLocation/CountryPhoneSelector";
import { DefaultButton, DefaultInput, Flex, SquareButton, TextField } from "../../../_controls";
import { NotificationBody, NotificationCardContainer } from "./styled";
import TooltipRadix from "../../../_controls/WahioTooltip/TooltipRadix";

export const DEFAULT_NOTIFICATION: OrderNotificationInput = {
    orderId: "",
    isNewOrder: false,
    accountUserId: "",
    customerId: "none",
    options: ["email", "sms"],
    contactName: "",
    contactShortName: "",
    phonePrefix: "57",
    phoneNumber: "",
    email: "",
};

export const getDefaultNotification = (order: Order) => {
    let showNotificationCard = order.showNotificationCard ?? false;
    let sendNotification = order.sendNotification ?? { ...DEFAULT_NOTIFICATION };

    if (order.customer && order.customer.id !== sendNotification.customerId) {
        sendNotification.contactName = getModelFullName(order.customer);
        sendNotification.contactShortName = order.customer.firstName;
        sendNotification.customerId = order.customer.id;
        showNotificationCard = true;
        if (order.customer.emails && order.customer.emails.length > 0) {
            sendNotification.email = order.customer.emails[0].email;
        } else {
            sendNotification.email = order.customer.email;
        }
        if (order.customer.phones && order.customer.phones.length > 0) {
            const phone = order.customer.phones[0];
            sendNotification.phoneNumber = phone.phoneNumber;
            sendNotification.phonePrefix = phone.phonePrefix ?? "57";
        } else {
            sendNotification.phoneNumber = order.customer.phoneNumber;
            sendNotification.phonePrefix = order.customer.phonePrefix ?? "57";
        }
    } else if (!order.customer && sendNotification?.customerId) {
        sendNotification = { ...DEFAULT_NOTIFICATION };
        showNotificationCard = false;
    }

    return { sendNotification, showNotificationCard };
};

interface NotificationOrderCardProps {
    order: Order;
    onOrderChange: (order: Order) => void;
}

export default function NotificationOrderCard(props: NotificationOrderCardProps) {
    const { order } = props;

    const sendNotification = order.sendNotification;

    const onSendNotificationChange = (value: OrderNotificationInput) => {
        props.onOrderChange({ ...order, sendNotification: value });
    };

    const onClickShowNotification = () => {
        let newShowNotification = !order.showNotificationCard;

        let sendNotification = order.sendNotification ? order.sendNotification : DEFAULT_NOTIFICATION;
        if (newShowNotification === false) {
            sendNotification = DEFAULT_NOTIFICATION;
        }
        props.onOrderChange({ ...order, sendNotification, showNotificationCard: newShowNotification });
    };

    return (
        <NotificationCardContainer className={order.showNotificationCard ? "notification-active" : ""}>
            <Flex alignCenter gap5 cursorPointer onClick={() => onClickShowNotification()}>
                <SquareButton small className={`btn-notification`}>
                    {order.showNotificationCard ? (
                        <span className={`fa-regular fa-bell`}></span>
                    ) : (
                        <span className={`fa-regular fa-bell-slash`}></span>
                    )}
                </SquareButton>
                <TextField small bold>
                    Notificaciones
                </TextField>
            </Flex>

            {order.showNotificationCard && sendNotification && (
                <NotificationCard
                    item={sendNotification}
                    onChange={(item) => {
                        onSendNotificationChange({ ...sendNotification, ...item });
                    }}
                />
            )}
        </NotificationCardContainer>
    );
}

export interface NotificationItem {
    contactName: string;
    phonePrefix: string;
    phoneNumber: string;
    email: string;
    isDisabled?: boolean;
}

interface NotificationCardProps {
    showContactName?: boolean;
    showDisabledButton?: boolean;
    item: NotificationItem;

    onChange: (value: NotificationItem) => void;
}

export const NotificationCard = (props: NotificationCardProps) => {
    const { item } = props;
    const onInputChange = (e: any) => {
        props.onChange({ ...props.item, [e.target.name]: e.target.value });
    };

    return (
        <NotificationBody>
            {props.showDisabledButton && (
                <TooltipRadix
                    delay={0}
                    title={item.isDisabled ? "Activar notificaciones" : "Desactivar notificaciones"}
                    trigger={
                        <DefaultButton
                            small
                            rounded
                            hideBorder
                            onClick={() => {
                                props.onChange({ ...props.item, isDisabled: !item.isDisabled });
                            }}
                        >
                            {!item.isDisabled ? <i className="fa-regular fa-bell"></i> : <i className="fa-regular fa-bell-slash"></i>}
                            Notificaciones
                        </DefaultButton>
                    }
                />
            )}
            {!item.isDisabled && (
                <>
                    {props.showContactName && (
                        <div className="notification-item">
                            <i className="fa-regular fa-user notification-icon"></i>
                            <DefaultInput
                                onChange={onInputChange}
                                value={item.contactName}
                                name="contactName"
                                type="name"
                                autoComplete="none"
                                placeholder="Nombre"
                                className="notification-input"
                            />
                        </div>
                    )}
                    <div className="notification-item">
                        <i className="fa-regular fa-envelope notification-icon" />
                        <DefaultInput
                            onChange={onInputChange}
                            value={item.email}
                            name="email"
                            type="email"
                            autoComplete="none"
                            placeholder="email@example.com"
                            className="notification-input"
                        />
                    </div>
                    <div className="notification-item">
                        <i className="fa-regular fa-phone notification-icon" />
                        <div className="phone-group">
                            <CountryPhoneSelector
                                hideTitle
                                defaultValue={item.phonePrefix}
                                onChangeState={(value) => {
                                    props.onChange({ ...item, phonePrefix: value?.dial_code ?? "57" });
                                }}
                            />
                            <DefaultInput
                                onChange={onInputChange}
                                value={item.phoneNumber}
                                name="phoneNumber"
                                type="phone"
                                autoComplete="none"
                                placeholder="Teléfono"
                                className="notification-input"
                            />
                        </div>
                    </div>
                </>
            )}
        </NotificationBody>
    );
};
