import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { OrderItemCardProps, QuantityCard } from ".";
import { getUserPermissions } from "../../../api/models/accountUser";
import { OrderItem, OrderItemPreparationStatusList } from "../../../api/models/order";
import { ITaxOrderItem, convertTaxOrderItem } from "../../../api/models/orderBase";
import { ITax } from "../../../api/models/tax";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { formatMoney, getModelFullName } from "../../../utils";
import { calculateItemBasePrice, extractTaxInformation, getItemTotalTax } from "../../../utils/taxes";
import TextareaChat from "../../Chats/ChatContainer/TextareaChat";
import Dropdown from "../../Dropdown";
import ProductOptionalsSelector from "../../Products/ProductOptionals/ProductOptionalsSelector";
import { ProductProfileModal } from "../../Products/ProductProfileNavigation";
import TaxOrderItemModal from "../../Products/Taxes/TaxOrderItem/TaxOrderItemModal";
import { getProductSku, getProductThumbnailUrl } from "../../Products/helper";
import PreparationStatusIcon from "../../Restaurants/PreparationStatusIcon";
import restaurantMessages from "../../Restaurants/messages";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, FlexImageStyle, PrimaryButton, SquareButton, TextField } from "../../_controls";
import PopoverRadix from "../../_controls/PopoverRadix";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import { Table } from "../../_controls/tables/styled";
import Modal, { IModalShow } from "../../modals/Modal";
import { onOrderItemPriceChange } from "../CreateOrderForm/helpers";
import messages from "../messages";
import { OrderItemCardModalContainer } from "./styled";

interface OrderItemModalProps extends IModalShow, OrderItemCardProps {}

export default function OrderItemModal(props: OrderItemModalProps) {
    const [orderItem, setOrderItem] = useState(props.orderItem);
    const [showTaxesModal, setShowTaxesModal] = useState(false);
    const { userState } = useContext(UserContext);
    const { product } = orderItem;

    const [panelIsCollapse, setPanelIsCollapse] = useState(true);

    const [showProductProfile, setShowProductProfile] = useState(false);

    const intl = useIntl();
    const { getUserById } = useAccountUserContext();

    const user = getUserById(props.orderItem.accountUserId);

    const onPriceChange = (newValue: number) => {
        onOrderItemPriceChange(orderItem, newValue, (result) => {
            calculateChangesValues(result);
        });
    };

    const onClickRemoveTax = (tax: ITaxOrderItem) => {
        let item = { ...orderItem };
        let taxes = item.taxes.filter((x) => x.taxId !== tax.taxId);
        item.taxes = taxes;
        calculateChangesValues(item);
    };

    const calculateChangesValues = (item: OrderItem) => {
        let [taxPercentage] = extractTaxInformation(item.taxes);
        let basePriceResult = calculateItemBasePrice(item, item.taxes, true, false);
        item.basePrice = basePriceResult.basePrice;
        item.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;

        let extraPrice = 0;
        if (item.optionalsConfig && item.optionalsConfig.totalExtraPrice > 0) {
            extraPrice = item.optionalsConfig.totalExtraPrice;
        }

        const taxAmount = getItemTotalTax(item.taxes, item, true, false);
        const subTotal = (item.basePrice + extraPrice) * item.quantity;
        const manualPercentageDiscountValue = (item.manualPercentageDiscount * item.unitPrice) / 100;
        const total = (item.basePrice + extraPrice + taxAmount - manualPercentageDiscountValue) * item.quantity;

        setOrderItem({
            ...item,
            basePrice: basePriceResult.basePrice,
            taxes: item.taxes,
            totalTax: taxAmount,
            totalTaxPercentage: taxPercentage,
            subTotal,
            manualPercentageDiscount: item.manualPercentageDiscount,
            manualPercentageDiscountValue,
            total,
        });
    };

    const onClickTax = (tax: ITax) => {
        let item = { ...orderItem };
        let existingTax = item.taxes.filter((x) => x.taxId === tax.id);

        if (existingTax.length === 0) {
            let taxes = [...item.taxes, convertTaxOrderItem(tax, item.basePrice, false)];
            item.taxes = taxes;
            calculateChangesValues(item);
        }
    };

    const updateQuantity = (value: OrderItem) => {
        calculateChangesValues(value);
    };

    const onChangeDiscount = (value: number) => {
        let item = { ...orderItem };
        item.manualPercentageDiscount = value;
        calculateChangesValues(item);
    };

    let containerClass = "";
    if (orderItem.product?.includesOptionals) {
        containerClass = "with-optionals";
    }

    let isDisabled = false;
    if (orderItem.optionalsConfig && !orderItem.optionalsConfig.isCompleted) {
        isDisabled = true;
    }

    const productSku = getProductSku(product, orderItem.productVariant);
    const permission = getUserPermissions(userState.user).order;

    const PermissionDenied = (permission: string) => {
        return (
            <PopoverRadix
                trigger={
                    <SquareButton small removeBackground>
                        <TextField light className="fa-regular fa-ban" />
                    </SquareButton>
                }
            >
                <TextField small>{`No tienes permisos para modificar ${intl.formatMessage((messages as any)[permission])}`}</TextField>
            </PopoverRadix>
        );
    };

    return (
        <Modal
            {...props}
            title={product?.name}
            sizeType={orderItem.product?.includesOptionals ? "md2" : "sm"}
            useButtonClose
            ignoreOutsideClick
        >
            <OrderItemCardModalContainer className={containerClass}>
                {showProductProfile && (
                    <ProductProfileModal
                        onUpdateProduct={(product) => {
                            let newItem = { ...orderItem, product: product };
                            setOrderItem(newItem);
                            props.onUpdateItem(newItem);
                        }}
                        onWarehouseProductChange={(wp) => {
                            if (orderItem.warehouseId === wp.warehouseId && orderItem.productId === wp.productId) {
                                let newItem = {
                                    ...orderItem,
                                    warehouseProduct: wp,
                                    stockTemp: wp.stock - wp.reserved,
                                    availableStock: wp.stock - wp.reserved,
                                };
                                setOrderItem(newItem);
                                props.onUpdateItem(newItem);
                            }
                        }}
                        product={product}
                        show={showProductProfile}
                        setShow={setShowProductProfile}
                    />
                )}
                {showTaxesModal && (
                    <TaxOrderItemModal
                        show={showTaxesModal}
                        setShow={setShowTaxesModal}
                        basePrice={orderItem.basePrice}
                        orderTaxes={orderItem.taxes}
                        taxesAvailable={props.availableTaxes}
                        taxAmount={orderItem.totalTax}
                        totalPrice={orderItem.unitPrice}
                        onClickTax={onClickTax}
                        onClickRemoveTax={onClickRemoveTax}
                    />
                )}

                {orderItem.optionalsConfig && orderItem.product?.includesOptionals && (
                    <div className="panel-optionals">
                        <Flex className="optionals-collapse" spaceBetween onClick={() => setPanelIsCollapse(!panelIsCollapse)}>
                            <TextField>{intl.formatMessage(messages.optionals)}</TextField>
                            <SquareButton small>
                                <i className="fa-regular fa-chevron-down"></i>
                            </SquareButton>
                        </Flex>
                        <div className={`optionals-collapse-panel ${panelIsCollapse ? "hide" : ""}`}>
                            <ProductOptionalsSelector
                                optionalsConfig={orderItem.optionalsConfig}
                                onChange={(config) => calculateChangesValues({ ...orderItem, optionalsConfig: config })}
                            />
                        </div>
                    </div>
                )}

                <Flex column gap={30} w100>
                    <Flex
                        gap10
                        alignCenter
                        className="panel-product-card"
                        title="Ver perfil del producto"
                        onClick={() => setShowProductProfile(!showProductProfile)}
                    >
                        <FlexImageStyle borderRadius={15} width={40} fitCover>
                            <img src={getProductThumbnailUrl(orderItem.product, orderItem.productVariant)} alt="" />
                        </FlexImageStyle>
                        <Flex column>
                            <TextField bold>
                                {product?.name} {productSku ? ` • ${productSku}` : ""}
                            </TextField>

                            {product?.controlInventory && (
                                <TextField light small>{`${intl.formatMessage(messages.availableStock)} ${
                                    orderItem.stockTemp
                                } `}</TextField>
                            )}
                        </Flex>
                    </Flex>
                    {orderItem.optionalsConfig && (
                        <Flex column gap5>
                            {orderItem.optionalsConfig && orderItem.optionalsConfig.totalExtraPrice > 0 && (
                                <Flex gap5>
                                    <TextField>{intl.formatMessage(messages.additionalOptionals)}:</TextField>
                                    <TextField bold>
                                        {formatMoney(orderItem.optionalsConfig.totalExtraPrice)} x {orderItem.quantity} ={" "}
                                        {formatMoney(orderItem.optionalsConfig.totalExtraPrice * orderItem.quantity)}
                                    </TextField>
                                </Flex>
                            )}
                            {orderItem.optionalsConfig?.description && (
                                <TextField small light>
                                    {orderItem.optionalsConfig.description}
                                </TextField>
                            )}
                        </Flex>
                    )}

                    <div className="modal-grid">
                        <Flex column w100 gap5>
                            <Flex alignCenter gap5>
                                <TextField small light>
                                    {intl.formatMessage(messages.unitPrice)}
                                </TextField>
                                {!permission?.orderFormAllowEditPrice && PermissionDenied("prices")}
                            </Flex>
                            <TextBoxNumeral
                                w100
                                className="textbox-order-item input-rounded input-price"
                                format="money"
                                value={orderItem.unitPrice}
                                onNumberChange={(value) => onPriceChange(value)}
                                disabled={!permission?.orderFormAllowEditPrice}
                            />
                        </Flex>

                        <Flex column w100 gap5>
                            <Flex alignCenter gap5>
                                <TextField small light>
                                    {intl.formatMessage(messages.discount)}
                                </TextField>
                                {!permission?.orderFormAllowEditDiscount && PermissionDenied("discounts")}
                            </Flex>
                            <Flex gap5 alignCenter w100>
                                <TextBoxNumeral
                                    w100
                                    className="textbox-order-item input-rounded min"
                                    format="number"
                                    value={orderItem.manualPercentageDiscount}
                                    onNumberChange={onChangeDiscount}
                                    maxLength={3}
                                    disabled={!permission?.orderFormAllowEditDiscount}
                                />
                                <i className="fa-regular fa-percent"></i>
                            </Flex>
                        </Flex>

                        <Flex column w100 gap5>
                            <Flex alignCenter gap5>
                                <TextField small light>
                                    {intl.formatMessage(messages.tax)}
                                </TextField>
                                {!permission?.orderFormAllowEditTax && PermissionDenied("taxes")}
                            </Flex>
                            <DefaultButton
                                disabled={!permission?.orderFormAllowEditTax}
                                w100
                                rounded
                                onClick={() => setShowTaxesModal(true)}
                            >
                                <span className="tax-percentage">{orderItem.totalTaxPercentage}%</span>
                            </DefaultButton>
                        </Flex>
                    </div>

                    {user && (
                        <Flex column gap5>
                            <TextField small light>
                                Vendedor
                            </TextField>
                            <Flex gap10 alignCenter>
                                <FlexImageLetter image={user.image} tooltipTitle={getModelFullName(user)} text={user.firstName ?? "NA"} />
                                <TextField>{getModelFullName(user)}</TextField>
                            </Flex>
                        </Flex>
                    )}
                    {orderItem.preparationStatus && (
                        <Flex column gap5>
                            <TextField small light>
                                Estado de preparación:
                            </TextField>
                            <Dropdown
                                customIcon={<PreparationStatusIcon status={orderItem.preparationStatus} />}
                                small
                                title={intl.formatMessage(restaurantMessages[orderItem.preparationStatus])}
                            >
                                <Flex padding={20} gap5 column>
                                    {OrderItemPreparationStatusList.map((item) => {
                                        return (
                                            <DefaultButton
                                                onClick={() => setOrderItem({ ...props.orderItem, preparationStatus: item })}
                                                className="dropdown-button"
                                            >
                                                <PreparationStatusIcon status={item} /> {intl.formatMessage(restaurantMessages[item])}
                                            </DefaultButton>
                                        );
                                    })}
                                </Flex>
                            </Dropdown>
                        </Flex>
                    )}

                    <Flex column gap5>
                        <TextField small light>
                            Observación
                        </TextField>

                        <TextareaChat
                            removePadding
                            withBorder
                            hideSendButton
                            placeholder="Agregar observación"
                            initialValue={orderItem.comment}
                            onInputChange={(value) => setOrderItem({ ...orderItem, comment: value })}
                            onSendMessage={() => {}}
                        />
                    </Flex>

                    <QuantityCard origin={props.origin} onUpdateItem={updateQuantity} orderItem={orderItem} bigView />

                    <Table cellPadding={0} cellSpacing={0} tdPadding={5} hideBorde tdPaddingRight={20} alignRight>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField light>Sub total</TextField>
                                </td>
                                <td className="min-width">{formatMoney(orderItem.subTotal)}</td>
                            </tr>

                            {orderItem.manualPercentageDiscount > 0 && (
                                <tr>
                                    <td>
                                        <TextField light>Descuento</TextField>
                                    </td>
                                    <td>{formatMoney(orderItem.manualPercentageDiscountValue * orderItem.quantity)}</td>
                                </tr>
                            )}
                            {orderItem.totalTax > 0 && (
                                <tr>
                                    <td>
                                        <TextField light>Impuestos</TextField>
                                    </td>
                                    <td>{formatMoney(orderItem.totalTax * orderItem.quantity)}</td>
                                </tr>
                            )}
                            <tr className="border-top-dashed">
                                <td>
                                    <TextField bold fontSize={16}>
                                        Total
                                    </TextField>
                                </td>
                                <td>
                                    <TextField bold fontSize={16}>
                                        {formatMoney(orderItem.total)}
                                    </TextField>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    {orderItem.optionalsConfig && !orderItem.optionalsConfig.isCompleted && (
                        <TextField small light>
                            Debe seleccionar los opcionales para continuar
                        </TextField>
                    )}
                    <Flex gap10 justifyEnd alignCenter w100 style={{ marginTop: "auto" }}>
                        <DefaultButton borderRadius={10} bgLight w100 onClick={() => props.setShow(false)}>
                            Cancelar
                        </DefaultButton>
                        <PrimaryButton
                            borderRadius={10}
                            w100
                            disabled={isDisabled}
                            onClick={() => {
                                props.setShow(false);
                                props.onUpdateItem(orderItem);
                            }}
                        >
                            Guardar
                        </PrimaryButton>
                    </Flex>
                </Flex>
            </OrderItemCardModalContainer>
        </Modal>
    );
}
