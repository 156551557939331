import styled from "styled-components";
import { device } from "../../../theme/themeProvider";
import { SelectBodyBase } from "../../Select/styled";
import { Flex } from "../../_controls";
import { TableCustom } from "../../_controls/tables/styled";

export const MainContainer = styled.div`
    position: relative;
`;

export const CloseContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 9px;
    z-index: 1;
`;

export const OrderCreatedPreviewStyle = styled.div`
    padding: 15px;
    background: ${(props) => props.theme.palette.primary}30;

    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin: 15px;
    border-radius: 15px;
    button {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        :hover {
            background: ${(props) => props.theme.palette.primaryDark};
        }
    }
`;

export const SubContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    .display-block {
        display: block;
    }
    .display-none {
        display: none;
    }
`;
interface IMobileProps {
    hideMaxWidth: boolean;
}
export interface IHideProps extends IMobileProps {
    hide: boolean;
}
export const ProductLeftContainer = styled.div`
    width: 100%;
    border-top-left-radius: 10px;
    overflow: hidden;
    min-width: 310px;

    ${(props: IHideProps) => (props.hide ? "display: none;" : "")}
    ${(props: IHideProps) => (props.hideMaxWidth ? "" : "max-width: 440px;")}
`;

export const OrderContainerParent = styled.div`
    box-sizing: border-box;
    width: 100%;
    scroll-behavior: smooth;
`;

export const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${(props: IHideProps) => (props.hide ? "display: none;" : "")}
    width: 100%;
    border-radius: 0px;
    @media ${device.md} {
        border-radius: 8px;
    }
    position: relative;

    box-sizing: border-box;
    height: auto;
    width: 100%;
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const FlexContainerCenter = styled.div`
    display: flex;
    align-items: center;
`;

export const TopViewBasePositionNormal = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    .margin,
    .dropdown {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    button {
        border-radius: 50px;
    }
    padding-left: 15px;
    padding-top: 15px;
`;

export const TopOrderView = styled(Flex)`
    position: sticky;
    top: 0px;
    background: ${(props) => props.theme.palette.background};
    z-index: 3;
    padding: 15px;

    .dropdown-btn {
        background: ${(props) => props.theme.palette.backgroundDark};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark}40;
        }
    }
`;

export const OrderInfoView = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;

    .info-line {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const TopLabelContainer = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    grid-gap: 10px;
    flex-wrap: wrap;
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 0.8rem;
    .label-title {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    height: 100%;
    border-right: 1px solid ${(props) => props.theme.palette.backgroundLight};
    border-left: 1px solid ${(props) => props.theme.palette.backgroundLight};

    &.disabled-edit {
        opacity: 0.8;
        :after {
            content: " ";
            position: absolute;
            background: ${(props) => props.theme.palette.backgroundLight}40;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: unset;
        }
    }
`;

export const SummaryContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ContainerLoading = styled.div`
    margin: 3rem;
    text-align: center;
`;

export const DetailsContainer = styled.div`
    ${(props: IHideProps) => (props.hide ? "display: none;" : "display: flex;")}

    gap: 10px 15px;
    flex-wrap: wrap;
    flex-direction: row;
`;

export const DetailCard = styled(SelectBodyBase)`
    display: flex;
    flex-direction: column;

    .label {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 5px;
    }

    .dropdown-btn {
        background: ${(props) => props.theme.palette.backgroundLight};
        border-color: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    .width-limit {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .textbox-numeral {
        padding: 6px;
        border-radius: 20px;
    }
    .card-input {
        border-radius: 20px;
        border: 1px solid ${(props) => props.theme.palette.textLight}60;
    }
    .small-text {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    .card-btn {
        background: transparent;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        &:hover {
            background: ${(props) => props.theme.palette.background};
        }
    }

    .tax-withholding-container {
        margin-top: 10px;
        input,
        .textbox-numeral {
            border-radius: 38px;
            padding: 7px;
            border: 1px solid ${(props) => props.theme.palette.textLight}60;
        }
        .textbox-numeral {
            width: 57px;
            text-align: center;
            margin-right: 5px;
        }
    }
`;

export const DetailsBody = styled.div`
    padding: 7px 14px;
    display: flex;
    flex-direction: column;
    margin: 6px 0px;

    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 3px 14px;
    padding-bottom: 16px;
    max-width: fit-content;
    @media ${device.md} {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 8px;
        padding: 7px 14px;
        margin: 6px;
    }

    justify-content: center;
    p {
        margin: 0px;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        margin-right: 10px;
    }
    .content {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    align-items: center;
    .content-col {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        overflow: hidden;
        padding-right: 10px;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }

    .small {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }
`;

export const DeliveryOptionContainer = styled(RowContainer)`
    grid-gap: 20px;
    flex-wrap: wrap;
    .separator {
        display: none;
        width: 1px;
        height: 25px;
        background: ${(props) => props.theme.palette.backgroundDark};
    }
    flex-direction: column;
    align-items: flex-start;
    @media ${device.md} {
        .separator {
            display: block;
        }
        align-items: center;
        flex-direction: row;
    }
`;

export const CostContainer = styled.div`
    input {
        border-radius: 40px;
        padding: 2px 9px;
        margin-top: 2px;
        font-weight: 500;
    }
`;

export const EmptyItemsMessage = styled.div`
    padding-left: 2rem;
    padding-bottom: 14px;
    color: ${(props) => props.theme.palette.textLight};
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const DraftContainer = styled.div`
    padding: 14px;
    .number {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const DraftSubContainer = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    border: 0px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 14px;
    border-radius: 7px;
    display: flex;
    align-items: center;
`;

export const TableCustomOrder = styled(TableCustom)``;

export const OrderHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 27px;
    height: 45px;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    overflow: auto;
    .btn-back {
        background: ${(props) => props.theme.palette.background};
    }
    .header-title {
        font-size: 17px;
        font-weight: 500;
        white-space: nowrap;
    }
    .btn-new {
        border-radius: 50px;
        padding: 3px 14px;
        color: ${(props) => props.theme.palette.textLight};
        background: ${(props) => props.theme.palette.backgroundDark};
        font-size: 13px;
        font-weight: 500;
    }
`;

export const LogisticsCard = styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    border-radius: 18px;
    padding: 3px 11px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    img {
        width: 20px;
        margin-right: 10px;
    }
`;

export const OrderStatusStyle = styled.div`
    width: fit-content;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 30px;
    display: flex;
    align-items: center;

    background: ${(props) => props.theme.palette.backgroundLight};
    color: ${(props) => props.theme.palette.textLight};
    border-color: transparent;

    &.size-sm {
        font-size: 12px;
        padding: 2px 7px;
    }

    &.size-md {
        font-size: 14px;
        padding: 5px 15px;
    }

    &.square {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.size-sm {
            font-size: 12px;
            width: 22px;
            min-width: 22px;
            height: 22px;
        }

        &.size-md {
            font-size: 13px;
            width: 25px;
            min-width: 25px;
            height: 25px;
        }
    }

    &.void {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
    }
    &.partial {
        background: #ff99002d;
        color: #ff9800 !important;
    }

    &.paid {
        background: #2962ff2c;
        color: #2962ff;
    }
    &.credit {
        background: #f443362d;
        color: #f44336;
    }
    &.quotation {
        background: #7c4dff20;
        color: #7c4dff;
    }
    &.draft {
        color: #607d8b;
        background: #607d8b20;
    }
    &.pending {
        background: #f443362d;
        color: #f44336;
    }
    &.style-green {
        background: #00968838;
        color: #009688;
    }
`;

export const OrderStatusLineStyle = styled(OrderStatusStyle)`
    padding: 3px 10px;
    font-weight: 500;
    font-size: 13px;
    border-radius: 10px;
`;

export const OrderStyles = styled.div``;
