export const ECOMMERCE_ICONS_FLAT = [
    {
        name: "001-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/001-discount.png",
    },
    {
        name: "002-percent.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/002-percent.png",
    },
    {
        name: "003-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/003-label.png",
    },
    {
        name: "004-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/004-coupon.png",
    },
    {
        name: "005-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/005-coupon.png",
    },
    {
        name: "006-price label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/006-price label.png",
    },
    {
        name: "007-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/007-coupon.png",
    },
    {
        name: "008-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/008-sticker.png",
    },
    {
        name: "009-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/009-label.png",
    },
    {
        name: "010-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/010-flag.png",
    },
    {
        name: "011-honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/011-honor.png",
    },
    {
        name: "012-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/012-badge.png",
    },
    {
        name: "013-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/013-badge.png",
    },
    {
        name: "014-shield.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/014-shield.png",
    },
    {
        name: "015-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/015-signboard.png",
    },
    {
        name: "016-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/016-reward.png",
    },
    {
        name: "017-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/017-medal of honor.png",
    },
    {
        name: "018-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/018-military.png",
    },
    {
        name: "019-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/019-insignia.png",
    },
    {
        name: "020-defence.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/020-defence.png",
    },
    {
        name: "021-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/021-police.png",
    },
    {
        name: "022-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/022-flag.png",
    },
    {
        name: "023-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/023-airforce.png",
    },
    {
        name: "024-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/024-cop.png",
    },
    {
        name: "025-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/025-army.png",
    },
    {
        name: "026-cross mark.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/026-cross mark.png",
    },
    {
        name: "027-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/027-insignia.png",
    },
    {
        name: "028-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/028-army.png",
    },
    {
        name: "029-crossed.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/029-crossed.png",
    },
    {
        name: "030-navy.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/030-navy.png",
    },
    {
        name: "031-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/031-badge.png",
    },
    {
        name: "032-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/032-insignia.png",
    },
    {
        name: "033-power.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/033-power.png",
    },
    {
        name: "034-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/034-police.png",
    },
    {
        name: "035-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/035-defense.png",
    },
    {
        name: "036-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/036-announcement.png",
    },
    {
        name: "037-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/037-star.png",
    },
    {
        name: "038-armor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/038-armor.png",
    },
    {
        name: "039-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/039-medal of honor.png",
    },
    {
        name: "040-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/040-insignia.png",
    },
    {
        name: "041-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/041-medal.png",
    },
    {
        name: "042-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/042-airforce.png",
    },
    {
        name: "043-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/043-flag.png",
    },
    {
        name: "044-badges.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/044-badges.png",
    },
    {
        name: "045-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/045-cop.png",
    },
    {
        name: "046-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/046-police.png",
    },
    {
        name: "047-star of david.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/047-star of david.png",
    },
    {
        name: "048-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/048-police.png",
    },
    {
        name: "049-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/049-announcement.png",
    },
    {
        name: "050-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/050-reward.png",
    },
    {
        name: "051-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/051-badge.png",
    },
    {
        name: "052-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/052-medal of honor.png",
    },
    {
        name: "053-stars.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/053-stars.png",
    },
    {
        name: "054-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/054-insignia.png",
    },
    {
        name: "055-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/055-reward.png",
    },
    {
        name: "056-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/056-flag.png",
    },
    {
        name: "057-shaped.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/057-shaped.png",
    },
    {
        name: "058-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/058-cop.png",
    },
    {
        name: "059-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/059-police.png",
    },
    {
        name: "060-ranger.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/060-ranger.png",
    },
    {
        name: "061-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/061-defense.png",
    },
    {
        name: "062-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/062-announcement.png",
    },
    {
        name: "063-flower.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/063-flower.png",
    },
    {
        name: "064-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/064-insignia.png",
    },
    {
        name: "065-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/065-badge.png",
    },
    {
        name: "066-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/066-army.png",
    },
    {
        name: "067-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/067-medal of honor.png",
    },
    {
        name: "068-navy.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/068-navy.png",
    },
    {
        name: "069-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/069-signboard.png",
    },
    {
        name: "070-shapes design.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/070-shapes design.png",
    },
    {
        name: "071-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/071-cop.png",
    },
    {
        name: "072-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/072-police.png",
    },
    {
        name: "073-free.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/073-free.png",
    },
    {
        name: "074-hot.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/074-hot.png",
    },
    {
        name: "075-promotional.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/075-promotional.png",
    },
    {
        name: "076-price tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/076-price tag.png",
    },
    {
        name: "077-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/077-signboard.png",
    },
    {
        name: "078-announcemnt.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/078-announcemnt.png",
    },
    {
        name: "079-favorite.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/079-favorite.png",
    },
    {
        name: "080-heart.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/080-heart.png",
    },
    {
        name: "081-double arrow.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/081-double arrow.png",
    },
    {
        name: "082-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/082-airforce.png",
    },
    {
        name: "083-ranking.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/083-ranking.png",
    },
    {
        name: "084-special force.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/084-special force.png",
    },
    {
        name: "085-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/085-military.png",
    },
    {
        name: "086-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/086-star.png",
    },
    {
        name: "087-lieutenant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/087-lieutenant.png",
    },
    {
        name: "088-stripes.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/088-stripes.png",
    },
    {
        name: "089-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/089-sticker.png",
    },
    {
        name: "090-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/090-label.png",
    },
    {
        name: "091-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/091-star.png",
    },
    {
        name: "092-uniform.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/092-uniform.png",
    },
    {
        name: "093-starred.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/093-starred.png",
    },
    {
        name: "094-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/094-cop.png",
    },
    {
        name: "095-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/095-defense.png",
    },
    {
        name: "096-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/096-defense.png",
    },
    {
        name: "097-starred.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/097-starred.png",
    },
    {
        name: "098-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/098-police.png",
    },
    {
        name: "099-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/099-defense.png",
    },
    {
        name: "100-ceremony.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/100-ceremony.png",
    },
    {
        name: "101-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/101-medal of honor.png",
    },
    {
        name: "102-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/102-medal.png",
    },
    {
        name: "103-recognition.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/103-recognition.png",
    },
    {
        name: "104-honorable.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/104-honorable.png",
    },
    {
        name: "105-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/105-sticker.png",
    },
    {
        name: "106-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/106-cop.png",
    },
    {
        name: "107-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/107-police.png",
    },
    {
        name: "108-shape.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/108-shape.png",
    },
    {
        name: "109-badges.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/109-badges.png",
    },
    {
        name: "110-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/110-medal.png",
    },
    {
        name: "111-honorable.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/111-honorable.png",
    },
    {
        name: "112-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/112-medal of honor.png",
    },
    {
        name: "113-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/113-insignia.png",
    },
    {
        name: "114-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/114-medal of honor.png",
    },
    {
        name: "115-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/115-military.png",
    },
    {
        name: "116-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/116-reward.png",
    },
    {
        name: "117-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/117-flag.png",
    },
    {
        name: "118-star of david.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/118-star of david.png",
    },
    {
        name: "119-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/119-label.png",
    },
    {
        name: "120-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/120-medal of honor.png",
    },
    {
        name: "121-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/121-cop.png",
    },
    {
        name: "122-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/122-police.png",
    },
    {
        name: "123-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/123-airforce.png",
    },
    {
        name: "124-bowtie.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/124-bowtie.png",
    },
    {
        name: "125-marine.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/125-marine.png",
    },
    {
        name: "126-officer.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/126-officer.png",
    },
    {
        name: "127-important.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-flat/127-important.png",
    },
];

export const ECOMMERCE_ICONS_TRITONE = [
    {
        name: "001-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/001-discount.png",
    },
    {
        name: "002-percent.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/002-percent.png",
    },
    {
        name: "003-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/003-label.png",
    },
    {
        name: "004-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/004-coupon.png",
    },
    {
        name: "005-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/005-coupon.png",
    },
    {
        name: "006-price label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/006-price label.png",
    },
    {
        name: "007-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/007-coupon.png",
    },
    {
        name: "008-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/008-sticker.png",
    },
    {
        name: "009-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/009-label.png",
    },
    {
        name: "010-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/010-flag.png",
    },
    {
        name: "011-honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/011-honor.png",
    },
    {
        name: "012-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/012-badge.png",
    },
    {
        name: "013-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/013-badge.png",
    },
    {
        name: "014-shield.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/014-shield.png",
    },
    {
        name: "015-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/015-signboard.png",
    },
    {
        name: "016-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/016-reward.png",
    },
    {
        name: "017-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/017-medal of honor.png",
    },
    {
        name: "018-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/018-military.png",
    },
    {
        name: "019-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/019-insignia.png",
    },
    {
        name: "020-defence.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/020-defence.png",
    },
    {
        name: "021-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/021-police.png",
    },
    {
        name: "022-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/022-flag.png",
    },
    {
        name: "023-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/023-airforce.png",
    },
    {
        name: "024-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/024-cop.png",
    },
    {
        name: "025-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/025-army.png",
    },
    {
        name: "026-cross mark.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/026-cross mark.png",
    },
    {
        name: "027-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/027-insignia.png",
    },
    {
        name: "028-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/028-army.png",
    },
    {
        name: "029-crossed.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/029-crossed.png",
    },
    {
        name: "030-navy.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/030-navy.png",
    },
    {
        name: "031-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/031-badge.png",
    },
    {
        name: "032-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/032-insignia.png",
    },
    {
        name: "033-power.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/033-power.png",
    },
    {
        name: "034-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/034-police.png",
    },
    {
        name: "035-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/035-defense.png",
    },
    {
        name: "036-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/036-announcement.png",
    },
    {
        name: "037-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/037-star.png",
    },
    {
        name: "038-armor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/038-armor.png",
    },
    {
        name: "039-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/039-medal of honor.png",
    },
    {
        name: "040-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/040-insignia.png",
    },
    {
        name: "041-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/041-medal.png",
    },
    {
        name: "042-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/042-airforce.png",
    },
    {
        name: "043-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/043-flag.png",
    },
    {
        name: "044-badges.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/044-badges.png",
    },
    {
        name: "045-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/045-cop.png",
    },
    {
        name: "046-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/046-police.png",
    },
    {
        name: "047-star of david.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/047-star of david.png",
    },
    {
        name: "048-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/048-police.png",
    },
    {
        name: "049-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/049-announcement.png",
    },
    {
        name: "050-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/050-reward.png",
    },
    {
        name: "051-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/051-badge.png",
    },
    {
        name: "052-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/052-medal of honor.png",
    },
    {
        name: "053-stars.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/053-stars.png",
    },
    {
        name: "054-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/054-insignia.png",
    },
    {
        name: "055-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/055-reward.png",
    },
    {
        name: "056-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/056-flag.png",
    },
    {
        name: "057-shaped.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/057-shaped.png",
    },
    {
        name: "058-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/058-cop.png",
    },
    {
        name: "059-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/059-police.png",
    },
    {
        name: "060-ranger.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/060-ranger.png",
    },
    {
        name: "061-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/061-defense.png",
    },
    {
        name: "062-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/062-announcement.png",
    },
    {
        name: "063-flower.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/063-flower.png",
    },
    {
        name: "064-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/064-insignia.png",
    },
    {
        name: "065-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/065-badge.png",
    },
    {
        name: "066-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/066-army.png",
    },
    {
        name: "067-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/067-medal of honor.png",
    },
    {
        name: "068-navy.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/068-navy.png",
    },
    {
        name: "069-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/069-signboard.png",
    },
    {
        name: "070-shapes design.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/070-shapes design.png",
    },
    {
        name: "071-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/071-cop.png",
    },
    {
        name: "072-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/072-police.png",
    },
    {
        name: "073-free.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/073-free.png",
    },
    {
        name: "074-hot.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/074-hot.png",
    },
    {
        name: "075-promotional.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/075-promotional.png",
    },
    {
        name: "076-price tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/076-price tag.png",
    },
    {
        name: "077-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/077-signboard.png",
    },
    {
        name: "078-announcemnt.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/078-announcemnt.png",
    },
    {
        name: "079-favorite.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/079-favorite.png",
    },
    {
        name: "080-heart.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/080-heart.png",
    },
    {
        name: "081-double arrow.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/081-double arrow.png",
    },
    {
        name: "082-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/082-airforce.png",
    },
    {
        name: "083-ranking.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/083-ranking.png",
    },
    {
        name: "084-special force.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/084-special force.png",
    },
    {
        name: "085-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/085-military.png",
    },
    {
        name: "086-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/086-star.png",
    },
    {
        name: "087-lieutenant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/087-lieutenant.png",
    },
    {
        name: "088-stripes.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/088-stripes.png",
    },
    {
        name: "089-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/089-sticker.png",
    },
    {
        name: "090-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/090-label.png",
    },
    {
        name: "091-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/091-star.png",
    },
    {
        name: "092-uniform.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/092-uniform.png",
    },
    {
        name: "093-starred.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/093-starred.png",
    },
    {
        name: "094-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/094-cop.png",
    },
    {
        name: "095-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/095-defense.png",
    },
    {
        name: "096-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/096-defense.png",
    },
    {
        name: "097-starred.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/097-starred.png",
    },
    {
        name: "098-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/098-police.png",
    },
    {
        name: "099-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/099-defense.png",
    },
    {
        name: "100-ceremony.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/100-ceremony.png",
    },
    {
        name: "101-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/101-medal of honor.png",
    },
    {
        name: "102-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/102-medal.png",
    },
    {
        name: "103-recognition.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/103-recognition.png",
    },
    {
        name: "104-honorable.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/104-honorable.png",
    },
    {
        name: "105-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/105-sticker.png",
    },
    {
        name: "106-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/106-cop.png",
    },
    {
        name: "107-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/107-police.png",
    },
    {
        name: "108-shape.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/108-shape.png",
    },
    {
        name: "109-badges.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/109-badges.png",
    },
    {
        name: "110-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/110-medal.png",
    },
    {
        name: "111-honorable.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/111-honorable.png",
    },
    {
        name: "112-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/112-medal of honor.png",
    },
    {
        name: "113-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/113-insignia.png",
    },
    {
        name: "114-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/114-medal of honor.png",
    },
    {
        name: "115-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/115-military.png",
    },
    {
        name: "116-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/116-reward.png",
    },
    {
        name: "117-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/117-flag.png",
    },
    {
        name: "118-star of david.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/118-star of david.png",
    },
    {
        name: "119-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/119-label.png",
    },
    {
        name: "120-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/120-medal of honor.png",
    },
    {
        name: "121-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/121-cop.png",
    },
    {
        name: "122-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/122-police.png",
    },
    {
        name: "123-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/123-airforce.png",
    },
    {
        name: "124-bowtie.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/124-bowtie.png",
    },
    {
        name: "125-marine.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/125-marine.png",
    },
    {
        name: "126-officer.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/126-officer.png",
    },
    {
        name: "127-important.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-tritone/127-important.png",
    },
];

export const ECOMMERCE_ICONS_LINEAR_COLOR = [
    {
        name: "001-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/001-discount.png",
    },
    {
        name: "002-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/002-sticker.png",
    },
    {
        name: "003-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/003-label.png",
    },
    {
        name: "004-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/004-coupon.png",
    },
    {
        name: "005-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/005-coupon.png",
    },
    {
        name: "006-price label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/006-price label.png",
    },
    {
        name: "007-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/007-coupon.png",
    },
    {
        name: "008-sticker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/008-sticker.png",
    },
    {
        name: "009-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/009-label.png",
    },
    {
        name: "010-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/010-flag.png",
    },
    {
        name: "011-honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/011-honor.png",
    },
    {
        name: "012-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/012-badge.png",
    },
    {
        name: "013-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/013-badge.png",
    },
    {
        name: "014-shield.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/014-shield.png",
    },
    {
        name: "015-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/015-signboard.png",
    },
    {
        name: "016-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/016-reward.png",
    },
    {
        name: "017-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/017-medal of honor.png",
    },
    {
        name: "018-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/018-military.png",
    },
    {
        name: "019-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/019-insignia.png",
    },
    {
        name: "020-defence.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/020-defence.png",
    },
    {
        name: "021-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/021-police.png",
    },
    {
        name: "022-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/022-flag.png",
    },
    {
        name: "023-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/023-airforce.png",
    },
    {
        name: "024-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/024-cop.png",
    },
    {
        name: "025-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/025-army.png",
    },
    {
        name: "026-cross mark.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/026-cross mark.png",
    },
    {
        name: "027-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/027-insignia.png",
    },
    {
        name: "028-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/028-army.png",
    },
    {
        name: "029-crossed.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/029-crossed.png",
    },
    {
        name: "030-navy.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/030-navy.png",
    },
    {
        name: "031-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/031-badge.png",
    },
    {
        name: "032-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/032-insignia.png",
    },
    {
        name: "033-power.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/033-power.png",
    },
    {
        name: "034-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/034-police.png",
    },
    {
        name: "035-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/035-defense.png",
    },
    {
        name: "036-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/036-announcement.png",
    },
    {
        name: "037-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/037-star.png",
    },
    {
        name: "038-armor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/038-armor.png",
    },
    {
        name: "039-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/039-medal of honor.png",
    },
    {
        name: "040-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/040-insignia.png",
    },
    {
        name: "041-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/041-medal.png",
    },
    {
        name: "042-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/042-airforce.png",
    },
    {
        name: "043-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/043-flag.png",
    },
    {
        name: "044-badges.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/044-badges.png",
    },
    {
        name: "045-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/045-cop.png",
    },
    {
        name: "046-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/046-police.png",
    },
    {
        name: "047-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/047-star.png",
    },
    {
        name: "048-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/048-police.png",
    },
    {
        name: "049-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/049-announcement.png",
    },
    {
        name: "050-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/050-reward.png",
    },
    {
        name: "051-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/051-badge.png",
    },
    {
        name: "052-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/052-medal of honor.png",
    },
    {
        name: "053-stars.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/053-stars.png",
    },
    {
        name: "054-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/054-insignia.png",
    },
    {
        name: "055-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/055-reward.png",
    },
    {
        name: "056-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/056-flag.png",
    },
    {
        name: "057-shaped.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/057-shaped.png",
    },
    {
        name: "058-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/058-cop.png",
    },
    {
        name: "059-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/059-police.png",
    },
    {
        name: "060-ranger.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/060-ranger.png",
    },
    {
        name: "061-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/061-defense.png",
    },
    {
        name: "062-announcement.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/062-announcement.png",
    },
    {
        name: "063-flower.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/063-flower.png",
    },
    {
        name: "064-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/064-insignia.png",
    },
    {
        name: "065-badge.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/065-badge.png",
    },
    {
        name: "066-army.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/066-army.png",
    },
    {
        name: "067-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/067-medal of honor.png",
    },
    {
        name: "068-navy.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/068-navy.png",
    },
    {
        name: "069-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/069-signboard.png",
    },
    {
        name: "070-shapes design.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/070-shapes design.png",
    },
    {
        name: "071-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/071-cop.png",
    },
    {
        name: "072-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/072-police.png",
    },
    {
        name: "073-free.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/073-free.png",
    },
    {
        name: "074-hot.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/074-hot.png",
    },
    {
        name: "075-promotional.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/075-promotional.png",
    },
    {
        name: "076-price tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/076-price tag.png",
    },
    {
        name: "077-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/077-signboard.png",
    },
    {
        name: "078-announcemnt.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/078-announcemnt.png",
    },
    {
        name: "079-favorite.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/079-favorite.png",
    },
    {
        name: "080-heart.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/080-heart.png",
    },
    {
        name: "081-double arrow.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/081-double arrow.png",
    },
    {
        name: "082-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/082-airforce.png",
    },
    {
        name: "083-ranking.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/083-ranking.png",
    },
    {
        name: "084-special force.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/084-special force.png",
    },
    {
        name: "085-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/085-military.png",
    },
    {
        name: "086-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/086-star.png",
    },
    {
        name: "087-lieutenant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/087-lieutenant.png",
    },
    {
        name: "088-stripes.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/088-stripes.png",
    },
    {
        name: "089-new.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/089-new.png",
    },
    {
        name: "090-new.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/090-new.png",
    },
    {
        name: "091-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/091-star.png",
    },
    {
        name: "092-uniform.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/092-uniform.png",
    },
    {
        name: "093-starred.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/093-starred.png",
    },
    {
        name: "094-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/094-cop.png",
    },
    {
        name: "095-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/095-defense.png",
    },
    {
        name: "096-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/096-defense.png",
    },
    {
        name: "097-favorite.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/097-favorite.png",
    },
    {
        name: "098-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/098-police.png",
    },
    {
        name: "099-defense.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/099-defense.png",
    },
    {
        name: "100-ceremony.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/100-ceremony.png",
    },
    {
        name: "101-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/101-medal of honor.png",
    },
    {
        name: "102-royal museum of the armed forces.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/102-royal museum of the armed forces.png",
    },
    {
        name: "103-recognition.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/103-recognition.png",
    },
    {
        name: "104-honorable.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/104-honorable.png",
    },
    {
        name: "105-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/105-medal.png",
    },
    {
        name: "106-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/106-cop.png",
    },
    {
        name: "107-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/107-police.png",
    },
    {
        name: "108-shape.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/108-shape.png",
    },
    {
        name: "109-badges.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/109-badges.png",
    },
    {
        name: "110-signboard.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/110-signboard.png",
    },
    {
        name: "111-honorable.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/111-honorable.png",
    },
    {
        name: "112-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/112-medal of honor.png",
    },
    {
        name: "113-insignia.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/113-insignia.png",
    },
    {
        name: "114-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/114-medal of honor.png",
    },
    {
        name: "115-military.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/115-military.png",
    },
    {
        name: "116-reward.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/116-reward.png",
    },
    {
        name: "117-flag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/117-flag.png",
    },
    {
        name: "118-star of david.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/118-star of david.png",
    },
    {
        name: "119-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/119-label.png",
    },
    {
        name: "120-medal of honor.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/120-medal of honor.png",
    },
    {
        name: "121-cop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/121-cop.png",
    },
    {
        name: "122-police.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/122-police.png",
    },
    {
        name: "123-airforce.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/123-airforce.png",
    },
    {
        name: "124-bowtie.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/124-bowtie.png",
    },
    {
        name: "125-marine.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/125-marine.png",
    },
    {
        name: "126-officer.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/126-officer.png",
    },
    {
        name: "127-vip.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-linear-color/127-vip.png",
    },
];

export const ECOMMERCE_ICONS_V2_LINEAR_COLOR = [
    {
        name: "001-card payment.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/001-card payment.png",
    },
    {
        name: "002-delivery truck.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/002-delivery truck.png",
    },
    {
        name: "003-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/003-discount.png",
    },
    {
        name: "004-store.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/004-store.png",
    },
    {
        name: "005-delivery.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/005-delivery.png",
    },
    {
        name: "006-sneaker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/006-sneaker.png",
    },
    {
        name: "007-alarm.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/007-alarm.png",
    },
    {
        name: "008-screen.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/008-screen.png",
    },
    {
        name: "009-tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/009-tag.png",
    },
    {
        name: "010-money.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/010-money.png",
    },
    {
        name: "011-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/011-star.png",
    },
    {
        name: "012-web page.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/012-web page.png",
    },
    {
        name: "013-online shopping.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/013-online shopping.png",
    },
    {
        name: "014-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/014-medal.png",
    },
    {
        name: "015-purse.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/015-purse.png",
    },
    {
        name: "016-cashier.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/016-cashier.png",
    },
    {
        name: "017-sale.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/017-sale.png",
    },
    {
        name: "018-wishlist.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/018-wishlist.png",
    },
    {
        name: "019-package.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/019-package.png",
    },
    {
        name: "020-gamepad.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/020-gamepad.png",
    },
    {
        name: "021-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/021-discount.png",
    },
    {
        name: "022-ribbon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/022-ribbon.png",
    },
    {
        name: "023-gift wrapping.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/023-gift wrapping.png",
    },
    {
        name: "024-wristwatch.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/024-wristwatch.png",
    },
    {
        name: "025-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/025-discount.png",
    },
    {
        name: "026-hot.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/026-hot.png",
    },
    {
        name: "027-credit card.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/027-credit card.png",
    },
    {
        name: "028-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/028-coupon.png",
    },
    {
        name: "029-dress.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/029-dress.png",
    },
    {
        name: "030-online shop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/030-online shop.png",
    },
    {
        name: "031-wallet.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/031-wallet.png",
    },
    {
        name: "032-cashier.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/032-cashier.png",
    },
    {
        name: "033-hanger.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/033-hanger.png",
    },
    {
        name: "034-sale.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/034-sale.png",
    },
    {
        name: "035-gift.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/035-gift.png",
    },
    {
        name: "036-bill.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/036-bill.png",
    },
    {
        name: "037-shopping bag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/037-shopping bag.png",
    },
    {
        name: "038-price tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/038-price tag.png",
    },
    {
        name: "039-megaphone.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/039-megaphone.png",
    },
    {
        name: "040-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/040-label.png",
    },
    {
        name: "041-black friday.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/041-black friday.png",
    },
    {
        name: "042-chronometer.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/042-chronometer.png",
    },
    {
        name: "043-t shirt.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/043-t shirt.png",
    },
    {
        name: "044-pennant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/044-pennant.png",
    },
    {
        name: "045-location.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/045-location.png",
    },
    {
        name: "046-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/046-label.png",
    },
    {
        name: "047-basket.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/047-basket.png",
    },
    {
        name: "048-shopping cart.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/048-shopping cart.png",
    },
    {
        name: "049-gift card.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/049-gift card.png",
    },
    {
        name: "050-pennant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-linear-color/050-pennant.png",
    },
];

export const ECOMMERCE_ICONS_V2_FLAT = [
    {
        name: "001-card payment.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/001-card payment.png",
    },
    {
        name: "002-delivery truck.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/002-delivery truck.png",
    },
    {
        name: "003-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/003-discount.png",
    },
    {
        name: "004-store.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/004-store.png",
    },
    {
        name: "005-delivery.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/005-delivery.png",
    },
    {
        name: "006-sneaker.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/006-sneaker.png",
    },
    {
        name: "007-alarm.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/007-alarm.png",
    },
    {
        name: "008-screen.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/008-screen.png",
    },
    {
        name: "009-tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/009-tag.png",
    },
    {
        name: "010-money.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/010-money.png",
    },
    {
        name: "011-star.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/011-star.png",
    },
    {
        name: "012-web page.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/012-web page.png",
    },
    {
        name: "013-online shopping.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/013-online shopping.png",
    },
    {
        name: "014-medal.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/014-medal.png",
    },
    {
        name: "015-purse.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/015-purse.png",
    },
    {
        name: "016-cashier.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/016-cashier.png",
    },
    {
        name: "017-sale.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/017-sale.png",
    },
    {
        name: "018-wishlist.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/018-wishlist.png",
    },
    {
        name: "019-package.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/019-package.png",
    },
    {
        name: "020-gamepad.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/020-gamepad.png",
    },
    {
        name: "021-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/021-discount.png",
    },
    {
        name: "022-ribbon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/022-ribbon.png",
    },
    {
        name: "023-gift wrapping.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/023-gift wrapping.png",
    },
    {
        name: "024-wristwatch.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/024-wristwatch.png",
    },
    {
        name: "025-discount.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/025-discount.png",
    },
    {
        name: "026-hot.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/026-hot.png",
    },
    {
        name: "027-coupon.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/027-coupon.png",
    },
    {
        name: "028-dress.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/028-dress.png",
    },
    {
        name: "029-online shop.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/029-online shop.png",
    },
    {
        name: "030-wallet.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/030-wallet.png",
    },
    {
        name: "031-cashier.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/031-cashier.png",
    },
    {
        name: "032-hanger.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/032-hanger.png",
    },
    {
        name: "033-sale.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/033-sale.png",
    },
    {
        name: "034-gift.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/034-gift.png",
    },
    {
        name: "035-bill.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/035-bill.png",
    },
    {
        name: "036-shopping bag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/036-shopping bag.png",
    },
    {
        name: "037-price tag.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/037-price tag.png",
    },
    {
        name: "038-megaphone.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/038-megaphone.png",
    },
    {
        name: "039-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/039-label.png",
    },
    {
        name: "040-black friday.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/040-black friday.png",
    },
    {
        name: "041-chronometer.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/041-chronometer.png",
    },
    {
        name: "042-t shirt.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/042-t shirt.png",
    },
    {
        name: "043-pennant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/043-pennant.png",
    },
    {
        name: "044-location.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/044-location.png",
    },
    {
        name: "045-label.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/045-label.png",
    },
    {
        name: "046-basket.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/046-basket.png",
    },
    {
        name: "047-shopping cart.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/047-shopping cart.png",
    },
    {
        name: "048-gift card.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/048-gift card.png",
    },
    {
        name: "049-pennant.png",
        url: "https://stowahioimagelibrary.blob.core.windows.net/ecommerce-icons-v2-flat/049-pennant.png",
    },
];
