import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { ThemeContext } from "styled-components";
import { ProductNavigationProfileProps } from ".";
import { GroupItem, GroupItemType, SearchModelProductView } from "../../../api/models/_statistics";
import { productViewsInGroup } from "../../../api/products/productViewApi";
import { getDateFormat, getUniqueId } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import customNumeral from "../../../utils/numberFormat/moneyFormat";
import LoadingDualRing from "../../LoadingDualRing";
import { ChartContainerItem } from "../../Statistics/styled";
import { DefaultButton, Flex, GroupButtonContainer } from "../../_controls";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationEcommerceStatistics(props: ProductNavigationProfileProps) {
    const intl = useIntl();
    return (
        <NavigationCard {...props} title={intl.formatMessage(messages.statistics)}>
            <EcommerceContent {...props} />
        </NavigationCard>
    );
}

const EcommerceContent = (props: ProductNavigationProfileProps) => {
    const [items, setItems] = useState<GroupItem[]>([]);
    const [chartId, setChartId] = useState(getUniqueId());
    const [chartFormatters, setChartFormatters] = useState({
        tick: "MMM D",
        label: "DD MMM YYYY",
    });
    const themeContext = useContext(ThemeContext);
    const [groupType, setGroupType] = useState<GroupItemType>("day");

    const groupMutation = useMutation((data: SearchModelProductView) => productViewsInGroup(data), {
        onSuccess: (data) => {
            setItems(data.reverse());
            setChartId(getUniqueId());
        },
    });

    useEffect(() => {
        handleProductViewsGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product, groupType]);

    const handleProductViewsGroup = () => {
        let data: SearchModelProductView = {
            groupType: groupType,
            limit: 12,
            productId: props.product.id,
            productVariantId: props.productVariant?.id,
            organizationId: "",
            dateStart: new Date(),
            dateEnd: new Date(),
            accountId: "",
            minutesOffset: new Date().getTimezoneOffset(),
        };

        groupMutation.mutate(data);
    };

    const onChangeGroupType = (type: GroupItemType) => {
        setChartFormatters({
            tick: type === "day" ? "MMM D" : "MMM",
            label: type === "day" ? "DD MMM YYYY" : "MMMM",
        });
        setGroupType(type);
    };

    const themePallete = themeContext.palette;

    if (groupMutation.isSuccess && items.length === 0)
        return <span className="text-light">Este producto no contiene visitas en ningún Ecommerce</span>;

    const color = "#2962FF";
    return (
        <Flex column gap={20}>
            <Flex gap5 column>
                <span className="text-light text-small">Visitas del productos</span>
                <GroupButtonContainer className="small-view ">
                    <DefaultButton onClick={() => onChangeGroupType("day")} className={`${groupType === "day" ? "active" : ""}`}>
                        Agrupar por Dia
                    </DefaultButton>
                    <DefaultButton onClick={() => onChangeGroupType("month")} className={`${groupType === "month" ? "active" : ""}`}>
                        Agrupar por Mes
                    </DefaultButton>
                    {groupMutation.isLoading && <LoadingDualRing small />}
                </GroupButtonContainer>
            </Flex>
            {groupMutation.isSuccess && items.length === 0 && (
                <p className="text-light">Este producto aún no tiene visitas en ningún Ecommerce</p>
            )}
            {items.length > 0 && (
                <>
                    <ChartContainerItem>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart width={730} height={250} data={items}>
                                <defs>
                                    <linearGradient id={chartId} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor={color} stopOpacity={0.7} />
                                        <stop offset="95%" stopColor={color} stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis
                                    dataKey={"date"}
                                    stroke="#93939326"
                                    tick={{ fill: "#838383a6" }}
                                    tickFormatter={(value) => getDateFormat(value, chartFormatters.tick)}
                                />

                                <Tooltip
                                    contentStyle={{
                                        background: themePallete.background,
                                        color: themePallete.text,
                                        borderRadius: 10,
                                        borderColor: themePallete.backgroundDark,
                                    }}
                                    labelFormatter={(t) => getDateFormat(customMoment(t).toDate(), chartFormatters.label)}
                                    formatter={(value, name) => {
                                        value = customNumeral(value).format("0,0");
                                        name = "Visitas";
                                        return [value, name];
                                    }}
                                />
                                <Legend />
                                <Area
                                    type="monotone"
                                    dataKey={"count"}
                                    name={"Visitas"}
                                    stroke={color}
                                    fillOpacity={1}
                                    fill={`url(#${chartId})`}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </ChartContainerItem>
                </>
            )}
        </Flex>
    );
};
