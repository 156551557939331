import React, { useState } from "react";
import { useMutation } from "react-query";
import { createUpdateTransactionAccount } from "../../../../api/account/transactionAccount";
import { TransactionAccount } from "../../../../api/models/transactionAccount";
import { getAuthBody } from "../../../../auth";
import { useOrganizationContext } from "../../../../store/contexts/OrganizationContext";
import { useUserContext } from "../../../../store/contexts/UserContext";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import NavigationTabs from "../../../NavigationTabs";
import PermissionDenied from "../../../PermissionDenied";
import { DefaultButton, Flex, PrimaryButton } from "../../../_controls";
import Modal, { IModalShow } from "../../../modals/Modal";
import { TransactionAccountFormContainer } from "../styled";
import Details from "./Details";
import Files from "./Files";
import Payments from "./Payments";
import { getTransactionBody } from "./helper";

export interface TransactionAccountStateProps {
    body: TransactionAccount;
    setBody: (value: TransactionAccount) => void;
}

export enum TransactionAccountTabs {
    details,
    payments,
    files,
}

interface TransactionAccountFormProps {
    transactionAccount?: TransactionAccount;
    onCreate: (value: TransactionAccount) => void;
    onCancel: () => void;
    defaultTab?: TransactionAccountTabs;
}

export default function TransactionAccountForm(props: TransactionAccountFormProps) {
    const [body, setBody] = useState<TransactionAccount>(getTransactionBody(props.transactionAccount));
    const { organizationState } = useOrganizationContext();
    const [selectedKey, setSelectedKey] = useState(props.defaultTab ?? TransactionAccountTabs.details);
    const alert = useAlert();

    const { userPermission } = useUserContext();
    const createMutation = useMutation((data: TransactionAccount) => createUpdateTransactionAccount(data), {
        onSuccess: (data) => {
            alert.success("Cuenta actualizada con éxito");
            props.onCreate(data);
        },
    });

    const handleCreate = () => {
        let bodyCopy = { ...body };
        bodyCopy.accountId = getAuthBody().accountId;
        bodyCopy.organizationId = organizationState.currentOrganization?.id ?? "";
        props.onCreate(bodyCopy);
        createMutation.mutate(bodyCopy);
    };

    if (body.id && !userPermission.accounting?.update) {
        return <PermissionDenied />;
    }

    if (!body.id && !userPermission.accounting?.create) {
        return <PermissionDenied />;
    }

    return (
        <TransactionAccountFormContainer>
            <NavigationTabs
                hideBorder
                removeAutoWidth={true}
                defaultKey={selectedKey}
                onChange={(tab) => setSelectedKey(tab.key)}
                options={[
                    {
                        key: TransactionAccountTabs.details,
                        title: "Detalles",
                        icon: "fa-regular fa-clipboard-list",
                        component: <Details body={body} setBody={setBody} />,
                    },
                    {
                        key: TransactionAccountTabs.payments,
                        title: "Pagos",
                        icon: "fa-regular fa-money-check-dollar",
                        component: <Payments body={body} setBody={setBody} />,
                    },
                    {
                        key: TransactionAccountTabs.files,
                        title: "Archivos",
                        icon: "fa-regular fa-folder",
                        component: <Files body={body} setBody={setBody} />,
                    },
                ]}
            />
            <Flex gap10 alignCenter justifyEnd marginTop={20}>
                <DefaultButton disabled={createMutation.isLoading} borderRadius={10} bgLight w100 onClick={() => props.onCancel()}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton disabled={createMutation.isLoading} borderRadius={10} w100 onClick={handleCreate}>
                    {props.transactionAccount ? "Guardar Cambios" : "Crear Cuenta"} {createMutation.isLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </TransactionAccountFormContainer>
    );
}

interface TransactionAccountFormModalProps extends IModalShow, TransactionAccountFormProps {}

export const TransactionAccountFormModal = (props: TransactionAccountFormModalProps) => {
    return (
        <Modal
            {...props}
            ignoreOutsideClick
            title={props.transactionAccount ? "Editar Cuenta" : "Crear Cuenta"}
            showHeader
            removeVerticalAlign
            useMobileView
        >
            <TransactionAccountForm
                {...props}
                onCancel={() => props.setShow(false)}
                onCreate={(value) => {
                    props.setShow(false);
                    props.onCreate(value);
                }}
            />
        </Modal>
    );
};

export const TransactionAccountFormModalButton = (props: TransactionAccountFormProps) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <DefaultButton rounded onClick={() => setShow(true)}>
                <i className="fa-regular fa-plus"></i> Crear
            </DefaultButton>
            {show && <TransactionAccountFormModal {...props} show={show} setShow={setShow} />}
        </>
    );
};
