import styled, { DefaultTheme } from "styled-components";
import { device } from "../../../theme/themeProvider";

export interface CollapseProps {
    isMobile?: boolean;
    paddingTop?: number;
    border?: boolean;
}
interface ContainerCollapseBodyProps extends CollapseProps {
    theme: DefaultTheme;
}

export const ContainerCollapseBody = styled.div`
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    border-radius: 15px;
    border: ${(props: ContainerCollapseBodyProps) => (props.border ? `1px solid ${props.theme.palette.backgroundDark}` : "none")};
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    .container-header {
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .title-header {
            font-weight: 500;
            font-size: 15px;
        }

        .btn-arrow {
            background: transparent;
        }
    }

    &.space-blank {
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        border: 0px;
    }

    .link-title {
        color: ${(props) => props.theme.palette.text};
        font-weight: 500;
        &:hover {
            color: ${(props) => props.theme.palette.primary};
            text-decoration: underline;
        }
    }

    .container-content {
        display: none;
        padding: 10px 5px;
        top: 20px;
        position: relative;
        transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out, display 0.2s ease-in-out;
        opacity: 0;
        flex-direction: column;
        gap: 20px;

        &.show {
            display: flex;
        }
        &.animate {
            top: 0px;
            opacity: 1;
        }
    }

    .container-content {
        padding: 20px;
    }

    &.mobile {
        .container-content {
            padding: 10px 5px;
        }
        border: 0px;
    }

    @media ${device.md} {
        padding: 6px 5px;
        transition: padding 0.3s;
    }

    .container-content {
        ${(props: ContainerCollapseBodyProps) => (props.paddingTop ? `padding-top: ${props.paddingTop}px;` : "")}
    }

    &.remove-border {
        border: 0;
        .container-header {
            padding: 0;
        }
        .container-content {
            padding: 0px;
        }
    }

    &.remove-all-border-padding {
        border: 0;
        padding: 0;
        .container-content {
            padding: 0;
        }
    }
`;
