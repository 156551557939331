import React from "react";
import messages from "./messages";
import { useIntl } from "react-intl";
import Modal, { IModalShow } from "../../modals/Modal";
import ContactLabelList from "./ContactLabelList";

interface IProps extends IModalShow {}

export default function ContactLabelListModal(props: IProps) {
    const intl = useIntl();
    return (
        <Modal
            sizeType="md"
            id="modalcontactlabelformmodal"
            scrollInside={false}
            overflowAuto={false}
            removeVerticalAlign={true}
            title={intl.formatMessage(messages.labelList)}
            showHeader={true}
            useMobileView={true}
            {...props}
        >
            <ContactLabelList />
        </Modal>
    );
}
