import { NavigationOption } from "../../NavigationPanel/navigationTypes";

export enum NavigationTabName {
    Profile = "profile",
    Stock = "stock",
    StockLocation = "stockLocation",
    StockSerials = "stockSerials",
    Statistics = "statistics",
    Files = "files",
    BarcodeFiles = "barcodeFiles",
    Settings = "settings",
    Movements = "movements",
    SalePrices = "salePrices",
    ProductCosts = "productCosts",
    Description = "description",
    Features = "features",
    EcommerceConfig = "ecommerceConfig",
    EcommerceVisibility = "ecommerceVisibility",
    EcommerceProductPreview = "ecommerceProductPreview",
    EcommerceStatistics = "ecommerceStatistics",
    Logs = "logs",
    Optionals = "optionals",
    ProductAiAnalysis = "productAiAnalysis",
}

export enum NavigationGroupName {
    Profile = "profile",
    Stock = "stock",
    Statistics = "statistics",
    Logs = "logs",
    Files = "files",
    Ecommerce = "ecommerce",
    Costs = "costs",
    Optionals = "optionals",
    Recommendations = "recommendations",
    ProductAiAnalysis = "productAiAnalysis",
    Variants = "variants",
}

export const MENU_OPTION_OPTIONALS = {
    tabName: NavigationGroupName.Optionals,
    icon: "fa-regular fa-burger-soda",
};
export const MENU_OPTION_VARIANTS = {
    tabName: NavigationGroupName.Variants,
    icon: "fa-regular fa-burger-soda",
};

export const MENU_OPTIONS: NavigationOption<NavigationGroupName>[] = [
    {
        tabName: NavigationGroupName.Profile,
        icon: "fa-regular fa-home",
    },
    {
        tabName: NavigationGroupName.Stock,
        icon: "fa-regular fa-box",
    },
    {
        tabName: NavigationGroupName.ProductAiAnalysis,
        icon: "fa-regular fa-brain",
    },
    {
        tabName: NavigationGroupName.Costs,
        icon: "fa-regular fa-money-bill",
    },
    {
        tabName: NavigationGroupName.Statistics,
        icon: "fa-regular fa-chart-bar",
    },
    {
        tabName: NavigationGroupName.Logs,
        icon: "fa-regular fa-clipboard-list",
    },
    {
        tabName: NavigationGroupName.Files,
        icon: "fa-regular fa-file",
    },
    {
        tabName: NavigationGroupName.Ecommerce,
        icon: "fa-regular fa-shopping-cart",
    },
];
