import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { getServiceMessageError } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { Contact, ContactLabel, ContactLabelContact } from "../../../api/models/contact";
import {
    contactDeleteList,
    createContactLabelContactMulti,
    deleteContactLabelContactMulti,
    getContact,
} from "../../../api/orders/contactApi";
import PATHS from "../../../constants/paths";
import { ContactContext } from "../../../store/contexts/ContactContext";
import { UserContext } from "../../../store/contexts/UserContext";
import Dropdown from "../../Dropdown";
import LoadingDualRing from "../../LoadingDualRing";
import { useConfirmationModal } from "../../modals/ConfirmationModal";
import PermissionDenied from "../../PermissionDenied";
import { DefaultButton, Flex } from "../../_controls";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import CheckBox from "../../_controls/inputs/CheckBox";
import CreateContactModal from "../ContactForm/CreateContactModal";
import { ContactLabelItem, CustomerRowTr, FlexCheckboxImage } from "./styled";

interface ContactListRowProps {
    contact: Contact;
    isMobile: boolean;
    selected?: boolean;
    onSelectedChange: (id: string, isRemove: boolean) => void;
}

export default function ContactListRow(props: ContactListRowProps) {
    const { contact } = props;
    const alert = useAlert();
    const { contactState, contactActions } = useContext(ContactContext);
    const { userState } = useContext(UserContext);

    const [showEditContacts, setShowEditContacts] = useState(false);
    const [availableLabels, setAvailableLabels] = useState<ContactLabel[]>([]);

    const [labelsToCreate, setLabelsToCreate] = useState<ContactLabel[]>([]);
    const [labelsToRemove, setLabelsToRemove] = useState<ContactLabelContact[]>([]);

    const permission = getUserPermissions(userState.user);

    const ConfirmDelete = useConfirmationModal();

    const labelMultiMutation = useMutation((values: ContactLabelContact[]) => createContactLabelContactMulti(values), {
        onSuccess: (data) => {
            let newItems = data.map((item) => {
                return { ...item, contactLabel: labelsToCreate.find((label) => label.id === item.contactLabelId) };
            });
            contactActions.update({ ...contact, labels: [...(contact.labels ?? []), ...newItems] });
            setLabelsToCreate([]);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const getContactMutation = useMutation((id: string) => getContact(id));
    const removeContactMutation = useMutation((contactIds: string[]) => contactDeleteList(contactIds), {
        onSuccess: (data) => {
            var items = contactState.pagination.items.filter((item) => !data.some((y) => y.id === item.id));
            let pagination = { ...contactState.pagination, items: items };
            contactActions.receive(pagination);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const labelDeleteMultiMutation = useMutation((values: ContactLabelContact[]) => deleteContactLabelContactMulti(values), {
        onSuccess: (data) => {
            let newItems = contact.labels?.filter((item) => !labelsToRemove.find((label) => label.id === item.id)) ?? [];
            contactActions.update({ ...contact, labels: newItems });
            setLabelsToRemove([]);
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        let labels = contactState.labels.filter((x) => !contact.labels?.find((y) => y.contactLabelId === x.id));
        setAvailableLabels(labels);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactState.labels, contact.labels]);

    const onSelected = (value: boolean) => {
        //  setIsSelected(value);
        props.onSelectedChange(contact.id ?? "", value);
    };

    const handleCreateDeleteLabels = () => {
        onCreateContactLabelsMulti();
        onDeleteContactLabelsMulti();
    };

    const onCreateContactLabelsMulti = () => {
        if (labelsToCreate.length === 0) return;
        const contactLabelContacts = _.uniqBy(labelsToCreate, "id").map((x) => {
            return {
                contactId: contact.id,
                contactLabelId: x.id,
            } as ContactLabelContact;
        });
        labelMultiMutation.mutate(contactLabelContacts);
    };

    const onDeleteContactLabelsMulti = () => {
        if (labelsToRemove.length === 0) return;
        const contactLabelContacts = _.uniqBy(labelsToRemove, "id");
        labelDeleteMultiMutation.mutate(contactLabelContacts);
    };

    const onClickLabelToAdd = (item: ContactLabel) => {
        //check to add o remove
        if (labelsToCreate.find((x) => x.id === item.id)) {
            setLabelsToCreate(labelsToCreate.filter((x) => x.id !== item.id));
        } else {
            setLabelsToCreate([...labelsToCreate, item]);
        }
    };
    const onClickLabelToRemove = (item: ContactLabelContact) => {
        //check is already in list
        if (labelsToRemove.find((x) => x.contactLabelId === item.contactLabelId)) {
            setLabelsToRemove(labelsToRemove.filter((x) => x.contactLabelId !== item.contactLabelId));
        } else {
            setLabelsToRemove([...labelsToRemove, item]);
        }
    };

    const onClickEditContact = () => {
        setShowEditContacts(true);
        if (!getContactMutation.data) {
            getContactMutation.mutate(contact.id ?? "NA");
        }
    };

    const ColumnLink = ({ children, className }: any) => {
        return (
            <td className={className}>
                <NavLink to={PATHS.contactProfile(contact.id)}>{children}</NavLink>
            </td>
        );
    };

    const isLoading =
        labelMultiMutation.isLoading ||
        labelDeleteMultiMutation.isLoading ||
        removeContactMutation.isLoading ||
        getContactMutation.isLoading;

    return (
        <CustomerRowTr>
            <td className="min-width">
                <ConfirmDelete.Modal
                    title="¿Estas seguro?"
                    description="Esta acción no se puede revertir"
                    onCancel={() => ConfirmDelete.show(false)}
                    onOkay={() => {
                        ConfirmDelete.show(false);
                        removeContactMutation.mutate([contact.id ?? "NA"]);
                    }}
                />
                {showEditContacts && getContactMutation.data && (
                    <CreateContactModal
                        contact={getContactMutation.data}
                        onCreate={(value) => {
                            contactActions.update(value);
                        }}
                        show={showEditContacts}
                        setShow={setShowEditContacts}
                    />
                )}
                <FlexCheckboxImage>
                    {isLoading ? (
                        <LoadingDualRing small />
                    ) : (
                        <>
                            <div className={`row-checkbox-container ${props.selected ? "row-selected" : ""}`}>
                                <CheckBox
                                    checked={props.selected}
                                    id={contact.id ?? ""}
                                    name={contact.firstName}
                                    onChange={(e) => onSelected(e.target.checked)}
                                />
                            </div>
                            {!props.selected && (
                                <div className="row-image-container">
                                    <FlexImageLetter defaultIcon={"fa-regular fa-user"} image={contact.image} text={contact.firstName} />
                                </div>
                            )}
                        </>
                    )}
                </FlexCheckboxImage>
            </td>
            <ColumnLink className="padding-left">
                <Flex column>
                    <span>
                        {contact.firstName} {contact.lastName}
                    </span>
                    <span className="light">{contact.identificationNumber ? contact.identificationNumber : ""}</span>
                </Flex>
            </ColumnLink>
            {!props.isMobile && (
                <ColumnLink>
                    <span>{contact.email}</span>
                </ColumnLink>
            )}
            <ColumnLink>
                <span>{contact.phoneNumber}</span>
            </ColumnLink>
            {!props.isMobile && (
                <td>
                    <Flex gap10 flexWrap>
                        {contact.labels
                            ?.filter((x) => !labelsToRemove.some((y) => y.id === x.id))
                            ?.map((item, index) => (
                                <ContactLabelItem key={index}>{item.contactLabel?.name}</ContactLabelItem>
                            ))}
                        {labelsToCreate.map((item, index) => (
                            <ContactLabelItem key={index}>{item.name}</ContactLabelItem>
                        ))}
                    </Flex>
                </td>
            )}
            <td>
                <div className="show-hover">
                    <Dropdown
                        ignoreInternalClicks
                        icon="fa-regular fa-ellipsis-vertical"
                        onClose={() => {
                            handleCreateDeleteLabels();
                        }}
                        contentHorizontal="left"
                        renderOnShow
                        isLoading={getContactMutation.isLoading || removeContactMutation.isLoading}
                    >
                        {permission.contact?.update ? (
                            <Flex column padding={15} gap10>
                                <DefaultButton rounded className="dropdown-button" onClick={onClickEditContact}>
                                    <i className="fa-light fa-pen"></i>
                                    Editar
                                </DefaultButton>
                                <DefaultButton rounded className="dropdown-button" onClick={() => ConfirmDelete.show()}>
                                    <i className="fa-light fa-trash"></i>
                                    Eliminar
                                </DefaultButton>
                                <hr />
                                <span className="text-light text-small">Etiquetas</span>
                                {contact.labels?.map((item, index) => (
                                    <DefaultButton
                                        key={index}
                                        onClick={() => onClickLabelToRemove(item)}
                                        rounded
                                        colorPrimary={!labelsToRemove.some((x) => x.id === item.id)}
                                        className="dropdown-button"
                                    >
                                        <i className="fa-light fa-tag"></i>
                                        {item.contactLabel?.name}
                                    </DefaultButton>
                                ))}
                                {availableLabels.map((item, index) => (
                                    <DefaultButton
                                        key={index}
                                        onClick={() => onClickLabelToAdd(item)}
                                        rounded
                                        colorPrimary={labelsToCreate.some((x) => x.id === item.id)}
                                        className="dropdown-button"
                                    >
                                        <i className="fa-light fa-tag"></i>
                                        {item.name}
                                    </DefaultButton>
                                ))}
                            </Flex>
                        ) : (
                            <PermissionDenied />
                        )}
                    </Dropdown>
                </div>
            </td>
        </CustomerRowTr>
    );
}
