interface IUnitMeasure {
    name: string;
    value: string;
    valueNumber: number;
    valueString: string;
    category: string;
}

export const MEASUREMENT_LIST: IUnitMeasure[] = [
    {
        name: "Kilogramo",
        value: "kg",
        category: "mass",
        valueNumber: 1,
        valueString: "1 kg",
    },
    {
        name: "Gramo",
        value: "g",
        category: "mass",
        valueNumber: 1000,
        valueString: "1 g",
    },
    {
        name: "Libra",
        value: "lb",
        valueNumber: 2.205,
        category: "mass",
        valueString: "1 lb",
    },
    {
        name: "Onza",
        value: "oz",
        category: "mass",
        valueNumber: 35.274,
        valueString: "1 oz",
    },
    {
        name: "Miligramo",
        value: "mg",
        category: "mass",
        valueNumber: 1000000,
        valueString: "1 mg",
    },
    {
        name: "Tonelada",
        value: "t",
        category: "mass",
        valueNumber: 0.001,
        valueString: "1 t",
    },
    {
        name: "Metro",
        value: "m",
        category: "unitLength",
        valueNumber: 1,
        valueString: "1 m",
    },
    {
        name: "Centímetro",
        value: "cm",
        category: "unitLength",
        valueNumber: 100,
        valueString: "1 cm",
    },
    {
        name: "Milímetro",
        value: "mm",
        category: "unitLength",
        valueNumber: 1000,
        valueString: "1 mm",
    },
    {
        name: "Pulgada",
        value: "in",
        category: "unitLength",
        valueNumber: 39.37,
        valueString: "1 in",
    },
    {
        name: "Litro",
        value: "L",
        category: "volume",
        valueNumber: 1,
        valueString: "1 L",
    },
    {
        name: "Mililitro",
        value: "mL",
        category: "volume",
        valueNumber: 1000,
        valueString: "1 mL",
    },
    {
        name: "Galón",
        value: "gal",
        category: "volume",
        valueNumber: 0.264,
        valueString: "1 gal",
    },
    {
        name: "Onza",
        value: "oz",
        category: "volume",
        valueNumber: 33.814,
        valueString: "1 oz",
    },
    {
        name: "Cuarto",
        value: "qt",
        category: "volume",
        valueNumber: 1.057,
        valueString: "1 qt",
    },
    {
        name: "Celsius",
        value: "C",
        category: "temperature",
        valueNumber: 1,
        valueString: "1 °C",
    },
    {
        name: "Fahrenheit",
        value: "F",
        category: "temperature",
        valueNumber: 33.8,
        valueString: "1 °F",
    },
    {
        name: "Kelvin",
        value: "K",
        category: "temperature",
        valueNumber: 274.15,
        valueString: "1 K",
    },
    {
        name: "Metro Cuadrado",
        value: "m2",
        category: "area",
        valueNumber: 1,
        valueString: "1 m²",
    },
    {
        name: "Centímetro Cuadrado",
        value: "cm2",
        category: "area",
        valueNumber: 10000,
        valueString: "1 cm²",
    },
    {
        name: "Kilómetro Cuadrado",
        value: "km2",
        category: "area",
        valueNumber: 0.000001,
        valueString: "1 km²",
    },
    {
        name: "Hectárea",
        value: "ha",
        category: "area",
        valueNumber: 0.0001,
        valueString: "1 ha",
    },
    {
        name: "Metros por Minuto",
        value: "m/min",
        category: "speed",
        valueNumber: 1,
        valueString: "1 m/min",
    },
    {
        name: "Metros por Hora",
        value: "m/h",
        category: "speed",
        valueNumber: 0.0167,
        valueString: "1 m/h",
    },
    {
        name: "Kilómetros por Hora",
        value: "km/h",
        category: "speed",
        valueNumber: 0.0002778,
        valueString: "1 km/h",
    },
    {
        name: "Kilómetros por Minuto",
        value: "km/min",
        category: "speed",
        valueNumber: 0.0167,
        valueString: "1 km/min",
    },
];

export interface SelectOption {
    label: string;
    options: IUnitMeasure[];
}

export const getSelectOptions = () => {
    let labelOptions: SelectOption[] = [];
    let lastUnitName = "";
    let options: any[] = [];

    MEASUREMENT_LIST.forEach((item) => {
        if (item.category !== lastUnitName) {
            if (lastUnitName && options.length > 0) {
                labelOptions.push({
                    label: lastUnitName,
                    options: options.map((option) => {
                        return { ...option, label: option.name };
                    }),
                });
            }
            lastUnitName = item.category;
            options = [item];
        } else {
            options.push(item);
        }
    });

    return labelOptions;
};
