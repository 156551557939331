import { defineMessages } from "react-intl";

const base = "components.digitalInvoiceConfigurationForm";

export default defineMessages({
    calendar: {
        id: `${base}.calendar`,
        defaultMessage: "Calendario",
    },
    today: {
        id: `${base}.today`,
        defaultMessage: "Hoy",
    },
    yesterday: {
        id: `${base}.yesterday`,
        defaultMessage: "Ayer",
    },
    thisWeek: {
        id: `${base}.thisWeek`,
        defaultMessage: "Esta semana",
    },
    lastWeek: {
        id: `${base}.lastWeek`,
        defaultMessage: "Semana pasada",
    },
    thisMonth: {
        id: `${base}.thisMonth`,
        defaultMessage: "Este mes",
    },
    lastMonth: {
        id: `${base}.lastMonth`,
        defaultMessage: "Mes pasado",
    },
    custom: {
        id: `${base}.custom`,
        defaultMessage: "Personalizado",
    },
    last7Days: {
        id: `${base}.last7Days`,
        defaultMessage: "Últimos 7 días",
    },
    last30Days: {
        id: `${base}.last30Days`,
        defaultMessage: "Últimos 30 días",
    },
});
