import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderStatus } from "../../../api/models/orderBase";
import { KeyParamMapping } from "../../FilterParams/helper";

export type PurchaseParamType = "search" | "status" | "supplier" | "user" | "page" | "startDate" | "endDate" | "backLink" | "order";

export const IgnoreActiveFilters: (keyof PurchaseListParams)[] = ["page", "backLink", "order"];
export const IgnoreFilterValues: (keyof PurchaseListParams)[] = ["backLink"];

export interface PurchaseListParams {
    search?: string;
    order?: string;
    status?: OrderStatus;
    user?: string;
    supplier?: string;
    startDate?: string;
    endDate?: string;
    page?: number;
    backLink?: string;
}

export const EntryParamsKeyNames: KeyParamMapping<PurchaseListParams, PurchaseParamType>[] = [
    { key: "search", param: "search" },
    { key: "order", param: "order" },
    { key: "status", param: "status" },
    { key: "supplier", param: "supplier" },
    { key: "startDate", param: "startDate" },
    { key: "endDate", param: "endDate" },
    { key: "user", param: "user" },
    { key: "page", param: "page" },
    { key: "backLink", param: "backLink" },
];

export const getPurchaseParams = (params: PurchaseListParams) => {
    let search = "?";

    EntryParamsKeyNames.forEach((item) => {
        const value = params[item.key];
        if (value) {
            const processed = value.toString();
            search += getSearchValue(item.param, processed);
        }
    });

    if (search[search.length - 1] === "&") {
        search = search.substring(0, search.length - 1);
    }
    return search;
};

export const calculateActiveFilters = (productParams: PurchaseListParams) => {
    let counter = EntryParamsKeyNames.filter((x) => !IgnoreActiveFilters.includes(x.key) && !!productParams[x.key]).length;
    return counter;
};

const getSearchValue = (key: keyof PurchaseListParams, value: string) => {
    return `${key}=${encodeURIComponent(value)}&`;
};

export const usePurchaseSearchParams = () => {
    const [urlParams, setParams] = useSearchParams();

    const setSearchParams = (state: PurchaseListParams) => {
        const search = getPurchaseParams(state);
        setParams(search);
    };

    const getSearchParamsText = (state: PurchaseListParams) => {
        const search = getPurchaseParams(state);
        return search;
    };

    const getSearchParams = () => {
        let searchParams: PurchaseListParams = {};
        EntryParamsKeyNames.forEach((item) => {
            const value = urlParams.get(item.param);
            if (value) {
                var processed = item.decode ? item.decode(value) : value;
                searchParams[item.key] = processed;
            }
        });
        searchParams.page = parseInt(urlParams.get("page") ?? "1") ?? 1;
        if (searchParams.page === 1) {
            searchParams.page = undefined;
        }

        return searchParams;
    };

    const [searchParams, activeFilterCount, paramsWithoutOrder] = useMemo(() => {
        const newParams = getSearchParams();
        const filterCount = calculateActiveFilters(newParams);
        const paramsWithoutOrder = getSearchParamsText({ ...newParams, order: undefined });
        return [newParams, filterCount, paramsWithoutOrder];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    return { purchaseParams: searchParams, setPurchaseParams: setSearchParams, getSearchParamsText, activeFilterCount, paramsWithoutOrder };
};
