import React, { useContext, useEffect, useState } from "react";
import { convertTaxOrderItem } from "../../../api/models/orderBase";
import { IPurchase, IPurchaseItem } from "../../../api/models/purchase";
import { ITax } from "../../../api/models/tax";
import { TaxContext } from "../../../store/contexts/TaxContext";
import { formatMoney } from "../../../utils";
import { calculateItemBasePrice, extractTaxInformation, getItemTotalTax } from "../../../utils/taxes";
import { calculateTotal } from "../../Orders/CreateOrderForm/helpers";
import { CardPercentage } from "../../Orders/CreateOrderForm/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import { DefaultButton, Flex, GridTemplate, PrimaryButton, SquareButton, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal, { IModalShow } from "../../modals/Modal";
import { getPurchaseItemTotals } from "./helpers";

interface ModalAllProductsPropsPurchase extends IModalShow, ApplyToAllProductsPurchaseProps {}

export const ModalAllProductsPurchase = (props: ModalAllProductsPropsPurchase) => {
    const [taxListAllProducts, setTaxListAllProducts] = useState<ITax[]>([]);
    const { taxState, taxActions } = useContext(TaxContext);
    const [discountState, setDiscountState] = useState(0);
    const [realOrderValue, setRealOrderValue] = useState(0);
    const items = props.currentPurchase.items;

    useEffect(() => {
        taxActions.fetchIfNotExists();

        items.map((item) => {
            let listTaxesItem: ITax[] = [];
            item.taxes.map((tax) => {
                return taxState.items.map((availableTax) => {
                    if (availableTax.id === tax.taxId) {
                        listTaxesItem.push(availableTax);
                    }
                    return null;
                });
            });
            let listTaxesItemExist = items
                .map((item) => item.taxes)
                .every((taxItem) => taxItem.some((tax) => listTaxesItem.some((taxItem) => taxItem.id === tax.taxId)));
            if (listTaxesItemExist) {
                setTaxListAllProducts([...taxListAllProducts, ...listTaxesItem]);
            }

            let discountPercentage = item.manualPercentageDiscount;
            if (discountPercentage) {
                setDiscountState(discountPercentage);
            }
            return listTaxesItem;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (items) {
            let total = calculateTotal(items);
            setRealOrderValue(total);
        }
    }, [items]);

    const addTaxAllProducts = (tax: ITax) => {
        let taxExist = taxListAllProducts.find((taxItem) => taxItem.id === tax.id);
        if (taxExist) {
            let newListTaxes = taxListAllProducts.filter((taxItem) => taxItem.id !== tax.id);
            setTaxListAllProducts(newListTaxes);
        } else {
            setTaxListAllProducts([...taxListAllProducts, tax]);
        }
    };

    const calculateChanges = (taxes: ITax[]) => {
        const newItems = [...items];
        const listItemsUpdates: IPurchaseItem[] = [];
        newItems.forEach((purchaseItem) => {
            purchaseItem.taxes = [];
            // orderItem.discounts = 0;
            purchaseItem.discounts = [];
            let newListTaxOrder = taxes.map((tax: ITax) => {
                return convertTaxOrderItem(tax, purchaseItem.basePrice, false);
            });
            let [taxPercentage] = extractTaxInformation(newListTaxOrder);
            let basePriceResult = calculateItemBasePrice(purchaseItem, newListTaxOrder, true, false);
            purchaseItem.basePrice = basePriceResult.basePrice;
            purchaseItem.manualPercentageDiscount = discountState;
            let taxAmount = getItemTotalTax(newListTaxOrder, purchaseItem, true, false);

            let newPurchaseItem = {
                ...purchaseItem,
                basePrice: basePriceResult.basePrice,
                taxes: [...purchaseItem.taxes, ...newListTaxOrder],
                totalTax: taxAmount,
                totalTaxPercentage: taxPercentage,
            };

            listItemsUpdates.push(
                getPurchaseItemTotals(newPurchaseItem, props.currentPurchase.taxIncluded, props.currentPurchase.preTaxDiscount)
            );
        });
        props.onUpdateAllItem(listItemsUpdates);
    };

    const onSaveTaxes = () => {
        calculateChanges(taxListAllProducts);
        props.setShow(false);
    };

    const getTotalPercentageTaxes = () => {
        let sumPercentageTaxes = 0;
        taxListAllProducts.map((tax) => {
            sumPercentageTaxes += tax.value;
            return sumPercentageTaxes;
        });
        return sumPercentageTaxes;
    };

    const calculateTotalTaxes = () => {
        let totalValueItems = realOrderValue;
        let sumPercentageTaxes = getTotalPercentageTaxes();
        let totalTaxes = (totalValueItems / (1 + sumPercentageTaxes / 100) - totalValueItems) * -1;
        return totalTaxes;
    };

    const calculateTotalDiscount = () => {
        let totalDiscount = realOrderValue * (discountState / 100);
        return totalDiscount;
    };

    return (
        <Modal showHeader title="Aplicar a todos los productos" show={props.show} setShow={props.setShow}>
            <Flex column padding={20} gap={20}>
                <Flex column gap10>
                    <Flex gap10>
                        <TextField className="fa-regular fa-circle-dollar-to-slot" />
                        <TextField bold>Impuestos</TextField>
                    </Flex>
                    <GridTemplate size={180} gap={10}>
                        {taxState.items.map((tax, index) => {
                            const active = taxListAllProducts.find((item: ITax) => item.id === tax.id) ? "active" : "";
                            return (
                                <CardPercentage className={`${active}`} key={index} onClick={() => addTaxAllProducts(tax)}>
                                    <span className={`selection ${active} ${active ? "fa-sharp fa-regular fa-check" : ""}`}></span>
                                    <TextField className="text">{tax.name}</TextField>
                                    <TextField className="right" small>
                                        {tax.value}%
                                    </TextField>
                                </CardPercentage>
                            );
                        })}
                    </GridTemplate>
                </Flex>
                <Flex column gap10>
                    <Flex gap10>
                        <TextField className="fa-sharp fa-regular fa-badge-percent" />
                        <TextField bold>Descuentos</TextField>
                    </Flex>
                    <Flex alignCenter gap5>
                        <TextBoxNumeral
                            rounded
                            name="discount"
                            format="number"
                            value={discountState}
                            onNumberChange={setDiscountState}
                            maxLength={3}
                            autoComplete="off"
                        />
                        <TextField>%</TextField>
                    </Flex>
                </Flex>

                <Flex column gap10 alignStart>
                    <Table cellPadding={0} cellSpacing={0} tdPadding={8} widthAuto tdPaddingRight={20} hideBorde>
                        <tbody>
                            <tr>
                                <td>Total impuestos:</td>
                                <td>{formatMoney(calculateTotalTaxes())}</td>
                            </tr>

                            <tr>
                                <td>Total descuento:</td>
                                <td>{formatMoney(calculateTotalDiscount())}</td>
                            </tr>
                            <tr className="border-dashed">
                                <td>Valor sin impuestos:</td>
                                <td>{formatMoney(realOrderValue - calculateTotalTaxes())}</td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField bold>Valor total:</TextField>
                                </td>
                                <td>
                                    <TextField bold>{formatMoney(realOrderValue - calculateTotalDiscount())}</TextField>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Flex>
                <Flex marginTop={10} alignEnd justifyEnd gap10>
                    <DefaultButton borderRadius={10} onClick={() => props.setShow(false)}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton borderRadius={10} onClick={() => onSaveTaxes()}>
                        Guardar
                    </PrimaryButton>
                </Flex>
            </Flex>
        </Modal>
    );
};

interface ApplyToAllProductsPurchaseProps {
    currentPurchase: IPurchase;
    onUpdateAllItem: (item: IPurchaseItem[]) => void;
    showTitle?: boolean;
}

export const ApplyToAllProductsPurchase = (props: ApplyToAllProductsPurchaseProps) => {
    const [showModalAllTaxes, setShowModalAllTaxes] = useState(false);
    return (
        <>
            {!props.showTitle && (
                <SquareButton
                    title="Impuestos/Descuentos"
                    colorText
                    removeBackground
                    onClick={() => setShowModalAllTaxes(!showModalAllTaxes)}
                >
                    <TextField className="fa-regular fa-gear" />
                </SquareButton>
            )}
            {props.showTitle && (
                <DefaultButton colorText borderRadius={10} removeBackground onClick={() => setShowModalAllTaxes(!showModalAllTaxes)}>
                    <TextField className="fa-regular fa-gear" /> Impuestos/Descuentos
                </DefaultButton>
            )}
            {showModalAllTaxes && <ModalAllProductsPurchase {...props} show={showModalAllTaxes} setShow={setShowModalAllTaxes} />}
        </>
    );
};
