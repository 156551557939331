import _ from "lodash";
import React, { useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../api";
import { AccountUser } from "../../../api/models/accountUser";
import { MessageOptionBody } from "../../../api/models/notification";
import { sendNotificationSms } from "../../../api/notifications/notificationApi";
import LoadingDualRing from "../../LoadingDualRing";
import Modal from "../../modals/Modal";
import { DefaultButton, DefaultInput, PrimaryButton } from "../../_controls";
import { FormFooter } from "../../_controls/forms/styled";
import { ConfirmPhoneContainer } from "./styled";

interface ConfirmPhoneProps {
    user: AccountUser;
    onHandleConfirm: () => void;
}

export default function ConfirmPhone(props: ConfirmPhoneProps) {
    const { user } = props;
    const alert = useAlert();
    const [showConfirmPanel, setShowConfirmPanel] = useState(false);
    const [randomNumber] = useState(_.random(1000, 9999));

    const smsMutation = useMutation((body: MessageOptionBody) => sendNotificationSms(body), {
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const sendAndShow = () => {
        if (!user.phoneNumber || !user.phonePrefix) {
            alert.info(`El numero de teléfono y el prefijo son requeridos`);
            return;
        }
        setShowConfirmPanel(true);

        smsMutation.mutate({
            countryCode: user.phonePrefix,
            message: `${randomNumber} Código de verificación de Wahio`,
            phoneNumber: `${user.phonePrefix}${user.phoneNumber}`,
            accountId: 'getAuthBody().accountId',
        });
    };

    const onClickConfirm = () => {
        const input: any = document.getElementById("input-confirm");
        if (input?.value === randomNumber.toString()) {
            setShowConfirmPanel(false);
            props.onHandleConfirm();
        } else {
            alert.info("El numero ingresado no es valido");
        }
    };

    return (
        <ConfirmPhoneContainer>
            {!user.phoneConfirmed && (
                <span className="text-danger">
                    El numero de teléfono no está confirmado haz{" "}
                    <strong className="action-clic" onClick={() => sendAndShow()}>
                        clic aca
                    </strong>{" "}
                    para confirmar
                </span>
            )}
            {showConfirmPanel && (smsMutation.isLoading || smsMutation.isSuccess) && (
                <Modal
                    show={showConfirmPanel}
                    setShow={setShowConfirmPanel}
                    ignoreOutsideClick
                    useButtonClose
                    styleBody={{ padding: "15px" }}
                >
                    {smsMutation.isLoading && (
                        <>
                            <h3>Enviando código...</h3>
                            <LoadingDualRing center />
                        </>
                    )}
                    {smsMutation.isSuccess && (
                        <>
                            <h3>Hemos enviado un código al {user.phoneNumber}</h3>
                            <p>Ingresa el código de 4 dígitos</p>
                            <DefaultInput id="input-confirm" type="number" placeholder="Ingresa el código aca" className="w-100" />
                            <FormFooter className="flex flex-center mt-2">
                                <DefaultButton onClick={() => setShowConfirmPanel(false)} className="mr-1">
                                    Cancelar
                                </DefaultButton>
                                <PrimaryButton onClick={onClickConfirm}>Confirmar Código</PrimaryButton>
                            </FormFooter>
                        </>
                    )}
                </Modal>
            )}
        </ConfirmPhoneContainer>
    );
}
