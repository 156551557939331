import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { createInitialAccountContract } from "../../../api/account/accountApi";
import useBillingPayment, { TransactionBodyResult } from "../../../api/billingPayments/hook";
import { BillingCycle, CurrencyType } from "../../../api/models/accountBilling";
import { AccountCreateContractInput, AccountPaymentMethod, AccountPlanBase, AccountUser } from "../../../api/models/accountUser";
import { getAuthBody } from "../../../auth";
import { useAccountUserContext } from "../../../store/contexts/AccountUserContext";
import { useUserContext } from "../../../store/contexts/UserContext";
import { formatMoney, getModelFullName, getRoundNumber } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import ToggleSwitch from "../../ToggleSwitch";
import { DefaultButton, Flex, PrimaryButton } from "../../_controls";
import { useModal } from "../../modals/Modal";
import { BillingPaymentMethodSelector } from "./BillingPaymentMethod/BillingPaymentMethodSelector";
import PaymentSetupForm from "./BillingPaymentMethod/StripePaymentSetupForm";
import messages from "./messages";
import { AccountUserRowView, BillingInvoiceFormContainer, BillingTable } from "./styled";

interface AccountBillingInvoiceFormProps {
    plan: AccountPlanBase;
    costPerUser: number;
    billingCycle: BillingCycle;
    currency: CurrencyType;
}

export default function AccountBillingInvoiceForm(props: AccountBillingInvoiceFormProps) {
    const { accountUserState, accountUserActions } = useAccountUserContext();
    const { userState, userActions, methodList } = useUserContext();

    const intl = useIntl();
    const alert = useAlert();
    const ModalUserConfig = useModal();
    const [calculator, setCalculator] = useState({
        usersCount: 0,
        costPerUser: 0,
        amount: 0,
        amountInCents: 0,
    });

    const [paymentMethod, setPaymentMethod] = useState<AccountPaymentMethod>();

    const accountCreateContractMutation = useMutation((data: AccountCreateContractInput) => createInitialAccountContract(data));

    const { transactionIsLoading, createTransactionWithPaymentMethod } = useBillingPayment();

    useEffect(() => {
        if (methodList.length === 0) return;

        const paymentMethod = methodList[0];
        if (paymentMethod) setPaymentMethod(paymentMethod);
    }, [methodList]);

    useEffect(() => {
        let totalUsers = accountUserState.items.filter((x) => x.status === "active").length;
        let price = props.costPerUser;
        const amount = getRoundNumber(totalUsers * price);
        setCalculator({ usersCount: totalUsers, costPerUser: price, amount, amountInCents: Math.trunc(amount) * 100 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items]);

    useEffect(() => {
        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUserChange = (value: AccountUser, index: number) => {
        let usersCopy = [...accountUserState.items];
        usersCopy[index] = value;
        accountUserActions.receiveAccountUsers(usersCopy);
    };

    if (accountUserState.isFetching) {
        return (
            <Flex padding={30} justifyCenter w100>
                <LoadingDualRing center />
            </Flex>
        );
    }

    const handleCreateContract = (transaction: TransactionBodyResult, paymentMethod: AccountPaymentMethod) => {
        const createContractData: AccountCreateContractInput = {
            id: "",
            plan: props.plan,
            cycle: props.billingCycle,
            accountPaymentMethod: paymentMethod,
            paymentAmount: calculator.amount,
            paymentMethod: "CARD",
            paymentSource: paymentMethod.source,
            paymentSourceTransactionId: transaction.transactionId,
            userLicenses: calculator.usersCount,
            usersIdsDisabled: accountUserState.items.filter((x) => x.status === "disabled").map((x) => x.id),
            accountId: getAuthBody().accountId,
            costPerUser: calculator.costPerUser,
            costPerSms: 70,
            costPerEmail: 10,
            costPerEBilling: 200,
            costPerCloudGb: 5000,
            currency: "COP",
            costPerUserSeller: 15000,
        };

        accountCreateContractMutation
            .mutateAsync(createContractData)
            .then((contractResult) => {
                alert.success("La cuenta ha sido actualizada con éxito");
                userActions.requestAccountUser(getAuthBody().accountUserId);
            })
            .catch((err) => {
                alert.error("No fue posible crear el contrato, por favor comunícate con soporte si el pago fue realizado");
            });
    };

    const onCreatePayment = async () => {
        if (!paymentMethod) {
            alert.info("Selecciona un método de pago");
            return;
        }

        const transactionResult = await createTransactionWithPaymentMethod(paymentMethod, calculator.amount);
        if (transactionResult) {
            handleCreateContract(transactionResult, paymentMethod);
        } else {
            alert.error("No fue posible crear el contrato, por favor comunícate con soporte si el pago fue realizado");
        }
    };

    const paymentIsLoading = transactionIsLoading || accountCreateContractMutation.isLoading;

    if (methodList.length === 0) {
        return (
            <BillingInvoiceFormContainer>
                <PaymentSetupForm paramsReturnUrl={`?launchCreatePlan=${props.plan}&cycle=${props.billingCycle}`} />
            </BillingInvoiceFormContainer>
        );
    }

    return (
        <BillingInvoiceFormContainer>
            <Flex column>
                <h2 className="text-bold m-0">
                    Plan {intl.formatMessage(messages[props.plan])} - {intl.formatMessage(messages[props.billingCycle])}
                </h2>
                {props.billingCycle === "annual" && <span className="text-light text-small">Tu plan anual se paga mes a mes.</span>}
            </Flex>
            <Flex gap5 alignStart column>
                <span className="text-small text-light">Tienes {calculator.usersCount} usuarios activos</span>
                <DefaultButton small bgLight rounded onClick={() => ModalUserConfig.show()}>
                    Gestionar
                </DefaultButton>
            </Flex>

            <ModalUserConfig.Modal ignoreOutsideClick>
                <Flex column gap10 padding={20}>
                    <h2 className="text-bold m-0 mb-1">Tienes {calculator.usersCount} usuarios activos</h2>
                    {accountUserState.items.map((user, index) => (
                        <AccountUserRowView key={index}>
                            <span>{getModelFullName(user)}</span>

                            {user.id !== userState.user?.account?.accountOwnerId && (
                                <Flex alignCenter gap5>
                                    <span className="text-small text-light">{user.status === "active" ? "Activo" : "Desactivado"}</span>
                                    <ToggleSwitch
                                        checked={user.status === "active"}
                                        onChecked={(checked) => {
                                            onUserChange({ ...user, status: checked ? "active" : "disabled" }, index);
                                        }}
                                    />
                                </Flex>
                            )}
                        </AccountUserRowView>
                    ))}
                    {calculator.usersCount !== accountUserState.items.length && (
                        <span className="text-light text-small">Los usuarios que desactives no se eliminan</span>
                    )}
                    <Flex w100 justifyEnd className="mt-2">
                        <PrimaryButton onClick={() => ModalUserConfig.show(false)}>Confirmar Cambios</PrimaryButton>
                    </Flex>
                </Flex>
            </ModalUserConfig.Modal>
            <hr />
            <Flex column>
                {paymentMethod && <BillingPaymentMethodSelector methodSelected={paymentMethod} onChangeMethodSelected={setPaymentMethod} />}
            </Flex>
            <hr />

            <Flex className="total-line">
                <span>
                    {formatMoney(calculator.costPerUser)} x {calculator.usersCount} usuarios x 1 Mes = {formatMoney(calculator.amount)}
                </span>
            </Flex>
            <BillingTable cellSpacing={0} cellPadding={0}>
                <tbody>
                    <tr>
                        <td>Usuarios</td>
                        <td>{calculator.usersCount}</td>
                    </tr>
                    <tr>
                        <td>Plan {intl.formatMessage(messages[props.plan])}</td>
                        <td>{formatMoney(calculator.costPerUser)} cop</td>
                    </tr>
                    <tr>
                        <td>Impuestos</td>
                        <td>$0 cop</td>
                    </tr>
                    <tr className="total">
                        <td>Total</td>
                        <td>{formatMoney(calculator.amount)} cop</td>
                    </tr>
                </tbody>
            </BillingTable>

            <Flex justifyEnd>
                <PrimaryButton disabled={paymentIsLoading} rounded onClick={onCreatePayment}>
                    Pagar plan {intl.formatMessage(messages[props.plan])} {paymentIsLoading && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </BillingInvoiceFormContainer>
    );
}
