export const ORDER_STATUS_STYLE = {
    void: { class: "style-neutral", icon: "fa-regular fa-trash" },
    paid: { class: "style-blue", icon: "wahioicon-check" },
    credit: { class: "style-red", icon: "wahioicon-dollar-sign" },
    quotation: { class: "style-purple", icon: "wahioicon-dollar-sign" },
    open: { class: "style-neutral", icon: "wahioicon-dollar-sign" },
    partial: { class: "style-orange", icon: "wahioicon-dollar-sign" },
    queued: { class: "style-neutral", icon: "wahioicon-clock" },
    draft: { class: "style-neutral", icon: "wahioicon-eraser" },
    authorized: { class: "style-neutral", icon: "wahioicon-eraser" },
    declined: { class: "style-neutral", icon: "wahioicon-times-circle" },
    invalid: { class: "style-neutral", icon: "wahioicon-close" },
};
