import { WahioCloudItemType } from "../../api/models/wahioCloud";
export const IMAGE_EXTENSIONS = ["webp", "svg", "gif", "png", "jpg", "jpeg"];

export const TEXT_EXTENSIONS = [
    "txt",
    "doc",
    "docx",
    "pdf",
    "xlsx",
    "xlsm",
    "xlsb",
    "xltx",
    "xltm",
    "xls",
    "xlt",
    "xlr",
    "csv",
    "ttf",
    "json",
    "one",
];
export const VIDEO_EXTENSIONS = ["avi", "mp4", "mpeg", "mwv"];
export const PRESENTATION_EXTENSIONS = [
    "ppt",
    "pot",
    "pps",
    "ppa",
    "ppam",
    "OOXML",
    "pptx",
    "pptm",
    "potx",
    "potm",
    "ppam",
    "ppsx",
    "ppsm",
    "sldx",
    "sldm",
    "pa",
];

export const AUDIO_EXTENSIONS = ["mp3", "wav", "wma"];
export const EDITABLE_EXTENSIONS = ["psd", "ai", "eps", "xd"];
export const COMPRESSION_EXTENSIONS = ["zip", "rar", "tar"];

export const ALL_EXTENSIONS = [
    ...IMAGE_EXTENSIONS,
    ...TEXT_EXTENSIONS,
    ...VIDEO_EXTENSIONS,
    ...AUDIO_EXTENSIONS,
    ...EDITABLE_EXTENSIONS,
    ...PRESENTATION_EXTENSIONS,
    ...COMPRESSION_EXTENSIONS,
];

export const checkIfExtensionIsValid = (extension?: string) => {
    if (!extension) return false;
    return !!ALL_EXTENSIONS.find(
        (x) => x.toLowerCase() === extension.toLowerCase()
    );
};

export const getWahioCloudItemType = (
    extension?: string
): WahioCloudItemType => {
    if (!extension) return "other";

    if (!!IMAGE_EXTENSIONS.find((x) => x === extension)) return "image";
    if (!!VIDEO_EXTENSIONS.find((x) => x === extension)) return "video";
    if (!!TEXT_EXTENSIONS.find((x) => x === extension)) return "text";
    if (!!AUDIO_EXTENSIONS.find((x) => x === extension)) return "audio";
    if (!!EDITABLE_EXTENSIONS.find((x) => x === extension)) return "editable";
    if (!!PRESENTATION_EXTENSIONS.find((x) => x === extension))
        return "presentation";
    if (!!COMPRESSION_EXTENSIONS.find((x) => x === extension))
        return "compression";

    return "other";
};

export const getFileExtensionFromString = (value: string) => {
    var re: any = /(?:\.([^.]+))?$/;
    return re.exec(value)[1];
};

export const getFileExtension = (file?: File) => {
    if (!file) return undefined;
    return getFileExtensionFromString(file.name);
};

export const validateUrlImage = (url: string, imageDefault?: any) => {
    const image = new Image();
    image.src = url;
    if (image.width === 0) {
        return imageDefault ?? undefined;
    }
    return url;
};
