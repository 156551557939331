export const PLAN_MONTHLY_LIMIT = {
    free: {
        storage: 0.5,
        email: 500,
        sms: 100,
        orders: 500,
        contacts: 500,
        products: 500,
    },
    basic: {
        storage: 2,
        email: 1000,
        sms: 200,
        orders: 500,
        contacts: 500,
        products: 500,
    },
    standard: {
        storage: 5,
        email: 2000,
        sms: 300,
        orders: 1500,
        contacts: 5000,
        products: 1500,
    },
    premium: {
        storage: 15,
        email: 5000,
        sms: 500,
        orders: 50000,
        contacts: 50000,
        products: 50000,
    },
};