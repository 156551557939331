import React, { useContext, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import PATHS from "../../constants/paths";
import { TOUR_NAME_QUICK_ACTIONS } from "../QuickActionsMenu";
import { TOUR_NAME_MENU_SALE } from "../../pages/AppContainer/AppVerticalMenu/MenuOptionsHook";
import { TOUR_NAME_CREATE_PRODUCT } from "../SearchTabTitle/SearchProductTabTitle";
import { Flex, PrimaryButton, TextField } from "../_controls";
import { useQuickStartUpdate } from "./quickStartHook";
import { CustomVideo } from "./styled";

export const MENU_TARGET = "." + TOUR_NAME_MENU_SALE;
export const QUICK_ACTIONS_TARGET = "." + TOUR_NAME_QUICK_ACTIONS;
export const CREATE_PRODUCT_TARGET = "." + TOUR_NAME_CREATE_PRODUCT;

export default function QuickStartCreateSale() {
    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const updateQuickStart = useQuickStartUpdate();

    const [steps] = useState<Step[]>(() => {
        const stepList: Step[] = [
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>Aprende a crear una venta</TextField>

                        <ul>
                            <li>Genera facturación electronica para tus ventas</li>
                            <li>Notifica automáticamente por correo y mensajes a tus clientes</li>
                            <li>Crea cotizaciones y borradores para tus clientes</li>
                        </ul>
                    </Flex>
                ),
                placement: "center",
                target: "body",
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-grid-2"></span> Acciones rápidas {">"} Crear Venta
                        </TextField>
                        <TextField>Aquí puedes acceder a diferentes acciones rápidas como crear productos, ventas, contactos...</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                target: QUICK_ACTIONS_TARGET,
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-cart-shopping"></span> Ordenes {">"} Crear Venta
                        </TextField>

                        <TextField>Aquí podrás gestionar todas tus ordenes</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                target: MENU_TARGET,
            },

            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>
                            <span className="fa-regular fa-bag-shopping"></span> Panel de Ventas
                        </TextField>
                        <TextField>Aquí se mostraran todas tus ventas y un resumen estadístico de tus ventas</TextField>
                        <TextField>
                            También podrás ver las ordenes que se encuentran anuladas, las devoluciones, cotizaciones y comentarios
                            realizados en las ordenes
                        </TextField>
                    </Flex>
                ),

                placement: "center",
                target: "body",
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>Crea tu primera Venta</TextField>
                    </Flex>
                ),
                floaterProps: {
                    disableAnimation: true,
                },
                spotlightPadding: 20,
                disableBeacon: true,
                locale: { next: "Crear Venta", last: "Crear Venta" },
                target: ".CREATE_ORDER",
            },
            {
                content: (
                    <Flex column gap10 textAlignLeft>
                        <TextField bold>Panel de Creación de Venta</TextField>
                        <TextField>Para iniciar creando tu venta, solo debes hacer clic en algún producto</TextField>
                    </Flex>
                ),

                placement: "center",
                target: "body",
            },
        ];
        return stepList;
    });

    const [tourState, setTourState] = useState({
        run: false,
        stepIndex: 0,
    });

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, action, index } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setTourState({ ...tourState, run: false });
        }

        if (index === 3) {
            navigate(PATHS.orderSalesList);
        }
        if (steps.length - 1 === index) {
            navigate(PATHS.orderPosNew("sale"));
        }

        if (status === STATUS.FINISHED) {
            handleCompleteTour();
        }

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
            setTourState({ ...tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        }
    };

    const handleCompleteTour = () => {
        updateQuickStart.updateOption("createSale", true);
    };

    const handleInitTour = () => {
        navigate("/tour");
        setTourState({ ...tourState, run: true, stepIndex: 0 });
    };

    return (
        <div>
            {steps.length > 0 && (
                <Joyride
                    callback={handleJoyrideCallback}
                    continuous={true}
                    run={tourState.run}
                    stepIndex={tourState.stepIndex}
                    scrollToFirstStep={true}
                    locale={{ next: "Siguiente", back: "Atrás" }}
                    showProgress={true}
                    showSkipButton={true}
                    disableOverlayClose
                    steps={steps}
                    styles={{
                        options: {
                            arrowColor: themeContext.palette.background,
                            backgroundColor: themeContext.palette.background,
                            beaconSize: 36,
                            overlayColor: "rgba(0, 0, 0, 0.5)",
                            primaryColor: "#304FFE",
                            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                            textColor: themeContext.palette.text,
                            width: undefined,
                            zIndex: 100,
                        },
                        buttonNext: {
                            fontWeight: 500,
                            borderRadius: "10px",
                            padding: "10px 15px",
                            background: "#304FFE",
                        },
                        tooltip: {
                            borderRadius: "20px",
                            background: themeContext.palette.background,
                            color: themeContext.palette.text,
                        },
                    }}
                />
            )}

            <Flex column gap10 padding={10}>
                <TextField>En Wahio existen dos formas de crear ventas</TextField>
                <Flex gap10 alignCenter>
                    1. En el menu lateral: <span className="fa-regular fa-users"></span>
                    <TextField bold>Ordenes</TextField>
                </Flex>
                <TextField>
                    2. En el menu de acciones rápidas: <span className="fa-regular fa-grid-2"></span>
                </TextField>
                <PrimaryButton rounded onClick={handleInitTour}>
                    Muéstrame como
                </PrimaryButton>

                <Flex className="mt-2" alignCenter gap10>
                    <span className="fa-regular fa-video"></span>
                    <TextField bold>Ver Tutorial</TextField>
                </Flex>

                <CustomVideo controls controlsList="nodownload" className="mt-1">
                    <source src={"https://wahiomedia.blob.core.windows.net/wahiotutorials/crear_venta.mp4"} type="video/mp4" />
                    Tu navegador no soporta la etiqueta de video.
                </CustomVideo>
            </Flex>
        </div>
    );
}
