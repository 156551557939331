import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DataBaseModel, DEFAULT_LISTING, ListingType } from ".";
import { SelectOptionObject } from "../../../api/models";
import { SelectBody } from "../../Select/styled";

interface EBillingDataSelectorProps {
    type: ListingType;
    selectedId?: number;
    onSelectedChange: (data: DataBaseModel, taxDescription?: string) => void;
    title: string;
    disabled?: boolean;
    w100?: boolean;
}

interface EBillingDataLabelProps {
    type: ListingType;
    selectedId: number;
}

export const EBillingDataLabel = (props: EBillingDataLabelProps) => {
    const [label, setLabel] = useState<string>("");

    useEffect(() => {
        var item = DEFAULT_LISTING[props.type].find((x) => x.id === props.selectedId);
        setLabel(item?.name ?? "");
    }, [props.type, props.selectedId]);

    return <span>{label}</span>;
};

export default function EBillingDataSelector(props: EBillingDataSelectorProps) {
    const [selectedValue, setSelectedValue] = useState<DataBaseModel>();
    const [items, setItems] = useState<DataBaseModel[]>([]);

    useEffect(() => {
        setItems(DEFAULT_LISTING[props.type]);
    }, [props.type]);

    useEffect(() => {
        if (props.selectedId) {
            var selected = items.find((x) => x.id === props.selectedId);
            setSelectedValue(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.selectedId]);

    const getOption = (item: DataBaseModel) => {
        let option: SelectOptionObject<DataBaseModel> = {
            value: item.id,
            label: item.name,
            object: item,
        };
        return option;
    };

    const onNumerationChange = (value?: DataBaseModel) => {
        if (value) {
            setSelectedValue(value);
            let taxDescription = "";
            if(props.type === "taxes"){
                const existDescription = DEFAULT_LISTING["taxes"].find(x => x.id === value.id)?.description
                taxDescription = existDescription ?? ""
            }
            props.onSelectedChange(value, taxDescription);
        }
    };

    const classList: string[] = [];
    if (props.w100) classList.push("w100");

    return (
        <SelectBody className={classList.join(" ")}>
            {props.title && <span className="label">{props.title}</span>}
            <Select
                placeholder="Numeración"
                className={`react-select-basic ${selectedValue ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedValue?.name}
                defaultValue={selectedValue ? getOption(selectedValue) : undefined}
                isDisabled={props.disabled}
                onChange={(value) => {
                    onNumerationChange(value?.object);
                }}
                isRtl={false}
                isSearchable={true}
                name="warehouse"
                options={items.map((item) => getOption(item))}
            />
        </SelectBody>
    );
}
