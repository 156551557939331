import * as HoverCard from "@radix-ui/react-hover-card";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAndGetMessage } from "../../i18n/helper";
import { IPathGroup } from "../../pages/AppContainer/AppVerticalMenu/AppVerticalMenu";
import { MenuNotification } from "../../pages/AppContainer/AppVerticalMenu/MenuOptionsHook";
import { IMenuOption } from "../../pages/AppContainer/menuConfig";
import messages from "../../pages/AppContainer/messages";
import { useMenuContext } from "../../store/contexts/MenuContext";
import { ignoreElementClick } from "../../utils";
import { HoverCardContentStyle } from "../_controls/PopoverRadix/hoverCardStyle";
import { ContainerItemsMenu, ItemSeparator, MenuChildListContainer, MenuIcon, MenuItemBack, MenuItemBase } from "./styled";

interface VerticalMenuItemProps extends IMenuOption {
    tourName?: string;
    hideTitle?: boolean;
    children?: React.ReactNode;
    childList?: IMenuOption[];
    startLink?: string;
    pathGroup: IPathGroup;
    position?: "bottom";
    useHistoryLink?: boolean;
    showBackOption?: boolean;
    onBackClick?: () => void;
    notifications?: MenuNotification[];
}

export default function VerticalMenuItem(props: VerticalMenuItemProps) {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);

    const [hideChildList, setHideChildList] = useState(false);

    const notificationCount = React.useMemo(() => {
        if (props.notifications) {
            return props.notifications.reduce((acc, curr) => acc + curr.count, 0);
        }
        return 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.notifications]);

    const getIsActive = (activeLink: string, currentLink: string) => {
        let _activeLink = activeLink + "/";
        let _currentLink = currentLink + "/";
        return _activeLink.includes(_currentLink);
    };

    useEffect(() => {
        const active = getIsActive(props.pathGroup.activeMenu, props.link);
        setIsActive(active);
        if (!active && hideChildList) {
            setHideChildList(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pathGroup, props.link]);

    const { icon, title } = props;
    let mainClass = props.hideTitle ? "center" : "";
    if (props.childList && props.childList.length > 0) {
        mainClass += " with-child";
    }
    const hasChildAndActive = isActive && props.childList && props.childList.length > 0;

    useEffect(() => {
        //ESTA FUNCIÓN PERMITE QUE SE REDIRIJA EL PRIMER ELEMENTO PARA QUE SE PERMITE ACTIVAR EL ITEM CON LOS SUBITEMS
        if (props.startLink && props.link === props.pathGroup.pathname) {
            navigate(props.startLink);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.link, props.pathGroup.pathname, props.startLink]);

    let styles: any = {};
    if (props.position === "bottom") {
        styles.marginTop = "auto";
    }

    const onClickItem = (e: any) => {
        var clickArrow = ignoreElementClick(e.target, "ignore");
        if (clickArrow) {
            return;
        }
        if (!(hasChildAndActive && hideChildList)) {
            navigate(props.link);
        }
        setHideChildList(false);
    };

    const showSeparator = !props.showBackOption && hasChildAndActive && !hideChildList;
    const showHoverList = (!hasChildAndActive || hideChildList) && props.childList && props.childList.length > 0;

    const showSubMenu = hasChildAndActive && props.childList && !hideChildList;

    const showNotificationAlert = notificationCount > 0;

    return (
        <>
            {showSeparator && <ItemSeparator />}
            {props.showBackOption && (
                <MenuItemBack
                    className={`${props.tourName ?? ""} mb-1`}
                    onClick={() => {
                        if (props.onBackClick) {
                            props.onBackClick();
                            setHideChildList(hasChildAndActive ? true : false);
                        }
                    }}
                >
                    <span className="fa-light fa-arrow-left-long"></span>
                    {!props.hideTitle && <span className="mr-1">{title}</span>}
                </MenuItemBack>
            )}
            {!props.showBackOption && (
                <ContainerItemsMenu>
                    <HoverCard.Root openDelay={50}>
                        <HoverCard.Trigger asChild>
                            <MenuItemBase
                                to={props.link}
                                className={`${props.tourName ?? ""} ${isActive ? "active" : ""} ${mainClass} ${
                                    hideChildList ? "child-list-hide" : ""
                                }`}
                                onClick={(e: any) => {
                                    onClickItem(e);
                                }}
                                style={styles}
                            >
                                <MenuIcon className="panel-icon">{icon && <span className={icon}></span>}</MenuIcon>
                                {!props.hideTitle && <span className="mr-1">{title}</span>}

                                {showNotificationAlert && <span className="notification-alert">{notificationCount}</span>}
                            </MenuItemBase>
                        </HoverCard.Trigger>

                        {showHoverList && (
                            <HoverCard.Portal>
                                <HoverCardContentStyle>
                                    <HoverCard.Content
                                        side="right"
                                        align="start"
                                        className="HoverCardContent"
                                        style={{ width: "220px", padding: "10px" }}
                                        sideOffset={5}
                                    >
                                        <MenuItemChilds {...props} hideTitle={false} smallDesign />

                                        <HoverCard.Arrow className="HoverCardArrow" />
                                    </HoverCard.Content>
                                </HoverCardContentStyle>
                            </HoverCard.Portal>
                        )}
                    </HoverCard.Root>
                </ContainerItemsMenu>
            )}

            {showSubMenu && (
                <MenuChildListContainer className={props.link}>
                    <MenuItemChilds {...props} />
                </MenuChildListContainer>
            )}
            {showSeparator && <ItemSeparator />}
        </>
    );
}

interface MenuItemChildsProps extends VerticalMenuItemProps {
    smallDesign?: boolean;
}

export const getMenuEmbedContentId = (url: string) => {
    return `EMBED-CONTENT-${url.replaceAll("/", "").toUpperCase()}`;
};

const MenuItemChilds = (props: MenuItemChildsProps) => {
    const location = useLocation();
    const intl = useIntl();

    return (
        <>
            {props.childList &&
                props.childList.map((child, index) => {
                    let notification: MenuNotification | undefined = undefined;
                    if (props.notifications) {
                        notification = props.notifications?.find((x) => x.path === child.link);
                    }

                    return (
                        <VerticalMenuItem
                            key={index}
                            tourName={child.tourName}
                            pathGroup={{ pathname: location.pathname, activeMenu: location.pathname }}
                            title={checkAndGetMessage(intl, messages, child.title)}
                            icon={child.icon}
                            link={child.link}
                            hideTitle={props.hideTitle}
                            notifications={notification ? [notification] : undefined}
                            childList={child.childs}
                        />
                    );
                })}
        </>
    );
};

export const VerticalMenuStateComp = () => {
    const { menuState } = useMenuContext();
    if (menuState.menuElement) {
        return <div>{menuState.menuElement}</div>;
    }
    return null;
};

export const VerticalMenuEmbedContent = (props: { children: any }) => {
    const { setMenuState } = useMenuContext();
    const location = useLocation();

    useEffect(() => {
        setMenuState({ menuElement: props.children });
        return () => {
            setMenuState({ menuElement: undefined });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children, location.pathname]);

    return null;
};
