export const CloudImages = {
    wahioKds: "https://stowahioimagelibrary.blob.core.windows.net/common/wahio-kds.png",
    wahioDark: "https://storagemultimediapro.blob.core.windows.net/wahio/wahio-dark.png",
    feelingSorry: "https://storagemultimediapro.blob.core.windows.net/wahio/feeling-sorry.png",
    networks: "https://storagemultimediapro.blob.core.windows.net/wahio/users-connected-min.png",
    americanExpress: "https://storagemultimediapro.blob.core.windows.net/wahio/card-american.jpg",
    visa: "https://storagemultimediapro.blob.core.windows.net/wahio/card-visa.jpg",
    masterCard: "https://storagemultimediapro.blob.core.windows.net/wahio/card-mastercard.jpg",
    othersCards: "https://storagemultimediapro.blob.core.windows.net/wahio/card-others.jpg",
    audioMessage: "https://storagemultimediapro.blob.core.windows.net/audios/message-alert.mp3",
    audioNotification: "https://storagemultimediapro.blob.core.windows.net/audios/notification.mp3",
    visualDataReport: "https://storagemultimediapro.blob.core.windows.net/wahio/visual-data-min.png",
    certificate: "https://stowahioimagelibrary.blob.core.windows.net/common/certificate.png",
    medalGold: "https://stowahioimagelibrary.blob.core.windows.net/common/gold-medal.png",
    medalSilver: "https://stowahioimagelibrary.blob.core.windows.net/common/silver-medal.png",
    medalBronze: "https://stowahioimagelibrary.blob.core.windows.net/common/bronze-medal.png",
    barcodeMan: "https://stowahioimagelibrary.blob.core.windows.net/common/barcode-min.png",
    facebookIcon: "https://stowahioimagelibrary.blob.core.windows.net/common/facebook.png",
    googleIcon: "https://stowahioimagelibrary.blob.core.windows.net/common/google.png",
    cashRegister: "https://stowahioimagelibrary.blob.core.windows.net/common/cash-register.png",
    factura: "https://stowahioimagelibrary.blob.core.windows.net/common/factura.png",
    pdf: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/pdf.svg",
    imageIllustrator: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/adobe-illustrator.svg",
    excel: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/excel.svg",
    imageXd: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/xd.svg",
    imagePsd: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/psd.svg",
    imageSvg: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/svg.svg",
    imageText: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/text.svg",
    imageWord: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/word.svg",
    imageZip: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/zip.svg",
    imageVideo: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/video.svg",
    imageMp3: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/mp3.svg",
    imagePowerPoint: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/powerpoint.svg",
    imageCsv: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/csv.svg",
    imageTxt: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/txt.svg",
    imageXml: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/xml.svg",
    jsonImage: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/javascript.svg",
    imageFile: "https://stowahioimagelibrary.blob.core.windows.net/wahio-cloud/file.svg",
    confusedMan: "https://stowahioimagelibrary.blob.core.windows.net/common/notfoundresult.svg",
    pcGirl: "https://stowahioimagelibrary.blob.core.windows.net/common/pcgirl.svg",
    boxCompose: "https://stowahioimagelibrary.blob.core.windows.net/common/box-compose.png",
    emojiBackground: "https://stowahioimagelibrary.blob.core.windows.net/common/emoji-background-min.png",
    locationEmpty: "https://stowahioimagelibrary.blob.core.windows.net/common/locationempty-min.png",
    lockPayment: "https://stowahioimagelibrary.blob.core.windows.net/common/lock-payment.png",
    openFolder: "https://stowahioimagelibrary.blob.core.windows.net/common/open-folder.png",
    defaultImage: "https://stowahioimagelibrary.blob.core.windows.net/common/picture-image.png",
    defaultImageVideo: "https://stowahioimagelibrary.blob.core.windows.net/common/picture-video.png",
    restaurant: "https://stowahioimagelibrary.blob.core.windows.net/common/restaurant.png",
    wahioLogo100: "https://stowahioimagelibrary.blob.core.windows.net/common/wahio-logo-100.png",
    warehouseMovement: "https://stowahioimagelibrary.blob.core.windows.net/common/warehousemovement.png",
    warehouseView: "https://stowahioimagelibrary.blob.core.windows.net/common/warehouseview-min.png",

    mapGoogle: "https://stowahioimagelibrary.blob.core.windows.net/map/map-google-2.jpeg",
    mapWaze: "https://stowahioimagelibrary.blob.core.windows.net/map/map-waze.png",
    mapApple: "https://stowahioimagelibrary.blob.core.windows.net/map/map-apple.png",

    poweredByStripe: "https://stowahioimagelibrary.blob.core.windows.net/common/powered-stripe.png",
};

export const MapIcons = [CloudImages.mapGoogle, CloudImages.mapWaze, CloudImages.mapApple];
export const MedalsImages = [CloudImages.medalGold, CloudImages.medalSilver, CloudImages.medalBronze];
