import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IContactAddress } from "../../../api/models/contact";
import { useUserContext } from "../../../store/contexts/UserContext";
import { CitySelector } from "../../GeoLocation/CitySelector";
import { CountrySelector } from "../../GeoLocation/CountrySelector";
import { CountryStateSelector } from "../../GeoLocation/CountryStatesSelector";
import { DefaultInput, Flex, GridTemplate } from "../../_controls";
import messages from "./messages";
import { AddressContainerFormView, InputGroup } from "./styled";

interface ICustomerAddressFormProps {
    item: IContactAddress;
    onChange: (item: IContactAddress) => void;
}

export default function ContactAddressForm(props: ICustomerAddressFormProps) {
    const intl = useIntl();
    const { userState } = useUserContext();
    const { item } = props;

    const [countryCode, setCountryCode] = useState(item.countryCode ?? userState.user?.account?.clientSettings?.countryCode ?? "CO");

    const onChange = (e: any) => {
        e.preventDefault();
        props.onChange({ ...item, [e.target.name]: e.target.value });
    };

    return (
        <AddressContainerFormView>
            <Flex gap15>
                <InputGroup>
                    <span className="label">{intl.formatMessage(messages.contactNameTwo)}</span>
                    <DefaultInput
                        required
                        rounded
                        type="text"
                        name="contactName"
                        onChange={onChange}
                        value={item.contactName}
                        placeholder={intl.formatMessage(messages.contactName)}
                    />
                </InputGroup>
                <InputGroup>
                    <span className="label">{intl.formatMessage(messages.phoneNumberTwo)}</span>
                    <DefaultInput
                        rounded
                        name="phoneNumber"
                        onChange={onChange}
                        value={item.phoneNumber}
                        placeholder={intl.formatMessage(messages.phoneNumber)}
                    />
                </InputGroup>
            </Flex>

            <GridTemplate gap={15} size={170}>
                <CountrySelector
                    countryCode={countryCode}
                    onChange={(value) => {
                        setCountryCode(value.iso2);
                        props.onChange({ ...item, state: "", stateCode: "", city: "", countryCode: value.iso2, country: value.name });
                    }}
                />
                <CountryStateSelector
                    countryCode={countryCode}
                    defaultValue={item.state}
                    onChangeState={(value) =>
                        props.onChange({ ...item, state: value?.name ?? "", stateCode: value?.state_code ?? "", city: "" })
                    }
                    onCreateValue={(value) => props.onChange({ ...item, state: value, stateCode: "", city: "" })}
                    w100
                />
                <CitySelector
                    countryCode={countryCode}
                    stateCode={item.stateCode}
                    defaultValue={item.city}
                    onChangeState={(value) => props.onChange({ ...item, city: value?.name ?? "" })}
                    onCreateValue={(value) => props.onChange({ ...item, city: value })}
                    w100
                />
            </GridTemplate>

            <Flex gap15>
                <InputGroup>
                    <span className="label">{intl.formatMessage(messages.addressThree)}</span>
                    <DefaultInput
                        rounded
                        required
                        name="description"
                        onChange={onChange}
                        value={item.description}
                        placeholder={intl.formatMessage(messages.addressFour)}
                    />
                </InputGroup>
                <InputGroup>
                    <span className="label">{intl.formatMessage(messages.additionalData)}</span>
                    <DefaultInput
                        rounded
                        name="additionalInformation"
                        onChange={onChange}
                        value={item.additionalInformation}
                        placeholder={intl.formatMessage(messages.towerFloor)}
                    />
                </InputGroup>
            </Flex>
        </AddressContainerFormView>
    );
}
