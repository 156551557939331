import React, { createContext, useContext, useEffect, useState } from "react";
import { LocalStoreKeys, getStoredData, saveData } from "..";
import { OrderLocalStorage } from "../../api/models/order";
import { OrderMetadata } from "../../api/models/orderMetadata";
import { EcommerceSocketVisitResponse } from "../../api/socket/models";

interface IContextProps {
    orderLocalStorageState: OrderLocalStorage;
    setOrderLocalStorageState: (value: OrderLocalStorage) => void;
}

export const OrderLocalStorageContext = createContext({} as IContextProps);

export class InitStateBase implements OrderLocalStorage {
    queueCount: number = 0;
    orderTempCount: number = 0;
    unreadComments: number = 0;
    orderMetadata?: OrderMetadata;
    ecommerceVisit?: EcommerceSocketVisitResponse;
}

const OrderLocalStorageContextProvider = (props: any) => {
    const [orderLocalStorageState, setOrderLocalStorageState] = useState<OrderLocalStorage>(
        getStoredData<OrderLocalStorage>(new InitStateBase(), LocalStoreKeys.localInfoOrder)
    );
    useEffect(() => {
        saveData(LocalStoreKeys.localInfoOrder, orderLocalStorageState);
    }, [orderLocalStorageState]);

    return (
        <OrderLocalStorageContext.Provider value={{ orderLocalStorageState, setOrderLocalStorageState }}>
            {props.children}
        </OrderLocalStorageContext.Provider>
    );
};

export const useOrderDataSummaryContext = () => {
    return useContext(OrderLocalStorageContext);
};

export default OrderLocalStorageContextProvider;
