import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { OrderChangeProps } from "..";
import { IOrderChannel } from "../../../../../api/models/orderChannel";
import { OrderChannelContext } from "../../../../../store/contexts/OrderChannelContext";
import { getSelectOption } from "../../../../../utils/select";
import { SelectBody } from "../../../../Select/styled";
import OrderChannelFormModal from "../../../OrderChannelForm/OrderChannelFormModal";

interface OrderChannelSelectProps extends OrderChangeProps {
    showMargin?: boolean;
}

export const OrderChannelSelect = (props: OrderChannelSelectProps) => {
    const { orderChannelState, orderChannelActions } = useContext(OrderChannelContext);
    const [channelNameForCreation, setChannelNameForCreation] = useState<string | undefined>(undefined);
    const { order } = props;

    useEffect(() => {
        if (orderChannelState.items.length === 0) orderChannelActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateChannelFromSelect = (channelId?: string) => {
        let channel: IOrderChannel | undefined = undefined;
        if (channelId) {
            channel = orderChannelState.items.find((x) => x.id === channelId);
        }
        updateChannelSale(channel);
    };

    const updateChannelSale = (channel?: IOrderChannel) => {
        props.onOrderChange({
            ...order,
            orderChannel: channel,
        });
    };

    return (
        <>
            {channelNameForCreation && (
                <OrderChannelFormModal
                    channelName={channelNameForCreation}
                    onCancel={() => {}}
                    onCreate={(channel) => {
                        updateChannelSale(channel);
                    }}
                    show={!!channelNameForCreation}
                    setShow={() => setChannelNameForCreation(undefined)}
                />
            )}
            <SelectBody>
                <span className="label">Canal</span>
                <CreatableSelect
                    placeholder="Canal de Venta"
                    className={` ${order.orderChannel ? "item-active" : ""}`}
                    classNamePrefix="select"
                    defaultValue={
                        order.orderChannel
                            ? getSelectOption({ id: order.orderChannel.id ?? "NA", value: order.orderChannel.name })
                            : undefined
                    }
                    isDisabled={false}
                    onChange={(value) => updateChannelFromSelect(value?.id)}
                    onCreateOption={setChannelNameForCreation}
                    isLoading={orderChannelState.isFetching}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="channel"
                    options={orderChannelState.items.map((item) => getSelectOption({ id: item.id ?? "", value: item.name }))}
                />
            </SelectBody>
        </>
    );
};

export default OrderChannelSelect;
