import { joinUrl } from "../utils";
import { API_GATEWAY_ENDPOINT } from "./appConfig";
import { IPaginationRequest } from "./models";
import { CompanyImageTypes } from "./models/accountUser";
import { DeliveryStatus } from "./models/deliveryOrder";
import { ProductEcommerceStatusInput } from "./models/product";

const api = API_GATEWAY_ENDPOINT;
const localOrderApi = joinUrl(api, "localorder"); //"http://localhost:7071/api";

const getPageRoute = (req: IPaginationRequest) => {
    return `page/${req.currentPage}/limit/${req.pageSize}`;
};

export const loginEndpoint = {
    login: joinUrl(api, `login/api/login`),
    socialLogin: joinUrl(api, `login/api/login/social`),
};

export const customerEndpoint = {
    get: {
        customersByAccount: joinUrl(api, "customer/api/customers/account/"),
        customer: joinUrl(api, "customer/api/customers/"),
    },
    post: {
        customer: joinUrl(api, `customer/api/customers`),
    },
};

export const messageEndpoint = {
    get: {
        messagesStatusByUser: (id: string) => joinUrl(api, `message/messages/messagesstatus/${id}`),
        messagesGroupStatusByUser: (group: string, userId: string) =>
            joinUrl(api, `message/messagegroups/messagesstatus/${group}/${userId}`),
    },
    post: {
        messageQuerySearch: joinUrl(api, "message/messages/search"),
        messageGroupQuerySearch: joinUrl(api, "message/messagegroups/search"),
        createMessage: joinUrl(api, "message/messages/createmessage"),
        createGroupMessage: joinUrl(api, "message/messagegroups/createmessage"),
    },
    put: {
        markMessagesAsRead: (userGroup: string, toUserId: string) =>
            joinUrl(api, `message/messages/markmessagesasread/${userGroup}/${toUserId}`),
    },
};

export const localOrderEndpoint = {
    get: {
        getOrderCommentsUnread: (organizationId: string, userId: string) =>
            joinUrl(localOrderApi, `ordercomments/unreadcount/${organizationId}/${userId}`),
        checkOrderPaymentAndUpdate: (orderId: string) => joinUrl(localOrderApi, `orders/payments/checkstatus${orderId}`),
        orderById: (orderId: string) => joinUrl(localOrderApi, `orders/${orderId}`),
        sellersByAccount: (accountId: string) => joinUrl(localOrderApi, `sellers/account/${accountId}`),
        channelsByAccount: (accountId: string) => joinUrl(localOrderApi, `orderchannels/account/${accountId}`),
        deliverySummaryByAccount: (accountId: string) => joinUrl(localOrderApi, `deliveryorders/summary/${accountId}`),
        loungesByAccount: (accountId: string) => joinUrl(localOrderApi, `lounges/account/${accountId}`),
        loungeById: (id: string) => joinUrl(localOrderApi, `lounges/${id}`),
        orderTableById: (id: string) => joinUrl(localOrderApi, `ordertables/${id}`),
        orderTableByOrder: (orderId: string) => joinUrl(localOrderApi, `ordertables/order/${orderId}`),
        customersByAccount: (accountId: string) => joinUrl(localOrderApi, `customers/account/${accountId}`),
        customersById: (id: string) => joinUrl(localOrderApi, `customers/${id}`),
        customersDebts: (accountId: string) => joinUrl(localOrderApi, `orders/customers/alldebts/${accountId}`),
        contactLabelsByAccount: (accountId: string) => joinUrl(localOrderApi, `customers/contactlabels/account/${accountId}`),
        customerSummaryById: (id: string) => joinUrl(localOrderApi, `orders/customers/summary/${id}`),
        invoiceDesignsByAccount: (accountId: string) => joinUrl(localOrderApi, `invoicedesigns/account/${accountId}`),
        accountingCustomerOrders: (customerId: string) => joinUrl(localOrderApi, `accounting/customerorders/${customerId}`),
        invoiceNumerationByAccount: (accountId: string) => joinUrl(localOrderApi, `orderinvoices/numerations/account/${accountId}`),
        orderByNumber: (number: string, accountId: string) =>
            joinUrl(localOrderApi, `orders/numberorinvoice/${number}/account/${accountId}`),
        orderReturnById: (id: string) => joinUrl(localOrderApi, `orderreturns/${id}`),
        orderTempById: (id: string, accountId: string) => joinUrl(localOrderApi, `orders/temp/account/${accountId}/order/${id}`),
        orderMetadata: (accountId: string) => joinUrl(localOrderApi, `orderMetadata/${accountId}`),
    },
    post: {
        orderCountByDateRange: joinUrl(localOrderApi, `orders/orderCountByDateRange`),
        orderChannel: joinUrl(localOrderApi, "orderchannels"),
        orderTable: joinUrl(localOrderApi, "ordertables"),
        seller: joinUrl(localOrderApi, "sellers"),
        loungeAll: joinUrl(localOrderApi, "lounges/update-all"),
        customer: joinUrl(localOrderApi, "customers"),
        contactLabel: joinUrl(localOrderApi, "customers/contactlabels"),
        contactDeleteList: joinUrl(localOrderApi, "contacts/multi/delete"),
        contactLabelContactMultiCreate: joinUrl(localOrderApi, "contacts/contactlabels/contact/multi/create"),
        contactLabelContactMultiDelete: joinUrl(localOrderApi, "contacts/contactlabels/contact/multi/delete"),
        contactSummarySellers: joinUrl(localOrderApi, "contacts/summary/sellers"),
        customerContactLabel: joinUrl(localOrderApi, "customers/customercontactlabels"),
        customerImport: joinUrl(localOrderApi, "customers/importcontacts"),
        singlePayment: joinUrl(localOrderApi, "payments"),
        paySelectedOrders: joinUrl(localOrderApi, "payments/payselectedorders"),
        orderUpdateCreateAll: joinUrl(localOrderApi, "orders/updateall"),
        orderTableMovement: joinUrl(localOrderApi, "ordertables/movetable"),
        orderItemLogsSearch: joinUrl(localOrderApi, "orders/itemlogssearch"),
        getOrderQuerySearch: joinUrl(localOrderApi, "orders/querysearch"),
        getOrderGroupQuerySearch: joinUrl(localOrderApi, "orders/group/querysearch"),
        getOrderCountByStatus: joinUrl(localOrderApi, "orders/ordercountbystatus"),
        getOrdersCountByBillingRange: joinUrl(localOrderApi, "orders/orderscountbyrange"),
        getDeliveryOrderQuerySearch: joinUrl(localOrderApi, "deliveryorders/querysearch"),
        getDeliveryOrderQuerySearchAllStatus: joinUrl(localOrderApi, "deliveryorders/allstatus/querysearch"),
        getOrderShipmentGroupQuerySearch: joinUrl(localOrderApi, "orders/querysearch/groupbydeliverystatus"),
        accountingQuerySearch: joinUrl(localOrderApi, "accounting/orders/querysearch"),
        accountingPaymentQuerySearch: joinUrl(localOrderApi, "accounting/orders/payments/querysearch"),
        invoiceDesign: joinUrl(localOrderApi, `invoicedesigns/`),
        deleteOrder: joinUrl(localOrderApi, `orders/deleteorder`),
        invoiceNumeration: joinUrl(localOrderApi, `orderinvoices/numeration`),
        createOrderInvoice: joinUrl(localOrderApi, `orders/createinvoice`),
        customerQuerySearch: joinUrl(localOrderApi, `customers/querysearch`),
        orderComment: joinUrl(localOrderApi, `ordercomments`),
        orderReturnQuerySearch: joinUrl(localOrderApi, `orderreturns/querysearch`),
        orderReturnCreate: joinUrl(localOrderApi, `orderreturns`),
        getProductReservedCount: joinUrl(localOrderApi, `orders/counter/productreserved`),
        productCountByDeliveryStatus: joinUrl(localOrderApi, `orders/counter/productbydeliverystatus`),
        getDeliveryOrdersByProductAndStatus: joinUrl(localOrderApi, `orders/deliveryorders/productandstatus`),
        getDeliveryOrdersReservedStock: joinUrl(localOrderApi, `orders/deliveryorders/productreserved`),
        postGenerateDigitalInvoice: (id: string) => joinUrl(localOrderApi, `orders/createdigitalinvoice/${id}`),
        createMultipleInvoices: joinUrl(localOrderApi, `orders/createmultipleinvoices`),
        accountingCustomerOrders: joinUrl(localOrderApi, `accounting/customerorders/querysearch`),
        orderCommentQuerySearch: joinUrl(localOrderApi, `ordercomments/querysearch`),
        orderSearchBySerial: joinUrl(localOrderApi, "orders/bySerialCode"),
        orderTempQuerySearch: joinUrl(localOrderApi, "orders/temp/querySearch"),
        orderNotificationCount: joinUrl(localOrderApi, "orders/notifications/count"),

        reportOrderOrganizationGroup: joinUrl(localOrderApi, "reports/orders/organizationGroup"),
        reportOrderCategoryGroup: joinUrl(localOrderApi, "reports/orderItems/categoryGroup"),
        reportOrderTaxGroup: joinUrl(localOrderApi, "reports/orderItems/taxGroup"),
        reportOrderPaymentMethodGroup: joinUrl(localOrderApi, "reports/orders/paymentMethod"),

        shipmentsQuerySearch: joinUrl(localOrderApi, "shipments/querySearch"),
        shipmentsStatusGroup: joinUrl(localOrderApi, "shipments/status/group"),
    },
    put: {
        setDeliveryOrderToArchived: (organizationId: string) =>
            joinUrl(localOrderApi, `deliveryorders/archive/organization/${organizationId}`),
        deliveryOrderLabel: (deliveryOrderId: string) => joinUrl(localOrderApi, `deliveryorders/updatelabel/${deliveryOrderId}`),
        seller: joinUrl(localOrderApi, "sellers"),
        customerAll: joinUrl(localOrderApi, "customers/update-all"),
        customer: joinUrl(localOrderApi, "customers"),
        updateDeliveryStatus: (deliveryOrderId: string, status: DeliveryStatus) =>
            joinUrl(localOrderApi, `deliveryorders/updatestatus/${deliveryOrderId}/status/${status}`),

        shipmentsUpdateStatus: (id: string, status: DeliveryStatus) =>
            joinUrl(localOrderApi, `shipments/updateStatus/${id}/status/${status}`),
        orderPickingSetBincode: joinUrl(localOrderApi, `orderpickings/setbincode`),
        orderPickingSetCompleted: joinUrl(localOrderApi, `orderpickings/setcompleted`),
        orderPickingUpdateBin: joinUrl(localOrderApi, `orderpickings/updatebin`),
        invoiceNumeration: joinUrl(localOrderApi, `orderinvoices/numeration`),
        orderStatus: joinUrl(localOrderApi, `orders/updatestatus`),
        deliveryOrder: joinUrl(localOrderApi, `deliveryorders`),
    },
    patch: {
        deliveryOrder: (id: string) => joinUrl(localOrderApi, `deliveryorders/${id}`),
    },
    delete: {
        order: (id: string) => joinUrl(localOrderApi, `orders/${id}`),
        invoiceNumeration: (id: string) => joinUrl(localOrderApi, `orderinvoices/numerations/${id}`),
        contactLabel: (id: string) => joinUrl(localOrderApi, `customers/contactlabels/${id}`),
        orderComment: (id: string) => joinUrl(localOrderApi, `ordercomments/${id}`),
        lounge: (id: string) => joinUrl(localOrderApi, `lounges/${id}`),
        channel: (id: string) => joinUrl(localOrderApi, `orderchannels/${id}`),
        orderTempById: (id: string, accountId: string) => joinUrl(localOrderApi, `orders/temp/account/${accountId}/order/${id}`),
    },
};

export const productEndpoint = {
    get: {
        productIdContent: (id: string) => joinUrl(api, `product/productAi/content/${id}`),
        warehouseProductCheckParentStock: (parentId: string, warehouseId: string) =>
            joinUrl(api, `product/warehouseproducts/checkParentStock/parent/${parentId}/warehouse/${warehouseId}`),
        warehouseCostSummary: (id: string) => joinUrl(api, `product/warehouses/costsummary/${id}/`),
        product: (id: string) => joinUrl(api, `product/products/${id}/`),
        warehouseMovement: (id: string) => joinUrl(api, `product/warehousemovements/${id}/`),
        productListExcelByAccount: (accountId: string) => joinUrl(api, `product/products/download/excel/account/${accountId}/`),
        productListExcelByWarehouse: (warehouseId: string) => joinUrl(api, `product/products/download/excel/warehouse/${warehouseId}/`),
        ecommerceByAccount: (accountId: string) => joinUrl(api, `product/productcatalog/account/${accountId}/`),
        ecommerceById: (id: string) => joinUrl(api, `product/productcatalog/${id}`),
        validateExistCatalogName: (name: string, id?: string) =>
            joinUrl(api, `product/productcatalog/checkname/${name}/productcatalog/${id}/`),
        warehouseLocationsByWarehouse: (warehouseId: string) => joinUrl(api, `product/warehouselocations/warehouse/${warehouseId}`),
        warehousesByAccount: (accountId: string) => joinUrl(api, `product/warehouses/account/${accountId}`),
        warehousesMovementsByAccount: (accountId: string) => joinUrl(api, `product/warehousemovements/account/${accountId}`),
        warehouseProductById: (warehouseProductById: string) => joinUrl(api, `product/warehouseproducts/${warehouseProductById}`),
        warehouseProductByBarcode: (accountId: string, warehouseId: string, barcode: string) =>
            joinUrl(api, `product/warehouseproducts/account/${accountId}/warehouse/${warehouseId}/barcode/${barcode}`),
        productByBarcode: (accountId: string, barcode: string) => joinUrl(api, `product/products/account/${accountId}/barcode/${barcode}`),
        companiesByAccount: (accountId: string) => joinUrl(api, `product/companies/account/${accountId}`),
        warehouseId: (id: string) => joinUrl(api, `product/warehouses/${id}`),
        getParentProducts: (productId: string) => joinUrl(api, `product/products/parents/${productId}/`),

        categories: joinUrl(api, "product/categories"),
        customCategories: (accountId: string) => joinUrl(api, `product/categories/account/${accountId}`),
        inventoryAdjustmentByAccount: (accountId: string) => joinUrl(api, `product/inventoryadjustments/account/${accountId}`),
        inventoryAdjustmentDetailByCode: (code: string) => joinUrl(api, `product/inventoryadjustments/details/code/${code}`),
        taxesByAccount: (accountId: string) => joinUrl(api, `product/taxes/account/${accountId}`),
        discountsByAccount: (accountId: string) => joinUrl(api, `product/discounts/account/${accountId}`),
        discountsById: (id: string) => joinUrl(api, `product/discounts/${id}`),
        productsByDiscount: (discountId: string) => joinUrl(api, `product/discounts/products/${discountId}`),
        catalogsByDiscount: (discountId: string) => joinUrl(api, `product/discounts/catalogs/${discountId}`),
        productsByTax: (taxId: string) => joinUrl(api, `product/products/byTax/${taxId}`),
        inventoryAdjustmentById: (id: string) => joinUrl(api, `product/inventoryadjustments/${id}`),
        inventoryAdjustmentFinalize: (id: string) => joinUrl(api, `product/inventoryadjustments/finalize/${id}`),
        warehouseProductSummaryByProduct: (id: string) => joinUrl(api, `product/productsummary/warehouseproduct/${id}`),
        warehouseProductLocationSummaryByProduct: (id: string) => joinUrl(api, `product/productsummary/warehouseproductlocation/${id}`),
        inventoryAdjustmentDetailsByProduct: joinUrl(api, `product/inventoryadjustments/details/productsearch`),
        inventoryLocationsByAccount: (accountId: string) => joinUrl(api, `product/inventoryLocations/account/${accountId}`),
        inventoryDetailsProductCount: (id: string) => joinUrl(api, `product/inventoryAdjustment/details/product/count/${id}`),
    },
    post: {
        productDiscount: joinUrl(api, `product/discounts/productDiscount`),
        catalogDiscount: joinUrl(api, `product/discounts/productCatalog`),
        discountProductBulk: joinUrl(api, `product/discounts/product/bulk`),
        productListingBasic: joinUrl(api, `product/products/listing/basic`),
        warehouseProductSummary: joinUrl(api, `product/productsummary`),
        warehouseMovement: joinUrl(api, "product/warehousemovements"),
        warehouseMovementQuerysearch: joinUrl(api, "product/warehousemovements/querysearch"),
        warehouseProductLocationMovement: joinUrl(api, "product/warehouselocations/productmovement"),
        warehouseProductLocationAdjustment: joinUrl(api, "product/warehouselocations/stockadjustment"),
        warehouseProductLocationAddProduct: joinUrl(api, "product/warehouselocations/addproduct"),
        productSearchQuery: joinUrl(api, "product/products/account/querysearch"),
        productLogSearchQuery: joinUrl(api, "product/productlogs/querysearch"),
        warehouseProductSearchQuery: joinUrl(api, "product/warehouseproducts/querysearch"),
        warehouseProductLocationSearchQuery: joinUrl(api, "product/warehouselocations/products/querysearch"),
        product: joinUrl(api, "product/products/updateall"),
        productImportLine: joinUrl(api, "product/products/import/line"),
        warehouseProductCheckStockSearchQuery: joinUrl(api, "product/warehouseproducts/checkstock/list"),
        productLocationValidation: joinUrl(api, "product/warehouselocations/productlocationvalidation"),
        warehouse: joinUrl(api, "product/warehouses"),
        warehouseLocationMultipleCreation: joinUrl(api, "product/warehouselocations/multiplecreation"),
        warehouseLocationQuerySearch: joinUrl(api, `product/warehouselocations/querysearch`),
        warehouseLocationDeleteMultiple: joinUrl(api, `product/warehouselocations/deletemultiple`),
        ecommerce: joinUrl(api, "product/productCatalog"),
        categories: joinUrl(api, "product/categories"),
        customCategory: joinUrl(api, "product/categories/custom"),
        customCategoryUpdateImage: joinUrl(api, "product/categories/custom/updateimage"),
        productImagesCreateList: joinUrl(api, "product/productimages/uploadlist"),
        deleteProductImagesList: joinUrl(api, "product/productimages/removelist"),
        changeStatus: joinUrl(api, "product/products/changestatus"),
        inventoryAdjustment: joinUrl(api, "product/inventoryadjustments"),
        inventoryAdjustmentDetailsQuerySearch: joinUrl(api, "product/inventoryadjustments/querysearch"),
        inventoryAdjustmentQuerySearch: joinUrl(api, "product/inventoryadjustments/adjustment/querysearch"),
        inventoryAdjustmentDetail: joinUrl(api, "product/inventoryadjustments/detail"),
        inventoryLocation: joinUrl(api, "product/inventoryLocations/createUpdate"),
        inventoryProductAdjustment: joinUrl(api, "product/inventoryadjustments/productadjustment"),
        tax: joinUrl(api, "product/taxes"),
        discount: joinUrl(api, "product/discounts"),
        importProduct: joinUrl(api, "product/products/importproducts"),
        productImportGetOrderProducts: joinUrl(api, "product/products/importproducts/orderitems"),
        customCategorySearch: joinUrl(api, "product/categories/custom/querysearch"),
        customCategorySorted: joinUrl(api, "product/categories/custom/sorted"),
        warehouseLocationByProduct: joinUrl(api, "product/warehouselocations/querysearchproduct"),
        productViewsByRange: joinUrl(api, "product/productviews/counterbyrange"),
        productViewsInGroup: joinUrl(api, "product/productviews/counteringroup"),
        productSearchBySerial: joinUrl(api, "product/products/bySerialCode"),
        taxProductBulk: joinUrl(api, `product/products/tax/bulk`),
        deleteInventoryAdjustmentDetailList: joinUrl(api, `product/inventoryadjustments/detail/deleteList`),
    },
    patch: {
        customCategory: (id: string) => joinUrl(api, `product/categories/custom/${id}`),
        productPartialUpdateMultiple: (accountId: string) => joinUrl(api, `product/products/bulk/account/${accountId}`),
        productPartialUpdate: (id: string) => joinUrl(api, `product/products/${id}`),
        productVariantPartialUpdate: (id: string) => joinUrl(api, `product/products/variant/${id}`),
    },
    put: {
        productEcommerceStatus: (data: ProductEcommerceStatusInput) =>
            joinUrl(api, `product/productCatalog/${data.catalogId}/product/${data.productId}/status/${data.status}`),
        customCategoryBulkIndex: joinUrl(api, `product/categories/custom/bulk/indexUpdate`),
        categoryName: joinUrl(api, `product/api/categories/updatename`),
        warehouseMovementDetails: joinUrl(api, `product/warehousemovements/details`),
        updateReservedStock: joinUrl(api, `product/inventoryadjustments/updatereservedstock`),
        updateLocationsColor: joinUrl(api, `product/warehouselocations/updatecolors`),
        updateLocationName: joinUrl(api, `product/warehouselocations/updatename`),
        updateProductTax: joinUrl(api, `product/product/updateproductTax`),
        updateLocationPriority: joinUrl(api, `product/warehouselocations/updatepriority`),
        productAiContent: joinUrl(api, `product/productAi/content/update`),
        productSearchEngine: joinUrl(api, `product/products/v2/searchEngine/update`),
    },
    delete: {
        ecommerceId: (catalogId: string) => joinUrl(api, `product/productCatalog/${catalogId}`),
        category: joinUrl(api, `product/api/categories/`),
        customCategory: (id: string) => joinUrl(api, `product/categories/custom/${id}`),
        warehouse: (id: string) => joinUrl(api, `product/warehouses/${id}`),
        warehouseLocationMultple: joinUrl(api, `product/warehouselocations/deletemultiple`),
        inventoryAdjustmentById: (id: string) => joinUrl(api, `product/inventoryadjustments/${id}`),
        taxById: (id: string) => joinUrl(api, `product/taxes/${id}`),
        productTax: (accountId: string, id: string) => joinUrl(api, `product/product/tax/${accountId}/${id}`),
        discountsById: (id: string) => joinUrl(api, `product/discounts/${id}`),
        productDiscount: (id: string) => joinUrl(api, `product/discounts/products/${id}`),
        catalogDiscount: (id: string) => joinUrl(api, `product/discounts/catalog/${id}`),
        warehouseMovement: (id: string) => joinUrl(api, `product/warehousemovements/${id}/`),
        inventoryLocation: (id: string) => joinUrl(api, `product/inventoryLocations/${id}/`),
        inventoryAdjustmentDetail: (id: string) => joinUrl(api, `product/inventoryadjustments/detail/${id}/`),
    },
};

export const productV2Endpoint = {
    post: {
        productSearch: joinUrl(api, "product/products/v2/search/warehouse/product"),
        productSearchWarehouse: joinUrl(api, "product/products/v2/search/warehouse"),
        productSearchWarehouseFilters: joinUrl(api, "product/products/v2/search/warehouse/filters"),
        productSearchWarehouseSuggestions: joinUrl(api, "product/products/v2/search/warehouse/suggestions"),
        productSearchWarehouseCategories: joinUrl(api, "product/products/v2/search/warehouse/categories"),
    },
};

export const purchaseEndpoint = {
    get: {
        purchaseById: (id: string) => joinUrl(api, `purchase/purchases/all/${id}`),
        supplier: (id: string) => joinUrl(api, `purchase/suppliers/${id}`),
        suppliersByAccount: (accountId: string) => joinUrl(api, `purchase/suppliers/account/${accountId}`),
        purchasesPages: (req: IPaginationRequest) => joinUrl(api, `purchase/purchases/` + getPageRoute(req)),
        accountingCustomerOrders: (customerId: string) => joinUrl(api, `purchase/accounting/customerorders/${customerId}`),
    },
    post: {
        supplier: joinUrl(api, `purchase/suppliers`),
        purchaseAll: joinUrl(api, `purchase/purchases/updateall`),
        singlePayment: joinUrl(api, `purchase/payments`),
        paySelectedOrders: joinUrl(api, "purchase/payments/payselectedorders"),
        purchaseSearchQuery: joinUrl(api, `purchase/purchases/querysearch`),
        deleteOrder: joinUrl(api, `purchase/purchases/deleteorder`),
        purchaseComment: joinUrl(api, `purchase/purchasecomments`),
        purchaseReturn: joinUrl(api, `purchase/purchasereturns`),
        accountingQuerySearch: joinUrl(api, "purchase/accounting/orders/querysearch"),
        accountingPaymentQuerySearch: joinUrl(api, "purchase/accounting/orders/payments/querysearch"),
        accountingCustomerOrders: joinUrl(api, "purchase/accounting/orders/filter/querysearch"),
        purchaseSearchBySerial: joinUrl(api, "purchase/purchases/bySerialCode"),
        productCostByPurchases: joinUrl(api, "purchase/purchases/productCost"),
        productCostByPurchasesMultiple: joinUrl(api, "purchase/purchases/productCost/multiple"),
    },
    put: {
        supplierAll: joinUrl(api, "purchase/suppliers/update-all"),
    },
    delete: {
        purchaseComment: (id: string) => joinUrl(api, `purchase/purchasecomments/${id}`),
    },
};
export const accountingEndpoint = {
    post: {
        accountingSummaryByAccount: joinUrl(api, `accounting/accounting/summary`),
    },
};
export const accountEndpoint = {
    get: {
        accountApiKeyByAccount: (accountId: string) => joinUrl(api, `account/accountApi/account/${accountId}`),
        cashRegisterEntryByReference: (id: string) => joinUrl(api, `account/cashRegisters/entries/reference/${id}`),
        cashRegisterEntryByReferencePayment: (id: string) => joinUrl(api, `account/cashRegisters/entries/referencePayment/${id}`),
        cashRegisterRecalculate: (id: string) => joinUrl(api, `account/cashRegisters/recalculate/${id}`),
        billingConfigData: joinUrl(api, `account/accountbilling/billingconfig`),
        wahioCloudSummary: (accountId: string) => joinUrl(api, `account/wahiocloud/summary/account/${accountId}`),
        accountBillingNextDate: (accountId: string) => joinUrl(api, `account/accountbilling/nextdate/${accountId}`),
        accountBillingNextDatePlanChange: (accountId: string, newCostPerUser: number) =>
            joinUrl(api, `account/accountbilling/nextdate/${accountId}/${newCostPerUser}`),
        accountBillingDueInvoices: (accountId: string) => joinUrl(api, `account/accountbilling/invoices/due/${accountId}`),
        accountUserOrganizations: (userId: string) => joinUrl(api, `account/accountusers/organizations/${userId}`),
        sendConfirmationEmail: (userId: string) => joinUrl(api, `account/accountusers/confirmemail/sendnotification/${userId}`),
        expenseById: (id: string) => joinUrl(api, `account/expenses/${id}`),
        expenseCategoriesByAccount: (accountId: string) => joinUrl(api, `account/expensecategories/account/${accountId}`),
        accountUserWithAccount: (accountuserId: string) => joinUrl(api, `account/accountusers/useraccount/${accountuserId}`),
        accountUserById: (accountuserId: string) => joinUrl(api, `account/accountusers/${accountuserId}`),
        accounts: joinUrl(api, `account/accounts`),
        cashRegisters: (accountId: string) => joinUrl(api, `account/cashregisters/account/${accountId}`),
        cashRegisterEntryCategories: (accountId: string) => joinUrl(api, `account/cashRegisters/entry/categories/${accountId}`),

        cashRegisterById: (id: string) => joinUrl(api, `account/cashregisters/${id}`),
        cashRegisterLogById: (id: string) => joinUrl(api, `account/cashregisters/logs/${id}`),
        cashRegisterLogsByCash: (cashRegisterId: string) => joinUrl(api, `account/cashregisters/logs/cashregister/${cashRegisterId}`),
        cashRegisterLogActive: (cashRegisterId: string) => joinUrl(api, `account/cashregisters/logs/active/${cashRegisterId}`),
        account: joinUrl(api, `account/accounts`),
        exceptions: joinUrl(api, `account/exceptions`),
        organizations: joinUrl(api, `account/organizations`),
        organizationsByUser: (accountuserId: string) => joinUrl(api, `account/organizations/user/${accountuserId}`),
        organizationsByAccount: (accountId: string) => joinUrl(api, `account/organizations/account/${accountId}`),
        organizationsById: (id: string) => joinUrl(api, `account/organizations/${id}`),
        warehouseByOrganization: (organizationId: string) =>
            joinUrl(api, `account/organizations/warehouses/organization/${organizationId}`),
        accountUsersByAccount: (accountId: string) => joinUrl(api, `account/accountusers/account/${accountId}`),
        accountPermissions: (accountId: string) => joinUrl(api, `account/accounts/accountpermissions/${accountId}`),
        cashRegistersByOrganization: (organizationId: string) =>
            joinUrl(api, `account/organizations/organizationcashregister/${organizationId}`),
        getAssociatedAccountsByAccount: (accountId: string) => joinUrl(api, `account/accounts/associatedAccount/byAccount/${accountId}`),

        transactionAccount: (id: string) => joinUrl(api, `account/transactionAccounts/${id}`),
        transactionAccountByRef: (id: string) => joinUrl(api, `account/transactionAccounts/reference/${id}`),
        transactionAccountConceptsByAccount: (id: string) => joinUrl(api, `account/transactionAccounts/concepts/${id}`),
        stripeGetSession: (id: string) => joinUrl(api, `account/stripe/session/${id}`),
        stripeGetCustomer: (accountId: string) => joinUrl(api, `account/accountbilling/stripe/customer/account/${accountId}`),
        stripeGetSetupIntent: (accountId: string) => joinUrl(api, `account/stripe/setupIntent/account/${accountId}`),
        stripeGetPaymentMethodList: (accountId: string) => joinUrl(api, `account/stripe/paymentMethod/list/account/${accountId}`),
    },
    post: {
        stripeCreatePaymentIntent: joinUrl(api, `account/stripe/create/paymentIntent`),
        stripeCreatePaymentIntentConfirm: joinUrl(api, `account/stripe/create/paymentIntent/confirm`),
        stripeCreateSession: joinUrl(api, `account/stripe/create/session`),
        cashRegisterEntryCategory: joinUrl(api, `account/cashRegisters/entry/categories`),
        billingInvoicePayment: joinUrl(api, "account/accountbilling/billinginvoices/createpayment"),
        billingInvoiceSearch: joinUrl(api, "account/accountbilling/invoices/search"),
        accountPaymentMethod: joinUrl(api, "account/accountbilling/paymentmethod/create"),
        wompiPaymentSource: joinUrl(api, "account/accountbilling/wompi/paymentsource"),
        wompiPaymentSourceTransaction: joinUrl(api, "account/accountbilling/wompi/paymentsource/transaction"),
        wompiSignatureIntegrity: joinUrl(api, "account/accountbilling/wompi/signatureIntegrity"),
        clientSettings: joinUrl(api, "account/accounts/clientsettings"),
        cashRegister: joinUrl(api, "account/cashregisters"),
        accountUser: joinUrl(api, "account/accountusers"),
        expenseCreateUpdate: joinUrl(api, "account/expenses/createupdate"),
        expenseCategory: joinUrl(api, "account/expensecategories"),
        expenseDelete: joinUrl(api, "account/expenses/delete"),
        expenseCreatePayment: joinUrl(api, "account/expenses/createPayment"),
        expenseSearchQuery: joinUrl(api, "account/expenses/account/querysearch"),
        expenseGroupSearchQuery: joinUrl(api, "account/expenses/group/querysearch"),
        expenseSummaryTotal: joinUrl(api, "account/expenses/summarytotal"),
        companyImage: (type: CompanyImageTypes) => joinUrl(api, `account/accounts/company/uploadimage/${type}`),
        organizationImage: joinUrl(api, `account/organizations/uploadimage`),
        accountUserImage: joinUrl(api, `account/accountusers/uploadimage`),
        expenseImage: joinUrl(api, `account/expenses/uploadimage`),
        newAccountCompany: joinUrl(api, `account/accountusers/newaccountcompany`),
        updatepermissions: joinUrl(api, `account/accountusers/updatepermissions`),
        organization: joinUrl(api, `account/organizations`),
        organizationEcommerceCategory: joinUrl(api, `account/organizations/ecommercehomecategory`),
        organizationEcommerceBanner: joinUrl(api, `account/organizations/ecommerceadvertisingbanner`),
        cashRegisterEntryQuerySearch: joinUrl(api, `account/cashregisters/entries/querysearch`),
        cashRegisterEntry: joinUrl(api, `account/cashregisters/newentry`),
        organizationUser: joinUrl(api, `account/organizations/organizationuser`),
        organizationWarehouse: joinUrl(api, `account/organizations/organizationwarehouse`),
        cashRegisterLogSearch: joinUrl(api, `account/cashregisters/logs/querysearch`),
        accountPermissionCreateUpdate: joinUrl(api, `account/accounts/accountpermissions`),
        uploadWahioCloudFiles: joinUrl(api, `account/wahiocloud/uploadfile`),
        wahioCloudQuerySearch: joinUrl(api, `account/wahiocloud/querysearch`),
        wahioCloudFolderQuerySearch: joinUrl(api, `account/wahiocloudFolder/querysearch`),
        deleteWahioCloudFiles: joinUrl(api, `account/wahiocloud/deletefiles`),
        deleteWahioCloudFolders: joinUrl(api, `account/wahiocloud/deleteFolders`),
        createWahioFolder: joinUrl(api, `account/wahiocloud/folder/createUpdate`),
        wahioFolderSearch: joinUrl(api, `account/wahiocloud/foldersearch`),
        accountUserPassValidation: joinUrl(api, `account/accounts/accountuseradmin/validation/account`),
        cashRegisterToOrganization: joinUrl(api, `account/organizations/organizationcashregister`),
        accountCancellation: joinUrl(api, `account/accounts/cancellation`),
        recoverCanceledAccount: joinUrl(api, `account/accounts/cancellation/recovery`),
        cashRegisterEntriesSummaryRange: joinUrl(api, `account/cashregisters/entries/summary/range`),
        cashRegisterEntriesSummaryRangePair: joinUrl(api, `account/cashregisters/entries/summary/range/pair`),
        cashRegisterEntriesTypeGroupRange: joinUrl(api, `account/cashregisters/entries/group/type/range`),
        cashRegisterEntriesMethodGroupRange: joinUrl(api, `account/cashregisters/entries/group/method/range`),
        cashRegisterEntriesGroup: joinUrl(api, `account/cashregisters/entries/group`),
        cashRegisterEntriesGroupComparison: joinUrl(api, `account/cashregisters/entries/group/comparison`),
        cashRegisterLogGetEntries: joinUrl(api, `account/cashregisters/logs/entries`),
        createAssociatedAccount: joinUrl(api, `account/accounts/associatedAccount`),
        importTransactionsAccounts: joinUrl(api, `account/transactionAccounts/import`),
        transactionAccountCreatePayment: joinUrl(api, `account/transactionAccounts/createPayment`),
        transactionAccount: joinUrl(api, `account/transactionAccounts/createUpdate`),
        transactionAccountConcept: joinUrl(api, `account/transactionAccounts/concepts/createUpdate`),
        transactionAccountQuerySearch: joinUrl(api, `account/transactionAccounts/querySearch`),
        transactionAccountDelete: joinUrl(api, `account/transactionAccounts/delete`),
    },
    put: {
        addNewLicenses: joinUrl(api, `account/accountbilling/newlicenses`),
        updatePlan: joinUrl(api, `account/accountbilling/updateplan`),
        createInitialContract: joinUrl(api, `account/accountbilling/startcontract`),
        updateAccountUserPassword: joinUrl(api, `account/accountusers/updatepassword`),
        accountUser: joinUrl(api, `account/accountusers`),
        updateAccount: joinUrl(api, `account/accounts/updateaccount`),
        organization: joinUrl(api, `account/organizations`),
        organizationName: joinUrl(api, `account/organizations/updatename`),
        organizationAddress: joinUrl(api, `account/organizations/updateaddress`),
        organizationWarehosue: joinUrl(api, `account/organizations/updatewarehouse`),
        organizationInvoiceDesign: joinUrl(api, `account/organizations/updateinvoicedesign`),
        putCashRegisterStartLog: joinUrl(api, `account/cashregisters/logs/start`),
        putCashRegisterLogFinish: joinUrl(api, `account/cashregisters/logs/finish`),
        putCashRegisterLogPreview: joinUrl(api, `account/cashregisters/logs/preview`),
        putCashRegisterLogUpdate: joinUrl(api, `accont/cashregisters/logs/update`),
        cashRegisterMovement: joinUrl(api, `account/cashregisters/movement`),
        updateWahioCloudFile: joinUrl(api, `account/wahiocloud/updatefile`),
        accountUserActiveStatus: (userId: string) => joinUrl(api, `account/accountusers/setenable/${userId}`),
        cashRegisterEntry: joinUrl(api, `account/cashregisters/entry`),
    },
    patch: {
        cashRegisterPartial: (id: string) => joinUrl(api, `account/cashRegisters/${id}`),
        clientSettingsPartial: (accountId: string) => joinUrl(api, `account/clientSettings/account/${accountId}`),
        updateOrganizationPartial: (organizationId: string) => joinUrl(api, `account/organizations/${organizationId}`),
    },
    delete: {
        accountPaymentMethod: (id: string) => joinUrl(api, `account/accountbilling/paymentmethod/${id}`),
        expense: (id: string) => joinUrl(api, `account/expenses/${id}`),
        expenseCategory: (id: string) => joinUrl(api, `account/expensecategories/${id}`),
        organizationEcommerceHomeCategory: (id: string) => joinUrl(api, `account/organizations/ecommercehomecategory/${id}`),
        organizationEcommerceBanner: (id: string) => joinUrl(api, `account/organizations/ecommerceadvertisingbanner/${id}`),
        accountUser: (id: string) => joinUrl(api, `account/accountusers/${id}`),
        organizationUser: (id: string) => joinUrl(api, `account/organizations/organizationuser/${id}`),
        organizationWarehouse: (id: string) => joinUrl(api, `account/organizations/organizationwarehouse/${id}`),
        accountPermission: (id: string) => joinUrl(api, `account/accounts/accountpermissions/${id}`),
        cashRegisters: (id: string) => joinUrl(api, `account/cashregisters/${id}`),
        organization: (id: string) => joinUrl(api, `account/organizations/${id}`),
        cashRegisterOrganization: (id: string) => joinUrl(api, `account/organizations/organizationcashregister/${id}`),
        associatedAccount: (id: string) => joinUrl(api, `account/accounts/associatedAccount/${id}`),
        transactionAccountConcept: (id: string) => joinUrl(api, `account/transactionAccounts/concepts/${id}`),
    },
};

export const eBillingEndpoint = {
    post: {
        createElectronicBilling: joinUrl(api, `ebilling/electronicbilling/sendinvoice`),
    },
    get: {
        accountCompany: (nit: string, accountId: string) => joinUrl(api, `ebilling/accountcompany/${nit}/${accountId}`),
        resolutionsByCompany: (nit: string, accountId: string) => joinUrl(api, `ebilling/accountcompany/resolutions/${nit}/${accountId}`),
        electronicBillingByOrder: (orderId: string) => joinUrl(api, `ebilling/electronicbilling/order/${orderId}`),
    },
    put: {
        accountCompany: joinUrl(api, `ebilling/accountcompany`),
        accountCompanyDefaultResolutions: joinUrl(api, `ebilling/accountcompany/defaultresolutions`),
        accountCompanyDefaultResolutionNextNumber: joinUrl(api, `ebilling/accountcompany/defaultresolutions/nextNumber`),
        accountCompanyEnvironment: joinUrl(api, `ebilling/accountcompany/environment`),
        accountCompanyTestSet: (accountId: string) => joinUrl(api, `ebilling/accountcompany/config/testset/${accountId}`),
    },
};

export const statisticsEndpoint = {
    post: {
        globalSummary: joinUrl(api, `statistics/statistics/globalsummary`),
        ordersMetricsOverview: joinUrl(api, `statistics/statistics/orders/metrics/overview`),
        orderAmountDayGroup: joinUrl(api, `statistics/statistics/orderamount/daygroup`),
        orderPurchaseInGroup: joinUrl(api, `statistics/statistics/orders/orderandpurchasegroup`),
        productSalesInGroup: joinUrl(api, `statistics/statistics/products/salesgroup`),
        productSalesByRange: joinUrl(api, `statistics/statistics/products/salesbyrange`),
        customCategoryalesInGroup: joinUrl(api, `statistics/statistics/customcategories/salesgroup`),
        customCategorySalesByRange: joinUrl(api, `statistics/statistics/customcategories/salesbyrange`),
        customCategoryGroup: joinUrl(api, `statistics/statistics/ordersummary/categorygroup`),
        getBestCustomers: joinUrl(api, `statistics/statistics/ordersummary/bestcustomers`),
        getCustomersHigherDebt: joinUrl(api, `statistics/statistics/ordersummary/customershigherdebt`),
        getBestChannels: joinUrl(api, `statistics/statistics/ordersummary/bestchannels`),
        productSales: joinUrl(api, `statistics/statistics/productsales`),
        lastProductSales: joinUrl(api, `statistics/statistics/productsummary/lastproducts`),
        orderReport: joinUrl(api, `statistics/reports/orders`),
        orderReportInPages: joinUrl(api, `statistics/reports/v2/orders`),
        orderReportSummary: joinUrl(api, `statistics/reports/v2/orders/summary`),
        productReportInPages: joinUrl(api, `product/reports`),
        productReportSummary: joinUrl(api, `product/reports/summary`),
        orderPaymentReportInPages: joinUrl(api, `statistics/reports/v2/orderpayments`),
        orderPaymentReportSummary: joinUrl(api, `statistics/reports/v2/orderpayments/summary`),
        expenseReportInPages: joinUrl(api, `statistics/reports/v2/expenses`),
        expenseReportSummary: joinUrl(api, `statistics/reports/v2/expenses/summary`),
        cashRegisterReportInPages: joinUrl(api, `statistics/reports/v2/cashregisters`),
        cashRegisterReportSummary: joinUrl(api, `statistics/reports/v2/cashregisters/summary`),
        purchaseReportInPages: joinUrl(api, `statistics/reports/v2/purchases`),
        purchaseReportSummary: joinUrl(api, `statistics/reports/v2/purchases/summary`),
        accountConsumption: joinUrl(api, `statistics/consumption/accountreport`),
        orderCountByRange: joinUrl(api, `statistics/consumption/ordercountbyrange`),
        purchaseReport: joinUrl(api, `statistics/reports/purchases`),
    },
};

export const marketingEndpoint = {
    post: {
        sendSms: joinUrl(api, `notification/notification/sms/sendmessage`),
        notificationSummaryByAccount: joinUrl(api, `notification/notifications/summary/account`),
        eventCreate: joinUrl(api, `notification/events`),
        eventSearch: joinUrl(api, `notification/events/search`),
        eventGroupByDate: joinUrl(api, `notification/events/search/groupByDate`),
        sendReceiptPaymentEmail: joinUrl(api, `notification/notification/paymentReceipt`),
    },
};

const DEFAULT_VALUE = { accountEndpoint, loginEndpoint };

export default DEFAULT_VALUE;
