import { defineMessages } from "react-intl";

const base = "components.productProfileNavigation";

export default defineMessages({
    profile: {
        id: `${base}.profile`,
        defaultMessage: "Perfil",
    },
    stock: {
        id: `${base}.stock`,
        defaultMessage: "Stock",
    },
    stockLocation: {
        id: `${base}.stockLocation`,
        defaultMessage: "Ubicación de stock",
    },
    stockSerials: {
        id: `${base}.stockSerials`,
        defaultMessage: "Stock de series",
    },
    salePrices: {
        id: `${base}.salePrices`,
        defaultMessage: "Precios de venta",
    },
    productCosts: {
        id: `${base}.productCosts`,
        defaultMessage: "Costos de producto",
    },
    statistics: {
        id: `${base}.statistics`,
        defaultMessage: "Estadísticas",
    },
    files: {
        id: `${base}.files`,
        defaultMessage: "Archivos",
    },
    settings: {
        id: `${base}.settings`,
        defaultMessage: "Configuración",
    },
    images: {
        id: `${base}.images`,
        defaultMessage: "Imágenes",
    },
    movements: {
        id: `${base}.movements`,
        defaultMessage: "Movimientos",
    },
    updated: {
        id: `${base}.updated`,
        defaultMessage: "Actualizado",
    },
    description: {
        id: `${base}.description`,
        defaultMessage: "Descripción",
    },
    features: {
        id: `${base}.features`,
        defaultMessage: "Características",
    },
    ecommerce: {
        id: `${base}.ecommerce`,
        defaultMessage: "E-commerce",
    },
    ecommerceConfig: {
        id: `${base}.ecommerceConfig`,
        defaultMessage: "Configuración",
    },
    ecommerceVisibility: {
        id: `${base}.ecommerceVisibility`,
        defaultMessage: "Visibilidad",
    },
    ecommerceProductPreview: {
        id: `${base}.ecommerceProductPreview`,
        defaultMessage: "Vista previa",
    },
    ecommerceStatistics: {
        id: `${base}.ecommerceStatistics`,
        defaultMessage: "Estadísticas",
    },
    costs: {
        id: `${base}.costs`,
        defaultMessage: "Costos",
    },
    logs: {
        id: `${base}.logs`,
        defaultMessage: "Movimientos",
    },
    optionals: {
        id: `${base}.optionals`,
        defaultMessage: "Opcionales",
    },
    recommendations: {
        id: `${base}.recommendations`,
        defaultMessage: "Recomendaciones",
    },
    barcodeFiles: {
        id: `${base}.barcodeFiles`,
        defaultMessage: "Códigos de barras",
    },
    barcode: {
        id: `${base}.barcode`,
        defaultMessage: "Código de barras",
    },
    sku: {
        id: `${base}.sku`,
        defaultMessage: "SKU",
    },
    productAiAnalysis: {
        id: `${base}.productAiAnalysis`,
        defaultMessage: "Análisis Ai",
    },
    variants: {
        id: `${base}.variants`,
        defaultMessage: "Variantes",
    },
});
