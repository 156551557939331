import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { KeyParamMapping } from "../../FilterParams/helper";

export type EntryTypeOption = "all" | "income" | "expense" | "backLink";
export type EntryParamType = "type" | "category" | "cashRegister" | "contact" | "paymentMethod" | "startDate" | "endDate" | "backLink";

export const IgnoreActiveFilters: (keyof EntryParams)[] = ["type", "backLink"];
export const IgnoreFilterValues: (keyof EntryParams)[] = ["cashRegister", "contact", "backLink"];

export interface EntryParams {
    type?: EntryTypeOption;
    category?: string;
    cashRegister?: string;
    contact?: string;
    paymentMethod?: string;
    startDate?: string;
    endDate?: string;
    backLink?: string;
}

export const EntryParamsKeyNames: KeyParamMapping<EntryParams, EntryParamType>[] = [
    { key: "type", param: "type" },
    { key: "category", param: "category" },
    { key: "cashRegister", param: "cashRegister" },
    { key: "contact", param: "contact" },
    { key: "paymentMethod", param: "paymentMethod" },
    { key: "startDate", param: "startDate" },
    { key: "endDate", param: "endDate" },
    { key: "backLink", param: "backLink" },
];

export const getEntryParams = (params: EntryParams) => {
    let search = "?";

    EntryParamsKeyNames.forEach((item) => {
        const value = params[item.key];
        if (value) {
            const processed = value.toString();
            search += getSearchValue(item.param, processed);
        }
    });

    if (search[search.length - 1] === "&") {
        search = search.substring(0, search.length - 1);
    }
    return search;
};

export const calculateActiveParams = (productParams: EntryParams) => {
    let counter = EntryParamsKeyNames.filter((x) => !IgnoreActiveFilters.includes(x.key) && !!productParams[x.key]).length;
    return counter;
};

const getSearchValue = (key: keyof EntryParams, value: string) => {
    return `${key}=${encodeURIComponent(value)}&`;
};

export const useEntrySearchParams = () => {
    const [urlParams, setParams] = useSearchParams();

    const setEntryParams = (state: EntryParams) => {
        const search = getEntryParams(state);
        setParams(search);
    };

    const getSearchParamsText = (state: EntryParams) => {
        const search = getEntryParams(state);
        return search;
    };

    const getSearchParams = () => {
        let searchParams: EntryParams = {};
        EntryParamsKeyNames.forEach((item) => {
            const value = urlParams.get(item.param);
            if (value) {
                var processed = item.decode ? item.decode(value) : value;
                searchParams[item.key] = processed;
            }
        });

        searchParams.type = (urlParams.get("type") as EntryTypeOption) ?? "all";

        return searchParams;
    };

    const [entryParams, activeFilterCount] = useMemo(() => {
        const newParams = getSearchParams();
        const filterCount = calculateActiveParams(newParams);
        return [newParams, filterCount];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    return { entryParams, setEntryParams, getSearchParamsText, activeFilterCount };
};
