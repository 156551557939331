import { GroupItemType } from "../api/models/_statistics";
import { IDeliverySummary } from "../api/models/deliveryOrder";
import { ProductPriceTypes, ProductSortType } from "../api/models/product";

export const CHANGE_THEME = "appSettings/CHANGE_THEME";
export const SET_LANGUAGE = "appSettings/SET_LANGUAGE";
export const SET_LIST_PRODUCT_SHAPE = "appSettings/SET_LIST_PRODUCT_SHAPE";
export const SET_DEFAULT_PRICE = "appSettings/SET_DEFAULT_PRICE";
export const SET_DEFAULT_ORGANIZATION = "appSettings/SET_DEFAULT_ORGANIZATION";
export const SET_DEFAULT_WAREHOUSE = "appSettings/SET_DEFAULT_WAREHOUSE";
export const SET_DEFAULT_LOUNGE = "appSettings/SET_DEFAULT_LOUNGE";
export const SET_DELIVERY_SUMMARY = "appSettings/SET_DELIVERY_SUMMARY";
export const CHANGE_MENU_SMALL = "appSettings/CHANGE_MENU_SMALL";
export const SET_ALL_SETTINGS = "appSettings/SET_ALL_SETTINGS";

export const APP_SETTINGS_KEY = "appSettings";

export type AppGripShape = "row" | "square" | undefined;
export type ThemeType = "light" | "dark" | "device";
export type PrintType = "80mm" | "halfPage" | "other";

export interface IAppSettingsState {
    language: string;
    productListShape: AppGripShape;
    orderListShape: AppGripShape;
    defaultPrice: ProductPriceTypes;
    defaultOrganizationId: string;
    menuSmall: boolean;
    menuOpen?: boolean;
    defaultWarehouseId: string;
    deliverySummary: IDeliverySummary;
    defaultLoungeId: string;
    activeLogCashRegisterId: string;
    appVersion: string;

    productSearchShowAddModal: boolean;
    productSearchQuantityDelta: number;
    productSearchPriceDelta: number;
    productSearchOnlyStockProducts: boolean;
    productSearchMinPrice: number;
    productSearchMaxPrice: number;
    productSearchMinStock: number;
    productSearchMaxStock: number;
    productSearchOnlyProductWithImage: boolean;
    productSearchOnlyProductWithoutImage: boolean;
    productSearchSort: ProductSortType;
    productSearchSortDesc: boolean;

    productContainerListShape: AppGripShape;
    productContainerSort: ProductSortType;
    productContainerSortDesc: boolean;

    printType: PrintType;
    autoPrintOrder: boolean;
    orderListStatisticsGroupType?: GroupItemType;

    orderSummaryDesktopSimpleView?: boolean;
    expenseListShape?: "row" | "square";
    warehouseListShowByStore?: boolean;

    imageAnalysisTagsAsHashtag?: boolean;
    selectedInvoiceNumerationId?: string;
    turnOfNotifications: boolean;

    createOrderPanelFullScreen?: boolean;
    themeSettings?: ThemeSettings;

    orderForm?: OrderFormSettings;
    purchaseForm?: PurchaseFormSettings;
}

export interface OrderFormSettings {
    showPriceSelector?: boolean;
    showWarehouseSelector?: boolean;
    showChannelSelector?: boolean;
    showSellerSelector?: boolean;
    showNumerationSelector?: boolean;
    allowCustomDate?: boolean;
    showTotalTip?: boolean;
    showTotalDiscount?: boolean;
    tipBeforeTax?: boolean;
    discountBeforeTax?: boolean;
}

export interface PurchaseFormSettings {
    purchaseTaxIncluded?: boolean;
    purchaseIncludesTaxWithholding?: boolean;
    purchasePreTaxDiscount?: boolean;
    allowCustomDate?: boolean;
}

export interface ThemeSettings {
    theme?: ThemeType;
    colorName?: string;
}

export const getDefaultAppSettings = (): IAppSettingsState => {
    return {
        language: "es",
        productListShape: "square",
        orderListShape: "square",
        defaultPrice: "price",
        defaultOrganizationId: "",
        deliverySummary: { total: 0, delivered: 0, pending: 0 },
        menuSmall: false,
        defaultWarehouseId: "",
        defaultLoungeId: "",
        activeLogCashRegisterId: "",
        appVersion: "",
        productSearchShowAddModal: false,
        productSearchQuantityDelta: 1,
        productSearchPriceDelta: 100,
        productSearchOnlyStockProducts: false,
        productSearchMinPrice: 0,
        productSearchMaxPrice: 0,
        productSearchMinStock: 0,
        productSearchMaxStock: 0,
        productSearchOnlyProductWithImage: false,
        productSearchOnlyProductWithoutImage: false,
        productSearchSort: "default",
        productSearchSortDesc: true,

        productContainerListShape: "square",

        productContainerSort: "default",
        productContainerSortDesc: true,

        printType: "other",
        autoPrintOrder: false,

        turnOfNotifications: false,
        themeSettings: {
            theme: "light",
            colorName: "Default",
        },
    };
};

export interface ChangeThemeType {
    type: typeof CHANGE_THEME;
}
export interface SetLanguageType {
    type: typeof SET_LANGUAGE;
    value: string;
}
export interface SetProductListShapeType {
    type: typeof SET_LIST_PRODUCT_SHAPE;
    shape: AppGripShape;
}

export interface SetDefaultPrice {
    type: typeof SET_DEFAULT_PRICE;
    value: ProductPriceTypes;
}
export interface SetDefaultOrganziation {
    type: typeof SET_DEFAULT_ORGANIZATION;
    value: string;
}
export interface SetDefaultWarehouse {
    type: typeof SET_DEFAULT_WAREHOUSE;
    value: string;
}
export interface SetDefaultLounge {
    type: typeof SET_DEFAULT_LOUNGE;
    value: string;
}
export interface SetDeliverySummary {
    type: typeof SET_DELIVERY_SUMMARY;
    value: IDeliverySummary;
}
export interface ChangeMenuSmall {
    type: typeof CHANGE_MENU_SMALL;
}

export interface SetAllSettings {
    type: typeof SET_ALL_SETTINGS;
    value: IAppSettingsState;
}

export type AppSettingsTypes =
    | ChangeThemeType
    | SetLanguageType
    | SetProductListShapeType
    | SetDefaultPrice
    | SetDefaultOrganziation
    | SetDefaultWarehouse
    | ChangeMenuSmall
    | SetDefaultLounge
    | SetDeliverySummary
    | SetAllSettings;

export const KEYS_FILTER_PRODUCT_SEARCH = [
    "productSearchOnlyStockProducts",
    "productSearchNegativeStockProducts",
    "productSearchOnlyProductWithImage",
    "productSearchOnlyProductWithoutImage",
    "productSearchMinPrice",
    "productSearchMaxPrice",
    "productSearchMinStock",
    "productSearchMaxStock",
];
