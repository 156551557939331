import React, { useContext, useState } from "react";

import { useAlert } from "../Alerts/Alert";
import { useIntl } from "react-intl";
import Select from "react-select";
import { getServiceMessageError, wahioFetch } from "../../api";
import { PaymentMethod, PAYMENT_METHOD_LIST, SelectBaseOption } from "../../api/models";
import { ICashRegister, ICashRegisterMovementInput } from "../../api/models/cashRegister";
import { accountEndpoint } from "../../api/restApiEndpoint";
import { getAuthBody } from "../../auth";
import globalMessages from "../../messages";
import { CashRegisterContext } from "../../store/contexts/CashRegisterContext";
import { formatMoney } from "../../utils";
import LoadingDualRing from "../LoadingDualRing";
import Modal, { IModalShow } from "../modals/Modal";
import { SelectBody } from "../Select/styled";
import TextBoxNumeral from "../TextBoxNumeral";
import { DefaultButton, Flex, TextField, PrimaryButton, SquareButton } from "../_controls";
import messages from "./messages";
import { CashRegisterMovementContainer, CashRegisterMovementGroup } from "./styled";

export interface CashRegisterMovementProps {
    defaultOrigin?: ICashRegister;
    onCreate: (value: ICashRegister) => void;
    onCancel: () => void;
}

interface CashRegisterMovementState extends ICashRegisterMovementInput {
    isFetching: boolean;
}
interface CashRegisterMovementModalProps extends CashRegisterMovementProps, IModalShow {}

interface CashRegisterMovementButtonProps extends CashRegisterMovementProps {
    square?: boolean;
}

export const CashRegisterMovementButton = (props: CashRegisterMovementButtonProps) => {
    const [showModal, setShowModal] = useState(false);
    const Button = props.square ? SquareButton : DefaultButton;
    return (
        <>
            <Button onClick={() => setShowModal(true)} rounded>
                <span className="wahioicon-exchange-alt"></span> {!props.square ? "Trasladar" : ""}
            </Button>
            {showModal && <CashRegisterMovementModal show={showModal} setShow={setShowModal} {...props} />}
        </>
    );
};

export default function CashRegisterMovement(props: CashRegisterMovementProps) {
    const intl = useIntl();
    const alert = useAlert();
    const { cashRegisterState } = useContext(CashRegisterContext);
    const [state, setState] = useState<CashRegisterMovementState>({
        accountUserId: getAuthBody().accountUserId,
        cashRegisterOriginId: props.defaultOrigin ? props.defaultOrigin.id ?? "" : "",
        cashRegisterOrigin: props.defaultOrigin,
        cashRegisterDestinyId: "",
        comment: "",
        amount: 0,
        method: "cash",
        isFetching: false,
    });

    const [currencyDestinyValue, setCurrencyDestinyValue] = useState(0);

    const handleCreateMovement = () => {
        setState({ ...state, isFetching: true });

        let body = { ...state };

        if (needCurrencyConversion()) {
            body.destinyConversionRate = currencyDestinyValue;
        }

        body.destinyConversionRate = wahioFetch.put(
            accountEndpoint.put.cashRegisterMovement,
            body,
            (success) => {
                alert.success(intl.formatMessage(messages.movementCreated));
                props.onCreate(success.data);
                setState({ ...state, isFetching: false });
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setState({ ...state, isFetching: false });
            }
        );
    };

    const getRegisterOption = (item: ICashRegister) => {
        let option: SelectBaseOption = {
            id: item.id ?? "",
            value: item.id ?? "",
            label: item.name,
        };
        return option;
    };

    const getMethodOption = (item: PaymentMethod) => {
        let option: SelectBaseOption = {
            id: item,
            value: item,
            label: intl.formatMessage(globalMessages[item]),
        };
        return option;
    };

    const originChange = (cashRegisterId?: string) => {
        let cashRegister: ICashRegister | undefined = undefined;
        if (cashRegisterId) {
            cashRegister = cashRegisterState.orgItems.find((x) => x.id === cashRegisterId);
        }
        setState({ ...state, cashRegisterOrigin: cashRegister, cashRegisterOriginId: cashRegister?.id ?? "" });
    };
    const originDestiny = (cashRegisterId?: string) => {
        let cashRegister: ICashRegister | undefined = undefined;
        if (cashRegisterId) {
            cashRegister = cashRegisterState.orgItems.find((x) => x.id === cashRegisterId);
        }
        setState({ ...state, cashRegisterDestiny: cashRegister, cashRegisterDestinyId: cashRegister?.id ?? "" });
    };

    const needCurrencyConversion = () => {
        const destinyCurrency = (state.cashRegisterDestiny?.currency ?? "COP").toUpperCase();
        const originCurrency = (state.cashRegisterOrigin?.currency ?? "COP").toUpperCase();
        const showCurrencyConversion = state.cashRegisterOrigin && state.cashRegisterDestiny && destinyCurrency !== originCurrency;
        return showCurrencyConversion;
    };

    const destinyCurrency = (state.cashRegisterDestiny?.currency ?? "COP").toUpperCase();
    const originCurrency = (state.cashRegisterOrigin?.currency ?? "COP").toUpperCase();
    const showCurrencyConversion = needCurrencyConversion();

    return (
        <CashRegisterMovementContainer>
            <h3 className="m-0 text-bold">Crear traslado</h3>
            <CashRegisterMovementGroup>
                <SelectBody className="select-custom">
                    <span className="label">{intl.formatMessage(messages.originBoxTwo)}</span>
                    <Select
                        placeholder={intl.formatMessage(messages.toSelectTwo)}
                        className={state.cashRegisterOrigin ? "item-active" : ""}
                        classNamePrefix="select"
                        key={state.cashRegisterOriginId}
                        defaultValue={state.cashRegisterOrigin ? getRegisterOption(state.cashRegisterOrigin) : undefined}
                        isDisabled={!!props.defaultOrigin}
                        onChange={(value) => originChange(value?.id)}
                        isRtl={false}
                        isSearchable={true}
                        name="seller"
                        options={
                            cashRegisterState.orgItems &&
                            cashRegisterState.orgItems.filter((x) => x.id !== state.cashRegisterDestinyId).map((x) => getRegisterOption(x))
                        }
                    />
                </SelectBody>
                <div className="middle-arrow">
                    <SquareButton className="btn-arrow">
                        <span className="wahioicon-arrow-right"></span>
                    </SquareButton>
                </div>
                <SelectBody className="select-custom">
                    <span className="label">{intl.formatMessage(messages.destinationCash)}</span>
                    <Select
                        placeholder={intl.formatMessage(messages.toSelectTwo)}
                        className={state.cashRegisterDestiny ? "item-active" : ""}
                        classNamePrefix="select"
                        key={state.cashRegisterDestinyId}
                        defaultValue={state.cashRegisterDestiny ? getRegisterOption(state.cashRegisterDestiny) : undefined}
                        isDisabled={false}
                        onChange={(value) => originDestiny(value?.id)}
                        isRtl={false}
                        isSearchable={true}
                        name="seller"
                        options={
                            cashRegisterState.orgItems &&
                            cashRegisterState.orgItems.filter((x) => x.id !== state.cashRegisterOriginId).map((x) => getRegisterOption(x))
                        }
                    />
                </SelectBody>
            </CashRegisterMovementGroup>
            <SelectBody>
                <span className="label">{intl.formatMessage(messages.paymentMethod)}</span>
                <Select
                    placeholder={intl.formatMessage(messages.toSelectTwo)}
                    className={state.method ? "item-active" : ""}
                    classNamePrefix="select"
                    key={state.method}
                    defaultValue={state.method ? getMethodOption(state.method) : undefined}
                    isDisabled={false}
                    onChange={(value) => {
                        if (value && (value.id as PaymentMethod)) setState({ ...state, method: value.id as PaymentMethod });
                    }}
                    isRtl={false}
                    isSearchable={true}
                    name="seller"
                    options={PAYMENT_METHOD_LIST.map((item) => getMethodOption(item))}
                />
            </SelectBody>

            <Flex column padding={15} bgLight borderRadius={15} gap5>
                <TextField light>Tasa de conversion: {showCurrencyConversion ? "Activa" : "No requerida"}</TextField>

                {showCurrencyConversion && (
                    <>
                        <Flex alignCenter gap10>
                            <TextField>
                                {originCurrency} 1 = {destinyCurrency}{" "}
                            </TextField>
                            <TextBoxNumeral
                                rounded
                                decimalsLimit={10}
                                style={{ minWidth: 120 }}
                                onNumberChange={(value) => {
                                    setCurrencyDestinyValue(value);
                                }}
                                format={"money"}
                                className="bg-background"
                            />
                        </Flex>

                        <TextField light>
                            {formatMoney(state.amount)} x {formatMoney(currencyDestinyValue)} ={" "}
                            {formatMoney(state.amount * currencyDestinyValue)} se trasladaran a {state.cashRegisterDestiny?.name}
                        </TextField>
                    </>
                )}
            </Flex>

            <Flex column gap5>
                <TextField small light>
                    {intl.formatMessage(messages.valueTransfer)}
                </TextField>
                <Flex alignCenter gap10>
                    <TextBoxNumeral
                        style={{ fontSize: "20px" }}
                        rounded
                        w100
                        decimalsLimit={10}
                        format="money"
                        onNumberChange={(value) => setState({ ...state, amount: value })}
                    />
                    {showCurrencyConversion && (
                        <Flex alignCenter gap5>
                            <span className="wahioicon-arrow-right"></span>
                            <TextField bold fontSize={18}>
                                {formatMoney(state.amount * currencyDestinyValue)}
                            </TextField>
                        </Flex>
                    )}
                </Flex>
            </Flex>

            <Flex alignCenter gap10 justifyEnd>
                <DefaultButton disabled={state.isFetching} onClick={() => props.onCancel()} rounded={true}>
                    Cancelar
                </DefaultButton>
                <PrimaryButton disabled={state.isFetching} onClick={() => handleCreateMovement()} rounded={true}>
                    Confirmar Traslado {state.isFetching && <LoadingDualRing small />}
                </PrimaryButton>
            </Flex>
        </CashRegisterMovementContainer>
    );
}

export function CashRegisterMovementModal(props: CashRegisterMovementModalProps) {
    const intl = useIntl();
    const onCreate = (value: ICashRegister) => {
        props.onCreate(value);
        props.setShow(false);
    };
    return (
        <Modal
            sizeType="sm"
            scrollInside={false}
            overflowAuto={false}
            useButtonClose
            title={intl.formatMessage(messages.createCashTransfer)}
            showHeader={true}
            ignoreOutsideClick
            {...props}
        >
            <div className="pb-3 pl-3 pr-3 pt-2">
                <CashRegisterMovement {...props} onCreate={onCreate} onCancel={() => props.setShow(false)} />
            </div>
        </Modal>
    );
}
