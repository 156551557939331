import React, { CSSProperties, useState } from "react";
import { OrderChangeProps } from "..";
import CreateEvent from "../../../../EventCalendar/CreateEvent";
import { DefaultButton, Flex, SquareButton, TextField } from "../../../../_controls";
import Modal from "../../../../modals/Modal";
import { getDateFormat } from "../../../../../utils";
import { LIGHT_COLORS, getColorValue } from "../../../../../constants/colors";

export default function ShipmentScheduleEvent(props: OrderChangeProps) {
    const [showEventCalendar, setShowEventCalendar] = useState(false);

    const { order } = props;
    const { deliveryOrder } = order;

    if (!deliveryOrder) return null;

    const event = deliveryOrder.event;

    const Button = event ? SquareButton : DefaultButton;

    let btnStyle: CSSProperties = {};
    if (event) {
        btnStyle = {
            backgroundColor: getColorValue(event.color),
            color: LIGHT_COLORS.includes(event.color as any) ? "black" : "white",
        };
    }

    const onRemoveEvent = () => {
        let delivery = { ...deliveryOrder };
        delivery.event = undefined;
        props.onOrderChange({ ...order, deliveryOrder: delivery });
        setShowEventCalendar(false);
    };

    return (
        <>
            {showEventCalendar && (
                <Modal show={showEventCalendar} setShow={setShowEventCalendar} useButtonClose ignoreOutsideClick>
                    {event && (
                        <Flex paddingLeft={20} paddingTop={20}>
                            <DefaultButton borderRadius={10} bgLight onClick={onRemoveEvent}>
                                Descartar Evento <span className="fa-regular fa-trash"></span>
                            </DefaultButton>
                        </Flex>
                    )}
                    <CreateEvent
                        defaultTitle={`Orden #000. ${deliveryOrder.contactName}`}
                        stopSave
                        defaultEvent={deliveryOrder.event}
                        onCancel={() => setShowEventCalendar(false)}
                        onCreate={(event) => {
                            let delivery = { ...deliveryOrder };
                            delivery.event = event;
                            props.onOrderChange({ ...order, deliveryOrder: delivery });
                            setShowEventCalendar(false);
                        }}
                    />
                </Modal>
            )}

            <Flex gap10 alignCenter>
                <Button disabled={!!order.id} style={btnStyle} borderRadius={10} bgLight onClick={() => setShowEventCalendar(true)}>
                    <i className="fa-light fa-calendar"></i>
                    {event ? "" : " Agendar"}
                </Button>
                {event && (
                    <Flex column>
                        <TextField light small>
                            Agendado
                        </TextField>
                        <TextField bold small>
                            {getDateFormat(event.startDate, "ddd DD MMMM HH:mm")}
                        </TextField>
                    </Flex>
                )}
            </Flex>
        </>
    );
}
