import React from "react";
import Modal, { IModalShow } from "../../../modals/Modal";
import { useIntl } from "react-intl";
import messages from "./messages";
import { ITax } from "../../../../api/models/tax";
import TaxForm, { TaxFormProps } from ".";

interface IProps extends TaxFormProps, IModalShow {}

export default function TaxFormModal(props: IProps) {
    const intl = useIntl();
    const onCreate = (value: ITax) => {
        props.onCreate(value);
        if (props.closeOnCreate) props.setShow(false);
    };
    return (
        <Modal
            scrollInside={false}
            ignoreOutsideClick
            overflowAuto={false}
            title={intl.formatMessage(!!props.taxUpdate ? messages.editTax : messages.createTax)}
            showHeader={true}
            {...props}
        >
            <TaxForm {...props} onCreate={onCreate} onCancel={() => props.setShow(false)} />
        </Modal>
    );
}
