import styled from "styled-components";
import { DefaultButton } from "../../_controls";

export const ButtonColor = styled(DefaultButton)`
    width: 100%;
    justify-content: start;

    .color-circle {
        width: 25px;
        min-width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #ccc;
        cursor: pointer;
    }
`;
