export const SHIPMENT_ICON_GROUP = {
    pending: "fa-regular fa-clock",
    processing: "fa-regular fa-pallet-box", //wahioicon-truck-loading
    picking: "fa-regular fa-basket-shopping",
    packing: "fa-regular fa-box-check",
    readyToShip: "fa-regular fa-box-check",
    shipped: "fa-regular fa-truck",
    delivered: "fa-regular fa-box-check",
};

export const SHIPMENT_STATUS_TEXT_HELPER = {
    pending: "Productos en estado reservado",
    processing: "Productos en preparación",
    picking: "Eligiendo productos de ubicaciones",
    packing: "Productos salen de estado reservado y se están empacando",
    readyToShip: "Los productos están listo para ser enviados",
    shipped: "Productos han sido enviados",
    delivered: "Productos entregados",
};

export const SHIPMENT_STATUS_COLOR = {
    pending: "#546e7a",
    processing: "#f57f17",
    picking: "#00BCD4",
    packing: "#4CAF50",
    readyToShip: "#7c4dff",
    shipped: "#00bfa5",
    delivered: "#304ffe",
};
