import _, { capitalize } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { ProductSearchGroupModel } from "../../../api/models/_statistics";
import { ProductAiContent, ProductAiResponse } from "../../../api/models/product";
import { ImageAnalysisResult, ImageSummaryBody } from "../../../api/models/wahioAiModels";
import { getProductAiContent, putProductAiContent } from "../../../api/products/productsApi";
import { productSalesInGroup } from "../../../api/statistics/statisticsApi";
import { useAppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getDateFromNow } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { ChatResponsePlayground } from "../../OpenAi";
import { ImageColorLabel } from "../../WahioCloud/styled";
import { Flex, PrimaryGradientButton, TextField } from "../../_controls";
import TextGroup from "../../_controls/TextFields";
import GroupButtonRow from "../../_controls/buttons/GroupButtonRow";
import NavigationCard from "../../NavigationPanel/NavigationCard";

var parse = require("html-react-parser");

export default function NavigationAi(props: ProductNavigationProfileProps) {
    const [productAiContent, setProductAiContent] = useState<ProductAiContent>();
    const alert = useAlert();
    const listResponses = useMemo(() => {
        if (productAiContent && productAiContent.contentGeneratedAsString) {
            try {
                var listResult = JSON.parse(productAiContent.contentGeneratedAsString) as ProductAiResponse[];
                return listResult;
            } catch (ex) {
                return [];
            }
        } else {
            return [];
        }
    }, [productAiContent]);

    useEffect(() => {
        if (props.product.id) {
            getAiContentMutation.mutate(props.product?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product.id]);

    const getAiContentMutation = useMutation((id: string) => getProductAiContent(id), {
        onSuccess: (data) => {
            setProductAiContent(data);
        },
    });

    const putAiContentMutation = useMutation((data: ProductAiContent) => putProductAiContent(data), {
        onSuccess: (data) => {
            setProductAiContent(data);
            alert.success("Respuesta guardada automáticamente");
        },
    });

    const onResponseChange = (response: ProductAiResponse) => {
        let newResponses = [...listResponses];
        let index = newResponses.findIndex((x) => x.id === response.id);
        if (index >= 0) {
            newResponses[index] = response;
        } else {
            newResponses.push(response);
        }
        handleSaveResponses(newResponses);
    };

    const handleSaveResponses = (responses: ProductAiResponse[]) => {
        if (productAiContent) {
            const contentString = JSON.stringify(responses);
            putAiContentMutation.mutate({ ...productAiContent, contentGeneratedAsString: contentString });
        }
    };

    return (
        <>
            <ImageAnalysisSummary {...props} />
            <NavigationSalesAiCard
                {...props}
                itemPrompt={promptSaleAnalysis}
                responses={listResponses}
                onResponseChange={onResponseChange}
            />

            {promptsGPT.map((item) => (
                <NavigationAiCard {...props} itemPrompt={item} responses={listResponses} onResponseChange={onResponseChange} />
            ))}
        </>
    );
}

interface NavigationAiCardProps extends ProductNavigationProfileProps {
    responses: ProductAiResponse[];
    itemPrompt: IPromptsGPT;
    onResponseChange: (response: ProductAiResponse) => void;
}

const getProductAiResponse = (itemPrompt: IPromptsGPT, content: string) => {
    let data: ProductAiResponse = {
        id: itemPrompt.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        theme: itemPrompt.theme,
        prompt: itemPrompt.prompt,
        content: content,
    };
    return data;
};

const NavigationAiCard = (props: NavigationAiCardProps) => {
    const { itemPrompt, responses } = props;
    const [response, setResponse] = useState<ProductAiResponse>();
    const previewRef = React.useRef<string>("");
    const [responsePreview, setResponsePreview] = useState("");

    const promptText = React.useMemo(() => {
        return itemPrompt.prompt.replace("[productName]", props.product.name).replace("[category]", props.product.customCategory.name);
    }, [itemPrompt, props.product]);

    useEffect(() => {
        if (responses && responses.length > 0) {
            var item = responses.find((x) => x.id === itemPrompt.id);
            if (item) {
                setResponse(item);
            }
        }
    }, [responses, itemPrompt]);

    const handleNewResponse = () => {
        props.onResponseChange(getProductAiResponse(itemPrompt, previewRef.current));
        previewRef.current = "";
    };

    return (
        <NavigationCard
            {...props}
            subTitle={response ? `Repuesta generada: ${getDateFromNow(response.createdAt)}` : ""}
            defaultClose
            allowClose
            title={itemPrompt.theme}
        >
            <Flex column gap={20}>
                <ChatResponsePlayground
                    btnTitle={response?.content ? "Generar Nuevo" : "Generar"}
                    onResponse={(value) => {
                        setResponsePreview(value);
                        previewRef.current = value;
                    }}
                    onCompleted={handleNewResponse}
                    prompt={promptText}
                />

                {parse(responsePreview)}
                {!responsePreview && response && parse(response.content)}
            </Flex>
        </NavigationCard>
    );
};

const NavigationSalesAiCard = (props: NavigationAiCardProps) => {
    const { itemPrompt, responses } = props;
    const [response, setResponse] = useState<ProductAiResponse>();
    const previewRef = React.useRef<string>("");
    const [responsePreview, setResponsePreview] = useState("");

    const promptText = React.useMemo(() => {
        return itemPrompt.prompt.replace("[productName]", props.product.name).replace("[category]", props.product.customCategory.name);
    }, [itemPrompt, props.product]);

    const [promptWithSalesData, setPromptWithSalesData] = useState("");

    const groupMutation = useMutation((data: ProductSearchGroupModel) => productSalesInGroup(data), {
        onSuccess: (data) => {
            if (data.length > 0) {
                setPromptWithSalesData(`${promptText}: ${JSON.stringify(data)}`);
            }
        },
    });

    useEffect(() => {
        if (responses && responses.length > 0) {
            var item = responses.find((x) => x.id === itemPrompt.id);
            if (item) {
                setResponse(item);
            }
        }
    }, [responses, itemPrompt]);

    const handleNewResponse = () => {
        createResponse(previewRef.current);
        previewRef.current = "";
        setPromptWithSalesData("");
    };

    const createResponse = (content: string) => {
        props.onResponseChange(getProductAiResponse(itemPrompt, content));
    };

    const handleGetProductSales = () => {
        let data: ProductSearchGroupModel = {
            groupType: "month",
            limit: 12,
            productId: props.product.id,
            productVariantId: props.productVariant?.id,
            organizationId: "",
            dateStart: new Date(),
            dateEnd: new Date(),
            accountId: "",
            minutesOffset: new Date().getTimezoneOffset(),
        };
        groupMutation.mutate(data);
    };

    return (
        <NavigationCard {...props} defaultClose allowClose title="Análisis de Ventas">
            <Flex column gap={20}>
                <div style={{ display: "none" }}>
                    {promptWithSalesData && (
                        <ChatResponsePlayground
                            btnTitle={response?.content ? "Generar Nuevo" : "Generar"}
                            onResponse={(value) => {
                                setResponsePreview(value);
                                previewRef.current = value;
                            }}
                            autoLoad
                            onCompleted={handleNewResponse}
                            prompt={promptWithSalesData}
                        />
                    )}
                </div>
                <PrimaryGradientButton rounded onClick={handleGetProductSales}>
                    <i className="fa-regular fa-wand-magic-sparkles" /> Generar Análisis
                    {groupMutation.isLoading && <LoadingDualRing small />}
                </PrimaryGradientButton>
                {response && (
                    <Flex column>
                        <TextField small light>
                            Repuesta generada: {getDateFromNow(response.createdAt)}
                        </TextField>
                    </Flex>
                )}

                {parse(responsePreview)}
                {!responsePreview && response && parse(response.content)}
            </Flex>
        </NavigationCard>
    );
};

export function ImageAnalysisSummary(props: ProductNavigationProfileProps) {
    const { appSettingsState, appSettingsActions } = useAppSettingsContext();
    const [imageSummaryBody, setImageSummaryBody] = useState<ImageSummaryBody>();

    useEffect(() => {
        var images = props.product.images;
        try {
            if (images && images.length > 0) {
                let body: ImageSummaryBody = {
                    brands: [],
                    descriptions: [],
                    colors: [],
                    tags: [],
                    isAdult: false,
                };
                let results = 0;
                let adultSum = 0;
                images.forEach((image) => {
                    if (image.imageAnalysisAsString) {
                        var result: ImageAnalysisResult = JSON.parse(image.imageAnalysisAsString);
                        if (result.brands && result.brands.length > 0) {
                            body.brands = [...body.brands, ...result.brands.map((x) => x.name)];
                        }
                        if (result.color) {
                            let colors = ["#" + result.color.accentColor, ...result.color.dominantColors];
                            body.colors = [...body.colors, ...colors];
                        }
                        if (result.description && result.description.captions && result.description.captions.length > 0) {
                            var values = result.description.captions.map((x) => x.text);
                            body.descriptions = [...body.descriptions, ...values];
                        }
                        if (result.adult?.isAdultContent) {
                            adultSum++;
                        }
                        if (result.tags) {
                            var tags = result.tags.map((x) => x.name);
                            body.tags = [...body.tags, ...tags];
                        }
                        results++;
                    }
                });

                if (results > 0) {
                    body.isAdult = adultSum > 0;
                    body.brands = _.uniq(body.brands);
                    body.descriptions = _.uniq(body.descriptions);
                    body.tags = _.uniq(body.tags);
                    body.colors = _.uniq(body.colors);
                    setImageSummaryBody(body);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }, [props.product.images]);

    if (!imageSummaryBody) return null;

    const getTags = () => {
        if (imageSummaryBody && imageSummaryBody.tags.length > 0) {
            if (appSettingsState.imageAnalysisTagsAsHashtag) {
                return imageSummaryBody.tags.map((x) => "#" + x.replaceAll("-", "").replaceAll(" ", "")).join(" ");
            } else {
                return imageSummaryBody.tags.map((x) => capitalize(x).replaceAll("-", " ")).join(", ");
            }
        } else {
            return "---";
        }
    };

    return (
        <NavigationCard {...props} title="Análisis de imágenes">
            {imageSummaryBody && (
                <>
                    <Flex column gap15>
                        <Flex column gap10>
                            <GroupButtonRow
                                smallView
                                activeOption={appSettingsState.imageAnalysisTagsAsHashtag ?? false}
                                onClickOption={(option) =>
                                    appSettingsActions.setAllSettings({
                                        ...appSettingsState,
                                        imageAnalysisTagsAsHashtag: option.value as boolean,
                                    })
                                }
                                options={[
                                    { title: "Etiquetas", value: false },
                                    { title: "Hashtags", value: true },
                                ]}
                            />
                            <span>{getTags()}</span>
                        </Flex>

                        {imageSummaryBody.brands.length > 0 && <TextGroup text1="Marcas" text2={imageSummaryBody.brands.join(", ")} />}
                        {imageSummaryBody.descriptions.length > 0 && (
                            <Flex column>
                                <span className="text-light">Descripciones automáticas</span>
                                <Flex column>
                                    {imageSummaryBody.descriptions.map((item, index) => (
                                        <span key={index}>- {item}</span>
                                    ))}
                                </Flex>
                            </Flex>
                        )}

                        {imageSummaryBody.colors.length > 0 && (
                            <Flex column gap5>
                                <span className="text-light">Colores</span>
                                <Flex>
                                    {imageSummaryBody.colors.map((color) => (
                                        <Flex w100 column gap5>
                                            <span className="text-light text-small">{color}</span>
                                            <ImageColorLabel
                                                style={{
                                                    background: color,
                                                }}
                                            ></ImageColorLabel>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </>
            )}
        </NavigationCard>
    );
}
interface IPromptsGPT {
    id: string;
    theme: string;
    prompt: string;
}

const promptSaleAnalysis: IPromptsGPT = {
    id: "saleAnalysis",
    theme: "Análisis de Ventas",
    prompt: `"Realiza un análisis detallado de las ventas del producto '[productName]'. Incluye lo siguiente: 
    1. Identifica el mes con mayores ventas y explica por qué fue el más exitoso.
    2. Determina el mes con menores ventas y analiza posibles razones para este rendimiento.
    3. Destaca consideraciones importantes que se desprenden de los datos de ventas, como tendencias estacionales o eventos atípicos.
    4. Proporciona un análisis de datos que incluya patrones o anomalías notables.
    5. Ofrece sugerencias basadas en el análisis para mejorar el rendimiento de ventas en el futuro."
    `,
};

const promptsGPT: IPromptsGPT[] = [
    {
        id: "1",
        theme: "Creación de Contenido en las Redes Sociales",
        prompt: "Crea 2 publicaciones para redes sociales que puedan ser usadas para promocionar el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "2",
        theme: "Crear anuncio publicitario",
        prompt: "Crea una anuncio publicitario para redes sociales que pueda ser usado para promocionar el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "3",
        theme: "Generación Automática de Reseña del producto",
        prompt: "Genera una reseña de producto positiva que podría ser escrita por un cliente satisfecho para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "4",

        theme: "Análisis de Tendencias del Mercado",
        prompt: "Genera un Análisis de Tendencias del Mercado para un producto de la categoría '[category]' con el nombre '[productName]'.",
    },
    {
        id: "5",
        theme: "Estrategias de Precios",
        prompt: "Proporciona Estrategias de Precios competitivas para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "6",
        theme: "Desarrollo de Producto",
        prompt: "Sugiere mejoras o nuevas características para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "7",
        theme: "Análisis de la Competencia",
        prompt: "Realiza un Análisis de la Competencia para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "8",
        theme: "Estrategias de Distribución y Logística",
        prompt: "Describe Estrategias de Distribución y Logística óptimas para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "9",
        theme: "Marketing de Influencia y Colaboraciones",
        prompt: "Identifica oportunidades de Marketing de Influencia y Colaboraciones para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "10",
        theme: "Estrategias de Fidelización de Clientes",
        prompt: "Desarrolla Estrategias de Fidelización de Clientes para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "11",
        theme: "Análisis SWOT",
        prompt: "Realiza un Análisis SWOT (Fortalezas, Debilidades, Oportunidades, Amenazas) para el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "12",
        theme: "Investigación de Mercado",
        prompt: "Diseña una Investigación de Mercado para obtener feedback sobre el producto '[productName]' en la categoría '[category]'.",
    },
    {
        id: "13",
        theme: "Estrategias de Responsabilidad Social Corporativa (RSC)",
        prompt: "Propone cómo el producto '[productName]' en la categoría '[category]' puede participar en iniciativas de Responsabilidad Social Corporativa (RSC).",
    },
];
