import _ from "lodash";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CreateTable from "react-select/creatable";
import { ProductCreationProps } from "..";
import { ProductOptional, ProductOptionalItem } from "../../../../api/models/product";
import { capitalize, getUniqueId } from "../../../../utils";
import { useLocalStorage } from "../../../../utils/hooks";
import { getSelectOption } from "../../../../utils/select";
import Dropdown from "../../../Dropdown";
import { SelectBody } from "../../../Select/styled";
import TextBoxNumeral from "../../../TextBoxNumeral";
import WahioCloudImageSelector from "../../../WahioCloud/WahioCloudImageSelector";
import { DefaultButton, DefaultInput, Flex, SquareButton, TextField } from "../../../_controls";
import { ProductAddOption } from "../../ProductCard";
import ProductSearchListModal from "../../ProductSearchList/ProductSearchListModal";
import { getProductThumbnailUrl } from "../../helper";
import { PRODUCT_OPTIONALS } from "../helpers";
import { DragProductOptionalItem, ProductOptionalCardStyle, ProductOptionalItemRowStyle } from "../styled";

interface ProductOptionalRowProps {
    index: number;
    totalItems: number;
    optional: ProductOptional;
    onChange: (value: ProductOptional) => void;
    customOptions: string[];
    newCustomOption: (value: string) => void;
    onRemove: () => void;
    onMoveOption: (direction: "up" | "down") => void;
}

export default function ProductOptionalsForm(props: ProductCreationProps) {
    const [customOptions, setOptions] = useLocalStorage<string[]>("OPTIONAL_NAMES", []);

    const onChangeOptions = (value: string) => {
        if (value) {
            let newOptions = [capitalize(value), ...customOptions];
            newOptions = _.uniq(newOptions);
            setOptions(newOptions.slice(0, 20));
        }
    };

    const onChangeOption = (value: ProductOptional, index: number) => {
        let featuresCopy = [...(props.product.optionals ?? [])];
        featuresCopy[index] = value;
        props.onChange({ ...props.product, optionals: featuresCopy });
    };

    const totalItems = props.product.optionals?.length ?? 0;

    const addOption = () => {
        const value: ProductOptional = {
            id: "",
            name: "",
            number: totalItems + 1,
            maxSelection: 1,
            minSelection: 1,
            extraPrice: 0,
            items: [getNewItem()],
        };
        let optionals = [...(props.product.optionals ?? []), value];
        props.onChange({ ...props.product, optionals });
    };

    const onRemoveOption = (index: number) => {
        let optionals = [...(props.product.optionals ?? [])];
        optionals.splice(index, 1);
        props.onChange({ ...props.product, optionals });
    };

    const onMoveOption = (optionIndex: number, direction: "up" | "down") => {
        //move the option on the position of optionIndex to up or down
        let optionals = [...(props.product.optionals ?? [])];
        let option = optionals[optionIndex];
        optionals.splice(optionIndex, 1);
        if (direction === "up") {
            optionals.splice(optionIndex - 1, 0, option);
        }
        if (direction === "down") {
            optionals.splice(optionIndex + 1, 0, option);
        }
        props.onChange({ ...props.product, optionals });
    };

    return (
        <Flex column gap={20} marginTop={20}>
            {props.product.optionals?.map((item, index) => {
                return (
                    <ProductOptionalRow
                        key={index}
                        totalItems={totalItems}
                        index={index}
                        customOptions={customOptions}
                        newCustomOption={onChangeOptions}
                        optional={item}
                        onChange={(value) => onChangeOption(value, index)}
                        onRemove={() => onRemoveOption(index)}
                        onMoveOption={(direction) => onMoveOption(index, direction)}
                    />
                );
            })}
            <DefaultButton borderRadius={10} bgLight onClick={() => addOption()}>
                <i className="fa-regular fa-plus"></i> Opción
            </DefaultButton>
        </Flex>
    );
}

const ProductOptionalRow = (props: ProductOptionalRowProps) => {
    const { optional, onChange, customOptions, newCustomOption } = props;

    const featureOptions = [...customOptions, ...PRODUCT_OPTIONALS];

    const addItem = () => {
        const totalItems = optional.items?.length ?? 0;
        let value = getNewItem();
        value.position = totalItems + 1;
        let optionals = [...(optional.items ?? []), value];
        props.onChange({ ...optional, items: optionals });
    };

    return (
        <ProductOptionalCardStyle>
            <Flex className="card-header">
                <Flex alignCenter gap10 flexWrap>
                    <SelectBody selectMinWidth={160}>
                        <CreateTable
                            placeholder="Opción"
                            className={optional.name ? "" : ""}
                            classNamePrefix="select"
                            value={optional.name ? getSelectOption({ id: optional.name, value: optional.name }) : undefined}
                            isDisabled={false}
                            isRtl={false}
                            onCreateOption={(value) => {
                                newCustomOption(value);
                                onChange({ ...optional, name: value });
                            }}
                            onChange={(value) => onChange({ ...optional, name: value?.value ?? "" })}
                            isSearchable={true}
                            name="seller"
                            options={featureOptions.map((item) => getSelectOption({ id: item, value: item }))}
                        />
                    </SelectBody>
                    <Flex className="line-group">
                        <TextField className="line-name">Min</TextField>
                        <TextBoxNumeral
                            value={optional.minSelection}
                            onNumberChange={(value) => onChange({ ...optional, minSelection: value })}
                            format={"number"}
                        />
                    </Flex>
                    <Flex className="line-group">
                        <TextField className="line-name">Max</TextField>
                        <TextBoxNumeral
                            value={optional.maxSelection}
                            onNumberChange={(value) => onChange({ ...optional, maxSelection: value })}
                            format={"number"}
                        />
                    </Flex>
                </Flex>

                <Flex gap10 alignCenter marginLeftAuto>
                    <SquareButton onClick={() => addItem()}>
                        <i className="fa-regular fa-plus"></i>
                    </SquareButton>
                    <Dropdown icon="fa-regular fa-ellipsis-v" contentHorizontal="left">
                        <Flex column padding={15} gap10>
                            <DefaultButton className="dropdown-button" onClick={() => props.onRemove()}>
                                <i className="fa-regular fa-trash"></i> Eliminar
                            </DefaultButton>
                            {props.index > 0 && (
                                <DefaultButton className="dropdown-button" onClick={() => props.onMoveOption("up")}>
                                    <i className="fa-regular fa-arrow-up"></i> Mover
                                </DefaultButton>
                            )}

                            {props.index < props.totalItems - 1 && (
                                <DefaultButton className="dropdown-button" onClick={() => props.onMoveOption("down")}>
                                    <i className="fa-regular fa-arrow-down"></i> Mover
                                </DefaultButton>
                            )}
                        </Flex>
                    </Dropdown>
                </Flex>
            </Flex>

            <ProductOptionalItemsPanel optional={optional} onChange={onChange} />
        </ProductOptionalCardStyle>
    );
};

interface ProductOptionalItemsPanelProps {
    optional: ProductOptional;
    onChange: (value: ProductOptional) => void;
}

const getNewItem = () => {
    const value: ProductOptionalItem = {
        id: getUniqueId(),
        position: 1,
        image: "",
        name: "",
        price: 0,
        relatedProductId: "",
        relatedProductQuantity: 0,
        maxSelection: 1,
    };
    return value;
};

const reorder = (list: ProductOptionalItem[] = [], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ProductOptionalItemsPanel = (props: ProductOptionalItemsPanelProps) => {
    const { optional } = props;
    const onChangeItem = (value: ProductOptionalItem, index: number) => {
        let newItems = [...(optional.items ?? [])];
        newItems[index] = value;
        props.onChange({ ...optional, items: newItems });
    };

    const onRemoveItem = (index: number) => {
        let newItems = [...(optional.items ?? [])];
        newItems.splice(index, 1);
        newItems = newItems.map((item, index) => ({ ...item, position: index + 1 }));
        props.onChange({ ...optional, items: newItems });
    };

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let itemsOrdered = reorder(optional.items ?? [], result.source.index, result.destination.index);
        itemsOrdered = itemsOrdered.map((item, index) => {
            item.position = index + 1;
            return item;
        });
        props.onChange({ ...optional, items: itemsOrdered });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                    <Flex column ref={provided.innerRef} {...provided.droppableProps}>
                        {optional.items?.map((item, index: number) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <DragProductOptionalItem
                                        style={provided.draggableProps.style}
                                        isDragging={snapshot.isDragging}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <ProductOptionalItemRow
                                            onRemove={() => onRemoveItem(index)}
                                            key={index}
                                            item={item}
                                            onChange={(value) => onChangeItem(value, index)}
                                        />
                                    </DragProductOptionalItem>
                                )}
                            </Draggable>
                        ))}

                        {provided.placeholder}
                    </Flex>
                )}
            </Droppable>
        </DragDropContext>
    );
};

interface ProductOptionalItemRowProps {
    item: ProductOptionalItem;
    onChange: (value: ProductOptionalItem) => void;
    onRemove: () => void;
}

const ProductOptionalItemRow = (props: ProductOptionalItemRowProps) => {
    const { item, onChange, onRemove } = props;

    const [showProductModal, setShowProductModal] = useState(false);

    const onClickProduct = (value: ProductAddOption) => {
        var image = getProductThumbnailUrl(value.product);
        onChange({
            ...item,
            relatedProductId: value.product.id,
            image,
            name: value.product.name,
            price: value.product.price,
            relatedProductQuantity: 1,
        });
        setShowProductModal(false);
    };

    return (
        <>
            {showProductModal && (
                <ProductSearchListModal onClickProduct={onClickProduct} show={showProductModal} setShow={setShowProductModal} />
            )}
            <ProductOptionalItemRowStyle>
                <WahioCloudImageSelector width={40} image={item.image} onSelectImage={(image) => onChange({ ...item, image })} />
                <DefaultInput placeholder="Descripción" value={item.name} onChange={(e) => onChange({ ...item, name: e.target.value })} />
                <TextBoxNumeral
                    className="item-price"
                    value={item.price}
                    onNumberChange={(value) => onChange({ ...item, price: value })}
                    format={"money"}
                />

                <Flex className="line-group">
                    <TextField className="line-name">Max</TextField>
                    <TextBoxNumeral
                        value={item.maxSelection}
                        onNumberChange={(value) => onChange({ ...item, maxSelection: value })}
                        format={"number"}
                        autoComplete="off"
                    />
                </Flex>
                <Flex alignCenter gap10 flexWrap marginLeftAuto>
                    {item.relatedProductId && (
                        <>
                            <TextBoxNumeral
                                title="Cantidad de productos relacionados"
                                className="item-quantity"
                                value={item.relatedProductQuantity}
                                onNumberChange={(value) => onChange({ ...item, relatedProductQuantity: value })}
                                format={"number"}
                            />
                            <i className={"fa-regular fa-link-simple"}></i>
                        </>
                    )}

                    <Dropdown icon="fa-regular fa-ellipsis-v" withOutBackgroundButton contentHorizontal="left">
                        <Flex column padding={15} gap10>
                            <DefaultButton className="dropdown-button" onClick={onRemove}>
                                <i className="fa-regular fa-trash"></i> Eliminar
                            </DefaultButton>

                            <DefaultButton
                                className={`dropdown-button btn-item ${item.relatedProductId ? "active-product" : ""}`}
                                onClick={() => setShowProductModal(true)}
                            >
                                <i className={!item.relatedProductId ? "fa-regular fa-link-simple" : "fa-regular fa-link-simple-slash"}></i>
                                {item.relatedProductId ? "Desconectar" : "Conectar"}
                            </DefaultButton>
                        </Flex>
                    </Dropdown>
                </Flex>
            </ProductOptionalItemRowStyle>
        </>
    );
};
