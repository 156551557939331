export interface TaxWithholdingType {
    minimumBase: number;
    concept: string;
    percentage: number;
}

export const COLOMBIA_TAX_WITH_HOLDING : TaxWithholdingType[] = [
    {
        concept: "Compras generales (declarantes)",
        minimumBase: 1026000,
        percentage: 2.5,
    },
    {
        concept: "Compras generales (no declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Compras con tarjeta débito o crédito",
        minimumBase: 1,
        percentage: 1.5,
    },
    {
        concept: "Compras de bienes o productos agrícolas o pecuarios sin procesamiento industrial",
        minimumBase: 3496000,
        percentage: 1.5,
    },
    {
        concept: "Compras de bienes o productos agrícolas o pecuarios con procesamiento industrial (declarantes)",
        minimumBase: 1026000,
        percentage: 2.5,
    },
    {
        concept: "Compras de bienes o productos agrícolas o pecuarios con procesamiento industrial declarantes (no declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Compras de café pergamino o cereza",
        minimumBase: 6081000,
        percentage: 0.5,
    },
    {
        concept: "Compras de combustibles derivados del petróleo",
        minimumBase: 10,
        percentage: 0.1,
    },
    {
        concept: "Enajenación de activos fijos de personas naturales (notarías y tránsito son agentes retenedores)",
        minimumBase: 10,
        percentage: 1,
    },
    {
        concept: "Compras de vehículos",
        minimumBase: 10,
        percentage: 1,
    },
    {
        concept:
            "Compras de bienes raíces cuya destinación y uso sea vivienda de habitación (por las primeras 20000 UVT, es decir hasta $726160000)",
        minimumBase: 10,
        percentage: 1,
    },
    {
        concept:
            "Compras de bienes raíces cuya destinación y uso sea vivienda de habitación (exceso de las primeras 20000 UVT, es decir superior a $726160,000)",
        minimumBase: 760080000,
        percentage: 2.5,
    },
    {
        concept: "Compras de bienes raíces cuya destinación y uso sea distinto a vivienda de habitación",
        minimumBase: 10,
        percentage: 2.5,
    },
    {
        concept: "Servicios generales (declarantes)",
        minimumBase: 15200,
        percentage: 4,
    },
    {
        concept: "Servicios generales (no declarantes)",
        minimumBase: 15200,
        percentage: 6,
    },
    {
        concept: "Por emolumentos eclesiásticos (declarantes)",
        minimumBase: 1026000,
        percentage: 4,
    },
    {
        concept: "Por emolumentos eclesiásticos (no declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Servicios de transporte de carga",
        minimumBase: 15200,
        percentage: 1,
    },
    {
        concept: "Servicios de transporte nacional de pasajeros por vía terrestre (declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Servicios de transporte nacional de pasajeros por vía terrestre (no declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Servicios de transporte nacional de pasajeros por vía aérea o marítima",
        minimumBase: 15200,
        percentage: 1,
    },
    {
        concept: "Servicios prestados por empresas de servicios temporales (sobre AIU)",
        minimumBase: 15200,
        percentage: 1,
    },
    {
        concept: "Servicios prestados por empresas de vigilancia y aseo (sobre AIU)",
        minimumBase: 15200,
        percentage: 2,
    },
    {
        concept: "Servicios integrales de salud prestados por IPS",
        minimumBase: 15200,
        percentage: 2,
    },
    {
        concept: "Servicios de hoteles y restaurantes (declarantes)",
        minimumBase: 15200,
        percentage: 3.5,
    },
    {
        concept: "Servicios de hoteles y restaurantes (no declarantes)",
        minimumBase: 15200,
        percentage: 3.5,
    },
    {
        concept: "Arrendamiento de bienes muebles",
        minimumBase: 10,
        percentage: 4,
    },
    {
        concept: "Arrendamiento de bienes inmuebles (declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Arrendamiento de bienes inmuebles (no declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Otros ingresos tributarios (declarantes)",
        minimumBase: 1026000,
        percentage: 2.5,
    },
    {
        concept: "Otros ingresos tributarios (no declarantes)",
        minimumBase: 1026000,
        percentage: 3.5,
    },
    {
        concept: "Honorarios y comisiones (personas jurídicas)",
        minimumBase: 10,
        percentage: 11,
    },
    {
        concept:
            "Honorarios y comisiones pagados a personas naturales que suscriban contratos por más de 3300 Uvt o que la sumatoria de los pagos o abonos en cuenta durante el año gravable superen 3300 UVT ($119816000)",
        minimumBase: 10,
        percentage: 11,
    },
    {
        concept: "Honorarios y comisiones (no declarantes)",
        minimumBase: 10,
        percentage: 1,
    },
    {
        concept: "Servicios de licenciamiento o derecho de uso de software",
        minimumBase: 10,
        percentage: 3.5,
    },
    {
        concept: "Intereses o rendimientos financieros",
        minimumBase: 10,
        percentage: 7,
    },
    {
        concept: "Rendimientos financieros provenientes de títulos de renta fija",
        minimumBase: 10,
        percentage: 4,
    },
    {
        concept: "Loterías, rifas, apuestas y similares",
        minimumBase: 1824000,
        percentage: 2,
    },
    {
        concept: "Retención en colocación independiente de juegos de suerte y azar",
        minimumBase: 19000,
        percentage: 3,
    },
    {
        concept: "Contratos de construcción y urbanización",
        minimumBase: 1026000,
        percentage: 2,
    },
];
