import { encrypted, decrypted } from "./crypto";
import customNumeral from "./numberFormat/moneyFormat";
import customMoment from "./momentFormat/dateFormat";
import { getFetchError } from "../api/fetchApi";
import { BusinessException } from "../api/models";
import { deleteData, LocalStoreKeys } from "../store/index";
import { useIntl } from "react-intl";
import MessagesDate from "./dates/messages";
import { v4 as uuid } from "uuid";
import React from "react";

export function useFirstRender() {
    const ref = React.useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
}

export const getYouTubeUrl = (url: string) => {
    const videoId = url.match(/v=([^&]+)/);
    if (videoId && videoId.length > 1) {
        return `https://www.youtube.com/embed/${videoId[1]}`;
    }
    return "";
};

export const removeHtmlTags = (str: string) => {
    if (!str) {
        return "";
    }
    var resultHtml = str.replace(/style="[^"]*(background|color|font-family)\s*:\s*[^;]+;?/gi, "");
    return resultHtml;
};

export function assignDeepValue(obj: any, prop: any, value: any) {
    if (typeof prop === "string") prop = prop.split(".");
    if (prop.length > 1) {
        var e = prop.shift();
        assignDeepValue((obj[e] = Object.prototype.toString.call(obj[e]) === "[object Object]" ? obj[e] : {}), prop, value);
    } else obj[prop[0]] = value;
}

export const deleteLocalStorage = () => {
    for (const property in LocalStoreKeys) {
        deleteData(property);
    }
};

export const getModelFullName = (model?: { firstName: string; lastName: string }, defaultValue?: string) => {
    return model ? model.firstName + " " + model.lastName : defaultValue ?? "";
};

export const getUtcDate = (stringDate?: string) => {
    if (!stringDate) return new Date();
    if (stringDate[stringDate.length - 1] === "Z") return new Date(stringDate);
    return new Date(`${stringDate}Z`);
};
export const datetime7FormatUtc = `YYYY-MM-DDTHH:mm:ss:sssssssZ`;

export const getDateFromNow = (value?: Date | string) => {
    if (!value) return " - ";
    const dateUtc = getUtcDate(value.toString());
    return customMoment(dateUtc).fromNow();
};

export const getDateFormatCalendar = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).calendar();
};

export const DateFormatCalendar = ({ value, hideTime }: { value?: Date | string; hideTime?: boolean }) => {
    const intl = useIntl();
    if (!value) return " - ";
    if (hideTime) {
        return customMoment(getUtcDate(value.toString())).calendar(null, {
            lastDay: `[${intl.formatMessage(MessagesDate.yesterday)}]`,
            sameDay: `[${intl.formatMessage(MessagesDate.today)}]`,
            nextDay: `[${intl.formatMessage(MessagesDate.tomorrow)}]`,
            lastWeek: `[${intl.formatMessage(MessagesDate.last)}] dddd`,
            nextWeek: "dddd",
            sameElse: "L",
        });
    }
    return customMoment(getUtcDate(value.toString())).calendar();
};

export const getDateFormatL = (value?: Date | string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("L");
};
export const getDateFormatLLLL = (value?: Date | string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("LLLL");
};
export const getDateFormatll = (value?: Date | string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("ll");
};
export const getDateFormatlll = (value?: Date | string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("lll");
};
export const getDateFormatllll = (value?: Date | string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("llll");
};
export const getDateFormat = (value?: Date | string, format?: string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format(format);
};

export const getDateFormatLocal = (value: Date | string) => {
    return customMoment(value).format();
};

export const getTimeInterval = (end?: Date | string, start?: Date | string) => {
    if (!end || !start) return "";

    const endDate = customMoment(getUtcDate(end.toString()));
    const startDate = customMoment(getUtcDate(start.toString()));
    var duration = customMoment.duration(endDate.diff(startDate));
    var timeInterval = Math.abs(Math.round(duration.asMinutes()));

    if (timeInterval <= 60) {
        return `${timeInterval} min`;
    }

    let hoursAndMinutes = Math.abs(duration.asHours());
    let hours = Math.abs(Math.trunc(hoursAndMinutes));
    let minutes = Math.abs(Math.trunc((hoursAndMinutes % hours) * 60));

    return `${hours}h, ${minutes} min`;
};

export const getTimeIntervalSeconds = (end?: Date | string, start?: Date | string) => {
    if (!end || !start) return 0;
    const endDate = customMoment(getUtcDate(end.toString()));
    const startDate = customMoment(getUtcDate(start.toString()));
    var duration = customMoment.duration(endDate.diff(startDate));
    var seconds = Math.abs(Math.trunc(duration.asSeconds()));
    return seconds;
};

export const joinUrl = (base: string, value: string) => {
    if (base && base.charAt(base.length - 1) === "/") {
        return base + value;
    } else return `${base}/${value}`;
};

export const addParamToUrl = (urlBase: string, paramName: string, paramValue: string) => {
    var url = new URL(urlBase);
    var search_params = url.searchParams;
    search_params.set(paramName, paramValue);
    url.search = search_params.toString();

    return url.toString();
};

export const isBunissesException = (data: any) => {
    return data && data.type && data.message && data.code;
};

export const setCookieAlreadyEncrypted = (name: string, value: any) => {
    if (value) {
        var cookie = name + "=" + value + "; Max-Age=2592000";
        document.cookie = cookie;
    }
};

export const setCookie = (name: string, value: any) => {
    if (value) {
        var cookie = name + "=" + encrypted(JSON.stringify(value)) + "; Max-Age=2592000";
        document.cookie = cookie;
    }
};

export const getEncryptedCookie = (name: string, keepEncryption?: boolean) => {
    var cookieArr = document.cookie.split(";");

    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name === cookiePair[0].trim()) {
            const cookieValue = cookiePair[1];
            if (keepEncryption) return cookieValue;
            const decryptedResult = decrypted(cookieValue);
            if (decryptedResult) return JSON.parse(decryptedResult);
            return null;
        }
    }
    return null;
};
export const deleteCookie = (name: string) => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const capitalize = (value: string) => {
    if (typeof value !== "string") return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const removeFirstCapitalize = (value: string) => {
    if (typeof value !== "string") return "";
    return value.charAt(0).toLowerCase() + value.slice(1);
};

export const stringIsNullOrEmpty = (value?: string) => {
    return !value || value === "" || value === null;
};

export const formatMoney = (value?: string | number) => {
    let number = customNumeral(value ? value : 0);
    return number.format(`$0,0.[00]`);
};

export const formatNumber = (value: string | number) => {
    var number = customNumeral(value);
    return number.format("0,0.[00]");
};

export const getComparisonPercentage = (newValue?: number, previousValue?: number) => {
    if (!newValue || !previousValue || previousValue === 0) return 0;

    const difference = newValue - previousValue;
    return getRoundNumber((difference * 100) / previousValue);
};

export const getUniqueId = () => {
    return uuid();
};

export const wahioStringFormat = (value: string, ...params: any[]) => {
    return value.replace(/{(\d+)}/g, function (match, number) {
        return typeof params[number] != "undefined" ? params[number] : match;
    });
};

export const getExceptionFromError = (error: any) => {
    var errResult = getFetchError(error);
    let data = errResult.data ? errResult.data : {};
    if (typeof data === "string") {
        data = { message: data };
    }
    return new BusinessException(data.code, data.message, data.type, errResult.status);
};

export const listenerContainerResize = (setContainerWidth: (value: number) => void, containerId: string) => {
    let element = document.getElementById(containerId);
    if (!element) return;
    window.addEventListener("resize", containerResize);
    function containerResize() {
        if (element) setContainerWidth(element?.clientWidth);
    }
};

export const listenerContainerPosition = (setContainerWidth: (value: number) => void, containerId: string) => {
    let element = document.getElementById(containerId);
    if (!element) return;
    element.addEventListener("resize", containerResize);
    function containerResize() {
        if (element) setContainerWidth(element?.getBoundingClientRect().x);
    }
};

export const getInitialWidth = (containerId: string) => {
    let element = document.getElementById(containerId);
    return element ? element.clientWidth : 1000;
};

export const getInitialPosition = (containerId: string) => {
    let element = document.getElementById(containerId);
    return element ? element?.getBoundingClientRect().x : 1000;
};

export const getRoundNumber = (value?: number) => {
    if (value) {
        return Math.round(value * 100) / 100;
    }
    return 0;
};

export const bytesToSize = (bytes: number) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) + "");
    return getRoundNumber(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export const ignoreElementClick = (element: any, elementId: string): boolean => {
    if (element) {
        if (element.id === elementId) return true;
        else return ignoreElementClick(element.parentElement, elementId);
    }
    return false;
};

export const ignoreElementClickByIds = (element: any, elementIds: string[]): boolean => {
    if (element) {
        if (elementIds.includes(element.id)) return true;
        else return ignoreElementClickByIds(element.parentElement, elementIds);
    }
    return false;
};
