import _ from "lodash";
import React from "react";
import { getColorValue } from "../../../constants/colors";
import { IWarehouseLocation } from "../../../api/models/warehouseLocation";
import Modal, { IModalShow } from "../../modals/Modal";
import { WarehouseLocationSelectorStyle, WarehouseLocationSelectButton } from "./styled";

interface WarehouseLocationSelectModalProps extends IModalShow {
    warehouseLocations: IWarehouseLocation[];
    stockMin: number;
    onClickLocation: (location: IWarehouseLocation) => void;
}

export default function WarehouseLocationSelectModal(props: WarehouseLocationSelectModalProps) {
    return (
        <Modal
            id="warehouse-location"
            scrollInside={false}
            overflowAuto={false}
            title={"Cambiar ubicación manualmente"}
            showHeader={true}
            {...props}
        >
            <WarehouseLocationSelectorStyle>
                {_.sortBy(props.warehouseLocations, ["globalStock"])
                    .reverse()
                    .map((location) => {
                        const color = getColorValue(location.color);
                        return (
                            <WarehouseLocationSelectButton
                                onClick={() => props.onClickLocation(location)}
                                disabled={location.globalStock < props.stockMin}
                                color={color}
                            >
                                {location.code} ({location.globalStock})
                            </WarehouseLocationSelectButton>
                        );
                    })}
            </WarehouseLocationSelectorStyle>
        </Modal>
    );
}
