import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SelectBaseOption } from "../../../api/models";
import { IProduct, IProductVariant, ProductOptionalsConfig, ProductPriceTypes } from "../../../api/models/product";
import { IWarehouse } from "../../../api/models/warehouse";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { formatMoney } from "../../../utils";
import { SelectBody } from "../../Select/styled";
import { Flex, TextField } from "../../_controls";
import { getPriceWithoutDiscount, getProductSku, getProductThumbnailUrl } from "../helper";
import ComposeIcon from "./ComposeIcon";

import {
    ActiveQuantity,
    CardContainer,
    ProductCardFakeContent,
    ProductContainer,
    ProductPriceContainer,
    ProductTitle,
    TextContainer,
} from "./styled";

import { ProductDiscount } from "../../../api/models/discount";
import { ProductProfileModal } from "../ProductProfileNavigation/";

export interface ProductAddOption {
    productIndex: number;
    product: IProduct;
    comment?: string;
    productVariant?: IProductVariant;
    optionalsConfig?: ProductOptionalsConfig;
    warehouseProduct?: IWarehouseProduct;
    warehouse?: IWarehouse;
    stock?: number;
    quantity: number;
    customPrice: number;
    combineDuplicate?: boolean;
}
export interface ProductClickProps {
    onClickProduct: (value: ProductAddOption) => void;
    warehouse?: IWarehouse;
    allowOutOfStock?: boolean;
}
export interface ProductCardProps extends ProductClickProps {
    product?: IProduct;
    stock?: number;
    defaultPrice: ProductPriceTypes;
    warehouseProduct?: IWarehouseProduct;
    hidePrice?: boolean;
    activeUnits?: number;
    isMovement?: boolean;
    origin?: string;
    index: number;
    disabled?: boolean;
}

export const PRODUCT_SQUARE_LIST_CARD = "PRODUCT_SQUARE_LIST_CARD";

export default function ProductCard(props: ProductCardProps) {
    const [warehouseProductVariant, setWarehouseProductVariant] = useState<IWarehouseProduct | undefined>();
    const [product, setProduct] = useState(props.product);
    const [warehouseProduct, setWarehouseProduct] = useState(props.warehouseProduct);
    const [wpStock, setWpStock] = useState(props.stock);
    const [productVariant, setProductVariant] = useState<IProductVariant>();

    const [showProductProfile, setShowProductProfile] = useState(false);

    const cardRef = React.useRef<any>();

    useEffect(() => {
        if (props.product) {
            setProduct(props.product);
        }
        if (props.warehouseProduct) {
            setWarehouseProduct(props.warehouseProduct);
        }
        if (props.stock) {
            setWpStock(props.stock);
        }
    }, [props.product, props.warehouseProduct, props.stock]);

    useEffect(() => {
        if (
            props.warehouseProduct &&
            props.warehouseProduct.warehouseProductVariants &&
            props.warehouseProduct.warehouseProductVariants.length > 0
        ) {
            setWarehouseProductVariant(
                props.warehouseProduct.warehouseProductVariants.find((x) => x.productVariantId === productVariant?.id)
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productVariant]);

    useEffect(() => {
        if (product?.variants && product.variants.length > 0) {
            const variant = product.variants.find((x) => x.warehouseProducts?.find((y) => y.stock > 0));
            if (variant) {
                setProductVariant(variant);
            } else {
                setProductVariant(product.variants[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.variants]);

    if (!product) {
        return null;
    }

    const getStock = () => {
        if (warehouseProductVariant) {
            return warehouseProductVariant.stock;
        }
        return wpStock;
    };

    const getSelectOption = (item?: IProductVariant) => {
        let option: SelectBaseOption = {
            id: item?.id ?? "",
            value: item?.id ?? "",
            label: item?.valuesLine ?? "",
        };
        return option;
    };

    const stock = getStock();
    const image = getProductThumbnailUrl(product, productVariant);

    const preventClickProduct =
        !props.warehouse?.allowNegativeStock &&
        !props.allowOutOfStock &&
        (!warehouseProductVariant || warehouseProductVariant?.stock === 0) &&
        !product.isParent &&
        product.controlInventory &&
        (stock === 0 ||
            (props.origin === "movement" && product.includesSerials) ||
            (props.origin === "restaurant" && product.includesSerials));

    const outclass = preventClickProduct ? "out" : "";

    const getPrice = () => {
        if (productVariant) {
            return productVariant[props.defaultPrice];
        } else {
            return product[props.defaultPrice];
        }
    };

    const onClick = (e: any) => {
        if (preventClickProduct) return;
        else {
            props.onClickProduct({
                product,
                warehouse: props.warehouse,
                stock: stock,
                quantity: 1,
                customPrice: getPrice(),
                productVariant: productVariant,
                warehouseProduct: props.warehouseProduct,
                productIndex: props.index,
            });
        }
    };

    const getVariants = () => {
        if (warehouseProduct && warehouseProduct.warehouseProductVariants) {
            let variants: IProductVariant[] = [];
            warehouseProduct.warehouseProductVariants.forEach((element) => {
                if (element.productVariant) {
                    variants.push(element.productVariant);
                }
            });
            return variants;
        } else {
            return product.variants;
        }
    };

    const onVariantChange = (productVariantId?: string) => {
        setProductVariant(product.variants.find((x) => x.id === productVariantId));
    };

    const hasActiveProduct = !!(props.activeUnits && props.activeUnits !== 0);

    return (
        <>
            {showProductProfile && <ProductProfileModal product={product} show={showProductProfile} setShow={setShowProductProfile} />}
            <CardContainer
                ref={cardRef}
                id={PRODUCT_SQUARE_LIST_CARD + props.index}
                className={`${PRODUCT_SQUARE_LIST_CARD}  ${hasActiveProduct && !outclass ? "active" : ""}`}
            >
                <ProductContainer onClick={onClick} className={`${outclass}`}>
                    {hasActiveProduct && !outclass && (
                        <ActiveQuantity>
                            <span className="wahioicon-shopping-basket active-icon"></span>
                            {props.activeUnits}
                        </ActiveQuantity>
                    )}
                    {product.isParent && <ComposeIcon left={6} top={6} />}
                    <div className="image-container">
                        <img className="background" src={image} alt={product.name} />
                        <img className="main" src={image} alt={product.name} />
                    </div>
                    <TextContainer>
                        <ProductTitle className="title">{product.name}</ProductTitle>
                        <div className="small-description">{getProductSku(product, productVariant)}</div>
                        {!props.hidePrice && (
                            <Flex spaceBetween alignCenter gap10>
                                <ProductPriceLine price={getPrice()} discounts={product.discounts} />
                                <TextField small light>
                                    {product.controlInventory ? stock : <i className="fa-regular fa-infinity"></i>}
                                </TextField>
                            </Flex>
                        )}
                    </TextContainer>
                </ProductContainer>
                {productVariant && product.variants.length > 0 && (
                    <SelectBody className="bg-light select">
                        <Select
                            placeholder="Seleccionar"
                            className="select-small"
                            classNamePrefix="select"
                            key={productVariant?.id}
                            defaultValue={productVariant ? getSelectOption(productVariant) : undefined}
                            isDisabled={false}
                            onChange={(value) => onVariantChange(value?.id)}
                            isRtl={false}
                            isSearchable={true}
                            name="seller"
                            options={getVariants().map((item) => getSelectOption(item))}
                        />
                    </SelectBody>
                )}
            </CardContainer>
        </>
    );
}

interface ProductPriceLineProps {
    price: number;
    discounts?: ProductDiscount[];
    direction?: "row" | "column";
}
export const ProductPriceLine = (props: ProductPriceLineProps) => {
    const { price: originalProductPrice, discounts, direction = "column" } = props;

    const discountItem = React.useMemo(() => {
        if (discounts && discounts.length > 0) return discounts[0];
        return undefined;
    }, [discounts]);

    let discountValue = 0;
    let productPrice = originalProductPrice;
    let discountLine = 0;

    if (discountItem) {
        discountValue = discountItem.percentage;
        productPrice = getPriceWithoutDiscount(originalProductPrice, discountItem.percentage);
        discountLine = originalProductPrice;
    }

    return (
        <ProductPriceContainer className={`direction-${direction}`}>
            <Flex gap10 alignCenter>
                <span className="product-price">{formatMoney(productPrice)}</span>
                {discountValue > 0 && <span className="discount-value">{discountValue}%</span>}
            </Flex>
            {discountLine > 0 && <span className="discount-line">{formatMoney(discountLine)}</span>}
        </ProductPriceContainer>
    );
};

export const ProductCardFake = () => {
    return (
        <ProductCardFakeContent className={PRODUCT_SQUARE_LIST_CARD}>
            <div className="fake-image"></div>
            <div className="fake-name"></div>
            <div className="fake-price"></div>
        </ProductCardFakeContent>
    );
};

export const ProductRowFake = () => {
    return (
        <ProductCardFakeContent className="row">
            <div className="fake-image"></div>
            <Flex column gap10 w100>
                <div className="fake-name"></div>
                <div className="fake-price"></div>
            </Flex>
        </ProductCardFakeContent>
    );
};
