import React, { useState } from "react";
import { DefaultButton, Flex, TextField } from "../../_controls";
import { ModalConfirmCreateInvoice } from "./ModalConfirmCreateInvoice";
import { Order } from "../../../api/models/order";
import { getUserPermissions } from "../../../api/models/accountUser";
import { useUserContext } from "../../../store/contexts/UserContext";
import { useOrganizationContext } from "../../../store/contexts/OrganizationContext";
import PATHS from "../../../constants/paths";
import { NavLink } from "react-router-dom";
import { OrderInvoiceContainerStyle } from "./styled";

export interface OrderInvoiceViewProps {
    order: Order;
    onOrderChange: (order: Order) => void;
}

export default function OrderInvoiceView(props: OrderInvoiceViewProps) {
    const [showModalCreateInvoice, setShowModalCreateInvoice] = useState(false);
    const { userState } = useUserContext();
    const { organizationState } = useOrganizationContext();

    const permission = getUserPermissions(userState.user).order;

    return (
        <>
            {showModalCreateInvoice && (
                <ModalConfirmCreateInvoice {...props} show={showModalCreateInvoice} setShow={setShowModalCreateInvoice} />
            )}
            <OrderInvoiceContainerStyle>
                <Flex column alignCenter>
                    <TextField bold>Orden sin Facturar</TextField>
                    <TextField small>Es necesario facturar la orden para agregar pagos</TextField>
                </Flex>
                {!permission?.update && <span className="text-small text-light mt-1">No tienes permisos para facturar</span>}
                {organizationState.currentOrganization?.orderInvoiceNumerationId && permission?.update && (
                    <DefaultButton className="action-btn" onClick={() => setShowModalCreateInvoice(true)} borderRadius={10}>
                        Facturar Orden
                    </DefaultButton>
                )}
                {!organizationState.currentOrganization?.orderInvoiceNumerationId && (
                    <div>
                        <span className="text-light">
                            Necesitas crear una numeración para facturar el pedido:
                            <NavLink to={PATHS.configInvoiceNumeration}>Crear Aquí</NavLink>
                        </span>
                    </div>
                )}
            </OrderInvoiceContainerStyle>
        </>
    );
}
