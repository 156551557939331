import { joinUrl } from "../../utils";
import { WOMPI_ENDPOINT, WOMPI_PUBLIC_KEY, WOMPI_TRANSACTION_ENDPOINT } from "../appConfig";
import wahioFetchApi from "../fetchApi";
import {
    IWompiCheckTransactionResponse,
    WompiCreditCardInput,
    WompiCreditCardResponse,
    WompiMerchantData,
    WompiPaymentSourceInput,
    WompiPaymentSourceResponse,
    WompiSignatureIntegrityInputBase,
    WompiSignatureResponse,
    WompiTransactionWithPaymentSource,
} from "../models/wompi";
import { accountEndpoint } from "../restApiEndpoint";

const wompiTokenEndpoint = () => {
    return joinUrl(WOMPI_ENDPOINT, `merchants/${WOMPI_PUBLIC_KEY}`);
};

const WOMPI_TOKENS_CARD = joinUrl(WOMPI_ENDPOINT, `tokens/cards`);

export const getWompiTransaction = (data: string): Promise<IWompiCheckTransactionResponse> => {
    return fetch(joinUrl(WOMPI_TRANSACTION_ENDPOINT, data)).then((response) => response.json());
};

export const getWompiToken = (): Promise<WompiMerchantData> => {
    return fetch(wompiTokenEndpoint()).then((response) => response.json());
};

export const createWompiCreditCard = (input: WompiCreditCardInput): Promise<WompiCreditCardResponse> => {
    return fetch(WOMPI_TOKENS_CARD, {
        method: "post",
        headers: {
            Authorization: `Bearer ${WOMPI_PUBLIC_KEY}`,
        },
        body: JSON.stringify(input),
    }).then((response: any) => response.json());
};

export const createWompiPaymentSource = (data: WompiPaymentSourceInput): Promise<WompiPaymentSourceResponse> => {
    return wahioFetchApi.post(accountEndpoint.post.wompiPaymentSource, data).then((res: any) => res.data);
};

export const createWompiPaymentSourceTransaction = (data: WompiTransactionWithPaymentSource): Promise<IWompiCheckTransactionResponse> => {
    return wahioFetchApi.post(accountEndpoint.post.wompiPaymentSourceTransaction, data).then((res: any) => res.data);
};

export const getWompiIntegritySignature = (data: WompiSignatureIntegrityInputBase): Promise<WompiSignatureResponse> => {
    return wahioFetchApi.post(accountEndpoint.post.wompiSignatureIntegrity, data).then((res: any) => res.data);
};
