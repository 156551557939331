import React, { useState } from "react";
import { Order } from "../../../../api/models/order";
import TextareaChat from "../../../Chats/ChatContainer/TextareaChat";
import { Flex, SquareButton, TextField } from "../../../_controls";
import NotificationCard from "./NotificationOrderCard";

interface NotificationMessageCardProps {
    order: Order;
    onOrderChange: (order: Order) => void;
}

export default function NotificationMessageCard(props: NotificationMessageCardProps) {
    const { order } = props;

    const [showObservation, setShowObservation] = useState(true);

    return (
        <>
            {(!order.id || order.status !== "draft") && <NotificationCard order={order} onOrderChange={props.onOrderChange} />}

            {!order.id && (
                <Flex column gap5>
                    <Flex alignCenter gap5 cursorPointer onClick={() => setShowObservation(!showObservation)}>
                        <SquareButton removeBackground small className={`btn-notification`}>
                            {showObservation ? (
                                <span className={`fa-regular fa-message`}></span>
                            ) : (
                                <span className={`fa-regular fa-message-slash`}></span>
                            )}
                        </SquareButton>
                        <TextField small bold>
                            Comentario
                        </TextField>
                    </Flex>
                    {showObservation && (
                        <TextareaChat
                            hideSendButton
                            removePadding
                            withBorder
                            initialValue={order.comment}
                            placeholder="Ingresa una comentario"
                            onInputChange={(value) => props.onOrderChange({ ...props.order, comment: value })}
                            onSendMessage={() => {}}
                        />
                    )}
                </Flex>
            )}
        </>
    );
}
