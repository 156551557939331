import React, { createContext, useState } from "react";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../api";
import { createUpdateTransactionAccountConcept, getTransactionAccountConcepts } from "../../api/account/transactionAccount";
import { TransactionAccountConcept } from "../../api/models/transactionAccount";
import { getAuthBody } from "../../auth";

export interface TransactionAccountState {
    items: TransactionAccountConcept[];
    isFetching: boolean;
}

interface TransactionAccountContextProps {
    concepts: TransactionAccountConcept[];
    fetchConceptComplete: boolean;
    setConcepts: (value: TransactionAccountConcept[]) => void;
    fetchItems: () => void;
    fetchItemsIfNotExits: () => void;
    isFetching: boolean;
    createUpdateConcept: (value: TransactionAccountConcept, onCreate: (value: TransactionAccountConcept) => void) => void;
}

export const TransactionAccountContext = createContext({} as TransactionAccountContextProps);

const TransactionAccountContextProvider = (props: any) => {
    const [concepts, setConcepts] = useState<TransactionAccountConcept[]>([]);

    const searchMutation = useMutation((id: string) => getTransactionAccountConcepts(id), {
        onSuccess: (data) => {
            setConcepts(data);
        },
        onError: (err) => {
            console.log(getServiceMessageError(err));
        },
    });

    const createUpdateConceptMutation = useMutation((data: TransactionAccountConcept) => createUpdateTransactionAccountConcept(data));

    const handleCreateUpdateConcept = (data: TransactionAccountConcept, onCreate: (data: TransactionAccountConcept) => void) => {
        createUpdateConceptMutation
            .mutateAsync(data)
            .then((result) => {
                let some = concepts.some((x) => x.id === result.id);
                if (some) {
                    setConcepts(concepts.filter((x) => (x.id === result.id ? result : x)));
                } else {
                    setConcepts([result, ...concepts]);
                }
                onCreate(result);
            })
            .catch((err) => {
                console.log(getServiceMessageError(err));
            });
    };

    const handleSearchList = () => {
        searchMutation.mutate(getAuthBody().accountId);
    };
    const fetchItemsIfNotExits = () => {
        if (concepts.length === 0) {
            searchMutation.mutate(getAuthBody().accountId);
        }
    };

    return (
        <TransactionAccountContext.Provider
            value={{
                concepts: concepts,
                setConcepts: setConcepts,
                isFetching: searchMutation.isLoading || createUpdateConceptMutation.isLoading,
                fetchItems: handleSearchList,
                fetchItemsIfNotExits,
                fetchConceptComplete: searchMutation.isSuccess,
                createUpdateConcept: handleCreateUpdateConcept,
            }}
        >
            {props.children}
        </TransactionAccountContext.Provider>
    );
};

export const useTransactionAccountContext = () => {
    return React.useContext(TransactionAccountContext);
};

export default TransactionAccountContextProvider;
