import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getServiceMessageError } from "../../../../api";
import { deleteTransactionAccount, getTransactionAccount, getTransactionAccountByRef } from "../../../../api/account/transactionAccount";
import { PaymentMethod } from "../../../../api/models";
import { TransactionAccount, TransactionAccountDelete, TransactionAccountPayment } from "../../../../api/models/transactionAccount";
import { AppSettingsContext } from "../../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../../auth";
import { checkAndGetMessage } from "../../../../i18n/helper";
import globalMessages from "../../../../messages";
import { OrganizationContext } from "../../../../store/contexts/OrganizationContext";
import { formatMoney, getDateFormat, getDateFromNow } from "../../../../utils";
import customMoment from "../../../../utils/momentFormat/dateFormat";
import { useAlert } from "../../../Alerts/Alert";
import CashRegisterOrganizationSelect from "../../../CashRegisters/CashRegisterSelect/CashRegisterOrganizationSelect";
import { getOrgCashRegisterOrDefault } from "../../../CashRegisters/cashRegisterUtils";
import { ContactProfileButtonModal } from "../../../Contacts/ContactProfile";
import FilesViewer from "../../../Files/FilesViewer";
import LoadingDualRing from "../../../LoadingDualRing";
import OrderStatusLine from "../../../Orders/OrderStatusLine";
import { DefaultButton, Flex, PrimaryButton, SquareButton, TextField } from "../../../_controls";
import { Table } from "../../../_controls/tables/styled";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import Modal, { IModalShow } from "../../../modals/Modal";
import accountingMessages from "../../messages";
import { TransactionAccountFormModal, TransactionAccountTabs } from "../TransactionAccountForm";
import { TransactionAccountProfileStyle, TransactionPaymentCardStyle } from "../styled";

interface TransactionAccountProfileProps {
    item: TransactionAccount;
    onChange?: (item: TransactionAccount) => void;
    hideBorder?: boolean;
    refetchQuery?: () => void;
}

interface TransactionAccountProfileModalProps extends TransactionAccountProfileProps, IModalShow {}

export const TransactionAccountProfileModal = (props: TransactionAccountProfileModalProps) => {
    const intl = useIntl();
    return (
        <Modal {...props} useMobileView showHeader title={checkAndGetMessage(intl, accountingMessages, props.item.type)}>
            <Flex padding={10}>
                <TransactionAccountProfile {...props} />
            </Flex>
        </Modal>
    );
};

interface TransactionAccountProfileModalButtonProps {
    transactionAccountId?: string;
    referenceId?: string;
}

export const TransactionAccountProfileModalButton = (props: TransactionAccountProfileModalButtonProps) => {
    const [value, setValue] = useState<TransactionAccount>();
    const [showModal, setShowModal] = useState(false);
    const alert = useAlert();

    const getTransactionApi = () => {
        if (props.referenceId) {
            return getTransactionAccountByRef(props.referenceId);
        }
        return getTransactionAccount(props.transactionAccountId ?? "NA");
    };

    const getMutation = useMutation(() => getTransactionApi(), {
        onSuccess: (data) => {
            setValue({ ...data, loaded: true });
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    return (
        <>
            <DefaultButton
                small
                borderRadius={10}
                bgLight
                onClick={() => {
                    if (!value) {
                        getMutation.mutate();
                    }
                    setShowModal(true);
                }}
            >
                Cuenta por Cobrar {getMutation.isLoading && <LoadingDualRing small />}
            </DefaultButton>
            {showModal && value && (
                <Modal
                    show={showModal}
                    setShow={setShowModal}
                    showHeader
                    title={value.type === "payable" ? "Cuenta por Pagar" : "Cuenta por Cobrar"}
                >
                    <Flex padding={20}>
                        <TransactionAccountProfile item={value} />
                    </Flex>
                </Modal>
            )}
        </>
    );
};

export default function TransactionAccountProfile(props: TransactionAccountProfileProps) {
    const [item, setItem] = useState(props.item);

    const { appSettingsState } = useContext(AppSettingsContext);
    const { organizationState } = useContext(OrganizationContext);
    const [openFormInTab, setOpenFormInTab] = useState<TransactionAccountTabs>();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteTransactionState, setDeleteTransactionState] = useState({
        existPayments: false,
        cashRegisterId: "",
    });

    const intl = useIntl();
    const alert = useAlert();
    const getMutation = useMutation((id: string) => getTransactionAccount(id), {
        onSuccess: (data) => {
            setItem({ ...data, loaded: true });
        },
    });

    const deleteMutation = useMutation((data: TransactionAccountDelete) => deleteTransactionAccount(data), {
        onSuccess: (data) => {
            alert.success("Transacción anulada correctamente");
            setDeleteTransactionState({ ...deleteTransactionState, cashRegisterId: "" });
            if (props.refetchQuery) props.refetchQuery();
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const handleDeleteTransaction = (message?: string) => {
        const data: TransactionAccountDelete = {
            transactionAccountId: props.item.id,
            accountUserId: getAuthBody().accountUserId,
            cashRegisterId: "",
            comment: message ?? "",
        };
        if (!deleteTransactionState.existPayments) {
            data.cashRegisterId = getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization) ?? "";
        } else {
            data.cashRegisterId = deleteTransactionState.cashRegisterId;
        }
        deleteMutation.mutate(data);
    };

    useEffect(() => {
        setItem(props.item);
        if (!props.item.loaded) {
            getMutation.mutate(props.item.id);
        }
        if (props.item.status === "partial" && props.item.totalPaid > 0) {
            setDeleteTransactionState({ ...deleteTransactionState, existPayments: true });
        } else setDeleteTransactionState({ ...deleteTransactionState, existPayments: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item]);

    const cardClass = props.hideBorder ? "hide-border" : "";

    return (
        <Flex column gap={20} marginBottom={20}>
            <TransactionAccountProfileStyle className={cardClass}>
                {showConfirmDeleteModal && (
                    <ConfirmationModal
                        show={showConfirmDeleteModal}
                        setShow={setShowConfirmDeleteModal}
                        description={
                            deleteTransactionState.existPayments
                                ? "Existen pagos asociados a esta transacción, selecciona una caja para gestionar pagos"
                                : "Esta acción no se puede revertir"
                        }
                        title={"Estas seguro que deseas eliminar esta transacción ?"}
                        showMessage={true}
                        placeholder="Comentario (opcional)"
                        onOkay={(message) => {
                            handleDeleteTransaction(message);
                        }}
                        trigger={
                            deleteTransactionState.existPayments ? (
                                <Flex column w100>
                                    <CashRegisterOrganizationSelect
                                        onCashRegisterChange={(value) => {
                                            if (value.id) {
                                                setDeleteTransactionState({ ...deleteTransactionState, cashRegisterId: value.id });
                                            }
                                        }}
                                    />
                                </Flex>
                            ) : null
                        }
                    />
                )}
                {openFormInTab !== undefined && (
                    <TransactionAccountFormModal
                        show={openFormInTab !== undefined}
                        defaultTab={openFormInTab}
                        onCancel={() => {}}
                        setShow={() => setOpenFormInTab(undefined)}
                        onCreate={(value) => {
                            setItem(value);
                            if (props.onChange) props.onChange(value);
                            if (props.refetchQuery) props.refetchQuery();
                        }}
                        transactionAccount={item}
                    />
                )}
                <Flex spaceBetween gap10 alignStart>
                    <Flex column>
                        <Flex gap10 alignCenter>
                            <h3 className="m-0 text-bold">
                                {checkAndGetMessage(intl, accountingMessages, item.type)} #{item.number}
                            </h3>
                        </Flex>
                        <TextField>{getDateFormat(item.createdAt, "DD MMM YYYY, hh:mm:a")}</TextField>
                    </Flex>
                    <Flex gap10 alignCenter>
                        {getMutation.isLoading && <LoadingDualRing small />}
                        {props.item.status !== "void" && (
                            <SquareButton onClick={() => setShowConfirmDeleteModal(true)} removeBackground>
                                <span className="fa-regular fa-trash"></span>
                            </SquareButton>
                        )}
                        <SquareButton removeBackground onClick={() => setOpenFormInTab(TransactionAccountTabs.details)}>
                            <span className="fa-regular fa-pen-to-square"></span>
                        </SquareButton>
                    </Flex>
                </Flex>
                <Flex spaceBetween gap10 alignStart flexWrap>
                    <Flex>
                        <Table className="fist-column-light" tdPadding={5} tdPaddingRight={15} cellPadding={0} cellSpacing={0} hideBorde>
                            <tbody>
                                <tr>
                                    <td>Vencimiento</td>
                                    <td>{customMoment(item.dueDate).format("DD MMM YYYY, hh:mm:a")}</td>
                                </tr>
                                <tr>
                                    <td>Estado</td>
                                    <td>
                                        <OrderStatusLine status={item.status} />
                                    </td>
                                </tr>
                                {item.contact && (
                                    <tr>
                                        <td>Contacto</td>
                                        <td>
                                            <ContactProfileButtonModal contact={item.contact} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>Concepto</td>
                                    <td>{item.concept?.name}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Flex>
                    <Flex border padding={15} borderRadius={15}>
                        <Table tdPadding={5} tdPaddingRight={15} cellPadding={0} cellSpacing={0} hideBorde>
                            <tbody>
                                <tr>
                                    <td>
                                        <TextField>Pagado</TextField>
                                    </td>
                                    <td>{formatMoney(item.totalPaid)}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <TextField bold fontSize={15}>
                                            Total
                                        </TextField>
                                    </td>
                                    <td>
                                        <TextField bold fontSize={15}>
                                            {formatMoney(item.totalAmount)}
                                        </TextField>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Flex>
                </Flex>
                {item.files && item.files.length && <FilesViewer cardMaxWidth={150} onUpdateFiles={() => {}} files={item.files ?? []} />}
            </TransactionAccountProfileStyle>
            <TransactionAccountProfileStyle className={cardClass}>
                <Flex spaceBetween alignCenter gap10>
                    <h3 className="m-0 text-bold">Pagos</h3>
                    {(item.status === "partial" || item.status === "credit") && (
                        <PrimaryButton rounded onClick={() => setOpenFormInTab(TransactionAccountTabs.payments)}>
                            <span className="wahioicon-plus"></span>
                            Pago
                        </PrimaryButton>
                    )}
                </Flex>
                {item.payments && item.payments.length > 0 && (
                    <Flex column gap5>
                        {item.payments.map((payment, index) => {
                            return <PaymentCard key={payment.id} payment={payment} />;
                        })}
                    </Flex>
                )}
            </TransactionAccountProfileStyle>
        </Flex>
    );
}

const getPaymentIcon = (method: PaymentMethod) => {
    if (method === "cash") {
        return "fa-regular fa-money-bill";
    } else if (method === "transfer") {
        return "fa-regular fa-tablet-button";
    }
    return "fa-regular fa-credit-card";
};

const PaymentCard = ({ payment }: { payment: TransactionAccountPayment }) => {
    const intl = useIntl();
    return (
        <TransactionPaymentCardStyle>
            <Flex alignCenter gap10 w100>
                <span
                    title={checkAndGetMessage(intl, globalMessages, payment.method)}
                    className={`method-icon ${payment.method} ${getPaymentIcon(payment.method)}`}
                ></span>
                <Flex column>
                    <TextField bold>{formatMoney(payment.amount)}</TextField>
                    <TextField light small>
                        {getDateFromNow(payment.createdAt)}
                    </TextField>
                </Flex>
                <Flex gap10 alignCenter marginLeftAuto>
                    <TextField light small>
                        {getDateFormat(payment.createdAt, "DD MMM YY hh:mm:a")}
                    </TextField>
                </Flex>
            </Flex>
            {payment.files && payment.files.length > 0 && <FilesViewer onUpdateFiles={() => {}} files={payment.files ?? []} />}
        </TransactionPaymentCardStyle>
    );
};
