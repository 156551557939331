import { PaymentMethod, WahioFile } from ".";

export type PaymentStatus = "invalid" | "active" | "void" | "pending";

export interface IOrderPaymentMultipleInput {
    orderIds: string[];
    accountUserId: string;
    files: WahioFile[];
    cashRegisterId: string;
    cashRegisterOriginId: string;
    method: PaymentMethod;
}

export interface IOrderPaymentBase {
    id?: string;
    dateCreated?: Date;
    amount: number;
    cashRegisterId: string;
    cashRegisterOriginId: string;
    accountUserId: string;
    method: PaymentMethod;
    status: PaymentStatus;
    files: WahioFile[];
    paymentGatewayId?: string;
    paymentGateway?: string;
    createdAt?: string | Date;
    ignoreCashRegisterEntry?: boolean;
}
export interface IOrderPayment extends IOrderPaymentBase {
    orderId?: string;
    paymentGatewayId?: string;
    paymentGateway?: string;
}

export interface IOrderReturnPayment extends IOrderPaymentBase {
    orderReturnId?: string;
}

export interface IPurchasePayment extends IOrderPayment {
    purchaseId?: string;
}
export class OrderPayment implements IOrderPayment {
    //helperId?: string | undefined; // only UI
    id?: string | undefined;
    orderId?: string;
    dateCreated?: Date | undefined;
    amount: number = 0;
    method: PaymentMethod;
    status: PaymentStatus = "active";
    cashRegisterId: string = "";
    cashRegisterOriginId: string = "";
    accountUserId: string;
    files: WahioFile[] = [];

    constructor(amount: number, method: PaymentMethod, cashRegisterId: string, accountUserId: string, dateCreated?: Date) {
        this.amount = amount;
        this.method = method;
        this.cashRegisterId = cashRegisterId;
        this.cashRegisterOriginId = cashRegisterId;
        this.accountUserId = accountUserId;
        this.dateCreated = dateCreated;
    }
}
export class OrderReturnPayment implements IOrderReturnPayment {
    helperId?: string | undefined;
    id?: string | undefined;
    orderReturnId?: string;
    dateCreated?: Date | undefined;
    amount: number = 0;
    method: PaymentMethod;
    status: PaymentStatus = "active";
    cashRegisterId: string = "";
    cashRegisterOriginId: string = "";
    accountUserId: string;
    files: WahioFile[] = [];

    constructor(amount: number, method: PaymentMethod, cashRegisterId: string, accountUserId: string) {
        this.amount = amount;
        this.method = method;
        this.cashRegisterId = cashRegisterId;
        this.accountUserId = accountUserId;
    }
}

export class PurchasePayment implements IPurchasePayment {
    helperId?: string | undefined;
    id?: string | undefined;
    purchaseId?: string;
    dateCreated?: Date | undefined;
    amount: number = 0;
    method: PaymentMethod;
    status: PaymentStatus = "active";
    cashRegisterId: string = "";
    cashRegisterOriginId: string = "";
    accountUserId: string = "";
    files: WahioFile[] = [];

    constructor(amount: number, method: PaymentMethod, cashRegisterId: string, accountUserId: string) {
        this.amount = amount;
        this.method = method;
        this.cashRegisterId = cashRegisterId;
        this.accountUserId = accountUserId;
    }
}

export interface IRefund {
    id: string;
    accountId: string;
    currency: string;
    amount: number;
    dateCreated: Date;
    comment: string;
    status: string;
}
export interface IOrderRefund {
    id?: string;
    orderId?: string;
    dateCreated?: Date;
    amount?: number;
    method?: PaymentMethod;
    refundId: string;
    refund: IRefund;
}
