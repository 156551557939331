import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { getServiceErrorMessageObject, wahioFetch } from "../../../api";
import { IOrderDeleteCreate } from "../../../api/models/order";
import { IPurchase } from "../../../api/models/purchase";
import { IPurchaseReturn } from "../../../api/models/purchaseReturns";
import { purchaseEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import PATHS from "../../../constants/paths";
import { AccountUserContext } from "../../../store/contexts/AccountUserContext";
import { PurchaseContext } from "../../../store/contexts/PurchaseContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { getDateFormat, getModelFullName } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import Dropdown from "../../Dropdown/index";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationProfile, { NavigationProfileHeader, NavigationProfileHeaderProps, useSelectedGroupTab } from "../../NavigationPanel";
import NavigationCard, { NavigationCardGroup } from "../../NavigationPanel/NavigationCard";
import RejectOrderModal, { RejectOrderState } from "../../Orders/DeliveryCouriers/RejectOrderModal";
import { NormalContainer, OrderButtonsFlex } from "../../Orders/OrderProfile/styled";
import OrderStatusLine from "../../Orders/OrderStatusLine";
import { DefaultButton, Flex, SquareButton } from "../../_controls";
import CreatePurchaseModal from "../CreatePurchaseForm/CreatePurchaseModal";
import { PurchaseReturnFormModal } from "../PurchasesReturns/PurchaseReturnForm";
import { TextField } from "./../../../components/_controls";
import { GeneralDetails } from "./GeneralDetails";
import InvoicePrint from "./InvoicePrint";
import OrderFiles from "./OrderFiles";
import OrderRejected from "./OrderRejected";
import { PurchaseDetailsTabView } from "./PurchaseDetailsTabView";
import PurchaseItemView from "./PurchaseItemView";
import { PurchaseReceiveProducts } from "./PurchaseReceiveProducts";
import { SummaryTabView } from "./SummaryTabView";
import { TotalDetails } from "./TotalDetails";
import { MENU_OPTIONS, NavigationGroupName } from "./menuHelper";
import messages from "./messages";
import { PurchaseItemViewContainer } from "./styled";

type ProfileParams = {
    orderId: string;
};

export interface PurchaseNavigationProps {
    order: IPurchase;
    onOrderChange: (order: IPurchase) => void;
    fetchOrder: () => void;
    onUpdateOrderReturned?: (value: IPurchaseReturn) => void;
}

interface OrderProfileProps {
    order?: IPurchase;
    showSimpleView?: boolean;
    onUpdateOrder?: (value: IPurchase) => void;
    onUpdateOrderReturned?: (value: IPurchaseReturn) => void;
    showBackButton?: boolean;
    onClickBack?: () => void;
}

function PurchaseProfileComp(props: OrderProfileProps) {
    const intl = useIntl();

    const params = useParams<ProfileParams>();
    const orderId = props.order?.id ?? params.orderId;
    const { purchaseState } = useContext(PurchaseContext);
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);
    const { userPermission } = useContext(UserContext);

    const [order, setOrder] = useState<IPurchase | undefined>(props.order ?? purchaseState.pagination.items.find((x) => x.id === orderId));

    const [responseResult, setResponseResult] = useState({
        isFetching: false,
        isError: false,
    });

    const { selectedGroup, setSelectedGroup } = useSelectedGroupTab<NavigationGroupName>(NavigationGroupName.Profile);

    const customOptions = useMemo(() => {
        let options = [...MENU_OPTIONS];

        let receive =
            userPermission.purchase?.update &&
            order &&
            (order.totalQuantityReceived + order.totalQuantityReturned >= order.totalQuantity ||
                order.status === "void" ||
                order.status === "draft");

        if (receive) {
            options = options.filter((x) => x.tabName !== NavigationGroupName.Receive);
        }

        return options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        if (order) loadOrderWithUsers(order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountUserState.items, order]);

    useEffect(() => {
        getPurchaseById();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPurchaseById = () => {
        setResponseResult({ ...responseResult, isFetching: true });
        wahioFetch.get(
            purchaseEndpoint.get.purchaseById(orderId ?? "NA"),
            (success) => {
                setResponseResult({ ...responseResult, isFetching: false, isError: false });
                setOrder(success.data);
                loadOrderWithUsers(success.data);
                if (accountUserState.items.length === 0) {
                    accountUserActions.requestAccountUsers();
                }
            },
            (error) => {
                setResponseResult({ ...responseResult, isFetching: false, isError: true });
            }
        );
    };

    const loadOrderWithUsers = (order: IPurchase) => {
        if (accountUserState.items.length > 0 && order && !order.accountUserEntitiesLoaded) {
            const items = accountUserState.items;
            if (order.accountUserId) {
                order.accountUser = items.find((x) => x.id === order.accountUserId);
            }
            if (order.orderDelete) {
                order.orderDelete.accountUser = items.find((x) => x.id === order.orderDelete?.accountUserId);
            }
            setOrder({ ...order, accountUserEntitiesLoaded: true });
        }
    };

    const onUpdateOrder = (order: IPurchase) => {
        setOrder(order);
        if (props.onUpdateOrder) props.onUpdateOrder(order);
    };

    if (!order && responseResult.isFetching) {
        return <LoadingDualRing center={true} />;
    }

    if (!order) {
        return null;
    }

    const orderProps = {
        order: order,
        onOrderChange: onUpdateOrder,
        showSimpleView: props.showSimpleView,
        fetchOrder: getPurchaseById,
    };

    let subTitle = "Cliente General";
    if (order.supplier) {
        subTitle = `${getModelFullName(order.supplier)}`;
        if (order.supplier.identificationNumber) {
            subTitle += ` - ${order.supplier.identificationNumber}`;
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${intl.formatMessage(messages.order)} ${order.number}`} - Wahio</title>
            </Helmet>

            <NavigationProfile
                headerComp={
                    <OrderHeader
                        order={order}
                        title={`Compra #${order.number}`}
                        titleLink={PATHS.productPurchasesListProfileId(order.id)}
                        subTitle={subTitle}
                        showBackButton={props.showBackButton}
                        onOrderChange={setOrder}
                        fetchOrder={getPurchaseById}
                        onBackAction={props.onClickBack}
                        customActions={<OrderActionsPanel {...orderProps} />}
                    />
                }
                menuOptions={customOptions}
                selectedTab={selectedGroup}
                setSelectedTab={setSelectedGroup}
            >
                <>
                    <NavigationCardGroup groupName={NavigationGroupName.Profile} selectedGroup={selectedGroup}>
                        {order.orderDelete && <OrderRejected order={order} orderDelete={order.orderDelete} />}

                        <NavigationCard title="Perfil">
                            <Flex gap15 flexWrap marginTop={10} alignStart spaceBetween>
                                <GeneralDetails currentOrder={order} />
                                <Flex border borderRadius={15} padding={15} mobileW100>
                                    <TotalDetails currentOrder={order} />
                                </Flex>
                            </Flex>
                            <Flex column marginTop={10}>
                                <TextField small light>
                                    {intl.formatMessage(messages.createdAt)}: {getDateFormat(order.dateCreated, "DD MMM YYYY hh:mm:a")}
                                </TextField>
                                {order.dateCreatedCustom && (
                                    <TextField small light>
                                        {intl.formatMessage(messages.customDate)}:{" "}
                                        {getDateFormat(order.dateCreatedCustom, "DD MMM YYYY hh:mm:a")}
                                    </TextField>
                                )}
                            </Flex>

                            {responseResult.isFetching && <LoadingDualRing center={true} />}
                        </NavigationCard>

                        <NavigationCard title="Productos">
                            <PurchaseItemViewContainer>
                                {order.items && order.items.map((item, index) => <PurchaseItemView item={item} key={index} />)}
                            </PurchaseItemViewContainer>
                        </NavigationCard>

                        <SummaryTabView
                            setChangeTabKey={setSelectedGroup}
                            currentOrder={order}
                            showSimpleView={props.showSimpleView}
                            onUpdateOrder={onUpdateOrder}
                            permission={userPermission}
                        />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.Receive} selectedGroup={selectedGroup}>
                        <PurchaseReceiveProducts
                            setChangeTabKey={setSelectedGroup}
                            currentOrder={order}
                            onUpdateOrder={onUpdateOrder}
                            permission={userPermission}
                        />
                    </NavigationCardGroup>
                    <NavigationCardGroup groupName={NavigationGroupName.Files} selectedGroup={selectedGroup}>
                        <OrderFiles order={order} onUpdateOrder={onUpdateOrder} />
                    </NavigationCardGroup>

                    <NavigationCardGroup groupName={NavigationGroupName.Details} selectedGroup={selectedGroup}>
                        <PurchaseDetailsTabView currentOrder={order} />
                    </NavigationCardGroup>
                </>
            </NavigationProfile>
        </>
    );
}

export default function PurchaseProfile(props: OrderProfileProps) {
    if (props.showSimpleView) {
        return (
            <NormalContainer>
                <PurchaseProfileComp {...props} />
            </NormalContainer>
        );
    }

    return <PurchaseProfileComp {...props} />;
}

const OrderActionsPanel = (props: PurchaseNavigationProps) => {
    const { order: currentOrder, onOrderChange } = props;
    const alert = useAlert();
    const [showEditOrder, setShowEditOrder] = useState(false);
    const [showModalCreatePurchaseReturn, setShowModalCreatePurchaseReturn] = useState(false);
    const [openConfirmRemove, setOpenConfirmRemove] = useState(false);
    const [responseResult, setResponseResult] = useState({
        isFetching: false,
        isError: false,
    });
    const intl = useIntl();

    const { userPermission } = useContext(UserContext);

    const handleOpenConfirmDelete = () => {
        if (!userPermission.purchase?.delete) {
            alert.info("No tienes permisos para anular compras");
            return;
        }
        setOpenConfirmRemove(true);
    };

    const handleDeleteOrder = (rejectBody: RejectOrderState) => {
        setResponseResult({ ...responseResult, isFetching: true });
        setOpenConfirmRemove(false);

        if (!rejectBody.cashRegisterId) {
            alert.error("La Tienda actual debe tener una caja asignada, o debes activar una caja para poder anular la compra");
            return;
        }

        let body: IOrderDeleteCreate = {
            accountUserId: getAuthBody().accountUserId,
            cashRegisterId: rejectBody.cashRegisterId,
            orderId: currentOrder.id ?? "",
            method: rejectBody.paymentMethod,
            status: "void",
            comment: rejectBody.message,
        };

        wahioFetch.post(
            purchaseEndpoint.post.deleteOrder,
            body,
            (success) => {
                onOrderChange(success.data);
                setResponseResult({ ...responseResult, isFetching: false });
                alert.success("Orden anulada con éxito");
            },
            (error) => {
                setResponseResult({ ...responseResult, isFetching: false });
                alert.error("Error al intentar anular la compra: " + getServiceErrorMessageObject(error));
            }
        );
    };

    return (
        <>
            {openConfirmRemove && (
                <RejectOrderModal
                    onOkay={(value) => handleDeleteOrder(value)}
                    showRefundsOptions={currentOrder.totalPaid > 0}
                    title="¿Estás seguro de que deseas anular la Compra?"
                    description="Esta acción no se puede revertir, los productos regresarán al stock de nuevo y se creará un reembolso con la fecha actual en caso te tener pagos"
                    show={openConfirmRemove}
                    setShow={setOpenConfirmRemove}
                />
            )}
            {showModalCreatePurchaseReturn && (
                <PurchaseReturnFormModal
                    show={showModalCreatePurchaseReturn}
                    setShow={setShowModalCreatePurchaseReturn}
                    purchaseProps={currentOrder}
                    onCreate={(order: IPurchaseReturn) => {
                        props.fetchOrder();
                        if (props.onUpdateOrderReturned) props.onUpdateOrderReturned(order);
                    }}
                />
            )}
            {showEditOrder && (
                <CreatePurchaseModal
                    onOrderChange={(order) => {
                        onOrderChange(order);
                        setShowEditOrder(false);
                        alert.success("La orden ha sido actualizada con éxito");
                    }}
                    show={showEditOrder}
                    setShow={setShowEditOrder}
                    order={currentOrder}
                />
            )}
            {!currentOrder.orderDelete && (
                <OrderButtonsFlex alignCenter gap10>
                    <InvoicePrint order={currentOrder} />
                    {currentOrder.status === "draft" ? (
                        <DefaultButton rounded className="dropdown-button" onClick={() => setShowEditOrder(true)}>
                            <span className="fa-regular fa-pen-to-square" /> {intl.formatMessage(messages.continueEdit)}
                        </DefaultButton>
                    ) : (
                        <SquareButton title="Editar Compra" onClick={() => setShowEditOrder(true)}>
                            <span className="fa-regular fa-pen-to-square" />
                        </SquareButton>
                    )}
                    {currentOrder.status !== "void" && (
                        <Dropdown textLight icon="fa-regular fa-ellipsis-vertical" contentHorizontal="left">
                            <Flex column padding={10} gap5>
                                <DefaultButton onClick={handleOpenConfirmDelete} rounded className="dropdown-button">
                                    <span className="wahioicon-trash" />
                                    Anular compra
                                </DefaultButton>

                                <DefaultButton className="dropdown-button" rounded onClick={() => setShowModalCreatePurchaseReturn(true)}>
                                    <span className="wahioicon-arrows-alt-h"></span>
                                    Devoluciones/Cambios
                                </DefaultButton>
                            </Flex>
                        </Dropdown>
                    )}
                </OrderButtonsFlex>
            )}
        </>
    );
};

const OrderHeader = (props: NavigationProfileHeaderProps & PurchaseNavigationProps) => {
    return (
        <>
            <NavigationProfileHeader
                {...props}
                titleComponent={
                    <OrderStatusLine
                        customStyle={{ width: "40px", height: "40px", borderRadius: "10px", fontSize: "16px" }}
                        size="lg"
                        useIcon
                        status={props.order.status}
                    />
                }
            />
        </>
    );
};
