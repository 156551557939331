import styled from "styled-components";
import { device } from "../../../theme/themeProvider";
import { OrderStyles } from "../../Orders/OrderStyles/styled";
import { TableCustom } from "../../_controls/tables/styled";

export const ContainerBody = styled(OrderStyles)`
    padding: 10px;
    padding-bottom: 2rem;
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 0px;

    @media ${device.md} {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        padding: 20px 25px;
        border-radius: 15px;
    }

    &.margin-none {
        margin-top: 0px;
    }

    .edit {
        background: ${(props) => props.theme.palette.backgroundDark};

        border-radius: 50px;
    }
    .btn-edit {
        border-radius: 40px;
    }
    .hide {
        display: none;
    }

    h1 {
        margin: 0px;
        margin-top: 14px;
    }
    .light-color {
        color: ${(props) => props.theme.palette.textLight};
    }

    .light {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    h1,
    h2,
    h3 {
        margin: 0px;
    }
    .spacetop {
        margin-top: 4px;
    }
`;

export const ReceivedTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BarcodeContainer = styled.div`
    background: ${(props) => props.color ?? props.theme.palette.backgroundDark};
    color: ${(props) => (props.color ? "white" : props.theme.palette.textLight)};
    padding: 7px 13px;
    border-radius: 40px;
    margin-left: 15px;
    font-size: 13px;
    font-weight: 500;
`;

export const ActiveResturantContainer = styled.div`
    padding: 15px 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    border-radius: 7px;
    font-size: 13px;
    background: #2962ff2c;
    color: #2962ff;
`;

export const TableReceivedCardContainer = styled.div`
    .items-container {
        position: relative;
        .purchase-item-receive {
            border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
        }

        .purchase-item-receive:last-child {
            border-bottom: 0px solid ${(props) => props.theme.palette.backgroundDark};
        }
    }

    .fake-container {
        position: sticky;
        top: 62px;
        z-index: 2;
    }
`;

export const PurchaseItemTableRow = styled.div`
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 5px;

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    &.returned {
        opacity: 0.5;
        &:hover {
            background: ${(props) => props.theme.palette.background};
        }
    }

    .item-discount {
        color: #ff5722;
        font-size: 13px;
        font-weight: 500;
    }
    .item-tax {
        color: #673ab7;
        font-size: 13px;
        font-weight: 500;
    }
    .product-name {
        color: ${(props) => props.theme.palette.text};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: ${(props) => props.theme.palette.primary};
            cursor: pointer;
        }
    }
`;

export const PurchaseItemViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
`;

export const PurchaseReceiveItemViewCard = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background: ${(props) => props.theme.palette.background};
    transition: all 0.2s;

    position: relative;

    transition: top 0.4s;

    &.selected {
        padding: 10px;
        border-radius: 10px;

        box-shadow: 1px 1px 16px 4px ${(props) => props.theme.palette.shadowColor};
        .textbox-numeral {
            border-color: ${(props) => props.theme.palette.backgroundDark};
            background: ${(props) => props.theme.palette.background};
        }

        button {
            background: ${(props) => props.theme.palette.background};
        }
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .flex-space {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
    }
    .FlexCol {
        display: flex;
        flex-direction: column;
    }
    @media ${device.md} {
        .flex-space {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .labelcontainer {
        margin-top: 2px;
    }

    .image {
        width: 50px;
        min-width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 8px;
        margin-right: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .quantity-controls {
        display: flex;
        align-items: center;
        input {
            width: 80px;
        }
    }

    .textbox-numeral {
        margin-left: 4px;
        margin-right: 4px;
        text-align: center;
        min-width: 80px;
    }
    .location {
        ${(props: { locationColor?: string }) =>
            props.locationColor
                ? `background: ${props.locationColor};     
       color: white; 
    `
                : ""}
    }

    &.invalid-location {
        .location {
            border: 1px solid #f44336;
        }
    }
    input {
        border-radius: 40px;
        width: 100%;
        &.bold {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
        width: 100%;
        .title {
            color: ${(props) => props.theme.palette.text};
            font-size: 14px;
        }
        .subtitle {
            margin-bottom: 3px;
        }
    }
`;

export const DeliveryContainer = styled.div`
    margin: 20px 0px;

    padding: 11px;
    border-radius: 7px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    .title-body {
        margin-bottom: 10px;
    }
    .delivery-title {
        margin: 0px;
        font-size: 13px;
        display: block;
    }
    .delivery-details {
    }
`;

export const TableContainer = styled.div`
    padding: 0px;
`;

export const RightPanel = styled.div`
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
    flex-wrap: wrap;

    .labelcontainer,
    button {
        margin-left: 8px;
        margin-top: 8px;
    }
`;

export const LeftContainerGrid = styled.div`
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;

    .labelcontainer,
    button {
        margin-right: 15px;
        margin-top: 8px;
    }
`;

export const ProgressReceiveView = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 201px;
    padding-right: 10px;
    .progress-title {
        font-size: 13px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .bgcontainer {
        background: #00968861;
        border-radius: 8px;
        margin-top: 3px;
        overflow: hidden;
    }
    .bg-green {
        background: #009688;
    }
`;

export const LocationContainer = styled.div`
    display: flex;
    align-items: center;

    .location-circle {
        background: ${(props: { color?: string }) => (props.color ? props.color : "grey")};
        border-radius: 40px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
`;

export const TableItems = styled(TableCustom)`
    overflow: auto;
`;

export const TableItemsContainer = styled.div`
    position: relative;
    overflow: auto;
`;

export const ContainerTabView = styled(OrderStyles)`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ContainerButtonReceive = styled.div`
    display: flex;

    .btn-receive {
        transition: transform 0.3s;
    }

    .btn-receive:hover {
        transform: translateX(10px);
    }
`;
