import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useIsMobileListener } from "../..";
import { Contact, CustomerAddress, IContactAddress, IContactPhone } from "../../../api/models/contact";
import { getContact, updateContactAll } from "../../../api/orders/contactApi";
import { CloudImages } from "../../../assets";
import PATHS from "../../../constants/paths";
import { useContactContext } from "../../../store/contexts/ContactContext";
import { getDateFormatll, getModelFullName } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import { useEntrySearchParams } from "../../CashRegisters/CashRegisterEntries/entryParams";
import { EBillingDataLabel } from "../../ElectronicBillings/EBillingData/EBillingDataSelector";
import FilesViewer from "../../Files/FilesViewer";
import { getParamBackLink } from "../../FilterParams/helper";
import LoadingDualRing from "../../LoadingDualRing";
import { useOrderSearchParams } from "../../Orders/OrderList/orderParamHelper";
import { usePurchaseSearchParams } from "../../Purchases/PurchaseList/orderParamHelper";
import { validateUrlImage } from "../../WahioCloud/helper";
import { DefaultTextarea, Flex, FlexImageStyle, GridTemplate, SquareButton, TextField } from "../../_controls";
import { DefaultButton, PrimaryButton } from "../../_controls/buttons/index";
import { FlexImageLetter } from "../../_controls/containers/FlexImageLetter";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import CreateContactModal from "../ContactForm/CreateContactModal";
import messages from "./messages";
import { ContactTypeLabel, Container, OrderLinkCardStyle, TextLinkStyle } from "./styled";

type ParamProps = {
    contactId: string;
};

const getAddressLine = (address: IContactAddress) => {
    let items: string[] = [];
    if (address.state) items.push(address.state);
    if (address.city) items.push(address.city);
    if (address.description) items.push(address.description);
    if (address.additionalInformation) items.push(address.additionalInformation);

    return items.join(", ");
};

export interface ContactProfileProps {
    contact?: Contact;
    hideBorder?: boolean;
}

export default function ContactProfile(props: ContactProfileProps) {
    const [showEditModal, setShowEditModal] = useState(false);

    const [contact, setContact] = useState<Contact | undefined>(props.contact);

    const { contactState, setContactState } = useContactContext();

    const purchaseParams = usePurchaseSearchParams();
    const orderParams = useOrderSearchParams();
    const entryParams = useEntrySearchParams();

    const params = useParams<ParamProps>();
    const [hasChanges, setHasChanges] = useState(false);
    const [showCommentEditor, setShowCommentEditor] = useState(false);
    const [observation, setObservation] = useState("");

    const isMobile = useIsMobileListener({ size: 600 });
    const alert = useAlert();
    const intl = useIntl();

    const navigate = useNavigate();

    const contactMutation = useMutation((id: string) => getContact(id), {
        onSuccess: (data: Contact) => getContactResponse(data),
    });

    const getContactResponse = (contact: Contact) => {
        if (!contact.addresses || contact.addresses.length === 0) {
            contact = { ...contact, addresses: [new CustomerAddress("customer-address-one")] };
        }
        setContact(contact);
    };

    const updateContactMutation = useMutation((contact: Contact) => updateContactAll(contact), {
        onSuccess: (data: Contact) => {
            alert.success("Contacto actualizado correctamente");
            setHasChanges(false);
        },
    });

    useEffect(() => {
        if (!contact || !contact.id) return;
        setContactState({ ...contactState, paramContacts: [contact] });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact]);

    useEffect(() => {
        let contactId = params.contactId ?? props.contact?.id;
        if (contactId) contactMutation.mutate(contactId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validatePhoneCallPrefix = (item: IContactPhone) => {
        const prefix = !item.phonePrefix || item.phonePrefix === "" ? "57" : item.phonePrefix;
        return `tel: +${prefix}${item?.phoneNumber}}`;
    };

    const onSaveComment = () => {
        if (!contact) return;
        setContact({ ...contact, observation });
        onUpdateContact({ ...contact, observation });
        setShowCommentEditor(false);
    };

    const onUpdateContact = (value?: Contact) => {
        if (!contact) return;
        updateContactMutation.mutate(value ?? contact);
    };

    if (contactMutation.isLoading) {
        return <LoadingDualRing center className="mt-2" />;
    }

    if (contactMutation.isSuccess && !contactMutation.data) {
        return (
            <Container>
                <h1>Customer not found</h1>
            </Container>
        );
    }

    const onBackToOrders = (type: "customer" | "seller" | "courier") => {
        if (!contact) return;
        navigate({
            pathname: PATHS.orderSalesList,
            search: orderParams.getSearchParamsText({
                [type]: contact?.id,
                backLink: getParamBackLink(`Contacto: ${contact?.firstName}`, window.location.pathname),
            }),
        });     
    };
    const onBackToPurchase = () => {
        if (!contact) return;
        navigate({
            pathname: PATHS.productPurchasesList,
            search: purchaseParams.getSearchParamsText({
                supplier: contact?.id,
                backLink: getParamBackLink(`Contacto: ${contact?.firstName}`, window.location.pathname),
            }),
        });
    };

    const onBackToPayments = () => {
        if (!contact) return;
        navigate({
            pathname: PATHS.paymentsAll,
            search: entryParams.getSearchParamsText({
                contact: contact?.id,
                backLink: getParamBackLink(`Contacto: ${contact?.firstName}`, window.location.pathname),
            }),
        });
    };

    if (!contact) return null;

    const birthDayIsValid = new Date(contact.birthday ?? "").getTime() >= new Date("1950-01-01T00:00:00Z").getTime();

    return (
        <Flex column gap={20}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {contact.firstName} {contact.lastName ?? ""} - Wahio
                </title>
            </Helmet>

            {contactMutation.isSuccess && showEditModal && (
                <CreateContactModal
                    contact={contact}
                    onCreate={(contact) => {
                        setContact(contact);
                    }}
                    show={showEditModal}
                    setShow={setShowEditModal}
                />
            )}

            <Flex column paddingDesktop={props.hideBorder ? 0 : 15} borderDesktop={!props.hideBorder} borderRadius={15}>
                <Flex column padding={10}>
                    <Flex gap15 alignStart>
                        {contact.image && (
                            <FlexImageStyle fitCover width={100} className="profile-image" borderRadius={15}>
                                {contact.image && <img src={validateUrlImage(contact.image)} alt="" />}
                            </FlexImageStyle>
                        )}
                        <Flex column w100>
                            <Flex alignCenter gap15 w100 spaceBetween>
                                <Flex column>
                                    <h3 className="m-0 text-bold">{getModelFullName(contact)}</h3>
                                    <span className="text-light text-small">Creado {getDateFormatll(contact.dateCreated)}</span>
                                </Flex>
                                <SquareButton onClick={() => setShowEditModal(true)}>
                                    <span className="wahioicon-pen"></span>
                                </SquareButton>
                            </Flex>

                            <Flex gap10 className="mt-1" overflowHidden>
                                {contact.isCompany && <ContactTypeLabel>Empresa</ContactTypeLabel>}
                                {contact.isSeller && <ContactTypeLabel>Vendedor</ContactTypeLabel>}
                                {contact.isCourier && <ContactTypeLabel>Mensajero</ContactTypeLabel>}
                                {contact.isSupplier && <ContactTypeLabel>Proveedor</ContactTypeLabel>}
                                {<ContactTypeLabel>Cliente</ContactTypeLabel>}
                            </Flex>
                        </Flex>
                    </Flex>

                    <Table cellPadding={0} cellSpacing={0}>
                        <tbody>
                            {contact.identificationNumber && (
                                <tr>
                                    {!isMobile && <td className="text-light">{contact.identificationType ?? "---"}</td>}
                                    <td>
                                        <Flex column>
                                            {isMobile && contact.identificationType && (
                                                <span className="text-light">{contact.identificationType}</span>
                                            )}
                                            <span>{contact.identificationNumber}</span>
                                        </Flex>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                {!isMobile && <td className="text-light">Email</td>}
                                <td>
                                    <Flex column>
                                        {isMobile && <span className="text-light">Email</span>}
                                        {contact.emails?.map((item, index) => {
                                            return (
                                                <Flex alignCenter gap10 key={index}>
                                                    <span>{item.email}</span>
                                                    {item.label && <span className="text-light text-small">{item.label}</span>}
                                                </Flex>
                                            );
                                        })}
                                    </Flex>
                                </td>
                            </tr>
                            <tr>
                                {!isMobile && <td className="text-light">Teléfono</td>}
                                <td>
                                    <Flex column>
                                        {isMobile && <span className="text-light">Teléfono</span>}
                                        {contact.phones?.map((item, index) => {
                                            return (
                                                <Flex alignCenter gap10 key={index}>
                                                    <span>{item.phoneNumber}</span>
                                                    {item.label && <span className="text-light text-small">{item.label}</span>}
                                                    {isMobile && (
                                                        <a href={validatePhoneCallPrefix(item)} className="ml-2">
                                                            <SquareButton>
                                                                <span className="wahioicon-phone text-small text-primary" />
                                                            </SquareButton>
                                                        </a>
                                                    )}
                                                </Flex>
                                            );
                                        })}
                                    </Flex>
                                </td>
                            </tr>
                            <tr>
                                {!isMobile && <td className="text-light">Dirección</td>}
                                <td>
                                    <Flex column>
                                        {isMobile && <span className="text-light">Dirección</span>}
                                        {contact.addresses?.map((item, index) => {
                                            return (
                                                <Flex alignCenter gap10 key={index}>
                                                    <span>{getAddressLine(item)}</span>
                                                    {item.label && <span className="text-light text-small">{item.label}</span>}
                                                </Flex>
                                            );
                                        })}
                                    </Flex>
                                </td>
                            </tr>
                            {birthDayIsValid && (
                                <tr>
                                    {!isMobile && <td className="text-light">Fecha de cumpleaños</td>}
                                    <td>
                                        <Flex column>
                                            {isMobile && <span className="text-light">Fecha de cumpleaños</span>}
                                            <span>{getDateFormatll(contact.birthday)}</span>
                                        </Flex>
                                    </td>
                                </tr>
                            )}
                            {contact.typeLiabilityId && (
                                <tr>
                                    {!isMobile && <td className="text-light">Responsabilidad</td>}
                                    <td>
                                        <Flex column>
                                            {isMobile && <span className="text-light">Responsabilidad</span>}
                                            <EBillingDataLabel selectedId={parseInt(contact.typeLiabilityId)} type="typeLiabilities" />
                                        </Flex>
                                    </td>
                                </tr>
                            )}
                            {contact.typePersonId && (
                                <tr>
                                    {!isMobile && <td className="text-light">Persona</td>}
                                    <td>
                                        <Flex column>
                                            {isMobile && <span className="text-light">Persona</span>}
                                            <EBillingDataLabel selectedId={parseInt(contact.typePersonId)} type="typeOrganizations" />
                                        </Flex>
                                    </td>
                                </tr>
                            )}
                            {contact.typeRegimeId && (
                                <tr>
                                    {!isMobile && <td className="text-light">Regimen</td>}
                                    <td>
                                        <Flex column>
                                            {isMobile && <span className="text-light">Regimen</span>}
                                            <EBillingDataLabel selectedId={parseInt(contact.typeRegimeId)} type="typeRegimes" />
                                        </Flex>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Flex>
            </Flex>
            <Flex column gap15 paddingDesktop={props.hideBorder ? 0 : 25} borderDesktop={!props.hideBorder} borderRadius={15}>
                <TextField bold>Movimientos de {contact.firstName}</TextField>
                <GridTemplate size={230}>
                    <OrderCardLink
                        color="#00C853"
                        icon={"fa-regular fa-credit-card"}
                        title="Transacciones"
                        onClick={() => {
                            onBackToPayments();
                        }}
                    />
                    <OrderCardLink
                        color="#304FFE"
                        icon={"fa-regular fa-bag-shopping"}
                        title="Ordenes"
                        onClick={() => {
                            onBackToOrders("customer");
                        }}
                    />
                    {contact.isSeller && (
                        <OrderCardLink
                            color="#5E35B1"
                            icon={"fa-regular fa-user-tie"}
                            title="Ordenes Vendidas"
                            onClick={() => {
                                onBackToOrders("seller");
                            }}
                        />
                    )}
                    {contact.isCourier && (
                        <OrderCardLink
                            color="#F57F17"
                            icon={"fa-sharp fa-regular fa-person-dolly"}
                            title="Ordenes Entregadas"
                            onClick={() => {
                                onBackToOrders("courier");
                            }}
                        />
                    )}
                    {contact.isSupplier && (
                        <OrderCardLink
                            color="#00BFA5"
                            icon={"fa-regular fa-truck"}
                            title="Compras"
                            onClick={() => {
                                onBackToPurchase();
                            }}
                        />
                    )}

                    <OrderCardLink
                        color="#bf0026"
                        icon={"fa-regular fa-wallet"}
                        title="Cartera"
                        onClick={() => {
                            navigate({ pathname: PATHS.paymentsAccountingContactProfile(contact.id) });
                        }}
                    />
                </GridTemplate>
            </Flex>
            <Flex column gap15 paddingDesktop={props.hideBorder ? 0 : 25} borderDesktop={!props.hideBorder} borderRadius={15}>
                <Flex spaceBetween alignCenter>
                    <TextField bold>Archivos Adjuntos</TextField>
                    {hasChanges && (
                        <Flex>
                            {updateContactMutation.isLoading ? (
                                <LoadingDualRing small />
                            ) : (
                                <Flex gap10>
                                    <DefaultButton
                                        onClick={() => setContact({ ...contact, files: props.contact?.files ?? [] })}
                                        rounded
                                        small
                                    >
                                        Cancelar
                                    </DefaultButton>
                                    <PrimaryButton rounded small onClick={() => onUpdateContact()}>
                                        <TextField className="fa-sharp fa-regular fa-folder-arrow-up" />
                                        <TextField bold>Guardar</TextField>
                                    </PrimaryButton>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </Flex>
                {(!contact.files || contact.files.length === 0) && (
                    <Flex marginLeft={25}>
                        <FlexImageStyle>
                            <img src={CloudImages.openFolder} alt="Folder" />
                        </FlexImageStyle>
                    </Flex>
                )}
                <FilesViewer
                    smallButton
                    smallCard
                    showAttachButton
                    cardMaxHeight={80}
                    cardMaxWidth={80}
                    onUpdateFiles={(value) => {
                        setHasChanges(true);
                        setContact({ ...contact, files: value });
                    }}
                    files={contact.files ?? []}
                />
            </Flex>
            <Flex column gap15 paddingDesktop={props.hideBorder ? 0 : 25} borderDesktop={!props.hideBorder} borderRadius={15}>
                <Flex alignCenter spaceBetween>
                    <TextField bold>{intl.formatMessage(messages.observation)}</TextField>
                    {!contact.observation && !showCommentEditor && (
                        <DefaultButton rounded small onClick={() => setShowCommentEditor(!showCommentEditor)}>
                            <TextField bold className="fa-regular fa-pencil-alt" /> Agregar Observación
                        </DefaultButton>
                    )}
                </Flex>
                {contact.observation && (
                    <Flex spaceBetween>
                        <Flex gap15 alignCenter>
                            <TextField>{contact.observation}</TextField>
                        </Flex>
                        <SquareButton small className="fa-regular fa-pencil-alt" onClick={() => setShowCommentEditor(!showCommentEditor)} />
                    </Flex>
                )}
                {showCommentEditor && (
                    <Flex column gap15>
                        <DefaultTextarea
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                            placeholder={intl.formatMessage(messages.addObservation)}
                            rows={3}
                        />
                        <Flex alignEnd justifyEnd gap10>
                            <DefaultButton
                                rounded
                                type="button"
                                small
                                onClick={() => {
                                    setContact({ ...contact, observation: props.contact?.observation ?? undefined });
                                    setShowCommentEditor(false);
                                }}
                            >
                                Cancelar
                            </DefaultButton>
                            <PrimaryButton rounded small onClick={() => onSaveComment()}>
                                Guardar
                            </PrimaryButton>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}

export const ContactProfileButtonModal = ({ contact }: { contact: Contact }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal && (
                <Modal showHeader title="Perfil del contacto" sizeType="md" useMobileView show={showModal} setShow={setShowModal}>
                    <Flex column padding={25}>
                        <ContactProfile contact={contact} hideBorder />
                    </Flex>
                </Modal>
            )}
            <Flex gap5 alignCenter>
                <FlexImageLetter width={25} text={contact.firstName ?? "-"} image={contact.image} />
                <TextLinkStyle onClick={() => setShowModal(true)}>{getModelFullName(contact)}</TextLinkStyle>
            </Flex>
        </>
    );
};

interface OrderCardLinkProps {
    title: string;
    subTitle?: string;
    icon: string;
    color: string;
    onClick: () => void;
}
const OrderCardLink = (props: OrderCardLinkProps) => {
    return (
        <>
            <OrderLinkCardStyle color={props.color} onClick={() => props.onClick()}>
                <i className={`icon ${props.icon}`}></i>
                <Flex column gap5>
                    <span>{props.title}</span>
                    {props.subTitle && <span>{props.subTitle}</span>}
                </Flex>
            </OrderLinkCardStyle>
        </>
    );
};
