import styled from "styled-components";
import { IThemePalette } from "../../../theme/themePalette";

interface ErrorProps {
    theme: {
        palette: IThemePalette;
    };
    error?: any;
}

export const FormGroupTextField = styled.div`
    position: relative;
`;

export const ErrorLabel = styled.span`
    color: #f44336;
    font-size: 13px;
`;

export const FormField = styled.input`
    box-sizing: border-box;
    font-family: inherit;
    width: 100%;
    border: 1px solid ${(props: ErrorProps) => (props.error ? "#f44336" : props.theme.palette.backgroundDark)};
    border-radius: 4px;
    outline: 0;
    font-size: 14px;
    color: ${(props) => props.theme.palette.text};
    padding: 0.8rem;
    background: transparent;
    transition: border-color 0.1s;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ .form__label {
        top: 13px;
        cursor: text;
    }
    &:focus {
        ~ .form__label {
            position: absolute;
            top: -9px;
            display: block;
            transition: top 0.2s;
            color: ${(props) => props.theme.palette.primary};
        }
        border-radius: 4px;
        border-color: ${(props) => props.theme.palette.primary};
    }

    &:required,
    &:invalid {
        box-shadow: none;
    }
`;

export const FormLabel = styled.label`
    position: absolute;
    top: -9px;
    left: 7px;
    display: block;
    transition: 0.2s;
    font-size: 13px;
    padding: 0px 2px;
    background: ${(props) => props.theme.palette.background};
    color: ${(props: ErrorProps) => (props.error ? "#f44336" : props.theme.palette.textLight)};
    border-radius: 11px;
`;

export const ParentContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
`;
