import styled from "styled-components";
import { horMenuHeight } from "../../../constants/defaultValues";
import { device } from "../../../theme/themeProvider";
import { DefaultButton } from "../../_controls";
import SearchInputTimer from "../../_controls/inputs/SearchInputTimer";
import { TableCustomTr } from "../../_controls/tables/styled";
import { OrderStyles } from "../OrderStyles/styled";

export const ListContainer = styled(OrderStyles)`
    padding: 0;
    padding-top: 2px;
`;

export const OrderListGlobalContainer = styled.div``;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const TopView = styled(FlexContainer)`
    position: sticky;
    top: ${horMenuHeight};
    padding: 10px 20px;
    background: ${(props) => props.theme.palette.background};
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    flex-wrap: wrap;
`;

export const SearchCustom = styled(SearchInputTimer)`
    border-radius: 50px;
    margin-right: 10px;
`;

export const CustomRow = styled(TableCustomTr)`
    td {
        padding: 0px;
        a,
        &.padding {
            padding: 10px 4px;
        }
    }

    &.declined {
        opacity: 0.3;
    }
`;

interface DateRangeProps {
    justifyStart?: boolean;
    w100?: boolean;
}

export const DateRangeSelectedView = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props: DateRangeProps) => (props.justifyStart ? "start" : "center")};
    overflow: hidden;
    width: ${(props: DateRangeProps) => (props.w100 ? "100%" : "auto")};
    .btn-date-picket-text {
        border: 0px;
    }
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 50px;
    .remove-date-filter {
        width: 25px;
        height: 25px;
        min-width: 25px;
        margin-right: 2px;
    }
`;

export const FakeOrderCardView = styled.div`
    width: 100%;
    height: 100px;
    border-radius: 15px;
    animation: backgroundChange 1s linear infinite;

    @keyframes backgroundChange {
        0% {
            background: transparent;
        }
        50% {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        100% {
            background: transparent;
        }
    }
`;

export const OrderCardContainer = styled.div`
    position: relative;
    .hide-on-hover {
        display: flex;
    }

    .hover-comp {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 2px;
        right: 5px;
        background: transparent;

        padding: 5px;
        border-radius: 15px;
        transition: opacity 0.2s ease-in-out;

        button {
            background: ${(props) => props.theme.palette.background};
            color: ${(props) => props.theme.palette.text};
            box-shadow: 1px 1px 5px ${(props) => props.theme.palette.shadowColor};
        }
    }
    &:hover {
        .hover-comp {
            visibility: visible;
            right: 5px;
            top: 2px;
            display: flex;
            opacity: 1;
        }
        .hide-on-hover {
            display: none;
        }
    }

    &:hover .order-body,
    .selected {
        background: ${(props) => props.theme.palette.primary}15;
        border-color: transparent;
        border-radius: 15px;
    }
`;

export const ShipmentStatusLine = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    background: ${(props) => props.theme.palette.backgroundLight};
    border-radius: 20px;
    padding: 2px 10px;
    width: fit-content;

    .status-icon {
    }

    &.status-delivered {
        color: #304ffe;
        background: #304ffe20;
    }
    &.status-shipped {
        color: #00bfa5;
        background: #00bfa520;
    }
    &.status-processing {
        color: #f57f17;
        background: #f57f1720;
    }
    &.status-readyToShip {
        color: #7c4dff;
        background: #7c4dff20;
    }
    &.status-pending {
        color: #546e7a;
        background: #546e7a20;
    }
`;
export const OrderCardBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: ${(props) => props.theme.palette.background};
    border-radius: 15px;
    padding: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    cursor: pointer;

    .card-header {
        .logistics-icon {
            font-size: 12px;
            color: ${(props) => props.theme.palette.textLight};
        }
        .card-header-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            &.general {
                color: ${(props) => props.theme.palette.textLight};
                font-weight: 400;
            }
        }
    }
`;

export const OrderPageOptions = styled.div`
    display: flex;
    margin-bottom: 10px;
    margin-left: 2px;
    width: 100%;
    overflow: auto;
`;

export const PageOptionButton = styled(DefaultButton)`
    border: 0px;
    font-weight: 400;
    border-radius: 10px 10px 0px 0px;
    color: ${(props) => props.theme.palette.textLight};
    border-bottom: 2px solid transparent;
    &.active {
        color: ${(props) => props.color};
        font-weight: 500;
        border-bottom: 2px solid ${(props) => props.color};
    }
`;

export const HeaderInvoiceMultiple = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 15px 0px 15px;
    align-items: flex-start;
`;

export const ContentInvoiceMultiple = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    position: relative;
    gap: 15px;
    cursor: default;

    .order-checkbox {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 3px solid ${(props) => props.theme.palette.backgroundDark};
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        position: absolute;
        left: 13px;

        &:hover {
            border: 3px solid ${(props) => props.theme.palette.primary};
            background: ${(props) => props.theme.palette.primary}20;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.selected {
        .order-checkbox {
            border: 3px solid ${(props) => props.theme.palette.primary};
            color: white;
            background: ${(props) => props.theme.palette.primary};
            visibility: visible;
        }
        background: ${(props) => props.theme.palette.primary}10;
        border-bottom: 1px solid ${(props) => props.theme.palette.background};
    }

    &:hover {
        .order-checkbox {
            visibility: visible;
        }
        box-shadow: 1px 1px 13px ${(props) => props.theme.palette.shadowColor};
    }

    &.success {
        background: ${(props) => props.theme.palette.primary}20;
    }
    &.error {
        background: ${(props) => props.theme.palette.dangerColor}20;
    }

    .status-label {
        height: 25px;
        width: 25px;
        border-radius: 50px;
        padding: 2px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.palette.backgroundLight};

        &.success {
            background: ${(props) => props.theme.palette.primary};
            color: white;
        }
        &.error {
            background: ${(props) => props.theme.palette.dangerColor};
            color: white;
        }
    }
`;

export const ShowFilterMore = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 10px 20px;
`;

export const FooterModalFilter = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    padding: 10px 20px 20px;
    height: fit-content;
    margin-bottom: 50px;
`;

export const SearchContainer = styled.div`
    background: ${(props) => props.theme.palette.background};
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    display: flex;
    position: sticky;
    top: 0px;
    padding: 10px 20px;
    flex-direction: column;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .withOutPadding {
        padding-left: -10px;
        margin-left: -10px;
    }
`;

export const OptionalHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ${device.md} {
        display: flex;
        flex-direction: row;
    }
`;
