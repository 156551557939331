import styled from "styled-components";

export const AccountPermissionCardList = styled.div`
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    .card {
        background: red;
        border-radius: 10px;
    }
`;

export const PermissionCardListContainer = styled.div`
    position: relative;
    padding: 2px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .blocked {
        position: absolute;
        top: 1px;
        z-index: 4;
        height: 100%;
        width: 100%;
    }

    .permission-row {
        padding-bottom: 10px;
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
        &:last-child {
            border-bottom: 0;
        }
    }
`;

export const PermissionFormSubContainer = styled.div`
    overflow: auto;
    padding: 0px 15px;
`;

export const StickyPanel = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    padding: 10px 15px;
    top: 0px;
    z-index: 1;
    background: ${(props) => props.theme.palette.background};
    margin-bottom: 10px;
    box-shadow: 1px 1px 6px 3px ${(props) => props.theme.palette.shadowColor};
`;

export const PermissionFormContainer = styled.form`
    padding: 15px 0px;
    padding-top: 0px;
`;

export const PermissionCheckboxContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(208px, 1fr));

    .checkbox-item {
    }
`;
