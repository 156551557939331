import React, { useEffect, useState } from "react";
import { Order, OrderItem } from "../../../api/models/order";
import { IOrderTable } from "../../../api/models/orderTable";
import InvoicePrint from "./OrderInvoicePrint";

export interface InvoiceGiftProps {
    currentOrder: Order;
    orderTable?: IOrderTable;
}

export const OrderInvoiceGiftPrint = (props: InvoiceGiftProps) => {
    const [order, setOrder] = useState<Order>();

    useEffect(() => {
        loadOrderGift();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentOrder]);

    const loadOrderGift = () => {
        let orderCopy = { ...props.currentOrder };
        orderCopy.items = props.currentOrder.items;
        let newItems: OrderItem[] = [];

        orderCopy.items?.forEach((item: OrderItem) => {
            let newItem = { ...item };
            newItem.subTotal = 0;
            newItem.total = 0;
            newItem.unitPrice = 0;
            newItems.push(newItem);
        });

        orderCopy.items = newItems;
        orderCopy.subTotal = 0;
        orderCopy.totalAmount = 0;
        orderCopy.totalTip = 0;
        orderCopy.payments = [];
        orderCopy.totalAmountItems = 0;
        orderCopy.totalAmountReturns = 0;
        setOrder(orderCopy);
    };

    return (
        <>
            {order && (
                <InvoicePrint
                    showDropdownClass
                    order={order}
                    orderTable={props.orderTable}
                    btnIcon="wahioicon-gift"
                    btnTitle="imprimir como regalo"
                />
            )}
        </>
    );
};

