import Compressor from "compressorjs";

export const compressImageAsync = (file: File) => {
    return new Promise<File>((resolve, reject) => {
        new Compressor(file, {
            quality: 0.7, // 0.6 can also be used, but it's not recommended to go below.
            maxWidth: 2000,
            maxHeight: 2000,
            convertSize: 5000000,
            strict: true,
            checkOrientation: true,
            success: (compressedResult) => {
                const fileResult = new File([compressedResult], file.name, { type: file.type, lastModified: Date.now() });
                resolve(fileResult);
            },
            error(err) {
                reject(err);
            },
        });
    });
};

export const compressImage = (file: any, onCompresResult: (file: File, result: any) => void) => {
    const image = file;
    new Compressor(image, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        maxWidth: 1500,
        maxHeight: 1500,
        convertSize: 5000000,
        strict: true,
        checkOrientation: true,        
        success: (compressedResult) => {
            const fileResult = new File([compressedResult], image.name, { type: image.type, lastModified: Date.now() });
            onCompresResult(fileResult, URL.createObjectURL(image));
        },
    });
};

export const isFileImageValid = (file: any) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/x-icon", "image/webp", "image/svg+xml", "image/avif", "image/apng"];
    if (validTypes.indexOf(file.type) === -1) {
        return false;
    }
    return true;
};

export const getFormDataImage = (imageFile: any, modelId?: string) => {
    const formData = new FormData();
    if (modelId) formData.append("id", modelId);
    formData.append("files", imageFile);
    return formData;
};
