import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { Contact, ContactLabel, ContactSearchModel, ContactType, SearchContactSortList } from "../../../api/models/contact";
import { localOrderEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import { useContactContext } from "../../../store/contexts/ContactContext";
import { UserContext } from "../../../store/contexts/UserContext";
import { useAlert } from "../../Alerts/Alert";
import ConfusedManView from "../../EmptyResultViews/ConfusedManView";
import ExcelImportModal from "../../ImportExport/ExcelImport/ExcelImportModal";
import {
    ContactImportModel,
    IContactImportItem,
    IImportRequestModel,
    ImportResponse,
    convertContactListToExport,
    exportExcelFile,
} from "../../ImportExport/ExcelImport/helper";
import PermissionDenied from "../../PermissionDenied";
import { DefaultButton, Flex, TextField } from "../../_controls";
import { TableCustom, TableCustomTr } from "../../_controls/tables/styled";
import CreateContactModal from "../ContactForm/CreateContactModal";
import ContactListRow from "./ContactListRow";
import messages from "./messages";

import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import { useIsMobileListener } from "../..";
import { DateFilter, IPaginationResponse } from "../../../api/models";
import { GroupItem } from "../../../api/models/_statistics";
import { contactLabelsByAccount, contactQuerySearch, contactSellerSummary } from "../../../api/orders/contactApi";
import { MedalsImages } from "../../../assets";
import { checkAndGetMessage } from "../../../i18n/helper";
import { formatMoney, getDateFormatLocal } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { useDatePickerModal } from "../../DatePicker/DatePickerModal";
import { dateDefaultOptionPairsTitle, dateDefaultOptions } from "../../DatePicker/filesRange";
import datePickerMessages from "../../DatePicker/messages";
import LoadingDualRing from "../../LoadingDualRing";
import { initialRange } from "../../Reports/Utils";
import SearchTabFilter from "../../SearchTabTitle/SearchTabFilter";
import { VerticalMenuEmbedContent } from "../../VerticalMenuItem";
import ButtonSortColumn from "../../_controls/buttons/ButtonSortColumn";
import { FlexImageIcon } from "../../_controls/containers/FlexImageLetter";
import CheckBox from "../../_controls/inputs/CheckBox";
import ConfirmationModal from "../../modals/ConfirmationModal";
import ContactLabelListModal from "../ContactLabels/ContactLabelListModal";
import ContactLabelPanel from "./ContactLabelPanel";
import { useContactSearchParams } from "./contactParams";
import { FlexCheckboxImage, MainContainer, SellerCardContainer, SellerCardContainers } from "./styled";

export interface SelectableCustomerProps {
    onSelectedSeller?: (customer: Contact) => void;
}

interface CustomerListState extends SelectableCustomerProps {
    redirect?: boolean;
}

const CONTAINER_ID = "CONTACT_CONTAINER_1828";

interface CustomerListState {
    showCustomerModal: boolean;
    showImportModal: boolean;
    importLoading: boolean;
    importResponse?: ImportResponse<Contact>;
    selectedContactIds: string[];
    showContactLabelListModal: boolean;
    selectedContactLabel?: ContactLabel;
}

interface ContactListProps {
    contactType?: ContactType;
}

export default function ContactList(props: ContactListProps) {
    const intl = useIntl();

    const { contactParams, setContactParams } = useContactSearchParams();

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [changeStatusInProgress, setChangeStatusInProgress] = useState(false);
    const [downloadingContacts, setDownloadingContacts] = useState(false);
    const alert = useAlert();
    const [state, setState] = useState<CustomerListState>({
        showCustomerModal: false,
        showImportModal: false,
        importLoading: false,
        importResponse: undefined,
        selectedContactIds: [],
        showContactLabelListModal: false,
        selectedContactLabel: undefined,
    });

    const { userState } = useContext(UserContext);
    const { contactState, setContactState } = useContactContext();

    const contactSearchMutation = useMutation((data: ContactSearchModel) => contactQuerySearch(data), {
        onSuccess: (data) => {
            setContactState({ ...contactState, pagination: data, contactType: props.contactType });
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });
    const labelSearchMutation = useMutation((accountId: string) => contactLabelsByAccount(accountId), {
        onSuccess: (data) => {
            setContactState({ ...contactState, labels: data });
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    useEffect(() => {
        if (contactSearchMutation.isSuccess && !labelSearchMutation.isSuccess) {
            if (contactState.labels.length === 0) labelSearchMutation.mutate(getAuthBody().accountId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactSearchMutation.isSuccess]);

    useEffect(() => {
        handleSearchCustomers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contactType, contactParams, state.selectedContactLabel]);

    const handleSearchCustomers = () => {
        let body: ContactSearchModel = {
            accountId: getAuthBody().accountId,
            searchValue: contactParams.search?.trim() ?? "",
            pageSize: 20,
            currentPage: contactParams.page ?? 1,
            contactLabelId: state.selectedContactLabel?.id,
            sort: (contactParams.sort as SearchContactSortList) ?? "default",
            sortDesc: !contactParams.sortAsc,
            includeLabels: true,
        };
        if (props.contactType) {
            body[props.contactType] = true;
        }
        contactSearchMutation.mutate(body);
    };

    const onCreateCustomer = (seller: Contact) => {};

    const isMobile = useIsMobileListener({ size: 800 });

    const handleDownloadContacts = (page: number = 1, items: Contact[] = []) => {
        let body: ContactSearchModel = {
            accountId: getAuthBody().accountId,
            searchValue: contactParams.search?.trim() ?? "",
            pageSize: 200,
            currentPage: page,
            contactLabelId: state.selectedContactLabel?.id,
            sort: "default",
            sortDesc: true,
            includeAddresses: true,
        };
        setDownloadingContacts(true);
        wahioFetch.post(
            localOrderEndpoint.post.customerQuerySearch,
            body,
            (success) => {
                let res: IPaginationResponse<Contact> = success.data;
                items = [...items, ...res.items];
                console.log("PAGE: ", page, " OF ", res.totalPages);
                if (res.totalPages > page) {
                    handleDownloadContacts(page + 1, items);
                } else {
                    exportExcelFile(convertContactListToExport(items), "contactos");
                    setDownloadingContacts(false);
                }
            },
            (error) => {
                setDownloadingContacts(false);
            }
        );
    };

    const handleImport = (items: IContactImportItem[]) => {
        let model: IImportRequestModel<IContactImportItem> = {
            accountId: getAuthBody().accountId,
            items,
        };
        setState({ ...state, importLoading: true });
        wahioFetch.post(
            localOrderEndpoint.post.customerImport,
            model,
            (success) => {
                let response: ImportResponse<Contact> = success.data;
                let closeModal = false;
                if (response.failures.length > 0 && response.items.length === 0) {
                    alert.info(intl.formatMessage(messages.conflictsFound));
                } else if (response.failures.length > 0) {
                    alert.info(intl.formatMessage(messages.contactSuccessfullyConflicts));
                } else {
                    closeModal = true;
                    alert.success(intl.formatMessage(messages.contactSuccessfullySummary));
                }
                handleSearchCustomers();
                setState({ ...state, importLoading: false, importResponse: success.data, showImportModal: !closeModal });
            },
            (error) => {
                setState({ ...state, importLoading: false });
                alert.error(getServiceMessageError(error));
            }
        );
    };
    const permission = getUserPermissions(userState.user);
    if (!permission.contact?.read) {
        return <PermissionDenied />;
    }

    const onSelectedProductChange = (id: string, isAdd: boolean) => {
        if (!isAdd) {
            const newList = state.selectedContactIds.filter((x) => x !== id);
            setState({ ...state, selectedContactIds: newList });
        } else {
            setState({ ...state, selectedContactIds: [...state.selectedContactIds, id] });
        }
    };

    const onRemoveCustomers = () => {
        setShowDeleteConfirmation(false);
        let contactIds = state.selectedContactIds;
        setChangeStatusInProgress(true);
        wahioFetch.post(
            localOrderEndpoint.post.contactDeleteList,
            { contactIds },
            () => {
                setChangeStatusInProgress(false);

                alert.success(intl.formatMessage(messages.contactsDeleted));
                setState({ ...state, selectedContactIds: [] });

                handleSearchCustomers();
            },
            (error) => {
                setChangeStatusInProgress(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const selectedAllProductsIds = (isAdd: boolean) => {
        if (!isAdd) {
            setState({ ...state, selectedContactIds: [] });
            return;
        }
        const ids = contactState.pagination.items.map((item) => (item.id ? item.id : ""));
        if (ids && ids.length > 0) {
            setState({ ...state, selectedContactIds: ids });
        }
    };

    const onClickSort = (sort: SearchContactSortList) => {
        setContactParams({ ...contactParams, sort: sort });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${intl.formatMessage(messages.contacts)}`} - Wahio</title>
            </Helmet>

            <MainContainer>
                <VerticalMenuEmbedContent>
                    <Flex column gap10 padding={15}>
                        <hr />
                        <DefaultButton
                            w100
                            className={`IMPORT_CONTACTS`}
                            disabled={!permission.contact?.update}
                            onClick={() => setState({ ...state, showImportModal: true })}
                            rounded
                            hideBorder
                            removeBackground
                            justifyStart
                            small={isMobile}
                        >
                            <i className="fa-light fa-upload"></i> {intl.formatMessage(messages.import)}
                        </DefaultButton>
                        <DefaultButton
                            w100
                            justifyStart
                            hideBorder
                            className={`DOWNLOAD_CONTACTS`}
                            removeBackground
                            disabled={downloadingContacts}
                            small={isMobile}
                            onClick={() => handleDownloadContacts()}
                            rounded
                        >
                            <i className="fa-light fa-download"></i>
                            {intl.formatMessage(messages.download)}
                            {downloadingContacts && <LoadingDualRing small />}
                        </DefaultButton>
                        <hr />

                        <ContactLabelPanel
                            onClickLabel={(label) => {
                                setState({
                                    ...state,
                                    selectedContactLabel: state.selectedContactLabel?.id === label.id ? undefined : label,
                                });
                            }}
                            selectedLabel={state.selectedContactLabel}
                        />
                    </Flex>
                </VerticalMenuEmbedContent>
                {state.showContactLabelListModal && (
                    <ContactLabelListModal
                        setShow={() => setState({ ...state, showContactLabelListModal: false })}
                        show={state.showContactLabelListModal}
                    />
                )}
                {showDeleteConfirmation && (
                    <ConfirmationModal
                        show={showDeleteConfirmation}
                        setShow={setShowDeleteConfirmation}
                        title={intl.formatMessage(messages.youAreSure)}
                        description={intl.formatMessage(messages.actionNotReversed)}
                        onCancel={() => setShowDeleteConfirmation(false)}
                        onOkay={() => onRemoveCustomers()}
                    />
                )}
                {state.showImportModal && (
                    <ExcelImportModal
                        modalTitle={intl.formatMessage(messages.importContacts)}
                        template={"contact"}
                        linkTitle={intl.formatMessage(messages.exampleContacts)}
                        show={state.showImportModal}
                        onHandleImport={handleImport}
                        importLoading={state.importLoading}
                        failures={state.importResponse?.failures}
                        importModel={ContactImportModel}
                        onCancel={() => setState({ ...state, showImportModal: false })}
                        setShow={(value) => setState({ ...state, showImportModal: value })}
                        mapping={{}}
                    />
                )}
                {state.showCustomerModal && (
                    <CreateContactModal
                        onCreate={onCreateCustomer}
                        show={state.showCustomerModal}
                        redirectToProfile
                        defaultContactType={props.contactType}
                        setShow={(value) => setState({ ...state, showCustomerModal: value })}
                    />
                )}
                <SearchTabFilter
                    title={intl.formatMessage(messages.contacts)}
                    searchValue={contactParams.search}
                    setSearchValue={(value) => {
                        setContactParams({ ...contactParams, search: value });
                    }}
                    btnTitle={`${props.contactType ? checkAndGetMessage(intl, messages, props.contactType) : "Contacto"}`}
                    usePlusIcon
                    placeholder={intl.formatMessage(messages.searchContacts)}
                    onClickBtn={() => setState({ ...state, showCustomerModal: true })}
                    loading={contactSearchMutation.isLoading}
                    pagination={contactState.pagination}
                    onPageChange={(page) => setContactParams({ ...contactParams, page })}
                    btnTourName={"CREATE_CONTACT"}
                >
                    {!isMobile && state.selectedContactIds.length > 0 && (
                        <DefaultButton onClick={() => setShowDeleteConfirmation(true)} rounded={true}>
                            <i className="fa-light fa-trash"></i> {intl.formatMessage(messages.remove)} ( {state.selectedContactIds.length})
                            {changeStatusInProgress && <LoadingDualRing small />}
                        </DefaultButton>
                    )}
                </SearchTabFilter>

                {contactState.pagination.items.length === 0 && <ConfusedManView />}

                <div id={CONTAINER_ID}>
                    <TableCustom cellSpacing="0" cellPadding="0">
                        <thead>
                            {!isMobile && contactState.pagination.items.length > 0 && (
                                <TableCustomTr>
                                    <th>
                                        <FlexCheckboxImage>
                                            <CheckBox
                                                id={"allproducts"}
                                                name={"allproducts"}
                                                onChange={(e) => selectedAllProductsIds(e.target.checked)}
                                            />
                                        </FlexCheckboxImage>
                                    </th>
                                    <th>
                                        <ButtonSortColumn
                                            title={intl.formatMessage(messages.name)}
                                            onClick={() => onClickSort("name")}
                                            selectedSortOption={{
                                                sort: (contactParams.sort as SearchContactSortList) ?? "default",
                                                sortAsc: !!contactParams.sortAsc,
                                            }}
                                            sort="name"
                                        />
                                    </th>
                                    <th>Email</th>
                                    <th>{intl.formatMessage(messages.phone)}</th>
                                    <th>{intl.formatMessage(messages.label)}</th>
                                </TableCustomTr>
                            )}
                        </thead>
                        <tbody>
                            {contactState.pagination.items.map((item) => (
                                <ContactListRow
                                    onSelectedChange={onSelectedProductChange}
                                    selected={state.selectedContactIds.indexOf(item.id ?? "") > -1}
                                    isMobile={isMobile}
                                    key={item.id}
                                    contact={item}
                                />
                            ))}
                        </tbody>
                    </TableCustom>
                </div>
            </MainContainer>
        </>
    );
}

export const ContactListSeller = () => {
    const DataPicker = useDatePickerModal({ defaultRange: initialRange });
    const alert = useAlert();
    const intl = useIntl();
    const [groupItems, setGroupItems] = useState<GroupItem[]>([]);

    const summaryMutation = useMutation((data: DateFilter) => contactSellerSummary(data), {
        onSuccess: (data) => {
            if (data.length > 0) {
                setGroupItems(data);
            } else {
                var items: GroupItem[] = [...Array(3)].map((_, i) => {
                    return {
                        type: "",
                        title: "Vendedor #" + (i + 1),
                        titleNumber: 0,
                        date: new Date(),
                        value: 0,
                        count: 0,
                    };
                });
                setGroupItems(items);
            }
        },
        onError: (error) => {
            alert.error(getServiceMessageError(error));
            setGroupItems([]);
        },
    });

    const [filterState, setFilterState] = useState(() => {
        return {
            dateStart: customMoment(dateDefaultOptions.startOfMonth).format(),
            dateEnd: customMoment(dateDefaultOptions.endOfMonth).format(),
            title: "thisMonth",
        };
    });

    useEffect(() => {
        handleLoadSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState]);

    const handleLoadSummary = () => {
        const filter: DateFilter = {
            dateStart: filterState.dateStart,
            dateEnd: filterState.dateEnd,
            minutesOffset: new Date().getTimezoneOffset(),
            accountId: getAuthBody().accountId,
            limit: 10,
        };
        summaryMutation.mutate(filter);
    };

    return (
        <>
            <Flex column>
                <Flex style={{ gap: "5px 15px" }} alignCenter paddingLeft={15} paddingTop={15} paddingBottom={5} flexWrap>
                    <TextField bold>
                        Resumen vendedores: <span>{checkAndGetMessage(intl, datePickerMessages, filterState.title)}</span>
                    </TextField>
                    <DataPicker.Modal
                        showSelectionTitle
                        preventRemove
                        small
                        bgLight
                        onChange={(range) =>
                            setFilterState({
                                ...filterState,
                                dateStart: getDateFormatLocal(range.startDate),
                                dateEnd: getDateFormatLocal(range.endDate),
                                title: dateDefaultOptionPairsTitle(range),
                            })
                        }
                    />
                    {summaryMutation.isLoading && <LoadingDualRing small />}
                </Flex>

                <SellerCardContainers>
                    <Flex gap15 className="body">
                        {groupItems.map((item, index) => {
                            let image: string | undefined = undefined;
                            if (index <= 2) {
                                image = MedalsImages[index];
                            }

                            return (
                                <SellerCardContainer key={index}>
                                    {image ? (
                                        <FlexImageIcon image={image} />
                                    ) : (
                                        <span className={`card-number-${index + 1}`}>{index + 1}</span>
                                    )}
                                    <Flex column className="card-body">
                                        <TextField ellipsis marginBottom={10}>
                                            {item.title}
                                        </TextField>
                                        {item.value !== 0 && (
                                            <TextField ellipsis bold fontSize={18}>
                                                {formatMoney(item.value)}
                                            </TextField>
                                        )}
                                        {item.count !== 0 && (
                                            <TextField ellipsis light>
                                                {item.count} Ordenes
                                            </TextField>
                                        )}
                                    </Flex>
                                </SellerCardContainer>
                            );
                        })}
                    </Flex>
                </SellerCardContainers>
            </Flex>
            <ContactList contactType="isSeller" />
        </>
    );
};
export const ContactListCompany = () => {
    return <ContactList contactType="isCompany" />;
};
export const ContactListSupplier = () => {
    return <ContactList contactType="isSupplier" />;
};
export const ContactListCourier = () => {
    return <ContactList contactType="isCourier" />;
};
