import React from "react";
import { WhatsappButtonLink } from "../styled";

export default function WhatsappButton() {
    return (
        <WhatsappButtonLink
            href="https://api.whatsapp.com/send?phone=573114114261&text=Hola,%20necesito%20ayuda%20para%20elegir%20un%20plan!"
            target="_blank"
        >
            <span className="wahioicon-whatsapp help-icon"></span> ¿Necesitas ayuda?
        </WhatsappButtonLink>
    );
}
