import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "../../../Alerts/Alert";
import { useMutation } from "react-query";
import CreateTable from "react-select/creatable";
import { SelectOptionObject } from "../../../../api/models";
import { IProductCategory, ProductCategory } from "../../../../api/models/productCategory";
import { createUpdateCustomCategory } from "../../../../api/products/categoryApi";
import { getAuthBody } from "../../../../auth";
import { CustomCategoryContext } from "../../../../store/contexts/CustomCategoryContext";
//import { SelectBody } from "../../../Select/styled";
import { SelectBody } from "../../../Select/styled";
import Select from "react-select";


interface ProductCategorySelectorProps {
    defaultCategoryId?: string;
    title?: string;
    onChangeCategory: (category?: IProductCategory) => void;
    allowRemove?: boolean;
    disabled?: boolean;
    hideTitle?: boolean;
    selectBodyClass?: string;
    placeHolder?: string;
    disallowCreate?: boolean;
}

export default function ProductCategorySelector(props: ProductCategorySelectorProps) {
    const isMounted = React.useRef(true);
    const [selectedCategory, setSelectedCategory] = useState<IProductCategory>();
    const alert = useAlert();
    
    const { customCategoryState, customCategoryActions } = useContext(CustomCategoryContext);

    const items = customCategoryState.items;

    const createMutation = useMutation((data: any) => createUpdateCustomCategory(data), {
        onSuccess: (data) => {
            customCategoryActions.add(data);
            onSelectedCategory(data);
            alert.success("Categoría creada con éxito");
        },
    });

    useEffect(() => {
        if (props.defaultCategoryId) {
            var selected = items.find((x) => x.id === props.defaultCategoryId);
            setSelectedCategory(selected);
        } else {
            setSelectedCategory(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.defaultCategoryId]);

    useEffect(() => {
        if (customCategoryState.items.length === 0) {
            customCategoryActions.request();
        }
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectedCategory = (category?: IProductCategory) => {
        setSelectedCategory(category);
        props.onChangeCategory(category);
    };

    const onCreateCustomCategory = (name: string) => {
        const newCategory = new ProductCategory(getAuthBody().accountId, name);
        createMutation.mutate(newCategory);
    };

    const getSelectCategory = (item: IProductCategory) => {
        let option: SelectOptionObject<IProductCategory> = {
            value: item.id ?? "",
            label: item.name,
            object: item,
        };
        return option;
    };

    const Selector = props.disallowCreate ? Select : CreateTable

    return (
        <SelectBody className={props.selectBodyClass ?? ""}>
            {!props.hideTitle && <span className="label">{props.title ?? "Seleccionar Categoría"}</span>}
            <Selector
                placeholder={props.placeHolder ?? "Seleccionar"}
                className={`react-select-basic ${selectedCategory?.id ? "item-active" : ""}`}
                classNamePrefix="select"
                key={selectedCategory?.id}
                defaultValue={selectedCategory?.id ? getSelectCategory(selectedCategory) : undefined}
                isDisabled={props.disabled}
                isRtl={false}
                onCreateOption={onCreateCustomCategory}
                onChange={(value) => onSelectedCategory(value?.object)}
                isClearable={props.allowRemove}
                isLoading={createMutation.isLoading}
                isSearchable={true}
                name="customCategory"
                options={customCategoryState.items.map((item) => getSelectCategory(item))}
            />
        </SelectBody>
    );
}
