import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "../../_controls";
import { Table } from "../../_controls/tables/styled";

export const NavigationCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.palette.background};
    border-radius: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    box-sizing: border-box;
    transition: border 0.2s ease-in-out;

    &.selected {
        border: 1px solid ${(props) => props.theme.palette.primary}60;
    }

    .card-header {
        padding: 10px;
        overflow: hidden;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        border-radius: 15px;
    }

    &.allow-close {
        .card-header {
            :hover {
                cursor: pointer;
                background: ${(props) => props.theme.palette.backgroundLight};
            }
        }
    }

    .card-content {
        padding: 20px;
        padding-top: 10px;
    }
`;

export const NavigationTitle = styled.h4`
    margin: 0;
    font-weight: 500;
`;

export const ProductCardPreviewContainer = styled.div`
    width: 200px;
    border: 2px solid ${({ theme }) => theme.palette.backgroundDark};
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: fit-content;

    .image {
        width: 100%;
        aspect-ratio: 1;
        display: flex;
        position: relative;
        .out-of-stock {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: ${({ theme }) => theme.palette.dangerColor};
            display: flex;
            color: white;
            font-size: 13px;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 16px;
        }
        .image-label {
            position: absolute;
            width: 70px;
            aspect-ratio: 1;
            filter: drop-shadow(5px 5px 5px ${({ theme }) => theme.palette.shadowColor});

            &.topRight {
                right: 10px;
                top: 10px;
            }
            &.topLeft {
                left: 10px;
                top: 10px;
            }
            &.bottomRight {
                right: 10px;
                bottom: 10px;
            }
            &.bottomLeft {
                left: 10px;
                bottom: 10px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &.outOfStock {
        .image {
            img {
                opacity: 0.4;
            }
        }
    }
    .title-content {
        padding: 10px;
        .product-title {
            color: ${({ theme }) => theme.palette.textLight};
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .product-price {
            color: ${({ theme }) => theme.palette.text};
            font-size: 16px;
        }
        .discount-line {
            font-size: 13px;
            text-decoration: line-through;
            color: ${({ theme }) => theme.palette.textLight};
        }
        .product-discount {
            color: white;
            background: #4caf50;
            border-radius: 20px;
            padding: 1px 7px;
        }
    }
`;

export const ImageTabViewContainer = styled.div`
    display: flex;
    gap: 10px;
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.palette.background};

    .tab {
        border-bottom: 2px solid transparent;
        font-size: 13px;
        padding: 4px 1px;
        &.active {
            border-bottom: 2px solid ${({ theme }) => theme.palette.primary};
        }
    }
`;

export const VariantsTable = styled(Table)`
    tr {
        td {
            padding-left: 10px;
        }
    }
    tr {
        :hover {
            td {
                background: ${(props) => props.theme.palette.backgroundLight};
                cursor: pointer;
            }
        }

        &.bg-primary-light {
            td {
                background: ${(props) => props.theme.palette.primary}20;
            }
        }
    }
`;

export const ProfileTable = styled(Table)`
    input {
        border-radius: 30px;
        border: 0;
        background: ${(props) => props.theme.palette.background};
        :hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
    .select__control {
        border-radius: 30px;
        background: transparent;
        border: 0;
        font-weight: 400;
        .select__value-container {
            padding-left: 12px;
        }
        .select__single-value {
            font-weight: 400;
        }
    }
    .item-active {
        .select__control {
            background: transparent;
        }
    }
`;

export const SelectedVariantCard = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};

    :hover {
        background: ${(props) => props.theme.palette.backgroundLight};
        cursor: pointer;
    }
`;

export const ProductStockInvalidContainer = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.palette.backgroundLight};
    width: 100%;
    box-sizing: border-box;
    border-radius: 7px;

    .icon {
        margin-right: 10px;
        font-size: 1.5rem;
        color: #f44336;
        margin-left: 5px;
    }
    .stock-message {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        .bold {
            font-weight: 500;
        }
    }
`;

export const ProductTitleLink = styled(NavLink)`
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.palette.text};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    :visited {
        color: ${({ theme }) => theme.palette.text};
    }
    :hover {
        text-decoration: underline;
    }
`;

export const BarcodeContainerView = styled.div`
    display: flex;
    flex-wrap: wrap;
    .code-card {
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-title {
            margin-bottom: 5px;
            font-size: 13px;
            color: ${(props) => props.theme.palette.textLight};
        }

        canvas,
        img {
            padding: 10px;
            background: white;
            border-radius: 10px;
            height: 100px;
        }
    }
`;

export const ProductEcommerceLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    padding-left: 15px;
    border-radius: 15px;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    .text-link {
        cursor: copy;
    }
    :hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;
export const ProductStatisticsCard = styled(Flex)`
    .card {
        padding: 10px 15px;
        border-radius: 15px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        .value-large {
            font-size: 25px;
            font-weight: 500;
        }
    }
`;
