import { defineMessages } from "react-intl";

const base = "components.orderStatusFilter";

export default defineMessages({
    remove: {
        id: `${base}.remove`,
        defaultMessage: "Remover",
    },
    void: {
        id: `${base}.void`,
        defaultMessage: "Anulado",
    },
    paid: {
        id: `${base}.paid`,
        defaultMessage: "Pagado",
    },
    credit: {
        id: `${base}.credit`,
        defaultMessage: "Crédito",
    },
    open: {
        id: `${base}.open`,
        defaultMessage: "Order Abierta",
    },
    partial: {
        id: `${base}.partial`,
        defaultMessage: "Pago parcial",
    },
    quotation: {
        id: `${base}.quotation`,
        defaultMessage: "Cotización",
    },
    queued: {
        id: `${base}.queued`,
        defaultMessage: "En cola",
    },
    draft: {
        id: `${base}.draft`,
        defaultMessage: "Borrador",
    },
    authorized: {
        id: `${base}.authorized`,
        defaultMessage: "Authorizado",
    },
    declined: {
        id: `${base}.declined`,
        defaultMessage: "Rechazada",
    },
    invalid: {
        id: `${base}.invalid`,
        defaultMessage: "Estado invalido",
    },
    status: {
        id: `${base}.status`,
        defaultMessage: "Estado",
    },
});
