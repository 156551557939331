import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IOrganization } from "../../api/models/organizationTypes";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { CloudImages } from "../../assets";
import Dropdown from "../../components/Dropdown";
import StoreFormModal from "../../components/Stores/StoreFormModal";
import { DefaultButton, Flex, SquareButton } from "../../components/_controls";
import PATHS from "../../constants/paths";
import { OrganizationContext } from "../../store/contexts/OrganizationContext";
import { OrganizationTabContainer, OrganizationTabContent, StoreTab } from "./styled";

interface OrganizationTabProps {
    smallView?: boolean;
}
export default function OrganizationTab(props: OrganizationTabProps) {
    const navigate = useNavigate();
    const { organizationState, setCurrentOrganizationState } = useContext(OrganizationContext);
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const currentOrganization = organizationState.currentOrganization;
    const [showCreateStoreModal, setShowCreateStoreModal] = useState(false);

    const onClickOrganization = (organization: IOrganization) => {
        setCurrentOrganizationState(organization);
        appSettingsActions.setAllSettings({
            ...appSettingsState,
            defaultOrganizationId: organization.id ?? "",
            defaultWarehouseId: organization.ecommerceDefaultWarehouseId,
        });

        let pathArr = window.location.pathname.split("/").filter((x) => x);

        if (pathArr.length > 0) {
            const isStoreProfile = `/${pathArr[0]}/id` === PATHS.storeProfile("id");
            if (isStoreProfile) {
                navigate(PATHS.storeProfile(organization.id));
                return;
            }
        }

        navigate(window.location.pathname + window.location.search);
    };

    if (!currentOrganization) return null;

    const smallSize = props.smallView ?? appSettingsState.menuSmall;

    return (
        <OrganizationTabContainer style={{ width: smallSize ? "auto" : "100%" }}>
            {showCreateStoreModal && <StoreFormModal show={showCreateStoreModal} setShow={setShowCreateStoreModal} />}
            <Dropdown
                styleContainer={{ width: "100%" }}
                styleButton={{
                    border: 0,
                    padding: smallSize ? "0" : "5px",
                    paddingLeft: smallSize ? "0" : "12px",
                }}
                contentHorizontal="right"
                contentVertical="inline"
                ignoreInternalClicks
                contentOutside
                hideIcon
                imageIcon={currentOrganization.image ? currentOrganization.image : CloudImages.defaultImage}
                title={smallSize ? "" : currentOrganization.name}
            >
                <OrganizationTabContent>
                    <div className="store-list">
                        <div className="mb-1">
                            <StoreTabView active item={currentOrganization} onClick={() => {}} />
                        </div>
                        {organizationState.items
                            .filter((x) => x.id !== currentOrganization.id)
                            .slice(0, 6)
                            .map((item, index) => (
                                <StoreTabView key={index} item={item} onClick={onClickOrganization} />
                            ))}
                    </div>
                    <Flex className="mt-1" alignCenter gap10>
                        <DefaultButton borderRadius={10} bgLight onClick={() => setShowCreateStoreModal(true)}>
                            <span className="fa-regular fa-plus"></span> Crear
                        </DefaultButton>
                        <NavLink to={PATHS.accountStores}>
                            <DefaultButton borderRadius={10} hideBorder>
                                <span className="fa-regular fa-arrow-right"></span> Ver Tiendas
                            </DefaultButton>
                        </NavLink>
                    </Flex>
                </OrganizationTabContent>
            </Dropdown>
        </OrganizationTabContainer>
    );
}

interface StoreTabViewProps {
    item: IOrganization;
    onClick: (item: IOrganization) => void;
    active?: boolean;
}

export const StoreTabView = (props: StoreTabViewProps) => {
    const { item, onClick } = props;
    const onClickTab = (e: any) => {
        if (e.target.id === item.id) return;
        onClick(item);
    };
    return (
        <StoreTab onClick={onClickTab} className={`${props.active ? "active" : ""}`}>
            <div className="store-image">
                <img src={item.image ? item.image : CloudImages.defaultImage} alt={item.name} />
            </div>
            <div className="store-content">
                <span>{item.name}</span>
                <span className="small-text">{item.address}</span>
            </div>
            <NavLink to={PATHS.storeProfile(item.id)} className="store-link">
                <SquareButton id={item.id} className="store-tab-icon">
                    <span className="wahioicon-eye"></span>
                </SquareButton>
            </NavLink>
        </StoreTab>
    );
};
