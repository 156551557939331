import React from "react";
import CreatePurchaseForm, { CreatePurchaseFormProps } from ".";
import Modal, { IModalShow } from "../../modals/Modal";

interface CreatePurchaseModalProps extends IModalShow, CreatePurchaseFormProps {}

export default function CreatePurchaseModal(props: CreatePurchaseModalProps) {
    return (
        <Modal
            removeVerticalAlign={true}
            useMobileView
            title={props.order ? "Editar Compra" : "Crear Compra"}
            showHeader={true}
            overflowAuto
            sizeType="lg"
            {...props}
        >
            <CreatePurchaseForm {...props} />
        </Modal>
    );
}
