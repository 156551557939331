import React from "react";
import { ProductNavigationProfileProps } from ".";
import { formatMoney } from "../../../utils";
import { Flex, TextField, FlexImageStyle } from "../../_controls";
import { getOptionalSelectionText } from "../ProductOptionals/helper";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationOptionals(props: ProductNavigationProfileProps) {
    const { product } = props;
    const optionals = product?.optionals;

    if (!optionals) return null;

    return (
        <NavigationCard {...props} title={props.intl.formatMessage(messages.optionals)}>
            <Flex column gap15>
                {optionals.map((optional, index) => {
                    return (
                        <Flex key={index} gap10 column>
                            <Flex alignCenter gap10>
                                <TextField bold>{optional.name}</TextField>
                                <TextField light small>
                                    {getOptionalSelectionText(optional)}
                                </TextField>
                            </Flex>
                            <Flex column gap5>
                                {optional.items.map((item, index) => {
                                    return (
                                        <Flex key={index} alignCenter gap10>
                                            {item.image && (
                                                <FlexImageStyle borderRadius={15} width={35}>
                                                    <img src={item.image} alt={item.name} />
                                                </FlexImageStyle>
                                            )}
                                            <Flex column>
                                                <TextField>{item.name}</TextField>
                                                {item.price > 0 && (
                                                    <TextField small light>
                                                        {formatMoney(item.price)}
                                                    </TextField>
                                                )}
                                            </Flex>
                                            <Flex column marginLeftAuto>
                                                <TextField>Max: {item.maxSelection}</TextField>
                                            </Flex>
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        </Flex>
                    );
                })}
            </Flex>
        </NavigationCard>
    );
}
