import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreatableSelect from "react-select/creatable";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { WahioFile } from "../../../api/models";
import { Contact } from "../../../api/models/contact";
import { IPurchasePayment } from "../../../api/models/orderPayment";
import { IProductOrderImport } from "../../../api/models/product";
import { IPurchase, IPurchaseItem, PurchaseItem } from "../../../api/models/purchase";
import { IWarehouse } from "../../../api/models/warehouse";
import { productEndpoint, purchaseEndpoint } from "../../../api/restApiEndpoint";
import { AppSettingsContext } from "../../../appSettings/AppSettingsContext";
import { getAuthBody } from "../../../auth";
import { COLOMBIA_TAX_WITH_HOLDING } from "../../../constants/colombiaTaxWithHolding";
import { CurrentPurchaseContext } from "../../../store/contexts/CurrentPurchaseContext";
import { OrganizationContext } from "../../../store/contexts/OrganizationContext";
import { emptyPurchase } from "../../../store/reducers/currentPurchase";
import { formatMoney, getUtcDate } from "../../../utils";
import customMoment from "../../../utils/momentFormat/dateFormat";
import { getSelectOption } from "../../../utils/select";
import { useAlert } from "../../Alerts/Alert";
import { getOrgCashRegisterOrDefault } from "../../CashRegisters/cashRegisterUtils";
import ContactSelect from "../../Contacts/ContactSelect";
import CustomDateInput from "../../DatePicker/CustomDateInput";
import Dropdown from "../../Dropdown";
import FilesViewer from "../../Files/FilesViewer";
import ExcelImportModal from "../../ImportExport/ExcelImport/ExcelImportModal";
import {
    IImportRequestModel,
    IProductPurchaseImportItem,
    ImportResponse,
    ImportState,
    ProductOrderImportModel,
} from "../../ImportExport/ExcelImport/helper";
import { PercentageList } from "../../Orders/CreateOrderForm/OrderDetailsPanel/OrderSummaryTotals";
import { InputTipContainer, TotalContainer, TotalTableStyle } from "../../Orders/CreateOrderForm/styled";
import { DetailCard, FlexContainerCenter, IHideProps } from "../../Orders/OrderStyles/styled";
import { getPurchaseCopyOmit } from "../../Orders/orderUtils";
import { SelectBody } from "../../Select/styled";
import TextBoxNumeral from "../../TextBoxNumeral";
import WarehouseSelect from "../../Warehouses/WarehouseSelect";
import { DefaultButton, DefaultInput, Flex, PrimaryButton, SquareButton, TextField } from "../../_controls";
import CheckBox from "../../_controls/inputs/CheckBox";
import ConfirmationModal from "../../modals/ConfirmationModal";
import Modal from "../../modals/Modal";
import { ApplyToAllProductsPurchase } from "./ApplyAllProductsPurchase";
import { ISaveResponse } from "./FinalizeOrderForm";
import ModalFinalizeOrder from "./ModalFinalizeOrder";
import { getPurchaseItemTotals } from "./helpers";
import messages from "./messages";

export interface OrderDetailsProps extends IHideProps {
    onUpdatePurchase: (value: IPurchase) => void;
    currentPurchase: IPurchase;
    hideDelivery?: boolean;
    isMobile?: boolean;
    onFinishOrder?: (value: IPurchase) => void;
    calculateTotalsUpdateItems: (value: IPurchaseItem[]) => void;
}

interface CustomerDetailsProps {
    currentPurchase: IPurchase;
    onUpdateCustomer: (value: Contact | undefined) => void;
}

const CustomerDetails = (props: CustomerDetailsProps) => {
    const { currentPurchase } = props;

    return (
        <ContactSelect
            title="Proveedor"
            hidePadding
            placeholder="Seleccionar Proveedor"
            contact={currentPurchase.supplier}
            onUpdateContact={props.onUpdateCustomer}
            isSupplier
            allowCreate
            checkDebts="debtsPayable"
            loadCompleteContact
        />
    );
};

export default function OrderDetails(props: OrderDetailsProps) {
    const { currentPurchase } = props;
    const { appSettingsState } = useContext(AppSettingsContext);
    const { organizationState } = useContext(OrganizationContext);
    const { currentPurchaseState } = useContext(CurrentPurchaseContext);

    const [openFinalizeModal, setOpenFinalizeModal] = useState(false);
    const [saveOrderContinueLater, setSaveOrderContinueLater] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showConfirmClearOrder, setShowConfirmClearOrder] = useState(false);

    const [importState, setImportState] = useState<ImportState<IProductOrderImport>>({
        importLoading: false,
        importResponse: undefined,
        showImport: false,
    });

    const [responseResult, setResponseResult] = useState<ISaveResponse>({
        isFetching: false,
        isError: false,
    });

    const alert = useAlert();

    const checkRequiredFields = () => {
        if (!currentPurchase.supplierId || !currentPurchase.warehouseId) {
            alert.info("La bodega y el proveedor son requeridos");
            return false;
        }
        return true;
    };

    const onClickFinalize = () => {
        if (!checkRequiredFields()) return;
        setOpenFinalizeModal(true);
        props.onUpdatePurchase({
            ...currentPurchase,
            status: "paid",
            organizationId: appSettingsState.defaultOrganizationId,
        });
    };

    const saveOrderAndContinueLater = () => {
        if (checkRequiredFields()) setSaveOrderContinueLater(true);
    };

    const onClickCancel = () => {
        setOpenFinalizeModal(false);
    };

    const onFinalizeClose = () => {
        setOpenFinalizeModal(false);
    };

    const updateItemsWithWarehouse = (receiveAllProducts?: boolean) => {
        let items: IPurchaseItem[] = [];

        currentPurchase.items.forEach((element) => {
            if (receiveAllProducts) {
                let quantityNew = element.quantity - element.quantityReceived;
                element.quantityReceived = element.quantityReceived + quantityNew;
                element.warehouseId = currentPurchase.warehouseId;
                items.push(element);
            } else {
                element.warehouseId = currentPurchase.warehouseId;
                items.push(element);
            }
        });

        let cashRegisterId = getOrgCashRegisterOrDefault(appSettingsState, organizationState.currentOrganization);
        let purchaseData = getPurchaseCopyOmit({ ...currentPurchase, items });

        return {
            ...purchaseData,
            status: "partial",
            accountUserId: getAuthBody().accountUserId,
            lastAccountUserId: getAuthBody().accountUserId,
            lastCashRegisterId: cashRegisterId,
        };
    };

    const onFinish = (receiveAllProducts?: boolean) => {
        let payments: IPurchasePayment[] = currentPurchase.payments ?? [];
        payments.forEach((element) => {
            if (!element.cashRegisterId) {
                alert.error("Debe seleccionar una caja para cada pago");
                return;
            }
        });

        setResponseResult({ ...responseResult, isFetching: true });
        wahioFetch.post(
            purchaseEndpoint.post.purchaseAll,
            updateItemsWithWarehouse(receiveAllProducts),
            (success) => {
                props.onUpdatePurchase(emptyPurchase(appSettingsState.purchaseForm));
                setOpenFinalizeModal(false);
                setResponseResult({
                    isFetching: false,
                    isError: false,
                    value: success.data,
                    isSave: true,
                });
                props.onFinishOrder && props.onFinishOrder(success.data);
            },
            (error) => {
                setResponseResult({
                    isFetching: false,
                    isError: true,
                    value: undefined,
                    isSave: false,
                    error,
                });
            }
        );
    };

    const onSaveDraft = () => {
        setSaveOrderContinueLater(false);
        setResponseResult({ ...responseResult, isFetching: true });
        props.onUpdatePurchase({ ...currentPurchaseState.currentPurchase, status: "draft" });
        var purchaseData = getPurchaseCopyOmit(currentPurchase);
        wahioFetch.post(
            purchaseEndpoint.post.purchaseAll,
            { ...purchaseData, status: "draft" },
            (success) => {
                props.onUpdatePurchase(emptyPurchase(appSettingsState.purchaseForm));
                setResponseResult({
                    isFetching: false,
                    isError: false,
                    value: success.data,
                    isSave: true,
                });
            },
            (error) => {
                setResponseResult({
                    isFetching: false,
                    isError: true,
                    value: undefined,
                });
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const handleImport = (items: IProductPurchaseImportItem[]) => {
        let model: IImportRequestModel<IProductPurchaseImportItem> = {
            accountId: getAuthBody().accountId,
            items,
        };

        setImportState({ ...importState, importLoading: true });
        wahioFetch.post(
            productEndpoint.post.productImportGetOrderProducts,
            model,
            (success) => {
                let response: ImportResponse<IProductOrderImport> = success.data;
                let closeModal = false;
                if (response.failures.length > 0 && response.items.length === 0) {
                    alert.info("Conflictos encontrados en la importación");
                } else if (response.failures.length > 0) {
                    alert.info("La lista de productos fue importada correctamente, pero hubo algunos conflictos");
                } else {
                    closeModal = true;
                    alert.success("La lista de productos fue importada correctamente, mira el resumen");
                }

                setImportState({ ...importState, importLoading: false, importResponse: response, showImport: !closeModal });
                handleProductFromImport(response.items);
            },
            (error) => {
                setImportState({ ...importState, importLoading: false });
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const handleProductFromImport = (items: IProductOrderImport[]) => {
        if (items && items.length > 0) {
            let newOrderItems: IPurchaseItem[] = [];
            let counter = 1;
            items.forEach((element) => {
                if (element.product) {
                    var item: IPurchaseItem = new PurchaseItem(element.product, element.quantity);
                    item.unitPrice = element.price;
                    item = getPurchaseItemTotals(item, currentPurchase.taxIncluded, currentPurchase.preTaxDiscount);
                    item.position = counter;
                    newOrderItems.push(item);
                    counter += 1;
                }
            });
            props.calculateTotalsUpdateItems(newOrderItems);
        }
    };

    return (
        <TotalContainer className={`total-container ${props.isMobile ? "mobile" : ""}`}>
            {openFinalizeModal && (
                <ModalFinalizeOrder
                    {...{ responseResult, setResponseResult, currentPurchase: currentPurchase, onFinalizeClose }}
                    updatePurchase={props.onUpdatePurchase}
                    onFinalize={onFinish}
                    onCancel={onClickCancel}
                    show={openFinalizeModal}
                    setShow={setOpenFinalizeModal}
                />
            )}
            {importState.showImport && (
                <ExcelImportModal
                    modalTitle="Importar Compra"
                    template={"productOrder"}
                    linkTitle="Ejemplo Productos de Compra"
                    show={importState.showImport}
                    onHandleImport={handleImport}
                    importLoading={importState.importLoading}
                    failures={importState.importResponse?.failures}
                    importModel={ProductOrderImportModel}
                    onCancel={() => setImportState({ ...importState, importLoading: false, showImport: false, importResponse: undefined })}
                    setShow={() => setImportState({ ...importState, importLoading: false, showImport: false, importResponse: undefined })}
                    mapping={{}}
                />
            )}
            {saveOrderContinueLater && (
                <ConfirmationModal
                    onCancel={() => setSaveOrderContinueLater(false)}
                    onOkay={onSaveDraft}
                    title="Guardar como borrador"
                    description="Podrás seguir editando la orden más adelante"
                    show={saveOrderContinueLater}
                    setShow={setSaveOrderContinueLater}
                />
            )}
            {showConfirmClearOrder && (
                <ConfirmationModal
                    onCancel={() => setShowConfirmClearOrder(false)}
                    onOkay={() => props.onUpdatePurchase(emptyPurchase(appSettingsState.purchaseForm))}
                    title="Estas seguro?"
                    description="Esta acción no se puede revertir, perderás el progreso de la compra"
                    show={showConfirmClearOrder}
                    setShow={setShowConfirmClearOrder}
                />
            )}
            {props.isMobile && showDetailsModal && (
                <Modal show={showDetailsModal} setShow={setShowDetailsModal} showHeader title="Detalles">
                    <Flex padding={20} column gap15>
                        <Details
                            currentPurchase={currentPurchase}
                            saveOrderAndContinueLater={saveOrderAndContinueLater}
                            importState={importState}
                            setImportState={setImportState}
                            onUpdatePurchase={props.onUpdatePurchase}
                            confirmClearOrder={() => setShowConfirmClearOrder(true)}
                        />
                        <ApplyToAllProductsPurchase
                            currentPurchase={currentPurchase}
                            onUpdateAllItem={props.calculateTotalsUpdateItems}
                            showTitle
                        />
                        <PurchaseTableTotals currentPurchase={props.currentPurchase} updatePurchase={props.onUpdatePurchase} />
                    </Flex>
                </Modal>
            )}
            {!props.isMobile && (
                <Details
                    currentPurchase={currentPurchase}
                    saveOrderAndContinueLater={saveOrderAndContinueLater}
                    importState={importState}
                    setImportState={setImportState}
                    onUpdatePurchase={props.onUpdatePurchase}
                    confirmClearOrder={() => setShowConfirmClearOrder(true)}
                />
            )}

            <Flex column gap15 className="total-values-container">
                {!props.isMobile && <PurchaseTableTotals currentPurchase={props.currentPurchase} updatePurchase={props.onUpdatePurchase} />}
                <Flex className={`action-btn-container ${props.isMobile ? "mobile" : ""}`}>
                    {props.isMobile && (
                        <DefaultButton
                            disabled={currentPurchase.items.length === 0}
                            title="Detalles"
                            onClick={() => setShowDetailsModal(true)}
                            className="btn-details"
                        >
                            <i className="fa-regular fa-filter-list"></i>
                        </DefaultButton>
                    )}
                    <PrimaryButton borderRadius={10} disabled={currentPurchase.items.length === 0} onClick={onClickFinalize}>
                        <FormattedMessage {...messages.finish} />
                    </PrimaryButton>
                </Flex>
            </Flex>
        </TotalContainer>
    );
}

interface detailsProps {
    currentPurchase: IPurchase;
    saveOrderAndContinueLater: () => void;
    importState: ImportState<IProductOrderImport>;
    setImportState: (value: ImportState<IProductOrderImport>) => void;
    onUpdatePurchase: (value: IPurchase) => void;
    confirmClearOrder: () => void;
}

const Details = (props: detailsProps) => {
    const { currentPurchase, saveOrderAndContinueLater, importState, setImportState, onUpdatePurchase } = props;
    const { appSettingsState } = useContext(AppSettingsContext);

    const [customDate] = useState(() => {
        return customMoment(getUtcDate(currentPurchase.dateCreatedCustom?.toString()) ?? new Date()).toDate();
    });

    const onWarehouseChange = (warehouse?: IWarehouse) => {
        const warehouseId = warehouse && warehouse.id ? warehouse.id : "";
        onUpdatePurchase({ ...currentPurchase, warehouse, warehouseId });
    };

    const getTaxWithholdingPercentage = (value: number) => {
        if (value > 100) {
            value = 100;
        }
        if (value < 0) {
            value = 0;
        }
        onUpdatePurchase({ ...currentPurchase, taxWithholdingPercentage: value });
    };

    const handleUpdateDateCustom = (date: Date) => {
        props.onUpdatePurchase({
            ...currentPurchase,
            dateCreatedCustom: date,
        });
    };

    const onSelectedFiles = (files: WahioFile[] = []) => {
        onUpdatePurchase({
            ...currentPurchase,
            files,
        });
    };

    const updateCustomer = (value?: Contact) => {
        props.onUpdatePurchase({ ...currentPurchase, supplier: value, supplierId: value?.id ?? "" });
    };

    return (
        <Flex column gap15>
            <PurchaseOptionsTop
                purchase={currentPurchase}
                handleOpenCancelModal={saveOrderAndContinueLater}
                onImportPurchase={(value) => setImportState({ ...importState, showImport: value })}
                onUpdatePurchase={onUpdatePurchase}
                confirmClearOrder={props.confirmClearOrder}
            />
            <CustomerDetails currentPurchase={currentPurchase} onUpdateCustomer={updateCustomer} />
            <DetailCard>
                <WarehouseSelect defaultWarehouseId={currentPurchase.warehouseId} onWarehouseChange={onWarehouseChange} />
            </DetailCard>
            <DetailCard>
                <span className="label">Factura proveedor</span>
                <DefaultInput
                    onChange={(e) => onUpdatePurchase({ ...currentPurchase, supplierInvoiceNumber: e.target.value })}
                    className="card-input"
                    placeholder="Ingresa el #"
                />
            </DetailCard>
            {appSettingsState.orderForm?.allowCustomDate && (
                <DetailCard>
                    <span className="label">Fecha personalizada</span>
                    <CustomDateInput date={customDate} onDateChange={(value) => handleUpdateDateCustom(value)} />
                </DetailCard>
            )}

            {currentPurchase.includesTaxWithholding && (
                <DetailCard>
                    <span className="label">Retenciones</span>
                    <FlexContainerCenter className="tax-withholding-container">
                        <SelectBody className={" mr-1"}>
                            <CreatableSelect
                                placeholder={"Nombre"}
                                className="react-select-basic select-max-len"
                                key={"000"}
                                classNamePrefix="select"
                                value={
                                    currentPurchase.taxWithholdingName
                                        ? getSelectOption({
                                              id: currentPurchase.taxWithholdingName,
                                              value: currentPurchase.taxWithholdingName,
                                          })
                                        : undefined
                                }
                                isDisabled={false}
                                onChange={(value) =>
                                    onUpdatePurchase({
                                        ...currentPurchase,
                                        taxWithholdingName: value?.id ?? "",
                                        taxWithholdingPercentage:
                                            COLOMBIA_TAX_WITH_HOLDING.find((x) => x.concept === value?.id)?.percentage ??
                                            currentPurchase.taxWithholdingPercentage,
                                    })
                                }
                                onCreateOption={(value) => onUpdatePurchase({ ...currentPurchase, taxWithholdingName: value })}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="supplier"
                                options={COLOMBIA_TAX_WITH_HOLDING.map((item) =>
                                    getSelectOption({
                                        id: item.concept,
                                        value: item.concept,
                                    })
                                )}
                            />
                        </SelectBody>

                        <TextBoxNumeral
                            format="number"
                            value={currentPurchase.taxWithholdingPercentage}
                            onNumberChange={(value) => getTaxWithholdingPercentage(value)}
                        />
                        <span>%</span>
                    </FlexContainerCenter>
                </DetailCard>
            )}
            <FilesViewer
                cardMaxHeight={80}
                cardMaxWidth={80}
                smallCard
                showAttachButton
                onUpdateFiles={onSelectedFiles}
                files={props.currentPurchase.files ?? []}
            />
        </Flex>
    );
};

interface PurchaseTableTotalProps {
    currentPurchase: IPurchase;
    updatePurchase: (value: IPurchase) => void;
}

export const PurchaseTableTotals = (props: PurchaseTableTotalProps) => {
    const { currentPurchase } = props;
    const intl = useIntl();

    const onExtraValueChange = (value: number, isPercentage?: boolean) => {
        let calculateValue = value;
        let calculatePer = value;
        if (currentPurchase.totalAmountItems === 0) return;
        if (isPercentage) {
            calculateValue = currentPurchase.totalAmountItems * (value / 100);
        } else {
            calculatePer = (100 * value) / currentPurchase.totalAmountItems;
        }

        if (calculatePer > 100) {
            calculatePer = 100;
            calculateValue = currentPurchase.totalAmountItems;
        }
        const realTotalValue = currentPurchase.items.reduce((a, b) => a + b.total, 0);
        props.updatePurchase({
            ...currentPurchase,
            totalDiscount: calculateValue,
            percentageDiscount: calculatePer,
            totalAmount: realTotalValue - calculateValue,
        });
    };

    return (
        <TotalTableStyle tdPaddingRight={20} cellPadding={0} cellSpacing={0}>
            <tbody>
                <tr>
                    <td>Sub total</td>
                    <td>{formatMoney(currentPurchase.subTotal)}</td>
                </tr>
                {currentPurchase.totalManualPercentageDiscountItems > 0 && (
                    <tr>
                        <td>
                            <TextField nowrap>Desc items</TextField>
                        </td>
                        <td>{formatMoney(currentPurchase.totalManualPercentageDiscountItems)}</td>
                    </tr>
                )}
                <tr>
                    <td>Descuento</td>
                    <td>
                        <InputTipContainer className={currentPurchase.totalDiscount > 0 ? "active" : ""}>
                            <TextBoxNumeral
                                value={currentPurchase.totalDiscount}
                                format="money"
                                onNumberChange={(value) => onExtraValueChange(value)}
                            />
                            <TextBoxNumeral
                                value={currentPurchase.percentageDiscount}
                                format="percentage"
                                className="percentage"
                                onNumberChange={(value) => onExtraValueChange(value, true)}
                            />
                            <PercentageList
                                title="Descuento"
                                value={currentPurchase.percentageDiscount}
                                onChangeValue={(item) => onExtraValueChange(item, true)}
                            />
                        </InputTipContainer>
                    </td>
                </tr>
                {currentPurchase.includesTaxWithholding && (
                    <tr>
                        <td>Retenciones</td>
                        <td>{formatMoney(currentPurchase.taxWithholdingValue)}</td>
                    </tr>
                )}
                {currentPurchase.totalDelivery > 0 && (
                    <tr>
                        <td>{intl.formatMessage(messages.delivery)}</td>
                        <td>{formatMoney(currentPurchase.totalDelivery)}</td>
                    </tr>
                )}
                {currentPurchase.totalTip > 0 && (
                    <tr>
                        <td>{intl.formatMessage(messages.tip)}</td>
                        <td>{formatMoney(currentPurchase.totalTip)}</td>
                    </tr>
                )}

                <tr>
                    <td>Impuestos</td>
                    <td>{formatMoney(currentPurchase.totalTaxItems)}</td>
                </tr>
                <tr>
                    <td>
                        <TextField bold fontSize={17}>
                            Total
                        </TextField>
                    </td>
                    <td>
                        <TextField bold fontSize={17}>
                            {formatMoney(currentPurchase.totalAmount)}
                        </TextField>
                    </td>
                </tr>
            </tbody>
        </TotalTableStyle>
    );
};

interface PurchaseTotalSummaryProps {
    purchase: IPurchase;
    handleOpenCancelModal: () => void;
    onImportPurchase: (value: boolean) => void;
    onUpdatePurchase: (value: IPurchase) => void;
    confirmClearOrder: () => void;
}

const PurchaseOptionsTop = (props: PurchaseTotalSummaryProps) => {
    const { appSettingsState, appSettingsActions } = useContext(AppSettingsContext);
    const intl = useIntl();

    let showDraft = false;
    if (props.purchase.status === "draft" || !props.purchase.id) {
        showDraft = true;
    }

    const purchaseSettings = appSettingsState.purchaseForm ?? {};

    useEffect(() => {
        props.onUpdatePurchase({
            ...props.purchase,
            taxIncluded: !!purchaseSettings?.purchaseTaxIncluded,
            includesTaxWithholding: !!purchaseSettings?.purchaseIncludesTaxWithholding,
            preTaxDiscount: !!purchaseSettings?.purchasePreTaxDiscount,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseSettings.purchaseTaxIncluded, purchaseSettings.purchaseIncludesTaxWithholding, purchaseSettings.purchasePreTaxDiscount]);

    return (
        <Flex spaceBetween gap10 alignCenter>
            <Flex>
                <TextField>Compra</TextField>
            </Flex>

            <Flex gap10 alignCenter>
                <SquareButton
                    title={intl.formatMessage(messages.thisOptionAllowsRestartTheOrder)}
                    rounded
                    removeBackground
                    colorText
                    onClick={() => props.confirmClearOrder()}
                >
                    <i className="fa-sharp fa-regular fa-broom"></i>
                </SquareButton>

                {showDraft && (
                    <SquareButton
                        colorText
                        removeBackground
                        title={intl.formatMessage(messages.saveYourOrderAndContinueLater)}
                        rounded
                        onClick={() => props.handleOpenCancelModal()}
                    >
                        <i className="fa-regular fa-floppy-disk"></i>
                    </SquareButton>
                )}

                <SquareButton
                    colorText
                    removeBackground
                    title={intl.formatMessage(messages.importPurchase)}
                    rounded
                    onClick={() => props.onImportPurchase(true)}
                >
                    <i className="fa-regular fa-upload"></i>
                </SquareButton>

                <Dropdown icon="fa-regular fa-ellipsis-vertical" colorText ignoreInternalClicks textLight contentHorizontal="left">
                    <Flex column gap15 padding={20}>
                        <TextField fontSize={18}>Acciones</TextField>
                        <CheckBox
                            name={"purchaseTaxIncluded"}
                            onChange={(e) => {
                                appSettingsActions.setAllSettings({
                                    ...appSettingsState,
                                    purchaseForm: {
                                        ...purchaseSettings,
                                        purchaseTaxIncluded: e.target.checked,
                                        purchasePreTaxDiscount: e.target.checked ? false : purchaseSettings.purchasePreTaxDiscount,
                                    },
                                });
                            }}
                            title="Impuesto incluido"
                            checked={purchaseSettings.purchaseTaxIncluded}
                        />
                        <CheckBox
                            name={"purchasePreTaxDiscount"}
                            onChange={(e) =>
                                appSettingsActions.setAllSettings({
                                    ...appSettingsState,
                                    purchaseForm: {
                                        ...purchaseSettings,
                                        purchasePreTaxDiscount: e.target.checked,
                                        purchaseTaxIncluded: e.target.checked === true ? false : purchaseSettings.purchaseTaxIncluded,
                                    },
                                })
                            }
                            title="Descuento antes de impuesto"
                            checked={purchaseSettings.purchasePreTaxDiscount}
                        />
                        <CheckBox
                            name={"purchaseIncludesTaxWithholding"}
                            onChange={(e) =>
                                appSettingsActions.setAllSettings({
                                    ...appSettingsState,
                                    purchaseForm: {
                                        ...purchaseSettings,
                                        purchaseIncludesTaxWithholding: e.target.checked,
                                    },
                                })
                            }
                            title="Retenciones"
                            checked={purchaseSettings.purchaseIncludesTaxWithholding}
                        />

                        <CheckBox
                            name={"orderAllowCustomDate"}
                            onChange={(e) =>
                                appSettingsActions.setAllSettings({
                                    ...appSettingsState,
                                    purchaseForm: {
                                        ...purchaseSettings,
                                        allowCustomDate: e.target.checked,
                                    },
                                })
                            }
                            title="Fecha Personalizada"
                            checked={purchaseSettings.allowCustomDate}
                        />
                        <Flex marginTop={20}>
                            <TextField small light>
                                El descuento antes de impuesto solo se puede activar si el impuesto no esta incluido
                            </TextField>
                        </Flex>
                    </Flex>
                </Dropdown>
            </Flex>
        </Flex>
    );
};
