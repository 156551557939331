const getValue = (name: string) => {
    var result = (process.env as any)[name];
    if (result) return result;
    throw new Error(`The attribute with the name [${name}] was not found`);
};

export type AppEnv = "development" | "production";

export const API_GATEWAY_ENDPOINT = getValue("REACT_APP_API_GATEWAY_ENDPOINT");
export const OCP_APIM_SUBSCRIPTION_KEY = getValue("REACT_APP_OCP_APIM_SUBSCRIPTION_KEY");
export const SIGNIN_ENDPOINT = getValue("REACT_APP_SIGN_IN_ENDPOINT");
export const SIGNOUT_ENDPOINT = getValue("REACT_APP_SIGN_OUT_ENDPOINT");
export const ECOMMERCE_ENDPOINT = getValue("REACT_APP_ECOMMERCE_ENDPOINT");
export const KDS_ENDPOINT = getValue("REACT_APP_KDS_ENDPOINT");
export const WAHIO_POS_ENDPOINT = getValue("REACT_APP_POS_ENDPOINT");
export const SOCKETIO_SERVER_ENDPOINT = getValue("REACT_APP_SOCKETIO_ENDPOINT");
export const TRACK_ENDPOINT = getValue("REACT_APP_TRACK_ENDPOINT");
export const GEO_LOCATION_ENDPOINT = getValue("REACT_APP_GEO_LOCATION_ENDPOINT");
export const APP_MODE = getValue("REACT_APP_APP_MODE");

export const WOMPI_TRANSACTION_ENDPOINT = getValue("REACT_APP_WOMPI_TRANSACTION_ENDPOINT");
export const WOMPI_ENDPOINT = getValue("REACT_APP_WOMPI_ENDPOINT");
export const WOMPI_PUBLIC_KEY = getValue("REACT_APP_WOMPI_PUBLIC_KEY");

export const GOOGLE_ANALYTICS_ID = getValue("REACT_APP_GOOGLE_ANALYTICS_ID");
export const GOOGLE_MAP_API_KEY = getValue("REACT_APP_GOOGLE_MAP_APIKEY");

export const ALGOLIA_ADMIN_API_KEY = getValue("REACT_APP_ALGOLIA_APPLICATION_ID");
export const ALGOLIA_APPLICATION_ID = getValue("REACT_APP_ALGOLIA_ADMIN_KEY");

export const STRIPE_API_KEY = getValue("REACT_APP_STRIPE_API_KEY");

export const APP_VERSION = getValue("REACT_APP_APP_VERSION");
export const APP_ENV: AppEnv = getValue("REACT_APP_ENV");

const API_GATE = {
    API_GATEWAY_ENDPOINT,
    OCP_APIM_SUBSCRIPTION_KEY,
};

export default API_GATE;
