import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getServiceMessageError } from "../../../api";
import { getAccountingSummaryByAccount } from "../../../api/accounting/accountingApi";
import { IPaginationResponse, SelectOptionObject } from "../../../api/models";
import { AccountingType, OrderAccountingSearch } from "../../../api/models/accounting";
import { Contact, ContactSearchModel } from "../../../api/models/contact";
import { contactQuerySearch, getContact } from "../../../api/orders/contactApi";
import { QUERY_CONTACT_LIST_SELECT } from "../../../api/queryKeys";
import { getAuthBody } from "../../../auth";
import PATHS from "../../../constants/paths";
import { formatMoney } from "../../../utils";
import { getDateFormatLLLL } from "../../../utils/index";
import { useAccountingParams } from "../../AccountingPayments/AccountingPanel/accountingParams";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import { SelectBody } from "../../Select/styled";
import { DefaultButton, Flex, SquareButton, TextField } from "../../_controls";
import { Table } from "../../_controls/tables/styled";
import Modal from "../../modals/Modal";
import CreateContactModal from "../ContactForm/CreateContactModal";
import TooltipRadix from "../../_controls/WahioTooltip/TooltipRadix";

export interface CustomerDetailsState {
    searchValue: string;
    queryKey: string;
}

export type CheckDebtsType = "debts" | "debtsPayable";

export interface ContactSelectProps {
    onUpdateContact: (value?: Contact) => void;
    onContactLoad?: (value?: Contact) => void; //CONTACT FETCH
    contact?: Contact;
    contactId?: string;
    useContactId?: boolean;
    title: string;
    inputId?: string;
    placeholder: string;
    hideLabel?: boolean;
    hidePadding?: boolean;
    isCourier?: boolean;
    isCompany?: boolean;
    isCustomer?: boolean;
    isSupplier?: boolean;
    isSeller?: boolean;
    preventRemoveCustomer?: boolean;
    showImage?: boolean;
    allowCreate?: boolean;
    loadCompleteContact?: boolean;
    disabled?: boolean;
    w100?: boolean;
    checkDebts?: CheckDebtsType;
}

export const getCustomerOption = (item: Contact, showDocument: boolean = true) => {
    let document = item.identificationNumber && showDocument ? `${item.identificationNumber}` : "";
    let name = `${item.firstName} ${item.lastName} ${document}`;
    let option: SelectOptionObject<Contact> = {
        value: item.id ?? "",
        label: name,
        object: item,
    };
    return option;
};

const useQuerySearchContact = (props: ContactSelectProps, state: CustomerDetailsState, stop: boolean) => {
    const alert = useAlert();
    const handleRequestCustomers = () => {
        let body: ContactSearchModel = {
            accountId: getAuthBody().accountId,
            searchValue: state.searchValue,
            pageSize: 100,
            currentPage: 1,
            sort: "default",
            sortDesc: true,
            isSupplier: props.isSupplier,
            isCompany: props.isCompany,
            isCustomer: props.isCustomer,
            isCourier: props.isCourier,
            isSeller: props.isSeller,
        };
        return contactQuerySearch(body);
    };
    return useQuery(state.queryKey, handleRequestCustomers, {
        refetchOnWindowFocus: false,
        enabled: stop,
        onError: (error) => alert.error(getServiceMessageError(error)),
    });
};

const ContactSelect = (props: ContactSelectProps) => {
    const [contact, setContact] = useState(props.contact);
    const stopSearchRef = useRef(false);
    const allowSearchRef = useRef(false);
    const navigate = useNavigate();

    const [createState, setCreateState] = useState({
        nameOrDocument: "",
        show: false,
    });

    const { getAccountingParamsText } = useAccountingParams();

    const [showModalContactDebitState, setShowModalContactDebitState] = useState(false);

    const contactMutation = useMutation((id: string) => getContact(id), {});
    const checkDebtsContactMutation = useMutation((data: OrderAccountingSearch) => getAccountingSummaryByAccount(data), {});

    const [state, setState] = useState<CustomerDetailsState>(() => {
        const keyExtra = Object.keys(_.pick(props, ["isSupplier", "isCompany", "isCustomer", "isCourier", "isSeller"]))
            .filter((x) => props[x as keyof ContactSelectProps] === true)
            .join("_");
        const queryKey = QUERY_CONTACT_LIST_SELECT + keyExtra;
        return {
            searchValue: "",
            queryKey,
        };
    });

    const queryClient = useQueryClient();
    const timeValue = useRef(setTimeout(() => {}, 10));
    const contactQuery = useQuerySearchContact(props, state, allowSearchRef.current);

    useEffect(() => {
        setContact(props.contact);
    }, [props.contact]);

    useEffect(() => {
        if (props.useContactId) {
            if (props.contactId) {
                var exists = contactQuery.data?.items.find((x) => x.id === props.contactId);
                if (exists) {
                    setContact(exists);
                    return;
                }

                contactMutation
                    .mutateAsync(props.contactId)
                    .then((value) => {
                        if (props.onContactLoad) {
                            props.onContactLoad(value);
                        }
                        setContact(value);
                    })
                    .catch((er) => {});
            } else {
                setContact(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contactId]);

    useEffect(() => {
        contactQuery.data = queryClient.getQueryData<IPaginationResponse<Contact>>(state.queryKey);
        if (!contactQuery.data) {
            allowSearchRef.current = true;
            contactQuery.refetch();
        }
        stopSearchRef.current = true;
        setTimeout(() => {
            stopSearchRef.current = false;
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stopSearchRef.current) return;
        contactQuery.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.searchValue]);

    const handleChangeSelectInput = (value: string) => {
        clearTimeout(timeValue.current);
        if (!state.searchValue && contactQuery.data && contactQuery.data.items.length >= contactQuery.data.totalItems) {
            return;
        }
        timeValue.current = setTimeout(() => {
            setState({ ...state, searchValue: value });
        }, 400);
    };

    const onSelectedContact = async (contact?: Contact) => {
        props.onUpdateContact(contact);
        if (props.useContactId && props.onContactLoad) {
            props.onContactLoad(contact);
        }
        if (props.loadCompleteContact && contact) {
            try {
                const contactSelected = await contactMutation.mutateAsync(contact.id ?? "INVALID");

                if (props.checkDebts) {
                    const modelRequest: OrderAccountingSearch = {
                        accountId: getAuthBody().accountId,
                        contactId: contact.id ?? "NA",
                        groupContacts: false,
                        type: props.checkDebts === "debts" ? "receivable" : "payable",
                    };

                    const value = await checkDebtsContactMutation.mutateAsync(modelRequest);

                    if (value.balance > 0 && contactSelected) {
                        contactSelected.contactDebtsBody = value;
                    }
                }

                props.onUpdateContact(contactSelected);
            } catch (error) {}
        }
    };

    const defaultValue = contact ? getCustomerOption(contact, false) : undefined;
    const CustomSelect = props.allowCreate ? CreatableSelect : Select;

    const contactHasDebts = contact?.contactDebtsBody;
    const contactDebts = contactHasDebts?.items[0];

    const navigateToContactDebts = () => {
        var tabName: AccountingType = props.checkDebts === "debts" ? "receivable" : "payable";
        navigate({
            pathname: PATHS.paymentsAccountingPanel,
            search: getAccountingParamsText({ tab: tabName, preview: contact?.id ?? "INVALID" }),
        });
    };

    return (
        <SelectBody menuMinWidth={200} className={`${props.w100 ? "w100" : ""}`}>
            {showModalContactDebitState && contactHasDebts && contactDebts && (
                <Modal
                    show={showModalContactDebitState}
                    setShow={setShowModalContactDebitState}
                    showHeader
                    colorStyle="danger"
                    title={props.checkDebts === "debts" ? "Contacto con saldos pendientes de pago" : "Contacto con Saldo Pendiente"}
                    useMobileView
                >
                    <Flex gap15 column padding={25} gap={20}>
                        <Table cellPadding={0} cellSpacing={0}>
                            <tbody>
                                <tr>
                                    <td className="text-light">Nombre: </td>
                                    <td className="text-right">{contactDebts.contactName}</td>
                                </tr>
                                <tr>
                                    <td className="text-light">Identificación: </td>
                                    <td className="text-right">{contactDebts.contactDocument}</td>
                                </tr>
                                {contactDebts.contactPhone && (
                                    <tr>
                                        <td className="text-light">Teléfono: </td>
                                        <td className="text-right">{contactDebts.contactPhone}</td>
                                    </tr>
                                )}
                                {contactDebts.contactEmail && (
                                    <tr>
                                        <td className="text-light">E-mail: </td>
                                        <td className="text-right">{contactDebts.contactEmail}</td>
                                    </tr>
                                )}
                                {new Date(contactDebts.lastPaymentDate ?? "") > new Date("2010-01-01") && (
                                    <tr>
                                        <td className="text-light">Ultimo pago: </td>
                                        <td className="text-right">{getDateFormatLLLL(contactDebts.lastPaymentDate)}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="text-light">Ordenes: </td>
                                    <td className="text-right">{contactHasDebts.count}</td>
                                </tr>
                                <tr>
                                    <td className="text-light">Saldo: </td>
                                    <td className="text-right">{formatMoney(contactHasDebts?.balance)}</td>
                                </tr>
                                <tr>
                                    <td className="text-light">Saldo Vencido: </td>
                                    <td className="text-right">{formatMoney(contactHasDebts?.balanceDue)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Flex>
                            <DefaultButton onClick={() => navigateToContactDebts()} bgLight borderRadius={10}>
                                <TextField>{props.checkDebts === "debts" ? "Ver cuentas por cobrar" : "Ver cuentas por pagar"}</TextField>
                            </DefaultButton>
                        </Flex>
                    </Flex>
                </Modal>
            )}
            {createState.show && (
                <CreateContactModal
                    customerNameOrDocument={createState.nameOrDocument}
                    onCreate={(customer) => {
                        props.onUpdateContact(customer);
                    }}
                    show={createState.show}
                    setShow={() => setCreateState({ ...createState, show: false, nameOrDocument: "" })}
                />
            )}
            {!props.hideLabel && <span className="label">{props.title}</span>}
            <Flex gap10 alignCenter w100={props.w100}>
                <CustomSelect
                    placeholder={props.placeholder}
                    className={`select-style ${contact ? "item-active" : ""}`}
                    key={contact?.id ?? "000"}
                    classNamePrefix="select"
                    inputId={props.inputId}
                    value={defaultValue}
                    onInputChange={(value) => handleChangeSelectInput(value)}
                    isDisabled={props.disabled}
                    onChange={(value) => onSelectedContact(value?.object)}
                    onCreateOption={(value) =>
                        !props.allowCreate ? {} : setCreateState({ ...createState, nameOrDocument: value, show: true })
                    }
                    isLoading={contactQuery.isFetching || contactMutation.isLoading}
                    isClearable={!props.preventRemoveCustomer}
                    isRtl={false}
                    isSearchable={true}
                    name="contact"
                    options={contactQuery.data?.items.map((item) => getCustomerOption(item))}
                />
                {checkDebtsContactMutation.isLoading && <LoadingDualRing small />}
                {contact && contactHasDebts && (
                    <TooltipRadix
                        title={`Saldo pendiente`}
                        trigger={
                            <SquareButton
                                title={`${contact.firstName} tiene un saldo pendiente`}
                                className="danger"
                                onClick={() => setShowModalContactDebitState(!showModalContactDebitState)}
                            >
                                <i className="fa-regular fa-triangle-exclamation"></i>
                            </SquareButton>
                        }
                    />
                )}
                {props.allowCreate && (
                    <TooltipRadix
                        title={`Crear Contacto`}
                        trigger={
                            <SquareButton
                                onClick={() => {
                                    setCreateState({ ...createState, show: true, nameOrDocument: "" });
                                }}
                            >
                                <i className="fa-regular fa-plus"></i>
                            </SquareButton>
                        }
                    />
                )}
            </Flex>
        </SelectBody>
    );
};

export default ContactSelect;
