import React from "react";
import { useIntl } from "react-intl";
import { IContactProps } from ".";
import { IContactPhone } from "../../../api/models/contact";
import { stringIsNullOrEmpty } from "../../../utils";
import InputTwoColsActions, { ILabelAction } from "../../InputTwoColsActions";
import { Flex } from "../../_controls";
import DropdownPhoneCodes from "../DropdownPhoneCodes";
import messages from "./messages";

interface IPhonesLabel extends ILabelAction, IContactProps {
    item: IContactPhone;
}
const CustomerPhoneLabels = (props: IPhonesLabel) => {
    const { item } = props;

    const onChange = (value: string, label: string) => {
        let phones = [...(props.currentCustomer.phones ?? [])];
        phones[props.index] = { ...item, phoneNumber: value, label };

        let addresses = props.currentCustomer.addresses ?? [];

        if (props.index === 0) {
            addresses.forEach((element) => {
                element.phoneNumber = value;
            });
        }

        props.setCurrentCustomer({ ...props.currentCustomer, phones, addresses: addresses });
    };

    const onChangePrefix = (value: string) => {
        let phones = [...(props.currentCustomer.phones ?? [])];
        phones[props.index] = { ...item, phonePrefix: value };
        props.setCurrentCustomer({ ...props.currentCustomer, phones });
    };

    return (
        <InputTwoColsActions
            initialComponent={<DropdownPhoneCodes callingCode={item.phonePrefix} onCallingCodeChange={(value) => onChangePrefix(value)} />}
            {...props}
            value={item.phoneNumber}
            label={item.label}
            className="mb-1"
            showRemove={props.totalItems > 1}
            showAdd={props.itemNumber === props.totalItems && !stringIsNullOrEmpty(item.phoneNumber)}
            onChange={onChange}
            minWidth={true}
        />
    );
};

const CustomerPhonesView = (props: IContactProps) => {
    const intl = useIntl();
    const { currentCustomer, setCurrentCustomer } = props;

    const onRemoveEmail = (index: number) => {
        if (currentCustomer.phones.length === 1) return;
        let phonesCopy = currentCustomer.phones.filter((x, i) => i !== index);
        setCurrentCustomer({ ...currentCustomer, phones: phonesCopy });
    };
    const onAddPhone = () => {
        let phonesCopy = currentCustomer.phones;
        phonesCopy = [...phonesCopy, { phoneNumber: "", phonePrefix: "57" }];
        setCurrentCustomer({ ...currentCustomer, phones: phonesCopy });
    };
    let elements = currentCustomer.phones.map((item, index) => (
        <CustomerPhoneLabels
            key={index}
            {...props}
            item={item}
            index={index}
            onAdd={onAddPhone}
            totalItems={currentCustomer.phones.length}
            itemNumber={index + 1}
            onRemove={onRemoveEmail}
            placeholder={intl.formatMessage(messages.phone)}
            labelPlaceholder={intl.formatMessage(messages.label)}
        />
    ));
    return (
        <Flex column border borderRadius={10}>
            {elements}
        </Flex>
    );
};

export default CustomerPhonesView;
