import React, { useState, useEffect, useRef } from "react";
import { getUniqueId } from "../../../utils";
import { SquareButton } from "../buttons";
import { TooltipContainer } from "./styled";

interface TooltipProps {
    width?: number;
    icon?: string;
    children?: React.ReactNode;
    text?: string;
    small?: boolean;
    trigger?: React.ReactNode;
    timeout: number;
    className?: string;
    fitContent?: boolean;
    classNameText?: string;
    iconPrimaryColor?:boolean;
}

export default function WahioTooltip(props: TooltipProps) {
    const [tooltipId] = useState(getUniqueId());
    const [mouseOver, setMouseOver] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const timerRef = useRef<any>();
    const contentVoid = props.text === "" || props.children?.toString() === "";

    useEffect(() => {
        updatePosition();
        clearTimeout(timerRef.current);

        if (mouseOver && !showContent && !contentVoid) {
            timerRef.current = setTimeout(() => {
                setShowContent(true);
            }, props.timeout);
        } else {
            setShowContent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mouseOver]);

    const updatePosition = () => {
        let tooltip = document.getElementById(tooltipId + "parent");
        let content = document.getElementById(tooltipId + "content");
        let arrow = document.getElementById(tooltipId + "arrow");
        if (tooltip && content && arrow) {
            let rect = tooltip.getBoundingClientRect();
            let windowWidth = window.innerWidth;

            let width = props.width ?? 200;

            if (width > windowWidth) {
                width = windowWidth - 30;
            }

            if (props.fitContent) {
                content.style.width = "fit-content";
            } else {
                content.style.width = width + "px";
            }

            let haltWidth = width / 2;
            let left = rect.x - haltWidth + rect.width;
            let difference = windowWidth - (left + width);

            if (difference < 0) {
                left = left - Math.abs(difference) - 30;
            }

            if (left <= 10) {
                left = 10;
            }

            let top = rect.top + rect.height;
            content.style.top = `${top}px`;
            content.style.left = `${left}px`;

            arrow.style.top = rect.y + tooltip.clientHeight - 5 + "px";
            arrow.style.left = rect.width / 2 + rect.x + "px";
        }
    };

    return (
        <TooltipContainer
            id={tooltipId + "parent"}
            {...props}
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            {props.trigger ? (
                props.trigger
            ) : (
                <SquareButton
                    onClick={() => setShowContent(!showContent)}
                    className={`btn-tooltip ${props.small ? "small" : ""} ${props.className ?? ""} `}
                >
                    <span className={props.icon ? props.icon : "fa-regular fa-info"}></span>
                </SquareButton>
            )}
            <div id={tooltipId + "arrow"} className={`tooltip-arrow ${showContent ? "show" : ""}`}></div>
            <div id={tooltipId + "content"} className={`tooltip-content ${showContent ? "show" : ""} ${props.classNameText ?? ""}`}>
                {props.children}
                {props.text}
            </div>
        </TooltipContainer>
    );
}
