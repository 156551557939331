import React from "react";
import { OrderStatus } from "../../api/models/orderBase";
import { OrderStatusStyle } from "./OrderStyles/styled";
import messages from "./messages";
import { useIntl } from "react-intl";
import { checkAndGetMessage } from "../../i18n/helper";

interface OrderStatusLineProps {
    status: OrderStatus;
    useIcon?: boolean;
    size?: "sm" | "md" | "lg";
    text?: string;
    width?: number;
    customStyle?: React.CSSProperties;
}

export default function OrderStatusLine(props: OrderStatusLineProps) {
    const intl = useIntl();
    const { size = "sm" } = props;

    if (props.useIcon) {
        return (
            <OrderStatusStyle
                style={props.customStyle}
                title={checkAndGetMessage(intl, messages, props.status)}
                className={`${props.status} square size-${size}`}
            >
                <i className="fa-regular fa-dollar-sign"></i>
            </OrderStatusStyle>
        );
    }

    return (
        <OrderStatusStyle style={props.customStyle} className={`${props.status} size-${size}`}>
            {checkAndGetMessage(intl, messages, props.status)} {props.text}
        </OrderStatusStyle>
    );
}
