import { defineMessages } from "react-intl";

const base = "components.customerList";

export default defineMessages({
    customers: {
        id: `${base}.customers`,
        defaultMessage: "Clientes",
    },
    newCustomer: {
        id: `${base}.newCustomer`,
        defaultMessage: "Nuevo Cliente",
    },
    contacts: {
        id: `${base}.contacts`,
        defaultMessage: "Contactos",
    },
    newContact: {
        id: `${base}.newContact`,
        defaultMessage: "Nuevo Contacto ",
    },
    searchContacts: {
        id: `${base}.searchContacts`,
        defaultMessage: "Buscar Contacto",
    },
    import: {
        id: `${base}.import`,
        defaultMessage: "Importar",
    },
    download: {
        id: `${base}.download`,
        defaultMessage: "Descargar",
    },
    importContacts: {
        id: `${base}.importContacts`,
        defaultMessage: "Importar",
    },
    exampleContacts: {
        id: `${base}.exampleContacts`,
        defaultMessage: "Ejemplo Contactos",
    },
    label: {
        id: `${base}.label`,
        defaultMessage: "Etiqueta",
    },
    name: {
        id: `${base}.name`,
        defaultMessage: "Nombre",
    },
    address: {
        id: `${base}.address`,
        defaultMessage: "Dirección",
    },
    phone: {
        id: `${base}.Phone`,
        defaultMessage: "Teléfono",
    },
    labels: {
        id: `${base}.labels`,
        defaultMessage: "Etiquetas",
    },
    created: {
        id: `${base}.created`,
        defaultMessage: "Creado",
    },
    conflictsFound: {
        id: `${base}.conflictsFoundOnImport`,
        defaultMessage: "Conflictos encontrados en la importación",
    },
    contactSuccessfullyConflicts: {
        id: `${base}.contactSuccessfullyConflicts`,
        defaultMessage: "La lista de contactos fue importada correctamente, pero hubo algunos conflictos",
    },
    contactSuccessfullySummary: {
        id: `${base}.contactSuccessfullySummary`,
        defaultMessage: "La lista de contactos fue importada correctamente, mira el resumen",
    },
    contactsDeleted: {
        id: `${base}.contactsDeleted`,
        defaultMessage: "Contactos eliminados correctamente",
    },
    youAreSure: {
        id: `${base}.youAreSure`,
        defaultMessage: "¿Estas seguro?",
    },
    actionNotReversed: {
        id: `${base}.actionNotReversed`,
        defaultMessage: "Esta acción no se puede revertir",
    },
    remove: {
        id: `${base}.remove`,
        defaultMessage: "Eliminar",
    },
    isCourier: {
        id: `${base}.isCourier`,
        defaultMessage: "Mensajero",
    },
    isSupplier: {
        id: `${base}.isSupplier`,
        defaultMessage: "Proveedor",
    },
    isCustomer: {
        id: `${base}.isCustomer`,
        defaultMessage: "Cliente",
    },
    isCompany: {
        id: `${base}.isCompany`,
        defaultMessage: "Empresa",
    },
    isSeller: {
        id: `${base}.isSeller`,
        defaultMessage: "Vendedor",
    },
});
