import React, { useEffect, useState } from "react";
import { getServiceMessageError, wahioFetch } from "../../api";
import { IPaginationResponse, PaginationResponse } from "../../api/models";
import { DeliveryStatus } from "../../api/models/deliveryOrder";
import { DeliveryStatusCountByProductSearch, Order } from "../../api/models/order";
import { IWarehouseProduct } from "../../api/models/warehouseProduct";
import { localOrderEndpoint } from "../../api/restApiEndpoint";
import { useAlert } from "../Alerts/Alert";
import LoadingDualRing from "../LoadingDualRing";
import PaginationView from "../PaginationView";
import { SquareButton } from "../_controls";
import { FormFooter } from "../_controls/forms/styled";
import { PanelCardGridItemsView } from "../_panels/styled";
import Modal, { IModalShow } from "../modals/Modal";
import OrderCardView from "./OrderList/OrderCardView";
import OrderProfile from "./OrderProfile";
import { OrderStyles } from "./OrderStyles/styled";

interface DeliveryOrdersByStatusModalProps extends DeliveryOrderStatusButtonProps, IModalShow {}

interface DeliveryOrderStatusButtonProps {
    warehouseProduct: IWarehouseProduct;
    deliveryStatusList: DeliveryStatus[];
    onOrderUpdate: (order: Order) => void;
    searchReservedStock?: boolean;
}

export default function DeliveryOrdersByStatusModal(props: DeliveryOrdersByStatusModalProps) {
    return (
        <Modal id="modal-delivery-orders" title="Ordenes" scrollInside={true} overflowAuto={false} showHeader={true} {...props}>
            <DeliveryOrdersComp {...props} />
        </Modal>
    );
}

export const DeliveryOrderStatusButton = (props: DeliveryOrderStatusButtonProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal && <DeliveryOrdersByStatusModal {...props} show={showModal} setShow={setShowModal} />}
            <SquareButton onClick={() => setShowModal(true)}>
                <i className="fa-regular fa-eye" />
            </SquareButton>
        </>
    );
};

interface OrderState {
    isFetching: boolean;
    order?: Order;
}

const DeliveryOrdersComp = (props: DeliveryOrdersByStatusModalProps) => {
    const { warehouseProduct } = props;
    const alert = useAlert();
    const [pagination, setPagination] = useState<IPaginationResponse<Order>>(new PaginationResponse<Order>());
    const [isFetching, setIsFetching] = useState(false);
    const [orderState, setOrderState] = useState<OrderState>({
        isFetching: false,
    });

    useEffect(() => {
        handleSearchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOrder = (orderId?: string) => {
        if (!orderId) return;
        setOrderState({ ...orderState, isFetching: true });
        wahioFetch.get(
            localOrderEndpoint.get.orderById(orderId),
            (success) => {
                setOrderState({ ...orderState, isFetching: false, order: success.data });
            },
            (error) => {
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const handleSearchOrders = (page: number = 1) => {
        const data: DeliveryStatusCountByProductSearch = {
            pageSize: 20,
            currentPage: page,
            productId: warehouseProduct.productId,
            productVariantId: warehouseProduct.productVariantId,
            warehouseId: warehouseProduct.warehouseId,
            shipmentStatusList: props.deliveryStatusList,
        };
        setIsFetching(true);

        const endpoint = props.searchReservedStock
            ? localOrderEndpoint.post.getDeliveryOrdersReservedStock
            : localOrderEndpoint.post.getDeliveryOrdersByProductAndStatus;

        wahioFetch.post(
            endpoint,
            data,
            (success) => {
                setPagination(success.data);
                setIsFetching(false);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setIsFetching(false);
            }
        );
    };

    return (
        <OrderStyles>
            {orderState.isFetching && <LoadingDualRing center small />}
            {orderState.order && (
                <Modal
                    sizeType="md"
                    title={`Orden: #${orderState.order.number}`}
                    showHeader={true}
                    scrollInside
                    setShow={() => setOrderState({ ...orderState, order: undefined })}
                    show={!!orderState.order}
                    id={`order-${orderState.order.id}`}
                >
                    <OrderProfile
                        showSimpleView={true}
                        order={orderState.order}
                        onUpdateDeliveryStatus={(value) => {
                            handleSearchOrders();
                            props.onOrderUpdate(value);
                        }}
                    />
                </Modal>
            )}
            <PanelCardGridItemsView className="width-auto">
                {pagination.items.map((order) => (
                    <OrderCardView
                        order={order}
                        key={order.id}
                        onSelected={(order) => fetchOrder(order.id)}
                        hideShipmentSteps
                        hideShipmentAddress
                    />
                ))}
            </PanelCardGridItemsView>
            {isFetching && <LoadingDualRing center small />}
            <FormFooter className="margin-none mr-1 mb-1">
                <PaginationView onPageChange={handleSearchOrders} pagination={pagination} />
            </FormFooter>
        </OrderStyles>
    );
};
