import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const ContainerItemsMenu = styled.div`
    display: flex;
    align-items: center;
    border-radius: 15px;
    color: ${(props) => props.theme.palette.textLight};
    position: relative;
    cursor: pointer;

    &.top-auto {
        margin-top: auto;
    }

    .notification-alert {
        background: ${(props) => props.theme.palette.primary};
        color: white;
        border-radius: 17px;
        position: absolute;
        right: 3px;
        padding: 1px 4px;
        font-size: 12px;
        font-weight: 500;
        z-index: 10;
    }

    .arrow {
        position: absolute;
        right: 5px;
        font-size: 13px;
        width: 20px;
        aspect-ratio: 1;
        background: transparent;
        transition: 0.3s background;
        border-radius: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }

    &:hover {
        background: ${(props) => props.theme.palette.menuHoverColor};
    }
`;

export const MenuItemBack = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
    padding: 8px 26px;
    align-items: center;
    font-size: 13px;
    border-radius: 31px;
    color: ${(props) => props.theme.palette.text};

    &:hover {
        font-weight: 500;
        background: ${(props) => props.theme.palette.menuHoverColor};
        animation: paddingLeft 1.2s linear infinite;
        animation-delay: 1s;
    }

    margin-top: 0px;

    @keyframes paddingLeft {
        0% {
            padding-left: 26px;
        }
        25% {
            padding-left: 33px;
        }
        50% {
            padding-left: 30px;
        }
        75% {
            padding-left: 26px;
        }
    }

    @keyframes animationMargin {
        0% {
            margin-top: 20px;
        }
        50% {
            margin-top: 0px;
        }
    }
`;

export const MenuItemBase = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 4px 0px;
    border-radius: 15px;
    color: ${(props) => props.theme.palette.text};
    position: relative;
    width: 100%;
    &:visited {
        color: ${(props) => props.theme.palette.text};
    }

    .icon-angle {
        position: absolute;
        right: 5px;
    }

    &.small-item {
        padding: 0px;
        margin: 0px;
    }

    &.center {
        justify-content: center;
    }

    &.active {
        background: ${(props) => props.theme.palette.menuHoverColor};
        color: ${(props) => props.theme.palette.text};
        font-weight: 500;

        &.with-child {
            background: transparent;
            font-size: 13px;
            font-weight: 400;

            &.child-list-hide {
                font-weight: 500;
                font-size: 14px;
            }
        }
        .panel-icon {
            color: ${(props) => props.theme.palette.menuIconColor};
        }
    }
`;

export const MenuIcon = styled.div`
    width: 35px;
    height: 35px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    border-radius: 100px;
`;

export const MenuChildListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    .child {
        margin: 3px 10px;
        padding: 0px;
        &.active {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
`;

export const ItemSeparator = styled.div`
    width: 100%;
    height: 2px;
    padding: 0px 20px;
    box-sizing: border-box;
    &:after {
        content: " ";
        background: ${(props) => props.theme.palette.backgroundLight};
        height: 2px;
        width: 100%;
        display: block;
        border-radius: 9px;
    }
`;
