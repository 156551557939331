import { IProduct, IProductVariant } from "../../../api/models/product";
import { IPurchase, IPurchaseItem, PurchaseItem } from "../../../api/models/purchase";
import { IWarehouse } from "../../../api/models/warehouse";
import { getRoundNumber } from "../../../utils";
import { calculateItemBasePrice, extractTaxInformation, getItemTotalTax, getItemTaxesFromProduct } from "../../../utils/taxes";

export const getAbsoluteAmount = (currentPurchase: IPurchase) => {
    const taxWithHolding = currentPurchase.includesTaxWithholding
        ? currentPurchase.totalAmountItems * (currentPurchase.taxWithholdingPercentage / 100)
        : 0;

    var total =
        currentPurchase.totalAmountItems + currentPurchase.totalDelivery ??
        0 + currentPurchase.totalTip ??
        0 - currentPurchase.totalDiscount ??
        0 - currentPurchase.totalItemsDiscount ??
        0;

    return total - taxWithHolding;
};

interface IInsertProductOrderList {
    items: IPurchaseItem[];
    productVariant?: IProductVariant;
    product: IProduct;
    warehouse?: IWarehouse;
    quantity?: number;
    price?: number;
    taxIncluded: boolean;
    preTaxDiscount: boolean;
}

export const insertProductInOrderList = (props: IInsertProductOrderList) => {
    const { items, product, warehouse } = props;
    let copy: IPurchaseItem[] = [];
    let exists = false;
    items.forEach((element) => {
        if (
            element.product?.id === product.id &&
            element.warehouseId === warehouse?.id &&
            (!props.productVariant?.id || element.productVariantId === props.productVariant?.id)
        ) {
            exists = true;
            element.quantity += 1;
            element = getPurchaseItemTotals(element, props.taxIncluded, props.preTaxDiscount);
            copy.push({ ...element });
        } else {
            copy.push(element);
        }
    });
    let newItems: IPurchaseItem[];
    if (exists) {
        newItems = copy;
    } else {
        let newItem = new PurchaseItem(product, 1, warehouse);
        newItem.position = items.length + 1;
        newItem.productVariant = props.productVariant;
        newItem.productVariantId = newItem.productVariant?.id;
        newItem.unitPrice = props.price ? props.price : product.basePrice;
        newItem.quantity = props.quantity ? props.quantity : 1;

        let taxes = newItem.taxes != null && newItem.taxes.length > 0 ? newItem.taxes : getItemTaxesFromProduct(0, product);
        let [taxPercentage] = extractTaxInformation(taxes);
        let basePriceResult = calculateItemBasePrice(newItem, taxes, props.taxIncluded, props.preTaxDiscount);
        newItem.basePrice = basePriceResult.basePrice;
        newItem.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;

        newItem.taxes = taxes;
        let taxAmount = getItemTotalTax(taxes, newItem, props.taxIncluded, props.preTaxDiscount);

        newItem.totalTax = taxAmount;
        newItem.totalTaxPercentage = taxPercentage;
        newItems = [...items, getPurchaseItemTotals(newItem, props.taxIncluded, props.preTaxDiscount)];
    }
    return newItems;
};

export const getPurchaseItemTotals = (item: IPurchaseItem, taxIncluded: boolean, preTaxDiscount: boolean) => {
    var discountBase = 0;
    if (taxIncluded) {
        discountBase = preTaxDiscount ? item.basePrice : item.unitPrice;
        let totalAmountManualDiscount = discountBase * (item.manualPercentageDiscount / 100);
        item.manualPercentageDiscountValue = totalAmountManualDiscount;
        item.subTotal = item.basePrice * item.quantity;
        item.total = getRoundNumber((item.unitPrice - totalAmountManualDiscount) * item.quantity);
    } else {
        discountBase = preTaxDiscount ? item.unitPrice : item.basePrice;
        let totalAmountManualDiscount = discountBase * (item.manualPercentageDiscount / 100);
        item.manualPercentageDiscountValue = totalAmountManualDiscount;
        item.subTotal = item.unitPrice * item.quantity;

        if (preTaxDiscount) {
            item.total = getRoundNumber((item.basePricePreTaxDiscount + item.totalTax) * item.quantity);
        } else {
            item.total = getRoundNumber((item.basePrice - totalAmountManualDiscount) * item.quantity);
        }
    }
    return item;
};

export const calculateTotalItems = (items: IPurchaseItem[]) => {
    let sum = 0;
    items.forEach((element) => {
        sum += element.total;
    });
    return sum;
};

export const getTotalItemsCounter = (items?: IPurchaseItem[]) => {
    if (!items) return 0;
    let value = 0;
    items.forEach((element) => {
        value += element.quantity;
    });
    return value;
};

export const onPurchaseItemPriceChange = (purchaseItem: IPurchaseItem, newValue: number, onChange: (value: IPurchaseItem) => void) => {
    purchaseItem.unitPrice = newValue;
    let basePriceResult = calculateItemBasePrice(purchaseItem, purchaseItem.taxes, true, false);
    purchaseItem.basePrice = basePriceResult.basePrice;
    purchaseItem.basePricePreTaxDiscount = basePriceResult.basePricePreTaxDiscount;
    let taxAmount = getItemTotalTax(purchaseItem.taxes, purchaseItem, true, false);
    let subTotal = purchaseItem.basePrice;
    let total = subTotal + taxAmount;
    onChange({ ...purchaseItem, totalTax: taxAmount, basePrice: basePriceResult.basePrice, unitPrice: newValue, subTotal, total });
};
