import React, { createContext, useReducer, useEffect } from "react";
import { saveData, LocalStoreKeys } from "..";
import { ICurrentSaleActions, useActions } from "../actions/currentOrder";
import { applyMiddleware } from "../middlewares/currentOrder";

import { currentOrderReducer, initState } from "../reducers/currentOrder";
import { ICurrentOrderState } from "../../api/models/order";

export interface ICurrentOrderContextProps {
    currentOrderState: ICurrentOrderState;
    currentOrderActions: ICurrentSaleActions;
}

export const CurrentOrderContext = createContext({} as ICurrentOrderContextProps);

const CurrentOrderContextProvider = (props: any) => {
    const [currentOrderState, dispatch] = useReducer(currentOrderReducer, initState);

    useEffect(() => {
        saveData(LocalStoreKeys.currentSale, currentOrderState);
    }, [currentOrderState]);

    const currentOrderActions = useActions(currentOrderState, applyMiddleware(dispatch));

    return <CurrentOrderContext.Provider value={{ currentOrderState, currentOrderActions }}>{props.children}</CurrentOrderContext.Provider>;
};

export default CurrentOrderContextProvider;
