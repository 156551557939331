import React, { useEffect, useState } from 'react';
import { WahioCloudFolder } from '../../api/models/wahioCloud';
import { CloudImages } from '../../assets';
import { ignoreElementClick } from '../../utils';
import Dropdown from '../Dropdown';
import { ID_IGNORE_CLICK } from '../Products/ProductSearchList/helper';
import { Flex, FlexImageStyle, TextField } from '../_controls';
import ConfirmationModal from '../modals/ConfirmationModal';
import { MoveFoldersModal } from './MoveFoldersModal';
import { WahioCloudCreateFolderModal } from './WahioCloudCreateFolderModal';
import { ActiveFolderContent, FolderCard, FolderInternalContent, GridFoldersContainer } from './styled';

interface WahioCloudFolderProps {
    folders: WahioCloudFolder[];
    folderOpen?: WahioCloudFolder;
    listOfSelectedFolders: WahioCloudFolder[];
    updateOpenedFolder: (value: WahioCloudFolder, id?: string) => void;
    changeListSelectedFolder: (item?: WahioCloudFolder) => void;
    deleteFolder: (item: WahioCloudFolder) => void
}

export const WahioCloudFolderContent = (props: WahioCloudFolderProps) => {
    const { folders, updateOpenedFolder, changeListSelectedFolder, listOfSelectedFolders } = props;
    const [listOpenFolders, setListOpenFolders] = useState<WahioCloudFolder[]>([]);
    const [showFolderModal, setShowFolderModal] = useState(false);
    const [folderIsEditable, setFolderIsEditable] = useState<WahioCloudFolder | undefined>(undefined);
    const [showFolders, setShowFolders] = useState(folders);

    useEffect(() => {
        if (!props.folderOpen) {
            setShowFolders(folders.filter(x => x.folderParentId === undefined));
        } else {
            setShowFolders(folders.filter(x => x.folderParentId === props.folderOpen?.id));
        }
    }, [props.folderOpen, folders]);

    const onOpenFolder = (folder: WahioCloudFolder) => {
        setListOpenFolders(prevListOpenFolders => [...prevListOpenFolders, folder]);
        changeListSelectedFolder();
        updateOpenedFolder(folder);
    };

    const onBackFolderParent = (listChilds: WahioCloudFolder[]) => {
        const folderOpen = listChilds[listChilds.length - 1];
        const folderParentId = folderOpen?.id ?? "";
        updateOpenedFolder(folderOpen, folderParentId);
        setListOpenFolders([...listChilds]);
        changeListSelectedFolder();
    };

    return (
        <>
            {showFolderModal && (
                <WahioCloudCreateFolderModal onCreate={() => {}} showModal={showFolderModal} setShowModal={setShowFolderModal} folderUpdate={folderIsEditable} />
            )}

            <FolderInternalContent>
                {props.folderOpen && (
                    <Flex gap10 paddingBottom={5}>
                        <Flex
                            gap10
                            alignCenter
                            justifyCenter
                            className="cursor-pointer"
                            onClick={() => {
                                const newListChilds = listOpenFolders?.slice(0, listOpenFolders?.length - 1);
                                onBackFolderParent(newListChilds);
                            }}
                        >
                            <TextField light className="fa-regular fa-arrow-left" />
                            <TextField light>Volver</TextField>
                        </Flex>
                        {listOpenFolders?.length > 0 && (
                            listOpenFolders?.map((item, index) => (
                                <ActiveFolderContent
                                    key={item.id}
                                    onClick={() => {
                                        if (index === listOpenFolders?.length - 1) return;
                                        const newListChilds = listOpenFolders?.slice(0, index + 1);
                                        onBackFolderParent(newListChilds);
                                    }}
                                >
                                    <TextField light >/</TextField>
                                    <TextField light key={index}>{item?.name}</TextField>
                                </ActiveFolderContent>
                            ))
                        )}
                    </Flex>
                )}
                <Flex>
                    {showFolders.length > 0 && (
                        <GridFoldersContainer>
                            {showFolders.map((item) => (
                                <FolderCardContent
                                    key={item.id}
                                    item={item}
                                    setFolderIsEditable={setFolderIsEditable}
                                    setShowFolderModal={setShowFolderModal}
                                    onOpenFolder={onOpenFolder}
                                    itemsFolder={folders}
                                    isSelected={listOfSelectedFolders.findIndex((x) => x.id === item.id) > -1}
                                    onSelected={() => changeListSelectedFolder(item)}
                                    deleteFolder={props.deleteFolder}
                                />
                            ))}
                        </GridFoldersContainer>
                    )}
                </Flex>
            </FolderInternalContent>
        </>
    );
};


interface FolderCardContentProps {
    item: WahioCloudFolder;
    setFolderIsEditable: (value: WahioCloudFolder) => void;
    setShowFolderModal: (value: boolean) => void;
    onOpenFolder: (folder: WahioCloudFolder) => void;
    itemsFolder: WahioCloudFolder[];
    isSelected?: boolean;
    onSelected: () => void;
    deleteFolder: (item: WahioCloudFolder) => void;
}

const FolderCardContent = (props: FolderCardContentProps) => {
    const { item, setFolderIsEditable, setShowFolderModal, onOpenFolder } = props
    const [showConfirmationDeleteFolder, setShowConfirmationDeleteFolder] = useState(false)
    const [showMoveContentModal, setShowMoveContentModal] = useState(false)

    return (
        <>
            {showMoveContentModal && (
                <MoveFoldersModal
                    show={showMoveContentModal}
                    setShow={setShowMoveContentModal}
                    folders={props.itemsFolder}
                    itemFolder={props.item}
                    onSelected={() => props.onSelected()}
                />
            )}
            {
                showConfirmationDeleteFolder &&
                <ConfirmationModal
                    title="¿Estás seguro de que quieres eliminar esta carpeta?"
                    onCancel={() => setShowConfirmationDeleteFolder(false)}
                    onOkay={() => props.deleteFolder(item)}
                    description="Esta acción no se puede revertir"
                    show={showConfirmationDeleteFolder}
                    setShow={setShowConfirmationDeleteFolder}
                />
            }
            <FolderCard
                onDoubleClick={(e) => {
                    const ignoreClick = ignoreElementClick(e.target, ID_IGNORE_CLICK);
                    if (ignoreClick) return;
                    onOpenFolder(item)
                }}
            >
                <FlexImageStyle width={30} >
                    <img className={`${props.isSelected ? "folder-image" : ""} image`} src={item.image !== "" ? item.image : CloudImages.openFolder} alt={item.name} />
                </FlexImageStyle>
                <Flex className={`${props.isSelected ? "active" : ""} btn-checkbox`}>
                    <Flex
                        id="ID_IGNORE_CLICK"
                        className="checkbox"
                        onClick={() => props.onSelected()}
                    >
                        <TextField className={props.isSelected ? "fa-regular fa-check" : ""} />
                    </Flex>
                </Flex>
                <TextField className='name' small light>{item.name}</TextField>
                <Dropdown
                    small
                    withOutBackgroundButton
                    icon="fa-regular fa-ellipsis-vertical"
                    contentHorizontal="left"
                    functionClick={() => { !props.isSelected && props.onSelected() }}
                >
                    <Flex className="dropContent">
                        <li onClick={() => {
                            setFolderIsEditable(item)
                            setShowFolderModal(true)
                        }}>
                            <TextField bold className="fa-regular fa-pen icon" />
                            <TextField small light> Cambiar nombre</TextField>
                        </li>
                        <li onClick={() => setShowConfirmationDeleteFolder(true)}>
                            <TextField bold className="fa-regular fa-trash icon" />
                            <TextField small light>Eliminar</TextField>
                        </li>
                        <li onClick={() => setShowMoveContentModal(true)}>
                            <TextField bold className="fa-regular fa-arrow-right-to-line icon" />
                            <TextField small light>Mover</TextField>
                        </li>
                    </Flex>
                </Dropdown>
            </FolderCard>
        </>
    )
}
