import React, { useEffect, useState } from "react";
import { useAlert } from "../../Alerts/Alert";
import { DefaultButton, DefaultInput, Flex, SquareButton } from "../../_controls";
import { ProductSerialsContainer } from "./styled";

interface ProductSerialsProps {
    serials: string[];
    onChange: (serials: string[]) => void;
    serialLimit?: number;
    useLimit?: boolean;
}

export const ProductSerials = (props: ProductSerialsProps) => {
    const timeCheckSerialRef = React.useRef<any>();
    const [repeatSerials, setRepeatSerials] = useState<string[]>([]);
    const alert = useAlert();

    const canAddMoreSerials = React.useMemo(() => {
        if (props.useLimit) {
            let difference = (props.serialLimit ?? 0) - props.serials.length;
            return difference > 0;
        }
        return true;
    }, [props.useLimit, props.serials, props.serialLimit]);

    useEffect(() => {
        handleCheckSerials(props.serials);
    }, [props.serials]);

    const handleCheckSerials = (serials: string[]) => {
        if (timeCheckSerialRef.current) {
            clearTimeout(timeCheckSerialRef.current);
        }
        timeCheckSerialRef.current = setTimeout(() => {
            let repeatedSerials = serials.filter((x, i) => serials.indexOf(x) !== i);
            if (repeatedSerials.length) {
                setRepeatSerials(repeatedSerials);
            } else {
                setRepeatSerials([]);
            }
        }, 500);
    };

    const onLocalSerialChange = (value: string, index: number) => {
        let serials = [...props.serials] ?? [];
        serials[index] = value;
        props.onChange(serials);
        handleCheckSerials(serials);
    };

    const onRemoveSerial = (index: number) => {
        let serials = [...props.serials] ?? [];
        serials.splice(index, 1);
        props.onChange(serials);
    };

    const addNewSerial = () => {
        if (props.serials.some((x) => x.trim() === "")) {
            return;
        }

        let serials = props.serials ?? [];
        let newSerials = [...serials, ""];

        let newQuantity = newSerials.length;

        if (props.useLimit) {
            let difference = props.serialLimit ?? 0 - newQuantity;
            if (difference < 0) {
                alert.info("No es posible agregar mas seriales");
                return;
            }
        }
        props.onChange(newSerials);
    };

    const onSerialInputKeyDown = (e: any) => {
        if (e.key === "Enter") {
            addNewSerial();
        }
    };

    return (
        <Flex column>
            <ProductSerialsContainer className={`mt-1 m-b2`}>
                {props.serials.map((serial, index) => (
                    <div key={index} className={`serial-line ${repeatSerials.some((x) => x === serial) ? "repeated" : ""}`}>
                        <span className={"index"}>{index + 1}</span>
                        <DefaultInput
                            placeholder="Ingresa el Serial del producto"
                            className={`input-serial`}
                            value={serial}
                            onKeyDown={onSerialInputKeyDown}
                            autoFocus
                            onChange={(e) => onLocalSerialChange(e.target.value, index)}
                        />
                        <SquareButton small className="btn-remove" onClick={() => onRemoveSerial(index)}>
                            <span className="wahioicon-times"></span>
                        </SquareButton>
                    </div>
                ))}
            </ProductSerialsContainer>
            {canAddMoreSerials && (
                <Flex alignCenter justifyEnd>
                    <DefaultButton bgLight borderRadius={10} onClick={() => addNewSerial()}>
                        <span className="wahioicon-plus"></span> Agregar seriales
                    </DefaultButton>
                </Flex>
            )}
        </Flex>
    );
};

export default ProductSerials;
