import styled from "styled-components";

export const CashEntryCardListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CashEntryCardContainer = styled.div`
    border-radius: 15px;
    border: 1px solid transparent;

    &:hover {
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    &.active {
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const EntryTypesStyle = styled.div`
    background: transparent;
    &.sale {
        color: ${(props) => props.theme.palette.primary};
    }
    &.purchase {
        color: ${(props) => props.theme.palette.primary};
    }
    &.expense {
        color: #ff5722;
    }
    &.manualAdjustment {
        color: #607d8b;
    }
    &.transfer {
        color: #3f51b5;
    }
`;

export const DateTitleLine = styled.div`
    margin: 5px 15px;
    font-size: 13px;
    color: ${(props) => props.theme.palette.textLight};
    position: relative;
    box-sizing: border-box;
    span {
        background: ${(props) => props.theme.palette.background};
        padding-right: 15px;
        position: relative;
        z-index: 1;
    }
    ::after {
        content: " ";
        border-bottom: 1px dashed ${(props) => props.theme.palette.backgroundDark};
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
    }
`;

export const EntryTypeCard = styled(EntryTypesStyle)`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 10px;
    text-transform: uppercase;
`;

export const EntryMethod = styled.div`
    display: flex;
    padding-right: 20px;
    border-radius: 10px;
    gap: 10px;
    align-items: center;
    &.cash {
        .method-icon {
            color: #00c853;
        }
    }
    &.card,
    &.creditCard,
    &.debitCard {
        .method-icon {
            color: #2962ff;
        }
    }
    &.transfer {
        .method-icon {
            color: #f57f17;
        }
    }
    &.deposit {
        .method-icon {
            color: #7c4dff;
        }
    }
    &.online {
        .method-icon {
            color: #ffab00;
        }
    }
`;

export const EntryIcon = styled.div`
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: ${(props) => props.theme.palette.backgroundDark};
`;

export const CashEntryCard = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;

    .entry-content {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .ml-auto {
        margin-left: auto;
    }
`;

export const CashEntryContent = styled.div``;

export const FilterContainer = styled.div`
    display: flex;
    grid-gap: 5px;
    flex-direction: column;
    padding: 10px;
    span {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
    }
    button {
        justify-content: flex-start;
        border: 0;
        border-radius: 50px;
    }
`;

export const PaymentFormatPrintContentSmall = styled.div`
    color: black;
    background: white;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    .company-card {
        min-width: 300px;
    }

    .image-container {
        height: 45px;
        width: auto;
        img {
            height: 100%;
            width: auto;
            object-fit: contain;
            border-radius: 10px;
        }
    }

    .table-items {
        .table-header {
            td {
                font-weight: 500;
            }
        }
        td:last-child {
            text-align: right;
        }
    }
`;
