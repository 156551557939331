import { IPaginationRequest } from ".";
import { Contact } from "./contact";
import { EventCalendar } from "./eventCalendar";
import { Order } from "./order";
import { OrderSourceType } from "./orderBase";
import { IOrganization } from "./organizationTypes";
import { TransactionAccountConcept } from "./transactionAccount";

export interface IDeliverySummary {
    total: number;
    delivered: number;
    pending: number;
}

export type DeliveryStatus = "pending" | "processing" | "picking" | "packing" | "readyToShip" | "shipped" | "delivered";

export interface ShipmentStatusItem {
    title: string;
    icon: string;
    disabled: boolean;
    color: string;
}

export const ALL_SHIPMENT_STATUS_LIST: DeliveryStatus[] = [
    "pending",
    "processing",
    "picking",
    "packing",
    "readyToShip",
    "shipped",
    "delivered",
];

export const SIMPLE_SHIPMENT_STATUS_LIST: DeliveryStatus[] = ["pending", "processing", "readyToShip", "shipped", "delivered"];

export const NON_ORDER_ITEM_UPDATE_STATUS_LIST: DeliveryStatus[] = ["packing", "readyToShip", "shipped", "delivered"];

export const allowOrderReturnStatusList: DeliveryStatus[] = ["delivered", "packing", "shipped", "readyToShip"];

export const getShipmentStatusList = (allowLogisticService?: boolean) => {
    return allowLogisticService ? ALL_SHIPMENT_STATUS_LIST : SIMPLE_SHIPMENT_STATUS_LIST;
};

export const shipmentStatusPickingCompleted: DeliveryStatus[] = ["readyToShip", "packing", "shipped", "delivered"];

export interface IDeliveryHistory {
    id: string;
    deliveryOrderId: string;
    comments: string;
    status: DeliveryStatus;
    dateCreated: Date;
}
export interface IDeliveryOrder {
    id?: string;
    auxid?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    accountId: string;
    courierId?: string;
    courier?: Contact;
    status: DeliveryStatus;
    orderId?: string;
    order?: Order;
    amount: number;

    //origin
    originCountry?: string;
    originState?: string;
    originCity?: string;
    originAddress?: string;

    countryCode?: string;
    stateCode?: string;
    cashOnDeliveryOrder?: boolean;

    country: string;
    state: string;
    city: string;

    email?: string;

    contactName: string;
    phoneNumber: string;
    additionalInformation: string;
    neighborhood: string;
    description?: string;

    address: string;
    locationLat: number;
    locationLng: number;
    locationInfo?: LocationInfo;
    shippingLabel?: IShippingLabel;
    shippingTrackingNumber?: string;
    shippingTrackingLink?: string;

    history?: IDeliveryHistory[];
    costItems?: CostItem[];
    costItemsTotal: number;
    excludesCostItemsInOrder: boolean;
    includesTransactionAccount?: boolean; //IU ONLY
    accountingConcept?: TransactionAccountConcept; //ONLY IU
    accountingAmount?: number; //ONLY IU

    event?: EventCalendar; //ONLY IU

    details?: { [key: string]: string };

    isGift?: boolean;
    destinationContactName?: string;
    destinationPhoneNumber?: string;
    destinationPhonePrefix?: string;
    destinationEmail?: string;
}

export interface LocationInfo {
    inputAddress: string;
    formattedAddress: string;
    lat: number;
    lng: number;
    type: string;
}

export interface DeliveryOrderUpdateModel {
    locationLat?: number;
    locationLng?: number;
    locationInfo?: LocationInfo;
    courierId?: string;
}
export class DeliveryOrder implements IDeliveryOrder {
    id?: string | undefined;
    dateCreated?: Date | undefined;
    dateUpdated?: Date | undefined;
    accountId: string;
    courierId?: string;
    courier?: Contact;

    status: DeliveryStatus = "pending";
    orderId?: string | undefined;
    order?: Order;
    amount: number = 0;

    originCountry?: string = "";
    originState?: string = "";
    originCity?: string = "";
    originAddress?: string = "";

    country: string = "";
    state: string = "";
    city: string = "";
    contactName: string = "";
    phoneNumber: string = "";
    additionalInformation: string = "";
    neighborhood: string = "";

    address: string;
    locationLat: number = 0;
    locationLng: number = 0;

    history?: IDeliveryHistory[];
    costItems?: CostItem[];
    costItemsTotal: number = 0;
    excludesCostItemsInOrder: boolean = false;
    locationInfo?: LocationInfo;

    constructor(accountId: string, address: string, organization?: IOrganization) {
        this.accountId = accountId;
        this.address = address;
        this.contactName = "XXXXX";
        this.originState = organization?.state ?? "";
        this.originCity = organization?.city ?? "";
        this.originAddress = organization?.address ?? "";
    }
}

export interface IShippingLabel {
    comment?: string;
    customerDocumentNumber?: boolean;
    organizationPhone: boolean;
    organizationAddress: boolean;
    observation: boolean;
    orderNumber: boolean;
    invoiceNumber: boolean;
    orderValue: boolean;
    shippingValue: boolean;
    paymentStatus: boolean;
    paymentMethod: boolean;
    trackingNumber: boolean;
    trackingQr: boolean;
}

export interface ShipmentSearch {
    orderId: string;
    deliveryOrderId: string;
    orderNumber: number;
    customerId: string;
    courierId?: string;
    courierName?: string;
    status: DeliveryStatus;
    statusUpdatedAt: string;
    customerName: string;
    city: string;
    state: string;
    address: string;
    locationInfo: LocationInfo;
}

export interface DeliveryOrderGroupItem {
    count: number;
    status: DeliveryStatus;
}

export interface IShippingLabelOrganization extends IShippingLabel {
    title1: string;
    title2: string;
    title3: string;
}

export class ShippingLabelOrganization implements IShippingLabelOrganization {
    title1: string = "";
    title2: string = "";
    title3: string = "";
    organizationPhone: boolean = false;
    organizationAddress: boolean = false;
    observation: boolean = false;
    orderNumber: boolean = false;
    invoiceNumber: boolean = false;
    orderValue: boolean = false;
    shippingValue: boolean = false;
    paymentStatus: boolean = false;
    paymentMethod: boolean = false;
    trackingNumber: boolean = false;
    trackingQr: boolean = false;
}

export class ShippingLabel implements IShippingLabel {
    organizationPhone: boolean = false;
    organizationAddress: boolean = false;
    observation: boolean = false;
    orderNumber: boolean = false;
    invoiceNumber: boolean = false;
    orderValue: boolean = false;
    shippingValue: boolean = false;
    paymentStatus: boolean = false;
    paymentMethod: boolean = false;
    trackingNumber: boolean = false;
    trackingQr: boolean = false;
}

export interface IDeliveryOrderSearchModel extends IPaginationRequest {
    accountId: string;
    searchValue?: string;
    status?: DeliveryStatus;
    organizationId: string;
    source?: OrderSourceType;
    accountUserId?: string;
    userSellerId?: string;
    dateRangeActive?: boolean;
    dateStart?: Date;
    dateEnd?: Date;
    customerId?: string;
    productId?: string;
    showCanceledOrders?: boolean;
    orderChannelId?: string;
    minutesOffset: number;
}

export interface CostItem {
    blockName?: boolean; //UI ONLY
    name: string;
    value: number;
}
