import styled from "styled-components";

export const OrderCommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input-observation {
        width: 100%;
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 15px;
    }
`;

export const OrderCommentInputBody = styled.div`
    width: 100%;
    input {
        background: ${(props) => props.theme.palette.backgroundLight};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        width: 100%;
        border-radius: 34px;
        padding: 8px 15px;
    }
    .add-comment {
        background: ${(props) => props.theme.palette.background};
    }
`;

export const OrderCommentRowContainer = styled.div`
    &.hide-controls {
        .rdw-editor-toolbar {
            display: none;
        }
        .rdw-editor-main {
            margin-top: 0px;
        }
    }
    .btn-squares {
        button {
            font-size: 0.8rem;
            background: ${(props) => props.theme.palette.background};
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            color: ${(props) => props.theme.palette.textLight};
        }
    }
    .header {
        margin-bottom: 5px;
        color: ${(props) => props.theme.palette.textLight};
        font-size: 13px;
    }
    .title {
        font-weight: 500;
        color: ${(props) => props.theme.palette.text};
        font-size: 14px;
    }
    .content {
        margin: 0px;

        p {
            margin: 0;
        }
    }

    .message-card {
    }
    .body {
        display: flex;
        gap: 10px;
    }
    .actions {
        display: flex;
        margin-top: 15px;
    }
    .image {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 15px;
        border-radius: 60px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;
