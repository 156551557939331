import React, { useContext, useEffect, useState } from "react";
import { WahioCloudItem } from "../../api/models/wahioCloud";
import Modal, { IModalShow } from "../modals/Modal";
import { ImageColorLabel, MainContainerInfoCloud } from "./styled";
import { bytesToSize, capitalize, getDateFormat, getModelFullName } from "../../utils/index";
import { AccountUserContext } from "../../store/contexts/AccountUserContext";
import TextGroup from "../_controls/TextFields";
import { Flex, FlexImageStyle, GridTemplate } from "../_controls";
import GroupButtonRow from "../_controls/buttons/GroupButtonRow";

interface InformationModalProps extends IModalShow {
    title?: string;
    description?: string;
    item: WahioCloudItem;
}

export const WahioCloudInfoModal = (props: InformationModalProps) => {
    const { accountUserState, accountUserActions } = useContext(AccountUserContext);

    const [showAsHashtag, setShowAsHashtag] = useState(false);
    useEffect(() => {
        if (accountUserState.items.length === 0) {
            accountUserActions.requestAccountUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { item } = props;

    const getTags = () => {
        if (item.fileTags && item.fileTags.length > 0) {
            if (showAsHashtag) {
                return item.fileTags.map((x) => "#" + x.replaceAll("-", "").replaceAll(" ", "")).join(" ");
            } else {
                return item.fileTags.map((x) => capitalize(x).replaceAll("-", " ")).join(", ");
            }
        } else {
            return "---";
        }
    };

    return (
        <Modal {...props} id={`WAHIO_CLOUD_INFO`} showHeader={true} title={props.title ?? ""} ignoreOutsideClick>
            <MainContainerInfoCloud>
                {item.type === "image" && (
                    <Flex>
                        <FlexImageStyle className="bg-light" maxHeight={400} borderRadius={15} w100 aspectRatioAuto>
                            <img src={item.url} alt="" />
                        </FlexImageStyle>
                    </Flex>
                )}
                <TextGroup text1={"Name"} text2={item.name} />

                <GridTemplate>
                    <TextGroup text1={"Fecha"} text2={item.dateCreated ? getDateFormat(item.dateCreated, "DD/MM/YYYY, hh:mm a") : "---"} />
                    <TextGroup text1={"Tipo"} text2={`${item.type}.${item.fileExtension}`} />
                    <TextGroup text1={"Tamaño"} text2={`${bytesToSize(item.size)}`} />
                    <TextGroup
                        text1={"Creado Por"}
                        text2={`${getModelFullName(accountUserState.items.find((x) => x.id === item.accountUserId))}`}
                    />
                </GridTemplate>

                {item.imageAnalysis && (
                    <>
                        <hr />
                        <h2 className="small-title">Wahio - Inteligencia Artificial</h2>
                        <Flex column gap10>
                            <GroupButtonRow
                                smallView
                                activeOption={showAsHashtag}
                                onClickOption={(option) => setShowAsHashtag(option.value as boolean)}
                                options={[
                                    { title: "Etiquetas", value: false },
                                    { title: "Hashtags", value: true },
                                ]}
                            />
                            <span>{getTags()}</span>
                        </Flex>

                        {item.imageAnalysis.brands && (
                            <GridTemplate size={120}>
                                {item.imageAnalysis.brands.map((cap, index) => (
                                    <TextGroup text1={`Marca`} key={index} text2={cap.name} />
                                ))}
                            </GridTemplate>
                        )}
                        {item.imageAnalysis.description?.captions && (
                            <Flex column>
                                {item.imageAnalysis.description?.captions.map((cap, index) => (
                                    <TextGroup key={index} text1={`Descripción Automática`} text2={cap.text} />
                                ))}
                            </Flex>
                        )}
                        {item.imageAnalysis.color && (
                            <Flex column gap5>
                                <span className="text-light">Colores</span>
                                <Flex>
                                    <Flex w100 column gap5>
                                        <span className="text-light text-small">{item.imageAnalysis.color.accentColor}</span>
                                        <ImageColorLabel
                                            style={{
                                                background: "#" + item.imageAnalysis.color.accentColor,
                                            }}
                                        ></ImageColorLabel>
                                    </Flex>
                                    {item.imageAnalysis.color.dominantColors.map((color) => (
                                        <Flex w100 column gap5>
                                            <span className="text-light text-small">{color}</span>
                                            <ImageColorLabel
                                                style={{
                                                    background: color,
                                                }}
                                            ></ImageColorLabel>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>
                        )}
                        {item.imageAnalysis.adult && (
                            <TextGroup text1={`Contenido para adultos`} text2={item.imageAnalysis.adult.isAdultContent ? "SI" : "NO"} />
                        )}
                    </>
                )}
                <div className="footer-container mt-2 text-small">
                    <span>
                        Los usuarios con permisos habilitados en la sección del Cloud, podrán ver, modificar y descargar este archivo
                    </span>
                </div>
            </MainContainerInfoCloud>
        </Modal>
    );
};
