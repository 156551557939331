import React, { useEffect, useState } from "react";
import { Order, OrderItem } from "../../../../api/models/order";
import { convertTaxOrderItem } from "../../../../api/models/orderBase";
import { ITax } from "../../../../api/models/tax";
import { useTaxContext } from "../../../../store/contexts/TaxContext";
import { formatMoney } from "../../../../utils";
import { calculateItemBasePrice, extractTaxInformation, getItemTotalTax } from "../../../../utils/taxes";
import TextBoxNumeral from "../../../TextBoxNumeral";
import { DefaultButton, Flex, GridTemplate, TextField } from "../../../_controls";
import { PrimaryButton, SquareButton } from "../../../_controls/buttons/index";
import { Table } from "../../../_controls/tables/styled";
import Modal from "../../../modals/Modal";
import { calculateTotal, getOrderItemTotals } from "../helpers";
import { CardPercentage } from "../styled";

interface OrderItemsConfigProps {
    currentOrder: Order;
    onUpdateAllItem: (item: OrderItem[]) => void;
    showTitle?: boolean;
    onClose?: () => void;
}

const OrderItemsConfig = (props: OrderItemsConfigProps) => {
    const [discountState, setDiscountState] = useState(0);
    const [realOrderValue, setRealOrderValue] = useState(0);
    const items = props.currentOrder.items;

    const { taxState, setLocalTaxes, localTaxes } = useTaxContext();
    const [tempTaxList, setTempTaxList] = useState<ITax[]>(localTaxes);

    useEffect(() => {
        items.map((item) => {
            let discountPercentage = item.manualPercentageDiscount;
            if (discountPercentage) {
                setDiscountState(discountPercentage);
            }
            return null;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (items) {
            let total = calculateTotal(items);
            setRealOrderValue(total);
        }
    }, [items]);

    const totalTaxes = React.useMemo(() => {
        let totalValueItems = realOrderValue;
        let sumPercentageTaxes = tempTaxList.reduce((a, b) => a + b.value, 0);
        let totalTaxes = (totalValueItems / (1 + sumPercentageTaxes / 100) - totalValueItems) * -1;
        return totalTaxes;
    }, [realOrderValue, tempTaxList]);

    const addTaxAllProducts = (tax: ITax) => {
        let exist = tempTaxList.find((item) => item.id === tax.id);
        if (exist) {
            let newList = tempTaxList.filter((item) => item.id !== tax.id);
            setTempTaxList(newList);
        } else {
            let newList = [...tempTaxList, tax];
            setTempTaxList(newList);
        }
    };

    const calculateChanges = (taxes: ITax[]) => {
        const newItems = [...items];
        const listItemsUpdates: OrderItem[] = [];

        newItems.forEach((orderItem) => {
            orderItem.taxes = [];
            orderItem.discount = 0;
            orderItem.discounts = [];
            let newListTaxOrder = taxes.map((tax: ITax) => {
                return convertTaxOrderItem(tax, orderItem.basePrice, false);
            });
            let [taxPercentage] = extractTaxInformation(newListTaxOrder);
            let basePriceResult = calculateItemBasePrice(orderItem, newListTaxOrder, true, false);
            orderItem.basePrice = basePriceResult.basePrice;
            orderItem.manualPercentageDiscount = discountState;
            let taxAmount = getItemTotalTax(newListTaxOrder, orderItem, true, false);

            let newOrderItem = {
                ...orderItem,
                basePrice: basePriceResult.basePrice,
                taxes: [...orderItem.taxes, ...newListTaxOrder],
                totalTax: taxAmount,
                totalTaxPercentage: taxPercentage,
            };

            listItemsUpdates.push(getOrderItemTotals(newOrderItem));
        });
        props.onUpdateAllItem(listItemsUpdates);
    };

    const onSaveTaxes = () => {
        calculateChanges(tempTaxList);
        setLocalTaxes(tempTaxList);
        if (props.onClose) props.onClose();
    };

    const calculateTotalDiscount = () => {
        let totalDiscount = realOrderValue * (discountState / 100);
        return totalDiscount;
    };

    return (
        <Flex column padding={20} gap={20}>
            <Flex column gap15>
                <Flex gap10 alignCenter spaceBetween>
                    <Flex gap10>
                        <TextField className="fa-regular fa-circle-dollar-to-slot" />
                        <TextField bold>Impuestos</TextField>
                    </Flex>
                </Flex>
                <GridTemplate size={180} gap={10}>
                    {taxState.items.map((tax, index) => {
                        const active = tempTaxList.find((item: ITax) => item.id === tax.id) ? "active" : "";
                        return (
                            <CardPercentage className={`${active}`} key={index} onClick={() => addTaxAllProducts(tax)}>
                                <span className={`selection ${active} ${active ? "fa-sharp fa-regular fa-check" : ""}`}></span>
                                <TextField className="text">{tax.name}</TextField>
                                <TextField className="right" small>
                                    {tax.value}%
                                </TextField>
                            </CardPercentage>
                        );
                    })}
                </GridTemplate>
            </Flex>
            <Flex column gap10>
                <Flex gap10>
                    <TextField className="fa-sharp fa-regular fa-badge-percent" />
                    <TextField bold>Descuentos</TextField>
                </Flex>
                <Flex alignCenter gap5>
                    <TextBoxNumeral
                        rounded
                        name="discount"
                        format="number"
                        value={discountState}
                        onNumberChange={setDiscountState}
                        maxLength={3}
                        autoComplete="off"
                    />
                    <TextField>%</TextField>
                </Flex>
            </Flex>

            <Flex column gap10 alignStart>
                <Table cellPadding={0} cellSpacing={0} tdPadding={8} widthAuto tdPaddingRight={20} hideBorde>
                    <tbody>
                        <tr>
                            <td>Total impuestos:</td>
                            <td>{formatMoney(totalTaxes)}</td>
                        </tr>

                        <tr>
                            <td>Total descuento:</td>
                            <td>{formatMoney(calculateTotalDiscount())}</td>
                        </tr>
                        <tr className="border-dashed">
                            <td>Valor sin impuestos:</td>
                            <td>{formatMoney(realOrderValue - totalTaxes)}</td>
                        </tr>
                        <tr>
                            <td>
                                <TextField bold>Valor total:</TextField>
                            </td>
                            <td>
                                <TextField bold>{formatMoney(realOrderValue - calculateTotalDiscount())}</TextField>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Flex>
            <Flex marginTop={10} alignEnd justifyEnd gap10>
                <DefaultButton
                    borderRadius={10}
                    onClick={() => {
                        if (props.onClose) props.onClose();
                    }}
                >
                    Cancelar
                </DefaultButton>
                <PrimaryButton borderRadius={10} onClick={() => onSaveTaxes()}>
                    Guardar
                </PrimaryButton>
            </Flex>
        </Flex>
    );
};

export const OrderItemsConfigModal = (props: OrderItemsConfigProps) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            {!props.showTitle && (
                <SquareButton title="Impuestos/Descuentos" colorText removeBackground onClick={() => setShowModal(!showModal)}>
                    <TextField className="fa-regular fa-gear" />
                </SquareButton>
            )}
            {props.showTitle && (
                <DefaultButton colorText borderRadius={10} removeBackground onClick={() => setShowModal(!showModal)}>
                    <TextField className="fa-regular fa-gear" /> Impuestos/Descuentos
                </DefaultButton>
            )}
            {showModal && (
                <Modal showHeader title="Aplicar a todos los productos" show={showModal} setShow={setShowModal}>
                    <OrderItemsConfig {...props} onClose={() => setShowModal(false)} />
                </Modal>
            )}
        </>
    );
};
