import React, { useState } from "react";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { WahioFile } from "../../../api/models";
import { IPurchase } from "../../../api/models/purchase";
import { purchaseEndpoint } from "../../../api/restApiEndpoint";
import { CloudImages } from "../../../assets";
import { useAlert } from "../../Alerts/Alert";
import FilesViewer from "../../Files/FilesViewer";
import LoadingDualRing from "../../LoadingDualRing";
import { EmptyOrderFilesContainer, OrderFilesContainerStyle } from "../../Orders/OrderProfile/styled";
import { DefaultButton, Flex, PrimaryButton } from "../../_controls";

interface OrderFilesProps {
    order: IPurchase;
    onUpdateOrder: (value: IPurchase) => void;
}

export default function OrderFiles(props: OrderFilesProps) {
    const { order } = props;
    const alert = useAlert();
    const [files, setFiles] = useState(order.files ?? []);
    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const handleSaveChanges = () => {
        setIsFetching(true);
        wahioFetch.post(
            purchaseEndpoint.post.purchaseAll,
            {
                ...order,
                files,
            },
            (success) => {
                setIsFetching(false);
                alert.success("Los cambios se guardaron correctamente");
                setShowSaveChanges(false);
                props.onUpdateOrder(success.data);
            },
            (error) => {
                setIsFetching(false);
                alert.error(getServiceMessageError(error));
            }
        );
    };

    const onFilesChange = (files: WahioFile[]) => {
        setFiles(files);
        setShowSaveChanges(true);
    };

    const handleCancelChanges = () => {
        setFiles(order.files ?? []);
        setShowSaveChanges(false);
    };

    return (
        <OrderFilesContainerStyle>
            {files.length === 0 && (
                <EmptyOrderFilesContainer>
                    <div className="image">
                        <img src={CloudImages.openFolder} alt="" />
                    </div>
                </EmptyOrderFilesContainer>
            )}
            <FilesViewer cardMaxWidth={140} files={files} onUpdateFiles={onFilesChange} showAccessType showAttachButton />
            {showSaveChanges && (
                <Flex gap10 alignCenter marginTop={20} justifyEnd>
                    <DefaultButton disabled={isFetching} onClick={handleCancelChanges} borderRadius={10}>
                        Cancelar
                    </DefaultButton>
                    <PrimaryButton disabled={isFetching} borderRadius={10} onClick={() => handleSaveChanges()}>
                        Guardar Cambios {isFetching && <LoadingDualRing small />}
                    </PrimaryButton>
                </Flex>
            )}
        </OrderFilesContainerStyle>
    );
}
