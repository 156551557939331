import React, { createContext, useReducer, useEffect } from "react";
import { saveData, LocalStoreKeys } from "..";
import { IPurchaseActions, useActions } from "../actions/purchase";
import { applyMiddleware } from "../middlewares/purchase";
import { purchaseReducer, initState } from "../reducers/purchase";
import { IPurchaseState } from "../../api/models/purchase";

interface IContextProps {
    purchaseState: IPurchaseState;
    purchaseActions: IPurchaseActions;
}

export const PurchaseContext = createContext({} as IContextProps);

const PurchaseContextProvider = (props: any) => {
    const [purchaseState, dispatch] = useReducer(purchaseReducer, initState);

    useEffect(() => {
        saveData(LocalStoreKeys.purchase, purchaseState);
    }, [purchaseState]);

    const purchaseActions = useActions(purchaseState, applyMiddleware(dispatch));

    return <PurchaseContext.Provider value={{ purchaseState, purchaseActions }}>{props.children}</PurchaseContext.Provider>;
};

export default PurchaseContextProvider;
