import styled from "styled-components";
import { device } from "../../theme/themeProvider";

export const InputFileContainer = styled.div`
    input {
        display: none;
    }
`;

export const ImageSelectorContainer = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 10px;
    width: fit-content;
    position: relative;
    display: flex;
    justify-content: center;

    .wahio-cloud-actions {
        position: absolute;
        bottom: -25px;
        transition: all 0.3s;
        display: none;
        z-index: 1;

        button {
            background: ${(props) => props.theme.palette.background};
            color: ${(props) => props.theme.palette.text};
            box-shadow: 3px 2px 13px ${(props) => props.theme.palette.shadowColor};
        }
    }
    &:hover {
        .wahio-cloud-actions {
            display: flex;
            bottom: 8px;
            transition: all 0.3s;
        }
    }
    .wahio-cloud-image {
        height: 100px;
        min-width: 100px;
        max-width: 380px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`;

export const AttachedFilesCardContainer = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    padding: 6px 17px;
    &.hide-border {
        border: 0px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 0px;
        padding: 0px;
    }
    .container-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-title {
            font-weight: 500;
        }
    }
`;

export const CloudImagenModalFooter = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    border-top: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 20px;
`;

export const AllImageContainer = styled.div`
    padding: 20px;
    padding-top: 0px;
    border: 2px solid transparent;
    border-radius: 9px;
    border-style: dashed;
    width: 100%;
    min-height: 231px;
    box-sizing: border-box;
    position: relative;

    &.modal {
        height: 100%;
        max-height: 400px;
        overflow: auto;
    }

    ${(props: { isModal?: boolean }) => (props.isModal ? "max-height: 600px;overflow: auto;" : "")}

    .drag-icon {
        color: ${(props) => props.theme.palette.textLight};
        text-align: center;
        display: block;
        font-size: 2.5rem;
        padding-bottom: 6px;
    }

    .dragcontainer {
        background: #4b4b807a;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 0px;
        position: absolute;
        z-index: 2;
    }

    transition: background 0.2s;

    &.show-drag {
        border: 2px dashed ${(props) => props.theme.palette.textLight};
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    &.dragactive {
        border: 2px dashed ${(props) => props.theme.palette.primary};
        background: ${(props) => props.theme.palette.primary}50;

        &:after {
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const CloudAllContainer = styled.div`
    margin-left: 10px;
`;

export const SelectionFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 15px 25px;
    padding-bottom: 20px;
    border-top: 1px solid ${(props) => props.theme.palette.backgroundDark};
`;

export const CloudItemListContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 1fr;

    @media ${device.lg} {
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        &.modal {
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        }
    }
`;

export const CloudSmallItemListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    .image-cloud {
        height: 80px;
    }
`;

export const CloudItemCardContainerFake = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 15px;
    width: 100%;
    aspect-ratio: 1;
    animation: background-animation 1s ease infinite;

    @keyframes background-animation {
        0% {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
        50% {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
        100% {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const CloudItemCardContainer = styled.div`
    border: 0px solid transparent;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.palette.background};

    .dropdown-btn {
        border: 0px;
        background: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    &.selected {
        background: ${(props) => props.theme.palette.primary}40;
    }

    .image-container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        position: relative;

        .access-type-icon {
            background: #4caf50;
            color: white;
            width: 30px;
            height: 30px;

            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 7px;
            right: 7px;
            border-radius: 10px;
        }
    }
    .image-cloud {
        width: 100%;
        aspect-ratio: 16/13;
        border-radius: 15px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.file-extention {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 80px;
                height: auto;
            }
        }
    }

    .body-cloud {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        flex-direction: row;
        .name {
            padding-left: 10px;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            margin: 0;
        }
        .btn-action {
            background: transparent;
            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark};
            }
        }
    }

    &.small-card {
        position: relative;
        .image-container {
            height: 74px;
            overflow: hidden;
        }
        .image-cloud {
            img {
                object-fit: cover;
            }

            &.file-extention {
                padding: 10px 0px;

                img {
                    object-fit: contain;
                    height: 50px;
                }
            }
        }

        .body-cloud {
            display: none;
            position: absolute;
            right: 0;
        }
        &:hover {
            .body-cloud {
                display: block;
                position: absolute;
                right: 0;
            }
        }
    }

    .private {
        background-color: ${(props) => props.theme.palette.dangerColor};
        color: white;
    }
    .public {
        background-color: ${(props) => props.theme.palette.primary2};
        color: white;
    }

    &.success {
        background-color: ${(props) => props.theme.palette.dangerColor}80;
        .image-cloud {
            background-color: ${(props) => props.theme.palette.dangerColor};
        }
    }
`;

export const FileItemRowContainer = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    display: flex;
    padding: 10px 20px;
    &.selected {
        border: 1px solid ${(props) => props.theme.palette.primary};
        background: ${(props) => props.theme.palette.primary}15;
    }
    .delete-view {
    }
    .dropbtn {
        width: 24px;
        border: 0;
    }
    .image-container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .image-cloud {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        overflow: hidden;
        margin-right: 10px;
        display: flex;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &.small {
            img {
                height: 40px;
                object-fit: contain;
            }
        }
    }
    .delete-view {
        margin-left: auto;
    }
    .body-cloud {
        display: flex;
        flex-direction: column;
        width: 65%;
        input {
            background: transparent;
            padding: 3px 5px;
        }
        .filename {
            padding-left: 6px;
            font-size: 13px;
            margin-top: 3px;
            color: ${(props) => props.theme.palette.textLight};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            margin: 0;
        }
    }
`;

export const CustomFilesContainerView = styled.div`
    display: flex;
    flex-direction: column;
    .container-list {
        .file-item-row:last-child {
        }
    }
    .header {
        display: flex;
        padding: 18px 20px;
        justify-content: flex-end;
    }
`;
export const ImageColorLabel = styled.div`
    width: 100%;
    height: 12px;
`;
export const MainContainerInfoCloud = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 1.5rem 2rem;

    .small-title {
        font-size: 13px;
        font-weight: 400;
        margin: 0px;
        color: ${(props) => props.theme.palette.textLight};
    }
    p {
        font-size: 13px;
        font-weight: 500;
    }
    .footer-container {
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const GridFoldersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    width: 100%;
`;

export const FolderCard = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    border-radius: 10px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .btn-checkbox {
        position: absolute;
        top: 4px;
        left: 7px;
        z-index: 1;
        visibility: hidden;
        font-weight: 500;
        background: ${(props) => props.theme.palette.primary}20;
        border-radius: 50px;
        padding: 7px;
        align-items: center;
        justify-content: center;

        .checkbox {
            background: ${(props) => props.theme.palette.background};
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            border-radius: 6px;
            padding: 3px;
            width: 12px;
            height: 12px;
            font-size: 11px;
            align-items: center;
            justify-content: center;
        }

        &.active {
            visibility: visible;

            .checkbox {
                background: ${(props) => props.theme.palette.primary};
                color: white;
                font-weight: 500;
            }
        }
    }

    &:hover {
        .btn-checkbox {
            visibility: visible;
        }
        .image {
            visibility: hidden;
        }
    }

    .name {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.palette.textLight};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    .dropContent {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px 0px;
        width: 100%;

        li {
            cursor: pointer;
            padding: 6px 15px;
            display: flex;
            align-items: center;

            .icon {
                width: 30px;
                text-align: center;
                padding-right: 7px;
            }

            &:hover {
                background: ${(props) => props.theme.palette.backgroundDark};
            }
        }
    }

    .folder-image {
        visibility: hidden;
    }
`;

export const FolderInternalContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    gap: 10px;
`;

export const ActiveFolderContent = styled.div`
    display: flex;
    gap: 5px;
    height: 100%;

    &:hover {
        &:not(:last-child) {
            cursor: pointer;
            span {
                color: ${(props) => props.theme.palette.primary};
                text-decoration: underline;
            }
        }
    }
`;

export const MoveModalContent = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${(props) => props.theme.palette.backgroundLight};
    min-height: 350px;
    padding: 10px;
    overflow-y: auto;
    width: 60%;
    justify-content: space-between;

    .image {
        display: flex;
        flex-direction: column;
        min-width: 80px;
        max-width: 80px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        padding: 10px;
        column-gap: 3px;
        overflow: hidden;
        gap: 3px;
        justify-content: center;
    }
    .name {
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        margin: 0;
        text-align: center;
    }
`;

export const FolderTreeContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    overflow-x: auto;
    width: 40%;
    padding-top: 10px;

    .main-folder {
        margin-left: 20px;
    }
`;

export const FolderCardTree = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
    &.active {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .caret {
        width: 10px;
        height: 10px;
        position: absolute;
        left: -9px;
        top: 6px;
    }
`;

export const GridFilesContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    scroll-behavior: auto;
    overflow-y: auto;
    max-height: 400px;
    scroll-behavior: smooth;

    .text {
        margin-left: 10px;
    }
`;
