import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError } from "../../../api";
import { ProductPartialUpdateData } from "../../../api/models/product";
import { productPartialUpdate } from "../../../api/products/productsApi";
import { useAlert } from "../../Alerts/Alert";
import ProductDescriptionForm from "../ProductCreationForm/ProductDescriptionForm";
import NavigationCard, { NavigationActions } from "../../NavigationPanel/NavigationCard";
import messages from "./messages";

export default function NavigationDescription(props: ProductNavigationProfileProps) {
    const [product, setProduct] = useState(props.product);
    const [hasChanges, setHasChanges] = useState(false);
    const alert = useAlert();
    const intl = useIntl();
    const timerRef = React.useRef<any>();

    const saveProductMutation = useMutation((body: ProductPartialUpdateData) => productPartialUpdate(body), {
        onSuccess: (data) => {
            alert.success("Descripción actualizada");
            setHasChanges(false);
            props.onUpdateProduct({ ...product, description: data.description ?? "" });
        },
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    useEffect(() => {
        handleCheckChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, props.product]);

    const handleCheckChanges = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setHasChanges(product.description !== props.product.description);
        }, 500);
    };

    const handleSaveDescription = () => {
        const body: ProductPartialUpdateData = {
            id: product.id,
            isVariant: false,
            data: { description: product.description },
        };
        saveProductMutation.mutate(body);
    };

    return (
        <NavigationCard
            {...props}
            allowClose
            defaultClose
            title={intl.formatMessage(messages.description)}
            actionsComponent={
                <NavigationActions isLoading={saveProductMutation.isLoading} disabled={!hasChanges} onSave={handleSaveDescription} />
            }
        >
            <ProductDescriptionForm
                product={product}
                onChange={(value) => {
                    setProduct(value);
                }}
            />
        </NavigationCard>
    );
}
