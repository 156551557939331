import styled from "styled-components";
import { SquareButton } from "../_controls";

export const PaymentRowBody = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    .ml-auto {
        margin-left: auto;
    }
    input {
        width: 100%;
        font-weight: 500;
        font-size: 17px;
    }

    &.column {
        flex-direction: column;
    }

    .hideInput {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`;

interface PaymentRowContainerProps {
    removePadding?: boolean;
}

export const BtnFiles = styled(SquareButton)`
    position: relative;

    .files-count {
        background: #009688;
        position: absolute;
        top: -3px;
        right: -8px;
        padding: 2px;
        border-radius: 12px;
        min-width: 29px;
        min-width: 17px;
        font-weight: 500;
        color: white;
        font-size: 12px;
    }
`;

export const ReadonlyPaymentCard = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    border-radius: 15px;
    padding: 10px 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    button {
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.backgroundLight};
    }
`;

export const PaymentRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    ${(props: PaymentRowContainerProps) => (props.removePadding ? `padding: 0;background:transparent;` : "")}
    gap: 10px;

    button {
        color: ${(props) => props.theme.palette.text};
    }

    .select__indicator-separator {
        display: none;
    }
    .select__indicator {
        padding-left: 0;
    }

    .textbox-numeral {
        width: 100%;
        background: transparent;
        padding: 4px;
        padding-left: 15px;
        border-radius: 15px 15px 0 0;
        font-size: 18px;
        font-weight: 600;
        border: 0;
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};

        &:focus,
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            border-radius: 15px;
            border-bottom: 1px solid transparent;
        }
    }

    .item-active .select__control {
        background: transparent;
    }
    .payment-btn {
        color: ${(props) => props.theme.palette.textLight};
    }
    .btn-tooltip {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const PaymentSuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    .CheckRoundSuccess {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.palette.green};
        color: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        border-radius: 50%;
        padding: 5px 7px;
        .iconCheck {
            font-size: 15px;
            font-weight: 500px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0px;
        border: 1px solid ${(props) => props.theme.palette.backgroundLight};
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        border-radius: 5px;

        .lineSeparation {
            border-top: 1px solid ${(props) => props.theme.palette.backgroundLight};
        }
    }
`;
