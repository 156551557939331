import { defineMessages } from "react-intl";

const base = "utils.dates";

export default defineMessages({
    yesterday: {
        id: `${base}.yesterday`,
        defaultMessage: "Ayer",
    },
    today: {
        id: `${base}.today`,
        defaultMessage: "Hoy",
    },
    tomorrow: {
        id: `${base}.tomorrow`,
        defaultMessage: "Mañana",
    },
    last: {
        id: `${base}.last`,
        defaultMessage: "Pasado",
    },
});
