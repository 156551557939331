import moment from "moment";
import QRCode from "qrcode.react";
import React, { useContext } from "react";
import { OrderInvoiceFormatProps } from ".";
import { TRACK_ENDPOINT } from "../../api/appConfig";
import { getAccountUserFullName } from "../../api/models/accountUser";
import { OrderItem } from "../../api/models/order";
import { OrderPrintSettings, TitleTemplate } from "../../api/models/organizationTypes";
import translate from "../../i18n/translate";
import { AccountUserContext } from "../../store/contexts/AccountUserContext";
import { OrganizationContext } from "../../store/contexts/OrganizationContext";
import { formatMoney, getDateFormat, joinUrl } from "../../utils";
import OrderProfileMessages from "../Orders/OrderProfile/messages";
import { Flex, TextField } from "../_controls";
import { CardBodyNormal, FlexColumn, InvoiceDesignCard, InvoiceTableNormal } from "./styled";
import { getCodeAndReference } from "../Products/helper";

export default function InvoiceFormatNormal(props: OrderInvoiceFormatProps) {
    const { order, testMode, printSettings } = props;
    const { accountUserState } = useContext(AccountUserContext);
    const { organizationState } = useContext(OrganizationContext);

    const organization = organizationState.currentOrganization;

    const disabledFields = printSettings?.disabledFields ?? organization?.settings?.orderPrint?.disabledFields ?? {};
    const titleTemplate = React.useMemo(() =>
        printSettings?.titleTemplate ??
        organization?.settings?.orderPrint?.titleTemplate ?? {}
        , [organization?.settings?.orderPrint?.titleTemplate, printSettings?.titleTemplate]);

    const customTitles = React.useMemo(() => {
        const template = props.template;
        const value: TitleTemplate = {
            title1: titleTemplate.title1 ?? template.invoiceTemplateTitle1,
            title2: titleTemplate.title2 ?? template.invoiceTemplateTitle2,
            title3: titleTemplate.title3 ?? template.invoiceTemplateTitle3,
        };
        return value;
    }, [titleTemplate, props.template]);

    if (!order) return null;

    const customer = order.customer;
    const delivery = order.deliveryOrder;
    const invoice = order.orderInvoice;

    const getCustomerFullName = () => {
        if (customer) {
            return customer.firstName + " " + customer.lastName;
        }
        return "Cliente General";
    };

    const getCustomerIdentification = () => {
        let type = customer?.identificationType;
        if (type && type.length > 3) {
            type = type.substring(0, 3);
        }
        return type + ": " + customer?.identificationNumber;
    };
    const getProductSku = (item: OrderItem) => {
        if (item.productVariant) {
            return item.productVariant.sku;
        } else {
            return item.product?.sku;
        }
    };

    const isPartial = order.status === "partial";
    const needPayment = order.totalAmount - order.totalPaid > 0;

    const firstAddress = customer?.addresses && customer.addresses.length > 0 ? customer.addresses[0] : undefined;

    let items = order.items ?? [];
    let orderDate = order.dateCreatedCustom ?? order.dateCreated;

    return (
        <InvoiceDesignCard className="invoice-normal-container">
            <CardBodyNormal className={testMode ? "test-mode" : ""}>
                <div className="header">
                    <Flex alignStart spaceBetween>
                        {props.image && (
                            <div className="img-container">
                                <img src={props.image} alt="Org" />
                            </div>
                        )}

                        <Flex gap10 alignStart>
                            <div className="border flexrow min-width">
                                <div className="FlexCol">
                                    <span className="bold">{customTitles.title1 ? customTitles.title1 : "---"}</span>
                                    <TextField>{!disabledFields.orderDate ? getDateFormat(orderDate, "DD MMM YYYY") : "---"}</TextField>
                                </div>

                                {isPartial && (
                                    <div className="FlexCol ml-2">
                                        <span>Pagos: {formatMoney(order.totalPaid)}</span>
                                        <span>Saldo: {formatMoney(order.totalAmount - order.totalPaid)}</span>
                                    </div>
                                )}
                            </div>
                        </Flex>
                    </Flex>

                    <div className="grid3 mt-1 ">
                        <div className="border card FlexCol">
                            <p className="m-0 bold">Cliente: {getCustomerFullName()}</p>

                            <p className="m-0">
                                Tel: {customer?.phoneNumber ? customer.phoneNumber : " - - -"}
                                {"  "}
                                {customer?.identificationNumber ? getCustomerIdentification() : ""}
                            </p>
                            {!delivery && firstAddress && (
                                <>
                                    <p className="m-0">
                                        {firstAddress.state}, {firstAddress.city}
                                    </p>
                                    <p className="m-0">
                                        {firstAddress.description} {firstAddress.additionalInformation}
                                    </p>
                                </>
                            )}
                            {!disabledFields.sign && (
                                <Flex gap5 alignEnd style={{ marginTop: "auto" }}>
                                    <TextField style={{ width: "50px" }}>Firma</TextField>
                                    <div style={{ width: "100%", height: "1px", background: "grey" }}></div>
                                </Flex>
                            )}
                        </div>
                        <div className="border card FlexCol  space-between">
                            <div className="FlexCol">
                                <span className="bold">Lugar y Fecha</span>
                                <span>{organization?.address}</span>
                                {!disabledFields.dueDate && order.paymentTermsDays !== 0 && order.status !== "paid" && (
                                    <span>Vence: {moment(orderDate).add(order.paymentTermsDays, "days").format("DD MMM YYYY")}</span>
                                )}
                                {!disabledFields.orderDate && (
                                    <span>Creado: {getDateFormat(order.dateCreatedCustom ?? order.dateCreated, "DD MMM YYYY")} </span>
                                )}
                                {!disabledFields.invoiceDate && invoice && (
                                    <span>Facturado: {getDateFormat(order.dateCreatedCustom ?? invoice.dateCreated, "DD MMM YYYY")}</span>
                                )}

                                {!disabledFields.user && (
                                    <span>
                                        Creado Por:{" "}
                                        {getAccountUserFullName(accountUserState.items.find((x) => x.id === order.accountUserId))}
                                    </span>
                                )}
                                {!disabledFields.seller && order.contactSellerId && (
                                    <span>Vendedor: {getAccountUserFullName(order.contactSeller)}</span>
                                )}
                            </div>
                        </div>
                        <div className="border card FlexCol  space-between">
                            <Flex gap={20} spaceBetween>
                                <Flex column>
                                    {!disabledFields.orderNumber && <TextField bold>Orden #{order.number}</TextField>}
                                    {!disabledFields.orderStatus && (
                                        <Flex gap5 alignCenter>
                                            <TextField>Estado</TextField>
                                            <span className={needPayment ? "status-card" : ""}>
                                                {translate(OrderProfileMessages[order.status]).props.defaultMessage}
                                            </span>
                                        </Flex>
                                    )}

                                    {!disabledFields.invoiceNumber && order.orderInvoice && <TextField>F: {order.orderInvoice?.number}</TextField>}
                                    {!disabledFields.eBillingNumber && order.eBillingNumber && <TextField>F.E {order.eBillingNumber}</TextField>}

                                    {!disabledFields.itemsCount && (
                                        <Flex column>
                                            <TextField>Artículos</TextField>
                                            <TextField>{`${order.itemsCount} / ${order.itemsQuantityCount}`}</TextField>
                                        </Flex>
                                    )}
                                </Flex>
                                {!disabledFields.qrTracking && (
                                    <QRCode size={75} value={joinUrl(TRACK_ENDPOINT, order.trackingNumber ?? "")} />
                                )}
                            </Flex>
                        </div>
                    </div>
                </div>

                <InvoiceTableNormal cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <td className="not-padding">
                                <div className="header-space"> </div>
                            </td>
                        </tr>
                    </thead>
                    <thead className="table-header">
                        <tr>
                            <th>#</th>
                            {!disabledFields.itemSku && <th>Sku</th>}
                            {!disabledFields.itemReference && <th>Referencia</th>}
                            {!disabledFields.itemCode && <th>Código</th>}

                            <th>Descripción</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            {!disabledFields.itemDiscount && <th>Desc</th>}

                            {!disabledFields.itemTax && <th>Imp</th>}
                            <th className="right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            if (item.product || item.productVariant) {
                                let total = item.unitPrice * (item.quantity - item.quantityReturned);
                                return (
                                    <tr key={index}>
                                        <td className="td-center">{index + 1}</td>

                                        {!disabledFields.itemSku && <td>{getProductSku(item) ?? "-"}</td>}
                                        {!disabledFields.itemReference && <td>{getCodeAndReference(item, true) ?? "-"}</td>}
                                        {!disabledFields.itemCode && <td>{getCodeAndReference(item) ?? "-"}</td>}
                                        <td>
                                            {item.product?.name.replace("[Eliminado]" && "[Serial]", "")}
                                            {item.serials
                                                ? item.serials.map((serial, index) => {
                                                      return (
                                                          <Flex column key={index}>
                                                              <span className="text-smallSerials">{serial}</span>
                                                          </Flex>
                                                      );
                                                  })
                                                : ""}
                                        </td>
                                        <td>{formatMoney(item.unitPrice)}</td>
                                        <td>
                                            {item.quantity - item.quantityReturned}{" "}
                                            {item.quantityReturned > 0 && `(${-item.quantityReturned})`}
                                        </td>
                                        {!disabledFields.itemDiscount && <td>{item.manualPercentageDiscount}%</td>}
                                        {!disabledFields.itemTax && <td>{item.totalTaxPercentage}%</td>}
                                        <td className="right">{formatMoney(total - (total * item.manualPercentageDiscount) / 100)}</td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="not-padding">
                                <div className="footer-space"> </div>
                            </td>
                        </tr>
                    </tfoot>
                </InvoiceTableNormal>

                <div className={testMode ? "" : "footer"}>
                    <FooterContent {...props} customTitles={customTitles} />
                </div>
            </CardBodyNormal>
        </InvoiceDesignCard>
    );
}

export interface ContentProps extends OrderInvoiceFormatProps {
    customTitles: TitleTemplate;
    printSettings?: OrderPrintSettings;
}

export const FooterContent = (props: ContentProps) => {
    const { order, testMode, customTitles, printSettings } = props;
    const delivery = order.deliveryOrder;

    const disabledFields = printSettings?.disabledFields ?? {};

    return (
        <div>
            <section className="grid3 mt-1">
                <div className={`${delivery ? "border cardFooter" : "border card"} FlexCol`}>
                    {delivery && (
                        <>
                            <span className="bold">Enviar a: {delivery.contactName}</span>
                            <p className="m-0">Teléfono: {delivery.phoneNumber}</p>
                            <p className="m-0">
                                Ciudad: {delivery.city}/{delivery.state}
                            </p>
                            <p className="m-0">Dirección: {delivery.address}</p>
                            <p className="m-0">{delivery.additionalInformation}</p>
                        </>
                    )}
                </div>
                <div className={`${delivery ? "border cardFooter" : "border card"} FlexCol`}>
                    {props.numeration && props.numeration.description && <div className="mb-1">{props.numeration.description}</div>}
                    {(customTitles.title2 || customTitles.title3) && (
                        <>
                            {customTitles.title2 && <span className="mb-1 all-text">{customTitles.title2}</span>}
                            <span className="all-text">{customTitles.title3}</span>
                        </>
                    )}
                </div>
                <div className={`${delivery ? "border cardFooter" : "border card"} FlexCol`}>
                    {order.moneyReceived > 0 && (
                        <FlexColumn className="invoice-details">
                            <span>Cantidad recibida: {formatMoney(order.moneyReceived)}</span>
                            <span className="bold">Cambio: {formatMoney(order.moneyReturned)}</span>
                        </FlexColumn>
                    )}
                    <table className="total-table">
                        <tbody>
                            <tr>
                                <td>Subtotal</td>
                                <td>{formatMoney(order.subTotal)}</td>
                            </tr>
                            {!disabledFields.itemTax && (
                                <tr>
                                    <td>Impuesto</td>
                                    <td>{formatMoney(order.totalTaxItems)}</td>
                                </tr>
                            )}
                            {!disabledFields.itemDiscount && order.totalManualPercentageDiscountItems > 0 && (
                                <tr>
                                    <td>Descuento</td>
                                    <td>{formatMoney(order.totalManualPercentageDiscountItems)}</td>
                                </tr>
                            )}
                            {!disabledFields.itemDiscount && order.totalDiscount > 0 && (
                                <tr>
                                    <td>Descuento General</td>
                                    <td>{formatMoney(order.totalDiscount)}</td>
                                </tr>
                            )}
                            {order.totalAmountReturns > 0 && (
                                <tr>
                                    <td>Devolución</td>
                                    <td>{formatMoney(order.totalAmountReturns)}</td>
                                </tr>
                            )}
                            {order.totalDelivery > 0 && (
                                <tr>
                                    <td>Envío</td>
                                    <td>{formatMoney(order.totalDelivery)}</td>
                                </tr>
                            )}
                            {order.totalTip > 0 && (
                                <tr>
                                    <td>Propina</td>
                                    <td>{formatMoney(order.totalTip)}</td>
                                </tr>
                            )}
                            <tr>
                                <td className="bold">TOTAL</td>
                                <td className="bold">{formatMoney(order.totalAmount)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <div className="border observation  FlexCol">
                <span>Observaciones:</span>
                <span>{order.comment}</span>
            </div>
            {!testMode && <div className="footer-subtitle">Software By Wahio, suite.wahio.com</div>}
        </div>
    );
};
