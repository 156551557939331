import _ from "lodash";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { OrderFormOrigin } from "..";
import { getServiceMessageError } from "../../../../api";
import { getUserPermissions } from "../../../../api/models/accountUser";
import { Order, OrderItem } from "../../../../api/models/order";
import { ProductCheckItem } from "../../../../api/models/product";
import { getProductsCheckStock } from "../../../../api/products/productsApi";
import { UserContext } from "../../../../store/contexts/UserContext";
import { useWarehouseContext } from "../../../../store/contexts/WarehouseContext";
import { formatMoney } from "../../../../utils";
import { useAlert } from "../../../Alerts/Alert";
import LoadingDualRing from "../../../LoadingDualRing";
import PaymentsForm from "../../../Payments/PaymentsForm";
import usePaymentHook from "../../../Payments/paymentHooks";
import { getProductThumbnailUrl } from "../../../Products/helper";
import TextBoxNumeral from "../../../TextBoxNumeral";
import { DefaultButton, Flex, PrimaryButton, TextField } from "../../../_controls";
import { useModal } from "../../../modals/Modal";
import messages from "../messages";
import { getDefaultNotification } from "./NotificationOrderCard";
import NotificationMessageCard from "./NotificationMessageCard";
import { getButtonStyle, getTotalPayments } from "./finalizeHelper";
import {
    ActionsButtonsContainer,
    AddChangeView,
    ContainerProductsOutOfStock,
    FinalizeContainer,
    PaymentTermView,
    TotalAmountCard,
} from "./styled";

export interface FinalizeOrderFormProps {
    onCancel: () => void;
    onFinalize: () => void;
    onFinalizeClose: () => void;
    isSaving?: boolean;
    onOrderChange: (value: Order) => void;
    order: Order;
    calculateItemsTotal: (value: OrderItem[]) => void;
    origin?: OrderFormOrigin;
}

export interface IProductStockError {
    item: OrderItem;
    stockAvailable: number;
}

export interface IProductStockValidation {
    newItems: OrderItem[];
    errors: IProductStockError[];
}

export default function FinalizeOrderForm(props: FinalizeOrderFormProps) {
    const intl = useIntl();
    const { order } = props;

    const { userState } = useContext(UserContext);

    const alert = useAlert();
    const { payments } = order;

    const [productStockValidation, setProductStockValidation] = useState<IProductStockValidation>({
        newItems: [],
        errors: [],
    });
    const productOutOfStockModal = useModal();
    const { warehouseState } = useWarehouseContext();

    const [defaultDataLoad, setDefaultDataLoad] = useState(false);
    const paymentHooks = usePaymentHook();

    const getInitialPayments = () => {
        if (order.payments.length === 1) {
            let myCustomPayment = order.payments[0];
            if (myCustomPayment && !myCustomPayment.id) {
                if (myCustomPayment.amount !== order.totalAmount) {
                    myCustomPayment.amount = order.totalAmount;
                    return [myCustomPayment];
                }
            }
        }

        if (props.order.id && props.order.deliveryOrder && !props.order.orderInvoice) {
            return props.order.payments ?? [];
        }

        if (payments.length === 0) {
            let payAmount = order.totalAmount;
            let payment = paymentHooks.getNewPayment({ amount: payAmount });
            if (payment) {
                return [...payments, payment];
            }
        }

        return payments ?? [];
    };

    const totalPayments = React.useMemo(() => {
        if (!defaultDataLoad) {
            let initialPayments = getInitialPayments();
            const { sendNotification, showNotificationCard } = getDefaultNotification(order);
            props.onOrderChange({ ...order, payments: initialPayments, showNotificationCard, sendNotification });
            setDefaultDataLoad(true);
            return getTotalPayments(initialPayments);
        } else {
            return getTotalPayments(payments);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payments, defaultDataLoad]);

    const checkStockProductMutation = useMutation((listChecked: ProductCheckItem[]) => getProductsCheckStock(listChecked), {
        onError: (error) => {
            alert.error(getServiceMessageError(error));
        },
    });

    const getProductCheckItems = (): ProductCheckItem[] => {
        let items = order.items;
        var warehouseIds = _.uniq(items.map((x) => x.warehouseId));
        var warehouseSelected = warehouseState.allWarehouses.filter((x) => warehouseIds.includes(x.id ?? "-")) ?? [];

        let checkList = items
            .filter((x) => !warehouseSelected.find((y) => y.id === x.warehouseId)?.allowNegativeStock)
            .filter((x) => x.product?.controlInventory || x.product?.isParent)
            .map((item: any) => {
                return {
                    productId: item.productId,
                    warehouseId: item.warehouseId,
                    productVariantId: item.productVariantId ?? null,
                };
            });
        return checkList;
    };

    const handleCheckProductStock = () => {
        if (order.items.length === 0) return;

        if (props.order.id) {
            props.onFinalize();
            return;
        }

        let checkItems = getProductCheckItems();
        if (checkItems.length === 0) {
            props.onFinalize();
            return;
        }

        checkStockProductMutation.mutateAsync(checkItems).then((responseList) => {
            let newItems: OrderItem[] = [];
            let itemsOutOfStock: IProductStockError[] = [];
            order.items.forEach((item) => {
                if (!item.product?.controlInventory) {
                    newItems.push(item);
                } else {
                    let matchResult = responseList.find(
                        (x) => x.productId === item.productId && (!item.productVariantId || item.productVariantId === x.productVariantId)
                    );

                    if (!matchResult) {
                        itemsOutOfStock.push({ item: item, stockAvailable: 0 });
                    } else if (matchResult && matchResult.stockAvailable < item.quantity) {
                        itemsOutOfStock.push({ item: item, stockAvailable: matchResult.stockAvailable });
                        if (matchResult.stockAvailable > 0) {
                            newItems.push({ ...item, quantity: matchResult.stockAvailable, stockTemp: matchResult.stockAvailable });
                        }
                    } else if (matchResult) {
                        newItems.push({ ...item, stockTemp: matchResult.stockAvailable });
                    }
                }
            });

            setProductStockValidation({
                newItems: newItems,
                errors: itemsOutOfStock,
            });
            if (itemsOutOfStock.length === 0) {
                props.onFinalize();
            } else {
                productOutOfStockModal.show();
            }
        });
    };

    const onExtraValueChange = (type: "discount" | "tip", value: number, isPercentage?: boolean) => {
        let calculateValue = value;
        let calculatePer = value;
        if (order.totalAmountItems === 0) return;
        if (isPercentage) {
            calculateValue = order.totalAmountItems * (value / 100);
        } else {
            calculatePer = (100 * value) / order.totalAmountItems;
        }
        if (type === "discount") {
            if (calculatePer > 100) {
                calculatePer = 100;
                calculateValue = order.totalAmountItems;
            }
            props.onOrderChange({ ...order, totalDiscount: calculateValue, percentageDiscount: calculatePer });
        } else {
            props.onOrderChange({ ...order, totalTip: calculateValue, percentageTip: calculatePer });
        }
    };

    const differencePrices = order.totalAmount - totalPayments;
    const buttonFinish = getButtonStyle(order.totalAmount, totalPayments);

    const paymentsGreaterThanAmount = payments.filter((item) => item.amount >= order.totalAmount);

    const onAddChangeAsTip = (value: number) => {
        onExtraValueChange("tip", value);
    };

    const handleAutoFixProductCheck = () => {
        productOutOfStockModal.show(false);
        props.calculateItemsTotal(productStockValidation.newItems);
        props.onCancel();
        alert.success("Los detalles de la orden han sido actualizados correctamente");
    };

    const requiredCustomer = !buttonFinish.paidAll && !order.customer;

    const invoiceExists = !order.id || !!order.orderInvoice;
    const allowCreatePayments =
        (getUserPermissions(userState.user).order?.allowCreatePayments && invoiceExists) || props.origin === "restaurant";

    const isLoading = checkStockProductMutation.isLoading || props.isSaving;

    return (
        <FinalizeContainer>
            <productOutOfStockModal.Modal useButtonClose>
                <ContainerProductsOutOfStock>
                    <Flex column className="header ">
                        <h2 className="title">Ops! Stock insuficiente</h2>
                        <p>El stock disponible de algunos productos ha cambiado</p>
                    </Flex>
                    <Flex column className="mt-2">
                        {productStockValidation.errors.map((item) => (
                            <Flex alignCenter className="mb-1">
                                <div className="image mr-1">
                                    <img src={getProductThumbnailUrl(item.item.product)} alt="" />
                                </div>
                                <Flex column>
                                    <span>{item.item.product?.name}</span>
                                    <Flex row className="word-spacing">
                                        <span className="text-light">Disponible:</span>
                                        <span className="text-bold">{item.stockAvailable}</span>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                    <Flex justifyCenter gap10 flexWrap className="mt-2">
                        <DefaultButton rounded onClick={() => handleAutoFixProductCheck()}>
                            <span className="wahioicon-magic"></span>
                            Corregir automáticamente
                        </DefaultButton>
                        <DefaultButton rounded onClick={() => productOutOfStockModal.show(false)}>
                            <span className="wahioicon-user-cog"></span>
                            Corrección Manual
                        </DefaultButton>
                    </Flex>
                </ContainerProductsOutOfStock>
            </productOutOfStockModal.Modal>

            <TotalAmountCard>
                <TextField small bold>
                    Total
                </TextField>
                <TextField bold600 fontSize={25} className="title-amount">
                    {formatMoney(order.totalAmount)}
                </TextField>
            </TotalAmountCard>

            {defaultDataLoad && (
                <>
                    <Flex column gap10>
                        <PaymentsForm
                            payments={order.payments ?? []}
                            onChange={(items) => props.onOrderChange({ ...order, payments: items })}
                            uniqueMethod={false}
                            showAddPaymentButton={allowCreatePayments}
                            totalAmount={order.totalAmount}
                            blockExistingPayments
                        />
                        {!invoiceExists && props.origin !== "restaurant" && (
                            <div className="light">
                                <span className="wahioicon-exclamation-circle mr-1"></span> Para agregar pagos debes facturar la orden
                            </div>
                        )}
                    </Flex>

                    {(order.payments ?? []).length === 1 && order.payments[0].amount > order.totalAmount && (
                        <AddChangeView>
                            <Flex gap5 alignCenter className="change-card">
                                Cambio:
                                <span className="change-value">{formatMoney(differencePrices * -1)}</span>
                            </Flex>
                            <DefaultButton rounded onClick={() => onAddChangeAsTip(differencePrices * -1)}>
                                <span className="wahioicon-arrow-right"></span>
                                {formatMoney(differencePrices * -1)} Convertir en Propina
                            </DefaultButton>
                        </AddChangeView>
                    )}

                    {requiredCustomer && (
                        <div className="font-md text-warning danger">
                            <span className="wahioicon-exclamation-triangle mr-1"></span>
                            <span>Debes agregar un cliente para finalizar la venta como parcial o crédito</span>
                        </div>
                    )}

                    {paymentsGreaterThanAmount.length > 1 && (
                        <div className="font-md text-warning warning">
                            <span className="wahioicon-exclamation-triangle mr-1"></span>
                            <span>Debes corregir los pagos</span>
                        </div>
                    )}

                    {order.totalAmount > totalPayments && (
                        <PaymentTermView>
                            <span className="payment-title">Plazo en días para el pago</span>
                            <div className="input-group">
                                <span className="wahioicon-user-clock"></span>
                                <div className="flex">
                                    <TextBoxNumeral
                                        value={order.paymentTermsDays}
                                        onNumberChange={(value) => {
                                            props.onOrderChange({ ...order, paymentTermsDays: value });
                                        }}
                                        format={"number"}
                                        placeholder="Plazo"
                                    />
                                    <div className="date">
                                        <span className="light">Vence</span>
                                        <span>
                                            {moment(order.dateCreatedCustom ?? order.dateCreated)
                                                .add(order.paymentTermsDays, "days")
                                                .format("LL")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </PaymentTermView>
                    )}
                </>
            )}

            <NotificationMessageCard order={order} onOrderChange={props.onOrderChange} />

            {paymentsGreaterThanAmount.length <= 1 && !requiredCustomer && (
                <ActionsButtonsContainer>
                    <DefaultButton borderRadius={10} disabled={isLoading} onClick={props.onCancel} className="cancel">
                        {intl.formatMessage(messages.cancelModal)}
                    </DefaultButton>
                    {order.isDelivery && !order.orderInvoice ? (
                        <PrimaryButton borderRadius={10} disabled={isLoading} onClick={() => handleCheckProductStock()}>
                            Guardar Cambios
                            {isLoading && <LoadingDualRing small />}
                        </PrimaryButton>
                    ) : (
                        <DefaultButton
                            borderRadius={10}
                            disabled={isLoading}
                            onClick={() => handleCheckProductStock()}
                            className={buttonFinish.className}
                        >
                            {buttonFinish.title}
                            {isLoading && <LoadingDualRing small />}
                        </DefaultButton>
                    )}
                </ActionsButtonsContainer>
            )}
        </FinalizeContainer>
    );
}
