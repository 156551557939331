import styled from "styled-components";

export const EditorContainer = styled.div`
    .edit-toolbar,
    .rdw-block-wrapper,
    .rdw-colorpicker-wrapper,
    .rdw-emoji-wrapper {
        margin: 0;
    }
    &.allWidth {
        width: 100%;
    }
    .editorClassName {
        padding: 0px 10px;
        border-radius: 15px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
        margin-top: 10px;
    }
    .rdw-option-wrapper {
        border-radius: 8px;
    }

    .rdw-dropdown-optionwrapper {
        border-radius: 15px;
        padding: 15px;
    }
    .rdw-emoji-modal {
        border-radius: 15px;
    }
    .toolbarClassName {
        border-radius: 10px;
        border: 0px solid ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.background};
        padding: 0;
    }

    &.hide-toolbar {
        .toolbarClassName {
            display: none;
        }
        .editorClassName {
            margin-top: 0;
        }
    }

    .rdw-colorpicker-modal {
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        box-shadow: ${(props) => props.theme.palette.shadow};
        border-radius: 9px;
    }

    .rdw-block-dropdown {
        width: 110px;
        background: transparent;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 6px;
        color: ${(props) => props.theme.palette.text};
        font-size: 13px;
        &:hover {
            box-shadow: 0px 0px;
        }
        a {
            color: ${(props) => props.theme.palette.text};
        }
    }

    .rdw-dropdownoption-active {
        background: ${(props) => props.theme.palette.backgroundDark};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }

    .rdw-emoji-modal,
    .rdw-link-modal,
    .rdw-dropdown-optionwrapper,
    .rdw-image-modal {
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        box-shadow: ${(props) => props.theme.palette.shadow};
        input {
            box-sizing: border-box;
            color: ${(props) => props.theme.palette.text};
        }
    }
    .rdw-option-wrapper {
        background: ${(props) => props.theme.palette.background};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
            box-shadow: 0px 0px;
        }
        img {
            width: 14px;
        }
    }
    .rdw-option-active {
        box-shadow: 0px 0px 0px transparent;
        background: #2962ff40;
        border: 1px solid transparent;
        outline: none;
        border-radius: 8px;
    }
`;
