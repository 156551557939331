import { Dispatch } from "react";

import { OrderTypes } from "../../api/models/order";

export const applyMiddleware = (dispatch: Dispatch<OrderTypes>) => (action: OrderTypes) => {
    switch (action.type) {
        default:
            dispatch(action);
    }
};
