import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { ProductNavigationProfileProps } from ".";
import { getServiceMessageError, wahioFetch } from "../../../api";
import { getUserPermissions } from "../../../api/models/accountUser";
import { IInventoryProductAdjustmentModel } from "../../../api/models/inventoryAdjustment";
import {
    IProduct,
    IProductVariant,
    ProductCountResponse,
    ProductReservedSearchInput,
    WarehouseProductSummaryInput,
} from "../../../api/models/product";
import { IWarehouse } from "../../../api/models/warehouse";
import { IWarehouseProduct } from "../../../api/models/warehouseProduct";
import { getWarehouseProductCheckParentStock } from "../../../api/products/productsApi";
import { localOrderEndpoint, productEndpoint } from "../../../api/restApiEndpoint";
import { getAuthBody } from "../../../auth";
import { useUserContext } from "../../../store/contexts/UserContext";
import { useWarehouseContext } from "../../../store/contexts/WarehouseContext";
import { getDateFromNow } from "../../../utils";
import { useAlert } from "../../Alerts/Alert";
import LoadingDualRing from "../../LoadingDualRing";
import NavigationCard from "../../NavigationPanel/NavigationCard";
import { DeliveryOrderStatusButton } from "../../Orders/DeliveryOrdersByStatusModal";
import ShipmentOrderCount from "../../Orders/Shipments/ShipmentOrderCount";
import WarehouseSelect from "../../Warehouses/WarehouseSelect";
import { Flex, GridTemplate, SquareButton, TextField } from "../../_controls";
import FlexImage from "../../_controls/containers/FlexImage";
import { Table } from "../../_controls/tables/styled";
import { ProductChildrenCardForm } from "../ProductCreationForm/styled";
import { getProductSku, getProductThumbnailUrl } from "../helper";
import InventoryProductAdjustmentModal from "./InventoryProductAdjusmentModal";
import messages from "./messages";

export const NavigationStock = (props: ProductNavigationProfileProps) => {
    return (
        <>
            <NavigationCard {...props} title={props.intl.formatMessage(messages.stock)}>
                <NavigationStockContent {...props} />
            </NavigationCard>
        </>
    );
};

const NavigationStockContent = (props: ProductNavigationProfileProps) => {
    const { warehouseProduct, selectedWarehouse, setSelectedWarehouse, fetchWarehouseProduct } = props;
    const { warehouseState } = useWarehouseContext();

    const defaultWarehouseId = props.defaultWarehouseId ?? selectedWarehouse?.id;

    const [showInventoryAdjustmentModal, setShowInventoryAdjustmentModal] = useState(false);

    const onProductChange = () => {
        fetchWarehouseProduct();
    };

    if (props.product.isParent) {
        return <ProductStockForParent {...props} />;
    }

    if (warehouseState.isFetching) {
        return <LoadingDualRing center />;
    }

    return (
        <Flex column gap={20}>
            <WarehouseSelect defaultWarehouseId={defaultWarehouseId} onWarehouseChange={(value) => setSelectedWarehouse(value)} />
            <div>
                {showInventoryAdjustmentModal && warehouseProduct && (
                    <InventoryProductAdjustmentModal
                        onCompleted={onProductChange}
                        onCancel={() => setShowInventoryAdjustmentModal(false)}
                        productVariant={props.productVariant}
                        warehouseProduct={{ ...warehouseProduct, product: props.product, productVariant: props.productVariant }}
                        show={showInventoryAdjustmentModal}
                        setShow={setShowInventoryAdjustmentModal}
                    />
                )}

                {selectedWarehouse && warehouseProduct && (
                    <ProductStockView
                        warehouseProduct={warehouseProduct}
                        product={props.product}
                        productVariant={props.productVariant}
                        onClickAdjustStock={() => setShowInventoryAdjustmentModal(true)}
                        selectedWarehouse={selectedWarehouse}
                        setWarehouseProduct={(value) => props.setWarehouseProduct(value)}
                    />
                )}
            </div>
        </Flex>
    );
};

interface ProductStockViewProps {
    selectedWarehouse: IWarehouse;
    warehouseProduct: IWarehouseProduct;
    setWarehouseProduct: (item: IWarehouseProduct) => void;
    product?: IProduct;
    productVariant?: IProductVariant;
    onClickAdjustStock: () => void;
}

interface StockRowProps {
    icon?: string;
    hideIcon?: boolean;
    title: string;
    value?: string | number | React.ReactNode;
    showLink?: boolean;
    buttonComp?: React.ReactNode;
    isLoading?: boolean;
    onClickButton?: () => void;
}
export const StockRow = (props: StockRowProps) => {
    const { title, value, icon } = props;

    const hasButton = props.showLink || props.buttonComp !== undefined;

    return (
        <tr className={hasButton ? "tr-button" : ""}>
            {!props.hideIcon && (
                <td className="min-width">
                    <span className={`${icon} row-icon`}></span>
                </td>
            )}
            <td>
                <TextField nowrap>{title}</TextField>
            </td>

            <td className="text-right">
                {props.isLoading ? (
                    <LoadingDualRing small />
                ) : (
                    <span>{value === undefined ? <strong className=" wahioicon-infinity" /> : <strong>{value}</strong>}</span>
                )}
            </td>

            <td className="min-width">
                {props.showLink && (
                    <SquareButton
                        onClick={() => props.onClickButton && props.onClickButton()}
                        borderRadius={10}
                        bgLight
                        className="btn-show small"
                    >
                        <i className="fa-regular fa-eye"></i>
                    </SquareButton>
                )}
                {props.buttonComp}
            </td>
        </tr>
    );
};

function ProductStockView(props: ProductStockViewProps) {
    const { warehouseProduct } = props;
    const intl = useIntl();
    const alert = useAlert();
    const stockAvailable = warehouseProduct.stock - warehouseProduct.reserved;
    const [updatingReservedStock, setUpdatingReservedStock] = useState(false);

    const { userState } = useUserContext();

    useEffect(() => {
        handleSearchReservedCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseProduct.id]);

    const autoUpdateReservedStock = (reservedStock: number) => {
        const body: IInventoryProductAdjustmentModel = {
            productId: warehouseProduct.productId,
            warehouseId: warehouseProduct.warehouseId,
            productVariantId: warehouseProduct.productVariantId,
            accountUserId: getAuthBody().accountUserId,
            quantity: reservedStock,
            serials: [],
        };
        setUpdatingReservedStock(true);
        wahioFetch.put(
            productEndpoint.put.updateReservedStock,
            body,
            () => {
                alert.info(`El stock reservado ha sido ajustado automáticamente de (${warehouseProduct.reserved}) a (${reservedStock})`);
                props.setWarehouseProduct({ ...warehouseProduct, reserved: reservedStock });
                setUpdatingReservedStock(false);
            },
            (error) => {
                alert.error(getServiceMessageError(error));
                setUpdatingReservedStock(false);
            }
        );
    };

    const handleSearchReservedCount = () => {
        const data: ProductReservedSearchInput = {
            productId: warehouseProduct.productId,
            warehouseId: warehouseProduct.warehouseId,
            productVariantId: warehouseProduct.productVariantId,
        };

        wahioFetch.post(
            localOrderEndpoint.post.getProductReservedCount,
            data,
            (success) => {
                const data: ProductCountResponse = success.data;
                if (data.count !== warehouseProduct.reserved) {
                    autoUpdateReservedStock(data.count);
                }
            },
            (error) => {}
        );
    };

    const permission = getUserPermissions(userState.user).inventoryAdjustment;

    return (
        <div>
            {props.productVariant && <TextField bold>Stock {props.productVariant ? `: ${props.productVariant.valuesLine}` : ""}</TextField>}

            <Table cellSpacing="0" cellPadding="0" tdPadding={6} tdPaddingRight={15} rowHeight={45}>
                <tbody>
                    <StockRow
                        icon="fa-regular fa-box"
                        title="Stock"
                        value={warehouseProduct.stock}
                        buttonComp={
                            !props.selectedWarehouse.allowLogisticsService &&
                            !props.product?.includesSerials &&
                            permission?.create &&
                            permission.update &&
                            props.product?.controlInventory && (
                                <SquareButton bgLight borderRadius={10} onClick={() => props.onClickAdjustStock()}>
                                    <span className="fa-regular fa-pen-to-square"></span>
                                </SquareButton>
                            )
                        }
                    />

                    <StockRow
                        icon="fa-regular fa-boxes-stacked"
                        title="Disponible"
                        value={props.product?.controlInventory ? (stockAvailable < 0 ? 0 : stockAvailable) : undefined}
                    />
                    <StockRow
                        icon="fa-regular fa-floppy-disk"
                        title="Reservado"
                        value={warehouseProduct.reserved}
                        isLoading={updatingReservedStock}
                        buttonComp={
                            <DeliveryOrderStatusButton
                                searchReservedStock
                                warehouseProduct={props.warehouseProduct}
                                deliveryStatusList={[]}
                                onOrderUpdate={() => {}}
                            />
                        }
                    />

                    {warehouseProduct.stock < 0 && (
                        <StockRow icon="wahioicon-exclamation-triangle" title="Stock Negativo" value={warehouseProduct.stock} />
                    )}

                    <StockRow
                        icon="fa-regular fa-truck-fast"
                        title="En Camino"
                        value={<ShipmentOrderCount warehouseProduct={props.warehouseProduct} status={"shipped"} />}
                        buttonComp={
                            <DeliveryOrderStatusButton
                                warehouseProduct={props.warehouseProduct}
                                deliveryStatusList={["shipped"]}
                                onOrderUpdate={() => {}}
                            />
                        }
                    />
                </tbody>
            </Table>

            <TextField small light>
                {intl.formatMessage(messages.updated)} {getDateFromNow(warehouseProduct.dateUpdated)}
            </TextField>
        </div>
    );
}

const ProductStockForParent = (props: ProductNavigationProfileProps) => {
    const { product, setWarehouseProduct, selectedWarehouse } = props;

    const alert = useAlert();

    const checkProductParentMutation = useMutation((body: WarehouseProductSummaryInput) => getWarehouseProductCheckParentStock(body), {
        onSuccess: (data) => {},
        onError: (err) => {
            alert.error(getServiceMessageError(err));
        },
    });

    useEffect(() => {
        if (props.warehouseProduct) handleCheckProductParent(props.warehouseProduct);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckProductParent = async (wp: IWarehouseProduct) => {
        if (!selectedWarehouse) return;

        if (product.isParent) {
            let data: WarehouseProductSummaryInput = {
                productId: product.id,
                warehouseId: selectedWarehouse?.id ?? "",
            };
            var result = await checkProductParentMutation.mutateAsync(data);
            if (result.stock !== wp.stock) {
                let newData = { ...wp, stock: result.stock };
                setWarehouseProduct(newData);

                alert.info("El stock del producto compuesto ha sido actualizado");
            }
        }
    };

    return (
        <Flex column gap={20}>
            <Flex column>
                <TextField bold>Stock: {props.warehouseProduct?.stock}</TextField>
                <TextField small light>
                    Stock del producto esta basado en la disponibilidad de sus composiciones
                </TextField>
            </Flex>
            <ProductChildrenView product={props.product} />
        </Flex>
    );
};

interface ProductChildrenViewProps {
    product: IProduct;
}

function ProductChildrenView(props: ProductChildrenViewProps) {
    const { product } = props;

    return (
        <>
            <GridTemplate>
                {product.childs?.map((item, index) => (
                    <ProductChildrenCardForm key={index}>
                        <FlexImage radius={10} width={40} image={getProductThumbnailUrl(item.child, item.productVariant)} />

                        <Flex column w100>
                            <Flex spaceBetween>
                                <span>{item.child?.name}</span>
                                <TextField bold> x {item.quantity}</TextField>
                            </Flex>
                            <TextField small light>
                                {getProductSku(item.child, item.productVariant)}
                            </TextField>
                        </Flex>
                    </ProductChildrenCardForm>
                ))}
            </GridTemplate>
        </>
    );
}

export default NavigationStock;
