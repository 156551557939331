import React from "react";
import messages from "./messages";
import { useIntl } from "react-intl";
import Modal, { IModalShow } from "../modals/Modal";
import StoreForm from "./StoreForm";

export interface StoreFormModalProps extends IModalShow {}

export default function StoreFormModal(props: StoreFormModalProps) {
    const intl = useIntl();
    return (
        <Modal
            scrollInside
            ignoreOutsideClick
            removeVerticalAlign
            title={intl.formatMessage(messages.newShop)}
            showHeader={true}
            {...props}
        >
            <StoreForm onCancel={() => props.setShow(false)} onCreate={() => props.setShow(false)} />
        </Modal>
    );
}
