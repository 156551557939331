import React from "react";
import { PermissionActionDeniedContainer } from "./styled";

interface PermissionActionDeniedProps {
    icon?: string;
    title?: string;
    message: string;
}

export default function PermissionActionDenied(props: PermissionActionDeniedProps) {
    return (
        <PermissionActionDeniedContainer>
            <div className="icon-card">
                <span className={props.icon ?? "wahioicon-lock"}></span>
            </div>
            <div className="content">
                <span>{props.title}</span>
                <span>{props.message}</span>
            </div>
        </PermissionActionDeniedContainer>
    );
}
