import styled from "styled-components";

export const QuickStartHelpContainer = styled.div`
    position: fixed;
    right: 25px;
    bottom: 28px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: white;
    padding-right: 10px;
    border-radius: 40px;
    background: #7c4dff;
    z-index: 6;
    cursor: pointer;
    .content-area {
        padding: 10px;
    }
    .btn-close {
        background: #651fff;
        color: white;
    }
`;

export const QuickHelpPanelContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    .loading-help {
        position: absolute;
        z-index: 0;
        width: 100%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;

export const IframeCustom = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0;
`;

export const QuickStartVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.palette.background};
    top: 70px;
    right: 25px;
    border-radius: 20px;
    position: fixed;
    max-width: 400px;

    width: 100%;
    box-sizing: border-box;
    z-index: 6;
    box-shadow: -2px 1px 15px rgb(0 0 0 / 31%);
    padding-bottom: 30px;

    .quick-start-close {
        position: absolute;
        top: 9px;
        right: 10px;
        border: 0;
        background: #651fff;
        color: white;
    }
    .quick-actions-header-mobile {
        padding: 20px;
        background: #7c4dff;
        color: white;
        a {
            color: white;
            :visited {
                color: white;
            }
        }
    }

    .quick-actions-header {
        padding: 15px 20px;
        color: white;
        background: #7c4dff;

        border-radius: 20px 20px 0 0;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .content-body {
        //column gap={20} paddingLeft={20} paddingRight={20} paddingTop={20}
        gap: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        flex-direction: column;
        overflow: auto;
        max-height: calc(100vh - 200px);
    }

    .quick-actions-options {
        background: ${(props) => props.theme.palette.background};

        .option-card {
            border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
            :last-child {
                border-bottom: none;
            }
        }
    }

    &.mobile {
        border-radius: 15px;
        overflow: hidden;
        height: auto;
        top: auto;
        bottom: 15px;
        right: 15px;
        padding-bottom: 0px;
    }
`;

export const PanelIconContainer = styled.div`
    display: flex;
    padding: 10px 15px;
    border-radius: 15px;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    .card-title {
        color: ${(props) => props.theme.palette.text};
    }

    .ml-auto {
        margin-left: auto;
    }

    :hover {
        background: #7c4dff20;
        .card-title {
            color: #7c4dff;
        }
    }
    .icon {
        color: #7c4dff;
        font-size: 18px;
    }
`;

export const QuickStartOptionCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background: ${(props) => props.theme.palette.background};
    color: ${(props) => props.theme.palette.text};

    .option-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        &.active {
            background: #7c4dff;
            color: white;
        }
    }
    .btn-angle {
        margin-left: auto;
    }
`;

export const CustomVideo = styled.video`
    width: 100%;
    height: auto;
    border-radius: 15px;
`;
