import styled from "styled-components";
import { device} from "../../theme/themeProvider";

export const DateRangeContainerView = styled.div`
    overflow: auto;
    .rdrStaticRange {
        border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.text};
    }
    .rdrStaticRangeLabel {
        &:hover,
        &:focus {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .rdrDefinedRangesWrapper {
        border-right: solid 1px ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.textLight};
    }
    .rdrInputRangeInput {
        border: solid 1px ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.background};
    }
    .rdrCalendarWrapper {
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.text};
    }
    .rdrDayNumber span {
        color: ${(props) => props.theme.palette.text};
    }
    .rdrMonthAndYearPickers select {
        color: ${(props) => props.theme.palette.text};
        background: ${(props) => props.theme.palette.backgroundLight};
    }
    .rdrDateDisplayWrapper {
        background: ${(props) => props.theme.palette.background};
    }
    .rdrDateDisplayItem {
        background-color: ${(props) => props.theme.palette.background};
        box-shadow: 0 1px 2px 0 ${(props) => props.theme.palette.shadowColor};
        border: 1px solid transparent;
    }

    .rdrDateDisplayItemActive {
        background: ${(props) => props.theme.palette.primary}30;
        color: ${(props) => props.theme.palette.primary};
        input {
            color: ${(props) => props.theme.palette.primary};
        }
    }
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
    }
    .rdrDayPassive {
        .rdrDayNumber {
            color: ${(props) => props.theme.palette.textLight};
            span {
                color: ${(props) => props.theme.palette.textLight};
            }
        }
    }
    .rdrDefinedRangesWrapper {
        display: none;
    }
    @media ${device.sm} {
        .rdrDefinedRangesWrapper {
            display: block;
        }
    }
`;

export const DateRangeSelectedView = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    width: fit-content;
    .btn-date-picket-text {
        border: 0px;
        &.small {
            font-size: 13px;
            padding: 5px 11px;
            height: auto;
        }
        &.bg-light {
            background: ${(props) => props.theme.palette.backgroundLight};
        }
    }
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    &.remove-border {
        border: 0px;
    }
    border-radius: 50px;
    .remove-date-filter {
        width: 25px;
        height: 25px;
        min-width: 25px;
        margin-right: 2px;
    }
`;
